import type { Article } from "../../../../../types";

export const Article588: Article = {
	id: 588,
	rsuiteId: "0d30a2c4-179e-42b1-9c5d-13e923e7b83d",
	type: "scientific article",
	title:
		"Validation of DASH and QuickDASH for Outcome Assessment of Anatomic Total Shoulder Arthroplasty for Treatment of Advanced Glenohumeral Osteoarthritis",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0d30a2c4-179e-42b1-9c5d-13e923e7b83d&type=jpeg&name=JBJS.23.01288f1",
	subSpecialties: ["shoulder_elbow"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Validation of DASH and QuickDASH for Outcome Assessment
                of Anatomic Total Shoulder Arthroplasty for Treatment of Advanced Glenohumeral Osteoarthritis</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Oscar
                      Covarrubias, BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ty Agaisse,
                      BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Background:</span> The Disabilities of the Arm, Shoulder and Hand
                        (DASH) questionnaire and its abbreviated version, QuickDASH, are commonly used patient-reported
                        outcome measures (PROMs) for the assessment of an upper-extremity disability. Theoretically,
                        they can be used to compare the treatment outcomes of different upper-extremity conditions.
                        Despite increasingly widespread use for some shoulder conditions, these questionnaires have not
                        been rigorously validated for use to assess the outcomes of patients undergoing anatomic total
                        shoulder arthroplasty (aTSA). The objective of this study was to validate the DASH and QuickDASH
                        scores for a longitudinal outcome assessment of patients undergoing aTSA to treat advanced
                        glenohumeral osteoarthritis (GHOA).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Methods:</span> In this study, 450 patients with a median age of 70.3
                        years (interquartile range [IQR] width, 11.7 years) were included; 52.4% of the patients were
                        female. The DASH and QuickDASH questionnaires, the American Shoulder and Elbow Surgeons (ASES)
                        score, the Simple Shoulder Test (SST), and the EuroQol-5 Dimensions (EQ-5D) questionnaire were
                        completed preoperatively and at follow-ups of 3 months, 6 to 12 months, and a minimum of 2 years
                        by patients undergoing aTSA. The criterion validity, construct validity, reliability, and
                        responsiveness of the DASH and QuickDASH were assessed.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Results:</span> The DASH and QuickDASH scores demonstrated moderate
                        to very strong correlations with the ASES scores (ρ = −0.83 to −0.62), SST scores (ρ = −0.73 to
                        −0.55), and EQ-5D scores (ρ = −0.72 to −0.57); minimal floor or ceiling effects; internal
                        consistency (Cronbach alpha &gt; 0.80); and large Cohen d and standardized response means
                        (&lt;1.6) at all time points. Very strong positive correlations were observed between the DASH
                        and QuickDASH scores at all time points (ρ = 0.96 to 0.97).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The DASH and QuickDASH scores, which are
                        region-specific rather than shoulder-specific, are valid, reliable, and responsive PROMs for the
                        evaluation of patients with advanced GHOA treated with aTSA. Therefore, the DASH and QuickDASH
                        scores can be used to compare the outcomes of aTSA for the treatment of advanced GHOA with the
                        outcomes of treatment of other upper-extremity disorders, potentially facilitating comparative
                        cost-effectiveness and value analysis studies.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Patient-reported outcome measures (PROMs) are valuable
                  tools for capturing a patient’s perception of and insight into their condition at a specific time
                  point. PROMs are also used to track changes over time, and they can facilitate comparative evaluation
                  of treatments for different conditions across different patient populations<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">3</span></a>.
                  Further, outcome tools are required to perform studies of cost-effectiveness and value analysis that
                  might be used to determine resource allocation. This requires that the tools, such as health-related
                  quality-of-life measures, are sufficiently general to facilitate comparisons and studies of the
                  treatments for various disorders. However, more specific tools may offer advantages for comparing
                  different musculoskeletal disorders within the same extremity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Several joint or condition-specific PROMs are routinely
                  used for the assessment of shoulder conditions<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">4</span></a>. In
                  contrast, the Disabilities of the Arm, Shoulder and Hand (DASH) questionnaire, which was developed in
                  collaboration with the American Academy of Orthopaedic Surgeons (AAOS) in 1995 as part of the MODEMS
                  (Musculoskeletal Outcomes Data Evaluation and Management System) program, is a generalized
                  upper-extremity outcome tool that assesses the symptoms and function of the entire upper extremity<a
                    href="" data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">7</span></a>. The
                  DASH questionnaire consists of 30 question items that assess physical, psychological, and social
                  function, and it has been independently validated for several upper-extremity conditions and in
                  multiple languages<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">13</span></a>. The DASH questionnaire has been validated for the
                  evaluation of patients with proximal humeral fractures<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">14</span></a>,
                  patients who are undergoing total elbow arthroplasty<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">15</span></a>, and
                  patients with distal radial fractures<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">16</span></a>. In response to critiques of question redundancy and
                  assessment burden, the 11-item QuickDASH was developed using the method of concept retention.
                  Likewise, the QuickDASH has been extensively validated for upper-extremity disorders and conditions<a
                    href="" data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">17</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">20</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Despite the extensive use of the DASH and QuickDASH for
                  evaluating outcomes of upper-extremity disorders, there have been few validation studies of the DASH
                  and QuickDASH questionnaires for specific cohorts with shoulder disorders<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">21</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">26</span></a>. Cost-effectiveness and value analysis studies that compare
                  the treatment of different conditions require the use of a common outcome measure. For example, when
                  comparing the treatment of carpal tunnel syndrome with the treatment of glenohumeral osteoarthritis
                  (GHOA), it is necessary to use the same outcome assessment tool. Although general health-related
                  quality-of-life scores such as the EuroQol-5 Dimensions (EQ-5D) and Short Form-6 Dimension (SF-6D) are
                  commonly used, it may be more appropriate to use a region-specific outcome tool for this purpose. Jain
                  et al. reported that shoulder PROMs are not interchangeable with health-related quality-of-life
                  measures, and recommended that studies of the cost-effectiveness and value of shoulder arthroplasty
                  should incorporate both types of assessments<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">27</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The effective use of PROMs requires that they are
                  validated within the specific patient population, including the same diagnosis and treatment, that is
                  being evaluated<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_11" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">28</span></a>. Prior validation studies of the DASH and QuickDASH
                  questionnaires for shoulder pathologies and treatments have consisted of mixed cohorts of patients,
                  included small cohorts, or only assessed 1 or 2 postoperative time points<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_12" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">29</span></a>. The objectives of this study were to rigorously assess the
                  validity and responsiveness of the DASH and QuickDASH questionnaires within a cohort of patients with
                  advanced GHOA who were treated with anatomic total shoulder arthroplasty (aTSA) across several
                  perioperative time points. We hypothesized that the DASH and QuickDASH questionnaires would
                  demonstrate a meaningful positive correlation with each other, as well as meaningful correlations with
                  the American Shoulder and Elbow Surgeons (ASES) score, the Simple Shoulder Test (SST), and the EQ-5D.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Selection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This study was approved by the Lifespan institutional
                  review board (1940141). A retrospective review of a clinical database of patients who underwent aTSA
                  to treat GHOA by the senior author from January 2000 to June 2021 was performed. Patients were
                  included if they completed the DASH, ASES, SST, and EQ-5D questionnaires preoperatively and during at
                  least 1 postoperative follow-up at 3 months, 6 to 12 months, or a minimum of 2 years. Patients were
                  excluded if they had undergone a prior ipsilateral shoulder surgery or if they had a diagnosis of
                  posttraumatic shoulder arthritis, inflammatory arthritis, osteonecrosis of the humeral head,
                  full-thickness rotator cuff tears, proximal humeral fracture, or a systemic neurological disorder.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Baseline questionnaires were completed by 450 patients
                  with a median age of 70.3 years (interquartile range [IQR] width, 11.7 years); 236 patients (52.4%)
                  were female. The median preoperative scores were 46.7 (IQR width, 22.5) for the DASH and 50.0 (IQR
                  width, 25.0) for the QuickDASH. The characteristics of the patients who did not complete follow-up at
                  the prescribed time points were compared with those who did. Patients with a complete 3-month
                  follow-up were younger (p &lt; 0.001) and more likely to report preoperative narcotic use (p = 0.03).
                  Patients with a complete 6 to 12-month follow-up were younger (p &lt; 0.001) and had a higher body
                  mass index (p = 0.002). Patients with a complete 2-year minimum follow-up, compared with patients who
                  had incomplete follow-up, had lower preoperative median DASH scores (44.2 [IQR width, 21.7] compared
                  with 49.2 [IQR width, 24.8]; p = 0.009) and QuickDASH scores (45.5 [IQR width, 22.2] compared with
                  52.3 [IQR width, 25.6]; p = 0.03) (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Characteristics<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 490.689px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.470672);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Characteristic</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Baseline</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">3 Months (N = 448)</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">6 to 12 Months (N = 424)</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">≥2 Years (N = 403)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Complete</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Incomplete</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Complete</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Incomplete</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Complete</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Incomplete</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">450</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">306
                          (68.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">142
                          (31.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">296
                          (69.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">128
                          (30.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">272
                          (67.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">131
                          (32.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70.3
                          (11.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69.3
                          (11.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72.1
                          (11.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_44" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69.6
                          (11.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72.0
                          (11.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_45" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69.5
                          (11.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71.9
                          (14.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.73</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.93</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">214
                          (47.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">147
                          (48%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65
                          (45.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">136
                          (45.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (50%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">128
                          (47.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63
                          (48.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">236
                          (52.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">159
                          (52%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77
                          (54.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">160
                          (54.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (50%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">144
                          (52.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68
                          (51.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Body mass index<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_40" class="annotation emphasis"
                            style="position: unset;">(kg/m</span><span data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_13"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_42"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.2
                          (6.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.3
                          (6.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.8
                          (6.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.35</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.5
                          (7.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.4
                          (11.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_46" class="annotation strong" style="position: unset;">0.002</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.2
                          (6.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.6
                          (7.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.10</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Current tobacco
                          smoking<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71
                          (15.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (15%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (17.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.58</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49
                          (16.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (13.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.48</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48
                          (17.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (13.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.29</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dominant arm
                          affected<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">247
                          (54.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">166
                          (54.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80
                          (56.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.76</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">159
                          (53.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74
                          (57.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.50</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">142
                          (52.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76
                          (58.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.32</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative narcotic
                          use<span data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (7.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (9.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (3.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_47" class="annotation strong" style="position: unset;">0.03</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (7.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (5.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.54</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (5.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (9.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.32</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total no. of medical
                          comorbidities<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (2.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (2.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (2.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.93</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (2.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (2.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.35</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (2.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (2.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.86</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative scores<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> DASH</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.7
                          (22.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45.8
                          (22.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47.5
                          (22.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.84</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.2
                          (22.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45.8
                          (22.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.43</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44.2
                          (21.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49.2
                          (24.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_48" class="annotation strong" style="position: unset;">0.009</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> QuickDASH</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.0
                          (25.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.0
                          (25.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.0
                          (24.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.89</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.0
                          (25.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45.5
                          (26.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.26</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45.5
                          (22.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52.3
                          (25.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_49" class="annotation strong" style="position: unset;">0.03</span></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Complete = completed DASH and QuickDASH questionnaires. Incomplete =
                        incomplete DASH and QuickDASH questionnaires. Bold indicates a significant difference between
                        groups (p &lt; 0.05).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with or without the
                        percentage in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the median, with the IQR width in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Patients were censored following any ipsilateral or
                  contralateral upper-extremity surgery after the aTSA. The last follow-up before any additional
                  upper-extremity surgery was performed subsequent to the index aTSA was considered the final data point
                  (<a href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01288f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0d30a2c4-179e-42b1-9c5d-13e923e7b83d&type=jpeg&name=JBJS.23.01288f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;">Initial cohort selection and postoperative
                          censoring of patients who underwent an upper-extremity surgical procedure following index
                          aTSA.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">To achieve a 65% compliance rate across all time points,
                  the 6 and 12-month postoperative follow-ups were combined. The consistency of scores across these time
                  points was assessed by comparing the changes in DASH and QuickDASH from preoperatively to 6 and 12
                  months for the 146 patients (49.3%) with completed questionnaires at all 3 time points. There were no
                  significant differences in DASH and QuickDASH scores between 6 and 12 months, with any changes being
                  less than the minimum clinically important difference (MCID) and therefore not clinically relevant
                  (see Appendix Table I). For the 2-year minimum follow-up, the earliest such postoperative follow-up
                  was used, resulting in a median follow-up of 2 years (IQR width, 1 year [range, 2 to 10 years]) (see
                  Appendix Tables II and III).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The distribution of continuous variables was assessed
                  using the Shapiro-Wilk test. As a result of the non-normal distributions, continuous variables were
                  reported as medians and IQR widths. Categorical variables were reported as frequencies and
                  percentages. Differences between patients with and without complete follow-up were assessed using
                  chi-square tests for categorical variables and independent 2-tailed Wilcoxon rank sum tests for
                  continuous variables. Generalized estimating equations with a first-order autoregressive correlation
                  structure were used to compare PROM scores across time points. The threshold for significance was set
                  at p &lt; 0.05. The MCIDs for the DASH and QuickDASH scores have yet to be determined for patients
                  undergoing aTSA for GHOA. Therefore, an MCID of 12 to 15 points, as recommended by <span
                    data-id="emphasis_43" class="annotation emphasis" style="position: unset;">The DASH and QuickDASH
                    Outcome Measure User’s Manual (Third Edition)</span>, was used to assess the clinical importance of
                  score changes<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">5</span></a>. All
                  statistical analyses were conducted using R software (version 4.3.0; The R Foundation).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Criterion validity was assessed using Spearman rank
                  correlation coefficients (ρ) to evaluate the construct convergence between the DASH and QuickDASH
                  scores and between them and the ASES and SST scores, which have been extensively validated within
                  patients undergoing shoulder arthroplasty and thus are considered to be reliable standards<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_15" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">31</span></a>. In addition, correlation with the EQ-5D was evaluated
                  because the DASH score also evaluates more general components of overall health<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">32</span></a>. Correlations were categorized as very weak (ρ = 0.00 to
                  0.19), weak (ρ = 0.20 to 0.39), moderate (ρ = 0.40 to 0.59), strong (ρ = 0.60 to 0.79), or very strong
                  (ρ = 0.80 to 1.00)<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">33</span></a>.
                  Negative correlations of the DASH and QuickDASH scores with the other PROM scores indicate a positive
                  association, as the best score on the DASH and QuickDASH is 0 and the worst is 100, whereas higher
                  scores on the other PROMs are better scores.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Construct validity was evaluated by testing the hypothesis
                  that satisfied patients would have lower postoperative DASH and QuickDASH scores compared with
                  unsatisfied patients<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">3</span></a>. The
                  presence of floor and ceiling effects in the DASH and QuickDASH scores was evaluated by determining
                  the percentage of patients with the maximum scores (100) and minimum scores (0) possible at each time
                  point.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Internal consistency describes the extent to which the
                  individual items that make up a questionnaire measure the same concept<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">34</span></a>, which, in this study, was the disability of the upper
                  extremity assessed by the DASH and QuickDASH scores. The Cronbach alpha (⍺) was used to evaluate
                  internal consistency by analyzing the correlation among the individual items of the 30 items of the
                  DASH and among the 11 items of the QuickDASH at each time point. An ⍺ of &gt;0.70 was considered
                  indicative of acceptable internal consistency<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">34</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The measurement error for the DASH and QuickDASH scores
                  was characterized by calculating the standard error of measurement (SEM) and smallest detectable
                  change (SDC) at confidence levels of 90% (SDC90%; z-score, 1.645) and 95% (SDC95%; z-score, 1.95). The
                  SEM was calculated on the basis of the previously determined intraclass correlation coefficient of
                  0.96<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_21" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">29</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The responsiveness of the DASH and QuickDASH scores was
                  evaluated using the Cohen d and the standardized response mean (SRM) at all postoperative time points.
                  The Cohen d was calculated by dividing the difference between the preoperative and postoperative
                  scores by the standard deviation of the preoperative score. The SRM was calculated by dividing the
                  difference between the preoperative and postoperative scores by the standard deviation of the change
                  in the scale<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">5</span></a>. The
                  relationships between score changes in the DASH, QuickDASH, ASES, SST, and EQ-5D scores was assessed
                  using the Spearman rank correlation coefficients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Criterion
                Validity</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">The DASH score demonstrated strong to very strong
                  correlations with the ASES (ρ = −0.81 to −0.62), moderate to strong correlations with the SST scores
                  (ρ = −0.73 to −0.55), and moderate to strong correlations with the EQ-5D score (ρ = −0.72 to −0.58).
                  The QuickDASH score demonstrated strong to very strong correlations with the ASES score (ρ = −0.83 to
                  −0.62) and moderate to strong correlations with the SST (ρ = −0.72 to −0.55) and EQ-5D scores (ρ =
                  −0.71 to −0.57). The DASH score demonstrated very strong positive correlations with the QuickDASH
                  score (ρ = 0.96 to 0.97) at all time points (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Concurrent Criterion Validity of the DASH and
                          QuickDASH Scores in Relation to the ASES, SST, and EQ-5D Scores<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 388.944px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.656687);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">DASH and QuickDASH</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">ASES Score<span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">SST<span data-id="table_footnote_reference_12"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">EQ-5D<span data-id="table_footnote_reference_13"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">DASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">QuickDASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">DASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">QuickDASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">DASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">QuickDASH</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.96 (0.95
                          to 0.97)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.62
                          (−0.70 to −0.53)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.62
                          (−0.70 to −0.53)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.55
                          (−0.61 to −0.48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.55
                          (−0.61 to −0.48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.61
                          (−0.68 to −0.52)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.63
                          (−0.70 to −0.54)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 months</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.97 (0.97
                          to 0.98)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.79
                          (−0.85 to 0.72)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.79
                          (−0.85 to −0.72)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.73
                          (−0.79 to −0.65)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.72
                          (−0.78 to −0.64)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.72
                          (−0.79 to −0.62)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.71
                          (−0.79 to −0.61)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6 to 12 months</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.97 (0.96
                          to 0.97)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.80
                          (−0.85 to −0.72)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.77
                          (−0.83 to −0.69)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.57
                          (−0.64 to −0.48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.57
                          (−0.64 to −0.48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.58
                          (−0.68 to −0.47)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.57
                          (−0.67 to −0.45)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥2 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.97 (0.96
                          to 0.97)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.81
                          (−0.85 to −0.74)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.83
                          (−0.87 to −0.77)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.63
                          (−0.70 to −0.56)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.65
                          (−0.71 to −0.57)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.65
                          (−0.73 to −0.55)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.63
                          (−0.71 to −0.53)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The Spearman correlation coefficients (ρ) are presented with the 95%
                        confidence intervals in parentheses and were categorized as very weak (ρ = 0.00 to 0.19), weak
                        (ρ = 0.20 to 0.39), moderate (ρ = 0.40 to 0.59), strong (ρ = 0.60 to 0.79), and very strong (ρ =
                        0.80 to 1.00)<a href="" data-id="citation_reference_40"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_23" class="annotation superscript"
                            style="position: unset;">33</span></a><span data-id="superscript_23"
                          class="annotation superscript" style="position: unset;"></span>.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Negative correlations indicate a positive association, as better
                        function is demonstrated by lower DASH and QuickDASH scores and higher ASES, SST, and EQ-5D
                        scores.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Construct
                Validity</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Satisfied patients, compared with unsatisfied patients,
                  had significantly lower median DASH scores (6.7 [IQR width, 12.4] compared with 21.7 [IQR width,
                  24.9]; p &lt; 0.001) and QuickDASH scores (6.8 [IQR width, 11.4] compared with 23.9 [IQR width, 25.0];
                  p &lt; 0.001) at the 2-year minimum follow-up. The observed differences met or exceeded the MCID of 15
                  and were above the SDC95% threshold (smallest detectable change at a 95% confidence interval). The SEM
                  and the SDC values for the DASH and QuickDASH scores are presented in <a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>. The differences at 3 months and 6 to 12 months were below
                  the SDC95% (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>). There
                  were no patients with the worst DASH or QuickDASH score at any postoperative time point. Ceiling
                  effects were observed in 2.0% to 7.7% of patients for the DASH score and in 7.5% to 14.7% of patients
                  for the QuickDASH score (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Reliability of the DASH and QuickDASH</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 165.862px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.800487);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">3 Months</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">6 to 12 Months</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">≥2 Years</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">DASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">QuickDASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">DASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">QuickDASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">DASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">QuickDASH</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SEM<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.36</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.60</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.15</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.44</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.40</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.64</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SDC90%<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.82</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.38</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.32</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.00</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.92</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.46</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SDC95%<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.32</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.98</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.72</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.52</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.43</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.08</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">SEM = standard error of measurement, calculated using an intraclass
                        correlation coefficient of 0.96<a href="" data-id="citation_reference_41"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_24" class="annotation superscript"
                            style="position: unset;">5</span></a><span data-id="superscript_24"
                          class="annotation superscript" style="position: unset;"></span>.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">SDC90% = the smallest detectable change at a 90% confidence
                        interval.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">SDC95% = the smallest detectable change at a 95% confidence
                        interval.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Postoperative DASH and QuickDASH Scores in Satisfied
                          Patients Compared with Unsatisfied Patients<span data-id="table_footnote_reference_21"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 174.649px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.538462);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">3 Months</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">6 to 12 Months</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">≥2 Years</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Satisfied</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Unsatisfied</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Satisfied</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Unsatisfied</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Satisfied</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Unsatisfied</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients<span
                            data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">114
                          (37.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">192
                          (62.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">181
                          (61.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">115
                          (38.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">173
                          (63.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99
                          (36.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">DASH<span
                            data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.1
                          (14.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.8
                          (22.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_50" class="annotation strong" style="position: unset;">0.001</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.8
                          (9.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.2
                          (24.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_51" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.7
                          (12.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.7
                          (24.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_52" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">QuickDASH<span
                            data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.6
                          (10.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.5
                          (16.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_53" class="annotation strong" style="position: unset;">0.001</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.8
                          (2.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.0
                          (6.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_54" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.8
                          (11.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.9
                          (25.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_55" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Bold indicates a significant difference between groups (p &lt;
                        0.05).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the median, with the IQR width in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Ceiling Effects of the DASH and QuickDASH Scores<span
                            data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 243px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Time Point</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">No. of Patients</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Ceiling Effect<span data-id="table_footnote_reference_22"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">DASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">QuickDASH</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">450</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 months</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">306</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (7.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6 to 12 months</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">296</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (5.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45
                          (15.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥2 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">272</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (7.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (11.4%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">No patients exhibited floor effects (maximum disability score of 100)
                        at any time point.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Ceiling effects are considered a minimal disability score of 0.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Reliability</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The DASH score (⍺ = 0.939 to 0.956) and QuickDASH score (⍺
                  = 0.846 to 0.886) demonstrated Cronbach alpha values indicative of internal consistency at all time
                  points (<a href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Internal Consistency of the DASH and QuickDASH</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 243px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Time Point</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">No. of Patients</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Cronbach Alpha<span data-id="table_footnote_reference_24"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">DASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">QuickDASH</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">450</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.939</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.846</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 months</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">306</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.953</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.874</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6 to 12 months</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">296</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.956</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.881</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥2 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">272</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.956</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.886</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_14" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Cronbach alpha values of &gt;0.70 are indicative of acceptable internal
                        consistency.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Responsiveness</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Large effect sizes were observed for the DASH and
                  QuickDASH scores at all postoperative time points, as indicated by the Cohen d (−2.20 to −1.72) and
                  the SRM (−2.10 to −1.61) (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VII</a>).
                  Moderate to strong correlations were observed between the change in DASH and QuickDASH scores and the
                  change in ASES scores (ρ = −0.700 to −0.581) and SST scores (ρ = −0.633 to −0.526) at all time points.
                  Weak to moderate correlations were observed between the change in DASH scores (ρ = −0.40 to −0.33) (<a
                    href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VIII</a>) and QuickDASH scores (ρ = −0.42 to −0.34) (<a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IX</a>) and the change in EQ-5D scores at all time points.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VII</span>Responsiveness of the DASH and QuickDASH</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 252.112px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.718341);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Time Point</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">No. of Patients</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Change from Preoperative Scores<span
                            data-id="table_footnote_reference_25" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Cohen d<span data-id="table_footnote_reference_26"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">SRM<span data-id="table_footnote_reference_27"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">DASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">QuickDASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">DASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">QuickDASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">DASH</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">QuickDASH</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 months</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">306</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−26.7
                          (20.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−28.8
                          (25.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1.72</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1.75</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1.61</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1.61</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6 to 12 months</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">296</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−31.7
                          (20.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−34.1
                          (23.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−2.20</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−2.13</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−2.10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−2.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥2 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">272</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−27.6
                          (21.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−29.5
                          (20.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1.80</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1.84</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1.69</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1.69</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_15" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the median, with the IQR width in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_16" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Cohen d and SRM effect sizes were categorized as small (&gt;0.20),
                        moderate (&gt;0.50), and large (&gt;0.80).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VIII</span>Correlations Between the Change in the DASH Score
                          and Changes in Other PROMs<span data-id="table_footnote_reference_37"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 381.298px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.689727);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="1" style="position: unset;"><span class="text"
                          style="position: unset;">DASH</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">QuickDASH</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">ASES Scores</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">SST</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">EQ-5D</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Change
                          from Preop. Score<span data-id="table_footnote_reference_28"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Change
                          from Preop. Score<span data-id="table_footnote_reference_29"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ρ<span
                            data-id="table_footnote_reference_30" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Change
                          from Preop. Score<span data-id="table_footnote_reference_31"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ρ<span
                            data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Change
                          from Preop. Score<span data-id="table_footnote_reference_33"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ρ<span
                            data-id="table_footnote_reference_34" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Change
                          from Preop. Score<span data-id="table_footnote_reference_35"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ρ<span
                            data-id="table_footnote_reference_36" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 months</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−26.7
                          (20.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−28.8
                          (25.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.94 (0.93
                          to 0.96)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+50.2
                          (6.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.72
                          (−0.80 to −0.63)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+6.0
                          (4.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.64
                          (−0.72 to −0.54)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+0.183
                          (0.332)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.40
                          (−0.54 to −0.23)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6 to 12 months</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−31.7
                          (20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−34.1
                          (23.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.93 (0.91
                          to 0.94)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+58.3
                          (26.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.61
                          (−0.71 to −0.50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+7.0
                          (3.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.42
                          (−0.51 to −0.31)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+0.230
                          (0.332)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.33
                          (−0.47 to −0.18)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥2 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−27.6
                          (21.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−29.5
                          (20.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.94 (0.93
                          to 0.95)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+56.9
                          (31.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.61
                          (−0.71 to −0.48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+7.0
                          (4.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.47
                          (−0.56 to −0.37)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+0.184
                          (0.305)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.36
                          (−0.50 to −0.21)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_17" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Negative correlations indicate a positive association, except between
                        the DASH and QuickDASH, as better function is demonstrated by lower DASH and QuickDASH scores
                        and higher ASES, SST, and EQ-5D scores.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_18" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the median, with the IQR width in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_19" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Spearman correlation coefficients (ρ) are presented with the 95%
                        confidence intervals in parentheses and were categorized as very weak (ρ =0.00 to 0.19), weak (ρ
                        = 0.20 to 0.39), moderate (ρ = 0.40 to 0.59), strong (ρ = 0.60 to 0.79), and very strong (ρ =
                        0.80 to 1.00)<a href="" data-id="citation_reference_42"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_25" class="annotation superscript"
                            style="position: unset;">33</span></a><span data-id="superscript_25"
                          class="annotation superscript" style="position: unset;"></span>.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IX</span>Correlations Between the Change in the QuickDASH
                          Score and Changes in Other PROMs</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 365.302px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.660643);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="1" style="position: unset;"><span class="text"
                          style="position: unset;">QuickDASH</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">DASH</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">ASES Score</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">SST</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">EQ-5D</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Change
                          from Preop. Score<span data-id="table_footnote_reference_38"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Change
                          from Preop. Score<span data-id="table_footnote_reference_39"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ρ<span
                            data-id="table_footnote_reference_40" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Change
                          from Preop. Score<span data-id="table_footnote_reference_41"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ρ<span
                            data-id="table_footnote_reference_42" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Change
                          from Preop. Score<span data-id="table_footnote_reference_43"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ρ<span
                            data-id="table_footnote_reference_44" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Change
                          from Preop. Score<span data-id="table_footnote_reference_45"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ρ<span
                            data-id="table_footnote_reference_46" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 months</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−28.8
                          (25.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−26.7
                          (20.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.94 (0.93
                          to 0.96)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+50.2
                          (6.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.70
                          (−0.78 to −0.60)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+6.0
                          (4.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.63
                          (−0.71 to −0.53)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+0.183
                          (0.332)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.42
                          (−0.56 to −0.25)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6 to 12 months</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−34.1
                          (23.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−31.7
                          (20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.93 (0.91
                          to 0.94)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+58.3
                          (26.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.58
                          (−0.68 to −0.45)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+7.0
                          (3.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.42
                          (−0.52 to −0.32)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+0.230
                          (0.332)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.38
                          (−0.51 to −0.23)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥2 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−29.5
                          (20.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−27.6
                          (21.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.94 (0.93
                          to 0.95)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+56.9
                          (31.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.61
                          (−0.71 to −0.48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+7.0
                          (4.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.49
                          (−0.58 to −0.39)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+0.184
                          (0.305)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.34
                          (−0.48 to −0.18)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_20" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Negative correlations indicate a positive association, as better
                        function is demonstrated by lower DASH and QuickDASH scores and higher ASES, SST, and EQ-5D
                        scores. The values are given as the median, with the IQR width in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_21" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Spearman correlation coefficients (ρ) are presented with the 95%
                        confidence intervals in parentheses and were categorized as very weak (ρ = 0.00 to 0.19), weak
                        (ρ = 0.20 to 0.39), moderate (ρ = 0.40 to 0.59), strong (ρ = 0.60 to 0.79), and very strong (ρ =
                        0.80 to 1.00)<a href="" data-id="citation_reference_43"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_26" class="annotation superscript"
                            style="position: unset;">33</span></a><span data-id="superscript_26"
                          class="annotation superscript" style="position: unset;"></span>.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Despite the widespread use of the DASH and QuickDASH
                  questionnaires for outcome assessment of upper-extremity disorders, to our knowledge, there have been
                  no validation studies of these instruments for patients undergoing aTSA for GHOA. The DASH score was
                  developed as a region-specific outcome assessment tool and is widely used in various shoulder
                  disorders. It is among the 3 most commonly used PROMs for evaluation of the shoulder in Europe<a
                    href="" data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">35</span></a>. The QuickDASH questionnaire was subsequently developed to
                  reduce the testing burden. Furthermore, there has been little reported on the validation of the DASH
                  questionnaire for more common shoulder disorders. This study specifically addressed the use of DASH
                  and QuickDASH questionnaires to assess the outcomes of patients with advanced GHOA who are treated
                  with aTSA. The DASH and QuickDASH questionnaires demonstrated moderate to very strong correlations
                  with the ASES, SST, and EQ-5D scores; minimal floor or ceiling effects; high internal consistency; and
                  large improvements at 3 months, 6 to 12 months, and a minimum follow-up of 2 years in a relatively
                  large cohort of patients undergoing aTSA for GHOA. There was a near-perfect correlation between the
                  DASH and QuickDASH scores at all time points, strongly suggesting that the shorter QuickDASH
                  questionnaire is also appropriate for use in this specific patient population. Overall, this study
                  demonstrated that, despite being region-specific and not shoulder-specific, the DASH and QuickDASH
                  scores can be used to evaluate the outcomes of patients with advanced GHOA treated with aTSA and thus
                  to make comparisons with treatments of other upper-extremity disorders.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The DASH score demonstrated high internal consistency (⍺
                  &gt; 0.93) and strong to very strong correlations with the ASES score (ρ = −0.81 to −0.62) at all time
                  points. The strength of the correlation between the DASH and ASES scores is consistent with previously
                  reported studies<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_28" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">29</span></a>. Notably, in a cohort of 132 patients, Macdermid et al.
                  reported similar correlation coefficients between DASH and ASES scores at 3 months (r = 0.81) and 6
                  months (r = 0.85) following aTSA<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">17</span></a>.
                  The results of the current study support the strong correlation of the DASH score with the ASES score
                  in the early postoperative period, as well as at time points up to 1 and 2 years when patients are
                  considered to have plateaued in their recovery. The strong correlation between the DASH and ASES
                  scores appears to extend beyond this plateau as suggested by the findings of Angst et al., who
                  performed a cross-sectional study of 43 patients who underwent aTSA and reported a strong correlation
                  (r = 0.79), similar to the present study, between the DASH and ASES scores at 5 to 6 years after the
                  surgical procedure<a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">29</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Additionally, DASH scores demonstrated moderate to strong
                  correlations with SST scores (ρ = −0.73 to −0.55) and EQ-5D scores (ρ = −0.72 to −0.58) at all time
                  points. Although substantial correlation has been consistently reported between the DASH and SST
                  scores<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_31" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_31" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">21</span></a>,
                  the existing literature has indicated a weaker correlation between the DASH and EQ-5D scores<a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">36</span></a>. In a previous report of 92 patients who underwent aTSA
                  performed by the senior author of the present study that included some of the patients in this study,
                  in 2020, Jain et al. found a weak correlation between the change in DASH and change in EQ-5D scores (r
                  = 0.32) at 1 year after the surgical procedure<a href="" data-id="citation_reference_56"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">27</span></a>,
                  which is consistent with the 6 to 12-month score change correlation (ρ = −0.33) observed in the
                  present study. The weakest correlation observed in the present study was between the change in DASH
                  and EQ-5D scores, with no ρ values of &gt;0.60. This suggests divergent criterion validity and
                  reflects the intended use of the EQ-5D as a generalized measure of health-related quality of life, in
                  contrast to the DASH, ASES, and SST scores<a href="" data-id="citation_reference_57"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">37</span></a>. Similar moderate correlations have been reported between
                  the QuickDASH and the EQ-5D scores within patients who underwent aTSA<a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">37</span></a><span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">38</span></a>. Together, these findings support the need for a dedicated
                  instrument, such as the EQ-5D, to assess the impact of aTSA on health-related quality of life.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The QuickDASH score demonstrated high internal consistency
                  (⍺ &gt; 0.84), strong to very strong correlations with the ASES score (ρ = −0.83 to −0.62), and
                  moderate to strong correlations with the SST score (ρ = −0.72 to −0.55) and EQ-5D score (ρ = −0.71 to
                  −0.57) at all time points. Prior validation studies of the QuickDASH score were performed in mixed
                  cohorts undergoing rotator cuff repair, undergoing other upper-extremity arthroplasties, and having
                  various upper-extremity conditions. Within these mixed-cohort validation studies, the QuickDASH score
                  had high internal consistency and nearly perfect correlation with the DASH and ASES scores<a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_36"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">20</span></a>. The results of the current study build upon these mixed
                  cohort studies by demonstrating internal consistency of the QuickDASH score within an isolated cohort
                  of patients who underwent aTSA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">No patients had a DASH or QuickDASH score of 100, and
                  &lt;15% had a score of 0 at all time points evaluated, indicating minimal floor and ceiling effects.
                  This finding, taken together with the large and nearly identical large effect sizes observed for the
                  DASH score (Cohen d = −2.20 to −1.72) and the QuickDASH score (Cohen d = −2.13 to −1.75), demonstrates
                  that these outcome measures are highly responsive for the evaluation of patients with advanced GHOA
                  treated with aTSA. This high degree of responsiveness observed within patients who undergo aTSA builds
                  upon the findings of prior validation studies that showed similar effect sizes in patients with
                  various shoulder conditions, some of which included patients treated with aTSA<a href=""
                    data-id="citation_reference_63" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_37"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">20</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">The primary limitation of this study was that it was a
                  retrospective study with incomplete follow-up. Although a minimum compliance rate of 65% was
                  maintained at all time points, a substantial proportion of the patients did not routinely return for
                  scheduled postoperative appointments. A rigorously structured prospective study might have achieved
                  more complete follow-up. The impact of these differences remains unclear, as there were no significant
                  differences in baseline PROMs between patients with and without follow-up at each time point.
                  Additionally, this study did not analyze the reproducibility of the DASH and QuickDASH scores, as this
                  was not possible because of the retrospective nature but would not likely have altered the validity of
                  the findings. Lastly, these results could not be applied to the evaluation of the outcomes of other
                  types of shoulder arthroplasties used to treat advanced GHOA. The notable strengths of this study
                  include the large sample size, the longitudinal data, a highly specific cohort of patients, and
                  rigorous statistical analysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, the results of this study demonstrate
                  longitudinal moderate to very strong correlations with the ASES, SST, and EQ-5D scores; minimal floor
                  and ceiling effects; internal consistency; and a high degree of responsiveness of both the DASH and
                  QuickDASH scores within patients undergoing aTSA for the treatment of advanced GHOA. The QuickDASH
                  score may be preferable given the reduced number of questions. Taken together, these findings
                  demonstrate that the DASH and QuickDASH scores, which are more generalized upper-extremity outcome
                  tools than the ASES and SST scores, can be used to evaluate the outcomes of these patients. Because
                  the DASH and QuickDASH scores are commonly used to assess outcomes of other upper-extremity disorders,
                  this facilitates their use in comparative studies of the treatment of different upper-extremity
                  disorders that might be relevant to resource allocation decisions in health-care systems.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I221" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I221</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Angst F</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Schwyzer HK</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Aeschlimann A</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Simmen BR</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Goldhahn J</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Measures of adult shoulder
                  function: Disabilities of the Arm, Shoulder, and Hand Questionnaire (DASH) and its short version
                  (QuickDASH), Shoulder Pain and Disability Index (SPADI), American Shoulder and Elbow Surgeons (ASES)
                  Society standardized shoulder assessment form, Constant (Murley) Score (CS), Simple Shoulder Test
                  (SST), Oxford Shoulder Score (OSS), Shoulder Disability Questionnaire (SDQ), and Western Ontario
                  Shoulder Instability Index (WOSI)</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Arthritis Care Res (Hoboken).</span> 2011 Nov;63(Suppl
                11):S174-88.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measures%20of%20adult%20shoulder%20function%3A%20Disabilities%20of%20the%20Arm%2C%20Shoulder%2C%20and%20Hand%20Questionnaire%20(DASH)%20and%20its%20short%20version%20(QuickDASH)%2C%20Shoulder%20Pain%20and%20Disability%20Index%20(SPADI)%2C%20American%20Shoulder%20and%20Elbow%20Surgeons%20(ASES)%20Society%20standardized%20shoulder%20assessment%20form%2C%20Constant%20(Murley)%20Score%20(CS)%2C%20Simple%20Shoulder%20Test%20(SST)%2C%20Oxford%20Shoulder%20Score%20(OSS)%2C%20Shoulder%20Disability%20Questionnaire%20(SDQ)%2C%20and%20Western%20Ontario%20Shoulder%20Instability%20Index%20(WOSI)&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Roy JS</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> MacDermid JC</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Woodhouse LJ</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Measuring shoulder function: a
                  systematic review of four questionnaires</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Arthritis Rheum.</span> 2009 May
                15;61(5):623-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measuring%20shoulder%20function%3A%20a%20systematic%20review%20of%20four%20questionnaires&as_occt=title&as_sauthors=%20%22JS%20Roy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Hsu JE</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Russ SM</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Somerson JS</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Tang A</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Warme WJ</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Matsen FA 3rd</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Is the Simple Shoulder Test a
                  valid outcome instrument for shoulder arthroplasty?</span><span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2017
                Oct;26(10):1693-700.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20the%20Simple%20Shoulder%20Test%20a%20valid%20outcome%20instrument%20for%20shoulder%20arthroplasty%3F&as_occt=title&as_sauthors=%20%22JE%20Hsu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Mosher ZA</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Ewing MA</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Collins CS</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Young PG</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Brabston EW</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Momaya AM</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Tashjian RZ</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Ponce BA</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Usage trends of patient-reported
                  outcome measures in shoulder literature</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2020 Sep 1;28(17):e774-81.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Usage%20trends%20of%20patient-reported%20outcome%20measures%20in%20shoulder%20literature&as_occt=title&as_sauthors=%20%22ZA%20Mosher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Kennedy CA</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Beaton DE</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Solway S</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> McConnell S</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Bombardier C</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">The DASH and QuickDASH
                  Outcome Measure User’s Manual.</span><span data-id="annotation_28" class="annotation"
                  style="position: unset;"> 3rd ed</span>. Toronto, Ontario: Institute for Work & Health; 2011.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;">Institute
                for Work & Health. <span data-id="strong_5" class="annotation strong" style="position: unset;">The DASH
                  Outcome Measure</span>. Accessed 2023 Jun 27. <a href="https://dash.iwh.on.ca/" target="_blank"
                  data-id="link_1" class="link" style="position: unset;">https://dash.iwh.on.ca/</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20DASH%20Outcome%20Measure&as_occt=title&as_sauthors=%20%22Institute%20for%20Work%20%26%20Health%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Gummesson C</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Atroshi I</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Ekdahl C</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The Disabilities of the Arm,
                  Shoulder and Hand (DASH) outcome questionnaire: longitudinal construct validity and measuring
                  self-rated health change after surgery</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">BMC Musculoskelet Disord.</span> 2003 Jun 16;4:11.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Disabilities%20of%20the%20Arm%2C%20Shoulder%20and%20Hand%20(DASH)%20outcome%20questionnaire%3A%20longitudinal%20construct%20validity%20and%20measuring%20self-rated%20health%20change%20after%20surgery&as_occt=title&as_sauthors=%20%22C%20Gummesson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Tsang P</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Walton D</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Grewal R</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> MacDermid J</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Validation of the QuickDASH and
                  DASH in patients with distal radius fractures through agreement analysis</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Arch Phys Med
                  Rehabil.</span> 2017 Jun;98(6):1217-1222.e1.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20of%20the%20QuickDASH%20and%20DASH%20in%20patients%20with%20distal%20radius%20fractures%20through%20agreement%20analysis&as_occt=title&as_sauthors=%20%22P%20Tsang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Beaton DE</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Katz JN</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Fossel AH</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Wright JG</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Tarasuk V</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Bombardier C</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Measuring the whole or the
                  parts? Validity, reliability, and responsiveness of the Disabilities of the Arm, Shoulder and Hand
                  outcome measure in different regions of the upper extremity</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">J Hand Ther.</span> 2001
                Apr-Jun;14(2):128-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measuring%20the%20whole%20or%20the%20parts%3F%20Validity%2C%20reliability%2C%20and%20responsiveness%20of%20the%20Disabilities%20of%20the%20Arm%2C%20Shoulder%20and%20Hand%20outcome%20measure%20in%20different%20regions%20of%20the%20upper%20extremity&as_occt=title&as_sauthors=%20%22DE%20Beaton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Lundquist CB</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Døssing K</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Christiansen DH</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Responsiveness of a Danish
                  version of the Disabilities of the Arm, Shoulder and Hand (DASH) questionnaire</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Dan Med J.</span> 2014
                Apr;61(4):A4813.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Responsiveness%20of%20a%20Danish%20version%20of%20the%20Disabilities%20of%20the%20Arm%2C%20Shoulder%20and%20Hand%20(DASH)%20questionnaire&as_occt=title&as_sauthors=%20%22CB%20Lundquist%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Imaeda T</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Toh S</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Wada T</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Uchiyama S</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Okinaga S</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Kusunose K</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Sawaizumi T</span>; Impairment
                Evaluation Committee, Japanese Society for Surgery of the Hand. <span data-id="strong_10"
                  class="annotation strong" style="position: unset;">Validation of the Japanese Society for Surgery of
                  the Hand Version of the Quick Disability of the Arm, Shoulder, and Hand (QuickDASH-JSSH)
                  questionnaire</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">J Orthop Sci.</span> 2006 May;11(3):248-53.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20of%20the%20Japanese%20Society%20for%20Surgery%20of%20the%20Hand%20Version%20of%20the%20Quick%20Disability%20of%20the%20Arm%2C%20Shoulder%2C%20and%20Hand%20(QuickDASH-JSSH)%20questionnaire&as_occt=title&as_sauthors=%20%22T%20Imaeda%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Hervás MT</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Navarro Collado MJ</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Peiró S</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Rodrigo Pérez JL</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> López Matéu P</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Martínez Tello I</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Spanish version of the DASH
                  questionnaire. Cross-cultural adaptation, reliability, validity and responsiveness</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Med Clin (Barc).</span>
                2006 Sep 30;127(12):441-7. Spanish.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spanish%20version%20of%20the%20DASH%20questionnaire.%20Cross-cultural%20adaptation%2C%20reliability%2C%20validity%20and%20responsiveness&as_occt=title&as_sauthors=%20%22MT%20Herv%C3%A1s%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Lee EWC</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Chung MMH</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Li APS</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Lo SK</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Construct validity of the
                  Chinese version of the Disabilities of the Arm, Shoulder and Hand questionnaire (DASH-HKPWH)</span>.
                <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Hand Surg Br.</span>
                2005 Feb;30(1):29-34.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Construct%20validity%20of%20the%20Chinese%20version%20of%20the%20Disabilities%20of%20the%20Arm%2C%20Shoulder%20and%20Hand%20questionnaire%20(DASH-HKPWH)&as_occt=title&as_sauthors=%20%22EWC%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Slobogean GP</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Noonan VK</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> O’Brien PJ</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">The reliability and validity of
                  the Disabilities of Arm, Shoulder, and Hand, EuroQol-5D, Health Utilities Index, and Short Form-6D
                  outcome instruments in patients with proximal humeral fractures</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2010
                Apr;19(3):342-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20reliability%20and%20validity%20of%20the%20Disabilities%20of%20Arm%2C%20Shoulder%2C%20and%20Hand%2C%20EuroQol-5D%2C%20Health%20Utilities%20Index%2C%20and%20Short%20Form-6D%20outcome%20instruments%20in%20patients%20with%20proximal%20humeral%20fractures&as_occt=title&as_sauthors=%20%22GP%20Slobogean%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Angst F</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> John M</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Pap G</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Mannion AF</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Herren DB</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Flury M</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Aeschlimann A</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Schwyzer HK</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Simmen BR</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Comprehensive assessment of
                  clinical outcome and quality of life after total elbow arthroplasty</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Arthritis Rheum.</span>
                2005 Feb 15;53(1):73-82.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comprehensive%20assessment%20of%20clinical%20outcome%20and%20quality%20of%20life%20after%20total%20elbow%20arthroplasty&as_occt=title&as_sauthors=%20%22F%20Angst%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> MacDermid JC</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Richards RS</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Donner A</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Bellamy N</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Roth JH</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Responsiveness of the Short
                  Form-36, Disability of the Arm, Shoulder, and Hand Questionnaire, Patient-Rated Wrist Evaluation, and
                  physical impairment measurements in evaluating recovery after a distal radius fracture</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J Hand Surg Am.</span> 2000
                Mar;25(2):330-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Responsiveness%20of%20the%20Short%20Form-36%2C%20Disability%20of%20the%20Arm%2C%20Shoulder%2C%20and%20Hand%20Questionnaire%2C%20Patient-Rated%20Wrist%20Evaluation%2C%20and%20physical%20impairment%20measurements%20in%20evaluating%20recovery%20after%20a%20distal%20radius%20fracture&as_occt=title&as_sauthors=%20%22JC%20MacDermid%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Macdermid JC</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Khadilkar L</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Birmingham TB</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Athwal GS</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Validity of the QuickDASH in
                  patients with shoulder-related disorders undergoing surgery</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Orthop Sports Phys Ther.</span> 2015
                Jan;45(1):25-36.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validity%20of%20the%20QuickDASH%20in%20patients%20with%20shoulder-related%20disorders%20undergoing%20surgery&as_occt=title&as_sauthors=%20%22JC%20Macdermid%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Angst F</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Goldhahn J</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Drerup S</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Flury M</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Schwyzer HK</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Simmen BR</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">How sharp is the short
                  QuickDASH? A refined content and validity analysis of the short form of the Disabilities of the
                  Shoulder, Arm and Hand questionnaire in the strata of symptoms and function and specific joint
                  conditions</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Qual Life Res.</span> 2009 Oct;18(8):1043-51.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20sharp%20is%20the%20short%20QuickDASH%3F%20A%20refined%20content%20and%20validity%20analysis%20of%20the%20short%20form%20of%20the%20Disabilities%20of%20the%20Shoulder%2C%20Arm%20and%20Hand%20questionnaire%20in%20the%20strata%20of%20symptoms%20and%20function%20and%20specific%20joint%20conditions&as_occt=title&as_sauthors=%20%22F%20Angst%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Fayad F</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Lefevre-Colau MM</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Gautheron V</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Macé Y</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Fermanian J</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Mayoux-Benhamou A</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Roren A</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Rannou F</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Roby-Brami A</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Revel M</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Poiraudeau S</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Reliability, validity and
                  responsiveness of the French version of the questionnaire Quick Disability of the Arm, Shoulder and
                  Hand in shoulder disorders</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Man Ther.</span> 2009 Apr;14(2):206-12.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reliability%2C%20validity%20and%20responsiveness%20of%20the%20French%20version%20of%20the%20questionnaire%20Quick%20Disability%20of%20the%20Arm%2C%20Shoulder%20and%20Hand%20in%20shoulder%20disorders&as_occt=title&as_sauthors=%20%22F%20Fayad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Gummesson C</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Ward MM</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Atroshi I</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">The shortened Disabilities of
                  the Arm, Shoulder and Hand questionnaire (QuickDASH): validity and reliability based on responses
                  within the full-length DASH</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">BMC Musculoskelet Disord.</span> 2006 May 18;7:44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20shortened%20Disabilities%20of%20the%20Arm%2C%20Shoulder%20and%20Hand%20questionnaire%20(QuickDASH)%3A%20validity%20and%20reliability%20based%20on%20responses%20within%20the%20full-length%20DASH&as_occt=title&as_sauthors=%20%22C%20Gummesson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Bot SD</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Terwee CB</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> van der Windt DAWM</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Bouter LM</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Dekker J</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> de Vet HCW</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Clinimetric evaluation of
                  shoulder disability questionnaires: a systematic review of the literature</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Ann Rheum Dis.</span> 2004
                Apr;63(4):335-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinimetric%20evaluation%20of%20shoulder%20disability%20questionnaires%3A%20a%20systematic%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22SD%20Bot%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Booker S</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Alfahad N</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Scott M</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Gooding B</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Wallace WA</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Use of scoring systems for
                  assessing and reporting the outcome results from shoulder surgery and arthroplasty</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">World J Orthop.</span> 2015
                Mar 18;6(2):244-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Use%20of%20scoring%20systems%20for%20assessing%20and%20reporting%20the%20outcome%20results%20from%20shoulder%20surgery%20and%20arthroplasty&as_occt=title&as_sauthors=%20%22S%20Booker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Challoumas D</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Zouvani A</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Creavin K</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Murray E</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Crosbie G</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Ng N</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Millar NL</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Determining minimal important
                  differences for patient-reported outcome measures in shoulder, lateral elbow, patellar and Achilles
                  tendinopathies using distribution-based methods</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">BMC Musculoskelet Disord.</span> 2023 Mar
                2;24(1):158.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Determining%20minimal%20important%20differences%20for%20patient-reported%20outcome%20measures%20in%20shoulder%2C%20lateral%20elbow%2C%20patellar%20and%20Achilles%20tendinopathies%20using%20distribution-based%20methods&as_occt=title&as_sauthors=%20%22D%20Challoumas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Kheradmand E</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Rahimi SM</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Nakhaei Amroodi M</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Nejati P</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Griffin S</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Cross-cultural adaptation,
                  validity and reliability of the Persian translation of the Western Ontario Shoulder Instability Index
                  (WOSI)</span>. <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J
                  Orthop Surg Res.</span> 2023 Mar 7;18(1):174.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cross-cultural%20adaptation%2C%20validity%20and%20reliability%20of%20the%20Persian%20translation%20of%20the%20Western%20Ontario%20Shoulder%20Instability%20Index%20(WOSI)&as_occt=title&as_sauthors=%20%22E%20Kheradmand%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Tuphe P</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Caubriere M</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Hubert L</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Lancigu R</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Sakek F</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Loisel F</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Obert L</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Rony L</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Early rehabilitation after
                  reverse total shoulder prosthesis on fracture of proximal humerus in elderly patients provides better
                  functional outcome</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Eur J Orthop Surg Traumatol.</span> 2023 Oct;33(7):2951-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20rehabilitation%20after%20reverse%20total%20shoulder%20prosthesis%20on%20fracture%20of%20proximal%20humerus%20in%20elderly%20patients%20provides%20better%20functional%20outcome&as_occt=title&as_sauthors=%20%22P%20Tuphe%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Venturin D</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Giannotta G</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Pellicciari L</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Rossi A</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Pennella D</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Goffredo M</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Poser A</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Reliability and validity of the
                  Shoulder Pain and Disability Index in a sample of patients with frozen shoulder</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2023 Mar 22;24(1):212.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reliability%20and%20validity%20of%20the%20Shoulder%20Pain%20and%20Disability%20Index%20in%20a%20sample%20of%20patients%20with%20frozen%20shoulder&as_occt=title&as_sauthors=%20%22D%20Venturin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Jain SS</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> DeFroda SF</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Paxton ES</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Green A</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Patient-reported outcome
                  measures and health-related quality-of-life scores of patients undergoing anatomic total shoulder
                  arthroplasty</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2020 Jul 1;102(13):1186-93.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2412107" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Fu MC</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Chang B</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Wong AC</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Nwachukwu BU</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Warren RF</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Dines DM</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Dines JS</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Cordasco FA</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Lyman S</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Gulotta LV</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">PROMIS physical function
                  underperforms psychometrically relative to American Shoulder and Elbow Surgeons score in patients
                  undergoing anatomic total shoulder arthroplasty</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2019
                Sep;28(9):1809-15.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=PROMIS%20physical%20function%20underperforms%20psychometrically%20relative%20to%20American%20Shoulder%20and%20Elbow%20Surgeons%20score%20in%20patients%20undergoing%20anatomic%20total%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22MC%20Fu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Angst F</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Pap G</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Mannion AF</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Herren DB</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Aeschlimann A</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Schwyzer HK</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Simmen BR</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Comprehensive assessment of
                  clinical outcome and quality of life after total shoulder arthroplasty: usefulness and validity of
                  subjective outcome measures</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Arthritis Rheum.</span> 2004 Oct 15;51(5):819-28.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comprehensive%20assessment%20of%20clinical%20outcome%20and%20quality%20of%20life%20after%20total%20shoulder%20arthroplasty%3A%20usefulness%20and%20validity%20of%20subjective%20outcome%20measures&as_occt=title&as_sauthors=%20%22F%20Angst%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Godfrey J</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Hamman R</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Lowenstein S</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Briggs K</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Kocher M</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Reliability, validity, and
                  responsiveness of the simple shoulder test: psychometric properties by age and injury type</span>.
                <span data-id="emphasis_29" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2007 May-Jun;16(3):260-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reliability%2C%20validity%2C%20and%20responsiveness%20of%20the%20simple%20shoulder%20test%3A%20psychometric%20properties%20by%20age%20and%20injury%20type&as_occt=title&as_sauthors=%20%22J%20Godfrey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Baessler AM</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Brolin TJ</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Azar FM</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Sen S</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Chang M</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Falkner D</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Zmistowski BM</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Routman HD</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Namdari S</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Gulotta LV</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Throckmorton TW</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Development and validation of a
                  predictive model for outcomes in shoulder arthroplasty: a multicenter analysis of nearly 2000
                  patients</span>. <span data-id="emphasis_30" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2021 Dec;30(12):2698-702.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20and%20validation%20of%20a%20predictive%20model%20for%20outcomes%20in%20shoulder%20arthroplasty%3A%20a%20multicenter%20analysis%20of%20nearly%202000%20patients&as_occt=title&as_sauthors=%20%22AM%20Baessler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Herdman M</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Gudex C</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Lloyd A</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Janssen M</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Kind P</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Parkin D</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Bonsel G</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Badia X</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Development and preliminary
                  testing of the new five-level version of EQ-5D (EQ-5D-5L)</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">Qual Life Res.</span> 2011
                Dec;20(10):1727-36.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20and%20preliminary%20testing%20of%20the%20new%20five-level%20version%20of%20EQ-5D%20(EQ-5D-5L)&as_occt=title&as_sauthors=%20%22M%20Herdman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Evans JD</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Straightforward Statistics
                  for the Behavioral Sciences.</span> Brooks/Cole Publishing Company; 1996.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Tavakol M</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Dennick R</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Making sense of Cronbach’s
                  alpha</span>. <span data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Int J
                  Med Educ.</span> 2011 Jun 27;2:53-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Making%20sense%20of%20Cronbach%E2%80%99s%20alpha&as_occt=title&as_sauthors=%20%22M%20Tavakol%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Torrance E</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Hallam L</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Walton MJ</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Monga P</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Watts AC</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Funk L</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">The combined shoulder
                  assessment: a convenient method for obtaining equivalent outcome scores</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Shoulder Elbow.</span> 2019
                Jun;11(3):182-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20combined%20shoulder%20assessment%3A%20a%20convenient%20method%20for%20obtaining%20equivalent%20outcome%20scores&as_occt=title&as_sauthors=%20%22E%20Torrance%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Barger J</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Zhang D</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Stenquist DS</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Ostergaard P</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Hall M</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Dyer GSM</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Earp BE</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> von Keudell A</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Correlation and responsiveness
                  of global health, upper extremity-specific, and shoulder-specific functional outcome measures
                  following reverse total shoulder arthroplasty for proximal humerus fracture</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2021 Jun 23;22(1):574.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Correlation%20and%20responsiveness%20of%20global%20health%2C%20upper%20extremity-specific%2C%20and%20shoulder-specific%20functional%20outcome%20measures%20following%20reverse%20total%20shoulder%20arthroplasty%20for%20proximal%20humerus%20fracture&as_occt=title&as_sauthors=%20%22J%20Barger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Marks M</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Grobet C</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Audigé L</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Validity, responsiveness and
                  minimal important change of the EQ-5D-5L in patients after rotator cuff repair, shoulder arthroplasty
                  or thumb carpometacarpal arthroplasty</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">Qual Life Res.</span> 2021 Oct;30(10):2973-82.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validity%2C%20responsiveness%20and%20minimal%20important%20change%20of%20the%20EQ-5D-5L%20in%20patients%20after%20rotator%20cuff%20repair%2C%20shoulder%20arthroplasty%20or%20thumb%20carpometacarpal%20arthroplasty&as_occt=title&as_sauthors=%20%22M%20Marks%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Grobet CE</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Glanzmann MC</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Eichler K</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Rickenbacher D</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Meier F</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Brunner B</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Audigé L</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Cost-utility analysis of total
                  shoulder arthroplasty: a prospective health economic study using real-world data</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2021 Sep;30(9):1998-2006.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cost-utility%20analysis%20of%20total%20shoulder%20arthroplasty%3A%20a%20prospective%20health%20economic%20study%20using%20real-world%20data&as_occt=title&as_sauthors=%20%22CE%20Grobet%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;0d30a2c4-179e-42b1-9c5d-13e923e7b83d&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[]}"
                href="/php/content/content_api.php?op=download&rsuite_id=0d30a2c4-179e-42b1-9c5d-13e923e7b83d&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 8.15602%;"></div>
                  <div class="pages" style="position: unset;"></div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;0d30a2c4-179e-42b1-9c5d-13e923e7b83d&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=0d30a2c4-179e-42b1-9c5d-13e923e7b83d&type=supplement&name=12500"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D0d30a2c4-179e-42b1-9c5d-13e923e7b83d%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2187</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01288</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 2, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_38"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Alpert
                            Medical School, Brown University, Providence, Rhode Island</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;0d30a2c4-179e-42b1-9c5d-13e923e7b83d&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=0d30a2c4-179e-42b1-9c5d-13e923e7b83d&type=pdf&name=JBJS.23.01288.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=0d30a2c4-179e-42b1-9c5d-13e923e7b83d&type=pdf&name=JBJS.23.01288.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_42"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this study. The <span data-id="strong_43" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I220"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I220</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;0d30a2c4-179e-42b1-9c5d-13e923e7b83d&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=0d30a2c4-179e-42b1-9c5d-13e923e7b83d&type=zip&name=JBJS.23.01288.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Oscar Covarrubias, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ty Agaisse, BS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6342-4175" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6342-4175</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brandon Portnoff, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5756-6940" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5756-6940</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ryan Hoffman, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Janine Molino, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6258-3913" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6258-3913</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">E. Scott Paxton, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0795-4918" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0795-4918</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Andrew Green, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:agshoulder@aol.com" class="" style="position: unset;">agshoulder@aol.com</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4599-2915" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4599-2915</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Shoulder and Elbow Surgery, Department of Orthopaedic Surgery,
                Alpert Medical School, Brown University. Providence, Rhode Island</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Alpert Medical School, Brown University, Providence, Rhode Island</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 31146.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
