/* ----------------- Third Party Imports --------------- */
import { View } from "react-native";

import { formatCount } from "@memorang/helpers";
import { MasonryFlashList } from "@shopify/flash-list";
import { router } from "expo-router";
/* ----------------- Internal Imports --------------- */
import OnboardingLayout from "../../../layouts/OnboardingLayout";
import Box from "../../Box";
import type { BreadcrumbType } from "../../CustomBreadcrumb";
import SelectableItem, { type SelectableItemType } from "../../SelectableItem";

/* ----------------- Local Imports --------------- */

interface Props {
	title: string;
	cards: SelectableItemType[];
	selectedItems?: string[];
	handleSelectRole: (role: string) => void;
	showBackButton?: boolean;
	loadingContinueButton?: boolean;
	handleClickContinue: () => void;
	numMaxSelect?: number;
	suffix?: string;
	variant?: "card" | "listItem";
	previousStepName?: string;
	hasNestedItems?: boolean;
	breadcrumbs?: BreadcrumbType[];
	handleSkip?: () => void;
	propHandleBack?: () => void;
}

export type { SelectableItemType };

const OnboardingSelectionView = ({
	title,
	cards,
	handleSelectRole,
	showBackButton,
	loadingContinueButton,
	handleClickContinue,
	selectedItems = [],
	numMaxSelect = 0,
	suffix,
	variant = "card",
	previousStepName,
	hasNestedItems = false,
	breadcrumbs,
	handleSkip,
	propHandleBack,
}: Props) => {
	const numColumns = variant === "card" ? 2 : 1;

	const handleBack = () => {
		if (propHandleBack) {
			propHandleBack();
			return;
		}
		if (previousStepName) {
			router.setParams({
				step: previousStepName,
			});
		}
	};
	const getCta = () => {
		if (selectedItems.length) {
			if (numMaxSelect) {
				return `Continue with ${selectedItems.length} ${formatCount(suffix!, selectedItems.length)}`;
			}
			return undefined;
		}
		if (numMaxSelect) {
			return `Select at least one ${suffix} to continue`;
		}
		return undefined;
	};

	const cta = getCta();

	if (hasNestedItems) {
		return (
			<OnboardingLayout
				title={title}
				showBackButton={showBackButton}
				handleBack={handleBack}
				disableContinueButton={!selectedItems.length || loadingContinueButton}
				loadingContinueButton={loadingContinueButton}
				handleClickContinue={handleClickContinue}
			>
				<Box paddingTop={16}>
					{cards.map((item) => {
						return (
							<View key={item.value}>
								<SelectableItem
									{...item}
									onSelect={handleSelectRole}
									variant="listItem"
									description={item.items?.map((item) => item.label).join(", ")}
									count={item.items?.length}
								/>
							</View>
						);
					})}
				</Box>
			</OnboardingLayout>
		);
	}

	return (
		<OnboardingLayout
			title={title}
			breadcrumbs={breadcrumbs}
			showBackButton={showBackButton}
			handleBack={handleBack}
			disableContinueButton={!selectedItems.length || loadingContinueButton}
			loadingContinueButton={loadingContinueButton}
			handleClickContinue={handleClickContinue}
			cta={cta}
			handleSkip={handleSkip}
			showChangeLater
		>
			<MasonryFlashList
				numColumns={numColumns}
				estimatedItemSize={150}
				data={cards}
				extraData={selectedItems}
				contentContainerStyle={{
					padding: 16,
				}}
				renderItem={({ item }) => {
					const disabled =
						numMaxSelect &&
						selectedItems.length >= numMaxSelect &&
						!selectedItems.includes(item.value!);
					return (
						<View
							style={{
								width: "100%",
								padding: 8,
								flexGrow: 1,
							}}
						>
							<SelectableItem
								{...item}
								onSelect={handleSelectRole}
								selected={selectedItems.includes(item.value!)}
								disabled={Boolean(disabled)}
								variant={variant}
							/>
						</View>
					);
				}}
				keyExtractor={(item, index) => `${item.value}-${index}`}
			/>
		</OnboardingLayout>
	);
};

export default OnboardingSelectionView;
