/* -----------------Globals--------------- */
import { useEffect } from "react";

/* -----------------UI--------------- */
import { Stack, useLocalSearchParams } from "expo-router";

/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";
import { useRouter } from "expo-router";

/* -----------------Child components--------------- */
import { EmailContainer } from "@memorang/applets";

/* -----------------Constants--------------- */
import { pageViewTypes } from "@constants/tracking";

import { isDev } from "@constants/urls";
import { useToast } from "@contexts/ToastContext";
import { OnboardingAppBar } from "@features/auth";
import { buildType, currentAppVersion } from "@helpers/expo-extras";
import { isWeb } from "@helpers/platform";
import { useDevice } from "@hooks/useDevice";
import { useGlobalStore } from "@hooks/useGlobalStore";
/* -----------------Analytics--------------- */
import { trackCustomEvent, trackPageView } from "analytics";
import DocumentTitle from "components/DocumentTitle";

const LoginPage = () => {
	const router = useRouter();

	const { companyName, supportEmail, baseUrl, app, tenant, tos, privacy } =
		useAppStore((store) => ({
			app: store.app,
			tenant: store.tenant,
			companyName: store.companyName,
			supportEmail: store.supportEmail,
			baseUrl: store.app.links.baseUrl,
			tos: store.app.links.tos,
			privacy: store.app.links.privacy,
		}));
	const { isMobile } = useDevice();
	const { showToast } = useToast();

	const appId = app.id;
	const tenantId = tenant.id;

	const baseRedirectUrl = isDev && isWeb ? "http://localhost:8081" : baseUrl;

	const lastLoggedInEmail = useGlobalStore((state) => state.lastLoggedInEmail);
	const setShowOnboarding = useGlobalStore((state) => state.setShowOnboarding);
	const params = useLocalSearchParams<{
		redeemCode: string;
		email: string;
	}>();
	useEffect(() => {
		trackPageView({
			type: pageViewTypes.login,
		});
	}, []);

	const title = "Continue with email";

	const emailFromUrl = params?.email
		? decodeURIComponent(params?.email).replace(/ /g, "+")
		: "";

	const handleNewUserSignUp = () => {
		setShowOnboarding(true);
	};

	const onError = (message: string) => {
		showToast({ message });
	};

	return (
		<>
			<Stack.Screen
				options={{
					headerShown: true,
					header: () => {
						return (
							<OnboardingAppBar
								showLogo={!isMobile}
								title={title}
								onBackAction={() => router.back()}
							/>
						);
					},
				}}
			/>
			<DocumentTitle title="Login" />
			<EmailContainer
				handleTracking={trackCustomEvent}
				handleNewUserSignUp={handleNewUserSignUp}
				companyName={companyName}
				lastLoggedInEmail={lastLoggedInEmail}
				supportEmail={supportEmail}
				buildType={buildType}
				appId={appId}
				tenantId={tenantId}
				redirectUrl={`${baseRedirectUrl}/verify`}
				currentAppVersion={currentAppVersion!}
				emailFromUrl={emailFromUrl}
				tos={tos}
				privacy={privacy}
				onError={onError}
			/>
		</>
	);
};

export default LoginPage;
