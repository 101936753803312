import { tosMeta } from "@constants/legal";
import LegalView from "components/LegalView";

const TermsRoute = () => {
	return (
		<LegalView dates={tosMeta.dates} text={tosMeta.tos} title={tosMeta.title} />
	);
};

export default TermsRoute;
