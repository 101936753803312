/**
 * @generated SignedSource<<fe68b9065d474f01648807af01119e91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetUserInfoQuery$variables = {
  appId: string;
  email: any;
};
export type GetUserInfoQuery$data = {
  readonly getUserInfo: {
    readonly alerts: ReadonlyArray<{
      readonly name: string | null;
      readonly optInStatus: boolean | null;
    } | null> | null;
    readonly country: string | null;
    readonly email: string | null;
    readonly examDate: string | null;
    readonly firstName: string | null;
    readonly gradYear: number | null;
    readonly id: string | null;
    readonly isOnboarded: boolean | null;
    readonly lastName: string | null;
    readonly name: string | null;
    readonly profession: string | null;
    readonly school: string | null;
    readonly specialities: ReadonlyArray<string | null> | null;
  };
};
export type GetUserInfoQuery = {
  response: GetUserInfoQuery$data;
  variables: GetUserInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "appId",
        "variableName": "appId"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "TenantUserDetails",
    "kind": "LinkedField",
    "name": "getUserInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "school",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gradYear",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "examDate",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isOnboarded",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profession",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "specialities",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "country",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Alert",
        "kind": "LinkedField",
        "name": "alerts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optInStatus",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetUserInfoQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GetUserInfoQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "966552e048e1149551e7c25711984a87",
    "id": null,
    "metadata": {},
    "name": "GetUserInfoQuery",
    "operationKind": "query",
    "text": "query GetUserInfoQuery(\n  $email: AWSEmail!\n  $appId: String!\n) {\n  getUserInfo(email: $email, appId: $appId) {\n    id\n    email\n    school\n    gradYear\n    examDate\n    name\n    firstName\n    lastName\n    isOnboarded\n    profession\n    specialities\n    country\n    alerts {\n      name\n      optInStatus\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "55293c60554d20fbede4a0bd4bb2c121";

export default node;
