/**
 * @generated SignedSource<<0af85159d3e3d8c76da3f8e22aefb5d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DashboardAction = "COMMON_PROGRESS_QUERY" | "GETEXAMS" | "GETEXAMSANDTAGS" | "GETEXAMSRETOOL" | "%future added value";
export type FetchCumulativeContentsDistQuery$variables = {
  bundleId: string;
  dashboardAction?: DashboardAction | null;
};
export type FetchCumulativeContentsDistQuery$data = {
  readonly getMixedItemDistribution: {
    readonly accuracy: number | null;
    readonly contents: ReadonlyArray<{
      readonly distribution: {
        readonly high: number | null;
        readonly low: number | null;
        readonly medium: number | null;
      } | null;
      readonly id: string;
      readonly totalCount: number | null;
    } | null> | null;
    readonly exams: ReadonlyArray<{
      readonly attempted: {
        readonly high: number | null;
        readonly low: number | null;
        readonly medium: number | null;
      } | null;
      readonly dateCompleted: any | null;
      readonly distribution: {
        readonly high: number | null;
        readonly low: number | null;
        readonly medium: number | null;
      } | null;
      readonly id: string | null;
      readonly legacyReport: {
        readonly numItems: number | null;
        readonly numericId: number | null;
        readonly score: number | null;
        readonly sessionDate: any | null;
      } | null;
      readonly numCompleted: number | null;
      readonly numItems: number | null;
      readonly numericId: number | null;
      readonly status: string | null;
      readonly time: string | null;
      readonly timeElapsed: number | null;
      readonly title: string | null;
      readonly totalTime: number | null;
    } | null> | null;
    readonly freeTagIds: ReadonlyArray<string | null> | null;
    readonly speed: number | null;
    readonly tags: ReadonlyArray<{
      readonly displayType: ReadonlyArray<string | null> | null;
      readonly id: string;
      readonly items: ReadonlyArray<{
        readonly distribution: string | null;
        readonly id: string;
        readonly type: string | null;
      } | null> | null;
      readonly numCorrect: number | null;
      readonly numIncorrect: number | null;
      readonly title: string | null;
      readonly type: string | null;
      readonly weight: number | null;
    } | null> | null;
  } | null;
};
export type FetchCumulativeContentsDistQuery = {
  response: FetchCumulativeContentsDistQuery$data;
  variables: FetchCumulativeContentsDistQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bundleId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dashboardAction"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "bundleId",
    "variableName": "bundleId"
  },
  {
    "kind": "Variable",
    "name": "dashboardAction",
    "variableName": "dashboardAction"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "freeTagIds",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accuracy",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "speed",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "low",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "medium",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "high",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ProgressDistribution",
  "kind": "LinkedField",
  "name": "distribution",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "MixedContent",
  "kind": "LinkedField",
  "name": "contents",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numericId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sessionDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numItems",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numCompleted",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "time",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeElapsed",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalTime",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "ProgressDistribution",
  "kind": "LinkedField",
  "name": "attempted",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateCompleted",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "MixedItemTag",
  "kind": "LinkedField",
  "name": "tags",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numCorrect",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numIncorrect",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    },
    (v21/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MixedItemScore",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distribution",
          "storageKey": null
        },
        (v21/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchCumulativeContentsDistQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MixedItemDistribution",
        "kind": "LinkedField",
        "name": "getMixedItemDistribution",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exams",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LegacyReport",
                "kind": "LinkedField",
                "name": "legacyReport",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v10/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v22/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchCumulativeContentsDistQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MixedItemDistribution",
        "kind": "LinkedField",
        "name": "getMixedItemDistribution",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exams",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LegacyReport",
                "kind": "LinkedField",
                "name": "legacyReport",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v10/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v22/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e3c686c444c92c87d6f6323dcfffba62",
    "id": null,
    "metadata": {},
    "name": "FetchCumulativeContentsDistQuery",
    "operationKind": "query",
    "text": "query FetchCumulativeContentsDistQuery(\n  $bundleId: ID!\n  $dashboardAction: DashboardAction\n) {\n  getMixedItemDistribution(bundleId: $bundleId, dashboardAction: $dashboardAction) {\n    freeTagIds\n    accuracy\n    speed\n    contents {\n      id\n      totalCount\n      distribution {\n        low\n        medium\n        high\n      }\n    }\n    exams {\n      id\n      title\n      legacyReport {\n        numericId\n        sessionDate\n        numItems\n        score\n        id\n      }\n      numItems\n      numericId\n      numCompleted\n      status\n      time\n      timeElapsed\n      totalTime\n      attempted {\n        low\n        medium\n        high\n      }\n      dateCompleted\n      distribution {\n        low\n        medium\n        high\n      }\n    }\n    tags {\n      id\n      title\n      displayType\n      numCorrect\n      numIncorrect\n      weight\n      type\n      items {\n        id\n        distribution\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f244112313f926be9827fa9154ceef8e";

export default node;
