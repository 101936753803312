/* ----------------- Globals --------------- */
import { Box, CustomBreadcrumb, MarkdownRenderer } from "@memorang/ui";
import { Appbar, Text } from "react-native-paper";

/* ----------------- Router --------------- */
import { router, useLocalSearchParams, useNavigation } from "expo-router";

/* ----------------- Components --------------- */
import { ScrollContainer } from "@memorang/ui";

/* ----------------- Hooks --------------- */
import { useSubjectsStore } from "@features/notes";
import { StackActions } from "@react-navigation/native";

const NoteRoute = () => {
	const { note } = useSubjectsStore();

	const navigation = useNavigation();

	const { title } = useLocalSearchParams<{
		title: string;
	}>();

	const popAction = StackActions.pop(2);

	const decodedTitle = decodeURIComponent(title!);

	const breadcrumbs = [
		{
			label: "Subjects",
			action: () => navigation.dispatch(popAction),
		},
		...(decodedTitle
			? [
					{
						label: decodedTitle,
						action: () => router.back(),
					},
				]
			: []),
		{
			label: note?.title!,
		},
	];

	const markdownText = note?.items[0].title;

	return (
		<>
			<Appbar.Header mode="large" elevated>
				<Appbar.BackAction
					onPress={() => {
						router.back();
					}}
				/>
				<Appbar.Content
					title={
						<Box gap={4}>
							<Text numberOfLines={1} variant={"titleLarge"}>
								{note?.title}
							</Text>
							<CustomBreadcrumb breadcrumbs={breadcrumbs} />
						</Box>
					}
				/>
			</Appbar.Header>
			<ScrollContainer
				contentContainerStyle={{
					flexGrow: 1,
					paddingHorizontal: 16,
					paddingTop: 16,
					paddingBottom: 32,
				}}
			>
				<MarkdownRenderer
					text={markdownText}
					customStyles={{
						paragraph: {
							fontSize: 16,
							lineHeight: 24,
						},
					}}
				/>
			</ScrollContainer>
		</>
	);
};

export default NoteRoute;
