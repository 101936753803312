import type { Article } from "../../../../../types";

export const Article539: Article = {
	id: 539,
	rsuiteId: "e4df4be4-a166-4e85-8233-2851626ac548",
	type: "scientific article",
	title: "Acute Flaccid Myelitis",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=jpeg&name=JBJS.23.01268f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Acute Flaccid Myelitis</div>
              <div class="text subtitle" style="position: unset;">Mid-Term Clinical Course of Knee Extension Paralysis
                and Outcomes of Nerve Transfer</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Kazuteru Doi,
                      MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Yasunori
                      Hattori, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Background:</span> Acute flaccid myelitis (AFM) is a rare
                        debilitating poliomyelitis-like illness characterized by the sudden onset of flaccid palsy in
                        the extremities. The purpose of this study was to report the mid-term clinical course of knee
                        extension in AFM and the effect of contralateral obturator nerve-to-femoral nerve transfer
                        (CONFNT) for restoration of knee extension in AFM.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Methods:</span> Twenty-six patients with lower extremity palsy due to
                        AFM were referred to our clinic for possible surgical reconstruction. Their median age was 4.0
                        years, and the first evaluation of the palsy was done at a mean of 6 months after paralysis
                        onset. The paralysis ranged from lower limb monoplegia to quadriplegia. The clinical course of
                        knee extension was assessed using the British Medical Research Council (MRC) grading scale and
                        surface electromyography (EMG). Five patients with unilateral paralysis of knee extension
                        underwent CONFNT.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Results:</span> The mean follow-up period for 19 limbs with complete
                        paralysis of knee extension (MRC grade M0) in 13 patients who were evaluated for spontaneous
                        recovery was 43 months. No patient who had complete paralysis of knee extension at &gt;6 months
                        and paralysis of the hip adductor muscle had improvement of knee extension to better than M2.
                        Five of the original 26 patients were treated with CONFNT and followed for a mean of 61 months.
                        Two of 5 patients had the CONFNT ≤8 months after paralysis onset and obtained M4 knee extension.
                        Only 1 of the 3 patients with CONFNT performed approximately 12 months after paralysis onset
                        obtained M3 knee extension; the other 2 obtained only M1 or M2 knee extension.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The paralysis of the lower extremity in our
                        patients with AFM was similar to that in poliomyelitis. However, in AFM, spontaneous recovery of
                        knee extension was possible if there were signs of recovery from hip adductor paralysis up to 6
                        months after paralysis onset. CONFNT may enhance the recovery of knee extension and seems to be
                        a reliable reconstruction for restoring knee extension if performed no more than 8 months after
                        paralysis onset.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level IV</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Acute flaccid myelitis (AFM) is a poliomyelitis-like
                  illness characterized by sudden onset of flaccid paralysis of the extremities, with magnetic resonance
                  imaging (MRI) demonstrating longitudinal lesions confined to the gray matter of the spinal cord<a
                    href="" data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. AFM was first reported in the United States in 2012, and
                  since then clusters of cases have been observed every 2 to 3 years in many countries<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>.
                  Overall, AFM is rare, with an estimated incidence of &lt;1 per million population<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a>. The
                  average incidence (and standard deviation [SD]) per million children &lt;15 years of age in the United
                  States was 1.3 ± 1.2 per year from 2014 to 2022<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">There is a high epidemiological association between AFM
                  and the enterovirus D68 (EV-D68)<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">6</span></a>.
                  After recovery from the acute illness, there is a high incidence of permanent muscle palsies<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">9</span></a>.
                  Incomplete recovery with persistent motor deficits is expected in &gt;75% of patients<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">10</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Recently, nerve transfers were introduced for restoration
                  of function in patients with AFM. Reports<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">12</span></a> have
                  recommended reconstructive surgery, especially in the upper extremities, when there has not been
                  adequate recovery after at least 3 months from the onset of paralysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, there have been no detailed reports
                  concerning the use of standard manual muscle testing in assessing individual joint function after
                  recovery from AFM. There are, however, published reports regarding poliomyelitis<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">13</span></a>. There are also a few reports on nerve transfers for
                  restoration of knee extension in AFM<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">16</span></a>—especially contralateral obturator nerve-to-femoral nerve
                  transfer (CONFNT)<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">14</span></a>, a
                  novel procedure for restoring knee extension.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The aim of the present study was to report on the
                  spontaneous recovery of knee extension in patients with AFM, determine the proper timing of surgical
                  intervention to restore knee extension, and assess the mid-term outcomes of CONFNT.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">This study was approved by the local hospital
                  Institutional Ethics Committee, and written informed consent was obtained from all of the patients.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Demographics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">This retrospective study conducted in our hospital
                  included 26 patients with lower limb palsies who met the inclusion criteria for “confirmed AFM”<span
                    data-id="superscript_12" class="annotation superscript" style="position: unset;">1,2</span> and were
                  referred to us for consideration of surgical reconstruction. There were 15 girls and 11 boys with a
                  median age at onset of 4.0 years (interquartile range [IQR]: 2.9 to 5.0 years). Three patients had
                  bronchial asthma, whereas 23 had been healthy before the onset of the illness. All patients
                  experienced prodromal symptoms with fever and respiratory manifestations, and all had a sudden onset
                  of neurological symptoms (lower limb flaccid palsy without sensory impairment) 0 to 11 days (median: 5
                  days) after the onset of the prodromal symptoms. All patients had a raised white blood-cell count in
                  the cerebrospinal fluid (mean: 96 ± 57/µL)<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_13" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_13" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">17</span></a>.
                  MRI performed at the children’s hospital radiology department at an average of 8.1 ± 5.1 days after
                  the onset of symptoms demonstrated longitudinal abnormal signals confined to the gray matter, spanning
                  ≥1 vertebral level<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">18</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">All patients received immunomodulation therapy<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">20</span></a> consisting of high-dose intravenous immunoglobulin or
                  pulsed methylprednisolone, or both, after the onset of the palsy<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">6</span></a>, but without achieving substantial clinical improvement of
                  the neurological deficits.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Initial
                Evaluations of Muscle Strength</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Initial neurological evaluation was performed at a median
                  of 6 months (IQR: 5 to 9.5 months) after the disease onset. Classifying the types of palsy according
                  to the involved spinal segments was difficult because there was a great variety of paralytic
                  involvement, with some specific palsies being more pronounced than others<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_17" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">7</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The senior orthopaedic surgeon and the physiotherapist
                  measured the muscle strength qualitatively using the British Medical Research Council (MRC) grading
                  scale authorized by U.K. Research and Innovation (UKRI)<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">21</span></a>.
                  Grades M0 and M1 were classified as “paralysis” and grades M2, M3, and M4, as “paresis.”<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">22</span></a> We had to differentiate between M0 and M1 because M0
                  indicates complete palsy with no possibility of recovery and M1 indicates partial palsy or initial
                  recovery. M1 was distinguished by the presence of active motor unit potentials recorded by surface
                  electromyography (EMG). In contrast, M4 and M5 were grouped together during the analysis of the
                  results because of the unreliability in distinguishing clinically between these 2 grades in children.
                  Spontaneous recovery of knee extension was compared between the initial and final examinations. We did
                  not use the Active Movement Scale<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">23</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">CONFNT</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">CONFNT surgery was considered to be indicated for 10
                  patients with unilateral complete paralysis of knee extension and hip adduction 6 months after the
                  onset of the paralysis. However, surgery was not performed in 5 of these patients: 3 one-year-olds due
                  to the complexity of postoperative management at such a young age, 1 who was evaluated before CONFNT
                  procedures had begun to be performed, and 1 whose parents who did not wish to consider a surgical
                  option for their child. CONFNT was performed in the remaining 5 children—3 girls and 2 boys with a
                  mean age at the onset of the paralysis of 5.2 ± 2.6 years. The 5 children underwent the CONFNT at a
                  mean of 10 ± 3 months after the onset of the paralysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Procedure<a href="" data-id="citation_reference_32"
                  class="annotation citation_reference resource-reference" style="position: unset;"><span
                    data-id="superscript_21" class="annotation superscript" style="position: unset;">14</span></a><span
                  data-id="superscript_21" class="annotation superscript" style="position: unset;"></span></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The CONFNT consisted of (1) exposure of the affected
                  femoral nerve and contralateral obturator nerve, (2) obturator nerve-to-femoral nerve transfer, and
                  (3) neurorrhaphy. This is described in detail in <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figures 1</a>
                  through <a href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">5</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01268f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=jpeg&name=JBJS.23.01268f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Skin incision. The left femoral nerve is explored
                          through the Smith-Petersen anterior approach to the hip. A longitudinal incision below the
                          inguinal ligament and posterior to the adductor longus muscle is used for exploration of the
                          right obturator nerve.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01268f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=jpeg&name=JBJS.23.01268f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">After exposing the femoral nerve at the femoral
                          triangle of the thigh and dissecting its motor branch to the quadriceps femoris and its
                          saphenous nerve branch, the femoral nerve is stimulated to confirm that it is not functioning
                          (no response). The nerve is traced proximally through the retroperitoneal space until the L2,
                          L3, and L4 roots are reached by retracting the psoas muscle medially. FN = femoral nerve, ON =
                          obturator nerve, RF = rectus femoris, VL = vastus lateralis, VM = vastus medialis, blue dotted
                          line = skin incision.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01268f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=jpeg&name=JBJS.23.01268f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Dissection of the right obturator nerve. The
                          anterior division of the obturator nerve was located between the gracilis and adductor longus
                          muscles. The motor nerves to the gracilis and adductor longus were identified at the
                          undersurface of the respective muscle. It is essential to judiciously retract the adductor
                          longus laterally for distal dissection of its motor nerve before it divides into 2 terminal
                          branches to the muscle and proximally to its origin from the anterior obturator nerve (A). The
                          sensory nerve was cut proximally, and the motor branches were cut as distally as possible,
                          close to the muscles. B = posterior division of the obturator nerve, Add b = adductor brevis,
                          Add l = adductor longus (partially removed), Gr = gracilis, Add m = adductor magnus.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01268f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=jpeg&name=JBJS.23.01268f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;">The left femoral nerve (FN) is transferred to the
                          right obturator nerve (ON) via a subcutaneous tunnel above the aponeurosis of the external
                          oblique muscle to avoid injuring the spermatic cord in male patients. The left femoral nerve
                          is passed under the skin to the right incision, where the nerve suturing is done.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01268f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=jpeg&name=JBJS.23.01268f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Donor to recipient nerve transfer. The donor
                          nerves (motor nerves to the gracilis and adductor longus) are brought proximally to determine
                          the site for neurorrhaphy (blue arrow). An adequate length of the femoral nerve (FN) was
                          obtained by measuring the distance between the neurorrhaphy site and the FN pivot point. The
                          FN is sectioned proximally where L2, L3, and L4 are joined as a common trunk, and the nerve is
                          passed underneath the inguinal ligament to become superficial in the subcutaneous plane (red
                          arrow). Perineurial neurorrhaphy is done between the obturator nerve (ON) and the lateral
                          component of the FN with interrupted 10-0 sutures. ant. = anterior branch of ON, post =
                          posterior branch of ON, Add b = adductor brevis, Add m = adductor magnus, Gr = gracilis, VL =
                          vastus lateralis, RF = rectus femoris, VM = vastus medialis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Postoperative Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">A pillow with straps was applied between the legs in the
                  neutral position to prevent further hip abduction, which may cause excessive tension on the
                  neurorrhaphy site (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>). During
                  the 3 weeks of splinting, disposable diapers were used. The postoperative rehabilitation protocol is
                  described in Appendix 1.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.01268f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=jpeg&name=JBJS.23.01268f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Postoperative immobilization. A supportive pillow
                          with straps was put between the legs in a neutral position to prevent hip abduction for 3
                          weeks.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Postoperative Assessment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The postoperative outcome was related to the timing of
                  muscle reinnervation as detected by EMG and MRC grading of the knee extensors. A handheld dynamometer
                  was used to measure the recovery of knee extension strength quantitatively (see Appendix 2)<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">26</span></a>. T2-weighted MRI of both thighs was used perioperatively to
                  measure the quadriceps femoris muscle cross-sectional area to assess muscle hypertrophy. The Child
                  Health Questionnaire-Parent Form 28 (CHQ-PF28) was used to evaluate the outcome as reported by the
                  parent. Activities of daily living including orthotic use, gait, stair climbing, and running were also
                  assessed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">In light of the secondary deformities of poliomyelitis<a
                    href="" data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">27</span></a>, we also investigated the postoperative deformities in our
                  patients with AFM, including scoliosis (Cobb angle), hip dysplasia, knee deformities (genu valgum,
                  abnormal patellar height), and ankle deformities (pes equinus)<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">28</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Based on normality, data were presented as the mean ± SD
                  or median (IQR). Statistical analysis was not performed because of the small sample size.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">At the initial examination, the 26 patients demonstrated 9
                  limbs with paresis and 26 with paralysis (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). Hip
                  adduction and knee extension were the functions most frequently diminished by paralysis, whereas
                  intrinsic foot muscle function was the rarest. No patient had sphincter involvement, although several
                  experienced constipation for long periods.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Data</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1335.38px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.889189);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Patient</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Sex</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Involved
                          Side</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Lower Limb
                          Palsy</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Concomitant Upper Limb Palsy</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Type of
                          Paralysis<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HK</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HK</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Bilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKA</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Bilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Triplegia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Paresis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Paresis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Hemiplegia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Paresis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Bilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HK</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Hemiplegia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKA</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Bilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HK</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Paresis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HK</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Bilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Quadriplegia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Paresis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Paresis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Bilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Quadriplegia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Bilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Quadriplegia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Paresis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Paresis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Bilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HK</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HK</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Bilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Triplegia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAT</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HK</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unilat.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Triplegia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Paresis</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">H = hip, K = knee, A = ankle, T = toe.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">There were 6 topographical patterns of paralysis (<a
                    href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 7</a>). Seventeen patients had paralysis of 1 lower extremity: 13 with
                  monoplegia and 4 who also had upper extremity paralysis (hemiplegia or triplegia). Nine patients had
                  paralysis involving both lower extremities: 5 had paraplegia and 4 also had upper extremity paralysis
                  (triplegia or quadriplegia). The 8 patients with upper extremity palsy underwent nerve transfer for
                  shoulder, elbow, and/or hand reconstruction.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.23.01268f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=jpeg&name=JBJS.23.01268f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;">Distribution of acute flaccid paralysis in the
                          lower extremity. The areas in darker red show both muscles with paralysis and those with
                          paresis. The numbers in parentheses (n) are the number of patients.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical
                Course of Spontaneous Recovery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Of the 35 affected lower limbs (in 26 patients), 19 (in 13
                  patients) were assessed for the spontaneous recovery of knee extension (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). We excluded 4 limbs of 3 patients who did not undergo
                  re-examination, 8 limbs of 5 patients with paresis, and 5 limbs of 5 patients who underwent CONFNT (<a
                    href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 8</a>). The 19 limbs with knee extension paralysis (M0) were followed
                  for &gt;18 months (mean, 43 ± 19 months) after the onset of paralysis. Three limbs had recovery to an
                  MRC grade of ≥M2, and all of the quadriceps and hip adduction muscles in these limbs showed EMG
                  innervation or muscle contraction ≤6 months after the onset of palsy. Three other limbs recovered to
                  M1 during this follow-up period, but no further strength recovery was detected later. The remaining 13
                  limbs remained at M0.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>MRC Grades of Paralyzed Muscles<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 592.667px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.331988);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Patient</span></th>
                      <th align="center" rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Involved Side</span></th>
                      <th align="center" colspan="12" style="position: unset;"><span class="text"
                          style="position: unset;">Initial Assessment</span></th>
                      <th align="center" colspan="12" style="position: unset;"><span class="text"
                          style="position: unset;">Final Assessment</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Time After Onset <span data-id="emphasis_38"
                            class="annotation emphasis" style="position: unset;">(mo)</span></span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Hip</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Knee</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Ankle</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Toe</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Time After Onset <span data-id="emphasis_39"
                            class="annotation emphasis" style="position: unset;">(mo)</span></span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Hip</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Knee</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Ankle</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Toe</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Add</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Abd</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Intrinsic</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Add</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Abd</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Intrinsic</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">16</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">17</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">18</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">19</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">20</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">21</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">22</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">23</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">24</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">25</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">26</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. with paralysis</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">% with paralysis<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3%</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Median</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">IQR</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5-9.5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">33-61</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">MRC = British Medical Research Council, Flex = flexion, Add =
                        adduction, Ext = extension, Abd = abduction, MRC 0 = no contraction, 1 = contraction without
                        movement, 2 = movement without gravity, 3 = movement against gravity, 4 = movement against
                        resistance, 5 = normal strength. Postoperative data are missing for 4 limbs due to loss to
                        follow-up.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">After CONFNT.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Based on 35 limbs for the preoperative assessment and 31 limbs for the
                        postoperative assessment.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 8</div>
                <div class="image-download" name="JBJS.23.01268f8" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=jpeg&name=JBJS.23.01268f8"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;">Clinical course of spontaneous recovery of knee
                          extension of 19 limbs of 13 patients. A horizontal bar means the muscle strength was at a
                          plateau. MRC = British Medical Research Council, m = months, y = years, No = patient number.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">We concluded that surgical reconstruction with nerve
                  transfer for restoration of knee extension might be considered when hip flexors and hip adduction
                  muscles remain paralyzed 6 months after paralysis onset and the contralateral hip adduction muscles
                  are intact.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcomes of
                CONFNT</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The 5 patients with CONFNT (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>) were followed for a mean of 61 ± 16.6 months after the
                  operation. All 5 patients showed successful innervation of the quadriceps femoris muscle. Innervation
                  of the rectus femoris—assessed by needle EMG (except in 1 patient in whom surface EMG was used)—was
                  demonstrated at a mean of 6.4 ± 1.1 months postoperatively (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).
                  Voluntary contraction of the rectus femoris was visible (with adduction of the contralateral hip)
                  approximately 3 to 4 months after innervation. Full knee extension power was reported after
                  approximately 14 months in 2 patients and 83 months in 1 patient. The final grade for knee extension
                  was M4 in 2 patients and M3, M2, and M1 in 1 each (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 9,</a> <a
                    href="" data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_41" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Data on Patients who Underwent CONFNT<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 322px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Patient</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_40" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Sex</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Involved
                          Side</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">20</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SD</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CONFNT = contralateral obturator nerve-to-femoral nerve
                        transfer.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_42" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>MRC Grades of Paralyzed Muscles in CONFNT Group<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 164.625px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.359563);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Patient</span></th>
                      <th align="center" colspan="11" style="position: unset;"><span class="text"
                          style="position: unset;">Initial Assessment</span></th>
                      <th align="center" rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Time Between Onset and Op. <span data-id="emphasis_41"
                            class="annotation emphasis" style="position: unset;">(mo)</span></span></th>
                      <th align="center" rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Time to EMG Innervation After Op. <span data-id="emphasis_42"
                            class="annotation emphasis" style="position: unset;">(mo)</span></span></th>
                      <th align="center" rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Postop. Follow-up <span data-id="emphasis_43"
                            class="annotation emphasis" style="position: unset;">(mo)</span></span></th>
                      <th align="center" colspan="10" style="position: unset;"><span class="text"
                          style="position: unset;">Final Assessment</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Time After Onset <span data-id="emphasis_44"
                            class="annotation emphasis" style="position: unset;">(mo)</span></span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Hip</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Knee</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Ankle</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Toe</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Hip</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Knee</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Ankle</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Toe</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Abd</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Add</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Abd</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Add</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ext</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Flex</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Mean</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">SD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.7</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">MRC = British Medical Research Council, CONFNT = contralateral
                        obturator nerve-to-femoral nerve transfer, Flex = flexion, Add = adduction, Ext = extension, Abd
                        = abduction, EMG = electromyography, MRC 0 = no contraction, 1 = contraction without movement, 2
                        = movement without gravity, 3 = movement against gravity, 4 = movement against resistance, 5 =
                        normal strength.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 9</div>
                <div class="image-download" name="JBJS.23.01268f9" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=jpeg&name=JBJS.23.01268f9"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_43" style="position: unset;">
                        <div class="content" style="position: unset;">Patient 20. At the preoperative examination, at 6
                          months after the onset of illness, the patient could not extend the right knee against
                          gravity. At the postoperative examination, 31 months after surgery, the child had full active
                          extension of the right knee that was grade 4 on manual muscle testing.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">MRI measurement of muscle bulk (cross-sectional area)
                  showed postoperative increases in the percentage of the value on the uninvolved side ranging from 6%
                  to 27% for the rectus femoris and 10% to 26% for the vastus lateralis in 3 patients with recovery to a
                  grade of M3 or higher (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 10</a>, <a
                    href="" data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>). The vastus medialis and vastus intermedius muscle bulks
                  decreased postoperatively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 10</div>
                <div class="image-download" name="JBJS.23.01268f10" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=jpeg&name=JBJS.23.01268f10"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_14" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_45" style="position: unset;">
                        <div class="content" style="position: unset;">Patient 20. T2-weighted MRI scans of the right and
                          left quadriceps muscles made preoperatively and 50 months after CONFNT. The muscle bulk was
                          measured using the cross-sectional area at the same MRI axial level (level 8) for both MRI
                          scans. Recovery of the right rectus femoris (white arrows) and vastus medialis (thick black
                          arrows) is seen postoperatively. The adductor magnus muscle bulk in the right thigh (thin
                          black arrows) was severely atrophied, indicating that the right obturator nerve did not
                          spontaneously recover. The left adductor magnus did not show muscle atrophy, indicating that
                          the posterior branch of the left obturator nerve was not paralyzed.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_15" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_46" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Quantitative Measurement of Knee Extension with HHD
                          and Muscle Cross-Sectional Area on MRI (ROI) after CONFNT<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 182.731px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.438667);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Patient</span></th>
                      <th align="center" rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Postop. Follow-up <span data-id="emphasis_45"
                            class="annotation emphasis" style="position: unset;">(mo)</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">MRC Grade</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">HHD Measurement</span></th>
                      <th align="center" colspan="8" style="position: unset;"><span class="text"
                          style="position: unset;">Muscle Cross-Sectional Area<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Hip Add</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Knee Ext</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Involved <span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(N)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Uninvolved <span data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;">(N)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">%<span data-id="table_footnote_reference_12"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">RF</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">VL</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">VM</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">ADD M</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Postop.</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Postop.</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Postop.</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Postop.</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">195</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DNM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DNM</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">145.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DNM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DNM</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">20</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">170</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SD</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">HHD = handheld dynamometer, MRI = magnetic resonance imaging, ROI =
                        range of interest, CONFNT = contralateral obturator nerve-to-femoral nerve transfer, MRC =
                        British Medical Research Council, Add = adduction, Ext = extension, RF = rectus femoris, VL =
                        vastus lateralis, VM = vastus medialis, ADD M = adductor magnus, DNM = did not measure.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Involved/uninvolved.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">Two patients could walk without a
                  quadriceps-femoris-weakness gait (i.e., without knee hyperextension during the stance phase) and
                  without an orthosis. They could stand up from a sitting position without support, climb stairs, and
                  run (<a href="" data-id="figure_reference_14" class="annotation figure_reference resource-reference"
                    style="position: unset;">Video 1</a>). The remaining 3 patients, who had L2-S2 palsy, required a
                  knee-ankle-foot orthosis (KAFO) when walking (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node video" data-id="video_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Video 1</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;JBJS2301268V1&quot;,&quot;type&quot;:&quot;video&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;e4df4be4-a166-4e85-8233-2851626ac548&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6352873589112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="JBJS2301268V1" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="JBJS2301268V1"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="ref:JBJS2301268V1" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/7b6a9384-9f8e-4ef1-8123-7862aa0141c2/main/1280x720/1m28s619ms/match/image.jpg"
                        src="blob:https://hub.jbjs.org/04fabb14-fcf6-4bad-945b-c6658e7c240d">
                        <track kind="metadata" label="segment-metadata">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/7b6a9384-9f8e-4ef1-8123-7862aa0141c2/main/1280x720/1m28s619ms/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:57</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:57">
                            <div class="vjs-load-progress" style="width: 0%;"><span
                                class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">0.00%</span></span>
                              <div data-start="0" data-end="0" style="left: 0%; width: 0%;"></div>
                            </div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:57</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">captions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button vjs-disabled"
                          type="button" title="Picture-in-Picture" aria-disabled="true" disabled="disabled"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                        <div class="vjs-overlay vjs-overlay-bottom-right vjs-overlay-no-background vjs-hidden"><img
                            src="//players.brightcove.net/2324982687001/studio_overlays/HyP0JSARx-0efbccee-026f-4138-bed4-07fddabe7725.png"
                            style="max-width: 100%;"></div>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_1336_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_1336_description">This is a modal window. This modal can be closed
                          by pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
              <div class="content-node caption" data-id="caption_16" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_48" style="position: unset;">
                        <div class="content" style="position: unset;">A seven-year-old boy (Patient 2) sustained left
                          knee extension paralysis due to confirmed acute flaccid myelitis (AFM), underwent
                          contralateral obturator nerve-to-femoral nerve transfer (CONFNT), and achieved full extension
                          of the left knee with M4 strength according to the British Medical Research Council (BMRC)
                          system. The video shows his ability to walk, run, and go up and down stairs 38 months
                          postoperatively. ABD = abduction, ADD = adduction, Ext = extension, Flex = flexion, TFL =
                          tensor fascia lata, RF = rectus femoris, MUP = motor unit potential, KAF = knee-ankle-foot
                          orthosis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_17" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_49" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Activities of Daily Living and Quality of Life
                          Assessment in CONFNT Group<span data-id="table_footnote_reference_15"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 328.295px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.629063);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Patient</span></th>
                      <th align="center" colspan="2" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Gait</span></th>
                      <th align="center" colspan="2" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Stairs</span></th>
                      <th align="center" rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Running</span></th>
                      <th align="center" rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Sports</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">CHQ-PF28 Score</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Postop.</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Indoor</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Outdoor</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Feet on
                          Each Step<span data-id="table_footnote_reference_14"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Need to
                          Hold Handrail</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">T-PhS</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">T-PsS</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">T-PhS</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">T-PsS</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Normal</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Free</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Free</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Slow</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">KAFO</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Slow</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Table tennis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">7</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Normal</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">KAFO</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Slow</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Swimming</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Slow</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">KAFO</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Difficult</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">20</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Normal</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Free</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Sometimes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Slow</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">22.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">41.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">36.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">47.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SD</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.4</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CONFNT = contralateral obturator nerve-to-femoral nerve transfer,
                        CHQ-PF28 = Child Health Questionnaire-Parent Form 28, T-PhS = summary score in physical domain,
                        T-PsS = summary score in psychosocial domain, KAFO = knee-ankle-foot orthosis.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">1: Able to go up and down stairs normally, placing only 1 foot on the
                        first step, then only the other foot on the next step, etc. 2: Going up and down stairs required
                        placing first one and then both feet on each step.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">The mean summary scores in the physical and psychosocial
                  domains of the CHQ-PF28 improved from 22.8 ± 6.9 and 41.6 ± 7.9, respectively, before the operation to
                  36.2 ± 9.0 and 47.0 ± 8.4 postoperatively (<a href="" data-id="figure_reference_16"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>). One
                  patient (Patient 6) with M2 knee extension developed severe scoliosis (Cobb angle: 58°), acetabular
                  dysplasia, genu valgum, patella baja, and a leg length discrepancy of 7 cm. However, the 3 patients
                  with a knee extension grade of M3 or higher did not show substantial scoliosis (<a href=""
                    data-id="figure_reference_17" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VII</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_18" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_51" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VII</span>Musculoskeletal Complications of Acute Flaccid
                          Myelitis in CONFNT Group<span data-id="table_footnote_reference_22"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 140.034px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.38843);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Patient</span></th>
                      <th align="center" rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Scoliosis: Cobb Angle <span data-id="emphasis_48"
                            class="annotation emphasis" style="position: unset;">(deg)</span></span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Hip</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Knee</span></th>
                      <th align="center" colspan="1" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Ankle Ext <span data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></th>
                      <th align="center" colspan="2" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Lower Extrem. Length<span data-id="table_footnote_reference_16"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="emphasis_50" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Neck-Shaft Angle <span data-id="emphasis_51"
                            class="annotation emphasis" style="position: unset;">(deg)</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">CE Angle <span data-id="emphasis_52" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">FTA Angle <span data-id="emphasis_53" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Patellar Height Ratio<span data-id="table_footnote_reference_17"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Involved</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Uninvolved</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Involved</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Uninvolved</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Involved</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Uninvolved</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Involved</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Uninvolved</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Involved</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Involved</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Uninvolved</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NR</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">133</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">147</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">174</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">172</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36.3<span
                            data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37<span
                            data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">146</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">129</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">168</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">172</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">136</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">130</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">175</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">160</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">143</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">145</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">167</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">175</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.5<span
                            data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.5<span
                            data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">20</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">167</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">147</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">176</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">177</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CONFNT = contralateral obturator nerve-to-femoral nerve transfer, CE =
                        lateral center-edge, FTA = femorotibial angle, Ext = extension, SMD = spinomalleolar distance,
                        NR = not recorded.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Middle point measurement described by Sharrard<a href=""
                          data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                          style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                            style="position: unset;">29</span></a><span data-id="superscript_25"
                          class="annotation superscript" style="position: unset;"></span>.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the SMD unless otherwise stated.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_14" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Femoral bone length measured with MRI.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_52" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_52" style="position: unset;">
                <div class="content" style="position: unset;">There were no severe complications due to CONFNT surgery.
                  Although some hypertrophic scars in the inguinal region were observed, none of the parents were
                  particularly concerned about it.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_53" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_53" style="position: unset;">
                <div class="content" style="position: unset;">AFM is pathologically and clinically most similar to
                  poliomyelitis<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_26" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">19</span></a>. Knee extension and hip adduction were the most affected by
                  the disease in our study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_54" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_54" style="position: unset;">
                <div class="content" style="position: unset;">Knee extension that was graded as M0 ≥6 months after the
                  onset of AFM did not show spontaneous recovery to higher than grade M2. The associated paralysis may
                  predict the prognosis of targeted muscle recovery in AFM, as described for poliomyelitis<a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">13</span></a>. If the hip adduction or hip flexor muscle strength has not
                  recovered to M1 within 6 months after the onset of paralysis, final recovery of quadriceps femoris
                  function will be less than M2 because the anterior horn cell column of these 3 muscles is located in
                  the same L2-L4 spinal segments.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_55" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_55" style="position: unset;">
                <div class="content" style="position: unset;">Paralysis of the lower extremity muscles was slightly less
                  frequent in AFM than in poliomyelitis<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">13</span></a>,
                  although our study did not focus on this aspect of the disease. The recovery of ankle dorsiflexors was
                  less predictable (see Appendix 3) if the muscles did not attain a grade of M1 or above within 6 months
                  from the onset of paralysis<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">29</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_56" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_56" style="position: unset;">
                <div class="content" style="position: unset;">It was 6 months after CONFNT before the reinnervation of
                  the rectus femoris could be detected by EMG. Delaying the surgery for &gt;12 months after the onset of
                  palsy was associated with poor results in our patients. However, we had to wait for 6 months for
                  spontaneous recovery. Considering these factors, we concluded that surgical reconstruction for
                  restoration of knee extension might be considered when hip flexor and adductor muscles remain
                  paralyzed for 6 months after paralysis onset. Patients with quadriceps femoris weakness could still
                  walk by leaning their body forward, realigning the ground reaction force anterior to the knee, and
                  forcing the knee into hyperextension during the stance phase. However, they could not perform
                  strenuous activities like running or climbing stairs. Two patients who recovered M4 knee extension
                  following CONFNT could function remarkably well postoperatively. They could walk without a
                  quadriceps-femoris-weakness gait, run, and climb stairs (<a href="" data-id="figure_reference_18"
                    class="annotation figure_reference resource-reference" style="position: unset;">Video 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_57" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_57" style="position: unset;">
                <div class="content" style="position: unset;">A problem that could still occur in 1 of the 2 patients
                  for whom the CONFNT was initially successful is recurvatum, which requires time to develop in knees
                  with weak knee flexors and a stronger quadriceps. However, the fact that this patient did not have an
                  equinus contracture made it less likely that knee recurvatum would develop (<a href=""
                    data-id="figure_reference_19" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VII</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_58" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_58" style="position: unset;">
                <div class="content" style="position: unset;">Treatment options for femoral palsy are either
                  muscle-tendon<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">27</span></a> or
                  nerve<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">30</span></a> transfers. These transfers were primarily of the tensor
                  fasciae latae, gracilis, or semitendinosus in patients with poliomyelitis and were usually reserved
                  for those with late presentation or who were elderly. However, the muscle-tendon transfers require
                  strong donor muscles, which were not available in our patients<a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">33</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_59" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_59" style="position: unset;">
                <div class="content" style="position: unset;">The gracilis and adductor longus innervated from the
                  anterior division of the obturator nerve have been proven to be reliable sources for free functional
                  muscle transfer in patients with brachial plexus injury<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">34</span></a>.
                  Knee extension can be performed using only 2 muscles of the quadriceps femoris group<a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">34</span></a>, and our MRI (cross-sectional area) study showed that
                  CONFNT reinnervated mainly 2 of the quadriceps femoris muscles: the rectus femoris and vastus
                  lateralis. Therefore, reinnervation of the quadriceps femoris by the anterior obturator nerve can
                  provide sufficient strength for knee extension. There was no important hip adduction weakness after
                  harvesting the anterior division of the obturator nerve because the adductor magnus, innervated by the
                  posterior division, could be spared.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_60" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_60" style="position: unset;">
                <div class="content" style="position: unset;">There are few reports of nerve transfer for restoration of
                  knee extension. We originally introduced CONFNT for patients with AFM<span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">14</span>, and Cao et al. reported a similar
                  procedure for traumatic femoral nerve injury<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">15</span></a>.
                  Moore et al. used a unique supercharge end-to-side nerve transfer procedure in AFM<a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">16</span></a>. However, the donor nerve was the motor branch to the
                  sartorius, innervated from L2-L3, which indicated that the femoral nerve was not completely paralyzed.
                  Their patients recovered only M2 knee extension, except for 1 with preoperative M1 knee extension, in
                  whom achieving a higher grade than M2 could have been due to spontaneous recovery. The M2 knee
                  extension could have been executed by the supplemental action of the iliotibial band, and all of their
                  patients needed an orthosis or wheelchair. This procedure should not be currently recommended.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_61" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_61" style="position: unset;">
                <div class="content" style="position: unset;">We sutured the anterior branch of the obturator nerve to
                  the lateral fascicular components of the femoral nerve because the median component included the
                  sensory fascicles of the saphenous nerve. The MRI study of cross-sectional area showed that only the
                  rectus femoris and vastus lateralis muscles had recovered, while the vastus medialis and intermedius
                  muscles demonstrated progressive atrophy. These studies also proved that the ipsilateral obturator
                  nerve did not recover during the mid-term follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_62" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_62" style="position: unset;">
                <div class="content" style="position: unset;">There are several limitations to this study. Muscle
                  strength was measured qualitatively with the MRC grading scale, which is an ordinal scale, not
                  completely reliable, and subject to measurement bias. We focused on differentiating between M0, M1,
                  and M2 without distinguishing between M3 and higher grades, as this is very difficult when evaluating
                  children with the MRC scale. Surface EMG, which has limitations, was also mainly used to determine M1.
                  A major study limitation is the lack of 3-dimensional gait analysis<a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">35</span></a><span data-id="superscript_38"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">36</span></a>, which would have provided an objective assessment of the
                  gait of the children who underwent CONFNT.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_63" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_63" style="position: unset;">
                <div class="content" style="position: unset;">M0 knee extension seen ≥6 months after the onset of AFM
                  did not show spontaneous recovery to more than M2. However, our sample is too small to draw accurate
                  conclusions regarding when further recovery is unlikely.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_64" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_64" style="position: unset;">
                <div class="content" style="position: unset;">MRI-based 3-dimensional calculation of muscle volume is
                  more relevant and reliable than MRI assessment of cross-sectional area. However, it needs to be more
                  quantitatively precise and reproducible to allow its routine clinical use<a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">37</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_65" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_65" style="position: unset;">
                <div class="content" style="position: unset;">A multitude of operative procedures, including tendon
                  transfer, osteotomy, and arthrodesis, have been performed both for the correction of paralytic
                  deformities and for the total physical rehabilitation of children with poliomyelitis<a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">27</span></a>. These innovative nerve transfers provided knee extension
                  reconstruction and might decrease leg length discrepancy and other deformities that were commonly seen
                  in poliomyelitis. However, the long-term follow-up of hip and ankle paralysis, which was not reported
                  in this study, is imperative to evaluate the results of nerve transfer for AFM.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_66" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_66" style="position: unset;">
                <div class="content" style="position: unset;">AFM causes the same flaccid paralysis as poliomyelitis. In
                  this study of patients with AFM and complete paralysis of knee extension, the knee extension remained
                  poor if it had not begun to recover by approximately 6 months after the onset of the paralysis.
                  CONFNT, which did not exist in the polio era, allows patients with complete knee extension paralysis
                  due to AFM to walk without braces and carry out daily activities if the surgery is performed early.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_67" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_67" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_4" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I48" target="_blank" data-id="link_5" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I48</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The MRC Muscle Scale was used with the permission of the
                  Medical Research Council. The authors made no changes to the scale.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Hopkins SE</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Acute Flaccid Myelitis:
                  Etiologic Challenges, Diagnostic and Management Considerations</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Curr Treat Options Neurol.</span> 2017 Nov
                28;19(12):48.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20Flaccid%20Myelitis%3A%20Etiologic%20Challenges%2C%20Diagnostic%20and%20Management%20Considerations&as_occt=title&as_sauthors=%20%22SE%20Hopkins%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Chong PF</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Kira R</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Mori H</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Okumura A</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Torisu H</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Yasumoto S</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Shimizu H</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Fujimoto T</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Hanaoka N</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Kusunoki S</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Takahashi T</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Oishi K</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Tanaka-Taya K</span>; Acute
                Flaccid Myelitis Collaborative Study Investigators. <span data-id="strong_2" class="annotation strong"
                  style="position: unset;">Clinical Features of Acute Flaccid Myelitis Temporally Associated With an
                  Enterovirus D68 Outbreak: Results of a Nationwide Survey of Acute Flaccid Paralysis in Japan,
                  August-December 2015</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Clin Infect Dis.</span> 2018 Feb 10;66(5):653-64.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20Features%20of%20Acute%20Flaccid%20Myelitis%20Temporally%20Associated%20With%20an%20Enterovirus%20D68%20Outbreak%3A%20Results%20of%20a%20Nationwide%20Survey%20of%20Acute%20Flaccid%20Paralysis%20in%20Japan%2C%20August-December%202015&as_occt=title&as_sauthors=%20%22PF%20Chong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Fatemi Y</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Chakraborty R</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Acute Flaccid Myelitis: A
                  Clinical Overview for 2019</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Mayo Clin Proc.</span> 2019 May;94(5):875-81.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20Flaccid%20Myelitis%3A%20A%20Clinical%20Overview%20for%202019&as_occt=title&as_sauthors=%20%22Y%20Fatemi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Walker LJ</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Thorley BR</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Morris A</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Elliott EJ</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Saul N</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Britton PN</span>; Australian
                Polio Expert Panel1. <span data-id="strong_4" class="annotation strong" style="position: unset;">Using
                  the Acute Flaccid Paralysis Surveillance System to Identify Cases of Acute Flaccid Myelitis,
                  Australia, 2000‒2018</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Emerg Infect Dis.</span> 2022 Jan;28(1):20-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Using%20the%20Acute%20Flaccid%20Paralysis%20Surveillance%20System%20to%20Identify%20Cases%20of%20Acute%20Flaccid%20Myelitis%2C%20Australia%2C%202000%E2%80%922018&as_occt=title&as_sauthors=%20%22LJ%20Walker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;">Centers for
                Disease Control and Prevention. <span data-id="strong_5" class="annotation strong"
                  style="position: unset;">Acute Flaccid Myelitis</span>. Accessed 2023 Dec 8. <a
                  href="https://www.cdc.gov/acute-flaccid-myelitis/index.html%0A" target="_blank" data-id="link_1"
                  class="link"
                  style="position: unset;">https://www.cdc.gov/acute-flaccid-myelitis/index.html%0A</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20Flaccid%20Myelitis&as_occt=title&as_sauthors=%20%22Centers%20for%20Disease%20Control%20and%20Prevention%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Murphy OC</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Messacar K</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Benson L</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Bove R</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Carpenter JL</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Crawford T</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Dean J</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> DeBiasi R</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Desai J</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Elrick MJ</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Farias-Moeller R</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Gombolay GY</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Greenberg B</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Harmelink M</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Hong S</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Hopkins SE</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Oleszek J</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Otten C</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Sadowsky CL</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Schreiner TL</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Thakur KT</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Van Haren K</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Carballo CM</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Chong PF</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Fall A</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Gowda VK</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Helfferich J</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Kira R</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Lim M</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Lopez EL</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Wells EM</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Yeh EA</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Pardo CA</span>; AFM working
                group. <span data-id="strong_6" class="annotation strong" style="position: unset;">Acute flaccid
                  myelitis: cause, diagnosis, and management</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Lancet.</span> 2021 Jan
                23;397(10271):334-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20flaccid%20myelitis%3A%20cause%2C%20diagnosis%2C%20and%20management&as_occt=title&as_sauthors=%20%22OC%20Murphy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Gordon-Lipkin E</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Muñoz LS</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Klein JL</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Dean J</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Izbudak I</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Pardo CA</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Comparative quantitative
                  clinical, neuroimaging, and functional profiles in children with acute flaccid myelitis at acute and
                  convalescent stages of disease</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Dev Med Child Neurol.</span> 2019 Mar;61(3):366-75.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparative%20quantitative%20clinical%2C%20neuroimaging%2C%20and%20functional%20profiles%20in%20children%20with%20acute%20flaccid%20myelitis%20at%20acute%20and%20convalescent%20stages%20of%20disease&as_occt=title&as_sauthors=%20%22E%20Gordon-Lipkin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Chong PF</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Torisu H</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Yasumoto S</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Okumura A</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Mori H</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Sato T</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Kimura J</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Ohga S</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Tanaka-Taya K</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Kira R</span>; Acute Flaccid
                Myelitis Collaborative Study Investigators. <span data-id="strong_8" class="annotation strong"
                  style="position: unset;">Clinical and electrophysiological features of acute flaccid myelitis: A
                  national cohort study</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Clin Neurophysiol.</span> 2021 Oct;132(10):2456-63.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20and%20electrophysiological%20features%20of%20acute%20flaccid%20myelitis%3A%20A%20national%20cohort%20study&as_occt=title&as_sauthors=%20%22PF%20Chong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Chong PF</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Kira R</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Torisu H</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Yasumoto S</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Okumura A</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Mori H</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Tanaka-Taya K</span>; AFM Study
                Group. <span data-id="strong_9" class="annotation strong" style="position: unset;">Three-Year
                  Longitudinal Motor Function and Disability Level of Acute Flaccid Myelitis</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Pediatr Neurol.</span> 2021
                Mar;116:14-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-Year%20Longitudinal%20Motor%20Function%20and%20Disability%20Level%20of%20Acute%20Flaccid%20Myelitis&as_occt=title&as_sauthors=%20%22PF%20Chong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Kirolos A</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Mark K</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Shetty J</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Chinchankar N</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Mcdougall C</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Eunson P</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Stevenson J</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Templeton K</span>; NHS Lothian
                EV-D68 Associated AFM Study Group. <span data-id="strong_10" class="annotation strong"
                  style="position: unset;">Outcome of paediatric acute flaccid myelitis associated with enterovirus D68:
                  a case series</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Dev Med Child Neurol.</span> 2019 Mar;61(3):376-80.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcome%20of%20paediatric%20acute%20flaccid%20myelitis%20associated%20with%20enterovirus%20D68%3A%20a%20case%20series&as_occt=title&as_sauthors=%20%22A%20Kirolos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Doi K</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Sem SH</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Hattori Y</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Sakamoto S</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Hayashi K</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> De la Red-Gallego MA</span>.
                <span data-id="strong_11" class="annotation strong" style="position: unset;">Surgical Reconstruction for
                  Upper-Extremity Paralysis Following Acute Flaccid Myelitis</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">JB JS Open Access.</span> 2019 Oct
                24;4(4):e0030.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20Reconstruction%20for%20Upper-Extremity%20Paralysis%20Following%20Acute%20Flaccid%20Myelitis&as_occt=title&as_sauthors=%20%22K%20Doi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Pino PA</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Intravia J</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Kozin SH</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Zlotolow DA</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Early results of nerve
                  transfers for restoring function in severe cases of acute flaccid myelitis</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Ann Neurol.</span> 2019
                Oct;86(4):607-15.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20results%20of%20nerve%20transfers%20for%20restoring%20function%20in%20severe%20cases%20of%20acute%20flaccid%20myelitis&as_occt=title&as_sauthors=%20%22PA%20Pino%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Sharrard WJ</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">The distribution of the
                  permanent paralysis in the lower limb in poliomyelitis; a clinical and pathological study</span>.
                <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 1955 Nov;37-B(4):540-58.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20distribution%20of%20the%20permanent%20paralysis%20in%20the%20lower%20limb%20in%20poliomyelitis%3B%20a%20clinical%20and%20pathological%20study&as_occt=title&as_sauthors=%20%22WJ%20Sharrard%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Doi K</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Sem SH</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Hattori Y</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Sakamoto S</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Hayashi K</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Maruyama A</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Contralateral Obturator Nerve
                  to Femoral Nerve Transfer for Restoration of Knee Extension After Acute Flaccid Myelitis: A Case
                  Report</span>. <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">JBJS
                  Case Connect.</span> 2019 Dec;9(4):e0073.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=2272135" target="_new" class="" style="position: unset;">JBJS Case Connect</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Cao Y</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Li S</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Jiang J</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Gu Y</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Xu L</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Contralateral obturator nerve
                  transfer for femoral nerve restoration: a case report</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Br J Neurosurg.</span> 2021
                Feb;35(1):35-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Contralateral%20obturator%20nerve%20transfer%20for%20femoral%20nerve%20restoration%3A%20a%20case%20report&as_occt=title&as_sauthors=%20%22Y%20Cao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Moore AM</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Bettlach CR</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Tung TT</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> West JM</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Russo SA</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Lower Extremity Nerve Transfers
                  in Acute Flaccid Myelitis Patients: A Case Series</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">Plast Reconstr Surg Glob Open.</span> 2021 Jul
                20;9(7):e3699.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lower%20Extremity%20Nerve%20Transfers%20in%20Acute%20Flaccid%20Myelitis%20Patients%3A%20A%20Case%20Series&as_occt=title&as_sauthors=%20%22AM%20Moore%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Messacar K</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Schreiner TL</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Van Haren K</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Yang M</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Glaser CA</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Tyler KL</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Dominguez SR</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Acute flaccid myelitis: A
                  clinical review of US cases 2012-2015</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Ann Neurol.</span> 2016 Sep;80(3):326-38.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20flaccid%20myelitis%3A%20A%20clinical%20review%20of%20US%20cases%202012-2015&as_occt=title&as_sauthors=%20%22K%20Messacar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Okumura A</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Mori H</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Fee Chong P</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Kira R</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Torisu H</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Yasumoto S</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Shimizu H</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Fujimoto T</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Tanaka-Taya K</span>; Acute
                Flaccid Myelitis Collaborative Study Investigators. <span data-id="strong_18" class="annotation strong"
                  style="position: unset;">Serial MRI findings of acute flaccid myelitis during an outbreak of
                  enterovirus D68 infection in Japan</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Brain Dev.</span> 2019 May;41(5):443-51.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Serial%20MRI%20findings%20of%20acute%20flaccid%20myelitis%20during%20an%20outbreak%20of%20enterovirus%20D68%20infection%20in%20Japan&as_occt=title&as_sauthors=%20%22A%20Okumura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Murphy OC</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Pardo CA</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Acute Flaccid Myelitis: A
                  Clinical Review</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Semin Neurol.</span> 2020 Apr;40(2):211-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20Flaccid%20Myelitis%3A%20A%20Clinical%20Review&as_occt=title&as_sauthors=%20%22OC%20Murphy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Moore DD</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Nix WA</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Oberste MS</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Weldon WC</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Neutralization of Enterovirus
                  D68 isolated from the 2014 US outbreak by commercial intravenous immune globulin products</span>.
                <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Clin Virol.</span>
                2015 Aug;69:172-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Neutralization%20of%20Enterovirus%20D68%20isolated%20from%20the%202014%20US%20outbreak%20by%20commercial%20intravenous%20immune%20globulin%20products&as_occt=title&as_sauthors=%20%22Y%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;">UK
                Research and Innovation. <span data-id="strong_21" class="annotation strong"
                  style="position: unset;">MRC Muscle Scale</span>. Accessed 2024 Feb 26. <a
                  href="https://www.ukri.org/councils/mrc/facilities-and-resources/find-an-mrc-facility-or-resource/mrc-muscle-scale/"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://www.ukri.org/councils/mrc/facilities-and-resources/find-an-mrc-facility-or-resource/mrc-muscle-scale/</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=MRC%20Muscle%20Scale&as_occt=title&as_sauthors=%20%22UK%20Research%20and%20Innovation%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Sunderland SS</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Nerve Injuries and Their
                  Repair. A Critical Appraisal.</span> Churchill Livingstone; 1991.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Werner JM</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Wlodarczyk Jordan SM.
                  Diagnostic Accuracy of Two Manual Muscle Testing Scales for Identifying Upper Extremity Nerve Transfer
                  Candidates in Children with Acute Flaccid Myelitis</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Plast Reconstr Surg.</span> 2023 Nov
                1;152(5):1057-67.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Wlodarczyk%20Jordan%20SM.%20Diagnostic%20Accuracy%20of%20Two%20Manual%20Muscle%20Testing%20Scales%20for%20Identifying%20Upper%20Extremity%20Nerve%20Transfer%20Candidates%20in%20Children%20with%20Acute%20Flaccid%20Myelitis&as_occt=title&as_sauthors=%20%22JM%20Werner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Mahony K</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Hunt A</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Daley D</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Sims S</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Adams R</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Inter-tester reliability and
                  precision of manual muscle testing and hand-held dynamometry in lower limb muscles of children with
                  spina bifida</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Phys Occup Ther Pediatr.</span> 2009;29(1):44-59.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inter-tester%20reliability%20and%20precision%20of%20manual%20muscle%20testing%20and%20hand-held%20dynamometry%20in%20lower%20limb%20muscles%20of%20children%20with%20spina%20bifida&as_occt=title&as_sauthors=%20%22K%20Mahony%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Berry ET</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Giuliani CA</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Damiano DL</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Intrasession and intersession
                  reliability of handheld dynamometry in children with cerebral palsy</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Pediatr Phys Ther.</span>
                2004 Winter;16(4):191-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intrasession%20and%20intersession%20reliability%20of%20handheld%20dynamometry%20in%20children%20with%20cerebral%20palsy&as_occt=title&as_sauthors=%20%22ET%20Berry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Eken MM</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Dallmeijer AJ</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Buizer AI</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Hogervorst S</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> van Hutten K</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Piening M</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> van der Krogt M</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Houdijk H</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Intraobserver Reliability and
                  Construct Validity of the Squat Test in Children With Cerebral Palsy</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Pediatr Phys Ther.</span>
                2020 Oct;32(4):399-403.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intraobserver%20Reliability%20and%20Construct%20Validity%20of%20the%20Squat%20Test%20in%20Children%20With%20Cerebral%20Palsy&as_occt=title&as_sauthors=%20%22MM%20Eken%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Herring JA</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Poliomyelitis</span>. In: <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Herring JA</span>, editor. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Tachdjian’s Pediatric
                  Orthopaedics.</span> 5th ed. Elsevier; 2013. p e195.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Poliomyelitis&as_occt=title&as_sauthors=%20%22JA%20Herring%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Koshino T</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Sugimoto K</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">New measurement of patellar
                  height in the knees of children using the epiphyseal line midpoint</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 1989
                Mar-Apr;9(2):216-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=New%20measurement%20of%20patellar%20height%20in%20the%20knees%20of%20children%20using%20the%20epiphyseal%20line%20midpoint&as_occt=title&as_sauthors=%20%22T%20Koshino%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Sharrard WJ</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Muscle recovery in
                  poliomyelitis</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 1955 Feb;37-B(1):63-79.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Muscle%20recovery%20in%20poliomyelitis&as_occt=title&as_sauthors=%20%22WJ%20Sharrard%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Lubelski D</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Pennington Z</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Tuffaha S</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Moore A</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Belzberg AJ</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Sciatic-to-Femoral Nerve
                  End-to-End Coaptation for Proximal Lower Extremity Function in Patients With Acute Flaccid Myelitis:
                  Technical Note and Review of the Literature</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">Oper Neurosurg (Hagerstown).</span> 2021 Jun
                15;21(1):20-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sciatic-to-Femoral%20Nerve%20End-to-End%20Coaptation%20for%20Proximal%20Lower%20Extremity%20Function%20in%20Patients%20With%20Acute%20Flaccid%20Myelitis%3A%20Technical%20Note%20and%20Review%20of%20the%20Literature&as_occt=title&as_sauthors=%20%22D%20Lubelski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Yount CC</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">An operation to imporve
                  function in quadriceps paralysis</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg.</span> 1938;20(2):314-9.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=150101" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Ober FR</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Tendon transplantation in the
                  lower extremity</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">N Engl J Med.</span> 1933;209:52e9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tendon%20transplantation%20in%20the%20lower%20extremity&as_occt=title&as_sauthors=%20%22FR%20Ober%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Riska EB</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Transposition of the tractus
                  ilio-tibialis to the patella as a treatment of quadriceps paralysis and certain deformities of the
                  lower extremity after poliomyelitis</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Acta Orthop Scand.</span> 1962;32:140-58.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Transposition%20of%20the%20tractus%20ilio-tibialis%20to%20the%20patella%20as%20a%20treatment%20of%20quadriceps%20paralysis%20and%20certain%20deformities%20of%20the%20lower%20extremity%20after%20poliomyelitis&as_occt=title&as_sauthors=%20%22EB%20Riska%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Dodakundi C</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Doi K</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Hattori Y</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Sakamoto S</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Fujihara Y</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Takagi T</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Fukuda M</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Outcome of surgical
                  reconstruction after traumatic total brachial plexus palsy</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2013 Aug
                21;95(16):1505-12.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1170643"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Molenaers G</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Desloovere K</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Fabry G</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> De Cock P</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">The effects of quantitative
                  gait assessment and botulinum toxin A on musculoskeletal surgery in children with cerebral
                  palsy</span>. <span data-id="emphasis_33" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Am.</span> 2006 Jan;88(1):161-70.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=967800" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Ma N</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Sclavos N</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Passmore E</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Thomason P</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Graham K</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Rutz E</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Three-Dimensional Gait Analysis
                  in Children Undergoing Gastrocsoleus Lengthening for Equinus Secondary to Cerebral Palsy</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Medicina (Kaunas).</span>
                2021 Jan 22;57(2):1-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-Dimensional%20Gait%20Analysis%20in%20Children%20Undergoing%20Gastrocsoleus%20Lengthening%20for%20Equinus%20Secondary%20to%20Cerebral%20Palsy&as_occt=title&as_sauthors=%20%22N%20Ma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Marcon M</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Ciritsis B</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Laux C</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Nanz D</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Nguyen-Kim TD</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Fischer MA</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Andreisek G</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Ulbrich EJ</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Cross-sectional area
                  measurements versus volumetric assessment of the quadriceps femoris muscle in patients with anterior
                  cruciate ligament reconstructions</span>. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">Eur Radiol.</span> 2015 Feb;25(2):290-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cross-sectional%20area%20measurements%20versus%20volumetric%20assessment%20of%20the%20quadriceps%20femoris%20muscle%20in%20patients%20with%20anterior%20cruciate%20ligament%20reconstructions&as_occt=title&as_sauthors=%20%22M%20Marcon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;e4df4be4-a166-4e85-8233-2851626ac548&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[&quot;pd kn&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=supplement&name=10519"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fhub.jbjs.org%2Freader.php%3Frsuite_id%3De4df4be4-a166-4e85-8233-2851626ac548%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2F1876%26topics%3Dpd%2Bkn"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="authorinsights_reference"
            class="annotation authorinsights_reference resource-reference" style="position: unset;"></a>
          <div class="content-node authorinsights" data-id="authorinsights" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Author Insights</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6361418776112&quot;,&quot;type&quot;:&quot;author_insights&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;e4df4be4-a166-4e85-8233-2851626ac548&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_647" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6361418776112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-1 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6361418776112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6361418776112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6361418776112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_647_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/3fb37977-0d52-4a60-af75-144f21d7cfb3/aedf15ab-7c58-4dd3-a141-8b6c5834ce6f/1280x720/match/image.jpg"
                        src="blob:https://hub.jbjs.org/06ea0518-a812-4335-a797-d4dbc1c10777">
                        <track kind="metadata" label="segment-metadata">
                        <track kind="subtitles" label="English" srclang="en" default="" id="English">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/3fb37977-0d52-4a60-af75-144f21d7cfb3/aedf15ab-7c58-4dd3-a141-8b6c5834ce6f/1280x720/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:58</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:58">
                            <div class="vjs-load-progress" style="width: 0%;"><span
                                class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">0.00%</span></span>
                              <div data-start="0" data-end="0" style="left: 0%; width: 0%;"></div>
                            </div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:58</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_647_component_926">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_647_component_926"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden vjs-disabled">
                          <button
                            class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button vjs-disabled"
                            type="button" aria-disabled="true" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false" disabled="disabled"><span class="vjs-icon-placeholder"
                              aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu vjs-hidden">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content" role="menu">
                              <li class="vjs-menu-item" tabindex="-1" role="menuitemradio" aria-disabled="false"
                                aria-checked="false"><span class="vjs-menu-item-text">captions off</span><span
                                  class="vjs-control-text" aria-live="polite"></span></li>
                              <li class="vjs-menu-item vjs-subtitles-menu-item vjs-selected" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">English</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button vjs-disabled"
                          type="button" title="Picture-in-Picture" aria-disabled="true" disabled="disabled"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                        <div class="vjs-overlay vjs-overlay-bottom-right vjs-overlay-no-background vjs-hidden"><img
                            src="//players.brightcove.net/2324982687001/studio_overlays/HyP0JSARx-0efbccee-026f-4138-bed4-07fddabe7725.png"
                            style="max-width: 100%;"></div>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_647_component_1141_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_647_component_1141_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_647_component_1147_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_647_component_1147_description">Beginning of dialog window. Escape will cancel
                          and close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_647_component_1147">Text</legend><label
                                id="captions-foreground-color-vjs_video_647_component_1147"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147">
                                <option id="captions-foreground-color-vjs_video_647_component_1147-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_647_component_1147-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_647_component_1147-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_647_component_1147-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_647_component_1147-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_647_component_1147-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_647_component_1147-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_647_component_1147-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147 captions-foreground-color-vjs_video_647_component_1147-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_647_component_1147"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_647_component_1147 captions-foreground-opacity-vjs_video_647_component_1147">
                                  <option id="captions-foreground-opacity-vjs_video_647_component_1147-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_647_component_1147 captions-foreground-opacity-vjs_video_647_component_1147 captions-foreground-opacity-vjs_video_647_component_1147-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_647_component_1147-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_647_component_1147 captions-foreground-opacity-vjs_video_647_component_1147 captions-foreground-opacity-vjs_video_647_component_1147-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_647_component_1147">Background</legend><label
                                id="captions-background-color-vjs_video_647_component_1147"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147">
                                <option id="captions-background-color-vjs_video_647_component_1147-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_647_component_1147-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_647_component_1147-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_647_component_1147-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_647_component_1147-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_647_component_1147-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_647_component_1147-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_647_component_1147-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147 captions-background-color-vjs_video_647_component_1147-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_647_component_1147"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_647_component_1147 captions-background-opacity-vjs_video_647_component_1147">
                                  <option id="captions-background-opacity-vjs_video_647_component_1147-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_647_component_1147 captions-background-opacity-vjs_video_647_component_1147 captions-background-opacity-vjs_video_647_component_1147-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_647_component_1147-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_647_component_1147 captions-background-opacity-vjs_video_647_component_1147 captions-background-opacity-vjs_video_647_component_1147-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_647_component_1147-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_647_component_1147 captions-background-opacity-vjs_video_647_component_1147 captions-background-opacity-vjs_video_647_component_1147-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_647_component_1147">Window</legend><label
                                id="captions-window-color-vjs_video_647_component_1147"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147">
                                <option id="captions-window-color-vjs_video_647_component_1147-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_647_component_1147-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_647_component_1147-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_647_component_1147-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_647_component_1147-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_647_component_1147-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_647_component_1147-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_647_component_1147-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147 captions-window-color-vjs_video_647_component_1147-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_647_component_1147"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_647_component_1147 captions-window-opacity-vjs_video_647_component_1147">
                                  <option id="captions-window-opacity-vjs_video_647_component_1147-Transparent"
                                    value="0"
                                    aria-labelledby="captions-window-vjs_video_647_component_1147 captions-window-opacity-vjs_video_647_component_1147 captions-window-opacity-vjs_video_647_component_1147-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_647_component_1147-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_647_component_1147 captions-window-opacity-vjs_video_647_component_1147 captions-window-opacity-vjs_video_647_component_1147-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_647_component_1147-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_647_component_1147 captions-window-opacity-vjs_video_647_component_1147 captions-window-opacity-vjs_video_647_component_1147-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_647_component_1147" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_647_component_1147">
                                <option id="captions-font-size-vjs_video_647_component_1147-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_647_component_1147 captions-font-size-vjs_video_647_component_1147-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_647_component_1147-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_647_component_1147 captions-font-size-vjs_video_647_component_1147-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_647_component_1147-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_647_component_1147 captions-font-size-vjs_video_647_component_1147-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_647_component_1147-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_647_component_1147 captions-font-size-vjs_video_647_component_1147-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_647_component_1147-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_647_component_1147 captions-font-size-vjs_video_647_component_1147-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_647_component_1147-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_647_component_1147 captions-font-size-vjs_video_647_component_1147-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_647_component_1147-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_647_component_1147 captions-font-size-vjs_video_647_component_1147-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_647_component_1147-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_647_component_1147 captions-font-size-vjs_video_647_component_1147-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_647_component_1147-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_647_component_1147 captions-font-size-vjs_video_647_component_1147-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_647_component_1147" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_647_component_1147">
                                <option id="vjs_video_647_component_1147-None" value="none"
                                  aria-labelledby="vjs_video_647_component_1147 vjs_video_647_component_1147-None">None
                                </option>
                                <option id="vjs_video_647_component_1147-Raised" value="raised"
                                  aria-labelledby="vjs_video_647_component_1147 vjs_video_647_component_1147-Raised">
                                  Raised</option>
                                <option id="vjs_video_647_component_1147-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_647_component_1147 vjs_video_647_component_1147-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_647_component_1147-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_647_component_1147 vjs_video_647_component_1147-Uniform">
                                  Uniform</option>
                                <option id="vjs_video_647_component_1147-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_647_component_1147 vjs_video_647_component_1147-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_647_component_1147" class="">Font Family
                              </legend><select aria-labelledby="captions-font-family-vjs_video_647_component_1147">
                                <option id="captions-font-family-vjs_video_647_component_1147-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_647_component_1147 captions-font-family-vjs_video_647_component_1147-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_647_component_1147-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_647_component_1147 captions-font-family-vjs_video_647_component_1147-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_647_component_1147-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_647_component_1147 captions-font-family-vjs_video_647_component_1147-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_647_component_1147-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_647_component_1147 captions-font-family-vjs_video_647_component_1147-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_647_component_1147-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_647_component_1147 captions-font-family-vjs_video_647_component_1147-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_647_component_1147-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_647_component_1147 captions-font-family-vjs_video_647_component_1147-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_647_component_1147-SmallCaps"
                                  value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_647_component_1147 captions-font-family-vjs_video_647_component_1147-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_647_component_1416_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_647_component_1416_description">This is a modal window. This modal can be closed
                          by pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): 1876</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01268</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 30, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_36"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopedic Surgery, Ogori Daiichi General Hospital, Yamaguchi, Japan</span>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e4df4be4-a166-4e85-8233-2851626ac548&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;pd kn&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=pdf&name=JBJS.23.01268.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=pdf&name=JBJS.23.01268.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_42"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this study. The <span data-id="strong_43" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I47"
                            target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I47</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e4df4be4-a166-4e85-8233-2851626ac548&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;pd kn&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=e4df4be4-a166-4e85-8233-2851626ac548&type=zip&name=JBJS.23.01268.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kazuteru Doi, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:doimicro@saikyo.or.jp" class=""
                    style="position: unset;">doimicro@saikyo.or.jp</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7162-2737" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7162-2737</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yasunori Hattori, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8053-2562" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8053-2562</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Akio Maruyama, PTR, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5837-0341" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5837-0341</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Abdelhakim Ezzat Marei, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4177-3459" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4177-3459</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sotetsu Sakamoto, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0136-5282" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0136-5282</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jun Sasaki, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-4481-1020" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-4481-1020</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kota Hayashi, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2543-7797" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2543-7797</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Makimi Fujita<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-8621-7574" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-8621-7574</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Ogori Daiichi General Hospital, Yamaguchi,
                Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopedics and Traumatology, Tanta University Hospital, Tanta,
                El- Gharbia Governorate, Egypt</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 37152.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
