import { Button, type ButtonProps } from "react-native-paper";

type Props = {
	fullWidth?: boolean;
	testId?: string;
} & ButtonProps;
const AuthButton = ({
	onPress,
	children,
	mode = "contained",
	disabled,
	icon,
	loading,
	fullWidth,
	testId,
}: Props) => (
	<Button
		mode={mode}
		onPress={onPress}
		disabled={disabled}
		style={fullWidth && { width: "100%", maxWidth: 500 }}
		icon={icon}
		loading={loading}
		accessibilityLabel={testId}
	>
		{children || "Continue"}
	</Button>
);

export default AuthButton;
