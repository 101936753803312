export const pageViewTypes = {
	// Authentication and Onboarding
	splash: "splash",
	login: "login",
	verifyEmail: "verify_email",
	onboardingRole: "onboarding_role",
	onboardingSpecialty: "onboarding_specialty",
	onboardingName: "onboarding_name",

	// Main Sections
	home: "home",
	leaderboard: "leaderboard",
	progress: "progress",
	settings: "settings",
	profile: "profile",
	library: "library",
	challenges: "challenges",
	insights: "insights",
	detailArticle: "detail_article",
	detailIssue: "detail_issue",
	allIssues: "all_issues",

	// Learning and Practice
	sessionConfig: "session_config",
	session: "session",
	report: "report",
	review: "review",
	stimulus: "stimulus",
	suggestCorrection: "suggest_correction",

	// Purchase and Subscription
	purchase: "purchase",
	checkout: "checkout",
	purchaseSuccess: "purchase_success",

	// Support and Feedback
	faq: "faq",
	feedback: "feedback",

	// AI Assistant
	aila: "aila",
};

export const newEvents = {
	// Authentication & Account Management
	userLoggedIn: "mem_user_logged_in",
	userLoggedOut: "mem_user_logged_out",
	userRegistered: "mem_user_registered",
	userDeleted: "mem_user_deleted",
	accountDeleted: "mem_account_deleted",
	deleteFeedbackSent: "mem_delete_feedback_sent",
	examContextSwitched: "mem_exam_context_switched",
	examDateSet: "mem_exam_date_set",
	userPreferenceChanged: "mem_user_preference_changed",
	avatarChanged: "mem_avatar_changed",
	waitlistJoined: "mem_waitlist_joined",
	specialtySelected: "mem_specialty_selected",
	roleSelected: "mem_role_selected",

	// Session Core Events
	sessionStarted: "mem_session_started",
	sessionEnded: "mem_session_ended",
	sessionPaused: "mem_session_paused",
	sessionResumed: "mem_session_resumed",
	sessionViewed: "mem_session_viewed",
	diagnosticSessionStarted: "mem_diagnostic_session_started",
	freeQuizStarted: "mem_free_quiz_started",
	sessionDiagnosticClicked: "mem_session_diagnostic_clicked",
	sessionRandomChallengeClicked: "mem_session_random_challenge_clicked",
	sessionItemImageClicked: "mem_session_item_image_clicked",
	sessionItemVideoStarted: "mem_session_item_video_started",
	sessionItemVideoPaused: "mem_session_item_video_paused",
	sessionItemVideoEnded: "mem_session_item_video_ended",

	// Session Navigation & Controls
	sessionPrevNavClicked: "mem_session_prev_nav_clicked",
	sessionNextNavClicked: "mem_session_next_nav_clicked",
	sessionFinishNavClicked: "mem_session_finish_nav_clicked",
	sessionConfigOpened: "mem_session_config_opened",

	// Session Item Interactions
	sessionItemViewed: "mem_session_item_viewed",
	sessionItemNavigated: "mem_session_item_navigated",
	sessionItemOptionSelected: "mem_session_item_option_selected",
	sessionItemConfidenceClicked: "mem_session_item_confidence_clicked",
	sessionItemCrossedOut: "mem_session_item_crossed_out",
	sessionItemHintGiven: "mem_session_item_hint_given",
	sessionItemTextInserted: "mem_session_item_text_inserted",
	completedChallengeClicked: "mem_completed_challenge_clicked",
	sessionItemTryAgainClicked: "mem_session_item_try_again_clicked",

	// Audio Related Events
	sessionItemAudioStarted: "mem_session_item_audio_started",
	sessionItemAudioPaused: "mem_session_item_audio_paused",
	sessionItemAudioEnded: "mem_session_item_audio_ended",
	sessionItemResponseAudioRecordingStarted:
		"mem_session_item_response_audio_recording_started",
	sessionItemResponseAudioRecordingEnded:
		"mem_session_item_response_audio_recording_ended",
	sessionItemResponseSubmitted: "mem_session_item_response_submitted",
	ttsClicked: "mem_tts_clicked",

	// Question & Answer
	answerSelected: "mem_answer_selected",
	answerSubmitted: "mem_answer_submitted",
	crossedOutAnswer: "mem_crossed_out_answer",
	hintViewed: "mem_hint_viewed",
	explanationSheetOpened: "mem_explanation_sheet_opened",
	reviewAnswersClicked: "Review_Answers_Clicked",
	reviewViewed: "mem_review_viewed",
	reportViewed: "mem_report_viewed",
	sessionReportCardClicked: "mem_session_report_card_clicked",
	suggestCorrectionViewed: "mem_suggest_correction_viewed",

	// Stimulus Events
	stimulusViewed: "mem_stimulus_viewed",
	sessionStimulusOpened: "mem_session_stimulus_opened",
	sessionStimulusClosed: "mem_session_stimulus_closed",

	// Payment & Subscription
	paymentSubmitted: "mem_payment_submitted",
	paymentSuccessful: "mem_payment_successful",
	paymentFailed: "mem_payment_failed",
	paywallOpened: "mem_paywall_opened",
	paywallClosed: "mem_paywall_closed",
	subscriptionOptionClicked: "mem_subscription_option_clicked",
	purchaseModalViewed: "mem_purchase_modal_viewed",
	checkoutViewed: "mem_checkout_viewed",
	purchaseSuccessViewed: "mem_purchase_success_viewed",
	planSelected: "mem_plan_selected",
	upgradeChipClicked: "mem_upgrade_chip_clicked",
	lockedTagClicked: "mem_locked_tag_clicked",
	purchaseButtonClicked: "mem_purchase_button_clicked",
	redeemViewed: "mem_redeem_viewed",
	redeemAccessCodeStarted: "mem_redeem_access_code_started",

	// UI Interactions
	dialogOpened: "mem_dialog_opened",
	dialogClosed: "mem_dialog_closed",
	commandBarClicked: "mem_command_bar_clicked",
	commandBarOptionClicked: "mem_command_bar_option_clicked",
	studyFabClicked: "mem_study_fab_clicked",
	categorySelected: "Category_Selected",
	suggestedChipClicked: "Suggested_Chip_Clicked",
	quickActionClicked: "mem_quick_action_clicked",
	taskCardClicked: "mem_task_card_clicked",
	leaderboardIntervalClicked: "mem_leaderboard_interval_clicked",
	skillExpanded: "mem_skill_expanded",

	// Search
	storeSearchQuerySubmitted: "mem_store_search_query_submitted",
	qaSearchModalViewed: "mem_qa_search_modal_viewed",

	// Support & Feedback
	contactSupportClicked: "mem_contact_support_clicked",
	feedbackSubmitted: "mem_feedback_submitted",
	termsOfServiceClicked: "mem_terms_of_service_clicked",
	privacyPolicyClicked: "mem_privacy_policy_clicked",

	// Email & Communications
	emailSent: "mem_email_sent",
	emailTriggered: "mem_email_triggered",

	// Copilot/AI Assistant
	copilotOpened: "mem_copilot_opened",
	copilotClosed: "mem_copilot_closed",
	copilotTextSubmitted: "mem_copilot_text_submitted",
	copilotFeedbackSubmitted: "mem_copilot_feedback_submitted",
	copilotFollowupSelected: "mem_copilot_followup_selected",
	articleClicked: "mem_article_clicked",
	challengeClicked: "mem_challenge_clicked",
	featuredChallengeClicked: "mem_featured_challenge_clicked",
	tabSwitched: "mem_tab_switched",
	articleBookmarked: "mem_article_bookmarked",
	articleUnbookmarked: "mem_article_unbookmarked",

	filterChipClicked: "mem_filter_chip_clicked",
	bottomSheetOpened: "mem_bottom_sheet_opened",
	bottomSheetClosed: "mem_bottom_sheet_closed",
};
export const events = {
	examContextSwitched: "Exam_Context_Switched",
	loginViewed: "Login_Viewed",
	verifyEmailViewed: "Verify_Email_Viewed",
	chooseCategoryViewed: "Choose_Category_Viewed",
	chooseExamViewed: "Choose_Exam_Viewed",
	chooseDateViewed: "Choose_Date_Viewed",
	// productHomeViewed: 'Product_Home_Viewed',
	// progressViewed: 'Progress_Viewed',
	storeViewed: "Store_Viewed",
	profileViewed: "Profile_Viewed",
	leaderBoardViewed: "Leaderboard_Viewed",
	// sessionConfigViewed: 'Session_Config_Viewed',
	professionSelected: "Profession_Selected",
	specialtySelected: "Specialty_Selected",
	avatarSelected: "Avatar_Selected",
	reportViewed: "Report_Viewed",
	reviewViewed: "Review_Viewed",
	purchaseModalViewed: "Purchase_Modal_Viewed",
	redeemViewed: "Redeem_Viewed",
	planSelected: "Plan_Selected",
	studyFabClicked: "Study_Fab_Clicked",
	upgradeChipClicked: "Upgrade_Button_Clicked",
	lockedTagClicked: "Locked_Tag_Clicked",
	purchaseButtonClicked: "Purchase_Button_Clicked",
	crossedOutAnswer: "Crossed_Out_Answer",
	answerSelected: "Answer_Selected",
	explanationSheetOpened: "Explanation_Sheet_Opened",
	suggestedChipClicked: "Suggested_Chip_Clicked",
	reviewAnswersClicked: "Review_Answers_Clicked",
	categorySelected: "Category_Selected",
	sessionStarted: "Session_Started",
	freeQuizStarted: "Free_Quiz_Started",
	sessionResumed: "Session_Resumed",
	sessionPaused: "Session_Paused",
	sessionViewed: "Session_Viewed",
	sessionEnded: "Session_Ended",
	storeSearchQuerySubmitted: "Store_Search_Query_Submitted",
	sessionPrevNavClicked: "Session_Prev_Nav_Clicked",
	sessionNextNavClicked: "Session_Next_Nav_Clicked",
	sessionFinishNavClicked: "Session_Finish_Nav_Clicked",
	answerSubmitted: "Answer_Submitted",
	quickActionClicked: "Quick_Action_Clicked",
	qaSearchModalViewed: "QA_Search_Modal_Viewed",
	redeemAccessCodeStarted: "Redeem_Access_Code_Started",
	deleteFeedbackSent: "Delete_Feedback_Sent",
	accountDeleted: "Account_Deleted",
	hintViewed: "Hint_Viewed",
	avatarChanged: "Avatar_Changed",
	taskCardClicked: "Task_Card_Clicked",
};
