/* ----------------- React Native --------------- */
import { View } from "react-native";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* ----------------- Third Party Imports --------------- */
import { Text } from "react-native-paper";

/* ----------------- Local Imports --------------- */
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";

interface Props {
	free?: boolean;
}
const FreemiumRibbon = ({ free }: Props) => {
	const theme = useAppTheme();
	const background = free
		? theme.colors.freeChip.background
		: theme.colors.premiumChip.background;
	const color = free
		? theme.colors.freeChip.color
		: theme.colors.premiumChip.color;
	const text = free ? "Free" : "Premium";
	const icon = free ? "gift-outline" : "crown-outline";
	return (
		<View
			style={{
				right: -36,
				top: 30,
				width: 170,
				position: "absolute",
				backgroundColor: background,
				padding: 4,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				transform: [{ rotate: "45deg" }],
			}}
		>
			<Box flexDirection="row" alignItems="center" gap={4}>
				<Icon name={icon} size={16} color={color} />
				<Text
					variant="labelMedium"
					style={{
						color,
					}}
				>
					{text}
				</Text>
			</Box>
		</View>
	);
};

export default FreemiumRibbon;
