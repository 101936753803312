import { REST_API_BASE_URL } from "@constants/urls";
import type { CMEData } from "./schema";

export const fetchCMEData = async ({
	userId,
}: { userId: number }): Promise<CMEData> => {
	const response = await fetch(
		`${REST_API_BASE_URL}/user-events/get-cme-details`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				userId,
			}),
		},
	);

	if (!response.ok) {
		throw new Error("Network response was not ok");
	}

	const json = await response.json();

	return json;
};
