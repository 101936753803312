import type { Article } from "../../../../../types";

export const Article579: Article = {
	id: 579,
	rsuiteId: "1e3e1f5d-170d-4ce5-b876-1a81a9b67448",
	type: "evidence-based orthopaedics",
	title:
		"Single-Level CDA for Cervical Radiculopathy Maintained Some ROM Measures and Reduced Adjacent Segment Degeneration Vs. ACDF at 20 Years",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/579.jpeg",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Single-Level CDA for Cervical Radiculopathy Maintained
                Some ROM Measures and Reduced Adjacent Segment Degeneration Vs. ACDF at 20 Years</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Nitin Bhatia,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Foley DP, Sasso WR, Ye JY, Vinayek S, Smucker JD, McCarthy
                  MH, Boody BS, Sasso RC. 20-year radiographic outcomes following single-level cervical disc
                  arthroplasty: results from a prospective randomized controlled trial. Spine (Phila Pa 1976). 2024 Mar
                  1;49(5):295-303.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_7" class="annotation strong"
                    style="position: unset;">Question:</span> In adults with cervical radiculopathy, how do single-level
                  cervical disc arthroplasty (CDA) and anterior cervical discectomy and fusion (ACDF) compare for range
                  of motion (ROM) and adjacent segment degeneration (ASD) at 20 years?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_8" class="annotation strong"
                    style="position: unset;">Design:</span> Randomized (allocation concealed), blinded (outcome
                  assessors), controlled trial with 20 years of follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_9" class="annotation strong"
                    style="position: unset;">Setting:</span> A single clinical center.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_10" class="annotation strong"
                    style="position: unset;">Patients:</span> 47 patients aged ≥21 years (mean age, 42 years; 59% men;
                  92% White) who had single-level cervical degenerative disc disease from C3 to C7 with associated
                  radiculopathy or myelopathy and a Neck Disability Index &gt;30 and failed ≥6 weeks of nonoperative
                  treatment. Exclusion criteria were substantial cervical anatomic deformity, advanced degenerative
                  changes, metabolic bone disease, spinal metastases, infection, diabetes mellitus, implant allergy,
                  extreme obesity, pregnancy, or previous cervical surgery. 83% of patients completed follow-up at 20
                  years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_11" class="annotation strong"
                    style="position: unset;">Intervention:</span> Patients were allocated to single-level CDA with a
                  BRYAN (Medtronic) cervical arthroplasty device (n = 24) or ACDF with allograft and anterior plating (n
                  = 23).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_12" class="annotation strong"
                    style="position: unset;">Main outcome measures:</span> Outcomes included ROM and ASD grade (assessed
                  with the Kellgren-Lawrence classification) of the superior and inferior adjacent levels at 20 years.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_13" class="annotation strong"
                    style="position: unset;">Main results:</span> ROM results are shown in <a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>. CDA reduced ASD at the superior (p = 0.002) and inferior (p =
                  0.004) levels compared with ACDF at 20 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Single-level cervical disc arthroplasty (CDA) versus
                          anterior cervical discectomy and fusion (ACDF) in adults with cervical radiculopathy</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 272px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Range-of-motion outcomes</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Mean values at 20 years <span data-id="emphasis_5"
                            class="annotation emphasis" style="position: unset;">(deg)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">CDA</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">ACDF</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total cervical</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">47.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">33.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.005</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Index level</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Superior adjacent
                          level</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.432</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Inferior adjacent
                          level</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.884</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_14" class="annotation strong"
                    style="position: unset;">Conclusion:</span> In adults with cervical radiculopathy, CDA maintained
                  total and index-level ROM measures and reduced ASD compared with ACDF at 20 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Sources of funding: No external funding.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_6" class="annotation emphasis"
                    style="position: unset;">For correspondence: Dr. David P. Foley, Indiana University School of
                    Medicine, Indianapolis, Indiana. E-mail address: <a href="mailto:dapfoley@iu.edu" target="_blank"
                      data-id="link_2" class="link" style="position: unset;">dapfoley@iu.edu</a></span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">It is thought that CDA might minimize the deleterious
                  biomechanical changes and associated ASD that can result from cervical radiculopathy, but these
                  benefits have not been unequivocally confirmed by clinical trials. Further, it is not known whether
                  improvements in biomechanics, ROM, and ASD derived from CDA result in clinical benefits.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The U.S. Food and Drug Administration Investigational
                  Device Exemption trial by Foley and colleagues found that, in patients with cervical radiculopathy,
                  CDA with the BRYAN cervical arthroplasty disc maintained the index operative level and total cervical
                  ROM versus ACDF at 20 years. ASD grades at both the inferior and superior levels were lower in the CDA
                  versus the ACDF group, but groups did not differ for ROM at these levels. Adjacent-level ossification
                  development (ALOD) at the superior and inferior levels was also reduced in the CDA versus the ACDF
                  group, but the proximity of the fusion plate to the adjacent disc space, which has previously been
                  shown to be a predictor of ALOD<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>, was
                  not reported. Plate position, rather than any biomechanical difference between groups, may be
                  responsible for the ALOD. The reduced ASD and revision surgery rates in the CDA group are consistent
                  with some published data<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a> but
                  conflict with other studies, which have not shown similar reductions in ASD<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a> or revision surgery rates<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a> with CDA versus ACDF.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">A strength of the trial was the long-term follow-up. The
                  trial also had some important weaknesses. The number of patients in each group was low. The BRYAN disc
                  is no longer used, and extrapolation of these findings to other disc designs has not been validated.
                  Furthermore, patients were meticulously screened to be appropriate candidates for CDA, but such close
                  evaluation may not occur in general practice.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Given the rapidly evolving field of CDA, further trials
                  are required to confirm whether modern arthroplasty engineering and indications for implantation
                  provide the benefits suggested in this trial, especially when coupled with the improvements in ACDF
                  technique that have occurred over the past 20 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Park JB</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Cho YS</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Riew KD</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Development of adjacent-level
                  ossification in patients with an anterior cervical plate</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2005
                Mar;87(3):558-63.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=961503"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Pointillart V</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Castelain JE</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Coudert P</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Cawley DT</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Gille O</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Vital JM</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Outcomes of the Bryan cervical
                  disc replacement: fifteen year follow-up</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2018
                Apr;42(4):851-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20the%20Bryan%20cervical%20disc%20replacement%3A%20fifteen%20year%20follow-up&as_occt=title&as_sauthors=%20%22V%20Pointillart%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Jawahar A</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Cavanaugh DA</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Kerr EJ</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Birdsong EM</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Nunley PD</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Total disc arthroplasty does not
                  affect the incidence of adjacent segment degeneration in cervical spine: results of 93 patients in
                  three prospective randomized clinical trials</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2010
                Dec;10(12):1043-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20disc%20arthroplasty%20does%20not%20affect%20the%20incidence%20of%20adjacent%20segment%20degeneration%20in%20cervical%20spine%3A%20results%20of%2093%20patients%20in%20three%20prospective%20randomized%20clinical%20trials&as_occt=title&as_sauthors=%20%22A%20Jawahar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Coric D</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Nunley PD</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Guyer RD</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Musante D</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Carmody CN</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Gordon CR</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Lauryssen C</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Ohnmeiss DD</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Boltes MO</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Prospective, randomized,
                  multicenter study of cervical arthroplasty: 269 patients from the Kineflex|C artificial disc
                  investigational device exemption study with a minimum 2-year follow-up: clinical article</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Neurosurg Spine.</span>
                2011 Oct;15(4):348-58.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prospective%2C%20randomized%2C%20multicenter%20study%20of%20cervical%20arthroplasty%3A%20269%20patients%20from%20the%20Kineflex%7CC%20artificial%20disc%20investigational%20device%20exemption%20study%20with%20a%20minimum%202-year%20follow-up%3A%20clinical%20article&as_occt=title&as_sauthors=%20%22D%20Coric%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D1e3e1f5d-170d-4ce5-b876-1a81a9b67448%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Evidence-Based Orthopaedics</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2167</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.01103</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 26, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;1e3e1f5d-170d-4ce5-b876-1a81a9b67448&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=1e3e1f5d-170d-4ce5-b876-1a81a9b67448&type=pdf&name=JBJS.24.01103.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=1e3e1f5d-170d-4ce5-b876-1a81a9b67448&type=pdf&name=JBJS.24.01103.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_5" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_6"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest form</span> is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I218" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I218</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;1e3e1f5d-170d-4ce5-b876-1a81a9b67448&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=1e3e1f5d-170d-4ce5-b876-1a81a9b67448&type=zip&name=JBJS.24.01103.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nitin Bhatia, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">University of California, Irvine, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5856.43px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
