/* -----------------Child components--------------- */
import { CustomAppbar } from "components/index";

import { appBarMeta } from "@constants/appbar";
/* -----------------Features--------------- */
import { LeaderboardContainer } from "@features/dashboard";
import { isWeb } from "@helpers/platform";
import { useDevice } from "@hooks/useDevice";
import DocumentTitle from "components/DocumentTitle";
const LeaderboardRoute = () => {
	const { isMobile } = useDevice();
	return (
		<>
			<CustomAppbar
				showCommandBar={isWeb}
				showHambugerMenu
				mode="medium"
				align="flex-start"
				options={{
					headerTitle: appBarMeta.leaderboard.title,
					subtitle: isMobile ? "" : appBarMeta.leaderboard.description,
				}}
			/>
			<DocumentTitle title="Leaderboard" />
			<LeaderboardContainer />
		</>
	);
};

export default LeaderboardRoute;
