import { Platform } from "react-native";

export const applyGlobalPolyfills = () => {
	if (Platform.OS === "ios" || Platform.OS === "android") {
		const {
			polyfill: polyfillEncoding,
		} = require("react-native-polyfill-globals/src/encoding");
		const {
			polyfill: polyfillReadableStream,
		} = require("react-native-polyfill-globals/src/readable-stream");
		const {
			polyfill: polyfillFetch,
		} = require("react-native-polyfill-globals/src/fetch");
		const {
			polyfill: polyfillBase64,
		} = require("react-native-polyfill-globals/src/base64");
		const {
			polyfill: pollyfillCrypto,
		} = require("react-native-polyfill-globals/src/crypto");

		polyfillEncoding();
		polyfillReadableStream();
		polyfillFetch();
		polyfillBase64();
		pollyfillCrypto();
	}
};
