import type { Article } from "../../../../../types";

export const Article531: Article = {
	id: 531,
	rsuiteId: "9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763",
	type: "current concepts review",
	title:
		"Slope Osteotomies in the Setting of Anterior Cruciate Ligament Deficiency",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&type=jpeg&name=JBJS.23.01352f1",
	subSpecialties: ["knee"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Slope Osteotomies in the Setting of Anterior Cruciate
                Ligament Deficiency</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Mahmut Enes
                      Kayaalp, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Philipp
                      Winkler, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node list" data-id="list_1" style="position: unset;">
                  <ul class="simple content" style="position: unset;">
                    <li style="position: unset;"><span class="label">➤</span>
                      <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_1" style="position: unset;">
                            <div class="content" style="position: unset;">Posterior tibial slope (PTS) of ≥12°
                              represents an important risk factor for both anterior cruciate ligament (ACL) injury and
                              ACL reconstruction failure.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➤</span>
                      <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_2" style="position: unset;">
                            <div class="content" style="position: unset;">PTS measurements can significantly differ on
                              the basis of the imaging modality and the measurement technique used. PTS should be
                              measured on strictly lateral radiographs, with a recommended proximal tibial length of 15
                              cm in the image. The PTS measurement can be made by placing 2 circles to define the
                              proximal tibial axis, 1 just below the tibial tubercle and another 10 cm below it. PTS
                              measurements are underestimated when made on magnetic resonance imaging and computed
                              tomography.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➤</span>
                      <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_3" style="position: unset;">
                            <div class="content" style="position: unset;">Slope-reducing osteotomies can be performed
                              using a (1) supratuberosity, (2) tubercle-reflecting transtuberosity, or (3)
                              infratuberosity method. The correction target remains a topic of debate. Although it is
                              controversial, some authors recommend overcorrecting the tibial slope slightly to a range
                              of 4° to 6°. For instance, if the initial slope is 12°, a correction of 6° to 8° should be
                              performed, given the target tibial slope of 4° to 6°.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➤</span>
                      <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_4" style="position: unset;">
                            <div class="content" style="position: unset;">Clinical outcomes following slope-reducing
                              osteotomies have been favorable. However, potential complications, limited data with
                              regard to the impact of slope-reducing osteotomies on osteoarthritis, and uncertainty with
                              regard to the effects on the patellofemoral joint are notable concerns.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➤</span>
                      <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_5" style="position: unset;">
                            <div class="content" style="position: unset;">Patients with complex deformities may need
                              biplanar osteotomies to comprehensively address the condition.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Increased posterior tibial slope (PTS) is a risk factor
                  for anterior cruciate ligament (ACL) injury and ACL reconstruction (ACLR) failure<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">3</span></a>.
                  Osteotomies that alter the PTS, either in isolation or as part of biplanar alignment corrections, are
                  increasingly being adopted by knee surgeons<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">10</span></a>. There has been debate with regard to the precise
                  definition of the PTS and the optimal approach for PTS measurement using various imaging modalities.
                  There are unresolved questions about when to consider a slope-reducing osteotomy and at what
                  anatomical level to perform osteotomy. In this review, the importance of increased tibial slope in ACL
                  graft failure and its definition, measurement, and biomechanical rationale will be reviewed.
                  Additionally, indications for slope-reducing osteotomies, preoperative planning, and various surgical
                  techniques will be discussed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The
                Significance of Increased PTS in the Setting of ACL Graft Failure</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">A PTS of ≥12° on a lateral knee radiograph was shown to be
                  the strongest predictor of ACL graft failure, particularly in adolescents<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">11</span></a>. A
                  recent systematic review and meta-analysis compared PTS measurements in ACL graft failure and intact
                  primary ACLR cases and identified an increased PTS as a risk factor for ACL graft failure<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">2</span></a>. Another study revealed an increased prevalence of knees
                  with a PTS of ≥12° in multiple-revision ACLR cohorts compared with single-time ACL graft failure
                  cohorts. In the entire group of patients who underwent revision ACLR, the prevalence of an increased
                  PTS (≥12° on a lateral long leg radiograph) was 35%<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">12</span></a>,
                  whereas it was 25% in patients with a first-time ACL injury<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">13</span></a>. In
                  another study, a comparison was made between the PTS in an ACL-injured cohort and in a healthy knee
                  cohort. The ACL-injured group exhibited more outliers, defined as patients with a PTS of ≥12°<a
                    href="" data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">14</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">A previous study investigated the change in PTS over time
                  in an adolescent population that initially underwent nonoperative treatment after an ACL injury. At a
                  mean follow-up of 9 years, there was a significant increase in lateral PTS by 2° compared with the
                  uninjured contralateral knee<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">15</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Although several previous studies have shown only lateral
                  PTS as a risk factor for an ACL injury<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">16</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">17</span></a>, a recent systematic review and meta-analysis showed that
                  both medial and lateral PTS increased the risk of ACL graft failure after primary ACLR<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">2</span></a>. Increased PTS is also a known factor associated with
                  greater anterior tibial translation (ATT)<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">18</span></a> and
                  a higher risk of medial or lateral meniscal tears in cases of ACL injury<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">20</span></a> (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01352f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&type=jpeg&name=JBJS.23.01352f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_13" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_85"
                            class="annotation strong" style="position: unset;">Figs. 1-A and 1-B</span> Images of the
                          left knee of a patient who presented with a failed ACLR. <span data-id="strong_86"
                            class="annotation strong" style="position: unset;">Fig. 1-A</span> The lateral radiograph
                          shows a PTS measurement of 13°. <span data-id="strong_87" class="annotation strong"
                            style="position: unset;">Fig. 1-B</span> The measurement of ATT using sagittal MRI. This
                          measurement involves referencing the posterior tibial cortex (depicted as a white dashed
                          line). Two continuous white lines are drawn parallel to this reference, representing the
                          posterior part of the medial plateau and the medial femoral condyle. The ATT is then
                          determined as the distance between the most posterior points of the medial plateau and the
                          medial femoral condyle. The ATT was measured as 10 mm.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Although most of the existing literature is currently
                  consistent on the effect of PTS on ACL graft failure<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_13" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">3</span></a>, some studies have shown no association between PTS and ACL
                  injury or graft failure<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">23</span></a>. It must be noted that various factors must be considered
                  when interpreting their results, including the ability to account for confounding factors, sample
                  size, follow-up rates, and the PTS measurement method (i.e., short compared with long radiographs or
                  magnetic resonance imaging [MRI] compared with radiographs).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Definition
                and Measurement of PTS</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The existing literature on PTS measurement lacks
                  consistency, leading to discrepancies in slope measurements. Some studies have assessed the lateral
                  slope or the medial slope<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">25</span></a>, whereas others have not differentiated between medial and
                  lateral slopes<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">27</span></a> (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). The
                  tibial length used in making measurements on radiographs varies and significantly impacts the slope
                  measurements<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">28</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01352f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&type=jpeg&name=JBJS.23.01352f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_88"
                            class="annotation strong" style="position: unset;">Figs. 2-A and 2-B</span> Radiographs of
                          the left knee of a patient who previously underwent a physis-sparing ACLR. <span
                            data-id="strong_89" class="annotation strong" style="position: unset;">Fig. 2-A</span> The
                          identification of the concave surface of the medial plateau (black dashed line) compared with
                          the convex surface of the lateral plateau (white dashed line). For PTS measurement on
                          radiographs, the medial plateau is used. <span data-id="strong_90" class="annotation strong"
                            style="position: unset;">Fig. 2-B</span> The identification of the most anterior points
                          (dashed-line black arrow) and most posterior points (solid black arrow) of the concave surface
                          of the medial plateau, which is used for medial slope measurement on a lateral knee
                          radiograph.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">These intricacies are important because it has been shown
                  that the medial and lateral plateaus within individuals can differ<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">30</span></a>. The choice of tibial length on radiographs, whether 10 or
                  15 cm, also influences PTS results, often leading to an overestimation of PTS when 10 cm is used, by a
                  mean of 3° compared with the mechanical tibial axis<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">31</span></a>.
                  Additionally, researchers have used various tibial diaphyseal levels to define the anatomical axis<a
                    href="" data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_20" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">32</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Some studies have indicated that using the anatomical axis
                  of the proximal tibia provides the most accurate results in PTS measurement when compared with the
                  mechanical axis of the tibia<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">33</span></a>.
                  The widely accepted cutoff value of 12° for PTS was determined using points located 5 and 15 cm distal
                  to the tibial joint line<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">1</span></a>. A
                  recent consensus report on revision ACLR also recommended this approach<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">34</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">However, the most accurate slope angle will be measured on
                  full-length lateral tibial radiographs and using the tibial mechanical axis<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">35</span></a>. This approach may yield significantly higher or lower PTS
                  measurements depending on the individual patient anatomy (e.g., tibial bowing)<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">35</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">36</span></a>. A revision ACLR cohort displayed increased diaphyseal
                  tibial bowing, which elevated the measured PTS<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">35</span></a> (<a
                    href="" data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). Increased tibial bowing may lead surgeons to consider a larger
                  correction. However, it remains uncertain if diaphyseal bowing or measuring the PTS using the
                  full-length lateral tibial axis has clinical relevance, especially with regard to proximal tibial
                  osteotomies and their applicability in correcting diaphyseal deformities<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">37</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01352f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&type=jpeg&name=JBJS.23.01352f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_91"
                            class="annotation strong" style="position: unset;">Figs. 3-A, 3-B, and 3-C</span>
                          Full-length lateral tibial radiographs of a left leg with increased diaphyseal bowing. <span
                            data-id="strong_92" class="annotation strong" style="position: unset;">Fig. 3-A</span> The
                          measurement of the PTS is conducted using the proximal tibial axis, resulting in a measured
                          PTS of 16°. <span data-id="strong_93" class="annotation strong" style="position: unset;">Fig.
                            3-B</span> Utilization of the tibial mechanical axis in measuring the PTS. This axis is
                          determined by the midpoint of the proximal tibial joint line and the talus. The PTS is
                          measured as 19°. <span data-id="strong_94" class="annotation strong"
                            style="position: unset;">Fig. 3-C</span> Bowing of the tibial diaphysis. White lines denote
                          the axes of the proximal and distal diaphysis. The bowing displays a center of rotation and
                          angulation located in the mid-diaphyseal region, with a bowing degree of 9°.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Additionally, some studies have employed various
                  techniques for tibial axis determination, including the anterior cortical line<a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">38</span></a>, the posterior cortical line<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">39</span></a>, or an intermediate line<a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">40</span></a>. Notably, using the anterior cortical line as the tibial
                  axis tends to overestimate the PTS, whereas posterior referencing tends to underestimate the PTS<a
                    href="" data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">33</span></a>. Many studies have stressed the importance of a clear
                  lateral radiographic view without appreciable posterior femoral condyle overlap, ideally &lt;6 mm, for
                  accurate PTS measurements<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">41</span></a>. A
                  recent study emphasized a greater impact when aligning the distal femoral surfaces of both condyles
                  compared with aligning the posterior condyles<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">42</span></a>.
                  However, the impact of tibial rotation on measurements, specifically axial rotation or lateral
                  tilting, is often underestimated, as noted in a previous study<a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">30</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">MRI and computed tomographic (CT) scans provide greater
                  precision in distinguishing between medial and lateral PTS<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">43</span></a>. Currently, there is no established MRI protocol for
                  determining the necessary tibial length for accurate PTS measurements. Existing techniques either use
                  the centers of integrated joint-sided circles, 1 placed just below the joint line and another just
                  below it<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">44</span></a> or
                  the midpoint of the most distal portion of the tibial shaft as a reference for the tibial axis<a
                    href="" data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">45</span></a>. Both methods primarily focus on the proximal tibia,
                  potentially resulting in an underestimation of the PTS. A comparative study found 4.9° of
                  underestimation of the medial PTS when using MRI, compared with lateral knee radiographs<a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">46</span></a>. Importantly, differences in PTS values between radiographs
                  and MRI in a similar patient cohort with failed ACLR can exceed 6°<a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_39"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">47</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 4</a>
                  demonstrates the influence of tibial length on PTS measurements by comparing MRI and full-length
                  lateral tibial radiographs of the same patient.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01352f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&type=jpeg&name=JBJS.23.01352f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_95"
                            class="annotation strong" style="position: unset;">Figs. 4-A through 4-D</span> MRI and
                          full-length lateral tibial radiographs showing measurements of PTS in a right knee. <span
                            data-id="strong_96" class="annotation strong" style="position: unset;">Fig. 4-A</span> The
                          MRI measurement follows the recommendations by Hudek et al.<a href=""
                            data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                              style="position: unset;">44</span></a>, determining the tibial axis as depicted in the
                          inset figure, and a perpendicular line to the proximal tibial medial slope, with the PTS
                          calculated as the angle between them. <span data-id="strong_97" class="annotation strong"
                            style="position: unset;">Fig. 4-B</span> This radiograph employs the circle placement method
                          of Dejour et al.<a href="" data-id="citation_reference_59"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_41" class="annotation superscript"
                              style="position: unset;">4</span></a>, with 1 circle positioned just below the tuberosity
                          level around 5 to 6 cm below the joint line and another circle 15 cm below the joint line. The
                          corresponding PTS value is 12°. <span data-id="strong_98" class="annotation strong"
                            style="position: unset;">Fig. 4-C</span> This radiograph illustrates the slope measurement
                          based on the mechanical axis, taken at the midpoint between the tibial joint line and the
                          talus. The corresponding PTS value is 12°. <span data-id="strong_99" class="annotation strong"
                            style="position: unset;">Fig. 4-D</span> This radiograph shows the anatomic axis-based slope
                          measurement, using circles placed 5 cm from the proximal and distal joint lines. The
                          corresponding PTS value is 13°.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">In surgical decision-making, PTS measurements based on MRI
                  and CT scans should be avoided. Instead, lateral knee radiographs with at least 15 cm of the tibial
                  diaphysis in the image should be utilized. The tibial axis can be determined by placing 2 circles
                  along the tibial diaphysis at 5 and 15 cm below the tibial plateau to establish the proximal tibial
                  axis. The axis of the medial tibial plateau should be employed for PTS measurement.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Currently, standardized comparative guidelines for studies
                  employing various imaging methods have been lacking. Therefore, surgeons and readers should exercise
                  caution when interpreting PTS measurements.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Biomechanical Rationale</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Studies assessing knee kinematics, contact mechanics, and
                  ligament forces have employed various techniques, such as robotic systems or optical motion capture<a
                    href="" data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_42"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">50</span></a>. The use of in situ ligament force evaluation may introduce
                  bias, necessitating native ligament detachment prior to obtaining load cell connections<a href=""
                    data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_43"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_63" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_43"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">49</span></a><span data-id="superscript_43"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_65" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">51</span></a>. Different slope-reducing osteotomy techniques, such as
                  supratuberosity, tubercle-reflecting transtuberosity, and infratuberosity methods<a href=""
                    data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_44" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_67"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">52</span></a><span data-id="superscript_44"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_68" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">53</span></a>, have unique implications for biomechanical research.
                  Supratuberosity osteotomies may impact patellofemoral contact mechanics and pose risks to
                  capsuloligamentous structures<a href="" data-id="citation_reference_69"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_45" class="annotation superscript" style="position: unset;">10</span></a>,
                  whereas transtuberosity osteotomies could affect the knee’s extensor mechanism and patellofemoral
                  contact mechanics. In cases of high, metaphyseal sagittal deformity, infratuberosity osteotomies may
                  lead to tibial translational deformity, as the osteotomy site is farther from the site of the
                  deformity<a href="" data-id="citation_reference_70"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_46" class="annotation superscript" style="position: unset;">37</span></a>. In
                  a controlled laboratory setting, ensuring high accuracy and repeatability is crucial for valid data.
                  Conducting multiple PTS adjustments during biomechanical testing is a complex surgical procedure
                  requiring an intact osteotomy hinge, stable fixation, and precise angulation. The repeatability of PTS
                  adjustments is often underreported in studies, casting doubt on the reliability of the results<a
                    href="" data-id="citation_reference_71" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_47"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_72" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">49</span></a><span data-id="superscript_47"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_73" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">51</span></a><span data-id="superscript_47"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">54</span></a><span data-id="superscript_47"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_75" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">55</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Biomechanical research has consistently indicated that
                  altering the PTS impacts tibial translation and rotation<a href="" data-id="citation_reference_76"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_77" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">50</span></a><span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_78" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">54</span></a><span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_79" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">56</span></a><span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_80" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">57</span></a>. However, there has been ongoing debate about the effect of
                  slope-reducing osteotomy on ACL forces. In the native ACL, increased PTS leads to decreased forces<a
                    href="" data-id="citation_reference_81" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">50</span></a><span data-id="superscript_49"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_82" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">58</span></a><span data-id="superscript_49"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_83" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">59</span></a>, whereas, in ACL grafts, decreased PTS results in decreased
                  forces<a href="" data-id="citation_reference_84"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_50"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_85" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">49</span></a><span data-id="superscript_50"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_86" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">51</span></a><span data-id="superscript_50"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_87" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">57</span></a>. These inconsistencies are due to various biomechanical
                  testing factors in slope-altering osteotomies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Further high-quality studies are required to accurately
                  assess the effects of slope-altering osteotomies on knee biomechanics. These studies should employ a
                  6-degrees-of-freedom biomechanical setup, suitable loading conditions, and consistent techniques;
                  should account for soft-tissue influences; and should ensure high repeatability in PTS adjustments.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Indications
                and Contraindications for Anterior Closing-Wedge, Slope-Reducing Osteotomy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Consensus has been lacking with regard to the specific
                  indications for slope-reducing osteotomy in patients with ACL injuries. Most studies have focused on
                  patients who underwent revision ACLR and simultaneous slope-reducing osteotomies<a href=""
                    data-id="citation_reference_88" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_51" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_89"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_51"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_90" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_51"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_91" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">60</span></a>. Preoperative PTS thresholds for slope-reducing osteotomy
                  vary, with some studies suggesting a PTS of ≥12° for revision ACLR and others suggesting &gt;15° for
                  primary ACLR<a href="" data-id="citation_reference_92"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_52" class="annotation superscript" style="position: unset;">5</span></a>. One
                  study suggested a PTS of &gt;13°, along with excessive ATT (&gt;10 mm) and a chronic posterior
                  meniscal horn tear (&gt;6 months), for a primary ACL injury<a href="" data-id="citation_reference_93"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_53" class="annotation superscript" style="position: unset;">7</span></a>. <a
                    href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a> provides an overview of the available recommendations on the
                  indications and contraindications, graded as described by Wright<a href=""
                    data-id="citation_reference_94" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_54" class="annotation superscript"
                      style="position: unset;">61</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Grades of Recommendations for the Indications and
                          Contraindications for Anterior Closing-Wedge, Slope-Reducing Osteotomy</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 376px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Recommendation</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Grade<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Indications</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Slope of ≥12°<a href=""
                            data-id="citation_reference_95" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_55" class="annotation superscript"
                              style="position: unset;">5</span></a><span data-id="superscript_55"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_96" class="annotation citation_reference resource-reference"
                              style="position: unset;">6</a>,<a href="" data-id="citation_reference_97"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">10</a>,<a href="" data-id="citation_reference_98"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">18</a>,<a href="" data-id="citation_reference_99"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">52</a>,<a href="" data-id="citation_reference_100"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">60</a></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">C</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ATT on weight-bearing
                          lateral radiograph of &gt;5 to 10 mm<span data-id="superscript_56"
                            class="annotation superscript" style="position: unset;">18</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Contraindications</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hyperextension of the
                          knee of &gt;10°<a href="" data-id="citation_reference_101"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_57" class="annotation superscript"
                              style="position: unset;">5</span></a><span data-id="superscript_57"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_102" class="annotation citation_reference resource-reference"
                              style="position: unset;">25</a></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Concurrent varus
                          deformity of &gt;5°<a href="" data-id="citation_reference_103"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_58" class="annotation superscript"
                              style="position: unset;">5</span></a><span data-id="superscript_58"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteoarthritic changes<a
                            href="" data-id="citation_reference_104"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_59" class="annotation superscript"
                              style="position: unset;">5</span></a><span data-id="superscript_59"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_105" class="annotation citation_reference resource-reference"
                              style="position: unset;">18</a></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">According to Wright<a href="" data-id="citation_reference_106"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_60" class="annotation superscript"
                            style="position: unset;">61</span></a><span data-id="superscript_60"
                          class="annotation superscript" style="position: unset;"></span>, grade A indicates good
                        evidence (Level-I studies with consistent findings) for or against recommending intervention;
                        grade B, fair evidence (Level-II or III studies with consistent findings) for or against
                        recommending intervention; grade C, poor-quality evidence (Level-IV or V studies with consistent
                        findings) for or against recommending intervention; and grade I, insufficient or conflicting
                        evidence not allowing a recommendation for or against intervention.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Preoperative
                Planning</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">In deformity correction surgery, determining osteotomy
                  levels traditionally relies on the center of rotation and angulation (CORA) and bisector line
                  methods<a href="" data-id="citation_reference_107"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_61" class="annotation superscript" style="position: unset;">62</span></a>.
                  However, the focus in slope-reducing osteotomies is primarily on the required correction degree,
                  rather than precisely specifying the deformity level during preoperative planning. This lack of
                  precision may be attributed to the absence of a patient-specific morphological description for
                  proximal tibial variations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Initial attempts to assess increased PTS in deformities
                  found moderate correlations between PTS and anterior/posterior metaphyseal inclination but weak
                  correlations with anterior metaphyseal height<a href="" data-id="citation_reference_108"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_62" class="annotation superscript" style="position: unset;">63</span></a>. As
                  there is currently no clear consensus, the choice of osteotomy level should be based on patient
                  factors and surgeon preference. Hinge placement may be another critical factor affecting tibial joint
                  mechanical alignment, as the proximal segment rotates around the hinge point.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The PTS
                Correction Target</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The goals for correcting PTS vary among surgeons, with
                  some advocating for overcorrection<a href="" data-id="citation_reference_109"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_63" class="annotation superscript" style="position: unset;">53</span></a> and
                  others recommending a moderate PTS decrease to 5° to 10°<a href="" data-id="citation_reference_110"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_64" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_64" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_111" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_64" class="annotation superscript"
                      style="position: unset;">52</span></a><span data-id="superscript_64"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_112" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_64" class="annotation superscript"
                      style="position: unset;">64</span></a>. Overcorrection might be undesirable, as a PTS angle of
                  &lt;5° could raise the risk of posterior cruciate ligament (PCL) injuries<a href=""
                    data-id="citation_reference_113" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_65" class="annotation superscript"
                      style="position: unset;">65</span></a>. However, the impact of PTS changes on the native PCL is
                  still uncertain. A recent study established a correlation between PTS change and static ATT on
                  monopedal weight-bearing lateral knee radiographs, with unknown clinical effects. The results showed
                  that a 1° change in PTS corresponded to a 0.5-mm change in ATT, prompting an adjustment in the target
                  PTS correction range from the previous 2° to 5° to an adjusted 4° to 6°<a href=""
                    data-id="citation_reference_114" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">18</span></a>. For instance, if the initial slope is 12°, a correction of
                  6° to 8° should be performed, given the target tibial slope of 4° to 6°.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Level of
                Slope-Reducing Osteotomy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Anterior closing-wedge, slope-reducing osteotomy can be
                  performed at different levels, including supratuberosity, tubercle-reflecting transtuberosity<a
                    href="" data-id="citation_reference_115" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_67" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_67" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_116"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_67" class="annotation superscript" style="position: unset;">52</span></a>, or
                  infratuberosity<a href="" data-id="citation_reference_117"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_68" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_68" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_118" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_68" class="annotation superscript"
                      style="position: unset;">53</span></a><span data-id="superscript_68"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_119" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_68" class="annotation superscript"
                      style="position: unset;">66</span></a> levels (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>). The
                  level of the anterior closing-wedge, slope-reducing osteotomy remains an issue of debate, with
                  reported excellent outcomes for different techniques<a href="" data-id="citation_reference_120"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_69" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_69" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_121" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_69" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_69" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_122"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_69" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_69"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_123" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_69" class="annotation superscript"
                      style="position: unset;">53</span></a>. In the absence of comparative clinical studies, the choice
                  of one technique over another is currently a matter of surgeon preference. Supratuberosity osteotomies
                  can also be performed by employing a biplanar slope-reducing osteotomy (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01352f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&type=jpeg&name=JBJS.23.01352f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_100"
                            class="annotation strong" style="position: unset;">Figs. 5-A through 5-F</span> Preoperative
                          and postoperative images of 3 different patients who underwent a surgical procedure involving
                          slope-reducing osteotomies at different levels. Revision ACLR was performed in the same single
                          stage for all 3 patients. <span data-id="strong_101" class="annotation strong"
                            style="position: unset;">Figs. 5-A and 5-B</span> Supratuberosity-level osteotomy, fixed
                          with 2 metallic staples, in a right knee. Note the multiple femoral-side cortical buttons
                          related to a previous failed ACLR. The preoperative image is shown in Figure 5-A, and the
                          postoperative image is shown in Figure 5-B. <span data-id="strong_102"
                            class="annotation strong" style="position: unset;">Figs. 5-C and 5-D</span>
                          Tubercle-reflecting transtuberosity-level osteotomy, fixed with a contoured locking plate, in
                          a left knee. Additionally, 2 compression screws were placed perpendicular to the
                          tubercle-reflecting osteotomy. The preoperative image is shown in Figure 5-C, and the
                          postoperative image is shown in Figure 5-D. <span data-id="strong_103"
                            class="annotation strong" style="position: unset;">Figs. 5-E and 5-F</span>
                          Infratuberosity-level osteotomy, fixed with a contoured locking plate, in a left knee. The
                          preoperative image is shown in Figure 5-E, and the postoperative image is shown in Figure 5-F.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.01352f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&type=jpeg&name=JBJS.23.01352f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_104"
                            class="annotation strong" style="position: unset;">Figs. 6-A and 6-B</span> Intraoperative
                          photographs of a patient undergoing a biplanar supratuberosity-level, slope-reducing
                          osteotomy. <span data-id="strong_105" class="annotation strong" style="position: unset;">Fig.
                            6-A</span> The initial step of the osteotomy, showing an osteotome in place and a vertical
                          cut parallel to the patellar tendon. The vertical cut is also indicated on the preoperative
                          lateral radiograph in Figure 6-C by the white vertical line. <span data-id="strong_106"
                            class="annotation strong" style="position: unset;">Fig. 6-B</span> The placement of 4
                          Kirschner wires, with 2 on each level of the anterior closing-wedge osteotomy. <span
                            data-id="strong_107" class="annotation strong" style="position: unset;">Fig. 6-C</span> The
                          preoperative lateral knee radiograph showing the anterior closing-wedge planes outlined by
                          dashed white lines. It is important to note that the convergence point of the 4 Kirschner
                          wires is intended to be approximately 1 cm from the PCL insertion. This point is identified on
                          the preoperative lateral knee radiograph by the white dashed circle with a radius of 1 cm.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">A previous retrospective study found that surgeons
                  increasingly preferred infratubercle osteotomy because of its perceived ease, more area to fixate with
                  a plate, and the ability for safer plate fixation, allowing for earlier weight-bearing<a href=""
                    data-id="citation_reference_124" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_70" class="annotation superscript"
                      style="position: unset;">8</span></a>. However, supratubercle osteotomies are criticized for this,
                  but a modeling study suggested that the remaining metaphyseal bone proximal to the supratuberosity
                  level may suffice for fixation area in all patients<a href="" data-id="citation_reference_125"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_71" class="annotation superscript" style="position: unset;">13</span></a>. A
                  case report on 3 patients revealed the importance of a stable construct for the osteotomy, as varus
                  collapse occurred following a supratubercle anterior closing-wedge, slope-reducing osteotomy,
                  emphasizing the impact of individual proximal tibial morphology, bone recovery, and postoperative
                  rehabilitation on postoperative outcomes<a href="" data-id="citation_reference_126"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_72" class="annotation superscript" style="position: unset;">67</span></a>.
                  There were speculative concerns about high distraction stress in the extensor mechanism with oblique
                  osteotomy in the infratuberosity technique<a href="" data-id="citation_reference_127"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_73" class="annotation superscript" style="position: unset;">64</span></a>,
                  but no study has shown pseudarthrosis after an infratuberosity-level osteotomy<a href=""
                    data-id="citation_reference_128" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_74" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_74" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_129"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_74" class="annotation superscript" style="position: unset;">8</span></a>. <a
                    href="" data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a> summarizes the pearls and pitfalls of osteotomy techniques at
                  different levels.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_39" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Pearls and Pitfalls for Different Levels of
                          Slope-Reducing Osteotomies</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 791px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Osteotomy Level</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Pearls and
                          Pitfalls</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Supratuberosity</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Challenging exposure
                          during the procedure<br data-id="break_1" style="position: unset;">• Risk to the patellar
                          tendon during osteotomy<br data-id="break_2" style="position: unset;">• Fixation may be
                          challenging with a shorter proximal bone segment, although careful execution may allow for
                          plate fixation<br data-id="break_3" style="position: unset;">• Potential impact on tibial
                          tunnel position for ACL reconstruction due to the shorter proximal bone segment</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Tubercle-reflecting
                          transtuberosity</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Detachment of the
                          tubercle may impact healing and postoperative rehabilitation<br data-id="break_4"
                            style="position: unset;">• Fewer studies have adopted this technique, resulting in fewer
                          reported patients and outcomes</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Infratuberosity</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Enhanced osteotomy
                          plane exposure<br data-id="break_5" style="position: unset;">• Fixation with a plate is
                          facilitated by a larger proximal bone segment<br data-id="break_6" style="position: unset;">•
                          Potential impact on coronal alignment due to challenges in maintaining perfect parallelism
                          with the joint line<br data-id="break_7" style="position: unset;">• Greater wedge thickness
                          must be removed per degree of correction</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">In slope-reducing osteotomies, varying osteotomy levels
                  result in differences in the thickness of the anterior cortical wedge that must be removed. The
                  variation in thickness depends on proximal tibial width and the specific osteotomy level. Achieving
                  the same angular PTS alteration may require thicker anterior-wedge removal for infratuberosity
                  osteotomies compared with supratuberosity osteotomies. These factors can vary on the basis of
                  individual proximal tibial morphology.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Previous studies have shown varying estimates for the
                  cortical wedge thickness required to achieve a 1° change in the PTS. One study, which employed a
                  tubercle-reflecting, transtuberosity-level, slope-reducing osteotomy, suggested a 1.7-mm anterior
                  resection for a 1° PTS change<a href="" data-id="citation_reference_130"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_75" class="annotation superscript" style="position: unset;">25</span></a>.
                  Others have assumed that 1 mm of anterior resection can result in a 1° PTS change<a href=""
                    data-id="citation_reference_131" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_76" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_76" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_132"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_76" class="annotation superscript"
                      style="position: unset;">66</span></a><span data-id="superscript_76"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_133" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_76" class="annotation superscript"
                      style="position: unset;">68</span></a><span data-id="superscript_76"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_134" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_76" class="annotation superscript"
                      style="position: unset;">69</span></a>. In a simulation study using CT scans, it was found that a
                  1° PTS change would necessitate a 1-mm wedge height for a supratuberosity-level osteotomy and 0.8 mm
                  for a tubercle-reflecting, transtuberosity osteotomy<a href="" data-id="citation_reference_135"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_77" class="annotation superscript" style="position: unset;">70</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">To determine the wedge thickness, preoperative planning
                  should involve analysis of the individual patient’s lateral knee radiograph. Depending on the target
                  correction, a preplanned triangle-shaped wedge is positioned on the lateral radiograph. The width of
                  the anterior portion of this triangle corresponds to the thickness of the wedge to be removed.
                  Notably, in the supratuberosity technique, the required wedge thickness for a 1° correction is
                  approximately 1 mm, whereas it is slightly longer in an infratuberosity osteotomy because of the
                  wedge’s distance from the hinge point (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 7</a>). For
                  tubercle-reflecting transtuberosity osteotomy, wedge thickness may be affected by the thickness of the
                  reflected tubercle. In summary, patient-specific measurements should be performed on the basis of the
                  surgical technique used.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.23.01352f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&type=jpeg&name=JBJS.23.01352f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_43" style="position: unset;">
                        <div class="content" style="position: unset;">Lateral radiograph of the left knee with a PTS of
                          15°, showing the projected wedge thicknesses for supratuberosity-level (white arrows) compared
                          with infratuberosity-level (black arrows), slope-reducing osteotomies. A target postoperative
                          PTS of 5° requires that a 10° reduction in the PTS must be made. The required anterior-wedge
                          resection is higher in the infratuberosity technique (in this patient, 12 mm) compared with
                          the supratuberosity technique (10.2 mm). This corresponds to 1 mm per 1° correction for
                          supratuberosity-level osteotomy and 1.2 mm per 1° correction for infratuberosity-level
                          osteotomy. However, to achieve precise operative correction, the individual patient’s
                          radiograph should be used for preoperative planning and determining the required
                          anterior-wedge removal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">In a recent study on surgical technique, Tensho et al.
                  described a posterior opening-wedge proximal tibial osteotomy aimed at decreasing the PTS<a href=""
                    data-id="citation_reference_136" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_78" class="annotation superscript"
                      style="position: unset;">71</span></a>. However, posterior opening-wedge, slope-reducing
                  osteotomies are uncommon, and there are currently no clinical outcomes reported for this technique.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Biplanar
                Slope-Altering Osteotomies in Complex Deformities</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Some patients may present with multiple risk factors for
                  ACL graft failure, such as combined sagittal and coronal malalignment. In such cases, a single-level,
                  slope-reducing osteotomy might not be adequate to address complex, multifocal deformities. Instead,
                  biplanar single-level or double-level osteotomies, in conjunction with primary or revision ACLR, may
                  be necessary for comprehensive treatment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">An individualized approach, which includes a meticulous
                  analysis of deformities, is essential for successful treatment. Deformities should always be corrected
                  at the center of angulation, which requires detailed preoperative planning. This planning should take
                  into account the deformity’s location, the resultant tibial and femoral knee angles, and the final
                  joint-line convergence angle (JLCA), with a normative value of 0° to 2°<a href=""
                    data-id="citation_reference_137" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_79" class="annotation superscript"
                      style="position: unset;">72</span></a><span data-id="superscript_79"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_138" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_79" class="annotation superscript"
                      style="position: unset;">75</span></a>. Measurements of knee joint angles, including the
                  mechanical medial proximal tibial angle (mMPTA), mechanical lateral distal femoral angle (mLDFA), and
                  the PTS, are essential for accurate and comprehensive preoperative planning. If pathologic torsional
                  deformities of the tibia and femur are suspected during the clinical examination, additional MRI or CT
                  assessments are recommended. The primary aim of osteotomies in patients with revision ACLR and coronal
                  and sagittal malalignment is to bring abnormal mMPTA (normative range, 84° to 90°)<a href=""
                    data-id="citation_reference_139" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_80" class="annotation superscript"
                      style="position: unset;">76</span></a>, mLDFA (normative range, 84° to 90°)<a href=""
                    data-id="citation_reference_140" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_81" class="annotation superscript"
                      style="position: unset;">76</span></a>, and PTS (normative range, 5° to 7°)<a href=""
                    data-id="citation_reference_141" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_82" class="annotation superscript"
                      style="position: unset;">76</span></a> values within or close to their normative ranges.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">Based on the aforementioned osteotomy principles, patients
                  with an increased PTS and extra-articular varus deformity caused by an abnormal mMPTA might require a
                  combined medial opening-wedge, high tibial osteotomy and anterior closing-wedge, slope-reducing
                  osteotomy. This correction can be done in a single-staged procedure, but, in cases in which higher
                  coronal and sagittal corrections (&gt;5°) are necessary, a 2-stage procedure might be required.
                  Conversely, patients with abnormal mLDFA values and an increased PTS may require a double-level
                  osteotomy. This includes femoral correction for the coronal malalignment and a slope-reducing
                  osteotomy for the sagittal deformity (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 8</a>). When
                  all 3 knee joint angles are abnormal, a staged procedure may be necessary. It is advisable to avoid
                  extensive corrections that would result in an abnormal knee joint angle or a correction of normative
                  knee joint angles, as this could lead to the development of new deformities, potentially causing
                  postoperative complications and poor outcomes<a href="" data-id="citation_reference_142"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_83" class="annotation superscript"
                      style="position: unset;">75</span></a><span data-id="superscript_83"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_143" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_83" class="annotation superscript"
                      style="position: unset;">77</span></a><span data-id="superscript_83"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_144" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_83" class="annotation superscript"
                      style="position: unset;">78</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 8</div>
                <div class="image-download" name="JBJS.23.01352f8" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&type=jpeg&name=JBJS.23.01352f8"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_48" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_108"
                            class="annotation strong" style="position: unset;">Figs. 8-A through 8-D</span> Preoperative
                          and postoperative radiographs showing the coronal and sagittal knee angle measurements of a
                          31-year-old woman with genu valgus (6.2°) and 2 previous failed ACLRs of the left knee. <span
                            data-id="strong_109" class="annotation strong" style="position: unset;">Fig. 8-A</span> An
                          anteroposterior full-length lower-extremity radiograph showing that the patient presented with
                          a normative JLCA and MPTA but an abnormal LDFA. <span data-id="strong_110"
                            class="annotation strong" style="position: unset;">Fig. 8-B</span> The patient had an
                          increased anatomical-mechanical angle (AMA) of 6.2°, the angle between the mechanical and
                          anatomical axes of the femur. PTS measurements showed an increased PTS of 16.3° preoperatively
                          in the left knee. <span data-id="strong_111" class="annotation strong"
                            style="position: unset;">Figs. 8-C and 8-D</span> After ACLR with single-staged,
                          double-level, medial closing-wedge distal femoral osteotomy and anterior closing-wedge, high
                          tibial osteotomy, normative AMA, LDFA, JLCA, MPTA, and PTS values were measured.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">When treating patients with complex tibial and femoral
                  deformities, the benefit of correcting each deformity independently and precisely needs to be balanced
                  against the increased risks and comorbidities (e.g., hinge fracture, loss of correction, and malunion)
                  associated with 2 separate interventions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">After the surgical procedure, patients are encouraged to
                  participate in passive and active knee range of motion. Patients are typically non-weight-bearing for
                  at least 3 weeks postoperatively and transition to weight-bearing as tolerated by 6 weeks
                  postoperatively. This postoperative management protocol also applies to additional procedures such as
                  double-level osteotomy. Patient-specific instrumentation for isolated PTS correction has been
                  documented in a technical report<a href="" data-id="citation_reference_145"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_84" class="annotation superscript" style="position: unset;">79</span></a>,
                  but it has not been reported in clinical studies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical
                Outcomes After Slope-Reducing Osteotomy in the Setting of ACLR</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_51" style="position: unset;">
                <div class="content" style="position: unset;">Few clinical studies have investigated subjective and
                  objective outcomes and complications following the combination of ACLR and slope-reducing osteotomy.
                  Detailed information on demographic characteristics, surgery-related factors, and outcome measures in
                  each study can be found in <a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_52" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_52" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Clinical Outcomes Following Primary and Revision
                          ACLR and Concomitant Slope-Reducing Osteotomy<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 495.275px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.292185);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Study</span>
                      </th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Level of Evidence</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Study Method</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Patients</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Follow-up<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="emphasis_84" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Age<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="emphasis_85" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Associated Procedures</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">ACL Graft</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Osteotomy Technique</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">PTS</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Clinical Outcomes, Preop. to Postop.</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Knee Stability Assessment</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Osteoarthritis Progression</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Complications</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preoperative</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Postoperative</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sonnery-Cottet<a href=""
                            data-id="citation_reference_146" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_85" class="annotation superscript"
                              style="position: unset;">52</span></a><span data-id="superscript_85"
                            class="annotation superscript" style="position: unset;"></span> (2014)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective case
                          series</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6 (2 to
                          3.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">26.5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2 ALLR</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Autograft (4 QT, 1
                          BTB)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Transtuberosity</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.6°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.2°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IKDC, 40 to 79; Lysholm,
                          46 to 88; Tegner, to 7.2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ATT: 10.4 mm to 2.8 mm;
                          PS: 0 (4/5), 1+ (1/5)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4 of 5 patients</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dejour<a href=""
                            data-id="citation_reference_147" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_86" class="annotation superscript"
                              style="position: unset;">4</span></a><span data-id="superscript_86"
                            class="annotation superscript" style="position: unset;"></span> (2015)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective case
                          series</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0 (2.0
                          to 7.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">30.3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4 Lemaire LET</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Autograft (8 QT, 1
                          HS)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Supratuberosity</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.2°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.4°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IKDC, 44 to 72; Lysholm,
                          38 to 74</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Lachman: 0 (9/9); PS:
                          0(8/9), 1+ (1/9)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2 of 9 patients</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Akoto<a href=""
                            data-id="citation_reference_148" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_87" class="annotation superscript"
                              style="position: unset;">6</span></a><span data-id="superscript_87"
                            class="annotation superscript" style="position: unset;"></span> (2020)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective case
                          series</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5 (2.0
                          to 4.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">27.8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">20 Lemaire LET
                          (staged)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Autograft (12 QT, 7 HS, 1
                          BTB)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Transtuberosity</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.3°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.9°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IKDC, to 87; Lysholm, 50
                          to 91; Tegner, 3 to 6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ATT: 7.2 to 1.1 mm; PS: 0
                          (20/20)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 postop. bleeding</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Song<a href=""
                            data-id="citation_reference_149" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_88" class="annotation superscript"
                              style="position: unset;">7</span></a><span data-id="superscript_88"
                            class="annotation superscript" style="position: unset;"></span> (2020)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective case
                          series</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8 (2.1
                          to 3.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">29.4</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Autograft (HS)</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Infratuberosity</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.5°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.1°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Lysholm, 47 to 90;
                          Tegner, 5.7 to 7.3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ATT: 13.0 to 1.6 mm; PS:
                          0 (18/18)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rozinthe<a href=""
                            data-id="citation_reference_150" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_89" class="annotation superscript"
                              style="position: unset;">69</span></a><span data-id="superscript_89"
                            class="annotation superscript" style="position: unset;"></span> (2022)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">(Long-term follow-up of
                          Dejour 2015)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">30.3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4 Lemaire LET, 8
                          meniscectomies or meniscal sutures</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Autograft (NA)</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Supratuberosity</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.2°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.4°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IKDC, 44 to 83; Lysholm,
                          38 to 84</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Lachman: 0 (8/8); PS: 0
                          (8/8)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3 of 8 patients</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Weiler<a href=""
                            data-id="citation_reference_151" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_90" class="annotation superscript"
                              style="position: unset;">8</span></a><span data-id="superscript_90"
                            class="annotation superscript" style="position: unset;"></span> (2022)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective case
                          series</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Min.
                          0.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">32.2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 ALL, 1 PLCR, 1 PT
                          repair</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Of 58, 48 infratuberosity
                          and 10 supratuberosity</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.6°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.5°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 infection</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MacLean<a href=""
                            data-id="citation_reference_152" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_91" class="annotation superscript"
                              style="position: unset;">67</span></a><span data-id="superscript_91"
                            class="annotation superscript" style="position: unset;"></span> (2022)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective case
                          series</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">40.3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Autograft (2 HS, 1
                          QT)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mixed</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3 malunion and varus
                          collapse</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Vivacqua<a href=""
                            data-id="citation_reference_153" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_92" class="annotation superscript"
                              style="position: unset;">10</span></a><span data-id="superscript_92"
                            class="annotation superscript" style="position: unset;"></span> (2023)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective case
                          series</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.2 (0.5
                          to 7.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">28.7</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2 MAT, 7 LET</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Allograft (9), autograft
                          (9 QT, 5 BTB)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mixed</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.0°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.0°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ATT: 8.5 to 3.6 mm</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2 failures, 1
                          instability, 6 implant removals</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dejour<a href=""
                            data-id="citation_reference_154" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_93" class="annotation superscript"
                              style="position: unset;">53</span></a><span data-id="superscript_93"
                            class="annotation superscript" style="position: unset;"></span> (2023)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective case
                          series</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4 (2.0
                          to 7.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">25.3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Supratuberosity</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.5°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.9°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IKDC, 46 to 80; Lysholm,
                          56 to 84</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ATT: 8.5 to 0 mm</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0 of 15 patients</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 medial meniscal suture,
                          1 infection</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mayer<a href=""
                            data-id="citation_reference_155" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_94" class="annotation superscript"
                              style="position: unset;">80</span></a><span data-id="superscript_94"
                            class="annotation superscript" style="position: unset;"></span> (2023)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective case
                          series</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">31.6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Infratuberosity</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.6°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.4°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 infection, 8 implant
                          removal</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mabrouk<a href=""
                            data-id="citation_reference_156" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_95" class="annotation superscript"
                              style="position: unset;">60</span></a><span data-id="superscript_95"
                            class="annotation superscript" style="position: unset;"></span> (2023)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective case
                          series</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2 to
                          5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">29.6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Meniscal repair (84%),
                          LET (72%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HS (30%), allograft
                          (28%), BTB (34%), unknown (8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Supratuberosity</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.8°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.2°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IKDC, 39 to 69; Lysholm,
                          52 to 74</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔATT with 134 N: −4 mm,
                          250 N: −3.6 mm</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3 failures, asymptomatic
                          hyperextension in one-third of patients</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ALLR = anterolateral ligament reconstruction, QT = quadriceps tendon,
                        BTB = bone-patellar tendon-bone, PS = pivot shift, NA = not available, LET = lateral
                        extra-articular tenodesis, HS = hamstring, ALL = anterolateral ligament, PLCR = posterolateral
                        corner reconstruction, PT = patellar tendon, MAT = meniscal allograft transplantation, and ΔATT
                        = change in ATT.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean, with or without the range in
                        parentheses, or as the range.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the mean.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Objective
                and Subjective Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Primary
                ACLR</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_53" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_53" style="position: unset;">
                <div class="content" style="position: unset;">Limited clinical studies exist to evaluate outcomes after
                  primary ACLR with concurrent slope-reducing osteotomy. In a study of 18 patients who underwent primary
                  ACLR with PTS of &gt;13°, tibial translation of &gt;10 mm, and concurrent chronic injury to the
                  posterior horn of the medial meniscus, improvements were observed from preoperatively to
                  postoperatively in Lysholm scores, Tegner activity scores, objective International Knee Documentation
                  Committee (IKDC) grades, pivot shift grades, and ATT measured with the KT-1000 arthrometer
                  (MEDmetric). Notably, no ACL graft ruptures occurred within the 2.8-year follow-up period<a href=""
                    data-id="citation_reference_157" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_96" class="annotation superscript"
                      style="position: unset;">7</span></a> (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Revision
                ACLR</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_54" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_54" style="position: unset;">
                <div class="content" style="position: unset;">Multiple retrospective case series have consistently shown
                  improvements in Lysholm score, Tegner activity score, IKDC subjective score, ATT, and pivot shift
                  grade after ACLR with slope-reducing osteotomy<a href="" data-id="citation_reference_158"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_97" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_97" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_159" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_97" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_97" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_160"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_97" class="annotation superscript"
                      style="position: unset;">52</span></a><span data-id="superscript_97"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_161" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_97" class="annotation superscript"
                      style="position: unset;">53</span></a><span data-id="superscript_97"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_162" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_97" class="annotation superscript"
                      style="position: unset;">60</span></a><span data-id="superscript_97"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_163" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_97" class="annotation superscript"
                      style="position: unset;">69</span></a> (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Complications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_55" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_55" style="position: unset;">
                <div class="content" style="position: unset;">Preventing varus collapse postoperatively requires stable
                  osteotomy fixation and patient compliance<a href="" data-id="citation_reference_164"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_98" class="annotation superscript" style="position: unset;">67</span></a>.
                  Some complications reported in studies include coronal plane alignment changes in the MPTA<a href=""
                    data-id="citation_reference_165" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_99" class="annotation superscript"
                      style="position: unset;">80</span></a> and negative static anterior tibial translation after
                  overcorrection in the PTS, whereas normative static ATT has been reported as 2.9 ± 3.2 mm<a href=""
                    data-id="citation_reference_166" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_100" class="annotation superscript"
                      style="position: unset;">18</span></a>. Asymptomatic hyperextension has been observed after
                  supratuberosity-level osteotomies<a href="" data-id="citation_reference_167"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_101" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_101"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_168" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_101" class="annotation superscript"
                      style="position: unset;">60</span></a><span data-id="superscript_101"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_169" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_101" class="annotation superscript"
                      style="position: unset;">69</span></a>. Other potential complications involve ACL graft failure,
                  surgical site infection, implant irritation or removal, hinge fractures, and overcorrections or
                  undercorrections as a result of surgical inaccuracy<a href="" data-id="citation_reference_170"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_102" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_102"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_171" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_102" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_102"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_172" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_102" class="annotation superscript"
                      style="position: unset;">53</span></a><span data-id="superscript_102"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_173" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_102" class="annotation superscript"
                      style="position: unset;">60</span></a><span data-id="superscript_102"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_174" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_102" class="annotation superscript"
                      style="position: unset;">80</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Progression
                or Prevention of Osteoarthritis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_56" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_56" style="position: unset;">
                <div class="content" style="position: unset;">Accelerated cartilage degeneration is a known occurrence
                  in all compartments after ACL graft failure<a href="" data-id="citation_reference_175"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_103" class="annotation superscript" style="position: unset;">81</span></a>.
                  However, it remains uncertain whether a slope-reducing osteotomy has any impact on cartilage
                  degeneration, whether in terms of prevention or further acceleration. Previous studies investigating
                  the progression of osteoarthritis following slope-reducing osteotomy have been limited<a href=""
                    data-id="citation_reference_176" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_104" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_104"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_177" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_104" class="annotation superscript"
                      style="position: unset;">52</span></a><span data-id="superscript_104"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_178" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_104" class="annotation superscript"
                      style="position: unset;">53</span></a><span data-id="superscript_104"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_179" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_104" class="annotation superscript"
                      style="position: unset;">69</span></a>. Reported rates of osteoarthritis progression ranged
                  between 0% and 80% in various studies<a href="" data-id="citation_reference_180"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_105" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_105"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_181" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_105" class="annotation superscript"
                      style="position: unset;">52</span></a><span data-id="superscript_105"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_182" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_105" class="annotation superscript"
                      style="position: unset;">53</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Alterations
                in the Patellofemoral Joint After Slope-Reducing Osteotomy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_57" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_57" style="position: unset;">
                <div class="content" style="position: unset;">The impact of slope-reducing osteotomy on patellofemoral
                  joint kinematics is poorly understood. Limited data exist on changes in patellar height, with
                  uncertain clinical importance. In a study on supratuberosity slope-reducing osteotomy, patellar height
                  remained stable, but the changes were inconsistent between patients: 44% had a decreased
                  Caton-Deschamps index, whereas 33% had an increase in the Caton-Deschamps index<a href=""
                    data-id="citation_reference_183" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_106" class="annotation superscript"
                      style="position: unset;">4</span></a>. In a multicenter study, it was found there was a
                  significant mean increase of 10% in patellar height using the Caton-Deschamps index<a href=""
                    data-id="citation_reference_184" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_107" class="annotation superscript"
                      style="position: unset;">10</span></a>. Another study recommended performing supratuberosity-level
                  osteotomy to preserve the extensor mechanism and avoid tubercle-reflecting osteotomy. The authors
                  suggested that patellar height might remain stable after supratuberosity-level osteotomy, as
                  determined with specific measurements such as a femur-referenced method<a href=""
                    data-id="citation_reference_185" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_108" class="annotation superscript"
                      style="position: unset;">64</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_58" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_58" style="position: unset;">
                <div class="content" style="position: unset;">Supratuberosity-level osteotomies may impact the
                  patellofemoral joint by potentially shortening the patellar tendon distance between the inferior pole
                  of the patella and the proximal tibia. A high tibial osteotomy performed above the level of the tibial
                  tubercle may result in a pseudo patella alta due to the osteotomy location, whereas patellar
                  contraction and retinacular tethering could potentially lead to a pseudo patella baja<a href=""
                    data-id="citation_reference_186" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_109" class="annotation superscript"
                      style="position: unset;">37</span></a><span data-id="superscript_109"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_187" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_109" class="annotation superscript"
                      style="position: unset;">82</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_59" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_59" style="position: unset;">
                <div class="content" style="position: unset;">When evaluating changes in the patellofemoral joint, it is
                  important to consider more than just static axial images. Symptoms such as osteoarthritis progression
                  and anterior knee pain are essential to evaluate but missing in previous studies. The clinical effect
                  of slope-reducing osteotomies on the patellofemoral joint is still largely uncharted, with a paucity
                  of literature on the topic.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Summary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_60" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_60" style="position: unset;">
                <div class="content" style="position: unset;">Understanding the PTS in the context of ACLR is important
                  because of the impact of the PTS on knee biomechanics and surgical outcomes. The variability in PTS
                  measurement methods poses ongoing challenges. Biomechanical insights highlight the influence of the
                  PTS on knee function, but translating biomechanical knowledge into clinical practice requires careful
                  consideration. Although osteotomies hold potential for managing the PTS, concerns about long-term
                  impact on osteoarthritis progression or prevention have persisted. For complex deformities, biplanar
                  osteotomies offer a promising solution. Revision ACLR calls for a holistic, patient-specific approach,
                  especially with respect to slope-reducing osteotomies, including the level and degree of correction.
                  Future research will need to explore the kinematic basis of the PTS and slope-altering surgical
                  procedures and utilize larger-scale clinical outcome trials reporting long-term outcomes for patients.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Salmon LJ</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Heath E</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Akrawi H</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Roe JP</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Linklater J</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Pinczewski LA</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">20-Year outcomes of anterior
                  cruciate ligament reconstruction with hamstring tendon autograft: the catastrophic effect of age and
                  posterior tibial slope</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2018 Mar;46(3):531-43.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=20-Year%20outcomes%20of%20anterior%20cruciate%20ligament%20reconstruction%20with%20hamstring%20tendon%20autograft%3A%20the%20catastrophic%20effect%20of%20age%20and%20posterior%20tibial%20slope&as_occt=title&as_sauthors=%20%22LJ%20Salmon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Duerr R</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Ormseth B</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Adelstein J</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Garrone A</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> DiBartola A</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Kaeding C</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Flanigan D</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Siston R</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Magnussen R</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Elevated posterior tibial slope
                  is associated with anterior cruciate ligament reconstruction failures: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2023 May;39(5):1299-1309.e6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Elevated%20posterior%20tibial%20slope%20is%20associated%20with%20anterior%20cruciate%20ligament%20reconstruction%20failures%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22R%20Duerr%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Pradhan P</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Kaushal SG</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Kocher MS</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Kiapour AM</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Development of anatomic risk
                  factors for ACL injuries: a comparison between ACL-injured knees and matched controls</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2023
                Jul;51(9):2267-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20of%20anatomic%20risk%20factors%20for%20ACL%20injuries%3A%20a%20comparison%20between%20ACL-injured%20knees%20and%20matched%20controls&as_occt=title&as_sauthors=%20%22P%20Pradhan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Dejour D</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Saffarini M</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Demey G</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Baverel L</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Tibial slope correction combined
                  with second revision ACL produces good knee stability and prevents graft rupture</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2015 Oct;23(10):2846-52.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20slope%20correction%20combined%20with%20second%20revision%20ACL%20produces%20good%20knee%20stability%20and%20prevents%20graft%20rupture&as_occt=title&as_sauthors=%20%22D%20Dejour%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Hees T</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Petersen W</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Anterior closing-wedge osteotomy
                  for posterior slope correction</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Arthrosc Tech.</span> 2018 Oct 1;7(11):e1079-87.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20closing-wedge%20osteotomy%20for%20posterior%20slope%20correction&as_occt=title&as_sauthors=%20%22T%20Hees%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Akoto R</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Alm L</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Drenck TC</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Frings J</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Krause M</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Frosch KH</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Slope-correction osteotomy with
                  lateral extra-articular tenodesis and revision anterior cruciate ligament reconstruction is highly
                  effective in treating high-grade anterior knee laxity</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2020
                Dec;48(14):3478-85.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Slope-correction%20osteotomy%20with%20lateral%20extra-articular%20tenodesis%20and%20revision%20anterior%20cruciate%20ligament%20reconstruction%20is%20highly%20effective%20in%20treating%20high-grade%20anterior%20knee%20laxity&as_occt=title&as_sauthors=%20%22R%20Akoto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Song GY</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Ni QK</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Zheng T</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Zhang ZJ</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Feng H</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Zhang H</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Slope-reducing tibial osteotomy
                  combined with primary anterior cruciate ligament reconstruction produces improved knee stability in
                  patients with steep posterior tibial slope, excessive anterior tibial subluxation in extension, and
                  chronic meniscal posterior horn tears</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2020 Dec;48(14):3486-94.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Slope-reducing%20tibial%20osteotomy%20combined%20with%20primary%20anterior%20cruciate%20ligament%20reconstruction%20produces%20improved%20knee%20stability%20in%20patients%20with%20steep%20posterior%20tibial%20slope%2C%20excessive%20anterior%20tibial%20subluxation%20in%20extension%2C%20and%20chronic%20meniscal%20posterior%20horn%20tears&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Weiler A</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Gwinner C</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Wagner M</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Ferner F</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Strobel MJ</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Dickschas J</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Significant slope reduction in
                  ACL deficiency can be achieved both by anterior closing-wedge and medial open-wedge high tibial
                  osteotomies: early experiences in 76 cases</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2022
                Jun;30(6):1967-75.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Significant%20slope%20reduction%20in%20ACL%20deficiency%20can%20be%20achieved%20both%20by%20anterior%20closing-wedge%20and%20medial%20open-wedge%20high%20tibial%20osteotomies%3A%20early%20experiences%20in%2076%20cases&as_occt=title&as_sauthors=%20%22A%20Weiler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Zsidai B</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Özbek EA</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Engler ID</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Kaarre J</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Nazzal EM</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Curley AJ</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Musahl V</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Slope-reducing high tibial
                  osteotomy and over-the-top anterior cruciate ligament reconstruction with Achilles tendon allograft in
                  multiple failed anterior cruciate ligament reconstruction</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Arthrosc Tech.</span> 2022 Oct
                20;11(11):e2021-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Slope-reducing%20high%20tibial%20osteotomy%20and%20over-the-top%20anterior%20cruciate%20ligament%20reconstruction%20with%20Achilles%20tendon%20allograft%20in%20multiple%20failed%20anterior%20cruciate%20ligament%20reconstruction&as_occt=title&as_sauthors=%20%22B%20Zsidai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Vivacqua T</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Thomassen S</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Winkler PW</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Lucidi GA</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Rousseau-Saine A</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Firth AD</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Heard M</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Musahl V</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Getgood AMJ</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Closing-wedge posterior tibial
                  slope-reducing osteotomy in complex revision ACL reconstruction</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span> 2023 Jan
                11;11(1):23259671221144786.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Closing-wedge%20posterior%20tibial%20slope-reducing%20osteotomy%20in%20complex%20revision%20ACL%20reconstruction&as_occt=title&as_sauthors=%20%22T%20Vivacqua%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Yoon KH</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Lee JH</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Kim SG</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Park JY</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Lee HS</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Kim SJ</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Kim YS</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Effect of posterior tibial
                  slopes on graft survival rates at 10 years after primary single-bundle posterior cruciate ligament
                  reconstruction</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2023 Apr;51(5):1194-201.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20posterior%20tibial%20slopes%20on%20graft%20survival%20rates%20at%2010%20years%20after%20primary%20single-bundle%20posterior%20cruciate%20ligament%20reconstruction&as_occt=title&as_sauthors=%20%22KH%20Yoon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Beel W</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Schuster P</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Michalski S</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Mayer P</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Schlumberger M</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Hielscher L</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Richter J</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">High prevalence of increased
                  posterior tibial slope in ACL revision surgery demands a patient-specific approach</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2023 Jul;31(7):2974-82.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High%20prevalence%20of%20increased%20posterior%20tibial%20slope%20in%20ACL%20revision%20surgery%20demands%20a%20patient-specific%20approach&as_occt=title&as_sauthors=%20%22W%20Beel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Demey G</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Giovannetti de Sanctis E</span>,
                <span data-id="annotation_76" class="annotation" style="position: unset;"> Mesnard G</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Müller JH</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Saffarini M</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Dejour DH</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Sufficient metaphyseal bone for
                  wedge removal and fixation hardware during supratuberosity tibial deflexion osteotomy in knees with
                  excessive posterior tibial slope</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2023 Jul;51(8):2091-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sufficient%20metaphyseal%20bone%20for%20wedge%20removal%20and%20fixation%20hardware%20during%20supratuberosity%20tibial%20deflexion%20osteotomy%20in%20knees%20with%20excessive%20posterior%20tibial%20slope&as_occt=title&as_sauthors=%20%22G%20Demey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Weiler A</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Berndt R</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Wagner M</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Scheffler S</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Schatka I</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Gwinner C</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Tibial slope on conventional
                  lateral radiographs in anterior cruciate ligament-injured and intact knees: mean value and
                  outliers</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2023 Jul;51(9):2285-90.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20slope%20on%20conventional%20lateral%20radiographs%20in%20anterior%20cruciate%20ligament-injured%20and%20intact%20knees%3A%20mean%20value%20and%20outliers&as_occt=title&as_sauthors=%20%22A%20Weiler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Martin RK</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Ekås GR</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Benth J</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Kennedy N</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Moatshe G</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Krych AJ</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Engebretsen L</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Change in posterior tibial
                  slope in skeletally immature patients with anterior cruciate ligament injury: a case series with a
                  mean 9 years’ follow-up</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2021 Apr;49(5):1244-50.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Change%20in%20posterior%20tibial%20slope%20in%20skeletally%20immature%20patients%20with%20anterior%20cruciate%20ligament%20injury%3A%20a%20case%20series%20with%20a%20mean%209%20years%E2%80%99%20follow-up&as_occt=title&as_sauthors=%20%22RK%20Martin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Rahnemai-Azar AA</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Yaseen Z</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> van Eck CF</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Irrgang JJ</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Fu FH</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Musahl V</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Increased lateral tibial
                  plateau slope predisposes male college football players to anterior cruciate ligament injury</span>.
                <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2016 Jun 15;98(12):1001-6.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1255950" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Waiwaiole A</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Gurbani A</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Motamedi K</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Seeger L</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Sim MS</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Nwajuaku P</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Hame SL</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Relationship of ACL injury and
                  posterior tibial slope with patient age, sex, and race</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span> 2016 Nov
                29;4(11):2325967116672852.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Relationship%20of%20ACL%20injury%20and%20posterior%20tibial%20slope%20with%20patient%20age%2C%20sex%2C%20and%20race&as_occt=title&as_sauthors=%20%22A%20Waiwaiole%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Dan MJ</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Cance N</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Pineda T</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Demey G</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Dejour DH</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Four to 6° is the target
                  posterior tibial slope after tibial deflection osteotomy according to the knee static anterior tibial
                  translation</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2024 Mar;40(3):846-54.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Four%20to%206%C2%B0%20is%20the%20target%20posterior%20tibial%20slope%20after%20tibial%20deflection%20osteotomy%20according%20to%20the%20knee%20static%20anterior%20tibial%20translation&as_occt=title&as_sauthors=%20%22MJ%20Dan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Bernholt D</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> DePhillipo NN</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Aman ZS</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Samuelsen BT</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Kennedy MI</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> LaPrade RF</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Increased posterior tibial
                  slope results in increased incidence of posterior lateral meniscal root tears in ACL reconstruction
                  patients</span>. <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Knee
                  Surg Sports Traumatol Arthrosc.</span> 2021 Nov;29(11):3883-91.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increased%20posterior%20tibial%20slope%20results%20in%20increased%20incidence%20of%20posterior%20lateral%20meniscal%20root%20tears%20in%20ACL%20reconstruction%20patients&as_occt=title&as_sauthors=%20%22D%20Bernholt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Park YB</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Kim H</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Lee HJ</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Baek SH</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Kwak IY</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Kim SH</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The clinical application of
                  machine learning models for risk analysis of ramp lesions in anterior cruciate ligament
                  injuries</span>. <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2023 Jan;51(1):107-18.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20clinical%20application%20of%20machine%20learning%20models%20for%20risk%20analysis%20of%20ramp%20lesions%20in%20anterior%20cruciate%20ligament%20injuries&as_occt=title&as_sauthors=%20%22YB%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Blanke F</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Kiapour AM</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Haenle M</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Fischer J</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Majewski M</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Vogt S</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Camathias C</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Risk of noncontact anterior
                  cruciate ligament injuries is not associated with slope and concavity of the tibial plateau in
                  recreational alpine skiers: a magnetic resonance imaging-based case-control study of 121
                  patients</span>. <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2016 Jun;44(6):1508-14.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20of%20noncontact%20anterior%20cruciate%20ligament%20injuries%20is%20not%20associated%20with%20slope%20and%20concavity%20of%20the%20tibial%20plateau%20in%20recreational%20alpine%20skiers%3A%20a%20magnetic%20resonance%20imaging-based%20case-control%20study%20of%20121%20patients&as_occt=title&as_sauthors=%20%22F%20Blanke%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> K S</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Chamala T</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Kumar A</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Comparison of anatomical risk
                  factors for noncontact anterior cruciate ligament injury using magnetic resonance imaging</span>.
                <span data-id="emphasis_22" class="annotation emphasis" style="position: unset;">J Clin Orthop
                  Trauma.</span> 2019 Jan-Feb;10(1):143-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20anatomical%20risk%20factors%20for%20noncontact%20anterior%20cruciate%20ligament%20injury%20using%20magnetic%20resonance%20imaging&as_occt=title&as_sauthors=%20%22S%20K%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Hinz M</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Brunner M</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Winkler PW</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Sanchez Carbonel JF</span>,
                <span data-id="annotation_137" class="annotation" style="position: unset;"> Fritsch L</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Vieider RP</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Siebenlist S</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Mehl J</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">The posterior tibial slope is
                  not associated with graft failure and functional outcomes after anatomic primary isolated anterior
                  cruciate ligament reconstruction</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2023 Dec;51(14):3670-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20posterior%20tibial%20slope%20is%20not%20associated%20with%20graft%20failure%20and%20functional%20outcomes%20after%20anatomic%20primary%20isolated%20anterior%20cruciate%20ligament%20reconstruction&as_occt=title&as_sauthors=%20%22M%20Hinz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Bernhardson AS</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Aman ZS</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Dornan GJ</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Kemler BR</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Storaci HW</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Brady AW</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Nakama GY</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> LaPrade RF</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Tibial slope and its effect on
                  force in anterior cruciate ligament grafts: anterior cruciate ligament force increases linearly as
                  posterior tibial slope increases</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2019 Feb;47(2):296-302.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20slope%20and%20its%20effect%20on%20force%20in%20anterior%20cruciate%20ligament%20grafts%3A%20anterior%20cruciate%20ligament%20force%20increases%20linearly%20as%20posterior%20tibial%20slope%20increases&as_occt=title&as_sauthors=%20%22AS%20Bernhardson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> DePhillipo NN</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Kennedy MI</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Dekker TJ</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Aman ZS</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Grantham WJ</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> LaPrade RF</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Anterior closing wedge proximal
                  tibial osteotomy for slope correction in failed ACL reconstructions</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Arthrosc Tech.</span> 2019
                Apr 11;8(5):e451-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20closing%20wedge%20proximal%20tibial%20osteotomy%20for%20slope%20correction%20in%20failed%20ACL%20reconstructions&as_occt=title&as_sauthors=%20%22NN%20DePhillipo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Nerhus TK</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Ekeland A</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Solberg G</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Sivertsen EA</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Madsen JE</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Heir S</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Radiological outcomes in a
                  randomized trial comparing opening wedge and closing wedge techniques of high tibial osteotomy</span>.
                <span data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Knee Surg Sports
                  Traumatol Arthrosc.</span> 2017 Mar;25(3):910-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiological%20outcomes%20in%20a%20randomized%20trial%20comparing%20opening%20wedge%20and%20closing%20wedge%20techniques%20of%20high%20tibial%20osteotomy&as_occt=title&as_sauthors=%20%22TK%20Nerhus%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Abs A</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Micicoi G</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Khakha R</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Escudier JC</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Jacquet C</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Ollivier M</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Clinical and radiological
                  outcomes of double-level osteotomy versus open-wedge high tibial osteotomy for bifocal varus
                  deformity</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Orthop J Sports Med.</span> 2023 Feb 17;11(2):23259671221148458.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20and%20radiological%20outcomes%20of%20double-level%20osteotomy%20versus%20open-wedge%20high%20tibial%20osteotomy%20for%20bifocal%20varus%20deformity&as_occt=title&as_sauthors=%20%22A%20Abs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Dean RS</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> DePhillipo NN</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Chahla J</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Larson CM</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> LaPrade RF</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Posterior tibial slope
                  measurements using the anatomic axis are significantly increased compared with those that use the
                  mechanical axis</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2021 Jan;37(1):243-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior%20tibial%20slope%20measurements%20using%20the%20anatomic%20axis%20are%20significantly%20increased%20compared%20with%20those%20that%20use%20the%20mechanical%20axis&as_occt=title&as_sauthors=%20%22RS%20Dean%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Akamatsu Y</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Sotozawa M</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Kobayashi H</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Kusayama Y</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Kumagai K</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Saito T</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Usefulness of long tibial axis
                  to measure medial tibial slope for opening wedge high tibial osteotomy</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2016 Nov;24(11):3661-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Usefulness%20of%20long%20tibial%20axis%20to%20measure%20medial%20tibial%20slope%20for%20opening%20wedge%20high%20tibial%20osteotomy&as_occt=title&as_sauthors=%20%22Y%20Akamatsu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Weinberg DS</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Williamson DF</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Gebhart JJ</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Knapik DM</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Voos JE</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Differences in medial and
                  lateral posterior tibial slope: an osteological review of 1090 tibiae comparing age, sex, and
                  race</span>. <span data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2017 Jan;45(1):106-13.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Differences%20in%20medial%20and%20lateral%20posterior%20tibial%20slope%3A%20an%20osteological%20review%20of%201090%20tibiae%20comparing%20age%2C%20sex%2C%20and%20race&as_occt=title&as_sauthors=%20%22DS%20Weinberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Faschingbauer M</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Sgroi M</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Juchems M</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Reichel H</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Kappe T</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Can the tibial slope be
                  measured on lateral knee radiographs?</span><span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2014
                Dec;22(12):3163-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Can%20the%20tibial%20slope%20be%20measured%20on%20lateral%20knee%20radiographs%3F&as_occt=title&as_sauthors=%20%22M%20Faschingbauer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Dejour H</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Bonnin M</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Tibial translation after
                  anterior cruciate ligament rupture. Two radiological tests compared</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 1994 Sep;76(5):745-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20translation%20after%20anterior%20cruciate%20ligament%20rupture.%20Two%20radiological%20tests%20compared&as_occt=title&as_sauthors=%20%22H%20Dejour%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Yoo JH</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Chang CB</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Shin KS</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Seong SC</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Kim TK</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Anatomical references to assess
                  the posterior tibial slope in total knee arthroplasty: a comparison of 5 anatomical axes</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2008
                Jun;23(4):586-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anatomical%20references%20to%20assess%20the%20posterior%20tibial%20slope%20in%20total%20knee%20arthroplasty%3A%20a%20comparison%20of%205%20anatomical%20axes&as_occt=title&as_sauthors=%20%22JH%20Yoo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Condello V</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Beaufilis P</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Becker R</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Ahmad SS</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Bonomo M</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Dejour D</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Eriksson K</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Filardo G</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Feucht MJ</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Grassi A</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Wilson A</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Menetrey J</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Pujol N</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Rathcke M</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Seil R</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Strauss MJ</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Tischer T</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Management of anterior cruciate
                  ligament revision in adults: the 2022 ESSKA consensus: part II-surgical strategy</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2023 Nov;31(11):4652-61.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20anterior%20cruciate%20ligament%20revision%20in%20adults%3A%20the%202022%20ESSKA%20consensus%3A%20part%20II-surgical%20strategy&as_occt=title&as_sauthors=%20%22V%20Condello%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Hees T</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Zielke J</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Petersen W</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Effect of anterior tibial
                  bowing on measurement of posterior tibial slope on conventional X-rays</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 2023 Jun;143(6):2959-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20anterior%20tibial%20bowing%20on%20measurement%20of%20posterior%20tibial%20slope%20on%20conventional%20X-rays&as_occt=title&as_sauthors=%20%22T%20Hees%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Alaia MJ</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Kaplan DJ</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Mannino BJ</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Strauss EJ</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Tibial sagittal slope in
                  anterior cruciate ligament injury and treatment</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2021 Nov
                1;29(21):e1045-56.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20sagittal%20slope%20in%20anterior%20cruciate%20ligament%20injury%20and%20treatment&as_occt=title&as_sauthors=%20%22MJ%20Alaia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Paley D.</span><span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Realignment for
                  Mono-Compartment Osteoarthritis of the Knee</span>. In: <span data-id="annotation_220"
                  class="annotation" style="position: unset;"> Paley D</span>, editor. <span data-id="emphasis_37"
                  class="annotation emphasis" style="position: unset;">Principles of Deformity Correction.</span>
                Berlin: Springer; 2002. p 479-507.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Realignment%20for%20Mono-Compartment%20Osteoarthritis%20of%20the%20Knee&as_occt=title&as_sauthors=%20%22D.%20Paley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Chen Y</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Ding J</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Dai S</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Yang J</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Wang M</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Tian T</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Deng X</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Li B</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Cheng G</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Liu J</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Radiographic measurement of the
                  posterior tibial slope in normal Chinese adults: a retrospective cohort study</span>. <span
                  data-id="emphasis_38" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2022 Apr 26;23(1):386.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20measurement%20of%20the%20posterior%20tibial%20slope%20in%20normal%20Chinese%20adults%3A%20a%20retrospective%20cohort%20study&as_occt=title&as_sauthors=%20%22Y%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Hinterwimmer S</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Beitzel K</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Paul J</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Kirchhoff C</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Sauerschnig M</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> von Eisenhart-Rothe R</span>,
                <span data-id="annotation_237" class="annotation" style="position: unset;"> Imhoff AB</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Control of posterior tibial
                  slope and patellar height in open-wedge valgus high tibial osteotomy</span>. <span
                  data-id="emphasis_39" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2011 Apr;39(4):851-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Control%20of%20posterior%20tibial%20slope%20and%20patellar%20height%20in%20open-wedge%20valgus%20high%20tibial%20osteotomy&as_occt=title&as_sauthors=%20%22S%20Hinterwimmer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Utzschneider S</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Goettinger M</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Weber P</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Horng A</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Glaser C</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Jansson V</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Müller PE</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Development and validation of a
                  new method for the radiologic measurement of the tibial slope</span>. <span data-id="emphasis_40"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2011
                Oct;19(10):1643-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20and%20validation%20of%20a%20new%20method%20for%20the%20radiologic%20measurement%20of%20the%20tibial%20slope&as_occt=title&as_sauthors=%20%22S%20Utzschneider%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Winkler PW</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Wagala NN</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Hughes JD</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Lesniak BP</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Musahl V</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">A high tibial slope, allograft
                  use, and poor patient-reported outcome scores are associated with multiple ACL graft failures</span>.
                <span data-id="emphasis_41" class="annotation emphasis" style="position: unset;">Knee Surg Sports
                  Traumatol Arthrosc.</span> 2022 Jan;30(1):139-48.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20high%20tibial%20slope%2C%20allograft%20use%2C%20and%20poor%20patient-reported%20outcome%20scores%20are%20associated%20with%20multiple%20ACL%20graft%20failures&as_occt=title&as_sauthors=%20%22PW%20Winkler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Bixby EC</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Tedesco LJ</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Confino JE</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Mueller JD</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Redler LH</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Effects of malpositioning of
                  the knee on radiographic measurements: the influence of adduction, abduction, and malrotation on
                  measured tibial slope</span>. <span data-id="emphasis_42" class="annotation emphasis"
                  style="position: unset;">Orthop J Sports Med.</span> 2023 Jun 1;11(6):23259671231164670.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20malpositioning%20of%20the%20knee%20on%20radiographic%20measurements%3A%20the%20influence%20of%20adduction%2C%20abduction%2C%20and%20malrotation%20on%20measured%20tibial%20slope&as_occt=title&as_sauthors=%20%22EC%20Bixby%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Jahn R</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Cooper JD</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Juhan T</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Kang HP</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Bolia IK</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Gamradt SC</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Hatch GF</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Weber AE</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Reliability of plain
                  radiographs versus magnetic resonance imaging to measure tibial slope in sports medicine patients: can
                  they be used interchangeably?</span><span data-id="emphasis_43" class="annotation emphasis"
                  style="position: unset;">Orthop J Sports Med.</span> 2021 Oct 7;9(10):23259671211033882.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reliability%20of%20plain%20radiographs%20versus%20magnetic%20resonance%20imaging%20to%20measure%20tibial%20slope%20in%20sports%20medicine%20patients%3A%20can%20they%20be%20used%20interchangeably%3F&as_occt=title&as_sauthors=%20%22R%20Jahn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Hudek R</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Schmutz S</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Regenfelder F</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Fuchs B</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Koch PP</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Novel measurement technique of
                  the tibial slope on conventional MRI</span>. <span data-id="emphasis_44" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2009 Aug;467(8):2066-72.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Novel%20measurement%20technique%20of%20the%20tibial%20slope%20on%20conventional%20MRI&as_occt=title&as_sauthors=%20%22R%20Hudek%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Hashemi J</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Chandrashekar N</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Gill B</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Beynnon BD</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Slauterbeck JR</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Schutt RC Jr</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Mansouri H</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Dabezies E</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">The geometry of the tibial
                  plateau and its influence on the biomechanics of the tibiofemoral joint</span>. <span
                  data-id="emphasis_45" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2008 Dec;90(12):2724-34.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1030583" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Gwinner C</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Fuchs M</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Sentuerk U</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Perka CF</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Walter TC</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Schatka I</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Rogasch JMM</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Assessment of the tibial slope
                  is highly dependent on the type and accuracy of the preceding acquisition</span>. <span
                  data-id="emphasis_46" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 2019 Dec;139(12):1691-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessment%20of%20the%20tibial%20slope%20is%20highly%20dependent%20on%20the%20type%20and%20accuracy%20of%20the%20preceding%20acquisition&as_occt=title&as_sauthors=%20%22C%20Gwinner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Leite CBG</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Merkely G</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Farina EM</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Smith R</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Görtz S</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Hazzard S</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Asnis P</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Lattermann C</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Effect of tibiofemoral rotation
                  angle on graft failure after anterior cruciate ligament reconstruction</span>. <span
                  data-id="emphasis_47" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2023 Jul;51(9):2291-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20tibiofemoral%20rotation%20angle%20on%20graft%20failure%20after%20anterior%20cruciate%20ligament%20reconstruction&as_occt=title&as_sauthors=%20%22CBG%20Leite%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Giffin JR</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Vogrin TM</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Zantop T</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Woo SL</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Harner CD</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Effects of increasing tibial
                  slope on the biomechanics of the knee</span>. <span data-id="emphasis_48" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2004 Mar;32(2):376-82.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20increasing%20tibial%20slope%20on%20the%20biomechanics%20of%20the%20knee&as_occt=title&as_sauthors=%20%22JR%20Giffin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Imhoff FB</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Comer B</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Obopilwe E</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Beitzel K</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Arciero RA</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Mehl JT</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Effect of slope and varus
                  correction high tibial osteotomy in the ACL-deficient and ACL-reconstructed knee on kinematics and ACL
                  graft force: a biomechanical analysis</span>. <span data-id="emphasis_49" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2021 Feb;49(2):410-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20slope%20and%20varus%20correction%20high%20tibial%20osteotomy%20in%20the%20ACL-deficient%20and%20ACL-reconstructed%20knee%20on%20kinematics%20and%20ACL%20graft%20force%3A%20a%20biomechanical%20analysis&as_occt=title&as_sauthors=%20%22FB%20Imhoff%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Winkler PW</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Chan CK</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Lucidi GA</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Polamalu SK</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Wagala NN</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Hughes JD</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Debski RE</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Musahl V</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Increasing the posterior tibial
                  slope lowers in situ forces in the native ACL primarily at deep flexion angles</span>. <span
                  data-id="emphasis_50" class="annotation emphasis" style="position: unset;">J Orthop Res.</span> 2023
                Jul;41(7):1430-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increasing%20the%20posterior%20tibial%20slope%20lowers%20in%20situ%20forces%20in%20the%20native%20ACL%20primarily%20at%20deep%20flexion%20angles&as_occt=title&as_sauthors=%20%22PW%20Winkler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Imhoff FB</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Mehl J</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Comer BJ</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Obopilwe E</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Cote MP</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Feucht MJ</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Wylie JD</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Imhoff AB</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Arciero RA</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Beitzel K</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Slope-reducing tibial osteotomy
                  decreases ACL-graft forces and anterior tibial translation under axial load</span>. <span
                  data-id="emphasis_51" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2019 Oct;27(10):3381-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Slope-reducing%20tibial%20osteotomy%20decreases%20ACL-graft%20forces%20and%20anterior%20tibial%20translation%20under%20axial%20load&as_occt=title&as_sauthors=%20%22FB%20Imhoff%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Sonnery-Cottet B</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Mogos S</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Thaunat M</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Archbold P</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Fayard JM</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Freychet B</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Clechet J</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Chambat P</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Proximal tibial anterior
                  closing wedge osteotomy in repeat revision of anterior cruciate ligament reconstruction</span>. <span
                  data-id="emphasis_52" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2014 Aug;42(8):1873-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Proximal%20tibial%20anterior%20closing%20wedge%20osteotomy%20in%20repeat%20revision%20of%20anterior%20cruciate%20ligament%20reconstruction&as_occt=title&as_sauthors=%20%22B%20Sonnery-Cottet%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Dejour D</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Rozinthe A</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Demey G</span>; <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">ReSurg. First revision ACL
                  reconstruction combined with tibial deflexion osteotomy improves clinical scores at 2 to 7 years
                  follow-up</span>. <span data-id="emphasis_53" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2023
                Oct;31(10):4467-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=ReSurg.%20First%20revision%20ACL%20reconstruction%20combined%20with%20tibial%20deflexion%20osteotomy%20improves%20clinical%20scores%20at%202%20to%207%20years%20follow-up&as_occt=title&as_sauthors=%20%22D%20Dejour%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Agneskirchner JD</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Hurschler C</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Stukenborg-Colsman C</span>,
                <span data-id="annotation_334" class="annotation" style="position: unset;"> Imhoff AB</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Lobenhoffer P</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">Effect of high tibial flexion
                  osteotomy on cartilage pressure and joint kinematics: a biomechanical study in human cadaveric knees.
                  Winner of the AGA-DonJoy Award 2004</span>. <span data-id="emphasis_54" class="annotation emphasis"
                  style="position: unset;">Arch Orthop Trauma Surg.</span> 2004 Nov;124(9):575-84.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20high%20tibial%20flexion%20osteotomy%20on%20cartilage%20pressure%20and%20joint%20kinematics%3A%20a%20biomechanical%20study%20in%20human%20cadaveric%20knees.%20Winner%20of%20the%20AGA-DonJoy%20Award%202004&as_occt=title&as_sauthors=%20%22JD%20Agneskirchner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Samuelsen BT</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Aman ZS</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Kennedy MI</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Dornan GJ</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Storaci HW</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Brady AW</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Turnbull TL</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> LaPrade RF</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Posterior medial meniscus root
                  tears potentiate the effect of increased tibial slope on anterior cruciate ligament graft
                  forces</span>. <span data-id="emphasis_55" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2020 Feb;48(2):334-40.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior%20medial%20meniscus%20root%20tears%20potentiate%20the%20effect%20of%20increased%20tibial%20slope%20on%20anterior%20cruciate%20ligament%20graft%20forces&as_occt=title&as_sauthors=%20%22BT%20Samuelsen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Giffin JR</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Stabile KJ</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Zantop T</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Vogrin TM</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Woo SL</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Harner CD</span>. <span
                  data-id="strong_56" class="annotation strong" style="position: unset;">Importance of tibial slope for
                  stability of the posterior cruciate ligament deficient knee</span>. <span data-id="emphasis_56"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2007
                Sep;35(9):1443-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Importance%20of%20tibial%20slope%20for%20stability%20of%20the%20posterior%20cruciate%20ligament%20deficient%20knee&as_occt=title&as_sauthors=%20%22JR%20Giffin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Yamaguchi KT</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Cheung EC</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Markolf KL</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Boguszewski DV</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Mathew J</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Lama CJ</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> McAllister DR</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Petrigliano FA</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Effects of anterior closing
                  wedge tibial osteotomy on anterior cruciate ligament force and knee kinematics</span>. <span
                  data-id="emphasis_57" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2018 Feb;46(2):370-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20anterior%20closing%20wedge%20tibial%20osteotomy%20on%20anterior%20cruciate%20ligament%20force%20and%20knee%20kinematics&as_occt=title&as_sauthors=%20%22KT%20Yamaguchi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Fening SD</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Kovacic J</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Kambic H</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> McLean S</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Scott J</span>, <span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Miniaci A</span>. <span
                  data-id="strong_58" class="annotation strong" style="position: unset;">The effects of modified
                  posterior tibial slope on anterior cruciate ligament strain and knee kinematics: a human cadaveric
                  study</span>. <span data-id="emphasis_58" class="annotation emphasis" style="position: unset;">J Knee
                  Surg.</span> 2008 Jul;21(3):205-11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effects%20of%20modified%20posterior%20tibial%20slope%20on%20anterior%20cruciate%20ligament%20strain%20and%20knee%20kinematics%3A%20a%20human%20cadaveric%20study&as_occt=title&as_sauthors=%20%22SD%20Fening%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_59" style="position: unset;">
            <div class="content" style="position: unset;">59&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Nelitz M</span>, <span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Seitz AM</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Bauer J</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Reichel H</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Ignatius A</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Dürselen L</span>. <span
                  data-id="strong_59" class="annotation strong" style="position: unset;">Increasing posterior tibial
                  slope does not raise anterior cruciate ligament strain but decreases tibial rotation ability</span>.
                <span data-id="emphasis_59" class="annotation emphasis" style="position: unset;">Clin Biomech (Bristol,
                  Avon).</span> 2013 Mar;28(3):285-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increasing%20posterior%20tibial%20slope%20does%20not%20raise%20anterior%20cruciate%20ligament%20strain%20but%20decreases%20tibial%20rotation%20ability&as_occt=title&as_sauthors=%20%22M%20Nelitz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_60" style="position: unset;">
            <div class="content" style="position: unset;">60&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Mabrouk A</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Kley K</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Jacquet C</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Fayard JM</span>, <span
                  data-id="annotation_374" class="annotation" style="position: unset;"> An JS</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Ollivier M</span>. <span
                  data-id="strong_60" class="annotation strong" style="position: unset;">Outcomes of slope-reducing
                  proximal tibial osteotomy combined with a third anterior cruciate ligament reconstruction procedure
                  with a focus on return to impact sports</span>. <span data-id="emphasis_60"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2023
                Nov;51(13):3454-63.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20slope-reducing%20proximal%20tibial%20osteotomy%20combined%20with%20a%20third%20anterior%20cruciate%20ligament%20reconstruction%20procedure%20with%20a%20focus%20on%20return%20to%20impact%20sports&as_occt=title&as_sauthors=%20%22A%20Mabrouk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_61" style="position: unset;">
            <div class="content" style="position: unset;">61&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Wright JG</span>. <span
                  data-id="strong_61" class="annotation strong" style="position: unset;">Revised grades of
                  recommendation for summaries or reviews of orthopaedic surgical studies</span>. <span
                  data-id="emphasis_61" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2006 May;88(5):1161-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revised%20grades%20of%20recommendation%20for%20summaries%20or%20reviews%20of%20orthopaedic%20surgical%20studies&as_occt=title&as_sauthors=%20%22JG%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_62" style="position: unset;">
            <div class="content" style="position: unset;">62&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Paley D.</span><span
                  data-id="strong_62" class="annotation strong" style="position: unset;">Normal Lower Limb Alignment and
                  Joint Orientation</span>. In: <span data-id="annotation_378" class="annotation"
                  style="position: unset;"> Paley D</span>, editor. <span data-id="emphasis_62"
                  class="annotation emphasis" style="position: unset;">Principles of Deformity Correction.</span>
                Berlin: Springer; 2002. p. 1-18.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Normal%20Lower%20Limb%20Alignment%20and%20Joint%20Orientation&as_occt=title&as_sauthors=%20%22D.%20Paley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_63" style="position: unset;">
            <div class="content" style="position: unset;">63&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Demey G</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Giovannetti de Sanctis E</span>,
                <span data-id="annotation_381" class="annotation" style="position: unset;"> Mesnard G</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Dejour DH</span>; <span
                  data-id="strong_63" class="annotation strong" style="position: unset;">ReSurg. Posterior tibial slope
                  correlated with metaphyseal inclination more than metaphyseal height</span>. <span
                  data-id="emphasis_63" class="annotation emphasis" style="position: unset;">Knee.</span> 2023
                Oct;44:262-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=ReSurg.%20Posterior%20tibial%20slope%20correlated%20with%20metaphyseal%20inclination%20more%20than%20metaphyseal%20height&as_occt=title&as_sauthors=%20%22G%20Demey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_64" style="position: unset;">
            <div class="content" style="position: unset;">64&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Guy S</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Khakha R</span>, <span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Ollivier M</span>. <span
                  data-id="strong_64" class="annotation strong" style="position: unset;">Anterior closing-wedge high
                  tibial osteotomy using patient-specific cutting guide in chronic anterior cruciate ligament-deficient
                  knees</span>. <span data-id="emphasis_64" class="annotation emphasis"
                  style="position: unset;">Arthrosc Tech.</span> 2022 Sep 21;11(9):e1605-12.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20closing-wedge%20high%20tibial%20osteotomy%20using%20patient-specific%20cutting%20guide%20in%20chronic%20anterior%20cruciate%20ligament-deficient%20knees&as_occt=title&as_sauthors=%20%22S%20Guy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_65" style="position: unset;">
            <div class="content" style="position: unset;">65&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Gwinner C</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Weiler A</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Roider M</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Schaefer FM</span>, <span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Jung TM</span>. <span
                  data-id="strong_65" class="annotation strong" style="position: unset;">Tibial slope strongly
                  influences knee stability after posterior cruciate ligament reconstruction: a prospective 5- to
                  15-year follow-up</span>. <span data-id="emphasis_65" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2017 Feb;45(2):355-61.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20slope%20strongly%20influences%20knee%20stability%20after%20posterior%20cruciate%20ligament%20reconstruction%3A%20a%20prospective%205-%20to%2015-year%20follow-up&as_occt=title&as_sauthors=%20%22C%20Gwinner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_66" style="position: unset;">
            <div class="content" style="position: unset;">66&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Queiros CM</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Abreu FG</span>, <span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Moura JL</span>, <span
                  data-id="annotation_394" class="annotation" style="position: unset;"> de Abreu GV</span>, <span
                  data-id="annotation_395" class="annotation" style="position: unset;"> Vieira TD</span>, <span
                  data-id="annotation_396" class="annotation" style="position: unset;"> Helfer L</span>, <span
                  data-id="annotation_397" class="annotation" style="position: unset;"> Sonnery-Cottet B</span>. <span
                  data-id="strong_66" class="annotation strong" style="position: unset;">Anterior closing-wedge
                  osteotomy for posterior slope correction with tibial tubercle preservation</span>. <span
                  data-id="emphasis_66" class="annotation emphasis" style="position: unset;">Arthrosc Tech.</span> 2019
                Sep 19;8(10):e1105-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20closing-wedge%20osteotomy%20for%20posterior%20slope%20correction%20with%20tibial%20tubercle%20preservation&as_occt=title&as_sauthors=%20%22CM%20Queiros%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_67" style="position: unset;">
            <div class="content" style="position: unset;">67&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_398" class="annotation" style="position: unset;"> MacLean IS</span>, <span
                  data-id="annotation_399" class="annotation" style="position: unset;"> Tyndall WA</span>, <span
                  data-id="annotation_400" class="annotation" style="position: unset;"> Schenck RC</span>, <span
                  data-id="annotation_401" class="annotation" style="position: unset;"> Miller MD</span>. <span
                  data-id="strong_67" class="annotation strong" style="position: unset;">Varus collapse following
                  anterior closing wedge proximal tibial osteotomy for ACL revision reconstruction: a case
                  series</span>. <span data-id="emphasis_67" class="annotation emphasis" style="position: unset;">J Exp
                  Orthop.</span> 2022 Oct 4;9(1):100.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Varus%20collapse%20following%20anterior%20closing%20wedge%20proximal%20tibial%20osteotomy%20for%20ACL%20revision%20reconstruction%3A%20a%20case%20series&as_occt=title&as_sauthors=%20%22IS%20MacLean%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_68" style="position: unset;">
            <div class="content" style="position: unset;">68&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_402" class="annotation" style="position: unset;"> Floyd ER</span>, <span
                  data-id="annotation_403" class="annotation" style="position: unset;"> Carlson GB</span>, <span
                  data-id="annotation_404" class="annotation" style="position: unset;"> Monson J</span>, <span
                  data-id="annotation_405" class="annotation" style="position: unset;"> LaPrade RF</span>. <span
                  data-id="strong_68" class="annotation strong" style="position: unset;">Tibial tubercle preserving
                  anterior closing wedge proximal tibial osteotomy and ACL tunnel bone grafting for increased posterior
                  tibial slope in failed ACL reconstructions</span>. <span data-id="emphasis_68"
                  class="annotation emphasis" style="position: unset;">Arthrosc Tech.</span> 2021 Sep
                8;10(10):e2221-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20tubercle%20preserving%20anterior%20closing%20wedge%20proximal%20tibial%20osteotomy%20and%20ACL%20tunnel%20bone%20grafting%20for%20increased%20posterior%20tibial%20slope%20in%20failed%20ACL%20reconstructions&as_occt=title&as_sauthors=%20%22ER%20Floyd%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_69" style="position: unset;">
            <div class="content" style="position: unset;">69&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_406" class="annotation" style="position: unset;"> Rozinthe A</span>, <span
                  data-id="annotation_407" class="annotation" style="position: unset;"> van Rooij F</span>, <span
                  data-id="annotation_408" class="annotation" style="position: unset;"> Demey G</span>, <span
                  data-id="annotation_409" class="annotation" style="position: unset;"> Saffarini M</span>, <span
                  data-id="annotation_410" class="annotation" style="position: unset;"> Dejour D</span>. <span
                  data-id="strong_69" class="annotation strong" style="position: unset;">Tibial slope correction
                  combined with second revision ACLR grants good clinical outcomes and prevents graft rupture at
                  7-15-year follow-up</span>. <span data-id="emphasis_69" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2022
                Jul;30(7):2336-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20slope%20correction%20combined%20with%20second%20revision%20ACLR%20grants%20good%20clinical%20outcomes%20and%20prevents%20graft%20rupture%20at%207-15-year%20follow-up&as_occt=title&as_sauthors=%20%22A%20Rozinthe%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_70" style="position: unset;">
            <div class="content" style="position: unset;">70&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_411" class="annotation" style="position: unset;"> Quinn CA</span>, <span
                  data-id="annotation_412" class="annotation" style="position: unset;"> Miller MD</span>, <span
                  data-id="annotation_413" class="annotation" style="position: unset;"> Turk RD</span>, <span
                  data-id="annotation_414" class="annotation" style="position: unset;"> Lewis DC</span>, <span
                  data-id="annotation_415" class="annotation" style="position: unset;"> Gaskin CM</span>, <span
                  data-id="annotation_416" class="annotation" style="position: unset;"> Werner BC</span>. <span
                  data-id="strong_70" class="annotation strong" style="position: unset;">Determining the ratio of wedge
                  height to degree of correction for anterior tibial closing wedge osteotomies for excessive posterior
                  tibial slope</span>. <span data-id="emphasis_70" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2021 Nov;49(13):3519-27.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Determining%20the%20ratio%20of%20wedge%20height%20to%20degree%20of%20correction%20for%20anterior%20tibial%20closing%20wedge%20osteotomies%20for%20excessive%20posterior%20tibial%20slope&as_occt=title&as_sauthors=%20%22CA%20Quinn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_71" style="position: unset;">
            <div class="content" style="position: unset;">71&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_417" class="annotation" style="position: unset;"> Tensho K</span>, <span
                  data-id="annotation_418" class="annotation" style="position: unset;"> Kumaki D</span>, <span
                  data-id="annotation_419" class="annotation" style="position: unset;"> Yoshida K</span>, <span
                  data-id="annotation_420" class="annotation" style="position: unset;"> Shimodaira H</span>, <span
                  data-id="annotation_421" class="annotation" style="position: unset;"> Horiuchi H</span>, <span
                  data-id="annotation_422" class="annotation" style="position: unset;"> Takahashi J</span>. <span
                  data-id="strong_71" class="annotation strong" style="position: unset;">Posterior opening-wedge
                  osteotomy for posterior tibial slope correction of failed anterior cruciate ligament
                  reconstruction</span>. <span data-id="emphasis_71" class="annotation emphasis"
                  style="position: unset;">Arthrosc Tech.</span> 2023 Nov 20;12(12):e2303-11.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior%20opening-wedge%20osteotomy%20for%20posterior%20tibial%20slope%20correction%20of%20failed%20anterior%20cruciate%20ligament%20reconstruction&as_occt=title&as_sauthors=%20%22K%20Tensho%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_72" style="position: unset;">
            <div class="content" style="position: unset;">72&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_423" class="annotation" style="position: unset;"> Paley D</span>, <span
                  data-id="annotation_424" class="annotation" style="position: unset;"> Herzenberg JE</span>, <span
                  data-id="annotation_425" class="annotation" style="position: unset;"> Tetsworth K</span>, <span
                  data-id="annotation_426" class="annotation" style="position: unset;"> McKie J</span>, <span
                  data-id="annotation_427" class="annotation" style="position: unset;"> Bhave A</span>. <span
                  data-id="strong_72" class="annotation strong" style="position: unset;">Deformity planning for frontal
                  and sagittal plane corrective osteotomies</span>. <span data-id="emphasis_72"
                  class="annotation emphasis" style="position: unset;">Orthop Clin North Am.</span> 1994
                Jul;25(3):425-65.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Deformity%20planning%20for%20frontal%20and%20sagittal%20plane%20corrective%20osteotomies&as_occt=title&as_sauthors=%20%22D%20Paley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_73" style="position: unset;">
            <div class="content" style="position: unset;">73&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_428" class="annotation" style="position: unset;"> Bellemans J</span>, <span
                  data-id="annotation_429" class="annotation" style="position: unset;"> Colyn W</span>, <span
                  data-id="annotation_430" class="annotation" style="position: unset;"> Vandenneucker H</span>, <span
                  data-id="annotation_431" class="annotation" style="position: unset;"> Victor J</span>. <span
                  data-id="strong_73" class="annotation strong" style="position: unset;">The Chitranjan Ranawat award:
                  is neutral mechanical alignment normal for all patients? The concept of constitutional varus</span>.
                <span data-id="emphasis_73" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2012 Jan;470(1):45-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Chitranjan%20Ranawat%20award%3A%20is%20neutral%20mechanical%20alignment%20normal%20for%20all%20patients%3F%20The%20concept%20of%20constitutional%20varus&as_occt=title&as_sauthors=%20%22J%20Bellemans%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_74" style="position: unset;">
            <div class="content" style="position: unset;">74&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_432" class="annotation" style="position: unset;"> Eberbach H</span>, <span
                  data-id="annotation_433" class="annotation" style="position: unset;"> Mehl J</span>, <span
                  data-id="annotation_434" class="annotation" style="position: unset;"> Feucht MJ</span>, <span
                  data-id="annotation_435" class="annotation" style="position: unset;"> Bode G</span>, <span
                  data-id="annotation_436" class="annotation" style="position: unset;"> Südkamp NP</span>, <span
                  data-id="annotation_437" class="annotation" style="position: unset;"> Niemeyer P</span>. <span
                  data-id="strong_74" class="annotation strong" style="position: unset;">Geometry of the valgus knee:
                  contradicting the dogma of a femoral-based deformity</span>. <span data-id="emphasis_74"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2017
                Mar;45(4):909-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Geometry%20of%20the%20valgus%20knee%3A%20contradicting%20the%20dogma%20of%20a%20femoral-based%20deformity&as_occt=title&as_sauthors=%20%22H%20Eberbach%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_75" style="position: unset;">
            <div class="content" style="position: unset;">75&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_438" class="annotation" style="position: unset;"> Feucht MJ</span>, <span
                  data-id="annotation_439" class="annotation" style="position: unset;"> Winkler PW</span>, <span
                  data-id="annotation_440" class="annotation" style="position: unset;"> Mehl J</span>, <span
                  data-id="annotation_441" class="annotation" style="position: unset;"> Bode G</span>, <span
                  data-id="annotation_442" class="annotation" style="position: unset;"> Forkel P</span>, <span
                  data-id="annotation_443" class="annotation" style="position: unset;"> Imhoff AB</span>, <span
                  data-id="annotation_444" class="annotation" style="position: unset;"> Lutz PM</span>. <span
                  data-id="strong_75" class="annotation strong" style="position: unset;">Isolated high tibial osteotomy
                  is appropriate in less than two-thirds of varus knees if excessive overcorrection of the medial
                  proximal tibial angle should be avoided</span>. <span data-id="emphasis_75"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2021
                Oct;29(10):3299-309.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Isolated%20high%20tibial%20osteotomy%20is%20appropriate%20in%20less%20than%20two-thirds%20of%20varus%20knees%20if%20excessive%20overcorrection%20of%20the%20medial%20proximal%20tibial%20angle%20should%20be%20avoided&as_occt=title&as_sauthors=%20%22MJ%20Feucht%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_76" style="position: unset;">
            <div class="content" style="position: unset;">76&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_445" class="annotation" style="position: unset;"> Ferrera A</span>, <span
                  data-id="annotation_446" class="annotation" style="position: unset;"> Menetrey J</span>. <span
                  data-id="strong_76" class="annotation strong" style="position: unset;">Optimizing indications and
                  technique in osteotomies around the knee</span>. <span data-id="emphasis_76"
                  class="annotation emphasis" style="position: unset;">EFORT Open Rev.</span> 2022 Jun
                8;7(6):396-403.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Optimizing%20indications%20and%20technique%20in%20osteotomies%20around%20the%20knee&as_occt=title&as_sauthors=%20%22A%20Ferrera%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_77" style="position: unset;">
            <div class="content" style="position: unset;">77&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_447" class="annotation" style="position: unset;"> Lee SJ</span>, <span
                  data-id="annotation_448" class="annotation" style="position: unset;"> Kim JH</span>, <span
                  data-id="annotation_449" class="annotation" style="position: unset;"> Choi W</span>. <span
                  data-id="strong_77" class="annotation strong" style="position: unset;">Factors related to the early
                  outcome of medial open wedge high tibial osteotomy: coronal limb alignment affects more than cartilage
                  degeneration state</span>. <span data-id="emphasis_77" class="annotation emphasis"
                  style="position: unset;">Arch Orthop Trauma Surg.</span> 2021 Aug;141(8):1339-48.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20related%20to%20the%20early%20outcome%20of%20medial%20open%20wedge%20high%20tibial%20osteotomy%3A%20coronal%20limb%20alignment%20affects%20more%20than%20cartilage%20degeneration%20state&as_occt=title&as_sauthors=%20%22SJ%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_78" style="position: unset;">
            <div class="content" style="position: unset;">78&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_450" class="annotation" style="position: unset;"> El-Azab HM</span>, <span
                  data-id="annotation_451" class="annotation" style="position: unset;"> Morgenstern M</span>, <span
                  data-id="annotation_452" class="annotation" style="position: unset;"> Ahrens P</span>, <span
                  data-id="annotation_453" class="annotation" style="position: unset;"> Schuster T</span>, <span
                  data-id="annotation_454" class="annotation" style="position: unset;"> Imhoff AB</span>, <span
                  data-id="annotation_455" class="annotation" style="position: unset;"> Lorenz SG</span>. <span
                  data-id="strong_78" class="annotation strong" style="position: unset;">Limb alignment after open-wedge
                  high tibial osteotomy and its effect on the clinical outcome</span>. <span data-id="emphasis_78"
                  class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2011 Oct
                5;34(10):e622-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Limb%20alignment%20after%20open-wedge%20high%20tibial%20osteotomy%20and%20its%20effect%20on%20the%20clinical%20outcome&as_occt=title&as_sauthors=%20%22HM%20El-Azab%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_79" style="position: unset;">
            <div class="content" style="position: unset;">79&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_456" class="annotation" style="position: unset;"> Ganokroj P</span>, <span
                  data-id="annotation_457" class="annotation" style="position: unset;"> Peebles AM</span>, <span
                  data-id="annotation_458" class="annotation" style="position: unset;"> Mologne MS</span>, <span
                  data-id="annotation_459" class="annotation" style="position: unset;"> Foster MJ</span>, <span
                  data-id="annotation_460" class="annotation" style="position: unset;"> Provencher MT</span>. <span
                  data-id="strong_79" class="annotation strong" style="position: unset;">Anterior closing-wedge high
                  tibial slope-correcting osteotomy using patient-specific preoperative planning software for failed
                  anterior cruciate ligament reconstruction</span>. <span data-id="emphasis_79"
                  class="annotation emphasis" style="position: unset;">Arthrosc Tech.</span> 2022 Oct
                20;11(11):e1989-95.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20closing-wedge%20high%20tibial%20slope-correcting%20osteotomy%20using%20patient-specific%20preoperative%20planning%20software%20for%20failed%20anterior%20cruciate%20ligament%20reconstruction&as_occt=title&as_sauthors=%20%22P%20Ganokroj%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_80" style="position: unset;">
            <div class="content" style="position: unset;">80&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_461" class="annotation" style="position: unset;"> Mayer P</span>, <span
                  data-id="annotation_462" class="annotation" style="position: unset;"> Schuster P</span>, <span
                  data-id="annotation_463" class="annotation" style="position: unset;"> Schlumberger M</span>, <span
                  data-id="annotation_464" class="annotation" style="position: unset;"> Michalski S</span>, <span
                  data-id="annotation_465" class="annotation" style="position: unset;"> Geßlein M</span>, <span
                  data-id="annotation_466" class="annotation" style="position: unset;"> Beel W</span>, <span
                  data-id="annotation_467" class="annotation" style="position: unset;"> Immendörfer M</span>, <span
                  data-id="annotation_468" class="annotation" style="position: unset;"> Richter J</span>. <span
                  data-id="strong_80" class="annotation strong" style="position: unset;">Effect of anterior tibial
                  closing wedge osteotomy on coronal tibial alignment in relation to preoperative medial proximal tibial
                  angle and wedge height</span>. <span data-id="emphasis_80" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2023 Aug;51(10):2567-73.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20anterior%20tibial%20closing%20wedge%20osteotomy%20on%20coronal%20tibial%20alignment%20in%20relation%20to%20preoperative%20medial%20proximal%20tibial%20angle%20and%20wedge%20height&as_occt=title&as_sauthors=%20%22P%20Mayer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_81" style="position: unset;">
            <div class="content" style="position: unset;">81&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_469" class="annotation" style="position: unset;"> Andrä K</span>, <span
                  data-id="annotation_470" class="annotation" style="position: unset;"> Prill R</span>, <span
                  data-id="annotation_471" class="annotation" style="position: unset;"> Kayaalp E</span>, <span
                  data-id="annotation_472" class="annotation" style="position: unset;"> Irlenbusch L</span>, <span
                  data-id="annotation_473" class="annotation" style="position: unset;"> Liesaus E</span>, <span
                  data-id="annotation_474" class="annotation" style="position: unset;"> Trommer T</span>, <span
                  data-id="annotation_475" class="annotation" style="position: unset;"> Ullmann P</span>, <span
                  data-id="annotation_476" class="annotation" style="position: unset;"> Becker R</span>. <span
                  data-id="strong_81" class="annotation strong" style="position: unset;">Increase in cartilage
                  degeneration in all knee compartments after failed ACL reconstruction at 4 years of follow-up</span>.
                <span data-id="emphasis_81" class="annotation emphasis" style="position: unset;">J Orthop
                  Traumatol.</span> 2021 Dec 16;22(1):54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increase%20in%20cartilage%20degeneration%20in%20all%20knee%20compartments%20after%20failed%20ACL%20reconstruction%20at%204%20years%20of%20follow-up&as_occt=title&as_sauthors=%20%22K%20Andr%C3%A4%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_82" style="position: unset;">
            <div class="content" style="position: unset;">82&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_477" class="annotation" style="position: unset;"> Tigani D</span>, <span
                  data-id="annotation_478" class="annotation" style="position: unset;"> Ferrari D</span>, <span
                  data-id="annotation_479" class="annotation" style="position: unset;"> Trentani P</span>, <span
                  data-id="annotation_480" class="annotation" style="position: unset;"> Barbanti-Brodano G</span>, <span
                  data-id="annotation_481" class="annotation" style="position: unset;"> Trentani F</span>. <span
                  data-id="strong_82" class="annotation strong" style="position: unset;">Patellar height after high
                  tibial osteotomy</span>. <span data-id="emphasis_82" class="annotation emphasis"
                  style="position: unset;">Int Orthop.</span> 2001;24(6):331-4.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patellar%20height%20after%20high%20tibial%20osteotomy&as_occt=title&as_sauthors=%20%22D%20Tigani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Current Concepts Review</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">September 4, 2024;
                      106 (17): 1615</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01352</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 25, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_83"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedic Surgery, UPMC Freddie Fu Sports Medicine Center, University of
                            Pittsburgh, Pittsburgh, Pennsylvania</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&type=pdf&name=JBJS.23.01352.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&type=pdf&name=JBJS.23.01352.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_83"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_84" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I124" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I124</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=9c8dd6d6-96c2-4c7a-9651-cfc8bdeba763&type=zip&name=JBJS.23.01352.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mahmut Enes Kayaalp, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:mek@mek.md" class="" style="position: unset;">mek@mek.md</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9545-7454" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9545-7454</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Philipp Winkler, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3997-1010" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3997-1010</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Balint Zsidai, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span><span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5697-6577" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5697-6577</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Gian Andrea Lucidi, MD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3065-9212" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3065-9212</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Armin Runer, MD<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9901-6552" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9901-6552</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ariana Lott, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8341-4000" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8341-4000</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jonathan D. Hughes, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1298-7514" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1298-7514</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Volker Musahl, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8881-6212" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8881-6212</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, UPMC Freddie Fu Sports Medicine Center,
                University of Pittsburgh, Pittsburgh, Pennsylvania</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedics and Traumatology, Istanbul Kartal Training and
                Research Hospital, Istanbul, Turkey</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department for Orthopaedics and Traumatology, Kepler University Hospital,
                Johannes Kepler University Linz, Linz, Austria</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopaedics, Institute of Clinical Sciences, Sahlgrenska
                Academy, University of Gothenburg, Gothenburg, Sweden</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Sahlgrenska Sports Medicine Center, Gothenburg, Sweden</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Biomedical and Neuromotor Sciences, University of Bologna,
                Bologna, Italy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Department of Sports Orthopaedics, Klinikum rechts der Isar Haus, Technical
                University of Munich, Munich, Germany</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 46553.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
