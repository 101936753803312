/* -----------------Globals--------------- */
import { PracticeTestsContainer } from "@features/dashboard";
import { isWeb } from "@helpers/platform";
import DocumentTitle from "components/DocumentTitle";
import { Redirect, Stack } from "expo-router";

/* -----------------Child components--------------- */

const DrawerPracticeTestsRoute = () => {
	if (!isWeb) {
		return <Redirect href={"/(protected)/study-pack"} />;
	}
	return (
		<>
			<Stack.Screen
				options={{
					header: () => null,
				}}
			/>

			<DocumentTitle title="Practice Tests" />
			<PracticeTestsContainer />
		</>
	);
};

export default DrawerPracticeTestsRoute;
