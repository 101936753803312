import type { Article } from "../../../../../types";

export const Article505: Article = {
	id: 505,
	rsuiteId: "aaac54e3-9c37-4708-a259-bd51942f0306",
	type: "commentary and perspective",
	title:
		"Cementless Oxford Unicompartmental Knee Replacements: More Data, More Questions",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/505.png",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset">
<div class="article lens-article" style="position: unset" data-context="toc">
<div class="panel content document width100" style="position: unset">
<div class="surface resource-view content" style="position: unset">
  <div class="nodes" style="padding-left: 0px; position: unset">
    <div
      class="content-node cover"
      data-id="cover"
      style="padding-top: 30px; position: unset"
    >
      <div class="content" style="position: unset">
        <div class="text title" style="position: unset">
          Cementless Oxford Unicompartmental Knee Replacements: More Data,
          More Questions
        </div>
        <div class="text subtitle" style="position: unset">
          Commentary on an article by Hasan R. Mohammad, MRCS, MRes(Dist),
          DPhil(Oxon), et al.: “A Matched Comparison of Implant and
          Functional Outcomes of Cemented and Cementless Unicompartmental
          Knee Replacements. A Study from the National Registry for
          England, Wales, Northern Ireland and the Isle of Man and the
          Hospital Episode Statistics Patient Reported Outcome Measures
          Database”
        </div>
        <div class="authors" style="position: unset">
          <div
            class="content-node text"
            data-id="text_contributor_1_reference"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <a
                href=""
                data-id="contributor_reference_1"
                class="annotation contributor_reference resource-reference"
                style="position: unset"
                >Aaron G. Chen, MD, MSc</a
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node text"
            data-id="text_contributor_2_reference"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <a
                href=""
                data-id="contributor_reference_2"
                class="annotation contributor_reference resource-reference"
                style="position: unset"
                >Michael S. Reich, MD</a
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="heading_4"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset">Commentary</span>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_3"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_3"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Unicompartmental knee replacement (UKR) is recognized as a
            valuable procedure for patients with isolated,
            single-compartment knee arthritis. The proposed benefits of
            the less-invasive UKR over a total knee replacement include
            decreased mortality, decreased length of stay, fewer
            complications, and improved patient-reported functional
            outcome measures<a
              href=""
              data-id="citation_reference_1"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_1"
                class="annotation superscript"
                style="position: unset"
                >1</span
              ></a
            >. As the popularity of UKR rises, interest in improving the
            durability of UKR implants has gained traction. One strategy
            has been to utilize cementless implants to allow for biologic
            osseointegration and thus improved fixation compared with that
            of traditional cemented implants. Previous research has shown
            that cementless UKRs have decreased rates of radiolucent lines
            and aseptic loosening compared with cemented UKRs<a
              href=""
              data-id="citation_reference_2"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_2"
                class="annotation superscript"
                style="position: unset"
                >2</span
              ></a
            >.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_4"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_4"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            In the present study, Mohammad et al. sought to further
            explore the impact of cementless fixation in UKRs while
            investigating related functional patient-reported outcome
            measures (PROMs). They utilized data from the National Joint
            Registry for England, Wales, Northern Ireland and the Isle of
            Man (NJR) and the Hospital Episode Statistics Patient Reported
            Outcome Measures (HES-PROMs) database to identify and compare
            a matched cohort of 3,453 cementless and 3,453 cemented Oxford
            (Zimmer Biomet) mobile-bearing medial UKRs. This study is an
            excellent example of the use of both “big data” from
            registries and propensity score matching to answer questions
            that would simply not be feasible to investigate by means of a
            prospective randomized controlled trial. The authors
            demonstrated improved 10-year cumulative implant survival
            (93.0% versus 91.3%) and improved PROMs (as measured with use
            of the Oxford Knee Score [OKS] and the EuroQol-5 Dimension
            index [EQ-5D]) in favor of the cementless version of the
            implant. Furthermore, they found significantly lower rates of
            both aseptic loosening (0.35% versus 1.10%; p &lt; 0.001) and
            osteoarthritis progression (0.72% versus 1.25%; p = 0.03) in
            the cementless group. Rates of periprosthetic fracture trended
            higher in the cementless group (0.23% versus 0.06%), but this
            difference did not reach significance (p = 0.06).
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_5"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_5"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            The subgroup analysis in this study is also especially
            informative. Improved survivorship with cementless implants
            was noted with UKRs performed by high-volume (≥30 UKRs per
            year), medium-volume (10 to &lt;30 UKRs per year), and
            low-volume (&lt;10 UKRs per year) surgeons, although this only
            reached significance for the high-volume surgeons. Among UKRs
            performed by medium or high-volume surgeons, greater
            improvement in the postoperative OKS was demonstrated with
            cementless fixation. Among UKRs performed by high-volume
            surgeons, higher postoperative EQ-5D scores were shown with
            cementless fixation.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_6"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_6"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            These findings beg the question: are higher-volume surgeons
            performing a more technically sound procedure than
            lower-volume surgeons? In other words, is it when these
            surgeons use the cementless Oxford UKR that we see the true
            potential of the implant and procedure, with improved
            survivorship and improved functional outcomes? Additionally,
            does the cementless implant, and by extension, the biologic
            fixation, further enhance the more “natural feel” of the
            implant? The lack of statistical differences in survivorship
            and in PROMs between cementless and cemented UKRs performed by
            low-volume surgeons may reflect the smaller sample size for
            this subgroup, or it may reflect the absence of a difference
            in surgeons who perform fewer procedures. The relationship
            between surgical volume and outcomes in UKR is certainly
            complex, and one of the limitations of registry studies is the
            challenge of identifying the many factors, including
            radiographic alignment, use of technology (e.g., navigation or
            robotics), and patient factors, that may influence outcomes
            and survivorship in UKR<a
              href=""
              data-id="citation_reference_3"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_3"
                class="annotation superscript"
                style="position: unset"
                >3</span
              ></a
            ><span
              data-id="superscript_3"
              class="annotation superscript"
              style="position: unset"
              >,</span
            ><a
              href=""
              data-id="citation_reference_4"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_3"
                class="annotation superscript"
                style="position: unset"
                >4</span
              ></a
            >. Other study designs and data sources will need to be
            utilized to sift out possible confounders.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_7"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_7"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Other questions remain as well. Will other joint registries
            demonstrate similar results? Will these findings hold up over
            longer-term follow-up? Can the superiority of the cementless
            version of the Oxford UKR implant be replicated with other
            implants? As noted by the authors, there are data to suggest
            otherwise, and—as is commonly a disclaimer in implant-related
            research—one must be cautious when attempting to generalize
            the results. Regardless, we commend Mohammad et al. on their
            excellent work, and future research will need to corroborate
            and complement this study to help answer these questions and
            those yet to be asked.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="undefined_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset">References</span>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        1&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_1"
            class="annotation"
            style="position: unset"
          >
            Liddle AD</span
          >,
          <span
            data-id="annotation_2"
            class="annotation"
            style="position: unset"
          >
            Judge A</span
          >,
          <span
            data-id="annotation_3"
            class="annotation"
            style="position: unset"
          >
            Pandit H</span
          >,
          <span
            data-id="annotation_4"
            class="annotation"
            style="position: unset"
          >
            Murray DW</span
          >.
          <span
            data-id="strong_1"
            class="annotation strong"
            style="position: unset"
            >Adverse outcomes after total and unicompartmental knee
            replacement in 101,330 matched patients: a study of data from
            the National Joint Registry for England and Wales</span
          >.
          <span
            data-id="emphasis_1"
            class="annotation emphasis"
            style="position: unset"
            >Lancet.</span
          >
          2014 Oct 18;384(9952):1437-1-2.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adverse%20outcomes%20after%20total%20and%20unicompartmental%20knee%20replacement%20in%20101%2C330%20matched%20patients%3A%20a%20study%20of%20data%20from%20the%20National%20Joint%20Registry%20for%20England%20and%20Wales&as_occt=title&as_sauthors=%20%22AD%20Liddle%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_2"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        2&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_5"
            class="annotation"
            style="position: unset"
          >
            Pandit H</span
          >,
          <span
            data-id="annotation_6"
            class="annotation"
            style="position: unset"
          >
            Liddle AD</span
          >,
          <span
            data-id="annotation_7"
            class="annotation"
            style="position: unset"
          >
            Kendrick BJL</span
          >,
          <span
            data-id="annotation_8"
            class="annotation"
            style="position: unset"
          >
            Jenkins C</span
          >,
          <span
            data-id="annotation_9"
            class="annotation"
            style="position: unset"
          >
            Price AJ</span
          >,
          <span
            data-id="annotation_10"
            class="annotation"
            style="position: unset"
          >
            Gill HS</span
          >,
          <span
            data-id="annotation_11"
            class="annotation"
            style="position: unset"
          >
            Dodd CAF</span
          >,
          <span
            data-id="annotation_12"
            class="annotation"
            style="position: unset"
          >
            Murray DW</span
          >.
          <span
            data-id="strong_2"
            class="annotation strong"
            style="position: unset"
            >Improved fixation in cementless unicompartmental knee
            replacement: five-year results of a randomized controlled
            trial</span
          >.
          <span
            data-id="emphasis_2"
            class="annotation emphasis"
            style="position: unset"
            >J Bone Joint Surg Am.</span
          >
          2013 Aug 7;95(15):1365-1-2.</span
        ><span class="jbjs" style="position: unset"
          ><a
            href="?rsuite_id=1168259"
            target="_new"
            class=""
            style="position: unset"
            >J Bone Joint Surg Am</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_3"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        3&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_13"
            class="annotation"
            style="position: unset"
          >
            Batailler C</span
          >,
          <span
            data-id="annotation_14"
            class="annotation"
            style="position: unset"
          >
            White N</span
          >,
          <span
            data-id="annotation_15"
            class="annotation"
            style="position: unset"
          >
            Ranaldi FM</span
          >,
          <span
            data-id="annotation_16"
            class="annotation"
            style="position: unset"
          >
            Neyret P</span
          >,
          <span
            data-id="annotation_17"
            class="annotation"
            style="position: unset"
          >
            Servien E</span
          >,
          <span
            data-id="annotation_18"
            class="annotation"
            style="position: unset"
          >
            Lustig S</span
          >.
          <span
            data-id="strong_3"
            class="annotation strong"
            style="position: unset"
            >Improved implant position and lower revision rate with
            robotic-assisted unicompartmental knee arthroplasty</span
          >.
          <span
            data-id="emphasis_3"
            class="annotation emphasis"
            style="position: unset"
            >Knee Surg Sports Traumatol Arthrosc.</span
          >
          2019 Apr;27(4):1232-1-2.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Improved%20implant%20position%20and%20lower%20revision%20rate%20with%20robotic-assisted%20unicompartmental%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22C%20Batailler%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_4"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        4&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_19"
            class="annotation"
            style="position: unset"
          >
            van der List JP</span
          >,
          <span
            data-id="annotation_20"
            class="annotation"
            style="position: unset"
          >
            Chawla H</span
          >,
          <span
            data-id="annotation_21"
            class="annotation"
            style="position: unset"
          >
            Zuiderbaan HA</span
          >,
          <span
            data-id="annotation_22"
            class="annotation"
            style="position: unset"
          >
            Pearle AD</span
          >.
          <span
            data-id="strong_4"
            class="annotation strong"
            style="position: unset"
            >The Role of Preoperative Patient Characteristics on Outcomes
            of Unicompartmental Knee Arthroplasty: A Meta-Analysis
            Critique</span
          >.
          <span
            data-id="emphasis_4"
            class="annotation emphasis"
            style="position: unset"
            >J Arthroplasty.</span
          >
          2016 Nov;31(11):2617-1-2.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Role%20of%20Preoperative%20Patient%20Characteristics%20on%20Outcomes%20of%20Unicompartmental%20Knee%20Arthroplasty%3A%20A%20Meta-Analysis%20Critique&as_occt=title&as_sauthors=%20%22JP%20van%20der%20List%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <a
      href=""
      data-id="eletter_reference"
      class="annotation eletter_reference resource-reference"
      style="position: unset"
    ></a>
    <div
      class="content-node eletter-submit"
      data-id="eletters"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="label">eLetters</div>
        <div class="link" style="position: unset">
          <a
            href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Daaac54e3-9c37-4708-a259-bd51942f0306%26native%3D1"
            target="_blank"
            class=""
            style="position: unset"
            ><img
              class="image"
              src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
              style="position: unset"
            /><span class="label">Submit an eLetter</span></a
          >
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="heading_5"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset"
          >Additional information</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node publication-info"
      data-id="publication_info"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="meta-data" style="position: unset">
          <div class="journal" style="position: unset">
            <div class="label">Journal</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >The Journal of Bone and Joint Surgery</span
              >
            </div>
          </div>
          <div class="subject" style="position: unset">
            <div class="label">Section</div>
            <div class="value" style="position: unset">
              Commentary and Perspective
            </div>
          </div>
          <div class="publishing" style="position: unset">
            <div class="label">Published</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >September 4, 2024; 106 (17): e37</span
              >
            </div>
          </div>
          <div class="doi" style="position: unset">
            <div class="label">DOI</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >10.2106/JBJS.24.00524</span
              >
            </div>
          </div>
          <div class="dates" style="position: unset">
            The article was first published on
            <b class="" style="position: unset">September 4, 2024</b>.
          </div>
        </div>
        <div
          class="content-node paragraph"
          data-id="articleinfo"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            <div
              class="content-node related-articles"
              data-id="related_articles_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="related-articles-box" style="position: unset">
                  <div class="related-article" style="position: unset">
                    <div class="heading" style="position: unset">
                      <i class="fa fa-link" style="position: unset"></i
                      >Article
                    </div>
                    <div class="article-info" style="position: unset">
                      <a
                        href="https://www.jbjs.org/reader.php?rsuite_id=92569169-3cfa-40b7-a840-10f9b8a81118&native=1"
                        class="article-title"
                        style="position: unset"
                        >A Matched Comparison of Implant and Functional
                        Outcomes of Cemented and Cementless
                        Unicompartmental Knee Replacements<span
                          class="subtitle"
                          style="position: unset"
                          ><br style="position: unset" />A Study from the
                          National Joint Registry for England, Wales,
                          Northern Ireland and the Isle of Man and the
                          Hospital Episode Statistics Patient Reported
                          Outcome Measures Database</span
                        ></a
                      >
                      <div
                        class="article-authors text-with-ellipsis one-line"
                        style="position: unset"
                      >
                        Mohammad, Hasan R.; Judge, Andrew; Murray, David
                        W.
                      </div>
                      <div
                        class="article-citations"
                        style="position: unset"
                      >
                        <img
                          src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg"
                          style="position: unset"
                        />J Bone Joint Surg Am, 106(17):1553 | July 9,
                        2024
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node heading level-3"
              data-id="heading_2"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <span class="text title" style="position: unset"
                  >Copyright & License</span
                >
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node paragraph"
              data-id="paragraph_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_1"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    Copyright © 2024 by The Journal of Bone and Joint
                    Surgery, Incorporated.
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node supplement"
              data-id="supplement_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="file" style="position: unset">
                  <span class="" style="position: unset"
                    >Open article PDF</span
                  ><a
                    class="jbjs_tracking gtm_tracking"
                    jbjs_tracking_type="download"
                    jbjs_tracking_data='{"id":"aaac54e3-9c37-4708-a259-bd51942f0306","type":"article","topics":[]}'
                    href="https://www.jbjs.org/reader.php?rsuite_id=aaac54e3-9c37-4708-a259-bd51942f0306&type=pdf&name=JBJS.24.00524.pdf"
                    target="_blank"
                    gtm_action="reader"
                    gtm_category="PDF_article_downloads"
                    gtm_label="https://www.jbjs.org/reader.php?rsuite_id=aaac54e3-9c37-4708-a259-bd51942f0306&type=pdf&name=JBJS.24.00524.pdf"
                    jbjs_tracking_source="reader"
                    style="position: unset"
                    ><img
                      src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                      style="position: unset"
                    />
                    Download</a
                  >
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node heading level-3"
              data-id="heading_3"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <span class="text title" style="position: unset"
                  >Disclosures of Potential Conflicts of Interest</span
                >
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node paragraph"
              data-id="paragraph_2"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_2"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <span
                      data-id="strong_5"
                      class="annotation strong"
                      style="position: unset"
                      >Disclosure:</span
                    >
                    The
                    <span
                      data-id="strong_6"
                      class="annotation strong"
                      style="position: unset"
                      >Disclosure of Potential Conflicts of Interest</span
                    >
                    forms are provided with the online version of the
                    article (<a
                      href="http://links.lww.com/JBJS/I97"
                      target="_blank"
                      data-id="link_1"
                      class="link"
                      style="position: unset"
                      >http://links.lww.com/JBJS/I97</a
                    >).
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node supplement"
              data-id="supplement_2"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="file" style="position: unset">
                  <span class="" style="position: unset"
                    >Disclosures of Potential Conflicts of Interest</span
                  ><a
                    class="jbjs_tracking"
                    jbjs_tracking_type="download"
                    jbjs_tracking_data='{"id":"aaac54e3-9c37-4708-a259-bd51942f0306","type":"disclosure","topics":[]}'
                    href="/php/content/content_api.php?op=download&rsuite_id=aaac54e3-9c37-4708-a259-bd51942f0306&type=zip&name=JBJS.24.00524.disclosure.zip&subtype=disclosure"
                    target="_blank"
                    jbjs_tracking_source="reader"
                    style="position: unset"
                    ><i class="fa fa-download" style="position: unset">
                      Download</i
                    ></a
                  >
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-3"
      data-id="heading_1_author_list"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset">Authors</span>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node contributor"
      data-id="contributor_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="contributor-name" style="position: unset">
          Aaron G. Chen, MD, MSc<span
            data-id="affiliation_reference_1"
            class="label annotation cross_reference"
            >1</span
          >
        </div>
        <div class="affiliations" style="position: unset">
          <span class="" style="position: unset"></span>
        </div>
        <div class="contrib-data" style="position: unset">
          <img
            class="orcid"
            src="https://www.jbjs.org/images/orcid_logo.png"
            style="position: unset"
          /><a
            href="https://orcid.org/0000-0002-1158-1264"
            target="_blank"
            class="orcid"
            style="position: unset"
            >https://orcid.org/0000-0002-1158-1264</a
          >
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node contributor"
      data-id="contributor_2"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="contributor-name" style="position: unset">
          Michael S. Reich, MD<span
            data-id="affiliation_reference_2"
            class="label annotation cross_reference"
            >2</span
          >
        </div>
        <div class="affiliations" style="position: unset">
          <span class="" style="position: unset"></span>
        </div>
        <div class="emails contrib-data" style="position: unset">
          <span class="contrib-label" style="position: unset"
            >For correspondence: </span
          ><span class="" style="position: unset"
            ><a
              href="mailto:michaelsreichmd@gmail.com"
              class=""
              style="position: unset"
              >michaelsreichmd@gmail.com</a
            ></span
          >
        </div>
        <div class="contrib-data" style="position: unset">
          <img
            class="orcid"
            src="https://www.jbjs.org/images/orcid_logo.png"
            style="position: unset"
          /><a
            href="https://orcid.org/0000-0002-5113-1321"
            target="_blank"
            class="orcid"
            style="position: unset"
            >https://orcid.org/0000-0002-5113-1321</a
          >
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node affiliation"
      data-id="affiliation_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="label">1</span
        ><span class="text" style="position: unset"
          >Division of Orthopaedic Surgery, Schulich School of Medicine
          & Dentistry, Western University and London Health Sciences
          Centre, London, Ontario, Canada</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node affiliation"
      data-id="affiliation_2"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="label">2</span
        ><span class="text" style="position: unset"
          >TRIA Orthopedic Center, Stillwater, Minnesota</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <a class="logo" href="home.php" style="position: unset"></a>
  </div>
</div>
<div
  class="surface-scrollbar content hidden"
  style="display: none; position: unset"
>
  <div
    class="visible-area"
    style="top: 0px; height: 6055.42px; position: unset"
  ></div>
</div>
</div>
</div>
</div>
`,
};
