import { Alert, type AlertColor, AlertTitle } from "@mui/material/";

type Props = {
	severity?: unknown;
	title?: string;
	description?: string;
	callback?: () => void;
	rightComponent?: React.ReactNode;
};
const AlertStandard = ({
	severity,
	title,
	description,
	callback,
	rightComponent,
}: Props) => (
	<Alert
		action={rightComponent}
		severity={severity as AlertColor}
		onClose={callback}
	>
		{title && <AlertTitle>{title}</AlertTitle>}
		{description || null}
	</Alert>
);

export { AlertStandard };
