import { tagIconUrlMap } from "@constants/mocked-image-challenge-data";
import type { ItemData } from "schema/item";
import type { SpecialityFilter, Tag } from "../types";

export const useSpecialities = (filteredItems: ItemData[]) => {
	const uniqueTags = [
		...new Set(
			filteredItems.flatMap(
				(item) => item.tags?.map((tag: Tag) => tag.label) || [],
			),
		),
	];

	const specialitiesFilter: SpecialityFilter[] = uniqueTags.map((item) => ({
		title: item,
		icon: tagIconUrlMap[item],
		description: `${
			filteredItems.filter((session) =>
				session.tags?.some((tag: Tag) => tag.label === item),
			).length
		} challenges`,
		isSelected: false,
	}));

	const getSpecialityLabel = (selectedFilters: string[]) => {
		const numSelectedFilters = selectedFilters.length;
		if (numSelectedFilters === 0) {
			return "Speciality";
		}
		if (numSelectedFilters === 1) {
			return selectedFilters[0];
		}
		return `${numSelectedFilters} Specialities`;
	};

	return {
		specialitiesFilter,
		uniqueTags,
		getSpecialityLabel,
	};
};
