import type { Article } from "../../../../../types";

export const Article536: Article = {
	id: 536,
	rsuiteId: "39b539eb-0a9e-490f-9d21-f02fda1c9f4c",
	type: "scientific article",
	title:
		"Clinical Outcomes Following Operative and Nonoperative Management of Odontoid Fractures Among Elderly Individuals with Dementia",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=39b539eb-0a9e-490f-9d21-f02fda1c9f4c&type=jpeg&name=JBJS.23.00835f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Clinical Outcomes Following Operative and Nonoperative
                Management of Odontoid Fractures Among Elderly Individuals with Dementia</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Andrew J.
                      Schoenfeld, MD, MSc</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Lingwei
                      Xiang, MPH</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Background:</span> The incidence of odontoid fractures among the
                        elderly population has been increasing in recent years. Elderly individuals with dementia may be
                        at increased risk for inferior outcomes following such fractures. Although surgical intervention
                        has been maintained to optimize survival and recovery, it is unclear if this benefit extends to
                        patients with dementia. We hypothesized that patients with dementia who were treated operatively
                        for odontoid fractures would experience improved survival and lower rates of hospice admission
                        but higher rates of delirium and of intensive interventions.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Methods:</span> We used Medicare claims data (2017 to 2018) to
                        identify community-dwelling individuals with dementia who sustained type-II odontoid fractures.
                        We considered treatment strategy (operative or nonoperative) as the primary predictor and
                        survival as the primary outcome. The secondary outcomes consisted of post-treatment delirium,
                        hospice admission, post-treatment intensive intervention, and post-discharge admission to a
                        nursing home or a skilled nursing facility. In all models, we controlled for age, biological
                        sex, race, Elixhauser Comorbidity Index, Frailty Index, admission source, treating hospital, and
                        dual eligibility. Adjusted analyses for survival were conducted using Cox proportional hazards
                        regression. Adjusted analyses for secondary outcomes were performed using generalized estimating
                        equations. To address confounding by indication, we performed confirmatory analyses using
                        inverse probability of treatment weighting.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Results:</span> In this study, we included 1,030 patients. The median
                        age of the cohort was 86.5 years (interquartile range, 80.9 to 90.8 years), 60.7% of the
                        patients were female, and 90% of the patients were White. A surgical procedure was performed in
                        19.8% of the cohort. Following an adjusted analysis, patients treated surgically had a 28% lower
                        hazard of mortality (hazard ratio, 0.72 [95% confidence interval (CI), 0.53 to 0.98]), but
                        higher odds of delirium (odds ratio, 1.64 [95% CI, 1.10 to 2.44]). These findings were preserved
                        in the inverse probability weighted analysis.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Conclusions:</span> We found that, among individuals with dementia
                        who sustain a type-II odontoid fracture, surgical intervention may confer a survival benefit. A
                        surgical procedure may be an appropriate treatment strategy for individuals with dementia whose
                        life-care goals include life prolongation and maximizing quality of life in the short term
                        following an injury.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Along with hip fractures, fractures of the odontoid
                  process are increasingly recognized as fragility fractures that can alter the life arc of elderly
                  individuals, accelerating functional deterioration, loss of independence, and mortality<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">7</span></a>. The
                  incidence of these injuries among individuals ≥65 years of age has been increasing recently<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">5</span></a>, as
                  has the enthusiasm for surgical intervention<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">8</span></a>. For
                  example, Alluri et al. reported that the rate of surgical procedures for elderly patients with
                  odontoid fractures nearly doubled from 2003 to 2017<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">1</span></a>. Some
                  studies have documented that elderly patients treated operatively have superior outcomes in terms of
                  quality of life and physical function<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">8</span></a>,
                  whereas others have maintained that there is no difference in functional outcomes<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">10</span></a>.
                  Several studies have substantiated improved survival for patients treated with operative intervention,
                  although this may have been due to selection bias<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">8</span></a>, and such findings are generally confined to individuals
                  &lt;80 years of age.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">At the same time, surgical intervention has a
                  non-negligible complication profile and may accelerate neurologic dysfunction or mortality in the
                  event of surgical site infection, sepsis, aspiration, or other perioperative adverse events<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">13</span></a>. This risk may be even higher among elderly individuals
                  with dementia, who represent both a growing subgroup in most industrialized nations and a population
                  at an elevated risk for falls and other injuries<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">14</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">15</span></a> that may lead to odontoid fractures. Elevated risks in this
                  population may be linked to the extent of cognitive impairment, comorbidities, and increased
                  physiologic frailty<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">15</span></a>. Prior studies in other areas of musculoskeletal injury
                  have found that patients with dementia were more likely to experience postoperative delirium and other
                  complications, greater loss of mobility, and higher rates of mortality following a surgical procedure
                  than counterparts without cognitive impairment<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">15</span></a>.
                  For many individuals with dementia, operative intervention may also not align with predefined goals of
                  care or may lead to events such as prolonged intubation, tracheotomy, and insertion of feeding tubes
                  that contravene advanced directives<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">14</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">At present, there has been limited information to support
                  robust shared decision-making with regard to clinical results and the care trajectory of patients with
                  dementia who sustain odontoid fractures. In this context, we sought to use recent Medicare claims data
                  to characterize the influence of operative or nonoperative management on clinical and patient-centered
                  outcomes including survival, the development of post-treatment delirium, admission to hospice, and
                  post-treatment intensive interventions, such as cardiopulmonary resuscitation, mechanical ventilation,
                  or feeding tube insertion. Based on prior work in the area of hip fractures<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">15</span></a>, we hypothesized that patients with dementia who were
                  treated operatively for odontoid fractures would experience improved survival and lower rates of
                  hospice admission but higher rates of delirium and of intensive interventions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">This study received institutional review board approval
                  prior to commencement. We used national Medicare data files for individuals undergoing treatment from
                  January 1, 2017, to June 30, 2018, including the Master Beneficiary Summary File (MBSF), Carrier File,
                  Inpatient and Outpatient claims data, Skilled Nursing Facility and Home Health claims, Minimum Data
                  Set (MDS), and Hospice files.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The sample consisted of community-dwelling patients with
                  dementia, identified using a previously validated approach that considered beneficiaries without an
                  MDS assessment in the 180 days before an initial incident code for odontoid fracture. Dementia was
                  identified using a previously validated approach that surveyed for associated International
                  Classification of Diseases, Tenth Revision (ICD-10) codes in Inpatient, Outpatient, Carrier, Hospice,
                  Home Health, and Skilled Nursing Facility files<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">14</span></a>. We
                  only evaluated beneficiaries who had 19 months of continuous fee-for-service coverage, allowing for a
                  gap of up to 1 month in the year prior. Patients were identified as having sustained an odontoid
                  fracture based on ICD-10 inpatient diagnosis codes (S12110A, S12112A, S12111A, S12120A, S12112B). We
                  limited consideration to patients with type-II odontoid fractures<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">16</span></a> to reduce heterogeneity and increase the prospect of
                  surgical intervention. Surgical intervention was identified on the basis of the ICD-10 procedure codes
                  for a relevant cervical spine surgical procedure that occurred in association with the index admission
                  and was associated with 1 of the indicated diagnosis codes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">We used the MBSF and Inpatient files to assess survival at
                  30, 90, and 180 days following the initial diagnosis of odontoid fracture. The occurrence of
                  in-hospital delirium following the initial fracture diagnosis was determined on the basis of the
                  presence of an established ICD-10 code for this condition<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">17</span></a>.
                  Hospice admission was determined via the presence of a new hospice claim within 180 days of discharge
                  after the index admission<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">14</span></a>.
                  New nursing home admissions within 180 days after discharge were determined via previously validated
                  approaches for claims-based data<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">19</span></a>. Intensive interventions were identified using surveys for
                  appropriate codes in Inpatient, Outpatient, Carrier, Hospice, Home Health, and Skilled Nursing
                  Facilities files for cardiopulmonary resuscitation, intubation, mechanical ventilation, feeding tube
                  insertion, or new dialysis at any time point within 180 days following the odontoid fracture
                  diagnosis<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">20</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">We considered the treatment strategy (operative or
                  nonoperative) to be the primary predictor and survival to be the primary outcome. Other
                  patient-centered and clinical outcomes were considered secondarily and consisted of post-treatment
                  delirium, hospice admission, post-treatment intensive intervention, and post-treatment nursing home
                  admission. In all models, we controlled for patient characteristics that may have influenced the
                  decision for operative or nonoperative management, including age, biological sex, race (categorized as
                  White compared with non-White due to sample-size limitations among racial minority groups), Elixhauser
                  Comorbidity Index (see Appendix 1)<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">22</span></a>, Frailty Index, admission source (categorized as home or
                  not home [for example, skilled nursing facility, hospice, or other inpatient facility]), and dual
                  Medicare and Medicaid eligibility as a marker for socioeconomic status. A Cox proportional hazard
                  model and generalized estimating equations (GEEs) were used to account for hospital clustering, as
                  certain facilities may be more likely to surgically treat patients with spine conditions than others<a
                    href="" data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">23</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">We presented continuous data using medians and
                  interquartile ranges (IQRs), with categorical data described using frequencies and percentages.
                  Wilcoxon 2-sample tests and Pearson chi-square tests were conducted to compare the patient
                  characteristics between operative and nonoperative treatment groups. Raw, unadjusted comparisons were
                  made across secondary outcomes using GEEs. Survival was evaluated using Kaplan-Meier curves, which
                  were also used to assess the proportionality assumption. We also evaluated the rate of survival at 30,
                  90, and 180 days following the fracture diagnosis. Adjusted analyses for survival were conducted using
                  Cox proportional hazards regression, with output presented using hazard ratios (HRs), 95% confidence
                  intervals (CIs), and p values. All abstracted variables were included as covariates in adjusted
                  models. Adjusted analyses for all secondary outcomes were performed using GEEs accounting for hospital
                  clustering, with output presented using odds ratios (ORs), 95% CIs, and p values. In order to address
                  confounding by indication and selection bias, we performed confirmatory testing using inverse
                  probability of treatment weighting via propensity scores. We further assessed the impact of the
                  surgical procedure by time frame, creating a cut-point at 45 days to assess whether the benefit of the
                  surgical procedure was realized only in the first 6 weeks following the procedure or over a longer
                  time period. Significance was established, a priori, for all variables as HR and OR point estimates
                  and 95% CIs exclusive of 1.0 and p values of &lt;0.05. All data were analyzed using SAS version 9.4
                  (SAS Institute). All coding algorithms used in this study are available from the authors by request.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">We identified 1,030 community-dwelling patients with
                  dementia who sustained odontoid fractures and met full inclusion criteria. The median age of the
                  cohort was 86.5 years (IQR, 80.9 to 90.8 years), and 60.7% of the population was female. Ninety
                  percent of the population was White, and the median Elixhauser Comorbidity Index was 10.0.
                  Seventy-three percent of the cohort was admitted from home, and 17.8% were dual-eligible Medicare
                  recipients. Among all patients with an odontoid fracture, 19.8% (204) were treated operatively and
                  80.2% (826) underwent nonoperative management. Because of the size of the sample, there were several
                  modest but significant differences in demographic and clinical characteristics between the operatively
                  and nonoperatively treated cohorts that were not considered to be clinically meaningful (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographic and Clinical Characteristics of
                          Individuals with Dementia Who Sustained a Type-II Odontoid Fracture</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1358.86px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.891599);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total
                          Population (N = 1,030)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Nonoperative Management (N = 826)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Operative
                          Management (N = 204)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86.5 (80.9
                          to 90.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87.4 (82.3
                          to 91.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82.8 (78.0
                          to 86.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Elixhauser Comorbidity
                          Index<span data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.0 (3.0
                          to 25.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11 (4.0 to
                          25.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.0 (3.0
                          to 22.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.07</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Frailty Index<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.30 (0.25
                          to 0.34)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.30 (0.25
                          to 0.34)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.29 (0.25
                          to 0.34)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.10</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">405
                          (39.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">313
                          (37.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92
                          (45.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">625
                          (60.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">513
                          (62.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">112
                          (54.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Race<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.72</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-White</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">103
                          (10.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84
                          (10.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (9.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> White</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">927
                          (90.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">742
                          (89.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">185
                          (90.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Admission source<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.16</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Home residence</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">752
                          (73.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">595
                          (72.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">157
                          (77.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-home
                          residence</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">278
                          (27.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">231
                          (28.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (23.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Discharge status<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Home self-care</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76
                          (7.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (7.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (5.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Home with home health
                          agency care</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">103
                          (10.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90
                          (10.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (6.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Skilled nursing
                          facility</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">505
                          (49.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">399
                          (48.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">106
                          (52.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Inpatient
                          rehabilitation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">124
                          (12.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84
                          (10.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (19.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other hospital</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (4.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (3.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (6.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hospice</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74
                          (7.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Death</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86
                          (8.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75
                          (9.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (5.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dual eligibility<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.11</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">183
                          (17.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">139
                          (16.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (21.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">847
                          (82.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">687
                          (83.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">160
                          (78.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the median, with the IQR in parentheses.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Data were suppressed according to Medicare data policies.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Patients with dementia who underwent surgical intervention
                  were significantly less likely to die within 30 days (10.8% in the operatively treated group compared
                  with 23.6% in the nonoperatively treated group; p &lt; 0.001), 90 days (18.6% in the operatively
                  treated group compared with 29.9% in the nonoperatively treated group; p &lt; 0.002), and 180 days
                  (24.0% in the operatively treated group compared with 39.1% in the nonoperatively treated group; p
                  &lt; 0.001) following the diagnosis of an odontoid fracture (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). In the
                  adjusted analysis, patients treated surgically were found to have a 28% lower hazard of mortality (HR,
                  0.72 [95% CI, 0.53 to 0.98]; p = 0.04) (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00835f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=39b539eb-0a9e-490f-9d21-f02fda1c9f4c&type=jpeg&name=JBJS.23.00835f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier curves depicting post-treatment
                          survival among patients with dementia treated operatively (red line) or nonoperatively (blue
                          line) for type-II odontoid fractures.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Adjusted Associations Between Survival and Clinical
                          and Demographic Factors</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 520px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Factor</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HR<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.04 (1.03
                          to 1.06)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.49 (1.21
                          to 1.85)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-White race</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.90 (0.62
                          to 1.30)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.57</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Elixhauser Comorbidity
                          Index<span data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01 (1.00
                          to 1.02)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.004</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Frailty Index<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.29 (1.12
                          to 1.48)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Treatment method</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Operative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.72 (0.53
                          to 0.98)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Admission source</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.84</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Home residence</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-home
                          residence</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98 (0.78
                          to 1.22)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dual eligible</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.73 (0.53
                          to 0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the HR, with the 95% CI in parentheses,
                        determined by multivariable Cox proportional hazards regression analysis.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Per each 1-year increase in age.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Per each 1-point increase in the Elixhauser Comorbidity Index.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Per each 0.1-point increase in the Frailty Index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">However, individuals who underwent surgical intervention
                  had a higher likelihood of experiencing delirium (25.5% in the operatively treated group compared with
                  19.4% in the nonoperatively treated group; p = 0.05). In the adjusted analysis, the surgical cohort
                  demonstrated a 64% increase in the odds of delirium (OR, 1.64 [95% CI, 1.10 to 2.44]; p = 0.02) (<a
                    href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Adjusted Associations Between Delirium and Clinical
                          and Demographic Factors</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 520px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Factor</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98 (0.95
                          to 1.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.10</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.89 (0.64
                          to 1.24)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.50</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-White race</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.67 (0.33
                          to 1.33)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.25</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Elixhauser Comorbidity
                          Index<span data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.06 (1.05
                          to 1.07)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Frailty Index<span
                            data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.65 (0.49
                          to 0.85)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.002</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Treatment method</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Operative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.64 (1.10
                          to 2.44)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Admission source</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.76</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Home residence</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-home
                          residence</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.06 (0.72
                          to 1.56)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dual eligible</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.69 (0.42
                          to 1.13)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.14</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the OR, with the 95% CI in parentheses,
                        determined by multivariable logistic regression analysis.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Per each 1-year increase in age.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Per each 1-point increase in the Elixhauser Comorbidity Index.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Per each 0.1-point increase in the Frailty Index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">In the adjusted analysis, the odds of hospice admission
                  were not significantly different in the surgical group compared with those managed nonoperatively (OR,
                  0.67 [95% CI, 0.42 to 1.05]; p = 0.08) (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>). No
                  significant differences were noted between the operative and nonoperative cohorts in terms of nursing
                  home admissions (OR, 0.89 [95% CI, 0.59 to 1.37]; p = 0.62) or intensive interventions (OR, 1.07 [95%
                  CI, 0.69 to 1.65]; p = 0.77).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Adjusted Associations Between Hospice Admission and
                          Clinical and Demographic Factors</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 520px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Factors</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR<span
                            data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_22" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.06 (1.04
                          to 1.09)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.95 (0.69
                          to 1.31)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.76</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Non-White race</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.67 (0.39
                          to 1.18)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.17</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Elixhauser Comorbidity
                          Index<span data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01 (1.00
                          to 1.02)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.10</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Frailty Index<span
                            data-id="table_footnote_reference_24" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.60 (1.27
                          to 2.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Treatment method</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Operative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.67 (0.42
                          to 1.05)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Admission source</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.82</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Home residence</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-home
                          residence</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.96 (0.69
                          to 1.34)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dual eligible</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.56 (0.34
                          to 0.91)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the OR, with the 95% CI in parentheses,
                        determined by multivariable logistic regression analysis.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Per each 1-year increase in age.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_14" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Per each 1-point increase in the Elixhauser Comorbidity Index.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_15" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Per each 0.1-point increase in the Frailty Index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">These results were largely preserved following inverse
                  probability weighting, including a lower hazard of mortality (HR, 0.66 [95% CI, 0.48 to 0.91]; p =
                  0.01) and higher odds of delirium (OR, 1.48 [95% CI, 1.01 to 2.18]; p = 0.046) for patients treated
                  surgically. Following inverse probability weighting, patients treated surgically also had lower odds
                  of hospice admission (OR, 0.54 [95% CI, 0.33 to 0.87]; p = 0.01). When considered by time period, a
                  significant survival benefit for undergoing a surgical procedure was seen within the first 45 days
                  (HR, 0.65 [95% CI, 0.43 to 0.97]; p = 0.003), but not for the time frame beyond 45 days (HR, 0.86 [95%
                  CI, 0.52 to 1.44]; p = 0.57).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Akin to hip fractures, odontoid fractures have been shown
                  to dramatically alter the clinical trajectory of an elderly individual’s life, often portending rapid
                  functional declination, loss of independence, and near-term mortality<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_22" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">7</span></a>. The
                  risk of such adverse events is likely even higher in elderly individuals with dementia who, because of
                  cognitive impairment and physiological frailty, are not only at higher risk for developing these
                  cervical fractures but also have a higher likelihood of suboptimal outcomes regardless of treatment.
                  This presents a daunting clinical challenge, as surgeons must balance the goals of care and the risk
                  of surgical procedures against the prospects of accelerated functional deterioration and lower
                  likelihood of survival in both the near term and the long term. If a surgical procedure can
                  meaningfully improve survival and functional independence, then this would be of value to patients.
                  However, if the utilization of a surgical procedure only accelerates the spiral of clinical
                  deterioration, while simultaneously driving the use of intensive interventions (for example,
                  tracheotomy, a feeding tube, and mechanical ventilation) and institutionalization, this would be a
                  suboptimal treatment method.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Our analysis suggests that, among community-dwelling
                  elderly patients with dementia, surgical intervention not only improves survival but also reduces the
                  potential for admission to hospice without simultaneously increasing the potential for other intensive
                  interventions or nursing home admission. In terms of mean age and clinical and demographic
                  characteristics, our cohort is similar to those in other investigations of fracture management in
                  patients with dementia<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">14</span></a>.
                  The rate of surgical intervention and the incidence of adverse events, such as mortality<a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_24" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">13</span></a> and post-treatment delirium<a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">24</span></a>, in our cohort are aligned with estimates from prior
                  series, which we believe endorses the external validity of our findings. This work is also
                  strengthened by the use of Medicare data, which allowed for the identification of a large sample of
                  patients treated in a variety of different health-care settings across the United States<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">23</span></a>. The use of Medicare claims also allowed us to follow
                  patient trajectories longitudinally and characterize the post-treatment course, including survival,
                  the environment of post-treatment institutional care, and the need for additional interventions. We
                  believe that these characteristics support the generalizability of our findings and the prospects for
                  ready clinical application.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The survival benefits for elderly patients with dementia
                  who underwent operative intervention for type-II odontoid fractures are similar to those in previous
                  work that has been performed among elderly individuals as a whole<a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_27" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_27" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_27" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">28</span></a>. For example, Vaccaro et al. reported that, at 1 year
                  following treatment, mortality was higher in elderly individuals treated nonoperatively for odontoid
                  fractures and Neck Disability Index and Short Form (SF)-36v2 Bodily Pain scores were inferior among
                  survivors<a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">8</span></a>.
                  Similarly, Robinson et al. encountered superior survival among individuals with odontoid fractures who
                  were 70 to 88 years of age and underwent surgical management in a multinational registry<a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">4</span></a>. Comparable results were also found in the report of the
                  AOSpine group in 2013<a href="" data-id="citation_reference_54"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">28</span></a>.
                  Barlow et al. previously reported that operative treatment for odontoid fractures was cost-effective
                  in elderly patients &lt;85 years of age<a href="" data-id="citation_reference_55"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">27</span></a>.
                  Our results are also concordant with hip fracture treatment among patients with dementia, where it has
                  been shown that surgical intervention was associated with a lower risk of mortality, as well as
                  improved quality of life<a href="" data-id="citation_reference_56"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">14</span></a>.
                  Surgical intervention, when properly indicated, may lead to the obviation of cervical immobilization
                  that can precipitate aspiration, functional restrictions, and increased risk of falls<a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_33" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_58"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_33"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">13</span></a>. A surgical procedure may also reduce pain and contribute
                  to a lower need for pain medications that may further impact sensory and cognitive function<a href=""
                    data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">15</span></a>. Based on our subset analysis, these benefits appear to be
                  most robust in the first few months following the surgical procedure. Although our results do indicate
                  a short-term increase in the likelihood of delirium that may be tied to the use of anesthesia<a
                    href="" data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">24</span></a>, we believe that the risk of this adverse event is modest
                  and it did not seem to exert an adverse influence on other post-treatment outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">We believe that our findings are important to orthopaedic
                  fracture services, spine surgeons, hospitalist geriatric liaison teams, policymakers, health-care
                  institutions, patients, and families. Our work corroborates and expands on prior literature that
                  highlights the notable risk of mortality and functional deterioration that follow in the wake of an
                  odontoid process fracture among individuals with dementia. At the same time, surgical intervention
                  does appear to provide a survival advantage and reduces the potential for hospice admission and
                  confinement to environments of care that are outside the patient’s home. Therefore, surgical fixation
                  of odontoid process fractures would appear to be an appropriate strategy for individuals with dementia
                  whose life-care goals include life prolongation, maximizing time in the home of residence, and quality
                  of life.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The results of our work should be interpreted in light of
                  this study’s limitations. Foremost, this was a retrospective study that used Medicare claims data. The
                  nature of the substrate data meant that we were dependent on coding algorithms to identify patients
                  who sustained odontoid fractures, as well as patients who had dementia. Although we used previously
                  accepted coding algorithms to establish these cohorts, we could not rule out the potential for coding
                  errors to introduce confounding. Further, we did not have patient-reported outcome measures, or
                  objective scores, to quantify physical function or quality of life. We were solely reliant on proxy
                  measures such as the environment of care, care intensity, and number of post-treatment health-care
                  encounters. Finally, we lacked clinically granular details to help to delineate treatment approaches
                  and management strategies, including time from admission to the surgical procedure. We also lacked
                  details with regard to fracture stability and displacement. Surgeon discretion and intuition remain
                  important factors in deciding how patients will be managed following an odontoid fracture. Although
                  our statistical approaches included many characteristics that would likely impact such decisions for a
                  surgical procedure and our use of inverse probability weighting via propensity scores addresses
                  selection bias to the extent possible, we do recognize the prospect for residual confounding to impact
                  our results. We therefore caution that the results presented here should not be used as definitive
                  recommendations with regard to surgical procedures in this population.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, our results convey that, among individuals
                  with dementia who sustain a type-II odontoid process fracture, surgical intervention may confer a
                  survival benefit and optimize the chance for functional recovery and return to preinjury environments
                  of care, including the patient’s home. At the same time, the injury itself exerts a notable effect on
                  the prospect of survival, with non-negligible levels of mortality regardless of treatment. Although
                  patients treated surgically had a modestly increased likelihood of delirium following treatment, they
                  also had a lower rate of hospice admission without a concomitant increase in intensive interventions.
                  These findings can prove useful when discussing care goals and the optimal treatment strategy with
                  patients and their families, and they may also aid in shared decision-making. The ultimate treatment
                  method should be tempered to the clinical context and informed by patient and family care goals. That
                  said, in those individuals with life-care goals that include life prolongation and maximizing quality
                  of life, our results would indicate that a surgical procedure should not be denied solely because of
                  the presence of dementia.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I76" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I76</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Alluri R</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Bouz G</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Solaru S</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kang H</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Wang J</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Hah RJ</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">A nationwide analysis of
                  geriatric odontoid fracture incidence, complications, mortality, and cost</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2021 Jan 15;46(2):131-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20nationwide%20analysis%20of%20geriatric%20odontoid%20fracture%20incidence%2C%20complications%2C%20mortality%2C%20and%20cost&as_occt=title&as_sauthors=%20%22R%20Alluri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Cooper Z</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Mitchell SL</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Lipsitz S</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Harris MB</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Ayanian JZ</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Bernacki RE</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Jha AK</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Mortality and readmission after
                  cervical fracture from a fall in older adults: comparison with hip fracture using national Medicare
                  data</span>. <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J Am
                  Geriatr Soc.</span> 2015 Oct;63(10):2036-42.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mortality%20and%20readmission%20after%20cervical%20fracture%20from%20a%20fall%20in%20older%20adults%3A%20comparison%20with%20hip%20fracture%20using%20national%20Medicare%20data&as_occt=title&as_sauthors=%20%22Z%20Cooper%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Rizvi SAM</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Helseth E</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Harr ME</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Mirzamohammadi J</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Rønning P</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Mejlænder-Evjensvold M</span>,
                <span data-id="annotation_20" class="annotation" style="position: unset;"> Linnerud H</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Management and long-term outcome
                  of type II acute odontoid fractures: a population-based consecutive series of 282 patients</span>.
                <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Spine J.</span> 2021
                Apr;21(4):627-37.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20and%20long-term%20outcome%20of%20type%20II%20acute%20odontoid%20fractures%3A%20a%20population-based%20consecutive%20series%20of%20282%20patients&as_occt=title&as_sauthors=%20%22SAM%20Rizvi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Robinson AL</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Olerud C</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Robinson Y</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Surgical treatment improves
                  survival of elderly with axis fracture-a national population-based multiregistry cohort study</span>.
                <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Spine J.</span> 2018
                Oct;18(10):1853-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20treatment%20improves%20survival%20of%20elderly%20with%20axis%20fracture-a%20national%20population-based%20multiregistry%20cohort%20study&as_occt=title&as_sauthors=%20%22AL%20Robinson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Sarode DP</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Demetriades AK</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Surgical versus nonsurgical
                  management for type II odontoid fractures in the elderly population: a systematic review</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Spine J.</span> 2018
                Oct;18(10):1921-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20versus%20nonsurgical%20management%20for%20type%20II%20odontoid%20fractures%20in%20the%20elderly%20population%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22DP%20Sarode%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Schoenfeld AJ</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Bono CM</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Reichmann WM</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Warholic N</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Wood KB</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Losina E</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Katz JN</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Harris MB</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Type II odontoid fractures of
                  the cervical spine: do treatment type and medical comorbidities affect mortality in elderly
                  patients?</span><span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Spine
                  (Phila Pa 1976).</span> 2011 May 15;36(11):879-85.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Type%20II%20odontoid%20fractures%20of%20the%20cervical%20spine%3A%20do%20treatment%20type%20and%20medical%20comorbidities%20affect%20mortality%20in%20elderly%20patients%3F&as_occt=title&as_sauthors=%20%22AJ%20Schoenfeld%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Traynelis VC</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Fontes RBV</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Abode-Iyamah KO</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Cox EM</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Greenlee JD</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Posterior fusion for fragility
                  type 2 odontoid fractures</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">J Neurosurg Spine.</span> 2021 Aug 13:1-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior%20fusion%20for%20fragility%20type%202%20odontoid%20fractures&as_occt=title&as_sauthors=%20%22VC%20Traynelis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Vaccaro AR</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Kepler CK</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Kopjar B</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Chapman J</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Shaffrey C</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Arnold P</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Gokaslan Z</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Brodke D</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> France J</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Dekutoski M</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Sasso R</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Yoon ST</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Bono C</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Harrop J</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Fehlings MG</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Functional and quality-of-life
                  outcomes in geriatric patients with type-II dens fracture</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2013 Apr
                17;95(8):729-35.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1162599"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Hamrick FA</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Sherrod BA</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Cole K</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Cox P</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Croci DM</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Bowers CA</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Mazur MD</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Dailey AT</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Bisson EF</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Using frailty measures to
                  predict functional outcomes and mortality after type II odontoid fracture in elderly patients: a
                  retrospective cohort study</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Global Spine J.</span> 2023 Jan 10:21925682221149394.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Using%20frailty%20measures%20to%20predict%20functional%20outcomes%20and%20mortality%20after%20type%20II%20odontoid%20fracture%20in%20elderly%20patients%3A%20a%20retrospective%20cohort%20study&as_occt=title&as_sauthors=%20%22FA%20Hamrick%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Chibbaro S</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Mallereau CH</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Ganau M</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Zaed I</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Teresa Bozzi M</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Scibilia A</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Cebula H</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Ollivier I</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Loit MP</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Chaussemy D</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Coca HA</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Dannhoff G</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Romano A</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Nannavecchia B</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Gubian A</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Spatola G</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Signorelli F</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Iaccarino C</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Pop R</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Proust F</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Baloglu S</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Todeschi J</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Odontoid type II fractures in
                  elderly: what are the real management goals and how to best achieve them? A multicenter European study
                  on functional outcome</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Neurosurg Rev.</span> 2022 Feb;45(1):709-18.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Odontoid%20type%20II%20fractures%20in%20elderly%3A%20what%20are%20the%20real%20management%20goals%20and%20how%20to%20best%20achieve%20them%3F%20A%20multicenter%20European%20study%20on%20functional%20outcome&as_occt=title&as_sauthors=%20%22S%20Chibbaro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Cronin PK</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Ferrone ML</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Marso CC</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Stieler EK</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Beck AW</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Blucher JA</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Makhni MC</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Simpson AK</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Harris MB</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Schoenfeld AJ</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Predicting survival in older
                  patients treated for cervical spine fractures: development of a clinical survival score</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Spine J.</span> 2019
                Sep;19(9):1490-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predicting%20survival%20in%20older%20patients%20treated%20for%20cervical%20spine%20fractures%3A%20development%20of%20a%20clinical%20survival%20score&as_occt=title&as_sauthors=%20%22PK%20Cronin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Harris MB</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Reichmann WM</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Bono CM</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Bouchard K</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Corbett KL</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Warholic N</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Simon JB</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Schoenfeld AJ</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Maciolek L</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Corsello P</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Losina E</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Katz JN</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Mortality in elderly patients
                  after cervical spine fractures</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2010 Mar;92(3):567-74.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1075790" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Schoenfeld AJ</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Harris MB</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> McGuire KJ</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Warholic N</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Wood KB</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Bono CM</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Mortality in elderly patients
                  with hyperostotic disease of the cervical spine after fracture: an age- and sex-matched study</span>.
                <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Spine J.</span> 2011
                Apr;11(4):257-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mortality%20in%20elderly%20patients%20with%20hyperostotic%20disease%20of%20the%20cervical%20spine%20after%20fracture%3A%20an%20age-%20and%20sex-matched%20study&as_occt=title&as_sauthors=%20%22AJ%20Schoenfeld%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Berry SD</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Rothbaum RR</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Kiel DP</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Lee Y</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Mitchell SL</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Association of clinical
                  outcomes with surgical repair of hip fracture vs nonsurgical management in nursing home residents with
                  advanced dementia</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">JAMA Intern Med.</span> 2018 Jun 1;178(6):774-80.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20clinical%20outcomes%20with%20surgical%20repair%20of%20hip%20fracture%20vs%20nonsurgical%20management%20in%20nursing%20home%20residents%20with%20advanced%20dementia&as_occt=title&as_sauthors=%20%22SD%20Berry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Shen H</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> He R</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Zhang P</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> He Y</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Liu Y</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Wang G</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Li T</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Risk factors for postoperative
                  medical morbidity and 3-month mortality in elderly patients with hip fracture following hip
                  arthroplasty during COVID-19 pandemic</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">J Orthop Surg Res.</span> 2023 Jan 22;18(1):59.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20postoperative%20medical%20morbidity%20and%203-month%20mortality%20in%20elderly%20patients%20with%20hip%20fracture%20following%20hip%20arthroplasty%20during%20COVID-19%20pandemic&as_occt=title&as_sauthors=%20%22H%20Shen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Anderson LD</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> D’Alonzo RT</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Fractures of the odontoid
                  process of the axis</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1974 Dec;56(8):1663-74.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=377463" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Kim DH</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Lee J</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Kim CA</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Huybrechts KF</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Bateman BT</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Patorno E</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Marcantonio ER</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Evaluation of algorithms to
                  identify delirium in administrative claims and drug utilization database</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Pharmacoepidemiol Drug
                  Saf.</span> 2017 Aug;26(8):945-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20algorithms%20to%20identify%20delirium%20in%20administrative%20claims%20and%20drug%20utilization%20database&as_occt=title&as_sauthors=%20%22DH%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Middleton A</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Kuo YF</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Graham JE</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Karmarkar A</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Lin YL</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Goodwin JS</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Haas A</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Ottenbacher KJ</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Readmission patterns over
                  90-day episodes of care among Medicare Fee-for-Service beneficiaries discharged to post-acute
                  care</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Am Med
                  Dir Assoc.</span> 2018 Oct;19(10):896-901.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Readmission%20patterns%20over%2090-day%20episodes%20of%20care%20among%20Medicare%20Fee-for-Service%20beneficiaries%20discharged%20to%20post-acute%20care&as_occt=title&as_sauthors=%20%22A%20Middleton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Goodwin JS</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Li S</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Zhou J</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Graham JE</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Karmarkar A</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Ottenbacher K</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Comparison of methods to
                  identify long term care nursing home residence with administrative data</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">BMC Health Serv Res.</span>
                2017 May 30;17(1):376.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20methods%20to%20identify%20long%20term%20care%20nursing%20home%20residence%20with%20administrative%20data&as_occt=title&as_sauthors=%20%22JS%20Goodwin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Luth EA</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Manful A</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Prigerson HG</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Xiang L</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Reich A</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Semco R</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Weissman JS</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Associations between dementia
                  diagnosis and end-of-life care utilization</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span> 2022
                Oct;70(10):2871-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Associations%20between%20dementia%20diagnosis%20and%20end-of-life%20care%20utilization&as_occt=title&as_sauthors=%20%22EA%20Luth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Moore BJ</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> White S</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Washington R</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Coenen N</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Elixhauser A</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Identifying increased risk of
                  readmission and in-hospital mortality using hospital administrative data: the AHRQ Elixhauser
                  Comorbidity Index</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Med Care.</span> 2017 Jul;55(7):698-705.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Identifying%20increased%20risk%20of%20readmission%20and%20in-hospital%20mortality%20using%20hospital%20administrative%20data%3A%20the%20AHRQ%20Elixhauser%20Comorbidity%20Index&as_occt=title&as_sauthors=%20%22BJ%20Moore%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Menendez ME</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Neuhaus V</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> van Dijk CN</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Ring D</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">The Elixhauser comorbidity
                  method outperforms the Charlson index in predicting inpatient death after orthopaedic surgery</span>.
                <span data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2014 Sep;472(9):2878-86.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Elixhauser%20comorbidity%20method%20outperforms%20the%20Charlson%20index%20in%20predicting%20inpatient%20death%20after%20orthopaedic%20surgery&as_occt=title&as_sauthors=%20%22ME%20Menendez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Schoenfeld AJ</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Harris MB</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Liu H</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Birkmeyer JD</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Variations in Medicare payments
                  for episodes of spine surgery</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2014 Dec 1;14(12):2793-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Variations%20in%20Medicare%20payments%20for%20episodes%20of%20spine%20surgery&as_occt=title&as_sauthors=%20%22AJ%20Schoenfeld%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Kazarian ER</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Lopez WY</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Eizember S</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Blucher JA</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Culley DJ</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Javedan H</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Kang JD</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Schoenfeld AJ</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Incidental durotomy is
                  associated with increased risk of delirium in patients aged 65 and older</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2020 Sep 1;45(17):1215-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidental%20durotomy%20is%20associated%20with%20increased%20risk%20of%20delirium%20in%20patients%20aged%2065%20and%20older&as_occt=title&as_sauthors=%20%22ER%20Kazarian%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Avila MJ</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Farber SH</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Rabah NM</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Hopp MJ</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Chapple KM</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Hurlbert RJ</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Tumialán LM</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Nonoperative versus operative
                  management of type II odontoid fracture in older adults: a systematic review and meta-analysis</span>.
                <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J Neurosurg
                  Spine.</span> 2023 Sep 29;40(1):45-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nonoperative%20versus%20operative%20management%20of%20type%20II%20odontoid%20fracture%20in%20older%20adults%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22MJ%20Avila%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Graffeo CS</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Perry A</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Puffer RC</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Carlstrom LP</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Chang W</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Mallory GW</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Clarke MJ</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Deadly falls: operative versus
                  nonoperative management of type II odontoid process fracture in octogenarians</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">J Neurosurg Spine.</span>
                2017 Jan;26(1):4-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Deadly%20falls%3A%20operative%20versus%20nonoperative%20management%20of%20type%20II%20odontoid%20process%20fracture%20in%20octogenarians&as_occt=title&as_sauthors=%20%22CS%20Graffeo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Barlow DR</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Higgins BT</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Ozanne EM</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Tosteson ANA</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Pearson AM</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Cost effectiveness of operative
                  versus non-operative treatment of geriatric type-II odontoid fracture</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2016 Apr;41(7):610-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cost%20effectiveness%20of%20operative%20versus%20non-operative%20treatment%20of%20geriatric%20type-II%20odontoid%20fracture&as_occt=title&as_sauthors=%20%22DR%20Barlow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Chapman J</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Smith JS</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Kopjar B</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Vaccaro AR</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Arnold P</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Fehlings MG</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">The AOSpine North America
                  Geriatric Odontoid Fracture Mortality Study: a retrospective review of mortality outcomes for
                  operative versus nonoperative treatment of 322 patients with long-term follow-up</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2013 Jun 1;38(13):1098-104.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20AOSpine%20North%20America%20Geriatric%20Odontoid%20Fracture%20Mortality%20Study%3A%20a%20retrospective%20review%20of%20mortality%20outcomes%20for%20operative%20versus%20nonoperative%20treatment%20of%20322%20patients%20with%20long-term%20follow-up&as_occt=title&as_sauthors=%20%22J%20Chapman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;39b539eb-0a9e-490f-9d21-f02fda1c9f4c&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[&quot;sp&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=39b539eb-0a9e-490f-9d21-f02fda1c9f4c&type=supplement&name=10515"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fhub.jbjs.org%2Freader.php%3Frsuite_id%3D39b539eb-0a9e-490f-9d21-f02fda1c9f4c%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2F1850%26topics%3Dsp"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): 1850</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00835</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 19, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_29"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Center
                            for Surgery and Public Health, Brigham and Women’s Hospital, Harvard Medical School, Boston,
                            Massachusetts</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;39b539eb-0a9e-490f-9d21-f02fda1c9f4c&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;sp&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=39b539eb-0a9e-490f-9d21-f02fda1c9f4c&type=pdf&name=JBJS.23.00835.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=39b539eb-0a9e-490f-9d21-f02fda1c9f4c&type=pdf&name=JBJS.23.00835.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_34"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was funded
                          through a grant from the National Institutes of Health – National Institute on Aging
                          (R01AG067507). The <span data-id="strong_35" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I75"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I75</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;39b539eb-0a9e-490f-9d21-f02fda1c9f4c&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;sp&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=39b539eb-0a9e-490f-9d21-f02fda1c9f4c&type=zip&name=JBJS.23.00835.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Andrew J. Schoenfeld, MD, MSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:ajschoen@neomed.edu" class="" style="position: unset;">ajschoen@neomed.edu</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3691-1215" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3691-1215</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lingwei Xiang, MPH<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0361-103X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0361-103X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Rachel R. Adler, ScD, RD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9482-7417" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9482-7417</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alyssa L. Schoenfeld<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-5135-5078" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-5135-5078</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">James D. Kang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7499-2909" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7499-2909</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joel S. Weissman, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4990-6263" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4990-6263</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Center for Surgery and Public Health,
                Brigham and Women’s Hospital, Harvard Medical School, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Surgery, Center for Surgery and Public Health, Brigham and
                Women’s Hospital, Harvard Medical School, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Medfield Public Schools, Medfield, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 26807.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
