import { isWeb } from "@helpers/platform";
import { Box } from "@memorang/ui";
import ContentLoader from "react-native-easy-content-loader";

export const ProductsSectionSkeleton = () => {
	const numRows = isWeb ? 2 : 1;
	return (
		<Box gap={24} paddingTop={4} paddingBottom={12} paddingHorizontal={16}>
			{[...Array(numRows).keys()].map((item) => {
				return (
					// @ts-ignore
					<Box
						key={item}
						width="100%"
						justifyContent="space-between"
						alignItems="center"
						flexDirection="row"
					>
						{/* @ts-ignore */}
						<ContentLoader
							titleStyles={{
								marginTop: 14,
								height: 8,
								marginLeft: 12,
							}}
							avatarStyles={{
								marginLeft: -8,
								marginTop: 16,
							}}
							paragraphStyles={{
								marginLeft: 12,
								width: "60%",
							}}
							containerStyles={{
								width: 300,
							}}
							avatar
							aSize={24}
							aShape="square"
							title
							pRows={1}
						/>
						{/* @ts-ignore */}
						<ContentLoader
							pWidth={80}
							avatar={false}
							title={false}
							pRows={1}
							pHeight={12}
							containerStyles={{
								flex: 1,
							}}
							paragraphStyles={{
								marginTop: 8,
								alignSelf: "flex-end",
							}}
						/>
					</Box>
				);
			})}
		</Box>
	);
};
