/* ----------------- React Native Paper Imports --------------- */
import { Appbar } from "react-native-paper";

/* ----------------- Memorang UI Imports --------------- */
import { Box, CustomBreadcrumb, EmptyState } from "@memorang/ui";

/* ----------------- Expo Router Imports --------------- */
import { router, useLocalSearchParams } from "expo-router";

/* ----------------- Local Hooks Imports --------------- */
import { useTakeChallenge } from "../hooks/useTakeChallenge";

/* ----------------- Local Components Imports --------------- */
import TakeChallengesView from "../components/TakeChallengesView";

/* ----------------- Global Components Imports --------------- */
import { ScrollContainer } from "@memorang/ui";
import { QuickActionChips } from "components/QuickActionChips";
import type { Variant } from "../types";

const TakeChallengesContainer = () => {
	const { takeChallengesList, quickActionsChips, handleQuickActionPress } =
		useTakeChallenge();

	const { id } = useLocalSearchParams<{
		id: string;
	}>();

	const breadcrumbs = [
		{
			label: "Challenges",
			action: () => router.back(),
		},
		{
			label: id,
		},
	];

	const handlePressChallenge = (variant: Variant) => {
		if (variant === "flip") {
			router.push({
				pathname: "/feedback",
				params: {
					isComingSoon: "true",
					view: "take-challenge",
				},
			});
			return;
		}
		// updateSession({
		// 	//TODO:Temporarily mockedSessionResponse
		// 	//@ts-ignore
		// 	sessionResponse: getMockedSessionResponse(),
		// 	mode: "QUESTION",
		// });
		router.replace({
			pathname: "/(protected)/session/[id]",
			params: {
				id: "34343",
			},
		});
	};

	return (
		<>
			<Appbar.Header mode="medium" elevated>
				<Appbar.BackAction onPress={() => router.back()} />
				<Appbar.Content
					title={
						<CustomBreadcrumb
							textVariant="titleLarge"
							breadcrumbs={breadcrumbs}
						/>
					}
				/>
			</Appbar.Header>
			<ScrollContainer>
				<Box paddingTop={16}>
					<QuickActionChips
						quickActionsChips={quickActionsChips}
						handleQuickActionPress={handleQuickActionPress}
					/>
					{takeChallengesList?.length ? (
						<TakeChallengesView
							challenges={takeChallengesList}
							handlePressChallenge={handlePressChallenge}
						/>
					) : (
						<Box flexGrow={1} height={"80%"}>
							<EmptyState
								title={"No results found"}
								subtitle={"Try changing your filters"}
							/>
						</Box>
					)}
				</Box>
			</ScrollContainer>
		</>
	);
};

export default TakeChallengesContainer;
