import { Box } from "@memorang/ui";
import CustomListItem from "@memorang/ui/src/components/CustomListItem";
import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";
import { List, RadioButton } from "react-native-paper";
import { CME_EXPORT_CONSTANTS } from "../constants";
import type { CmeType } from "../types";

type Props = {
	selectedType: CmeType;
	onSelect: (id: CmeType) => void;
};

export const CMETypeSelector = ({ selectedType, onSelect }: Props) => {
	return (
		<Box>
			<CustomListSubheaderContainer>CME type</CustomListSubheaderContainer>
			{CME_EXPORT_CONSTANTS.CME_TYPES.map((type) => (
				<CustomListItem
					key={type.id}
					title={type.title}
					description={type.subtitle}
					style={{
						paddingHorizontal: 16,
					}}
					onPress={() => onSelect(type.id)}
					titleStyle={{
						fontFamily: "OpenSans-SemiBold",
					}}
					descriptionStyle={{
						fontSize: 12,
						fontFamily: "OpenSans-SemiBold",
					}}
					left={() => <List.Icon icon={type.icon} />}
					right={(p) => (
						<List.Icon
							{...p}
							icon={() => {
								return (
									<RadioButton.Android
										value={type.id}
										status={selectedType === type.id ? "checked" : "unchecked"}
										onPress={() => onSelect(type.id)}
									/>
								);
							}}
						/>
					)}
				/>
			))}
		</Box>
	);
};
