/* -----------------Globals--------------- */
import { useAppTheme } from "@hooks/useAppTheme";

/* -----------------Types--------------- */
import type { SectionTag } from "../types/tag";

/* -----------------Child components--------------- */
import CheckboxListItem from "./CheckboxListItem";
import ListAccordion from "./ListAccordion";

interface Props {
	selectedCheckboxMap?: Record<string, boolean>;
	sectionTags: SectionTag[];
	handleSelectCheckbox: (distKey: string, locked?: boolean) => void;
	disableAll?: boolean;
}
const TagFiltersList = ({
	selectedCheckboxMap,
	handleSelectCheckbox,
	sectionTags,
	disableAll,
}: Props) => {
	const theme = useAppTheme();
	const accordions = sectionTags
		.filter((item) => item.tags.length > 0)
		.map(({ title, tags }) => {
			return {
				title,
				children: tags.map(({ id, title, items, locked }) => {
					const count = items.length;
					const status = selectedCheckboxMap?.[id] ? "checked" : "unchecked";
					return (
						<CheckboxListItem
							key={id}
							title={title}
							disableAll={disableAll}
							textColor={theme.colors.text}
							status={status}
							locked={locked}
							count={count}
							handleSelectCheckbox={() =>
								handleSelectCheckbox(String(id), locked)
							}
						/>
					);
				}),
			};
		});
	return <ListAccordion accordions={accordions} />;
};

export default TagFiltersList;
