import type { Article } from "../../../../../types";

export const Article659: Article = {
	id: 659,
	rsuiteId: "1077c6cc-ba10-4286-9472-5d2ff2a501bd",
	type: "scientific article",
	title:
		"A Novel Preoperative Scoring System to Accurately Predict Cord-Level Intraoperative Neuromonitoring Data Loss During Spinal Deformity Surgery",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1077c6cc-ba10-4286-9472-5d2ff2a501bd&type=jpeg&name=JBJS.24.00386f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">A Novel Preoperative Scoring System to Accurately Predict
                Cord-Level Intraoperative Neuromonitoring Data Loss During Spinal Deformity Surgery</div>
              <div class="text subtitle" style="position: unset;">A Machine-Learning Approach</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Nathan J.
                      Lee, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Lawrence G.
                      Lenke, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 22 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Background:</span> An accurate knowledge of a patient’s risk of
                        cord-level intraoperative neuromonitoring (IONM) data loss is important for an informed
                        decision-making process prior to deformity correction, but no prediction tool currently exists.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Methods:</span> A total of 1,106 patients with spinal deformity and
                        205 perioperative variables were included. A stepwise machine-learning (ML) approach using
                        random forest (RF) analysis and multivariable logistic regression was performed. Patients were
                        randomly allocated to training (75% of patients) and testing (25% of patients) groups. Feature
                        score weights were derived by rounding up the regression coefficients from the multivariable
                        logistic regression model. Variables in the final scoring calculator were automatically selected
                        through the ML process to optimize predictive performance.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Results:</span> Eight features were included in the scoring system:
                        sagittal deformity angular ratio (sDAR) of ≥15 (score = 2), type-3 spinal cord shape (score =
                        2), conus level below L2 (score = 2), cervical upper instrumented vertebra (score = 2),
                        preoperative upright largest thoracic Cobb angle of ≥75° (score = 2), preoperative
                        lower-extremity motor deficit (score = 2), preoperative upright largest thoracic kyphosis of
                        ≥80° (score = 1), and total deformity angular ratio (tDAR) of ≥25 (score = 1). Higher cumulative
                        scores were associated with increased rates of cord-level IONM data loss: patients with a
                        cumulative score of ≤2 had a cord-level IONM data loss rate of 0.9%, whereas those with a score
                        of ≥7 had a loss rate of 86%. When evaluated in the testing group, the scoring system achieved
                        an accuracy of 93%, a sensitivity of 75%, a specificity of 94%, and an AUC (area under the
                        receiver operating characteristic curve) of 0.898.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Conclusions:</span> This is the first study to provide an ML-derived
                        preoperative scoring system that predicts cord-level IONM data loss during pediatric and adult
                        spinal deformity surgery with &gt;90% accuracy.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Minimizing the risk of neurologic deficit in spinal
                  deformity surgery is a priority for every spine surgeon. Despite advancements in technology and
                  surgical techniques, the prevalence of intraoperative neurologic injury can be as high as 23%,
                  particularly among patients undergoing complex spinal deformity reconstruction<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">2</span></a>.
                  These complications can be detrimental to the quality of life of the patient, can increase the risk of
                  additional adverse medical events due to decreased mobility, and can place a profound burden on the
                  affected patient and their family.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">To mitigate the risk of neurologic deficit, intraoperative
                  neuromonitoring (IONM) emerged as a standard of care in spinal deformity surgery, as it enables
                  immediate intraoperative intervention when potential injuries to the spinal cord are suspected<a
                    href="" data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a>. As a result, consensus-based best practice guidelines have
                  been introduced to address IONM events for adult and pediatric spinal deformity surgeries<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a>.
                  Furthermore, prior studies have reported potential risk factors for IONM events, including older age,
                  a larger coronal deformity angular ratio (cDAR), the performing of a lumbar osteotomy, and a type-3
                  spinal cord shape<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">9</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Although an accurate knowledge of a patient’s relative
                  risk of cord-level IONM data loss is important for an informed decision-making process prior to
                  deformity correction, no preoperative risk stratification tool currently exists. By leveraging a
                  machine-learning (ML) approach, we aimed to develop the first preoperative prediction tool for
                  cord-level IONM data loss during adult and pediatric spinal deformity surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">This study was conducted and reported in compliance with
                  the STROBE (Strengthening the Reporting of Observational Studies in Epidemiology) statement.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Enrollment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Following institutional review board approval (AAAU8101),
                  a retrospective review was conducted of a prospective, consecutively collected database of pediatric
                  and adult spinal deformity surgeries performed by a single senior surgeon between 2015 and 2023.
                  Patients with any spinal deformity diagnosis and of any age group who underwent elective
                  posterior-based fusion were included (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). Both
                  primary and revision procedures were included. Patients with acute spinal trauma, an active infection,
                  or a spinal tumor were excluded. The minimum follow-up time was the duration of the patient’s
                  postoperative inpatient stay.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Characteristics and Perioperative
                          Factors<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 5028px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">All (N =
                          1,106)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Training
                          Group (N = 829)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Testing
                          Group (N = 277)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">66.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">67.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">72.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.3 ±
                          20.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.1 ±
                          20.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.0 ±
                          20.1</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_39" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_40" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_5" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_5"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_41"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.3 ±
                          5.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.3 ±
                          5.89</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.1 ±
                          5.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Primary diagnosis</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Idiopathic
                          scoliosis</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">34.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">34.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">35.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Degenerative
                          scoliosis</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">29.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">28.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">31.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Revision-related
                          complication</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Syndromic
                          scoliosis</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.2%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Neuromuscular
                          scoliosis</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Congenital or
                          early-onset scoliosis</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Scheuermann
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Post-radiation
                          therapy</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other fixed sagittal
                          malalignment</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Spondylolisthesis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Prior spine
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">42.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">42.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">40.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medical risk
                          factors</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ASA
                          classification</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0 ±
                          0.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0 ±
                          0.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0 ±
                          0.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> CCI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9 ±
                          1.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9 ±
                          1.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9 ±
                          1.3</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteoporosis</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Any smoking
                          history</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.2%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Any cardiac
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.2%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypertension</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hyperlipidemia</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Peripheral vascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Myocardial
                          infarction</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Congestive heart
                          failure</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.4%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Stroke</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> COPD</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.2%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Connective tissue
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> GERD</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.0%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Any liver disease</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.4%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypothyroidism</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Chronic kidney
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.2%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-spinal cancer</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Venous
                          thromboembolism</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop. IVC filter</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.2%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. examination</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lower-extremity motor
                          deficit</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">21.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">21.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Myelopathy</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Radiculopathy</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">24.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">25.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">21.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. imaging</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> CVA ≥ 40 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">29.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">29.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">29.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> SVA ≥ 40 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">22.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">22.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">21.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Largest thoracic Cobb
                          angle ≥ 75°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Largest TK ≥ 80°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.4%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> LL <span
                            data-id="emphasis_42" class="annotation emphasis" style="position: unset;">(°)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45.8 ±
                          22.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.0 ±
                          22.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45.0 ±
                          21.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PT <span
                            data-id="emphasis_43" class="annotation emphasis" style="position: unset;">(°)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.1 ±
                          14.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.6 ±
                          14.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.8 ±
                          14.2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PI <span
                            data-id="emphasis_44" class="annotation emphasis" style="position: unset;">(°)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52.3 ±
                          15.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51.9 ±
                          15.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53.5 ±
                          16.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Coronal stiffness ≤
                          10°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.8%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Sagittal stiffness ≤
                          10°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> sDAR</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.7 ±
                          4.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.8 ±
                          5.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.5 ±
                          4.1</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> cDAR</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.0 ±
                          5.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.0 ±
                          5.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.1 ±
                          4.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> tDAR</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.8 ±
                          8.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.8 ±
                          8.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.6 ±
                          7.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Conus level</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Above L1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">36.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">35.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">37.2%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  L1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  L1-L2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">41.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">41.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">40.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  L2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.4%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Below L2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Spinal cord shape
                          type</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">69.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">69.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">71.8%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">21.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Operative factors</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop. halo
                          traction</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Intraop. traction</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Halo gravity</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.2%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Gardner-Wells
                          tongs</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">27.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">26.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">28.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No weight</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">53.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">53.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">50.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Staged</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.8%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> TIL</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.3 ±
                          4.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.4 ±
                          4.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.1 ±
                          4.5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> UIV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Cervical</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.0%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Upper thoracic</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">70.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">70.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">70.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Lower thoracic</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Lumbar</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.4%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> LIV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Thoracic</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.2%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Lumbar</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">43.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">43.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">44.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Ilium</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">50.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">51.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">48.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Any 3CO</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No. of TLIFs</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5 ±
                          0.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5 ±
                          0.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5 ±
                          0.9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Operative time <span
                            data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(min)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">409.0 ±
                          159.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">409.1 ±
                          158.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">408.7 ±
                          163.3</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dural tear</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Blood loss</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop. Hgb <span
                            data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(g/dL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.2 ±
                          4.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.2 ±
                          4.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.9 ±
                          1.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop. Hct <span
                            data-id="emphasis_47" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.8 ±
                          4.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.9 ±
                          5.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.5 ±
                          4.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lowest intraop. Hgb
                          <span data-id="emphasis_48" class="annotation emphasis"
                            style="position: unset;">(g/dL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.6 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.7 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.4 ±
                          1.5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lowest intraop. Hct
                          <span data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.8 ±
                          5.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.1 ±
                          5.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.1 ±
                          4.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POD1 Hgb <span
                            data-id="emphasis_50" class="annotation emphasis"
                            style="position: unset;">(g/dL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.8 ±
                          2.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.8 ±
                          2.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.6 ±
                          2.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POD1 Hct <span
                            data-id="emphasis_51" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.6 ±
                          6.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.7 ±
                          6.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.0 ±
                          7.3</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> TXA amount <span
                            data-id="emphasis_52" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,192.1 ±
                          1,148.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,205.8 ±
                          1,146.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,150.5 ±
                          1,153.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> EBL <span
                            data-id="emphasis_53" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,046.7 ±
                          695.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,046.2 ±
                          672.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,048.1 ±
                          762.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cell salvage <span
                            data-id="emphasis_54" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">351.9 ±
                          277.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">351.8 ±
                          263.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">352.3 ±
                          315.9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Any intraop. pRBC</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">29.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">30.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">26.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Intraop. pRBC <span
                            data-id="emphasis_55" class="annotation emphasis"
                            style="position: unset;">(units)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 ±
                          1.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 ±
                          1.3</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the percentage of patients or as the mean ±
                        standard deviation. BMI = body mass index, ASA = American Society of Anesthesiologists, CCI =
                        Charlson Comorbidity Index, COPD = chronic obstructive pulmonary disease, GERD =
                        gastroesophageal reflux disease, IVC = inferior vena cava, CVA = coronal vertical axis, SVA =
                        sagittal vertical axis, TK = thoracic kyphosis, LL = lumbar lordosis, PT = pelvic tilt, PI =
                        pelvic incidence, sDAR = sagittal deformity angular ratio, cDAR = coronal deformity angular
                        ratio, tDAR = total deformity angular ratio, TIL = total instrumented levels, UIV = upper
                        instrumented vertebra, LIV = lower instrumented vertebra, 3CO = 3-column osteotomy, TLIF =
                        transforaminal lumbar interbody fusion, Hgb = hemoglobin, Hct = hematocrit, POD1 = postoperative
                        day 1, TXA = tranexamic acid, EBL = estimated blood loss, pRBC = packed red blood cells.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Collection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">A comprehensive set of 205 perioperative variables were
                  analyzed, including patient demographic characteristics, primary diagnosis, medical comorbidities,
                  preoperative physical examination findings, prior spine surgery, operative factors, perioperative
                  hemoglobin (Hgb) and hematocrit (Hct) values, preoperative and intraoperative radiographic measures,
                  and preoperative cervical, thoracic, and lumbar magnetic resonance imaging (MRI) and computed
                  tomography (CT) scan findings (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>; see
                  also Appendix Supplementary Materials and Methods for further details). Data regarding patient race
                  and ethnicity were not included.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Extensive measurements on preoperative and intraoperative
                  radiographs and on preoperative MRI and CT scans were performed, including alignment (coronal and
                  sagittal vertical axes), thoracic Cobb measurements of both major and minor curves in the upright and
                  supine positions, thoracic kyphosis (TK), lumbar lordosis (LL), pelvic incidence (PI), pelvic tilt
                  (PT), coronal and sagittal deformity angular ratios (cDAR and sDAR, respectively)<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">8</span></a>, conus medullaris level of termination, and spinal cord
                  shape type<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Outcome</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcome was cord-level IONM data loss, which
                  included patients who either had complete IONM cord data loss or met institutional warning criteria.
                  Specifically, the warning criteria for IONM cord data loss are based on somatosensory evoked
                  potentials (SSEPs; warning criteria = 10% increase in latency or &gt;50% loss in amplitude) and motor
                  evoked potentials (MEPs; warning criteria = 75% loss in amplitude without a return to acceptable
                  limits after stimulation up to 100 V above the baseline level) after all technical variables are ruled
                  out<a href="" data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">10</span></a>. In the literature review by Angelliaume et al.<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">10</span></a>, higher threshold values of &gt;75% transcranial MEP data
                  decline were not associated with more neurologic deficit than lower threshold values. Furthermore,
                  false-negative rates did not significantly differ by threshold. To distinguish between cord-level and
                  root-level IONM data loss in the present study, all IONM data were reviewed with the senior surgeon
                  and the senior member of the IONM team and cross-referenced with the operative note. The loss of any
                  single muscle group was designated as a root-level IONM data loss, whereas the loss of ≥3 muscle
                  responses generally indicated a cord event.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Model
                Development</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">A stepwise ML approach using random forest (RF) decision
                  tree analysis and multivariable logistic regression was performed. Patients were randomly allocated to
                  either the training group (75% of patients) or the testing group (25% of patients). All model
                  development and testing were performed with use of the training data set, and all performance metrics
                  are presented following testing of the model on blinded testing data. All ML analysis was performed
                  with use of scikit-learn (version 0.24.2) in Python (version 3.9.18; Python Software Foundation).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Feature selection was performed with use of feature
                  importance weights generated from the training of the RF model in tandem with a Boruta analysis.
                  Features that were automatically selected for inclusion, as determined from the Boruta analysis, were
                  included for score development (see Appendix Supplementary Figure 1). More details regarding the ML
                  approach, Boruta analysis, and missing data can be found in the Appendix Supplementary Materials and
                  Methods.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Multivariable logistic regression analysis was performed
                  to determine scores by rounding up β (regression) coefficients to the nearest integer<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">13</span></a>. Rates of cord-level IONM data loss were then calculated
                  and stratified by cumulative score and reported for all patients. Model performance was assessed on
                  the basis of accuracy, sensitivity, specificity, and the area under the receiver operating
                  characteristic curve (AUC). Chi-square tests were utilized for categorical variables and t tests were
                  utilized for continuous variables. Significance was set at p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Sample</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">A total of 1,106 patients were included in the study
                  (training group, 829 patients; testing group, 277 patients). Perioperative factors are summarized in
                  <a href="" data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>. The overall incidence of cord-level IONM data loss was 4.9% (n
                  = 54). The cause of IONM cord data loss was MEP loss in 96.3% of patients (52 of 54) and SSEP loss in
                  40.7% of patients (22 of 54), with loss of both occurring in 37.0% of the patients with IONM cord data
                  loss (20 of 54). All patients with IONM cord data loss underwent an intraoperative intervention, and
                  33.3% (18 of 54) had a neurologic deficit, relative to their preoperative motor examination, on
                  postoperative day 1. In the training group, the mean age (and standard deviation) was 35.1 ± 20.9
                  years, the majority (67.6%) of the patients were female, the most common diagnosis was idiopathic
                  scoliosis (34.4%), and 42.9% of the patients had previously undergone a spine surgery. The mean total
                  instrumented levels (TIL) in the training group was 13.4 ± 4.2, the mean operative time was 409 ± 159
                  minutes, the mean estimated blood loss (EBL) was 1,046 ± 673 mL, and the mean transfusion amount was
                  0.6 ± 1.6 units. The training group was older than the testing group (mean age, 35.1 ± 20.9 versus
                  32.0 ± 20.1 years; p = 0.03) and had a lower rate of prior smoking (11.6% vs. 16.2%; p = 0.044), a
                  higher lowest intraoperative Hgb (mean, 9.7 ± 1.6 g/dL vs. 9.4 ± 1.5 g/dL; p = 0.005), and a higher
                  lowest intraoperative Hct (mean, 29.1% ± 5.0% vs. 28.1% ± 4.7%; p = 0.005).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Rate of
                Cord-Level IONM Data Loss</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">The rates of cord-level IONM data loss in the training and
                  testing groups were 4.8% and 5.1%, respectively. Based on the number of features that were utilized
                  for model training, the testing group had an adequate number of events for model and score
                  development<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">16</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Development
                of the Score Calculator</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Of the variables analyzed, the top 20 are ranked by
                  importance in <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>. After
                  Boruta analysis, the following 8 features, with assigned scores, were included in the score
                  calculator: sDAR of ≥15 (score = 2), type-3 spinal cord shape (score = 2), conus level below L2 (score
                  = 2), cervical upper instrumented vertebra (UIV; score = 2), preoperative upright largest thoracic
                  Cobb angle of ≥75° (score = 2), preoperative lower-extremity motor deficit (score = 2), preoperative
                  upright largest TK of ≥80° (score = 1), and total deformity angular ratio (tDAR) of ≥25 (score = 1)
                  (<a href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). A decrease of ≥12% in Hct from the preoperative to
                  intraoperative time points was initially included on the basis of the Boruta analysis; however, this
                  feature was removed in order to assess model performance on preoperative factors alone. Cumulative
                  cord-level IOM loss rates increased with increasing total scores (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>) in both groups. The score distribution within the cohort
                  demonstrated that an increasing level of risk, from low (total score of 0 to 2) to moderate (3 to 6)
                  to high (7 to 12), corresponded with increasing rates of cord-level IONM data loss (0.9%, 19.8%, and
                  85.7%, respectively; <a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Ranking of the Top 20 Features<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1292px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Feature</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β
                          Coefficient Magnitude</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean RF
                          Importance</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Boruta
                          Inclusion</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Conus level below
                          L2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.86</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.118</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Yes</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Type-3 spinal cord
                          shape</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.81</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.097</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Yes</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">UIV, cervical</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.20</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.039</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Yes</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. lower-extremity
                          motor deficit</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.07</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.037</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Yes</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. upright largest
                          thoracic Cobb angle ≥ 75°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.063</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Yes</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">sDAR ≥ 15</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.056</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Yes</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">COPD</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.83</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.015</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">No</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sagittal stiffness ≤
                          10°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.74</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.037</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">No</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. upright largest TK
                          ≥ 80°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.72</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.058</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Yes</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. to intraop. Hct
                          decrease ≥ 12%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.62</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.034</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Yes</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">tDAR ≥ 25</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.060</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Yes</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Any 3CO</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.43</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.021</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">No</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CCI</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.43</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.005</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">No</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dural tear</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.41</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.009</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">No</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age category, 40 to 64
                          yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.39</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.012</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">No</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">cDAR</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.24</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.027</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">No</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age category: 18 to 40
                          yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.09</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.018</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">No</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiculopathy</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.025</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">No</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Myelopathy</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.015</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">No</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. to intraop. Hgb
                          decrease ≥ 4 g/dL</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.029</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">No</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">RF = random forest, UIV = upper instrumented vertebra, sDAR = sagittal
                        deformity angular ratio, COPD = chronic obstructive pulmonary disease, TK = thoracic kyphosis,
                        Hct = hematocrit, tDAR = total deformity angular ratio, 3CO = 3-column osteotomy, CCI = Charlson
                        Comorbidity Index, cDAR = coronal deformity angular ratio, Hgb = hemoglobin.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Score for Each Predictor<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 362px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Predictor</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Score</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Conus level below
                          L2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Type-3 spinal cord
                          shape</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">UIV, cervical</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. upright largest
                          thoracic Cobb angle ≥ 75°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">sDAR ≥ 15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. lower-extremity
                          motor deficit</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. upright largest TK
                          ≥ 80°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">tDAR ≥ 25</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">UIV = upper instrumented vertebra, sDAR = sagittal deformity angular
                        ratio, TK = thoracic kyphosis, tDAR = total deformity angular ratio.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00386f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1077c6cc-ba10-4286-9472-5d2ff2a501bd&type=jpeg&name=JBJS.24.00386f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Cumulative cord-level IONM data loss rate versus
                          risk score.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Cord-Level IONM Data Loss Rates in the Cohort
                          Stratified by Risk Score (N = 1,106)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 774px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Risk
                          Score</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">IONM Cord Data Loss <span data-id="emphasis_56"
                            class="annotation emphasis" style="position: unset;">(no. of patients)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Loss Rate</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Yes</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Low risk</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">963</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">677</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">270</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Moderate risk</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.8%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">35.0%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">33.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">High risk</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">85.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">42.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100.0%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100.0%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100.0%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100.0%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100.0%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Model
                Performance</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The performance of the ML-generated score had an AUC of
                  0.921 and 0.898 on the training and testing data sets, respectively (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). When evaluated in the testing group with use of a threshold
                  score of 3, the scoring system achieved an accuracy of 93.1%, a sensitivity of 75%, and a specificity
                  of 94%.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00386f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1077c6cc-ba10-4286-9472-5d2ff2a501bd&type=jpeg&name=JBJS.24.00386f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;">Graphs showing the AUC (area under the receiver
                          operating characteristic curve) for the training and testing groups.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Case
                Examples</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Patient A was a 47-year-old female with fixed coronal and
                  sagittal malalignment, solid fusion from T2 to the sacrum, and a prior Chiari decompression surgery
                  (<a href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 3</a> and <a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">4</a>). She did not
                  have a preoperative motor deficit. She underwent T2-ilium posterior instrumented fusion (PIF) with a
                  T9 vertebral column resection (VCR). Her only IONM preoperative risk factor was an upright TK of ≥80°
                  (<a href="" data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). Based on the total risk score of 1, she was deemed “low risk.”
                  She had no IONM changes and a normal postoperative neurological examination.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00386f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1077c6cc-ba10-4286-9472-5d2ff2a501bd&type=jpeg&name=JBJS.24.00386f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;">Preoperatively, patient A was determined to have a
                          low risk of IONM cord data loss (total score = 1). This patient did not experience cord-level
                          IONM data loss when she underwent surgery. MRI scans were T2-weighted. SCS = spinal cord
                          shape.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00386f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1077c6cc-ba10-4286-9472-5d2ff2a501bd&type=jpeg&name=JBJS.24.00386f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;">Patient A preoperative and postoperative upright
                          radiographs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Patient B was a 17-year-old male with congenital thoracic
                  kyphoscoliosis, congenital autofusion from L4 to the sacrum, and a prior tethered cord release (<a
                    href="" data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>). He underwent T1-ilium PIF with an L3 pedicle subtraction
                  osteotomy (PSO) and T11-L1 VCRs (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>). His
                  preoperative IONM risk factors were a type-3 spinal cord shape (score = 2), a thoracic Cobb angle of
                  87° (score = 2), an sDAR of ≥15 (score = 2), preoperative weakness in the left iliopsoas muscle (score
                  = 2), a tDAR of ≥25 (score = 1), and thoracolumbar kyphosis of 83° (score = 1) (<a href=""
                    data-id="figure_reference_14" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>). With a preoperative total score of 10, he was deemed “high
                  risk.” Complete MEP loss in all lower-extremity muscle groups bilaterally and diminished SSEP
                  bilaterally was observed after the T11 and T12 VCRs, specifically after using a cantilever-rod
                  technique on the site of the VCR during VCR closure and deformity correction. After IONM cord data
                  loss, the mean arterial pressure was increased to 100 mm Hg. Permanent rods were replaced with
                  temporary rods because a subluxation, with circumferential stenosis, was noted at T12-L1, which
                  required an additional laminectomy and an additional L1 VCR. SSEPs and MEPs partially improved by the
                  time of closure. On postoperative day 1, the patient had a motor strength of 4 out of 5 in the
                  iliopsoas, quadriceps, and hamstrings bilaterally, which improved to full strength by the time of
                  discharge on postoperative day 9, with the patient able to walk &gt;350 ft (106.7 m).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00386f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1077c6cc-ba10-4286-9472-5d2ff2a501bd&type=jpeg&name=JBJS.24.00386f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;">Preoperatively, patient B was determined to have a
                          high risk of IONM cord data loss (total score = 10). This patient experienced complete
                          cord-level IONM data loss after VCR. MRI scans were T2-weighted. SCS = spinal cord shape.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.24.00386f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1077c6cc-ba10-4286-9472-5d2ff2a501bd&type=jpeg&name=JBJS.24.00386f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;">Patient B preoperative and postoperative upright
                          radiographs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Historically, intraoperative neurologic assessment relied
                  on the Stagnara wake-up test. Over the last 20 years, several studies have demonstrated IONM as a safe
                  and reliable mechanism for detecting an impending spinal cord injury<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">17</span></a>. As a result, the Scoliosis Research Society considers IONM
                  to be a standard of care for spinal deformity surgery<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">3</span></a>.
                  Despite efforts to minimize the risk of neurologic deficit through best-practice guidelines and
                  checklists, the incidence of postoperative neurologic deficits varies widely, from 1% to 23%, and is
                  highest among patients undergoing complex spinal deformity surgery<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_14" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_14" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">21</span></a>. Recognizing that the risk of intraoperative cord-level
                  IONM data loss may be inherently related to patient-specific factors, we utilized an ML approach to
                  create the first preoperative risk stratification tool for adult and pediatric patients with spinal
                  deformity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Overall, cord-level IONM data loss occurred in 4.9% of
                  patients in the present study. Through ML algorithms, this study identified 8 preoperative risk
                  factors that were the most important for predicting cord-level IONM data loss: an sDAR of ≥15, a
                  type-3 spinal cord shape, a conus level below L2, a cervical UIV, a preoperative upright largest
                  thoracic Cobb angle of ≥75°, a preoperative lower-extremity motor deficit, a preoperative upright
                  largest TK of ≥80°, and a tDAR of ≥25. Several of these features have been supported in prior
                  literature.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The DAR, which is utilized to assess the segmental
                  angularity of a deformity, is an important preoperative radiographic parameter to consider<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_15" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">24</span></a>. In a retrospective study of 202 pediatric and adult
                  patients with spinal deformity, Wang et al. reported incidences of 20.5% and 4.0% for IONM cord data
                  loss and new neurologic deficits, respectively<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">8</span></a>. The
                  incidence of IONM cord data loss in that study was relatively high because all patients required a
                  VCR. However, among these patients, they found that the rate of IONM cord data loss varied
                  significantly according to the angularity of the spinal deformity. Specifically, patients with a
                  higher sDAR (≥15) and tDAR (≥25) had significantly higher rates of IONM cord data loss and new
                  neurologic deficit. Recently, Illingworth et al. performed a retrospective review of 253 pediatric
                  patients with severe spinal deformity who underwent posterior fusion with or without a 3-column
                  osteotomy (3CO)<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">24</span></a>.
                  They found that IONM cord data loss was significantly associated with increased TK, sDAR, and tDAR,
                  even in the absence of a 3CO. Although 3COs are known to be high-risk procedures, the risk of
                  neurologic deficit may be more inherent to the preoperative angularity of the deformity than to the
                  procedure itself. A higher DAR implies a sharper angle of deformity and thus a higher risk of
                  stretching the spinal cord during deformity correction.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">In 2020, Sielatycki et al. introduced a novel spinal cord
                  shape classification system to identify patients at an increased risk for IONM cord data loss<a
                    href="" data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">7</span></a>. They found that patients with a type-3 spinal cord shape,
                  with the cord deformed against the concavity on the axial plane on MRI, had significantly higher odds
                  of IONM cord data loss than patients with a normal, type-1 spinal cord shape. Their findings have been
                  externally validated in other studies<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">27</span></a>. Keshavarzi et al. examined 155 patients with adolescent
                  idiopathic scoliosis (AIS) to further investigate type-3 cords<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">25</span></a>. They found that larger thoracic Cobb angles and apical
                  vertebral translation (AVT) were associated with type-3 cords, and that the risk factors for IONM cord
                  data loss included a type-3 cord, a thoracic Cobb angle of ≥65°, a cDAR of &gt;10, and an AVT of &gt;5
                  cm. TK and sDAR were not significant factors in the study by Keshavarzi et al., likely because
                  patients with AIS typically have thoracic hypokyphosis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">In a recent prospective cohort study of 256 adult and
                  pediatric patients, Bakhsheshian et al. found that type-3 cords had the greatest odds of IONM cord
                  data loss in a multivariable analysis<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">26</span></a>.
                  Other significant factors included greater DAR, 3CO, longer operative time, higher transfusion volume,
                  and greater sagittal corrections. The overall predictive performance was excellent, with an AUC of
                  0.827. Similarly, the present study demonstrated that a type-3 cord and a larger sDAR, tDAR, and TK
                  were important predictive variables. Although operative time, 3CO, and transfusion amount were
                  included in our initial analysis, they did not remain in the final model after the ML-derived feature
                  selection process.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">EBL is frequently cited as a significant risk factor,
                  largely due to its potential to result in spinal cord ischemia<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">30</span></a>. In a retrospective review of &gt;12,000 adult and
                  pediatric spine surgeries, poor spinal cord perfusion was the second most common cause of IONM alerts,
                  resulting in approximately 12% of the total alerts, and was second only to screw malposition, which
                  accounted for 30% of alerts<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">31</span></a>.
                  Spinal cord ischemia may result from prolonged hypotension, hypoxia due to decreased Hgb level, or
                  vascular compromise due to ligation of segmental vessels. The risk of ischemia is particularly
                  important in cases of severe kyphosis and high sDAR, in which stretching of the anterior spinal artery
                  during spinal column manipulation may further disrupt perfusion to the spinal cord. In the present
                  study, a decrease of ≥12% in Hct from the preoperative to intraoperative time points was included as a
                  feature in the ML feature selection process. However, transfusion amount and EBL were not considered
                  important features. This suggests that patients who have higher Hct values preoperatively should be
                  considered for a transfusion if there is a substantial decrease in Hct value intraoperatively despite
                  a relatively elevated intraoperative Hct level.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Other important features to consider include a low-lying
                  conus and preoperative lower-extremity motor deficits. A low-lying conus medullaris is typically
                  observed in patients with congenital conditions, such as tethered cord syndrome, spinal dysraphism,
                  and spina bifida. The tethering and/or retethering of the spinal cord leads to stretching of the
                  neural elements, resulting in chronic damage due to reduced oxygen and blood supply to the
                  conus-caudal region and predisposing patients to a higher risk of IONM cord data loss<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">32</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">33</span></a>. However, in certain instances, a low-lying conus
                  medullaris may remain asymptomatic and be unexpectedly identified during imaging studies that are
                  conducted for unrelated reasons. It seems possible that patients with an asymptomatic low-lying conus
                  due to a tethered cord could potentially benefit from prophylactic detethering; however, this
                  relationship was not directly examined in the present study and future work is needed to elucidate it.
                  According to several reports, neurologic complications appear to be more frequent in patients with
                  preexisting neurologic deficits and/or neuromuscular and congenital scoliosis<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">36</span></a>. The exclusion of primary diagnoses from the final ML model
                  in the present study suggests that specific diagnoses are less meaningful for predicting a compromised
                  spinal cord than the other preoperative factors.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">The IONM risk scoring system achieved an accuracy of
                  93.1%, a sensitivity of 75%, and a specificity of 94%. The AUC was 0.921 for the training group and
                  0.898 for the testing group. This suggests that the IONM risk score has excellent discrimination.
                  Higher cumulative scores were associated with dramatically increased rates of cord-level IONM data
                  loss, with a loss rate of 0.9% for a score of ≤2 and a loss rate of 86% for a score of ≥7. Except for
                  differences in mean age, smoking status, and lowest intraoperative Hgb and Hct, no significant
                  differences between the training and testing groups were observed. After feature selection, 9
                  variables remained, and all were preoperative factors except for the preoperative-to-intraoperative
                  decrease in Hct. This variable was removed to emphasize that a risk assessment tool that is based
                  entirely on preoperative factors still achieves excellent model performance.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">This study has limitations. First, the model was developed
                  with data from a single surgeon whose practice exclusively involves pediatric and adult spinal
                  deformity surgery. Therefore, external validation is needed. The advantage of using data from a single
                  surgeon is that it eliminates the variability in surgical skill, anesthesia protocol, and IONM warning
                  criteria that would confound a multi-surgeon or multicenter study. Second, given the low incidence of
                  cord-level IONM data loss, stratifying our sample between adult and pediatric patients would have
                  resulted in an even lower incidence, thereby limiting our ability to conduct any meaningful analysis.
                  Since many practicing spine surgeons treat a single patient group, future studies should validate
                  these findings in various populations and practices. Third, intrinsic cord abnormalities such as
                  diastematomyelia (split cord), and intracanal lesions, which may be potential risk factors, were not
                  included in our data set and thus were not included in our analysis. Fourth, the absence of
                  descriptive data on patient race and ethnicity is a limitation that may affect the study’s
                  generalizability. Lastly, there is currently no universally accepted warning criteria for cord-level
                  IONM data loss. Without a standardized definition of IONM cord data loss across institutions, it may
                  be difficult to apply the same risk scoring system.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, this study represents the first
                  single-surgeon investigation to provide an ML-derived preoperative scoring system that predicts
                  cord-level IONM data loss during pediatric and adult spinal deformity surgery with over 90% accuracy.
                  Noteworthy strengths of this study include a robust patient cohort with minimal data gaps and a novel
                  ML analysis. Furthermore, excellent model performance was observed when the IONM scoring system
                  underwent validation on a blinded testing cohort. It is important to note that these critical 8 risk
                  factors are nonmodifiable, which underscores the fact that even the most skilled spinal deformity
                  surgeons may encounter new neurologic deficits as an inherent complication of operative management. By
                  employing predictive modeling for proper risk stratification, clinicians can engage in more informed
                  discussions with patients, particularly those with elevated risk factors for cord-level IONM data
                  loss, regarding potential complications. Future endeavors will involve external validation of this
                  scoring system across diverse surgical practices and institutions, with potential application in
                  tailoring interventions on the basis of individual risk profiles.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I293" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I293</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Carreon LY</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Cheung KMC</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Dahl BT</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Fehlings MG</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Ames CP</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Boachie-Adjei O</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Dekutoski MB</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Kebaish KM</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Lewis SJ</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Mehdian H</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Pellisé F</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Qiu Y</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Schwab FJ</span>; AO Spine
                International and SRS Scoli-RISK-1 Study Group. <span data-id="strong_1" class="annotation strong"
                  style="position: unset;">Lower Extremity Motor Function Following Complex Adult Spinal Deformity
                  Surgery: Two-Year Follow-up in the Scoli-RISK-1 Prospective, Multicenter, International Study</span>.
                <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2018 Apr 18;100(8):656-65.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1636149" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Fehlings MG</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Cheung KM</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Carreon L</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Dekutoski MB</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Schwab FJ</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Boachie-Adjei O</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Kebaish KM</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Ames CP</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Qiu Y</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Dahl BT</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Mehdian H</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Pellisé-Urquiza F</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Lewis SJ</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Berven SH</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Neurologic Outcomes of Complex
                  Adult Spinal Deformity Surgery: Results of the Prospective, Multicenter Scoli-RISK-1 Study</span>.
                <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2016 Feb;41(3):204-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Neurologic%20Outcomes%20of%20Complex%20Adult%20Spinal%20Deformity%20Surgery%3A%20Results%20of%20the%20Prospective%2C%20Multicenter%20Scoli-RISK-1%20Study&as_occt=title&as_sauthors=%20%22LG%20Lenke%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Halsey MF</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Myung KS</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Ghag A</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Vitale MG</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> de Kleuver M</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Neurophysiological monitoring of
                  spinal cord function during spinal deformity surgery: 2020 SRS neuromonitoring information
                  statement</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Spine Deform.</span> 2020 Aug;8(4):591-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Neurophysiological%20monitoring%20of%20spinal%20cord%20function%20during%20spinal%20deformity%20surgery%3A%202020%20SRS%20neuromonitoring%20information%20statement&as_occt=title&as_sauthors=%20%22MF%20Halsey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Fano AN</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Iyer RR</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Matsumoto H</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Sucato DJ</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Samdani AF</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Smith JS</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Gupta MC</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Kelly MP</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Sciubba DM</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Cho SK</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Polly DW</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Boachie-Adjei O</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Lewis SJ</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Angevine PD</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Vitale MG</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Development of consensus-based
                  best practice guidelines for response to intraoperative neuromonitoring events in high-risk spinal
                  deformity surgery</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Spine Deform.</span> 2022 Jul;10(4):745-61.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20of%20consensus-based%20best%20practice%20guidelines%20for%20response%20to%20intraoperative%20neuromonitoring%20events%20in%20high-risk%20spinal%20deformity%20surgery&as_occt=title&as_sauthors=%20%22LG%20Lenke%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Vitale MG</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Skaggs DL</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Pace GI</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Wright ML</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Matsumoto H</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Anderson RC</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Brockmeyer DL</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Dormans JP</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Emans JB</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Erickson MA</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Flynn JM</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Glotzbecker MP</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Ibrahim KN</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Lewis SJ</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Luhmann SJ</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Mendiratta A</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Richards BS 3rd</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Sanders JO</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Shah SA</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Smith JT</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Song KM</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Sponseller PD</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Sucato DJ</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Roye DP</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Lenke LG</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Best Practices in Intraoperative
                  Neuromonitoring in Spine Deformity Surgery: Development of an Intraoperative Checklist to Optimize
                  Response</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Spine
                  Deform.</span> 2014 Sep;2(5):333-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Best%20Practices%20in%20Intraoperative%20Neuromonitoring%20in%20Spine%20Deformity%20Surgery%3A%20Development%20of%20an%20Intraoperative%20Checklist%20to%20Optimize%20Response&as_occt=title&as_sauthors=%20%22MG%20Vitale%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Fehlings MG</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Kato S</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Nakashima H</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Nagoshi N</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Cheung KMC</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Carreon L</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Dekutoski MB</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Schwab FJ</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Boachie-Adjei O</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Kebaish KM</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Ames CP</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Qiu Y</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Dahl BT</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Mehdian H</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Pellisé-Urquiza F</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Lewis SJ</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Berven SH</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Incidence and risk factors of
                  postoperative neurologic decline after complex adult spinal deformity surgery: results of the
                  Scoli-RISK-1 study</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2018 Oct;18(10):1733-40.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20and%20risk%20factors%20of%20postoperative%20neurologic%20decline%20after%20complex%20adult%20spinal%20deformity%20surgery%3A%20results%20of%20the%20Scoli-RISK-1%20study&as_occt=title&as_sauthors=%20%22MG%20Fehlings%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Sielatycki JA</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Cerpa M</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Baum G</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Pham M</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Thuet E</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Lehman RA</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Lenke LG</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">A novel MRI-based classification
                  of spinal cord shape and CSF presence at the curve apex to assess risk of intraoperative
                  neuromonitoring data loss with thoracic spinal deformity correction</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2020
                Aug;8(4):655-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20novel%20MRI-based%20classification%20of%20spinal%20cord%20shape%20and%20CSF%20presence%20at%20the%20curve%20apex%20to%20assess%20risk%20of%20intraoperative%20neuromonitoring%20data%20loss%20with%20thoracic%20spinal%20deformity%20correction&as_occt=title&as_sauthors=%20%22JA%20Sielatycki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Wang XB</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Thuet E</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Blanke K</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Koester LA</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Roth M</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Deformity Angular Ratio
                  Describes the Severity of Spinal Deformity and Predicts the Risk of Neurologic Deficit in Posterior
                  Vertebral Column Resection Surgery</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2016 Sep 15;41(18):1447-55.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Deformity%20Angular%20Ratio%20Describes%20the%20Severity%20of%20Spinal%20Deformity%20and%20Predicts%20the%20Risk%20of%20Neurologic%20Deficit%20in%20Posterior%20Vertebral%20Column%20Resection%20Surgery&as_occt=title&as_sauthors=%20%22XB%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Lewis ND</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Keshen SG</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Zywiel MG</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Skaggs DL</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Dear TE</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Strantzas S</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Lewis SJ</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The Deformity Angular Ratio:
                  Does It Correlate With High-Risk Cases for Potential Spinal Cord Monitoring Alerts in Pediatric
                  3-Column Thoracic Spinal Deformity Corrective Surgery?</span><span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2015 Aug
                1;40(15):E879-85.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Deformity%20Angular%20Ratio%3A%20Does%20It%20Correlate%20With%20High-Risk%20Cases%20for%20Potential%20Spinal%20Cord%20Monitoring%20Alerts%20in%20Pediatric%203-Column%20Thoracic%20Spinal%20Deformity%20Corrective%20Surgery%3F&as_occt=title&as_sauthors=%20%22ND%20Lewis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Angelliaume A</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Alhada TL</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Parent HF</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Royer J</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Harper L</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Intraoperative
                  neurophysiological monitoring in scoliosis surgery: literature review of the last 10 years</span>.
                <span data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Eur Spine J.</span>
                2023 Sep;32(9):3072-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intraoperative%20neurophysiological%20monitoring%20in%20scoliosis%20surgery%3A%20literature%20review%20of%20the%20last%2010%20years&as_occt=title&as_sauthors=%20%22A%20Angelliaume%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Zhang Z</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Zhang H</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Khanal MK</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Development of scoring system
                  for risk stratification in clinical medicine: a step-by-step tutorial</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Ann Transl Med.</span> 2017
                Nov;5(21):436.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20of%20scoring%20system%20for%20risk%20stratification%20in%20clinical%20medicine%3A%20a%20step-by-step%20tutorial&as_occt=title&as_sauthors=%20%22Z%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Ohno T</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Adachi S</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Okuno M</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Horibe Y</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Goto N</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Iwama M</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Yamauchi O</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Kojima T</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Saito K</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Ibuka T</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Yasuda I</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Araki H</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Moriwaki H</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Shimizu M</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Development of a Novel Scoring
                  System for Predicting the Risk of Colorectal Neoplasia: A Retrospective Study</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">PLoS One.</span> 2016 Jun
                10;11(6):e0157269.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20of%20a%20Novel%20Scoring%20System%20for%20Predicting%20the%20Risk%20of%20Colorectal%20Neoplasia%3A%20A%20Retrospective%20Study&as_occt=title&as_sauthors=%20%22T%20Ohno%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Szumilas M</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Explaining odds ratios</span>.
                <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Can Acad Child
                  Adolesc Psychiatry.</span> 2010 Aug;19(3):227-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Explaining%20odds%20ratios&as_occt=title&as_sauthors=%20%22M%20Szumilas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Ogundimu EO</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Altman DG</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Collins GS</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Adequate sample size for
                  developing prediction models is not simply related to events per variable</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Clin Epidemiol.</span>
                2016 Aug;76:175-82.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adequate%20sample%20size%20for%20developing%20prediction%20models%20is%20not%20simply%20related%20to%20events%20per%20variable&as_occt=title&as_sauthors=%20%22EO%20Ogundimu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Harrell FE Jr</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Lee KL</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Califf RM</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Pryor DB</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Rosati RA</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Regression modelling strategies
                  for improved prognostic prediction</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Stat Med.</span> 1984 Apr-Jun;3(2):143-52.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Regression%20modelling%20strategies%20for%20improved%20prognostic%20prediction&as_occt=title&as_sauthors=%20%22FE%20Jr%20Harrell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Chowdhury MZI</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Turin TC</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Precision health through
                  prediction modelling: factors to consider before implementing a prediction model in clinical
                  practice</span>. <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J
                  Prim Health Care.</span> 2020 Mar;12(1):3-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Precision%20health%20through%20prediction%20modelling%3A%20factors%20to%20consider%20before%20implementing%20a%20prediction%20model%20in%20clinical%20practice&as_occt=title&as_sauthors=%20%22MZI%20Chowdhury%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Thirumala PD</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Huang J</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Thiagarajan K</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Cheng H</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Balzer J</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Crammond DJ</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Diagnostic Accuracy of Combined
                  Multimodality Somatosensory Evoked Potential and Transcranial Motor Evoked Potential Intraoperative
                  Monitoring in Patients With Idiopathic Scoliosis</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2016 Oct
                1;41(19):E1177-84.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnostic%20Accuracy%20of%20Combined%20Multimodality%20Somatosensory%20Evoked%20Potential%20and%20Transcranial%20Motor%20Evoked%20Potential%20Intraoperative%20Monitoring%20in%20Patients%20With%20Idiopathic%20Scoliosis&as_occt=title&as_sauthors=%20%22PD%20Thirumala%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Hamilton DK</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Smith JS</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Sansur CA</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Glassman SD</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Ames CP</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Berven SH</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Polly DW Jr</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Perra JH</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Knapp DR</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Boachie-Adjei O</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> McCarthy RE</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Shaffrey CI</span>; Scoliosis
                Research Society Morbidity and Mortality Committee. <span data-id="strong_18" class="annotation strong"
                  style="position: unset;">Rates of new neurological deficit associated with spine surgery based on
                  108,419 procedures: a report of the Scoliosis Research Society Morbidity and Mortality
                  Committee</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2011 Jul 1;36(15):1218-28.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rates%20of%20new%20neurological%20deficit%20associated%20with%20spine%20surgery%20based%20on%20108%2C419%20procedures%3A%20a%20report%20of%20the%20Scoliosis%20Research%20Society%20Morbidity%20and%20Mortality%20Committee&as_occt=title&as_sauthors=%20%22DK%20Hamilton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Eck KR</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Bridwell KH</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Ungacta FF</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Riew KD</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Lapp MA</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Baldus C</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Blanke K</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Complications and results of
                  long adult deformity fusions down to L4, L5, and the sacrum</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2001 May
                1;26(9):E182-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Complications%20and%20results%20of%20long%20adult%20deformity%20fusions%20down%20to%20L4%2C%20L5%2C%20and%20the%20sacrum&as_occt=title&as_sauthors=%20%22KR%20Eck%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Laratta JL</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Ha A</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Shillingford JN</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Makhni MC</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Lombardi JM</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Thuet E</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Lehman RA</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Lenke LG</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Neuromonitoring in Spinal
                  Deformity Surgery: A Multimodality Approach</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Global Spine J.</span> 2018
                Feb;8(1):68-77.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Neuromonitoring%20in%20Spinal%20Deformity%20Surgery%3A%20A%20Multimodality%20Approach&as_occt=title&as_sauthors=%20%22JL%20Laratta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Yoshida G</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Ushirozako H</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Kobayashi S</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Hasegawa T</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Yamato Y</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Banno T</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Oe S</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Arima H</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Mihara Y</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Yasuda T</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Togawa D</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Matsuyama Y</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Intraoperative Neuromonitoring
                  During Adult Spinal Deformity Surgery: Alert-Positive Cases for Various Surgical Procedures</span>.
                <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Spine Deform.</span>
                2019 Jan;7(1):132-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intraoperative%20Neuromonitoring%20During%20Adult%20Spinal%20Deformity%20Surgery%3A%20Alert-Positive%20Cases%20for%20Various%20Surgical%20Procedures&as_occt=title&as_sauthors=%20%22G%20Yoshida%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Li XS</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Fan HW</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Huang ZF</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Deng YL</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Chen BL</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Li ZZ</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Yang JL</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Predictive Value of Spinal Cord
                  Function Classification and Sagittal Deformity Angular Ratio for Neurologic Risk Stratification in
                  Patients with Severe and Stiff Kyphoscoliosis</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">World Neurosurg.</span> 2019
                Mar;123:e787-96.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictive%20Value%20of%20Spinal%20Cord%20Function%20Classification%20and%20Sagittal%20Deformity%20Angular%20Ratio%20for%20Neurologic%20Risk%20Stratification%20in%20Patients%20with%20Severe%20and%20Stiff%20Kyphoscoliosis&as_occt=title&as_sauthors=%20%22XS%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Gupta MC</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Gupta S</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Farooqi AS</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Boachie-Adjei O</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Erickson MA</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Samdani AF</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Shah SA</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Shufflebarger HL</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Sponseller PD</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Sucato DJ</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Kelly MP</span>; Fox Pediatric
                Spinal Deformity Study Group. <span data-id="strong_23" class="annotation strong"
                  style="position: unset;">Intraoperative neuromonitoring predicts postoperative deficits in severe
                  pediatric spinal deformity patients</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Spine Deform.</span> 2024 Jan;12(1):109-18.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intraoperative%20neuromonitoring%20predicts%20postoperative%20deficits%20in%20severe%20pediatric%20spinal%20deformity%20patients&as_occt=title&as_sauthors=%20%22MC%20Gupta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Illingworth KD</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Siddiqui AA</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Skaggs DL</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Andras LM</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Deformity angular ratio is
                  associated with neuromonitoring changes without a vertebral column resection: spinal deformity is more
                  influential than type of surgery</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Spine Deform.</span> 2023 Jul;11(4):951-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Deformity%20angular%20ratio%20is%20associated%20with%20neuromonitoring%20changes%20without%20a%20vertebral%20column%20resection%3A%20spinal%20deformity%20is%20more%20influential%20than%20type%20of%20surgery&as_occt=title&as_sauthors=%20%22KD%20Illingworth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Keshavarzi S</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Ramchandran S</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Spardy J</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Dobyns A</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Errico T</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> George S</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Utilization of pre-operative
                  MRI to identify AIS patients at highest risk of intra-operative neuromonitoring alert</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2023
                Nov;11(6):1419-26.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Utilization%20of%20pre-operative%20MRI%20to%20identify%20AIS%20patients%20at%20highest%20risk%20of%20intra-operative%20neuromonitoring%20alert&as_occt=title&as_sauthors=%20%22S%20Keshavarzi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Bakhsheshian J</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Hassan FM</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Lewerenz E</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Reyes JL</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Zuckerman SL</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Prospective Validation of the
                  Spinal Cord Shape Classification System in the Prediction of Intraoperative Neuromonitoring Data Loss:
                  Assessing the Risk of Spinal Cord Data Loss During Spinal Deformity Correction</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2024 Apr 17;106(8):716-26.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=c1bfa1cf-3b8e-4469-bfa4-d9e6cda0739a" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Mathew SE</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Milbrandt TA</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Shaughnessy WJ</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Stans AA</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Larson AN</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Is the axial spinal cord
                  classification predictive of intraoperative neurologic alert for pediatric scoliosis
                  patients?</span><span data-id="emphasis_27" class="annotation emphasis" style="position: unset;">An
                  independent retrospective validation study. Spine Deform.</span> 2021 Mar;9(2):395-401.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20the%20axial%20spinal%20cord%20classification%20predictive%20of%20intraoperative%20neurologic%20alert%20for%20pediatric%20scoliosis%20patients%3F&as_occt=title&as_sauthors=%20%22SE%20Mathew%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Apel DM</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Marrero G</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> King J</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Tolo VT</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Bassett GS</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Avoiding paraplegia during
                  anterior spinal surgery. The role of somatosensory evoked potential monitoring with temporary
                  occlusion of segmental spinal arteries</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 1991 Aug;16(8)(Suppl):S365-70.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Avoiding%20paraplegia%20during%20anterior%20spinal%20surgery.%20The%20role%20of%20somatosensory%20evoked%20potential%20monitoring%20with%20temporary%20occlusion%20of%20segmental%20spinal%20arteries&as_occt=title&as_sauthors=%20%22DM%20Apel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Samdani AF</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Bennett JT</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Ames RJ</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Asghar JK</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Orlando G</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Pahys JM</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Yaszay B</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Miyanji F</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Lonner BS</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Lehman RA Jr</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Cahill PJ</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Betz RR</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Reversible Intraoperative
                  Neurophysiologic Monitoring Alerts in Patients Undergoing Arthrodesis for Adolescent Idiopathic
                  Scoliosis: What Are the Outcomes of Surgery?</span><span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2016 Sep
                7;98(17):1478-83.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1259896"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Kamerlink JR</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Errico T</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Xavier S</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Patel A</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Patel A</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Cohen A</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Reiger M</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Dryer J</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Feldman D</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Lonner B</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Beric A</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Schwab F</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Major intraoperative neurologic
                  monitoring deficits in consecutive pediatric and adult spinal deformity patients at one
                  institution</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2010 Jan 15;35(2):240-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Major%20intraoperative%20neurologic%20monitoring%20deficits%20in%20consecutive%20pediatric%20and%20adult%20spinal%20deformity%20patients%20at%20one%20institution&as_occt=title&as_sauthors=%20%22JR%20Kamerlink%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Raynor BL</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Bright JD</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Rahman RK</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Bridwell KH</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Riew KD</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Buchowski JM</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Luhmann SJ</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Padberg AM</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Significant change or loss of
                  intraoperative monitoring data: a 25-year experience in 12,375 spinal surgeries</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2013 Jan 15;38(2):E101-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Significant%20change%20or%20loss%20of%20intraoperative%20monitoring%20data%3A%20a%2025-year%20experience%20in%2012%2C375%20spinal%20surgeries&as_occt=title&as_sauthors=%20%22BL%20Raynor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Abu-Ata MM</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Pasquali C</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Sala F</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Intraoperative Neuromonitoring
                  in Tethered Cord Surgery in Children</span>. <span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">J Clin Neurophysiol.</span> 2024 Feb 1;41(2):123-33.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intraoperative%20Neuromonitoring%20in%20Tethered%20Cord%20Surgery%20in%20Children&as_occt=title&as_sauthors=%20%22MM%20Abu-Ata%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Pelosi L</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Lamb J</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Grevitt M</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Mehdian SM</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Webb JK</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Blumhardt LD</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Combined monitoring of motor
                  and somatosensory evoked potentials in orthopaedic spinal surgery</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">Clin Neurophysiol.</span> 2002
                Jul;113(7):1082-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Combined%20monitoring%20of%20motor%20and%20somatosensory%20evoked%20potentials%20in%20orthopaedic%20spinal%20surgery&as_occt=title&as_sauthors=%20%22L%20Pelosi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Forbes HJ</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Allen PW</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Waller CS</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Jones SJ</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Edgar MA</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Webb PJ</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Ransford AO</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Spinal cord monitoring in
                  scoliosis surgery. Experience with 1168 cases</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 1991
                May;73(3):487-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spinal%20cord%20monitoring%20in%20scoliosis%20surgery.%20Experience%20with%201168%20cases&as_occt=title&as_sauthors=%20%22HJ%20Forbes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Qiu Y</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Wang S</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Wang B</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Yu Y</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Zhu F</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Zhu Z</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Incidence and risk factors of
                  neurological deficits of surgical correction for scoliosis: analysis of 1373 cases at one Chinese
                  institution</span>. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2008 Mar 1;33(5):519-26.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20and%20risk%20factors%20of%20neurological%20deficits%20of%20surgical%20correction%20for%20scoliosis%3A%20analysis%20of%201373%20cases%20at%20one%20Chinese%20institution&as_occt=title&as_sauthors=%20%22Y%20Qiu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Vitale MG</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Moore DW</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Matsumoto H</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Emerson RG</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Booker WA</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Gomez JA</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Gallo EJ</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Hyman JE</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Roye DP Jr</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Risk factors for spinal cord
                  injury during surgery for spinal deformity</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2010
                Jan;92(1):64-71.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1064564"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;1077c6cc-ba10-4286-9472-5d2ff2a501bd&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=1077c6cc-ba10-4286-9472-5d2ff2a501bd&type=supplement&name=12560"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D1077c6cc-ba10-4286-9472-5d2ff2a501bd%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): 237</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00386</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 20, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_37"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopedic Surgery, Columbia University Irving Medical Center, New York,
                            NY</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;1077c6cc-ba10-4286-9472-5d2ff2a501bd&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=1077c6cc-ba10-4286-9472-5d2ff2a501bd&type=pdf&name=JBJS.24.00386.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=1077c6cc-ba10-4286-9472-5d2ff2a501bd&type=pdf&name=JBJS.24.00386.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_42"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_43" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I294"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I294</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;1077c6cc-ba10-4286-9472-5d2ff2a501bd&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=1077c6cc-ba10-4286-9472-5d2ff2a501bd&type=zip&name=JBJS.24.00386.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nathan J. Lee, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0496-4977" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0496-4977</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lawrence G. Lenke, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5595-4958" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5595-4958</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Varun Arvind, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3826-1786" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3826-1786</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ted Shi, BS<span data-id="affiliation_reference_3"
                  class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-8169-3568" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-8169-3568</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alexandra C. Dionne, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0001-1070-0882" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0001-1070-0882</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Chidebelum Nnake, BS<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0004-2481-0646" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0004-2481-0646</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mitchell Yeary, BS<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3542-372X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3542-372X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael Fields, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6077-1494" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6077-1494</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matt Simhon, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6999-1234" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6999-1234</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Anastasia Ferraro, BS, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0005-8312-9604" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0005-8312-9604</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew Cooney, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0001-1769-3101" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0001-1769-3101</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Erik Lewerenz, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-5118-8531" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-5118-8531</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Justin L. Reyes, MS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6596-6488" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6596-6488</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Steven G. Roth, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5383-0428" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5383-0428</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Chun Wai Hung, MD, MEng<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8008-6817" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8008-6817</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Justin K. Scheer, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2536-601X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2536-601X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Thomas Zervos, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8397-5388" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8397-5388</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Earl D. Thuet, BS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7743-2309" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7743-2309</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joseph M. Lombardi, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0484-3027" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0484-3027</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Zeeshan M. Sardar, MD, MSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8417-3798" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8417-3798</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ronald A. Lehman, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5799-9885" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5799-9885</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Benjamin D. Roye, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3542-5108" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3542-5108</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael G. Vitale, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7519-3909" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7519-3909</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Fthimnir M. Hassan, MPH<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:fh2444@cumc.columbia.edu" class=""
                    style="position: unset;">fh2444@cumc.columbia.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3928-8972" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3928-8972</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Columbia University Irving Medical Center,
                New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">The Och Spine Hospital at New York-Presbyterian, New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Vagelos College of Physicians and Surgeons, Columbia University, New York,
                NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Morgan Stanley Children’s Hospital, New-York Presbyterian, New York, NY</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 38720.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
