import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { ImageBackground } from "expo-image";
import { StyleSheet, View } from "react-native";
import { Card, Chip, Text } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";
import Responses from "../ChallengeCard/Responses";
import CustomListItem from "../CustomListItem";
import { supabaseImageUrlParser } from "../Image";

export type ChallengeCountdownProps = {
	imageUrl: string;
	formattedTime: string;
	challengeNumber: number;
	numResponses: number;
	title: string;
};

const TimeUnit = ({ value, label }: { value: string; label: string }) => {
	const theme = useAppTheme();
	return (
		<Box
			alignItems="center"
			minWidth={69}
			height={60}
			borderRadius={8}
			padding={12}
			backgroundColor={theme.colors.surfaceVariant}
			justifyContent="center"
		>
			<Text
				variant="headlineMedium"
				style={{ fontFamily: "OpenSans-SemiBold" }}
			>
				{value.padStart(2, "0")}
			</Text>
			<Text
				variant="labelSmall"
				style={{
					letterSpacing: 1,
					fontFamily: "OpenSans-SemiBold",
				}}
			>
				{label}
			</Text>
		</Box>
	);
};
const ChallengeCountdown = ({
	imageUrl,
	formattedTime,
	challengeNumber,
	numResponses,
	title,
}: ChallengeCountdownProps) => {
	const [days, hours, minutes, seconds] = formattedTime.split(":");
	const theme = useAppTheme();

	const optimizedImageUrl = supabaseImageUrlParser({
		src: imageUrl,
		width: 100,
	});

	return (
		<Card
			mode="outlined"
			style={{
				backgroundColor: theme.colors.elevation.level2,
				borderColor: theme.colors.outlineVariant,
				overflow: "hidden",
			}}
		>
			<CustomListItem
				title={title}
				description={() => {
					return (
						<Box gap={4}>
							<Text variant="bodySmall">Challenge #{challengeNumber}</Text>
							<Box
								flexDirection="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<Chip
									compact
									style={{
										borderRadius: 20,
										backgroundColor: theme.colors.elevation.level5,
									}}
									textStyle={{
										fontFamily: "OpenSans-SemiBold",
										fontSize: 12,
									}}
								>
									{`#${challengeNumber}`}
								</Chip>
								<Responses numResponses={numResponses} />
							</Box>
						</Box>
					);
				}}
				style={{
					borderRadius: 8,
					borderWidth: 1,
					borderColor: theme.colors.outlineVariant,
					marginTop: -14,
					height: 116,
				}}
				left={(p) => (
					<ImageBackground
						{...p}
						source={{ uri: optimizedImageUrl }}
						style={{
							width: 100,
							height: 100,
							justifyContent: "center",
							alignItems: "center",
							position: "relative",
							borderRadius: 4,
							overflow: "hidden",
						}}
					>
						<View
							style={{
								...StyleSheet.absoluteFillObject,
								backgroundColor: theme.colors.backdrop,
							}}
						/>
						<Icon
							name="checkbox-marked-circle"
							size={24}
							color={theme.palette.success.light}
						/>
					</ImageBackground>
				)}
			/>
			<Box padding={16} gap={16} paddingBottom={24}>
				<Text
					variant="titleMedium"
					style={{
						textAlign: "center",
					}}
				>
					NEXT CHALLENGE IN...
				</Text>
				<Box flexDirection="row" justifyContent="space-between">
					<TimeUnit value={days || "00"} label="DAYS" />
					<TimeUnit value={hours || "00"} label="HOURS" />
					<TimeUnit value={minutes || "00"} label="MINUTES" />
					<TimeUnit value={seconds || "00"} label="SECONDS" />
				</Box>
			</Box>
		</Card>
	);
};

export default ChallengeCountdown;
