/* -----------------Globals--------------- */
import { relayEnv } from "@memorang/configs";
import { fetchData } from "@memorang/helpers";
import { graphql } from "react-relay";

type Response = {
	viewer: {
		name: string;
		email: string;
		iconUrl?: string;
		purchased: {
			folder: {
				contents: {
					edges: {
						node: {
							id: string;
						};
					}[];
				};
			};
		};
	};
};
const query = graphql`
  query FetchUserDetailsQuery {
    viewer {
      name
      email
      iconUrl

    }
  }
`;

const fetchUserDetails = async (): Promise<{
	name: string;
	email: string;
	iconUrl?: string;
	isPremiumUser: boolean;
}> => {
	const { viewer } = (await fetchData(relayEnv, query)) as Response;

	return {
		...viewer,
		// isPremiumUser: viewer.purchased.folder.contents.edges.length > 0,
		isPremiumUser: false,
	};
};

export default fetchUserDetails;
