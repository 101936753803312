/* ======== Components ========= */
import { useAppStore } from "@hooks/useAppStore";
import { Box } from "@memorang/ui";
import { ProductIcon } from "components";
import { Text } from "react-native-paper";

export type ProductIconProps = {
	url?: string;
	numItems: number;
	selectedDate?: string;
	fromHomePage?: boolean;
};

const ProductIconContainer = ({
	url,
	selectedDate,
	fromHomePage,
}: ProductIconProps) => {
	const purchase = useAppStore((store) => store.app.purchase);

	const customSubheading = purchase?.subheading;

	const subHeadingTemplate = () => {
		if (fromHomePage) {
			return "Purchase to unlock all items and maximize your test readiness";
		}
		if (customSubheading) {
			return customSubheading;
		}
		return `Unlock all items and maximize your test readiness ${
			selectedDate ? `before ${selectedDate}` : ""
		}`;
	};
	const subheading = subHeadingTemplate();
	return (
		<>
			<Box alignItems="center">
				<ProductIcon enabledBoxShadow image={url || ""} size={128} />
			</Box>
			<Text
				variant="titleMedium"
				style={{
					textAlign: "center",
					fontWeight: "bold",
				}}
			>
				{subheading}
			</Text>
		</>
	);
};

export default ProductIconContainer;
