import { Button, IconButton } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";

const AudioRecorder = ({
	icon,
	handleRecord,
	bgColor,
	disabled,
	handleDelete,
	showDelete,
}: {
	icon: string;
	handleRecord: () => void;
	bgColor?: string;
	disabled?: boolean;
	handleDelete: () => void;
	showDelete?: boolean;
}) => {
	const theme = useAppTheme();
	return (
		<Box gap={16} alignItems="center">
			<IconButton
				icon={icon}
				disabled={disabled}
				style={{
					backgroundColor: bgColor || theme.colors.error,
				}}
				size={140}
				onPress={handleRecord}
				iconColor={theme.colors.surface}
			/>
			{showDelete && (
				<Button
					mode="outlined"
					disabled={disabled}
					icon="delete-outline"
					onPress={handleDelete}
				>
					Discard
				</Button>
			)}
		</Box>
	);
};
export default AudioRecorder;
