import { z } from "zod";

const userDetailsSchema = z.object({
	firstName: z.string().min(1, "First name is required"),
	lastName: z.string().min(1, "Last name is required"),
});

type UserDetails = z.infer<typeof userDetailsSchema>;

export { userDetailsSchema, type UserDetails };
