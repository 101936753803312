/**
 * @generated SignedSource<<496e19d1df7c1c64e85a62aba098b01f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AnswerItemType = "HOTSPOT" | "MCQ" | "PASSAGE" | "SATA" | "SEQUENCE" | "WRITTEN" | "%future added value";
export type Direction = "FORWARD" | "REVERSE" | "%future added value";
export type Mode = "FLIP" | "MATCH" | "QUESTION" | "QUIZ" | "%future added value";
export type SessionType = "COMBINED" | "MANUAL" | "SCHEDULE" | "%future added value";
export type CommonAnswerInput = {
  answerId: string;
  chosenAnswerId: string;
  confidence: number;
  correctChoiceIds?: ReadonlyArray<string | null> | null;
  correctness?: number | null;
  eventContext: EventContext;
  itemId: string;
  points?: number | null;
  score: number;
  selectedChoiceIds?: ReadonlyArray<string | null> | null;
  sequenceOrder?: ReadonlyArray<string | null> | null;
  sessionMode?: SessionMode | null;
  variant?: AnswerItemType | null;
};
export type EventContext = {
  contextId: string;
  eventId?: string | null;
  gameType?: string | null;
  isCorrect?: boolean | null;
  numDistractors?: number | null;
  scheduleId?: string | null;
  sessionId: string;
  time: any;
  timeFactor?: number | null;
  timeTaken?: number | null;
  timesChanged?: ReadonlyArray<TimesChanged | null> | null;
  timezone?: string | null;
};
export type TimesChanged = {
  id: string;
  timeTaken?: ReadonlyArray<number> | null;
};
export type SessionMode = {
  direction: Direction;
  mode: Mode;
  sessionType?: SessionType | null;
};
export type RecordFormativeTestEventsMutation$variables = {
  answer: CommonAnswerInput;
};
export type RecordFormativeTestEventsMutation$data = {
  readonly recordAnswerEvent: boolean;
};
export type RecordFormativeTestEventsMutation = {
  response: RecordFormativeTestEventsMutation$data;
  variables: RecordFormativeTestEventsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "answer"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "answer",
        "variableName": "answer"
      }
    ],
    "kind": "ScalarField",
    "name": "recordAnswerEvent",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecordFormativeTestEventsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecordFormativeTestEventsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a5ad6d1bddb003ad83d89c04dc37f7fa",
    "id": null,
    "metadata": {},
    "name": "RecordFormativeTestEventsMutation",
    "operationKind": "mutation",
    "text": "mutation RecordFormativeTestEventsMutation(\n  $answer: CommonAnswerInput!\n) {\n  recordAnswerEvent(answer: $answer)\n}\n"
  }
};
})();

(node as any).hash = "05d0f001eb0436b1b3fc902e466d179b";

export default node;
