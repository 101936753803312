import type { Article } from "../../../../../types";

export const Article646: Article = {
	id: 646,
	rsuiteId: "43350630-c4a4-478a-bd50-f1b5f2ff4a34",
	type: "scientific article",
	title:
		"Achieving Better Clinical Outcomes After Total Knee Arthroplasty in Knees with Valgus Deformity",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=43350630-c4a4-478a-bd50-f1b5f2ff4a34&type=jpeg&name=JBJS.24.00207f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Achieving Better Clinical Outcomes After Total Knee
                Arthroplasty in Knees with Valgus Deformity</div>
              <div class="text subtitle" style="position: unset;">The Role of Alignment Strategies</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Cécile
                      Batailler, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Timothy
                      Lording, MBBS, FRACS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_26" class="annotation strong"
                          style="position: unset;">Background:</span> Personalized alignment in total knee arthroplasty
                        (TKA) has demonstrated good functional outcomes for knees with varus alignment. However, limited
                        research has explicitly addressed optimal alignment strategies for valgus knees. The aims of the
                        current study were to assess the impact of the postoperative knee alignment and of the degree of
                        correction of knee alignment on functional outcomes and satisfaction in a population with
                        preoperative valgus and to evaluate the complication and revision rates based on postoperative
                        alignment.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_27" class="annotation strong"
                          style="position: unset;">Methods:</span> This retrospective study included primary
                        posterior-stabilized TKA with a preoperative hip-knee-ankle (HKA) angle of ≥180°, with a minimum
                        follow-up of 32 months. There were 460 knees included, divided into 3 groups: (1) preoperative
                        neutral alignment (180° to 183°) (n = 162), (2) preoperative mild valgus (184° to 190°) (n =
                        204), and (3) preoperative severe valgus (&gt;190°) (n = 94). A standardized surgical technique
                        was employed with a goal of achieving neutral postoperative alignment. Data on radiographs, Knee
                        Society Scores (KSS), range of motion, satisfaction, complications, and revisions were collected
                        at the last follow-up.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Results:</span> The mean follow-up was 74.3 ± 12.4 months. In the
                        preoperative mild valgus group, 10.8% of patients had postoperative varus, 81.4% had
                        postoperative neutral alignment, and 7.8% had postoperative valgus. In the preoperative severe
                        valgus group, 4.3% had postoperative varus, 83.0% had postoperative neutral alignment, and 12.8%
                        had postoperative valgus. In the preoperative mild valgus group, patients with postoperative
                        neutral alignment had significantly higher satisfaction (p = 0.0004) and KSS function score (p =
                        0.031) than patients with postoperative valgus alignment. In the preoperative severe valgus
                        group, patients with postoperative valgus alignment had significantly higher satisfaction (p =
                        0.035) and greater improvement of the KSS knee score (p = 0.014) than patients with
                        postoperative neutral alignment. Functional outcomes were not impacted by the degree of HKA
                        angle correction. There were significantly fewer complications (p = 0.022) and revisions (p =
                        0.007) in the preoperative mild valgus group when patients had a postoperative neutral alignment
                        compared with a postoperative valgus alignment.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Conclusions:</span> For preoperative mild valgus, correction to
                        neutral alignment achieved better outcomes and fewer complications than leaving residual valgus.
                        For preoperative severe valgus, retaining residual valgus postoperatively ensured satisfactory
                        functional outcomes without increased complications.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Traditionally, the target of postoperative knee alignment
                  after total knee arthroplasty (TKA) was described as neutral alignment, which was believed to maximize
                  the long-term survival of primary TKA<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">4</span></a>. This paradigm has recently been questioned. Long-term
                  studies have found no difference in survival rate between TKAs with alignment outliers and neutrally
                  aligned TKAs<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">6</span></a>. Several authors have considered the alignment target and
                  its impact on functional outcomes and patient satisfaction. As mechanical alignment was questioned in
                  the 1980s, anatomical alignment was introduced by Hungerford and Krackow to improve functionality by
                  mimicking native knee alignment<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">7</span></a>.
                  Still, the alignment was similar for all and was not personalized. This led to the development of
                  several concepts of personalized alignment: kinematic, inverse kinematic, restricted kinematic, and
                  functional<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">8</span></a>.
                  These personalized alignments have demonstrated satisfying survival and low complication rates with
                  good functional outcomes for preoperative varus alignment<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">9</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">However, few studies have specifically addressed this
                  question for the valgus knee<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">11</span></a>. Valgus alignment is present in 10% of all patients
                  requiring primary TKA<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">12</span></a>.
                  Valgus is a particular entity that requires tailored considerations during TKA, including femoral
                  condyle hypoplasia<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">13</span></a>,
                  lateral soft-tissue contracture, bone loss<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">12</span></a>,
                  patellar maltracking<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">10</span></a>,
                  and aesthetic consideration. Restoring the preoperative valgus alignment may not be satisfying for all
                  patients. The available current literature has lacked objective data to determine an alignment target
                  for a preoperative valgus knee.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The aims of this study were to assess the impact of the
                  postoperative knee alignment on the following, in the intermediate term, in a population of knees with
                  preoperative valgus: (1) the functional outcomes and satisfaction, (2) the impact of the degree of
                  correction of knee alignment on the functional outcomes and satisfaction, and (3) the rates of
                  complications and revisions. The hypothesis was that correction of valgus to neutral alignment would
                  result in better clinical outcomes than conserving preoperative valgus deformity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Material and
                Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">All procedures were performed in accordance with the
                  ethical standards of the institutional and/or national research committee, the 1964 Helsinki
                  Declaration and its later amendments, or comparable ethical standards. Data collection and analysis
                  were carried out in accordance with MR004 Reference Methodology from the Commission Nationale de
                  l’Informatique et des Libertés (Ref. 2229975V0) obtained on May 6, 2023. The study was registered and
                  filed on the Health Data Hub website.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patients</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This retrospective study was based on a single-center
                  cohort and analyzed prospectively gathered data on primary TKAs. A total of 4,736 patients underwent a
                  primary TKA between January 2008 and December 2018; of these patients, 672 had a preoperative
                  hip-knee-ankle (HKA) angle of ≥180°. The inclusion criteria were primary posterior-stabilized TKA for
                  osteoarthritis and a preoperative HKA angle of ≥180°. The exclusion criteria were additional
                  associated surgical procedures (such as tibial or femoral osteotomy, medial patellofemoral ligament
                  reconstruction), the use of semiconstrained or hinged implants, and flexion contracture of &gt;5° (<a
                    href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). This study included 460 knees with a preoperative HKA angle of
                  ≥180°. The patients were divided into 3 groups based on preoperative valgus deformity: (1) neutral
                  alignment, with an HKA angle of 180° to 183° (n = 162), (2) mild valgus, with an HKA angle of 184° to
                  190° (n = 204), and (3) severe valgus, with an HKA angle of &gt;190° (n = 94)<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">15</span></a>. The mean age (and standard deviation) was 69.3 ± 10.3
                  years (range, 28 to 95 years). The mean body mass index (BMI) was 27.3 ± 4.6 kg/m<span
                    data-id="superscript_12" class="annotation superscript" style="position: unset;">2</span> (range, 16
                  to 47 kg/m<span data-id="superscript_13" class="annotation superscript"
                    style="position: unset;">2</span>). The demographic parameters were similar in the 3 deformity
                  groups (<a href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00207f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=43350630-c4a4-478a-bd50-f1b5f2ff4a34&type=jpeg&name=JBJS.24.00207f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;">Study flowchart. CCK = constrained condylar knee.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographic Data in the 3 Preoperative Alignment
                          Groups<span data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1118px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Neutral
                          Alignment Group (N = 162)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mild
                          Valgus Group (N = 204)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Severe
                          Valgus Group (N = 94)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_27" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.4 ±
                          10.7 (28 to 88)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69.1 ±
                          10.0 (31 to 90)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71.2 ±
                          10.1 (42 to 95)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.12</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_28" class="annotation emphasis"
                            style="position: unset;">(kg/m</span><span data-id="emphasis_29" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_14"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_30"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.7 ± 4.5
                          (18.6 to 42.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.2 ± 4.3
                          (16.9 to 41.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.9 ± 5.3
                          (16.4 to 46.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.20</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Right side<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86
                          (53.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80
                          (39.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37
                          (39.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_33" class="annotation strong" style="position: unset;">0.018</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male gender<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57
                          (35.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65
                          (31.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (28.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.57</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative KSS knee
                          score<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62.9 ±
                          14.9 (25 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61.2 ±
                          14.8 (12 to 96)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.5 ±
                          17.3 (23 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.43</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative KSS function
                          score<span data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62.5 ±
                          19.9 (5 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59.2 ±
                          18.2 (5 to100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54.5 ±
                          21.1 (0 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_34" class="annotation strong" style="position: unset;">0.0071</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative flexion<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">120.3 ±
                          15.9 (70 to 150)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">117.7 ±
                          16.1 (60 to 150)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">117.0 ±
                          17.9 (60 to 145)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.35</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative HKA
                          angle<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">181.3 ±
                          1.2 (180 to 183)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">186.8 ±
                          2.1 (184 to 190)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">194.5 ±
                          3.3 (191 to 207)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(mo)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76.5 ±
                          11.8 (34 to 157)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.7 ±
                          12.0 (40 to 150)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72.8 ±
                          13.2 (32 to 137)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.64</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Approach<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medial</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">162
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (11%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">181
                          (89%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94
                          (100%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">NA = not appropriate.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">A significant difference between the 3 groups (p &lt; 0.05) is shown in
                        bold.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation, with the
                        range in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">A standardized surgical technique was utilized for all
                  patients; 185 knees underwent medial arthrotomy, and 275 knees underwent a lateral arthrotomy<a
                    href="" data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">16</span></a>. The aim for the postoperative alignment was neutral (mean
                  HKA angle, within 3° of 180°) in all cases. Intramedullary and extramedullary guides were used for the
                  primary tibial cut. An intramedullary guide was used to perform the distal femoral cut in 5° of
                  valgus. In cases of hypoplasia of the lateral condyle, the femoral component was positioned in 3° of
                  uncentered external rotation. The same implant was used for every patient. It was a cemented,
                  fixed-bearing, posterior-stabilized implant (KneeTec; Tornier). The patella was routinely resurfaced.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical and
                Radiographic Assessments</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">All patients had standardized clinical and radiographic
                  evaluation preoperatively, at 2 and 12 months after the surgical procedure with their surgeon, and
                  then every 2 years. The Knee Society Score (KSS) function and knee scores<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">17</span></a>, range of motion, and satisfaction were collected for the
                  most recent follow-up. The KSS knee score was calculated without the knee alignment value to avoid
                  biasing the scores according to the preoperative or postoperative alignment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Radiographs included an anteroposterior view, lateral
                  view, patellar axial view, and full long-leg view. Full-weight-bearing, long-leg standing radiographs
                  were performed barefoot with the feet placed together and the patella oriented forward to avoid
                  rotational variation<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">18</span></a>.
                  The following radiographic measurements were performed: HKA angle, medial proximal tibial angle, and
                  mechanical medial distal femoral angle<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">18</span></a>.
                  All measurements were performed using Centricity Universal Viewer Zero Footprint software (version 6.0
                  SP7.0.2; GE HealthCare). Measurement accuracy was to 1 decimal place.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Statistical analysis was performed using XLSTAT (2021;
                  Addinsoft). Continuous variables were described using means, standard deviations, and ranges.
                  Categorical variables were described using counts and percentages. Categorical outcomes were compared
                  using the Fisher exact test or the chi-square test. Normally distributed continuous variables were
                  compared using analysis of variance. Non-normally distributed continuous variables were compared using
                  the Mann-Whitney test or the Kruskal-Wallis test. Significance was set at p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The mean follow-up was 74.3 ± 12.4 months (range, 32 to
                  157 months) (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). In the
                  preoperative mild valgus group, 10.8% (22) of patients had postoperative varus (HKA angle, ≤ 176°),
                  81.4% (166) had postoperative neutral alignment (HKA angle, 177° to 183°), and 7.8% (16) had
                  postoperative valgus (HKA angle, ≥ 184°). In the preoperative severe valgus group, 4.3% (4) had
                  postoperative varus, 83.0% (78) had postoperative neutral alignment, and 12.8% (12) had postoperative
                  valgus.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">In the preoperative neutral alignment group, patients with
                  postoperative valgus alignment had significantly less flexion improvement (p = 0.02) and a lower
                  satisfaction rate (p = 0.026) at the last follow-up compared with patients with postoperative varus or
                  neutral knee alignment (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Alignment and Functional Outcomes According to the
                          Postoperative Alignment in the Preoperative Neutral Alignment Group (N = 162)<span
                            data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1087.25px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.670061);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Postoperative Alignment</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value<span data-id="table_footnote_reference_13"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Degree of Correction</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">≤176°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">177° to
                          183°</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">≥184°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">0° to
                          3°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">4° to
                          9°</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">≥10°</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (6.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">142
                          (87.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (5.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">133
                          (82.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (16.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">HKA angle<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">§</span><span data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">181.6 ±
                          1.1 (180 to 183)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">181.2 ±
                          1.2 (180 to 183)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">181.4 ±
                          1.5 (180 to 183)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">181.2 ±
                          1.2 (180 to 183)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">181.9 ±
                          1.1 (180 to 183)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">180.0 ± 0
                          (180 to 180)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Postoperative</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">174.6 ±
                          1.8 (170 to 176)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">180.2 ±
                          1.6 (177 to 183)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">186.0 ±
                          2.5 (184 to 191)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">180.5 ±
                          1.6 (177 to 185)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">178.4 ±
                          4.1 (173 to 189)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">180.5 ±
                          14.8 (170 to 191)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative
                          flexion<span data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">§</span><span data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">129.1 ±
                          6.6 (115 to 135)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">122.5 ±
                          11.1 (90 to 145)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">116.7 ±
                          18.0 (80 to 135)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.054</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">122.6 ±
                          11.2 (90 to 145)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">121.7 ±
                          12.6 (80 to 135)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">132.5 ±
                          3.5 (130 to 135)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.28</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Flexion improvement<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">§</span><span data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.5 ±
                          15.8 (−5 to 45)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7 ± 14.9
                          (−40 to 40)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−5 ± 17.1
                          (−40 to 20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_35" class="annotation strong" style="position: unset;">0.020</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2 ± 14.9
                          (−40 to 40)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.8 ± 17.5
                          (−40 to 45)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.5 ± 3.5
                          (−5 to 0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.11</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative KSS knee
                          score<span data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93.3 ± 9.8
                          (67 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90.9 ±
                          10.4 (60 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88.0 ±
                          12.0 (65 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.661</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90.6 ±
                          10.7 (60 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">91.7 ± 9.5
                          (67 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100 ± 0
                          (100 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.17</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KSS knee score
                          improvement<span data-id="table_footnote_reference_19"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.9 ±
                          13.0 (6 to 51)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.1 ±
                          19.2 (−21 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.2 ±
                          25.8 (−25 to 53)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.635</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.4 ±
                          19.1 (−25 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.7 ±
                          19.8 (−10 to 75)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.0 ± 7.1
                          (30 to 40)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.35</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative KSS
                          function score<span data-id="table_footnote_reference_20"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76.8 ±
                          19.7 (45 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.5 ±
                          19.5 (5 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78.3 ±
                          19.7 (50 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.611</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.6 ±
                          19.7 (5 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76.7 ±
                          17.7 (45 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100 ± 0
                          (100 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.083</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KSS function score
                          improvement<span data-id="table_footnote_reference_21"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.1 ±
                          22.0 (−20 to 50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.0 ±
                          21.6 (−40 to 80)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.1 ±
                          21.2 (−20 to 55)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.770</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.9 ±
                          21.7 (−40 to 80)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.0 ±
                          20.3 (−20 to 50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10 ± 0 (10
                          to 10)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.19</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Satisfaction<span
                            data-id="table_footnote_reference_22" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_36" class="annotation strong" style="position: unset;">0.026</span></span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.28</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Very satisfied</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (63.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75
                          (52.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (11.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70
                          (52.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (48.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (50%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Satisfied</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (36.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58
                          (40.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (55.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53
                          (39.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (48.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unsatisfied</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (6.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (33.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (7.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (50%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">NA = not appropriate.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Significant differences between the 3 groups (p &lt; 0.05) are shown in
                        bold.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation, with the
                        range in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">In the preoperative mild valgus group, patients with
                  postoperative neutral alignment had significantly higher satisfaction (p = 0.0004) and KSS function
                  score (p = 0.031) at the last follow-up compared with patients with postoperative valgus or varus
                  alignment (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 2-A</a> and <a
                    href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">2-B</a>, <a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_37"
                            class="annotation strong" style="position: unset;">Figs. 2-A and 2-B</span> Satisfaction
                          rate (<span data-id="strong_38" class="annotation strong" style="position: unset;">Fig.
                            2-A</span>) and KSS function score (<span data-id="strong_39" class="annotation strong"
                            style="position: unset;">Fig. 2-B</span>) at the last follow-up according to the
                          postoperative HKA angle in the preoperative mild valgus group. The asterisk indicates
                          significance. In Figure 2-B, the interquartile range (IQR) is indicated by a box. A line
                          within the box indicates the median. A blue dot indicates the mean. The whiskers extend 1.5
                          times the IQR from the top and bottom of the box. If the data do not extend to the end of the
                          whiskers, then the whiskers extend to the minimum and maximum data values. Gray dots indicate
                          the outliers. The orange dots indicate the minimum and maximum data values without outliers.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-A</div>
                    <div class="image-download" name="JBJS.24.00207f2a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=43350630-c4a4-478a-bd50-f1b5f2ff4a34&type=jpeg&name=JBJS.24.00207f2a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-B</div>
                    <div class="image-download" name="JBJS.24.00207f2b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=43350630-c4a4-478a-bd50-f1b5f2ff4a34&type=jpeg&name=JBJS.24.00207f2b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Alignment and Functional Outcomes According to the
                          Postoperative Alignment in the Preoperative Mild Valgus Group (N = 204)<span
                            data-id="table_footnote_reference_35" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1009.21px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.656687);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Postoperative Alignment</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value<span data-id="table_footnote_reference_24"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Degree of Correction</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">≤176°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">177° to
                          183°</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">≥184°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">0° to
                          3°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">4° to
                          9°</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">≥10°</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients<span
                            data-id="table_footnote_reference_25" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (10.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">166
                          (81.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (7.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (15.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">120
                          (58.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53
                          (26.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">HKA angle<span
                            data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">§</span><span data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">187.3 ±
                          1.9 (184 to 190)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">186.7 ±
                          2.1 (184 to 190)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">186.9 ±
                          2.1 (184 to 190)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">185.1 ±
                          1.3 (184 to 190)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">186.4 ±
                          1.9 (184 to 190)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">188.7 ±
                          1.7 (184 to 190)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Postoperative</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">174.4 ±
                          2.1 (168 to 176)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">180.0 ±
                          1.7 (177 to 183)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">185.7 ±
                          2.6 (184 to 194)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">183.4 ±
                          1.8 (181 to 188)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">180.2 ±
                          2.2 (176 to 194)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">177.0 ±
                          2.7 (168 to 180)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative
                          flexion<span data-id="table_footnote_reference_27" class="annotation table_footnote_reference"
                            style="position: unset;">§</span><span data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">118.4 ±
                          10.3 (95 to 135)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">121.2 ±
                          11.9 (85 to 145)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">114.7 ±
                          14.7 (85 to 135)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.091</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">119.2 ±
                          14.7 (85 to 140)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">120.8 ±
                          12.0 (85 to 145)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">120.2 ±
                          10.8 (90 to 140)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.87</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Flexion improvement<span
                            data-id="table_footnote_reference_28" class="annotation table_footnote_reference"
                            style="position: unset;">§</span><span data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.4 ± 22.7
                          (−40 to 60)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.3 ± 15.6
                          (−35 to 50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.5 ±
                          18.0 (−20 to 40)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.106</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9 ± 17.7
                          (−30 to 40)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1 ± 16.6
                          (−35 to 50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9 ± 16.5
                          (−40 to 60)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.73</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative KSS knee
                          score<span data-id="table_footnote_reference_29" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92.2 ± 9.2
                          (69 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90.9 ±
                          12.3 (35 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86.6 ±
                          15.2 (54 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.560</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87.6 ±
                          14.3 (54 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90.5 ±
                          12.8 (35 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93.1 ± 9.0
                          (63 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.45</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KSS knee score
                          improvement<span data-id="table_footnote_reference_30"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.1 ±
                          13.1 (9 to 55)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.7 ±
                          19.1 (−28 to 76)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.7 ±
                          17.4 (0 to 61)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.710</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.9 ±
                          19.8 (−8 to 62)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.6 ±
                          19.5 (−28 to 76)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.6 ±
                          14.8 (−5 to 55)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.74</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative KSS
                          function score<span data-id="table_footnote_reference_31"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78.4 ±
                          19.0 (30 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82.5 ±
                          19.9 (15 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.2 ±
                          26.6 (10 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_43" class="annotation strong" style="position: unset;">0.031</span><span
                            data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75.6 ±
                          22.6 (10 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.2 ±
                          20.8 (15 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83.2 ±
                          19.0 (30 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.25</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KSS function score
                          improvement<span data-id="table_footnote_reference_33"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.8 ±
                          29.3 (−50 to 70)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.1 ±
                          22.7 (−40 to 90)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.4 ±
                          17.9 (−25 to 45)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.200</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.7 ±
                          17.8 (−25 to 50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.2 ±
                          23.0 (−30 to 85)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.7 ±
                          26.7 (−50 to 90)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Satisfaction<span
                            data-id="table_footnote_reference_34" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_44" class="annotation strong" style="position: unset;">0.00042</span></span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.55</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Very satisfied</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (18.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">103
                          (62.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (43.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (51.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69
                          (57.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (56.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Satisfied</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (63.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55
                          (33.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (37.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (35.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45
                          (37.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (34.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unsatisfied</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (18.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (4.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (18.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (12.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (5.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (9.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">NA = not appropriate.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Significant differences between the 3 groups (p &lt; 0.05) are shown in
                        bold.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation, with the
                        range in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">There was a significant difference between 177° to 183° and ≥184° (p =
                        0.013).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">In the preoperative severe valgus group, patients with
                  postoperative valgus alignment (≥184°) had significantly higher satisfaction (p = 0.035) and greater
                  improvement of the KSS knee score (p = 0.014) at the last follow-up compared with patients with
                  postoperative neutral or varus alignment (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 3-A</a> and <a
                    href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">3-B</a>, <a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).
                  Unsatisfied patients reported mainly persistent severe and unexplained pains (9 of 12).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Figs. 3-A and 3-B</span> Satisfaction
                          rate (<span data-id="strong_46" class="annotation strong" style="position: unset;">Fig.
                            3-A</span>) and improvement of KSS knee score (<span data-id="strong_47"
                            class="annotation strong" style="position: unset;">Fig. 3-B</span>) at the last follow-up
                          according to the postoperative HKA angle in the preoperative severe valgus group. The asterisk
                          indicates significance. In Figure 3-B, the interquartile range (IQR) is indicated by a box. A
                          line within the box indicates the median. A blue dot indicates the mean. The whiskers extend
                          1.5 times the IQR from the top and bottom of the box. If the data do not extend to the end of
                          the whiskers, then the whiskers extend to the minimum and maximum data values. The gray dot
                          indicates the outlier. The orange dots indicate the minimum and maximum data values without
                          outliers.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-A</div>
                    <div class="image-download" name="JBJS.24.00207f3a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=43350630-c4a4-478a-bd50-f1b5f2ff4a34&type=jpeg&name=JBJS.24.00207f3a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-B</div>
                    <div class="image-download" name="JBJS.24.00207f3b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=43350630-c4a4-478a-bd50-f1b5f2ff4a34&type=jpeg&name=JBJS.24.00207f3b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Alignment and Functional Outcomes According to the
                          Postoperative Alignment in the Preoperative Severe Valgus Group (N = 94)<span
                            data-id="table_footnote_reference_47" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1119.29px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.689727);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Postoperative Alignment</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value<span data-id="table_footnote_reference_36"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Degree of Correction</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">≤176°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">177° to
                          183°</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">≥184°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">0° to
                          3°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">4° to
                          9°</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">≥10°</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients<span
                            data-id="table_footnote_reference_37" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (4.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78
                          (83.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (12.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (10.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84
                          (89.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">HKA angle<span
                            data-id="table_footnote_reference_38" class="annotation table_footnote_reference"
                            style="position: unset;">§</span><span data-id="emphasis_40" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">196.0 ±
                          1.6 (194 to 198)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">194.4 ±
                          3.3 (191 to 207)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">195.2 ±
                          3.7 (191 to 202)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">191.8 ±
                          1.0 (191 to 193)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">194.9 ±
                          3.4 (191 to 207)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Postoperative</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">175.3 ±
                          0.5 (175 to 176)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">179.9 ±
                          1.7 (177 to 183)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">185.2 ±
                          1.7 (184 to 189)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">183.9 ±
                          2.1 (182 to 189)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">179.9 ±
                          2.4 (175 to 188)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative
                          flexion<span data-id="table_footnote_reference_39" class="annotation table_footnote_reference"
                            style="position: unset;">§</span><span data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">113.8 ±
                          13.1 (100 to 125)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">120.9 ±
                          13.0 (80 to 140)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">117.9 ±
                          8.9 (100 to 135)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.27</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">121.5 ±
                          11.6 (100 to 135)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">120.1 ±
                          12.7 (80 to 140)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.78</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Flexion improvement<span
                            data-id="table_footnote_reference_40" class="annotation table_footnote_reference"
                            style="position: unset;">§</span><span data-id="emphasis_42" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5 ± 37.6
                          (−40 to 45)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.9 ± 14.8
                          (−35 to 40)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.8 ±
                          25.4 (−20 to 60)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.61</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5 ± 20.5
                          (−20 to 40)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.5 ± 17.5
                          (−40 to 60)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.33</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative KSS knee
                          score<span data-id="table_footnote_reference_41" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88.3 ±
                          11.6 (77 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88.6 ±
                          13.5 (55 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94.3 ± 9.7
                          (68 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.28</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93.3 ±
                          10.1 (70 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88.8 ±
                          13.3 (55 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.25</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KSS knee score
                          improvement<span data-id="table_footnote_reference_42"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.5 ±
                          32.3 (−15 to 61)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.9 ±
                          19.5 (−17 to 69)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42.3 ±
                          16.7 (22 to 77)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_51" class="annotation strong" style="position: unset;">0.014</span><span
                            data-id="table_footnote_reference_43" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.1 ±
                          16.3 (10 to 66)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.5 ±
                          20.8 (−22 to 77)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.19</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative KSS
                          function score<span data-id="table_footnote_reference_44"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.8 ± 8.5
                          (60 to 80)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75.6 ±
                          22.6 (15 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73.8 ±
                          22.4 (40 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.64</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.5 ±
                          20.7 (55 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.4 ±
                          22.2 (15 to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.49</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KSS function score
                          improvement<span data-id="table_footnote_reference_45"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.3 ±
                          17.0 (−5 to 30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.1 ±
                          25.9 (−50 to 85)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.8 ±
                          16.8 (−5 to 50)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.72</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.5 ±
                          14.0 (−5 to 45)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.8 ±
                          25.5 (−50 to 85)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.54</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Satisfaction<span
                            data-id="table_footnote_reference_46" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_52" class="annotation strong" style="position: unset;">0.035</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.50</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Very satisfied</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (50%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59
                          (75.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (58.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (80%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49
                          (58.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Satisfied</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (11.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (41.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (20%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (32.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unsatisfied</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (50%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (12.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (9.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_14" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">NA = not appropriate.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_15" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Significant differences between the 3 groups (p &lt; 0.05) are shown in
                        bold.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_16" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_17" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation, with the
                        range in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_18" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">There was a significant difference between 177° to 183° and ≥184° (p =
                        0.004).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Range of motion and functional outcomes were not impacted
                  by the degree of HKA angle correction (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Tables II</a>
                  through <a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">V</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_14" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Complications and Revisions at the Last Follow-up in
                          Each Preoperative Alignment Group According to the Degree of HKA Angle Correction<span
                            data-id="table_footnote_reference_50" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 197.611px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.517296);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Preoperative Neutral Alignment (N = 162)</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Preoperative Mild Valgus (N = 204)</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Preoperative Severe Valgus (N = 94)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">0° to
                          3°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">4° to
                          9°</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">≥10°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">0° to
                          3°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">4° to
                          9°</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">≥10°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">0° to
                          3°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">4° to
                          9°</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">≥10°</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">133
                          (82.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (16.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (15.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">120
                          (58.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53
                          (26.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (10.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84
                          (89.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complications</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (20.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.087<span
                            data-id="table_footnote_reference_48" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (35.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (16.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (18.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.077<span
                            data-id="table_footnote_reference_49" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (10.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (15.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.99</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Revisions</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (7.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.73</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (12.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (5.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (5.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.31</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (8.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.99</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_19" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_20" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">There was a significant difference between 0° to 3° and 4° to 9° (p =
                        0.039).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_21" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">There was a significant difference between 0° to 3° and 4° to 9° (p =
                        0.027).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">There were significantly fewer complications (p = 0.022)
                  and revisions (p = 0.007) in the preoperative mild valgus group when patients had a postoperative
                  neutral alignment compared with a postoperative valgus alignment (<a href=""
                    data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_15" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Complications and Revisions at the Last Follow-up in
                          Each Preoperative Alignment Group According to the Postoperative Alignment</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 824.664px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.47);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Preoperative Neutral Alignment (N = 162)</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Preoperative Mild Valgus (N = 204)</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Preoperative Severe Valgus (N = 94)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">≤176°
                          Postop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">177° to
                          183° Postop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">≥184°
                          Postop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">≤176°
                          Postop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">177° to
                          183° Postop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">≥184°
                          Postop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_51" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">≤176°
                          Postop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">177° to
                          183° Postop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">≥184°
                          Postop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients<span
                            data-id="table_footnote_reference_52" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (6.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">142
                          (87.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (5.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (10.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">166
                          (81.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (7.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (4.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78
                          (83.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (12.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complications<span
                            data-id="table_footnote_reference_53" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (19.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (11.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.31</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (22.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (17.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (43.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.052<span
                            data-id="table_footnote_reference_54" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (16.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (8.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.84</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Patellar fracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Clunk syndrome</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Patellar
                          dislocation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tibial fracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femoral fracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Implant breakage</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Loosening</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medial
                          instability</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Stiffness</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Polyethylene wear</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Skin necrosis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Revisions<span
                            data-id="table_footnote_reference_55" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (6.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (22.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.19</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (9.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (4.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (25.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_53" class="annotation strong" style="position: unset;">0.007</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (9.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.70</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Patellar fracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Clunk syndrome</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tibial fracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femoral fracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Implant breakage</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Loosening</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medial
                          instability</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Stiffness</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Polyethylene wear</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pains</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_22" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Significant differences between the 3 groups (p &lt; 0.05) are shown in
                        bold.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_23" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_24" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">There was a significant difference between 177° to 183° and ≥184° (p =
                        0.022).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">The main findings of this study were that superior
                  functional and satisfaction outcomes were achieved when mild valgus deformity was corrected to neutral
                  alignment after TKA, and when severe valgus deformity was corrected to leave some residual valgus
                  alignment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The optimal target for knee alignment after TKA in a
                  population with preoperative valgus deformity is unclear in the literature, particularly with respect
                  to postoperative functional outcomes and the patient satisfaction rate<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">10</span></a>. Personalized alignment has demonstrated satisfying results
                  in restoring the preoperative deformity in the population with varus alignment<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_20" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">20</span></a>. Some authors reported good functional outcomes or
                  survivorship with kinematic or personalized alignment in the population with preoperative valgus
                  deformity<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">22</span></a>. Nevertheless, the preoperative valgus deformity was rarely
                  severe, and postoperative alignment was close to neutral. In a retrospective study of 51 kinematic
                  TKAs with good functional outcomes at 3 years of follow-up, the mean preoperative valgus deformity was
                  185.9°, and the mean postoperative knee alignment was 181.4°, corresponding to a postoperative neutral
                  alignment in our study<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">21</span></a>.
                  The higher KSS function score and satisfaction rate found in the current study when a mild valgus
                  deformity was converted to neutral alignment may be explained by an improvement in patellar tracking<a
                    href="" data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">10</span></a>, a well-balanced knee<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">23</span></a>, and an improvement of the aesthetic appearance of the
                  legs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Correcting severe valgus deformity during TKA is
                  challenging<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">24</span></a>.
                  Several studies have suggested that it is necessary for TKA to maintain a degree of residual valgus
                  alignment in knees with preoperative severe valgus deformity<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">23</span></a>, especially as the risk of TKA revision due to residual
                  valgus was not higher compared with postoperative neutral alignment<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">25</span></a>. A retrospective study of 557 knees with severe
                  preoperative valgus demonstrated that overcorrection to a varus alignment occurred in poorer
                  functional outcomes. However, they found no difference in functional outcomes between neutral and
                  residual valgus alignment<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">11</span></a>. In
                  the current study, the satisfaction rate and improvement of the KSS knee score were greater if a
                  moderate valgus alignment (184° to 189°) was retained in knees with preoperative severe valgus
                  deformity. Several reasons may explain this finding. First, the ligament balancing was easier when
                  moderate valgus was retained, with a lower risk of medial instability. Indeed, lateral releases were
                  limited and less frequent than releases on the medial side. The potential release procedures included
                  the release of the iliotibial band, Burdin osteotomy, and eventually popliteus or biceps release<a
                    href="" data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">12</span></a>. A higher level of constraint is required if there are
                  ligamentous insufficiency and instability. Second, residual tightness of the lateral soft tissues can
                  be painful and is not easily relieved. Indeed, most of the unsatisfied patients with neutral or varus
                  alignment after correction of severe valgus described recurrent lateral pain. Valgus deformity is
                  often secondary to lateral femoral hypoplasia. The correction of such deformity increases the length
                  of the lateral condyle and thus increases the constraint on the lateral compartment. Moreover, the
                  valgus knee is characterized by, among other things, a contracted posterolateral capsule, a contracted
                  iliotibial band, a contracted lateral collateral ligament, and a contracted posterior cruciate
                  ligament. These structures were not all released, explaining potential recurrent tightness when severe
                  preoperative valgus was corrected to neutral alignment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Nevertheless, this study did not demonstrate a significant
                  difference in outcomes according to the degree of HKA angle correction. This was probably due to the
                  variability in the correctability of the valgus deformity. As the target was neutral alignment, the
                  alignment of knees with residual valgus deformity was probably not further correctable. For this
                  reason, a strict limit for residual valgus alignment could not be determined in the current study
                  because the residual alignment mainly depends on the degree of correctability for each knee. In this
                  study, a postoperative valgus between 184° and 189° in a population of knees with preoperative severe
                  valgus led to high satisfaction and functional outcomes at the last follow-up. It would be interesting
                  to follow the patients who underwent operations with a personalized alignment technique for
                  preoperative severe valgus to confirm this target of alignment in this specific population.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">A higher risk of complications has been reported for
                  overcorrection of valgus deformity after TKA<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">11</span></a>.
                  Boyer et al. showed a 26% rate of complications with postoperative varus knee alignment compared with
                  11% with neutral alignment and 4% with residual valgus alignment<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">11</span></a>. The current study likewise showed the highest complication
                  rates when some residual varus was retained. Indeed, for severe, and sometimes fixed, valgus
                  deformity, the strict correction of knee alignment can expose the patient to femoral or tibial
                  fracture, extensor mechanism complications, femorotibial instability, or early loosening. A
                  retrospective study of 5,342 TKAs assessed the impact of postoperative alignment according to the
                  preoperative deformity on the risk of TKA failure<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">23</span></a>. In
                  preoperative severe valgus deformity (&gt;11°), they demonstrated a higher risk of failure if the
                  postoperative alignment was neutral (&lt;2.5° of valgus) (3.2%) or residual valgus of &gt;7.4° (3.3%),
                  when compared with moderate residual valgus (2.5° to 7.4°) (1.9%)<a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">23</span></a>. The only failure mechanism was ligamentous instability. In
                  the current study, ligamentous instability was not the main cause of complications, probably due to
                  the exclusion of patients needing more constrained implants. The main complications and revisions in
                  this study, when preoperative severe valgus has been corrected to neutral alignment, were tibial or
                  femoral fractures or implant breakage, reflecting the high constraints on the knee.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">This study had several limitations. It was a retrospective
                  study of data collected prospectively. However, such severe deformity is rare, making a prospective
                  study challenging. The mean follow-up was not very long, so a reliable survivorship assessment was not
                  possible. However, the main aim of this study was to evaluate the functional outcomes according to the
                  postoperative alignment. Furthermore, patient partitioning into the subgroups was not homogenous.
                  Indeed, the initial target was neutral alignment, and the number of outliers was limited. This uneven
                  partitioning probably reduced the study power. Nevertheless, significant differences were demonstrated
                  between the alignment subgroups. Finally, we excluded all patients needing semiconstrained or hinged
                  implants. This exclusion criterion probably excluded the patients with a fixed valgus deformity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Despite these limitations, this study was the first large
                  cohort study to analyze the functional outcomes according to the postoperative alignment in a
                  population with preoperative mild to severe valgus deformity. This study reported objective data on
                  the impact of correction of valgus alignment after TKA in a population with a valgus knee.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, correcting the preoperative deformity
                  during TKA in a valgus knee population was not advisable, as it is for a varus population. For
                  preoperative mild valgus deformity, correction to neutral alignment during TKA achieved better
                  outcomes, higher satisfaction, and fewer complications than leaving a residual valgus deformity. For
                  preoperative severe valgus deformity, retaining moderate residual valgus postoperatively yielded a
                  high satisfaction rate and satisfactory functional outcomes without higher rates of complications
                  compared with neutral alignment. A long-term study is necessary to analyze whether retaining valgus
                  alignment increases the risk of implant loosening or failure in the long term.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Ritter MA</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Faris PM</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Keating EM</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Meding JB</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Postoperative alignment of total
                  knee replacement. Its effect on survival</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 1994
                Feb;(299):153-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postoperative%20alignment%20of%20total%20knee%20replacement.%20Its%20effect%20on%20survival&as_occt=title&as_sauthors=%20%22MA%20Ritter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Rivière C</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Iranpour F</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Auvinet E</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Aframian A</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Asare K</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Harris S</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Cobb J</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Parratte S</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Mechanical alignment technique
                  for TKA: are there intrinsic technical limitations?</span><span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Orthop Traumatol Surg Res.</span> 2017
                Nov;103(7):1057-67.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mechanical%20alignment%20technique%20for%20TKA%3A%20are%20there%20intrinsic%20technical%20limitations%3F&as_occt=title&as_sauthors=%20%22C%20Rivi%C3%A8re%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Tew M</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Waugh W</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Tibiofemoral alignment and the
                  results of knee replacement</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 1985 Aug;67(4):551-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibiofemoral%20alignment%20and%20the%20results%20of%20knee%20replacement&as_occt=title&as_sauthors=%20%22M%20Tew%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Berend ME</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Ritter MA</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Meding JB</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Faris PM</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Keating EM</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Redelman R</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Faris GW</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Davis KE</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Tibial component failure
                  mechanisms in total knee arthroplasty</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2004 Nov;(428):26-34.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20component%20failure%20mechanisms%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22ME%20Berend%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Parratte S</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Pagnano MW</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Berry DJ</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Effect of postoperative
                  mechanical axis alignment on the fifteen-year survival of modern, cemented total knee
                  replacements</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2010 Sep 15;92(12):2143-9.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1100697" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Abdel MP</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Ollivier M</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Parratte S</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Berry DJ</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Pagnano MW</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Effect of postoperative
                  mechanical axis alignment on survival and functional outcomes of modern total knee arthroplasties with
                  cement: a concise follow-up at 20 years</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2018 Mar 21;100(6):472-8.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1611151" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Rivière C</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Iranpour F</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Auvinet E</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Howell S</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Vendittoli PA</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Cobb J</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Parratte S</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Alignment options for total knee
                  arthroplasty: a systematic review</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Orthop Traumatol Surg Res.</span> 2017 Nov;103(7):1047-56.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Alignment%20options%20for%20total%20knee%20arthroplasty%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22C%20Rivi%C3%A8re%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Lustig S</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Sappey-Marinier E</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Fary C</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Servien E</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Parratte S</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Batailler C</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Personalized alignment in total
                  knee arthroplasty: current concepts</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">SICOT J.</span> 2021;7:19.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Personalized%20alignment%20in%20total%20knee%20arthroplasty%3A%20current%20concepts&as_occt=title&as_sauthors=%20%22S%20Lustig%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Choi BS</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Kim SE</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Yang M</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Ro DH</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Han HS</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Functional alignment with
                  robotic-arm assisted total knee arthroplasty demonstrated better patient-reported outcomes than
                  mechanical alignment with manual total knee arthroplasty</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2023
                Mar;31(3):1081.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Functional%20alignment%20with%20robotic-arm%20assisted%20total%20knee%20arthroplasty%20demonstrated%20better%20patient-reported%20outcomes%20than%20mechanical%20alignment%20with%20manual%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22BS%20Choi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Lee SS</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Lee H</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Lee DH</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Moon YW</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Slight under-correction
                  following total knee arthroplasty for a valgus knee results in similar clinical outcomes</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 2018 Jul;138(7):1011-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Slight%20under-correction%20following%20total%20knee%20arthroplasty%20for%20a%20valgus%20knee%20results%20in%20similar%20clinical%20outcomes&as_occt=title&as_sauthors=%20%22SS%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Boyer B</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Pailhé R</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Ramdane N</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Eichler D</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Remy F</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Ehlinger M</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Pasquier G</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Under-corrected knees do not
                  fail more than aligned knees at 8 years in fixed severe valgus total knee replacement</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2018 Nov;26(11):3386-94.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Under-corrected%20knees%20do%20not%20fail%20more%20than%20aligned%20knees%20at%208%20years%20in%20fixed%20severe%20valgus%20total%20knee%20replacement&as_occt=title&as_sauthors=%20%22B%20Boyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Ranawat AS</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Ranawat CS</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Elkus M</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Rasquinha VJ</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Rossi R</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Babhulkar S</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Total knee arthroplasty for
                  severe valgus deformity</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2005 Sep;87(Pt 2):271-84.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20knee%20arthroplasty%20for%20severe%20valgus%20deformity&as_occt=title&as_sauthors=%20%22AS%20Ranawat%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Springer B</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Bechler U</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Waldstein W</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Rueckl K</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Boettner CS</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Boettner F</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">The influence of femoral and
                  tibial bony anatomy on valgus OA of the knee</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2020
                Sep;28(9):2998-3006.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20influence%20of%20femoral%20and%20tibial%20bony%20anatomy%20on%20valgus%20OA%20of%20the%20knee&as_occt=title&as_sauthors=%20%22B%20Springer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> De Muylder J</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Victor J</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Cornu O</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Kaminski L</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Thienpont E</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Total knee arthroplasty in
                  patients with substantial deformities using primary knee components</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2015 Dec;23(12):3653-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20knee%20arthroplasty%20in%20patients%20with%20substantial%20deformities%20using%20primary%20knee%20components&as_occt=title&as_sauthors=%20%22J%20De%20Muylder%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Czekaj J</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Fary C</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Gaillard T</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Lustig S</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Does low-constraint mobile
                  bearing knee prosthesis give satisfactory results for severe coronal deformities? A five to twelve
                  year follow up study</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Int Orthop.</span> 2017 Jul;41(7):1369-77.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20low-constraint%20mobile%20bearing%20knee%20prosthesis%20give%20satisfactory%20results%20for%20severe%20coronal%20deformities%3F%20A%20five%20to%20twelve%20year%20follow%20up%20study&as_occt=title&as_sauthors=%20%22J%20Czekaj%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Keblish PA</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The lateral approach to the
                  valgus knee. Surgical technique and analysis of 53 cases with over two-year follow-up
                  evaluation</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 1991 Oct;(271):52-62.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20lateral%20approach%20to%20the%20valgus%20knee.%20Surgical%20technique%20and%20analysis%20of%2053%20cases%20with%20over%20two-year%20follow-up%20evaluation&as_occt=title&as_sauthors=%20%22PA%20Keblish%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Debette C</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Parratte S</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Maucort-Boulch D</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Blanc G</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Pauly V</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Lustig S</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Servien E</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Neyret P</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Argenson JN</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">French adaptation of the new
                  Knee Society Scoring System for total knee arthroplasty</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Orthop Traumatol Surg Res.</span> 2014
                Sep;100(5):531-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=French%20adaptation%20of%20the%20new%20Knee%20Society%20Scoring%20System%20for%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22C%20Debette%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Paley D</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Tetsworth K</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Mechanical axis deviation of
                  the lower limbs. Preoperative planning of multiapical frontal plane angular and bowing deformities of
                  the femur and tibia</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 1992 Jul;(280):65-71.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mechanical%20axis%20deviation%20of%20the%20lower%20limbs.%20Preoperative%20planning%20of%20multiapical%20frontal%20plane%20angular%20and%20bowing%20deformities%20of%20the%20femur%20and%20tibia&as_occt=title&as_sauthors=%20%22D%20Paley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Kafelov M</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Batailler C</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Shatrov J</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Al-Jufaili J</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Farhat J</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Servien E</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Lustig S</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Functional positioning
                  principles for image-based robotic-assisted TKA achieved a higher Forgotten Joint Score at 1 year
                  compared to conventional TKA with restricted kinematic alignment</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2023
                Dec;31(12):5591-602.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Functional%20positioning%20principles%20for%20image-based%20robotic-assisted%20TKA%20achieved%20a%20higher%20Forgotten%20Joint%20Score%20at%201%20year%20compared%20to%20conventional%20TKA%20with%20restricted%20kinematic%20alignment&as_occt=title&as_sauthors=%20%22M%20Kafelov%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Luo Z</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Zhou K</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Peng L</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Shang Q</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Pei F</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Zhou Z</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Similar results with kinematic
                  and mechanical alignment applied in total knee arthroplasty</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2020
                Jun;28(6):1736-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Similar%20results%20with%20kinematic%20and%20mechanical%20alignment%20applied%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22Z%20Luo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Bar-Ziv Y</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Beit Ner E</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Lamykin K</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Essa A</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Gilat R</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Livshits G</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Shohat N</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Beer Y</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Minimum 2-year radiographic and
                  clinical outcomes of kinematic alignment total knee arthroplasty in the valgus knee</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J Pers Med.</span> 2022 Jul
                18;12(7):1164.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Minimum%202-year%20radiographic%20and%20clinical%20outcomes%20of%20kinematic%20alignment%20total%20knee%20arthroplasty%20in%20the%20valgus%20knee&as_occt=title&as_sauthors=%20%22Y%20Bar-Ziv%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Calliess T</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Bauer K</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Stukenborg-Colsman C</span>,
                <span data-id="annotation_119" class="annotation" style="position: unset;"> Windhagen H</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Budde S</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Ettinger M</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">PSI kinematic versus non-PSI
                  mechanical alignment in total knee arthroplasty: a prospective, randomized study</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2017 Jun;25(6):1743-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=PSI%20kinematic%20versus%20non-PSI%20mechanical%20alignment%20in%20total%20knee%20arthroplasty%3A%20a%20prospective%2C%20randomized%20study&as_occt=title&as_sauthors=%20%22T%20Calliess%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Ritter MA</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Davis KE</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Davis P</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Farris A</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Malinzak RA</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Berend ME</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Meding JB</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Preoperative malalignment
                  increases risk of failure after total knee arthroplasty</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2013 Jan
                16;95(2):126-31.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1158974"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Erard J</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Batailler C</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Swan J</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Sappey-Marinier E</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Servien E</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Lustig S</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Lateral approach total knee
                  arthroplasty achieves equivalent patellar tracking in severe valgus deformity compared to mild valgus
                  deformity</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2022
                Feb;30(2):740-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lateral%20approach%20total%20knee%20arthroplasty%20achieves%20equivalent%20patellar%20tracking%20in%20severe%20valgus%20deformity%20compared%20to%20mild%20valgus%20deformity&as_occt=title&as_sauthors=%20%22J%20Erard%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Bonner TJ</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Eardley WG</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Patterson P</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Gregg PJ</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The effect of post-operative
                  mechanical axis alignment on the survival of primary total knee replacements after a follow-up of 15
                  years</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Br.</span> 2011 Sep;93(9):1217-22.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20post-operative%20mechanical%20axis%20alignment%20on%20the%20survival%20of%20primary%20total%20knee%20replacements%20after%20a%20follow-up%20of%2015%20years&as_occt=title&as_sauthors=%20%22TJ%20Bonner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D43350630-c4a4-478a-bd50-f1b5f2ff4a34%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node eletter" data-id="eletter_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="file" style="position: unset;"><span class="" style="position: unset;">eLetter 1</span><a
                  class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;43350630-c4a4-478a-bd50-f1b5f2ff4a34&quot;,&quot;type&quot;:&quot;eletter&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=43350630-c4a4-478a-bd50-f1b5f2ff4a34&type=eletter&name=1708"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): 152</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00207</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 26, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_26"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Orthopedic Surgery Department, Croix-Rousse Hospital, Lyon, France</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;43350630-c4a4-478a-bd50-f1b5f2ff4a34&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=43350630-c4a4-478a-bd50-f1b5f2ff4a34&type=pdf&name=JBJS.24.00207.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=43350630-c4a4-478a-bd50-f1b5f2ff4a34&type=pdf&name=JBJS.24.00207.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_31"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_32" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I298"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I298</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;43350630-c4a4-478a-bd50-f1b5f2ff4a34&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=43350630-c4a4-478a-bd50-f1b5f2ff4a34&type=zip&name=JBJS.24.00207.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Cécile Batailler, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:cecile-batailler@hotmail.fr" class=""
                    style="position: unset;">cecile-batailler@hotmail.fr</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3990-0906" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3990-0906</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Timothy Lording, MBBS, FRACS<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-6869-7009" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-6869-7009</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Thibaut Libert, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9884-4556" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9884-4556</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Elvire Servien, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sébastien Lustig, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Orthopedic Surgery Department, Croix-Rousse Hospital, Lyon, France</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Univ Lyon, Université Claude Bernard Lyon 1, IFSTTAR, Villeurbanne,
                France</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Melbourne Orthopaedic Group, Windsor, Victoria, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Interuniversity Laboratory of Biology of Mobility, Claude Bernard Lyon 1
                University, Lyon, France</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 28523.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
