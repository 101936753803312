import { useRouter } from "expo-router";
import { Platform } from "react-native";
import { Appbar } from "react-native-paper";

type Props = {
	headerTitle?: string;
	backIcon?: string;
	headerRight?: () => React.ReactNode;
};
const ModalHeader = ({ headerTitle, backIcon, headerRight }: Props) => {
	const router = useRouter();
	return (
		<Appbar.Header
			statusBarHeight={Platform.OS === "android" ? 16 : 0}
			mode="center-aligned"
			elevated
		>
			<Appbar.Action onPress={() => router.back()} icon={backIcon || "close"} />
			<Appbar.Content title={headerTitle || ""} />
			{headerRight?.()}
		</Appbar.Header>
	);
};
export default ModalHeader;
