import type { Article } from "../../../../../types";

export const Article657: Article = {
	id: 657,
	rsuiteId: "9fc4ecc6-b456-45db-a60d-38c36dfc1ba1",
	type: "commentary and perspective",
	title:
		"Pain and Inflammation After Joint Replacement Are Variable Among Patients, Depending on the Individual Biological Factors",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/657.jpeg",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Pain and Inflammation After Joint Replacement Are
                Variable Among Patients, Depending on the Individual Biological Factors</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Julian Wier, MD, et al.:
                “Complete Blood Cell Count-Based Ratios Identify Total Joint Arthroplasty Patients Likely to Benefit
                from Perioperative Dexamethasone”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Sreevathsa
                      Boraiah, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">As a surgeon, when I perform exactly the same surgery with
                  similar complexity, duration of surgery, and level of soft-tissue dissection on 2 different patients,
                  they can have totally different levels of pain perception and inflammation. Other than the demographic
                  and psychosocial factors, there are biological factors that drive these differences. Predicting the
                  level of postoperative pain preoperatively can be immensely helpful. It can create a paradigm shift
                  toward outpatient surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">There are few studies that have tried to analyze the
                  biological markers for pain. These studies are from various subspecialties, including orthopaedic
                  surgery, general surgery, and anesthesiology<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. Some
                  studies tried to analyze the factors that are predictive of chronic pain and quality of life<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>, while others analyzed predictors of acute postoperative
                  pain<a href="" data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a>. Both
                  kinds of predictors will have unique value in our clinical practice. The markers of chronic pain will
                  have value in treating and understanding not only the biological markers but also the associated
                  psychosocial factors. The study by Wier et al. analyzed the effect of dexamethasone in patients
                  undergoing total joint arthroplasty. The authors found a higher marginal benefit and a reduction in
                  inflammation in patients with certain complete blood count-based ratios. They found that a higher
                  neutrophil-lymphocyte ratio (NLR) was a predictor of a higher level of inflammation and hence of a
                  better response to dexamethasone. However, given the nature of the study, it did not analyze the
                  postoperative blood ratios or the change in the ratios after surgery. Despite these and previously
                  established benefits of dexamethasone, however, its use is not universal. For example, dexamethasone
                  may be avoided in diabetics because it has a hyperglycemic effect. The benefit of the drug has to be
                  weighed against its side effects.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Correlations of multiple factors including tumor necrosis
                  factor (TNF)-alpha, soluble TNF (sTNF)<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a>,
                  C-reactive protein (CRP), the NLR, the platelet-lymphocyte ratio (PLR), and the monocyte-lymphocyte
                  ratio (MLR) with acute pain, chronic pain, and the level of inflammation have been studied.
                  Understanding these factors and addressing them will be a game changer in reducing the inflammatory
                  response to surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Of all of the markers studied by Wier et al., the NLR
                  seemed to have the highest predictive value. The higher the NLR, the higher the inflammatory response.
                  However, there is a paucity of literature in this area to clearly delineate what specific neutrophil
                  and lymphocyte counts are clinically important. Further research in this area is vital in
                  understanding the true correlation. Other biological markers need to be studied as well. Once we are
                  clearly able to predict the cohort of patients who will have greater pain and inflammation, treatment
                  can be targeted toward these specific patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, this is a valuable area of research and
                  plenty of further work needs to be done.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Rathee A</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Chaurasia MK</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Singh MK</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Singh V</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Kaushal D</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Relationship Between Pre- and
                  Post-Operative C-Reactive Protein (CRP), Neutrophil-to-Lymphocyte Ratio (NLR), and
                  Platelet-to-Lymphocyte Ratio (PLR) With Post-Operative Pain After Total Hip and Knee Arthroplasty: An
                  Observational Study</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Cureus.</span> 2023 Aug 20;15(8):e43782.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Relationship%20Between%20Pre-%20and%20Post-Operative%20C-Reactive%20Protein%20(CRP)%2C%20Neutrophil-to-Lymphocyte%20Ratio%20(NLR)%2C%20and%20Platelet-to-Lymphocyte%20Ratio%20(PLR)%20With%20Post-Operative%20Pain%20After%20Total%20Hip%20and%20Knee%20Arthroplasty%3A%20An%20Observational%20Study&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Shu B</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Xu F</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Zheng X</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Liu Q</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Li S</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Chen J</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Chen Y</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Huang H</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Duan G</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Change in perioperative
                  neutrophil-lymphocyte ratio as a potential predictive biomarker for chronic postsurgical pain and
                  quality of life: an ambispective observational cohort study</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Front Immunol.</span> 2023 Apr
                12;14:1177285.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Change%20in%20perioperative%20neutrophil-lymphocyte%20ratio%20as%20a%20potential%20predictive%20biomarker%20for%20chronic%20postsurgical%20pain%20and%20quality%20of%20life%3A%20an%20ambispective%20observational%20cohort%20study&as_occt=title&as_sauthors=%20%22B%20Shu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Canbolat N</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Buget MI</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Sivrikoz N</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Altun D</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Kucukay S</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Relação entre a proporção
                  neutrófilo/linfócito e a dor pós‐operatória em artroplastia total de joelho e quadril [The
                  relationship between neutrophil to lymphocyte ratio and postoperative pain in total knee and hip
                  arthroplasty]</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Braz J Anesthesiol.</span> 2019 Jan-Feb;69(1):42-1-2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rela%C3%A7%C3%A3o%20entre%20a%20propor%C3%A7%C3%A3o%20neutr%C3%B3filo%2Flinf%C3%B3cito%20e%20a%20dor%20p%C3%B3s%E2%80%90operat%C3%B3ria%20em%20artroplastia%20total%20de%20joelho%20e%20quadril%20%5BThe%20relationship%20between%20neutrophil%20to%20lymphocyte%20ratio%20and%20postoperative%20pain%20in%20total%20knee%20and%20hip%20arthroplasty%5D&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Daoudia M</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Decruynaere C</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Le Polain de Waroux B</span>,
                <span data-id="annotation_24" class="annotation" style="position: unset;"> Thonnard JL</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Plaghki L</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Forget P</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Biological inflammatory markers
                  mediate the effect of preoperative pain-related behaviours on postoperative analgesics
                  requirements</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">BMC Anesthesiol.</span> 2015 Dec 16;15:183.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biological%20inflammatory%20markers%20mediate%20the%20effect%20of%20preoperative%20pain-related%20behaviours%20on%20postoperative%20analgesics%20requirements&as_occt=title&as_sauthors=%20%22M%20Daoudia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Ko FC</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Rubenstein WJ</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Lee EJ</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Siu AL</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Sean Morrison R</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">TNF-α and sTNF-RII Are
                  Associated with Pain Following Hip Fracture Surgery in Older Adults</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Pain Med.</span> 2018 Jan
                1;19(1):169-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=TNF-%CE%B1%20and%20sTNF-RII%20Are%20Associated%20with%20Pain%20Following%20Hip%20Fracture%20Surgery%20in%20Older%20Adults&as_occt=title&as_sauthors=%20%22FC%20Ko%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D9fc4ecc6-b456-45db-a60d-38c36dfc1ba1%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): e6</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.01077</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">January 15, 2025</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&native=1"
                              class="article-title" style="position: unset;">Complete Blood Cell Count-Based Ratios
                              Identify Total Joint Arthroplasty Patients Likely to Benefit from Perioperative
                              Dexamethasone</a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Wier,
                              Julian; Jones, Ian A.; Palmer, Ryan; Mayfield, Cory K.; Kassebaum, Nicholas J.; Lieberman,
                              Jay R.; Heckmann, Nathanael D.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 107(2):163 | November 20, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2025 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;9fc4ecc6-b456-45db-a60d-38c36dfc1ba1&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=9fc4ecc6-b456-45db-a60d-38c36dfc1ba1&type=pdf&name=JBJS.24.01077.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=9fc4ecc6-b456-45db-a60d-38c36dfc1ba1&type=pdf&name=JBJS.24.01077.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_6" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_7"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I281" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I281</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;9fc4ecc6-b456-45db-a60d-38c36dfc1ba1&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=9fc4ecc6-b456-45db-a60d-38c36dfc1ba1&type=zip&name=JBJS.24.01077.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sreevathsa Boraiah, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:vathsaboraiah@gmail.com" class=""
                    style="position: unset;">vathsaboraiah@gmail.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1921-117X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1921-117X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Zucker School of Medicine, Hofstra/Northwell, Hofstra University, Hempstead,
                New York</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Long Island Jewish Medical Center, Queens, New York</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Fellowship in Adult Reconstruction, Northwell Health, New Hyde Park, New
                York</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5105.5px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
