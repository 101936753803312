import type { Article } from "../../../../../types";

export const Article574: Article = {
	id: 574,
	rsuiteId: "a4cbcc14-ee6e-4856-ad35-8302a1c8ed94",
	type: "scientific article",
	title:
		"Manual Forearm Palpation in Acute Forearm Compartment Syndrome Is Not Accurate",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a4cbcc14-ee6e-4856-ad35-8302a1c8ed94&type=jpeg&name=JBJS.24.00229f1",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Manual Forearm Palpation in Acute Forearm Compartment
                Syndrome Is Not Accurate</div>
              <div class="text subtitle" style="position: unset;">A Cadaveric Study</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jouad Haydar,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Lukas
                      Ernstbrunner, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_24" class="annotation strong"
                          style="position: unset;">Background:</span> Manual compartment palpation is used as a
                        component of the clinical diagnosis of acute compartment syndrome (ACS), particularly in
                        obtunded patients. However, its utility and accuracy in the upper limb are unknown. The purposes
                        of this study were to assess the accuracy of manual compartment palpation of ACS in the forearm
                        in a cadaveric model and to assess the role of clinician experience in this setting.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_25" class="annotation strong"
                          style="position: unset;">Methods:</span> Reproducible, sustained elevation of
                        intracompartmental pressure was established in 8 fresh upper-limb cadaveric specimens. The 3
                        forearm compartments (volar, dorsal, and mobile wad) were randomized to pressures of 20 and 40
                        mm Hg (negative controls) and 60 and 80 mm Hg (ACS). This was achieved by using fluid infusion
                        and a pressure monitoring system. Orthopaedic clinicians with varying experience (residents,
                        registrars, and consultants) used palpation to assess forearm compartments with known
                        intracompartmental pressures. The examiners were blinded to the compartment pressures and the
                        other examiners’ responses. After the examination, the following 3 questions were answered: (1)
                        Was compartment syndrome present? (2) In which compartment(s) was the pressure elevated? (3)
                        What would be the next management step (fasciotomy or observation)?</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_26" class="annotation strong"
                          style="position: unset;">Results:</span> Manual palpation of compartment pressure had an
                        overall sensitivity of 70%, a specificity of 56%, a positive predictive value of 24%, a negative
                        predictive value of 90%, and a likelihood ratio (LR) of 20.3 (p &lt; 0.001). The sensitivity was
                        similar in detecting ACS in the volar and dorsal compartments (70% and 69%, respectively). The
                        sensitivity and specificity of combined volar and dorsal compartment palpation were 81% and 64%
                        (LR, 16.6; p &lt; 0.001) when performed by residents, 72% and 46% (LR, 4.2; p = 0.040) when
                        performed by registrars, and 58% and 63% (LR, 3.6; p = 0.057) when performed by consultants. All
                        of the compartments that were deemed positive for ACS were recommended for fasciotomy.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_27" class="annotation strong"
                          style="position: unset;">Conclusions:</span> In our study, manual palpation of compartment
                        pressure had a low accuracy in the diagnosis of ACS of the forearm and was not improved by
                        clinician experience.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> We recommend against the use of manual
                        palpation of compartment pressure in the diagnosis of forearm ACS in an obtunded patient.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Acute compartment syndrome (ACS) occurs when the
                  intracompartmental pressure exceeds the perfusion gradient across tissue capillary beds, resulting in
                  cellular anoxia and tissue ischemia<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">3</span></a>. A missed diagnosis of ACS can have devastating
                  consequences, including irreversible tissue ischemia, amputation, and death<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">7</span></a>. The
                  timely ability to detect a critical elevation in intracompartmental pressure in an extremity is
                  paramount<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">14</span></a>. In an obtunded patient, the lack of clinical symptoms
                  makes the diagnosis challenging<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">15</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">The diagnostic suspicion of ACS is based on the “5 Ps” of
                  pain, pallor, paresthesia, pulselessness, and paralysis. These clinical findings are purely
                  subjective, and sensitivity for the diagnosis of ACS is &lt;20%<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">16</span></a>.
                  Compartment firmness is possibly the only objective sign of ACS. It is the earliest clinical
                  manifestation of increased intracompartmental pressure and is not expected to change based on mental
                  status<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">3</span></a>.
                  However, palpation has never been brought forward as an accepted diagnostic criterion of ACS. This is
                  mainly due to its poor diagnostic accuracy, as shown with the lower limb<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">18</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The second most common site of ACS is the forearm<a
                    href="" data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">19</span></a>. Similar to the lower limb, the detection of elevated
                  intracompartmental pressure in this location is difficult and relies on the same clinical findings.
                  The role of palpation in the setting of forearm ACS in an obtunded patient is unclear and, as such,
                  the sensitivity and specificity of direct palpation of the forearm for detection of elevated
                  compartment pressures are unknown. The purposes of this study were to (1) assess the sensitivity of
                  manual palpation for the detection of critical elevations in isolated intracompartmental pressures in
                  the forearm, and (2) assess whether the ability to manually detect isolated elevations in forearm
                  intracompartmental pressure is dependent on the level of clinician experience. We hypothesized that
                  the sensitivity of manual palpation for the detection of critical elevations in intracompartmental
                  pressures in the forearm is poor and is influenced by the level of experience of the clinician.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The University of Melbourne Office of Research Ethics and
                  Integrity approved this cadaveric study (2021-22071-24124-4).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Specimen
                Preparation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">We used 8 fresh-frozen upper-limb cadavers from 5 men and
                  3 women with an average age of 69.7 years at the time of death that had been donated to the University
                  of Melbourne Body Donor Program. All of the cadaveric upper limbs were screened for evidence of
                  previous surgery or injury.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Experimental
                Setup and Testing</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">We utilized the cadaveric model of ACS that had previously
                  been described by Shuler and Dietz, which is capable of generating sustained and reproducible
                  alterations of intracompartmental pressures between 0 and 80 mm Hg<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">17</span></a>. Using Dyonics fluid-management pumps (Smith & Nephew), the
                  3 forearm compartments (volar, dorsal, and mobile wad) were insufflated with 0.9% sodium chloride
                  (NaCl) solution through 18-gauge cannulas in order to reach the desired pressures. These pressures
                  were 20, 40, 60, or 80 mm Hg. Pressures of 20 and 40 mm Hg were considered true negatives for ACS
                  (negative controls), whereas pressures of 60 and 80 mm Hg were considered true positives for ACS<a
                    href="" data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">17</span></a>. An online research randomizer tool was used to randomize
                  the volar and dorsal cadaveric compartments to the different pressures (20, 40, 60, and 80 mm Hg)<a
                    href="" data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">20</span></a>. All of the mobile wad compartments were left at 20 mm Hg
                  to function as internal controls.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The pressures were recorded and monitored with the
                  Datex-Ohmeda pressure monitoring system (GE) through arterial line tubing. Maintenance of these
                  pressures was achieved through continuous NaCl infusion through the Dyonics pumps and was monitored by
                  the pressure monitoring system (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00229f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a4cbcc14-ee6e-4856-ad35-8302a1c8ed94&type=jpeg&name=JBJS.24.00229f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;">Fluid-management pumps were used to insufflate the
                          3 forearm compartments (volar, dorsal, and mobile wad) to achieve pressures of 20, 40, 60, and
                          80 mm Hg. In order to reach the desired pressures, 0.9% NaCl solution was pumped into the
                          respective compartment through an 18-gauge cannula that was connected to the pump. EC =
                          extensor compartment (dorsal), FC = flexor compartment (volar), and MW = mobile wad.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">After a waiting period of 20 minutes to allow for
                  equilibration of intracompartmental pressures, direct intracompartmental pressure measurements at the
                  proximal, middle, and distal compartmental levels confirmed a consistent pressure throughout the
                  entire compartment. The specimens were kept moist with a spray of NaCl solution that was applied every
                  10 to 15 minutes during testing, which was performed at room temperature (24°C).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">All of the examiners (5 residents, 8 registrars, and 6
                  consultants) were clinicians who were currently working in an orthopaedic trauma unit of a tertiary
                  trauma center. All of the consultants had been fellowship-trained in orthopaedic trauma. The cadaveric
                  upper limb was presented to all of the examiners as a “single-limb model of an obtunded patient with a
                  blood pressure of 120/80 [mm Hg].” All of the examiners were blinded with regard to the pressure
                  monitoring system and the other examiners’ responses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Each examiner was asked to palpate all 3 of the forearm
                  compartments. They were provided 1 minute per upper limb. They then offered their assessments on each
                  compartment, which included (1) whether the compartment was positive or negative for ACS, (2) which
                  compartment(s) had elevated pressures, if any, and (3) what would be the next management step (either
                  performing a fasciotomy or proceeding with expectant management, namely, observation). The examiners
                  were divided into 4 separate examination groups, with an updated randomization for every group. Each
                  group underwent 2 circuits of examinations, with each circuit having a new set of randomizations for
                  each of the compartments. In total, every examiner palpated and assessed 24 compartments. The original
                  4 cadaveric specimens were replaced with 4 new specimens between the second and third circuits to
                  avoid cadaveric tissue fatigue. After completion of testing, the placement of the arterial line tubing
                  into the correct compartment was confirmed by dissection and direct visualization.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">A response was considered to be a true positive when the
                  participant (1) detected an elevated intracompartmental pressure (60 or 80 mm Hg) and (2) correctly
                  identified which compartment (volar or dorsal) was involved. A true negative response was considered
                  to be when the participant indicated no compartment syndrome in the compartments with pressures of 20
                  or 40 mm Hg.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The sensitivity, specificity, positive predictive value
                  (PPV), and negative predictive value (NPV) of manual forearm palpation in the diagnosis of ACS were
                  calculated for the entire cohort of examiners, each subgroup (residents, registrars, and consultants),
                  each compartment (dorsal and volar), and each intracompartmental pressure (20, 40, 60, and 80 mm Hg).
                  A likelihood ratio (LR) for each test result was calculated to indicate how likely compartments that
                  were deemed to have ACS (i.e., those with positive test results) actually had critically elevated
                  intracompartmental pressures (i.e., a positive target condition). Significance was set as p &lt; 0.05,
                  and all of the p values were 2-tailed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">A total of 456 compartment examinations were conducted
                  across the groups: 120 examinations (26%) were performed by residents, 192 (42%) were performed by
                  registrars, and 144 (32%) were performed by consultants.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The overall sensitivity of detecting ACS in a forearm with
                  palpation of both the combined volar and dorsal compartments was 70%, and the specificity was 56%. The
                  PPV was 24%, and the NPV was 90%. The LR of combined volar and dorsal palpation was 20.3 (p &lt;
                  0.001) (<a href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Detecting Critically Elevated Intracompartmental
                          Pressures of Combined Volar and Dorsal Compartments<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 183.667px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.779621);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Group</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Sensitivity <span data-id="emphasis_24" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Specificity <span data-id="emphasis_25" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">PPV <span
                            data-id="emphasis_26" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">NPV <span
                            data-id="emphasis_27" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Likelihood
                          Ratio</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Combined</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Residents</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Registrars</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">89</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.040</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Consultants</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.057</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Significance level was set as p &lt; 0.05. PPV = positive predictive
                        value, and NPV = negative predictive value.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">When analyzing the influence of clinician experience on
                  the ability to palpate elevated compartment pressures, when performed by residents, the sensitivity
                  and specificity of the combined volar and dorsal compartments were 81% and 64%, respectively, with an
                  LR of 16.6 (p &lt; 0.001). When performed by registrars, the sensitivity and specificity were 72% and
                  46%, respectively, with an LR of 4.2 (p = 0.040); and when performed by consultants, the sensitivity
                  and specificity were 58% and 63%, respectively, with an LR of 3.6 (p = 0.057) (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 2-A</a> and <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">2-B</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node figure" data-id="figure_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-A</div>
                    <div class="image-download" name="JBJS.24.00229f2a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a4cbcc14-ee6e-4856-ad35-8302a1c8ed94&type=jpeg&name=JBJS.24.00229f2a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_26" style="position: unset;">
                            <div class="content" style="position: unset;">Sensitivity to detect elevated compartment
                              pressures in the volar, dorsal, and combined compartments for the entire cohort of
                              examiners and for each subgroup (residents, registrars, and consultants).</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-B</div>
                    <div class="image-download" name="JBJS.24.00229f2b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a4cbcc14-ee6e-4856-ad35-8302a1c8ed94&type=jpeg&name=JBJS.24.00229f2b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_27" style="position: unset;">
                            <div class="content" style="position: unset;">Specificity to rule out elevated compartment
                              pressures in the volar, dorsal, and combined compartments for the entire cohort of
                              examiners and for each subgroup (residents, registrars, and consultants).</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The overall sensitivity and specificity of volar
                  compartment palpation alone was found to be 70% for both values, whereas the sensitivity and
                  specificity of dorsal compartment palpation were 69% and 46%, respectively. The LR was 22.6 (p &lt;
                  0.001) for volar compartment palpation and 3.2 (p = 0.076) for dorsal compartment palpation; the
                  latter did not reach significance. The mobile wad compartment (i.e., the negative control) had a
                  false-positive rate of 42%.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">When considering the accuracy of detecting ACS by
                  compartment palpation when only the extremes of intracompartmental pressures were considered (i.e., 20
                  versus 80 mm Hg; 40 and 60 mm Hg were excluded), the overall sensitivity of combined volar and dorsal
                  compartment palpation was 76%, the specificity was 60%, the PPV was 18%, the NPV was 96%, and the LR
                  was 18.5 (p &lt; 0.001). The sensitivity and specificity at 40 versus 60 mm Hg were 63% and 34%,
                  respectively, with an LR of 0.1 (p = 0.811) (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 3-A</a> and <a
                    href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">3-B</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node figure" data-id="figure_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-A</div>
                    <div class="image-download" name="JBJS.24.00229f3a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a4cbcc14-ee6e-4856-ad35-8302a1c8ed94&type=jpeg&name=JBJS.24.00229f3a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_31" style="position: unset;">
                            <div class="content" style="position: unset;">Overall sensitivity of combined volar and
                              dorsal compartment palpation at 60 and 80 mm Hg for the entire cohort of examiners as well
                              as for each subgroup (residents, registrars, and consultants).</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-B</div>
                    <div class="image-download" name="JBJS.24.00229f3b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a4cbcc14-ee6e-4856-ad35-8302a1c8ed94&type=jpeg&name=JBJS.24.00229f3b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_32" style="position: unset;">
                            <div class="content" style="position: unset;">Overall specificity of combined volar and
                              dorsal compartment palpation at 20 and 40 mm Hg for the entire cohort of examiners as well
                              as for each subgroup (residents, registrars, and consultants).</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">All of the compartments that were deemed positive for ACS
                  were recommended for fasciotomy. However, only 21% of the clinicians (2 registrars and 2 consultants)
                  opted for fasciotomy in all of the compartments when they assessed that a single compartment was
                  positive for ACS.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The main finding of this cadaveric study was that
                  palpation of compartment firmness in the forearm is neither sensitive nor a reliable method for the
                  diagnosis of ACS. While our first hypothesis was confirmed, we found that the accuracy of manual
                  forearm palpation in the setting of ACS does not improve with a clinician’s experience, thus refuting
                  our second hypothesis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">This study casts doubt on the clinical utility of
                  palpation in the diagnosis of ACS of the forearm. This has important implications for the clinical
                  assessment of obtunded patients with an upper limb injury who are at risk for ACS. Our results
                  indicate that assessment of compartments with suspected forearm ACS with palpation alone would miss
                  30% of the actual ACS cases. Therefore, because of the catastrophic clinical implications of a missed
                  diagnosis of compartment syndrome, palpation should not be used in isolation. The current results are
                  in line with the limited literature on the accuracy of manual detection of ACS. Shuler and Dietz found
                  manual compartment palpation in the lower limb to have a sensitivity of 24% and a specificity of 55%,
                  with a PPV of 21% and NPV of 64%<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">17</span></a>.
                  They also found that there was no substantial difference in sensitivity across the levels of clinician
                  experience. Similarly, Wong et al. conducted a cadaver-based study and demonstrated that palpation of
                  the thenar eminence had a sensitivity of 49% and a specificity of 79% when assessing for ACS<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">18</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">The low specificity (56%) of manual palpation observed in
                  the current study means that approximately 45% of patients who are assessed as positive for ACS
                  through palpation alone have false-positive results. This low specificity is less clinically
                  concerning than low sensitivity, yet it would result in needless surgery with the potential for
                  substantial comorbidity. In a retrospective study of 850 patients, McQueen et al. estimated that the
                  sensitivity of invasive compartment pressure testing in tibial shaft fractures was 94%<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">21</span></a>. To our knowledge, there has been no such study for forearm
                  compartment monitoring. However, considering our finding that palpation is unreliable, we recommend
                  that a decision to proceed to fasciotomy only be undertaken after manometry has been performed in
                  obtunded patients with suspected ACS of the forearm.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">In our study, the compartments that were deemed positive
                  for ACS were recommended for fasciotomy. However, only 21% of the clinicians opted for fasciotomy in
                  all of the compartments when they assessed that a single compartment was positive for ACS. It remains
                  unclear whether single-compartment rather than all-compartment fasciotomy in the setting of ACS of the
                  forearm should be performed, although the morbidity of an unnecessary fasciotomy on long-term limb
                  function is thought to be relatively low.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">The accuracy of manual forearm palpation slightly improved
                  at more extreme pressures. When including palpation performance at only 20 and 80 mm Hg, sensitivity
                  and specificity improved to 76% and 60%, respectively. With the borderline values (i.e., 60 and 40 mm
                  Hg), sensitivity and specificity dropped to 63% and 34%, respectively, which is concerning.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">We did not find a difference in the sensitivity of manual
                  forearm palpation based on the location of the compartment syndrome. The volar and dorsal compartments
                  demonstrated similar sensitivities (70% and 69%, respectively), although critically elevated volar
                  compartment pressures were 7 times more likely to be present in volar compartments deemed to be
                  positive for ACS than in dorsal compartments. The mobile wad compartment (20 mm Hg across all
                  specimens [negative control]) had a false-positive rate of 42%, demonstrating high rates of
                  confirmation bias among the examiners.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Surgical experience did not improve the diagnostic
                  accuracy of palpation. We had hypothesized that with increasing surgical and clinical experience,
                  there would be a trend toward more accurate diagnosis of ACS. However, we found no substantial
                  difference among the different levels of experience and palpation sensitivity. We were surprised to
                  see a trend toward reduced sensitivity of examination with increasing experience, although we were
                  unable to draw meaningful conclusions based on the observed trends. This does, however, cast doubt on
                  the suggestion that an orthopaedic surgeon with more experience may better determine the presence of
                  ACS by palpation alone. These findings have important clinical implications. Our results highlight the
                  difficulty of diagnosing compartment syndrome of the forearm in obtunded patients with clinical signs
                  alone. Based on our findings, clinicians should have a low threshold to undertake routine pressure
                  manometry (continuous or instantaneous) in any obtunded patient with an injury pattern that is
                  associated with a risk of ACS.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">There were limitations to this study, including pressure
                  limits that were taken from the previous model of Shuler and Dietz<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">17</span></a>, which were originally applied to the lower limb according
                  to resting compartment pressures of the leg. Gershuni et al.<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">22</span></a> and
                  Prayson et al.<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">23</span></a>
                  showed that the average resting compartment pressure in the anterior compartment of the leg was 28 and
                  20 mm Hg, respectively. To our knowledge, there has not been a large-scale study that has determined
                  the average forearm compartment pressure at rest. Although the model of Shuler and Dietz has proven to
                  be a reproducible cadaveric model<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">17</span></a>, we
                  did not conduct repeat measurements, which is another limitation. Additionally, it is possible that
                  the resting compartment pressures of the forearm and the leg are different. Another limitation is the
                  applicability of cadaveric models to clinical practice. There is the possibility of a difference in
                  the elastic and dynamic behaviors of live and cadaveric tissue. Cadaveric samples also tend to be from
                  older subjects with less muscle mass. It is unclear how compartment palpation would be affected by the
                  difference between cadaveric and live tissue. Furthermore, pumping fluid into cadaveric compartments
                  creates an isotropic liquid medium that is unlikely to mimic the heterogeneous nature of living
                  tissue. This could have affected the validity of the internal controls. ACS may develop from isolated
                  soft-tissue injuries, but it frequently is associated with bleeding from fractured long bones<a
                    href="" data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_19" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_19" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">11</span></a>. While we were able to reliably maintain compartment
                  pressures at the desired level, it is worth noting that our experimental model utilized only forearms
                  without fractures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">In our study, manual palpation of compartment pressure had
                  a low accuracy in the diagnosis of ACS of the forearm and was not improved by clinician experience. We
                  recommend against its use in the diagnosis of forearm ACS in an obtunded patient.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Gulli B</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Templeman D</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Compartment syndrome of the
                  lower extremity</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Orthop Clin North Am.</span> 1994 Oct;25(4):677-84.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Compartment%20syndrome%20of%20the%20lower%20extremity&as_occt=title&as_sauthors=%20%22B%20Gulli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Mabee JR</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Bostwick TL</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Pathophysiology and mechanisms
                  of compartment syndrome</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Orthop Rev.</span> 1993 Feb;22(2):175-81.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pathophysiology%20and%20mechanisms%20of%20compartment%20syndrome&as_occt=title&as_sauthors=%20%22JR%20Mabee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Olson SA</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Glasgow RR</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Acute compartment syndrome in
                  lower extremity musculoskeletal trauma</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2005 Nov;13(7):436-44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20compartment%20syndrome%20in%20lower%20extremity%20musculoskeletal%20trauma&as_occt=title&as_sauthors=%20%22SA%20Olson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> McQueen MM</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Christie J</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Court-Brown CM</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Acute compartment syndrome in
                  tibial diaphyseal fractures</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 1996 Jan;78(1):95-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20compartment%20syndrome%20in%20tibial%20diaphyseal%20fractures&as_occt=title&as_sauthors=%20%22MM%20McQueen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Rorabeck CH</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Macnab L</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Anterior tibial-compartment
                  syndrome complicating fractures of the shaft of the tibia</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 1976
                Jun;58(4):549-50.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=446763"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Sheridan GW</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Matsen FA 3rd</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Fasciotomy in the treatment of
                  the acute compartment syndrome</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1976 Jan;58(1):112-5.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=394673" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Stevanovic M</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Sharpe F</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Management of established
                  Volkmann’s contracture of the forearm in children</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Hand Clin.</span> 2006
                Feb;22(1):99-111.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20established%20Volkmann%E2%80%99s%20contracture%20of%20the%20forearm%20in%20children&as_occt=title&as_sauthors=%20%22M%20Stevanovic%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Bourne RB</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Rorabeck CH</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Compartment syndromes of the
                  lower leg</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 1989 Mar;(240):97-104.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Compartment%20syndromes%20of%20the%20lower%20leg&as_occt=title&as_sauthors=%20%22RB%20Bourne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Elliott KG</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Johnstone AJ</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Diagnosing acute compartment
                  syndrome</span>. <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Br.</span> 2003 Jul;85(5):625-32.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnosing%20acute%20compartment%20syndrome&as_occt=title&as_sauthors=%20%22KG%20Elliott%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Heckman MM</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Whitesides TE Jr</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Grewe SR</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Rooks MD</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Compartment pressure in
                  association with closed tibial fractures. The relationship between tissue pressure, compartment, and
                  the distance from the site of the fracture</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 1994
                Sep;76(9):1285-92.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=616274"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Matava MJ</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Whitesides TE Jr</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Seiler JG 3rd</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Hewan-Lowe K</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Hutton WC</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Determination of the
                  compartment pressure threshold of muscle ischemia in a canine model</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Trauma.</span> 1994
                Jul;37(1):50-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Determination%20of%20the%20compartment%20pressure%20threshold%20of%20muscle%20ischemia%20in%20a%20canine%20model&as_occt=title&as_sauthors=%20%22MJ%20Matava%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> McQueen MM</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Gaston P</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Court-Brown CM</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Acute compartment syndrome. Who
                  is at risk?</span><span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Br.</span> 2000 Mar;82(2):200-3.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20compartment%20syndrome.%20Who%20is%20at%20risk%3F&as_occt=title&as_sauthors=%20%22MM%20McQueen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Mubarak SJ</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Owen CA</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Hargens AR</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Garetto LP</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Akeson WH</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Acute compartment syndromes:
                  diagnosis and treatment with the aid of the wick catheter</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 1978
                Dec;60(8):1091-5.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=414462"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Ulmer T</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">The clinical diagnosis of
                  compartment syndrome of the lower leg: are clinical findings predictive of the disorder?</span><span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span>
                2002 Sep;16(8):572-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20clinical%20diagnosis%20of%20compartment%20syndrome%20of%20the%20lower%20leg%3A%20are%20clinical%20findings%20predictive%20of%20the%20disorder%3F&as_occt=title&as_sauthors=%20%22T%20Ulmer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Ouellette EA</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Compartment syndromes in
                  obtunded patients</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Hand Clin.</span> 1998 Aug;14(3):431-50.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Compartment%20syndromes%20in%20obtunded%20patients&as_occt=title&as_sauthors=%20%22EA%20Ouellette%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> White TO</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Howell GE</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Will EM</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Court-Brown CM</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> McQueen MM</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Elevated intramuscular
                  compartment pressures do not influence outcome after tibial fracture</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J Trauma.</span> 2003
                Dec;55(6):1133-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Elevated%20intramuscular%20compartment%20pressures%20do%20not%20influence%20outcome%20after%20tibial%20fracture&as_occt=title&as_sauthors=%20%22TO%20White%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Shuler FD</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Dietz MJ</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Physicians’ ability to manually
                  detect isolated elevations in leg intracompartmental pressure</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2010
                Feb;92(2):361-7.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1066085"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Wong JC</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Vosbikian MM</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Dwyer JM</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Ilyas AM</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Accuracy of measurement of hand
                  compartment pressures: a cadaveric study</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">J Hand Surg Am.</span> 2015
                Apr;40(4):701-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Accuracy%20of%20measurement%20of%20hand%20compartment%20pressures%3A%20a%20cadaveric%20study&as_occt=title&as_sauthors=%20%22JC%20Wong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Raza H</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Mahapatra A</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Acute compartment syndrome in
                  orthopedics: causes, diagnosis, and management</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Adv Orthop.</span> 2015;2015:543412.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20compartment%20syndrome%20in%20orthopedics%3A%20causes%2C%20diagnosis%2C%20and%20management&as_occt=title&as_sauthors=%20%22H%20Raza%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Research Randomizer</span>.
                Accessed 2024 Jul 25. www.Randomizer.org</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> McQueen MM</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Duckworth AD</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Aitken SA</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Court-Brown CM</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">The estimated sensitivity and
                  specificity of compartment pressure monitoring for acute compartment syndrome</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2013 Apr 17;95(8):673-7.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1162559" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Gershuni DH</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Yaru NC</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Hargens AR</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Lieber RL</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> O’Hara RC</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Akeson WH</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Ankle and knee position as a
                  factor modifying intracompartmental pressure in the human leg</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 1984
                Dec;66(9):1415-20.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=494014"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Prayson MJ</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Chen JL</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Hampers D</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Vogt M</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Fenwick J</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Meredick R</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Baseline compartment pressure
                  measurements in isolated lower extremity fractures without clinical compartment syndrome</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">J Trauma.</span> 2006
                May;60(5):1037-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Baseline%20compartment%20pressure%20measurements%20in%20isolated%20lower%20extremity%20fractures%20without%20clinical%20compartment%20syndrome&as_occt=title&as_sauthors=%20%22MJ%20Prayson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Da4cbcc14-ee6e-4856-ad35-8302a1c8ed94%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2119</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00229</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 19, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_23"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Biomedical Engineering, University of Melbourne, Parkville, Victoria,
                            Australia</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;a4cbcc14-ee6e-4856-ad35-8302a1c8ed94&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=a4cbcc14-ee6e-4856-ad35-8302a1c8ed94&type=pdf&name=JBJS.24.00229.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=a4cbcc14-ee6e-4856-ad35-8302a1c8ed94&type=pdf&name=JBJS.24.00229.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_29"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_30" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I202"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I202</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;a4cbcc14-ee6e-4856-ad35-8302a1c8ed94&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=a4cbcc14-ee6e-4856-ad35-8302a1c8ed94&type=zip&name=JBJS.24.00229.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jouad Haydar, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0003-5039-7587" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0003-5039-7587</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lukas Ernstbrunner, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:lukas.ernstbrunner@icloud.com" class=""
                    style="position: unset;">lukas.ernstbrunner@icloud.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4920-8518" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4920-8518</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">David C. Ackland, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0559-7569" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0559-7569</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Christopher Pullen, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0431-4544" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0431-4544</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Thomas Treseder, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0003-6974-7575" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0003-6974-7575</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Royal Melbourne Hospital, Parkville,
                Victoria, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Biomedical Engineering, University of Melbourne, Parkville,
                Victoria, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 20987.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
