import { useAuth } from "@clerk/clerk-expo";
import { cacheKeys } from "@constants/cache-keys";
import { useQuery } from "@tanstack/react-query";
import { fetchUserRank } from "../queries/leaderboard";

const useUserLeaderboard = (tenantId: string, scope: string) => {
	const { getToken } = useAuth();
	const { data: response } = useQuery({
		queryKey: [cacheKeys.userLeaderboard, tenantId, scope],
		queryFn: async () => {
			const token = await getToken({
				template: "jwt-with-user",
			});
			if (!token) {
				throw new Error("No token");
			}
			return await fetchUserRank({ userId: 361459, token, tenantId });
		},
	});

	const userLeaderboardResponse = response?.data.all_time;

	return {
		// userLeaderboardResponse: isScreenshotMode
		// 	? mockedUserLeaderboard
		// 	: userLeaderboardResponse,
		userLeaderboardResponse,
	};
};

export default useUserLeaderboard;
