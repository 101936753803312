import type { Article } from "../../../../../types";

export const Article642: Article = {
	id: 642,
	rsuiteId: "a29eb012-899d-4a2a-9e08-690734ea4b8f",
	type: "scientific article",
	title:
		"Multicenter Randomized Clinical Trial of Highly Cross-Linked Polyethylene Versus Conventional Polyethylene in 518 Primary TKAs at 10 Years",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a29eb012-899d-4a2a-9e08-690734ea4b8f&type=jpeg&name=JBJS.24.00445f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Multicenter Randomized Clinical Trial of Highly
                Cross-Linked Polyethylene Versus Conventional Polyethylene in 518 Primary TKAs at 10 Years</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Harold I.
                      Salmons, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Dirk R.
                      Larson, MS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Background:</span> Second-generation highly cross-linked polyethylene
                        (HXLPE) has revolutionized total hip arthroplasty. However, the long-term risks and benefits of
                        HXLPE in primary total knee arthroplasty (TKA) remain unknown. This randomized clinical trial
                        evaluated implant survivorship, complications, radiographic results, and clinical outcomes of
                        HXLPE and conventional ultra-high molecular weight polyethylene (UHMWPE) inserts in primary
                        TKAs.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Methods:</span> We enrolled 518 patients (518 knees) who underwent
                        primary TKA in 3 centers within 1 tertiary referral network. The mean age was 67 years, the mean
                        body mass index was 32 kg/m<span data-id="superscript_1" class="annotation superscript"
                          style="position: unset;">2</span>, and 58% of the patients were women. All of the patients
                        underwent primary TKA with a cemented posterior-stabilized tibial insert and patellar
                        resurfacing. Randomization proceeded via stratified dynamic allocation. The patients were
                        blinded to their study group allocation: those in the control group (254 knees) underwent TKA
                        with an UHMWPE insert (N2Vac; Stryker); those in the treatment group (264 knees) received an
                        HXLPE insert (X3; Stryker). Kaplan-Meier survivorship, radiographic results, and clinical
                        outcomes were assessed. This trial was registered with ClinicalTrials.gov. The mean follow-up
                        was 11 years.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Results:</span> The 10-year overall survivorship free from any
                        revision and from any reoperation was 96% and 94%, respectively. There were no differences in
                        the risk of revision or reoperation between the groups (p &gt; 0.05). There were a total of 19
                        revisions. Revision indications included periprosthetic joint infection (14 knees), instability
                        (4 knees), and open reduction and internal fixation (ORIF) for a patellar fracture due to
                        osteolysis around a UHMWPE insert (1 knee). There were no revisions due to polyethylene wear,
                        osteolysis, or fracture of the post in the HXLPE group. The radiographic results and clinical
                        outcomes were otherwise similar.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Notably, no wear-related failures were identified
                        in the HXLPE group, but there was 1 case of osteolysis in the UHMWPE group.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level I</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The demand for total knee arthroplasty (TKA) in the United
                  States is projected to rise by as much as 139% by 2040<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">1</span></a>. With
                  this increase in primary TKAs, the risk of revision will correspondingly increase<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">2</span></a>. Polyethylene (PE) wear and osteolysis are recognized
                  etiologies for late cases of aseptic loosening that necessitate revision<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">3</span></a>. Hence, it remains imperative to develop durable implants
                  that will meet the growing needs of patients undergoing TKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Highly cross-linked polyethylene (HXLPE) was introduced in
                  the late 1990s as a means of reducing wear and debris-induced osteolysis after total joint
                  arthroplasty<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">4</span></a>. One
                  method of producing HXLPE is by gamma-irradiating ultra-high molecular weight polyethylene (UHMWPE)<a
                    href="" data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">4</span></a>. Radiation interrupts intramolecular bonds and produces free
                  radicals that lead to crosslinking across multiple polymer chains. This leads to increased density and
                  improved wear characteristics for the PE<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">5</span></a>.
                  HXLPE has been clinically determined to have improved wear characteristics when compared with
                  conventional UHMWPE in total hip arthroplasty (THA), and it is the preferred insert material in many
                  contemporary THAs<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">8</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">HXLPE has reduced ductility and fatigue resistance, which
                  potentially impact the performance of the material<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">9</span></a>. In
                  contrast to the ball-and-socket joint of THAs, the tibial inserts used in TKAs undergo substantially
                  different force profiles during gait. These forces place the PE inserts at a theoretically greater
                  risk for wear. To combat these risks, second-generation HXLPE inserts were developed in an attempt to
                  reduce free radicals, with some manufacturers using sequential irradiation and annealing or
                  antioxidant stabilization. However, while second-generation HXLPE has demonstrated improved wear
                  profiles through biomechanical analysis, the existing clinical knowledge regarding the performance of
                  HXLPE in TKAs is limited<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">12</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Therefore, the goal of the present multicenter randomized
                  clinical trial (RCT) was to investigate long-term implant survivorship, clinical outcomes, and
                  radiographic results of a sequentially annealed HXLPE insert (X3; Stryker) and compare it with a
                  conventional UHMWPE insert (N2Vac; Stryker) in primary TKAs. We hypothesized that HXLPE would perform
                  equally well compared with UHMWPE at 10 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Prior to enrollment, this trial was registered at
                  ClinicalTrials.gov (NCT00493792) and received institutional review board approval (IRB 06-003093).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The present multicenter RCT was designed to evaluate the
                  long-term survivorship of second-generation HXLPE compared with conventional UHMWPE in primary TKAs
                  with cemented components. Patients were randomly allocated to a treatment or control group. All
                  patients underwent posterior-stabilized TKA with cemented components (Triathlon; Stryker), a
                  fixed-bearing PE tibial insert, and patellar resurfacing. The control group received a UHMWPE insert
                  (N2Vac), and the treatment group received an HXLPE insert (X3). Patients were blinded to their study
                  group allocation. All of the surgeons implemented mechanical-alignment TKA balancing strategies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patients</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Patients were recruited from 3 academic tertiary-care
                  referral centers within 1 health-care network and provided informed consent. Patients who were
                  approached for enrollment underwent a unilateral primary TKA for osteoarthritis (OA), osteonecrosis,
                  or posttraumatic arthritis. Patients were excluded if they had inflammatory arthritis, a history of
                  prior reconstruction of the index knee, a prior high tibial osteotomy or distal femoral osteotomy, a
                  history of neuromuscular or neurosensory disease, systemic or metabolic bone disorders leading to
                  progressive bone loss, a prior arthrodesis of the index knee, or a history of infection in the index
                  knee, or if they were immunocompromised, incarcerated, or considered part of a vulnerable patient
                  population. Demographic details were recorded, including age at the index arthroplasty, sex, body mass
                  index (BMI), Charlson Comorbidity Index, and American Society of Anesthesiologists (ASA)
                  classification.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Randomization</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The randomization of patients was performed following
                  enrollment and prior to the index surgery. Randomization was stratified by variables with potential
                  confounding effects on the outcomes of interest. Specifically, patients were stratified by sex (male
                  or female), BMI category (&lt;25.0, 25.0 to 29.9, and ≥30 kg/m<span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">2</span>), and age group (21 to 55 and 56 to
                  85 years of age). Within each stratum, patients were allocated to 1 of the 2 treatment groups using a
                  computerized dynamic allocation program that was hosted at the principal investigative site (Mayo
                  Clinic, Rochester, Minnesota). This system was password-protected and was available only to study
                  personnel at the participating centers.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Power
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The sample size was estimated on the basis of the primary
                  outcome, the revision rate at 10 years (regarded as any orthopaedic follow-up between 9 and 12 years
                  postoperatively). It was assumed that the 10-year revision rate in the control group would be 5.0%,
                  and that the corresponding revision rate in the treatment group would be 1.6%. This corresponds to a
                  rate ratio of 0.32 (HXLPE relative to UHMWPE). In order to have 80% power to identify this rate ratio
                  as being significantly different from 1.0 required that 26 revisions be observed during the 10-year
                  follow-up period. Assuming that the overall 10-year revision rate was 5%, the sample size that was
                  required to observe 26 revisions was 26 ÷ 0.05 = 520, or n = 260 patients in each of the 2 study
                  groups. To account for a potential 10% rate of loss to follow-up within the 10-year period, the target
                  sample size was increased to 578 (n = 289 in each group).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcome
                Measures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcome measure was implant survivorship free
                  from any revision at 10 years postoperatively. Secondary outcome measures included survivorship free
                  from any reoperation or from any nonoperative complication, functional and clinical outcome scores,
                  and radiographic evaluation for radiolucent lines according to The Knee Society Roentgenographic
                  Evaluation and Scoring System<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">13</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Perioperative Care</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Patient disposition (outpatient versus inpatient) was
                  determined, and venous thromboembolism chemoprophylaxis was administered to the patients at the
                  discretion of the treating orthopaedic surgeon. Patients received standard inpatient physical therapy
                  services while in the hospital and were sent home with physical therapy exercises pursuant to their
                  surgeon’s preferred protocols. All of the surgeries were performed by board-certified or
                  board-eligible orthopaedic consultant surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Demographics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The final number of enrolled and analyzed patients was 518
                  (58% female, 518 knees), with 264 patients in the treatment group and 254 patients in the control
                  group (<a href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). At the time of the TKA, patients had a mean age of 67 years
                  (range, 36 to 84 years) and a mean BMI of 32 kg/m<span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">2</span> (range, 18 to 79 kg/m<span
                    data-id="superscript_14" class="annotation superscript" style="position: unset;">2</span>). Patient
                  race, ethnicity, and socioeconomic status were not collected during the study surveillance period, and
                  thus not reported in the present investigation. The 2 groups were similar with respect to their
                  baseline demographics (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). Prior
                  to 10 years of follow-up, 16 of the TKAs were revised, 48 patients died, and 71 (14%) were lost to
                  follow-up. The mean follow-up for the remaining 383 patients who were alive and had not undergone
                  revision at 10 years was 11 years (range, 9 to 16 years). There was no significant difference in the
                  number of patients lost to follow-up between the study groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00445f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a29eb012-899d-4a2a-9e08-690734ea4b8f&type=jpeg&name=JBJS.24.00445f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">CONSORT flow diagram outlining study enrollment.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Baseline Demographics<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 858px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Study Group</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Total (N = 518)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HXLPE (N =
                          264)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">UHMWPE (N
                          = 254)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at surgery <span
                            data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean (SD)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67
                          (9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67
                          (9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67
                          (9)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Range</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">43-84</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">36-84</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">36-84</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex <span
                            data-id="emphasis_32" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109
                          (41%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109
                          (43%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">218
                          (42%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">155
                          (59%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">145
                          (57%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">300
                          (58%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Height <span
                            data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean (SD)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">169
                          (10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">169
                          (10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">169
                          (10)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Range</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">148-198</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">118-196</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">118-198</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Weight <span
                            data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(kg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean (SD)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92
                          (21)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92
                          (21)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92
                          (21)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Range</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">46-179</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">53-157</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">46-179</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_35" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_36" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_15" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_15"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_37"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean (SD)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (7)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Range</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18-50</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20-79</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18-79</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Previous knee surgery
                          <span data-id="emphasis_38" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">179
                          (69%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">157
                          (63%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">336
                          (66%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82
                          (31%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">91
                          (37%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">173
                          (34%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">HXLPE = highly cross-linked polyethylene, UHMWPE = ultra-high molecular
                        weight polyethylene, SD = standard deviation, and BMI = body mass index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Data were reported using suitable summary statistics,
                  including counts and percentages for categorical variables, and means and standard deviations for
                  continuous variables. As the subjects were randomly allocated to their respective study groups,
                  meaningful differences in baseline data between each group were not anticipated. As such, pursuant to
                  the Consolidating Standards of Reporting Trials (CONSORT), no statistical comparisons of baseline
                  demographics were performed<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">15</span></a>. Normally distributed data were compared using the 2-sample
                  t test; Wilcoxon rank sum tests were utilized for non-normally distributed data. Chi-square tests were
                  utilized to compare categorical outcomes. The analysis was focused on the 10-year time point.
                  Time-to-event outcomes, such as revisions, reoperations, and other complications, were analyzed using
                  survivorship analysis, including Kaplan-Meier estimation and Cox regression. Patients were censored at
                  the time of death, withdrawal from the study, or the last known follow-up. All of the statistical
                  tests were 2-sided, and p values of &lt;0.05 were considered significant. The data were analyzed using
                  SAS software (version 9.4M6; SAS Institute) and R (version 4.2.2; R Foundation for Statistical
                  Computing).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Survival
                Free from Any Revision, Any Reoperation, and Any Complication</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The survivorship free from any revision in the entire
                  cohort at 10 years was 96% (95% confidence interval [CI]: 94% to 98%; <a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). The 10-year survivorship free from any revision in the HXLPE
                  and UHMWPE groups was 97% (95% CI: 94% to 99%) and 96% (95% CI: 93% to 98%), respectively (hazard
                  ratio [HR], 0.72; 95% CI: 0.29 to 1.77; p = 0.47). There were a total of 19 revisions, which were
                  performed for periprosthetic joint infection (14 knees), instability (4 knees), and open reduction and
                  internal fixation (ORIF) for patellar fracture due to osteolysis around a conventional UHMWPE insert
                  (1 knee). There were no tibial insert revisions or additional patellar component revisions for aseptic
                  loosening at 10 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00445f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a29eb012-899d-4a2a-9e08-690734ea4b8f&type=jpeg&name=JBJS.24.00445f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier survivorship curve demonstrating the
                          10-year survivorship free from any revision.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The survivorship free from any reoperation in the entire
                  cohort at 10 years was 94% (95% CI: 92% to 96%; <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>). The
                  10-year survivorship free from any reoperation in the HXLPE and UHMWPE groups was 96% (95% CI: 93% to
                  99%) and 92% (95% CI: 89% to 96%), respectively (HR, 0.49; 95% CI: 0.23 to 1.03; p = 0.06). There were
                  a total of 11 non-revision reoperations; these included manipulation under anesthesia (MUA) or
                  arthroscopic lysis of adhesions for arthrofibrosis in 7 knees, ORIF of a periprosthetic fracture in 3
                  knees, and a superficial wound revision in 1 knee.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00445f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a29eb012-899d-4a2a-9e08-690734ea4b8f&type=jpeg&name=JBJS.24.00445f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier survivorship curve demonstrating the
                          10-year survivorship free from any reoperation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The survivorship free from any non-revision or
                  non-reoperation-related complication was 87% (95% CI: 84% to 90%) for the entire cohort at 10 years.
                  There were no differences in the rate of nonoperative complications in either group at any time point.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">When comparing preoperative validated outcome scores to
                  those at 10 years of follow-up, a significant improvement in the Knee Society Score (KSS) Knee, the
                  KSS Function score, and the 12-item Short Form Health Survey (SF-12) Physical Component Summary (PCS)
                  was observed (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). No
                  differences between the treatment groups were noted for any outcome measure at 10 years (p &gt; 0.05).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Preoperative Versus 10-Year Clinical Outcome
                          Scores<span data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 329px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Outcome</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Baseline</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">10-Year Follow-up</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Difference</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Mean</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Mean</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Mean</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KSS Knee</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_39" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KSS Function score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">200</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">200</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_40" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SF-12 MCS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.65</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.57</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SF-12 PCS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_41" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">KSS = Knee Society Score, and SF-12 = 12-item Short Form Health Survey,
                        MCS= Mental Component Summary, and PCS= Physical Component Summary. Bold numbers indicate
                        statistical significance.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">There was no radiographic evidence of loosening or
                  osteolysis about the TKA prostheses at 10 years. With respect to the tibial component, the bone-cement
                  interface had nonprogressive radiolucent lines in 2 (9%) of the knees that underwent TKA with HXLPE
                  components versus 7 (18%) of the knees that underwent TKA with UHMWPE components (p = 0.48). At the
                  prosthesis-cement interface, there were no radiolucent areas. Regarding the femoral component, the
                  bone-prosthesis interface had no radiolucent lines in the HXLPE group, but 3 (8%) in the UHMWPE group
                  had radiolucent lines (p = 0.29).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The present multicenter RCT evaluated long-term implant
                  survivorship, clinical outcomes, and radiographic results of a second-generation HXLPE tibial insert
                  (X3) and compared the results to those of a conventional UHMWPE (N2Vac) insert in primary TKAs with
                  cemented components. The 10-year survivorship free from any revision or reoperation was excellent for
                  both the X3 HXLPE and N2Vac UHMWPE inserts. There were no statistical or clinically important
                  differences between the groups with respect to revision risk. Importantly, we did not observe problems
                  regarding reduced ductility and fatigue resistance of the HXLPE inserts that could potentially have
                  led to an increased risk of tibial insert or post fractures. Indeed, only 1 conventional UHMWPE
                  patellar component was revised for osteolysis, fracture, and loosening at 9 years postoperatively.
                  Furthermore, clinical and radiographic results were excellent in both groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">While studies have demonstrated excellent wear
                  characteristics of HXLPE components in THAs, the data with respect to HXLPE tibial inserts in TKAs are
                  limited<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_17" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_17" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">17</span></a>. Fairly recent studies have demonstrated no difference in
                  implant survivorship free from aseptic revision between HXLPE and conventional PE inserts in TKAs at
                  midterm follow-up<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_18" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">17</span></a>. In 1 subanalysis of a series of over a half million TKAs,
                  Partridge et al. demonstrated improved revision rates with HXLPE versus UHMWPE inserts in a younger,
                  higher-demand patient population<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">5</span></a>.
                  However, adjusted analysis showed no difference between insert types in the overall cohort. In the
                  present RCT, we determined that long-term survivorship free from any revision or reoperation was
                  excellent with both HXLPE and conventional UHMWPE fixed-bearing inserts in TKAs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">An understanding of the impact of implant design on
                  patient clinical outcome scores following TKA is imperative. In our study, we determined that there
                  was an overall significant improvement in the KSS Knee, the KSS Function score, and the SF-12 PCS from
                  preoperatively to 10 years postoperatively for the entire cohort. However, no differences were
                  observed between the groups for any clinical outcome measure at long-term follow-up. These data concur
                  with existing midterm Level-I evidence<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">18</span></a>.
                  The choice between HXLPE and UHMWPE inserts in primary TKAs appeared to have no impact on long-term
                  clinical outcomes in the present RCT.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">HXLPE was developed out of concern for wear and
                  osteolysis-related failures. Furthermore, prior investigations have reported concerns with
                  HXLPE-reduced fracture toughness and associated fracture or failure of the posterior-stabilized post
                  or the patellar component<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">23</span></a>. In our study, only 1 UHMWPE patellar component required
                  revision for osteolysis-associated fracture. These results are corroborated by existing biomechanical
                  and short-to-midterm clinical data<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">28</span></a>. Whether or not a true difference in wear-related failures
                  may be observed clinically between HXLPE and UHMWPE components remains to be determined in longer-term
                  analyses with larger data sets. Interestingly, in a recent meta-analysis that studied &gt;900,000
                  patients, although no significant overall difference was found for the risk of any revision with HXLPE
                  versus conventional PE inserts, a subanalysis of 411,543 patients suggested that the revision rate for
                  aseptic loosening was significantly lower with HXLPE than conventional PE inserts<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">29</span></a>. Thus, longer-term studies with more power are necessary to
                  further evaluate the potential mitigation of wear-related failures in HXLPE versus conventional UHMWPE
                  inserts.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">This study did have potential limitations. As wear-related
                  failures require a substantial length of time in which to present, longer-term data are necessary to
                  definitively uncover differential wear and osteolysis risk between HXLPE and UHMWPE components.
                  However, to our knowledge, this RCT represents the largest and longest multicenter study of this topic
                  to date, and no cases of aseptic tibial or femoral component loosening necessitating revision were
                  detected in either group. Additionally, the present investigation strictly utilized cemented femoral
                  and tibial component fixation and posterior-stabilized bearing designs. Therefore, additional study is
                  required to assess the performance of HXLPE in non-posterior-stabilized bearings as well as in
                  combination with cementless fixation. Additionally, a potential for bias existed because 14% of the
                  patients were lost to follow-up by 10 years; however, there was no differential attrition between the
                  groups. Furthermore, while the sample size that we had estimated as providing sufficient power was
                  slightly larger than the number of subjects enrolled in the control group (260 expected, 254
                  randomized), it is unlikely that a few more patients in that group would have made an impact on the
                  equivocal results observed in the investigation. Additionally, the present investigation did not
                  record patient ethnicity, race, or socioeconomic status. While this may influence the investigation’s
                  generalizability, the impact of these parameters on revision risk after TKA are uncertain. Lastly, as
                  this study assessed the use of HXLPE inserts in mechanically aligned TKAs, additional study remains
                  necessary to investigate the outcomes of HXLPE inserts in kinematically aligned TKAs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, this multicenter RCT underscored the
                  remarkable long-term performance of both HXLPE and UHMWPE tibial inserts in primary cemented TKAs over
                  a mean follow-up duration of 11 years. Notably, the HXLPE group exhibited no instances of wear-related
                  failures, while 1 of the TKAs in the UHMWPE group necessitated revision of the patellar component due
                  to osteolysis-associated fracture. Given their potential to mitigate wear-related risks over extended
                  follow-up periods, along with their comparable survivorship and clinical outcomes to UHMWPE
                  components, HXLPE components may emerge as the preferred choice for TKAs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank Kristin R. Fruth, BS, for her statistical
                  assistance and our clinical research staff who supported this project throughout its duration. We also
                  acknowledge the Andrew A. and Mary S. Sugg Professorship in Orthopedic Surgery (MPA) that made such
                  research possible.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Shichman I</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Roof M</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Askew N</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Nherera L</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Rozell JC</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Seyler TM</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Schwarzkopf R</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Projections and Epidemiology of
                  Primary Hip and Knee Arthroplasty in Medicare Patients to 2040-2060</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">JB JS Open Access.</span> 2023 Feb
                28;8(1):e22.00112.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Projections%20and%20Epidemiology%20of%20Primary%20Hip%20and%20Knee%20Arthroplasty%20in%20Medicare%20Patients%20to%202040-2060&as_occt=title&as_sauthors=%20%22I%20Shichman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Delanois RE</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Mistry JB</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Gwam CU</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Mohamed NS</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Choksi US</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Mont MA</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Current Epidemiology of Revision
                  Total Knee Arthroplasty in the United States</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2017
                Sep;32(9):2663-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20Epidemiology%20of%20Revision%20Total%20Knee%20Arthroplasty%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22RE%20Delanois%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Khan M</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Osman K</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Green G</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Haddad FS</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">The epidemiology of failure in
                  total knee arthroplasty: avoiding your next revision</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2016 Jan;98-B(1)(Suppl
                A):105-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20epidemiology%20of%20failure%20in%20total%20knee%20arthroplasty%3A%20avoiding%20your%20next%20revision&as_occt=title&as_sauthors=%20%22M%20Khan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Brown TS</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Van Citters DW</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Berry DJ</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Abdel MP</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The use of highly crosslinked
                  polyethylene in total knee arthroplasty</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2017 Aug;99-B(8):996-1002.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20highly%20crosslinked%20polyethylene%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22TS%20Brown%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Partridge TCJ</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Baker PN</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Jameson SS</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Mason J</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Reed MR</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Deehan DJ</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Conventional Versus Highly
                  Cross-Linked Polyethylene in Primary Total Knee Replacement: A Comparison of Revision Rates Using Data
                  from the National Joint Registry for England, Wales, and Northern Ireland</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2020 Jan 15;102(2):119-27.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=2236061" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Jasty M</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Rubash HE</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Muratoglu O</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Highly cross-linked
                  polyethylene: the debate is over—in the affirmative</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2005 Jun;20(4)(Suppl
                2):55-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Highly%20cross-linked%20polyethylene%3A%20the%20debate%20is%20over%E2%80%94in%20the%20affirmative&as_occt=title&as_sauthors=%20%22M%20Jasty%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Olyslaegers C</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Defoort K</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Simon JP</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Vandenberghe L</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Wear in conventional and highly
                  cross-linked polyethylene cups: a 5-year follow-up study</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2008
                Jun;23(4):489-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Wear%20in%20conventional%20and%20highly%20cross-linked%20polyethylene%20cups%3A%20a%205-year%20follow-up%20study&as_occt=title&as_sauthors=%20%22C%20Olyslaegers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Bergvinsson H</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Zampelis V</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Sundberg M</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Flivik G</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Highly cross-linked polyethylene
                  still outperforms conventional polyethylene in THA: 10-year RSA results</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2021
                Oct;92(5):568-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Highly%20cross-linked%20polyethylene%20still%20outperforms%20conventional%20polyethylene%20in%20THA%3A%2010-year%20RSA%20results&as_occt=title&as_sauthors=%20%22H%20Bergvinsson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Bradford L</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Baker D</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Ries MD</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Pruitt LA</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Fatigue crack propagation
                  resistance of highly crosslinked polyethylene</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2004
                Dec;(429):68-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fatigue%20crack%20propagation%20resistance%20of%20highly%20crosslinked%20polyethylene&as_occt=title&as_sauthors=%20%22L%20Bradford%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Meneghini RM</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Ireland PH</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Bhowmik-Stoker M</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Multicenter Study of Highly
                  Cross-linked vs Conventional Polyethylene in Total Knee Arthroplasty</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2016
                Apr;31(4):809-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Multicenter%20Study%20of%20Highly%20Cross-linked%20vs%20Conventional%20Polyethylene%20in%20Total%20Knee%20Arthroplasty&as_occt=title&as_sauthors=%20%22RM%20Meneghini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Kindsfater KA</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Pomeroy D</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Clark CR</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Gruen TA</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Murphy J</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Himden S</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">In Vivo Performance of
                  Moderately Crosslinked, Thermally Treated Polyethylene in a Prospective Randomized Controlled Primary
                  Total Knee Arthroplasty Trial</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2015 Aug;30(8):1333-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=In%20Vivo%20Performance%20of%20Moderately%20Crosslinked%2C%20Thermally%20Treated%20Polyethylene%20in%20a%20Prospective%20Randomized%20Controlled%20Primary%20Total%20Knee%20Arthroplasty%20Trial&as_occt=title&as_sauthors=%20%22KA%20Kindsfater%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> de Ridder R</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Kaptein BL</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Pijls BG</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Nelissen RGHH</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Kaptijn HH</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Five-year migration and insert
                  wear of uncemented tibial components with either conventional polyethylene or sequentially annealed
                  highly crosslinked polyethylene inserts: a blinded randomized controlled trial using radiostereometric
                  analysis</span>. <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Bone
                  Joint J.</span> 2023 May 1;105-B(5):518-25.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Five-year%20migration%20and%20insert%20wear%20of%20uncemented%20tibial%20components%20with%20either%20conventional%20polyethylene%20or%20sequentially%20annealed%20highly%20crosslinked%20polyethylene%20inserts%3A%20a%20blinded%20randomized%20controlled%20trial%20using%20radiostereometric%20analysis&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Ewald FC</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">The Knee Society total knee
                  arthroplasty roentgenographic evaluation and scoring system</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 1989
                Nov;(248):9-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Knee%20Society%20total%20knee%20arthroplasty%20roentgenographic%20evaluation%20and%20scoring%20system&as_occt=title&as_sauthors=%20%22FC%20Ewald%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Butcher NJ</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Monsour A</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Mew EJ</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Chan AW</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Moher D</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Mayo-Wilson E</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Terwee CB</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Chee-A-Tow A</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Baba A</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Gavin F</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Grimshaw JM</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Kelly LE</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Saeed L</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Thabane L</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Askie L</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Smith M</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Farid-Kapadia M</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Williamson PR</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Szatmari P</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Tugwell P</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Golub RM</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Monga S</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Vohra S</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Marlin S</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Ungar WJ</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Offringa M</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Guidelines for Reporting
                  Outcomes in Trial Reports: The CONSORT-Outcomes 2022 Extension</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">JAMA.</span> 2022 Dec
                13;328(22):2252-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Guidelines%20for%20Reporting%20Outcomes%20in%20Trial%20Reports%3A%20The%20CONSORT-Outcomes%202022%20Extension&as_occt=title&as_sauthors=%20%22NJ%20Butcher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Abdel MP</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Salmons HI</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Larson DR</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Austin MS</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Barnes CL</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Bolognesi MP</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Della Valle CJ</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Dennis DA</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Garvin KL</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Geller JA</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Incavo SJ</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Lombardi AV Jr</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Peters CL</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Schwarzkopf R</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Sculco PK</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Springer BD</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Pagnano MW</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Berry DJ</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">The Chitranjan S. Ranawat
                  Award: Manipulation Under Anesthesia to Treat Postoperative Stiffness After Total Knee Arthroplasty: A
                  Multicenter Randomized Clinical Trial</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2024 Aug;39(8S1):S9-S14.e1.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Chitranjan%20S.%20Ranawat%20Award%3A%20Manipulation%20Under%20Anesthesia%20to%20Treat%20Postoperative%20Stiffness%20After%20Total%20Knee%20Arthroplasty%3A%20A%20Multicenter%20Randomized%20Clinical%20Trial&as_occt=title&as_sauthors=%20%22MP%20Abdel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Hanna SA</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Somerville L</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> McCalden RW</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Naudie DD</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> MacDonald SJ</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Highly cross-linked
                  polyethylene decreases the rate of revision of total hip arthroplasty compared with conventional
                  polyethylene at 13 years’ follow-up</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2016 Jan;98-B(1):28-32.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Highly%20cross-linked%20polyethylene%20decreases%20the%20rate%20of%20revision%20of%20total%20hip%20arthroplasty%20compared%20with%20conventional%20polyethylene%20at%2013%20years%E2%80%99%20follow-up&as_occt=title&as_sauthors=%20%22SA%20Hanna%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Boyer B</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Bordini B</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Caputo D</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Neri T</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Stea S</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Toni A</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Is Cross-Linked Polyethylene an
                  Improvement Over Conventional Ultra-High Molecular Weight Polyethylene in Total Knee
                  Arthroplasty?</span><span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2018 Mar;33(3):908-14.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20Cross-Linked%20Polyethylene%20an%20Improvement%20Over%20Conventional%20Ultra-High%20Molecular%20Weight%20Polyethylene%20in%20Total%20Knee%20Arthroplasty%3F&as_occt=title&as_sauthors=%20%22B%20Boyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Lachiewicz PF</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Soileau ES</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Is There a Benefit to Highly
                  Crosslinked Polyethylene in Posterior-stabilized Total Knee Arthroplasty? A Randomized Trial</span>.
                <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2016 Jan;474(1):88-95.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20There%20a%20Benefit%20to%20Highly%20Crosslinked%20Polyethylene%20in%20Posterior-stabilized%20Total%20Knee%20Arthroplasty%3F%20A%20Randomized%20Trial&as_occt=title&as_sauthors=%20%22PF%20Lachiewicz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Cole JC</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Lemons JE</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Eberhardt AW</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Gamma irradiation alters
                  fatigue-crack behavior and fracture toughness in 1900H and GUR 1050 UHMWPE</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Biomed Mater Res.</span>
                2002;63(5):559-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gamma%20irradiation%20alters%20fatigue-crack%20behavior%20and%20fracture%20toughness%20in%201900H%20and%20GUR%201050%20UHMWPE&as_occt=title&as_sauthors=%20%22JC%20Cole%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Fehring TK</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Murphy JA</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Hayes TD</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Roberts DW</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Pomeroy DL</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Griffin WL</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Factors influencing wear and
                  osteolysis in press-fit condylar modular total knee replacements</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2004
                Nov;(428):40-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20influencing%20wear%20and%20osteolysis%20in%20press-fit%20condylar%20modular%20total%20knee%20replacements&as_occt=title&as_sauthors=%20%22TK%20Fehring%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Hambright DS</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Watters TS</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Kaufman AM</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Lachiewicz PF</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Bolognesi MP</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Fracture of highly cross-linked
                  all-polyethylene patella after total knee arthroplasty</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">J Knee Surg.</span> 2010
                Dec;23(4):237-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%20of%20highly%20cross-linked%20all-polyethylene%20patella%20after%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22DS%20Hambright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Jung KA</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Lee SC</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Hwang SH</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Kim SM</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Fracture of a second-generation
                  highly cross-linked UHMWPE tibial post in a posterior-stabilized scorpio knee system</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2008
                Nov;31(11):1137.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%20of%20a%20second-generation%20highly%20cross-linked%20UHMWPE%20tibial%20post%20in%20a%20posterior-stabilized%20scorpio%20knee%20system&as_occt=title&as_sauthors=%20%22KA%20Jung%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Stulberg BN</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Wright TM</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Stoller AP</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Mimnaugh KL</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Mason JJ</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Bilateral patellar component
                  shear failure of highly cross-linked polyethylene components: report of a case and laboratory analysis
                  of failure mechanisms</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2012 May;27(5):789-96.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bilateral%20patellar%20component%20shear%20failure%20of%20highly%20cross-linked%20polyethylene%20components%3A%20report%20of%20a%20case%20and%20laboratory%20analysis%20of%20failure%20mechanisms&as_occt=title&as_sauthors=%20%22BN%20Stulberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Akagi M</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Asano T</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Clarke IC</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Niiyama N</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Kyomoto M</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Nakamura T</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Hamanishi C</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Wear and toughness of
                  crosslinked polyethylene for total knee replacements: a study using a simulator and small-punch
                  testing</span>. <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">J
                  Orthop Res.</span> 2006 Oct;24(10):2021-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Wear%20and%20toughness%20of%20crosslinked%20polyethylene%20for%20total%20knee%20replacements%3A%20a%20study%20using%20a%20simulator%20and%20small-punch%20testing&as_occt=title&as_sauthors=%20%22M%20Akagi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Hodrick JT</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Severson EP</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> McAlister DS</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Dahl B</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Hofmann AA</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Highly crosslinked polyethylene
                  is safe for use in total knee arthroplasty</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2008
                Nov;466(11):2806-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Highly%20crosslinked%20polyethylene%20is%20safe%20for%20use%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22JT%20Hodrick%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Fisher J</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> McEwen HM</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Tipper JL</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Galvin AL</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Ingram J</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Kamali A</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Stone MH</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Ingham E</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Wear, debris, and biologic
                  activity of cross-linked polyethylene in the knee: benefits and potential concerns</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2004 Nov;(428):114-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Wear%2C%20debris%2C%20and%20biologic%20activity%20of%20cross-linked%20polyethylene%20in%20the%20knee%3A%20benefits%20and%20potential%20concerns&as_occt=title&as_sauthors=%20%22J%20Fisher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Hermida JC</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Fischler A</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Colwell CW Jr</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> D’Lima DD</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The effect of oxidative aging
                  on the wear performance of highly crosslinked polyethylene knee inserts under conditions of severe
                  malalignment</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">J Orthop Res.</span> 2008 Dec;26(12):1585-90.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20oxidative%20aging%20on%20the%20wear%20performance%20of%20highly%20crosslinked%20polyethylene%20knee%20inserts%20under%20conditions%20of%20severe%20malalignment&as_occt=title&as_sauthors=%20%22JC%20Hermida%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Muratoglu OK</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Rubash HE</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Bragdon CR</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Burroughs BR</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Huang A</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Harris WH</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Simulated normal gait wear
                  testing of a highly cross-linked polyethylene tibial insert</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2007
                Apr;22(3):435-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Simulated%20normal%20gait%20wear%20testing%20of%20a%20highly%20cross-linked%20polyethylene%20tibial%20insert&as_occt=title&as_sauthors=%20%22OK%20Muratoglu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Gkiatas I</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Karasavvidis T</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Sharma AK</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Xiang W</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Malahias MA</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Chalmers BP</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Sculco PK</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Highly cross-linked
                  polyethylene in primary total knee arthroplasty is associated with a lower rate of revision for
                  aseptic loosening: a meta-analysis of 962,467 cases</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Arch Orthop Trauma Surg.</span> 2022
                Jun;142(6):1177-84.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Highly%20cross-linked%20polyethylene%20in%20primary%20total%20knee%20arthroplasty%20is%20associated%20with%20a%20lower%20rate%20of%20revision%20for%20aseptic%20loosening%3A%20a%20meta-analysis%20of%20962%2C467%20cases&as_occt=title&as_sauthors=%20%22I%20Gkiatas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;a29eb012-899d-4a2a-9e08-690734ea4b8f&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[]}"
                href="/php/content/content_api.php?op=download&rsuite_id=a29eb012-899d-4a2a-9e08-690734ea4b8f&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 100%; display: none;"></div>
                  <div class="pages" style="position: unset;"><canvas class="page" width="337" height="189"></canvas>
                  </div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Da29eb012-899d-4a2a-9e08-690734ea4b8f%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): 121</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00445</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 6, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_30"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Mayo
                            Clinic, Rochester, Minnesota; the Mayo Clinic, Phoenix, Arizona; and the Mayo Clinic,
                            Jacksonville, Florida</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_35"
                            class="annotation strong" style="position: unset;">A commentary by Jeremy M. Gililland,
                            MD,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=9406a46e-30da-44fe-a9bf-3be2aeebbe67&native=1"
                              class="article-title" style="position: unset;">Compelling Evidence Regarding Long-Term
                              Performance of Highly Cross-Linked Versus Conventional Polyethylene in TKA at 10
                              Years<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Commentary on an article by Harold I. Salmons, MD, et al.:
                                “Multicenter Randomized Clinical Trial of Highly Cross-Linked Polyethylene Versus
                                Conventional Polyethylene in 518 Primary TKAs at 10 Years”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Gililland,
                              Jeremy M.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 107(2):e5 | January 15, 2025</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;a29eb012-899d-4a2a-9e08-690734ea4b8f&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=a29eb012-899d-4a2a-9e08-690734ea4b8f&type=pdf&name=JBJS.24.00445.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=a29eb012-899d-4a2a-9e08-690734ea4b8f&type=pdf&name=JBJS.24.00445.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_36"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study received
                          funding through philanthropic support by the Anna Maria and Stephen Kellen Foundation (MPA)
                          and from Stryker Corporation. The <span data-id="strong_37" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I264"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I264</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;a29eb012-899d-4a2a-9e08-690734ea4b8f&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=a29eb012-899d-4a2a-9e08-690734ea4b8f&type=zip&name=JBJS.24.00445.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                        Sharing</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">A <span data-id="strong_38"
                            class="annotation strong" style="position: unset;">data-sharing statement</span> is provided
                          with the online version of the article (<a href="http://links.lww.com/JBJS/I267"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I267</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Sharing
                          PDF</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;a29eb012-899d-4a2a-9e08-690734ea4b8f&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=a29eb012-899d-4a2a-9e08-690734ea4b8f&type=pdf&name=JBJS.24.00445.dataavailability.pdf&subtype=dataavailability"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Harold I. Salmons, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4124-9657" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4124-9657</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dirk R. Larson, MS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2970-2124" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2970-2124</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Cedric J. Ortiguera, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5308-1025" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5308-1025</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Henry D. Clarke, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0654-9400" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0654-9400</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mark J. Spangehl, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1090-9165" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1090-9165</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mark W. Pagnano, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1679-0046" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1679-0046</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael J. Stuart, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2281-0018" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2281-0018</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew P. Abdel, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:abdel.matthew@mayo.edu" class=""
                    style="position: unset;">abdel.matthew@mayo.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2398-1724" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2398-1724</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Mayo Clinic, Rochester, Minnesota</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Quantitative Health Sciences, Mayo Clinic, Rochester,
                Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Mayo Clinic, Jacksonville, Florida</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Mayo Clinic, Phoenix, Arizona</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 25202.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
