/* -----------------Globals--------------- */
import { router, useLocalSearchParams } from "expo-router";

import * as Animatable from "react-native-animatable";
import { ChooseCategoryContainer } from "../screens/ChooseCategoryContainer";
/* -----------------Child components--------------- */
import ChooseDateContainer from "../screens/ChooseDateContainer";
import { ChooseExamContainer } from "../screens/ChooseExamContainer";
import { RedeemContainer } from "../screens/RedeemContainer";

/* -----------------UI--------------- */
import {
	CollectNameRouteContainer,
	SelectAvatarRouteContainer,
	SelectRoleRouteContainer,
	SelectSpecialtyRouteContainer,
} from "@features/onboardings";
import DisclaimerView from "@features/onboardings/components/DisclamierView";
import { getSelectRoleOnboarding } from "@helpers/find-onboarding";
import { useAppStore } from "@hooks/useAppStore";
import { useDevice } from "@memorang/ui";

const OnboardingRouteContainer = () => {
	const searchParams = useLocalSearchParams();

	const onboarding = useAppStore((store) => store.onboarding);
	const isImageChallengeApp = useAppStore(
		(store) => store.app.isImageChallengeApp,
	);

	const selectRoleOnboardingStep = getSelectRoleOnboarding(onboarding!);

	const hasRoleOnboarding = selectRoleOnboardingStep?.step != null;

	const { isMobile } = useDevice();

	const initialStep = isImageChallengeApp
		? selectRoleOnboardingStep?.step || "specialty"
		: "category";

	const showSpecialityBack = isImageChallengeApp && hasRoleOnboarding;
	const step = (searchParams?.step || initialStep) as string;
	const handleUpdateParam = (newStep: string) => {
		router.setParams({
			step: newStep,
		});
	};

	const comps: Record<string, React.ReactNode> = {
		chooseexam: <ChooseExamContainer handleNext={handleUpdateParam} />,
		userdetails: <ChooseDateContainer />,
		redeem: (
			<RedeemContainer
				handleNext={() => {
					handleUpdateParam("category");
				}}
			/>
		),
		category: <ChooseCategoryContainer />,
		role: <SelectRoleRouteContainer />,
		specialty: (
			<SelectSpecialtyRouteContainer showSpecialityBack={showSpecialityBack} />
		),
		avatar: <SelectAvatarRouteContainer />,
		name: <CollectNameRouteContainer />,
		disclaimer: <DisclaimerView fromOnboarding />,
	};

	if (!isMobile) {
		return <>{comps[step]}</>;
	}

	return (
		<Animatable.View
			animation="slideInRight"
			duration={500}
			key={step}
			style={{ flex: 1 }}
		>
			{comps[step]}
		</Animatable.View>
	);
};

export default OnboardingRouteContainer;
