interface UserInfo {
	id: string;
	email: string;
	name?: string;
	firstName?: string;
	lastName?: string;
	school?: string;
	gradYear?: number;
	examDate?: string;
	specialities?: string[];
	profession?: string;
	isOnboarded?: boolean;
	country?: string;
	alerts?: {
		name: string;
		optInStatus: boolean;
	}[];
}
type AchievementType = {
	title: string;
	description: string;
	points: number;
	level: number;
	events: number;
	totalEvents: number;
	usersEarned: number;
	icon: {
		url: string;
		backgroundColor: string;
	};
};
export type { UserInfo, AchievementType };
