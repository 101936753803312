/* ----------------- React Native Paper --------------- */
import { Text } from "react-native-paper";

/* ----------------- Expo --------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

/* ----------------- Local Components --------------- */
import Box from "../../Box";
import TooltipWrapper from "../../TooltipWrapper";

/* ----------------- Types --------------- */
import type { IconType } from "../../..";

type ScoreRange = {
	range: string;
	color: string;
	icon: string;
};
type Props = {
	scoreRanges?: ScoreRange[];
	tooltip: string;
};
export const ScoreRanges = ({ scoreRanges, tooltip }: Props) => {
	return (
		<Box flexDirection="row" gap={16} marginTop={50}>
			{scoreRanges?.map(({ range: rangeValue, color, icon }) => (
				<TooltipWrapper key={rangeValue} title={tooltip}>
					<Box flexDirection="row" gap={4} alignItems="center">
						<Icon name={icon as IconType} color={color} size={16} />
						<Text
							key={rangeValue}
							variant="labelSmall"
							style={{ color, fontWeight: "bold" }}
						>
							{`${rangeValue}`}
						</Text>
					</Box>
				</TooltipWrapper>
			))}
		</Box>
	);
};
