import { Box } from "@memorang/ui";
import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";
import DateTimePicker, {
	type DateTimePickerProps,
} from "components/DateTimePicker";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { TextInput } from "react-native-paper";

type Props = Omit<
	DateTimePickerProps,
	"onChange" | "onConfirm" | "onCancel"
> & {
	value: {
		from: string;
		to: string;
	};
	onChange: (value: { from: string; to: string }) => void;
	minimumDate?: Date | null;
	initialDate?: Date;
};

export const DateRangeInput = ({ value, onChange, ...rest }: Props) => {
	const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
	const [activeDateField, setActiveDateField] = useState<"from" | "to">("from");

	const activeDate = useMemo(() => {
		if (activeDateField === "from") {
			if (!value.from) {
				return undefined;
			}
			return dayjs(value.from, "MM/DD/YYYY").toDate();
		}
		if (!value.to) {
			return undefined;
		}
		return dayjs(value.to, "MM/DD/YYYY").toDate();
	}, [activeDateField, value.to, value.from]);

	const handleDateConfirm = (date: Date) => {
		const formattedDate = date.toLocaleDateString("en-US", {
			month: "numeric",
			day: "numeric",
			year: "numeric",
		});

		onChange({
			...value,
			[activeDateField]: formattedDate,
		});
		setIsDatePickerVisible(false);
	};

	const handleOnPressFrom = () => {
		setActiveDateField("from");
		setIsDatePickerVisible(true);
	};

	const handleOnPressTo = () => {
		setActiveDateField("to");
		setIsDatePickerVisible(true);
	};

	return (
		<>
			<CustomListSubheaderContainer
				style={{
					paddingBottom: 0,
				}}
			>
				Select date range
			</CustomListSubheaderContainer>
			<Box flexDirection="row" gap={16} paddingHorizontal={16}>
				<Box flex={1}>
					<TextInput
						mode="outlined"
						label="From"
						value={value.from}
						right={
							<TextInput.Icon icon="calendar" onPress={handleOnPressFrom} />
						}
						onPressIn={handleOnPressFrom}
						showSoftInputOnFocus={false}
					/>
				</Box>
				<Box flex={1}>
					<TextInput
						mode="outlined"
						label="To"
						value={value.to}
						right={<TextInput.Icon icon="calendar" onPress={handleOnPressTo} />}
						onPressIn={handleOnPressTo}
						showSoftInputOnFocus={false}
					/>
				</Box>
			</Box>

			<DateTimePicker
				{...rest}
				date={activeDate}
				isVisible={isDatePickerVisible}
				onCancel={() => setIsDatePickerVisible(false)}
				onConfirm={handleDateConfirm}
			/>
		</>
	);
};
