import { Fragment } from "react";

import { useAppTheme } from "@hooks/useAppTheme";
/* -----------------Types--------------- */
import type { Choice, StemImageType } from "@memorang/types";
import { getSortedChoices } from "./helper";

/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* -----------------Components--------------- */
import { Box, ImagesCarousel } from "@memorang/ui";
import { CustomListSubheader, MarkdownRenderer } from "@memorang/ui";

/* -----------------Props--------------- */
type Props = {
	choices: Choice[];
	explanation?: string;
	explanationMedia?: StemImageType[];
};

const RenderExplanation = ({
	explanation,
	explanationMedia,
}: {
	explanation: string | undefined;
	explanationMedia?: StemImageType[];
}) => {
	if (explanation) {
		const normalizedExplanation = explanation.replace(/<br>/g, "\n\n");
		return (
			<Box gap={16}>
				<MarkdownRenderer
					text={normalizedExplanation}
					variant="explanation"
					fontSize={14}
					customStyles={{
						paragraph: {
							lineHeight: 22,
							marginBottom: 8,
						},
					}}
				/>
				{explanationMedia?.length ? (
					<ImagesCarousel images={explanationMedia} />
				) : null}
			</Box>
		);
	}
	return null;
};

/* -----------------Component--------------- */
const ExplanationSection = (props: Props) => {
	const { choices, explanation, explanationMedia } = props;

	const sortedChoices = getSortedChoices(choices);
	const correctChoices = sortedChoices.filter(({ isCorrect }) => isCorrect);

	const theme = useAppTheme();

	const RenderChoice = ({ isCorrect, stem, testID }: Choice) => (
		<Box display="flex" flexDirection="row" gap={8} alignItems="center">
			{isCorrect ? (
				<Icon
					name="checkbox-marked-circle"
					color={theme.colors.progress.high}
					size={24}
					testID={testID}
				/>
			) : (
				<Icon
					name="close-circle"
					size={24}
					color={theme.colors.progress.low}
					testID={testID}
				/>
			)}
			<Box flex={1}>
				<MarkdownRenderer
					text={stem}
					customStyles={{
						paragraph: {
							...theme.typography.fonts.medium,
						},
					}}
					fontSize={14}
					variant="answer"
				/>
			</Box>
		</Box>
	);
	const ChoicesWithExplanation = () =>
		explanation ? (
			<RenderExplanation
				explanation={explanation}
				explanationMedia={explanationMedia}
			/>
		) : (
			<Box gap={16}>
				{sortedChoices.map((choice) => (
					<Fragment key={choice.id}>
						<RenderChoice {...choice} />
						<RenderExplanation
							explanation={choice.explanation}
							explanationMedia={explanationMedia}
						/>
					</Fragment>
				))}
			</Box>
		);

	if (correctChoices.length > 1) {
		const incorrectChoices = choices.filter(({ isCorrect }) => !isCorrect);
		return (
			<>
				<CustomListSubheader>Explanation</CustomListSubheader>

				{correctChoices.map((choice, index) => (
					<Box key={choice.id} paddingTop={8}>
						<RenderChoice {...choice} testID={`choice-${index + 1}-correct`} />
					</Box>
				))}
				<RenderExplanation explanation={correctChoices[0].explanation} />
				{incorrectChoices.map((choice, index) => (
					<Box key={choice.id} paddingTop={8}>
						<RenderChoice
							{...choice}
							testID={`choice-${index + 1}-incorrect`}
						/>
					</Box>
				))}
			</>
		);
	}
	return <ChoicesWithExplanation />;
};

export default ExplanationSection;
