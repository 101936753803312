/* -----------------UI--------------- */
import { Dialog, Portal } from "react-native-paper";

import { DialogWrapper } from "@memorang/ui";
/* -----------------Child components--------------- */
import SnackBarCustom from "components/CustomSnackbar/CustomSnackbar";
import AppReviewForm from "../AppReviewForm";
import FeedbackForm from "../FeedbackForm";

/* -----------------Constants--------------- */
import { dialogTexts } from "../../constants";

/* -----------------Helpers & Hooks--------------- */
import { useReview } from "@features/feedback";
import { useKeyboard } from "@react-native-community/hooks";

export const AppReviewDialog = () => {
	const {
		handleDismissFeedback,
		handleReviewButtonsClick,
		hasFocus,
		isRoutedFromSession,
		showReviewPrompt,
		showSnackbar,
		currentStep,
		setShowSnackBar,
	} = useReview();

	const { keyboardHeight } = useKeyboard();

	const comps: Record<"prompt" | "feedback", React.ReactNode> = {
		prompt: <AppReviewForm handleButtonsClick={handleReviewButtonsClick} />,
		feedback: (
			<FeedbackForm view="home" handleDismiss={handleDismissFeedback} />
		),
	};

	const showReviewDialog = showReviewPrompt && hasFocus && isRoutedFromSession;

	const currentComponent = comps[currentStep];
	const config = dialogTexts[currentStep];
	return (
		<>
			<DialogWrapper
				visible={showReviewDialog}
				handleClose={handleDismissFeedback}
				bottom={keyboardHeight ? keyboardHeight - 200 : 0}
			>
				<Dialog.Icon icon="message-star-outline" />
				<Dialog.Title
					style={{
						textAlign: "center",
					}}
				>
					{config.title}
				</Dialog.Title>
				<Dialog.Content>{currentComponent}</Dialog.Content>
			</DialogWrapper>
			<Portal>
				<SnackBarCustom
					message="Thank you for your feedback!"
					visible={showSnackbar}
					duration={2000}
					handleDismiss={() => setShowSnackBar(false)}
				/>
			</Portal>
		</>
	);
};
