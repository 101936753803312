/**
 * @generated SignedSource<<b13b830f0457472c71f33f96f52797c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type applyAccessCodeMutation$variables = {
  code: string;
};
export type applyAccessCodeMutation$data = {
  readonly applyAccessCode: {
    readonly data: {
      readonly beginStartDate: string | null;
      readonly bundleId: string | null;
      readonly bundleName: string | null;
      readonly bundleNumericId: number | null;
      readonly bundleType: string | null;
      readonly endStartDate: string | null;
      readonly examId: string | null;
      readonly examName: string | null;
      readonly expiryDate: string | null;
      readonly features: ReadonlyArray<string | null> | null;
      readonly icon: string | null;
      readonly isCustomAccess: boolean | null;
      readonly planLength: string | null;
    } | null;
    readonly message: string;
    readonly productData: ReadonlyArray<{
      readonly beginStartDate: string | null;
      readonly bundleId: string | null;
      readonly bundleName: string | null;
      readonly bundleNumericId: number | null;
      readonly bundleType: string | null;
      readonly endStartDate: string | null;
      readonly examId: string | null;
      readonly examName: string | null;
      readonly expiryDate: string | null;
      readonly features: ReadonlyArray<string | null> | null;
      readonly icon: string | null;
      readonly isCustomAccess: boolean | null;
      readonly planLength: string | null;
    } | null> | null;
    readonly success: boolean;
  };
};
export type applyAccessCodeMutation = {
  response: applyAccessCodeMutation$data;
  variables: applyAccessCodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bundleId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bundleNumericId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "examName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bundleType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bundleName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "planLength",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "expiryDate",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "icon",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "examId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "features",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isCustomAccess",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "beginStartDate",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "endStartDate",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "code",
        "variableName": "code"
      }
    ],
    "concreteType": "AccessCodeResult",
    "kind": "LinkedField",
    "name": "applyAccessCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ApplyCodeSuccessData",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ApplyCodeSuccessData",
        "kind": "LinkedField",
        "name": "productData",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "applyAccessCodeMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "applyAccessCodeMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "25d6ed1dae5171edf39ded47f25049e7",
    "id": null,
    "metadata": {},
    "name": "applyAccessCodeMutation",
    "operationKind": "mutation",
    "text": "mutation applyAccessCodeMutation(\n  $code: String!\n) {\n  applyAccessCode(code: $code) {\n    success\n    message\n    data {\n      bundleId\n      bundleNumericId\n      examName\n      bundleType\n      bundleName\n      planLength\n      expiryDate\n      icon\n      examId\n      features\n      isCustomAccess\n      beginStartDate\n      endStartDate\n    }\n    productData {\n      bundleId\n      bundleNumericId\n      examName\n      bundleType\n      bundleName\n      planLength\n      expiryDate\n      icon\n      examId\n      features\n      isCustomAccess\n      beginStartDate\n      endStartDate\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9d5390fca0016ee72a63fc574caba36";

export default node;
