import type { ViewStyle } from "react-native";
import { useAppTheme } from "../../useAppTheme";
/* -----------------Globals--------------- */
import Box, { type AlignItems } from "../Box";

/* -----------------Props--------------- */
export type Props = {
	children: React.ReactNode;
	style?: ViewStyle;
	containerStyle?: ViewStyle;
	maxWidth?: "xs" | "sm" | "md" | "lg";
	fullWidth?: boolean;
	padding?: boolean;
	alignItems?: AlignItems;
};

/* -----------------Component--------------- */
const Container = (props: Props) => {
	const {
		children,
		style,
		containerStyle,
		padding,
		maxWidth = "md",
		fullWidth,
		...rest
	} = props;

	const theme = useAppTheme();

	return (
		<Box
			{...rest}
			flexDirection="column"
			alignItems="center"
			width="100%"
			padding={padding ? 16 : 0}
			flex={1}
			style={style}
		>
			<Box
				maxWidth={fullWidth ? "100%" : theme.breakpoints?.[maxWidth] || "100%"}
				style={containerStyle}
				width="100%"
			>
				{children}
			</Box>
		</Box>
	);
};

export default Container;
