import type { Article } from "../../../../../types";

export const Article625: Article = {
	id: 625,
	rsuiteId: "5b18562e-abf6-4092-aaf7-081179312776",
	type: "scientific article",
	title:
		"Outcomes Following Transtibial Amputation with and without a Tibiofibular Synostosis Procedure",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5b18562e-abf6-4092-aaf7-081179312776&type=jpeg&name=JBJS.23.01408f1",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Outcomes Following Transtibial Amputation with and
                without a Tibiofibular Synostosis Procedure</div>
              <div class="text subtitle" style="position: unset;">A Multicenter Randomized Clinical Trial (TAOS Study)
              </div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Major
                      Extremity Trauma Research Consortium (METRC)</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                  <div class="footnotes" style="position: unset;"><span data-id="footnote_reference_1"
                      class="label annotation cross_reference author">*</span></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_22" class="annotation strong"
                          style="position: unset;">Background:</span> This study compared outcomes at 18 months between
                        patients who underwent transtibial amputation with and without a tibiofibular synostosis
                        procedure. We hypothesized that complication rates would be lower in patients who did not
                        receive a synostosis procedure compared with those who did receive a synostosis procedure, but
                        the synostosis group would report better function.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_23" class="annotation strong"
                          style="position: unset;">Methods:</span> This multicenter randomized clinical trial (RCT)
                        included patients aged 18 to 60 years who were treated without (n = 52) or with synostosis (n =
                        54) during transtibial amputation. Patients who refused randomization were enrolled in an
                        observational (OBS) study (n = 93; 31 synostosis, 62 non-synostosis). There were 2 primary
                        outcomes: operative treatment for a complication within 18 months of amputation and Short
                        Musculoskeletal Function Assessment (SMFA) scores. The primary analyses were based on the RCT
                        participants, and the secondary analyses were based on the treatment received by the combined
                        RCT + OBS participants.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_24" class="annotation strong"
                          style="position: unset;">Results:</span> In the primary RCT analysis, the probability of ≥1
                        operatively treated complication was higher for the synostosis group (42%; 95% confidence
                        interval [CI]: 29.8% to 56.2%) than the non-synostosis group (24%; 95% CI: 14.2% to 37.9%), with
                        an absolute risk difference of 18% (95% CI: 0.31% to 36%). There were no appreciable differences
                        in mean SMFA scores. In the secondary combined analysis, the probability of an operatively
                        treatment complication was larger in patients who did versus did not receive a synostosis
                        procedure (absolute risk difference: 26.1%; 95% CI: 12.0% to 40.3%). Differences in SMFA scores
                        favored synostosis, although there were no differences in pain, prosthetic use, and
                        satisfaction.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_25" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The results of the primary RCT analysis supported
                        our hypothesis of fewer complications in patients who do not undergo a synostosis procedure
                        compared with those who undergo a synostosis procedure, but we found insufficient evidence to
                        conclude that those in the synostosis group have better function. The trial did not reach target
                        enrollment. The secondary combined analysis supported the primary analysis in terms of
                        complications. In the secondary analysis, while pain, prosthetic use, and satisfaction remained
                        similar, the SMFA scores were better for the patients who received a synostosis procedure,
                        although the differences are of uncertain clinical importance.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_26" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level I</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The optimal technique for a transtibial amputation
                  following severe lower-extremity trauma is controversial<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Proponents of amputation with a tibiofibular synostosis (Ertl-type procedure) argue that the
                  synostosis provides stability, shape, and weight-bearing capability to the terminal residual limb,
                  resulting in less pain as well as improved prosthesis fit and residual limb function<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">6</span></a>.
                  Others argue that the additional steps of the synostosis procedure increase the surgical time and
                  complications without a notable improvement in function<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">8</span></a>. A systematic review concluded that limited Level-III
                  evidence supports the synostosis technique as an equivalent option to amputation without a synostosis,
                  and that prospective clinical trials are needed to guide future clinical practice<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">9</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The aim of this randomized clinical trial (RCT) was to
                  compare the 18-month rate of reoperation for a complication and patient-reported function between
                  patients who underwent transtibial amputation with versus without a tibiofibular synostosis procedure
                  following lower extremity trauma. We hypothesized that complication rates would be lower following
                  amputation in patients who did not undergo a synostosis procedure compared with those who underwent a
                  synostosis procedure, but patient-reported function would be better for patients who underwent the
                  synostosis procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Material and
                Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Trial
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The trial was conducted at 25 U.S. trauma centers under
                  the coordination of the Major Extremity Trauma Research Consortium<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">11</span></a>.
                  Patients who refused randomization were enrolled in a concurrent observational (OBS) cohort study, and
                  they received treatment according to surgeon and patient preference. Additionally, we included 16
                  patients who enrolled in the OBS arm without prior refusal of the RCT. The study was registered at
                  ClinicalTrials.gov (NCT01821976).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Participants</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Patients between the ages of 18 and 60 years who were
                  undergoing a unilateral transtibial amputation following major limb trauma were eligible if they met
                  the following criteria: (1) the residual limb was not fractured, or the fracture was stabilized by
                  internal fixation, (2) the proximal tibiofibular joint was uninjured or stabilized, (3) the
                  soft-tissue coverage allowed for typical closure or atypical closure with a skin graft of &lt;100
                  cm<span data-id="superscript_6" class="annotation superscript" style="position: unset;">2</span>, and
                  (4) the residual limb length was ≥10 cm from the joint line to the end of the tibia. Detailed criteria
                  have been previously published<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">11</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Randomization and Blinding</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Within each site, patients were block randomized in a 1:1
                  ratio to amputation either with or without a tibiofibular synostosis procedure. Randomization was
                  performed with a central computerized system with variable block sizes and was not disclosed to each
                  site. There was no practical way to blind patients, surgeons, and research coordinators to treatment
                  assignment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Treatment
                Groups</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">All patients received a transtibial amputation and
                  postoperative care and rehabilitation per each center’s protocol. Patients randomized to the
                  synostosis group received a tibiofibular synostosis using either the Ertl or modified Ertl technique<a
                    href="" data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">13</span></a>. The tibiofibular synostosis could be achieved by a variety
                  of techniques, including using a cortical screw, a suture button, or nonabsorbable suture to secure
                  the bone bridge and maintain blood supply to the fibular segment, or transposing a sleeve of
                  periosteum with attached bone chips from the tibia to the fibula. Soft-tissue closure for patients in
                  both treatment groups was accomplished per surgeon preference, but typically involved a long posterior
                  myocutaneous flap.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Clinical outcomes were scheduled to be collected at
                  routine intervals over 18 months (547 days) following definitive amputation (the date of final
                  soft-tissue closure). Patient-reported outcomes (PROs) were scheduled to be assessed at the final
                  18-month study visit.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">We defined 2 primary outcomes. The first primary outcome
                  was the occurrence of reoperation for revision of the residual limb, deep infection, exostosis or
                  heterotopic ossification removal, neuroma excision, or implant removal or revision within 18 months of
                  amputation. Revisions included surgery for soft-tissue coverage because of necrosis, wound dehiscence,
                  soft-tissue rearrangement, and procedures that shortened the bone Implant revision included implant
                  removal due to pain as well as implant revision or removal secondary to infection or nonunion of the
                  bone bridge. Data for this outcome were obtained at each follow-up visit by reviewing the patient’s
                  medical record. The second primary outcome was patient-reported function using the Short
                  Musculoskeletal Function Assessment (SMFA). Scores were determined for the bother and dysfunction
                  indices as well as the domains of mobility, daily activities, arm and hand function, and emotional
                  function<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">14</span></a>. The
                  SMFA was obtained through interviews with the participants during the 18-month clinic visit, or over
                  the telephone if participants were unable to complete an in-person visit.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Secondary outcomes included components of the first
                  primary outcome (number of reoperations and number of readmissions for a complication), the number of
                  nonoperatively treated complications, pain (Brief Pain Inventory [BPI]<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">15</span></a>; phantom limb pain), prosthesis use (number of hours worn
                  per week), and satisfaction with the prosthesis (Orthotics and Prosthetics Users’ Survey [OPUS])<a
                    href="" data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">16</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcome
                Adjudication</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Complications were independently adjudicated by 3
                  experienced orthopaedic trauma surgeons who were not affiliated with the study. Due to the nature of
                  the study interventions, it was not possible to blind the independent reviewers to treatment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">We conducted a primary analysis (RCT) and a secondary
                  analysis (combined RCT + OBS); no adjustment was planned for multiple comparisons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Primary
                Analysis (RCT)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The analysis of RCT data followed a modified
                  intent-to-treat (ITT) approach, where late ineligibles were removed from the analysis set. The
                  probability of experiencing a reoperation for a complication by 18 months (the first primary outcome)
                  was estimated using the Kaplan-Meier method. The 18-month SMFA scores (the second primary outcome)
                  were analyzed using linear regression with treatment, time to assessment, the interaction between time
                  to assessment and treatment, age, body mass index (BMI), and preinjury health status as covariates.
                  This model was used to estimate the difference in the mean scores at 18 months between the treatment
                  groups. Covariates were included to adjust for deviation in assessment times from 18 months.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The secondary count outcomes were analyzed using Poisson
                  regression, with a treatment indicator as the sole covariate and the log of the follow-up time as an
                  offset. This model was used to estimate the difference in the mean number of complications by 18
                  months between the 2 treatment groups. The secondary PROs were analyzed using the linear regression
                  approach described above.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Secondary
                Analysis (Combined RCT + OBS)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">In the secondary analysis, patients were analyzed
                  according to the treatment received. The probability of the treatment received was modeled using
                  logistic regression with the following preinjury covariates: age, BMI, tobacco use, health status, job
                  demand, physical activity, major comorbidities, and substance abuse. We also included a study
                  indicator (RCT versus OBS). The predicted probabilities of the treatment received (i.e., propensity
                  scores) were used to compute inverse probability of treatment weights. These weights were used to
                  correct for nonrandom receipt of treatment for the primary clinical outcome and the secondary count
                  outcomes. Specifically, the probability of experiencing reoperation for a complication by 18 months
                  (the first primary outcome) was estimated using the weighted Kaplan-Meier method. The secondary count
                  outcomes were analyzed using weighted Poisson regression, with the treatment indicator as the sole
                  covariate and the log of the follow-up time as an offset. SMFA scores (the second primary outcome)
                  were analyzed using linear regression with treatment, time to assessment, and the interaction of the
                  time to assessment and treatment, as well as all the covariates used in building the propensity score
                  model. Missing baseline covariates were imputed 10 times using multiple imputation by chained
                  equations (MICE)<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">17</span></a>.
                  Each imputed data set was analyzed separately, and standard errors were computed using the Rubin
                  combining rules<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">17</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Planned
                Sample Size</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The sample size calculation for the RCT was based on
                  testing of the primary hypotheses regarding surgical complications and patient-reported function. The
                  planned sample size in the RCT was 107 per arm; the details have been previously published<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">11</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_28" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">From May 2013 through September 2018, 120 patients were
                  randomly assigned to amputation with (n = 61) or without (n = 59) a synostosis procedure. The 18-month
                  follow-up assessments were completed in April 2020. Of the 120 patients who underwent randomization,
                  14 (12%) were found to be ineligible following randomization. Thus, 106 patients (88%) were included
                  in the primary analysis (n = 54 in the synostosis group, n = 52 in the non-synostosis group).
                  Follow-up regarding reoperation for a complication was 94% of the expected person-time through 18
                  months for the synostosis group and 91% for the non-synostosis group. The 18-month SMFA was collected
                  from 87% of the patients in the synostosis group and 75% of the patients in the non-synostosis group.
                  The secondary analysis included 196 patients (106 randomized patients and 90 patients enrolled in the
                  OBS arm [60 in the non-synostosis group and 30 in the synostosis group]) (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>; see also Appendix Supplemental Table I). Of the 30 OBS cohort
                  patients who underwent a synostosis procedure, 16 were treated at 1 center that had an Ertl-dedicated
                  orthotics and rehabilitation program.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01408f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5b18562e-abf6-4092-aaf7-081179312776&type=jpeg&name=JBJS.23.01408f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">CONSORT (Consolidating Standards of Reporting
                          Trials) flow diagram. ITT = intent-to-treat.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Primary
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Most patients were male (92, 87%) and the mean age was 42
                  years (<a href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). Sixty-three percent (67) of the amputations occurred within 3
                  months of injury, 75% (79) of the patients had an open fracture, and 83% (88) of the amputations
                  received a standard posterior flap without a skin graft. Of the 54 patients randomized to the
                  synostosis group, 7 (13%) were treated without a synostosis procedure (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). No patients who were randomized to the non-synostosis group
                  received a synostosis procedure (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Preamputation Characteristics<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1115.05px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform: scale(0.863517); transform-origin: left top;">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Primary Analysis: Intent-to-Treat RCT</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Secondary Analysis: As-Treated RCT + OBS</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Non-Synostosis (N = 52)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Synostosis
                          (N = 54)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Non-Synostosis (N = 119)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Synostosis
                          (N = 77)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean age (SD) <span
                            data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41
                          (11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (12)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39
                          (12)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male sex <span
                            data-id="emphasis_47" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43
                          (83)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49
                          (91)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100
                          (84)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66
                          (86)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Non-Hispanic White <span
                            data-id="emphasis_48" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38
                          (73)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (74)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95
                          (80)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52
                          (68)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">College educated <span
                            data-id="emphasis_49" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (40)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (28)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (37)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (36)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean body mass index (SD)
                          <span data-id="emphasis_50" class="annotation emphasis"
                            style="position: unset;">(kg/m</span><span data-id="emphasis_51" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_15"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_52"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Current tobacco user
                          <span data-id="emphasis_53" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (44)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (39)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50
                          (42)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (43)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preexisting
                          comorbidity<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="emphasis_54" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (39)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (39)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (24)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (30)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preinjury health:
                          excellent/very good <span data-id="emphasis_55" class="annotation emphasis"
                            style="position: unset;">(no. [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (67)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (65)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82
                          (69)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56
                          (73)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Working prior to
                          amputation <span data-id="emphasis_56" class="annotation emphasis"
                            style="position: unset;">(no. [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37
                          (71)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (82)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88
                          (74)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60
                          (78)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Worked a physically
                          demanding job <span data-id="emphasis_57" class="annotation emphasis"
                            style="position: unset;">(no. [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (67)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39
                          (72)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83
                          (70)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53
                          (69)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No health insurance <span
                            data-id="emphasis_58" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (15)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (12)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (18)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Engaged in vigorous
                          physical activity<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="emphasis_59" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (31)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48
                          (40)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (36)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">SD = standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Comorbidities included diabetes, cardiac disease, vascular disease,
                        pulmonary disease, or psychiatric conditions.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The Paffenbarger Physical Activity Questionnaire (PPAQ) was used to
                        measure preinjury activity. Vigorous activity was defined as reporting ≥1 leisure or
                        recreational activity associated with a metabolic equivalent score (MET) of ≥6.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Injury and Treatment Characteristics<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 2742.75px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform: scale(0.894022); transform-origin: left top;">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Primary Analysis: Intent-to-Treat RCT</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Secondary Analysis: As-Treated RCT + OBS</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Non-Synostosis (N = 52)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Synostosis
                          (N = 54)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Non-Synostosis (N = 119)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Synostosis
                          (N = 77)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Time of amputation</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Within 3 mo of injury
                          <span data-id="emphasis_60" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (63)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (63)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83
                          (70)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (61)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mean days from injury
                          (SD)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.8
                          (18.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.5
                          (15.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.1
                          (17.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.8
                          (13.4)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> After 3 months from
                          injury <span data-id="emphasis_61" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (37)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (37)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (39)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mean days from injury
                          (SD)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,314
                          (1,291)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,168
                          (2,972)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,387
                          (1,558)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,589
                          (2,405)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fracture type <span
                            data-id="emphasis_62" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (8)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Closed</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (13)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (12)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (12)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Gustilo type IIIA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (22)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (16)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (19)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Gustilo type IIIB</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (29)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (32)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (34)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (32)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Gustilo type IIIC</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (25)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (24)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (25)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unknown</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (12)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Injury classification
                          <span data-id="emphasis_63" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tibia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (25)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (22)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (23)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (22)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Ankle</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pilon</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (13)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (18)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (22)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Talus/calc</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (10)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other foot</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (27)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (22)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (25)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (22)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Multiple fractures
                          (distal tibia/foot)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (12)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (13)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (10)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other injury</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unknown</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tibial plateau</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Traumatic
                          amputation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Reason(s) for amputation
                          <span data-id="emphasis_64" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Extremity
                          characteristics</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (85)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48
                          (89)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95
                          (80)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71
                          (92)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Patient request</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (27)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (22)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (21)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (23)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Associated
                          injuries</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Soft-tissue closure <span
                            data-id="emphasis_65" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Atypical flap with skin
                          graft</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Atypical flap without
                          skin graft</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (12)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Standard flap with skin
                          graft</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Standard flap without
                          skin graft</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (89)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (78)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">104
                          (87)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (83)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean size of skin graft
                          (SD) among 8 patients with skin graft <span data-id="emphasis_66" class="annotation emphasis"
                            style="position: unset;">(cm</span><span data-id="emphasis_67" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_16"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_68"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80
                          (NA)<span data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67
                          (35.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.8
                          (47.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.5
                          (39.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean length of residual
                          limb (SD) <span data-id="emphasis_69" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.8
                          (12.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.0
                          (2.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.4
                          (8.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.4
                          (3.9)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Definitive amputation
                          <span data-id="emphasis_70" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Received assigned
                          treatment</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52
                          (100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (87)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unplanned treatment
                          crossover</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (13)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">SD = standard deviation, and NA = not applicable.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">This cell contains only 1 patient.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Primary
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The probability of operative treatment for a complication
                  within 18 months was higher for patients who received a synostosis procedure (42%; 95% confidence
                  interval [CI]: 29.8% to 56.2%) than for those who did not receive a synostosis procedure (24%; CI:
                  14.2% to 37.9%), with an absolute risk difference of 18% (95% CI: 0.3% to 36%) (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). The number of reoperations by complication type is
                  presented in Appendix Supplemental Table II. The average observed SMFA bother scores were 27.7 and
                  27.4 for the synostosis and non-synostosis groups, respectively (adjusted difference, −2.2; 95% CI:
                  −13.7 to 9.2). The average observed SMFA dysfunction scores were 26.1 and 27.2 for the synostosis and
                  non-synostosis groups, respectively (adjusted difference, −3.4; 95% CI: −12.3 to 5.6). There were no
                  appreciable differences in the scores for the mobility, daily activities, emotional, or arm and hand
                  function domains (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>). An
                  as-treated analysis of the primary outcomes showed similar results.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Complications Treated with Surgery or Admission
                          within 18 Months of Definitive Amputation Among Participants Treated with or without a
                          Synostosis Procedure<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 178.703px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform: scale(0.571181); transform-origin: left top;">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="3" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Primary Analysis: Intent-to-Treat RCT<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Secondary Analysis: As-Treated RCT + OBS<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Kaplan-Meier Estimate (95% CI)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Treatment Effect, Synostosis−Non-Synostosis (95% CI)</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Adjusted Kaplan-Meier Estimate (95% CI)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Treatment Effect, Synostosis−Non-Synostosis (95% CI)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Non-Synostosis (N = 52)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Synostosis
                          (N = 54)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Non-Synostosis (N = 119)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Synostosis
                          (N = 77)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complications <span
                            data-id="emphasis_71" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.7 (14.2
                          to 37.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.7 (29.8
                          to 56.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.1 (0.31
                          to 35.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.6 (13.9
                          to 29.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47.7 (35.9
                          to 59.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.1 (12.0
                          to 40.3)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Complications included revision of the residual limb, infection,
                        exostosis or heterotopic ossification, neuroma, hardware revision, and/or a diagnosis of complex
                        regional pain syndrome.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Unadjusted.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Adjusted for study type (RCT versus OBS), age, body mass index, tobacco
                        use, substance abuse, preexisting comorbidity, preinjury health status, physical demand of job,
                        and physical activity prior to injury.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Short Musculoskeletal Function Assessment Scores 18
                          Months Following Definitive Amputation Among Participants Treated with or without a Synostosis
                          Procedure</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 430.474px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform: scale(0.613806); transform-origin: left top;">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Domain</span>
                      </th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Primary Analysis: Intent-to-Treat RCT<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Secondary Analysis: As-Treated RCT + OBS<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Non-Synostosis (N = 39)<span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Synostosis
                          (N = 47)<span data-id="table_footnote_reference_12"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Adjusted
                          Treatment Difference, Synostosis−Non-Synostosis (95% CI)<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Non-Synostosis (N = 83)<span data-id="table_footnote_reference_14"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Synostosis
                          (N = 56)<span data-id="table_footnote_reference_15"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Adjusted
                          Treatment Difference, Synostosis–Non-Synostosis (95% CI)<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dysfunction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.2
                          (17.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.1
                          (19.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−3.4
                          (−12.3 to 5.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.7
                          (18.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.7
                          (19.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−7.0
                          (−13.8 to −0.08)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mobility</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.9
                          (20.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.8
                          (21.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.2
                          (−12.4 to 8.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.3
                          (21.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.7
                          (21.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−7.9
                          (−15.7 to −0.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Daily activities</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.3
                          (22.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.3
                          (26.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.9
                          (−14.8 to 9.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.5
                          (24.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.2
                          (24.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−10.1
                          (−19.5 to −0.8)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Emotional</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36.4
                          (22.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.6
                          (24.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−5.4
                          (−16.7 to 5.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.0
                          (23.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.5
                          (24.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−7.0
                          (−15.9 to 1.8)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Arm/hand</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.3
                          (11.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3
                          (10.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−3.5 (−9.0
                          to 1.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.7
                          (9.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.8
                          (12.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.6 (−5.8
                          to 2.5)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bother</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.4
                          (23.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.7
                          (23.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.2
                          (−13.7 to 9.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.2
                          (23.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.3
                          (21.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−10.1
                          (−18.7 to −1.5)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Adjusted for age, body mass index, preinjury health status, and time to
                        assessment.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Adjusted for study type (RCT versus OBS), age, body mass index, tobacco
                        use, substance abuse, preexisting comorbidity, preinjury health status, physical demand of job,
                        physical activity prior to injury, and time to assessment.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the mean with the standard deviation in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Secondary
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The rate of reoperation for a complication was higher for
                  patients in the synostosis group than for the non-synostosis group; these differences, however, were
                  coupled with appreciable uncertainty (incidence rate ratio for reoperations: 1.71; 95% CI: 0.98 to
                  2.98) (see Appendix Supplemental Table III). There were no appreciable differences in patient-reported
                  pain, prosthesis use, and satisfaction with the prosthesis (see Appendix Supplemental Table IV).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Secondary
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">In the combined (RCT + OBS) analysis, the probability of
                  operative treatment for a complication was larger in patients who did versus did not receive a
                  synostosis procedure (absolute risk difference, 26.1%; 95% CI: 12.0% to 40.3%) (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). The differences in mean SMFA scores were in favor of
                  synostosis for dysfunction (−7.0; 95% CI: −13.8 to −0.08), mobility (−7.9; 95% CI: −15.7 to −0.1), and
                  daily activities (−10.1; 95% CI: −19.5 to −0.8) (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).
                  Results were comparable with the primary analysis for readmission (2.13%; 95% CI: 1.01% to 4.50%), but
                  the difference was larger for reoperation (3.22%; 95% CI: 1.68% to 6.17%) (see Appendix Supplemental
                  <a href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). There were no differences in other secondary outcomes (see
                  Appendix Supplemental Table IV).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_30" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, this is the first multicenter
                  prospective trial comparing outcomes following trauma-related unilateral transtibial amputation with
                  or without a tibiofibular synostosis procedure. The study was designed to have an OBS (observational)
                  arm to test the generalizability of the RCT results. Due to a lower-than-expected amputation rate and
                  a higher-than-anticipated number of patients declining randomization and enrolling in the OBS arm, we
                  included results from both the RCT and the combined RCT + OBS “as-treated” cohort to assess outcomes.
                  Inclusion of the combined results allows us to increase our precision and to address the
                  disproportionate number of crossovers in the RCT.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">In both analyses, we found that amputation in a patient
                  who received a tibiofibular synostosis procedure resulted in a higher probability of reoperation for a
                  complication compared with patients who did not receive a synostosis procedure, supporting our
                  hypothesis for this outcome. These results are consistent with a prior retrospective study of patients
                  with combat-related transtibial amputation who showed higher rates of operative complications among
                  the patients treated with a synostosis procedure (62%) compared with those without synostosis (49%)
                  during amputation<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">8</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">In the primary RCT analysis, there were no appreciable
                  differences in 18-month SMFA scores. These findings, coupled with no difference in pain, prosthetic
                  use, or satisfaction, suggested no improvement in outcome in those in the synostosis group compared
                  with those in the non-synostosis group. In the secondary combined “as-treated” analysis, the
                  differences in SMFA scores were larger, in favor of synostosis. The literature suggests that a
                  difference of ≥7 points on the SMFA is considered clinically important<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">18</span></a>. However, a recent study of 3,185 patients with surgically
                  treated severe lower-extremity orthopaedic trauma revealed a broader range of values for the SFMA
                  minimal clinically important difference (MCID)—ranging from 7 to 11 for the dysfunction index and 11
                  to 17 for the bother index<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">19</span></a>.
                  While some differences observed in the present combined “as-treated” analysis may be clinically
                  meaningful (i.e., a difference of ≥7 points on the dysfunction index), other subscales need to be
                  interpreted in light of the evolving understanding of the SMFA MCID in severe lower-extremity trauma.
                  The results should therefore be interpreted with caution. Because these data included nonrandomized
                  patients, there was risk of selection bias, which is why this was chosen as an a priori secondary
                  analysis. Differences also may have been impacted by unmeasured confounding, such as variation in
                  surgeon experience and orthotic and prosthetic rehabilitation protocols between the treatment groups
                  in this analysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Prior retrospective studies have also found insufficient
                  evidence regarding the benefit of a synostosis procedure in terms of patient-reported quality of life
                  and physical function. A study that evaluated Vietnam veteran amputees found no difference in Short
                  Form-36 (SF-36) physical function scores 28 years later among those treated with (n = 42) and without
                  (n = 30) a synostosis procedure (70.4 versus 63.1, unadjusted p = 0.18)<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">20</span></a>. Another study of 55 veteran amputees found no difference
                  in SF-36 physical function scores at 32 months among 27 patients treated with a synostosis procedure
                  versus 28 patients treated without a synostosis procedure (78.3 versus 78.9, respectively, unadjusted
                  p = 0.98)<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">21</span></a>.
                  That study found essentially no difference in scores in the ambulation domain of the Prosthesis
                  Evaluation Questionnaire (PEQ) (81.2 in the synostosis group versus 79.9 in the non-synostosis group,
                  unadjusted p = 0.73). The PEQ was also evaluated. Additionally, in a single-center study, the average
                  score in the PEQ ambulation domain was lower (worse) among 8 patients treated with a synostosis
                  procedure versus 15 patients treated without a synostosis procedure (64.5 versus 70.9)<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">7</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Although advocates of the synostosis procedure employ the
                  technique with the expectation of enhanced function, to our knowledge, only 1 recent trauma study
                  (which included combat-related amputations from 2001 to 2011) has supported this theory. That study
                  compared return to duty and Medical Board evaluation results in patients with combat-related
                  transtibial amputations and found a higher rate of retention on active duty in the synostosis cohort<a
                    href="" data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">22</span></a>. The study was retrospective and did not adjust for
                  differing patient characteristics and injury patterns or preinjury level of function (e.g.,
                  special-operations or higher-ranking service members being more likely to receive the synostosis
                  procedure).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">The strengths of our trial include the multicenter
                  randomized design and enrollment across 25 centers. The internal validity of our findings was
                  strengthened by a central, independent adjudication of outcomes. A substantive weakness of the study
                  was the failure to enroll the RCT target sample size of 107 per arm. A cohort of 93 patients were
                  enrolled in the OBS study to help provide further insight into this question<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">11</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Our study had several limitations. The RCT experienced a
                  13% crossover rate for patients randomized to the synostosis procedure, which was driven by surgeon or
                  patient treatment preference. We addressed this by using both an ITT and an as-treated analysis, and
                  we found no difference between their results. The study was not designed to control for or assess the
                  quality of post-amputation rehabilitation or prosthetic care, which may have been associated with the
                  type of amputation procedure and had an important impact on outcomes. The lack of blinding was a
                  limitation, but we attempted to minimize potential biases that this may have introduced by utilizing
                  independent outcome adjudication. Surgical technique, notably the variation in tibiofibular synostosis
                  techniques and surgeon experience with these techniques, may have biased results in favor of the
                  non-synostosis procedure. In contrast, the OBS cohort included a disproportionate number of patients
                  who received a synostosis procedure at a single center with a dedicated synostosis orthotics and
                  rehabilitation program.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_29" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">The results of the primary RCT analysis supported our
                  hypothesis of fewer operatively treated complications in patients who did not receive a synostosis
                  procedure compared with patients who did receive a synostosis procedure, but we found insufficient
                  evidence to conclude that those in the synostosis group had better function. The secondary combined
                  analysis supported the primary analysis in terms of complications. In the secondary analysis, while
                  pain, prosthetic use, and satisfaction remained similar, the SMFA scores were better for the patients
                  who received a synostosis procedure. These differences in SMFA scores, however, are of uncertain
                  clinical importance.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_31" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I296" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I296</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: Members of the METRC Writing Group include Michael J. Bosse,
                  MD, Atrium Health Musculoskeletal Institute, Carolinas Medical Center, Charlotte, NC; Daniel O.
                  Scharfstein, ScD, Johns Hopkins Bloomberg School of Public Health, Baltimore, MD, and University of
                  Utah School of Medicine, Salt Lake City, UT; Ellen J. MacKenzie, PhD, Johns Hopkins Bloomberg School
                  of Public Health, Baltimore, MD; Benjamin K. Potter, MD, Walter Reed National Military Medical Center,
                  Bethesda, MD (now affiliated with University of Pennsylvania Perelman School of Medicine,
                  Philadelphia, PA); David Teague, MD, University of Oklahoma, Norman, OK; Saam Morshed, MD, PhD,
                  University of California, San Francisco, San Francisco, CA; Robert V. O’Toole, MD, University of
                  Maryland School of Medicine, Baltimore, MD; Joshua L. Gary, MD, Keck School of Medicine of the
                  University of Southern California, Los Angeles, CA; William Obremskey, MD, MPH, Vanderbilt University
                  Medical Center, Nashville, TN; William Ertl, MD, University of Oklahoma, Norman, OK; Rachel B.
                  Seymour, PhD, Atrium Health Musculoskeletal Institute, Carolinas Medical Center, Charlotte, NC; and
                  Lisa Reider, PhD, Johns Hopkins Bloomberg School of Public Health, Baltimore, MD.Corporate
                  Authorship:<span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Atrium
                    Health-Carolinas Medical Center:</span> Joseph R. Hsu, MD; Madhav A. Karunakar, MD; Stephen H. Sims,
                  MD; Christine Churchill, MA; <span data-id="emphasis_24" class="annotation emphasis"
                    style="position: unset;">Atrium Health-Wake Forest Baptist:</span> Eben A. Carroll, MD; James Brett
                  Goodman, MBA; Martha B. Holden, AAS, AA; <span data-id="emphasis_25" class="annotation emphasis"
                    style="position: unset;">Barnes-Jewish Hospital at Washington University in St. Louis:</span> Amanda
                  Spraggs-Hughes, PhD (now affiliated with UCONN Health); <span data-id="emphasis_26"
                    class="annotation emphasis" style="position: unset;">Brooke Army Medical Center:</span> Daniel J.
                  Stinner, MD, PhD (now affiliated with Vanderbilt University Medical Center); <span
                    data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Dartmouth-Hitchcock
                    Medical Center:</span> Ida Leah Gitajn, MD, MS; <span data-id="emphasis_28"
                    class="annotation emphasis" style="position: unset;">Emory University:</span> Mara L. Schenker, MD;
                  Thomas Moore Jr., MD; <span data-id="emphasis_29" class="annotation emphasis"
                    style="position: unset;">Florida Orthopaedic Institute:</span> Hassan Mir, MD, MBA; <span
                    data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Grant Medical
                    Center:</span> Benjamin C. Taylor, MD; <span data-id="emphasis_31" class="annotation emphasis"
                    style="position: unset;">Hennepin Healthcare:</span> Patrick Yoon, MD (now affiliated with
                  University of Minnesota); <span data-id="emphasis_32" class="annotation emphasis"
                    style="position: unset;">McGovern Medical School at The University of Texas Health Science Center at
                    Houston:</span> Andrew Choo, MD; William Mcgarvey, MD; Danielle Melton, MD (now affiliated with
                  University of Colorado); John W. Munz, MD; Stephen J. Warner, MD, PhD; Sterling J. Boutte, BS; <span
                    data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Mission Hospital:</span>
                  H. Michael Frisch, MD (now affiliated with Novant Health Charlotte Orthopedic Hospital); <span
                    data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Penn State Health Milton
                    S. Hershey Medical Center:</span> J. Spence Reid, MD; <span data-id="emphasis_35"
                    class="annotation emphasis" style="position: unset;">R. Adams Cowley Shock Trauma Center at the
                    University of Maryland:</span> Jason Nascone, MD; Marcus F. Sciadini, MD; Gerard Slobogean, MD;
                  Andrea L. Howe, BS; <span data-id="emphasis_36" class="annotation emphasis"
                    style="position: unset;">Stanford University:</span> Michael J. Gardner, MD; <span
                    data-id="emphasis_37" class="annotation emphasis" style="position: unset;">University of California,
                    San Francisco:</span> Theodore Miclau, MD; <span data-id="emphasis_38" class="annotation emphasis"
                    style="position: unset;">University of Miami Ryder Trauma Center:</span> James J. Hutson Jr., MD
                  (now retired); <span data-id="emphasis_39" class="annotation emphasis"
                    style="position: unset;">University of Mississippi Medical Center:</span> Patrick F. Bergin, MD;
                  Eldrin L. Bhanat, MD, MPH; Matt L. Graves, MD; Clay A. Spitler, MD (now affiliated with University of
                  Alabama at Birmingham); <span data-id="emphasis_40" class="annotation emphasis"
                    style="position: unset;">University of Pittsburgh:</span> Gele B. Moloney, MD; <span
                    data-id="emphasis_41" class="annotation emphasis" style="position: unset;">University of Washington
                    Medicine Harborview Medical Center:</span> Reza Firoozabadi, MD, MA; Julie Agel, MA; <span
                    data-id="emphasis_42" class="annotation emphasis" style="position: unset;">University of
                    Wisconsin:</span> Paul S. Whiting, MD; Natasha M. Simske, MD; Alexander B. Siy, BS; <span
                    data-id="emphasis_43" class="annotation emphasis" style="position: unset;">Vanderbilt University
                    Medical Center:</span> Kristin R. Archer, PhD, DPT; Eduardo J. Burgos, MD (now affiliated with
                  Hospital Serena del Mar); Vamshi Gajari, MBBS (no longer affiliated); Alex Jahangir, MD; Andres
                  Rodriguez-Buitrago, MD (now affiliated with Hospital Universitario Fundacion Santa Fe de Bogota);
                  Manish K. Sethi, MD; Karen M. Trochez, BA; <span data-id="emphasis_44" class="annotation emphasis"
                    style="position: unset;">Walter Reed National Military Medical Center:</span> Jean-Claude G.
                  D’Alleyrand, MD, MSE (now affiliated with University of Pennsylvania); Wade T. Gordon, MD (now
                  affiliated with Las Vegas VA Medical Center); John J. Keeling, MD (now affiliated with Montgomery
                  Orthopaedics); Xochitl Ceniceros, PhD, CCRP (now affiliated with Naval Medical Research Command);
                  Sandra L. Waggoner, BA (now affiliated with Henry M. Jackson Foundation); <span data-id="emphasis_45"
                    class="annotation emphasis" style="position: unset;">METRC Coordinating Center at Johns Hopkins
                    Bloomberg School of Public Health:</span> Renan C. Castillo, PhD; Lauren E. Allen, DrPH; Anthony R.
                  Carlini, MS.The authors thank Christopher M. McAndrew, MD, MSc, Barnes-Jewish Hospital at Washington
                  University in St. Louis, for patient enrollment; Gudrun E. Mirick Mueller, MD, Hennepin Healthcare,
                  for patient enrollment and surgical intervention; Mary A. Breslin, MPH, MetroHealth Medical Center
                  (now affiliated with Evellere Group), for site study management, patient enrollment and follow-up,
                  data collection, management, and cleaning; M.J. Crisco, RN, CRC, Rhode Island Hospital at Brown
                  University, for patient interaction, enrollment and follow-up, data collection and monitoring; Lisa K.
                  Cannada, MD, Saint Louis University (now affiliated with Novant Health), for institutional review
                  board correspondence and patient and data review; J. Lawrence Marsh, MD, University of Iowa, for
                  patient enrollment; Bruce Sangeorzan, MD, University of Washington Medicine Harborview Medical Center,
                  for study design and outcome assessment; and Robert H. Boyce, MD, Vanderbilt University Medical
                  Center, for patient enrollment.Site affiliations are as of time of study unless otherwise noted.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Pinzur MS</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Gottschalk FA</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Pinto MA</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Smith DG</span>; <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">American Academy of Orthopaedic
                  Surgeons. Controversies in lower-extremity amputation</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2007
                May;89(5):1118-27.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1014386"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Pinto MA</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Harris WW</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Fibular segment bone bridging in
                  trans-tibial amputation</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Prosthet Orthot Int.</span> 2004 Dec;28(3):220-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fibular%20segment%20bone%20bridging%20in%20trans-tibial%20amputation&as_occt=title&as_sauthors=%20%22MA%20Pinto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Pinzur MS</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Pinto MAGS</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Saltzman M</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Batista F</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Gottschalk F</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Juknelis D</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Health-related quality of life
                  in patients with transtibial amputation and reconstruction with bone bridging of the distal tibia and
                  fibula</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Foot
                  Ankle Int.</span> 2006 Nov;27(11):907-12.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health-related%20quality%20of%20life%20in%20patients%20with%20transtibial%20amputation%20and%20reconstruction%20with%20bone%20bridging%20of%20the%20distal%20tibia%20and%20fibula&as_occt=title&as_sauthors=%20%22MS%20Pinzur%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Berlet GC</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Pokabla C</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Serynek P</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">An alternative technique for the
                  Ertl osteomyoplasty</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Foot Ankle Int.</span> 2009 May;30(5):443-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20alternative%20technique%20for%20the%20Ertl%20osteomyoplasty&as_occt=title&as_sauthors=%20%22GC%20Berlet%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> DeCoster TA</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Homedan S</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Amputation osteoplasty</span>.
                <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Iowa Orthop J.</span>
                2006;26:54-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Amputation%20osteoplasty&as_occt=title&as_sauthors=%20%22TA%20DeCoster%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Deffer PA</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Moll JH</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> LaNoue AM</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The Ertl osteoplastic below-knee
                  amputation</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 1971;53:1028.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Ertl%20osteoplastic%20below-knee%20amputation&as_occt=title&as_sauthors=%20%22PA%20Deffer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Pinzur MS</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Beck J</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Himes R</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Callaci J</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Distal tibiofibular
                  bone-bridging in transtibial amputation</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2008 Dec;90(12):2682-7.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1030558" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Tintle SM</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Keeling JJ</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Forsberg JA</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Shawen SB</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Andersen RC</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Potter BK</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Operative complications of
                  combat-related transtibial amputations: a comparison of the modified Burgess and modified Ertl
                  tibiofibular synostosis techniques</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2011 Jun 1;93(11):1016-21.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1119956" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Kahle JT</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Highsmith MJ</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Kenney J</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Ruth T</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Lunseth PA</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Ertl J</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The effectiveness of the bone
                  bridge transtibial amputation technique: A systematic review of high-quality evidence</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Prosthet Orthot Int.</span>
                2017 Jun;41(3):219-26.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effectiveness%20of%20the%20bone%20bridge%20transtibial%20amputation%20technique%3A%20A%20systematic%20review%20of%20high-quality%20evidence&as_occt=title&as_sauthors=%20%22JT%20Kahle%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;">Major
                Extremity Trauma Research Consortium (METRC). <span data-id="strong_10" class="annotation strong"
                  style="position: unset;">Building a Clinical Research Network in Trauma Orthopaedics: The Major
                  Extremity Trauma Research Consortium (METRC)</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2016
                Jul;30(7):353-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Building%20a%20Clinical%20Research%20Network%20in%20Trauma%20Orthopaedics%3A%20The%20Major%20Extremity%20Trauma%20Research%20Consortium%20(METRC)&as_occt=title&as_sauthors=%20%22Major%20Extremity%20Trauma%20Research%20Consortium%20(METRC)%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Bosse MJ</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Morshed S</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Reider L</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Ertl W</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Toledano J</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Firoozabadi R</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Seymour RB</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Carroll E</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Scharfstein DO</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Steverson B</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> MacKenzie EJ</span>; <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">METRC. Transtibial Amputation
                  Outcomes Study (TAOS): Comparing Transtibial Amputation With and Without a Tibiofibular Synostosis
                  (Ertl) Procedure</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2017 Apr;31(Suppl 1):S63-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=METRC.%20Transtibial%20Amputation%20Outcomes%20Study%20(TAOS)%3A%20Comparing%20Transtibial%20Amputation%20With%20and%20Without%20a%20Tibiofibular%20Synostosis%20(Ertl)%20Procedure&as_occt=title&as_sauthors=%20%22MJ%20Bosse%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Ertl J</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Uber amputationsstumpfe</span>.
                <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Chirurgie.</span>
                1949;20:218-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Uber%20amputationsstumpfe&as_occt=title&as_sauthors=%20%22J%20Ertl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Taylor BC</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Poka A</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Osteomyoplastic Transtibial
                  Amputation: The Ertl Technique</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2016 Apr;24(4):259-65.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteomyoplastic%20Transtibial%20Amputation%3A%20The%20Ertl%20Technique&as_occt=title&as_sauthors=%20%22BC%20Taylor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Swiontkowski MF</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Engelberg R</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Martin DP</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Agel J</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Short Musculoskeletal Function
                  Assessment questionnaire: validity, reliability, and responsiveness</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 1999 Sep;81(9):1245-60.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=711469" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Cleeland CS</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Ryan KM</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Pain assessment: global use of
                  the Brief Pain Inventory</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Ann Acad Med Singap.</span> 1994 Mar;23(2):129-38.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pain%20assessment%3A%20global%20use%20of%20the%20Brief%20Pain%20Inventory&as_occt=title&as_sauthors=%20%22CS%20Cleeland%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Heinemann AW</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Bode RK</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> O’Reilly C</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Development and measurement
                  properties of the Orthotics and Prosthetics Users’ Survey (OPUS): a comprehensive set of clinical
                  outcome instruments</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Prosthet Orthot Int.</span> 2003 Dec;27(3):191-206.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20and%20measurement%20properties%20of%20the%20Orthotics%20and%20Prosthetics%20Users%E2%80%99%20Survey%20(OPUS)%3A%20a%20comprehensive%20set%20of%20clinical%20outcome%20instruments&as_occt=title&as_sauthors=%20%22AW%20Heinemann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Little RJ</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Rubin DB</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Statistical analysis with
                  missing data.</span> 3rd ed. Hoboken: John Wiley & Sons; 2019.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Busse JW</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Bhandari M</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Guyatt GH</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Heels-Ansdell D</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Mandel S</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Sanders D</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Schemitsch E</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Swiontkowski M</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Tornetta P 3rd</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Wai E</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Walter SD</span>; <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> SPRINT Investigators</span>.
                <span data-id="strong_17" class="annotation strong" style="position: unset;">Use of both Short
                  Musculoskeletal Function Assessment questionnaire and Short Form-36 among tibial-fracture patients was
                  redundant</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J
                  Clin Epidemiol.</span> 2009 Nov;62(11):1210-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Use%20of%20both%20Short%20Musculoskeletal%20Function%20Assessment%20questionnaire%20and%20Short%20Form-36%20among%20tibial-fracture%20patients%20was%20redundant&as_occt=title&as_sauthors=%20%22JW%20Busse%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Agel J</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Carlini AR</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Frey KP</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Staguhn ED</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Vallier HA</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Obremskey WT</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Swiontkowski MF</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Cannada L</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Schmidt A</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Wegener ST</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> MacKenzie EJ</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> O’Toole RV</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Bosse MJ</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Castillo RC</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">METRC. Minimal Clinically
                  Important Difference (MCID) for the Short Musculoskeletal Functional Assessment (SMFA): Pooled Data
                  from Nine Multicenter, Prospective Clinical Trials</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Presented as a poster exhibit at the Orthopaedic
                  Trauma Association Annual Meeting</span>; 2022 Oct 12-15; Tampa, FL. Poster no. 25.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=METRC.%20Minimal%20Clinically%20Important%20Difference%20(MCID)%20for%20the%20Short%20Musculoskeletal%20Functional%20Assessment%20(SMFA)%3A%20Pooled%20Data%20from%20Nine%20Multicenter%2C%20Prospective%20Clinical%20Trials&as_occt=title&as_sauthors=%20%22J%20Agel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Dougherty PJ</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Transtibial amputees from the
                  Vietnam War. Twenty-eight-year follow-up</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2001
                Mar;83(3):383-9.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=779041"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Keeling JJ</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Shawen SB</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Forsberg JA</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Kirk KL</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Hsu JR</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Gwinn DE</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Potter BK</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Comparison of functional
                  outcomes following bridge synostosis with non-bone-bridging transtibial combat-related
                  amputations</span>. <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2013 May 15;95(10):888-93.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1165085" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Plucknette BF</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Krueger CA</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Rivera JC</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Wenke JC</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Combat-related bridge
                  synostosis versus traditional transtibial amputation: comparison of military-specific outcomes</span>.
                <span data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Strategies Trauma Limb
                  Reconstr.</span> 2016 Apr;11(1):5-11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Combat-related%20bridge%20synostosis%20versus%20traditional%20transtibial%20amputation%3A%20comparison%20of%20military-specific%20outcomes&as_occt=title&as_sauthors=%20%22BF%20Plucknette%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;5b18562e-abf6-4092-aaf7-081179312776&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[]}"
                href="/php/content/content_api.php?op=download&rsuite_id=5b18562e-abf6-4092-aaf7-081179312776&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 100%; display: none;"></div>
                  <div class="pages" style="position: unset;"><canvas class="page" width="337" height="189"></canvas>
                  </div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;5b18562e-abf6-4092-aaf7-081179312776&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=5b18562e-abf6-4092-aaf7-081179312776&type=supplement&name=12525"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D5b18562e-abf6-4092-aaf7-081179312776%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_32" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): 1</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01408</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 2, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5b18562e-abf6-4092-aaf7-081179312776&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=5b18562e-abf6-4092-aaf7-081179312776&type=pdf&name=JBJS.23.01408.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=5b18562e-abf6-4092-aaf7-081179312776&type=pdf&name=JBJS.23.01408.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_27"
                            class="annotation strong" style="position: unset;">Disclosure:</span> Support for this study
                          was provided by the United States Department of Defense Award W81XWH-10-2-0090. The sponsor
                          had no role in the design or conduct of the trial; the collection, management, analysis, or
                          interpretation of the data; or the preparation, review, or approval of the manuscript for
                          submission. The <span data-id="strong_28" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I295"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I295</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5b18562e-abf6-4092-aaf7-081179312776&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=5b18562e-abf6-4092-aaf7-081179312776&type=zip&name=JBJS.23.01408.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                        Sharing</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">A <span data-id="strong_29"
                            class="annotation strong" style="position: unset;">data-sharing statement</span> is provided
                          with the online version of the article (<a href="http://links.lww.com/JBJS/I297"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I297</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Sharing
                          PDF</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5b18562e-abf6-4092-aaf7-081179312776&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=5b18562e-abf6-4092-aaf7-081179312776&type=pdf&name=JBJS.23.01408.dataavailability.pdf&subtype=dataavailability"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Major Extremity Trauma Research Consortium
                (METRC)<span data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">A list of the METRC group members is included as a
                        note at the end of the article.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 30788.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
