type ClerkError = {
	errors: Array<{ code: string; message?: string }>;
};

const isClerkError = (error: unknown): error is ClerkError => {
	return (
		typeof error === "object" &&
		error !== null &&
		"errors" in error &&
		Array.isArray((error as ClerkError).errors)
	);
};

export const isDuplicateUserError = (error: unknown): boolean => {
	return (
		isClerkError(error) && error.errors[0]?.code === "form_identifier_exists"
	);
};

export const isIncorrectPasscodeError = (error: unknown): boolean => {
	return isClerkError(error) && error.errors[0]?.code === "form_code_incorrect";
};

export const isTestModeEmail = (email: string) => {
	return email.endsWith("+clerk_test@memorang.com");
};
