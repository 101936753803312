import ChallengesView from "@features/library/components/ChallengesView";
import { Container } from "@memorang/ui";
import { useLocalSearchParams } from "expo-router";
import { useCME } from "../hooks/useCME";

const CMEActivityLogView = () => {
	const { from, to } = useLocalSearchParams<{
		from?: string;
		to?: string;
	}>();
	const { prepareActivityLog, activityLog: allActivityLogs } = useCME();

	let activityLog = allActivityLogs;

	if (from && to) {
		activityLog = prepareActivityLog({
			startDate: from,
			endDate: to,
			onlyEarned: true,
		});
	}

	return (
		<Container maxWidth="sm">
			<ChallengesView sections={activityLog} isCME totalChallenges={0} />
		</Container>
	);
};

export default CMEActivityLogView;
