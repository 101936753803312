import type { Article } from "../../../../../types";

export const Article610: Article = {
	id: 610,
	rsuiteId: "9d375183-590e-4ee9-8002-754449b56328",
	type: "scientific article",
	title:
		"Dependable Automated Approach for Measuring the Retrograde Superior Ramus Screw Corridor in Pelvic Fracture Fixation",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9d375183-590e-4ee9-8002-754449b56328&type=jpeg&name=JBJS.23.00695f1",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Dependable Automated Approach for Measuring the
                Retrograde Superior Ramus Screw Corridor in Pelvic Fracture Fixation</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jing-Xin
                      Zhao, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Hua Chen,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_25" class="annotation strong"
                          style="position: unset;">Background:</span> Precise measurement of the intraosseous corridor
                        within the superior pubic ramus is essential for the accurate percutaneous placement of a
                        retrograde superior ramus screw (SRS). However, conventional manual measurement methods are
                        often subjective, leading to variations in results among observers. Our goal was to develop an
                        automated and dependable method for determining the retrograde SRS corridor.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_26" class="annotation strong"
                          style="position: unset;">Methods:</span> We developed an automated technique that utilized a
                        computed tomography (CT) image-based search algorithm to identify the retrograde SRS corridor
                        with the maximum diameter. We evaluated the reliability of this automated approach in comparison
                        to a manual method using 17 pelves. Subsequently, we used both methods to measure the diameter,
                        length, and orientation of the retrograde SRS corridor in 204 pelves in a Chinese population and
                        assessed the intra- and interobserver agreement of each method by calculating the
                        root-mean-square error (RMSE) and constructing Bland-Altman plots. We determined the screw
                        applicability (percentages of hemipelves that could be treated with specific sizes of screws)
                        for each method. Additionally, we investigated potential factors influencing the corridor, such
                        as sex, age, height, and weight, through regression analysis.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_27" class="annotation strong"
                          style="position: unset;">Results:</span> The intra- and interobserver intraclass correlation
                        coefficients (ICCs) for the automated method (0.998 and 0.995) were higher than those for the
                        manual approach (0.925 and 0.918) in the assessment of the corridor diameter. Furthermore, the
                        diameter identified by the automated method was notably larger than the diameter measured with
                        the manual method, with a mean difference and RMSE of 0.9 mm and 1.1 mm, respectively. The
                        automated method revealed a significantly smaller corridor diameter in females than in males (an
                        average of 7.5 and 10.4 mm, respectively). Moreover, use of the automated method allowed 80.6%
                        of the females to be managed with a 4.5-mm screw while a 6.5-mm screw could be utilized in
                        19.4%, surpassing the capabilities of the manual method. Female sex had the most substantial
                        impact on corridor diameter (β = −0.583).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The automated method exhibited better reliability
                        than the manual method in measuring the retrograde SRS corridor, and showed a larger corridor
                        diameter for screw placement. Females had a significantly smaller corridor diameter than males.
                        Given the intricate nature of the automated approach, which entails utilizing different software
                        and interactive procedures, our current method is not readily applicable for traumatologists. We
                        are working on developing integrated software with the goal of providing a more user-friendly
                        solution for traumatologists in the near future.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Diagnostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Percutaneous retrograde screw fixation has been advocated
                  as a preferred option for treating most superior pubic ramus fractures, due to its benefits of reduced
                  blood loss, increased stability, and faster recovery compared with open reduction and internal
                  fixation<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">3</span></a>. However, this is a technically demanding procedure due to
                  the irregular bone shape, an extremely narrow intraosseous corridor, and proximity to the acetabulum<a
                    href="" data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">9</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Accurate measurement of the intraosseous corridor of the
                  superior pubic ramus is of substantial surgical importance in determining intraosseous placement of a
                  percutaneous pubic ramus screw<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">13</span></a>.
                  Most of the existing methods require subjective judgment and manual measurements on computed
                  tomography (CT) sections<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">14</span></a>, with measurement results varying among different observers
                  because of nonstandardized processes and methodology. Mendel et al. developed an automated computation
                  tool for determining the optimal trajectory for sacroiliac screws<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">16</span></a>.
                  Similarly, Gras et al. and Wagner et al. established an optimization method for trans-sacral screw
                  placement based on the mean shape of the pelvis determined using statistical shape modeling and a
                  registration algorithm<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">17</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">18</span></a>. To our knowledge, there have been no studies on the use of
                  an automated method to determine intraosseous placement of a retrograde superior ramus screw (SRS).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Therefore, our aims were to (1) develop an automated
                  approach for determining the retrograde SRS corridor based on pelvic CT imaging, (2) evaluate the
                  reliability and measurement results of our method by comparing them with a manual method, and (3)
                  perform a biomorphometric analysis of the retrograde SRS corridor in 204 pelves and analyze the
                  influencing factors.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">This study, which was approved by the local ethics
                  committee (No. S2022-781-01), included CT data from 204 Chinese patients with a normal pelvis. All CT
                  scans were conducted using the Somatom Sensation CT System (Siemens) with typical scanning parameters,
                  including a tube potential of 120 kVp, exposure time of 500 ms, tube current of 30 to 60 mA, and B30f
                  kernel. The field of view contained 512 × 512 pixels with pixel spacing of 0.6 to 1 mm and a slice
                  thickness of 1.5 mm. Four experienced orthopaedic surgeons (J.-X.Z., H.C., H.L., and L.-C.Z.),
                  proficient in Mimics software (Materialise), manually segmented all pelvic models through software
                  protocols of thresholding, region growing, mask editing, etc. The segmentation assessment is shown in
                  the Appendix. Demographic information is summarized in <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographic Characteristics of the Included
                          Population<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 346px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Characteristic</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Male (N = 142)</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Female (N = 62)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          (SD)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Range</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          (SD)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Range</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_26" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64.04
                          (12.83)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15-85</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69.73
                          (8.43)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">50-85</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Height <span
                            data-id="emphasis_27" class="annotation emphasis" style="position: unset;">(m)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.69
                          (0.06)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.48-1.84</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.58
                          (0.06)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.4-1.77</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Weight <span
                            data-id="emphasis_28" class="annotation emphasis"
                            style="position: unset;">(kg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.56
                          (9.77)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">43-97</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61.71
                          (9.75)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">37-86</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">SD = standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Construction
                of the Pelvis-Specific Coordinate System</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">To standardize the measurement process, we normalized the
                  pelvic orientation by establishing the pelvis-specific coordinate system using 3-matic software
                  (Materialise). First, we created the pelvic sagittal plane based on the symmetry of bilateral
                  hemipelves and their mirrored models. The x axis of the pelvis-specific coordinate system was
                  designated as the vector normal to the pelvic sagittal plane. Subsequently, we created the anterior
                  pelvic plane as passing through 3 ventralmost points of the original pelvis, including 1 pubic
                  tubercle and 2 anterior superior iliac spines. The line formed by the intersection of the anterior and
                  sagittal pelvic planes was considered the y axis of the pelvis-specific coordinate system. Next, the
                  pelvic orientation was standardized by aligning the pelvis-specific coordinate system with the CT
                  coordinate system to eliminate any deviations. The process is shown in Appendix Figure S1.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Intraosseous
                Corridor for Retrograde SRS</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">All pelvic volume data were resampled with a voxel size of
                  1 × 1 × 1 mm in Amira software (ThermoFisher Scientific). A pelvic shell with a thickness of 1 voxel
                  was generated with the erosion and arithmetic functions of Amira. As the Nakatani zone is universally
                  defined for superior pubic ramus fractures<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">9</span></a>, the
                  entry area of the retrograde SRS was identified as Nakatani zone I. The exit area was specified as the
                  anterior half of the external iliac plate (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). Both
                  areas were marked by an orthopaedic surgeon (J.-X.Z.) with a minimum of 10 years of experience in
                  orthopaedic surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00695f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9d375183-590e-4ee9-8002-754449b56328&type=jpeg&name=JBJS.23.00695f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;">The entry and exit areas and an axial view of the
                          retrograde SRS corridor. <span data-id="strong_32" class="annotation strong"
                            style="position: unset;">Fig. 1-A</span> Anteroposterior (AP) view of a left hemipelvis. The
                          entry area was identified as Nakatani zone I of the superior pubic ramus. <span
                            data-id="strong_33" class="annotation strong" style="position: unset;">Fig. 1-B</span>
                          Lateral view. The exit area was defined as the anterior half of the external iliac plate.
                          <span data-id="strong_34" class="annotation strong" style="position: unset;">Fig. 1-C</span>
                          Axial view showing the likely largest intraosseous area and the retrograde SRS corridor with
                          the maximum diameter. The retrograde SRS corridor is represented by a cylinder within the
                          superior pubic ramus.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">To determine the retrograde SRS corridor, we developed an
                  algorithm that searched all lines connecting 2 points on the entry and exit areas and was aimed at
                  maximizing the minimum distance of the line from the pelvic surface. This algorithm involved
                  traversing all pairs of points on the entry and exit areas, connecting them with lines, and skipping
                  lines penetrating the pelvic surface. This resulted in an average of 17 million connection lines
                  (range, 2 to 50 million) within the intraosseous corridor. The minimum distances of all connection
                  lines to any point in the pelvic shell were compared with each other using a gradient approach until
                  the maximum value and the corresponding point in the pelvic shell were obtained (see Appendix). Since
                  the voxel point in the pelvic shell has a size of 1 × 1 × 1 mm, there was a margin of approximately
                  0.5 mm between the center of the calculated voxel point and the outer pelvic surface. Therefore, we
                  added 0.5 mm to the minimum distance to determine the radius of the retrograde SRS corridor and
                  subtracted 0.5 mm from the minimum distance for the screw applicability analysis (percentages of
                  hemipelves that could be treated with specific sizes of screws).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The automated algorithm was used to determine the
                  diameter, length, and orientation of the retrograde SRS corridor for each pelvis, and the values were
                  confirmed using 3 orthogonal planes on the original CT images (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). The
                  orientation of the corridor was assessed based on 2 angles: the abduction angle, measuring the
                  deviation of the corridor axis in the coronal plane from the sagittal plane, and the flexion angle,
                  measuring the deviation of the corridor axis in the sagittal plane from the coronal plane (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00695f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9d375183-590e-4ee9-8002-754449b56328&type=jpeg&name=JBJS.23.00695f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;">The retrograde SRS corridor (yellow arrows) with
                          maximum diameter is confirmed in the coronal (<span data-id="strong_35"
                            class="annotation strong" style="position: unset;">Fig. 2-A</span>), axial (<span
                            data-id="strong_36" class="annotation strong" style="position: unset;">Fig. 2-B</span>), and
                          sagittal (<span data-id="strong_37" class="annotation strong" style="position: unset;">Figs.
                            2-C and 2-D</span>) planes of the CT image. The 3D models of the transparent pelvis and
                          corridor are displayed with 3 orthogonal CT sections (<span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Fig. 2-E</span>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.00695f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9d375183-590e-4ee9-8002-754449b56328&type=jpeg&name=JBJS.23.00695f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">The orientation of the retrograde SRS corridor.
                          <span data-id="strong_39" class="annotation strong" style="position: unset;">Fig. 3-A</span>
                          Anteroposterior (AP) view. The abduction angle is the angle between the sagittal plane and the
                          projection of the corridor axis in the coronal plane. <span data-id="strong_40"
                            class="annotation strong" style="position: unset;">Fig. 3-B</span> Lateral view. The flexion
                          angle is the angle between the coronal plane and the projection of the corridor axis in the
                          sagittal plane. <span data-id="strong_41" class="annotation strong"
                            style="position: unset;">Fig. 3-C</span> Oblique view. The projections of the retrograde SRS
                          axis (denoted as T) in the coronal and sagittal planes are labeled as S1 and S2, respectively.
                          The retrograde SRS corridor axis and its planar projections are represented by the
                          arrow-headed rod.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Measurement
                Reliability and Consistency Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">We compared the inter- and intraobserver reliability of
                  our automated method with that of a manual method<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">10</span></a>.
                  With the manual method, the transparent pelvic model was rotated manually to present the axial view of
                  the retrograde SRS corridor until a cylinder with the maximum diameter could be placed within the
                  intraosseous area of the corridor (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1-C</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Seventeen pelves were randomly selected from our data set
                  for the reliability analyses. The sample size was determined using the method of Walter et al.<a
                    href="" data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">19</span></a>, with an estimated intraclass correlation coefficient (ICC)
                  of 0.9. The inter- and intraobserver reliability was evaluated using 2-way random and 2-way fixed
                  models, respectively<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">20</span></a>. To
                  evaluate intraobserver reliability, 1 of the experienced orthopaedic surgeons (J.-X.Z.) measured the
                  corridor diameter and orientation 3 times in a randomized order, with a minimum of 24 hours between
                  trials for both methods. For the assessment of interobserver reliability, the 3 other experienced
                  orthopaedic surgeons (H.C., H.L., L.-C.Z.) independently conducted the same measurements, in a random
                  order, with each method.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">We measured all corridor parameters using both methods.
                  The manual measurements were performed 3 times by J.-X.Z. in a random manner. The average result was
                  compared with the result of the automated method for each pelvis. The agreement between the 2 methods
                  was evaluated with the root-mean-square error (RMSE) and Bland-Altman plots. We analyzed the screw
                  applicability for both methods.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The distribution of the measurements of the retrograde SRS
                  corridor parameters using the automated method was assessed using the Kolmogorov-Smirnov test. Data
                  with a normal distribution were compared between sexes using the independent samples t test. For data
                  with a non-normal distribution, the mean differences were analyzed using the Mann-Whitney U test. The
                  paired samples t test was employed to compare the difference between sides or repeated measurements. A
                  stepwise linear regression model was applied to investigate the influence of sex, laterality, age,
                  height, and weight on the retrograde SRS corridor. Statistical analysis was conducted using SPSS 20
                  (IBM), with the significance level set at p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Reliability
                and Consistency Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Compared with the manual approach, the automated method
                  exhibited superior intra- and interobserver reliability in measuring each parameter, with intra- and
                  interobserver ICCs of 0.998 and 0.995 for corridor diameter, 0.966 and 0.959 for abduction angle, and
                  0.881 and 0.893 for flexion angle (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). When
                  employing the manual method, intra- and interobserver agreements with ICCs higher than 0.9 were
                  observed only for the diameter measurements (ICC values of 0.925 and 0.918).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Reliability Study Results<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 572px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Method/Parameter</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Intraobserver</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Interobserver</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ICC</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ICC</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Automated</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diameter</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.998</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.996-0.999</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.992-0.997</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Abduction</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.966</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.939-0.982</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.959</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.931-0.971</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Flexion</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.881</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.765-0.937</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.893</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.772-0.945</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Manual</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diameter</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.925</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.872-0.959</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.918</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.861-0.955</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Abduction</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.869</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.783-0.927</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.805</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.687-0.89</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Flexion</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.745</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.601-0.852</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.672</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.504-0.806</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ICC = intraclass correlation coefficient, CI = confidence
                        interval.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The manual measurements are presented in Appendix Table
                  S1. Although the Bland-Altman plots demonstrated good consistency between the 2 methods for each
                  corridor parameter (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>), the
                  manual method exhibited a negative proportional bias compared with the automated method. This pattern
                  was observed across all parameters and within both the male and female subgroups. This indicates that
                  as the magnitude of the measured value increased, the manual method usually yielded lower values than
                  the automated method.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.00695f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9d375183-590e-4ee9-8002-754449b56328&type=jpeg&name=JBJS.23.00695f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Bland-Altman plots for the analysis of the
                          agreement between the manual and automated methods. The vertical axis indicates the absolute
                          difference between the manual and automated measurements, while the horizontal axis represents
                          the mean of their measures. Each row of plots presents a different variable (diameter, length,
                          flexion, abduction) and each column, a different group (total, male, female). Limits of
                          agreement are shown as 2 dashed dark brown lines, and the mean difference is shown as a solid
                          blue line. The dotted purple line (a diagonal line crossing the mean) represents the
                          regression fit of the differences of the means, with the 95% confidence interval shown as
                          light brown lines. The small green bars represent the 95% confidence intervals for the mean
                          difference and limits of agreement. SD = standard deviation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">All corridor parameters displayed significant differences
                  between the 2 methods, overall and in both sexes (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>). In
                  particular, the automated method resulted in a significantly larger corridor diameter, with an RMSE of
                  1.1 mm; the manual method yielded a longer length, greater abduction, and less flexion of the
                  corridor, with RMSE values of 7.3 mm, 3.6°, and 9.9°, respectively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Difference Between Manual and Automated
                          Measurements<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 678px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Parameter</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          Difference (SD)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">RMSE</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diameter <span
                            data-id="emphasis_29" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.9
                          (0.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.0
                          (0.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.6
                          (0.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Flexion <span
                            data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−5.6
                          (8.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−5.9
                          (8.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−5.1
                          (7.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Abduction <span
                            data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.1
                          (2.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6
                          (2.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1
                          (2.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Length <span
                            data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.4
                          (6.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7
                          (6.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7
                          (7.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.015</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">SD = standard deviation, RMSE = root-mean-square error.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Paired samples t test comparing the 2 methods.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Screw
                Applicability Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">The automated method demonstrated superior screw
                  applicability compared with the manual method, particularly for females. Accounting for a 1-mm safety
                  margin around the screw, 80.6% of the female hemipelves could be managed with a 4.5-mm screw based on
                  measurements made with the automated method compared with only 64.5% for the manual method. Only 7.3%
                  of the female hemipelves could be treated with the commonly used 6.5-mm screw based on measurements
                  made with the manual method, whereas this value increased to 19.4% with the automated method. For
                  males, the automated method also increased the percentage of hemipelves that could be treated with a
                  6.5-mm screw (88.4%) compared with the manual method (75.4%). Even without the safety margin, the
                  superiority of the automated method over the manual method remained for females in terms of the
                  percentage that could be treated with a 6.5-mm screw (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Screw Applicability of Automated and Manual
                          Measurement Methods with and without Safety Margin</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 445.893px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.826633);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="3" style="position: unset;"><span class="text" style="position: unset;">Sex/Safety
                          Margin</span></th>
                      <th align="center" colspan="6" style="position: unset;"><span class="text"
                          style="position: unset;">No. (%) of Hemipelves That Could Be Treated According to Screw
                          Size</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Automated</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Manual</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3.5
                          mm</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">4.5
                          mm</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">6.5
                          mm</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3.5
                          mm</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">4.5
                          mm</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">6.5
                          mm</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">284
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">284
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">251
                          (88.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">283
                          (99.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">282
                          (99.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">214
                          (75.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 0 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">284
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">284
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">284
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">284
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">284
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">282
                          (99.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">121
                          (97.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100
                          (80.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (19.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">112
                          (90.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80
                          (64.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (7.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 0 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">124(100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">124(100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100
                          (80.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">124
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">124
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80
                          (64.5%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Characteristics of Retrograde SRS Corridor</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a> shows
                  the SRS corridor parameters measured with the automated method in all patients. Males exhibited a
                  significantly larger mean corridor diameter compared with females (10.4 versus 7.5 mm; p &lt; 0.001).
                  Females displayed a more vertically angled corridor than males, with an average flexion angle of 17.6°
                  versus 23.9° (p &lt; 0.001). There was a significant but minimal difference between sexes in terms of
                  abduction angles, with an average of 49.1° in males versus 51.1° in females (p &lt; 0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Anatomical Parameters of the Retrograde SRS
                          Corridor<span data-id="table_footnote_reference_10"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1058.37px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.937322);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Parameter/Laterality</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Male (N = 142)</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Female (N = 62)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          (SD)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Range</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          (SD)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Range</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diameter <span
                            data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.4
                          (1.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.8-14.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.5
                          (1.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.8-10.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.5
                          (1.5)<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.9-14.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.6
                          (1.2)<span data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.5-10.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.3
                          (1.5)<span data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.8-14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.4
                          (1.2)<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.8-10.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Length <span
                            data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">127.9
                          (10.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">108.2-166.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">125.3
                          (11.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">98.4-156.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.021</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">127.8
                          (9.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">109.8-166.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">125.0
                          (11.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">107.0-155.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.108</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">128.1
                          (10.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">108.2-164.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">125.6
                          (11.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">98.4-156.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.126</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Abduction <span
                            data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49.1
                          (3.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">37.6-59.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51.1
                          (3.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">42.5-61.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49.1
                          (3.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">41.2-59.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.6
                          (4.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">42.5-59.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.038</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49.0
                          (3.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">37.6-59.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51.7
                          (3.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">43.8-61.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Flexion <span
                            data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.9
                          (8.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.0-49.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.6
                          (8.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.8-46.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.8
                          (8.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.1-46.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.0
                          (9.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.15-46.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.9
                          (9.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.0-49.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.1
                          (7.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.8-34.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">SD = standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Independent samples t test comparing the 2 sexes.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">P &lt; 0.05 for the difference between sides according to the paired
                        samples t test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Factors
                Influencing Retrograde SRS Corridor</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Multivariable regression analysis showed that female sex
                  was the only independent factor affecting all of the corridor parameters, with the greatest effect on
                  the corridor diameter (β = −0.583, p &lt; 0.001), followed by the abduction and flexion angles, which
                  had a moderate influence (β = 0.265 and −0.322, respectively; p &lt; 0.001 for both) and the corridor
                  length, which had the least impact (β = 0.128) (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>). The
                  corridor length was also positively influenced by body height and weight, with β coefficients of 0.282
                  and 0.203, respectively (p &lt; 0.001 for both).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Regression Analysis of Parameters of the Retrograde
                          SRS Corridor<span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 249.354px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.790865);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Parameter</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Diameter</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Length</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Abduction</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Flexion</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female sex</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.583</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_42" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.128</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_43" class="annotation strong" style="position: unset;">0.039</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.265</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_44" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.322</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_45" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Right side</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.054</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.144</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.667</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.033</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.493</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.015</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.758</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.047</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.245</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.026</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.615</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.026</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.629</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.089</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.088</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Height</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.116</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.282</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_46" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.032</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.670</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.677</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Weight</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.054</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.241</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.203</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_47" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.009</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.885</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.041</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.489</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Boldface represents a strong prediction that has significance.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">In an attempt to improve the strength and safety of screw
                  fixation of superior pubic ramus fractures, numerous studies have evaluated the maximum possible screw
                  diameters accommodated by the superior pubic ramus or the available intraosseous space of the superior
                  pubic ramus<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_11" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_11" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_11" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">13</span></a>. Most of these studies used a manual measurement method,
                  involving identification of the center line of the intraosseous corridor on CT images<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_12" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_12" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_12" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">13</span></a>. To minimize human intervention and the discrepant
                  measurements associated with the manual method, we developed a dependable automated method for
                  identifying the retrograde SRS corridor for percutaneous pelvic fracture fixation. To our knowledge,
                  this study is the first to employ an automated program for performing 3D measurements of the
                  retrograde SRS corridor. The results showed that our automated method could determine a larger
                  corridor diameter than was identified with the manual method and thus increase the percentage of
                  patients receiving ideal percutaneous screw placement. Females might benefit more from our automated
                  procedure, as they had a significantly narrower SRS corridor compared with males. The average corridor
                  diameters calculated by our automated method are generally consistent with existing CT-based studies<a
                    href="" data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_13" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_13" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">7</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Usually, the SRS can be placed in a retrograde or
                  antegrade fashion, depending on the location of the fracture. The Nakatani classification is a useful
                  descriptor and clinical predictor for superior pubic ramus fractures<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">9</span></a>. According to Starr et al.<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">9</span></a>, ramus fractures at the middle of or medial to the obturator
                  foramen (Nakatani zones I and II) are often stabilized by retrograde screws, while some Nakatani
                  zone-III fractures should be stabilized in the antegrade fashion. Safe retrograde placement of an SRS
                  is typically restricted by 3 specific isthmus zones: the middle portion of the pubic ramus, the top
                  region of the acetabular dome<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_16" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_16" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">7</span></a>, and
                  the parasymphyseal area near the entry point of the SRS<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">21</span></a>. In cases where the isthmus site is situated in the
                  parasymphyseal area, failure to clearly define the entrance area would lead to a substantially larger
                  corridor diameter than what the diameter actually is, as illustrated by Feng et al.<a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">10</span></a>. Thus, we believe that Nakatani zone I should be defined as
                  the entry area for the retrograde SRS based on 2 considerations: (1) it enables our preferred SRS
                  technique to be applicable to most superior pubic ramus fractures, including those in Nakatani zones I
                  and II and nearly half of those in zone III<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">21</span></a>,
                  and (2) it ensures effective screw placement to bridge Nakatani zone-I fractures<a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">9</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">The anterior pelvic plane has frequently been used as a
                  reference plane in analyzing pelvic anatomy. However, this plane does not account for the inherent
                  symmetry of pelvic anatomy. A pelvis-specific coordinate system was utilized in this study based on
                  the self-symmetry of the pelvis. As the process of developing the pelvis-specific coordinate system
                  normalizes pelvic orientation by correcting arbitrary body poses during CT scanning and eliminates the
                  variable sagittal orientations of different pelves, it will enable accurate assessment of the
                  influence of individual pelvic anatomy on corridor angles in future studies. Our results revealed
                  significant disparities in the angles of the retrograde SRS corridor between sexes, presenting
                  different results compared with previous studies (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VII</a>)<a
                    href="" data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_21" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_21" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">8</span></a>. Additionally, our findings demonstrate that females have a
                  more vertically angled SRS corridor compared with males, after accounting for laterality, age, height,
                  and weight.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_42" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VII</span>Summary of Previous Studies on CT-Based
                          Measurements of SRS Corridor or Acetabular Anterior Column<span
                            data-id="table_footnote_reference_30" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 578.557px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.544702);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Study</span>
                      </th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Population</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Sex</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Mean Age (SD) <span data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Diameter <span data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Length <span data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Flexion <span data-id="emphasis_40" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Abduction <span data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          (SD)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Range</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          (SD)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Range</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          (SD)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Range</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          (SD)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Range</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Chen<a href=""
                            data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                              style="position: unset;">4</span></a><span data-id="superscript_22"
                            class="annotation superscript" style="position: unset;"></span>, 2009</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Chinese</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.60-10.8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.81
                          (4.58)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42.66
                          (3.23)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">80 males</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51.2
                          (19.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.76
                          (0.98)<span data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">113.83
                          (7.07)<span data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">84 females</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47.0
                          (16.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.58
                          (1.13)<span data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">105.16
                          (8.52)<span data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Puchwein<a href=""
                            data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                              style="position: unset;">8</span></a><span data-id="superscript_23"
                            class="annotation superscript" style="position: unset;"></span>, 2012</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Austria</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.2-20.2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">111.9-144.6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.4-23.1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">32.3-45.2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">35 males</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.3
                          (18.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.0
                          (2.0)<span data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">128.3
                          (6.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.4
                          (3.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.2
                          (3.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">15 females</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43.4
                          (21.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.4
                          (2.9)<span data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">124. 8
                          (7.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.2
                          (4.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.7
                          (3.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ochs<a href=""
                            data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                              style="position: unset;">7</span></a><span data-id="superscript_24"
                            class="annotation superscript" style="position: unset;"></span>, 2014</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">European</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">65 males</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49.68
                          (20.15)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.3
                          (1.2)<span data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.5-11.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">111.5
                          (7.4)<span data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">97.0-133.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.81
                          (4.89)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.30-43.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.89
                          (3.79)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">31.30-50.2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">65 females</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52.06
                          (20.83)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.1
                          (1.0)<span data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.5-9.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100.6
                          (7.6)<span data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">77.5-118.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.46
                          (4.12)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.70-45.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42.27
                          (3.92)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">32.90-53</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Feng<a href=""
                            data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                              style="position: unset;">10</span></a><span data-id="superscript_25"
                            class="annotation superscript" style="position: unset;"></span>, 2015</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Chinese</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">50.28</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">65 males</span></td>
                      <td align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">—</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.28
                          (1.27)<span data-id="table_footnote_reference_22" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.20-14.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.74
                          (5.94)<span data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.06-32.25</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">65 females</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.87
                          (1.22)<span data-id="table_footnote_reference_24" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.80-13.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.37
                          (7.57)<span data-id="table_footnote_reference_25" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.49-40.36</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Trikha<a href=""
                            data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                              style="position: unset;">3</span></a><span data-id="superscript_26"
                            class="annotation superscript" style="position: unset;"></span>, 2020</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Indian</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">71 males</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.8
                          (2.3)<span data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.6-16.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">110.7
                          (8.2)<span data-id="table_footnote_reference_27" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">93-134.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">31 females</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">44.09</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.1
                          (1.9)<span data-id="table_footnote_reference_28" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.4-11.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">102.5
                          (7.5)<span data-id="table_footnote_reference_29" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">87.8-122.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">SD = standard deviation, SRS = superior pubic ramus screw.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">P &lt; 0.05 for difference between sexes.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Several studies have examined the associations between
                  anthropometric data and sacroiliac or infra-acetabular screw corridors<a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">22</span></a>. However, such data on the retrograde SRS corridor are
                  scarce. Our analysis indicated that female sex is an independent predictor of a narrower SRS corridor.
                  As the size and shape of the pelvis are patient-specific, each pelvis has to be addressed on an
                  individualized basis when the surgical plan requires a high degree of accuracy. Future studies should
                  be conducted to validate our findings regarding sex-related dependencies of the corridor parameters
                  after normalizing the size of pelvic models.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Compared with the automated techniques for measuring
                  trans-sacral, sacroiliac, or infra-acetabular intraosseous corridors<a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">22</span></a>, our method has the advantage of calculation speed. Gras et
                  al. calculated the intraosseous pathway of trans-sacral and infra-acetabular screws through nonrigid
                  registration with a template model<a href="" data-id="citation_reference_58"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">22</span></a>. The process of searching the trans-sacral pathway was
                  performed over a range of discretized angles, in which the calculated result might not be optimized.
                  In the infra-acetabular and sacroiliac screw studies by Mendel et al.<a href=""
                    data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_30"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">16</span></a>, it took too much time for traversal of all of the entry
                  and exit points in a manner of gradually increased resolution or nested loops. Compared with about 35
                  minutes for calculating the corridor for a sacroiliac screw<a href="" data-id="citation_reference_62"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">23</span></a>,
                  our program took about 2 to 5 minutes, depending on the number of points on the entry and exit areas.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Our study has several limitations. First, we did not
                  consider biomechanical or patient-specific factors by, for example, identifying areas with sufficient
                  bone quality for better screw purchase or accounting for dysmorphic corridors in our procedure.
                  Second, a systematic bias was observed for each corridor parameter between the automated and manual
                  measurements, and we did not employ a gold standard method with which to quantitatively evaluate and
                  compare the accuracy of the 2 techniques. Therefore, our automated method should undergo validation
                  through additional studies. Third, given that our automated method involves time-consuming image
                  manipulations, such as image segmentation, 3D model alignment, and area marking, it is currently
                  applicable only to preoperative planning and cannot be utilized in real time during surgery. Optical
                  tracking navigation has been established as the gold standard for precise implant positioning in
                  percutaneous pelvic fracture fixation<a href="" data-id="citation_reference_63"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">24</span></a>.
                  The feasibility of our technique needs to be assessed by in vitro or clinical studies utilizing a
                  navigation system.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, our automated method showed superior intra-
                  and interobserver reliability compared with the manual method of measuring the retrograde SRS
                  corridor. Ongoing study is being performed to incorporate biomechanical and patient-specific factors,
                  automate image manipulations, and improve computational efficiency and accuracy. While our automated
                  method cannot be directly utilized by traumatologists in its current version, the development of
                  integrated software is in progress and the result will be made available in the near future.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I142" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I142</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Bozzio AE</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Wydra FB</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Mitchell JJ</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Ackerson RM</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Mauffrey C</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Percutaneous fixation of
                  anterior and posterior column acetabular fractures</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2014
                Oct;37(10):675-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Percutaneous%20fixation%20of%20anterior%20and%20posterior%20column%20acetabular%20fractures&as_occt=title&as_sauthors=%20%22AE%20Bozzio%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Debuka E</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Kumar G</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Dalal N</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Kalra S</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Fracture characteristics and
                  outcomes of acetabular fracture management with minimumly invasive approach and percutaneous
                  fixation</span>. <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Eur J
                  Orthop Surg Traumatol.</span> 2021 Oct;31(7):1363-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%20characteristics%20and%20outcomes%20of%20acetabular%20fracture%20management%20with%20minimumly%20invasive%20approach%20and%20percutaneous%20fixation&as_occt=title&as_sauthors=%20%22E%20Debuka%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Trikha V</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Kumar A</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Mittal S</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Passey J</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Gaba S</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Kumar A</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Morphometric analysis of the
                  anterior column of the acetabulum and safety of intramedullary screw fixation for its fractures in
                  Indian population: a preliminary report</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Int Orthop.</span> 2020 Apr;44(4):655-64.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Morphometric%20analysis%20of%20the%20anterior%20column%20of%20the%20acetabulum%20and%20safety%20of%20intramedullary%20screw%20fixation%20for%20its%20fractures%20in%20Indian%20population%3A%20a%20preliminary%20report&as_occt=title&as_sauthors=%20%22V%20Trikha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Chen KN</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Wang G</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Cao LG</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Zhang MC</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Differences of percutaneous
                  retrograde screw fixation of anterior column acetabular fractures between male and female: a study of
                  164 virtual three-dimensional models</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Injury.</span> 2009 Oct;40(10):1067-72.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Differences%20of%20percutaneous%20retrograde%20screw%20fixation%20of%20anterior%20column%20acetabular%20fractures%20between%20male%20and%20female%3A%20a%20study%20of%20164%20virtual%20three-dimensional%20models&as_occt=title&as_sauthors=%20%22KN%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Eastman JG</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Chip Routt ML Jr</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Intramedullary Fixation
                  Techniques for the Anterior Pelvic Ring</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2018 Sep;32(Suppl 6):S4-13.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intramedullary%20Fixation%20Techniques%20for%20the%20Anterior%20Pelvic%20Ring&as_occt=title&as_sauthors=%20%22JG%20Eastman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Mauffrey C</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Hao J</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Cuellar DO</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Herbert B</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Chen X</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Liu B</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Smith W</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The epidemiology and injury
                  patterns of acetabular fractures: are the USA and China comparable?</span><span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2014
                Nov;472(11):3332-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20epidemiology%20and%20injury%20patterns%20of%20acetabular%20fractures%3A%20are%20the%20USA%20and%20China%20comparable%3F&as_occt=title&as_sauthors=%20%22C%20Mauffrey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Ochs BG</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Stuby FM</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Ateschrang A</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Stoeckle U</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Gonser CE</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Retrograde lag screw placement
                  in anterior acetabular column with regard to the anterior pelvic plane and midsagittal plane — virtual
                  mapping of 260 three-dimensional hemipelvises for quantitative anatomic analysis</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Injury.</span> 2014
                Oct;45(10):1590-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Retrograde%20lag%20screw%20placement%20in%20anterior%20acetabular%20column%20with%20regard%20to%20the%20anterior%20pelvic%20plane%20and%20midsagittal%20plane%20%E2%80%94%20virtual%20mapping%20of%20260%20three-dimensional%20hemipelvises%20for%20quantitative%20anatomic%20analysis&as_occt=title&as_sauthors=%20%22BG%20Ochs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Puchwein P</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Enninghorst N</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Sisak K</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Ortner T</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Schildhauer TA</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Balogh ZJ</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Pichler W</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Percutaneous fixation of
                  acetabular fractures: computer-assisted determination of safe zones, angles and lengths for screw
                  insertion</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Arch
                  Orthop Trauma Surg.</span> 2012 Jun;132(6):805-11.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Percutaneous%20fixation%20of%20acetabular%20fractures%3A%20computer-assisted%20determination%20of%20safe%20zones%2C%20angles%20and%20lengths%20for%20screw%20insertion&as_occt=title&as_sauthors=%20%22P%20Puchwein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Starr AJ</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Nakatani T</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Reinert CM</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Cederberg K</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Superior pubic ramus fractures
                  fixed with percutaneous screws: what predicts fixation failure?</span><span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2008
                Feb;22(2):81-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Superior%20pubic%20ramus%20fractures%20fixed%20with%20percutaneous%20screws%3A%20what%20predicts%20fixation%20failure%3F&as_occt=title&as_sauthors=%20%22AJ%20Starr%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Feng X</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Fang J</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Lin C</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Zhang S</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Lei W</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Tang S</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Chen B</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Axial perspective to find the
                  largest intraosseous space available for percutaneous screw fixation of fractures of the acetabular
                  anterior column</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Int J Comput Assist Radiol Surg.</span> 2015 Aug;10(8):1347-53.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Axial%20perspective%20to%20find%20the%20largest%20intraosseous%20space%20available%20for%20percutaneous%20screw%20fixation%20of%20fractures%20of%20the%20acetabular%20anterior%20column&as_occt=title&as_sauthors=%20%22X%20Feng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Quan Q</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Hong L</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Chang B</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Liu RX</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Zhang YQ</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Zhao Q</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Lu SB</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">A radiographic simulation study
                  of fixed superior pubic ramus fractures with retrograde screw insertion</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Orthop.</span> 2016 Jul
                14;13(4):364-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20radiographic%20simulation%20study%20of%20fixed%20superior%20pubic%20ramus%20fractures%20with%20retrograde%20screw%20insertion&as_occt=title&as_sauthors=%20%22Q%20Quan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Attias N</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Lindsey RW</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Starr AJ</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Borer D</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Bridges K</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Hipp JA</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The use of a virtual
                  three-dimensional model to evaluate the intraosseous space available for percutaneous screw fixation
                  of acetabular fractures</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 2005 Nov;87(11):1520-3.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20a%20virtual%20three-dimensional%20model%20to%20evaluate%20the%20intraosseous%20space%20available%20for%20percutaneous%20screw%20fixation%20of%20acetabular%20fractures&as_occt=title&as_sauthors=%20%22N%20Attias%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Shahulhameed A</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Roberts CS</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Pomeroy CL</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Acland RD</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Giannoudis PV</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Mapping the columns of the
                  acetabulum—implications for percutaneous fixation</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Injury.</span> 2010 Apr;41(4):339-42.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mapping%20the%20columns%20of%20the%20acetabulum%E2%80%94implications%20for%20percutaneous%20fixation&as_occt=title&as_sauthors=%20%22A%20Shahulhameed%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Suzuki T</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Soma K</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Shindo M</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Minehara H</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Itoman M</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Anatomic study for pubic
                  medullary screw insertion</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">J Orthop Surg (Hong Kong).</span> 2008 Dec;16(3):321-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anatomic%20study%20for%20pubic%20medullary%20screw%20insertion&as_occt=title&as_sauthors=%20%22T%20Suzuki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Mendel T</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Radetzki F</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Wohlrab D</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Stock K</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Hofmann GO</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Noser H</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">CT-based 3-D visualisation of
                  secure bone corridors and optimal trajectories for sacroiliac screws</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Injury.</span> 2013
                Jul;44(7):957-63.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=CT-based%203-D%20visualisation%20of%20secure%20bone%20corridors%20and%20optimal%20trajectories%20for%20sacroiliac%20screws&as_occt=title&as_sauthors=%20%22T%20Mendel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Mendel T</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Noser H</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Kuervers J</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Goehre F</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Hofmann GO</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Radetzki F</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The influence of sacral
                  morphology on the existence of secure S1 and S2 transverse bone corridors for iliosacroiliac screw
                  fixation</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Injury.</span> 2013 Dec;44(12):1773-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20influence%20of%20sacral%20morphology%20on%20the%20existence%20of%20secure%20S1%20and%20S2%20transverse%20bone%20corridors%20for%20iliosacroiliac%20screw%20fixation&as_occt=title&as_sauthors=%20%22T%20Mendel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Gras F</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Gottschling H</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Schröder M</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Marintschev I</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Hofmann GO</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Burgkart R</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Transsacral Osseous Corridor
                  Anatomy Is More Amenable To Screw Insertion In Males: A Biomorphometric Analysis of 280 Pelves</span>.
                <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2016 Oct;474(10):2304-11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Transsacral%20Osseous%20Corridor%20Anatomy%20Is%20More%20Amenable%20To%20Screw%20Insertion%20In%20Males%3A%20A%20Biomorphometric%20Analysis%20of%20280%20Pelves&as_occt=title&as_sauthors=%20%22F%20Gras%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Wagner D</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Kamer L</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Sawaguchi T</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Richards RG</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Noser H</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Hofmann A</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Rommens PM</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Morphometry of the sacrum and
                  its implication on trans-sacral corridors using a computed tomography data-based three-dimensional
                  statistical model</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2017 Aug;17(8):1141-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Morphometry%20of%20the%20sacrum%20and%20its%20implication%20on%20trans-sacral%20corridors%20using%20a%20computed%20tomography%20data-based%20three-dimensional%20statistical%20model&as_occt=title&as_sauthors=%20%22D%20Wagner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Walter SD</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Eliasziw M</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Donner A</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Sample size and optimal designs
                  for reliability studies</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Stat Med.</span> 1998 Jan 15;17(1):101-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sample%20size%20and%20optimal%20designs%20for%20reliability%20studies&as_occt=title&as_sauthors=%20%22SD%20Walter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Shrout PE</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Fleiss JL</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Intraclass correlations: uses
                  in assessing rater reliability</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Psychol Bull.</span> 1979 Mar;86(2):420-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intraclass%20correlations%3A%20uses%20in%20assessing%20rater%20reliability&as_occt=title&as_sauthors=%20%22PE%20Shrout%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Weatherby DJ</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Chip Routt ML Jr</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Eastman JG</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">The
                  Retrograde-Antegrade-Retrograde Technique for Successful Placement of a Retrograde Superior Ramus
                  Screw</span>. <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J
                  Orthop Trauma.</span> 2017 Jul;31(7):e224-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Retrograde-Antegrade-Retrograde%20Technique%20for%20Successful%20Placement%20of%20a%20Retrograde%20Superior%20Ramus%20Screw&as_occt=title&as_sauthors=%20%22DJ%20Weatherby%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Gras F</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Gottschling H</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Schröder M</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Marintschev I</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Reimers N</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Burgkart R</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Sex-specific differences of the
                  infraacetabular corridor: a biomorphometric CT-based analysis on a database of 523 pelves</span>.
                <span data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2015 Jan;473(1):361-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex-specific%20differences%20of%20the%20infraacetabular%20corridor%3A%20a%20biomorphometric%20CT-based%20analysis%20on%20a%20database%20of%20523%20pelves&as_occt=title&as_sauthors=%20%22F%20Gras%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Noser H</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Radetzki F</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Stock K</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Mendel T</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">A method for computing general
                  sacroiliac screw corridors based on CT scans of the pelvis</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Digit Imaging.</span> 2011
                Aug;24(4):665-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20method%20for%20computing%20general%20sacroiliac%20screw%20corridors%20based%20on%20CT%20scans%20of%20the%20pelvis&as_occt=title&as_sauthors=%20%22H%20Noser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Baumann F</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Becker C</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Freigang V</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Alt V</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Imaging, post-processing and
                  navigation: Surgical applications in pelvic fracture treatment</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Injury.</span> 2022 Nov;53(Suppl
                3):S16-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Imaging%2C%20post-processing%20and%20navigation%3A%20Surgical%20applications%20in%20pelvic%20fracture%20treatment&as_occt=title&as_sauthors=%20%22F%20Baumann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;9d375183-590e-4ee9-8002-754449b56328&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=9d375183-590e-4ee9-8002-754449b56328&type=supplement&name=12512"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D9d375183-590e-4ee9-8002-754449b56328%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2356</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00695</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">August 9, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_25"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedics, The Fourth Medical Center, Chinese PLA General Hospital,
                            Beijing, People’s Republic of China</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;9d375183-590e-4ee9-8002-754449b56328&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=9d375183-590e-4ee9-8002-754449b56328&type=pdf&name=JBJS.23.00695.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=9d375183-590e-4ee9-8002-754449b56328&type=pdf&name=JBJS.23.00695.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_30"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This work was
                          supported in part by grants from National Natural Science Foundation of China (81902272),
                          China Postdoctoral Science Foundation (2020T130780), and Beijing Natural Science Foundation
                          (L222061). The <span data-id="strong_31" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I141"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I141</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;9d375183-590e-4ee9-8002-754449b56328&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=9d375183-590e-4ee9-8002-754449b56328&type=zip&name=JBJS.23.00695.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jing-Xin Zhao, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5385-3969" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5385-3969</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hua Chen, MD<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span><span data-id="affiliation_reference_2"
                  class="label annotation cross_reference">2</span><span data-id="footnote_reference_1"
                  class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4410-6742" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4410-6742</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mingjie Dong, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5303-3899" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5303-3899</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Fujiao Ju, PhD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0188-6996" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0188-6996</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Houchen Lyu, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0128-0062" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0128-0062</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Li-Cheng Zhang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:pftang301@163.com" class="" style="position: unset;">pftang301@163.com</a><span
                    class="" style="position: unset;">;</span><a href="mailto:zhanglcheng218@126.com" class=""
                    style="position: unset;">zhanglcheng218@126.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6782-1749" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6782-1749</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Pei-Fu Tang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:pftang301@163.com" class="" style="position: unset;">pftang301@163.com</a><span
                    class="" style="position: unset;">;</span><a href="mailto:zhanglcheng218@126.com" class=""
                    style="position: unset;">zhanglcheng218@126.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4279-1704" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4279-1704</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedics, The Fourth Medical Center, Chinese PLA General
                Hospital, Beijing, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">National Clinical Research Center for Orthopedics, Sports Medicine &
                Rehabilitation, Beijing, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Faculty of Materials and Manufacturing, Beijing University of Technology,
                Beijing, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Faculty of Information Technology, Beijing University of Technology, Beijing,
                People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Jing-Xin Zhao, MD, and Hua Chen, MD, contributed
                        equally to this work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 29217.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
