import { queryKeys } from "@constants/common";
import useClerkUser from "@hooks/useClerkUser";
import { useGlobalStore } from "@hooks/useGlobalStore";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import { fetchCMEData } from "../queries/cme";
import type {
	ActivityLogCardData,
	CMEData,
	SpecialtyPerformanceData,
} from "../queries/schema";

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

const isDateRangeOverlapping = (
	logStartDate: string,
	logEndDate: string,
	filterStartDate: string,
	filterEndDate: string,
): boolean => {
	if (!(filterStartDate && filterEndDate)) {
		return false;
	}
	const start = dayjs(filterStartDate, "MM/DD/YYYY").startOf("day");
	const end = dayjs(filterEndDate, "MM/DD/YYYY").endOf("day");
	const eventStart = dayjs(logStartDate);
	const eventEnd = dayjs(logEndDate);

	return (
		eventStart.isBetween(start, end, null, "[]") ||
		eventEnd.isBetween(start, end, null, "[]") ||
		(eventStart.isBefore(start) && eventEnd.isAfter(end))
	);
};

export const useCME = () => {
	const { userPublicMetadataId } = useClerkUser();
	const hasAcceptedCMEDisclaimer = useGlobalStore(
		(state) => state.hasAcceptedCMEDisclaimer,
	);

	const { data: cmeResponse, isLoading } = useQuery<CMEData>({
		queryKey: [queryKeys.cme],
		queryFn: async () => await fetchCMEData({ userId: userPublicMetadataId! }),
		enabled: !!userPublicMetadataId,
	});

	const cmeData = cmeResponse?.data;

	const activityLogData = cmeData?.activityLog;

	const showEmptyState =
		!activityLogData || (activityLogData.length === 0 && !isLoading);

	const earliestActivityDate = activityLogData?.reduce(
		(earliest, log) => {
			const logDate = new Date(log.dateStarted);
			return earliest === null || logDate < earliest ? logDate : earliest;
		},
		null as Date | null,
	);

	const formattedDate = earliestActivityDate
		? new Date(earliestActivityDate).toLocaleDateString("en-US", {
				month: "short",
				day: "numeric",
				year: "numeric",
			})
		: "";
	const calculateProgressScore = (
		completed: number,
		remaining: number,
	): number => {
		if (completed + remaining === 0) {
			return 0;
		}
		return Math.round((completed / (completed + remaining)) * 100);
	};

	const activityLogCardData: ActivityLogCardData = {
		icon: "view-list-outline" as const,
		title: "Activity Log",
		subtitle: `Based on activities completed since ${formattedDate}`,
		data:
			activityLogData?.map((log) => ({
				distribution: log.creditEarned
					? [0, 0]
					: [
							cmeData?.currentProgress.completedItems || 0,
							cmeData?.currentProgress.remainingItems || 0,
						],
				earned: log.creditEarned,
				score: log.creditEarned
					? 0
					: calculateProgressScore(
							cmeData?.currentProgress.completedItems || 0,
							cmeData?.currentProgress.remainingItems || 0,
						),
				title: log.creditTypeName,
				subtitle: log.creditEarned ? "Credits earned" : "Credits remaining",
			})) ?? [],
	};

	const specialitesPerformanceCardData: SpecialtyPerformanceData = {
		title: "Performance by system",
		subtitle: "Based on activities across 17 specialties",
		insights: "You're a super star in Cardiology!",
		tooltip: "",
		icon: "hexagram-outline" as const,
		specialties: [
			{ name: "Cardiology", progress: 50 },
			{ name: "Dermatology", progress: 75 },
			{ name: "Neurology", progress: 25 },
			{ name: "Oncology", progress: 60 },
			{ name: "Pediatrics", progress: 80 },
			{ name: "Psychiatry", progress: 40 },
			{ name: "Urology", progress: 90 },
		],
	};

	const prepareActivityLog = ({
		startDate,
		endDate,
		onlyEarned,
	}: {
		startDate?: string;
		endDate?: string;
		onlyEarned?: boolean;
	} = {}) => {
		const groupedEvents = activityLogData?.reduce(
			(acc, log) => {
				if (onlyEarned && !log.creditEarned) {
					return acc;
				}

				if (startDate && endDate) {
					const isInRange = isDateRangeOverlapping(
						log.dateStarted,
						log.dateEnded,
						startDate,
						endDate,
					);
					if (!isInRange) {
						return acc;
					}
				}

				const key = `${log.dateStarted}-${log.dateEnded}`;
				if (!acc[key]) {
					acc[key] = {
						dateRange: { start: log.dateStarted, end: log.dateEnded },
						events: [],
					};
				}
				acc[key].events.push(...log.events);
				return acc;
			},
			{} as Record<
				string,
				{
					dateRange: { start: string; end: string };
					events: (typeof activityLogData)[number]["events"];
				}
			>,
		);

		return groupedEvents
			? Object.entries(groupedEvents).map(([_, group]) => {
					const numChallenges = group.events.length;
					const cmeCredits = group.events.reduce(
						(acc, event) => acc + event.creditValue,
						0,
					);

					return {
						title: `+${Math.round(cmeCredits * 2) / 2} CME • ${numChallenges} challenges • ${new Date(group.dateRange.start).toLocaleDateString()}-${new Date(group.dateRange.end).toLocaleDateString()}`,
						data: group.events.map((event) => ({
							id: event.itemId,
							title: event.itemDetails.items.data.stem,
							imageUri: event.itemDetails.items.media
								? event.itemDetails.items.media[0].url
								: "",
							numResponses: event.itemDetails.items.numAttempts || 0,
							description: `Challenge #${event.itemId} • ${new Date(event.date).toLocaleDateString()}`,
							updatedAt: event.date,
							completed: event.itemDetails.items.completedAt != null,
							tags: event.itemDetails.items.tags || [],
						})),
					};
				})
			: [];
	};

	const activityLog = prepareActivityLog();
	const currentProgressCompletedItems =
		cmeData?.currentProgress?.completedItems ?? 0;
	const currentProgressRemainingItems =
		cmeData?.currentProgress?.remainingItems ?? 0;

	const getEarnedCreditLogsForDateRange = (
		startDate: string,
		endDate: string,
	) => {
		if (!activityLogData) {
			return [];
		}

		return activityLogData.filter(
			(log) =>
				log.creditEarned &&
				isDateRangeOverlapping(
					log.dateStarted,
					log.dateEnded,
					startDate,
					endDate,
				),
		);
	};

	const getEarnedCreditsForDateRange = (startDate: string, endDate: string) => {
		return Number(
			getEarnedCreditLogsForDateRange(startDate, endDate)
				.reduce(
					(sum, log) =>
						sum +
						log.events.reduce(
							(eventSum, event) => eventSum + event.creditValue,
							0,
						),
					0,
				)
				.toFixed(2),
		);
	};

	return {
		hasAcceptedCMEDisclaimer,
		specialitesPerformanceCardData,
		activityLogCardData,
		earnedCredits: cmeData?.earnedCredits ?? 0,
		remainingCredits: cmeData?.remainingCredits ?? 0,
		isLoading,
		currentProgressCompletedItems,
		currentProgressRemainingItems,
		showEmptyState,
		activityLog,
		getEarnedCreditLogsForDateRange,
		getEarnedCreditsForDateRange,
		prepareActivityLog,
	};
};
