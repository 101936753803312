import { router } from "expo-router";
import { Appbar, type AppbarHeaderProps } from "react-native-paper";

type Props = {
	view: string;
	title: string;
} & Omit<AppbarHeaderProps, "children">;
export const TempAppbar = ({ view, title, ...rest }: Props) => {
	return (
		<Appbar.Header mode="medium" elevated {...rest}>
			<Appbar.Content title={title} />

			<Appbar.Action
				onPress={() =>
					router.push({
						pathname: "/feedback",
						params: {
							view: view,
						},
					})
				}
				icon={"message-alert-outline"}
			/>
		</Appbar.Header>
	);
};
