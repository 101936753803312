import { relayEnv } from "@memorang/configs";
import {
	type GraphQLTaggedNode,
	type Variables,
	fetchQuery,
} from "react-relay";
import { logApiErrors } from "./sentry-logger";

const fetchQueryWrapper = async (
	query: GraphQLTaggedNode,
	variables: Variables = {},
) => {
	const promise = new Promise((resolve, reject) => {
		fetchQuery(relayEnv, query, variables).subscribe({
			next: (data) => resolve(data),
			error: (err: unknown) => {
				logApiErrors(query, err, variables);
				reject(err);
			},
		});
	});
	return promise;
};

export default fetchQueryWrapper;
