import type { Article } from "../../../../../types";

export const Article624: Article = {
	id: 624,
	rsuiteId: "8e8874f6-7101-4ac1-b498-5be046498b1e",
	type: "erratum",
	title:
		"Erratum: Generational Differences in Grit, Self-Control, and Conscientiousness Among Orthopaedic Surgeons",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/624.jpeg",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Erratum: Generational Differences in Grit, Self-Control,
                and Conscientiousness Among Orthopaedic Surgeons</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_2" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_2" class="annotation emphasis"
                    style="position: unset;">The Journal</span> publishes corrections when they are of significance to
                  patient care, scientific data or record-keeping, or authorship, whether that error was made by an
                  author, editor, or staff. Errata also appear in the online version and are attached to files
                  downloaded from <a href="http://jbjs.org" target="_blank" data-id="link_1" class="link"
                    style="position: unset;">jbjs.org</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">In the article entitled “Generational Differences in Grit,
                  Self-Control, and Conscientiousness Among Orthopaedic Surgeons: From Millennials to Baby Boomers” (J
                  Bone Joint Surg Am. 2019;101[14]:e71), by Samuelsen et al., there was an error in the Supplemental
                  Data, page 3. Specifically, in the table titled “Appendix B: 10-Item Self-Control Questionnaire and
                  Scoring Scale,” a comment and credit line should have been included as a footnote to the table. The
                  comment should have read: “It should also be noted that this study used a 10-item adaptation from the
                  original 13-item scale that was validated and published by Tangney et al. Please see that reference
                  for the original, 13-item scale.” The credit line should have read: “Adapted from: Tangney JP,
                  Baumeister RF, Boone AL. High self-control predicts good adjustment, less pathology, better grades,
                  and interpersonal success. J Pers. 2004 Apr;72(2):271-324. Epub 2004 Mar 16.”</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Reference</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1.&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Samuelsen BT</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Desai VS</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Turner NS</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kelly AM</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Grawe B</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Camp CL</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Generational differences in
                  grit, self-control, and conscientiousness among orthopaedic surgeons: from millennials to baby
                  boomers</span>. <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Am.</span> 2019 Jul 17;101(14):e71.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=2102076" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D8e8874f6-7101-4ac1-b498-5be046498b1e%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Erratum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): e55</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.ER.18.00275</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=2102076&native=1" class="article-title"
                              style="position: unset;">Generational Differences in Grit, Self-Control, and
                              Conscientiousness Among Orthopaedic Surgeons<span class="subtitle"
                                style="position: unset;"><br style="position: unset;">From Millennials to Baby
                                Boomers</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Samuelsen,
                              Brian T.; Desai, Vishal S.; Turner, Norman S.; Kelly, Anne M.; Grawe, Brian; Camp,
                              Christopher L.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 101(14):e71 | July 17, 2019</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_1" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;8e8874f6-7101-4ac1-b498-5be046498b1e&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=8e8874f6-7101-4ac1-b498-5be046498b1e&type=pdf&name=JBJS.ER.18.00275.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=8e8874f6-7101-4ac1-b498-5be046498b1e&type=pdf&name=JBJS.ER.18.00275.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 2348.11px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
