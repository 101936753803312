import { useMemo } from "react";
import { useWindowDimensions } from "react-native";

const useResponsiveCardLayout = (numOfImages: number) => {
	const { width } = useWindowDimensions();

	const layout = useMemo(() => {
		let cardWidth: number;
		let cardHeight: number;
		const maxCardHeight = 200; // Max height is the width of an iPhone 13
		const baseMargin = 16;

		if (numOfImages === 1) {
			// Single image should take 300 width
			cardWidth = Math.min(300, width - baseMargin * 2);
			cardHeight = Math.min(maxCardHeight, cardWidth * 0.6); // maintain aspect ratio
		} else if (numOfImages === 2) {
			// Two images should each take ~50% of the width
			cardWidth = (width - baseMargin * 3) / 2; // space for margins
			cardHeight = Math.min(maxCardHeight, cardWidth * 0.6); // maintain aspect ratio
		} else {
			// 3+ images, follow the carousel behavior with overlap
			let cardsToShow: number;

			if (width < 320) {
				cardsToShow = 2.4;
			} else if (width >= 320 && width < 360) {
				cardsToShow = 2.4;
			} else if (width >= 360 && width < 480) {
				cardsToShow = 1.5;
			} else if (width >= 480 && width < 720) {
				cardsToShow = 3.2;
			} else {
				cardsToShow = 3.5;
			}
			cardWidth = (width - 16 * (cardsToShow + 1)) / cardsToShow;
			cardHeight = Math.min(maxCardHeight, cardWidth * 0.6); // keep aspect ratio and max height
		}

		return { cardWidth, cardHeight };
	}, [width, numOfImages]);

	return layout;
};

export default useResponsiveCardLayout;
