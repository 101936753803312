import { pageViewTypes } from "@constants/tracking";
import { DetailArticleRouteContainer } from "@features/articles";
import useAnalytics from "@hooks/useAnalytics";
import { useLocalSearchParams } from "expo-router";

const DetailArticleRoute = () => {
	const { id } = useLocalSearchParams<{ id: string }>();

	useAnalytics({
		type: pageViewTypes.detailArticle,
		id,
	});

	return <DetailArticleRouteContainer id={Number(id)} />;
};

export default DetailArticleRoute;
