import fetchQueryWrapper from "@helpers/relay-fetch-query-wrapper";
/* -----------------Globals--------------- */
import { graphql } from "react-relay";
import type { ExamDetails } from "../types";

type Response = {
	examDetails: ExamDetails;
};
const query = graphql`
  query FetchExamDetailsQuery($id: ID, $numericId: Int, $tenantId: ID) {
    examDetails(id: $id, tenantId: $tenantId, numericId: $numericId) {
      metadata {
        examDate
      }
      id
      exam {
        id
        name
        icon
        isActive
        numericId
        category {
          id
          name
          icon
        }
        bundles {
          bundle {
            id
            numericId
            name
            isActive
            accessType
            bundleType
            plans {
              id
              duration
              interval
              amount
            }
          }
        }
      }
    }
  }
`;

const fetchExamDetails = async ({
	examId,
	tenantId,
}: {
	examId: string;
	tenantId: string;
}): Promise<ExamDetails> => {
	const variables = {
		tenantId,
		id: examId,
	};
	const { examDetails } = (await fetchQueryWrapper(
		query,
		variables,
	)) as Response;
	return examDetails;
};

export { fetchExamDetails };
