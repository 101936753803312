import type { Article } from "../../../../../types";

export const Article665: Article = {
	id: 665,
	rsuiteId: "6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28",
	type: "scientific article",
	title:
		"The Impact of Pelvic Incidence on Spinopelvic and Hip Alignment and Mobility in Asymptomatic Subjects",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&type=jpeg&name=JBJS.23.00493f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">The Impact of Pelvic Incidence on Spinopelvic and Hip
                Alignment and Mobility in Asymptomatic Subjects</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Youngwoo Kim,
                      MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Claudio
                      Vergari, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Background:</span> The influence of pelvic incidence (PI) on
                        spinopelvic and hip alignment and mobility has not been well investigated. The aim of this study
                        was to evaluate the influence of PI on spinopelvic and hip alignment and mobility, including the
                        pelvic-femoral angle (PFA) and motion (ΔPFA), in functional positions in a cohort of
                        asymptomatic volunteers.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Methods:</span> This was a single-center, prospective,
                        cross-sectional study. We included 136 healthy volunteers (69% female; mean age, 38 ± 11 years;
                        mean body mass index, 22 ± 3 kg/m<span data-id="superscript_1" class="annotation superscript"
                          style="position: unset;">2</span>) divided into 3 subgroups on the basis of their PI: PI &lt;
                        45° (low PI), 45° ≤ PI ≤ 60° (medium PI), and PI &gt; 60° (high PI). We made full-body lateral
                        radiographs in free-standing, standing with extension, relaxed-seated, and flexed-seated
                        positions. We measured the sacral slope (SS), lumbar lordosis (LL), and PFA. We calculated
                        lumbar (∆LL), pelvic (∆SS), and hip (∆PFA) mobilities as the change between the standing (i.e.,
                        standing with or without extension) and sitting (i.e., relaxed-seated or flexed-seated)
                        positions.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Results:</span> There were significant differences between some of
                        the 3 subgroups with respect to the LL, SS, and PFA in each of the 4 positions. There were no
                        significant differences in ΔLL, ΔSS, or ΔPFA between the 3 groups when moving from a standing to
                        a sitting position. PI had an inverse linear correlation with PFA<span data-id="subscript_1"
                          class="annotation subscript" style="position: unset;">extension</span> (R = −0.48; p &lt;
                        0.0001), PFA<span data-id="subscript_2" class="annotation subscript"
                          style="position: unset;">standing</span> (R = −0.53; p &lt; 0.0001), PFA<span
                          data-id="subscript_3" class="annotation subscript"
                          style="position: unset;">relaxed-seated</span> (R = −0.37; p &lt; 0.0001), and PFA<span
                          data-id="subscript_4" class="annotation subscript"
                          style="position: unset;">flexed-seated</span> (R = −0.47; p &lt; 0.0001). However, PI was not
                        correlated with ΔPFA<span data-id="subscript_5" class="annotation subscript"
                          style="position: unset;">standing/relaxed-seated</span> (R = −0.062; p = 0.48) or ΔPFA<span
                          data-id="subscript_6" class="annotation subscript"
                          style="position: unset;">extension/flexed-seated</span> (R = −0.12; p = 0.18). Similarly, PI
                        was not significantly correlated with ΔLL or ΔSS in either pair of positions.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Conclusions:</span> This study confirmed that spinopelvic and hip
                        parameters in functional positions were affected by PI, whereas lumbar, pelvic, and hip
                        mobilities did not depend on PI. These findings suggest that hip surgeons should consider the PI
                        of the patient to determine the patient’s specific functional safe zones before and after total
                        hip arthroplasty.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level II</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Hip mobility is important in the sagittal flexion and
                  extension of the whole coordinated kinematic chain of the spine, pelvis, and lower limbs<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">1</span></a>. This movement occurs in the femoroacetabular articulation.
                  The motion of the femur relative to the pelvis can be estimated with use of the pelvic-femoral angle
                  (PFA), which is the angle of the femur in relationship to the sacrum. This angle helps to define hip
                  mobility (ΔPFA) between postural changes with use of lateral spinopelvic radiographs. Recent studies
                  have utilized lateral radiographs to focus on spinopelvic parameters, including the PFA, in functional
                  positions such as standing and sitting. These studies found that abnormal spinopelvic and hip
                  alignment and mobility impact postoperative outcomes after total hip arthroplasty (THA)<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Pelvic incidence (PI) is a constant morphological
                  parameter. It is defined as the angle between the line orthogonal to the sacral plate and the line
                  that connects the middle of the sacral plate to the acetabular axis (the P-line)<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">6</span></a>. PI is a radiographic measurement that represents the
                  biomechanical relationship between the lumbar spine and the pelvis in patients with differing pelvic
                  anatomy, which may lead to different patterns of spinopelvic alignment and compensation during daily
                  activities performed by patients with physiological and pathological conditions such as hip
                  osteoarthritis<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">9</span></a>. Recent studies have demonstrated a relationship between PI
                  and postoperative compensation and dislocation following THA<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">13</span></a>.
                  However, conflicting results were found for sagittal pelvic morphology<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">14</span></a>. Furthermore, the role of PI in a patient’s risk of
                  instability following THA is not well understood.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">A prior study demonstrated a correlation between PI and
                  pelvic tilt (PT = −7 + 0.37 × PI; R = 0.66)<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">15</span></a>.
                  However, the relationship between PI and PFA has not been well investigated despite its potential
                  importance, both before and after THA, in the kinematics of the femur relative to the pelvis when
                  moving between standing and sitting positions. Furthermore, it is not yet clear whether and how PI is
                  associated with normal spinopelvic and hip alignment and mobility during functional activities.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The aim of the present study was to evaluate the influence
                  of PI on spinopelvic and hip alignment in functional positions involving standing and sitting, and on
                  spinopelvic and hip mobility in moving between those positions, with use of radiographic spinopelvic
                  and hip parameters in a cohort of asymptomatic volunteers. We hypothesized that spinopelvic and hip
                  parameters, including the PFA, would be affected by the PI.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Cohort</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">This was a single-center, prospective, cross-sectional
                  study. We recruited 151 volunteers between March 2022 and August 2022. We included 136 healthy
                  volunteers in the study, comprising 94 (69%) women and 42 (31%) men, with a mean age and standard
                  deviation (SD) of 38 ± 11 years (range, 23 to 64 years). The mean body mass index (BMI) was 22 ± 3
                  kg/m<span data-id="superscript_9" class="annotation superscript" style="position: unset;">2</span>
                  (range, 17 to 31 kg/m<span data-id="superscript_10" class="annotation superscript"
                    style="position: unset;">2</span>; <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). All
                  subjects were hospital health-care workers. Subjects were excluded on the basis of 6 criteria: (1) the
                  presence of abnormalities in either of the hip joints, including joint-space narrowing and the
                  presence of osteophytes (Tönnis grade of ≥2), as seen on anteroposterior hip radiographs; (2)
                  moderate-to-severe lumbar abnormalities, such as multiple disc degeneration (Kellgren-Lawrence grade
                  of ≥3), spondylolisthesis (Meyerding grade of ≥2), or scoliosis (&gt;30°), as seen on anterior and
                  lateral lumbar radiographs; (3) hip symptoms, as indicated by an Oxford Hip Score of &lt;45 points
                  (range, 0 [worst] to 48 [best]); (4) low-back pain, as indicated by an Oswestry Disability Index of
                  &gt;20 (range, 0 [no disability] to 100 [maximum disability]); (5) a history of hip or spinal surgery;
                  and (6) an age of &lt;20 years. Subjects with mild lumbar abnormalities were included if the
                  abnormalities were considered asymptomatic. Eight volunteers were excluded because of hip or lumbar
                  degeneration, and 7 volunteers were excluded because of inadequate radiographs. The study was approved
                  by the institutional review board of the Kyoto City Hospital (authorization 621) and was conducted per
                  the 2008 Declaration of Helsinki.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographic Characteristics of the Cohort<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 522px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Whole Cohort</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Subgroup</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Low PI (PI
                          &lt; 45°)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Medium PI
                          (45° ≤ PI ≤ 60°)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">High PI
                          (PI &gt; 60°)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of subjects</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">136</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37
                          (27%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70
                          (52%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (21%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex <span
                            data-id="emphasis_35" class="annotation emphasis" style="position: unset;">(no. [%] of
                            subjects)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94
                          (69%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (31%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38 ± 11
                          (23-64)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(kg/m</span><span data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_11"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_39"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22 ± 3
                          (17-31)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">NS = not significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD, with the range in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Using the values for PI classifications described by
                  Thelen et al.<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">16</span></a>, we
                  divided all subjects into 3 subgroups on the basis of their PI: PI &lt; 45° (low PI), 45° ≤ PI ≤ 60°
                  (medium PI), and PI &gt; 60° (high PI)<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">16</span></a>, as
                  shown in <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Collection and Radiographic Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">We made full-body lateral radiographs in free-standing,
                  standing with extension, relaxed-seated, and flexed-seated positions (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). For the extension radiograph, the study volunteers were asked
                  to hold onto a horizontal bar slightly higher than shoulder level and to extend their pelvis and spine
                  as much as possible<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">17</span></a>.
                  The relaxed-seated position was defined as a 90° sitting position on a height-adjustable chair with
                  the femora parallel to the floor<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">18</span></a>. In
                  the flexed-seated position, the femora were parallel to the floor with the trunk leaning maximally
                  forward<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">19</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00493f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&type=jpeg&name=JBJS.23.00493f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;">Lateral radiographs and diagrams in the
                          free-standing (i.e., standing), standing with extension (i.e., extension), relaxed-seated, and
                          flexed-seated positions. The main radiographic parameters are depicted, including the sacral
                          slope (SS), pelvic tilt (PT), pelvic incidence (PI), lumbar lordosis (LL), pelvic-femoral
                          angle (PFA), and hip angle (HipA = PFA + α). α is the theoretical normal value of PT (tPT = −7
                          + 0.37 × PI). The P-line is defined as the line connecting the center of the acetabulum to the
                          center of the sacral end plate. The H-line is defined as the line extending from the center of
                          the acetabulum at a positive-angle tPT from the P-line.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">We measured the sacral slope (SS), PT, PI, L1-S1 lumbar
                  lordosis (LL), and PFA on all of the radiographs (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>)<a
                    href="" data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_17" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">21</span></a>. PT is defined as the angle between a vertical line and the
                  P-line. The P-line is defined as the line connecting the center of the acetabulum to the center of the
                  sacral end plate. According to Vialle et al.<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">15</span></a>,
                  the theoretical normal value of PT (tPT) depends on the PI of the subject and can be estimated with
                  use of the equation tPT = −7 + 0.37 × PI, as previously described<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">23</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">A new line, the H-line, was defined from the center of the
                  acetabulum at a positive-angle tPT from the P-line (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). When
                  the PT of the patient coincides with the tPT, the H-line will be vertical while standing, regardless
                  of the PI. The hip angle (HipA) was defined as the angle between the femur and the H-line. This can be
                  calculated with use of the following equation: HipA = PFA + tPT = PFA – 7 + 0.37 × PI. HipA is a
                  femoral flexion angle relative to the anatomical reference of the pelvis that provides a quantitative
                  assessment of the hip flexion angle, similar to a physical examination with the patient in a supine
                  position. The H-line can be considered an anatomical reference of the pelvis as it does not depend on
                  pelvic position.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Two experienced operators (1 hip surgeon [Y.K.] and 1
                  physiotherapist [H.T.]) performed the radiographic measurements. Measurements in 4 positions were
                  repeated, in a blinded fashion, for 20% of all subjects, who were selected at random. Intraobserver
                  repeatability and interobserver reproducibility were assessed via the intraclass correlation
                  coefficient, which showed an excellent agreement of 0.850 to 0.978 for interobserver reproducibility
                  (see Appendix Table I).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Spinopelvic and hip mobilities were calculated as the
                  change between the standing (i.e., standing or extension) and sitting (i.e., relaxed-seated or
                  flexed-seated) positions, indicated as ΔX<span data-id="subscript_7" class="annotation subscript"
                    style="position: unset;">standing/sitting</span> = X<span data-id="subscript_8"
                    class="annotation subscript" style="position: unset;">sitting</span> − X<span data-id="subscript_9"
                    class="annotation subscript" style="position: unset;">standing</span>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">We divided the dynamic spine-pelvis-hip motion into
                  pelvic, hip, and lumbar mobilities<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">1</span></a>.
                  Pelvic mobility was defined as the difference in SS between the standing and sitting positions
                  (ΔSS<span data-id="subscript_10" class="annotation subscript"
                    style="position: unset;">standing/sitting</span>) and was classified as stiff (ΔSS<span
                    data-id="subscript_11" class="annotation subscript"
                    style="position: unset;">standing/relaxed-seated</span> ≥ −10°), normal (−10° &gt; ΔSS<span
                    data-id="subscript_12" class="annotation subscript"
                    style="position: unset;">standing/relaxed-seated</span> &gt; −30°), or hypermobile (ΔSS<span
                    data-id="subscript_13" class="annotation subscript"
                    style="position: unset;">standing/relaxed-seated</span> ≤ −30°)<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">24</span></a>. We defined hip mobility as the difference in PFA between
                  the standing and sitting positions (ΔPFA<span data-id="subscript_14" class="annotation subscript"
                    style="position: unset;">standing/sitting</span>). Lumbar mobility was defined as the difference in
                  LL between the standing and sitting positions (ΔLL<span data-id="subscript_15"
                    class="annotation subscript" style="position: unset;">standing/sitting</span>) and was classified as
                  stiff (ΔLL<span data-id="subscript_16" class="annotation subscript"
                    style="position: unset;">standing/flexed-seated</span> &gt; −20°), flexible (−20° ≥ ΔLL<span
                    data-id="subscript_17" class="annotation subscript"
                    style="position: unset;">standing/flexed-seated</span> &gt; −40°), or hypermobile (ΔLL<span
                    data-id="subscript_18" class="annotation subscript"
                    style="position: unset;">standing/flexed-seated</span> ≤ −40°)<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_22" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">25</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">After describing the cohort in terms of baseline
                  demographics and radiographic alignments, we conducted comparisons of the radiographic measurements
                  between positions with use of the paired t test and chi-square test. We made demographic and
                  radiographic-parameter comparisons across the PI subgroups (i.e., low, medium, and high PI) with use
                  of either analysis of variance or the Kruskal-Wallis test, according to the normality of the data,
                  which was checked with use of the Shapiro-Wilk test. We applied a Tukey-Kramer correction to multiple
                  comparisons. The level of significance was set at p &lt; 0.05. We utilized MATLAB 2020b (MathWorks)
                  for the calculations. We performed a correlation analysis with use of Microsoft Excel (version 16.0)
                  to determine the Pearson correlation coefficient (r) between the parameters. Correlation was defined
                  as strong (r ≥ 0.7), moderate (0.4 &lt; r &lt; 0.7), or weak (r ≤ 0.4).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Demographic data of the asymptomatic volunteers are
                  presented in <a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>. There
                  were no significant differences in demographic data between the 3 subgroups. However, more subjects (p
                  &lt; 0.001) presented with a medium PI (52%) than a low PI (27%) or a high PI (21%), which was
                  expected.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Spinopelvic
                and Hip Alignment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">There were significant differences between some of the 3
                  subgroups (low, medium, and high PI) with respect to the LL, SS, PT, and PFA in each of the 4
                  positions (extension, standing, relaxed-seated, and flexed-seated; <a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). No significant differences were found between the subgroups
                  with respect to the HipA, nor was any difference found in the mean HipA between women (3.1° ± 6.6°)
                  and men (5.2° ± 4.7°; p = 0.07) in the standing position.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Spinopelvic and Hip Parameters in the Standing,
                          Extension, Relaxed-Seated, and Flexed-Seated Positions<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1069.69px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.670061);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Parameter</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Position</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Whole Cohort</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Subgroup</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Low
                          PI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Medium
                          PI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">High
                          PI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">L Versus
                          M</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">L Versus
                          H</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">M Versus
                          H</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PI <span
                            data-id="emphasis_40" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Standing</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39 ±
                          4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52 ±
                          4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68 ±
                          5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">LL <span
                            data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Extension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68 ±
                          10</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Standing</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60 ±
                          8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Relaxed-seated</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34 ±
                          14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27 ±
                          14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34 ±
                          14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40 ±
                          10</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Flexed-seated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−9 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−16 ±
                          9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−8 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2 ±
                          10</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PI-LL <span
                            data-id="emphasis_42" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Standing</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−6 ±
                          9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2 ±
                          8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7 ±
                          11</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">SS <span
                            data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Extension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35 ±
                          9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30 ±
                          8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36 ±
                          8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42 ±
                          6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Standing</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39 ±
                          8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32 ±
                          6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39 ±
                          6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46 ±
                          5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Relaxed-seated</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21 ±
                          9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36 ±
                          8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Flexed-seated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62 ±
                          14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62 ±
                          16</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68 ±
                          10</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">PT <span
                            data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Extension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16 ±
                          9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10 ±
                          8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15 ±
                          7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24 ±
                          8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Standing</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13 ±
                          7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8 ±
                          5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13 ±
                          5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21 ±
                          6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Relaxed-seated</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31 ±
                          9</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Flexed-seated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−9 ±
                          13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−15 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−10 ±
                          12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1 ±
                          11</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">PFA <span
                            data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Extension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−13 ±
                          9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−8 ±
                          8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−13 ±
                          8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−20 ±
                          9</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Standing</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−8 ±
                          7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−4 ±
                          7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−8 ±
                          6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−15 ±
                          6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Relaxed-seated</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69 ±
                          12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74 ±
                          13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63 ±
                          11</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Flexed-seated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">103 ±
                          12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">103 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94 ±
                          12</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">HipA <span
                            data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Extension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1 ±
                          8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1 ±
                          8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1 ±
                          8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2 ±
                          8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Standing</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 ±
                          6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 ±
                          7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 ±
                          6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3 ±
                          5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Relaxed-seated</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81 ±
                          13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81 ±
                          10</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Flexed-seated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">115 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">117 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">115 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">112 ±
                          11</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD, and p values are reported for
                        significant differences between the subgroups. L = low PI, M = medium PI, H = high PI, NS = not
                        significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Lumbar,
                Pelvic, and Hip Mobilities</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">No significant differences in ΔLL, ΔSS, or ΔPFA were found
                  between the 3 groups when moving from a standing to a relaxed-seated position or from an extension to
                  a flexed-seated position (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>). A
                  stiff pelvis was demonstrated in 51% of the cohort, and a hypermobile pelvis was demonstrated in 1%,
                  with a normal pelvis demonstrated in the remaining subjects (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>). All
                  subjects demonstrated a hypermobile lumbar spine.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Changes in Spinopelvic and Hip Parameters from the
                          Standing to Relaxed-Seated Position and the Extension to Flexed-Seated Position<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 690.491px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.877333);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Parameter</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Position</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Whole Cohort</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Subgroup</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Low
                          PI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Medium
                          PI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">High
                          PI</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;">ΔLL <span
                            data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Standing to
                          relaxed-seated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−19 ±
                          13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−18 ±
                          14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−20 ±
                          14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−20 ±
                          11</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Extension to
                          flexed-seated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−71 ±
                          12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−72 ±
                          9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−70 ±
                          14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−70 ±
                          12</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;">ΔSS <span
                            data-id="emphasis_48" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Standing to
                          relaxed-seated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−10 ±
                          9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−10 ±
                          9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−10 ±
                          9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−10 ±
                          8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Extension to
                          flexed-seated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26 ±
                          13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27 ±
                          12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27 ±
                          15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26 ±
                          11</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;">ΔPFA <span
                            data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Standing to
                          relaxed-seated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78 ±
                          10</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Extension to
                          flexed-seated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">116 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">117 ±
                          12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">116 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">114 ±
                          11</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD. NS = not significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Distribution of the Study Volunteers by Pelvic
                          Mobility and Lumbar Mobility Types<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 772px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Mobility
                          Type</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Whole Cohort</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Subgroup</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Low
                          PI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Medium
                          PI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">High
                          PI</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pelvic mobility</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Stiff (ΔSS ≥
                          −10°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46%</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Normal (−10° &gt; ΔSS
                          &gt; −30°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54%</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypermobile (ΔSS ≤
                          −30°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lumbar mobility</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Stiff (ΔLL &gt;
                          −20°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Flexible (−20° ≥ ΔLL
                          &gt; −40°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypermobile (ΔLL ≤
                          −40°)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100%</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NS</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">NS = not significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Relationship
                of PI with PFA and Lumbar, Pelvic, and Hip Mobilities</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">PI was moderately correlated with PFA<span
                    data-id="subscript_19" class="annotation subscript" style="position: unset;">extension</span> (R =
                  −0.48; p &lt; 0.0001) and PFA<span data-id="subscript_20" class="annotation subscript"
                    style="position: unset;">standing</span> (R = −0.53; p &lt; 0.0001; <a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). Similarly, PI was weakly correlated with PFA<span
                    data-id="subscript_21" class="annotation subscript" style="position: unset;">relaxed-seated</span>
                  (R = −0.37; p &lt; 0.0001) and moderately correlated with PFA<span data-id="subscript_22"
                    class="annotation subscript" style="position: unset;">flexed-seated</span> (R = −0.47; p &lt;
                  0.0001; <a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).
                  However, there was no significant correlation between PI and ΔPFA<span data-id="subscript_23"
                    class="annotation subscript" style="position: unset;">standing/relaxed-seated</span> (R = −0.062; p
                  = 0.48) or ΔPFA<span data-id="subscript_24" class="annotation subscript"
                    style="position: unset;">extension/flexed-seated</span> (R = −0.12; p = 0.18; <a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). Similarly, there was no significant correlation between PI and
                  ΔLL or ΔSS in either pair of positions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00493f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&type=jpeg&name=JBJS.23.00493f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;">Graphs showing the correlation between PI and PFA
                          in each position. The solid line represents the linear regression between the parameters, and
                          the dashed lines represent the bounds of the 95% prediction interval.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.00493f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&type=jpeg&name=JBJS.23.00493f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;">Graphs showing the correlation of PI with ΔLL,
                          ΔSS, and ΔPFA from the standing to relaxed-seated position and the extension to flexed-seated
                          position. The solid line represents the linear regression between the parameters, and the
                          dashed lines represent the bounds of the 95% prediction interval.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">This study is among the first to describe the
                  classification of spinopelvic and hip alignment and mobility based on the PI of asymptomatic
                  volunteers in 4 positions (free-standing, extension, relaxed-seated, and flexed-seated). Spinopelvic
                  and hip alignment (LL, SS, PT, and PFA) varied significantly according to the PI of the subject (low,
                  medium, or high) in each position, with many of the differences between the subgroups being
                  significant, whereas there were no significant differences in lumbar, pelvic, or hip mobility (ΔLL,
                  ΔSS, and ΔPFA) between the low, medium, and high-PI subgroups. These results support our hypothesis
                  that PI influences spinopelvic and hip parameters, including the PFA. The factors affecting
                  spinopelvic and hip alignment and mobility in patients before and after THA are of increasing
                  interest. It is important for surgeons to understand spinopelvic kinematics in order to identify
                  patients who are at a high risk for dislocation and impingement before and after THA<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_23" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">26</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The role of PI is also an important consideration in the
                  preoperative evaluation of a patient for spinal realignment surgery because PI directly influences
                  spinopelvic alignment, including PT, SS, LL, and overall sagittal spinal balance<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">27</span></a>. Previous studies have shown that PI is strongly correlated
                  with SS (r = 0.80) and LL (r = 0.60) in the standing position<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_25" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">28</span></a>. This finding is consistent with the results of the present
                  study, which also examined different positions. Furthermore, we found that high PI was associated with
                  a lower PFA (more extension of the femur relative to the P-line) and low PI was associated with a
                  higher PFA (more flexion of the femur relative to the P-line) in the standing and sitting positions
                  (<a href="" data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>). This relationship between PI and the PFA may lead to
                  compensatory changes in spinopelvic alignment. Similarly, Ike et al. reported that, for patients with
                  low-PI hips who were undergoing THA, more femoral flexion was required for sitting because the PFA in
                  such patients was high<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">29</span></a>
                  (note that the PFA measured by these authors was the complement of the definition utilized in the
                  present study).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.00493f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&type=jpeg&name=JBJS.23.00493f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;">Descriptions and diagrams of the spinopelvic and
                          hip parameters in the standing and relaxed-seated positions in a subject with low PI and a
                          subject with high PI. The solid blue line represents the H-line, the solid pink line denotes
                          the P-line, and the striped white and black line represents the line orthogonal to the sacral
                          plate. α = −7 + 0.37 × PI.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">However, the differences in PFA between individuals with
                  low PI and those with high PI depend on the PI and not on the difference in femoral flexion angle.
                  Nevertheless, the correlation between PFA and PI was only weak or moderate in all 4 positions (<a
                    href="" data-id="figure_reference_14" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>), suggesting that other variables influence this relationship.
                  The posture of the subject in each of the 4 positions may affect the variability in the PFA that is
                  not attributable to the PI. The differences in PFA should be considered during the preoperative and
                  postoperative evaluation of patients undergoing THA (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 5</a> and <a
                    href="" data-id="figure_reference_16" class="annotation figure_reference resource-reference"
                    style="position: unset;">6</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.00493f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&type=jpeg&name=JBJS.23.00493f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;">Clinical application in the standing position. The
                          lateral spinopelvic-hip radiographs of 2 study volunteers (<span data-id="strong_41"
                            class="annotation strong" style="position: unset;">Figs. 5-A and 5-D</span>) and 2 patients
                          with hip arthrosis (<span data-id="strong_42" class="annotation strong"
                            style="position: unset;">Figs. 5-B and 5-C</span>) in the standing position illustrate the
                          study measurements, with the solid pink line representing the P-line. The patient radiographs
                          were made preoperatively. The patients had the same PFA (−20°) with different PIs (48° versus
                          72°). The calculated α angle (−7 + 0.37 × PI) was 11° in <span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Fig. 5-B</span> but 9° higher, 20°, in
                          <span data-id="strong_44" class="annotation strong" style="position: unset;">Fig. 5-C</span>.
                          The calculated HipA (PFA − 7 + 0.37 × PI) was −9° in <span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Fig. 5-B</span> but 0° in <span
                            data-id="strong_46" class="annotation strong" style="position: unset;">Fig. 5-C</span>. The
                          hip alignment in the standing position was normal in both the patient with high PI (<span
                            data-id="strong_47" class="annotation strong" style="position: unset;">Fig. 5-C</span>) and
                          the volunteer with the same PI (<span data-id="strong_48" class="annotation strong"
                            style="position: unset;">Fig. 5-D</span>). However, the patient with low PI (<span
                            data-id="strong_49" class="annotation strong" style="position: unset;">Fig. 5-B</span>) had
                          hyperextended hip alignment due to compensation for lumbar kyphosis (PI − LL = 33°), whereas
                          the volunteer with the same PI had normal hip alignment (<span data-id="strong_50"
                            class="annotation strong" style="position: unset;">Fig. 5-A</span>). Although the 2 patients
                          had the same preoperative PFA, the patient with low PI had a higher risk of anterior
                          dislocation after THA as a result of the extension of the femur relative to the pelvis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.00493f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&type=jpeg&name=JBJS.23.00493f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_39" style="position: unset;">
                        <div class="content" style="position: unset;">Clinical application in the relaxed-seated
                          position. The lateral spinopelvic-hip radiographs of 2 study volunteers (<span
                            data-id="strong_51" class="annotation strong" style="position: unset;">Figs. 6-A and
                            6-D</span>) and 2 patients with hip arthrosis (<span data-id="strong_52"
                            class="annotation strong" style="position: unset;">Figs. 6-B and 6-C</span>) in the
                          relaxed-seated position illustrate the study measurements, with the solid pink line
                          representing the P-line. The patient radiographs were made preoperatively. The patients had
                          the same PFA (72°) with different PIs (36° versus 66°). The calculated α angle (−7 + 0.37 ×
                          PI) was 6° in <span data-id="strong_53" class="annotation strong"
                            style="position: unset;">Fig. 6-B</span> but 11° higher, 17°, in <span data-id="strong_54"
                            class="annotation strong" style="position: unset;">Fig. 6-C</span>. The calculated HipA (PFA
                          – 7 + 0.37 × PI) was 78° in <span data-id="strong_55" class="annotation strong"
                            style="position: unset;">Fig. 6-B</span> but 89° in <span data-id="strong_56"
                            class="annotation strong" style="position: unset;">Fig. 6-C</span>. The hip alignment in the
                          relaxed-seated position was normal in both the patient with low PI (<span data-id="strong_57"
                            class="annotation strong" style="position: unset;">Fig. 6-B</span>) and the volunteer with
                          the same PI (<span data-id="strong_58" class="annotation strong" style="position: unset;">Fig.
                            6-A</span>). However, the patient with high PI (<span data-id="strong_59"
                            class="annotation strong" style="position: unset;">Fig. 6-C</span>) had hyperflexed hip
                          alignment due to hip hypermobility, whereas the volunteer with the same PI had normal hip
                          alignment (<span data-id="strong_60" class="annotation strong" style="position: unset;">Fig.
                            6-D</span>). Although the 2 patients had the same preoperative PFA, the patient with high PI
                          had a higher risk of posterior dislocation after THA as a result of the flexion of the femur
                          relative to the pelvis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">We hypothesized that the PFA is directly influenced by the
                  PI, since the PFA is the angle of the femur relative to the pelvis<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">29</span></a>. HipA is a new angle that allows for the quantification of
                  hip mobility while accounting for the PI. This was confirmed by the fact that the HipA did not vary
                  with the PI, whereas the PFA did (<a href="" data-id="figure_reference_17"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).
                  Therefore, the HipA is independent of the PI and can be evaluated on its own, whereas the PFA should
                  be considered according to the PI of the patient and may have a different interpretation in different
                  patients. Surgeons should consider the newly developed radiographic femoral flexion angle (HipA)
                  relative to the new calculated reference line (the H-line), which is not affected by the PI of the
                  individual before or after THA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">A recent study demonstrated that PI did not show any
                  significant correlation with lumbar or pelvic mobility in patients undergoing THA<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">26</span></a>. However, that study preoperatively evaluated patients with
                  osteoarthritis and hip contracture, which may affect lumbar and pelvic mobility<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">1</span></a>, and did not evaluate hip mobility. The present study is the
                  first, to our knowledge, to evaluate the spinopelvic and hip measurements of asymptomatic volunteers
                  in order to clarify the role of PI in lumbar, pelvic, and hip mobilities. Our results confirmed that
                  there were no significant differences in lumbar, pelvic, or hip mobility between the low, medium, and
                  high-PI subgroups. This finding suggests that the functional hip’s mobility cone for daily living
                  activities and the risk of prosthetic impingement and edge loading are constant, regardless of the PI.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Interestingly, 51% of the volunteers in our study had a
                  stiff pelvis. Patients with spinopelvic stiffness when moving from a standing to a sitting position
                  are at a high risk for hip dislocation after THA<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_30"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">30</span></a>. Spinopelvic stiffness is a well-established parameter that
                  can be measured with use of standing and sitting lateral radiographs<a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">30</span></a>. However, a recent study found that a ∆SS<span
                    data-id="subscript_25" class="annotation subscript"
                    style="position: unset;">standing/relaxed-seated</span> of ≥ −10° was not correlated with a stiff
                  spine and overpredicted its presence<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">31</span></a>.
                  Furthermore, we previously reported that improvements in hip mobility were associated with decreased
                  postoperative lumbar and pelvic mobility<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">1</span></a>. The
                  findings of these studies suggest that, in the present study, the presence of a stiff pelvis in half
                  of a normal population without hip disease was the result of good hip flexion. Classifications of
                  pelvic mobility warrant future studies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Preoperative identification of abnormalities in
                  spinopelvic and hip alignment and mobility can lead to patient-specific alterations in the position of
                  the component to insure against impingement and mechanical instability following THA<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">3</span></a>. Furthermore, postoperative evaluation of these
                  abnormalities in patients with impingement and late dislocation following THA can clarify the optimal
                  surgical treatment<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_35" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">32</span></a>. Hip hypermobility is a risk factor for impingement and
                  dislocation after THA<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_36" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_36" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">32</span></a>,
                  but previous studies, such as the one by Bodner et al.<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">3</span></a>,
                  have defined extension and flexion with use of the PFA. The results of the present study are supported
                  by the findings of previous studies<a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_38" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">33</span></a>, including the findings reported by Ike et al.<a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">29</span></a>, who demonstrated that the combined sagittal index (CSI;
                  i.e., the anteinclination of the cup + the PFA) was stratified by PI into 10°-stepped ranges for the
                  standing and sitting positions. The present study showed that the difference in the PFA due to the PI
                  should be considered when evaluating the spine-pelvis-hip alignment preoperatively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">The present study has several limitations. First, it
                  included only volunteers and did not include any patient data, which was beyond the scope of this
                  work. Future studies should include patients with spinal and hip disorders, especially because
                  degenerative disease of the hip and lower back is a common comorbidity. Second, 69% of the volunteers
                  were female, which might have resulted in a sex distribution bias. The impact of sex on spinopelvic
                  and hip alignment and mobility requires future study. Third, the study volunteers had a low BMI
                  relative to the general population, even if 77% of the volunteers were of a healthy weight between
                  18.5 and 24.9. Fourth, uncertainty regarding the angles of interest was evaluated by repeating the
                  measurements on the same set of radiographs. Although the subjects were positioned correctly, repeated
                  radiographs could have confirmed that. However, the reproducibility of the radiographs was not
                  addressed because doing so would have increased the radiation exposure of the subjects. Fifth,
                  although the subjects were placed orthogonal to the x-ray plane, the results showed some degree of
                  oblique projection, which affected the measurements. Three-dimensional measurements of biplanar
                  radiographs would address this issue and would allow for the assessment of uncertainty introduced by
                  subject misalignment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">This study found that lumbar (∆LL), pelvic (∆SS), and hip
                  (∆PFA) mobilities were constant regardless of the PI in each functional position. However, spinopelvic
                  and hip parameters, including the LL, SS, and PFA, were affected by PI and should be corrected
                  according to the PI in a functional position. On the basis of these results, we suggest 3 specific
                  recommendations for surgeons: (1) PI should be considered in preoperative planning and postoperative
                  evaluation of spinopelvic and hip alignment in functional positions because of the difference in
                  normal values between individuals with low and high PI; (2) the PFA should be considered key to
                  determining the optimal cup orientation and CSI preoperatively<a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">32</span></a>; and (3) the PFA should be corrected according to the PI of
                  the patient, which determines the patient’s specific functional safe zone. The optimal cup position
                  relative to the PI of the individual should be addressed in future research.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I36" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I36</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank Dr. Aidin Eslam Pour (Yale University) for
                  English-language editing.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Kim Y</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Vergari C</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Shimizu Y</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Tokuyasu H</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Takemoto M</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The Impact of Hip Mobility on
                  Lumbar and Pelvic Mobility before and after Total Hip Arthroplasty</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">J Clin Med.</span> 2022 Dec
                31;12(1):331.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Impact%20of%20Hip%20Mobility%20on%20Lumbar%20and%20Pelvic%20Mobility%20before%20and%20after%20Total%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22Y%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Heckmann N</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> McKnight B</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Stefl M</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Trasolini NA</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Ike H</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Dorr LD</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Late dislocation following total
                  hip arthroplasty: Spinopelvic imbalance as a causative factor</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2018 Nov
                7;100(21):1845-53.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1849236"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Bodner RJ</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Tezuka T</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Heckmann N</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Chung B</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Jadidi S</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">The Dorr Classification for
                  Spinopelvic Functional Safe Component Positioning in Total Hip Replacement: A Primer for All</span>.
                <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Journal of Orthopaedic
                  Experience & Innovation.</span> 2022 Nov 18.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Dorr%20Classification%20for%20Spinopelvic%20Functional%20Safe%20Component%20Positioning%20in%20Total%20Hip%20Replacement%3A%20A%20Primer%20for%20All&as_occt=title&as_sauthors=%20%22RJ%20Bodner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Vigdorchik JM</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Sharma AK</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Madurawe CS</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Pierrepont JW</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Dennis DA</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Shimmin AJ</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Prevalence of Risk Factors for
                  Adverse Spinopelvic Mobility Among Patients Undergoing Total Hip Arthroplasty</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2021
                Jul;36(7):2371-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20Risk%20Factors%20for%20Adverse%20Spinopelvic%20Mobility%20Among%20Patients%20Undergoing%20Total%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22JM%20Vigdorchik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Tezuka T</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Heckmann ND</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Bodner RJ</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Dorr LD</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Functional Safe Zone Is Superior
                  to the Lewinnek Safe Zone for Total Hip Arthroplasty: Why the Lewinnek Safe Zone Is Not Always
                  Predictive of Stability</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2019 Jan;34(1):3-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Functional%20Safe%20Zone%20Is%20Superior%20to%20the%20Lewinnek%20Safe%20Zone%20for%20Total%20Hip%20Arthroplasty%3A%20Why%20the%20Lewinnek%20Safe%20Zone%20Is%20Not%20Always%20Predictive%20of%20Stability&as_occt=title&as_sauthors=%20%22T%20Tezuka%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Legaye J</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Duval-Beaupère G</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Hecquet J</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Marty C</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Pelvic incidence: a fundamental
                  pelvic parameter for three-dimensional regulation of spinal sagittal curves</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Eur Spine J.</span>
                1998;7(2):99-103.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20incidence%3A%20a%20fundamental%20pelvic%20parameter%20for%20three-dimensional%20regulation%20of%20spinal%20sagittal%20curves&as_occt=title&as_sauthors=%20%22J%20Legaye%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Boulay C</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Tardieu C</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Hecquet J</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Benaim C</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Mouilleseaux B</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Marty C</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Prat-Pradal D</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Legaye J</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Duval-Beaupère G</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Pélissier J</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Sagittal alignment of spine and
                  pelvis regulated by pelvic incidence: standard values and prediction of lordosis</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2006
                Apr;15(4):415-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sagittal%20alignment%20of%20spine%20and%20pelvis%20regulated%20by%20pelvic%20incidence%3A%20standard%20values%20and%20prediction%20of%20lordosis&as_occt=title&as_sauthors=%20%22C%20Boulay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Kim Y</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Pour AE</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Lazennec JY</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">How do global sagittal alignment
                  and posture change after total hip arthroplasty?</span><span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2020
                Feb;44(2):267-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20do%20global%20sagittal%20alignment%20and%20posture%20change%20after%20total%20hip%20arthroplasty%3F&as_occt=title&as_sauthors=%20%22Y%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Roussouly P</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Pinheiro-Franco JL</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Sagittal parameters of the
                  spine: biomechanical approach</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2011 Sep;20(Suppl 5)(Suppl 5):578-85.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sagittal%20parameters%20of%20the%20spine%3A%20biomechanical%20approach&as_occt=title&as_sauthors=%20%22P%20Roussouly%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> DelSole EM</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Vigdorchik JM</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Schwarzkopf R</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Errico TJ</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Buckland AJ</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Total Hip Arthroplasty in the
                  Spinal Deformity Population: Does Degree of Sagittal Deformity Affect Rates of Safe Zone Placement,
                  Instability, or Revision?</span><span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2017 Jun;32(6):1910-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20Hip%20Arthroplasty%20in%20the%20Spinal%20Deformity%20Population%3A%20Does%20Degree%20of%20Sagittal%20Deformity%20Affect%20Rates%20of%20Safe%20Zone%20Placement%2C%20Instability%2C%20or%20Revision%3F&as_occt=title&as_sauthors=%20%22EM%20DelSole%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Dagneaux L</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Marouby S</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Maillot C</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Canovas F</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Rivière C</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Dual mobility device reduces
                  the risk of prosthetic hip instability for patients with degenerated spine: A case-control
                  study</span>. <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Orthop
                  Traumatol Surg Res.</span> 2019 May;105(3):461-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dual%20mobility%20device%20reduces%20the%20risk%20of%20prosthetic%20hip%20instability%20for%20patients%20with%20degenerated%20spine%3A%20A%20case-control%20study&as_occt=title&as_sauthors=%20%22L%20Dagneaux%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Snijders TE</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Schlösser TPC</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Heckmann ND</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Tezuka T</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Castelein RM</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Stevenson RP</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Weinans H</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> de Gast A</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Dorr LD</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The Effect of Functional Pelvic
                  Tilt on the Three-Dimensional Acetabular Cup Orientation in Total Hip Arthroplasty
                  Dislocations</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2021 Jun;36(6):2184-2188.e1.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Effect%20of%20Functional%20Pelvic%20Tilt%20on%20the%20Three-Dimensional%20Acetabular%20Cup%20Orientation%20in%20Total%20Hip%20Arthroplasty%20Dislocations&as_occt=title&as_sauthors=%20%22TE%20Snijders%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Furuhashi H</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Yamato Y</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Hoshino H</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Shimizu Y</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Hasegawa T</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Yoshida G</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Banno T</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Arima H</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Oe S</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Ushirozako H</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Matsuyama Y</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Dislocation rate and its risk
                  factors in total hip arthroplasty with concurrent extensive spinal corrective fusion with pelvic
                  fixation for adult spinal deformity</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Eur J Orthop Surg Traumatol.</span> 2021 Feb;31(2):283-90.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dislocation%20rate%20and%20its%20risk%20factors%20in%20total%20hip%20arthroplasty%20with%20concurrent%20extensive%20spinal%20corrective%20fusion%20with%20pelvic%20fixation%20for%20adult%20spinal%20deformity&as_occt=title&as_sauthors=%20%22H%20Furuhashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> van der Gronde BATD</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Schlösser TPC</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> van Erp JHJ</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Snijders TE</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Castelein RM</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Weinans H</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> de Gast A</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Current Evidence for
                  Spinopelvic Characteristics Influencing Total Hip Arthroplasty Dislocation Risk</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">JBJS Rev.</span> 2022 Aug
                23;10(8).</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20Evidence%20for%20Spinopelvic%20Characteristics%20Influencing%20Total%20Hip%20Arthroplasty%20Dislocation%20Risk&as_occt=title&as_sauthors=%20%22BATD%20van%20der%20Gronde%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Vialle R</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Levassor N</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Rillardon L</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Templier A</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Skalli W</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Guigui P</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Radiographic analysis of the
                  sagittal alignment and balance of the spine in asymptomatic subjects</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2005 Feb;87(2):260-7.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=951917" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Thelen T</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Thelen P</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Demezon H</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Aunoble S</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Le Huec JC</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Normative 3D acetabular
                  orientation measurements by the low-dose EOS imaging system in 102 asymptomatic subjects in standing
                  position: Analyses by side, gender, pelvic incidence and reproducibility</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Orthop Traumatol Surg
                  Res.</span> 2017 Apr;103(2):209-15.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Normative%203D%20acetabular%20orientation%20measurements%20by%20the%20low-dose%20EOS%20imaging%20system%20in%20102%20asymptomatic%20subjects%20in%20standing%20position%3A%20Analyses%20by%20side%2C%20gender%2C%20pelvic%20incidence%20and%20reproducibility&as_occt=title&as_sauthors=%20%22T%20Thelen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Vergari C</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Kim Y</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Takemoto M</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Shimizu Y</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Tanaka C</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Fukae S</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Sagittal alignment in patients
                  with flexion contracture of the hip before and after total hip arthroplasty</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 2023 Jun;143(6):3587-96.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sagittal%20alignment%20in%20patients%20with%20flexion%20contracture%20of%20the%20hip%20before%20and%20after%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22C%20Vergari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Lazennec JY</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Clark IC</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Folinais D</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Tahar IN</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Pour AE</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">What is the Impact of a Spinal
                  Fusion on Acetabular Implant Orientation in Functional Standing and Sitting Positions?</span><span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2017
                Oct;32(10):3184-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20is%20the%20Impact%20of%20a%20Spinal%20Fusion%20on%20Acetabular%20Implant%20Orientation%20in%20Functional%20Standing%20and%20Sitting%20Positions%3F&as_occt=title&as_sauthors=%20%22JY%20Lazennec%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Innmann MM</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Merle C</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Phan P</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Beaulé PE</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Grammatopoulos G</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Differences in Spinopelvic
                  Characteristics Between Hip Osteoarthritis Patients and Controls</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2021
                Aug;36(8):2808-16.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Differences%20in%20Spinopelvic%20Characteristics%20Between%20Hip%20Osteoarthritis%20Patients%20and%20Controls&as_occt=title&as_sauthors=%20%22MM%20Innmann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Esposito CI</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Miller TT</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Barlow BT</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Wright TM</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Padgett DE</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Jerabek SA</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Mayman DJ</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Does Degenerative Lumbar Spine
                  Disease Influence Femoroacetabular Flexion in Patients Undergoing Total Hip Arthroplasty?</span><span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2016 Aug;474(8):1788-97.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20Degenerative%20Lumbar%20Spine%20Disease%20Influence%20Femoroacetabular%20Flexion%20in%20Patients%20Undergoing%20Total%20Hip%20Arthroplasty%3F&as_occt=title&as_sauthors=%20%22CI%20Esposito%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Boulay C</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Bollini G</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Legaye J</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Tardieu C</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Prat-Pradal D</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Chabrol B</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Jouve JL</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Duval-Beaupère G</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Pélissier J</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Pelvic incidence: a predictive
                  factor for three-dimensional acetabular orientation-a preliminary study</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Anat Res Int.</span>
                2014;2014(594650):594650.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20incidence%3A%20a%20predictive%20factor%20for%20three-dimensional%20acetabular%20orientation-a%20preliminary%20study&as_occt=title&as_sauthors=%20%22C%20Boulay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Liu C</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Hu F</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Li Z</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Zhang X</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Anterior Pelvic Plane: A
                  Potentially Useful Pelvic Anatomical Reference Plane in Assessing the Patients’ Ideal Pelvic
                  Parameters Without the Influence of Spinal Sagittal Deformity</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Global Spine J.</span> 2022
                May;12(4):567-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20Pelvic%20Plane%3A%20A%20Potentially%20Useful%20Pelvic%20Anatomical%20Reference%20Plane%20in%20Assessing%20the%20Patients%E2%80%99%20Ideal%20Pelvic%20Parameters%20Without%20the%20Influence%20of%20Spinal%20Sagittal%20Deformity&as_occt=title&as_sauthors=%20%22C%20Liu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Aurouer N</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Obeid I</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Gille O</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Pointillart V</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Vital JM</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Computerized preoperative
                  planning for correction of sagittal deformity of the spine</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">Surg Radiol Anat.</span> 2009
                Dec;31(10):781-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Computerized%20preoperative%20planning%20for%20correction%20of%20sagittal%20deformity%20of%20the%20spine&as_occt=title&as_sauthors=%20%22N%20Aurouer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Stefl M</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Lundergan W</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Heckmann N</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> McKnight B</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Ike H</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Murgai R</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Dorr LD</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Spinopelvic mobility and
                  acetabular component position for total hip arthroplasty</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2017 Jan;99-B(1)(Supple
                A):37-45.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spinopelvic%20mobility%20and%20acetabular%20component%20position%20for%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22M%20Stefl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Vigdorchik JM</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Sharma AK</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Dennis DA</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Walter LR</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Pierrepont JW</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Shimmin AJ</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The Majority of Total Hip
                  Arthroplasty Patients With a Stiff Spine Do Not Have an Instrumented Fusion</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2020
                Jun;35(6S):S252-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Majority%20of%20Total%20Hip%20Arthroplasty%20Patients%20With%20a%20Stiff%20Spine%20Do%20Not%20Have%20an%20Instrumented%20Fusion&as_occt=title&as_sauthors=%20%22JM%20Vigdorchik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Kleeman-Forsthuber L</span>,
                <span data-id="annotation_151" class="annotation" style="position: unset;"> Vigdorchik JM</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Pierrepont JW</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Dennis DA</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Pelvic incidence significance
                  relative to spinopelvic risk factors for total hip arthroplasty instability</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2022
                Mar;104-B(3):352-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20incidence%20significance%20relative%20to%20spinopelvic%20risk%20factors%20for%20total%20hip%20arthroplasty%20instability&as_occt=title&as_sauthors=%20%22L%20Kleeman-Forsthuber%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Mehta VA</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Amin A</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Omeis I</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Gokaslan ZL</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Gottfried ON</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Implications of spinopelvic
                  alignment for the spine surgeon</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Neurosurgery.</span> 2012 Mar;70(3):707-21.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Implications%20of%20spinopelvic%20alignment%20for%20the%20spine%20surgeon&as_occt=title&as_sauthors=%20%22VA%20Mehta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Roussouly P</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Gollogly S</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Berthonnaud E</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Labelle H</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Weidenbaum M</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Sagittal alignment of the spine
                  and pelvis in the presence of L5-S1 isthmic lysis and low-grade spondylolisthesis</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2006 Oct 1;31(21):2484-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sagittal%20alignment%20of%20the%20spine%20and%20pelvis%20in%20the%20presence%20of%20L5-S1%20isthmic%20lysis%20and%20low-grade%20spondylolisthesis&as_occt=title&as_sauthors=%20%22P%20Roussouly%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Ike H</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Bodner RJ</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Lundergan W</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Saigusa Y</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Dorr LD</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">The Effects of Pelvic Incidence
                  in the Functional Anatomy of the Hip Joint</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2020 Jun
                3;102(11):991-9.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2376038"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Ike H</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Dorr LD</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Trasolini N</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Stefl M</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> McKnight B</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Heckmann N</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Spine-Pelvis-Hip Relationship
                  in the Functioning of a Total Hip Replacement</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2018 Sep
                19;100(18):1606-15.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1795390"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Sharma AK</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Grammatopoulos G</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Pierrepont JW</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Madurawe CS</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Innmann MM</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Vigdorchik JM</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Shimmin AJ</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Sacral Slope Change From
                  Standing to Relaxed-Seated Grossly Overpredicts the Presence of a Stiff Spine</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2023
                Apr;38(4):713-718.e1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sacral%20Slope%20Change%20From%20Standing%20to%20Relaxed-Seated%20Grossly%20Overpredicts%20the%20Presence%20of%20a%20Stiff%20Spine&as_occt=title&as_sauthors=%20%22AK%20Sharma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Grammatopoulos G</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Falsetto A</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Sanders E</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Weishorn J</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Gill HS</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Beaulé PE</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Innmann MM</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Merle C</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Integrating the Combined
                  Sagittal Index Reduces the Risk of Dislocation Following Total Hip Replacement</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2022 Mar 2;104(5):397-411.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=3105964" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Kanawade V</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Dorr LD</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Wan Z</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Predictability of Acetabular
                  Component Angular Change with Postural Shift from Standing to Sitting Position</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2014 Jun 18;96(12):978-86.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1213936" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&type=supplement&name=12558"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): 287</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00493</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 23, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_34"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Kyoto City
                            Hospital, Kyoto, Japan</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&type=pdf&name=JBJS.23.00493.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&type=pdf&name=JBJS.23.00493.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_39"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_40" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I35"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I35</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=6ef01fe9-eb8d-4181-b4b6-fe80a74b1e28&type=zip&name=JBJS.23.00493.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Youngwoo Kim, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:woochan76@hotmail.co.jp" class=""
                    style="position: unset;">woochan76@hotmail.co.jp</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8450-4120" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8450-4120</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Claudio Vergari, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hiroyuki Tokuyasu, RPT<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yu Shimizu, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7049-2405" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7049-2405</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mitsuru Takemoto, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3345-7336" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3345-7336</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Kyoto City Hospital, Kyoto, Japan</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Institut de Biomécanique Humaine Georges Charpak, Arts et Métiers Institute of
                Technology, Université Sorbonne Paris Nord, Paris, France</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Rehabilitation, Kyoto City Hospital, Kyoto, Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 31341.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
