import type { GraphQLTaggedNode, Variables } from "relay-runtime";
import { getSentryInstance } from "./lib/sentry";
export const captureErrorToSentry = (
	error: Error,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	extraData: Record<string, any>,
	title?: string,
) => {
	const captureException = getSentryInstance().captureException;

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	captureException(error, (scope: any) => {
		scope.setExtras(extraData);
		if (title) {
			scope.setTransactionName(title);
		}
		return scope;
	});
};

export const logApiErrors = (
	query: GraphQLTaggedNode,
	error: unknown,
	variables?: Variables,
) => {
	const captureException = getSentryInstance().captureException;
	captureException({
		serverError: JSON.stringify(error),
		query: JSON.stringify(query),
		variables: variables ? JSON.stringify(variables) : null,
	});
};
