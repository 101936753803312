/* -----------------Child components--------------- */
import { Box, ImagesCarousel } from "@memorang/ui";
import ChoicesList from "../../choices/ChoicesList";

/* -----------------Helpers & Hooks--------------- */
import useSessionStore from "../../../hooks/useSessionStore";

import { newEvents } from "@constants/tracking";
import { isWeb } from "@helpers/platform";
import useAnalytics from "@hooks/useAnalytics";
import { useAppStore } from "@hooks/useAppStore";
import { useGlobalStore } from "@hooks/useGlobalStore";
/* -----------------Types--------------- */
import { QuestionVariant, type SessionChildItem } from "@memorang/types";
import { useDevice } from "@memorang/ui";
import SnackBarCustom from "components/CustomSnackbar/CustomSnackbar";
import { useState } from "react";
import { Caption, Portal } from "react-native-paper";
import { sessionMaxWidth } from "../../../constants";
import HintContainer from "../../../containers/HintContainer";
import { StemContainer } from "../../StemContainer";

interface Props {
	item: SessionChildItem;
}

const QuestionItem = ({ item }: Props) => {
	const {
		stem,
		children,
		variant,
		id,
		answers,
		highlight,
		crossOut,
		video,
		maxChoiceCount,
		parentStem,
		parentMedia,
		media,
	} = item;
	const multiSelect = variant === QuestionVariant.sata;

	const { isMobile } = useDevice();
	const { explicitCallTrackCustomEvent } = useAnalytics();

	const settingsEnabledFeatures = useGlobalStore(
		(state) => state.settingsEnabledFeatures,
	);

	const updateItemStates = useSessionStore((state) => state.updateItemStates);
	const isImageChallengeApp = useAppStore(
		(state) => state.app.isImageChallengeApp,
	);
	const sessionView = useAppStore((state) => state.sessionView);
	const showCrossOut = sessionView?.showCrossOut;
	const updateItemAttempts = useSessionStore(
		(state) => state.updateItemAttempts,
	);
	const itemStates = useSessionStore((state) => state.itemStates);
	const attempts = useSessionStore((state) => state.attempts);

	const isSummativeTest = useSessionStore((state) => state.isSummativeTest);
	const inReviewMode = useSessionStore((state) => state.inReviewMode);
	const blockStates = useSessionStore((state) => state.blockStates);
	const currentBlockIndex = useSessionStore((state) => state.currentBlockIndex);
	const sessionItems = useSessionStore((state) => state.sessionItems);

	const [showMaxChoiceError, setShowMaxChoiceError] = useState(false);
	const updateHighlightedItems = useSessionStore(
		(state) => state.updateHighlightedItems,
	);
	const currentBlock = sessionItems[currentBlockIndex];
	const currentBlockState = blockStates.get(currentBlock?.id);

	const currentAttempt = attempts?.get(id);
	const highlightedHtml =
		highlight || currentBlockState?.highlightedItems?.get(id);

	const selectedChoiceIds = Object.keys(
		attempts?.get(id)?.choiceAttempts || {},
	);

	const correctAnswerIds = Object.keys(
		currentAttempt?.choiceAttempts || {},
	).filter((choiceId) => currentAttempt?.choiceAttempts[choiceId]?.correct);

	const crossedOutChoiceIds = isImageChallengeApp
		? Object.keys(currentAttempt?.choiceAttempts || {}).filter(
				(choiceId) => currentAttempt?.choiceAttempts[choiceId]?.crossOut,
			)
		: itemStates?.get(id)?.crossedOutChoiceIds || crossOut;

	const isAnswered = isImageChallengeApp
		? currentAttempt?.answered
		: itemStates?.get(id)?.answered ||
			(answers != null && (isSummativeTest ? inReviewMode : true));

	const handleSelectChoice = (choiceId: string, crossingOut?: boolean) => {
		explicitCallTrackCustomEvent({
			eventName: crossingOut
				? newEvents.sessionItemCrossedOut
				: newEvents.sessionItemOptionSelected,
			choiceId,
			itemId: id!,
			sessionId: id,
		});
		if (variant === "SATA" && maxChoiceCount) {
			if (
				selectedChoiceIds &&
				selectedChoiceIds.length >= maxChoiceCount &&
				selectedChoiceIds.indexOf(choiceId) === -1
			) {
				setShowMaxChoiceError(true);
				return;
			}
		}

		if (isImageChallengeApp) {
			updateItemAttempts({
				itemId: id,
				selectedChoiceId: choiceId,
				crossOut: crossingOut,
			});
		}
		// trackCustomEvent({
		//   eventName: crossingOut ? events.crossedOutAnswer : events.answerSelected,
		//   choiceId,
		//   multiSelect: String(multiSelect),
		//   itemId: id,
		//   variant,
		//   stem,
		// });
		else {
			updateItemStates({
				itemId: id,
				selectedChoiceId: choiceId,
				multiSelect,
				crossOut: crossingOut,
			});
		}
	};

	// TODO: Instructions different based on type
	// Should come from config and/or backend and/or schema?
	const instructions = "Select the best choice";
	const Instructions = ({ text }: { text: string }) => (
		<Caption style={{ paddingLeft: 0 }}>{text}</Caption>
	);

	const handleStoreHtml = (html: string) => {
		updateHighlightedItems(id, html);
	};

	const showHint =
		!(isSummativeTest || isAnswered) && settingsEnabledFeatures.hint;

	const handleTrackImageClick = (imageUrl: string) => {
		explicitCallTrackCustomEvent({
			eventName: newEvents.sessionItemImageClicked,
			imageUrl,
			itemId: id,
			sessionId: id,
		});
	};

	return (
		<Box height="100%" gap={16}>
			<Box paddingHorizontal={16}>
				{parentMedia?.length ? (
					<ImagesCarousel
						images={parentMedia}
						handleTrackImageClick={handleTrackImageClick}
					/>
				) : null}
			</Box>
			<StemContainer
				stem={stem}
				highlightedHtml={highlightedHtml}
				video={video}
				parentStem={parentStem}
				key={id}
				handleTrackImageClick={handleTrackImageClick}
				media={media}
				handleStoreHtml={handleStoreHtml}
			/>
			<Box>
				{!(multiSelect || isImageChallengeApp) && (
					<Box paddingHorizontal={isWeb && !isMobile ? 0 : 16}>
						<Instructions text={instructions} />
					</Box>
				)}
				<Box
					paddingHorizontal={isSummativeTest || (isWeb && !isMobile) ? 0 : 16}
					maxWidth={isSummativeTest ? sessionMaxWidth.summative : 960}
				>
					<ChoicesList
						choices={children!}
						useCardWrapper={!isSummativeTest}
						showCrossOut={!isSummativeTest && showCrossOut}
						isAnswered={isAnswered}
						selectedChoiceIds={selectedChoiceIds}
						multiSelect={multiSelect}
						itemId={id}
						correctAnswerIds={correctAnswerIds}
						handleSelectChoice={handleSelectChoice}
						crossedOutChoiceIds={crossedOutChoiceIds}
						isImageChallengeApp={isImageChallengeApp}
					/>
				</Box>
				<Box marginTop={16} marginHorizontal={isMobile ? 16 : 0}>
					{showHint && <HintContainer key={item.id} item={item} />}
				</Box>
			</Box>
			{showMaxChoiceError && (
				<Portal>
					<SnackBarCustom
						handleDismiss={() => setShowMaxChoiceError(false)}
						visible={showMaxChoiceError}
						duration={2000}
						message={`Maximum limit reached. You can only select up to ${maxChoiceCount} answers.`}
					/>
				</Portal>
			)}
		</Box>
	);
};

export default QuestionItem;
