import { getBrightcoveVideoUrl } from "@helpers/mocked-helpers";

type VideoContent = {
	id: number;
	title: string;
	description: string;
	type: string;
	imageUri: string;
	videoUri: string;
};

const getMockedVideo = (
	video: Omit<VideoContent, "type" | "imageUri" | "videoUri">,
) => ({
	...video,
	type: "video",
	imageUri: "",
	videoUri: getBrightcoveVideoUrl(video.id),
});

export const mockedVideos = [
	{
		title: "Revision Risk Calculator",
		data: [
			[
				getMockedVideo({
					id: 6225652219001,
					title: "Revision Risk Calculator",
					description:
						"Demonstration of how the Revision Risk Calculator can be used in the clinic setting to estimate the patient-specific risk of a subsequent revision surgical procedure following ACL reconstruction.",
				}),
			],
		],
	},
	{
		title: "ACL surgery after primary double-bundle ACL reconstruction",
		data: [
			[
				getMockedVideo({
					id: 3118375623001,
					title: "Revision ACL Surgery",
					description:
						"Video demonstrating revision ACL surgery after primary double-bundle ACL reconstruction. AM = anteromedial, PL = posterolateral, AP = anteroposterior, and PA = posteroanterior.",
				}),
			],
		],
	},
	{
		title: "Constructing an Osseointegrated Prosthetic Leg",
		data: [
			[
				getMockedVideo({
					id: 6338403713112,
					title: "Introduction, references, background, overview",
					description: "Video 1",
				}),
				getMockedVideo({
					id: 6332749920112,
					title: "Key steps of the surgery",
					description: "Video 2",
				}),
				getMockedVideo({
					id: 6332748261112,
					title: "Step 1 team evaluation",
					description: "Video 3",
				}),
				getMockedVideo({
					id: 6332749253112,
					title: "Step 2 implant inspection",
					description: "Video 4",
				}),
				getMockedVideo({
					id: 6332747972112,
					title: "Step 3 temporary prosthesis",
					description: "Video 5",
				}),
				getMockedVideo({
					id: 6332748752112,
					title: "Step 4 quality check",
					description: "Video 6",
				}),
				getMockedVideo({
					id: 6332748466112,
					title: "Step 5 bench alignment",
					description: "Video 7",
				}),
				getMockedVideo({
					id: 6332749374112,
					title: "Step 6 static alignment",
					description: "Video 8",
				}),
				getMockedVideo({
					id: 6332750815112,
					title: "Step 7 initial dynamic alignment",
					description: "Video 9",
				}),
				getMockedVideo({
					id: 6332750723112,
					title: "Step 8 continued care",
					description: "Video 10",
				}),
				getMockedVideo({
					id: 6332749566112,
					title: "Risks",
					description: "Video 11",
				}),
				getMockedVideo({
					id: 6338402638112,
					title: "Outcomes",
					description: "Video 12",
				}),
				getMockedVideo({
					id: 6332749657112,
					title: "Conclusions, pearls, and pitfalls",
					description: "Video 13",
				}),
				getMockedVideo({
					id: 6332750731112,
					title: "References",
					description: "Video 14",
				}),
			],
		],
	},
	{
		title: "TERT for patellar instability",
		data: [
			[
				getMockedVideo({
					id: 6254202494001,
					title: "TERT for patellar instability",
					description:
						"The TERT for patellar instability. The video provides an explanation of the test and demonstrates a positive test in 8 patients.",
				}),
			],
		],
	},
	{
		title: "Open Excision of Dorsal Wrist Ganglion",
		data: [
			[
				getMockedVideo({
					id: 6317560222112,
					title: "Introduction and background",
					description: "Video 1",
				}),
				getMockedVideo({
					id: 6317560525112,
					title: "Overview of procedure",
					description: "Video 2",
				}),
				getMockedVideo({
					id: 6317558568112,
					title: "Planning, positioning, and exposure",
					description: "Video 3",
				}),
				getMockedVideo({
					id: 6317561124112,
					title: "Incision",
					description: "Video 4",
				}),
				getMockedVideo({
					id: 6317561864112,
					title: "Deep dissection",
					description: "Video 5",
				}),
				getMockedVideo({
					id: 6317560199112,
					title: "Cyst visualization",
					description: "Video 6",
				}),
				getMockedVideo({
					id: 6317559994112,
					title: "Cyst removal",
					description: "Video 7",
				}),
				getMockedVideo({
					id: 6317562472112,
					title: "Inspection of joint",
					description: "Video 8",
				}),
				getMockedVideo({
					id: 6317561595112,
					title: "Closure",
					description: "Video 9",
				}),
				getMockedVideo({
					id: 6317563665112,
					title: "Results and conclusions",
					description: "Video 10",
				}),
			],
		],
	},
];
