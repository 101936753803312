/* ----------------- Globals --------------- */
import { getSummativeTestAlertConfig } from "@features/dashboard";
import { emptyStates } from "@features/dashboard";
import { useGlobalStore } from "@hooks/useGlobalStore";
import useDetailedListTopics from "../../hooks/useDetailedListTopics";

/* ----------------- UI Components --------------- */
import {
	AlertStandard,
	Box,
	CustomListSubheader,
	EmptyState,
	Loader,
	ScrollContainer,
} from "@memorang/ui";
import DetailedListTopicsTable from "components/DetailedListTopicsTable";
import { CustomAppbar } from "components/index";

/* ----------------- Router --------------- */
import { useLocalSearchParams, useRouter } from "expo-router";

/* ----------------- Assets --------------- */
import { ProgressEmptySvg } from "@memorang/media";

const DetailedListTopicsContainer = () => {
	const { type, id, examName } = useLocalSearchParams<{
		type: string;
		id: string;
		examName: string;
	}>();

	const description = `Your predicted mastery per ${type!.toLocaleLowerCase()} based upon your progress so far`;

	const alertConfig = getSummativeTestAlertConfig(examName!);

	const { detailMastertyScreenData, ascendingMap, updateRows } =
		useDetailedListTopics({
			id,
			type: type!,
			examName,
		});

	const hasShownMasteryTopicsAlert = useGlobalStore(
		(state) => state.hasShownMasteryTopicsAlert,
	);

	const router = useRouter();

	const showAlert = !hasShownMasteryTopicsAlert;

	const setHasShownMasteryTopicsAlert = useGlobalStore(
		(state) => state.setHasShownMasteryTopicsAlert,
	);

	const CustomLoader = () => {
		return (
			<Box gap={16} flex={1}>
				<CustomAppbar
					back
					showRightAction={false}
					options={{
						headerTitle: type,
						subtitle: description,
					}}
				/>
				<Box flexGrow={1}>
					<Loader />
				</Box>
			</Box>
		);
	};
	if (!detailMastertyScreenData) {
		return <CustomLoader />;
	}

	const { rows, columns, breadcrumbs, subheader, title, footerData } =
		detailMastertyScreenData;
	const handleCloseAlert = () => {
		setHasShownMasteryTopicsAlert(true);
	};

	const showEmptyState = rows.length === 0;

	return (
		<>
			<CustomAppbar
				back
				breadcrumbs={breadcrumbs}
				showRightAction={false}
				options={{
					headerTitle: title,
					subtitle: description,
				}}
			/>
			<ScrollContainer maxWidth="md">
				{showEmptyState ? (
					<EmptyState
						{...emptyStates.progress}
						cta={"Take Practice Test"}
						image={ProgressEmptySvg}
						handleAction={() => {
							router.push("/practice-tests");
						}}
					/>
				) : (
					<>
						<CustomListSubheader>{subheader}</CustomListSubheader>
						<Box paddingHorizontal={16} gap={16}>
							{showAlert ? (
								<AlertStandard
									{...alertConfig.topicMastery}
									callback={handleCloseAlert}
								/>
							) : null}
							<DetailedListTopicsTable
								columns={columns}
								rows={rows}
								footerData={footerData}
								ascendingMap={ascendingMap}
								updateRows={updateRows}
							/>
						</Box>
					</>
				)}
			</ScrollContainer>
		</>
	);
};

export default DetailedListTopicsContainer;
