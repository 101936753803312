/* -----------------Third parties--------------- */
import { Auth, type CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import type { CognitoUser } from "@aws-amplify/auth";
import { Platform } from "react-native";

const updateUserPlatform = async (platform: string, version: string) => {
	const user = await Auth.currentAuthenticatedUser();
	const attributes = {
		"custom:platform": platform,
		"custom:version": version,
	};
	const result = await Auth.updateUserAttributes(user, attributes);
	return result;
};

const updateCurrentUserAttributes = async () => {
	const user = await Auth.currentAuthenticatedUser();
	const attributes = {
		"custom:currentLogin": Platform.OS === "web" ? "Portal" : "Mobile",
	};
	const result = await Auth.updateUserAttributes(user, attributes);
	return result;
};

const sendPlaformAndOsVersion = () => {
	const { OS, Version } = Platform;
	updateUserPlatform(OS, String(Version));
};
const isAuthenticated = async () => {
	try {
		const cognitoUser = await Auth.currentAuthenticatedUser();
		const currentSession = await Auth.currentSession();
		const promise = new Promise((resolve) => {
			if (currentSession.getRefreshToken()?.getToken()) {
				cognitoUser.refreshSession(currentSession.getRefreshToken(), () => {
					resolve(cognitoUser);
				});
			} else {
				resolve(cognitoUser);
			}
		});
		return promise;
	} catch (_e) {
		return null;
	}
};

const signInUsingSocialAuth = async (
	provider: CognitoHostedUIIdentityProvider,
) => {
	await Auth.federatedSignIn({ provider });
};

const signOut = async () => {
	await Auth.signOut();
};

const passwordValidation = (password: string) => {
	const expression = /^[a-zA-Z0-9!@#$%^&*()_-]*$/;
	return expression.test(password);
};

const signInUsingEmail = async (
	email: string,
	appId: string,
	tenantId: string,
) => {
	const cognitoUser = await Auth.signIn(
		{
			username: email.toLocaleLowerCase(),
			password: "",
			validationData: {
				tenantId,
				appId,
			},
		},
		undefined,
		{
			tenantId,
			appId,
		},
	);
	return cognitoUser;
};

const signUpUsingEmail = async (
	email: string,
	appId: string,
	tenantId: string,
) => {
	const cognitoUser = await Auth.signUp({
		username: email.toLocaleLowerCase(),
		password: "123456",
		clientMetadata: {
			tenantId,
			appId,
		},
	});
	return cognitoUser;
};
const handleCustomChallenge = async (
	code: string,
	cognitoUser: CognitoUser,
) => {
	try {
		const res = await Auth.sendCustomChallengeAnswer(cognitoUser, code);

		if (res.signInUserSession) {
			sendPlaformAndOsVersion();
			//TODO:Need to check
			//    await updateCurrentUserAttributes();
			return res;
		}
		return null;
	} catch (_error) {
		return null;
	}
};

const resendOTP = async (username: string) => {
	try {
		await Auth.resendSignUp(username);
	} catch (_error) {
		// TODO:Handle error
	}
};
export default Auth;
export {
	isAuthenticated,
	passwordValidation,
	signInUsingSocialAuth,
	signOut,
	updateUserPlatform,
	signInUsingEmail,
	handleCustomChallenge,
	resendOTP,
	signUpUsingEmail,
	updateCurrentUserAttributes,
};
