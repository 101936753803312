/* -----------------Globals--------------- */
import { Button, Dialog, Text } from "react-native-paper";

import { useClerk } from "@clerk/clerk-expo";
/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "@hooks/useAppTheme";

/* -----------------Child components--------------- */
import { DialogWrapper } from "@memorang/ui";

import { storage } from "@memorang/helpers";
import { useQueryClient } from "@tanstack/react-query";
/* -----------------Third parties--------------- */
import { trackLogout } from "analytics";
import { useRouter } from "expo-router";

type Props = {
	visible: boolean;
	handleClose: () => void;
};
export const SignoutDialog = ({ visible, handleClose }: Props) => {
	const theme = useAppTheme();
	const queryClient = useQueryClient();

	const { signOut } = useClerk();
	const router = useRouter();
	const handleSignout = async () => {
		await signOut();
		handleClose();
		router.replace("/");
		queryClient.clear();
		storage.clearAll();
		trackLogout();
	};
	return (
		<DialogWrapper visible={visible} handleClose={handleClose} maxWidth={"xs"}>
			<Dialog.Title>Sign out</Dialog.Title>
			<Dialog.Content
				style={{
					maxWidth: 400,
				}}
			>
				<Text variant="bodyMedium">Are you sure you want to sign out?</Text>
			</Dialog.Content>
			<Dialog.Actions>
				<Button
					labelStyle={{
						color: theme.colors.onSurfaceVariant,
					}}
					mode="text"
					onPress={handleClose}
				>
					Cancel
				</Button>
				<Button mode="text" onPress={handleSignout}>
					Ok
				</Button>
			</Dialog.Actions>
		</DialogWrapper>
	);
};
