const planIntervalMap: Record<
	number,
	{
		interval: string;
		duration: number;
	}
> = {
	7: {
		interval: "Week",
		duration: 1,
	},
	1: {
		interval: "Month",
		duration: 1,
	},
	12: {
		interval: "Year",
		duration: 1,
	},
};

const restorePurchaseTemplate = (baseUrl: string) => {
	const body = `If you already purchased this product and it's still locked, please try the following:

1. Make sure you're logged into the proper account       
2. Make sure you have the correct product selected
3. Make sure your subscription hasn't expired
4. Make sure you've activated your subscription (check your junk mail)
5. If you have an access code, [access code](${baseUrl}/redeem)
    
    
If none of these apply, please **click here**
    `;
	return body;
};

const restorePurchaseTemplateWeb = () => {
	const body = `
1. Make sure you're logged into the right email
2. Make sure you have the correct **[exam selected](/account/settings)**
3. Make sure your subscription hasn't expired
        
If none of these apply, please **contact support**
    `;
	return body;
};

export { planIntervalMap, restorePurchaseTemplate, restorePurchaseTemplateWeb };
