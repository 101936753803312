import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
/* ----------------- React Query Imports --------------- */
import {
	type PersistQueryClientOptions,
	PersistQueryClientProvider,
} from "@tanstack/react-query-persist-client";

import { isWeb } from "@helpers/platform";
/* ----------------- External Imports --------------- */
import createPersister from "./persister";

const sqlitePersister = createPersister();

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			gcTime: 1000 * 60 * 60 * 24,
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60 * 60,
			refetchOnMount: false,
		},
	},
});
const persistOptions: Omit<PersistQueryClientOptions, "queryClient"> = {
	persister: sqlitePersister,
};

const onPersisterSuccess = () => {
	queryClient.resumePausedMutations();
};
const ReactQueryProvider = ({ children }: { children: React.ReactNode }) => {
	if (!isWeb) {
		//Disable cache on native apps since it's making the app slow, optimize sqlite persister
		return (
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		);
	}
	return (
		<PersistQueryClientProvider
			client={queryClient}
			persistOptions={persistOptions}
			onSuccess={onPersisterSuccess}
		>
			{children}
		</PersistQueryClientProvider>
	);
};

export default ReactQueryProvider;
