import AiInsightsSuggestion from "../../AiInsightsSuggestion";
import Box from "../../Box";
import CardContainer from "../../CardContainer";
import RowStats, { type Stat } from "../../RowStats";

type Props = {
	title: string;
	tooltip?: string;
	subtitle: string;
	stats: Stat[];
	insights: string;
};
const StrenghtsAndWeaknessCard = ({
	title,
	tooltip,
	subtitle,
	stats,
	insights,
}: Props) => {
	return (
		<CardContainer
			title={title}
			tooltip={tooltip}
			subtitle={subtitle}
			icon="arm-flex-outline"
		>
			<Box padding={16} gap={24}>
				<RowStats stats={stats} />
			</Box>
			<AiInsightsSuggestion
				text={insights}
				buttonProps={{
					cta: "Learn more",
					onPress: () => {
						// TODO: Implement insights modal
					},
				}}
			/>
		</CardContainer>
	);
};

export default StrenghtsAndWeaknessCard;
