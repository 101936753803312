import { REST_API_BASE_URL } from "@constants/urls";
import axios from "axios";
import type { ItemData } from "schema/item";
import {
	type AnswerEventPayload,
	AnswerEventPayloadSchema,
	type CreateSessionPayload,
	CreateSessionPayloadSchema,
	CreateSessionResponseSchema,
	GetItemDetailsPayloadSchema,
} from "./schema";

const BASE_URL = `${REST_API_BASE_URL}/responses/track-answer-event-response`;

export const getItemDetails = async (payload: {
	itemId: number;
	userId: number;
	token: string;
	tenantId: string;
}): Promise<{
	data: {
		items: ItemData;
	};
}> => {
	const validatedPayload = GetItemDetailsPayloadSchema.parse(payload);
	const { data } = await axios.post(
		`${REST_API_BASE_URL}/user-events/get-item-details`,
		validatedPayload,
		{
			headers: {
				Authorization: payload.token,
				tenantId: payload.tenantId,
			},
		},
	);

	return data;
};

export const createSession = async (payload: CreateSessionPayload) => {
	const validatedPayload = CreateSessionPayloadSchema.parse(payload);
	const { data } = await axios.post(
		`${REST_API_BASE_URL}/session/create-session`,
		validatedPayload,
		{
			headers: {
				Authorization: payload.token,
				tenantId: payload.tenantId,
			},
		},
	);

	return CreateSessionResponseSchema.parse(data);
};

const trackAnswerEvent = async (payload: AnswerEventPayload) => {
	try {
		const validatedPayload = AnswerEventPayloadSchema.parse(payload);
		const { data } = await axios.post(BASE_URL, validatedPayload, {
			headers: {
				Authorization: payload.token,
				tenantId: payload.tenantId,
			},
		});
		return data;
	} catch (error) {
		console.error("Error tracking answer event:", error);
		throw error;
	}
};

export { trackAnswerEvent };
