/* -----------------Globals--------------- */
import { useMemo } from "react";

import { Image } from "@memorang/ui";
/* -----------------UI--------------- */
import { Platform, type TextStyle } from "react-native";

/* -----------------Helpers & Hooks--------------- */
import { useDevice } from "../../useDevice";

/* -----------------Types--------------- */
import type { GetMessageActions } from "./types";

/* -----------------Child components--------------- */
import Box from "../Box";
import { CustomListSubheader } from "../CustomListSubheader";
import { MessageActions } from "./MessageActions";
import { MessageMenuActions } from "./MessageMenuActions";

type Props = {
	showSummaryActions?: boolean;
	content?: string;
	subheader?: string;
	subHeaderStyle?: TextStyle;
	profileImageUri?: string | null;
	messageActions?: GetMessageActions;
	runId?: string;
};

export const MessageHeader = ({
	showSummaryActions,
	content,
	subheader,
	subHeaderStyle,
	profileImageUri,
	messageActions,
	runId,
}: Props) => {
	const { isMobile } = useDevice();

	const profileImage = useMemo(() => {
		if (!profileImageUri) {
			return null;
		}

		return (
			<Image
				{...(Platform.OS !== "web" ? { cachePolicy: "memory-disk" } : {})}
				source={{
					uri: profileImageUri,
					width: 24,
				}}
				// * This fixes the flickering of image on web
				placeholder={{
					uri: profileImageUri,
					width: 24,
				}}
				style={{
					borderRadius: 6,
					width: 24,
					height: 24,
				}}
			/>
		);
	}, [profileImageUri]);

	const showSubheader = subheader && subheader.length > 0;
	return (
		<Box flexDirection="row" alignItems="center" justifyContent="space-between">
			{showSubheader ? (
				<Box flexDirection="row" alignItems="center" gap={8}>
					{profileImage}
					<CustomListSubheader
						enableGutters={false}
						style={[{ paddingLeft: 0 }, subHeaderStyle]}
					>
						{subheader}
					</CustomListSubheader>
				</Box>
			) : (
				<Box />
			)}
			{showSummaryActions &&
				messageActions &&
				(isMobile ? (
					<MessageMenuActions
						messageActions={messageActions}
						content={content}
						runId={runId}
					/>
				) : (
					<MessageActions
						messageActions={messageActions}
						content={content}
						runId={runId}
					/>
				))}
		</Box>
	);
};
