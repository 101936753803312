import type { BundleDetails } from "@memorang/types";

export type Category = {
	id: string;
	name: string;
	icon: string;
	tenantId: string;
};
export type ExamContext = {
	id: string;
	name: string;
	icon: string;
	isActive: boolean;
	created: string;
	updated: string;
	numericId: number;
	category: Category[];
	tenantId: string;
	bundles: BundleDetails[];
};
type ExamMetaData = {
	isActiveExam: boolean;
	examDate: string;
};
type ExamDetails = {
	id: string;
	exam: ExamContext;
	metadata: ExamMetaData;
};

export type { ExamMetaData, ExamDetails };
