import { ScrollContainer } from "@memorang/ui";
import RenderMarkdown from "@memorang/ui/src/components/MarkdownRenderer";

const AiInsightsRoute = () => {
	const dummyInsights =
		"You're excelling in Pediatrics and Urology. Consider focusing on Neurology to improve your overall performance.";
	return (
		<ScrollContainer
			contentContainerStyle={{
				padding: 16,
			}}
		>
			<RenderMarkdown text={dummyInsights} />
		</ScrollContainer>
	);
};

export default AiInsightsRoute;
