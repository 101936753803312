/* -----------------Child components--------------- */
import { CustomAppbar } from "components/index";

import { MoreRouteContainer } from "@features/more";
import { isWeb } from "@helpers/platform";
/* -----------------Types--------------- */
import { useDevice } from "@hooks/useDevice";
import DocumentTitle from "components/DocumentTitle";

const MoreRoute = () => {
	const { isMobile } = useDevice();

	return (
		<>
			<CustomAppbar
				showCommandBar={isWeb}
				showHambugerMenu
				showRightAction={false}
				options={{
					headerTitle: "More Settings",
					subtitle: isMobile ? "" : "Explore additional settings here",
				}}
			/>
			<DocumentTitle title="More Settings" />
			<MoreRouteContainer />
		</>
	);
};

export default MoreRoute;
