/* -----------------Child components--------------- */
import { CustomAppbar } from "components/index";

/* -----------------Globals--------------- */
import { Stack } from "expo-router";

import { pageViewTypes } from "@constants/tracking";
/* -----------------Types--------------- */
import { AccountSettings } from "@features/account";
import useAnalytics from "@hooks/useAnalytics";
import { useDevice } from "@memorang/ui";
import DocumentTitle from "components/DocumentTitle";
const AccountSettingsRoute = () => {
	const { isMobile } = useDevice();
	useAnalytics({
		type: pageViewTypes.settings,
	});
	return (
		<>
			<Stack.Screen
				options={{
					headerShown: true,
					header: () => (
						<CustomAppbar
							options={{
								headerTitle: "Account Settings",
								subtitle: isMobile ? "" : "Manage your account settings",
							}}
							showSettings={false}
							showUserMenu={false}
							back
						/>
					),
				}}
			/>
			<DocumentTitle title="Account Settings" />
			<AccountSettings />
		</>
	);
};

export default AccountSettingsRoute;
