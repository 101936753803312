import { MarkdownRenderer } from "@memorang/ui";
import { ScrollView } from "react-native";

type Props = {
	sessionAnalysisData: string;
	bottomContent?: React.ReactNode;
};
const FullReportContent = ({ sessionAnalysisData, bottomContent }: Props) => {
	return (
		<ScrollView
			contentContainerStyle={{
				paddingHorizontal: 16,
				paddingBottom: 24,
			}}
		>
			<MarkdownRenderer
				variant="explanation"
				text={sessionAnalysisData}
				fontSize={14}
				customStyles={{
					paragraph: {
						lineHeight: 22,
						marginBottom: 8,
					},
				}}
			/>
			{bottomContent}
		</ScrollView>
	);
};

export default FullReportContent;
