import { useAuth } from "@clerk/clerk-expo";
import { queryKeys } from "@constants/common";
import { useAppStore } from "@hooks/useAppStore";
import useClerkUser from "@hooks/useClerkUser";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { filterChips } from "../components/Leaderboard/constants";
import { fetchLeaderboardItems } from "../queries/leaderboard";

export type FilterChipType = (typeof filterChips)[number]["type"];
const useLeaderboard = () => {
	const tenantId = useAppStore((store) => store.tenant.id);
	const [selectedChip, setSelectedChip] = useState(filterChips[0].type);

	const { userPublicMetadataId } = useClerkUser();

	const { getToken } = useAuth();
	const { data: leaderboardResponse, isLoading: fetchingLeaderboard } =
		useQuery({
			queryKey: [queryKeys.leaderboard, tenantId, selectedChip],
			queryFn: async () => {
				const token = await getToken({
					template: "jwt-with-user",
				});
				if (!token) {
					throw new Error("No token");
				}
				return await fetchLeaderboardItems({
					periodType: selectedChip,
					userId: userPublicMetadataId,
					token,
					tenantId,
				});
			},
			enabled: !!userPublicMetadataId,
		});

	const leaderboards = leaderboardResponse?.data[selectedChip] || [];
	const userRank =
		leaderboardResponse?.data.userRank[selectedChip] || undefined;
	// const finalLeaderboards = isScreenshotMode
	// 	? mockedLeaderboard[selectedChip]
	// 	: leaderboards;
	const finalLeaderboards = leaderboards;

	const leaderboardPodiumData = finalLeaderboards
		.slice(0, 3)
		.map((item, index) => ({
			...item,
			rank: index + 1,
		}));

	const reorderedPodiumData = leaderboardPodiumData?.length
		? [
				leaderboardPodiumData[1],
				leaderboardPodiumData[0],
				leaderboardPodiumData[2],
			]
		: [];
	return {
		leaderboards: finalLeaderboards,
		fetchingLeaderboard,
		podiumData: reorderedPodiumData,
		selectedChip,
		handleSelectChip: (chip: FilterChipType) => setSelectedChip(chip),
		userRank,
	};
};

export default useLeaderboard;
