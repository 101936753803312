import useItems from "@features/dashboard/hooks/useItems";
import { useSession } from "@features/session";
import useSubscriptionPurchase from "@hooks/useSubscriptionPurchase";
import { useLocalSearchParams } from "expo-router";
import type { ItemData } from "schema/item";

const useMoreChallenges = () => {
	const { type = "recent" } = useLocalSearchParams<{ type: string }>();
	const { filteredItems } = useItems();

	const { handleCreateFormativeSession } = useSession();

	const { isPremiumUser } = useSubscriptionPurchase();

	const getChallenges = (items: ItemData[]) => {
		switch (type) {
			case "recommended":
				return items
					.filter((item) => !item.completedAt && item.status !== "ARCHIVED")
					.sort((a, b) => b.numAttempts - a.numAttempts);
			case "recent":
				return items.sort((a, b) => {
					const dateA = a.publishedAt ? new Date(a.publishedAt) : new Date(0);
					const dateB = b.publishedAt ? new Date(b.publishedAt) : new Date(0);
					return dateB.getTime() - dateA.getTime();
				});
			case "popular":
				return items.sort((a, b) => b.numAttempts - a.numAttempts);
			default:
				return items;
		}
	};

	const challenges = getChallenges(filteredItems).map((item) => ({
		id: item.id,
		title: item.data.stem,
		description: `Challenge #${item.id}`,
		imageUri: item.media?.[0]?.url || "https://placeholder.com/image.jpg",
		locked: item.accessType === "PREMIUM" && !isPremiumUser,
		numResponses: item.numAttempts,
	}));

	const handlePressChallenge = (id: number) => {
		const challengeItem = filteredItems.find((item) => item.id === id);
		if (challengeItem) {
			handleCreateFormativeSession({
				challengeItem,
			});
		}
	};

	return {
		challenges,
		type,
		handlePressChallenge,
	};
};

export default useMoreChallenges;
