import type { Article } from "../../../../../types";

export const Article595: Article = {
	id: 595,
	rsuiteId: "083d1ab1-a056-4800-95a6-2315762e4acc",
	type: "scientific article",
	title:
		"Validation of Examination Maneuvers for Adolescent Idiopathic Scoliosis in the Telehealth Setting",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=083d1ab1-a056-4800-95a6-2315762e4acc&type=jpeg&name=JBJS.23.01146f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Validation of Examination Maneuvers for Adolescent
                Idiopathic Scoliosis in the Telehealth Setting</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Alexander R.
                      Farid, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">M. Timothy
                      Hresko, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 8 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_56" class="annotation strong"
                          style="position: unset;">Background:</span> Telehealth visits (THVs) have made it essential to
                        adopt innovative ways to evaluate patients virtually. This study validates a novel THV approach
                        that uses educational videos and an instructional datasheet, enabling parents to use smartphones
                        to measure their child’s scoliosis at home or in telehealth settings.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_57" class="annotation strong"
                          style="position: unset;">Methods:</span> We identified a prospective cohort of patients with
                        adolescent idiopathic scoliosis (AIS) scheduled for follow-up care from March to July 2021. The
                        angle of trunk rotation (ATR) was first measured at home by patients’ guardians using
                        instructional video guidance and a smartphone application with internal accelerometer software.
                        The second measurement was made during a THV examination performed by caregivers with
                        supervision by trained associates via a telehealth appointment. Lastly, the clinician measured
                        the child’s ATR during an in-person clinic visit. Intraclass correlation coefficients (ICCs) and
                        interrater reliability were compared between in-person clinic measurements and (1) at-home and
                        (2) THV measurements. Shoulder, lower back, and pelvic asymmetry were observed and quantified at
                        home and virtually, and then were compared with in-person clinic evaluations using kappa values.
                        Surveys were used to evaluate the experience of the patient/caregiver with the at-home and
                        telehealth assessment tools.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_58" class="annotation strong"
                          style="position: unset;">Results:</span> Seventy-three patients were included (mean age, 14.1
                        years; 25% male). There was excellent agreement in the ATR measurements between THVs and
                        in-person visits (ICC = 0.88; 95% confidence interval [CI] = 0.83 to 0.92). ATR agreement
                        between at-home and in-person visits was also excellent, but slightly diminished (ICC = 0.76;
                        95% CI = 0.64 to 0.83). Agreement between THV and in-person measurements was significantly
                        higher compared with that between at-home and in-person measurements (p = 0.04). There was poor
                        agreement in lower back asymmetry between THV and in-person assessments (kappa = 0.37; 95% CI =
                        0.14 to 0.60); however, there was no significant agreement between at-home and in-person
                        assessments (kappa = 0.06; 95% CI = −0.17 to 0.29). Patient/caregiver satisfaction surveys (n =
                        70) reported a median score of 4 (“good”) for comfort with use of the technology, and a score of
                        3 (“neutral”) for equivalence of THV and in-person evaluation.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_59" class="annotation strong"
                          style="position: unset;">Conclusions:</span> There was a high level of agreement between
                        telehealth and in-person spine measurements, suggesting that THVs may be reliably used to
                        evaluate AIS, thus improving access to specialized care.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_60" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Diagnostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level II</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Telehealth visits (THVs) have become an important
                  component of orthopaedic surgery, including in the assessment of patients who have undergone spine
                  surgery. Thus, it is necessary to consider innovative ways to utilize existing technology to conduct
                  the key components of a spine examination remotely.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Studies exploring the use of telemedicine among
                  orthopaedic patients with spine conditions have mainly focused on feasibility, administration of pre-
                  and postoperative surveys, cost assessment, and use of wearable activity monitors<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">7</span></a>. To
                  our knowledge, this is the first prospective study to validate spine examination maneuvers in children
                  with adolescent idiopathic scoliosis (AIS) that can be used in a virtual setting.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The orthopaedic literature has demonstrated that access to
                  subspecialty care is not equal across the heterogeneous population of the United States, particularly
                  in rural areas or among those with mobility limitations<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">13</span></a>. We investigated ways to utilize existing technology to
                  conduct aspects of the spine examination virtually. This study aimed to validate a novel approach that
                  provides educational videos and an instructional datasheet so that parents can use their smartphones
                  to measure their child’s scoliosis via a telehealth assessment. We hypothesized that patients’
                  caregivers would be able to obtain satisfactory scoliometer readings, shoulder height measurements,
                  and pelvic asymmetry measurements following appropriate education, which would allow continued
                  reliable use of THVs to improve patient access to specialized care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">This prospective study was conducted in the orthopaedic
                  surgery department at a single tertiary care pediatric hospital. It was approved by the institutional
                  review board (IRB-P00036853). We investigated patients known to have AIS who had preexisting clinic
                  appointments, in order to compare agreement between measurements of scoliosis made in 3 settings: an
                  at-home examination performed by caregivers who followed instructions for performing a self-assessment
                  for scoliosis without clinician supervision, a THV examination performed by caregivers with clinician
                  supervision via a telehealth appointment, and an in-person examination performed by clinicians using
                  the Measure application (app) (Apple) on their smartphones in outpatient clinic rooms at our tertiary
                  care facility. Legal guardians were present in the room for the duration of the study. A
                  patient/caregiver satisfaction survey was administered during the in-person clinic visit.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Participants</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Patients with AIS who were scheduled for routine follow-up
                  between March and July 2021 were screened for eligibility. Patients were eligible if they were between
                  10 and 17 years of age at the time of their first scheduled follow-up, they had no neurologic deficit
                  that had previously been discovered on examination, and they and their caregivers were proficient in
                  the English language in order to communicate during a virtual examination. Patients were ineligible if
                  they had a known or suspected history of prior spine surgeries, had a diagnosis of nonidiopathic
                  scoliosis, or had any other concomitant diagnosis involving the spine.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Eligible patients and their families were initially sent
                  an outreach email with a recruitment flyer, and we followed up via telephone to discuss the study and
                  confirm eligibility. Study staff obtained informed consent over Zoom and discussed the virtual
                  scoliosis examination sheet that provided instructions for performing at-home self-examination. Of 109
                  eligible participants, 76 provided informed consent and completed the study, 30 declined
                  participation, and 3 did not complete the required threefold examination. The final cohort was 73
                  patients; the remaining 3 were excluded from analysis due to invalid measurements.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Collection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Demographic information and the medical history were
                  extracted from the patients’ electronic medical records. Prospective scoliosis assessment data that
                  were collected from the spine virtual-examination sheet, which had been developed specifically for AIS
                  patients, were securely managed using REDCap (Research Electronic Data Capture; Vanderbilt University)
                  software at our site. In addition, we also collected data concerning body mass index (BMI), type of
                  patient visit for scoliosis, and the type of smartphone used for assessments.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">AIS
                Telehealth Examination Information Sheet</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The virtual spine examination guide was developed to
                  deliver a standardized virtual scoliosis assessment during the COVID-19 pandemic. This was a novel
                  approach to spine examination utilizing educational videos and examination sheets to adapt routine
                  physical examination maneuvers for use in telehealth settings. The guide included pictures and
                  instructions to simulate standard physical examination techniques (see Appendix A). Caregivers were
                  instructed to stand behind the patient during the entirety of the examination. Any notable difference
                  in shoulder height, deviation of spinal curve alignment, and prominent changes in sagittal position
                  were documented. The vertebral rotation was measured by the Adams forward bend test. Parents/guardians
                  were asked to download accelerometer or inclinometer apps on their smartphones (the “Measure” app for
                  iPhones [Apple] and the “Bubble Level” app for Android users) with instructions to measure the angle
                  of trunk rotation (ATR); the current literature has indicated that a smartphone may be used reliably
                  as a scoliometer<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">14</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">15</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">ATR
                Measurement</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Each patient was placed in a standing position with feet
                  together and asked to bend forward at the hips, keeping both knees straight, with the trunk anteriorly
                  flexed and parallel to the ground, and with relaxed arms hanging perpendicular to the trunk. Examiners
                  then held the smartphone with their index fingers at the top and their thumbs at the bottom, and drew
                  along the spinous processes to discover the level with the highest reading in order to measure the
                  axial trunk rotation. ATR measurements were obtained at home, via THV, and in-person in the clinic as
                  described above. To minimize bias, the spine clinicians were blinded to the prior measurements made by
                  the patient/family (both at home and via THV).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Shoulder
                Height, Lower Back, and Waist Assessment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Along with the ATR measurements, asymmetry assessments
                  were done for the shoulders, lower back, and pelvis. Patients were asked to wear comfortable clothing,
                  pin their long hair up, and leave their back exposed. With each patient standing straight, facing
                  forward and looking away, the examiners visually assessed for asymmetry in shoulder height, the lower
                  back, and the level of the hip/pelvis, and they were asked to quantify any asymmetry.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patient-Reported Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">A 5-item survey that assessed the experience using at-home
                  and telehealth assessment tools was administered after the in-person clinic visit. The survey inquired
                  about caregivers’ comfort using telehealth services, their confidence level in identifying shoulder
                  and waist asymmetry, their confidence level in using phone accelerometer or inclinometer apps, and
                  their overall satisfaction. Each item was given a score that ranged from 1 (very poor) to 5 (very
                  good).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Analysis was performed using SAS software (version 9.4;
                  SAS Institute). Patient demographics and clinical data were summarized: categorical variables were
                  summarized using frequencies and percentages, while age and BMI were summarized using the mean and
                  standard deviation (SD). For the primary analysis, reliability of at-home and telehealth scoliometer
                  readings in comparison with in-person readings was assessed by estimating the intraclass correlation
                  coefficient (ICC) with a 95% confidence interval (CI). Interrater reliability was assessed between
                  in-person clinic visits and at-home visits and THVs, respectively, using an ICC (2,2) model.
                  Interpretations of the ICC estimates were based on the scale from Fleiss and Cicchetti and Sparrow
                  (&lt;0.40, poor; 0.40 to 0.59, fair; 0.60 to 0.74, good; and &gt;0.74, excellent)<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">17</span></a>. The
                  ICCs were calculated for the comparisons between at-home and in-person clinic measurements as well as
                  between THV and in-person clinic measurements. P values were calculated using a nonparametric, 2-sided
                  hypothesis test comparing the bootstrapped distribution of the ICC statistics across groups. We used
                  bootstrapped resampling with 5,000 replications. Agreement was also assessed using the Bland-Altman
                  analysis, including estimation of systematic bias and the 95% limits of agreement (LoA). For the
                  secondary analysis, the concordance between at-home and telehealth shoulder height, lower back, and
                  waist asymmetry assessments in comparison with in-person clinic visits was assessed using the Fleiss
                  kappa coefficient and a 95% CI. The responses from the patient satisfaction surveys were summarized
                  using the median and interquartile range (IQR).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The 73 patients with AIS who successfully completed all 3
                  types of visits were analyzed. The mean patient age at the first examination date was 14.1 years
                  (range, 11.4 to 18.0 years), and 25% of the patients were male (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). Most patients had a bracing visit (61%) and were iPhone users
                  (71%).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Cohort Demographics (N = 73)<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 722px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_58" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.1 ±
                          1.46</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (25%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Race (n = 55)<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> White</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (80%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Black, African American,
                          or African</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Asian or South
                          Asian</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hispanic, Latino/a, or
                          Latinx</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ethnicity (n = 61)<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hispanic, Latino/a, or
                          Latinx</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Not Hispanic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48
                          (79%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unknown</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (13%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Body mass index <span
                            data-id="emphasis_59" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_60" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_5" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_5"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_61"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.1 ±
                          3.21</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Visit type (n = 72)<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Observation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (36%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Brace</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (61%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Physical therapy/Schroth
                          method</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other
                          (presurgical)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± the standard deviation or as the
                        number of patients with the percentage in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The number in parentheses represents the number of cases with available
                        data for the given characteristic.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">There was excellent agreement in the ATR measurements
                  between telehealth and in-person clinic visits (ICC = 0.88; 95% CI = 0.83 to 0.92) (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>, <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). The
                  ATR measurement agreement between at-home and in-person clinic visits was also excellent, but slightly
                  diminished (ICC = 0.76; 95% CI = 0.64 to 0.83) (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>, <a
                    href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). The agreement between telehealth and in-person clinic
                  measurements was significantly higher compared with that between at-home and in-person clinic
                  measurements (p = 0.04). The average in-person ATR measurement was −1.8° (range, −17.0° to 15.0°); the
                  average at-home measurement was −3.4° (−21.0° to 15.0°), and the average telehealth measurement was
                  −2.6° (−17.0° to 14.0°). There was poor agreement in determining lower back asymmetry between
                  telehealth and in-person clinic assessments (kappa = 0.37; 95% CI = 0.14 to 0.60). However, there was
                  no significant agreement between at-home and in-person clinic assessments (kappa = 0.06; 95% CI =
                  −0.17 to 0.29). Additionally, there was no significant agreement in shoulder or pelvic asymmetry
                  measurements between at-home or THV assessments and in-person clinic visits (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01146f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=083d1ab1-a056-4800-95a6-2315762e4acc&type=jpeg&name=JBJS.23.01146f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Bland-Altman plot for the agreement between
                          in-person clinic and THV measurement of the ATR. The solid black line represents the mean
                          difference between in-person clinic and telehealth measurements. The dashed black lines
                          represent the 95% LoA.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Agreement Between Visit Types<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 210px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Measurements</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ICC</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">MD</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">SD<span
                            data-id="subscript_1" class="annotation subscript" style="position: unset;">D</span></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">LoA</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Home versus in-person
                          clinic</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.76</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.64-0.83</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.12</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.17-3.03</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−10.39-13.60</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Telehealth versus
                          in-person clinic</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.88</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.83-0.92</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.67</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.25-1.93</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−8.32-9.99</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ICC = intraclass correlation coefficient, CI = confidence interval, MD
                        = mean difference, SD<span data-id="subscript_2" class="annotation subscript"
                          style="position: unset;">D</span> = standard deviation of the difference, and LoA = limits of
                        agreement.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01146f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=083d1ab1-a056-4800-95a6-2315762e4acc&type=jpeg&name=JBJS.23.01146f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Bland-Altman plot for the agreement between
                          in-person clinic and at-home measurement of the ATR. The solid black line represents the mean
                          difference between in-person clinic and at-home measurements. The dashed black lines represent
                          the 95% LoA.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Height and Weight Asymmetry Assessments (N =
                          73)<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 653.9px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.694093);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Agreed,
                          Yes vs. Yes</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Disagreed,
                          Yes vs. No</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Disagreed,
                          No vs. Yes</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Agree, No
                          vs. No</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">%
                          Agreement</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Kappa</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Interrater agreement, at
                          home vs. in-person</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Shoulder
                          asymmetry</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.15</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.08-0.38</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lower back
                          asymmetry</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.17-0.29</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pelvic/hip
                          asymmetry</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.22-0.24</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Interrater agreement, THV
                          vs. in-person</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Shoulder
                          asymmetry</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.01-0.45</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lower back
                          asymmetry</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.37</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.14-0.60</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pelvic/hip
                          asymmetry</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.19-0.27</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CI = confidence interval.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Patient/caregiver satisfaction surveys (n = 70) were
                  notable for a median score of 4 (“good”) for their personal comfort with using a smartphone for AIS
                  care, confidence in examining the patient’s back for shoulder and waist symmetry, and comfort level in
                  measuring the degree of spinal rotation, as well as whether they would recommend that other families
                  consider THVs for AIS care (n = 69). Patients reported a median score of 3 (“neutral”) for equivalence
                  of THV to in-person evaluation (n = 69) (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Satisfaction Survey Results<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 471px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Question</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Median</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">IQR</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Personal comfort level
                          using an accessible smartphone for AIS care (n = 70)<span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.0-5.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Confidence level in
                          examining your child’s back for shoulder and waist asymmetry (n = 70)<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.0-5.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Comfort level in using
                          the Measure or Bubble Level app on your phone to measure the degree of spinal rotation (n =
                          70)<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.0-5.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Recommendation to other
                          families to consider telehealth services for AIS care (n = 69)<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.0-4.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Do you think the THV is
                          equivalent to service as in-person evaluation? (n = 69)<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.0-4.0</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">IQR = interquartile range, AIS = adolescent idiopathic scoliosis, and
                        THV = telehealth visit.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The number in parentheses represents the number of cases with available
                        data for the given question.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Telehealth-based care has shown substantial progress,
                  particularly since 2020<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">18</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">20</span></a>, across various fields of medicine<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">23</span></a>.
                  Orthopaedic surgery, despite its emphasis on the physical examination, is no exception<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">33</span></a>.
                  However, to our knowledge, there has been no prior research evaluating the validity of this approach
                  in patients with AIS. In this prospective cohort study, we sought to validate the use of THVs in the
                  setting of AIS and orthopaedic spine care. Our results suggest that, particularly regarding
                  measurement of the ATR, THV measurements are comparable with in-person clinic visit measurements for
                  follow-up in patients with AIS.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">This study found excellent agreement between THVs and
                  in-person clinic visits for ATR measurements, as well as excellent but slightly diminished agreement
                  between at-home visits and in-person clinic visits. Further, our findings revealed poor agreement in
                  determining lower back asymmetry between THVs and in-person clinic assessments. Overall, and
                  importantly, these results indicate that THVs may provide reliable measurements of the ATR that are
                  comparable with measurements at in-person clinic visits. However, there remains room for improvement
                  in assessing asymmetry, particularly with at-home assessment; the lower agreement observed in that
                  unsupervised setting may be attributed to factors such as variations in patient positioning or
                  limitations in capturing accurate images or measurements remotely. Additionally, the lack of agreement
                  in shoulder and pelvic asymmetry between THVs and in-person clinic visits suggests that these
                  assessments may require direct hands-on evaluation. Relatedly, a recent study by Iyer et al. concluded
                  that certain physical maneuvers evaluating the spine—albeit in adult patients—are difficult to
                  accurately perform and may be better evaluated by a clinician during an in-person assessment<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">34</span></a>. Interestingly, that study, while offering a nonvalidated,
                  preliminary means of evaluating extremity strength in patients with spine conditions via telehealth,
                  concluded that there is a pressing need for a validated virtual spine physical examination, further
                  supporting the necessity of the present study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The rapid evolution of virtual assessments has
                  demonstrated their important, wide-ranging benefits. Primarily, remote care allows patients to be seen
                  by providers in a timely, efficient, and more accessible manner, while maintaining continuity of care
                  (which is essential for scoliosis management), regardless of transportation limitations that are
                  particularly prevalent when accessing specialty care<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">35</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">40</span></a>. The option to use either an iPhone or an Android-based app
                  further increased accessibility among our cohort. Additionally, patients may be better able to
                  participate in their own care since physicians may project and explain imaging results on patients’
                  own devices, allowing patients to capture and share results, discuss concerns in real time, and
                  receive education remotely<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">41</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">44</span></a>. Lastly, THVs are often more cost-effective<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_12" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">45</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">46</span></a>. Virtual-based care has become a substantial portion of
                  many practices<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">47</span></a> and is likely to maintain a permanent role.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Traditionally, in-person clinic visits have been the
                  standard for assessing patients with AIS due to reliance on hands-on examination and measurements;
                  however, recent publications have challenged this standard. Those studies have investigated the
                  accuracy and quality of telehealth spine assessments, patient satisfaction, treatment adherence, and
                  the potential for telemedicine to reduce health-care costs<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">52</span></a>. In aggregate, they report high satisfaction levels among
                  patients with spine and other orthopaedic conditions while maintaining safe, high-level orthopaedic
                  care via THVs<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">50</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">51</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">53</span></a>. Other literature has evaluated the role of the THV in a
                  wide range of orthopaedic subspecialties, similarly indicating strong clinical results and high levels
                  of patient satisfaction following trauma, sports, and arthroplasty visits<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">32</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">54</span></a>. These findings ultimately support our results, which
                  indicate that, in addition to promising clinical results, patients report high levels of satisfaction,
                  confidence, and personal comfort when utilizing telemedicine and their personal devices to perform AIS
                  measurements. Collectively, the current and prior studies provide valuable insights into the
                  feasibility and benefits of integrating telemedicine into the comprehensive care of patients with AIS.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">It is important to note that our results should not be
                  utilized to suggest that THVs should replace all in-person clinic visits, particularly for patients
                  with rapidly progressive curves or for those who require imaging as determined by a clinician. We
                  propose that THVs, using the standardized virtual examination, are most effective as a screening tool.
                  Patients with minimal and stable curves (as in our cohort) with no evidence of worsening status via
                  standardized examinations are likely the safest candidates for a THV to replace a standard check-in
                  visit; if concerning findings that indicate a worsening curve are discovered during the THV, the
                  physician may recommend an in-person follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">This study had limitations. First, the sample size of 73
                  patients was relatively small, potentially limiting its generalizability. Second, to improve
                  generalizability, we assessed the use of both iPhone and Android-based apps, thus ideally covering
                  options that would be used by most of the population. However, it is possible that there are other
                  operating systems that were not assessed, or that patients may not have access to reliable internet in
                  certain areas where THVs may be most useful. Third, we observed a substantial early learning curve as
                  patients were educated on scoliometer use. While this learning curve typically plateaued after a few
                  attempts, it is important to note that this inexperience posed an initial challenge. Fourth,
                  telehealth presents important challenges that need to be acknowledged, including patient data security
                  and the possibility that the patient may have limited availability of technology or literacy in some
                  settings, which may disproportionately affect certain patient cohorts<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">54</span></a>; fortunately, in general, patients have typically reported
                  good satisfaction with telehealth, but some continue to prefer face-to-face interactions<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">55</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">56</span></a>. Relatedly, in a recent study on THVs in pediatric
                  orthopaedics and sports medicine, technical challenges were reported to be worst at the onset of these
                  visits, with improvement over time and overall high satisfaction with THVs; however, the study
                  identified historically disadvantaged groups as more likely to report lower ratings for technical
                  connection quality, ease of scheduling, assessment of providers’ concerns, and virtual care overall<a
                    href="" data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">54</span></a>. This points to ongoing limitations with THVs, which cannot
                  alone address the disparities already present in health care. Fifth, this study only evaluated
                  standard AIS follow-up visits for patients who did not undergo surgery; further research is needed to
                  assess surgical outcomes after THV evaluation. Lastly, only patients who completed all 3 types of
                  appointments were included in our analysis. Family members who were involved may have had certain
                  characteristics (e.g., higher engagement in the patient’s care, increased motivation, or increased
                  reliability) that led to more comparable THV and in-person results, but these characteristics may not
                  be consistently present in the general population.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Overall, our study serves as a preliminary validation for
                  the use of THVs in assessing patients with AIS. The results indicate that THVs are reliable for ATR
                  measurements in this patient population, demonstrating values that closely mimic those obtained during
                  in-person visits. Lower back asymmetry measurements, particularly at-home measurements without
                  guidance via THV, showed lower agreement with the gold standard of in-person measurements, but were
                  still found to be fairly aligned. While the protocol proposed in this study can reliably be used for
                  these measurements, there remains the need for further refinement of telehealth strategies to improve
                  the reliability of the remote measurements, particularly regarding shoulder or pelvic asymmetry.
                  Additional research may evaluate the factors that impact the agreement between THV and in-person
                  measurements, including patient compliance, positioning, and device variability. Ultimately, we hope
                  that this study will serve as a foundation to formalize and standardize the THV protocol for AIS
                  tracking, allowing improved access for patients with barriers to care while also presenting a
                  framework for future use of THVs in other aspects of spine care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I225" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I225</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Thakar S</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Rajagopal N</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Mani S</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Shyam M</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Aryan S</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Rao AS</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Srinivasa R</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Mohan D</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Hegde AS</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Comparison of telemedicine with
                  in-person care for follow-up after elective neurosurgery: results of a cost-effectiveness analysis of
                  1200 patients using patient-perceived utility scores</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Neurosurg Focus.</span> 2018
                May;44(5):E17.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20telemedicine%20with%20in-person%20care%20for%20follow-up%20after%20elective%20neurosurgery%3A%20results%20of%20a%20cost-effectiveness%20analysis%20of%201200%20patients%20using%20patient-perceived%20utility%20scores&as_occt=title&as_sauthors=%20%22S%20Thakar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Hou J</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Yang R</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Yang Y</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Tang Y</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Deng H</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Chen Z</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Wu Y</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Shen H</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The Effectiveness and Safety of
                  Utilizing Mobile Phone-Based Programs for Rehabilitation After Lumbar Spinal Surgery: Multicenter,
                  Prospective Randomized Controlled Trial</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">JMIR Mhealth Uhealth.</span> 2019 Feb 20;7(2):e10201.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Effectiveness%20and%20Safety%20of%20Utilizing%20Mobile%20Phone-Based%20Programs%20for%20Rehabilitation%20After%20Lumbar%20Spinal%20Surgery%3A%20Multicenter%2C%20Prospective%20Randomized%20Controlled%20Trial&as_occt=title&as_sauthors=%20%22J%20Hou%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Scheer JK</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Bakhsheshian J</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Keefe MK</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Lafage V</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Bess S</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Protopsaltis TS</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Burton DC</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Hart RA</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Schwab F</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Smith JS</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Smith ZA</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Koski TR</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Ames CP</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Initial Experience With
                  Real-Time Continuous Physical Activity Monitoring in Patients Undergoing Spine Surgery</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Clin Spine Surg.</span> 2017
                Dec;30(10):E1434-43.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Initial%20Experience%20With%20Real-Time%20Continuous%20Physical%20Activity%20Monitoring%20in%20Patients%20Undergoing%20Spine%20Surgery&as_occt=title&as_sauthors=%20%22JK%20Scheer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Dicianno BE</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Fairman AD</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> McCue M</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Parmanto B</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Yih E</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> McCoy A</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Pramana G</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Yu DX</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> McClelland J</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Collins DM</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Brienza DM</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Feasibility of Using Mobile
                  Health to Promote Self-Management in Spina Bifida</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Am J Phys Med Rehabil.</span> 2016
                Jun;95(6):425-37.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Feasibility%20of%20Using%20Mobile%20Health%20to%20Promote%20Self-Management%20in%20Spina%20Bifida&as_occt=title&as_sauthors=%20%22BE%20Dicianno%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Stewart JJ</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Fayed I</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Henault S</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Kalantar B</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Voyadzis JM</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Use of a Smartphone Application
                  for Spine Surgery Improves Patient Adherence with Preoperative Instructions and Decreases Last-minute
                  Surgery Cancellations</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Cureus.</span> 2019 Mar 6;11(3):e4192.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Use%20of%20a%20Smartphone%20Application%20for%20Spine%20Surgery%20Improves%20Patient%20Adherence%20with%20Preoperative%20Instructions%20and%20Decreases%20Last-minute%20Surgery%20Cancellations&as_occt=title&as_sauthors=%20%22JJ%20Stewart%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Debono B</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Bousquet P</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Sabatier P</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Plas JY</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Lescure JP</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Hamel O</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Postoperative monitoring with a
                  mobile application after ambulatory lumbar discectomy: an effective tool for spine surgeons</span>.
                <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2016
                Nov;25(11):3536-42.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postoperative%20monitoring%20with%20a%20mobile%20application%20after%20ambulatory%20lumbar%20discectomy%3A%20an%20effective%20tool%20for%20spine%20surgeons&as_occt=title&as_sauthors=%20%22B%20Debono%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Bokshan SL</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Godzik J</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Dalton J</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Jaffe J</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Kelly MP</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Reliability of the revised
                  Scoliosis Research Society-22 and Oswestry Disability Index (ODI) questionnaires in adult spinal
                  deformity when administered by telephone</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2016
                Sep;16(9):1042-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reliability%20of%20the%20revised%20Scoliosis%20Research%20Society-22%20and%20Oswestry%20Disability%20Index%20(ODI)%20questionnaires%20in%20adult%20spinal%20deformity%20when%20administered%20by%20telephone&as_occt=title&as_sauthors=%20%22SL%20Bokshan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Mayer ML</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Slifkin RT</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Skinner AC</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">The effects of rural residence
                  and other social vulnerabilities on subjective measures of unmet need</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Med Care Res Rev.</span>
                2005 Oct;62(5):617-28.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effects%20of%20rural%20residence%20and%20other%20social%20vulnerabilities%20on%20subjective%20measures%20of%20unmet%20need&as_occt=title&as_sauthors=%20%22ML%20Mayer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Lagu T</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Hannon NS</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Rothberg MB</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Wells AS</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Green KL</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Windom MO</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Dempsey KR</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Pekow PS</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Avrunin JS</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Chen A</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Lindenauer PK</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Access to subspecialty care for
                  patients with mobility impairment: a survey</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Ann Intern Med.</span> 2013 Mar
                19;158(6):441-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Access%20to%20subspecialty%20care%20for%20patients%20with%20mobility%20impairment%3A%20a%20survey&as_occt=title&as_sauthors=%20%22T%20Lagu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Perlstein MA</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Goldberg SJ</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Meaney FJ</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Davis MF</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Zwerdling Kluger C</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Factors influencing age at
                  referral of children with congenital heart disease</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Arch Pediatr Adolesc Med.</span> 1997
                Sep;151(9):892-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20influencing%20age%20at%20referral%20of%20children%20with%20congenital%20heart%20disease&as_occt=title&as_sauthors=%20%22MA%20Perlstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Mayer ML</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Skinner AC</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Slifkin RT</span>; National
                Survey of Children With Special Health Care Needs. <span data-id="strong_11" class="annotation strong"
                  style="position: unset;">Unmet need for routine and specialty care: data from the National Survey of
                  Children With Special Health Care Needs</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Pediatrics.</span> 2004
                Feb;113(2):e109-15.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Unmet%20need%20for%20routine%20and%20specialty%20care%3A%20data%20from%20the%20National%20Survey%20of%20Children%20With%20Special%20Health%20Care%20Needs&as_occt=title&as_sauthors=%20%22ML%20Mayer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Kane DJ</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Zotti ME</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Rosenberg D</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Factors associated with health
                  care access for Mississippi children with special health care needs</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Matern Child Health
                  J.</span> 2005 Jun;9(2):S23-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20associated%20with%20health%20care%20access%20for%20Mississippi%20children%20with%20special%20health%20care%20needs&as_occt=title&as_sauthors=%20%22DJ%20Kane%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Skinner AC</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Mayer ML</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Effects of insurance status on
                  children’s access to specialty care: a systematic review of the literature</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">BMC Health Serv Res.</span>
                2007 Nov 28;7(1):194.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20insurance%20status%20on%20children%E2%80%99s%20access%20to%20specialty%20care%3A%20a%20systematic%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22AC%20Skinner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Franko OI</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Bray C</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Newton PO</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Validation of a scoliometer
                  smartphone app to assess scoliosis</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">J Pediatr Orthop.</span> 2012 Dec;32(8):e72-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20of%20a%20scoliometer%20smartphone%20app%20to%20assess%20scoliosis&as_occt=title&as_sauthors=%20%22OI%20Franko%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Naziri Q</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Detolla J</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Hayes W</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Burekhovich S</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Merola A</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Akamnanu C</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Paulino CB</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">A Systematic Review of All
                  Smart Phone Applications Specifically Aimed for Use as a Scoliosis Screening Tool</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J Long Term Eff Med
                  Implants.</span> 2018;28(1):25-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Systematic%20Review%20of%20All%20Smart%20Phone%20Applications%20Specifically%20Aimed%20for%20Use%20as%20a%20Scoliosis%20Screening%20Tool&as_occt=title&as_sauthors=%20%22Q%20Naziri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Fleiss JL</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Statistical methods for
                  rates and proportions.</span> 1st edn. New York: Wiley; 1981.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Cicchetti DV</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Sparrow SA</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Developing criteria for
                  establishing interrater reliability of specific items: applications to assessment of adaptive
                  behavior</span>. <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Am J
                  Ment Defic.</span> 1981 Sep;86(2):127-37.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Developing%20criteria%20for%20establishing%20interrater%20reliability%20of%20specific%20items%3A%20applications%20to%20assessment%20of%20adaptive%20behavior&as_occt=title&as_sauthors=%20%22DV%20Cicchetti%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Weiner JP</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Bandeian S</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Hatef E</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Lans D</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Liu A</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Lemke KW</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">In-Person and Telehealth
                  Ambulatory Contacts and Costs in a Large US Insured Cohort Before and During the COVID-19
                  Pandemic</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">JAMA
                  Netw Open.</span> 2021 Mar 1;4(3):e212618.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=In-Person%20and%20Telehealth%20Ambulatory%20Contacts%20and%20Costs%20in%20a%20Large%20US%20Insured%20Cohort%20Before%20and%20During%20the%20COVID-19%20Pandemic&as_occt=title&as_sauthors=%20%22JP%20Weiner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Shaver J</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">The State of Telehealth Before
                  and After the COVID-19 Pandemic</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Prim Care.</span> 2022 Dec;49(4):517-30.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20State%20of%20Telehealth%20Before%20and%20After%20the%20COVID-19%20Pandemic&as_occt=title&as_sauthors=%20%22J%20Shaver%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Monaghesh E</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Hajizadeh A</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">The role of telehealth during
                  COVID-19 outbreak: a systematic review based on current evidence</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">BMC Public Health.</span> 2020 Aug
                1;20(1):1193.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20telehealth%20during%20COVID-19%20outbreak%3A%20a%20systematic%20review%20based%20on%20current%20evidence&as_occt=title&as_sauthors=%20%22E%20Monaghesh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Harris R</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Rosecrans A</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Zoltick M</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Willman C</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Saxton R</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Cotterell M</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Bell J</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Blackwell I</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Page KR</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Utilizing telemedicine during
                  COVID-19 pandemic for a low-threshold, street-based buprenorphine program</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Drug Alcohol Depend.</span>
                2022 Jan 1;230:109187.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Utilizing%20telemedicine%20during%20COVID-19%20pandemic%20for%20a%20low-threshold%2C%20street-based%20buprenorphine%20program&as_occt=title&as_sauthors=%20%22R%20Harris%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Ortega G</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Rodriguez JA</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Maurer LR</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Witt EE</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Perez N</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Reich A</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Bates DW</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Telemedicine, COVID-19, and
                  disparities: Policy implications</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Health Policy Technol.</span> 2020 Sep;9(3):368-71.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Telemedicine%2C%20COVID-19%2C%20and%20disparities%3A%20Policy%20implications&as_occt=title&as_sauthors=%20%22G%20Ortega%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Gachabayov M</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Latifi LA</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Parsikia A</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Latifi R</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">The Role of Telemedicine in
                  Surgical Specialties During the COVID-19 Pandemic: A Scoping Review</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">World J Surg.</span> 2022
                Jan;46(1):10-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Role%20of%20Telemedicine%20in%20Surgical%20Specialties%20During%20the%20COVID-19%20Pandemic%3A%20A%20Scoping%20Review&as_occt=title&as_sauthors=%20%22M%20Gachabayov%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Macías-Hernández SI</span>,
                <span data-id="annotation_130" class="annotation" style="position: unset;"> Vásquez-Sotelo DS</span>,
                <span data-id="annotation_131" class="annotation" style="position: unset;"> Ferruzca-Navarro MV</span>,
                <span data-id="annotation_132" class="annotation" style="position: unset;"> Badillo Sánchez SH</span>,
                <span data-id="annotation_133" class="annotation" style="position: unset;"> Gutiérrez-Martínez J</span>,
                <span data-id="annotation_134" class="annotation" style="position: unset;"> Núñez-Gaona MA</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Meneses HA</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Velez-Gutiérrez OB</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Tapia-Ferrusco I</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Soria-Bastida MdeL</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Coronado-Zarco R</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Morones-Alba JD</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Proposal and Evaluation of a
                  Telerehabilitation Platform Designed for Patients With Partial Rotator Cuff Tears: A Preliminary
                  Study</span>. <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Ann
                  Rehabil Med.</span> 2016 Aug;40(4):710-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Proposal%20and%20Evaluation%20of%20a%20Telerehabilitation%20Platform%20Designed%20for%20Patients%20With%20Partial%20Rotator%20Cuff%20Tears%3A%20A%20Preliminary%20Study&as_occt=title&as_sauthors=%20%22SI%20Mac%C3%ADas-Hern%C3%A1ndez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Buvik A</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Bugge E</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Knutsen G</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Småbrekke A</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Wilsgaard T</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Patient reported outcomes with
                  remote orthopaedic consultations by telemedicine: A randomised controlled trial</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J Telemed Telecare.</span>
                2019 Sep;25(8):451-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20reported%20outcomes%20with%20remote%20orthopaedic%20consultations%20by%20telemedicine%3A%20A%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22A%20Buvik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Ajrawat P</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Young Shin D</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Dryan D</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Khan M</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Ravi B</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Veillette C</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Leroux T</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The Use of Telehealth for
                  Orthopedic Consultations and Assessments: A Systematic Review</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2021
                Jul-Aug;44(4):198-206.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Use%20of%20Telehealth%20for%20Orthopedic%20Consultations%20and%20Assessments%3A%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22P%20Ajrawat%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Silva M</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Delfosse EM</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Aceves-Martin B</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Scaduto AA</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Ebramzadeh E</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Telehealth: a novel approach
                  for the treatment of nondisplaced pediatric elbow fractures</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop B.</span> 2019
                Nov;28(6):542-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Telehealth%3A%20a%20novel%20approach%20for%20the%20treatment%20of%20nondisplaced%20pediatric%20elbow%20fractures&as_occt=title&as_sauthors=%20%22M%20Silva%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Pastora-Bernal JM</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Martín-Valero R</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Barón-López FJ</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Moyano NG</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Estebanez-Pérez MJ</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Telerehabilitation after
                  arthroscopic subacromial decompression is effective and not inferior to standard practice: Preliminary
                  results</span>. <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">J
                  Telemed Telecare.</span> 2018 Jul;24(6):428-33.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Telerehabilitation%20after%20arthroscopic%20subacromial%20decompression%20is%20effective%20and%20not%20inferior%20to%20standard%20practice%3A%20Preliminary%20results&as_occt=title&as_sauthors=%20%22JM%20Pastora-Bernal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Russell TG</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Blumke R</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Richardson B</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Truter P</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Telerehabilitation mediated
                  physiotherapy assessment of ankle disorders</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Physiother Res Int.</span> 2010
                Sep;15(3):167-75.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Telerehabilitation%20mediated%20physiotherapy%20assessment%20of%20ankle%20disorders&as_occt=title&as_sauthors=%20%22TG%20Russell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Wood G</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Naudie D</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> MacDonald S</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> McCalden R</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Bourne R</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">An electronic clinic for
                  arthroplasty follow-up: a pilot study</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Can J Surg.</span> 2011 Dec;54(6):381-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20electronic%20clinic%20for%20arthroplasty%20follow-up%3A%20a%20pilot%20study&as_occt=title&as_sauthors=%20%22G%20Wood%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Fahey E</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Elsheikh MFH</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Davey MS</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Rowan F</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Cassidy JT</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Cleary MS</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Telemedicine in Orthopedic
                  Surgery: A Systematic Review of Current Evidence</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">Telemed J E Health.</span> 2022
                May;28(5):613-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Telemedicine%20in%20Orthopedic%20Surgery%3A%20A%20Systematic%20Review%20of%20Current%20Evidence&as_occt=title&as_sauthors=%20%22E%20Fahey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Chanlalit C</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Kongmalai P</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Validation of the
                  telemedicine-based goniometry for measuring elbow range of motion</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">J Med Assoc Thai.</span> 2012 Dec;95(Suppl
                12):S113-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20of%20the%20telemedicine-based%20goniometry%20for%20measuring%20elbow%20range%20of%20motion&as_occt=title&as_sauthors=%20%22C%20Chanlalit%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Goldstein Y</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Schermann H</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Dolkart O</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Kazum E</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Rabin A</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Maman E</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Chechik O</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Video examination via the
                  smartphone: A reliable tool for shoulder function assessment using the constant score</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">J Orthop Sci.</span> 2019
                Sep;24(5):812-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Video%20examination%20via%20the%20smartphone%3A%20A%20reliable%20tool%20for%20shoulder%20function%20assessment%20using%20the%20constant%20score&as_occt=title&as_sauthors=%20%22Y%20Goldstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Iyer S</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Shafi K</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Lovecchio F</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Turner R</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Albert TJ</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Press J</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Katsuura Y</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Sandhu H</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Schwab F</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Qureshi S</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">The Spine Physical Examination
                  Using Telemedicine: Strategies and Best Practices</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">Global Spine J.</span> 2022
                Jan;12(1):8-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Spine%20Physical%20Examination%20Using%20Telemedicine%3A%20Strategies%20and%20Best%20Practices&as_occt=title&as_sauthors=%20%22S%20Iyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Gebbia V</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Piazza D</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Valerio MR</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Borsellino N</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Firenze A</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Patients With Cancer and
                  COVID-19: A WhatsApp Messenger-Based Survey of Patients’ Queries, Needs, Fears, and Actions
                  Taken</span>. <span data-id="emphasis_35" class="annotation emphasis" style="position: unset;">JCO
                  Glob Oncol.</span> 2020 May;6(6):722-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patients%20With%20Cancer%20and%20COVID-19%3A%20A%20WhatsApp%20Messenger-Based%20Survey%20of%20Patients%E2%80%99%20Queries%2C%20Needs%2C%20Fears%2C%20and%20Actions%20Taken&as_occt=title&as_sauthors=%20%22V%20Gebbia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Corden E</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Rogers AK</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Woo WA</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Simmonds R</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Mitchell CD</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">A targeted response to the
                  COVID-19 pandemic: analysing effectiveness of remote consultations for triage and management of
                  routine dermatology referrals</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">Clin Exp Dermatol.</span> 2020 Dec;45(8):1047-50.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20targeted%20response%20to%20the%20COVID-19%20pandemic%3A%20analysing%20effectiveness%20of%20remote%20consultations%20for%20triage%20and%20management%20of%20routine%20dermatology%20referrals&as_occt=title&as_sauthors=%20%22E%20Corden%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Khoshrounejad F</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Hamednia M</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Mehrjerd A</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Pichaghsaz S</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Jamalirad H</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Sargolzaei M</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Hoseini B</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Aalaei S</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Telehealth-Based Services
                  During the COVID-19 Pandemic: A Systematic Review of Features and Challenges</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">Front Public Health.</span>
                2021 Jul 19;9:711762.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Telehealth-Based%20Services%20During%20the%20COVID-19%20Pandemic%3A%20A%20Systematic%20Review%20of%20Features%20and%20Challenges&as_occt=title&as_sauthors=%20%22F%20Khoshrounejad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Mahtta D</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Daher M</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Lee MT</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Sayani S</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Shishehbor M</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Virani SS</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Promise and Perils of
                  Telehealth in the Current Era</span>. <span data-id="emphasis_38" class="annotation emphasis"
                  style="position: unset;">Curr Cardiol Rep.</span> 2021 Jul 16;23(9):115.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Promise%20and%20Perils%20of%20Telehealth%20in%20the%20Current%20Era&as_occt=title&as_sauthors=%20%22D%20Mahtta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Rutledge CM</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Kott K</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Schweickert PA</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Poston R</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Fowler C</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Haney TS</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Telehealth and eHealth in nurse
                  practitioner training: current perspectives</span>. <span data-id="emphasis_39"
                  class="annotation emphasis" style="position: unset;">Adv Med Educ Pract.</span> 2017 Jun
                26;8:399-409.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Telehealth%20and%20eHealth%20in%20nurse%20practitioner%20training%3A%20current%20perspectives&as_occt=title&as_sauthors=%20%22CM%20Rutledge%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Gajarawala SN</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Pelkowski JN</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Telehealth Benefits and
                  Barriers</span>. <span data-id="emphasis_40" class="annotation emphasis" style="position: unset;">J
                  Nurse Pract.</span> 2021 Feb;17(2):218-21.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Telehealth%20Benefits%20and%20Barriers&as_occt=title&as_sauthors=%20%22SN%20Gajarawala%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Rockwell KL</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Gilroy AS</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Incorporating telemedicine as
                  part of COVID-19 outbreak response systems</span>. <span data-id="emphasis_41"
                  class="annotation emphasis" style="position: unset;">Am J Manag Care.</span> 2020
                Apr;26(4):147-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incorporating%20telemedicine%20as%20part%20of%20COVID-19%20outbreak%20response%20systems&as_occt=title&as_sauthors=%20%22KL%20Rockwell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Ji Y</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Ma Z</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Peppelenbosch MP</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Pan Q</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Potential association between
                  COVID-19 mortality and health-care resource availability</span>. <span data-id="emphasis_42"
                  class="annotation emphasis" style="position: unset;">Lancet Glob Health.</span> 2020
                Apr;8(4):e480.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Potential%20association%20between%20COVID-19%20mortality%20and%20health-care%20resource%20availability&as_occt=title&as_sauthors=%20%22Y%20Ji%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Vitacca M</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Montini A</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Comini L</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">How will telemedicine change
                  clinical practice in chronic obstructive pulmonary disease?</span><span data-id="emphasis_43"
                  class="annotation emphasis" style="position: unset;">Ther Adv Respir Dis.</span> 2018
                Jan-Dec;12:1753465818754778.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20will%20telemedicine%20change%20clinical%20practice%20in%20chronic%20obstructive%20pulmonary%20disease%3F&as_occt=title&as_sauthors=%20%22M%20Vitacca%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Speyer R</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Denman D</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Wilkes-Gillan S</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Chen YW</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Bogaardt H</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Kim JH</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Heckathorn DE</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Cordier R</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Effects of telehealth by allied
                  health professionals and nurses in rural and remote areas: A systematic review and
                  meta-analysis</span>. <span data-id="emphasis_44" class="annotation emphasis"
                  style="position: unset;">J Rehabil Med.</span> 2018 Feb 28;50(3):225-35.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20telehealth%20by%20allied%20health%20professionals%20and%20nurses%20in%20rural%20and%20remote%20areas%3A%20A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22R%20Speyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Mehrotra A</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Jena AB</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Busch AB</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Souza J</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Uscher-Pines L</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Landon BE</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Utilization of Telemedicine
                  Among Rural Medicare Beneficiaries</span>. <span data-id="emphasis_45" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2016 May 10;315(18):2015-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Utilization%20of%20Telemedicine%20Among%20Rural%20Medicare%20Beneficiaries&as_occt=title&as_sauthors=%20%22A%20Mehrotra%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Sauers-Ford HS</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Hamline MY</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Gosdin MM</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Kair LR</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Weinberg GM</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Marcin JP</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Rosenthal JL</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Acceptability, Usability, and
                  Effectiveness: A Qualitative Study Evaluating a Pediatric Telemedicine Program</span>. <span
                  data-id="emphasis_46" class="annotation emphasis" style="position: unset;">Acad Emerg Med.</span> 2019
                Sep;26(9):1022-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acceptability%2C%20Usability%2C%20and%20Effectiveness%3A%20A%20Qualitative%20Study%20Evaluating%20a%20Pediatric%20Telemedicine%20Program&as_occt=title&as_sauthors=%20%22HS%20Sauers-Ford%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Gadzinski AJ</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Ellimoottil C</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Telehealth in urology after the
                  COVID-19 pandemic</span>. <span data-id="emphasis_47" class="annotation emphasis"
                  style="position: unset;">Nat Rev Urol.</span> 2020 Jul;17(7):363-4.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Telehealth%20in%20urology%20after%20the%20COVID-19%20pandemic&as_occt=title&as_sauthors=%20%22AJ%20Gadzinski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Sathiyakumar V</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Apfeld JC</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Obremskey WT</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Thakore RV</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Sethi MK</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Prospective randomized
                  controlled trial using telemedicine for follow-ups in an orthopedic trauma population: a pilot
                  study</span>. <span data-id="emphasis_48" class="annotation emphasis" style="position: unset;">J
                  Orthop Trauma.</span> 2015 Mar;29(3):e139-45.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prospective%20randomized%20controlled%20trial%20using%20telemedicine%20for%20follow-ups%20in%20an%20orthopedic%20trauma%20population%3A%20a%20pilot%20study&as_occt=title&as_sauthors=%20%22V%20Sathiyakumar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Iyer S</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Shafi K</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Lovecchio F</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Turner R</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Albert TJ</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Press J</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Katsuura Y</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Sandhu H</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Schwab F</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Qureshi S</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">The Spine Telehealth Physical
                  Examination: Strategies for Success</span>. <span data-id="emphasis_49" class="annotation emphasis"
                  style="position: unset;">HSS J.</span> 2021 Feb;17(1):14-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Spine%20Telehealth%20Physical%20Examination%3A%20Strategies%20for%20Success&as_occt=title&as_sauthors=%20%22S%20Iyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Shafi K</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Lovecchio F</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Forston K</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Wyss J</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Casey E</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Press J</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Creighton A</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Sandhu H</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Iyer S</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">The Efficacy of Telehealth for
                  the Treatment of Spinal Disorders: Patient-Reported Experiences During the COVID-19 Pandemic</span>.
                <span data-id="emphasis_50" class="annotation emphasis" style="position: unset;">HSS J.</span> 2020
                Nov;16(Suppl 1):17-23.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Efficacy%20of%20Telehealth%20for%20the%20Treatment%20of%20Spinal%20Disorders%3A%20Patient-Reported%20Experiences%20During%20the%20COVID-19%20Pandemic&as_occt=title&as_sauthors=%20%22K%20Shafi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Buvik A</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Bugge E</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Knutsen G</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Småbrekke A</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Wilsgaard T</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Quality of care for remote
                  orthopaedic consultations using telemedicine: a randomised controlled trial</span>. <span
                  data-id="emphasis_51" class="annotation emphasis" style="position: unset;">BMC Health Serv Res.</span>
                2016 Sep 8;16(1):483.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quality%20of%20care%20for%20remote%20orthopaedic%20consultations%20using%20telemedicine%3A%20a%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22A%20Buvik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Lightsey HM</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Yeung CM</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Samartzis D</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Makhni MC</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">The past, present, and future
                  of remote patient monitoring in spine care: an overview</span>. <span data-id="emphasis_52"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2021
                Aug;30(8):2102-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20past%2C%20present%2C%20and%20future%20of%20remote%20patient%20monitoring%20in%20spine%20care%3A%20an%20overview&as_occt=title&as_sauthors=%20%22HM%20Lightsey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Glinkowski WM</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Orthopedic Telemedicine
                  Outpatient Practice Diagnoses Set during the First COVID-19 Pandemic Lockdown-Individual
                  Observation</span>. <span data-id="emphasis_53" class="annotation emphasis"
                  style="position: unset;">Int J Environ Res Public Health.</span> 2022 Apr 29;19(9):5418.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Orthopedic%20Telemedicine%20Outpatient%20Practice%20Diagnoses%20Set%20during%20the%20First%20COVID-19%20Pandemic%20Lockdown-Individual%20Observation&as_occt=title&as_sauthors=%20%22WM%20Glinkowski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Hogue GD</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Liu DS</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Kaushal SG</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Tavabi N</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Feldman L</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Stracciolini A</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Shore B</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Hedequist D</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Bae D</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Meehan W</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Kocher M</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Murray MM</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Kiapour AM</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Telehealth Potential in
                  Pediatric Orthopaedics and Sports Medicine Care is Comparable to In-Person Care But Disparities
                  Remain</span>. <span data-id="emphasis_54" class="annotation emphasis" style="position: unset;">J
                  Pediatr Orthop.</span> 2024 Jul 1;44(6):379-85.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Telehealth%20Potential%20in%20Pediatric%20Orthopaedics%20and%20Sports%20Medicine%20Care%20is%20Comparable%20to%20In-Person%20Care%20But%20Disparities%20Remain&as_occt=title&as_sauthors=%20%22GD%20Hogue%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Williams TL</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> May CR</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Esmail A</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">Limitations of patient
                  satisfaction studies in telehealthcare: a systematic review of the literature</span>. <span
                  data-id="emphasis_55" class="annotation emphasis" style="position: unset;">Telemed J E Health.</span>
                2001 Winter;7(4):293-316.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Limitations%20of%20patient%20satisfaction%20studies%20in%20telehealthcare%3A%20a%20systematic%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22TL%20Williams%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Polinski JM</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Barker T</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Gagliano N</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Sussman A</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Brennan TA</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Shrank WH</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Patients’ Satisfaction with and
                  Preference for Telehealth Visits</span>. <span data-id="emphasis_56" class="annotation emphasis"
                  style="position: unset;">J Gen Intern Med.</span> 2016 Mar;31(3):269-75.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patients%E2%80%99%20Satisfaction%20with%20and%20Preference%20for%20Telehealth%20Visits&as_occt=title&as_sauthors=%20%22JM%20Polinski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;083d1ab1-a056-4800-95a6-2315762e4acc&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=083d1ab1-a056-4800-95a6-2315762e4acc&type=supplement&name=12497"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D083d1ab1-a056-4800-95a6-2315762e4acc%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2249</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01146</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 2, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_57"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Boston
                            Children’s Hospital, Boston, Massachusetts</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;083d1ab1-a056-4800-95a6-2315762e4acc&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=083d1ab1-a056-4800-95a6-2315762e4acc&type=pdf&name=JBJS.23.01146.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=083d1ab1-a056-4800-95a6-2315762e4acc&type=pdf&name=JBJS.23.01146.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_61"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this study. The <span data-id="strong_62" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I224"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I224</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;083d1ab1-a056-4800-95a6-2315762e4acc&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=083d1ab1-a056-4800-95a6-2315762e4acc&type=zip&name=JBJS.23.01146.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alexander R. Farid, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4558-3900" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4558-3900</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">M. Timothy Hresko, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8769-0969" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8769-0969</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Semhal Ghessese, MD, MPH<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9751-9180" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9751-9180</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Gabriel S. Linden, BA<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-6792-9395" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-6792-9395</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Stephanie Wong, BS<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9144-7841" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9144-7841</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daniel Hedequist, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4067-5665" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4067-5665</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Craig Birch, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5168-4623" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5168-4623</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Danielle Cook, MA<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-5327-7903" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-5327-7903</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kelsey Mikayla Flowers, MA<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6566-046X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6566-046X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Grant D. Hogue, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:grant.hogue@childrens.harvard.edu" class=""
                    style="position: unset;">grant.hogue@childrens.harvard.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7507-0511" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7507-0511</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Harvard Combined Orthopaedic Residency Program, Boston, Massachusetts</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Harvard Medical School, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Boston Children’s Hospital, Harvard Medical
                School, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 30775.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
