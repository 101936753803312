/* -----------------Third parties--------------- */
import { List, type ListItemProps } from "react-native-paper";

import { ExamContextListItem } from "@features/dashboard/";
import type { Features } from "@hooks/useGlobalStore";
import type { ViewStyle } from "react-native";
/* -----------------Child components--------------- */
import DarkModeSwitch from "./DarkModeSwitch";
import FeatureToggleSwitch from "./FeatureToggleSwitch";

/* -----------------Props--------------- */
type Props = {
	type?: string;
	icon: string;
	options?: {
		title: string;
		description: string;
	}[];

	feature?: Features;
	iconRight?: string;
} & ListItemProps;

/* -----------------Component--------------- */
const AccountListSection = (props: Props) => {
	const { type, title, description, icon, onPress, iconRight, feature } = props;

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const renderLeft = (p: any) => {
		if (icon.includes(".")) {
			return (
				<List.Image
					{...p}
					variant="image"
					source={{ uri: icon }}
					style={{
						...p.style,
						borderRadius: "20%",
					}}
				/>
			);
		}
		return <List.Icon {...p} icon={icon} />;
	};
	const renderRight = (propsRight: {
		color: string;
		style?: ViewStyle;
	}) => {
		if (type === "toggle") {
			return <DarkModeSwitch />;
		}
		if (feature) {
			return <FeatureToggleSwitch feature={feature} />;
		}
		if (iconRight) {
			return <List.Icon {...propsRight} icon={iconRight} />;
		}

		return null;
	};

	const RenderListitem = () => (
		<List.Item
			title={title}
			description={description}
			onPress={onPress}
			style={{
				paddingLeft: 0,
				paddingRight: 16,
			}}
			left={renderLeft}
			right={renderRight}
		/>
	);
	switch (type) {
		case "exam-filter":
			return <ExamContextListItem title={title as string} icon={icon} />;
		default:
			return <RenderListitem />;
	}
};

export default AccountListSection;
