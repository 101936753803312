/* -----------------UI--------------- */
import { FlatList, StyleSheet, TouchableOpacity } from "react-native";

import { Image } from "@memorang/ui";
import { type AppTheme, useAppTheme } from "../../useAppTheme";
import { useDevice } from "../../useDevice";
/* -----------------Third parties--------------- */
import { avatarContainerSize, avatarSize } from "./constants";

export type Avatar = {
	id: string;
	url: string;
};

/* -----------------Props--------------- */
type Props = {
	data: Avatar[];
	handleSelectAvatar: (id: string) => void;
	selectedAvatarId?: string;
};

type AvatarItemProps = {
	item: Avatar;
	handleSelectAvatar: (avatar: string) => void;
	isSelected: boolean;
	theme: AppTheme;
};

const getStyles = (theme: AppTheme) =>
	StyleSheet.create({
		avatar: {
			width: avatarContainerSize,
			height: avatarContainerSize,
			borderRadius: avatarContainerSize / 2,
			margin: 4,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: theme.colors.elevation.level5,
		},
		avatarSelected: {
			// backgroundColor: theme.colors.elevation.level1,
			borderWidth: 3,
			borderColor: theme.colors.primary,
		},
		columnWrapper: {
			justifyContent: "space-around",
		},
	});

/* -----------------AvatarItem Component--------------- */
const AvatarItem = ({
	item,
	handleSelectAvatar,
	isSelected,
	theme,
}: AvatarItemProps) => {
	const styles = getStyles(theme);
	return (
		<TouchableOpacity
			onPress={() => handleSelectAvatar(item.id)}
			style={[styles.avatar, isSelected && styles.avatarSelected]}
			key={item.id}
		>
			<Image
				style={{
					width: avatarSize,
					height: avatarSize,
					borderRadius: avatarSize / 2,
				}}
				contentFit="contain"
				source={{ uri: item.url }}
			/>
		</TouchableOpacity>
	);
};

/* -----------------AvatarList Component--------------- */
const AvatarList = (props: Props) => {
	const { data, handleSelectAvatar, selectedAvatarId } = props;

	const theme = useAppTheme();

	const styles = getStyles(theme);

	const { isMobile } = useDevice();

	const numColumns = isMobile ? 3 : 4;

	const renderItem = ({ item }: { item: Avatar }) => (
		<AvatarItem
			item={item}
			handleSelectAvatar={handleSelectAvatar}
			isSelected={selectedAvatarId === item.id}
			theme={theme}
		/>
	);

	return (
		<FlatList
			renderItem={renderItem}
			data={data}
			showsVerticalScrollIndicator={false}
			keyExtractor={(item) => item.id}
			numColumns={numColumns}
			contentContainerStyle={{
				padding: 16,
			}}
			initialNumToRender={40}
			columnWrapperStyle={styles.columnWrapper}
		/>
	);
};

export default AvatarList;
