/* -----------------Types--------------- */
import type { BundleDetails, MixedItemDistResponse } from "@memorang/types";
import type { IconType } from "components/index";

import { isScreenshotMode } from "@constants/common";
import { mockedProgressHomeViewStats } from "@constants/mocked-data";
/* -----------------Helpers & Hooks--------------- */
import { groupTagsByType, hasBundleAccess } from "@helpers/content/tag";
import { randomizeDistribution } from "@helpers/mocked-helpers";

import theme from "@configs/theme";
import { getParentMetadata } from "@helpers/content/metadata";
/* -----------------Child components--------------- */
import { getSectionTags } from "./tag";

const getDashboardData = (
	response: MixedItemDistResponse,
	currentlySelectedBundleDetails: BundleDetails,
	handleNavigateToIAP: () => void,
	groupByCategory?: boolean | null,
) => {
	const { getMixedItemDistribution } = response;

	const { contents, iconUrl, title, tags, freeTagIds } =
		getMixedItemDistribution;
	const numItems = contents.reduce((a, c) => a + c.totalCount, 0);

	const metadata = getParentMetadata(
		getMixedItemDistribution.contents,
		numItems,
	);
	const { distribution } = metadata;
	const hasAccess = hasBundleAccess(currentlySelectedBundleDetails);
	const tagsByType = groupTagsByType(tags, "Display", freeTagIds, hasAccess);
	const sectionTags = getSectionTags(
		numItems,
		tagsByType,
		handleNavigateToIAP,
		hasAccess,
	);

	const { high, low, medium, none } = distribution;
	const masteryPercentage = Math.round(
		(high / (high + none + low + medium)) * 100,
	);

	const sections = Object.keys(tagsByType)
		.filter((type) => tagsByType[type].length > 0)
		.map((type) => {
			return {
				...sectionTags[0],
				title: type,
				tags: tagsByType[type].sort((a, b) => a.title.localeCompare(b.title)),
			};
		});

	const finalSectionTags = groupByCategory
		? sections
				.map((section) => {
					const { tags } = section;
					const newTags = tags.map((tag) => {
						const { distribution } = tag;

						return {
							...tag,
							distribution: isScreenshotMode
								? randomizeDistribution(distribution)
								: distribution,
						};
					});
					return {
						...section,
						tags: newTags,
					};
				})
				.sort((a, b) => {
					return a.title.localeCompare(b.title);
				})
		: sectionTags;
	return {
		distribution: isScreenshotMode
			? randomizeDistribution(distribution)
			: distribution,
		productTitle: title,
		productImage: iconUrl,
		sectionTags: finalSectionTags,
		masteryPercentage,
		numItems,
	};
};

const getStats = ({
	masteryPercentage,
	numAnswered,
	xp,
}: {
	masteryPercentage: number;
	numAnswered: number;
	xp?: number;
}) => {
	const stats = isScreenshotMode
		? mockedProgressHomeViewStats
		: [
				{
					label: "Mastered",
					icon: "brain" as IconType,
					value: `${masteryPercentage}%`,
					color: theme().palette.purple.main,
				},
				{
					label: "Answered",
					icon: "check-decagram-outline" as IconType,
					value: numAnswered,
					color: theme().colors.secondary,
				},
				{
					label: "XP earned",
					icon: "medal-outline" as IconType,
					value: xp ? xp.toLocaleString() : 0,
					color: theme().colors.error,
				},
			];
	return stats;
};
export { getDashboardData, getStats };
