import { commitMutation, graphql } from "react-relay";

import { relayEnv } from "@memorang/configs";

type Input = {
	paymentId: string;
	source: string;
	productId: string;
	planId: string;
	amount: number;
	tenantId?: string;
};

type Response = {
	handlePaymentSuccess: boolean;
};
const mutation = graphql`
  mutation HandlePaymentSuccessMutation($req: PaymentSuccessRequest!) {
    handlePaymentSuccess(req: $req)
  }
`;

const handlePaymentSuccess = async (req: Input) => {
	const promise = new Promise((resolve, reject) => {
		const variables = {
			req,
		};

		commitMutation(relayEnv, {
			mutation,
			variables,
			onCompleted: (response) => {
				const finalResponse = response as Response;
				resolve(finalResponse.handlePaymentSuccess);
			},
			onError: (error) => {
				reject(error);
			},
		});
	});
	return promise;
};

export default handlePaymentSuccess;
