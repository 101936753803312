import type { Article } from "../../../../../types";

export const Article627: Article = {
	id: 627,
	rsuiteId: "b3029aef-3521-49b3-9723-efc1ec840798",
	type: "scientific article",
	title:
		"Intraoperative Facet Joint Block Reduces Pain After Oblique Lumbar Interbody Fusion",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b3029aef-3521-49b3-9723-efc1ec840798&type=jpeg&name=JBJS.23.01480f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Intraoperative Facet Joint Block Reduces Pain After
                Oblique Lumbar Interbody Fusion</div>
              <div class="text subtitle" style="position: unset;">A Double-Blinded, Randomized, Placebo-Controlled
                Clinical Trial</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Sung Hyeon
                      Noh, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Sang-Woo Lee,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Background:</span> Oblique lumbar interbody fusion (OLIF) results in
                        less tissue damage than in other surgeries, but immediate postoperative pain occurs. Notably,
                        facet joint widening occurs in the vertebral body after OLIF. We hypothesized that the
                        application of a facet joint block to the area of widening would relieve facet joint pain. The
                        purpose of this study was to evaluate the analgesic effects of such injections on postoperative
                        pain.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Methods:</span> This double-blinded, placebo-controlled study
                        randomized patients into 2 groups. Patients assigned to the active group received an
                        intra-articular injection of a compound mixture of bupivacaine and triamcinolone, whereas
                        patients in the placebo group received an equivalent volume of normal saline solution injection.
                        Back and dominant leg pain were evaluated with use of a visual analog scale (VAS) at 12, 24, 48,
                        and 72 hours postoperatively. Clinical outcomes were evaluated preoperatively and at 6 months
                        postoperatively with use of the Oswestry Disability Index (ODI) and VAS for back and dominant
                        leg pain.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Results:</span> Of the 61 patients who were included, 31 were
                        randomized to the placebo group and 30 were randomized to the active group. Postoperative
                        fentanyl consumption from patient-controlled analgesia was higher in the placebo group than in
                        the active group at up to 36 hours postoperatively (p &lt; 0.001) and decreased gradually in
                        both groups. VAS back pain scores were significantly higher in the placebo group than in the
                        active group at up to 48 hours postoperatively. On average, patients in the active group had a
                        higher satisfaction score (p = 0.038) and were discharged 1.3 days earlier than those in the
                        placebo group.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The use of an intraoperative facet joint block
                        decreased pain perception during OLIF, thereby reducing opioid consumption and the severity of
                        postoperative pain. This effect was also associated with a reduction in the length of the stay.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level I</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Although the primary goal of lumbar surgery is to relieve
                  pain, the procedure itself can, paradoxically, cause postoperative pain. Strong narcotics or
                  analgesics are often required to alleviate this postoperative discomfort. However, these medications
                  can lead to complications such as delirium, nausea, vomiting, and respiratory depression. Therefore,
                  it is preferable to minimize the use of narcotics and analgesics after surgery<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Recently, the use of oblique lumbar interbody fusion
                  (OLIF) has gradually increased<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>. OLIF
                  is an effective surgical method because it minimizes muscle injury and enables nerve release without
                  direct decompression<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">5</span></a>. In open lumbar surgery, severe pain, typically associated
                  with muscle, ligament, and bone damage, is felt immediately after lumbar surgery and may reduce
                  patient compliance with surgical aftercare<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">7</span></a>. In the case of OLIF, research has shown that, although the
                  damage to tissues around the spine (including injuries to muscle, ligament, and bone) is significantly
                  less than that which occurs in other surgeries, immediate postoperative pain occurs<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">8</span></a>. Widening of the facet joint has been noted among the
                  structural changes in the vertebral body after OLIF<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">4</span></a>.
                  Facet joints are small joints located between and behind adjacent vertebrae of the spine<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">9</span></a>. Facet joints are innervated by various nerves and are a
                  common cause of lower back pain and buttock pain<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">9</span></a>.
                  Therefore, to alleviate such pain, a facet joint block is usually performed. We hypothesized that a
                  facet joint block applied to the area of facet joint widening that occurs after OLIF surgery would be
                  effective in relieving facet joint pain. The aim of this study was to evaluate the analgesic effects
                  of facet joint injections on pain following OLIF.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The study protocol was approved by the institutional
                  review board of our hospital (KNUH 2022-05-007-002) and registered in CRIS (Clinical Research
                  Information Service; KCT0007839). This study was conducted in accordance with the principles of the
                  2000 Declaration of Helsinki, and written informed consent was obtained from all participants before
                  their inclusion in the trial. This manuscript complies with the applicable CONSORT (Consolidated
                  Standards of Reporting Trials) guidelines.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Subjects</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">All patients who underwent elective 1-level OLIF under
                  general anesthesia between January 2022 and March 2023 were assessed for study eligibility. The
                  inclusion criteria were the presence of degenerative spondylolisthesis on dynamic digital radiography
                  and magnetic resonance imaging, and persistent lower back pain or radiating leg pain after 6 weeks of
                  conservative treatment. Patients ranged from 35 to 79 years of age. The exclusion criteria were as
                  follows: weight of &lt;45 kg or &gt;100 kg; severe underlying respiratory, renal, hepatic, or
                  cardiologic disease; psychotic disease; a history of allergic reactions to local anesthetics; evidence
                  of previous opioid use; or a psychiatric medical history. The decision to enroll or exclude a patient
                  was made by 2 investigators (S.H.N. and E.L.), who did not otherwise participate in conducting the
                  study or collecting data.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study Design
                and Randomization</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">This randomized, double-blinded, placebo-controlled study
                  separated patients into 2 groups based on a random number table generated with use of R (version
                  4.0.2; R Foundation for Statistical Computing). To prevent imbalances in treatment assignments, block
                  randomization with a block size of 4 or 6 and equal allocation was utilized. The randomization
                  sequence was generated by a statistician who was not involved in the study. The details of the
                  sequence were unknown to the investigators, and the group assignments were kept in sealed envelopes,
                  with each envelope bearing only the case number on the outside. After admitting the patient to the
                  operating room, and immediately before the administration of anesthesia, a numbered envelope was
                  opened, and the card inside determined the group assignment for that patient. Patients assigned to the
                  active group received an intra-articular injection of a compound mixture of bupivacaine and
                  triamcinolone, whereas those assigned to the placebo group received the same amount of normal saline
                  solution injection. All of the facet joint injections were performed by a single anesthesiologist who
                  was kept blinded by being provided with the medications without labels. All parties involved—patients,
                  surgeons, anesthesiologists, and the investigator who prepared the drugs and collected data—were
                  blinded to the study drugs and patient group assignments, with the exception of the study coordinator
                  (J.-m.H.).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">OLIF
                Procedure</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Surgery was performed by a single surgeon (K.-T.K.) with
                  use of a previously described OLIF technique<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">3</span></a>. OLIF
                  involves a minimally invasive lateral interbody fusion that is performed with use of a left-sided
                  retroperitoneal approach and percutaneous pedicle screw fixation. The intervertebral disc is exposed
                  through an open corridor between the psoas muscle and the aorta. The procedure was performed with use
                  of an OLIF system (Medtronic), fusion material (Grafton; Medtronic), and a percutaneous pedicle screw
                  fixation system (CD Horizon Longitude; Medtronic). A 10-mm cage was inserted if the intervertebral
                  disc space was &lt;4 mm, whereas a cage 6 mm greater than the disc height was inserted if the
                  intervertebral disc space was ≥4 mm. Cage selection was performed by considering the general lumbar
                  disc height<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">12</span></a>. No patient underwent posterior decompression.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Facet Joint
                Injection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Under fluoroscopic guidance, the Scottie dog sign was
                  verified on the oblique view at the level of the spondylolisthesis. The Scottie dog sign represents a
                  normal appearance of the lumbar spine on an oblique view<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a>. A 24-gauge spinal needle was meticulously advanced into
                  the intra-articular space of the facet joint. Contrast medium (0.5 mL) was injected in order to verify
                  the precise positioning of the needle within the facet joint. Proper placement was characterized by
                  linear contrast distribution along the intra-articular space (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). Once
                  the optimal positioning of the needle was confirmed, an injection was administered comprising 3 mL of
                  a local anesthetic agent combined with steroids (a compound mixture of 0.5% bupivacaine [2 mL] and
                  triamcinolone [1 mL]) for the active group or an equivalent volume of normal saline solution for the
                  placebo group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01480f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b3029aef-3521-49b3-9723-efc1ec840798&type=jpeg&name=JBJS.23.01480f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;">Fluoroscopic images of a facet joint block.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Collection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Back and dominant leg pain were evaluated with use of a
                  visual analog scale (VAS) at 12, 24, 48, and 72 hours postoperatively. The frequency with which
                  patients pushed the button (FPB) of the patient-controlled analgesia (PCA) system was evaluated at 24
                  and 48 hours postoperatively. Data on fentanyl consumption were collected at 12, 24, 36, and 48 hours
                  postoperatively. Other outcomes included satisfaction scores regarding pain control and the overall
                  recovery process, the incidence of postoperative nausea and vomiting (PONV), and the length of stay
                  (LOS). The white blood-cell (WBC) count, erythrocyte sedimentation rate (ESR), and C-reactive protein
                  (CRP) level were evaluated at the same time (8 <span data-id="custom_annotation_1"
                    class="annotation sc" style="position: unset;">a</span>.<span data-id="custom_annotation_2"
                    class="annotation sc" style="position: unset;">m</span>.) at admission and at 1, 3, and 5 days
                  postoperatively<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">15</span></a>.
                  All outcome assessments were performed by a single investigator (J.-m.H.) blinded to the study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">During the preoperative visit, patients and the
                  investigator who was responsible for data collection were trained on using the VAS to assess pain
                  (with 0 indicating “no pain” and 10 indicating “worst pain imaginable”) and on operating the PCA
                  device. A protocol for instruction on their use was provided. Furthermore, the LOS was recorded for
                  each patient based on the number of days from admission to discharge. The criteria for discharge
                  included the ability to walk or perform self-care independently, no indications of wound issues, no
                  signs of infection or elevated inflammatory parameters, and pain management with oral analgesics.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical and
                Radiographic Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Clinical outcomes were evaluated preoperatively and at 6
                  months postoperatively with use of the Oswestry Disability Index (ODI) and VAS for back and dominant
                  leg pain. Intervertebral disc height was defined as the distance between the superior and inferior end
                  plates, measured along a vertical line originating from the center of the anteroposterior diameter of
                  the inferior vertebral body. The spondylolisthesis distance was defined as the distance between the
                  anterior margins of the superior and inferior vertebral bodies. Subsidence of the cage was determined
                  by comparing the distance between the superior and inferior end plates at follow-up with the
                  intraoperative distance. Subsidence was classified according to the percentage loss of disc height
                  postoperatively, as follows: none, &lt;10%; mild, 10% to 24%; moderate, 25% to 49%; and severe, 50% to
                  100%<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">16</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Continuous data that were normally distributed are
                  presented as means and standard deviations (SDs), and those that were non-normally distributed are
                  presented as medians and interquartile ranges (IQRs). Categorical data are presented as counts and
                  percentages. Continuous variables with a normal distribution were analyzed with use of the Student t
                  test, and those with a non-normal distribution were analyzed with use of the Mann-Whitney U test.
                  Categorical data were analyzed with use of the Fisher exact test or chi-square test. To estimate the
                  required sample size, a pilot study was conducted with VAS pain score as the primary outcome variable,
                  which was measured at 12 hours postoperatively<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">17</span></a>.
                  Postoperative VAS pain scores were measured in 10 patients (placebo group, 5 patients; active group, 5
                  patients) who received either normal saline solution or lidocaine intravenously<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">19</span></a>. The VAS pain score at 12 hours postoperatively was 6.3 in
                  the placebo group and 5.1 in the active group. We wanted to demonstrate a between-group difference of
                  10 in the VAS pain score at 12 hours postoperatively. With a significance level of 0.05 and a power of
                  0.8, 24 patients were required in each group. Estimating a loss to follow-up rate of 20%, 61 patients
                  were included in the study. All of the statistical analyses were performed with use of IBM SPSS
                  (version 25). Significance was set at p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Demographics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Of 82 patients who were assessed for eligibility, 13 did
                  not meet the inclusion criteria and 4 declined to participate in the study. After randomization, 2
                  patients in the placebo group and 1 in the active group were treated with other painkillers because of
                  PONV that was unresponsive to antiemetic treatment and that was likely induced by fentanyl injection
                  and and were thus excluded (i.e., counted as lost to follow-up at 2 weeks), and 1 patient was lost to
                  follow-up at 6 months. Therefore, a total of 61 patients were included in the final analysis, with 31
                  in the placebo group and 30 in the active group (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). There
                  were no significant differences between the 2 groups in terms of basic demographics (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01480f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b3029aef-3521-49b3-9723-efc1ec840798&type=jpeg&name=JBJS.23.01480f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">CONSORT flow diagram for the study. LOCF = last
                          observation carried forward, PONV = postoperative nausea and vomiting.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographics and Preoperative Values<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1233px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Placebo
                          Group (N = 31)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Active
                          Group (N = 30)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66.1 ±
                          9.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.3 ±
                          7.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.706</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male sex <span
                            data-id="emphasis_33" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (52%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (37%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.306</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Height <span
                            data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">161 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">160 ±
                          8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.575</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Weight <span
                            data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(kg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.2 ±
                          9.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61.8 ±
                          9.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.586</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Body mass index <span
                            data-id="emphasis_36" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_37" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_16" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_16"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_38"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.4 ±
                          2.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.2 ±
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.802</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diabetes mellitus <span
                            data-id="emphasis_39" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (32%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (37%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.178</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hypertension <span
                            data-id="emphasis_40" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (29%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (33%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.412</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative analgesics
                          <span data-id="emphasis_41" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.516</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Bone mineral density
                          <span data-id="emphasis_42" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.254</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Normal (T-score
                          &gt;−1.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (52%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (33%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteopenia (T-score −1.0
                          to ∼−2.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (35%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (40%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteoporosis (T-score
                          ≤−2.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (13%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (27%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical lumbar level
                          <span data-id="emphasis_43" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.191</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> L2-3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> L3-4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (23%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> L4-5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (65%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (87%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> L5-S1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Degenerative
                          spondylolisthesis <span data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(no. of patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.749</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Grade 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (77%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (83%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Grade 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (23%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (17%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD or as the count, with or without the
                        percentage in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Pain
                Intensity, Opioid Consumption, and Side Effects of the Drug</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">VAS back pain scores were significantly higher in the
                  placebo group than in the active group at up to 48 hours postoperatively (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). In both groups, the postoperative VAS back pain score was the
                  highest at 12 hours and gradually diminished over time. The FPB was significantly higher in the
                  placebo group than in the active group at up to 48 hours postoperatively (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). Postoperative fentanyl consumption from PCA was higher in
                  the placebo group than in the active group at up to 36 hours (p &lt; 0.001) and decreased gradually in
                  both groups (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>). In
                  terms of the total amount of fentanyl injected, the active group required significantly less analgesia
                  than the placebo group (p &lt; 0.001; <a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). There
                  was no difference in PONV between the active group and the placebo group (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01480f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b3029aef-3521-49b3-9723-efc1ec840798&type=jpeg&name=JBJS.23.01480f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">Changes in visual analog scale (VAS) back pain
                          scores after OLIF. The horizontal yellow line represents the median, the boundaries of the box
                          represent the IQR, and the whiskers represent points within 1.5 times the IQR width of the
                          box. POH = postoperative hour.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Pain and Opioid Consumption at Up to 3 Days After
                          Surgery<span data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1285px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Placebo
                          Group (N = 31)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Active
                          Group (N = 30)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VAS lower back
                          pain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.0
                          (3.5-7.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0
                          (4.0-6.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.751</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POH12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.0
                          (5.0-7.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0
                          (4.0-5.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POH24</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0
                          (4.5-6.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.5
                          (2.3-4.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POH48</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0
                          (3.0-5.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (2.0-3.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POH72</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0
                          (2.0-3.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (1.0-2.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.075</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VAS pain in dominant
                          leg</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.0
                          (6.0-7.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.0
                          (5.0-7.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.239</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POH12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.0
                          (6.0-7.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0
                          (4.0-5.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.057</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POH24</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0
                          (4.0-5.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0
                          (3.0-4.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.348</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POH48</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0
                          (3.0-4.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0
                          (2.0-3.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.083</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POH72</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (1.0-2.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (2.0-2.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.363</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">FPB</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.9
                          (34.5-44.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.7
                          (25.5-33.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 0 to 24 hours</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.5
                          (25.5-32.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.1
                          (18.0-23.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &gt;24 to 48
                          hours</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.4
                          (9.0-13.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.6
                          (6.0-9.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean total fentanyl
                          consumption at 48 hours <span data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(µg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,103.1 ±
                          198.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">833.2 ±
                          152.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fentanyl usage per hour
                          <span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(µg/hr)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 0 to 12 hours</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40.3
                          (38.0-45.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.8
                          (28.0-35.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &gt;12 to 24
                          hours</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.1
                          (23.0-26.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.3
                          (15.0-17.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &gt;24 to 36
                          hours</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.2
                          (16.0-22.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.7
                          (10.5-16.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &gt;36 to 48
                          hours</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.4
                          (6.5-10.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.7
                          (6.5-10.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.458</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the median, with the IQR in parentheses, or as the
                        mean ± SD. VAS = visual analog scale, POH = postoperative hour, FPB = frequency in which
                        patients pushed the button.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01480f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b3029aef-3521-49b3-9723-efc1ec840798&type=jpeg&name=JBJS.23.01480f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;">Frequency with which patients pushed the button
                          (FPB) for fentanyl (<span data-id="strong_39" class="annotation strong"
                            style="position: unset;">Fig. 4-A</span>) and fentanyl consumption per hour (the sum of
                          additional intravenous fentanyl bolus injections and the fentanyl delivered by the
                          patient-controlled analgesia system) (<span data-id="strong_40" class="annotation strong"
                            style="position: unset;">Fig. 4-B</span>) after OLIF. The horizontal yellow line represents
                          the median, the boundaries of the box represent the IQR, the whiskers represent points within
                          1.5 times the IQR width of the box, and the circles represent outliers.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Duration of Surgery, LOS, Satisfaction Score, and
                          Perioperative Complications<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 572px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Placebo
                          Group (N = 31)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Active
                          Group (N = 30)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Duration of surgery <span
                            data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;">(min)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">137.5 ±
                          25.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">142.3 ±
                          21.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.436</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PONV <span
                            data-id="emphasis_48" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nausea</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (23%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (20%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.527</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Vomiting</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.516</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LOS <span
                            data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(days)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.1 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.8 ±
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.006</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Satisfaction score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.7 ±
                          0.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.2 ±
                          0.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.038</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Perioperative
                          complications <span data-id="emphasis_50" class="annotation emphasis"
                            style="position: unset;">(no. of patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Transient
                          numbness</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (13%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.519</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Transient psoas
                          weakness</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.648</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD or as the count, with or without the
                        percentage in parentheses. PONV = postoperative nausea and vomiting, LOS = length of
                        stay.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Duration of
                Surgery, LOS, Satisfaction Score, and Perioperative Complications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The duration of surgery did not differ between the 2
                  groups. With respect to the LOS, there was a significant difference between the groups, with patients
                  in the active group discharged a mean of 1.3 days earlier than those in the placebo group (p = 0.006;
                  <a href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). Patients in the active group also had a higher mean
                  satisfaction score (p = 0.038; <a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  Complications related to the surgical approach, such as thigh numbness and psoas weakness, occurred in
                  both groups, without a significant difference between the groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical and
                Radiographic Outcomes at 6 Months Postoperatively</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The clinical outcomes at 6 months postoperatively were
                  compared between the placebo group and the active group (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>). VAS
                  back and leg pain and ODI scores did not differ between the groups preoperatively or at 6 months
                  postoperatively. There was no difference in the preoperative and postoperative spondylolisthesis
                  distance between the groups. The postoperative intervertebral disc height was significantly higher in
                  the active group (14.1 ± 1.5 mm) than in the placebo group (13.2 ± 1.4 mm; p = 0.017). The groups did
                  not differ with respect to mean cage subsidence values or cage subsidence grades (<a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Clinical and Radiographic Outcomes<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1623px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Placebo
                          Group (N = 31)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Active
                          Group (N = 30)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Clinical outcomes</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  VAS back pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.2 ±
                          2.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0 ±
                          1.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.751</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  VAS leg pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.6 ±
                          1.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.2 ±
                          1.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.239</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  ODI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.0 ±
                          6.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.5 ±
                          4.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.759</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 6-month postop.</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  VAS back pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.1 ±
                          1.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.2 ±
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.635</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  VAS leg pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.8 ±
                          1.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.8 ±
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.816</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  ODI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.8 ±
                          5.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.7 ±
                          3.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.931</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Change from preop. to
                          6-month postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  VAS back pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1 ±
                          1.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8 ±
                          0.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.423</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  VAS leg pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.8 ±
                          1.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4 ±
                          0.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.269</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  ODI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.2 ±
                          4.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.8 ±
                          3.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.891</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Complications <span
                            data-id="emphasis_51" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Transient numbness,
                          left side</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (13%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.519</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Transient psoas
                          weakness, left side</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.648</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiographic
                          outcomes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop. <span
                            data-id="emphasis_52" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  LD</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.3 ±
                          3.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.5 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.793</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  DH</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.8 ±
                          2.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.5 ±
                          2.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.303</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 6-month postop. <span
                            data-id="emphasis_53" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  LD</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.9 ±
                          1.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1 ±
                          1.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.626</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  DH</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.2 ±
                          1.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.1 ±
                          1.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.017</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Change from preop. to
                          6-month postop. <span data-id="emphasis_54" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  LD correction</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.992</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  DH increase</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.4 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.6 ±
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.614</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean cage subsidence
                          <span data-id="emphasis_55" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1 ±
                          2.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8 ±
                          1.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.512</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cage subsidence grade
                          <span data-id="emphasis_56" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  None</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (68%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (77%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mild</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (23%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (23%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Moderate</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Severe</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD or as the count, with or without the
                        percentage in parentheses. VAS = visual analog scale, ODI = Oswestry Disability Index, LD =
                        spondylolisthesis distance, DH = intervertebral disc height.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">A p value is not provided because there were 0 cases in the active
                        group in the Moderate and Severe categories.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Inflammatory
                Markers</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The WBC count, ESR, and CRP level at each time point are
                  shown in <a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>. The WBC
                  count and ESR did not significantly differ between the groups at any time point, but CRP measurements
                  were significantly lower in the active group on postoperative days 1, 3, and 5 (p &lt; 0.05; <a
                    href="" data-id="figure_reference_14" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Effects of the Facet Joint Block on Inflammatory
                          Markers<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 729px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Placebo
                          Group (N = 31)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Active
                          Group (N = 30)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">WBC count <span
                            data-id="emphasis_57" class="annotation emphasis" style="position: unset;">(10</span><span
                            data-id="emphasis_58" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_17" class="annotation superscript"
                              style="position: unset;">9</span></span><span data-id="superscript_17"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_59"
                            class="annotation emphasis" style="position: unset;">/L)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.3 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.4 ±
                          1.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.060</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POD1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.4 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.2 ±
                          2.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.736</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POD3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.8 ±
                          1.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.8 ±
                          2.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.990</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POD5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.5 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.6 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.837</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ESR <span
                            data-id="emphasis_60" class="annotation emphasis"
                            style="position: unset;">(mm/hr)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.8 ±
                          15.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.3 ±
                          11.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.473</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POD1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.9 ±
                          19.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.3 ±
                          9.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.678</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POD3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37.9 ±
                          15.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.3 ±
                          24.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.296</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POD5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40.8 ±
                          17.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.6 ±
                          25.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.145</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CRP level <span
                            data-id="emphasis_61" class="annotation emphasis"
                            style="position: unset;">(mg/dL)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3 ±
                          0.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1 ±
                          0.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.208</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POD1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.8 ±
                          3.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4 ±
                          2.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.049</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POD3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.1 ±
                          4.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.2 ±
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> POD5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.2 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.3 ±
                          1.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD. WBC = white blood cell, POD =
                        postoperative day, ESR = erythrocyte sedimentation rate, CRP = C-reactive protein.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">The aim of this study was to evaluate the analgesic
                  effects of facet joint injections on pain after OLIF. Facet joints play a crucial role in providing
                  stability, guiding movements, and preventing excessive spinal rotation. However, these joints can
                  become a source of pain due to various factors such as arthritis, injury, and degeneration<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">20</span></a>. Patients who undergo OLIF experience severe back pain
                  during the perioperative period. Therefore, we hypothesized that facet joint distraction is one of the
                  main mechanisms underlying back pain after OLIF. The facet joints, also known as zygapophyseal joints
                  or z-joints, are synovial joints located between the vertebral arches of adjacent vertebrae in the
                  spine<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_19" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">23</span></a>. The lumbar facet joints are innervated by the medial
                  branches of the dorsal rami of the spinal nerves at the corresponding levels, which provides sensory
                  innervation for pain and proprioception. Neural structures of the lumbar facet joint can vary between
                  individuals and can potentially overlap with the neural structures of adjacent levels. Similar to our
                  hypothesis regarding the source of pain in the lumbar spine following OLIF, Lee et al. proposed that
                  axial pain in the cervical spine occurs as a result of facet distraction following anterior cervical
                  discectomy and fusion<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">24</span></a>.
                  Overall, the medial branches of the dorsal rami of the spinal nerves are key players in the
                  innervation of the facet joints and are the main source of lower back pain. Therefore, we hypothesized
                  that a facet joint injection can reduce the pain caused by the stretching of the facet joint capsule
                  after OLIF. Lee et al. compared facet joint injections and medial branch blocks (MBBs) for thoracic
                  facet joint pain<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">25</span></a>. No
                  difference was observed between the 2 methods in terms of pain reduction. Kuslich et al. reported that
                  back and leg pain originate from muscle, fascia, and bone rather than the facet joint. Therefore, they
                  recommended an erector spinae plane block for pain management<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">26</span></a>. In the present study, we employed a facet joint block
                  instead of an MBB because the injection site for an MBB would almost coincide with the screw insertion
                  point in OLIF and we were concerned that the treatment effect would be reduced as a result.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Interestingly, we found significant differences in CRP
                  levels between the groups at 1, 3, and 5 days postoperatively. Measuring CRP levels is useful in
                  determining the inflammatory reaction after surgery<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">27</span></a>.
                  However, our finding does not confirm the anti-inflammatory effects of facet joint injections in
                  general. The CRP levels may have decreased due to the facet joint injection of triamcinolone.
                  Triamcinolone is commonly administered via joint injections. For example, Aydin et al. utilized
                  triamcinolone via intra-articular injection in patients with juvenile idiopathic arthritis<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">28</span></a>, and Sukharomana et al. utilized triamcinolone via
                  intra-articular injection in patients with non-systemic juvenile idiopathic arthritis<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">29</span></a>. Triamcinolone has also been administered via facet joint
                  injection in patients with severe central lumbar spinal stenosis<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">29</span></a>. Aydin et al. and Sukharomana et al. reported that
                  triamcinolone reduced inflammation and pain and that CRP levels decreased after triamcinolone
                  injection in patients with either type of arthritis or spinal stenosis<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">29</span></a>. Therefore, the results of the present study are
                  inconclusive as to whether facet joint injection has an anti-inflammatory effect.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The LOS difference was an important finding of our study.
                  The LOS in the active group was 1.3 days shorter than that in the placebo group. Effective
                  postoperative pain management has been linked to lower rates of morbidity and mortality, shorter LOS,
                  and reduced overall costs<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">30</span></a>. In
                  the present study, facet joint injections reduced postoperative back pain for up to 48 hours, which
                  could potentially enhance physical activity following surgery. These effects contributed to shortening
                  the LOS.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">The present study has some limitations. First, the
                  follow-up period was only 6 months, with a focus on immediate postoperative pain rather than on
                  long-term outcomes. Second, OLIF might damage the facet joint capsule, which could affect the
                  effectiveness of the facet joint block. However, injections were administered before the insertion of
                  the posterior percutaneous screw, and we tried to avoid damaging the facet joint in order to help
                  retain the injected bupivacaine and triamcinolone.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, the use of an intraoperative facet joint
                  block decreased pain perception during OLIF, thereby reducing opioid consumption and the severity of
                  postoperative pain. This effect was also associated with a reduction in the LOS. Additionally, facet
                  joint blocks may reduce inflammatory reactions; however, further research is needed to support this
                  finding.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Horn R</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Hendrix JM</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Kramer J</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Postoperative Pain
                  Control.</span> StatPearls Publishing; 2024.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Chen W</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Yang H</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Jiang X</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Fan S</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The Effect of Oblique Lumbar
                  Interbody Fusion Compared with Transforaminal Lumbar Interbody Fusion Combined with Enhanced Recovery
                  after Surgery Program on Patients with Lumbar Degenerative Disease at Short-Term Follow-Up</span>.
                <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Biomed Res Int.</span>
                2021 Sep 27;2021:5806066.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Effect%20of%20Oblique%20Lumbar%20Interbody%20Fusion%20Compared%20with%20Transforaminal%20Lumbar%20Interbody%20Fusion%20Combined%20with%20Enhanced%20Recovery%20after%20Surgery%20Program%20on%20Patients%20with%20Lumbar%20Degenerative%20Disease%20at%20Short-Term%20Follow-Up&as_occt=title&as_sauthors=%20%22W%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Lee YS</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Lee DH</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Cho DC</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Han I</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Kim CH</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Kwon HD</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Kim KT</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The Change of Spinal Canal
                  According to Oblique Lumbar Interbody Fusion in Degenerative Spondylolisthesis: A Prospective
                  Observational Study</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Neurospine.</span> 2022 Sep;19(3):492-500.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Change%20of%20Spinal%20Canal%20According%20to%20Oblique%20Lumbar%20Interbody%20Fusion%20in%20Degenerative%20Spondylolisthesis%3A%20A%20Prospective%20Observational%20Study&as_occt=title&as_sauthors=%20%22YS%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Kim H</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Chang BS</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Chang SY</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Pearls and Pitfalls of Oblique
                  Lateral Interbody Fusion: A Comprehensive Narrative Review</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Neurospine.</span> 2022
                Mar;19(1):163-76.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pearls%20and%20Pitfalls%20of%20Oblique%20Lateral%20Interbody%20Fusion%3A%20A%20Comprehensive%20Narrative%20Review&as_occt=title&as_sauthors=%20%22H%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Inoue M</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Orita S</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Inage K</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Fujimoto K</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Shiga Y</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Kanamoto H</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Abe K</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Kinoshita H</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Norimoto M</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Umimura T</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Sato T</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Sato M</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Suzuki M</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Enomoto K</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Eguchi Y</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Aoki Y</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Akazawa T</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Ohtori S</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Radiological Assessment of
                  Damage to the Iliopsoas Muscle by the Oblique Lateral Interbody Fusion Approach</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Spine Surg Relat Res.</span>
                2019 Oct 20;4(2):152-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiological%20Assessment%20of%20Damage%20to%20the%20Iliopsoas%20Muscle%20by%20the%20Oblique%20Lateral%20Interbody%20Fusion%20Approach&as_occt=title&as_sauthors=%20%22M%20Inoue%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Lee DY</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Shim CS</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Ahn Y</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Choi YG</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Lee SH</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Comparison of percutaneous
                  endoscopic lumbar discectomy and open lumbar microdiscectomy for recurrent disc herniation</span>.
                <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Korean Neurosurg
                  Soc.</span> 2009 Dec;46(6):515-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20percutaneous%20endoscopic%20lumbar%20discectomy%20and%20open%20lumbar%20microdiscectomy%20for%20recurrent%20disc%20herniation&as_occt=title&as_sauthors=%20%22DY%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Arts M</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Brand R</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> van der Kallen B</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Lycklama à Nijeholt G</span>,
                <span data-id="annotation_46" class="annotation" style="position: unset;"> Peul W</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Does minimally invasive lumbar
                  disc surgery result in less muscle injury than conventional surgery? A randomized controlled
                  trial</span>. <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Eur
                  Spine J.</span> 2011 Jan;20(1):51-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20minimally%20invasive%20lumbar%20disc%20surgery%20result%20in%20less%20muscle%20injury%20than%20conventional%20surgery%3F%20A%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22M%20Arts%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Wang W</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Xiao B</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Wang H</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Qi J</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Gu X</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Yu J</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Ye X</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Xu G</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Xi Y</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Oblique lateral interbody fusion
                  stand-alone vs. combined with percutaneous pedicle screw fixation in the treatment of discogenic low
                  back pain</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Front Surg.</span> 2022 Oct 6;9:1013431.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Oblique%20lateral%20interbody%20fusion%20stand-alone%20vs.%20combined%20with%20percutaneous%20pedicle%20screw%20fixation%20in%20the%20treatment%20of%20discogenic%20low%20back%20pain&as_occt=title&as_sauthors=%20%22W%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Kapetanakis S</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Gkantsinikoudis N</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Anatomy of lumbar facet joint: a
                  comprehensive review</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Folia Morphol (Warsz).</span> 2021;80(4):799-805.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anatomy%20of%20lumbar%20facet%20joint%3A%20a%20comprehensive%20review&as_occt=title&as_sauthors=%20%22S%20Kapetanakis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Bach K</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Ford J</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Foley R</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Januszewski J</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Murtagh R</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Decker S</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Uribe JS</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Morphometric Analysis of Lumbar
                  Intervertebral Disc Height: An Imaging Study</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">World Neurosurg.</span> 2018 Dec
                20:S1878-8750(18)32836-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Morphometric%20Analysis%20of%20Lumbar%20Intervertebral%20Disc%20Height%3A%20An%20Imaging%20Study&as_occt=title&as_sauthors=%20%22K%20Bach%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Chen C</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Li Q</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Wang W</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Ji C</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Kang Y</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Wang C</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Zhang H</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Zhang M</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Zhou H</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Feng H</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Feng S</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Comparison of the efficacy of
                  expandable interbody fusion cage (EXP-IFC) and non-expandable interbody fusion cage (NE-IFC) in
                  MIS-TLIF for lumbar degenerative diseases: A systematic retrospective study on 62 patients</span>.
                <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Front Surg.</span> 2022
                Oct 18;9:1008171.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20the%20efficacy%20of%20expandable%20interbody%20fusion%20cage%20(EXP-IFC)%20and%20non-expandable%20interbody%20fusion%20cage%20(NE-IFC)%20in%20MIS-TLIF%20for%20lumbar%20degenerative%20diseases%3A%20A%20systematic%20retrospective%20study%20on%2062%20patients&as_occt=title&as_sauthors=%20%22C%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Wu C</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Bian H</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Liu J</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Zhao D</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Yang H</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Chen C</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Sun X</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Guan B</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Sun G</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Liu G</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Xu B</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Ma X</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Wang Z</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Yang Q</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Effects of the cage height and
                  positioning on clinical and radiographic outcome of lateral lumbar interbody fusion: a retrospective
                  study</span>. <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">BMC
                  Musculoskelet Disord.</span> 2022 Dec 9;23(1):1075.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20the%20cage%20height%20and%20positioning%20on%20clinical%20and%20radiographic%20outcome%20of%20lateral%20lumbar%20interbody%20fusion%3A%20a%20retrospective%20study&as_occt=title&as_sauthors=%20%22C%20Wu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Kang RA</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Sim WS</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Choi JW</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Kang S</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Lee S</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Jee HS</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Oh EJ</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Kim Y</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Ko JS</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Comparison between
                  anteroposterior and oblique “Scotty dog” approach during S1 transforaminal epidural steroid injection:
                  A randomized controlled trial</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Medicine (Baltimore).</span> 2020 Oct 23;99(43):e22895.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20between%20anteroposterior%20and%20oblique%20%E2%80%9CScotty%20dog%E2%80%9D%20approach%20during%20S1%20transforaminal%20epidural%20steroid%20injection%3A%20A%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22RA%20Kang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Kaliya-Perumal AK</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Yeh YC</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Luo CA</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Joey-Tan KY</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Assessment of Anteroposterior
                  Subpedicular Approach and Oblique Scotty Dog Subpedicular Approach for Selective Nerve Root
                  Block</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Surg.</span> 2017 Mar;9(1):71-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessment%20of%20Anteroposterior%20Subpedicular%20Approach%20and%20Oblique%20Scotty%20Dog%20Subpedicular%20Approach%20for%20Selective%20Nerve%20Root%20Block&as_occt=title&as_sauthors=%20%22AK%20Kaliya-Perumal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Kunakornsawat S</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Tungsiripat R</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Putthiwara D</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Piyakulkaew C</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Pluemvitayaporn T</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Pruttikul P</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Kittithamvongs P</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Postoperative Kinetics of
                  C-Reactive Protein and Erythrocyte Sediment Rate in One-, Two-, and Multilevel Posterior Spinal
                  Decompressions and Instrumentations</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Global Spine J.</span> 2017 Aug;7(5):448-51.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postoperative%20Kinetics%20of%20C-Reactive%20Protein%20and%20Erythrocyte%20Sediment%20Rate%20in%20One-%2C%20Two-%2C%20and%20Multilevel%20Posterior%20Spinal%20Decompressions%20and%20Instrumentations&as_occt=title&as_sauthors=%20%22S%20Kunakornsawat%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Marchi L</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Abdala N</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Oliveira L</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Amaral R</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Coutinho E</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Pimenta L</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Radiographic and clinical
                  evaluation of cage subsidence after stand-alone lateral interbody fusion</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J Neurosurg Spine.</span>
                2013 Jul;19(1):110-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20and%20clinical%20evaluation%20of%20cage%20subsidence%20after%20stand-alone%20lateral%20interbody%20fusion&as_occt=title&as_sauthors=%20%22L%20Marchi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Galloway S</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Chimhanda M</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Sloan J</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Anderson C</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Sinacore J</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Brubaker L</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Pain scores are not predictive
                  of pain medication utilization</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Pain Res Treat.</span> 2011;2011:987468.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pain%20scores%20are%20not%20predictive%20of%20pain%20medication%20utilization&as_occt=title&as_sauthors=%20%22S%20Galloway%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Arhami Dolatabadi A</span>,
                <span data-id="annotation_123" class="annotation" style="position: unset;"> Mohammadian A</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Kariman H</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Lidocaine-Midazolam-Fentanyl
                  Combination in Controlling Pain for Reduction of Anterior Shoulder Dislocation; a Randomized Clinical
                  Trial</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Emerg
                  (Tehran).</span> 2018;6(1):e24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lidocaine-Midazolam-Fentanyl%20Combination%20in%20Controlling%20Pain%20for%20Reduction%20of%20Anterior%20Shoulder%20Dislocation%3B%20a%20Randomized%20Clinical%20Trial&as_occt=title&as_sauthors=%20%22A%20Arhami%20Dolatabadi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Walczak BE</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Bernardoni ED</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Steiner Q</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Baer GS</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Donnelly MJ</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Shepler JA</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Effects of General Anesthesia
                  Plus Multimodal Analgesia on Immediate Perioperative Outcomes of Hamstring Tendon Autograft ACL
                  Reconstruction: A Randomized, Double-Blinded, Placebo-Controlled Trial</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">JB JS Open Access.</span>
                2023 Mar 27;8(1):e22.00144.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20General%20Anesthesia%20Plus%20Multimodal%20Analgesia%20on%20Immediate%20Perioperative%20Outcomes%20of%20Hamstring%20Tendon%20Autograft%20ACL%20Reconstruction%3A%20A%20Randomized%2C%20Double-Blinded%2C%20Placebo-Controlled%20Trial&as_occt=title&as_sauthors=%20%22BE%20Walczak%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Du R</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Xu G</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Bai X</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Li Z</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Facet Joint Syndrome:
                  Pathophysiology, Diagnosis, and Treatment</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">J Pain Res.</span> 2022 Nov
                30;15:3689-710.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Facet%20Joint%20Syndrome%3A%20Pathophysiology%2C%20Diagnosis%2C%20and%20Treatment&as_occt=title&as_sauthors=%20%22R%20Du%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Kotb SY</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Sherif NM</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Saleh HA</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Ahmed SF</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Sakr HM</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Taeimah MO</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The role of intra-articular
                  injection of autologous platelet-rich plasma versus corticosteroids in the treatment of synovitis in
                  lumbar facet joint disease</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Saudi Med J.</span> 2022 Nov;43(11):1200-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20intra-articular%20injection%20of%20autologous%20platelet-rich%20plasma%20versus%20corticosteroids%20in%20the%20treatment%20of%20synovitis%20in%20lumbar%20facet%20joint%20disease&as_occt=title&as_sauthors=%20%22SY%20Kotb%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Kang WY</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Lee JW</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Lee E</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Kang Y</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Ahn JM</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Kang HS</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Efficacy and outcome predictors
                  of fluoroscopy-guided facet joint injection for spondylolysis</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Skeletal Radiol.</span> 2018
                Aug;47(8):1137-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20and%20outcome%20predictors%20of%20fluoroscopy-guided%20facet%20joint%20injection%20for%20spondylolysis&as_occt=title&as_sauthors=%20%22WY%20Kang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Lee E</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Lee JW</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Kang Y</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Ahn JM</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Efficacy of Fluoroscopy-Guided
                  Lumbar Facet Joint Synovial Cyst Rupture with Intra-Articular Steroid Injection after
                  Laminectomy</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Taehan Yongsang Uihakhoe Chi.</span> 2021 Jan;82(1):162-72.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20Fluoroscopy-Guided%20Lumbar%20Facet%20Joint%20Synovial%20Cyst%20Rupture%20with%20Intra-Articular%20Steroid%20Injection%20after%20Laminectomy&as_occt=title&as_sauthors=%20%22HJ%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Lee DH</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Lee J</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Lee H</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Cho J</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Hwang C</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Lee C</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Establishing a relationship
                  using CT between facet distraction and clinical outcomes after ACDF</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Spine J.</span> 2023
                Jun;23(6):841-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Establishing%20a%20relationship%20using%20CT%20between%20facet%20distraction%20and%20clinical%20outcomes%20after%20ACDF&as_occt=title&as_sauthors=%20%22DH%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Lee DG</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Ahn SH</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Cho YW</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Do KH</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Kwak SG</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Chang MC</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Comparison of Intra-articular
                  Thoracic Facet Joint Steroid Injection and Thoracic Medial Branch Block for the Management of Thoracic
                  Facet Joint Pain</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2018 Jan 15;43(2):76-80.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20Intra-articular%20Thoracic%20Facet%20Joint%20Steroid%20Injection%20and%20Thoracic%20Medial%20Branch%20Block%20for%20the%20Management%20of%20Thoracic%20Facet%20Joint%20Pain&as_occt=title&as_sauthors=%20%22DG%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Kuslich SD</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Ulstrom CL</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Michael CJ</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The tissue origin of low back
                  pain and sciatica: a report of pain response to tissue stimulation during operations on the lumbar
                  spine using local anesthesia</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Orthop Clin North Am.</span> 1991 Apr;22(2):181-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20tissue%20origin%20of%20low%20back%20pain%20and%20sciatica%3A%20a%20report%20of%20pain%20response%20to%20tissue%20stimulation%20during%20operations%20on%20the%20lumbar%20spine%20using%20local%20anesthesia&as_occt=title&as_sauthors=%20%22SD%20Kuslich%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Biring GS</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Masri BA</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Greidanus NV</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Duncan CP</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Garbuz DS</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Predictors of quality of life
                  outcomes after revision total hip replacement</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 2007
                Nov;89(11):1446-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictors%20of%20quality%20of%20life%20outcomes%20after%20revision%20total%20hip%20replacement&as_occt=title&as_sauthors=%20%22GS%20Biring%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Aydın F</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Çakar N</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Kurt T</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Çelikel Acar B</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Çelikel E</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Özçakar ZB</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Yalçınkaya F</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">What are the factors associated
                  with the duration of remission of intra-articular corticosteroid injection in juvenile idiopathic
                  arthritis?</span><span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Clin
                  Rheumatol.</span> 2022 Dec;41(12):3791-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20are%20the%20factors%20associated%20with%20the%20duration%20of%20remission%20of%20intra-articular%20corticosteroid%20injection%20in%20juvenile%20idiopathic%20arthritis%3F&as_occt=title&as_sauthors=%20%22F%20Ayd%C4%B1n%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Sukharomana M</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Charuvanij S</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Outcomes of intraarticular
                  triamcinolone acetonide injection in children with non-systemic juvenile idiopathic arthritis</span>.
                <span data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Clin Rheumatol.</span>
                2023 Jun;42(6):1665-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20intraarticular%20triamcinolone%20acetonide%20injection%20in%20children%20with%20non-systemic%20juvenile%20idiopathic%20arthritis&as_occt=title&as_sauthors=%20%22M%20Sukharomana%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Kim KT</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Cho DC</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Sung JK</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Kim YB</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Kang H</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Song KS</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Choi GJ</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Intraoperative systemic
                  infusion of lidocaine reduces postoperative pain after lumbar surgery: a double-blinded, randomized,
                  placebo-controlled clinical trial</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2014 Aug 1;14(8):1559-66.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intraoperative%20systemic%20infusion%20of%20lidocaine%20reduces%20postoperative%20pain%20after%20lumbar%20surgery%3A%20a%20double-blinded%2C%20randomized%2C%20placebo-controlled%20clinical%20trial&as_occt=title&as_sauthors=%20%22KT%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Db3029aef-3521-49b3-9723-efc1ec840798%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): 16</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01480</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 20, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_31"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Neurosurgery, Kyungpook National University Hospital, Daegu, Republic of
                            Korea</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_35"
                            class="annotation strong" style="position: unset;">A commentary by Mary L. Forte, PhD,
                            DC,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=566081f9-f9f8-4907-aa0c-459cf2e605da&native=1"
                              class="article-title" style="position: unset;">Facet Joint Block: A Temporary Fix for an
                              Iatrogenic Problem After Oblique Lumbar Interbody Fusion?<span class="subtitle"
                                style="position: unset;"><br style="position: unset;">Commentary on an article by Sung
                                Hyeon Noh, MD, PhD, et al.: “Intraoperative Facet Joint Block Reduces Pain After Oblique
                                Lumbar Interbody Fusion. A Double-Blinded, Randomized, Placebo-Controlled Clinical
                                Trial”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Forte,
                              Mary L.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 107(1):e2 | January 1, 2025</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;b3029aef-3521-49b3-9723-efc1ec840798&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=b3029aef-3521-49b3-9723-efc1ec840798&type=pdf&name=JBJS.23.01480.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=b3029aef-3521-49b3-9723-efc1ec840798&type=pdf&name=JBJS.23.01480.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_36"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This work was
                          supported by the grants of the Korea Health Technology R&D Project through the Korea Health
                          Industry Development Institute (KHIDI), funded by the Ministry of Health & Welfare, Republic
                          of Korea (grant number: HR22C1832) and the Starting Growth Technological R&D Program (TIPS
                          Program, (No. RS-2023-00258550)), and funded by the Ministry of SMEs and Startups (MSS, Korea)
                          in 2023. The <span data-id="strong_37" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I280"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I280</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;b3029aef-3521-49b3-9723-efc1ec840798&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=b3029aef-3521-49b3-9723-efc1ec840798&type=zip&name=JBJS.23.01480.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                        Sharing</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">A <span data-id="strong_38"
                            class="annotation strong" style="position: unset;">data-sharing statement</span> is provided
                          with the online version of the article (<a href="http://links.lww.com/JBJS/I286"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I286</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Sharing
                          PDF</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;b3029aef-3521-49b3-9723-efc1ec840798&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=b3029aef-3521-49b3-9723-efc1ec840798&type=pdf&name=JBJS.23.01480.dataavailability.pdf&subtype=dataavailability"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sung Hyeon Noh, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4867-6854" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4867-6854</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sang-Woo Lee, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1751-2163" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1751-2163</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jong-moon Hwang, MD, PhD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9807-8783" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9807-8783</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">JinWoo Jung, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9517-6193" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9517-6193</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eunyoung Lee, PhD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9440-9119" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9440-9119</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dae-Chul Cho, MD, PhD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2899-8015" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2899-8015</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Chi Heon Kim, MD, PhD<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kyoung-Tae Kim, MD, PhD<span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:nskimkt7@gmail.com" class="" style="position: unset;">nskimkt7@gmail.com</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0001-3302-5314" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0001-3302-5314</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Neurosurgery, Ajou University College of Medicine, Suwon,
                Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Neurosurgery, Yonsei University College of Medicine, Seoul,
                Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Spine Center, Department of Neurosurgery, Hu Hospital, Busan, Republic of
                Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Rehabilitation Medicine, Daegu Fatima Hospital, Daegu, Republic
                of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Department of Neurosurgery, Kyungpook National University Hospital, Daegu,
                Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Neurology, McGovern Medical School, The University of Texas
                Health Science Center at Houston, Houston, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Department of Neurosurgery, Seoul National University College of Medicine,
                Seoul, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Department of Neurosurgery, Bokwang Hospital, Daegu, Republic of Korea</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 31542.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
