import { Image } from "@memorang/ui";
/* -----------------Globals--------------- */
import { useEffect, useState } from "react";
import { List, Text } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";

/* -----------------Types--------------- */
import { type Category, fetchCategories } from "@features/exam";

/* -----------------Child components--------------- */
import { AuthContainer, Box, Loader } from "@memorang/ui";

import { events } from "@constants/tracking";
import { useDevice } from "@hooks/useDevice";
import { trackCustomEvent } from "analytics";
/* -----------------Third parties--------------- */
import { useRouter } from "expo-router";
import OnboardingAppBar from "../components/OnboardingAppBar";

export const ChooseCategoryContainer = () => {
	const [categories, setCategories] = useState<Category[]>([]);

	const {
		app: { id: appId },
		tenant: { id: tenantId },
	} = useAppStore((store) => ({
		app: store.app,
		tenant: store.tenant,
	}));

	const router = useRouter();
	const { isMobile } = useDevice();
	useEffect(() => {
		fetchCategories(tenantId, appId).then((categories) => {
			const sortedCats = categories.sort((a, b) =>
				a.name.localeCompare(b.name),
			);
			setCategories(sortedCats);
		});
	}, [appId, tenantId]);

	useEffect(() => {
		trackCustomEvent({
			eventName: events.chooseCategoryViewed,
		});
	}, []);

	useEffect(() => {
		if (categories.length && categories.length < 2) {
			router.setParams({
				step: "chooseexam",
				showBackButton: "false",
			});
		}
	}, [categories, router]);
	if (!categories.length) {
		return <Loader />;
	}
	const ListCategory = ({ categories }: { categories: Category[] }) => {
		return (
			<Box justifyContent="center" gap={16} paddingTop={24}>
				{categories.map(({ icon, name, id }) => {
					return (
						<List.Item
							key={id}
							onPress={() => {
								trackCustomEvent({
									eventName: events.categorySelected,
									category: name,
								});
								router.setParams({
									step: "chooseexam",
									category: name,
								});
							}}
							style={{
								paddingHorizontal: 16,
							}}
							title={name}
							left={() => (
								<Image
									source={{
										uri: icon,
									}}
									style={{
										width: 32,
										height: 32,
									}}
								/>
							)}
							right={(p) => <List.Icon {...p} icon="chevron-right" />}
						/>
					);
				})}
			</Box>
		);
	};
	return (
		<>
			<OnboardingAppBar
				showLogo={!isMobile}
				elevated
				title={"Choose Category"}
				showProfileMenu={!isMobile}
			/>
			<AuthContainer
				containerStyle={{
					paddingHorizontal: 0,
				}}
			>
				<Text
					style={{
						textAlign: "center",
						paddingTop: 24,
					}}
					variant="titleMedium"
				>
					{"Which type of exam are you preparing for?"}
				</Text>
				<ListCategory categories={categories} />
			</AuthContainer>
		</>
	);
};
