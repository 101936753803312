import type { Article } from "../../../../../types";

export const Article510: Article = {
	id: 510,
	rsuiteId: "144b08a6-73f2-4462-b46f-5a5de24e24c6",
	type: "guest editorial",
	title: "What’s New in Osteoporosis: Emphasis on the Aging Athlete",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=144b08a6-73f2-4462-b46f-5a5de24e24c6&type=jpeg&name=JBJS.24.00644f1",
	subSpecialties: ["sports_medicine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s New in Osteoporosis: Emphasis on the Aging Athlete
              </div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ting Cong,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Dan C.M.
                      Viola, MD, PhD, MBA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">The contemporary treatment of osteoporosis has seen
                  tremendous evolution. Today, we have access to powerful biologic agents for the treatment of
                  osteoporosis and an improved understanding of their safety and how to use them<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. It is now possible to “skeletally optimize” patients not
                  just for life, but in preparation for sport or elective surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">This edition of “What’s New in Osteoporosis” is as much
                  focused on the changing demographic characteristics of our patients as it is on therapy.
                  Contemporaneously with the increase in new drug therapies, we have seen a growing group of aging
                  athletes ≥60 years of age<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a>. Cultural shifts in athletic trends such as pickleball<a
                    href="" data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a> illustrate an attitude toward an active lifestyle. Although
                  age has been independently associated with injuries<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">6</span></a>, the positive psychosocial and cognitive effects of an
                  active lifestyle can substantially and positively impact overall health<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">7</span></a>, with the resulting improved physical activity known to
                  independently decrease fracture risk<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">9</span></a>. Data have suggested that impact sports have a positive,
                  direction-specific effect on bone strength and structure, in many instances mitigating age effects<a
                    href="" data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">10</span></a>. “Biological age” compared with “chronological age”<a
                    href="" data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">11</span></a> continues to be a salient point of discussion in today’s
                  treatment of osteoporosis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Stratifying
                Osteoporosis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">We know that the density of skeletal bone and fracture
                  risk are correlated. Dual x-ray absorptiometry (DXA) and bone-related laboratory markers are often
                  used to assess the disease burden. However, despite advancements in imaging modalities, including
                  quantitative or peripheral computed tomography (CT)<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">3</span></a>,
                  fracture risk still remains incompletely explained by these biomarkers.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient Case
                Scenarios</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">To illustrate the point, we present 2 case scenarios
                  commonly seen in the clinic. The patients have the same bone mineral density (BMD) and Fracture Risk
                  Assessment Tool (FRAX) scores, but they are expected to have different true fracture risks. A thorough
                  history and physical examination focusing on identifying risk factors, such as social isolation<a
                    href="" data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">13</span></a>, frailty<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">14</span></a>,
                  and physical performance<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_12" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">15</span></a>, among others (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>), are
                  critical for identifying and differentiating residual risk.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00644f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=144b08a6-73f2-4462-b46f-5a5de24e24c6&type=jpeg&name=JBJS.24.00644f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Spectrum of disease in contemporary patients with
                          osteoporosis and their respective treatment foci. Residual risk remains despite widespread use
                          of age, FRAX, and BMD as primary screening tools for osteoporosis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient 1:
                The Frail Patient</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">This patient is an 80-year-old woman. She is widowed and
                  has no children. She rarely leaves home and has an 8-hour-per-day home attendant. She has a slow gait,
                  is fatigued, and cannot perform a single-leg stance. She has a positive Gower sign.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">DXA determined that the patient has T-scores of −3.0 for
                  the lumbar spine and −2.8 for the femoral neck. The FRAX determined that the patient has a 50% risk of
                  a major osteoporotic fracture and a 38% risk of a hip fracture.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient 2:
                The Aging Athlete</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The patient is an 80-year-old woman. She is married with 2
                  nearby children. She loves to hike and travel and plays pickleball twice a week. She has a good build
                  and a confident gait. She is stable performing a 12-second single-leg stance and can do an effortless
                  squat.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">DXA determined that the patient has T-scores of −3.0 for
                  the lumbar spine and −2.8 for the femoral neck. The FRAX determined that the patient has a 50% risk of
                  a major osteoporotic fracture and a 38% risk of a hip fracture.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">High-Risk
                Patients on the Fracture Risk Continuum: Osteosarcopenia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">There is currently no consensus on the optimal screening
                  or interventional approach to primary prevention of fragility fractures<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">16</span></a>. In a summary statement of randomized controlled trials
                  (RCTs), Gates et al. demonstrated that a 2-step screening using the FRAX plus BMD measurement has an
                  area under the curve (AUC) predictive value of around 0.7 for the 5 to 10-year fracture risk, which,
                  in 2024, still leaves room for improvement<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">16</span></a>.
                  Newer imaging methods appear to help to circumvent this problem but have yet to change the standard of
                  care. Sornay-Rendu et al., in a long-term subanalysis of the Os des Femmes de Lyon (OFELY) study<a
                    href="" data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">17</span></a> and in a study utilizing DXA finite element analysis<a
                    href="" data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">18</span></a>, showed that osseous microarchitecture is critical in
                  defining fracture risk. Szulc et al. has now linked bone architecture to a fracture risk that appears
                  independent of chronological age<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">19</span></a>.
                  Until we can better understand the causative link between osseous microarchitecture and fracture risk,
                  which in the future will likely involve deep-learning algorithms<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">20</span></a>, chronological age remains a critical screening item for
                  patients at high risk for fracture today.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Regardless of advancements, in high-risk patients with a
                  recent fracture, treatment duration and adherence in the United States continue to be poor, with
                  recent data reporting a BMD testing rate of only 8.6% within 12 months of the index fracture, and a
                  secondary fracture rate of 13.6% over a 2-year follow-up, in patients ≥50 years of age<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">21</span></a>. This highlights the continued systemic failure to
                  appropriately capture, stratify, triage, and treat patients for primary or secondary fracture
                  prevention. By extrapolation, we cannot yet rely on systems-based clinical pathways to replace a
                  clinician’s ability to identify and act upon risk; the doctor remains on the front line for fracture
                  prevention.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Osteosarcopenia prevalence in the community is high,
                  reportedly 21% in a recent systematic review and meta-analysis, which identified female sex (odds
                  ratio [OR], 5.10; p &lt; 0.0001), older age (OR, 1.12; p = 0.008), and prior fracture (OR, 2.92; p =
                  0.0003) as risk factors<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">22</span></a>. In
                  a perspective article, Binkley et al. discussed how dysmobility syndrome, causing eventual fragility
                  fracture and decline, intimately relates to the concept of osteosarcopenia<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">23</span></a>. In their original description of a “bone attack,” in
                  parallel with outcome similarities to a heart attack, critical items were listed in the initial workup
                  of a patient, including prior low-energy fracture, sarcopenia, prior falls, diabetes, parental
                  fracture history, and toxin exposure<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">23</span></a>.
                  Vitamin D deficiency continues to be linked to functional decline in such high-risk patients. In a
                  recent RCT subanalysis of 246 women residing in long-term care centers, Haeri et al. showed that every
                  5-ng/dL increase in serum 25-OH vitamin D was associated with an increase of 0.012 m/second in gait
                  speed (p = 0.0144)<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">24</span></a>.
                  Although some of these risk factors (parental fracture history and toxin exposure) are captured within
                  the FRAX score, they made the important distinction, in the spirit of primary prevention, that such a
                  method dependent on a high FRAX score is too little, too late in the capture of the at-risk patient.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Evidently, the FRAX score and BMD also do not tell the
                  entire story. Visualizing this in a hypothetical Venn diagram demonstrates overlap of these 3
                  biomarkers but failure to explain residual risk (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). FRAX
                  adjustments for items such as fall history<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">25</span></a>,
                  diabetes<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">26</span></a>,
                  and corticosteroid dose exposure<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">27</span></a>
                  have now been published, leading to the development of the FRAXplus score currently in beta testing<a
                    href="" data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">28</span></a>. For instance, a patient-reported history of fragility
                  fractures is an independent risk factor for subsequent fractures at a higher rate than can be
                  explained by BMD alone, with the effect being sex-independent<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">29</span></a>. This finding was corroborated by a recent meta-analysis by
                  Vandenput et al. of 906,359 patients, finding that a fall history has independent implications for
                  fracture risk regardless of BMD<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">30</span></a>.
                  The implementation of fall prevention programs has been shown to lower the risk of fractures<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">31</span></a>. Patient safety thus becomes critical as a focus of
                  treatment in high-risk patients (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Low-Risk
                Patients on the Fracture Risk Continuum: Overoptimize</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The aging athlete (Patient 2) has maintained a higher
                  physiologic baseline, which has health benefits beyond just a demonstrable effect on longevity<a
                    href="" data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">32</span></a>. As patients now live well beyond the ninth decade,
                  baseline skeletal optimization dictates the need to proactively minimize and maintain low skeletal
                  disease burden during periods of good health, in anticipation for potential progression of
                  osteoporosis 30 to 40 years later.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Early intervention is always the best intervention. The
                  principal stimulus to osteoanabolic response is exercise<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">33</span></a>.
                  Exercise intensity and duration are inversely correlated with fracture risk, especially in the hip<a
                    href="" data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">34</span></a><span data-id="superscript_33"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">35</span></a>. However, spinal and upper-extremity fractures and physical
                  activity do not relate in this way, suggesting that mechanical loading is protective against fractures
                  only in the anatomic regions experiencing the loading<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">35</span></a>.
                  Twisting or bending exercises placing patients at risk for compression fractures should be avoided,
                  particularly given that the spine does not readily respond to weight-bearing exercise<a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">36</span></a>. It should be mentioned at this juncture that the profound
                  positive effect of anabolic agents on bone density in the spine compared with other body parts has
                  been well documented<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">37</span></a><span data-id="superscript_36"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">39</span></a>, as was demonstrated by Händel et al. in a large network
                  meta-analysis and meta-regression analysis of 69 RCTs<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">40</span></a>.
                  Anabolic agents additionally improve trabecular architecture, based on a post hoc 3-dimensional (3D)
                  modeling study on hip CT data from patients who received romosozumab from the FRAME (Fracture Study in
                  Postmenopausal Women with Osteoporosis) and ARCH (Romosozumab vs Alendronate for Osteoporosis)
                  trials<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">41</span></a>,
                  suggesting that anabolic drug treatment may be especially important in the active healthy patient with
                  higher physical demands<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">42</span></a>.
                  Taken together, it is possible that the aging athlete may especially benefit from anabolic agents, as
                  the treatment effect is potentiated by mechanical input. The synergy between exercise and its anabolic
                  effect in older athletes warrants further research, in order to highlight whether overoptimization may
                  be a valid strategy in improving the patient baseline.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Abolishing toxic lifestyle habits such as smoking or
                  excessive alcohol consumption needs to be addressed<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_40" class="annotation superscript" style="position: unset;">43</span></a>.
                  Nutrient counseling is critical for maintaining eucalcemia, especially in the setting of
                  antiresorptive medications. There is an inverse relationship between body mass and bone mass and thus
                  fracture risk<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_41" class="annotation superscript" style="position: unset;">44</span></a>,
                  although it is now understood that muscle mass positively correlates with bone mass<a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">45</span></a><span data-id="superscript_42"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">46</span></a> and lower-extremity strength is protective against
                  fracture<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">47</span></a>,
                  alluding to the potential for osteosarcopenia prevention through exercise. This has led to a change in
                  the patient-doctor dialogue from “avoid too much weight loss” to “work on building muscle,” as a way
                  to take advantage of psychophysiologic empowerment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">In order to appropriately capture some of the above items,
                  the Short Physical Performance Battery (SPPB) has been used with good success in the stratification of
                  patients based on physical performance metrics, without a major ceiling effect<a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">48</span></a>. These metrics include balance, walking speed, and repeated
                  chair stand tests. The SPPB generally demonstrates good to excellent test-retest reliability and
                  interrater reliability, but may present ceiling effects in athletes. The routine measurement of the
                  SPPB requires an additional 10 minutes during a clinical visit and, although it represents additional
                  patient and clinical burden, provides added value in the quantification of the physiologic protection
                  and/or reserve (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>)<a
                    href="" data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                      style="position: unset;">49</span></a>, thus speaking to the elusive biological age<a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">11</span></a> of the patient.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Osteoporosis
                Among Men</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Although osteoporosis is often associated with the female
                  sex, the mortality rate associated with fragility fracture in men is up to double the rate in women<a
                    href="" data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">50</span></a>. In men, fragility fracture risk increases after 70 years
                  of age, increasing from 2.1% to 9.5% in men who are 80 years of age<a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">51</span></a>. Although there are well-established guidelines for
                  screening female patients for osteoporosis, the evidence for screening men in low-risk populations has
                  been weak. Current guidelines state that men should be assessed for osteoporosis beginning at 70 years
                  of age, although this age may decrease in the future based on improved risk factor analyses<a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">52</span></a>. Lower morbidity but a potential for higher mortality in
                  male patients in the setting of an osteoporotic fracture have led the osteoporosis community to call
                  for raising awareness about osteoporosis screening in male patients<a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">53</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Vertebral compression fractures are the most common type
                  of osteoporotic fracture. Usually, the treatment focus for these fractures is to implement measures to
                  prevent further collapse of the involved vertebral body, to prevent secondary fractures, and to
                  improve pain and function in patients. In an RCT, Peris et al. identified that patient sex can
                  influence the patient’s outcomes regardless of the kind of treatment used (conservative or
                  vertebroplasty)<a href="" data-id="citation_reference_59"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_51" class="annotation superscript" style="position: unset;">54</span></a>.
                  Male patients appear to have better outcomes in terms of pain and quality of life, independent of the
                  treatment received<a href="" data-id="citation_reference_60"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_52" class="annotation superscript" style="position: unset;">54</span></a>.
                  However, in a retrospective study of 492 patients, Gutiérrez-González et al. showed that male sex is
                  an independent predictor of mortality following a vertebral fracture<a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">55</span></a>, although this male predilection continues to be challenged
                  by studies such as the Tromsø Study<a href="" data-id="citation_reference_62"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_54" class="annotation superscript" style="position: unset;">56</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Drug Therapy
                for Osteoporosis: New Frontiers</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Multidrug
                Sequence and Dosing</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">First-line treatment for osteoporosis is typically an
                  antiresorptive agent such as bisphosphonates or denosumab, except in the setting of severe
                  osteoporosis, in which case an osteoanabolic agent is preferred. Romosozumab is an anti-sclerostin
                  antibody with both antiresorptive and anabolic effects resulting in up to 3 to 4 times the potency
                  compared with denosumab and is especially potent when followed by an antiresorptive agent<a href=""
                    data-id="citation_reference_63" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_55" class="annotation superscript"
                      style="position: unset;">57</span></a>. A summary of 4 RCTs<a href=""
                    data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_56" class="annotation superscript"
                      style="position: unset;">58</span></a> by Cosman et al. evaluated different romosozumab sequences
                  and identified that initial treatment with romosozumab followed by an antiresorptive agent
                  demonstrated the best BMD response. Patients who took denosumab for even a short period of time (1
                  year) before romosozumab saw smaller improvements in BMD. Generally, primary treatment with an
                  anabolic agent followed by an antiresorptive agent appears to be preferable to the reverse.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Rebound osteoporosis is a well-described problem with
                  discontinuation of denosumab. Inadvertent or intentional discontinuation is common in a real-world
                  clinical setting; in 1 retrospective study, Cruchelow et al. reported 36% of patients having lapses in
                  treatment and 10% of patients having discontinuation<a href="" data-id="citation_reference_65"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_57" class="annotation superscript" style="position: unset;">59</span></a>.
                  Rebound osteoporosis can be counteracted by using alendronate or zoledronic acid following
                  discontinuation of denosumab and subsequently tracking C-terminal peptide levels to anticipate reentry
                  into a resorptive state. Risedronate and raloxifene appear to be too weak to counteract this rebound
                  effect<a href="" data-id="citation_reference_66"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_58" class="annotation superscript"
                      style="position: unset;">60</span></a><span data-id="superscript_58"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_67" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_58" class="annotation superscript"
                      style="position: unset;">61</span></a>. Transitioning to a half-dose denosumab regimen was found
                  by Khan et al. to prevent bone loss and prevent fractures in postmenopausal women with a moderate
                  fracture risk, which presents an additional option for patients who do not tolerate the full-dose
                  therapy<a href="" data-id="citation_reference_68"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_59" class="annotation superscript" style="position: unset;">62</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Dito et al.<a href="" data-id="citation_reference_69"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_60" class="annotation superscript" style="position: unset;">63</span></a>
                  reported their retrospective experience with patients who underwent drug switching from 24 months of
                  teriparatide treatment to denosumab or zoledronic acid. Both regimens were found to improve BMD, and
                  zoledronic acid was not found to be inferior to denosumab. These results demonstrate that bone mass
                  consolidation after teriparatide can likely be performed with the use of any modern antiresorptive
                  agent, although the decreased need for redosing and lower cost favors the use of zoledronic acid in
                  this role.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Combination
                Drug Therapy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">One option to address non-response is combination therapy.
                  The DATA-HD (Combination Denosumab and High-Dose Teriparatide for Postmenopausal Osteoporosis) study<a
                    href="" data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_61" class="annotation superscript"
                      style="position: unset;">64</span></a><span data-id="superscript_61"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_71" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_61" class="annotation superscript"
                      style="position: unset;">65</span></a> showed that combination therapy with high-dose teriparatide
                  and denosumab is better than either drug alone. Rebound osteoporosis following this combination may be
                  circumvented by transition to denosumab alone or a bisphosphonate. Patients who stop teriparatide
                  alone do not experience rebound osteoporosis, but do experience persistent bone loss and also require
                  treatment. In an extension of the DATA-HD trial, Ramchand et al.<a href=""
                    data-id="citation_reference_72" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_62" class="annotation superscript"
                      style="position: unset;">66</span></a> identified that a single dose of zoledronic acid can
                  maintain the BMD improvement resulting from the combined treatment for at least 12 months. They also
                  identified that loss in BMD can occur up to 27 months after transition, and so repeat dosing with
                  zoledronic acid may be required alongside long-term follow-up. In a second extension study<a href=""
                    data-id="citation_reference_73" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_63" class="annotation superscript"
                      style="position: unset;">67</span></a>, Ramchand et al. identified that a single dose of
                  zoledronic acid is effective in maintaining large gains in BMD from combination therapy. However, this
                  single dose does not prevent loss in the volumetric BMD and bone microarchitecture, which suggests
                  that a follow-up dose of zoledronic acid or another treatment is needed in order to consolidate bone
                  density<a href="" data-id="citation_reference_74"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_64" class="annotation superscript" style="position: unset;">67</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Cyclic
                Teriparatide</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Teriparatide treatment produces a nonlinear increase in
                  BMD that is most rapid in the first 3 to 6 months of treatment, within what is known as the anabolic
                  window<a href="" data-id="citation_reference_75"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_65" class="annotation superscript" style="position: unset;">68</span></a>. In
                  a randomized, open-label study of postmenopausal women, Ganapathy et al.<a href=""
                    data-id="citation_reference_76" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">69</span></a> compared CT images following cyclic administration of
                  teriparatide or regular daily administration for 24 months. The cyclic regimen consisted of 3 months
                  of taking daily teriparatide followed by 3 months of not taking the medication. The regular regimen
                  was daily teriparatide for 24 months. In the extension period of the study, patients undergoing cyclic
                  dosing stayed on the cyclic regimen for 24 additional months, whereas the patients on the regular
                  regimen switched to alendronate for 24 additional months. In the first 24 months, spine BMD
                  improvement was significantly higher in the regular daily administration group than in the cyclic
                  group: total bone density, 12% in the regular daily administration group compared with 8% in the
                  cyclic group (p &lt; 0.001) and vertebral strength, 22% in the regular daily administration group
                  compared with 12% in the cyclic group (p &lt; 0.001). Hip BMD improvements were found to be minimal
                  but still favored the daily administration group. Comparing the 24-month daily administration and the
                  48-month cyclic administration regimen demonstrated no difference; the total spine density change was
                  +12% for the 24-month group and +11% for the 48-month group, and the total hip density change was +2%
                  for the 24-month group and +2% for the 48-month group (nonsignificant). Overall, the analysis did not
                  show any benefit to the cyclic regimen. Improvement in BMD and bone strength with teriparatide appears
                  to be related to the cumulative dose administrated rather than the regimen.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Updates on
                Drug Therapy for Non-Osteoporotic Indications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Peri-Implant
                Bone-Healing</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Supporting safety data for the use of newer agents
                  including abaloparatide and romosozumab have continued to popularize osteoanabolics for use in
                  perioperative skeletal optimization<a href="" data-id="citation_reference_77"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_67" class="annotation superscript" style="position: unset;">70</span></a>.
                  Mohanty et al.<a href="" data-id="citation_reference_78"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_68" class="annotation superscript" style="position: unset;">1</span></a>
                  studied patients with osteoporosis undergoing long spinal fusion who were treated with teriparatide
                  and had follow-up of at least 2 years. They compared outcomes among patients with preexisting
                  osteoporosis treated with teriparatide, patients with osteopenia, and patients with normal bone
                  density. A lower 2-year reoperation rate was observed in patients with osteoporosis receiving
                  teriparatide compared with the osteopenia group; OR, 0.45 (p = 0.018) in an unmatched cohort and OR,
                  0.45 (p = 0.019) in a matched cohort). Additionally, a lower pseudarthrosis rate was observed in
                  patients with osteoporosis receiving teriparatide compared with patients with osteopenia. Pedicle
                  screw loosening has similarly been linked to peripheral BMD<a href="" data-id="citation_reference_79"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_69" class="annotation superscript" style="position: unset;">71</span></a>,
                  creating a possible role for point-of-care peripheral BMD testing in anticipation of spinal fusion
                  surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Fracture-Healing</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Recent articles continue to show the positive impact of
                  parathyroid hormone (PTH) receptor analogs on fracture-healing. Yang et al.<a href=""
                    data-id="citation_reference_80" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_70" class="annotation superscript"
                      style="position: unset;">72</span></a> retrospectively compared 16 patients treated with
                  teriparatide for 6 months and 15 patients who underwent cement sacroplasty and found that the
                  teriparatide group showed greater improvements in pain and the Oswestry Disability Index at 1, 3, and
                  6 months. Gou et al.<a href="" data-id="citation_reference_81"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_71" class="annotation superscript" style="position: unset;">73</span></a>
                  retrospectively evaluated patients treated for acute osteoporotic compression fractures. They compared
                  patients who underwent cement augmentation and patients who received recombinant human PTH, rhPTH(1 to
                  34), 20 μg daily for 6 months. They reported that the PTH group had improved pain control and better
                  health-related quality of life. Also, these patients had substantial improvements in BMD after 12
                  months.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Although treatment with romosozumab is theoretically
                  beneficial for fracture-healing due to its dual antiresorptive-osteoanabolic action, Schemitsch et al.
                  previously showed that it did not improve fracture-healing following intertrochanteric hip fractures<a
                    href="" data-id="citation_reference_82" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_72" class="annotation superscript"
                      style="position: unset;">74</span></a>. In a retrospective study, Hayashi<a href=""
                    data-id="citation_reference_83" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_73" class="annotation superscript"
                      style="position: unset;">75</span></a> evaluated the effect of 3 different formulations of
                  teriparatide as well as romosozumab on bone healing and pain following a vertebral compression
                  fracture, finding that a daily teriparatide injection led to greater improvement in bone union (84.4%;
                  p = 0.0029) than romosozumab (40.0%). When comparing the teriparatide and romosozumab groups, Hayashi
                  also found greater improvement in low back pain in the groups receiving teriparatide daily (84.4%
                  compared with 25.0%; p = 0.0001), twice a week (78.3% compared with 25.0%; p = 0.0009), and weekly
                  (62.5% compared with 25.0%; p = 0.0341).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Delayed
                Union and Stress Fractures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The treatment of subchondral or stress fractures using
                  anabolic agents is also relevant to the discussion of the aging athlete, although data are limited. In
                  a multicenter study, Gariffo et al.<a href="" data-id="citation_reference_84"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_74" class="annotation superscript" style="position: unset;">76</span></a>
                  retrospectively analyzed 20 patients presenting with delayed union of long-bone fractures at a mean
                  age of 55 years. They found that using daily teriparatide in an off-label manner yielded complete
                  fracture-healing in 85% by 6 months. Byun et al.<a href="" data-id="citation_reference_85"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_75" class="annotation superscript" style="position: unset;">77</span></a>
                  performed a systematic review and meta-analysis that showed a lower rate of delayed union in
                  teriparatide-treated atypical femoral fractures compared with untreated controls (OR, 0.23; p &lt;
                  0.01). The untreated group exhibited a longer healing time, by 1.78 months, but did not demonstrate a
                  difference in the overall rates of nonunion or reoperation. Bisphosphonates, selective estrogen
                  receptor modulators (SERMs), and hormone replacement therapy have been shown to be safe to continue in
                  the setting of fracture-healing<a href="" data-id="citation_reference_86"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_76" class="annotation superscript" style="position: unset;">78</span></a>.
                  Nevertheless, due to the potential for interference with intramembranous bone healing and a potential
                  detrimental effect on osteocytes<a href="" data-id="citation_reference_87"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_77" class="annotation superscript" style="position: unset;">79</span></a>,
                  especially following primary fixation using a stiff orthopaedic construct, surgeons should still
                  consider pausing bisphosphonates in favor of switching to a PTH receptor analog for patients with a
                  fracture who were undergoing bisphosphonate therapy, especially for those with suppressed
                  osteoanabolic serum markers<a href="" data-id="citation_reference_88"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_78" class="annotation superscript"
                      style="position: unset;">80</span></a><span data-id="superscript_78"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_89" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_78" class="annotation superscript"
                      style="position: unset;">81</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Summary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">As patients and treatment regimens evolve together and
                  push the boundaries of what is possible in active aging individuals, clinicians remain central in
                  their orchestration. Optimistically, advancing research may finally teach us how to summarily measure
                  risk and tailor patient-centered treatments. Until then, the doctor remains the first and last line of
                  defense in our patients’ fight against osteoporosis and, perhaps more importantly, in their
                  maintenance of healthy physical independence.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Evidence-Based Orthopaedics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The editorial staff of <span data-id="emphasis_82"
                    class="annotation emphasis" style="position: unset;">JBJS</span> reviewed a large number of recently
                  published studies related to the musculoskeletal system that received a higher Level of Evidence
                  grade. In addition to articles cited already in this update, 4 other articles were identified that are
                  relevant to osteoporosis. A list of those titles is appended to this review after the standard
                  bibliography. We have provided a brief commentary about each of the articles to help guide your
                  further reading, in an evidence-based fashion, in this subspecialty area.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Evidence-Based Orthopaedics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_83" class="annotation strong"
                    style="position: unset;">Huang HK, Chuang AT, Liao TC, Shao SC, Liu PP, Tu Y, Lai EC.</span>
                  Denosumab and the risk of diabetes in patients treated for osteoporosis. <span data-id="emphasis_83"
                    class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2024 Feb
                  5;7(2):e2354734.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">In a large Taiwanese cohort study, Huang et al. evaluated
                  data from 68,510 patients. They found a lower cumulative incidence of diabetes in patients undergoing
                  denosumab treatment compared with a propensity-matched control cohort. Their age-stratified analysis
                  identified an association between decreased diabetes risk and denosumab treatment, specifically in
                  adults ≥65 years of age. Although confounding bias is always possible, this is nevertheless an
                  exciting development in the osteoporosis literature.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">These new data suggest a lower risk for diabetes in
                  patients receiving denosumab and may favor its use in specific at-risk populations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_84" class="annotation strong"
                    style="position: unset;">Lyu H, Zhao SS, Zhang L, Wei J, Li X, Li H, Liu Y, Yin P, Norvang V,
                    Yoshida K, Tedeschi SK, Zeng C, Lei G, Tang P, Solomon DH.</span> Denosumab and incidence of type 2
                  diabetes among adults with osteoporosis: population based cohort study. <span data-id="emphasis_84"
                    class="annotation emphasis" style="position: unset;">BMJ</span>. 2023 Apr 18;381:e073435.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">This propensity-matched retrospective cohort study
                  comparing 4,301 patients treated with denosumab and 21,038 patients treated with oral bisphosphonates
                  demonstrated a lower incidence of type 2 diabetes with a hazard ratio of 0.68 (95% confidence interval
                  [CI], 0.52 to 0.89). This study utilized a U.K. database, which suggests that the positive glycemic
                  effect of denosumab may be conserved across populations. It was hypothesized that the mechanism of
                  action is driven by improved glucose metabolism through suppression of receptor activator of nuclear
                  factor kappa-B ligand (RANKL) signaling. Prior randomized controlled drug trials on the effect of
                  denosumab on osteoporosis did not detect this effect, likely because of their underpowered study
                  designs for this secondary outcome.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The complementary data on the lower incidence of type 2
                  diabetes in patients receiving denosumab in the United Kingdom add to the evidence that denosumab may
                  have consistent antiglycemic effects.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_85" class="annotation strong"
                    style="position: unset;">Snyder PJ, Bauer DC, Ellenberg SS, Cauley JA, Buhr KA, Bhasin S, Miller MG,
                    Khan NS, Li X, Nissen SE.</span> Testosterone treatment and fractures in men with hypogonadism.
                  <span data-id="emphasis_85" class="annotation emphasis" style="position: unset;">N Engl J Med.</span>
                  2024 Jan 18;390(3):203-11.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Male patients with low levels of testosterone due to
                  hypogonadism benefit from testosterone replacement therapy. In a secondary analysis of a previous
                  study of the effect of testosterone treatment on cardiovascular events in middle-aged men with
                  hypogonadism, Snyder et al. evaluated the effect of testosterone treatment in lowering the risk of
                  fractures. The authors found that, among middle-aged men and older men with hypogonadism, testosterone
                  replacement compared with placebo did not result in a lower incidence of clinical fracture.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Hormone replacement therapy is historically known to be
                  clinically ineffective in older women in treating osteoporosis and preventing fractures. This study
                  demonstrates that testosterone replacement in men is also ineffective.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_86" class="annotation strong"
                    style="position: unset;">Tsai WH, Sung FC, Muo CH, Tsai MC, Wu SI.</span> Antiosteoporosis
                  medications and cardiovascular disease: a population-based nationwide nested case-control study. <span
                    data-id="emphasis_86" class="annotation emphasis" style="position: unset;">Front. Pharmacol</span>.
                  2023 Oct 10;14:1220174.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">In a report of the possible cardioprotective effects of
                  anti-osteoporosis medications from Taiwan, Tsai et al. performed a nested case-control study utilizing
                  the National Health Insurance Research Database. The study included 41,102 patients with a new
                  diagnosis of osteoporosis undergoing treatment with no drug, denosumab, teriparatide, bisphosphonates,
                  or hormone replacement therapy. When compared with patients who had no drug treatment, the adjusted
                  ORs of cardiovascular disease incidence were 0.13 (95% CI, 0.12 to 0.15) for denosumab users, 0.52
                  (95% CI, 0.45 to 0.61) for teriparatide users, and 0.80 (95% CI, 0.76 to 0.85) for bisphosphonate
                  users. In contrast, patients who used hormone replacement therapy demonstrated higher odds of
                  developing cardiovascular disease (OR, 1.36 [95% CI, 1.25 to 1.47]), which, when interpreted in
                  context, acts as a validation cohort for the statistical methods used. This large odds reduction in
                  favor of denosumab contradicts prior meta-analyses suggesting its net-neutral effect on cardiovascular
                  risk.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">The literature has been mixed on the topic of
                  cardioprotective effects of denosumab. However, recent data across multiple studies have suggested a
                  positive and durable effect of denosumab on cardiovascular health. This may favor its use in patients
                  with known risk factors for cardiovascular disease.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors specifically thank Heather Berman, BS, and William
                  Friedman, BS, for their assistance in the preparation of this work.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Mohanty S</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Sardar ZM</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Hassan FM</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Lombardi JM</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Lehman RA</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Lenke LG</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Impact of teriparatide on
                  complications and patient-reported outcomes of patients undergoing long spinal fusion according to
                  bone density</span>. <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2024 Feb 7;106(3):206-17.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=734f992f-c03d-43e7-9372-0ca7bbfe5c72" target="_new"
                  class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Catapano M</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Ahmed M</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Breslow RG</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Borg-Stein J</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The aging athlete</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">PM R.</span> 2022
                May;14(5):643-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20aging%20athlete&as_occt=title&as_sauthors=%20%22M%20Catapano%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Ahmadyar B</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Rosemann T</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Rüst CA</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Knechtle B</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Improved race times in
                  marathoners older than 75 years in the last 25 years in the world’s largest marathons</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Chin J Physiol.</span> 2016
                Jun 30;59(3):139-47.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Improved%20race%20times%20in%20marathoners%20older%20than%2075%20years%20in%20the%20last%2025%20years%20in%20the%20world%E2%80%99s%20largest%20marathons&as_occt=title&as_sauthors=%20%22B%20Ahmadyar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Pergolizzi J</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Matera J</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> LeQuang JAK</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Treating geriatric sports injury
                  among pickleball players: a narrative review of an exercise craze among seniors</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Cureus.</span> 2023 Dec
                4;15(12):e49909.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treating%20geriatric%20sports%20injury%20among%20pickleball%20players%3A%20a%20narrative%20review%20of%20an%20exercise%20craze%20among%20seniors&as_occt=title&as_sauthors=%20%22J%20Pergolizzi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Ángel Rodríguez M</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> García-Calleja P</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Terrados N</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Crespo I</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Del Valle M</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Olmedillas H</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Injury in CrossFit®: a
                  systematic review of epidemiology and risk factors</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Phys Sportsmed.</span> 2022
                Feb;50(1):3-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Injury%20in%20CrossFit%C2%AE%3A%20a%20systematic%20review%20of%20epidemiology%20and%20risk%20factors&as_occt=title&as_sauthors=%20%22M%20%C3%81ngel%20Rodr%C3%ADguez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Kammerlander C</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Braito M</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Kates S</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Jeske C</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Roth T</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Blauth M</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Dallapozza C</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The epidemiology of
                  sports-related injuries in older adults: a central European epidemiologic study</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Aging Clin Exp Res.</span>
                2012 Oct;24(5):448-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20epidemiology%20of%20sports-related%20injuries%20in%20older%20adults%3A%20a%20central%20European%20epidemiologic%20study&as_occt=title&as_sauthors=%20%22C%20Kammerlander%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Fong Yan A</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Nicholson LL</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Ward RE</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Hiller CE</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Dovey K</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Parker HM</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Low LF</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Moyle G</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Chan C</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">The effectiveness of dance
                  interventions on psychological and cognitive health outcomes compared with other forms of physical
                  activity: a systematic review with meta-analysis</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Sports Med.</span> 2024
                May;54(5):1179-205.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effectiveness%20of%20dance%20interventions%20on%20psychological%20and%20cognitive%20health%20outcomes%20compared%20with%20other%20forms%20of%20physical%20activity%3A%20a%20systematic%20review%20with%20meta-analysis&as_occt=title&as_sauthors=%20%22A%20Fong%20Yan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Lim Y</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Ha J</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Yoon KH</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Baek KH</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Kang MI</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Measures of physical performance
                  as a predictor of fracture risk independent of BMD: The Chungju metabolic disease cohort study</span>.
                <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Bone.</span> 2021
                Apr;145:115878.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measures%20of%20physical%20performance%20as%20a%20predictor%20of%20fracture%20risk%20independent%20of%20BMD%3A%20The%20Chungju%20metabolic%20disease%20cohort%20study&as_occt=title&as_sauthors=%20%22Y%20Lim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Han S</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Jang HD</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Choi S</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Kim GD</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Han K</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Lim H</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Koo B</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Min KD</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Hong JY</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Changes in physical activity and
                  risk of fracture: a Korean nationwide population-based cohort study</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Sci Rep.</span> 2020 Oct
                1;10(1):16266.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Changes%20in%20physical%20activity%20and%20risk%20of%20fracture%3A%20a%20Korean%20nationwide%20population-based%20cohort%20study&as_occt=title&as_sauthors=%20%22S%20Han%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Korhonen MT</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Heinonen A</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Siekkinen J</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Isolehto J</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Alén M</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Kiviranta I</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Suominen H</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Bone density, structure and
                  strength, and their determinants in aging sprint athletes</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Med Sci Sports Exerc.</span> 2012
                Dec;44(12):2340-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20density%2C%20structure%20and%20strength%2C%20and%20their%20determinants%20in%20aging%20sprint%20athletes&as_occt=title&as_sauthors=%20%22MT%20Korhonen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Cong T</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Hall AJ</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Jia Z</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Christiano A</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Elsevier HCK</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Cheung ZB</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Wellman D</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Forsh D</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Lane JM</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Conceptualizing biological
                  aging and frailty in orthopaedics: a framework for clinical practice</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2022 Jul 6;104(13):1212-22.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=3234210" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Mandl LA</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Rajan M</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Lipschultz RA</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Lian S</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Sheira D</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Frey MB</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Shea YM</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Lane JM</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The effect of social isolation
                  on 1-year outcomes after surgical repair of low-energy hip fracture</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span>
                2024 Apr 1;38(4):e149-56.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20social%20isolation%20on%201-year%20outcomes%20after%20surgical%20repair%20of%20low-energy%20hip%20fracture&as_occt=title&as_sauthors=%20%22LA%20Mandl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Ronel D</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Keren Y</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Muallem A</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Elboim-Gabyzon M</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">The effect of physical and
                  social isolation due to the COVID-19 pandemic on the incidence of hip fractures among senior
                  citizens</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Geriatr Nurs.</span> 2022 Jan-Feb;43:21-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20physical%20and%20social%20isolation%20due%20to%20the%20COVID-19%20pandemic%20on%20the%20incidence%20of%20hip%20fractures%20among%20senior%20citizens&as_occt=title&as_sauthors=%20%22D%20Ronel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Dent E</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Dalla Via J</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Bozanich T</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Hoogendijk EO</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Gebre AK</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Smith C</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Zhu K</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Prince RL</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Lewis JR</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Sim M</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Frailty increases the long-term
                  risk for fall and fracture-related hospitalizations and all-cause mortality in community-dwelling
                  older women</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J
                  Bone Miner Res.</span> 2024 Apr 19;39(3):222-30.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Frailty%20increases%20the%20long-term%20risk%20for%20fall%20and%20fracture-related%20hospitalizations%20and%20all-cause%20mortality%20in%20community-dwelling%20older%20women&as_occt=title&as_sauthors=%20%22E%20Dent%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Zhong BX</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Zhong HL</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Zhou GQ</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Xu WQ</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Lu Y</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Zhao Q</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Physical performance and risk
                  of hip fracture in community-dwelling elderly people in China: a 4-year longitudinal cohort
                  study</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Maturitas.</span> 2021 Apr;146:26-33.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Physical%20performance%20and%20risk%20of%20hip%20fracture%20in%20community-dwelling%20elderly%20people%20in%20China%3A%20a%204-year%20longitudinal%20cohort%20study&as_occt=title&as_sauthors=%20%22BX%20Zhong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Gates M</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Pillay J</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Nuspl M</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Wingert A</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Vandermeer B</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Hartling L</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Screening for the primary
                  prevention of fragility fractures among adults aged 40 years and older in primary care: systematic
                  reviews of the effects and acceptability of screening and treatment, and the accuracy of risk
                  prediction tools</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Syst Rev.</span> 2023 Mar 21;12(1):51.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Screening%20for%20the%20primary%20prevention%20of%20fragility%20fractures%20among%20adults%20aged%2040%20years%20and%20older%20in%20primary%20care%3A%20systematic%20reviews%20of%20the%20effects%20and%20acceptability%20of%20screening%20and%20treatment%2C%20and%20the%20accuracy%20of%20risk%20prediction%20tools&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Sornay-Rendu E</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Boutroy S</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Duboeuf F</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Chapurlat RD</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Bone Microarchitecture assessed
                  by HR-pQCT as predictor of fracture risk in postmenopausal women: the OFELY study</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J Bone Miner Res.</span>
                2017 Jun;32(6):1243-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20Microarchitecture%20assessed%20by%20HR-pQCT%20as%20predictor%20of%20fracture%20risk%20in%20postmenopausal%20women%3A%20the%20OFELY%20study&as_occt=title&as_sauthors=%20%22E%20Sornay-Rendu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Sornay-Rendu E</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Duboeuf F</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Ulivieri FM</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Rinaudo L</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Chapurlat R</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">The bone strain index predicts
                  fragility fractures. The OFELY study</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Bone.</span> 2022 Apr;157:116348.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20bone%20strain%20index%20predicts%20fragility%20fractures.%20The%20OFELY%20study&as_occt=title&as_sauthors=%20%22E%20Sornay-Rendu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Szulc P</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Dufour AB</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Hannan MT</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Kiel DP</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Chapurlat R</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Sornay-Rendu E</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Merle B</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Boyd SK</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Whittier DE</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Hanley DA</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Goltzman D</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Kin On Wong A</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Lespessailles E</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Kholsa S</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Ferrari S</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Biver E</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Bouxsein ML</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Samelson EJ</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Fracture risk based on
                  high-resolution peripheral quantitative computed tomography measures does not vary with age in older
                  adults - the Bone Microarchitecture International Consortium (BoMIC) prospective cohort study</span>.
                <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Bone Miner
                  Res.</span> 2024 May 24;39(5):561-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%20risk%20based%20on%20high-resolution%20peripheral%20quantitative%20computed%20tomography%20measures%20does%20not%20vary%20with%20age%20in%20older%20adults%20-%20the%20Bone%20Microarchitecture%20International%20Consortium%20(BoMIC)%20prospective%20cohort%20study&as_occt=title&as_sauthors=%20%22P%20Szulc%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Senanayake D</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Seneviratne S</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Imani M</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Harijanto C</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Sales M</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Lee P</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Duque G</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Ackland DC</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Classification of fracture risk
                  in fallers using dual-energy X-ray absorptiometry (DXA) images and deep learning-based feature
                  extraction</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">JBMR Plus.</span> 2023 Oct 19;7(12):e10828.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Classification%20of%20fracture%20risk%20in%20fallers%20using%20dual-energy%20X-ray%20absorptiometry%20(DXA)%20images%20and%20deep%20learning-based%20feature%20extraction&as_occt=title&as_sauthors=%20%22D%20Senanayake%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Singer AJ</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Williams SA</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Pearman L</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Pyrih N</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Jeray K</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">An evaluation of treatment
                  patterns for osteoporosis and outcomes after a fragility fracture in a real-world setting</span>.
                <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span>
                2023 Apr 1;37(4):e159-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20evaluation%20of%20treatment%20patterns%20for%20osteoporosis%20and%20outcomes%20after%20a%20fragility%20fracture%20in%20a%20real-world%20setting&as_occt=title&as_sauthors=%20%22AJ%20Singer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Huang T</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Li C</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Chen F</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Xie D</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Yang C</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Chen Y</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Wang J</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Zheng F</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Prevalence and risk factors of
                  osteosarcopenia: a systematic review and meta-analysis</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">BMC Geriatr.</span> 2023 Jun
                15;23(1):369.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20and%20risk%20factors%20of%20osteosarcopenia%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22T%20Huang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Binkley N</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Blank RD</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Leslie WD</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Lewiecki EM</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Eisman JA</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Bilezikian JP</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Osteoporosis in crisis: it’s
                  time to focus on fracture</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">J Bone Miner Res.</span> 2017 Jul;32(7):1391-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoporosis%20in%20crisis%3A%20it%E2%80%99s%20time%20to%20focus%20on%20fracture&as_occt=title&as_sauthors=%20%22N%20Binkley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Haeri NS</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Perera S</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Greenspan SL</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">The association of vitamin D
                  with bone microarchitecture, muscle strength, and mobility performance in older women in long-term
                  care</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Bone.</span> 2023 Nov;176:116867.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20association%20of%20vitamin%20D%20with%20bone%20microarchitecture%2C%20muscle%20strength%2C%20and%20mobility%20performance%20in%20older%20women%20in%20long-term%20care&as_occt=title&as_sauthors=%20%22NS%20Haeri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Kanis JA</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Johansson H</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Harvey NC</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Lorentzon M</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Liu E</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Vandenput L</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Morin S</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Leslie WD</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> McCloskey EV</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Adjusting conventional FRAX
                  estimates of fracture probability according to the number of prior falls in the preceding year</span>.
                <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Osteoporos Int.</span>
                2023 Mar;34(3):479-87.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adjusting%20conventional%20FRAX%20estimates%20of%20fracture%20probability%20according%20to%20the%20number%20of%20prior%20falls%20in%20the%20preceding%20year&as_occt=title&as_sauthors=%20%22JA%20Kanis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Schwartz AV</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Vittinghoff E</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Bauer DC</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Hillier TA</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Strotmeyer ES</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Ensrud KE</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Donaldson MG</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Cauley JA</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Harris TB</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Koster A</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Womack CR</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Palermo L</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Black DM</span>; <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Study of Osteoporotic Fractures
                  (SOF) Research Group; Osteoporotic Fractures in Men (MrOS) Research Group; Health, Aging, and Body
                  Composition (Health ABC) Research Group. Association of BMD and FRAX score with risk of fracture in
                  older adults with type 2 diabetes</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2011 Jun 1;305(21):2184-92.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Study%20of%20Osteoporotic%20Fractures%20(SOF)%20Research%20Group%3B%20Osteoporotic%20Fractures%20in%20Men%20(MrOS)%20Research%20Group%3B%20Health%2C%20Aging%2C%20and%20Body%20Composition%20(Health%20ABC)%20Research%20Group.%20Association%20of%20BMD%20and%20FRAX%20score%20with%20risk%20of%20fracture%20in%20older%20adults%20with%20type%202%20diabetes&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Kanis JA</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Johansson H</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Oden A</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> McCloskey EV</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Guidance for the adjustment of
                  FRAX according to the dose of glucocorticoids</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Osteoporos Int.</span> 2011
                Mar;22(3):809-16.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Guidance%20for%20the%20adjustment%20of%20FRAX%20according%20to%20the%20dose%20of%20glucocorticoids&as_occt=title&as_sauthors=%20%22JA%20Kanis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text"
                style="position: unset;">Osteoporosis Research, Ltd. <span data-id="strong_28" class="annotation strong"
                  style="position: unset;">Welcome to FRAX</span>. Accessed 2024 Apr 14. <a
                  href="http://www.fraxplus.org" target="_blank" data-id="link_1" class="link"
                  style="position: unset;">www.fraxplus.org</a>.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Welcome%20to%20FRAX&as_occt=title&as_sauthors=%20%22Osteoporosis%20Research%2C%20Ltd%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Kanis JA</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Johansson H</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> McCloskey EV</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Liu E</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Åkesson KE</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Anderson FA</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Azagra R</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Bager CL</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Beaudart C</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Bischoff-Ferrari HA</span>,
                <span data-id="annotation_199" class="annotation" style="position: unset;"> Biver E</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Bruyère O</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Cauley JA</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Center JR</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Chapurlat R</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Christiansen C</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Cooper C</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Crandall CJ</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Cummings SR</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> da Silva JAP</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Dawson-Hughes B</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Diez-Perez A</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Dufour AB</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Eisman JA</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Elders PJM</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Ferrari S</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Fujita Y</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Fujiwara S</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Glüer CC</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Goldshtein I</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Goltzman D</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Gudnason V</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Hall J</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Hans D</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Hoff M</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Hollick RJ</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Huisman M</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Iki M</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Ish-Shalom S</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Jones G</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Karlsson MK</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Khosla S</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Kiel DP</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Koh WP</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Koromani F</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Kotowicz MA</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Kröger H</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Kwok T</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Lamy O</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Langhammer A</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Larijani B</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Lippuner K</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Mellström D</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Merlijn T</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Nordström A</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Nordström P</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> O’Neill TW</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Obermayer-Pietsch B</span>,
                <span data-id="annotation_247" class="annotation" style="position: unset;"> Ohlsson C</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Orwoll ES</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Pasco JA</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Rivadeneira F</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Schott AM</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Shiroma EJ</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Siggeirsdottir K</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Simonsick EM</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Sornay-Rendu E</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Sund R</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Swart KMA</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Szulc P</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Tamaki J</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Torgerson DJ</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> van Schoor NM</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> van Staa TP</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Vila J</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Wareham NJ</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Wright NC</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Yoshimura N</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Zillikens MC</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Zwart M</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Vandenput L</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Harvey NC</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Lorentzon M</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Leslie WD</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Previous fracture and
                  subsequent fracture risk: a meta-analysis to update FRAX</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">Osteoporos Int.</span> 2023
                Dec;34(12):2027-45.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Previous%20fracture%20and%20subsequent%20fracture%20risk%3A%20a%20meta-analysis%20to%20update%20FRAX&as_occt=title&as_sauthors=%20%22JA%20Kanis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Vandenput L</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Johansson H</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> McCloskey EV</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Liu E</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Schini M</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Åkesson KE</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Anderson FA</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Azagra R</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Bager CL</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Beaudart C</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Bischoff-Ferrari HA</span>,
                <span data-id="annotation_284" class="annotation" style="position: unset;"> Biver E</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Bruyère O</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Cauley JA</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Center JR</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Chapurlat R</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Christiansen C</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Cooper C</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Crandall CJ</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Cummings SR</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> da Silva JAP</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Dawson-Hughes B</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Diez-Perez A</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Dufour AB</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Eisman JA</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Elders PJM</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Ferrari S</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Fujita Y</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Fujiwara S</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Glüer CC</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Goldshtein I</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Goltzman D</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Gudnason V</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Hall J</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Hans D</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Hoff M</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Hollick RJ</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Huisman M</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Iki M</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Ish-Shalom S</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Jones G</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Karlsson MK</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Khosla S</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Kiel DP</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Koh WP</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Koromani F</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Kotowicz MA</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Kröger H</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Kwok T</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Lamy O</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Langhammer A</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Larijani B</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Lippuner K</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> McGuigan FEA</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Mellström D</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Merlijn T</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Nguyen TV</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Nordström A</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Nordström P</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> O’Neill TW</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Obermayer-Pietsch B</span>,
                <span data-id="annotation_334" class="annotation" style="position: unset;"> Ohlsson C</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Orwoll ES</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Pasco JA</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Rivadeneira F</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Schott AM</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Shiroma EJ</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Siggeirsdottir K</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Simonsick EM</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Sornay-Rendu E</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Sund R</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Swart KMA</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Szulc P</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Tamaki J</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Torgerson DJ</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> van Schoor NM</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> van Staa TP</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Vila J</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Wareham NJ</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Wright NC</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Yoshimura N</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Zillikens M</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Zwart M</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Harvey NC</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Lorentzon M</span>, <span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Leslie WD</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Kanis JA</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">A meta-analysis of previous
                  falls and subsequent fracture risk in cohort studies</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Osteoporos Int.</span> 2024
                Mar;35(3):469-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20meta-analysis%20of%20previous%20falls%20and%20subsequent%20fracture%20risk%20in%20cohort%20studies&as_occt=title&as_sauthors=%20%22L%20Vandenput%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Rapp K</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Lamb SE</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Roigk P</span>, <span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Becker C</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Konnopka C</span>, <span
                  data-id="annotation_365" class="annotation" style="position: unset;"> König HH</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Peter RS</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Rothenbacher D</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Büchele G</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Effect of an osteoporotic
                  fracture prevention program on fracture incidence in routine care: a cluster-randomized trial</span>.
                <span data-id="emphasis_30" class="annotation emphasis" style="position: unset;">BMC Med.</span> 2022
                Feb 4;20(1):49.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20an%20osteoporotic%20fracture%20prevention%20program%20on%20fracture%20incidence%20in%20routine%20care%3A%20a%20cluster-randomized%20trial&as_occt=title&as_sauthors=%20%22K%20Rapp%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Chudasama YV</span>, <span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Khunti KK</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Zaccardi F</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Rowlands AV</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Yates T</span>, <span
                  data-id="annotation_374" class="annotation" style="position: unset;"> Gillies CL</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Davies MJ</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Dhalwani NN</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Physical activity,
                  multimorbidity, and life expectancy: a UK Biobank longitudinal study</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">BMC Med.</span> 2019 Jun
                12;17(1):108.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Physical%20activity%2C%20multimorbidity%2C%20and%20life%20expectancy%3A%20a%20UK%20Biobank%20longitudinal%20study&as_occt=title&as_sauthors=%20%22YV%20Chudasama%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Zheng Q</span>, <span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Kernozek T</span>, <span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Daoud-Gray A</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Borer KT</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Anabolic bone stimulus requires
                  a pre-exercise meal and 45-minute walking impulse of suprathreshold speed-enhanced momentum to prevent
                  or mitigate postmenopausal osteoporosis within circadian constraints</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Nutrients.</span> 2021 Oct
                22;13(11):3727.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anabolic%20bone%20stimulus%20requires%20a%20pre-exercise%20meal%20and%2045-minute%20walking%20impulse%20of%20suprathreshold%20speed-enhanced%20momentum%20to%20prevent%20or%20mitigate%20postmenopausal%20osteoporosis%20within%20circadian%20constraints&as_occt=title&as_sauthors=%20%22Q%20Zheng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_381" class="annotation" style="position: unset;"> LaMonte MJ</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Wactawski-Wende J</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Larson JC</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Mai X</span>, <span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Robbins JA</span>, <span
                  data-id="annotation_386" class="annotation" style="position: unset;"> LeBoff MS</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Chen Z</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Jackson RD</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> LaCroix AZ</span>, <span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Ockene JK</span>, <span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Hovey KM</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Cauley JA</span>; Women’s Health
                Initiative (WHI). <span data-id="strong_34" class="annotation strong"
                  style="position: unset;">Association of physical activity and fracture risk among postmenopausal
                  women</span>. <span data-id="emphasis_33" class="annotation emphasis" style="position: unset;">JAMA
                  Netw Open.</span> 2019 Oct 2;2(10):e1914084.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20physical%20activity%20and%20fracture%20risk%20among%20postmenopausal%20women&as_occt=title&as_sauthors=%20%22MJ%20LaMonte%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Stattin K</span>, <span
                  data-id="annotation_394" class="annotation" style="position: unset;"> Höijer J</span>, <span
                  data-id="annotation_395" class="annotation" style="position: unset;"> Hållmarker U</span>, <span
                  data-id="annotation_396" class="annotation" style="position: unset;"> Baron JA</span>, <span
                  data-id="annotation_397" class="annotation" style="position: unset;"> Larsson SC</span>, <span
                  data-id="annotation_398" class="annotation" style="position: unset;"> Wolk A</span>, <span
                  data-id="annotation_399" class="annotation" style="position: unset;"> Michaëlsson K</span>, <span
                  data-id="annotation_400" class="annotation" style="position: unset;"> Byberg L</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Fracture risk across a wide
                  range of physical activity levels, from sedentary individuals to elite athletes</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Bone.</span> 2021
                Dec;153:116128.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%20risk%20across%20a%20wide%20range%20of%20physical%20activity%20levels%2C%20from%20sedentary%20individuals%20to%20elite%20athletes&as_occt=title&as_sauthors=%20%22K%20Stattin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_401" class="annotation" style="position: unset;"> Giangregorio LM</span>, <span
                  data-id="annotation_402" class="annotation" style="position: unset;"> Ponzano M</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Exercise and physical activity
                  in individuals at risk of fracture</span>. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">Best Pract Res Clin Endocrinol Metab.</span> 2022
                Mar;36(2):101613.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Exercise%20and%20physical%20activity%20in%20individuals%20at%20risk%20of%20fracture&as_occt=title&as_sauthors=%20%22LM%20Giangregorio%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_403" class="annotation" style="position: unset;"> Lewiecki EM</span>, <span
                  data-id="annotation_404" class="annotation" style="position: unset;"> Dinavahi RV</span>, <span
                  data-id="annotation_405" class="annotation" style="position: unset;"> Lazaretti-Castro M</span>, <span
                  data-id="annotation_406" class="annotation" style="position: unset;"> Ebeling PR</span>, <span
                  data-id="annotation_407" class="annotation" style="position: unset;"> Adachi JD</span>, <span
                  data-id="annotation_408" class="annotation" style="position: unset;"> Miyauchi A</span>, <span
                  data-id="annotation_409" class="annotation" style="position: unset;"> Gielen E</span>, <span
                  data-id="annotation_410" class="annotation" style="position: unset;"> Milmont CE</span>, <span
                  data-id="annotation_411" class="annotation" style="position: unset;"> Libanati C</span>, <span
                  data-id="annotation_412" class="annotation" style="position: unset;"> Grauer A</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">One year of romosozumab
                  followed by two years of denosumab maintains fracture risk reductions: results of the FRAME Extension
                  study</span>. <span data-id="emphasis_36" class="annotation emphasis" style="position: unset;">J Bone
                  Miner Res.</span> 2019 Mar;34(3):419-28.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=One%20year%20of%20romosozumab%20followed%20by%20two%20years%20of%20denosumab%20maintains%20fracture%20risk%20reductions%3A%20results%20of%20the%20FRAME%20Extension%20study&as_occt=title&as_sauthors=%20%22EM%20Lewiecki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_413" class="annotation" style="position: unset;"> Langdahl B</span>, <span
                  data-id="annotation_414" class="annotation" style="position: unset;"> Hofbauer LC</span>, <span
                  data-id="annotation_415" class="annotation" style="position: unset;"> Ferrari S</span>, <span
                  data-id="annotation_416" class="annotation" style="position: unset;"> Wang Z</span>, <span
                  data-id="annotation_417" class="annotation" style="position: unset;"> Fahrleitner-Pammer A</span>,
                <span data-id="annotation_418" class="annotation" style="position: unset;"> Gielen E</span>, <span
                  data-id="annotation_419" class="annotation" style="position: unset;"> Lakatos P</span>, <span
                  data-id="annotation_420" class="annotation" style="position: unset;"> Czerwinski E</span>, <span
                  data-id="annotation_421" class="annotation" style="position: unset;"> Gimeno EJ</span>, <span
                  data-id="annotation_422" class="annotation" style="position: unset;"> Timoshanko J</span>, <span
                  data-id="annotation_423" class="annotation" style="position: unset;"> Oates M</span>, <span
                  data-id="annotation_424" class="annotation" style="position: unset;"> Libanati C</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Romosozumab efficacy and safety
                  in European patients enrolled in the FRAME trial</span>. <span data-id="emphasis_37"
                  class="annotation emphasis" style="position: unset;">Osteoporos Int.</span> 2022
                Dec;33(12):2527-36.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Romosozumab%20efficacy%20and%20safety%20in%20European%20patients%20enrolled%20in%20the%20FRAME%20trial&as_occt=title&as_sauthors=%20%22B%20Langdahl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_425" class="annotation" style="position: unset;"> McClung MR</span>, <span
                  data-id="annotation_426" class="annotation" style="position: unset;"> Betah D</span>, <span
                  data-id="annotation_427" class="annotation" style="position: unset;"> Deignan C</span>, <span
                  data-id="annotation_428" class="annotation" style="position: unset;"> Shi Y</span>, <span
                  data-id="annotation_429" class="annotation" style="position: unset;"> Timoshanko J</span>, <span
                  data-id="annotation_430" class="annotation" style="position: unset;"> Cosman F</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Romosozumab efficacy in
                  postmenopausal women with no prior fracture who fulfill criteria for very high fracture risk</span>.
                <span data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Endocr Pract.</span>
                2023 Sep;29(9):716-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Romosozumab%20efficacy%20in%20postmenopausal%20women%20with%20no%20prior%20fracture%20who%20fulfill%20criteria%20for%20very%20high%20fracture%20risk&as_occt=title&as_sauthors=%20%22MR%20McClung%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_431" class="annotation" style="position: unset;"> Händel MN</span>, <span
                  data-id="annotation_432" class="annotation" style="position: unset;"> Cardoso I</span>, <span
                  data-id="annotation_433" class="annotation" style="position: unset;"> von Bülow C</span>, <span
                  data-id="annotation_434" class="annotation" style="position: unset;"> Rohde JF</span>, <span
                  data-id="annotation_435" class="annotation" style="position: unset;"> Ussing A</span>, <span
                  data-id="annotation_436" class="annotation" style="position: unset;"> Nielsen SM</span>, <span
                  data-id="annotation_437" class="annotation" style="position: unset;"> Christensen R</span>, <span
                  data-id="annotation_438" class="annotation" style="position: unset;"> Body JJ</span>, <span
                  data-id="annotation_439" class="annotation" style="position: unset;"> Brandi ML</span>, <span
                  data-id="annotation_440" class="annotation" style="position: unset;"> Diez-Perez A</span>, <span
                  data-id="annotation_441" class="annotation" style="position: unset;"> Hadji P</span>, <span
                  data-id="annotation_442" class="annotation" style="position: unset;"> Javaid MK</span>, <span
                  data-id="annotation_443" class="annotation" style="position: unset;"> Lems WF</span>, <span
                  data-id="annotation_444" class="annotation" style="position: unset;"> Nogues X</span>, <span
                  data-id="annotation_445" class="annotation" style="position: unset;"> Roux C</span>, <span
                  data-id="annotation_446" class="annotation" style="position: unset;"> Minisola S</span>, <span
                  data-id="annotation_447" class="annotation" style="position: unset;"> Kurth A</span>, <span
                  data-id="annotation_448" class="annotation" style="position: unset;"> Thomas T</span>, <span
                  data-id="annotation_449" class="annotation" style="position: unset;"> Prieto-Alhambra D</span>, <span
                  data-id="annotation_450" class="annotation" style="position: unset;"> Ferrari SL</span>, <span
                  data-id="annotation_451" class="annotation" style="position: unset;"> Langdahl B</span>, <span
                  data-id="annotation_452" class="annotation" style="position: unset;"> Abrahamsen B</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Fracture risk reduction and
                  safety by osteoporosis treatment compared with placebo or active comparator in postmenopausal women:
                  systematic review, network meta-analysis, and meta-regression analysis of randomised clinical
                  trials</span>. <span data-id="emphasis_39" class="annotation emphasis"
                  style="position: unset;">BMJ.</span> 2023 May 2;381:e068033.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%20risk%20reduction%20and%20safety%20by%20osteoporosis%20treatment%20compared%20with%20placebo%20or%20active%20comparator%20in%20postmenopausal%20women%3A%20systematic%20review%2C%20network%20meta-analysis%2C%20and%20meta-regression%20analysis%20of%20randomised%20clinical%20trials&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_453" class="annotation" style="position: unset;"> Lewiecki EM</span>, <span
                  data-id="annotation_454" class="annotation" style="position: unset;"> Betah D</span>, <span
                  data-id="annotation_455" class="annotation" style="position: unset;"> Humbert L</span>, <span
                  data-id="annotation_456" class="annotation" style="position: unset;"> Libanati C</span>, <span
                  data-id="annotation_457" class="annotation" style="position: unset;"> Oates M</span>, <span
                  data-id="annotation_458" class="annotation" style="position: unset;"> Shi Y</span>, <span
                  data-id="annotation_459" class="annotation" style="position: unset;"> Winzenrieth R</span>, <span
                  data-id="annotation_460" class="annotation" style="position: unset;"> Ferrari S</span>, <span
                  data-id="annotation_461" class="annotation" style="position: unset;"> Omura F</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">3D-modeling from hip DXA shows
                  improved bone structure with romosozumab followed by denosumab or alendronate</span>. <span
                  data-id="emphasis_40" class="annotation emphasis" style="position: unset;">J Bone Miner Res.</span>
                2024 May 2;39(4):473-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=3D-modeling%20from%20hip%20DXA%20shows%20improved%20bone%20structure%20with%20romosozumab%20followed%20by%20denosumab%20or%20alendronate&as_occt=title&as_sauthors=%20%22EM%20Lewiecki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_462" class="annotation" style="position: unset;"> Schlacht TZ</span>, <span
                  data-id="annotation_463" class="annotation" style="position: unset;"> Haque I</span>, <span
                  data-id="annotation_464" class="annotation" style="position: unset;"> Skelton DA</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">What are the effects of
                  exercise on trabecular microarchitecture in older adults? A systematic review and meta-analysis of
                  HR-pQCT studies</span>. <span data-id="emphasis_41" class="annotation emphasis"
                  style="position: unset;">Calcif Tissue Int.</span> 2023 Oct;113(4):359-82.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20are%20the%20effects%20of%20exercise%20on%20trabecular%20microarchitecture%20in%20older%20adults%3F%20A%20systematic%20review%20and%20meta-analysis%20of%20HR-pQCT%20studies&as_occt=title&as_sauthors=%20%22TZ%20Schlacht%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_465" class="annotation" style="position: unset;"> Rogmark C</span>, <span
                  data-id="annotation_466" class="annotation" style="position: unset;"> Fedorowski A</span>, <span
                  data-id="annotation_467" class="annotation" style="position: unset;"> Hamrefors V</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Physical activity and
                  psychosocial factors associated with risk of future fractures in middle-aged men and women</span>.
                <span data-id="emphasis_42" class="annotation emphasis" style="position: unset;">J Bone Miner
                  Res.</span> 2021 May;36(5):852-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Physical%20activity%20and%20psychosocial%20factors%20associated%20with%20risk%20of%20future%20fractures%20in%20middle-aged%20men%20and%20women&as_occt=title&as_sauthors=%20%22C%20Rogmark%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_468" class="annotation" style="position: unset;"> Ma M</span>, <span
                  data-id="annotation_469" class="annotation" style="position: unset;"> Feng Z</span>, <span
                  data-id="annotation_470" class="annotation" style="position: unset;"> Liu X</span>, <span
                  data-id="annotation_471" class="annotation" style="position: unset;"> Jia G</span>, <span
                  data-id="annotation_472" class="annotation" style="position: unset;"> Geng B</span>, <span
                  data-id="annotation_473" class="annotation" style="position: unset;"> Xia Y</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">The saturation effect of body
                  mass index on bone mineral density for people over 50 years old: a cross-sectional study of the US
                  population</span>. <span data-id="emphasis_43" class="annotation emphasis"
                  style="position: unset;">Front Nutr.</span> 2021 Oct 15;8:763677.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20saturation%20effect%20of%20body%20mass%20index%20on%20bone%20mineral%20density%20for%20people%20over%2050%20years%20old%3A%20a%20cross-sectional%20study%20of%20the%20US%20population&as_occt=title&as_sauthors=%20%22M%20Ma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_474" class="annotation" style="position: unset;"> Han H</span>, <span
                  data-id="annotation_475" class="annotation" style="position: unset;"> Chen S</span>, <span
                  data-id="annotation_476" class="annotation" style="position: unset;"> Wang X</span>, <span
                  data-id="annotation_477" class="annotation" style="position: unset;"> Jin J</span>, <span
                  data-id="annotation_478" class="annotation" style="position: unset;"> Li X</span>, <span
                  data-id="annotation_479" class="annotation" style="position: unset;"> Li Z</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Association between muscle
                  strength and mass and bone mineral density in the US general population: data from NHANES
                  1999-2002</span>. <span data-id="emphasis_44" class="annotation emphasis" style="position: unset;">J
                  Orthop Surg Res.</span> 2023 Jun 1;18(1):397.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20muscle%20strength%20and%20mass%20and%20bone%20mineral%20density%20in%20the%20US%20general%20population%3A%20data%20from%20NHANES%201999-2002&as_occt=title&as_sauthors=%20%22H%20Han%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_480" class="annotation" style="position: unset;"> Qin H</span>, <span
                  data-id="annotation_481" class="annotation" style="position: unset;"> Jiao W</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Correlation of muscle mass and
                  bone mineral density in the NHANES US general population, 2017-2018</span>. <span
                  data-id="emphasis_45" class="annotation emphasis" style="position: unset;">Medicine
                  (Baltimore).</span> 2022 Sep 30;101(39):e30735.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Correlation%20of%20muscle%20mass%20and%20bone%20mineral%20density%20in%20the%20NHANES%20US%20general%20population%2C%202017-2018&as_occt=title&as_sauthors=%20%22H%20Qin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_482" class="annotation" style="position: unset;"> Elhakeem A</span>, <span
                  data-id="annotation_483" class="annotation" style="position: unset;"> Hartley A</span>, <span
                  data-id="annotation_484" class="annotation" style="position: unset;"> Luo Y</span>, <span
                  data-id="annotation_485" class="annotation" style="position: unset;"> Goertzen AL</span>, <span
                  data-id="annotation_486" class="annotation" style="position: unset;"> Hannam K</span>, <span
                  data-id="annotation_487" class="annotation" style="position: unset;"> Clark EM</span>, <span
                  data-id="annotation_488" class="annotation" style="position: unset;"> Leslie WD</span>, <span
                  data-id="annotation_489" class="annotation" style="position: unset;"> Tobias JH</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Lean mass and lower limb muscle
                  function in relation to hip strength, geometry and fracture risk indices in community-dwelling older
                  women</span>. <span data-id="emphasis_46" class="annotation emphasis"
                  style="position: unset;">Osteoporos Int.</span> 2019 Jan;30(1):211-20.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lean%20mass%20and%20lower%20limb%20muscle%20function%20in%20relation%20to%20hip%20strength%2C%20geometry%20and%20fracture%20risk%20indices%20in%20community-dwelling%20older%20women&as_occt=title&as_sauthors=%20%22A%20Elhakeem%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_490" class="annotation" style="position: unset;"> Beaudart C</span>, <span
                  data-id="annotation_491" class="annotation" style="position: unset;"> Rolland Y</span>, <span
                  data-id="annotation_492" class="annotation" style="position: unset;"> Cruz-Jentoft AJ</span>, <span
                  data-id="annotation_493" class="annotation" style="position: unset;"> Bauer JM</span>, <span
                  data-id="annotation_494" class="annotation" style="position: unset;"> Sieber C</span>, <span
                  data-id="annotation_495" class="annotation" style="position: unset;"> Cooper C</span>, <span
                  data-id="annotation_496" class="annotation" style="position: unset;"> Al-Daghri N</span>, <span
                  data-id="annotation_497" class="annotation" style="position: unset;"> Araujo de Carvalho I</span>,
                <span data-id="annotation_498" class="annotation" style="position: unset;"> Bautmans I</span>, <span
                  data-id="annotation_499" class="annotation" style="position: unset;"> Bernabei R</span>, <span
                  data-id="annotation_500" class="annotation" style="position: unset;"> Bruyère O</span>, <span
                  data-id="annotation_501" class="annotation" style="position: unset;"> Cesari M</span>, <span
                  data-id="annotation_502" class="annotation" style="position: unset;"> Cherubini A</span>, <span
                  data-id="annotation_503" class="annotation" style="position: unset;"> Dawson-Hughes B</span>, <span
                  data-id="annotation_504" class="annotation" style="position: unset;"> Kanis JA</span>, <span
                  data-id="annotation_505" class="annotation" style="position: unset;"> Kaufman JM</span>, <span
                  data-id="annotation_506" class="annotation" style="position: unset;"> Landi F</span>, <span
                  data-id="annotation_507" class="annotation" style="position: unset;"> Maggi S</span>, <span
                  data-id="annotation_508" class="annotation" style="position: unset;"> McCloskey E</span>, <span
                  data-id="annotation_509" class="annotation" style="position: unset;"> Petermans J</span>, <span
                  data-id="annotation_510" class="annotation" style="position: unset;"> Rodriguez Mañas L</span>, <span
                  data-id="annotation_511" class="annotation" style="position: unset;"> Reginster JY</span>, <span
                  data-id="annotation_512" class="annotation" style="position: unset;"> Roller-Wirnsberger R</span>,
                <span data-id="annotation_513" class="annotation" style="position: unset;"> Schaap LA</span>, <span
                  data-id="annotation_514" class="annotation" style="position: unset;"> Uebelhart D</span>, <span
                  data-id="annotation_515" class="annotation" style="position: unset;"> Rizzoli R</span>, <span
                  data-id="annotation_516" class="annotation" style="position: unset;"> Fielding RA</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Assessment of muscle function
                  and physical performance in daily clinical practice: a position paper endorsed by the European Society
                  for Clinical and Economic Aspects of Osteoporosis, Osteoarthritis and Musculoskeletal Diseases
                  (ESCEO)</span>. <span data-id="emphasis_47" class="annotation emphasis"
                  style="position: unset;">Calcif Tissue Int.</span> 2019 Jul;105(1):1-14.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessment%20of%20muscle%20function%20and%20physical%20performance%20in%20daily%20clinical%20practice%3A%20a%20position%20paper%20endorsed%20by%20the%20European%20Society%20for%20Clinical%20and%20Economic%20Aspects%20of%20Osteoporosis%2C%20Osteoarthritis%20and%20Musculoskeletal%20Diseases%20(ESCEO)&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_517" class="annotation" style="position: unset;"> Pavasini R</span>, <span
                  data-id="annotation_518" class="annotation" style="position: unset;"> Guralnik J</span>, <span
                  data-id="annotation_519" class="annotation" style="position: unset;"> Brown JC</span>, <span
                  data-id="annotation_520" class="annotation" style="position: unset;"> di Bari M</span>, <span
                  data-id="annotation_521" class="annotation" style="position: unset;"> Cesari M</span>, <span
                  data-id="annotation_522" class="annotation" style="position: unset;"> Landi F</span>, <span
                  data-id="annotation_523" class="annotation" style="position: unset;"> Vaes B</span>, <span
                  data-id="annotation_524" class="annotation" style="position: unset;"> Legrand D</span>, <span
                  data-id="annotation_525" class="annotation" style="position: unset;"> Verghese J</span>, <span
                  data-id="annotation_526" class="annotation" style="position: unset;"> Wang C</span>, <span
                  data-id="annotation_527" class="annotation" style="position: unset;"> Stenholm S</span>, <span
                  data-id="annotation_528" class="annotation" style="position: unset;"> Ferrucci L</span>, <span
                  data-id="annotation_529" class="annotation" style="position: unset;"> Lai JC</span>, <span
                  data-id="annotation_530" class="annotation" style="position: unset;"> Bartes AA</span>, <span
                  data-id="annotation_531" class="annotation" style="position: unset;"> Espaulella J</span>, <span
                  data-id="annotation_532" class="annotation" style="position: unset;"> Ferrer M</span>, <span
                  data-id="annotation_533" class="annotation" style="position: unset;"> Lim JY</span>, <span
                  data-id="annotation_534" class="annotation" style="position: unset;"> Ensrud KE</span>, <span
                  data-id="annotation_535" class="annotation" style="position: unset;"> Cawthon P</span>, <span
                  data-id="annotation_536" class="annotation" style="position: unset;"> Turusheva A</span>, <span
                  data-id="annotation_537" class="annotation" style="position: unset;"> Frolova E</span>, <span
                  data-id="annotation_538" class="annotation" style="position: unset;"> Rolland Y</span>, <span
                  data-id="annotation_539" class="annotation" style="position: unset;"> Lauwers V</span>, <span
                  data-id="annotation_540" class="annotation" style="position: unset;"> Corsonello A</span>, <span
                  data-id="annotation_541" class="annotation" style="position: unset;"> Kirk GD</span>, <span
                  data-id="annotation_542" class="annotation" style="position: unset;"> Ferrari R</span>, <span
                  data-id="annotation_543" class="annotation" style="position: unset;"> Volpato S</span>, <span
                  data-id="annotation_544" class="annotation" style="position: unset;"> Campo G</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Short Physical Performance
                  Battery and all-cause mortality: systematic review and meta-analysis</span>. <span
                  data-id="emphasis_48" class="annotation emphasis" style="position: unset;">BMC Med.</span> 2016 Dec
                22;14(1):215.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Short%20Physical%20Performance%20Battery%20and%20all-cause%20mortality%3A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22R%20Pavasini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_545" class="annotation" style="position: unset;"> Brown JP</span>, <span
                  data-id="annotation_546" class="annotation" style="position: unset;"> Adachi JD</span>, <span
                  data-id="annotation_547" class="annotation" style="position: unset;"> Schemitsch E</span>, <span
                  data-id="annotation_548" class="annotation" style="position: unset;"> Tarride JE</span>, <span
                  data-id="annotation_549" class="annotation" style="position: unset;"> Brown V</span>, <span
                  data-id="annotation_550" class="annotation" style="position: unset;"> Bell A</span>, <span
                  data-id="annotation_551" class="annotation" style="position: unset;"> Reiner M</span>, <span
                  data-id="annotation_552" class="annotation" style="position: unset;"> Oliveira T</span>, <span
                  data-id="annotation_553" class="annotation" style="position: unset;"> Motsepe-Ditshego P</span>, <span
                  data-id="annotation_554" class="annotation" style="position: unset;"> Burke N</span>, <span
                  data-id="annotation_555" class="annotation" style="position: unset;"> Slatkovska L</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Mortality in older adults
                  following a fragility fracture: real-world retrospective matched-cohort study in Ontario</span>. <span
                  data-id="emphasis_49" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2021 Jan 23;22(1):105.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mortality%20in%20older%20adults%20following%20a%20fragility%20fracture%3A%20real-world%20retrospective%20matched-cohort%20study%20in%20Ontario&as_occt=title&as_sauthors=%20%22JP%20Brown%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_556" class="annotation" style="position: unset;"> Agarwal AR</span>, <span
                  data-id="annotation_557" class="annotation" style="position: unset;"> Tarawneh O</span>, <span
                  data-id="annotation_558" class="annotation" style="position: unset;"> Cohen JS</span>, <span
                  data-id="annotation_559" class="annotation" style="position: unset;"> Gu A</span>, <span
                  data-id="annotation_560" class="annotation" style="position: unset;"> Moseley KF</span>, <span
                  data-id="annotation_561" class="annotation" style="position: unset;"> DeBritz JN</span>, <span
                  data-id="annotation_562" class="annotation" style="position: unset;"> Golladay GJ</span>, <span
                  data-id="annotation_563" class="annotation" style="position: unset;"> Thakkar SC</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">The incremental risk of
                  fragility fractures in aging men</span>. <span data-id="emphasis_50" class="annotation emphasis"
                  style="position: unset;">Osteoporos Int.</span> 2024 Mar;35(3):495-503.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20incremental%20risk%20of%20fragility%20fractures%20in%20aging%20men&as_occt=title&as_sauthors=%20%22AR%20Agarwal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_564" class="annotation" style="position: unset;"> Bello MO</span>, <span
                  data-id="annotation_565" class="annotation" style="position: unset;"> Rodrigues Silva Sombra L</span>,
                <span data-id="annotation_566" class="annotation" style="position: unset;"> Anastasopoulou C</span>,
                <span data-id="annotation_567" class="annotation" style="position: unset;"> Garla VV</span>. <span
                  data-id="emphasis_51" class="annotation emphasis" style="position: unset;">Osteoporosis in
                  Males.</span> Treasure Island, FL: StatPearls; 2024.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_568" class="annotation" style="position: unset;"> Bandeira L</span>, <span
                  data-id="annotation_569" class="annotation" style="position: unset;"> Silva BC</span>, <span
                  data-id="annotation_570" class="annotation" style="position: unset;"> Bilezikian JP</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Male osteoporosis</span>. <span
                  data-id="emphasis_52" class="annotation emphasis" style="position: unset;">Arch Endocrinol
                  Metab.</span> 2022 Nov 11;66(5):739-47.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Male%20osteoporosis&as_occt=title&as_sauthors=%20%22L%20Bandeira%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_571" class="annotation" style="position: unset;"> Peris P</span>, <span
                  data-id="annotation_572" class="annotation" style="position: unset;"> Blasco J</span>, <span
                  data-id="annotation_573" class="annotation" style="position: unset;"> Monegal A</span>, <span
                  data-id="annotation_574" class="annotation" style="position: unset;"> Florez H</span>, <span
                  data-id="annotation_575" class="annotation" style="position: unset;"> Guañabens N</span>, <span
                  data-id="annotation_576" class="annotation" style="position: unset;"> Carrasco JL</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Effect of gender on the
                  evolution of pain and quality of life after treatment of symptomatic vertebral fragility
                  fractures</span>. <span data-id="emphasis_53" class="annotation emphasis"
                  style="position: unset;">Osteoporos Int.</span> 2024 Mar;35(3):515-21.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20gender%20on%20the%20evolution%20of%20pain%20and%20quality%20of%20life%20after%20treatment%20of%20symptomatic%20vertebral%20fragility%20fractures&as_occt=title&as_sauthors=%20%22P%20Peris%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_577" class="annotation" style="position: unset;"> Gutiérrez-González R</span>,
                <span data-id="annotation_578" class="annotation" style="position: unset;"> Royuela A</span>, <span
                  data-id="annotation_579" class="annotation" style="position: unset;"> Zamarron A</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">Survival following vertebral
                  compression fractures in population over 65 years old</span>. <span data-id="emphasis_54"
                  class="annotation emphasis" style="position: unset;">Aging Clin Exp Res.</span> 2023
                Aug;35(8):1609-17.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Survival%20following%20vertebral%20compression%20fractures%20in%20population%20over%2065%20years%20old&as_occt=title&as_sauthors=%20%22R%20Guti%C3%A9rrez-Gonz%C3%A1lez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_580" class="annotation" style="position: unset;"> Johansson J</span>, <span
                  data-id="annotation_581" class="annotation" style="position: unset;"> Emaus N</span>, <span
                  data-id="annotation_582" class="annotation" style="position: unset;"> Geelhoed B</span>, <span
                  data-id="annotation_583" class="annotation" style="position: unset;"> Sagelv E</span>, <span
                  data-id="annotation_584" class="annotation" style="position: unset;"> Morseth B</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Vertebral fractures assessed by
                  dual-energy X-ray absorptiometry and all-cause mortality: the Tromsø study, 2007-2020</span>. <span
                  data-id="emphasis_55" class="annotation emphasis" style="position: unset;">Am J Epidemiol.</span> 2023
                Jan 6;192(1):62-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebral%20fractures%20assessed%20by%20dual-energy%20X-ray%20absorptiometry%20and%20all-cause%20mortality%3A%20the%20Troms%C3%B8%20study%2C%202007-2020&as_occt=title&as_sauthors=%20%22J%20Johansson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_585" class="annotation" style="position: unset;"> Cosman F</span>, <span
                  data-id="annotation_586" class="annotation" style="position: unset;"> Libanati C</span>, <span
                  data-id="annotation_587" class="annotation" style="position: unset;"> Deignan C</span>, <span
                  data-id="annotation_588" class="annotation" style="position: unset;"> Yu Z</span>, <span
                  data-id="annotation_589" class="annotation" style="position: unset;"> Wang Z</span>, <span
                  data-id="annotation_590" class="annotation" style="position: unset;"> Ferrari S</span>, <span
                  data-id="annotation_591" class="annotation" style="position: unset;"> Beck Jensen JE</span>, <span
                  data-id="annotation_592" class="annotation" style="position: unset;"> Peris P</span>, <span
                  data-id="annotation_593" class="annotation" style="position: unset;"> Bertoldo F</span>, <span
                  data-id="annotation_594" class="annotation" style="position: unset;"> Lespessailles E</span>, <span
                  data-id="annotation_595" class="annotation" style="position: unset;"> Hesse E</span>, <span
                  data-id="annotation_596" class="annotation" style="position: unset;"> Cummings SR</span>. <span
                  data-id="strong_56" class="annotation strong" style="position: unset;">Romosozumab followed by
                  antiresorptive treatment increases the probability of achieving bone mineral density treatment
                  goals</span>. <span data-id="emphasis_56" class="annotation emphasis" style="position: unset;">JBMR
                  Plus.</span> 2021 Oct 6;5(11):e10546.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Romosozumab%20followed%20by%20antiresorptive%20treatment%20increases%20the%20probability%20of%20achieving%20bone%20mineral%20density%20treatment%20goals&as_occt=title&as_sauthors=%20%22F%20Cosman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_597" class="annotation" style="position: unset;"> Cosman F</span>, <span
                  data-id="annotation_598" class="annotation" style="position: unset;"> Kendler DL</span>, <span
                  data-id="annotation_599" class="annotation" style="position: unset;"> Langdahl BL</span>, <span
                  data-id="annotation_600" class="annotation" style="position: unset;"> Leder BZ</span>, <span
                  data-id="annotation_601" class="annotation" style="position: unset;"> Lewiecki EM</span>, <span
                  data-id="annotation_602" class="annotation" style="position: unset;"> Miyauchi A</span>, <span
                  data-id="annotation_603" class="annotation" style="position: unset;"> Rojeski M</span>, <span
                  data-id="annotation_604" class="annotation" style="position: unset;"> McDermott M</span>, <span
                  data-id="annotation_605" class="annotation" style="position: unset;"> Oates MK</span>, <span
                  data-id="annotation_606" class="annotation" style="position: unset;"> Milmont CE</span>, <span
                  data-id="annotation_607" class="annotation" style="position: unset;"> Libanati C</span>, <span
                  data-id="annotation_608" class="annotation" style="position: unset;"> Ferrari S</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Romosozumab and antiresorptive
                  treatment: the importance of treatment sequence</span>. <span data-id="emphasis_57"
                  class="annotation emphasis" style="position: unset;">Osteoporos Int.</span> 2022
                Jun;33(6):1243-56.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Romosozumab%20and%20antiresorptive%20treatment%3A%20the%20importance%20of%20treatment%20sequence&as_occt=title&as_sauthors=%20%22F%20Cosman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_59" style="position: unset;">
            <div class="content" style="position: unset;">59&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_609" class="annotation" style="position: unset;"> Cruchelow KR</span>, <span
                  data-id="annotation_610" class="annotation" style="position: unset;"> Peter ME</span>, <span
                  data-id="annotation_611" class="annotation" style="position: unset;"> Chakrabarti A</span>, <span
                  data-id="annotation_612" class="annotation" style="position: unset;"> Gipson HM</span>, <span
                  data-id="annotation_613" class="annotation" style="position: unset;"> Gregory WT</span>, <span
                  data-id="annotation_614" class="annotation" style="position: unset;"> DeClercq J</span>, <span
                  data-id="annotation_615" class="annotation" style="position: unset;"> Choi L</span>, <span
                  data-id="annotation_616" class="annotation" style="position: unset;"> Tanner SB</span>. <span
                  data-id="strong_58" class="annotation strong" style="position: unset;">Denosumab treatment lapses,
                  discontinuation, and off-treatment fracture risk: a retrospective study of patients with osteoporosis
                  in a real-world clinical setting</span>. <span data-id="emphasis_58" class="annotation emphasis"
                  style="position: unset;">Bone.</span> 2023 Dec;177:116925.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Denosumab%20treatment%20lapses%2C%20discontinuation%2C%20and%20off-treatment%20fracture%20risk%3A%20a%20retrospective%20study%20of%20patients%20with%20osteoporosis%20in%20a%20real-world%20clinical%20setting&as_occt=title&as_sauthors=%20%22KR%20Cruchelow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_60" style="position: unset;">
            <div class="content" style="position: unset;">60&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_617" class="annotation" style="position: unset;"> Ha J</span>, <span
                  data-id="annotation_618" class="annotation" style="position: unset;"> Kim J</span>, <span
                  data-id="annotation_619" class="annotation" style="position: unset;"> Jeong C</span>, <span
                  data-id="annotation_620" class="annotation" style="position: unset;"> Lim Y</span>, <span
                  data-id="annotation_621" class="annotation" style="position: unset;"> Kim MK</span>, <span
                  data-id="annotation_622" class="annotation" style="position: unset;"> Kwon HS</span>, <span
                  data-id="annotation_623" class="annotation" style="position: unset;"> Song KH</span>, <span
                  data-id="annotation_624" class="annotation" style="position: unset;"> Kang MI</span>, <span
                  data-id="annotation_625" class="annotation" style="position: unset;"> Baek KH</span>. <span
                  data-id="strong_59" class="annotation strong" style="position: unset;">Effect of follow-up raloxifene
                  therapy after denosumab discontinuation in postmenopausal women</span>. <span data-id="emphasis_59"
                  class="annotation emphasis" style="position: unset;">Osteoporos Int.</span> 2022
                Jul;33(7):1591-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20follow-up%20raloxifene%20therapy%20after%20denosumab%20discontinuation%20in%20postmenopausal%20women&as_occt=title&as_sauthors=%20%22J%20Ha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_61" style="position: unset;">
            <div class="content" style="position: unset;">61&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_626" class="annotation" style="position: unset;"> Tutaworn T</span>, <span
                  data-id="annotation_627" class="annotation" style="position: unset;"> Nieves JW</span>, <span
                  data-id="annotation_628" class="annotation" style="position: unset;"> Wang Z</span>, <span
                  data-id="annotation_629" class="annotation" style="position: unset;"> Levin JE</span>, <span
                  data-id="annotation_630" class="annotation" style="position: unset;"> Yoo JE</span>, <span
                  data-id="annotation_631" class="annotation" style="position: unset;"> Lane JM</span>. <span
                  data-id="strong_60" class="annotation strong" style="position: unset;">Bone loss after denosumab
                  discontinuation is prevented by alendronate and zoledronic acid but not risedronate: a retrospective
                  study</span>. <span data-id="emphasis_60" class="annotation emphasis"
                  style="position: unset;">Osteoporos Int.</span> 2023 Mar;34(3):573-84.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20loss%20after%20denosumab%20discontinuation%20is%20prevented%20by%20alendronate%20and%20zoledronic%20acid%20but%20not%20risedronate%3A%20a%20retrospective%20study&as_occt=title&as_sauthors=%20%22T%20Tutaworn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_62" style="position: unset;">
            <div class="content" style="position: unset;">62&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_632" class="annotation" style="position: unset;"> Khan AA</span>, <span
                  data-id="annotation_633" class="annotation" style="position: unset;"> AbuAlrob H</span>, <span
                  data-id="annotation_634" class="annotation" style="position: unset;"> M’Hiri I</span>, <span
                  data-id="annotation_635" class="annotation" style="position: unset;"> Ali DS</span>, <span
                  data-id="annotation_636" class="annotation" style="position: unset;"> Dandurand K</span>, <span
                  data-id="annotation_637" class="annotation" style="position: unset;"> Said H</span>, <span
                  data-id="annotation_638" class="annotation" style="position: unset;"> Alkassem H</span>, <span
                  data-id="annotation_639" class="annotation" style="position: unset;"> Hakami Y</span>, <span
                  data-id="annotation_640" class="annotation" style="position: unset;"> Hweija I</span>, <span
                  data-id="annotation_641" class="annotation" style="position: unset;"> Iqbal S</span>, <span
                  data-id="annotation_642" class="annotation" style="position: unset;"> Romanovschi M</span>, <span
                  data-id="annotation_643" class="annotation" style="position: unset;"> Mehmood S</span>, <span
                  data-id="annotation_644" class="annotation" style="position: unset;"> Zariffeh H</span>, <span
                  data-id="annotation_645" class="annotation" style="position: unset;"> Guyatt G</span>, <span
                  data-id="annotation_646" class="annotation" style="position: unset;"> Ibrahim Q</span>, <span
                  data-id="annotation_647" class="annotation" style="position: unset;"> Brignardello-Petersen R</span>,
                <span data-id="annotation_648" class="annotation" style="position: unset;"> Syed HI</span>. <span
                  data-id="strong_61" class="annotation strong" style="position: unset;">Effect of low dose denosumab on
                  bone mineral density in postmenopausal women with osteoporosis after a transition from 60 mg dose: a
                  prospective observational study</span>. <span data-id="emphasis_61" class="annotation emphasis"
                  style="position: unset;">Endocrine.</span> 2023 Jun;80(3):647-57.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20low%20dose%20denosumab%20on%20bone%20mineral%20density%20in%20postmenopausal%20women%20with%20osteoporosis%20after%20a%20transition%20from%2060%20mg%20dose%3A%20a%20prospective%20observational%20study&as_occt=title&as_sauthors=%20%22AA%20Khan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_63" style="position: unset;">
            <div class="content" style="position: unset;">63&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_649" class="annotation" style="position: unset;"> Dito G</span>, <span
                  data-id="annotation_650" class="annotation" style="position: unset;"> Lugaresi M</span>, <span
                  data-id="annotation_651" class="annotation" style="position: unset;"> Degradi C</span>, <span
                  data-id="annotation_652" class="annotation" style="position: unset;"> Guabello G</span>, <span
                  data-id="annotation_653" class="annotation" style="position: unset;"> Longhi M</span>, <span
                  data-id="annotation_654" class="annotation" style="position: unset;"> Corbetta S</span>. <span
                  data-id="strong_62" class="annotation strong" style="position: unset;">Efficacy of switching from
                  teriparatide to zoledronic acid or denosumab on bone mineral density and biochemical markers of bone
                  turnover in older patients with severe osteoporosis: a real-life study</span>. <span
                  data-id="emphasis_62" class="annotation emphasis" style="position: unset;">Endocrine.</span> 2023
                Oct;82(1):181-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20switching%20from%20teriparatide%20to%20zoledronic%20acid%20or%20denosumab%20on%20bone%20mineral%20density%20and%20biochemical%20markers%20of%20bone%20turnover%20in%20older%20patients%20with%20severe%20osteoporosis%3A%20a%20real-life%20study&as_occt=title&as_sauthors=%20%22G%20Dito%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_64" style="position: unset;">
            <div class="content" style="position: unset;">64&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_655" class="annotation" style="position: unset;"> Tsai JN</span>, <span
                  data-id="annotation_656" class="annotation" style="position: unset;"> Lee H</span>, <span
                  data-id="annotation_657" class="annotation" style="position: unset;"> David NL</span>, <span
                  data-id="annotation_658" class="annotation" style="position: unset;"> Eastell R</span>, <span
                  data-id="annotation_659" class="annotation" style="position: unset;"> Leder BZ</span>. <span
                  data-id="strong_63" class="annotation strong" style="position: unset;">Combination denosumab and high
                  dose teriparatide for postmenopausal osteoporosis (DATA-HD): a randomised, controlled phase 4
                  trial</span>. <span data-id="emphasis_63" class="annotation emphasis" style="position: unset;">Lancet
                  Diabetes Endocrinol.</span> 2019 Oct;7(10):767-75.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Combination%20denosumab%20and%20high%20dose%20teriparatide%20for%20postmenopausal%20osteoporosis%20(DATA-HD)%3A%20a%20randomised%2C%20controlled%20phase%204%20trial&as_occt=title&as_sauthors=%20%22JN%20Tsai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_65" style="position: unset;">
            <div class="content" style="position: unset;">65&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_660" class="annotation" style="position: unset;"> Ramchand SK</span>, <span
                  data-id="annotation_661" class="annotation" style="position: unset;"> David NL</span>, <span
                  data-id="annotation_662" class="annotation" style="position: unset;"> Leder BZ</span>, <span
                  data-id="annotation_663" class="annotation" style="position: unset;"> Tsai JN</span>. <span
                  data-id="strong_64" class="annotation strong" style="position: unset;">Bone mineral density response
                  with denosumab in combination with standard or high-dose teriparatide: the DATA-HD RCT</span>. <span
                  data-id="emphasis_64" class="annotation emphasis" style="position: unset;">J Clin Endocrinol
                  Metab.</span> 2020 Mar 1;105(3):890-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20mineral%20density%20response%20with%20denosumab%20in%20combination%20with%20standard%20or%20high-dose%20teriparatide%3A%20the%20DATA-HD%20RCT&as_occt=title&as_sauthors=%20%22SK%20Ramchand%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_66" style="position: unset;">
            <div class="content" style="position: unset;">66&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_664" class="annotation" style="position: unset;"> Ramchand SK</span>, <span
                  data-id="annotation_665" class="annotation" style="position: unset;"> David NL</span>, <span
                  data-id="annotation_666" class="annotation" style="position: unset;"> Lee H</span>, <span
                  data-id="annotation_667" class="annotation" style="position: unset;"> Eastell R</span>, <span
                  data-id="annotation_668" class="annotation" style="position: unset;"> Tsai JN</span>, <span
                  data-id="annotation_669" class="annotation" style="position: unset;"> Leder BZ</span>. <span
                  data-id="strong_65" class="annotation strong" style="position: unset;">Efficacy of zoledronic acid in
                  maintaining areal and volumetric bone density after combined denosumab and teriparatide
                  administration: DATA-HD study extension</span>. <span data-id="emphasis_65"
                  class="annotation emphasis" style="position: unset;">J Bone Miner Res.</span> 2021
                May;36(5):921-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20zoledronic%20acid%20in%20maintaining%20areal%20and%20volumetric%20bone%20density%20after%20combined%20denosumab%20and%20teriparatide%20administration%3A%20DATA-HD%20study%20extension&as_occt=title&as_sauthors=%20%22SK%20Ramchand%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_67" style="position: unset;">
            <div class="content" style="position: unset;">67&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_670" class="annotation" style="position: unset;"> Ramchand SK</span>, <span
                  data-id="annotation_671" class="annotation" style="position: unset;"> David NL</span>, <span
                  data-id="annotation_672" class="annotation" style="position: unset;"> Lee H</span>, <span
                  data-id="annotation_673" class="annotation" style="position: unset;"> Bruce M</span>, <span
                  data-id="annotation_674" class="annotation" style="position: unset;"> Bouxsein ML</span>, <span
                  data-id="annotation_675" class="annotation" style="position: unset;"> Tsai JN</span>, <span
                  data-id="annotation_676" class="annotation" style="position: unset;"> Leder BZ</span>. <span
                  data-id="strong_66" class="annotation strong" style="position: unset;">The effect of zoledronic acid
                  on bone microarchitecture and strength after denosumab and teriparatide administration: DATA-HD study
                  extension</span>. <span data-id="emphasis_66" class="annotation emphasis" style="position: unset;">J
                  Bone Miner Res.</span> 2023 Jan;38(1):26-34.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20zoledronic%20acid%20on%20bone%20microarchitecture%20and%20strength%20after%20denosumab%20and%20teriparatide%20administration%3A%20DATA-HD%20study%20extension&as_occt=title&as_sauthors=%20%22SK%20Ramchand%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_68" style="position: unset;">
            <div class="content" style="position: unset;">68&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_677" class="annotation" style="position: unset;"> Rubin MR</span>, <span
                  data-id="annotation_678" class="annotation" style="position: unset;"> Bilezikian JP</span>. <span
                  data-id="strong_67" class="annotation strong" style="position: unset;">The anabolic effects of
                  parathyroid hormone therapy</span>. <span data-id="emphasis_67" class="annotation emphasis"
                  style="position: unset;">Clin Geriatr Med.</span> 2003 May;19(2):415-32.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20anabolic%20effects%20of%20parathyroid%20hormone%20therapy&as_occt=title&as_sauthors=%20%22MR%20Rubin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_69" style="position: unset;">
            <div class="content" style="position: unset;">69&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_679" class="annotation" style="position: unset;"> Ganapathy A</span>, <span
                  data-id="annotation_680" class="annotation" style="position: unset;"> Nieves JW</span>, <span
                  data-id="annotation_681" class="annotation" style="position: unset;"> Keaveny TM</span>, <span
                  data-id="annotation_682" class="annotation" style="position: unset;"> Cosman F</span>. <span
                  data-id="strong_68" class="annotation strong" style="position: unset;">Effects of four-year cyclic
                  versus two-year daily teriparatide treatment on volumetric bone density and bone strength in
                  postmenopausal women with osteoporosis</span>. <span data-id="emphasis_68" class="annotation emphasis"
                  style="position: unset;">Bone.</span> 2023 Feb;167:116618.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20four-year%20cyclic%20versus%20two-year%20daily%20teriparatide%20treatment%20on%20volumetric%20bone%20density%20and%20bone%20strength%20in%20postmenopausal%20women%20with%20osteoporosis&as_occt=title&as_sauthors=%20%22A%20Ganapathy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_70" style="position: unset;">
            <div class="content" style="position: unset;">70&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_683" class="annotation" style="position: unset;"> Kostenuik PJ</span>, <span
                  data-id="annotation_684" class="annotation" style="position: unset;"> Binkley N</span>, <span
                  data-id="annotation_685" class="annotation" style="position: unset;"> Anderson PA</span>. <span
                  data-id="strong_69" class="annotation strong" style="position: unset;">Advances in osteoporosis
                  therapy: focus on osteoanabolic agents, secondary fracture prevention, and perioperative bone
                  health</span>. <span data-id="emphasis_69" class="annotation emphasis" style="position: unset;">Curr
                  Osteoporos Rep.</span> 2023 Aug;21(4):386-400.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advances%20in%20osteoporosis%20therapy%3A%20focus%20on%20osteoanabolic%20agents%2C%20secondary%20fracture%20prevention%2C%20and%20perioperative%20bone%20health&as_occt=title&as_sauthors=%20%22PJ%20Kostenuik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_71" style="position: unset;">
            <div class="content" style="position: unset;">71&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_686" class="annotation" style="position: unset;"> Dash AS</span>, <span
                  data-id="annotation_687" class="annotation" style="position: unset;"> Billings E</span>, <span
                  data-id="annotation_688" class="annotation" style="position: unset;"> Vlastaris K</span>, <span
                  data-id="annotation_689" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_690" class="annotation" style="position: unset;"> Cunningham ME</span>, <span
                  data-id="annotation_691" class="annotation" style="position: unset;"> Raphael J</span>, <span
                  data-id="annotation_692" class="annotation" style="position: unset;"> Lovecchio F</span>, <span
                  data-id="annotation_693" class="annotation" style="position: unset;"> Carrino JA</span>, <span
                  data-id="annotation_694" class="annotation" style="position: unset;"> Lebl D</span>, <span
                  data-id="annotation_695" class="annotation" style="position: unset;"> McMahon D</span>, <span
                  data-id="annotation_696" class="annotation" style="position: unset;"> Stein EM</span>. <span
                  data-id="strong_70" class="annotation strong" style="position: unset;">Pre-operative bone quality
                  deficits and risk of complications following spine fusion surgery among postmenopausal women</span>.
                <span data-id="emphasis_70" class="annotation emphasis" style="position: unset;">Osteoporos Int.</span>
                2024 Mar;35(3):551-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pre-operative%20bone%20quality%20deficits%20and%20risk%20of%20complications%20following%20spine%20fusion%20surgery%20among%20postmenopausal%20women&as_occt=title&as_sauthors=%20%22AS%20Dash%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_72" style="position: unset;">
            <div class="content" style="position: unset;">72&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_697" class="annotation" style="position: unset;"> Yang YC</span>, <span
                  data-id="annotation_698" class="annotation" style="position: unset;"> Hsieh MH</span>, <span
                  data-id="annotation_699" class="annotation" style="position: unset;"> Chien JT</span>, <span
                  data-id="annotation_700" class="annotation" style="position: unset;"> Liu KC</span>, <span
                  data-id="annotation_701" class="annotation" style="position: unset;"> Yang CC</span>. <span
                  data-id="strong_71" class="annotation strong" style="position: unset;">Teriparatide treatment shows
                  faster healing than sacroplasty for postmenopausal women with sacral insufficiency fracture</span>.
                <span data-id="emphasis_71" class="annotation emphasis" style="position: unset;">Osteoporos
                  Sarcopenia.</span> 2023 Mar;9(1):27-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Teriparatide%20treatment%20shows%20faster%20healing%20than%20sacroplasty%20for%20postmenopausal%20women%20with%20sacral%20insufficiency%20fracture&as_occt=title&as_sauthors=%20%22YC%20Yang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_73" style="position: unset;">
            <div class="content" style="position: unset;">73&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_702" class="annotation" style="position: unset;"> Gou P</span>, <span
                  data-id="annotation_703" class="annotation" style="position: unset;"> Zhao Z</span>, <span
                  data-id="annotation_704" class="annotation" style="position: unset;"> Yu C</span>, <span
                  data-id="annotation_705" class="annotation" style="position: unset;"> Hou X</span>, <span
                  data-id="annotation_706" class="annotation" style="position: unset;"> Gao G</span>, <span
                  data-id="annotation_707" class="annotation" style="position: unset;"> Zhang T</span>, <span
                  data-id="annotation_708" class="annotation" style="position: unset;"> Chang F</span>. <span
                  data-id="strong_72" class="annotation strong" style="position: unset;">Efficacy of recombinant human
                  parathyroid hormone versus vertebral augmentation procedure on patients with acute osteoporotic
                  vertebral compression fracture</span>. <span data-id="emphasis_72" class="annotation emphasis"
                  style="position: unset;">Orthop Surg.</span> 2022 Oct;14(10):2510-18.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20recombinant%20human%20parathyroid%20hormone%20versus%20vertebral%20augmentation%20procedure%20on%20patients%20with%20acute%20osteoporotic%20vertebral%20compression%20fracture&as_occt=title&as_sauthors=%20%22P%20Gou%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_74" style="position: unset;">
            <div class="content" style="position: unset;">74&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_709" class="annotation" style="position: unset;"> Schemitsch EH</span>, <span
                  data-id="annotation_710" class="annotation" style="position: unset;"> Miclau T</span>, <span
                  data-id="annotation_711" class="annotation" style="position: unset;"> Karachalios T</span>, <span
                  data-id="annotation_712" class="annotation" style="position: unset;"> Nowak LL</span>, <span
                  data-id="annotation_713" class="annotation" style="position: unset;"> Sancheti P</span>, <span
                  data-id="annotation_714" class="annotation" style="position: unset;"> Poolman RW</span>, <span
                  data-id="annotation_715" class="annotation" style="position: unset;"> Caminis J</span>, <span
                  data-id="annotation_716" class="annotation" style="position: unset;"> Daizadeh N</span>, <span
                  data-id="annotation_717" class="annotation" style="position: unset;"> Dent-Acosta RE</span>, <span
                  data-id="annotation_718" class="annotation" style="position: unset;"> Egbuna O</span>, <span
                  data-id="annotation_719" class="annotation" style="position: unset;"> Chines A</span>, <span
                  data-id="annotation_720" class="annotation" style="position: unset;"> Maddox J</span>, <span
                  data-id="annotation_721" class="annotation" style="position: unset;"> Grauer A</span>, <span
                  data-id="annotation_722" class="annotation" style="position: unset;"> Bhandari M</span>. <span
                  data-id="strong_73" class="annotation strong" style="position: unset;">A randomized,
                  placebo-controlled study of romosozumab for the treatment of hip fractures</span>. <span
                  data-id="emphasis_73" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2020 Apr 15;102(8):693-702.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=2313017" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_75" style="position: unset;">
            <div class="content" style="position: unset;">75&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_723" class="annotation" style="position: unset;"> Hayashi K</span>. <span
                  data-id="strong_74" class="annotation strong" style="position: unset;">Efficacy of three teriparatide
                  preparations and romosozumab, osteogenesis promoters, in the treatment of fresh vertebral fractures: a
                  retrospective observational study</span>. <span data-id="emphasis_74" class="annotation emphasis"
                  style="position: unset;">Drugs Real World Outcomes.</span> 2023 Dec;10(4):631-37.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20three%20teriparatide%20preparations%20and%20romosozumab%2C%20osteogenesis%20promoters%2C%20in%20the%20treatment%20of%20fresh%20vertebral%20fractures%3A%20a%20retrospective%20observational%20study&as_occt=title&as_sauthors=%20%22K%20Hayashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_76" style="position: unset;">
            <div class="content" style="position: unset;">76&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_724" class="annotation" style="position: unset;"> Gariffo G</span>, <span
                  data-id="annotation_725" class="annotation" style="position: unset;"> Bottai V</span>, <span
                  data-id="annotation_726" class="annotation" style="position: unset;"> Falcinelli F</span>, <span
                  data-id="annotation_727" class="annotation" style="position: unset;"> Di Sacco F</span>, <span
                  data-id="annotation_728" class="annotation" style="position: unset;"> Cifali R</span>, <span
                  data-id="annotation_729" class="annotation" style="position: unset;"> Troiano E</span>, <span
                  data-id="annotation_730" class="annotation" style="position: unset;"> Capanna R</span>, <span
                  data-id="annotation_731" class="annotation" style="position: unset;"> Mondanelli N</span>, <span
                  data-id="annotation_732" class="annotation" style="position: unset;"> Giannotti S</span>. <span
                  data-id="strong_75" class="annotation strong" style="position: unset;">Use of teriparatide in
                  preventing delayed bone healing and nonunion: a multicentric study on a series of 20 patients</span>.
                <span data-id="emphasis_75" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2023 Mar 11;24(1):184.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Use%20of%20teriparatide%20in%20preventing%20delayed%20bone%20healing%20and%20nonunion%3A%20a%20multicentric%20study%20on%20a%20series%20of%2020%20patients&as_occt=title&as_sauthors=%20%22G%20Gariffo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_77" style="position: unset;">
            <div class="content" style="position: unset;">77&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_733" class="annotation" style="position: unset;"> Byun SE</span>, <span
                  data-id="annotation_734" class="annotation" style="position: unset;"> Lee KJ</span>, <span
                  data-id="annotation_735" class="annotation" style="position: unset;"> Shin WC</span>, <span
                  data-id="annotation_736" class="annotation" style="position: unset;"> Moon NH</span>, <span
                  data-id="annotation_737" class="annotation" style="position: unset;"> Kim CH</span>. <span
                  data-id="strong_76" class="annotation strong" style="position: unset;">The effect of teriparatide on
                  fracture healing after atypical femoral fracture: a systematic review and meta-analysis</span>. <span
                  data-id="emphasis_76" class="annotation emphasis" style="position: unset;">Osteoporos Int.</span> 2023
                Aug;34(8):1323-34.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20teriparatide%20on%20fracture%20healing%20after%20atypical%20femoral%20fracture%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22SE%20Byun%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_78" style="position: unset;">
            <div class="content" style="position: unset;">78&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_738" class="annotation" style="position: unset;"> Thorne TJ</span>, <span
                  data-id="annotation_739" class="annotation" style="position: unset;"> Steffenson L</span>, <span
                  data-id="annotation_740" class="annotation" style="position: unset;"> O’Neill DC</span>, <span
                  data-id="annotation_741" class="annotation" style="position: unset;"> Marchand LS</span>, <span
                  data-id="annotation_742" class="annotation" style="position: unset;"> Martin BI</span>, <span
                  data-id="annotation_743" class="annotation" style="position: unset;"> Haller JM</span>. <span
                  data-id="strong_77" class="annotation strong" style="position: unset;">No increased risk of nonunion
                  with bisphosphonate use in a Medicare claims cohort following operatively treated long-bone
                  fractures</span>. <span data-id="emphasis_77" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2023 Apr 5;105(7):549-55.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=2eb0b314-a532-4142-b569-eb6dc0d18043" target="_new"
                  class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_79" style="position: unset;">
            <div class="content" style="position: unset;">79&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_744" class="annotation" style="position: unset;"> de Sousa Ferreira VC</span>,
                <span data-id="annotation_745" class="annotation" style="position: unset;"> Lopes AP</span>, <span
                  data-id="annotation_746" class="annotation" style="position: unset;"> Alves NM</span>, <span
                  data-id="annotation_747" class="annotation" style="position: unset;"> Sousa FRN</span>, <span
                  data-id="annotation_748" class="annotation" style="position: unset;"> Pereira KMA</span>, <span
                  data-id="annotation_749" class="annotation" style="position: unset;"> Gondim DV</span>, <span
                  data-id="annotation_750" class="annotation" style="position: unset;"> Girão VCC</span>, <span
                  data-id="annotation_751" class="annotation" style="position: unset;"> Leitão RFC</span>, <span
                  data-id="annotation_752" class="annotation" style="position: unset;"> Goes P</span>. <span
                  data-id="strong_78" class="annotation strong" style="position: unset;">Bisphosphonate-related
                  osteonecrosis induced change in alveolar bone architecture in rats with participation of Wnt
                  signaling</span>. <span data-id="emphasis_78" class="annotation emphasis"
                  style="position: unset;">Clin Oral Investig.</span> 2021 Feb;25(2):673-82.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bisphosphonate-related%20osteonecrosis%20induced%20change%20in%20alveolar%20bone%20architecture%20in%20rats%20with%20participation%20of%20Wnt%20signaling&as_occt=title&as_sauthors=%20%22VC%20de%20Sousa%20Ferreira%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_80" style="position: unset;">
            <div class="content" style="position: unset;">80&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_753" class="annotation" style="position: unset;"> Ashcherkin N</span>, <span
                  data-id="annotation_754" class="annotation" style="position: unset;"> Patel AA</span>, <span
                  data-id="annotation_755" class="annotation" style="position: unset;"> Algeciras-Schimnich A</span>,
                <span data-id="annotation_756" class="annotation" style="position: unset;"> Doshi KB</span>. <span
                  data-id="strong_79" class="annotation strong" style="position: unset;">Bone turnover markers to
                  monitor oral bisphosphonate therapy</span>. <span data-id="emphasis_79" class="annotation emphasis"
                  style="position: unset;">Cleve Clin J Med.</span> 2023 Jan 3;90(1):26-31.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20turnover%20markers%20to%20monitor%20oral%20bisphosphonate%20therapy&as_occt=title&as_sauthors=%20%22N%20Ashcherkin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_81" style="position: unset;">
            <div class="content" style="position: unset;">81&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_757" class="annotation" style="position: unset;"> Kates SL</span>, <span
                  data-id="annotation_758" class="annotation" style="position: unset;"> Ackert-Bicknell CL</span>. <span
                  data-id="strong_80" class="annotation strong" style="position: unset;">How do bisphosphonates affect
                  fracture healing?</span><span data-id="emphasis_80" class="annotation emphasis"
                  style="position: unset;">Injury.</span> 2016 Jan;47(1):S65-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20do%20bisphosphonates%20affect%20fracture%20healing%3F&as_occt=title&as_sauthors=%20%22SL%20Kates%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D144b08a6-73f2-4462-b46f-5a5de24e24c6%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Guest Editorial</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">September 4, 2024;
                      106 (17): 1538</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00644</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 25, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_81"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Hospital
                            for Special Surgery, New York, NY</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;144b08a6-73f2-4462-b46f-5a5de24e24c6&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=144b08a6-73f2-4462-b46f-5a5de24e24c6&type=pdf&name=JBJS.24.00644.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=144b08a6-73f2-4462-b46f-5a5de24e24c6&type=pdf&name=JBJS.24.00644.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_81"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_82" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I114" target="_blank" data-id="link_2"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I114</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;144b08a6-73f2-4462-b46f-5a5de24e24c6&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=144b08a6-73f2-4462-b46f-5a5de24e24c6&type=zip&name=JBJS.24.00644.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ting Cong, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4262-4674" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4262-4674</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dan C.M. Viola, MD, PhD, MBA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6173-8004" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6173-8004</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Witchaporn Witayakom, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7527-5170" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7527-5170</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jeri W. Nieves, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8593-343X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8593-343X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joseph M. Lane, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:lanej@hss.edu" class="" style="position: unset;">lanej@hss.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5086-6940" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5086-6940</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Metabolic Bone Disease Service, Department of Orthopedics, Hospital for Special
                Surgery, New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopedics, Weill Cornell Medicine, New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopedics, Faculty of Medicine, Khon Kaen University, Khon
                Kaen, Thailand</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Mailman School of Public Health, Columbia University, New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 38885.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
