/* ----------------- React --------------- */
import { useState } from "react";

/* ----------------- Constants --------------- */
import { ENDPOINT_HINT } from "@constants/urls";

/* ----------------- Features --------------- */
import { useExamContext } from "@features/exam";

/* ----------------- Applets --------------- */
import { useAuthContext } from "@memorang/applets";

/* ----------------- Helpers --------------- */
import { getPlatform } from "@memorang/helpers";

import type { SessionChildItem } from "@memorang/types";
/* ----------------- React Query --------------- */
import { useMutation } from "@tanstack/react-query";

export const useHint = ({
	item: { id: itemId, children, tags, stem },
	type = "item",
	selectedChoice,
	selectedChoiceId,
}: {
	item: SessionChildItem;
	type?: "choice" | "item";
	selectedChoice?: string;
	selectedChoiceId?: string;
}) => {
	const topics = tags?.map((tag) => tag.label!);

	const [hint, setHint] = useState<string>("");
	const correctChoice = children?.find((child) => child.isCorrect);
	const explanation = correctChoice ? correctChoice.explanation : "";
	const incorrectChoices = children
		?.filter((child) => !child.isCorrect)
		.map((child) => child.stem);

	const correctChoiceText = correctChoice?.stem;
	const { viewerId: userId } = useAuthContext();
	const {
		currentExamContext: { examId },
	} = useExamContext();

	const platform = getPlatform();
	const [loading, setLoading] = useState(false);
	const [streaming, setStreaming] = useState(false);

	const { mutate: getHint } = useMutation({
		mutationKey: selectedChoiceId
			? ["hint", itemId, selectedChoiceId]
			: ["hint", itemId],
		mutationFn: async () => {
			const params = {
				platform,
				itemId: String(itemId),
				examId,
				originalQuestion: {
					topics,
					explanation,
					stem,
					incorrectChoices,
					correctChoice: correctChoiceText,
					selectedChoice,
				},
				type,
				userId,
			};

			setLoading(true);
			const response = await fetch(ENDPOINT_HINT, {
				//@ts-ignore
				reactNative: { textStreaming: true },
				method: "POST",
				body: JSON.stringify(params),
			});
			const reader = response.body?.getReader();
			return reader;
		},
		onSuccess: (reader) => {
			setLoading(false);
			setStreaming(true);
			const decoder = new TextDecoder("utf-8");
			let result = "";

			async function read() {
				if (reader) {
					const { done, value } = await reader.read();
					if (done) {
						setStreaming(false);
						return;
					}
					result += decoder.decode(value);
					setHint(result);
					await read();
				}
			}
			read();
		},
	});

	return {
		hint,
		loading,
		streaming,
		getHint,
	};
};
