import { Text } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";

type Props = {
	guess?: string;
	count: number;
	maxValue: number;
	barContainerWidth: number;
	barColor?: string;
	barValue?: string;
};

const DistributionBar = ({
	guess,
	count,
	maxValue,
	barContainerWidth,
	barColor,
	barValue,
}: Props) => {
	const theme = useAppTheme();
	const percentage = maxValue > 0 ? (count / maxValue) * 100 : 0;

	const getBarColor = () => {
		if (count === 0) {
			return theme.colors.progress.none;
		}
		return barColor || theme.colors.primary;
	};

	return (
		<Box flexDirection="row" alignItems="center" gap={8}>
			{guess && <Text variant="labelMedium">{guess}</Text>}
			<Box
				width={barContainerWidth}
				height={24}
				backgroundColor={theme.colors.progress.none}
				borderRadius={4}
			>
				<Box height="100%" width="100%" borderRadius={4} flexDirection="row">
					<Box
						height="100%"
						width={`${percentage}%`}
						backgroundColor={getBarColor()}
						borderRadius={4}
					/>
					<Box
						height="100%"
						width={`${100 - percentage}%`}
						backgroundColor={theme.colors.progress.none}
						borderRadius={4}
					/>
					<Text
						variant="labelMedium"
						style={{
							position: "absolute",
							left: 8,
							alignSelf: "center",
							color:
								count === 0
									? theme.colors.onSurfaceVariant
									: theme.colors.onPrimary,
						}}
					>
						{barValue || count}
					</Text>
				</Box>
			</Box>
		</Box>
	);
};

export default DistributionBar;
