import { StoreContainer } from "@features/dashboard";
import DocumentTitle from "components/DocumentTitle";
import { Stack } from "expo-router";
const StoreRoute = () => {
	return (
		<>
			<Stack.Screen
				options={{
					headerShown: false,
				}}
			/>
			<DocumentTitle title="Store" />
			<StoreContainer />
		</>
	);
};

export default StoreRoute;
