/**
 * @generated SignedSource<<3da238e5065f6fd613d4e2c3ab33e360>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DashboardAction = "COMMON_PROGRESS_QUERY" | "GETEXAMS" | "GETEXAMSANDTAGS" | "GETEXAMSRETOOL" | "%future added value";
export type ExamType = "PRACTICE_TEST" | "QUIZ" | "%future added value";
export type FetchPracticeTestsContentsQuery$variables = {
  bundleId: string;
  dashboardAction?: DashboardAction | null;
};
export type FetchPracticeTestsContentsQuery$data = {
  readonly getMixedItemDistribution: {
    readonly description: ReadonlyArray<string | null> | null;
    readonly exams: ReadonlyArray<{
      readonly attempted: {
        readonly high: number | null;
        readonly low: number | null;
        readonly medium: number | null;
      } | null;
      readonly beginStartDate: any | null;
      readonly dateCompleted: any | null;
      readonly distribution: {
        readonly high: number | null;
        readonly low: number | null;
        readonly medium: number | null;
      } | null;
      readonly endStartDate: any | null;
      readonly hasAccess: boolean | null;
      readonly id: string | null;
      readonly legacyReport: {
        readonly id: string | null;
        readonly numItems: number | null;
        readonly numericId: number | null;
        readonly score: number | null;
        readonly sessionDate: any | null;
      } | null;
      readonly migratedSessionReport: {
        readonly id: string | null;
        readonly numItems: number | null;
        readonly numericId: number | null;
        readonly score: number | null;
        readonly sessionDate: any | null;
      } | null;
      readonly numCompleted: number | null;
      readonly numItems: number | null;
      readonly numericId: number | null;
      readonly status: string | null;
      readonly time: string | null;
      readonly timeElapsed: number | null;
      readonly title: string | null;
      readonly totalTime: number | null;
      readonly type: ExamType | null;
    } | null> | null;
    readonly hasAccess: boolean | null;
  } | null;
};
export type FetchPracticeTestsContentsQuery = {
  response: FetchPracticeTestsContentsQuery$data;
  variables: FetchPracticeTestsContentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bundleId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dashboardAction"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAccess",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numericId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numItems",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sessionDate",
    "storageKey": null
  },
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "score",
    "storageKey": null
  },
  (v2/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "low",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "medium",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "high",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bundleId",
        "variableName": "bundleId"
      },
      {
        "kind": "Variable",
        "name": "dashboardAction",
        "variableName": "dashboardAction"
      }
    ],
    "concreteType": "MixedItemDistribution",
    "kind": "LinkedField",
    "name": "getMixedItemDistribution",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Exam",
        "kind": "LinkedField",
        "name": "exams",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "beginStartDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endStartDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LegacyReport",
            "kind": "LinkedField",
            "name": "legacyReport",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LegacyReport",
            "kind": "LinkedField",
            "name": "migratedSessionReport",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          (v4/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numCompleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "time",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeElapsed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgressDistribution",
            "kind": "LinkedField",
            "name": "attempted",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateCompleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgressDistribution",
            "kind": "LinkedField",
            "name": "distribution",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchPracticeTestsContentsQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchPracticeTestsContentsQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "434f3923a0c4b3901d08390488b8146d",
    "id": null,
    "metadata": {},
    "name": "FetchPracticeTestsContentsQuery",
    "operationKind": "query",
    "text": "query FetchPracticeTestsContentsQuery(\n  $bundleId: ID!\n  $dashboardAction: DashboardAction\n) {\n  getMixedItemDistribution(bundleId: $bundleId, dashboardAction: $dashboardAction) {\n    hasAccess\n    description\n    exams {\n      id\n      title\n      hasAccess\n      type\n      beginStartDate\n      endStartDate\n      legacyReport {\n        numericId\n        sessionDate\n        numItems\n        score\n        id\n      }\n      migratedSessionReport {\n        numericId\n        sessionDate\n        numItems\n        score\n        id\n      }\n      numItems\n      numericId\n      numCompleted\n      status\n      time\n      timeElapsed\n      totalTime\n      attempted {\n        low\n        medium\n        high\n      }\n      dateCompleted\n      distribution {\n        low\n        medium\n        high\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "823445bd1757255117cdc13fc5f25eb4";

export default node;
