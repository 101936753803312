export const redeemContactSupportTemplate =
	"Entered the code perfectly and still having an issue? **Contact support** and make sure to include your access code and purchase details";

export const mockedAccessCodeResponse = {
	success: true,
	message: "Code applied successfully",
	data: {
		bundleId: "e854f7ea-b006-4d7a-add8-8309d2943f93",
		bundleNumericId: 101,
		examName: "Nail Technician Theory (Vietnamese)",
		bundleType: "STUDY_PACK",
		bundleName: "Nail Technician Study-Pack (Vietnamese)",
		planLength: "12 months",
		expiryDate: "2026-02-08T06:53:44.978Z",
		icon: "https://memorang-prod-icon.s3.amazonaws.com/icon/icon_bundle_101.png",
		examId: "01cdb75a-3d93-430c-8577-337a660a836d",
		features: [
			"Tạo phiên học tùy chỉnh trên bất kỳ sự kết hợp nào của các chủ đề được tập trung",
			"365 câu hỏi tương tự như bạn sẽ thấy vào ngày thi",
			"Học theo cách tiến trình với giải thích chi tiết và hiểu biết",
			"Báo cáo điểm chi tiết và phân tích về hiệu suất của bạn theo chủ đề",
		],
	},
	productData: [
		{
			bundleId: "e854f7ea-b006-4d7a-add8-8309d2943f93",
			bundleNumericId: 101,
			examName: "Nail Technician Theory (Vietnamese)",
			bundleType: "STUDY_PACK",
			bundleName: "Nail Technician Study-Pack (Vietnamese)",
			planLength: "12 months",
			expiryDate: "2026-02-08T06:53:44.978Z",
			icon: "https://memorang-prod-icon.s3.amazonaws.com/icon/icon_bundle_101.png",
			examId: "01cdb75a-3d93-430c-8577-337a660a836d",
			features: [
				"Tạo phiên học tùy chỉnh trên bất kỳ sự kết hợp nào của các chủ đề được tập trung",
				"365 câu hỏi tương tự như bạn sẽ thấy vào ngày thi",
				"Học theo cách tiến trình với giải thích chi tiết và hiểu biết",
				"Báo cáo điểm chi tiết và phân tích về hiệu suất của bạn theo chủ đề",
			],
		},
		{
			bundleId: "8b3c107e-dd3c-465f-a6bf-3dada67e6550",
			bundleNumericId: 102,
			examName: "Nail Technician Theory (Vietnamese)",
			bundleType: "PRACTICE_TEST",
			bundleName: "Nail Technician Practice Tests (Vietnamese)",
			planLength: "12 months",
			expiryDate: "2026-02-08T06:53:46.076Z",
			icon: "https://memorang-prod-icon.s3.amazonaws.com/icon/icon_bundle_102.png",
			examId: "01cdb75a-3d93-430c-8577-337a660a836d",
			features: [
				"3 bài thi thực hành đầy đủ, có giờ và chẩn đoán",
				"250 câu hỏi tương tự như bạn sẽ thấy vào ngày thi",
				"Trải nghiệm học không bị phân tâm và có giờ",
				"Báo cáo điểm chi tiết và phân tích về hiệu suất của bạn theo chủ đề",
			],
		},
	],
};
