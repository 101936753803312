import ChallengeListItem from "@features/challenges/components/ChallengeListItem";
import { Box, CustomListSubheader, EmptyState } from "@memorang/ui";
import { useCallback, useMemo } from "react";
import { SectionList, type StyleProp, type ViewStyle } from "react-native";
import { getEmptyStateConfig } from "../constants/empty-states";

type Challenge = {
	id: number;
	title: string;
	description: string;
	imageUri: string;
	accessType?: "PREMIUM" | "FREE";
	tags: { id: number; label: string }[];
	completed?: boolean;
};

type Section = {
	title: string;
	data: Challenge[];
};

type FlattenedItem = Challenge | { title: string; isHeader: true };

type Props = {
	sections: Section[];
	handleChallengePress?: (id: number) => void;
	handleClearFilters?: () => void;
	isCME?: boolean;
	activeFilters?: {
		searchQuery?: string;
		isPremiumSelected?: boolean;
		isCompletedSelected?: boolean;
	};
	totalChallenges: number;
	isPremiumUser?: boolean;
	contentContainerStyle?: StyleProp<ViewStyle>;
};

const ChallengesView = ({
	sections,
	handleChallengePress,
	handleClearFilters,
	isCME,
	activeFilters,
	totalChallenges,
	isPremiumUser,
	contentContainerStyle,
}: Props) => {
	const renderItem = useCallback(
		({ item }: { item: FlattenedItem }) => {
			if ("isHeader" in item) {
				return <CustomListSubheader>{item.title}</CustomListSubheader>;
			}

			return (
				<ChallengeListItem
					{...item}
					id={Number(item.id)}
					locked={item.accessType === "PREMIUM" && !isPremiumUser}
					onPress={() => handleChallengePress?.(item.id)}
					isCME={isCME}
				/>
			);
		},
		[handleChallengePress, isCME, isPremiumUser],
	);

	const getEmptyState = useCallback(() => {
		const { searchQuery, isPremiumSelected, isCompletedSelected } =
			activeFilters ?? {};
		const hiddenChallenges =
			totalChallenges -
			sections.reduce((acc, section) => acc + section.data.length, 0);
		const activeFilterCount = [isPremiumSelected, isCompletedSelected].filter(
			Boolean,
		).length;

		return getEmptyStateConfig({
			hiddenChallenges,
			activeFilterCount,
			hasSearchQuery: Boolean(searchQuery),
			isPremiumSelected,
			isCompletedSelected,
			selectedSpecialities: [],
		});
	}, [activeFilters, sections, totalChallenges]);

	const keyExtractor = useCallback(
		(item: Challenge, index: number) => `${item.id}-${index}`,
		[],
	);

	const renderSectionHeader = useCallback(
		({ section }: { section: Section }) => (
			<CustomListSubheader>{section.title}</CustomListSubheader>
		),
		[],
	);

	const viewabilityConfig = useMemo(
		() => ({
			itemVisiblePercentThreshold: 50,
			minimumViewTime: 1000,
		}),
		[],
	);

	return (
		<SectionList
			sections={sections}
			initialNumToRender={10}
			contentContainerStyle={contentContainerStyle}
			maxToRenderPerBatch={5}
			windowSize={5}
			updateCellsBatchingPeriod={100}
			removeClippedSubviews
			stickySectionHeadersEnabled={false}
			keyExtractor={keyExtractor}
			renderSectionHeader={renderSectionHeader}
			renderItem={renderItem}
			viewabilityConfig={viewabilityConfig}
			getItemLayout={(_, index) => ({
				length: 100, // Approximate height of each item
				offset: 100 * index,
				index,
			})}
			ListEmptyComponent={
				<Box paddingBottom={24}>
					<EmptyState
						title={getEmptyState().title}
						subtitle={getEmptyState().subtitle}
						cta={getEmptyState().cta}
						handleAction={handleClearFilters}
					/>
				</Box>
			}
		/>
	);
};

export default ChallengesView;
