/* ----------------- React Native Imports --------------- */
import type { ViewStyle } from "react-native";

/* ----------------- Third Party Imports --------------- */
import ContentLoader from "react-native-easy-content-loader";

interface DonutChartSkeletonProps {
	size?: number;
	avatarStyle?: ViewStyle;
}
export const DonutChartSkeleton = ({
	size = 82,
	avatarStyle,
}: DonutChartSkeletonProps) => {
	return (
		//@ts-ignore
		<ContentLoader
			avatar
			title={false}
			pRows={0}
			avatarStyles={avatarStyle}
			aSize={size}
			containerStyles={{
				width: size,
			}}
		/>
	);
};
