/* -----------------UI--------------- */
import { Portal, Snackbar, useTheme } from "react-native-paper";

/* -----------------Type declarations--------------- */
type Action = {
	label: string;
	action: () => void;
};

/* -----------------Props--------------- */
type Props = {
	visible: boolean;
	handleDismiss: () => void;
	action?: Action;
	message: string;
	duration?: number;
	usePortal?: boolean;
};

/* -----------------Component--------------- */
const SnackBarCustom = (props: Props) => {
	const {
		action,
		visible,
		handleDismiss,
		duration = 7000,
		message,
		usePortal = true,
	} = props;

	const theme = useTheme();
	return (
		<SnackbarHOC usePortal={usePortal}>
			<Snackbar
				visible={visible}
				duration={duration}
				// @ts-ignore
				style={theme.componentOverrides.snackbar}
				theme={{
					colors: {
						accent: "red",
					},
				}}
				onDismiss={handleDismiss}
				action={
					action
						? {
								label: action.label,
								onPress: action.action,
							}
						: undefined
				}
			>
				{message}
			</Snackbar>
		</SnackbarHOC>
	);
};

const SnackbarHOC = ({
	usePortal,
	children,
}: {
	usePortal: boolean;
	children: React.ReactNode;
}) => {
	if (usePortal) {
		return <Portal>{children}</Portal>;
	}
	return <>{children}</>;
};

export default SnackBarCustom;
