import { useExamContext } from "@features/exam";
import { getShowStudyPackViews } from "@helpers/exams";
import { isWeb } from "@helpers/platform";

export const useProductSections = ({
	isFetchingItemsCount,
	practiceTestSubtitle,
	studyPackSubtitle,
}: {
	isFetchingItemsCount: boolean;
	practiceTestSubtitle: string;
	studyPackSubtitle: string;
}) => {
	const {
		currentExamContext: { examName, studyPackProductId },
	} = useExamContext();
	const section = {
		sectionTitle: examName || "Current Exam",
		type: "exam",
		onPress: undefined,
		data: [],
	};

	const showStudypackListItem = getShowStudyPackViews(studyPackProductId);
	const examSectionData = isFetchingItemsCount
		? {
				...section,
				loading: isFetchingItemsCount,
			}
		: {
				...section,
				loading: false,
				data: [
					...(isWeb
						? [
								{
									title: "Practice Tests",
									subtitle: practiceTestSubtitle,
									link: "/practice-tests",
									icon: "clipboard-check-outline" as const,
									type: "practice-tests" as const,
								},
							]
						: []),
					...(showStudypackListItem
						? [
								{
									title: "Study Pack",
									subtitle: studyPackSubtitle,
									link: "/study-pack/",
									icon: "study-pack-outline",
									custom: true,
									type: "study-pack" as const,
								},
							]
						: []),
				],
			};

	return { examSectionData };
};
