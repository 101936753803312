/* eslint-disable @typescript-eslint/no-var-requires */
/* ----------------- Expo --------------- */
import { ImageBackground } from "expo-image";
import Image from "../Image";

/* ----------------- Local Imports --------------- */
import { nameCardMaskImageUrl } from "./constants";

import { useImageDimensions } from "@react-native-community/hooks";
/* ----------------- React Native Paper --------------- */
import { Text } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";
import AvatarGenerator from "./AvatarGenerator";

interface Props {
	image?: string;
	footerImagesUrls?: string[];
	subTitle?: string;
	title?: string;
	avatarType?: "initials" | "identicon" | null;
	firstName?: string;
	lastName?: string;
}
const NameCard = ({
	firstName,
	lastName,
	image,
	subTitle,
	title,
	footerImagesUrls,
	avatarType,
}: Props) => {
	const { dimensions } = useImageDimensions({
		uri: nameCardMaskImageUrl,
	});

	const theme = useAppTheme();

	let fullName = `${firstName} ${lastName}`;
	fullName = fullName.trim().length > 0 ? fullName : "User";

	//TODO:Need to fix the image aspect ratio on web
	return (
		<ImageBackground
			source={{
				uri: nameCardMaskImageUrl,
			}}
			contentFit="contain"
			// contentPosition={'center'}

			style={{
				width: "100%",
				height: 380,
				position: "relative",
				aspectRatio: dimensions?.aspectRatio,
				alignSelf: "center",
				alignItems: "center",
			}}
		>
			{image ? (
				<Image
					style={{
						position: "absolute",
						top: "36%",
						width: 116,
						height: 118,
						borderRadius: 118 / 2,
					}}
					source={{ uri: image }}
				/>
			) : (
				<AvatarGenerator
					firstName={firstName}
					lastName={lastName}
					avatarType={avatarType}
				/>
			)}
			<Box bottom={"22%"} position="absolute" gap={24}>
				<Text
					variant="headlineSmall"
					style={{
						width: 150,
						textAlign: "center",
						color: theme.dark
							? theme.colors.onPrimary
							: theme.colors.onPrimaryContainer,
					}}
					numberOfLines={1}
				>
					{fullName}
				</Text>
			</Box>
			<Box bottom={"3%"} position="absolute" gap={8}>
				<Box>
					{title && (
						<Text
							variant="headlineSmall"
							style={{
								width: 150,
								textAlign: "center",
								fontFamily: "opensans-bold",
								color: theme.colors.white,
							}}
							numberOfLines={1}
						>
							{title.toUpperCase()}
						</Text>
					)}
					{subTitle && (
						<Text
							variant="labelSmall"
							style={{
								width: 150,
								textAlign: "center",
								color: theme.colors.white,
							}}
							numberOfLines={1}
						>
							{subTitle}
						</Text>
					)}
				</Box>
				{footerImagesUrls?.length && !subTitle && !title ? (
					<Box
						flexDirection="row"
						gap={8}
						alignItems="center"
						justifyContent="center"
					>
						{footerImagesUrls.map((item) => {
							return (
								<Image
									source={{
										uri: item,
									}}
									key={item}
									style={{
										width: 36,
										height: 36,
									}}
								/>
							);
						})}
					</Box>
				) : null}
			</Box>
		</ImageBackground>
	);
};

export default NameCard;
