/* ----------------- Globals --------------- */
import { timePerQuestion } from "@hooks/useProcessTimeEstimate";

/* ----------------- Local Imports --------------- */
import { useDailyProgressStore } from "./useDailyProgressStore";

import { events } from "@constants/tracking";
import { useExamContext } from "@features/exam";
import { LearningModes, useSession } from "@features/session";
import { useBundleStore } from "@features/store";
import { hasBundleAccess } from "@helpers/content/tag";
import { getShowStudyPackViews } from "@helpers/exams";
/* ----------------- Helpers --------------- */
import { convertToMinutes, getCurrentDate } from "@memorang/helpers";
import type { Distribution } from "@memorang/types";
import { trackCustomEvent } from "analytics";
import { useCallback, useEffect } from "react";
import { getExamDaysLeft } from "../helpers/stats";
import { taskCardBodyTemplate } from "./constants";

const QUICK_REFRESH_MIN_ITEMS_A_DAY = 20;

const currentDate = getCurrentDate();
export const useDailyProgress = (distribution?: Distribution) => {
	const {
		currentExamContext: { examDate, examId, studyPackProductId },
	} = useExamContext();

	const { handleCreateFormativeSession, creatingSession } = useSession();
	const { currentlySelectedBundleDetails } = useBundleStore();

	const showStudyPackViews = getShowStudyPackViews(studyPackProductId);

	const hasStudyPackAccess = hasBundleAccess(currentlySelectedBundleDetails!);
	// const hasStudyPackAccess = true;
	const { dailyProgressByData, setDailyProgressByDate } =
		useDailyProgressStore();

	const key = `${examId}-${currentDate}`;

	const currentDailyProgress = dailyProgressByData?.[key];

	const processQuickRefreshItems = useCallback(() => {
		if (distribution && !currentDailyProgress) {
			const { low, medium, none } = distribution;
			const daysLeftUntilExam = getExamDaysLeft(examDate);

			if (
				daysLeftUntilExam !== undefined &&
				!Number.isNaN(daysLeftUntilExam) &&
				!currentDailyProgress &&
				hasStudyPackAccess &&
				showStudyPackViews
			) {
				const numTotalUnMasteredItems = low + medium + none;
				const numItemsToStudyADay = Math.min(
					QUICK_REFRESH_MIN_ITEMS_A_DAY,
					Math.floor(numTotalUnMasteredItems / daysLeftUntilExam),
				);

				const progress = {
					[key]: {
						numCompleted: 0,
						numGoal: numItemsToStudyADay,
						status: "NOT_STARTED" as const,
					},
				};

				setDailyProgressByDate(progress);
			}
		}
	}, [
		currentDailyProgress,
		distribution,
		examDate,
		hasStudyPackAccess,
		key,
		setDailyProgressByDate,
		showStudyPackViews,
	]);
	useEffect(() => {
		processQuickRefreshItems();
	}, [processQuickRefreshItems]);

	if (currentDailyProgress) {
		const { numCompleted, numGoal } = currentDailyProgress;
		const isCompleted = numCompleted >= numGoal;

		const numItemsRemaining = numGoal - numCompleted;
		const estimatedTime = `${convertToMinutes(
			numItemsRemaining * timePerQuestion,
		)} minutes`;

		const status = isCompleted
			? ("COMPLETE" as const)
			: currentDailyProgress.status;
		const body = taskCardBodyTemplate({
			isDailyProgress: true,
		})[status];

		return {
			dailyProgressSectionData: {
				title: "Quick Refresh",
				subtitle: `${numCompleted} / ${numGoal} • ${estimatedTime}`,
				status,
				completion: (numCompleted / numGoal) * 100,
				loading: creatingSession,
				showReport: false,
				handleClickTask: () => {
					if (!distribution) {
						return;
					}
					let numRemaining = numGoal - numCompleted;
					const sessionReqDist = {
						low: 0,
						none: 0,
						medium: 0,
						high: 0,
					};
					sessionReqDist.low = Math.min(distribution.low, numRemaining);
					numRemaining -= sessionReqDist.low;
					if (numRemaining > 0) {
						sessionReqDist.medium = Math.min(distribution.medium, numRemaining);
						numRemaining -= sessionReqDist.medium;
					}
					if (numRemaining > 0) {
						sessionReqDist.none = Math.min(distribution.none, numRemaining);
						numRemaining -= sessionReqDist.none;
					}
					trackCustomEvent({
						eventName: events.taskCardClicked,
						isQuickRefresh: "true",
						status,
						numCompleted,
						numGoal,
					});
					handleCreateFormativeSession({
						dist: sessionReqDist,
						learningMode: LearningModes.questions,
					});
				},
				body,
			},
		};
	}
	return null;
};
