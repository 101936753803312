/* -----------------Child components--------------- */
import { pageViewTypes } from "@constants/tracking";
import { SessionContainer } from "@features/session";
import useAnalytics from "@hooks/useAnalytics";
import DocumentTitle from "components/DocumentTitle";

/* -----------------Globals--------------- */
import { Stack } from "expo-router";

const SessionRoute = () => {
	useAnalytics({
		type: pageViewTypes.session,
	});

	return (
		<>
			<Stack.Screen
				options={{
					headerShown: false,
				}}
			/>
			<DocumentTitle title="Session" />
			<SessionContainer />
		</>
	);
};

export default SessionRoute;
