import { useWindowDimensions } from "react-native";
import { useAppTheme } from "./useAppTheme";

const useBreakpoints = () => {
	const { width } = useWindowDimensions();
	const { breakpoints } = useAppTheme();

	return {
		xxsUp: width >= breakpoints.xxs,
		xxsDown: width <= breakpoints.xxs,
		xsUp: width >= breakpoints.xs,
		xsDown: width <= breakpoints.xs,
		smUp: width >= breakpoints.sm,
		smDown: width <= breakpoints.sm,
		mdUp: width >= breakpoints.md,
		mdDown: width <= breakpoints.md,
		lgUp: width >= breakpoints.lg,
		lgDown: width <= breakpoints.lg,
	};
};

export default useBreakpoints;
