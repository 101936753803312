import { newEvents } from "@constants/tracking";
import { trackCustomEvent } from "analytics";
// import useSubscriptionPurchase from "@hooks/useSubscriptionPurchase";
import { useCallback } from "react";
import { useLibraryUIStore } from "../stores/useLibraryUIStore";

export const useFiltering = () => {
	const store = useLibraryUIStore();

	// const { isPremiumUser } = useSubscriptionPurchase();

	const handleQuickActionChipPress = useCallback(
		(type: string) => {
			switch (type) {
				case "speciality":
					{
						trackCustomEvent({
							eventName: newEvents.filterChipClicked,
							filterType: type,
						});
						store.setShowFilterModal(true);
					}
					break;
				case "premium":
					{
						store.toggleFilter("isPremium");
						trackCustomEvent({
							eventName: newEvents.filterChipClicked,
							filterType: type,
						});
					}
					break;
				case "completed":
					{
						store.toggleFilter("isCompleted");
						trackCustomEvent({
							eventName: newEvents.filterChipClicked,
							filterType: type,
						});
					}
					break;
			}
		},
		[store],
	);

	const quickActionsChips = [
		// ...(isPremiumUser
		// 	? []
		// 	: [
		// 			{
		// 				label: "Premium",
		// 				type: "premium",
		// 				tooltip: "Challenges that require a premium subscription",
		// 				selected: store.filterState.isPremium,
		// 			},
		// 		]),
		{
			label: "Completed",
			type: "completed",
			tooltip: "Challenges that you have completed",
			selected: store.filterState.isCompleted,
		},
		{
			label:
				store.selectedFilters.length === 0
					? "Speciality"
					: store.selectedFilters.length === 1
						? store.selectedFilters[0]
						: `${store.selectedFilters.length} Specialities`,
			type: "speciality",
			tooltip: "Challenges that are part of a speciality",
			selected: store.selectedFilters.length > 0,
			closeIcon: "chevron-down",
			onClose: () => {
				// TODO: Clear speciality filters
			},
		},
	];

	const clearAllFilters = useCallback(() => {
		store.setSearchQuery("");
		store.applySpecialityFilters([]);
		if (store.filterState.isPremium) {
			store.toggleFilter("isPremium");
		}
		if (store.filterState.isCompleted) {
			store.toggleFilter("isCompleted");
		}
	}, [store]);

	return {
		quickActionsChips,
		handleQuickActionChipPress,
		clearAllFilters,
	};
};
