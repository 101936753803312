/* ----------------- React Native Paper Imports --------------- */
import { Appbar, IconButton, Text } from "react-native-paper";

/* ----------------- UI Component Imports --------------- */
import { Box } from "@memorang/ui";

import { useAppTheme } from "@hooks/useAppTheme";
/* ----------------- Custom Component Imports --------------- */
import CustomTabs from "components/CustomTabs";
import { router } from "expo-router";
// import DetailArticleCMEView from "../components/DetailArticleCMEView";
import DetailArticleContentView from "../components/DetailArticleContentView";
import DetailArticleSupplementalyView from "../components/DetailArticleSupplementalyView";
import { issues } from "../constants/issues";
import { useDetailArticle } from "../hooks/useDetailArticle";

type Props = {
	id: number;
};

const DetailArticleRouteContainer = ({ id }: Props) => {
	const theme = useAppTheme();
	const { menuIcons } = useDetailArticle(id);

	const article = issues
		.flatMap((issue) => issue.articles)
		.find((article) => article.id === id);

	const tabs = [
		{
			label: "Content",
			content: article ? (
				<DetailArticleContentView article={article} />
			) : (
				<Text>No article found</Text>
			),
		},
		// {
		// 	label: "CME",
		// 	content: <DetailArticleCMEView article={mockedArticleContentMarkdown} />,
		// },
		{
			label: "Supplemental",
			content: <DetailArticleSupplementalyView />,
		},
	];

	return (
		<>
			<Appbar.Header
				mode="small"
				style={{
					backgroundColor: theme.colors.elevation.level3,
				}}
			>
				<Appbar.BackAction onPress={() => router.back()} />
				<Appbar.Content title={"Article"} />
				<Appbar.Action
					onPress={() =>
						router.push({
							pathname: "/feedback",
							params: {
								view: "detail-article",
							},
						})
					}
					icon={"message-alert-outline"}
				/>
				<Box flexDirection="row">
					{menuIcons.map(({ icon, onPress }) => {
						return <IconButton icon={icon} key={icon} onPress={onPress} />;
					})}
				</Box>
			</Appbar.Header>
			<CustomTabs tabs={tabs} />
		</>
	);
};

export default DetailArticleRouteContainer;
