import type { Article } from "../../../../../types";

export const Article616: Article = {
	id: 616,
	rsuiteId: "2511a5f6-e3d8-461f-8cc0-548a869ced1f",
	type: "the orthopaedic forum",
	title:
		"What's Important: Equitable Orthopaedic Care for Patients with Disabilities",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/616.jpeg",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s Important: Equitable Orthopaedic Care for Patients
                with Disabilities</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Uma
                      Balachandran, BA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Brocha Z.
                      Stern, PhD, MOT</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">At a young age, I (U.B.) learned that health care is
                  inaccessible to many patients. Walking arm-in-arm with my aunt, who is blind, into her doctor’s office
                  allowed me to see early on that health care was not made for her. Forms were written in a language she
                  could not read. Free-standing machines in hallways slowed her down as she followed nurses’ voices
                  through noisy hallways into cluttered examination rooms.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Armed with these biases about the inaccessibility of the
                  world around us, I remember the awe I felt walking into the school where my aunt teaches children with
                  multiple disabilities, Lavelle School for the Blind in New York. For the first time, I saw a world in
                  which students who used wheelchairs could easily roll through hallways, one where students who were
                  blind could play with balls that had bells.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Now, as a medical student, I am drawn to orthopaedic
                  surgery because of the potential that it holds to increase the ease with which people move through the
                  world. Our procedures are often essential in improving the quality of life of persons with
                  disabilities. However, this care may be inherently inaccessible because of the many
                  obstacles—environmental (e.g., elevators without braille numbers in a clinician’s office),
                  interpersonal (e.g., clinicians who talk to people with disabilities using “baby talk”), and
                  infrastructural (e.g., a paucity of training for clinicians on working with patients with a variety of
                  disabilities)—that patients may face when interacting with the health-care system.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Background</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">The World Health Organization frames disability as a state
                  that results from an interaction between health conditions and contextual factors (e.g., personal
                  factors, such as age, social background, and education; and environmental factors, such as social
                  attitudes and terrain)<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. In
                  the U.S., 1 in 4, or 61 million, adults live with a disability<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. Disability can take many forms, with examples including a
                  physical disability (e.g., spinal cord injury), developmental disability (e.g., autism), or sensory
                  disability (e.g., blindness). Additionally, individuals with an impairment of the musculoskeletal
                  system that affects daily functioning may experience temporary or long-term disability. Indeed, the
                  2016 Global Burden of Disease Study identified that many of the top causes of years lived with
                  disability in the U.S. were forms of musculoskeletal impairment: low back pain (no. 1), other
                  musculoskeletal disorders (no. 4), neck pain (no. 6), and osteoarthritis (no. 12)<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a>. Hence, orthopaedists may encounter individuals with
                  disabilities under many circumstances in routine practice, such as a surgical consultation for an
                  older adult with low vision and hip pain related to osteoarthritis, a young adult with a spinal cord
                  injury and rotator cuff pathology, and a middle-aged adult with chronic low back pain.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">In September 2023, the National Institutes of Health
                  designated people with disabilities as a population with health disparities, alongside others such as
                  racial/ethnic and sexual/gender minority groups<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>. As a
                  group that experiences substantial health inequities, individuals with disabilities have reported
                  dissatisfaction with the information received about their conditions, the costs of care, and the ease
                  with which they are able to visit a clinician<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">5</span></a>. This
                  designation highlights the reality of the lived experience of many patients seeking orthopaedic care.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Orthopaedists hold the power to help demolish current
                  barriers that marginalized patient populations face when attempting to access care. As a call to
                  action, we provide examples of solutions addressing environmental, interpersonal, and infrastructural
                  factors that are barriers to health equity for patients with disabilities.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Environmental Factors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Although the Americans with Disabilities Act requires that
                  hospitals and doctors’ offices be designed to be accessible for people with disabilities, barriers
                  often persist in health-care environments. Beyond ramps, clinicians and those they work with to design
                  their facilities should incorporate principles of universal design, which focuses on creating spaces
                  that are inclusive and accessible to all people who use them. Specifically, they may ensure that
                  patients can access all parts of their facilities (e.g., hallways, examination rooms) and equipment
                  (e.g., examination tables, weighing machines)<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>.
                  Clinicians may also choose to highlight the importance of inclusion in their office through a display
                  or other means of communicating nondiscriminatory policies<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a>.
                  Finally, it is worth remembering that not all patients with disabilities have the same needs (e.g.,
                  patients with hearing impairments may need sign language interpreters, patients with autism may need
                  quiet spaces), and we should strive to help all patients feel cared for.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Interpersonal Factors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Some individuals may hold an implicit disability bias, or
                  the belief that the life of a person with a disability is less valuable than that of a person without
                  a disability<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">8</span></a>.
                  Clinicians who hold such an unconscious bias may joke that a wheelchair user is “zooming around” or
                  talk to someone with a disability in “baby talk.”<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">9</span></a> We all can examine our biases, both conscious and
                  unconscious, through self-reflection and using tools such as the Harvard Implicit Assessment Tests<a
                    href="" data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">10</span></a>. Efforts should also be invested in workforce diversity
                  that includes disability status, as doing so helps to improve not only patient outcomes, but also
                  clinicians’ productivity and performance. Indeed, when clinicians and staff are reflective of the
                  populations they care for, patients feel more supported, fortifying the patient-provider relationship
                  and subsequently leading to better outcomes<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">11</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Infrastructural Factors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Ultimately, sources of inequitable care for people with
                  disabilities are deeply ingrained in our health-care system, and we all must play a role in creating
                  infrastructural change. Within health systems, clinicians must be trained to improve care for
                  populations that are systematically marginalized, such as patients with disabilities<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">12</span></a>. A particularly effective training could be led by or
                  highlight the voices of people with disabilities and may include case studies, didactics, or
                  simulations<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">12</span></a>.
                  Nationwide, disparities may be exacerbated by the fact that patients with disabilities often have
                  Medicaid insurance, which further limits access to care. One study noted that 40% of orthopaedic
                  surgeons were hesitant to care for patients with Medicaid, potentially because of low reimbursement
                  rates and the additional time and resources that may be needed to comprehensively care for a patient
                  with social complexity<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">14</span></a>. As such, reimbursement models may need to be restructured
                  to ensure that clinicians are not penalized for providing orthopaedic care needed by patients with
                  disabilities. Additionally, the systematic and routine capture of disability status in health-care
                  documentation and national data are necessary to guide efforts to identify and address disparities
                  experienced by patients with disabilities.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Accessible
                Care for All Patients</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Patients with disabilities commonly seek orthopaedic care
                  but often encounter many barriers to accessing these services. By taking steps to modify
                  environmental, interpersonal, and infrastructural factors, orthopaedists can increase equity for this
                  population that faces notable health-care disparities. As a medical student, I hope to use my voice to
                  highlight the lived experiences of my aunt, and many others in the disability community, in order to
                  equip orthopaedic surgeons with the tools they need to provide all patients with equitable and
                  transformative care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;">World
                Health Organization. <span data-id="strong_1" class="annotation strong" style="position: unset;">Towards
                  a common language for functioning, disability, and health</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">ICF.</span> 2002. Accessed 2024 May 23. <a
                  href="https://cdn.who.int/media/docs/default-source/classification/icf/icfbeginnersguide.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://cdn.who.int/media/docs/default-source/classification/icf/icfbeginnersguide.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Towards%20a%20common%20language%20for%20functioning%2C%20disability%2C%20and%20health&as_occt=title&as_sauthors=%20%22World%20Health%20Organization%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;">Centers for
                Disease Control and Prevention. <span data-id="strong_2" class="annotation strong"
                  style="position: unset;">Disability Impacts All of Us</span>. 2023 May 15. Accessed 2024 Mar 3. <a
                  href="https://www.cdc.gov/ncbddd/disabilityandhealth/infographic-disability-impacts-all.html"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://www.cdc.gov/ncbddd/disabilityandhealth/infographic-disability-impacts-all.html</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Disability%20Impacts%20All%20of%20Us&as_occt=title&as_sauthors=%20%22Centers%20for%20Disease%20Control%20and%20Prevention%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Mokdad AH</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Ballestros K</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Echko M</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Glenn S</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Olsen HE</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Mullany E</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Lee A</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Khan AR</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Ahmadi A</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Ferrari AJ</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Kasaeian A</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Werdecker A</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Carter A</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Zipkin B</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Sartorius B</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Serdar B</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Sykes BL</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Troeger C</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Fitzmaurice C</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Rehm CD</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Santomauro D</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Kim D</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Colombara D</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Schwebel DC</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Tsoi D</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Kolte D</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Nsoesie E</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Nichols E</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Oren E</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Charlson FJ</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Patton GC</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Roth GA</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Hosgood HD</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Whiteford HA</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Kyu H</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Erskine HE</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Huang H</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Martopullo I</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Singh JA</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Nachega JB</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Sanabria JR</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Abbas K</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Ong K</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Tabb K</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Krohn KJ</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Cornaby L</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Degenhardt L</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Moses M</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Farvid M</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Griswold M</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Criqui M</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Bell M</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Nguyen M</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Wallin M</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Mirarefin M</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Qorbani M</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Younis M</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Fullman N</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Liu P</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Briant P</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Gona P</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Havmoller R</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Leung R</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Kimokoti R</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Bazargan-Hejazi S</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Hay SI</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Yadgir S</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Biryukov S</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Vollset SE</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Alam T</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Frank T</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Farid T</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Miller T</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Vos T</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Bärnighausen T</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Gebrehiwot TT</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Yano Y</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Al-Aly Z</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Mehari A</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Handal A</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Kandel A</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Anderson B</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Biroscak B</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Mozaffarian D</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Dorsey ER</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Ding EL</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Park EK</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Wagner G</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Hu G</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Chen H</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Sunshine JE</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Khubchandani J</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Leasher J</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Leung J</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Salomon J</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Unutzer J</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Cahill L</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Cooper L</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Horino M</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Brauer M</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Breitborde N</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Hotez P</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Topor-Madry R</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Soneji S</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Stranges S</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> James S</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Amrock S</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Jayaraman S</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Patel T</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Akinyemiju T</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Skirbekk V</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Kinfu Y</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Bhutta Z</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Jonas JB</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Murray CJL</span>; US Burden of
                Disease Collaborators. <span data-id="strong_3" class="annotation strong" style="position: unset;">The
                  State of US Health, 1990-2016: Burden of Diseases, Injuries, and Risk Factors Among US States</span>.
                <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">JAMA.</span> 2018 Apr
                10;319(14):1444-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20State%20of%20US%20Health%2C%201990-2016%3A%20Burden%20of%20Diseases%2C%20Injuries%2C%20and%20Risk%20Factors%20Among%20US%20States&as_occt=title&as_sauthors=%20%22AH%20Mokdad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;">National
                Institutes of Health. <span data-id="strong_4" class="annotation strong" style="position: unset;">NIH
                  designates people with disabilities as a population with health disparities</span>. 2023 Sep 25.
                Accessed 2024 Dec 24. <a
                  href="https://www.nih.gov/news-events/news-releases/nih-designates-people-disabilities-population-health-disparities"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://www.nih.gov/news-events/news-releases/nih-designates-people-disabilities-population-health-disparities</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=NIH%20designates%20people%20with%20disabilities%20as%20a%20population%20with%20health%20disparities&as_occt=title&as_sauthors=%20%22National%20Institutes%20of%20Health%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Iezzoni LI</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Davis RB</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Soukup J</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> O’Day B</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Satisfaction with quality and
                  access to health care among people with disabling conditions</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Int J Qual Health Care.</span> 2002
                Oct;14(5):369-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Satisfaction%20with%20quality%20and%20access%20to%20health%20care%20among%20people%20with%20disabling%20conditions&as_occt=title&as_sauthors=%20%22LI%20Iezzoni%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Iezzoni LI</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> O’Day BL</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">More than Ramps: A Guide to
                  Improving Health Care Quality and Access for People with Disabilities.</span> Oxford University Press;
                2005.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Houtrow A</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Sabatini C</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Jarvis J</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Health Equity for Children with
                  Disabilities: Current Concept Review</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Journal of the Pediatric Orthopaedic Society of North America.</span>
                2023;5(1).</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20Equity%20for%20Children%20with%20Disabilities%3A%20Current%20Concept%20Review&as_occt=title&as_sauthors=%20%22A%20Houtrow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Chicoine C</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Hickey EE</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Kirschner KL</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Chicoine BA</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Ableism at the Bedside: People
                  with Intellectual Disabilities and COVID-19</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">J Am Board Fam Med.</span> 2022
                Mar-Apr;35(2):390-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ableism%20at%20the%20Bedside%3A%20People%20with%20Intellectual%20Disabilities%20and%20COVID-19&as_occt=title&as_sauthors=%20%22C%20Chicoine%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Kattari SK</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Ableist Microaggressions and the
                  Mental Health of Disabled Adults</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Community Ment Health J.</span> 2020 Aug;56(6):1170-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ableist%20Microaggressions%20and%20the%20Mental%20Health%20of%20Disabled%20Adults&as_occt=title&as_sauthors=%20%22SK%20Kattari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Chapman EN</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Kaatz A</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Carnes M</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Physicians and implicit bias:
                  how doctors may unwittingly perpetuate health care disparities</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">J Gen Intern Med.</span> 2013
                Nov;28(11):1504-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Physicians%20and%20implicit%20bias%3A%20how%20doctors%20may%20unwittingly%20perpetuate%20health%20care%20disparities&as_occt=title&as_sauthors=%20%22EN%20Chapman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Gomez LE</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Bernet P</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Diversity improves performance
                  and outcomes</span>. <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J
                  Natl Med Assoc.</span> 2019 Aug;111(4):383-92.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diversity%20improves%20performance%20and%20outcomes&as_occt=title&as_sauthors=%20%22LE%20Gomez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Rotenberg S</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Rodríguez Gatta D</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Wahedi A</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Loo R</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> McFadden E</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Ryan S</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Disability training for health
                  workers: A global evidence synthesis</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Disabil Health J.</span> 2022 Apr;15(2):101260.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Disability%20training%20for%20health%20workers%3A%20A%20global%20evidence%20synthesis&as_occt=title&as_sauthors=%20%22S%20Rotenberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Dy CJ</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Tipping AD</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Nickel KB</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Jiang W</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> O’Keefe RJ</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Olsen MA</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Variation in the Delivery of
                  Inpatient Orthopaedic Care to Medicaid Beneficiaries within a Single Metropolitan Region</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2019 Aug 21;101(16):1451-9.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=2141027" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Decker SL</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Two-thirds of primary care
                  physicians accepted new Medicaid patients in 2011-12: a baseline to measure future acceptance
                  rates</span>. <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Health
                  Aff (Millwood).</span> 2013 Jul;32(7):1183-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Two-thirds%20of%20primary%20care%20physicians%20accepted%20new%20Medicaid%20patients%20in%202011-12%3A%20a%20baseline%20to%20measure%20future%20acceptance%20rates&as_occt=title&as_sauthors=%20%22SL%20Decker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D2511a5f6-e3d8-461f-8cc0-548a869ced1f%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2397</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00470</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 26, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;2511a5f6-e3d8-461f-8cc0-548a869ced1f&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=2511a5f6-e3d8-461f-8cc0-548a869ced1f&type=pdf&name=JBJS.24.00470.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=2511a5f6-e3d8-461f-8cc0-548a869ced1f&type=pdf&name=JBJS.24.00470.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_14"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_15" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I143" target="_blank" data-id="link_4"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I143</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;2511a5f6-e3d8-461f-8cc0-548a869ced1f&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=2511a5f6-e3d8-461f-8cc0-548a869ced1f&type=zip&name=JBJS.24.00470.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Uma Balachandran, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:uma.balachandran@icahn.mssm.edu" class=""
                    style="position: unset;">uma.balachandran@icahn.mssm.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-5002-9978" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-5002-9978</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brocha Z. Stern, PhD, MOT<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Leni and Peter W. May Department of Orthopaedic Surgery, Icahn School of
                Medicine at Mount Sinai, New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 9500.27px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
