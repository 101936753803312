/**
 * @generated SignedSource<<9ca2232993c390332d43b52976f16026>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CheckoutMode = "EMBED" | "HOSTED" | "%future added value";
export type PurchaseMode = "GUEST" | "USER" | "%future added value";
export type CreateCheckoutInput = {
  checkoutMode?: CheckoutMode | null;
  description?: string | null;
  failureUrl: string;
  isTestPurchase?: boolean | null;
  planId: string;
  productId: string;
  purchaseMode: PurchaseMode;
  successUrl: string;
};
export type createCheckoutMutation$variables = {
  payment: CreateCheckoutInput;
};
export type createCheckoutMutation$data = {
  readonly createCheckoutSession: {
    readonly clientSecret: string | null;
    readonly objectId: string | null;
    readonly paymentKey: string;
    readonly paymentUrl: string | null;
    readonly success: boolean | null;
  };
};
export type createCheckoutMutation = {
  response: createCheckoutMutation$data;
  variables: createCheckoutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "payment"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "payment",
        "variableName": "payment"
      }
    ],
    "concreteType": "CheckoutResult",
    "kind": "LinkedField",
    "name": "createCheckoutSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "objectId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paymentKey",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paymentUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientSecret",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createCheckoutMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createCheckoutMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "35355856e8a291a5fcdcede52c72b267",
    "id": null,
    "metadata": {},
    "name": "createCheckoutMutation",
    "operationKind": "mutation",
    "text": "mutation createCheckoutMutation(\n  $payment: CreateCheckoutInput!\n) {\n  createCheckoutSession(payment: $payment) {\n    objectId\n    paymentKey\n    success\n    paymentUrl\n    clientSecret\n  }\n}\n"
  }
};
})();

(node as any).hash = "f7e279b61ede9c3254d52b1c05644a3e";

export default node;
