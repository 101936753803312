import { useRouter } from "expo-router";
/* -----------------Globals--------------- */
import { Platform } from "react-native";

/* -----------------UI--------------- */
import { Appbar, Text } from "react-native-paper";
import Box from "../Box";
import TooltipWrapper from "../TooltipWrapper";
import { AppBarMenu } from "./AppBarMenu";
import { RecommendedChipsSkeleton } from "./RecommendedChipsSkeleton";

/* -----------------Helpers & Hooks--------------- */
import { defaultAiAssistantInfo } from "@memorang/helpers";

/* -----------------Types--------------- */
import type { ChatAppbarProps } from "./types/props";

const ChatAppbar = (props: ChatAppbarProps) => {
	const router = useRouter();
	const {
		actions = [],
		menuActions = [],
		title = defaultAiAssistantInfo.title,
		isBackButtonVisible = true,
		backAction = {
			icon: Platform.OS === "android" ? "arrow-left" : "close",
			size: 24,
			handleAction: () => {
				if (router.canGoBack()) {
					router.back();
				}
			},
		},
		subtitle,
		isSubtitleLoading,
	} = props;

	return (
		<Appbar.Header
			elevated
			mode="center-aligned"
			statusBarHeight={Platform.OS !== "android" ? 0 : undefined}
		>
			{isBackButtonVisible && (
				<Appbar.Action
					icon={backAction.icon}
					onPress={backAction.handleAction}
					size={backAction.size}
				/>
			)}
			<Box gap={2} flex={1}>
				<Appbar.Content
					title={title}
					style={{
						flex: 0,
					}}
				/>

				{isSubtitleLoading ? (
					<RecommendedChipsSkeleton
						numLines={1}
						skeletonHeight={8}
						width={200}
					/>
				) : (
					!!subtitle && (
						<TooltipWrapper title={subtitle}>
							<Text variant="labelSmall" numberOfLines={1}>
								{subtitle}
							</Text>
						</TooltipWrapper>
					)
				)}
			</Box>
			{actions.map(({ icon, handleAction, title, disabled }) => (
				<TooltipWrapper title={title} key={icon}>
					<Appbar.Action
						icon={icon}
						onPress={handleAction}
						disabled={disabled}
					/>
				</TooltipWrapper>
			))}
			{menuActions.length > 0 && <AppBarMenu menuActions={menuActions} />}
		</Appbar.Header>
	);
};

export default ChatAppbar;
