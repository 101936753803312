import type { Distribution, MixedItemDist } from "@memorang/types";
import type { Stat } from "components/RowStats";
import type { EXAM_STATUS } from "types/tag";

export interface CumulativeSummaryResponse {
	cumulativeSummaryStats: {
		cumulative: {
			accuracy: number;
			speed: number;
			completion: {
				completionPercentage: number;
				distCount: number;
				distCompleted: number;
				numCorrect: number;
			};
		}[];
	};
}

export type FetchMasteryOvertimeResponse = {
	masteryOverTime: {
		mastery: {
			date: string;
			masteryCount: number;
		}[];
		speed: number[];
	};
};

export interface TaskCardProps {
	title: string;
	subtitle?: string;
	status: EXAM_STATUS;
	handleClickTask: () => void;
	body: string;
	loading?: boolean;
	completion?: number;
}

export type SectionItems =
	| {
			title: string;
			subtitle: string;
			link: string;
			icon: string;
			custom?: boolean;
			type?: "practice-tests" | "study-pack";
	  }[]
	| MixedItemDist["tags"]
	| TaskCardProps[];
export type HeaderSectionData = {
	headerStats: Stat[];
	masteryPercentage: number;
	distribution: Distribution;
	examIcon?: string;
	examName?: string;
	examStatusText?: string;
	examDate?: string;
};

export type SectionData = {
	type: string;
	loading?: boolean;
	items: SectionItems;
};
export type HomeSection = {
	title: string;
	type?: string;
	loading?: boolean;
	data: SectionData[];
	onPress?: () => void;
};
