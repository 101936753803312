const title = "Analysis";
const subtitle = "Personalized analysis and recommendations";

const isDev = false;
const baseUrl = isDev ? "http://localhost:3000" : "https://hub.memorang.com";
const ENDPOINT_SESSION_ANALYSIS = `${baseUrl}/api/ai/sessionAnalysis`;

const events = {
	viewReportClicked: "View_Report_Clicked",
	fullReportViewed: "Full_Report_Viewed",
	readingTime: "Reading_Time",
};
export { title, subtitle, ENDPOINT_SESSION_ANALYSIS, events };
