import { getSelectCountryOnboarding } from "@helpers/find-onboarding";
import { useAuthContext } from "@memorang/applets";
import { useLocales } from "expo-localization";
import { useEffect } from "react";
import { useAppStore } from "./useAppStore";
import { type Country, useCurrentCountryStore } from "./useCurrentCountryStore";

export const useCurrentCountry = () => {
	const locales = useLocales();

	const { newUserInfo } = useAuthContext();

	const countryCodeFromBackend = newUserInfo?.country;
	const { country, setCountry } = useCurrentCountryStore();
	const regionCode = locales?.length ? locales[0].regionCode : null;
	const onboarding = useAppStore((store) => store.onboarding);
	const onboardingCountryStep = getSelectCountryOnboarding(onboarding!);

	const currentCountry = onboardingCountryStep?.data?.find(
		(country) => country.type === regionCode,
	);

	const countryFromBackend = onboardingCountryStep?.data?.find(
		(country) => country.type === countryCodeFromBackend,
	);

	useEffect(() => {
		if (currentCountry || countryFromBackend) {
			setCountry(countryFromBackend || (currentCountry as Country));
		}
	}, [currentCountry, countryFromBackend, setCountry]);

	const currentCountryCode = currentCountry?.type;

	return {
		regionCode,
		country,
		setCountry,
		currentCountryCode,
	};
};
