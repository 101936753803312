import CMEView from "@features/cme/components/CMEView";
import { isWeb } from "@helpers/platform";
import { useDevice } from "@memorang/ui";
import { CustomAppbar } from "components/index";
import { router } from "expo-router";

const CMERoute = () => {
	const { isMobile } = useDevice();
	return (
		<>
			<CustomAppbar
				showCommandBar={isWeb}
				mode="medium"
				align="flex-start"
				showSettings={false}
				showUserMenu={false}
				handleClickInfoIcon={() => {
					router.push("/cme/disclaimer");
				}}
				options={{
					headerTitle: "Continuing Medical Education",
					subtitle: isMobile ? undefined : "Your CME dashboard",
				}}
			/>
			<CMEView />
		</>
	);
};

export default CMERoute;
