import type { Article } from "../../../../../types";

export const Article521: Article = {
	id: 521,
	rsuiteId: "5400f646-1057-48c7-801b-1efe9735adc7",
	type: "scientific article",
	title:
		"Optimizing Muscle-Tendon Lengths in Reverse Total Shoulder Arthroplasty",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=jpeg&name=JBJS.23.01123f1",
	subSpecialties: ["shoulder_elbow"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Optimizing Muscle-Tendon Lengths in Reverse Total
                Shoulder Arthroplasty</div>
              <div class="text subtitle" style="position: unset;">Evaluation of Surgical and Implant-Design-Related
                Parameters</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jay M. Levin,
                      MD, MBA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Fabrizio
                      Gobbi, MS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Background:</span> Optimizing the function of muscles that cross the
                        glenohumeral articulation in reverse total shoulder arthroplasty (RTSA) is controversial. The
                        current study used a geometric model of the shoulder to systematically examine surgical
                        placement and implant-design parameters to determine which RTSA configuration most closely
                        reproduces native muscle-tendon lengths of the deltoid and rotator cuff.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Methods:</span> A geometric model of the glenohumeral joint was
                        developed and adjusted to represent small, medium, and large shoulders. Muscle-tendon lengths
                        were assessed for the anterior deltoid, middle deltoid, posterior deltoid, and supraspinatus
                        from 0 to 90° of scaption; for the subscapularis from 0° to 60° of internal rotation (IR) and 0°
                        to 60° of scaption; for the infraspinatus from 0° to 60° of external rotation (ER) and 0° to 60°
                        of scaption; and for the teres minor from 0° to 60° of ER at 90° of scaption. RTSA designs were
                        virtually implanted using the following parameters: (1) surgical placement with a centered or
                        inferior glenosphere position and a humeral offset of 0, 5, or 10 mm relative to the anatomic
                        neck plane, (2) implant design involving a glenosphere size of 30, 36, or 42 mm, glenosphere
                        lateralization of 0, 5, or 10 mm, and humeral neck-shaft angle of 135°, 145°, or 155°. Thus, 486
                        RTSA-shoulder size combinations were analyzed. Linear regression assessed the strength of
                        association between parameters and the change in each muscle-tendon length from the native
                        length.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Results:</span> The configuration that most closely restored anatomic
                        muscle-tendon lengths in a small shoulder was a 30-mm glenosphere with a centered position, 5 mm
                        of glenoid lateralization, 0 mm of humeral offset, and a 135° neck-shaft angle. For a medium
                        shoulder, the corresponding combination was 36 mm, centered, 5 mm, 0 mm, and 135°. For a large
                        shoulder, it was 30 mm, centered, 10 mm, 0 mm, and 135°. The most important implant-design
                        parameter associated with restoration of native muscle-tendon lengths was the neck-shaft angle,
                        with a 135° neck-shaft angle being favored (β = 0.568 to 0.657, p &lt; 0.001). The most
                        important surgical parameter associated with restoration of native muscle-tendon lengths was
                        humeral offset, with a humeral socket placed at the anatomic neck plane being favored (β = 0.441
                        to 0.535, p &lt; 0.001).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Conclusions:</span> A combination of a smaller, lateralized
                        glenosphere, a humeral socket placed at the anatomic neck plane, and an anatomic 135° neck-shaft
                        angle best restored native deltoid and rotator cuff muscle-tendon lengths in RTSA.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> This study of surgical and implant factors
                        in RTSA highlighted optimal configurations for restoration of native muscle-tendon lengths of
                        the deltoid and rotator cuff, which has direct implications for surgical technique and implant
                        selection. Additionally, it demonstrated the most influential surgical and implant factors with
                        respect to muscle-tendon lengths, which can be used to aid intraoperative decision-making.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Reverse total shoulder arthroplasty (RTSA) is a widely
                  accepted surgical procedure for the treatment of various shoulder pathologies and has excellent
                  long-term outcomes<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">4</span></a>. Achieving appropriate deltoid and rotator cuff tension is
                  critical to optimize functional outcomes and minimize complications<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">6</span></a>.
                  Insufficient tension can lead to prosthetic instability, limited range of motion, and muscle
                  weakness<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">7</span></a>,
                  while excessive tension has been associated with pain, acromial fracture, nerve injury, stiffness,
                  polyethylene wear, and inability to repair the subscapularis<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">10</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Although the importance of optimizing soft-tissue tension
                  in RTSA is widely recognized, the best way to achieve optimal muscle-tendon lengths across the
                  glenohumeral articulation remains controversial<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">11</span></a>.
                  Multiple parameters, including implant placement and design and patient-specific anatomy, influence
                  muscle-tendon length following RTSA. Previous studies assessing muscle-tendon lengths after RTSA are
                  limited, with heterogeneous methodology and findings<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">14</span></a>. Also, prior literature has not systematically assessed the
                  relative importance of surgical and implant-design parameters in the restoration of anatomic
                  length-tension relationships in RTSA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Substantial previous investigations of how RTSA improves
                  function have been performed on the premise that the moment arm of a muscle is the most important
                  feature that facilitates motion of the humerus<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">15</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">17</span></a>. However, there has been little interest in understanding
                  the potentially detrimental implications of altering the force-length relationships of the muscles,
                  which ultimately affect their ability to generate torque and control movement of the shoulder<a
                    href="" data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">18</span></a>. Given that osteoarthritis is now the most common
                  indication for RTSA, consideration of the rotator cuff in addition to the deltoid is increasingly
                  important<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">19</span></a>. The
                  purpose of this study was to use a geometric model of the shoulder to systematically examine surgical
                  placement parameters and implant-design parameters that most closely reproduce native muscle-tendon
                  lengths of the deltoid and rotator cuff in small, medium, and large shoulders. We hypothesized that a
                  smaller, lateralized glenosphere and a 135° humeral neck-shaft angle with a humeral socket placed at
                  the level of the anatomic neck plane would most closely restore native muscle-tendon lengths about the
                  shoulder.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Native
                Shoulder Model</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Modeling software (Houdini SideFX; Side Effects Software)
                  was used to create a 3-dimensional model of the glenohumeral joint from a computed tomography (CT)
                  scan of a native healthy shoulder. The origin and insertion sites of the anterior deltoid, middle
                  deltoid, posterior deltoid, subscapularis, supraspinatus, infraspinatus, and teres minor were assigned
                  coordinates on the osseous model according to anatomical textbooks and peer-reviewed literature<a
                    href="" data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">21</span></a>. Thirty evenly distributed muscle fibers were modeled for
                  each muscle as lines connecting origin and insertion sites without considering muscle wrapping.
                  Muscle-tendon length was calculated as the mean distance of the muscle fibers from the origin to the
                  insertion of the muscle-tendon unit. Next, anatomic data on scapular and humeral sizes in patients
                  undergoing RTSA<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">22</span></a> for
                  severe rotator cuff deficiency were used to anthropometrically scale the model to small, medium, and
                  large shoulders (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>)<a
                    href="" data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">22</span></a>. Muscle-tendon lengths were assessed for the anterior
                  deltoid, middle deltoid, posterior deltoid, subscapularis, and supraspinatus from 0° to 90° of
                  scaption; for the subscapularis from 0° to 60° of internal rotation (IR) and 0° to 60° of scaption;
                  for the infraspinatus from 0° to 60° of external rotation (ER) and 0° to 60° of scaption; and for the
                  teres minor from 0° to 60° of ER at 90° of scaption. A coordinate system was defined on the basis of
                  coronal, axial, and sagittal planes with reference to the scapular plane<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">22</span></a>. Model verification is presented in the Appendix.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_12" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Glenohumeral Anthropometric Scaling Parameters
                          According to Shoulder Size</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 125px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Small</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Medium</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Large</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Glenoid height <span
                            data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">31.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">35.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">39.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Humeral head diameter
                          <span data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">44.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">48.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">52.5</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">RTSA
                Model</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">RTSA components were modeled with the following
                  parameters, similar to a prior study<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">23</span></a>:
                  centered or inferior glenosphere placement; humeral offset of 0, 5, or 10 mm (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>); glenosphere size of 30, 36, or 42 mm; glenoid lateralization
                  of 0, 5, or 10 mm; and humeral neck-shaft angle of 135°, 145°, or 155° (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). Glenospheres were virtually implanted, centered on a 25-mm
                  baseplate in neutral version and inclination. A total of 486 (3<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">5</span></a> × 2) unique shoulder size-RTSA configurations based on the 3
                  values each for shoulder size, glenosphere size, glenoid lateralization, humeral neck-shaft angle, and
                  humeral offset and the 2 values for glenosphere position were therefore created and analyzed. These
                  parameters represent the full spectrum of RTSA options available<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">24</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01123f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=jpeg&name=JBJS.23.01123f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_40"
                            class="annotation emphasis" style="position: unset;">Top:</span> Geometric models of a
                          medium shoulder with the glenosphere positioned either centered (left) or inferiorly (right)
                          on the glenoid. Glenoid baseplates were placed either centrally on the glenoid or with the
                          baseplate flush with the inferior glenoid. <span data-id="emphasis_41"
                            class="annotation emphasis" style="position: unset;">Bottom:</span> Geometric models of a
                          medium shoulder demonstrating humeral offset of 0 mm (<span data-id="emphasis_42"
                            class="annotation emphasis" style="position: unset;">left</span>), 5 mm (<span
                            data-id="emphasis_43" class="annotation emphasis" style="position: unset;">center</span>),
                          or 10 mm (<span data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">right</span>) in a prosthesis with a 135° neck-shaft angle. The
                          humeral component offset was defined as the distance between the anatomic neck and the humeral
                          socket; 0 mm of humeral offset corresponds to a humeral socket at the level of the anatomic
                          neck.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01123f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=jpeg&name=JBJS.23.01123f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_45"
                            class="annotation emphasis" style="position: unset;">Top:</span> Geometric models of a
                          medium shoulder with a 30-mm (<span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">left</span>), 36-mm (<span data-id="emphasis_47"
                            class="annotation emphasis" style="position: unset;">center</span>), or 42-mm (<span
                            data-id="emphasis_48" class="annotation emphasis" style="position: unset;">right</span>)
                          glenosphere in the centered position on the glenoid. <span data-id="emphasis_49"
                            class="annotation emphasis" style="position: unset;">Middle:</span> Geometric models of a
                          medium shoulder with a glenosphere with 0 mm (<span data-id="emphasis_50"
                            class="annotation emphasis" style="position: unset;">left</span>), 5 mm (<span
                            data-id="emphasis_51" class="annotation emphasis" style="position: unset;">center</span>),
                          or 10 mm of lateralization (<span data-id="emphasis_52" class="annotation emphasis"
                            style="position: unset;">right</span>) in the centered position on the glenoid. Glenospheres
                          were modeled as a hemisphere, with lateralization accomplished by shifting the glenosphere
                          laterally by 5 or 10 mm. <span data-id="emphasis_53" class="annotation emphasis"
                            style="position: unset;">Bottom:</span> Geometric models of a medium shoulder demonstrating
                          a humeral neck-shaft angle of 135° (<span data-id="emphasis_54" class="annotation emphasis"
                            style="position: unset;">left</span>), 145° (<span data-id="emphasis_55"
                            class="annotation emphasis" style="position: unset;">center</span>), or 155° (<span
                            data-id="emphasis_56" class="annotation emphasis" style="position: unset;">right</span>).
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Descriptive statistics were used to quantify the
                  muscle-tendon length changes across the RTSA configurations. The magnitude of change in muscle-tendon
                  length from the native shoulder was calculated by taking the absolute value of the percentage change
                  in muscle-tendon length. This was critical since many RTSA configurations result in lengthening of a
                  muscle in a certain glenohumeral joint position but shortening of the muscle in other positions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">For each muscle in the model, the RTSA configurations were
                  then ranked in ascending order in terms of the absolute percentage of change in the muscle-tendon
                  length compared with that in a size-matched native shoulder. Next, the mean rank across all muscles
                  was calculated for each configuration, and the highest mean rank was considered to indicate the
                  configuration that most closely replicated the muscle-tendon lengths of the native shoulder.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Multivariable linear regression was utilized to assess the
                  relative contribution and strength of association of each individual surgical and implant parameter
                  for achieving both muscle-tendon lengthening and the absolute percentage change from native
                  muscle-tendon lengths. The standardized beta coefficient was used to assess the strength of each
                  correlation. P &lt; 0.05 was considered significant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The proportion of RTSA configurations that achieved a
                  &lt;5% difference from the native muscle-tendon length was also calculated for each muscle to
                  demonstrate the importance of certain surgical and implant-design parameters for achieving
                  near-anatomic muscle-tendon lengths, and how certain parameters preclude restoring anatomic
                  muscle-tendon lengths<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">22</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Additionally, the impact of deviation from the native
                  muscle-tendon length on the force-length curve of the middle deltoid was assessed, using previously
                  published methodology<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">18</span></a>, to
                  assess how active and passive deltoid forces differed in a comparison of the native shoulder, the
                  optimal RTSA configuration (in terms of restoring anatomic muscle-tendon lengths), and the suboptimal
                  RTSA configuration (with the greatest deviation from the anatomic length).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Optimal RTSA
                Configurations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The configuration that most closely restored anatomic
                  muscle-tendon lengths in a small shoulder was a 30-mm glenosphere with a centered position, 5 mm of
                  glenoid lateralization, 0 mm of humeral offset, and a 135° neck-shaft angle (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). For a medium shoulder, the corresponding parameters were 36
                  mm, centered, 5 mm, 0 mm, and 135° (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>). For a
                  large shoulder, the parameters were 30 mm, centered, 10 mm, 0 mm, and 135° (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>) (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). When
                  only inferior glenoid component placement was considered, the configuration that most closely restored
                  anatomic muscle-tendon lengths in a small shoulder was 36 mm, inferior, 5 mm, 0 mm, and 135°. For a
                  medium shoulder, it was 30 mm, inferior, 10 mm, 0 mm, and 135°. For a large shoulder, it was 36 mm,
                  inferior, 10 mm, 0 mm, and 135°.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01123f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=jpeg&name=JBJS.23.01123f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Geometric models of a small shoulder with the
                          optimal RTSA configuration (30-mm glenosphere/centered/5 mm glenoid lateralization/0 mm
                          humeral offset/135° neck-shaft angle) superimposed on a native glenohumeral joint in 0°, 30°,
                          and 60° of scaption. Muscle fibers are depicted for the deltoid (anterior, middle, and
                          posterior) and infraspinatus, with native shoulder fibers in blue and RTSA shoulder fibers in
                          red, demonstrating similar lengths and line of action in the optimal RTSA configuration
                          compared with the native shoulder.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01123f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=jpeg&name=JBJS.23.01123f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">Geometric models of a medium shoulder with the
                          optimal RTSA configuration (36-mm glenosphere/centered/5 mm glenoid lateralization/0 mm
                          humeral offset/135° neck-shaft angle) superimposed on a native glenohumeral joint in 0°, 30°,
                          and 60° of scaption. Muscle fibers are depicted for the deltoid (anterior, middle, and
                          posterior) and infraspinatus, with native shoulder fibers in blue and RTSA shoulder fibers in
                          red, demonstrating similar lengths and line of action in the optimal RTSA configuration
                          compared with the native shoulder.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01123f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=jpeg&name=JBJS.23.01123f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Geometric models of a large shoulder with the
                          optimal RTSA configuration (30-mm glenosphere/centered/10 mm glenoid lateralization/0 mm
                          humeral offset/135° neck-shaft angle) superimposed on a native glenohumeral joint in 0°, 30°,
                          and 60° of scaption. Muscle fibers are depicted for the deltoid (anterior, middle, and
                          posterior) and infraspinatus, with native shoulder fibers in blue and RTSA shoulder fibers in
                          red, demonstrating similar lengths and line of action in the optimal RTSA configuration
                          compared with the native shoulder.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>The RTSA Configuration That Minimizes Change in
                          Muscle-Tendon Lengths from the Native Shoulder in Small, Medium, and Large Shoulders</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 992px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.859008);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Muscle(s)</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Glenosphere_Glenoid Lateralization_Humeral Offset_Neck-Shaft
                          Angle</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Small
                          Shoulder</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Medium
                          Shoulder</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Large
                          Shoulder</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Anterior deltoid</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_10 mm_0 mm
                          (inlay)_135°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_10 mm_0 mm
                          (inlay)_135°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_10 mm_0 mm
                          (inlay)_135°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Middle deltoid</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_10 mm_0 mm
                          (inlay)_135°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_10 mm_0 mm
                          (inlay)_135°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_10 mm_0 mm
                          (inlay)_135°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior deltoid</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_5 mm_0 mm
                          (inlay)_135°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_10 mm_0 mm
                          (inlay)_135°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_10 mm_0 mm
                          (inlay)_135°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Supraspinatus</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_0 mm_0 mm
                          (inlay)_155°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_0 mm_0 mm
                          (inlay)_155°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">36 mm_centered_0 mm_5
                          mm_145°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Subscapularis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">36 mm_centered_5 mm_0 mm
                          (inlay)_135°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_10 mm_0 mm
                          (inlay)_135°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_10 mm_0 mm
                          (inlay)_135°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Infraspinatus</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">36 mm_centered_5 mm_0 mm
                          (inlay)_135°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_10 mm_0 mm
                          (inlay)_135°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">36 mm_centered_10 mm_0 mm
                          (inlay)_135°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Teres minor</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_0 mm_0 mm
                          (inlay)_155°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">42 mm_centered_5 mm_0 mm
                          (inlay)_135°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">36 mm_centered_0 mm_5
                          mm_145°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Overall deltoid and
                          rotator cuff</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_5 mm_0 mm
                          (inlay)_135°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">36 mm_centered_5 mm_0 mm
                          (inlay)_135°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30 mm_centered_10 mm_0 mm
                          (inlay)_135°</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Muscle-Tendon Lengthening</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Knowledge of the surgical and implant factors associated
                  with lengthening of the deltoid and rotator cuff musculature can be used to modify soft-tissue tension
                  intraoperatively. Of the surgical parameters, increasing humeral offset had the strongest association
                  with deltoid lengthening (β = 0.475 to 0.520 [moderate], p &lt; 0.001). Of the implant-design
                  parameters, increasing humeral neck-shaft angle had the strongest association with deltoid lengthening
                  (β = 0.593 to 0.680 [moderate to strong], p &lt; 0.001), while increasing glenoid lateralization had
                  the strongest association with rotator cuff lengthening (β = 0.498 to 0.715 [moderate to strong], p
                  &lt; 0.001) (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  Therefore, if one is seeking to increase deltoid length, a more valgus humeral implant or greater
                  humeral offset should be used, whereas glenoid lateralization will most effectively achieve rotator
                  cuff lengthening.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Multivariable Linear Regression Model Evaluating
                          the Correlations of Surgical and Implant-Design Parameters with Muscle-Tendon Lengthening in a
                          Medium-Sized Shoulder<span data-id="table_footnote_reference_19"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 403.638px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.544702);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="7" style="position: unset;"><span class="text"
                          style="position: unset;">Standardized Beta</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Anterior
                          Deltoid</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Middle
                          Deltoid</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Posterior
                          Deltoid</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Subscapularis</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Supraspinatus</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Infraspinatus</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Teres
                          Minor</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical factors</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Glenosphere position
                          (inferior vs. centered)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.302</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.281</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.300</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.064</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.015</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.063</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.238</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Humeral offset</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.479<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.475<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.520<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.564<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.594<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.582<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.572<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Implant factors</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Glenosphere size</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.288</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.285</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.312</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.338</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.356</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.349</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.343</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Glenoid
                          lateralization</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.400<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.387</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.435<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.594<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.679<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.715<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.498<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Humeral neck-shaft
                          angle</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.659<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.680<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.593<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.457<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.235</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.144</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.500<span
                            data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">All correlations had a p value of &lt;0.001. Correlations were
                        classified as follows: 0-0.19, very weak; 0.2-0.39, weak; 0.4-0.59, moderate, 0.6-0.79, strong;
                        0.8-1, very strong.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Moderate.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Strong.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Restoration
                of Native Muscle-Tendon Lengths</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Knowledge of the surgical and implant factors associated
                  with restoration of native deltoid and rotator cuff muscle-tendon lengths aids in surgical placement
                  and implant selection in order to restore native muscle-tendon lengths and soft-tissue tension. Among
                  the surgical parameters, increasing humeral offset had the strongest correlation with the change in
                  deltoid length (β = 0.458 to 0.514 [moderate], p &lt; 0.001) and rotator cuff length (β = 0.441 to
                  0.535 [moderate], p &lt; 0.001). Of the implant-design parameters, increasing humeral neck-shaft angle
                  had the strongest correlation with the change in deltoid length compared with the native shoulder (β =
                  0.568 to 0.657 [moderate to strong], p &lt; 0.001). Additionally humeral offset (β = 0.441 to 0.535
                  [moderate], p &lt; 0.001), neck-shaft angle (β = 0.335 to 0.457 [weak to moderate], p &lt; 0.001), and
                  glenoid lateralization (β = 0.284 to 0.546 [weak to moderate], p &lt; 0.001) had the strongest
                  correlations with the change in rotator cuff length (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Multivariable Linear Regression Model Evaluating the
                          Correlation of Surgical and Implant-Design Factors with the Absolute Change in Muscle-Tendon
                          Lengthening in a Medium-Sized Shoulder<span data-id="table_footnote_reference_35"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 403.638px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.544702);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="7" style="position: unset;"><span class="text"
                          style="position: unset;">Standardized Beta</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Anterior
                          Deltoid</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Middle
                          Deltoid</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Posterior
                          Deltoid</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Subscapularis</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Supraspinatus</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Infraspinatus</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Teres
                          Minor</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical factors</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Glenosphere position
                          (inferior vs. centered)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.336</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.312</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.339</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.021 (p
                          = 0.635)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.069 (p =
                          0.040)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.070 (p
                          = 0.205)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.210</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Humeral offset</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.470<span
                            data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.458<span
                            data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.514<span
                            data-id="table_footnote_reference_22" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.512<span
                            data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.529<span
                            data-id="table_footnote_reference_24" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.441<span
                            data-id="table_footnote_reference_25" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.535<span
                            data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Implant factors</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Glenosphere size</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.279</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.272</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.306</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.296</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.305</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.254</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.309</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Glenoid
                          lateralization</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.328</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.310</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.389</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.483<span
                            data-id="table_footnote_reference_27" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.546<span
                            data-id="table_footnote_reference_28" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.284</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.456<span
                            data-id="table_footnote_reference_29" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Humeral neck-shaft
                          angle</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.631<span
                            data-id="table_footnote_reference_30" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.657<span
                            data-id="table_footnote_reference_31" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.568<span
                            data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.335</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.386</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.423<span
                            data-id="table_footnote_reference_33" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.457<span
                            data-id="table_footnote_reference_34" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">All correlations were p &lt; 0.001, unless otherwise noted.
                        Correlations were classified as follows: 0-0.19, very weak; 0.2-0.39, weak; 0.4-0.59, moderate,
                        0.6-0.79, strong; 0.8-1, very strong.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Moderate.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Strong.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Furthermore, the proportion of RTSA configurations that
                  restored near-anatomic muscle-tendon lengths (within &lt;5%) was assessed for the deltoid (<a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6</a>) and rotator cuff (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 7</a>). This
                  analysis demonstrated the influence of glenosphere position, humeral offset, and neck-shaft angle on
                  the ability to restore near-anatomic deltoid muscle-tendon length (<a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>). For the middle deltoid, 26% of RTSA configurations involving a
                  prosthesis with a 135° neck-shaft angle achieved a near-anatomic muscle-tendon length, compared with
                  9% of those with a 145° prosthetic neck-shaft angle and 0% of those with a 155° angle (<a href=""
                    data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.01123f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=jpeg&name=JBJS.23.01123f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;">Chart demonstrating the proportion of RTSA
                          configurations in a medium shoulder that achieved near-anatomic muscle-tendon lengths for the
                          anterior, middle, and posterior deltoid, stratified by each surgical and implant-design
                          parameter.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.23.01123f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=jpeg&name=JBJS.23.01123f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;">in a medium shoulder Chart demonstrating the
                          proportion of RTSA configurations that achieved near-anatomic muscle-tendon lengths for each
                          of the rotator cuff muscles, stratified by each surgical and implant-design parameter.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">There was also a significant influence of humeral offset,
                  neck-shaft angle, and glenoid lateralization on restoring near-anatomic rotator cuff lengths (<a
                    href="" data-id="figure_reference_14" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 7</a>). For the subscapularis, 65% of RTSA configurations achieved a
                  near-anatomic muscle-tendon length when humeral offset was 0 mm, compared with 46% when it was 5 mm
                  and 19% when it was 10 mm. For the infraspinatus, 61% of RTSA configurations achieved a near-anatomic
                  muscle-tendon length with a neck-shaft angle of 135°, compared with 41% with 145° and 6% with 155°.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">It is important to note that although particular values of
                  individual parameters may be associated with a greater proportion of configurations that restore
                  anatomic muscle-tendon lengths, the overall configuration must be considered. For example, increasing
                  glenoid lateralization (from 0 to 10 mm) was detrimental to restoring native muscle-tendon lengths of
                  the deltoid and rotator cuff, yet when a lateralized glenoid was combined with a 135° neck-shaft angle
                  and humeral offset of 0 mm, the optimal configuration was achieved.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Operating
                Region for the Middle Deltoid on its Force-Length Curve</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Restoration of native muscle-tendon lengths with the
                  optimal RTSA configuration resulted in an operating region of the middle deltoid that lay in a similar
                  portion of the plateau region of its force-length (Blix) curve compared with the native muscle,
                  whereas the suboptimal RTSA configuration resulted in substantial lengthening of the muscle such that
                  passive tension rose exponentially, and active force production was substantially compromised due to
                  inadequate sarcomere overlap (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 8</a> and <a
                    href="" data-id="figure_reference_16" class="annotation figure_reference resource-reference"
                    style="position: unset;">9</a>). The model of the suboptimal RTSA configuration is illustrated in <a
                    href="" data-id="figure_reference_17" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 10</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 8</div>
                <div class="image-download" name="JBJS.23.01123f8" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=jpeg&name=JBJS.23.01123f8"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;">Normalized muscle-fiber length of the middle
                          deltoid in a medium-sized shoulder throughout 0° to 90° of scapular plane abduction for the
                          native shoulder, the optimal RTSA configuration (36-mm glenosphere/centered/5 mm glenoid
                          lateralization/0 mm humeral offset/135° neck-shaft angle), and the most suboptimal RTSA
                          configuration (42-mm glenosphere/inferior/10 mm glenoid lateralization/10 mm humeral
                          offset/155° neck-shaft angle)—i.e., the configuration that resulted in the largest change in
                          muscle-tendon lengths for the medium shoulder.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 9</div>
                <div class="image-download" name="JBJS.23.01123f9" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=jpeg&name=JBJS.23.01123f9"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;">Operating range on the force-length curve for the
                          middle deltoid in a medium-sized shoulder (minimum to maximum normalized muscle fiber length
                          from 0° to 90° of scaption) for a native shoulder, the optimal RTSA configuration (36-mm
                          glenosphere/centered/5 mm glenoid lateralization/0 mm humeral offset/135° neck-shaft angle),
                          and the most suboptimal RTSA configuration (42-mm glenosphere/inferior/10 mm glenoid
                          lateralization/10 mm humeral offset/155° neck-shaft angle). The dashed line represents the
                          passive force developed by the muscle, and the solid line represents the muscle’s active
                          force-length curve.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 10</div>
                <div class="image-download" name="JBJS.23.01123f10" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=jpeg&name=JBJS.23.01123f10"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_14" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;">Geometric models of a medium shoulder representing
                          the suboptimal RTSA configuration (42 mm/inferior/10 mm glenoid lateralization/10 mm humeral
                          offset/155° neck-shaft angle) superimposed on a native glenohumeral joint in 0°, 30°, and 60°
                          of scaption. Muscle fibers are depicted for the deltoid (anterior, middle, and posterior) and
                          infraspinatus, with native shoulder fibers in blue and RTSA shoulder fibers in red,
                          demonstrating substantially different lengths and lines of action in the most suboptimal RTSA
                          configuration compared with the native shoulder.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Previous studies of RTSA that sought to better understand
                  how these prostheses improve shoulder function have been performed on the premise that the moment arms
                  of the muscles are the most important feature<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_19" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">17</span></a>. Little attention was given to understanding how altering
                  the force-length relationship of a muscle can profoundly affect its ability to produce force, generate
                  torque, and control joint movement. Grammont’s original explanation promoted a valgus humerus that
                  facilitated lengthening of the deltoid to achieve a mechanical advantage in abduction<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">25</span></a>. Unfortunately, substantially altering the shape of the
                  shoulder often resulted in complications. Such complications (notching, loss of external rotational
                  strength) initially were trivialized, but with the expanding indications for RTSA, they have become a
                  primary concern<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">26</span></a>.
                  The alternative line of investigation in this study challenges the prevailing idea that a muscle’s
                  moment arm is the critical quantity to consider, and instead considers how one could optimize shoulder
                  function by restoration of native muscle-tendon lengths.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The functions of the deltoid and rotator cuff muscles in
                  the native shoulder are optimized by virtue of their operating positions on the force-length curve
                  during glenohumeral joint motion<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">28</span></a>. Our study demonstrated that a smaller lateralized
                  glenosphere placed centrally rather than inferiorly on the glenoid, a humeral socket placed at the
                  anatomic neck plane, and an anatomic 135° neck-shaft angle best restore native deltoid and rotator
                  cuff muscle-tendon lengths in RTSA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Although the optimal muscle lengthening in RTSA is
                  unknown, there are theorized benefits of restoring the native position of the humerus in relation to
                  the scapula, with regard to deltoid wrapping and anatomic tensioning of the rotator cuff to improve
                  joint stability, muscle function, and active internal and external rotation<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">24</span></a>. Schmalzl et al.<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">6</span></a>
                  assessed deltoid muscle tension using shear wave elastography and demonstrated increased pain in those
                  with higher muscle tension. Sabesan et al.<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">29</span></a>
                  demonstrated that deltoid lengthening was negatively correlated with postoperative forward elevation.
                  Furthermore, Zitnay et al.<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">30</span></a>
                  assessed deltoid and rotator cuff forces after RTSA using a biorobotic simulator and suggested that
                  inlay humeral components help to restore more anatomic rotator cuff muscle forces during abduction and
                  may promote better long-term outcomes. Consistent with these findings, our previous biomechanical
                  study<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">18</span></a>
                  found that a lateralized glenosphere, 0 mm of humeral offset, and a 135° neck-shaft angle optimized
                  the supraspinatus moment arm for scaption while also optimizing the capacity of the deltoid for force
                  generation by avoiding deltoid overlengthening.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Despite the detrimental effects of deltoid
                  overlengthening, deltoid lengthening may be desired in certain circumstances—for example, to obtain
                  stability in the setting of cuff tear arthropathy. In such cases, an increased humeral neck-shaft
                  angle or humeral offset can be used, as the middle deltoid lengthens 3.4% for every 10° increase in
                  neck-shaft angle and 4.9% for every 10-mm increase in humeral offset. If the surgeon deems the rotator
                  cuff tension inadequate intraoperatively, increased glenoid lateralization or humeral offset can be
                  used, as the subscapularis lengthens 8.4% and the infraspinatus lengthens 8.2% for every 10-mm
                  increase in glenoid lateralization, and the subscapularis lengthens 7.3% and the infraspinatus
                  lengthens 6.7% for every 10-mm increase in humeral offset. Similarly, if the subscapularis is under
                  excess tension during repair, the humeral offset can be decreased by placing a thinner polyethylene
                  liner or countersinking the humeral implant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Individual RTSA parameters cannot be considered
                  independently, as the muscle-tendon lengths are determined by the combined geometry. For example, if a
                  lateralized glenosphere is not used with a varus neck-shaft angle and inset humerus, the
                  lateralization is paradoxically detrimental to restoring native muscle-tendon lengths (<a href=""
                    data-id="figure_reference_18" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 6</a> and <a href="" data-id="figure_reference_19"
                    class="annotation figure_reference resource-reference" style="position: unset;">7</a>).
                  Specifically, glenoid lateralization (from 0 to 10 mm) was found to result in substantial deltoid and
                  rotator cuff lengthening compared with the native lengths in the setting of a 42-mm glenosphere with
                  10 mm of humeral offset and a 145° neck-shaft angle in a medium shoulder. This provides evidence to
                  support that “mixing and matching” of various RTSA design philosophies may yield unexpected (and
                  unintended) alterations in muscle-tendon lengths.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Previous studies assessing muscle lengths after RTSA are
                  limited and heterogeneous<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">14</span></a>. Lädermann et al.<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">13</span></a>
                  performed a computer modeling study assessing 30 RTSA configurations and found that humeral offset
                  increased supraspinatus, infraspinatus, and subscapularis lengths, whereas the subscapularis was
                  shortened, in most combinations. In contrast, our study demonstrated mean subscapularis lengthening in
                  calculations of muscle-tendon lengths performed in multiple joint positions—not just in 0° of
                  abduction as in the prior study. The subscapularis lengthens in RTSA as the shoulder abducts, which
                  explains this finding<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">31</span></a>.
                  Roche et al.<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">14</span></a>
                  used a computer model and reported mean muscle lengths relative to the native shoulder in 9 RTSA
                  combinations. They found that a decreased neck-shaft angle and increased humeral or glenoid
                  lateralization improved deltoid wrapping and anatomic tensioning of the anterior and posterior rotator
                  cuff. However, their study was limited in that mean values were reported across the range of motion,
                  which masks the overall magnitude of the difference in length for those muscles that are shortened and
                  lengthened at different joint angles.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">The current study investigated the impact of surgical and
                  implant-design parameters on muscle-tendon lengths, but it cannot definitively be concluded that
                  restoration of native muscle-tendon lengths will yield better muscle performance after RTSA. There are
                  trade-offs between increasing the moment arms of muscles and changing the geometry of the glenohumeral
                  joint, which alters muscle-tendon lengths, muscle lines of action, and excursion of the muscle-tendon
                  units. Nonetheless, this study is among the first to provide a framework for altering surgical and
                  implant factors to optimize muscle-tendon lengths. Additionally, the current model did not include an
                  estimate of muscle wrapping; however, we do not believe that this limits the validity of our findings
                  because we included 7 muscles, each with unique origin and insertion sites on the scapula and humerus,
                  and compared distances between these attachment sites through multiple planes of motion, which allowed
                  a meaningful comparison of muscle-tendon lengths between the RTSA and native shoulder.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Although the current study did not assess the impact of
                  RTSA design and placement on prosthetic shoulder stability or impingement-free range of motion,
                  glenoid lateralization and a 135° neck-shaft angle have been shown to decrease scapular notching and
                  adduction deficits, and increase impingement-free range of motion<a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">32</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">35</span></a>. Additionally, glenoid lateralization has been shown to
                  mitigate instability in biomechanical and clinical studies<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">32</span></a><span data-id="superscript_33"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">34</span></a>. Furthermore, we propose that restoring anatomic
                  subscapularis tension increases the likelihood that the subscapularis can be repaired, which has been
                  shown to minimize RTSA instability<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">37</span></a>. RTSA carries an increased instability risk in the setting
                  of cuff tear arthropathy<a href="" data-id="citation_reference_55"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">37</span></a>,
                  and the surgeon may therefore choose to increase soft-tissue tension to achieve adequate glenohumeral
                  compressive forces for stability. Ultimately, intraoperative stability testing of trial components
                  must be performed when choosing the prosthesis for a patient, but our results can aid in guiding
                  modification of soft-tissue tension while considering native muscle-tendon lengths.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">Our results demonstrated that the combination of a smaller
                  lateralized glenosphere placed centrally rather than inferiorly on the glenoid, a humeral socket
                  placed at the anatomic neck plane, and an anatomic 135° neck-shaft angle best restores native deltoid
                  and rotator cuff muscle-tendon lengths in RTSA. Additionally, a 135° humeral neck-shaft angle and
                  humeral socket placement at the anatomic neck were the implant and surgical parameters most closely
                  associated with restoration of native muscle-tendon lengths. Importantly, the parameters were highly
                  interdependent, so implant selection and placement should be considered within the context of the
                  complete RTSA configuration.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I19" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I19</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Bacle G</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Nové-Josserand L</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Garaud P</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Walch G</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Long-Term Outcomes of Reverse
                  Total Shoulder Arthroplasty: A Follow-up of a Previous Study</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2017 Mar
                15;99(6):454-61.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1273998"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Bassens D</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Decock T</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Van Tongel A</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> De Wilde L</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Long-term results of the Delta
                  Xtend reverse shoulder prosthesis</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2019 Jun;28(6):1091-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20results%20of%20the%20Delta%20Xtend%20reverse%20shoulder%20prosthesis&as_occt=title&as_sauthors=%20%22D%20Bassens%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Cuff DJ</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Pupello DR</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Santoni BG</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Clark RE</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Frankle MA</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Reverse Shoulder Arthroplasty
                  for the Treatment of Rotator Cuff Deficiency: A Concise Follow-up, at a Minimum of 10 Years, of
                  Previous Reports</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2017 Nov 15;99(22):1895-9.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1483049" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Levin JM</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Bokshan S</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Roche CP</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Zuckerman JD</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Wright T</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Flurin PH</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Klifto CS</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Anakwenze O</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Reverse shoulder arthroplasty
                  with and without baseplate wedge augmentation in the setting of glenoid deformity and rotator cuff
                  deficiency-a multicenter investigation</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2022 Dec;31(12):2488-96.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reverse%20shoulder%20arthroplasty%20with%20and%20without%20baseplate%20wedge%20augmentation%20in%20the%20setting%20of%20glenoid%20deformity%20and%20rotator%20cuff%20deficiency-a%20multicenter%20investigation&as_occt=title&as_sauthors=%20%22JM%20Levin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Bauer S</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Blakeney WG</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Wang AW</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Ernstbrunner L</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Corbaz J</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Werthel JD</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Challenges for Optimization of
                  Reverse Shoulder Arthroplasty Part II: Subacromial Space, Scapular Posture, Moment Arms and Muscle
                  Tensioning</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J
                  Clin Med.</span> 2023 Feb 17;12(4):1616.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Challenges%20for%20Optimization%20of%20Reverse%20Shoulder%20Arthroplasty%20Part%20II%3A%20Subacromial%20Space%2C%20Scapular%20Posture%2C%20Moment%20Arms%20and%20Muscle%20Tensioning&as_occt=title&as_sauthors=%20%22S%20Bauer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Schmalzl J</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Fenwick A</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Reichel T</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Schmitz B</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Jordan M</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Meffert R</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Plumhoff P</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Boehm D</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Gilbert F</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Anterior deltoid muscle tension
                  quantified with shear wave ultrasound elastography correlates with pain level after reverse shoulder
                  arthroplasty</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Eur J Orthop Surg Traumatol.</span> 2022 Feb;32(2):333-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20deltoid%20muscle%20tension%20quantified%20with%20shear%20wave%20ultrasound%20elastography%20correlates%20with%20pain%20level%20after%20reverse%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22J%20Schmalzl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Lädermann A</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Edwards TB</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Walch G</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Arm lengthening after reverse
                  shoulder arthroplasty: a review</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Int Orthop.</span> 2014 May;38(5):991-1000.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arm%20lengthening%20after%20reverse%20shoulder%20arthroplasty%3A%20a%20review&as_occt=title&as_sauthors=%20%22A%20L%C3%A4dermann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Franceschetti E</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> de Sanctis EG</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Ranieri R</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Palumbo A</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Paciotti M</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Franceschi F</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">The role of the subscapularis
                  tendon in a lateralized reverse total shoulder arthroplasty: repair versus nonrepair</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2019
                Nov;43(11):2579-86.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20the%20subscapularis%20tendon%20in%20a%20lateralized%20reverse%20total%20shoulder%20arthroplasty%3A%20repair%20versus%20nonrepair&as_occt=title&as_sauthors=%20%22E%20Franceschetti%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Giles JW</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Langohr GDG</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Johnson JA</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Athwal GS</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Implant Design Variations in
                  Reverse Total Shoulder Arthroplasty Influence the Required Deltoid Force and Resultant Joint
                  Load</span>. <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2015 Nov;473(11):3615-26.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Implant%20Design%20Variations%20in%20Reverse%20Total%20Shoulder%20Arthroplasty%20Influence%20the%20Required%20Deltoid%20Force%20and%20Resultant%20Joint%20Load&as_occt=title&as_sauthors=%20%22JW%20Giles%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Langohr GDG</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Giles JW</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Athwal GS</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Johnson JA</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The effect of glenosphere
                  diameter in reverse shoulder arthroplasty on muscle force, joint load, and range of motion</span>.
                <span data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2015 Jun;24(6):972-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20glenosphere%20diameter%20in%20reverse%20shoulder%20arthroplasty%20on%20muscle%20force%2C%20joint%20load%2C%20and%20range%20of%20motion&as_occt=title&as_sauthors=%20%22GDG%20Langohr%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Pitocchi J</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Plessers K</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Wirix-Speetjens R</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Debeer P</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> van Lenthe GH</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Jonkers I</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Pérez MA</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Vander Sloten J</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Automated muscle elongation
                  measurement during reverse shoulder arthroplasty planning</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2021
                Mar;30(3):561-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Automated%20muscle%20elongation%20measurement%20during%20reverse%20shoulder%20arthroplasty%20planning&as_occt=title&as_sauthors=%20%22J%20Pitocchi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Hamilton MA</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Roche CP</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Diep P</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Flurin PH</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Routman HD</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Effect of prosthesis design on
                  muscle length and moment arms in reverse total shoulder arthroplasty</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Bull Hosp Joint Dis
                  (2013).</span> 2013;71(Suppl 2):S31-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20prosthesis%20design%20on%20muscle%20length%20and%20moment%20arms%20in%20reverse%20total%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22MA%20Hamilton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Lädermann A</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Denard PJ</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Collin P</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Zbinden O</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Chiu JC</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Boileau P</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Olivier F</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Walch G</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Effect of humeral stem and
                  glenosphere designs on range of motion and muscle length in reverse shoulder arthroplasty</span>.
                <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2020
                Mar;44(3):519-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20humeral%20stem%20and%20glenosphere%20designs%20on%20range%20of%20motion%20and%20muscle%20length%20in%20reverse%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22A%20L%C3%A4dermann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Roche CP</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Diep P</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Hamilton M</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Crosby LA</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Flurin PH</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Wright TW</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Zuckerman JD</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Routman HD</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Impact of inferior glenoid
                  tilt, humeral retroversion, bone grafting, and design parameters on muscle length and deltoid wrapping
                  in reverse shoulder arthroplasty</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Bull Hosp Joint Dis (2013).</span> 2013;71(4):284-93.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20inferior%20glenoid%20tilt%2C%20humeral%20retroversion%2C%20bone%20grafting%2C%20and%20design%20parameters%20on%20muscle%20length%20and%20deltoid%20wrapping%20in%20reverse%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22CP%20Roche%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Ackland DC</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Roshan-Zamir S</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Richardson M</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Pandy MG</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Moment arms of the shoulder
                  musculature after reverse total shoulder arthroplasty</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2010
                May;92(5):1221-30.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1093903"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Burnier M</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Hooke A</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Gil J</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Sanchez-Sotelo J</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Elhassan B</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Biomechanical analysis of the
                  subscapularis, infraspinatus and teres minor length and moment arm after reverse shoulder
                  arthroplasty: a cadaveric study</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Semin Arthroplasty.</span> 2022;32(1):45-54.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biomechanical%20analysis%20of%20the%20subscapularis%2C%20infraspinatus%20and%20teres%20minor%20length%20and%20moment%20arm%20after%20reverse%20shoulder%20arthroplasty%3A%20a%20cadaveric%20study&as_occt=title&as_sauthors=%20%22M%20Burnier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Hamilton MA</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Diep P</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Roche C</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Flurin PH</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Wright TW</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Zuckerman JD</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Routman H</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Effect of reverse shoulder
                  design philosophy on muscle moment arms</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">J Orthop Res.</span> 2015
                Apr;33(4):605-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20reverse%20shoulder%20design%20philosophy%20on%20muscle%20moment%20arms&as_occt=title&as_sauthors=%20%22MA%20Hamilton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Levin JM</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Pugliese M</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Gobbi F</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Pandy MG</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Di Giacomo G</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Frankle MA</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Impact of reverse shoulder
                  arthroplasty design and patient shoulder size on moment arms and muscle fiber lengths in shoulder
                  abductors</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2023 Dec;32(12):2550-60.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20reverse%20shoulder%20arthroplasty%20design%20and%20patient%20shoulder%20size%20on%20moment%20arms%20and%20muscle%20fiber%20lengths%20in%20shoulder%20abductors&as_occt=title&as_sauthors=%20%22JM%20Levin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Mayfield CK</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Korber SS</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Hwang NM</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Bolia IK</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Gamradt SC</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Weber AE</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Liu JN</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Petrigliano FA</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Volume, indications, and number
                  of surgeons performing reverse total shoulder arthroplasty continue to expand: a nationwide cohort
                  analysis from 2016-2020</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">JSES Int.</span> 2023 May 25;7(5):827-34.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Volume%2C%20indications%2C%20and%20number%20of%20surgeons%20performing%20reverse%20total%20shoulder%20arthroplasty%20continue%20to%20expand%3A%20a%20nationwide%20cohort%20analysis%20from%202016-2020&as_occt=title&as_sauthors=%20%22CK%20Mayfield%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Elzanie A</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Varacallo M</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Anatomy, Shoulder and Upper
                  Limb, Deltoid Muscle</span>. In: <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">StatPearls.</span> StatPearls Publishing; 2023.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anatomy%2C%20Shoulder%20and%20Upper%20Limb%2C%20Deltoid%20Muscle&as_occt=title&as_sauthors=%20%22A%20Elzanie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Sakoma Y</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Sano H</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Shinozaki N</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Itoigawa Y</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Yamamoto N</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Ozaki T</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Itoi E</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Anatomical and functional
                  segments of the deltoid muscle</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">J Anat.</span> 2011 Feb;218(2):185-90.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anatomical%20and%20functional%20segments%20of%20the%20deltoid%20muscle&as_occt=title&as_sauthors=%20%22Y%20Sakoma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Cabezas AF</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Gutiérrez S</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Teusink MJ</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Schwartz DG</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Hartzler RU</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Santoni BG</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Frankle MA</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Kinematic impact of size on the
                  existing glenohumeral joint in patients undergoing reverse shoulder arthroplasty</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Clin Biomech (Bristol,
                  Avon).</span> 2014 Jun;29(6):622-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Kinematic%20impact%20of%20size%20on%20the%20existing%20glenohumeral%20joint%20in%20patients%20undergoing%20reverse%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22AF%20Cabezas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Virani NA</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Cabezas A</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Gutiérrez S</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Santoni BG</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Otto R</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Frankle M</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Reverse shoulder arthroplasty
                  components and surgical techniques that restore glenohumeral motion</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2013 Feb;22(2):179-87.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reverse%20shoulder%20arthroplasty%20components%20and%20surgical%20techniques%20that%20restore%20glenohumeral%20motion&as_occt=title&as_sauthors=%20%22NA%20Virani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Werthel JD</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Walch G</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Vegehan E</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Deransart P</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Sanchez-Sotelo J</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Valenti P</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Lateralization in reverse
                  shoulder arthroplasty: a descriptive analysis of different implants in current practice
                  [SICOT]</span>. <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Int
                  Orthop.</span> 2019 Oct;43(10):2349-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lateralization%20in%20reverse%20shoulder%20arthroplasty%3A%20a%20descriptive%20analysis%20of%20different%20implants%20in%20current%20practice%20%5BSICOT%5D&as_occt=title&as_sauthors=%20%22JD%20Werthel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Boileau P</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Watkinson DJ</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Hatzidakis AM</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Balg F</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Grammont reverse prosthesis:
                  design, rationale, and biomechanics</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2005 Jan-Feb;14(1)(Suppl
                S):147S-61S.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Grammont%20reverse%20prosthesis%3A%20design%2C%20rationale%2C%20and%20biomechanics&as_occt=title&as_sauthors=%20%22P%20Boileau%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Galvin JW</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Kim R</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Ment A</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Durso J</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Joslin PMN</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Lemos JL</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Novikov D</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Curry EJ</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Alley MC</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Parada SA</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Eichinger JK</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Li X</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Outcomes and complications of
                  primary reverse shoulder arthroplasty with minimum of 2 years’ follow-up: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2022 Nov;31(11):e534-44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20and%20complications%20of%20primary%20reverse%20shoulder%20arthroplasty%20with%20minimum%20of%202%20years%E2%80%99%20follow-up%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22JW%20Galvin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Garner BA</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Pandy MG</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Musculoskeletal model of the
                  upper limb based on the visible human male dataset</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Comput Methods Biomech Biomed Engin.</span> 2001
                Feb;4(2):93-126.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Musculoskeletal%20model%20of%20the%20upper%20limb%20based%20on%20the%20visible%20human%20male%20dataset&as_occt=title&as_sauthors=%20%22BA%20Garner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Garner BA</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Pandy MG</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Estimation of musculotendon
                  properties in the human upper limb</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Ann Biomed Eng.</span> 2003 Feb;31(2):207-20.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Estimation%20of%20musculotendon%20properties%20in%20the%20human%20upper%20limb&as_occt=title&as_sauthors=%20%22BA%20Garner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Sabesan VJ</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Lombardo D</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Josserand D</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Buzas D</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Jelsema T</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Petersen-Fitts GR</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Wiater JM</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">The effect of deltoid
                  lengthening on functional outcome for reverse shoulder arthroplasty</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Musculoskelet Surg.</span>
                2016 Aug;100(2):127-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20deltoid%20lengthening%20on%20functional%20outcome%20for%20reverse%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22VJ%20Sabesan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Zitnay JL</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Tashjian RZ</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Walch G</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Chalmers PN</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Joyce CD</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Henninger HB</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Inlay vs. onlay humeral
                  components in reverse total shoulder arthroplasty: a biorobotic shoulder simulator study</span>. <span
                  data-id="emphasis_30" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2023 Nov 28:S1058-2746(23)00831-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inlay%20vs.%20onlay%20humeral%20components%20in%20reverse%20total%20shoulder%20arthroplasty%3A%20a%20biorobotic%20shoulder%20simulator%20study&as_occt=title&as_sauthors=%20%22JL%20Zitnay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Eno JT</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Kontaxis A</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Novoa-Boldo A</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Windsor E</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Chen X</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Erickson BJ</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Warren RF</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Dines DM</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Dines JS</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Gulotta LV</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Taylor SA</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">The biomechanics of
                  subscapularis repair in reverse shoulder arthroplasty: The effect of lateralization and insertion
                  site</span>. <span data-id="emphasis_31" class="annotation emphasis" style="position: unset;">J Orthop
                  Res.</span> 2020 Apr;38(4):888-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20biomechanics%20of%20subscapularis%20repair%20in%20reverse%20shoulder%20arthroplasty%3A%20The%20effect%20of%20lateralization%20and%20insertion%20site&as_occt=title&as_sauthors=%20%22JT%20Eno%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Guarrella V</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Chelli M</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Domos P</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Ascione F</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Boileau P</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Walch G</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Risk factors for instability
                  after reverse shoulder arthroplasty</span>. <span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">Shoulder Elbow.</span> 2021 Feb;13(1):51-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20instability%20after%20reverse%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22V%20Guarrella%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Gutiérrez S</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Comiskey CA 4th</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Luo ZP</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Pupello DR</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Frankle MA</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Range of impingement-free
                  abduction and adduction deficit after reverse shoulder arthroplasty. Hierarchy of surgical and
                  implant-design-related factors</span>. <span data-id="emphasis_33" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2008 Dec;90(12):2606-15.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1030497" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Gutiérrez S</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Keller TS</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Levy JC</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Lee WE 3rd</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Luo ZP</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Hierarchy of stability factors
                  in reverse shoulder arthroplasty</span>. <span data-id="emphasis_34" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2008 Mar;466(3):670-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hierarchy%20of%20stability%20factors%20in%20reverse%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22S%20Guti%C3%A9rrez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Liu B</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Kim YK</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Nakla A</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Chung MS</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Kwak D</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> McGarry MH</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Lee TQ</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Oh JH</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Biomechanical consequences of
                  glenoid and humeral lateralization in reverse total shoulder arthroplasty</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2023 Aug;32(8):1662-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biomechanical%20consequences%20of%20glenoid%20and%20humeral%20lateralization%20in%20reverse%20total%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22B%20Liu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Olson JJ</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Galetta MD</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Keller RE</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Oh LS</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> O’Donnell EA</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Systematic review of
                  prevalence, risk factors, and management of instability following reverse shoulder
                  arthroplasty</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">JSES Rev Rep Tech.</span> 2022 Mar 30;2(3):261-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Systematic%20review%20of%20prevalence%2C%20risk%20factors%2C%20and%20management%20of%20instability%20following%20reverse%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22JJ%20Olson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;">ASES
                Complications of RSA Research Group; <span data-id="annotation_214" class="annotation"
                  style="position: unset;"> Lohre R</span>, <span data-id="annotation_215" class="annotation"
                  style="position: unset;"> Swanson DP</span>, <span data-id="annotation_216" class="annotation"
                  style="position: unset;"> Mahendraraj KA</span>, <span data-id="annotation_217" class="annotation"
                  style="position: unset;"> Elmallah R</span>, <span data-id="annotation_218" class="annotation"
                  style="position: unset;"> Glass EA</span>, <span data-id="annotation_219" class="annotation"
                  style="position: unset;"> Dunn WR</span>, <span data-id="annotation_220" class="annotation"
                  style="position: unset;"> Cannon DJ</span>, <span data-id="annotation_221" class="annotation"
                  style="position: unset;"> Friedman LGM</span>, <span data-id="annotation_222" class="annotation"
                  style="position: unset;"> Gaudette JA</span>, <span data-id="annotation_223" class="annotation"
                  style="position: unset;"> Green J</span>, <span data-id="annotation_224" class="annotation"
                  style="position: unset;"> Grobaty L</span>, <span data-id="annotation_225" class="annotation"
                  style="position: unset;"> Gutman M</span>, <span data-id="annotation_226" class="annotation"
                  style="position: unset;"> Kakalecik J</span>, <span data-id="annotation_227" class="annotation"
                  style="position: unset;"> Kloby MA</span>, <span data-id="annotation_228" class="annotation"
                  style="position: unset;"> Konrade EN</span>, <span data-id="annotation_229" class="annotation"
                  style="position: unset;"> Knack MC</span>, <span data-id="annotation_230" class="annotation"
                  style="position: unset;"> Loveland A</span>, <span data-id="annotation_231" class="annotation"
                  style="position: unset;"> Mathew JI</span>, <span data-id="annotation_232" class="annotation"
                  style="position: unset;"> Myhre L</span>, <span data-id="annotation_233" class="annotation"
                  style="position: unset;"> Nyfeler J</span>, <span data-id="annotation_234" class="annotation"
                  style="position: unset;"> Parsell DE</span>, <span data-id="annotation_235" class="annotation"
                  style="position: unset;"> Pazik M</span>, <span data-id="annotation_236" class="annotation"
                  style="position: unset;"> Polisetty TS</span>, <span data-id="annotation_237" class="annotation"
                  style="position: unset;"> Ponnuru P</span>, <span data-id="annotation_238" class="annotation"
                  style="position: unset;"> Smith KM</span>, <span data-id="annotation_239" class="annotation"
                  style="position: unset;"> Sprengel KA</span>, <span data-id="annotation_240" class="annotation"
                  style="position: unset;"> Thakar O</span>, <span data-id="annotation_241" class="annotation"
                  style="position: unset;"> Turnbull L</span>, <span data-id="annotation_242" class="annotation"
                  style="position: unset;"> Vaughan A</span>, <span data-id="annotation_243" class="annotation"
                  style="position: unset;"> Wheelwright JC</span>, <span data-id="annotation_244" class="annotation"
                  style="position: unset;"> Abboud J</span>, <span data-id="annotation_245" class="annotation"
                  style="position: unset;"> Armstrong A</span>, <span data-id="annotation_246" class="annotation"
                  style="position: unset;"> Austin L</span>, <span data-id="annotation_247" class="annotation"
                  style="position: unset;"> Brolin T</span>, <span data-id="annotation_248" class="annotation"
                  style="position: unset;"> Entezari V</span>, <span data-id="annotation_249" class="annotation"
                  style="position: unset;"> Garrigues GE</span>, <span data-id="annotation_250" class="annotation"
                  style="position: unset;"> Grawe B</span>, <span data-id="annotation_251" class="annotation"
                  style="position: unset;"> Gulotta LV</span>, <span data-id="annotation_252" class="annotation"
                  style="position: unset;"> Hobgood R</span>, <span data-id="annotation_253" class="annotation"
                  style="position: unset;"> Horneff JG</span>, <span data-id="annotation_254" class="annotation"
                  style="position: unset;"> Iannotti J</span>, <span data-id="annotation_255" class="annotation"
                  style="position: unset;"> Khazzam M</span>, <span data-id="annotation_256" class="annotation"
                  style="position: unset;"> King JJ</span>, <span data-id="annotation_257" class="annotation"
                  style="position: unset;"> Kirsch JM</span>, <span data-id="annotation_258" class="annotation"
                  style="position: unset;"> Levy JC</span>, <span data-id="annotation_259" class="annotation"
                  style="position: unset;"> Murthi A</span>, <span data-id="annotation_260" class="annotation"
                  style="position: unset;"> Namdari S</span>, <span data-id="annotation_261" class="annotation"
                  style="position: unset;"> Nicholson GP</span>, <span data-id="annotation_262" class="annotation"
                  style="position: unset;"> Otto RJ</span>, <span data-id="annotation_263" class="annotation"
                  style="position: unset;"> Ricchetti ET</span>, <span data-id="annotation_264" class="annotation"
                  style="position: unset;"> Tashjian R</span>, <span data-id="annotation_265" class="annotation"
                  style="position: unset;"> Throckmorton T</span>, <span data-id="annotation_266" class="annotation"
                  style="position: unset;"> Wright T</span>, <span data-id="annotation_267" class="annotation"
                  style="position: unset;"> Jawa A</span>. <span data-id="strong_37" class="annotation strong"
                  style="position: unset;">Predictors of dislocations after reverse shoulder arthroplasty: a study by
                  the ASES Complications of RSA Multicenter Research Group</span>. <span data-id="emphasis_37"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2024
                Jan;33(1):73-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictors%20of%20dislocations%20after%20reverse%20shoulder%20arthroplasty%3A%20a%20study%20by%20the%20ASES%20Complications%20of%20RSA%20Multicenter%20Research%20Group&as_occt=title&as_sauthors=%20%22R%20Lohre%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;5400f646-1057-48c7-801b-1efe9735adc7&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=supplement&name=10450"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D5400f646-1057-48c7-801b-1efe9735adc7%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): 1493</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01123</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 16, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5400f646-1057-48c7-801b-1efe9735adc7&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=pdf&name=JBJS.23.01123.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=pdf&name=JBJS.23.01123.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_44" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I18"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I18</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5400f646-1057-48c7-801b-1efe9735adc7&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=5400f646-1057-48c7-801b-1efe9735adc7&type=zip&name=JBJS.23.01123.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jay M. Levin, MD, MBA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0949-9269" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0949-9269</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Fabrizio Gobbi, MS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0006-9620-4703" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0006-9620-4703</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Marcus G. Pandy, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Giovanni Di Giacomo, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5682-8331" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5682-8331</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mark A. Frankle, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:mfrankle@floridaortho.com" class=""
                    style="position: unset;">mfrankle@floridaortho.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6427-0665" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6427-0665</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Duke University Medical Center, Durham,
                North Carolina</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Concordia Hospital, Rome, Italy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Mechanical Engineering, University of Melbourne, Parkville,
                Victoria, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Shoulder and Elbow Specialty, Florida Orthopaedic Institute, Tampa,
                Florida</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 34948.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
