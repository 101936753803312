import type { Article } from "../../../../../types";

export const Article584: Article = {
	id: 584,
	rsuiteId: "d8052f61-c357-400f-94e3-1d71f332a7fd",
	type: "the orthopaedic forum",
	title:
		"Challenging the Concept of Statistical Fragility: Is There Any Value Added?",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/584.jpeg",
	subSpecialties: ["sports_medicine"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Challenging the Concept of Statistical Fragility: Is
                There Any Value Added?</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Kevin A. Hao,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Terrie
                      Vasilopoulos, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 2 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract:</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Today, well-designed randomized clinical trials
                        (RCTs) are considered the pinnacle of clinical research, and they inform many practices in
                        orthopaedics. When designing these studies, researchers conduct a power analysis, which allows
                        researchers to strike a balance between (1) enrolling enough patients to detect a clinically
                        important treatment effect (i.e., researchers can be confident that the effect is unlikely due
                        to chance) and (2) cost, time, and risk to patients, which come with enrolling an excessive
                        number of patients. Because researchers will have a desire to conduct resource-efficient RCTs
                        and protect patients from harm, many studies report a p value that is close to the threshold for
                        significance. The concept of the fragility index (FI) was introduced as a simple way to
                        interpret RCT findings, but it does not account for RCT design. The adoption of the FI conflicts
                        with researchers’ goals of designing efficient RCTs that conserve resources and limit
                        ineffective or harmful treatments to patients. The use of the FI may reflect many clinicians’
                        lack of familiarity with interpreting p values beyond “significant” or “nonsignificant.” Instead
                        of inventing new metrics to convey the same information provided by the p value, greater
                        emphasis should be placed on educating clinicians on how to interpret p values and, more
                        broadly, statistics, when reading scientific studies.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Modern orthopaedic research has evolved from humble
                  beginnings, with physician-scientists reporting single-surgeon case series comprised of a handful of
                  patients with no quantitative statistical testing. Today, large single- and multi-institution registry
                  studies and randomized clinical trials (RCTs) with rigorous statistical analysis are commonplace. The
                  increased rigor that is expected of clinical research in order to be considered for publication has
                  consequently advanced evidence-based orthopaedics. However, surgeons now must become educated
                  consumers of statistics if they hope to stay up to date with the latest advancements in implant design
                  and surgical techniques.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Undoubtedly, the addition of statistics to clinical
                  research has contributed greatly to improving the quality of the literature; however, it is not
                  without limitations. One important limitation is the common use of the null-hypothesis testing
                  framework, which necessitates that researchers set an arbitrary threshold for determining significance
                  in order to reject the null hypothesis. In null hypothesis testing, p values are used in statistical
                  tests to determine significance by comparing their value to an a priori cutoff for an acceptable
                  false-positive rate (i.e., type-I error). Most commonly, the acceptable false-positive rate is set to
                  0.05, or 5%, meaning that significance is deemed present for p values of &lt;0.05, reflecting a &lt;5%
                  chance that an observed effect is due to chance. Thus, hypothesis tests that return a p value of ≥0.05
                  are considered nonsignificant, and the researcher cannot, in good faith, conclude definitely that an
                  effect was present. While use of this metric has provided a consistent criterion to help standardize
                  how results of scientific studies are communicated, the binary determination of significant versus
                  nonsignificant findings has also inevitably led to the occasional errant conclusion (i.e., type-I and
                  II errors). Additionally, declaring an effect as either “significant” or “nonsignificant” somewhat
                  fails to acknowledge that there always exists some degree of uncertainty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Today, well-designed RCTs are considered the pinnacle of
                  clinical research, and they inform many practices in orthopaedics. However, relative to the body of
                  scientific works that are published each month, few RCTs are performed, and they compose only a small
                  fraction of the orthopaedic literature. This is in part due to expense, time, institutional
                  requirements, and patient willingness to be randomized to different interventions<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Therefore, a critical aspect of conducting an RCT is the
                  power analysis, which helps researchers determine the minimum number of subjects that must be enrolled
                  such that significance will be achieved if a minimum effect size is present. The effect size is chosen
                  to reflect the minimum difference between treatment groups that, if present, is sufficiently large to
                  merit a change in clinical practice. Conducting a power analysis allows researchers to strike a
                  balance between (1) enrolling enough patients to detect a clinically important treatment effect (i.e.,
                  researchers can be confident that the effect is unlikely due to chance) and (2) cost, time, and risk
                  to patients, which come with enrolling an excessive number of patients. Because researchers will have
                  a desire to conduct resource-efficient RCTs and protect patients from harm, many studies report a p
                  value that is close to the threshold for significance.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">The concept of statistical fragility was first described
                  by Walsh et al.<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>; it
                  refers to the observation that many significant results in RCTs are based on a difference of only a
                  few events between the experimental and control groups. To quantify the statistical fragility of RCTs,
                  the authors proposed the fragility index (FI), which corresponds to the number of events that are
                  required to change a significant result to a nonsignificant result, focusing on the binary
                  determination of significance that is based on a p value of either ≥0.05 or &lt;0.05. The reverse FI
                  has also been described<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>; it
                  corresponds to the number of events that are required to change a nonsignificant result to a
                  significant result. Since the conception of statistical fragility as a limitation to RCTs, it has seen
                  widespread adoption, and is seen throughout the orthopaedic surgery literature<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">9</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Despite the attention that the FI has received, it is
                  fundamentally nothing more than a restatement of the p value. To illustrate this, we present a
                  hypothetical RCT comparing the satisfaction rates of interventions A and B. Given equal patient
                  randomization and taking a clinically meaningful difference in the satisfaction rate as 20%, the
                  calculated effect size is 0.436 and the required total sample size to achieve 95% power with an α of
                  0.05 is 104 patients. Given these parameters, a chi-square test (without Yates correction) comparing
                  intervention A (69% [36 of 52] achieving satisfaction) and intervention B (50% [26 of 52] achieving
                  satisfaction) yields a p value of 0.046. In this example, the FI would be 1 because a single patient
                  not achieving a satisfactory result in group A would yield a p value of 0.073. Using the same rates of
                  satisfaction achievement (69% in group A and 50% in group B), expanding the total sample size to 280
                  (satisfaction achieved by 97 of 140 patients in group A and 70 of 140 in group B) would yield a p
                  value of 0.001, with a calculated FI of 11. When the sample size is increased from 104 to 280, the
                  efficacy (i.e., the treatment effect size) of the intervention is unchanged; however, both the p value
                  and the FI become more favorable.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Like the p value, the FI provides a quantification of the
                  level of uncertainty that an effect size is due to chance. In fact, a simulation study demonstrated
                  that the FI linearly correlates with the p value near perfectly (R &gt; 0.9)<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">10</span></a>. To reduce the level of uncertainty (and thereby decrease
                  the p value and increase the FI), researchers merely need to increase the number of subjects that are
                  enrolled in their study; however, this results in increased time and cost, as well as randomization of
                  patients to a possibly less-effective or even harmful intervention. Although the FI restates the
                  uncertainty of an association in terms that may be more easily understood by some readers, it provides
                  no new information to the reader that is not already present, and it may actually mislead the reader
                  as it fails to provide information regarding underlying statistical uncertainty. Consider the
                  aforementioned hypothetical RCT with enrollment of 104 patients as determined by the power analysis.
                  Reporting an FI of 1 would cast doubt on the results of the trial without quantifying the degree of
                  uncertainty, given the binary determination of significance that underlies the FI. In contrast, if
                  only p values are reported, the discerning reader will understand that a p value of 0.046 versus 0.073
                  in a study in which a single patient in the intervention group failed to achieve a satisfactory result
                  would indicate only an additional 2.7% chance that the differences observed between the groups arose
                  from random chance rather than from a real treatment effect. We are not the first to point out that
                  the FI adds no value beyond the information already reported with standard CONSORT (Consolidated
                  Standards of Reporting Trials) guidelines (i.e., a measure of central tendency such as the median or
                  mean, a measure of variance such as the standard deviation or 95% confidence interval, and a p
                  value)<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">16</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Despite the lack of added value, the popularity of the FI
                  begs the question: Why has it gained so much popularity in scientific journals and at academic
                  meetings? One explanation is that the introduction and subsequent popularization of the FI are a
                  reflection on clinicians failing to understand the nuances of p values. This is not the first time
                  that use of a new statistic that fails to add any real value has gained popularity among clinicians.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Our criticism of statistical fragility parallels the
                  criticisms of post hoc power analyses, which are often performed when authors who fail to obtain a
                  significant result wish to implicate a lack of power rather than the absence of a true effect. Authors
                  use this type of analysis to give merit to their claim that the hypothesized effect actually exists,
                  but an insufficient sample size prohibited their study from demonstrating it statistically. However,
                  post hoc power analyses are entirely determined by the p value; in fact, the observed power derived
                  from a post hoc power analysis is a 1:1 function of the p value<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">17</span></a>. Although many authors have argued against the use of post
                  hoc power analyses<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">18</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">19</span></a>, these analyses continue to be employed by authors and be
                  requested by journal editors. Both the FI and results from post hoc power analyses are a direct
                  function of the p value and study sample size, and neither provide additional useful information to
                  readers.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">We would have no qualms if the only consequence of the FI
                  was clinicians gaining another tool to evaluate clinical research; however, this is not the case. In
                  fact, we contend that the application of the FI to demerit RCTs may either demoralize researchers from
                  conducting RCTs or encourage excessive patient enrollment and randomization, potentially resulting in
                  more patients undergoing ineffective or harmful interventions. RCTs are designed to require the fewest
                  patients necessary to detect the minimum clinically important difference between study groups, with
                  the goal of conserving resources and minimizing potential harm to patients<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">15</span></a>. Therefore, it should not be surprising and, indeed, should
                  rather be expected, that many RCTs demonstrate statistical fragility. The statistical fragility of an
                  RCT can intuitively be reduced by increasing the sample size at the expense of increased cost,
                  increased resource utilization, and a delay in disseminating practice-changing findings. In order to
                  achieve a more favorable FI, an RCT can enroll many more patients than are necessary to accept or
                  reject the null hypothesis according to the a priori power analysis, but this practice would be
                  inefficient and result in some patients receiving an ineffective or even harmful treatment. The
                  balance between study power and resource utilization is a critical aspect of study design in every
                  RCT.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">In summary, we contend that the FI does not provide
                  surgeons with novel insight into interpreting RCTs. In fact, the adoption of the FI conflicts with
                  researchers’ goals of designing efficient RCTs that conserve resources and limit ineffective or
                  harmful treatments to patients. Evaluation of RCTs should rely on an understanding of study design and
                  analysis, effect sizes, confidence intervals, and study limitations. Rather than evaluating the
                  statistical fragility of individual RCTs, evidence-based decision-making should be based on
                  meta-analyses of multiple RCTs with similar methodologies and inclusion criteria that have been
                  conducted by independent groups of investigators in different patient populations. More broadly, the
                  introduction of the FI may reflect many clinicians’ lack of familiarity with interpreting p values
                  beyond “significant” or “nonsignificant.” Instead of inventing new metrics to convey the same
                  information provided by the p value, greater emphasis should be placed on educating clinicians on how
                  to interpret p values and, more broadly, statistics, when reading scientific studies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Fries JF</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Krishnan E</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Equipoise, design bias, and
                  randomized controlled trials: the elusive ethics of new drug development</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Arthritis Res Ther.</span>
                2004;6(3):R250-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Equipoise%2C%20design%20bias%2C%20and%20randomized%20controlled%20trials%3A%20the%20elusive%20ethics%20of%20new%20drug%20development&as_occt=title&as_sauthors=%20%22JF%20Fries%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Walsh M</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Srinathan SK</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> McAuley DF</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Mrkobrada M</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Levine O</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Ribic C</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Molnar AO</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Dattani ND</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Burke A</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Guyatt G</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Thabane L</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Walter SD</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Pogue J</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Devereaux PJ</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The statistical significance of
                  randomized controlled trial results is frequently fragile: a case for a Fragility Index</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J Clin Epidemiol.</span>
                2014 Jun;67(6):622-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20statistical%20significance%20of%20randomized%20controlled%20trial%20results%20is%20frequently%20fragile%3A%20a%20case%20for%20a%20Fragility%20Index&as_occt=title&as_sauthors=%20%22M%20Walsh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Khan MS</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Fonarow GC</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Friede T</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Lateef N</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Khan SU</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Anker SD</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Harrell FE Jr</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Butler J</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Application of the Reverse
                  Fragility Index to Statistically Nonsignificant Randomized Clinical Trial Results</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2020
                Aug 3;3(8):e2012469.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Application%20of%20the%20Reverse%20Fragility%20Index%20to%20Statistically%20Nonsignificant%20Randomized%20Clinical%20Trial%20Results&as_occt=title&as_sauthors=%20%22MS%20Khan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Davey MS</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Hurley ET</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Doyle TR</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Dashti H</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Gaafar M</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Mullett H</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The Fragility Index of
                  Statistically Significant Findings From Randomized Controlled Trials Comparing the Management
                  Strategies of Anterior Shoulder Instability</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2023
                Jul;51(8):2186-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Fragility%20Index%20of%20Statistically%20Significant%20Findings%20From%20Randomized%20Controlled%20Trials%20Comparing%20the%20Management%20Strategies%20of%20Anterior%20Shoulder%20Instability&as_occt=title&as_sauthors=%20%22MS%20Davey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Ruzbarsky JJ</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Khormaee S</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Rauck RC</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Warren RF</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Fragility of randomized clinical
                  trials of treatment of clavicular fractures</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2019
                Mar;28(3):415-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fragility%20of%20randomized%20clinical%20trials%20of%20treatment%20of%20clavicular%20fractures&as_occt=title&as_sauthors=%20%22JJ%20Ruzbarsky%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Ruzbarsky JJ</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Rauck RC</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Manzi J</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Khormaee S</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Jivanelli B</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Warren RF</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The fragility of findings of
                  randomized controlled trials in shoulder and elbow surgery</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2019
                Dec;28(12):2409-17.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20fragility%20of%20findings%20of%20randomized%20controlled%20trials%20in%20shoulder%20and%20elbow%20surgery&as_occt=title&as_sauthors=%20%22JJ%20Ruzbarsky%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> McCormick KL</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Tedesco LJ</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Swindell HW</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Forrester LA</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Jobin CM</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Levine WN</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Statistical fragility of
                  randomized clinical trials in shoulder arthroplasty</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2021
                Aug;30(8):1787-93.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Statistical%20fragility%20of%20randomized%20clinical%20trials%20in%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22KL%20McCormick%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Parisien RL</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Trofa DP</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Cronin PK</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Dashe J</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Curry EJ</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Eichinger JK</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Levine WN</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Tornetta P 3rd</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Li X</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Comparative Studies in the
                  Shoulder Literature Lack Statistical Robustness: A Fragility Analysis</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Arthrosc Sports Med
                  Rehabil.</span> 2021 Oct 12;3(6):e1899-904.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparative%20Studies%20in%20the%20Shoulder%20Literature%20Lack%20Statistical%20Robustness%3A%20A%20Fragility%20Analysis&as_occt=title&as_sauthors=%20%22RL%20Parisien%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Sudah SY</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Moverman MA</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Masood R</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Mojica ES</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Pagani NR</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Puzzitiello RN</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Menendez ME</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Salzler MJ</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The Majority of Sports Medicine
                  and Arthroscopy-Related Randomized Controlled Trials Reporting Nonsignificant Results Are
                  Statistically Fragile</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2023 Sep;39(9):2071-2083.e1.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Majority%20of%20Sports%20Medicine%20and%20Arthroscopy-Related%20Randomized%20Controlled%20Trials%20Reporting%20Nonsignificant%20Results%20Are%20Statistically%20Fragile&as_occt=title&as_sauthors=%20%22SY%20Sudah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Carter RE</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> McKie PM</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Storlie CB</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The Fragility Index: a P-value
                  in sheep’s clothing?</span><span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Eur Heart J.</span> 2017 Feb 1;38(5):346-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Fragility%20Index%3A%20a%20P-value%20in%20sheep%E2%80%99s%20clothing%3F&as_occt=title&as_sauthors=%20%22RE%20Carter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Schröder A</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Muensterer OJ</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Oetzmann von Sochaczewski
                  C</span>. <span data-id="strong_11" class="annotation strong" style="position: unset;">Meta-analyses
                  in paediatric surgery are often fragile: implications and consequences</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Pediatr Surg Int.</span>
                2021 Mar;37(3):363-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Meta-analyses%20in%20paediatric%20surgery%20are%20often%20fragile%3A%20implications%20and%20consequences&as_occt=title&as_sauthors=%20%22A%20Schr%C3%B6der%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Porco TC</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Lietman TM</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">A Fragility Index: Handle with
                  Care</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Ophthalmology.</span> 2018 May;125(5):649.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Fragility%20Index%3A%20Handle%20with%20Care&as_occt=title&as_sauthors=%20%22TC%20Porco%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Schröder A</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Muensterer OJ</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Oetzmann von Sochaczewski
                  C</span>. <span data-id="strong_13" class="annotation strong" style="position: unset;">Paediatric
                  surgical trials, their fragility index, and why to avoid using it to evaluate results</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Pediatr Surg Int.</span>
                2022 Jul;38(7):1057-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Paediatric%20surgical%20trials%2C%20their%20fragility%20index%2C%20and%20why%20to%20avoid%20using%20it%20to%20evaluate%20results&as_occt=title&as_sauthors=%20%22A%20Schr%C3%B6der%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Niforatos JD</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Zheutlin AR</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Chaitoff A</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Pescatore RM</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">The fragility index of practice
                  changing clinical trials is low and highly correlated with P-values</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Clin Epidemiol.</span>
                2020 Mar;119:140-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20fragility%20index%20of%20practice%20changing%20clinical%20trials%20is%20low%20and%20highly%20correlated%20with%20P-values&as_occt=title&as_sauthors=%20%22JD%20Niforatos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Acuna SA</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Sue-Chue-Lam C</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Dossa F</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">The Fragility Index-P Values
                  Reimagined, Flaws and All</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">JAMA Surg.</span> 2019 Jul 1;154(7):674.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Fragility%20Index-P%20Values%20Reimagined%2C%20Flaws%20and%20All&as_occt=title&as_sauthors=%20%22SA%20Acuna%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Condon TM</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Sexton RW</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Wells AJ</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> To MS</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The weakness of fragility index
                  exposed in an analysis of the traumatic brain injury management guidelines: A meta-epidemiological and
                  simulation study</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">PLoS One.</span> 2020 Aug 18;15(8):e0237879.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20weakness%20of%20fragility%20index%20exposed%20in%20an%20analysis%20of%20the%20traumatic%20brain%20injury%20management%20guidelines%3A%20A%20meta-epidemiological%20and%20simulation%20study&as_occt=title&as_sauthors=%20%22TM%20Condon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Hoenig JM</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Heisey DM</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">The Abuse of Power: The
                  Pervasive Fallacy of Power Calculations for Data Analysis</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Am Stat.</span> 2001;55(1):19-24.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Abuse%20of%20Power%3A%20The%20Pervasive%20Fallacy%20of%20Power%20Calculations%20for%20Data%20Analysis&as_occt=title&as_sauthors=%20%22JM%20Hoenig%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Althouse AD</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Post Hoc Power: Not Empowering,
                  Just Misleading</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">J Surg Res.</span> 2021 Mar;259:A3-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Post%20Hoc%20Power%3A%20Not%20Empowering%2C%20Just%20Misleading&as_occt=title&as_sauthors=%20%22AD%20Althouse%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Hao KA</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Calfee RP</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Waljee JF</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Srinivasan RC</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Study Design and Analysis in
                  Hand Surgery Research: Tips for Success</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Hand Surg Am.</span> 2023
                Sep;48(9):941-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Study%20Design%20and%20Analysis%20in%20Hand%20Surgery%20Research%3A%20Tips%20for%20Success&as_occt=title&as_sauthors=%20%22KA%20Hao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dd8052f61-c357-400f-94e3-1d71f332a7fd%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2172</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00368</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 20, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_20"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedic Surgery and Sports Medicine, University of Florida, Gainesville,
                            Florida</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;d8052f61-c357-400f-94e3-1d71f332a7fd&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=d8052f61-c357-400f-94e3-1d71f332a7fd&type=pdf&name=JBJS.24.00368.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=d8052f61-c357-400f-94e3-1d71f332a7fd&type=pdf&name=JBJS.24.00368.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_4" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_20"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_21" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I77"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I77</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;d8052f61-c357-400f-94e3-1d71f332a7fd&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=d8052f61-c357-400f-94e3-1d71f332a7fd&type=zip&name=JBJS.24.00368.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kevin A. Hao, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Terrie Vasilopoulos, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jonathan O. Wright, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Bradley S. Schoch, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Schoch.bradley@mayo.edu" class=""
                    style="position: unset;">Schoch.bradley@mayo.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9355-5069" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9355-5069</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery and Sports Medicine, University of Florida,
                Gainesville, Florida</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Anesthesiology, University of Florida, Gainesville,
                Florida</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Mayo Clinic, Jacksonville, Florida</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 12648.2px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
