import type { Article } from "../../../../../types";

export const Article581: Article = {
	id: 581,
	rsuiteId: "75fc1e69-d51c-4f13-b6d0-c6d514460035",
	type: "evidence-based orthopaedics",
	title:
		"In Treatment-Naïve Dupuytren Contracture, Surgery Had a Higher Success Rate at 2 Years Than Needle Fasciotomy or Collagenase Injection",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/581.jpeg",
	subSpecialties: ["hand_wrist"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">In Treatment-Naïve Dupuytren Contracture, Surgery Had a
                Higher Success Rate at 2 Years Than Needle Fasciotomy or Collagenase Injection</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Stephen
                      McCollam, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Räisänen MP, Leppänen OV, Soikkeli J, Reito A, Malmivaara
                  A, Buchbinder R, Kautiainen H, Kaivorinne A, Stjernberg-Salmela S, Lappalainen M, Luokkala T, Pönkkö
                  A, Taskinen HS, Pääkkönen M, Jaatinen K, Juurakko J, Karjalainen V-L, Karjalainen T. Surgery, needle
                  fasciotomy, or collagenase injection for Dupuytren contracture: a randomized controlled trial. Ann
                  Intern Med. 2024 Mar;177:280-90.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_3" class="annotation strong"
                    style="position: unset;">Question:</span> In patients with treatment-naïve Dupuytren contracture,
                  what are the relative success rates of surgery, needle fasciotomy, and collagenase injection at 3
                  months and 2 years?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_4" class="annotation strong"
                    style="position: unset;">Design:</span> Multicenter, randomized (allocation concealed), blinded
                  (outcome assessors, analysts, writing committee), controlled trial with 2 years of follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_5" class="annotation strong"
                    style="position: unset;">Setting:</span> 6 national secondary and tertiary hospitals in Finland.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_6" class="annotation strong"
                    style="position: unset;">Patients:</span> 302 adults (mean age, 65 years; 81% men; 69% had a single
                  finger contracture) who had treatment-naïve Dupuytren contracture with ≥20° combined extension deficit
                  in the metacarpophalangeal (MCP) joint and/or the proximal interphalangeal (PIP) joint in finger(s) 2
                  to 5 and a palpable cord. Exclusion criteria included neurologic condition affecting finger function,
                  rheumatoid arthritis, previous fracture in the finger affecting range of motion, contracture &gt;135°,
                  contraindication to collagen, or age &gt;80 years. 97% had outcomes measured at 3 months and 94% at 2
                  years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_7" class="annotation strong"
                    style="position: unset;">Intervention:</span> Patients were allocated to surgery (n = 101), needle
                  fasciotomy (n = 101), or collagenase injection (n = 100). All procedures were done by board-certified
                  hand surgery specialists. Patients in the surgery group were offered surgery if reintervention was
                  required; patients in the fasciotomy and collagenase groups without success after 3 months could
                  select 1 of the percutaneous treatments or surgery for reintervention.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_8" class="annotation strong"
                    style="position: unset;">Main outcome measures:</span> The primary outcome was success rate (≥50%
                  contracture release in all initially treated fingers and a patient-acceptable symptom state) at 2
                  years after recruitment. Secondary outcomes included retreatment of the same finger.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_9" class="annotation strong"
                    style="position: unset;">Main results:</span> Results for success rates at 2 years are in <a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>; groups did not differ at 3 months. The surgery group had a
                  lower risk of retreatment of the same finger at 2 years versus the needle fasciotomy group and the
                  collagenase group (hazard ratio for each, 0.09; 95% confidence interval [CI], 0.01 to 0.73). 3 serious
                  adverse events occurred: 2 in the surgery group (arterial injury and digital nerve injury) and 1 in
                  the fasciotomy group (flexor tendon rupture).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Surgery versus needle fasciotomy versus collagenase
                          injections for Dupuytren contracture (intention-to-treat analysis)<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 307px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Comparisons</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Success<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> rates at 2
                          years</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Adjusted
                          risk difference (95% CI) at 2 years</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgery vs. needle
                          fasciotomy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78% vs.
                          50%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30% (17%
                          to 43%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgery vs.
                          collagenase</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78% vs.
                          65%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13% (1% to
                          26%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Collagenase vs. needle
                          fasciotomy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65% vs.
                          50%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17% (3% to
                          30%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CI = confidence interval.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">≥50% contracture release in all initially treated fingers and a
                        patient-acceptable symptom state (yes response to “Would you be satisfied with this outcome and
                        not want to have further treatment if the functional impairment caused by the contracture would
                        remain the same as it is today?”).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_10" class="annotation strong"
                    style="position: unset;">Conclusion:</span> In patients with treatment-naïve Dupuytren contracture,
                  surgery had higher success rates than did needle fasciotomy or collagenase injection at 2 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Sources of funding: Research Council of Finland; Finnish
                  Medical Foundation; Finnish Society of Surgery of the Hand; Tampere University Hospital Foundation;
                  The Finnish State Grants Funding; Vappu Uuspää Foundation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_2" class="annotation emphasis"
                    style="position: unset;">For correspondence: Dr. Teemu Karjalainen, Department of Hand Surgery and
                    Microsurgery, Tampere University, Tampere, Finland. E-mail address: <a
                      href="mailto:teemu.karjalainen@hyvaks.fi" target="_blank" data-id="link_2" class="link"
                      style="position: unset;">teemu.karjalainen@hyvaks.fi</a></span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Dupuytren disease is an inherited condition for which the
                  genetics are incompletely understood. Although any digit can be affected, the ring and small fingers
                  are most often involved. It is important to reassure patients that only 20% of those with an early
                  finding of Dupuytren disease progress to digital contracture requiring active treatment<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. We cannot predict who will be most likely to progress with
                  any precision, but a strong family history of aggressive disease and/or manifestation at a young age
                  (&lt;40 years) are thought to be risk factors for progression to digital contractures<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">1</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The first line of treatment is reassurance and observation
                  if the patient has no digital contractures. The cam design of the collateral ligaments of the MCP
                  joints allows them to tolerate flexion contractures for prolonged periods of time while still allowing
                  for full correction. The PIP joints are not “privileged” in the same manner and require early
                  intervention to correct any contracture.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Surgical correction of Dupuytren contracture has been a
                  longstanding option, with partial palmar fasciectomy being the most common procedure. Minimally
                  invasive procedures became available with the advent of needle fasciotomy in 1972 and U.S. Food and
                  Drug Administration approval of collagenase injections in 2013. The randomized controlled trial by
                  Räisänen and colleagues is the first to compare all 3 of these treatments. The well-designed,
                  multicenter trial was adequately powered, had blinded outcome assessment, and had 94% follow-up at 2
                  years. Their findings that surgery had higher success rates than needle fasciotomy or collagenase
                  injections at 2 years confirm the consensus opinion of the hand surgeon community. They also provide
                  insight into needle fasciotomy and collagenase injection recurrence rates and best options for
                  retreatment of recurrent contractures. Trial patients will be assessed at 5 years (primary end point)
                  and 10 years; the results at these time points will hopefully provide further insights to inform
                  physician and patient decision-making.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Two further observations were noted. First, of the 2
                  noninvasive treatments, collagenase injections had higher success rates than needle fasciotomy at 2
                  years but are far more expensive. Second, risk of retreatment of the same finger was lower with
                  surgery than with needle fasciotomy or collagenase injections.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The authors are to be congratulated on a well-designed,
                  nicely powered study with high patient retention rates and findings that can inform our decisions
                  about treatment of Dupuytren contracture. I will use their results to better counsel my patients.
                  These results remind me once again that Dupuytren contracture is not curable and is at best
                  modifiable. More research is ongoing to determine how best to modify this disease.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Reference</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Eaton C</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Dupuytren Disease</span>.
                In: <span data-id="annotation_2" class="annotation" style="position: unset;"> Wolfe SW</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Pederson WC</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kozin SH</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Cohen MS</span>. Green’s Operative
                Hand Surgery. Elsevier; 2022.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D75fc1e69-d51c-4f13-b6d0-c6d514460035%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Evidence-Based Orthopaedics</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2169</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.01104</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 24, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;75fc1e69-d51c-4f13-b6d0-c6d514460035&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=75fc1e69-d51c-4f13-b6d0-c6d514460035&type=pdf&name=JBJS.24.01104.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=75fc1e69-d51c-4f13-b6d0-c6d514460035&type=pdf&name=JBJS.24.01104.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_1" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_2"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I203" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I203</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;75fc1e69-d51c-4f13-b6d0-c6d514460035&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=75fc1e69-d51c-4f13-b6d0-c6d514460035&type=zip&name=JBJS.24.01104.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Stephen McCollam, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Peachtree Orthopaedic Clinic, Atlanta, Georgia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 6380.4px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
