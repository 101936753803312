import { blackListedStudypackIdsForWeb } from "@constants/common";
import { formatSecondsToUTCtime } from "@memorang/helpers";
import type { Distribution, PracticeTestExam } from "@memorang/types";
import { formatDate, formatTimeString } from "./date";
import { isWeb } from "./platform";

export const getIsOlxExam = (id: string) => id.includes("OLX");

const getDescAndCTA = ({
	status,
	numItems,
	totalTime,
	dateCompleted,
	timeElapsed,
	attempted,
	endStartDate,
}: PracticeTestExam) => {
	if (status === "COMPLETE") {
		return {
			description: `${numItems} items • ${formatDate(dateCompleted)}`,
			cta: "View report",
		};
	}
	if (status === "ACTIVE") {
		const timeRemaining = totalTime - timeElapsed;
		const numAttempts = attempted
			? attempted.high + attempted.low + attempted.medium
			: 0;
		return {
			description: `${numAttempts} / ${numItems} items • ${formatSecondsToUTCtime(
				timeRemaining,
			)} Remaining${
				endStartDate
					? ` • Ends on: ${formatTimeString(endStartDate, true, false)}`
					: ""
			}`,
			cta: "Resume",
		};
	}
	if (status === "NOT_STARTED") {
		return {
			description: `${numItems} items • ${formatSecondsToUTCtime(totalTime)}${
				endStartDate
					? ` • Ends on: ${formatTimeString(endStartDate, true, false)}`
					: ""
			}`,
			cta: "Start",
		};
	}
	return {
		description: `${numItems} items • ${formatSecondsToUTCtime(totalTime)}`,
		cta: "Start",
	};
};
export const calculateScore = (
	distribution: Distribution,
	numItems: number,
) => {
	const { high } = distribution;

	const itemsScore = high * 100;
	const score = itemsScore / numItems;
	return Math.ceil(score);
};
export const getMappedExams = (exams: PracticeTestExam[]) => {
	const mappedExams = exams.map((exam) => {
		const { title, status, id, numericId, distribution, numItems } = exam;
		const { description, cta } = getDescAndCTA(exam);
		const score = distribution ? calculateScore(distribution, numItems) : 0;
		return {
			title,
			description,
			status: status || ("NOT_STARTED" as const),
			numericId: numericId || 0,
			cta,
			id,
			score,
			hasExamAccess: exam.hasAccess,
			type: exam.type,
			beginStartDate: exam.beginStartDate,
			endStartDate: exam.endStartDate,
		};
	});

	const legacyReports = exams.filter((item) => item.legacyReport != null);
	const migratedReports = exams.filter(
		(item) => item.migratedSessionReport != null,
	);

	if (legacyReports.length > 0) {
		const legacyReport = legacyReports.map((item) => {
			const { title, legacyReport } = item;
			const { description, cta } = getDescAndCTA({
				...item,
				status: "COMPLETE",
				...legacyReport!,
			});
			const { numericId, id, score } = legacyReport!;

			return {
				title,
				description,
				status: "LEGACYREPORT" as const,
				numericId: numericId || 0,
				cta,
				id,
				score: Math.round(score),
				hasExamAccess: true,
				type: item.type,
				beginStartDate: item.beginStartDate,
				endStartDate: item.endStartDate,
			};
		});
		mappedExams.push(...legacyReport);
		if (migratedReports.length > 0) {
			const migratedReport = migratedReports.map((item) => {
				const { title, migratedSessionReport } = item;
				const { description, cta } = getDescAndCTA({
					...item,
					status: "COMPLETE",
					...migratedSessionReport!,
				});
				const { numericId, id, score } = migratedSessionReport!;
				return {
					title,
					description,
					status: "MIGRATEDREPORT" as const,
					numericId: numericId || 0,
					cta,
					id,
					score: Math.round(score),
					hasExamAccess: true,
					type: item.type,
					beginStartDate: item.beginStartDate,
					endStartDate: item.endStartDate,
				};
			});
			mappedExams.push(...migratedReport);
		}
	}

	return mappedExams;
};

export const getShowStudyPackViews = (studyPackProductId?: string) => {
	if (!isWeb) {
		return true;
	}
	if (!studyPackProductId) {
		return false;
	}
	return !blackListedStudypackIdsForWeb.includes(studyPackProductId);
};

export const isOlxExamWithinDateRange = (
	beginStartDate?: string,
	endStartDate?: string,
): boolean => {
	if (!(beginStartDate && endStartDate)) {
		return true;
	}

	const beginTime = new Date(beginStartDate).getTime();
	const endTime = new Date(endStartDate).getTime();
	const currentTime = new Date().getTime();

	return currentTime >= beginTime && currentTime <= endTime;
};

export const getOLXExamEligibilityConfig = (
	startDate: string,
	endDate: string,
) => {
	const currentTime = new Date().getTime();
	const beginTime = new Date(startDate).getTime();
	const endTime = new Date(endDate).getTime();

	if (currentTime < beginTime) {
		return {
			title: "Exam Not Yet Available",
			body: `You have unlocked the OLX exam, but you cannot start it yet. The exam will be available on ${formatTimeString(
				startDate,
				true,
				false,
			)}. Please check back then.`,
		};
	}
	if (currentTime > endTime) {
		return {
			title: "Exam Window Closed",
			body: "Unfortunately, the window to take your OLX exam has passed. Please contact your professor or school administrator for further assistance.",
		};
	}
	return {
		title: "Start Your OLX Exam",
		body: `You have unlocked the OLX exam. You can start the exam now and continue until ${formatTimeString(
			endDate,
			true,
			false,
		)} from the practice tests tab.`,
		cta: "Go to Practice tests",
	};
};
