import { Box, ScrollContainer } from "@memorang/ui";
import { Button, Text } from "react-native-paper";

import { TextInput } from "react-native-paper";
import useChangeDisplayUsername from "../hooks/useChangeDisplayUsername";
const ChangeUserNameRouteView = () => {
	const {
		firstName,
		setFirstName,
		lastName,
		setLastName,
		updatingUsername,
		handleUpdateUsername,
	} = useChangeDisplayUsername();
	const caption = "What do you want to be called on leaderboard?";
	const disabled = updatingUsername || !firstName || !lastName;
	return (
		<ScrollContainer>
			<Box padding={16} gap={24}>
				<Text variant="bodySmall">{caption}</Text>
				<TextInput
					label="First name"
					value={firstName}
					autoFocus
					placeholder="Enter your first name"
					onChangeText={setFirstName}
					mode="outlined"
					disabled={updatingUsername}
				/>
				<TextInput
					label="Last name"
					value={lastName}
					placeholder="Enter your last name"
					onChangeText={setLastName}
					mode="outlined"
					disabled={updatingUsername}
				/>
				<Button
					mode="contained"
					onPress={handleUpdateUsername}
					loading={updatingUsername}
					disabled={disabled}
				>
					Change username
				</Button>
			</Box>
		</ScrollContainer>
	);
};

export default ChangeUserNameRouteView;
