import { newEvents } from "@constants/tracking";
import { getSelectRoleOnboarding } from "@helpers/find-onboarding";
import { useAppStore } from "@hooks/useAppStore";
import useClerkUser from "@hooks/useClerkUser";
import { updateUserInfo } from "@memorang/api-client";
import { trackCustomEvent } from "analytics";
import { useRouter } from "expo-router";
import { useState } from "react";
import { useOnboardingStore } from "./useOnboardingStore";

export const useRoleOnboarding = () => {
	const router = useRouter();

	const [currentStepIndex, setCurrentStepIndex] = useState(0);

	const { appId, onboarding } = useAppStore((store) => ({
		appId: store.app.id,
		onboarding: store.onboarding,
	}));

	const {
		setRole,
		setSubRole,
		role: selectedRole,
		subRole,
	} = useOnboardingStore();

	const onboardingRoleStep = getSelectRoleOnboarding(onboarding!);

	const numMaxSelect = onboardingRoleStep?.numMaxSelect;

	const { email } = useClerkUser();

	const roles = onboardingRoleStep?.itemsData?.items || [];

	const selectedItem = roles?.find((role) => role.value === subRole);
	const hasNestedItems =
		currentStepIndex === 0 ? roles.some((card) => card.items?.length) : false;

	const _defaultSelectedIds =
		currentStepIndex === 1
			? roles.find((role) => role.value === selectedRole)?.defaultSelectedIds
			: onboardingRoleStep?.itemsData?.defaultSelectedIds;

	// useEffect(() => {
	// 	if (defaultSelectedIds) {
	// 		// setRole(defaultSelectedIds[0]);
	// 		setSubRole(defaultSelectedIds[0]);
	// 	}
	// }, [defaultSelectedIds, setSubRole]);

	const handleSelectRole = (role: string) => {
		if (currentStepIndex === 0 && hasNestedItems) {
			setRole(role);
			setCurrentStepIndex(1);
		} else {
			setSubRole(role);
			updateUserInfo(email!, appId, {
				profession: role,
			});
			router.setParams({
				step: "specialty",
				selectedRoles: subRole,
			});
			trackCustomEvent(
				{
					eventName: newEvents.roleSelected,
					profession: subRole,
					type: "onboarding",
				},
				{
					profession: subRole!,
				},
			);
			setCurrentStepIndex(0);
		}
	};

	const handleSkip = () => {
		router.setParams({
			step: "specialty",
			selectedRoles: subRole,
		});
	};

	const handleClickContinue = () => {
		updateUserInfo(email!, appId, {
			profession: selectedItem?.label,
		});
		router.setParams({
			step: "specialty",
			selectedRoles: subRole,
		});
	};

	return {
		handleSelectRole,
		selectedRole,
		handleClickContinue,
		onboardingRoleStep,
		roles,
		selectedItem,
		numMaxSelect,
		currentStepIndex,
		setCurrentStepIndex,
		subRole,
		hasNestedItems,
		handleSkip,
	};
};
