import type { Article } from "../../../../../types";

export const Article593: Article = {
	id: 593,
	rsuiteId: "6645dbc9-fc41-462e-b816-bf81761ff562",
	type: "scientific article",
	title: "Demographic Disparities and Outcomes Following Hip Arthroscopy",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6645dbc9-fc41-462e-b816-bf81761ff562&type=jpeg&name=JBJS.24.00217f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Demographic Disparities and Outcomes Following Hip
                Arthroscopy</div>
              <div class="text subtitle" style="position: unset;">Exploring the Impact of Social Determinants of Health
                in Femoroacetabular Impingement Syndrome</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Omair Kazi,
                      MS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Alexander B.
                      Alvero, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_71" class="annotation strong"
                          style="position: unset;">Background:</span> The purpose of this study was to explore the
                        impact of social deprivation on preoperative characteristics and postoperative outcomes
                        following hip arthroscopy (HA) for femoroacetabular impingement syndrome (FAIS).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_72" class="annotation strong"
                          style="position: unset;">Methods:</span> Patients undergoing primary HA for FAIS were
                        identified, and their social deprivation index (SDI) score was assigned on the basis of the
                        provided ZIP code. Quartiles (Q1 to Q4) were established using national percentiles, with Q4
                        representing patients from the areas of greatest deprivation. Patient-reported outcomes (PROs)
                        were collected preoperatively and at a minimum follow-up of 2 years. Achievement rates for
                        clinically meaningful outcomes, including the minimal clinically important difference (MCID),
                        patient acceptable symptom state (PASS), and substantial clinical benefit (SCB), were
                        determined. The incidences of revision HA and conversion to total hip arthroplasty (THA) were
                        recorded. SDI groups were compared with respect to preoperative characteristics and
                        postoperative outcome measures. Predictors of MCID, PASS, and SCB achievement; revision HA; and
                        conversion to THA were identified with use of multivariable logistic regression.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_73" class="annotation strong"
                          style="position: unset;">Results:</span> In total, 2,060 hips were included, which had the
                        following SDI distribution: Q1 = 955, Q2 = 580, Q3 = 281, and Q4 = 244. The composition of the
                        included patients with respect to race and/or ethnicity was 85.3% Caucasian, 3.8% African
                        American, 3.7% Hispanic, 1.7% Asian, and 5.4% “other.” Patients with more social deprivation
                        presented at a later age and with a higher body mass index (BMI), a longer duration of
                        preoperative hip pain, and greater joint degeneration (p ≤ 0.035 for all). The most socially
                        deprived groups had higher proportions of African American and Hispanic individuals, less
                        participation in physical activity, and greater prevalences of smoking, lower back pain, and
                        Workers’ Compensation (p ≤ 0.018 for all). PRO scores and achievement of the PASS and SCB were
                        worse among patients from areas of greater social deprivation (p ≤ 0.017 for all). Age, BMI,
                        activity status, race and/or ethnicity classified as “other,” SDI quartile, Workers’
                        Compensation, preoperative back pain, duration of preoperative hip pain, and Tönnis grade were
                        independent predictors of clinically meaningful outcome achievement, revision arthroscopy,
                        and/or THA conversion (p ≤ 0.049 for all).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_74" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Individuals with more social deprivation
                        demonstrated inferior postoperative outcome measures. This was driven primarily by preoperative
                        characteristics such as SDI, hip pain duration, joint degeneration, and overall health at
                        presentation. Despite differential outcomes, patients still showed clinical improvement
                        regardless of SDI quartile.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_75" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">The medical community has increasingly recognized the
                  relationship between social determinants of health and health-care disparities. Socioeconomic
                  discrepancies can contribute to poor overall health, reduced utilization of health-care resources, and
                  inferior clinical outcomes<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">5</span></a>. Practitioners have sought to quantify the interplay of
                  socioeconomic factors in order to provide a more holistic representation of the resource deprivation
                  of a community. Statistical tools such as the social deprivation index (SDI) have been widely used in
                  the medical field, and research has shown that groups with greater deprivation consistently
                  demonstrate decreased health-care utilization and increased rates of complications and readmission,
                  with worse overall outcomes following medical or surgical intervention<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">12</span></a>.
                  Across orthopaedics, the use of these indices has similarly illustrated that more socially deprived
                  communities often have inferior postoperative outcomes<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">13</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">18</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Hip arthroscopy (HA) has emerged as a valuable treatment
                  modality for patients with femoroacetabular impingement syndrome (FAIS)<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">21</span></a>.
                  Considerable focus has been placed on identifying contributors to poor outcomes following the
                  treatment of FAIS with HA; however, few studies have explored the influence of social determinants of
                  health on outcomes following HA<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">22</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">29</span></a>. The purpose of this study was to explore the role of
                  social deprivation, as measured with use of the SDI, on preoperative characteristics and 2-year
                  postoperative outcomes following HA for FAIS. We hypothesized that patients living in areas of greater
                  social deprivation would demonstrate poorer overall health, worse clinical outcomes, and a higher
                  incidence of secondary surgery than those residing in areas of less social deprivation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Selection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">After institutional review board approval, a prospectively
                  maintained repository for a single surgeon (S.J.N.) was retrospectively reviewed to identify patients
                  who underwent HA for FAIS between January 2012 and December 2018 and had a minimum follow-up of 2
                  years. Patients were excluded if they underwent HA for reasons other than FAIS, had previously
                  undergone ipsilateral HA, underwent a concomitant extra-articular procedure, underwent HA as part of a
                  staged procedure with periacetabular osteotomy, had a history of a congenital hip disorder (i.e.,
                  slipped capital femoral epiphysis or Legg-Calvé-Perthes disease), had an address outside of the United
                  States, or were missing minimum 2-year follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">SDI</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Developed by the Robert Graham Center, the SDI is a
                  statistical tool that is utilized to quantify the level of social deprivation for specific
                  geographical areas<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">30</span></a>.
                  Measures of social deprivation are acquired through the American Community Survey, including the
                  percentages of individuals in a given population who are under the federal poverty line, &gt;25 years
                  of age with &lt;12 years of education, unemployed and between 16 and 64 years of age, living in
                  renter-occupied housing units, living in crowded housing units, from single-parent families with
                  dependent(s) under 18 years of age, or without a vehicle<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">30</span></a>.
                  These data points are weighted to generate a score for a given ZIP code, with greater SDI scores
                  indicating greater social deprivation. Prior studies have utilized ZIP codes and the associated SDI
                  scores to quantify health-care access and outcomes<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">17</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">34</span></a>. In
                  the present study, publicly available SDI scores from 2011 to 2018 were assigned to patients on the
                  basis of the ZIP code recorded during registration. Patients were grouped into quartiles (Q1 to Q4)
                  based on national SDI scores, in accordance with prior literature<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">28</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">35</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">36</span></a>. Q1
                  represents the least socially deprived group, whereas Q4 represents the most deprived group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Techniques</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Patients were indicated for surgery if they had imaging
                  and physical examination findings that were consistent with FAIS that was refractory to conservative
                  management<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">37</span></a>.
                  Surgical procedures were performed by the senior author (S.J.N.) with use of previously described
                  techniques<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">38</span></a>. All patients underwent general anesthesia and were placed
                  in the supine position, with the operative leg in traction. Anterolateral and mid-anterior capsular
                  portals were established, and an interportal capsulotomy was performed. A survey of the joint was
                  conducted to identify the primary pathology. When present, labral tears were repaired and chondral
                  lesions were debrided to a stable edge. Traction was then released, and a third distal anterolateral
                  portal was established. In cases in which interportal capsulotomy did not allow for adequate access to
                  the cam-type FAIS deformity, the interportal capsulotomy was converted to a T-type capsulotomy.
                  Femoral osteochondroplasty was then performed with fluoroscopic assistance. Capsular repair was
                  performed in all cases.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Collection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Demographic data collection included age, sex, body mass
                  index (BMI), self-reported race and/or ethnicity, engagement in physical activity ≥1 time per week,
                  history of smoking, preoperative lower back pain, history of psychiatric diagnosis, and duration of
                  preoperative hip pain. BMI was classified as underweight (&lt;18.5 kg/m<span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">2</span>), healthy weight (18.5 to 24.9
                  kg/m<span data-id="superscript_13" class="annotation superscript" style="position: unset;">2</span>),
                  overweight (25.0 to 29.9 kg/m<span data-id="superscript_14" class="annotation superscript"
                    style="position: unset;">2</span>), or obese (≥30.0 kg/m<span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">2</span>), according to the Centers for
                  Disease Control and Prevention categories<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">39</span></a>.
                  Preoperative and postoperative radiographs, including anteroposterior pelvic and 90° Dunn-lateral
                  views, were collected. The lateral center-edge angle (LCEA) and Tönnis angle were measured on the
                  anteroposterior pelvic view. The alpha angle was measured on the 90° Dunn-lateral view. Intraoperative
                  information was also collected, including acetabular and femoral head cartilage damage, which were
                  characterized with use of the Beck classification and the International Cartilage Regeneration and
                  Joint Preservation Society (ICRS) classification, respectively<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">40</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">43</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcome
                Measures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Patient-reported outcomes (PROs) were collected
                  preoperatively and at 2 years postoperatively. PROs included the Hip Outcome Score (HOS) Activities of
                  Daily Living (ADL) and Sports subscales, modified Harris hip score (mHHS), 12-item International Hip
                  Outcome Tool questionnaire (iHOT-12), visual analog scale (VAS) for pain, and VAS for satisfaction.
                  Cohort-specific thresholds for clinically meaningful outcomes were established. The minimal clinically
                  important difference (MCID) was determined with use of the distribution method<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">44</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">47</span></a>. The patient acceptable symptom state (PASS) and
                  substantial clinical benefit (SCB) were determined with use of previously described anchor questions<a
                    href="" data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">44</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">46</span></a>. Results from the anchor questions were utilized to
                  generate receiver operating characteristic (ROC) curves for each PRO. An area under the curve (AUC) of
                  ≥0.70 was deemed clinically useful<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">49</span></a>. The Youden J index was utilized to identify PASS and SCB
                  thresholds that maximized sensitivity and specificity<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">50</span></a>.
                  Achievement of the MCID, PASS, and SCB for each PRO was calculated.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Nominal variables are reported as counts with frequencies.
                  Continuous variables are reported as means with standard deviations. Univariate comparisons between
                  SDI quartiles were performed with use of chi-square tests with post-hoc pairwise analyses or with use
                  of analyses of variance with post-hoc Tukey analyses<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">51</span></a>.
                  Demographic and radiographic variables that were found to be significantly (p &lt; 0.05) different
                  between SDI quartiles were retained for the multivariable logistic regression analyses in order to
                  identify independent predictors of MCID, PASS, and SCB achievement for ≥1 PRO; predictors of revision
                  HA; and predictors of conversion to total hip arthroplasty (THA). Multicollinearity was assessed with
                  use of the variance inflation factor (VIF); a VIF of 1 indicated no correlation between the variables
                  and a VIF of &gt;5 indicated notable multicollinearity<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">52</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">53</span></a>. Significance for all analyses was set at an a priori α of
                  0.05. Statistical analyses were performed with use of R (version 4.2.3; R Core Team).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">A total of 2,060 hips were included, which had the
                  following SDI distribution: Q1 = 955 hips, Q2 = 580 hips, Q3 = 281 hips, and Q4 = 244 hips (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). Demographic and radiographic data stratified by the SDI
                  quartiles are presented in <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>. On
                  average, patients with more social deprivation presented at a later age (p &lt; 0.001) and with a
                  higher BMI (p &lt; 0.001), a longer duration of ipsilateral hip pain (p &lt; 0.001), and greater joint
                  degeneration as indicated by the Tönnis grade (p = 0.035). In addition, the most socially deprived
                  groups had higher proportions of African American and Hispanic patients (p &lt; 0.001), reported lower
                  participation in regular physical activity (p = 0.004), more commonly had a history of smoking (p =
                  0.003), reported preoperative lower back pain more often (p = 0.018), and were more likely to be
                  receiving care under Workers’ Compensation (p = 0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00217f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6645dbc9-fc41-462e-b816-bf81761ff562&type=jpeg&name=JBJS.24.00217f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;">Consolidated Standards Of Reporting Trials
                          (CONSORT) flowchart of patient selection. Follow-up refers to the percent of eligible patients
                          with outcome survey completion.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Cohort Demographic and Radiographic
                          Characteristics<span data-id="table_footnote_reference_13"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1831px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q1 (N =
                          955)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q2 (N =
                          580)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q3 (N =
                          281)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q4 (N =
                          244)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up <span
                            data-id="emphasis_71" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6 ±
                          3.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4 ±
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5 ±
                          3.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5 ±
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.795</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_72" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.2 ±
                          13.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.4 ±
                          12.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.6 ±
                          10.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.8 ±
                          10.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_78" class="annotation strong" style="position: unset;">&lt;0.001</span><span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.6%
                          (655)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.3%
                          (396)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.0%
                          (191)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.2%
                          (164)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.981</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_73" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_74" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_24" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_24"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_75"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.7 ±
                          4.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.0 ±
                          5.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.0 ±
                          5.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.6 ±
                          5.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_79" class="annotation strong" style="position: unset;">&lt;0.001</span><span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI category</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_80" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Underweight</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.5%
                          (33)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.8%
                          (22)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9%
                          (11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0%
                          (5)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Healthy weight</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.4%
                          (548)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56.4%
                          (327)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55.9%
                          (157)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.0%
                          (117)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Overweight</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.3%
                          (270)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.4%
                          (153)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.7%
                          (75)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.9%
                          (68)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Obese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.9%
                          (104)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.4%
                          (78)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.5%
                          (38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.1%
                          (54)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Race and/or
                          ethnicity</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_81" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Caucasian</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88.7%
                          (847)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87.8%
                          (509)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82.2%
                          (231)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69.7%
                          (170)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> African American</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.2%
                          (21)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8%
                          (16)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0%
                          (14)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.5%
                          (28)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hispanic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.1%
                          (20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8%
                          (16)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9%
                          (11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.3%
                          (30)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Asian</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.8%
                          (17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7%
                          (10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5%
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8%
                          (2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.2%
                          (50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0%
                          (29)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.4%
                          (18)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.7%
                          (14)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Workers’
                          Compensation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8%
                          (27)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1%
                          (24)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.1%
                          (20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.4%
                          (18)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_82" class="annotation strong" style="position: unset;">0.001</span><span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Physical activity</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.0%
                          (764)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77.9%
                          (452)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.4%
                          (209)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69.7%
                          (170)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_83" class="annotation strong" style="position: unset;">0.004</span><span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">History of smoking</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.6%
                          (82)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.8%
                          (57)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.9%
                          (39)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.6%
                          (38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_84" class="annotation strong" style="position: unset;">0.003</span><span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. lower back
                          pain</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.5%
                          (138)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.1%
                          (82)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.3%
                          (57)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.1%
                          (49)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_85" class="annotation strong" style="position: unset;">0.018</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Psychiatric
                          diagnosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.0%
                          (134)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.7%
                          (91)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.4%
                          (49)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.0%
                          (44)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.309</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. hip pain duration
                          <span data-id="emphasis_76" class="annotation emphasis"
                            style="position: unset;">(mo)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.7 ±
                          29.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.8 ±
                          30.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.7 ±
                          40.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.2 ±
                          47.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_86" class="annotation strong" style="position: unset;">&lt;0.001</span><span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. alpha angle, Dunn
                          view <span data-id="emphasis_77" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.5 ±
                          13.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.8 ±
                          13.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.4 ±
                          13.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59.5 ±
                          11.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.293</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LCEA <span
                            data-id="emphasis_78" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.7 ±
                          6.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.0 ±
                          6.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.1 ±
                          6.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.1 ±
                          7.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.081</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Tönnis grade 1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.3%
                          (89)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.2%
                          (42)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.1%
                          (34)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.7%
                          (31)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_87" class="annotation strong" style="position: unset;">0.035</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postop. alpha angle, Dunn
                          view <span data-id="emphasis_79" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.0 ±
                          6.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.1 ±
                          6.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.5 ±
                          6.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.5 ±
                          6.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.721</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Q1 represents the least socially deprived group, and Q4 represents the
                        most socially deprived group. Values are given as the mean</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;"></b> <span class="text"
                        style="position: unset;">± standard deviation or as the percentage of hips, with the number of
                        hips in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">A significant difference between Q1 and Q4.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">A significant difference between Q1 and Q3.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">A significant difference between Q2 and Q4.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Intraoperative Findings and Procedures Performed</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>
                  summarizes the intraoperative findings and the procedures that were performed. No differences in
                  intraoperative findings (p ≥ 0.072) or procedures (p ≥ 0.854) were observed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Intraoperative Findings and Procedures
                          Performed<span data-id="table_footnote_reference_14"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 471px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q1 (N =
                          955)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q2 (N =
                          580)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q3 (N =
                          281)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q4 (N =
                          244)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Acetabular grade-3 or 4
                          chondral defects</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.0%
                          (143)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.3%
                          (77)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.6%
                          (55)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.7%
                          (31)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.072</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Femoral grade-3 or 4
                          chondral defects</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.4%
                          (23)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1%
                          (18)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8%
                          (8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0%
                          (5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.785</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Labral repair</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.7%
                          (933)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.9%
                          (568)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.2%
                          (273)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.1%
                          (237)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.854</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Femoral
                          osteochondroplasty</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100%
                          (955)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100%
                          (580)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100%
                          (281)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100%
                          (244)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.000</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Capsular repair</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100%
                          (955)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100%
                          (580)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100%
                          (281)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100%
                          (244)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.000</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Q1 represents the least socially deprived group, and Q4 represents the
                        most socially deprived group. Values are given as the percentage of hips, with the number of
                        hips in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcome
                Measures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">There were no significant differences in preoperative PRO
                  scores across the SDI groups (p ≥ 0.111; <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>). All
                  SDI groups showed significant (p &lt; 0.001) improvements from the preoperative to postoperative
                  timepoints for all measured PROs. Postoperatively, patients with the highest social deprivation (Q4)
                  had worse outcome scores than those with the lowest deprivation (Q1 and Q2) for all measured PROs (p ≤
                  0.004). MCID, PASS, and SCB achievement rates are shown in <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 2</a>.
                  Patients from areas of greater social deprivation were found to have lower percent achievement of the
                  PASS and SCB (p = 0.017 and p &lt; 0.001, respectively).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Patient-Reported Outcomes<span
                            data-id="table_footnote_reference_29" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 901px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q1</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q2</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q3</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q4</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> HOS-ADL</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.6 ±
                          17.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64.9 ±
                          17.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.9 ±
                          18.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62.1 ±
                          20.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.111</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> HOS-Sports</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42.5 ±
                          22.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42.4 ±
                          22.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.9 ±
                          23.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.7 ±
                          23.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.251</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> mHHS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59.7 ±
                          14.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59.3 ±
                          13.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60.2 ±
                          15.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.1 ±
                          15.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.479</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> iHOT-12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.4 ±
                          18.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.9 ±
                          17.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.2 ±
                          17.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.3 ±
                          19.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.816</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> VAS pain</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.8 ±
                          23.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.6 ±
                          22.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59.5 ±
                          21.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.2 ±
                          24.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.737</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Final
                          postoperative</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> HOS-ADL</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86.7 ±
                          17.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85.9 ±
                          18.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85.9 ±
                          18.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82.0 ±
                          20.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_88" class="annotation strong" style="position: unset;">0.009</span><span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_16"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> HOS-Sports</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77.3 ±
                          25.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75.9 ±
                          25.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75.2 ±
                          26.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69.4 ±
                          29.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_89" class="annotation strong" style="position: unset;">0.002</span><span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_18"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> mHHS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.9 ±
                          17.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.0 ±
                          17.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79.3 ±
                          17.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75.5 ±
                          20.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_90" class="annotation strong" style="position: unset;">&lt;0.001</span><span
                            data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_20"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> iHOT-12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.4 ±
                          26.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.6 ±
                          25.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73.2 ±
                          27.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.8 ±
                          29.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_91" class="annotation strong" style="position: unset;">&lt;0.001</span><span
                            data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_22"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> VAS pain</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.2 ±
                          24.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.2 ±
                          25.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.8 ±
                          24.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.9 ±
                          27.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_92" class="annotation strong" style="position: unset;">&lt;0.001</span><span
                            data-id="table_footnote_reference_24" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_25"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> VAS satisfaction</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82.4 ±
                          26.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82.2 ±
                          26.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.9 ±
                          26.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.1 ±
                          30.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_93" class="annotation strong" style="position: unset;">0.001</span><span
                            data-id="table_footnote_reference_27" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_28"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Q1 represents the least socially deprived group, and Q4 represents the
                        most socially deprived group. Values are given as the mean</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;"></b> <span class="text"
                        style="position: unset;">± standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">A significant difference between Q1 and Q4.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">A significant difference between Q2 and Q4.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">A significant difference between Q3 and Q4.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00217f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6645dbc9-fc41-462e-b816-bf81761ff562&type=jpeg&name=JBJS.24.00217f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Percent achievement of MCID, PASS, and SCB for ≥1
                          of the measured PROs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">There were no differences in the overall incidence of
                  secondary surgery across the groups (p = 0.118); however, there was a lower likelihood of undergoing
                  revision HA in the higher quartiles (p = 0.048). There were no differences in conversion to THA (p =
                  0.120). All groups had similar rates of postoperative complications, including neuropathy, deep vein
                  thrombosis, and infection (p ≥ 0.193 for all). Secondary surgery and complications are summarized in
                  <a href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Secondary Surgery and Complications<span
                            data-id="table_footnote_reference_30" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 522px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q1 (N =
                          955)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q2 (N =
                          580)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q3 (N =
                          281)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Q4 (N =
                          244)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Secondary surgery</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.9%
                          (56)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.4%
                          (37)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.8%
                          (19)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5%
                          (6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.118</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Revision HA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.2%
                          (40)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5%
                          (26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8%
                          (8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8%
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_94" class="annotation strong" style="position: unset;">0.048</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> THA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7%
                          (16)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.9%
                          (11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9%
                          (11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.6%
                          (4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.120</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complications</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Neuropathy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9%
                          (9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7%
                          (4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.4%
                          (1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0%
                          (5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.193</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> DVT</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.4%
                          (4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3%
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0%
                          (0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0%
                          (0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.547</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5%
                          (5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5%
                          (3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0%
                          (0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0%
                          (0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.432</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Q1 represents the least socially deprived group, and Q4 represents the
                        most socially deprived group. DVT = deep vein thrombosis. Values are given as the percentage of
                        hips, with the number of hips in parentheses. Percentages are based on the column total.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Multivariable Regression Analyses</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The results of the multivariable regression analyses are
                  summarized in <a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>. The VIF
                  was ≤1.45 for all variables. Achievement of clinically meaningful outcomes was predicted for at least
                  1 outcome measure by underweight or obese BMI, Q4 SDI, physical activity status, duration of
                  preoperative pain, race and/or ethnicity classified as “other,” and Tönnis grade 1 (p ≤ 0.048 for
                  all). Revision arthroscopy was predicted by underweight BMI, Workers’ Compensation status, and
                  presence of preoperative back pain (p ≤ 0.049 for all). Conversion to THA was predicted by age,
                  obesity, Q3 SDI, race and/or ethnicity classified as “other,” and Tönnis grade (p ≤ 0.012 for all).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Multivariable Regression Analyses<span
                            data-id="table_footnote_reference_75" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1078.52px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.514867);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">MCID Achievement</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">PASS Achievement</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">SCB Achievement</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Revision Arthroscopy</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">THA Conversion</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98
                          (0.97-1.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.059</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.99
                          (0.98-1.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.132</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (0.99-1.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.665</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (0.97-1.03)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.823</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.07
                          (1.02-1.11)<span data-id="table_footnote_reference_31"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_95" class="annotation strong" style="position: unset;">0.003</span><span
                            data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Underweight</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.57
                          (0.16-0.96)<span data-id="table_footnote_reference_33"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_96" class="annotation strong" style="position: unset;">0.042</span><span
                            data-id="table_footnote_reference_34" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.35
                          (0.16-0.73)<span data-id="table_footnote_reference_35"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_97" class="annotation strong" style="position: unset;">0.005</span><span
                            data-id="table_footnote_reference_36" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.44
                          (0.22-0.88)<span data-id="table_footnote_reference_37"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_98" class="annotation strong" style="position: unset;">0.021</span><span
                            data-id="table_footnote_reference_38" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.08
                          (1.27-13.15)<span data-id="table_footnote_reference_39"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_99" class="annotation strong" style="position: unset;">0.019</span><span
                            data-id="table_footnote_reference_40" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.00
                          (0.00-infinity)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.993</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Healthy weight</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Overweight</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.66
                          (0.43-1.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.057</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.80
                          (0.56-1.14)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.223</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.79
                          (0.58-1.07)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.127</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.34
                          (0.63-2.84)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.448</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.01
                          (0.69-5.88)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.202</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Obese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.47
                          (0.25-0.73)<span data-id="table_footnote_reference_41"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_100" class="annotation strong" style="position: unset;">0.002</span><span
                            data-id="table_footnote_reference_42" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.57
                          (0.37-0.87)<span data-id="table_footnote_reference_43"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_101" class="annotation strong" style="position: unset;">0.009</span><span
                            data-id="table_footnote_reference_44" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.50
                          (0.34-0.74)<span data-id="table_footnote_reference_45"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_102" class="annotation strong"
                            style="position: unset;">&lt;0.001</span><span data-id="table_footnote_reference_46"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.99
                          (0.32-3.03)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.981</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.39
                          (1.38-13.95)<span data-id="table_footnote_reference_47"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_103" class="annotation strong" style="position: unset;">0.012</span><span
                            data-id="table_footnote_reference_48" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SDI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Q1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Q2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.84
                          (0.55-1.28)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.416</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.91
                          (0.64-1.28)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.588</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.97
                          (0.72-1.31)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.827</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.85
                          (0.43-1.68)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.637</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.92
                          (0.32-2.70)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.884</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Q3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.17
                          (0.65-2.12)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.602</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.04
                          (0.65-1.66)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.867</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (0.67-1.51)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.987</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.23
                          (0.05-1.05)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.057</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.73
                          (1.53-14.57)<span data-id="table_footnote_reference_49"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_104" class="annotation strong" style="position: unset;">0.007</span><span
                            data-id="table_footnote_reference_50" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Q4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98
                          (0.55-1.28)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.959</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.81
                          (0.51-1.30)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.385</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.64
                          (0.43-0.98)<span data-id="table_footnote_reference_51"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_105" class="annotation strong" style="position: unset;">0.041</span><span
                            data-id="table_footnote_reference_52" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.14
                          (0.01-1.08)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.060</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.37
                          (0.06-2.11)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.263</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Physical activity</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.84
                          (0.53-1.33)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.457</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.89
                          (1.32-2.62)<span data-id="table_footnote_reference_53"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_106" class="annotation strong"
                            style="position: unset;">&lt;0.001</span><span data-id="table_footnote_reference_54"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.54
                          (1.12-2.11)<span data-id="table_footnote_reference_55"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_107" class="annotation strong" style="position: unset;">0.008</span><span
                            data-id="table_footnote_reference_56" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.77
                          (0.94-8.14)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.064</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.83
                          (0.31-2.25)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.714</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Race and/or
                          ethnicity</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Caucasian</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> African American</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.03
                          (0.56-7.32)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.280</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.56
                          (0.25-1.23)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.146</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.59
                          (0.28-1.27)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.181</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.32
                          (0.16-10.75)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.796</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.63
                          (0.07-5.86)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.683</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hispanic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.80
                          (0.34-1.85)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.600</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.05
                          (0.51-2.16)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.889</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.03
                          (0.55-1.95)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.918</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.40
                          (0.30-6.42)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.669</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.46
                          (0.16-13.16)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.736</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Asian</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.20
                          (0.41-24.76)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.264</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.30
                          (0.68-48.28)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.111</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.77
                          (0.57-5.53)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.327</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.02
                          (0.11-9.07)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.987</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.00
                          (0.00-infinity)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.994</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.55
                          (0.19-1.57)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.264</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.40
                          (0.21-0.78)<span data-id="table_footnote_reference_57"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_108" class="annotation strong" style="position: unset;">0.007</span><span
                            data-id="table_footnote_reference_58" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.52
                          (0.28-0.99)<span data-id="table_footnote_reference_59"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_109" class="annotation strong" style="position: unset;">0.048</span><span
                            data-id="table_footnote_reference_60" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.00
                          (0.00-infinity)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.985</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.35
                          (1.44-19.88)<span data-id="table_footnote_reference_61"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_110" class="annotation strong"
                            style="position: unset;">&lt;0.001</span><span data-id="table_footnote_reference_62"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Workers’
                          Compensation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.25
                          (0.53-2.98)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.608</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.04
                          (0.54-1.97)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.913</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.29
                          (0.70-2.36)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.418</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.76
                          (1.10-12.83)<span data-id="table_footnote_reference_63"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_111" class="annotation strong" style="position: unset;">0.034</span><span
                            data-id="table_footnote_reference_64" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.00
                          (0.00-infinity)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.989</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Smoking history</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.78
                          (0.46-1.34)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.368</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.93
                          (0.59-1.45)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.734</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.78
                          (0.52-1.16)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.214</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01
                          (0.33-3.09)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.987</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.88
                          (0.27-2.84)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.834</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. lower back
                          pain</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.16
                          (0.69-1.94)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.574</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.81
                          (0.55-1.21)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.310</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.92
                          (0.64-1.32)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.646</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.28
                          (1.01-5.15)<span data-id="table_footnote_reference_65"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_112" class="annotation strong" style="position: unset;">0.049</span><span
                            data-id="table_footnote_reference_66" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.06
                          (0.35-3.18)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.914</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. hip pain
                          duration</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (1.00-1.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.843</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (1.00– 1.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.925</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (1.00-1.01)<span data-id="table_footnote_reference_67"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_113" class="annotation strong" style="position: unset;">0.003</span><span
                            data-id="table_footnote_reference_68" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (0.91-1.13)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.869</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (0.99-1.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.128</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Tönnis grade</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.35
                          (0.21-0.60)<span data-id="table_footnote_reference_69"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_114" class="annotation strong"
                            style="position: unset;">&lt;0.001</span><span data-id="table_footnote_reference_70"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.60
                          (0.37-0.96)<span data-id="table_footnote_reference_71"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_115" class="annotation strong" style="position: unset;">0.032</span><span
                            data-id="table_footnote_reference_72" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.74
                          (0.47-1.16)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.185</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.71
                          (0.58-5.07)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.330</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.85
                          (3.39-23.07)<span data-id="table_footnote_reference_73"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_116" class="annotation strong"
                            style="position: unset;">&lt;0.001</span><span data-id="table_footnote_reference_74"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">OR = odds ratio, CI = confidence interval.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_14" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Indicates a lower likelihood of a positive outcome (in terms of MCID,
                        PASS, or SCB achievement) or a higher likelihood of a negative outcome (in terms of revision
                        arthroscopy or conversion to THA).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_15" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Indicates a higher likelihood of a positive outcome (in terms of MCID,
                        PASS, or SCB achievement) or a lower likelihood of a negative outcome (in terms of revision
                        arthroscopy or conversion to THA).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The orthopaedic community has increasingly acknowledged
                  that socioeconomic factors and health have a dynamic interplay that has implications regarding the
                  inequities of the health-care system. Many studies have identified an influence of social determinants
                  of health on surgical outcomes; however, the existing literature exploring this topic in HA is sparse
                  and has failed to establish a similar relationship<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">29</span></a>. The present study demonstrated that patients from areas
                  with greater social deprivation had worse postoperative PROs and decreased achievement of the PASS and
                  SCB, which were predicted by SDI as well as various other demographic factors. Despite worse outcomes
                  for those with more social deprivation, patients in all groups showed significant (p &lt; 0.001)
                  improvements postoperatively for all measured PROs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Previously, Maempel et al.<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">29</span></a> conducted a retrospective analysis of 88 patients from the
                  Non-Arthroplasty Hip Registry who underwent HA for FAIS. In that study, the Scottish Index of Multiple
                  Deprivation quintile was not predictive of PROs or patient satisfaction at 1 year postoperatively.
                  Saks et al.<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">28</span></a>
                  stratified 616 patients into quartiles based on SDI and found no differences in mHHS, Nonarthritic Hip
                  Score, VAS pain, or iHOT-12 values at 2 years postoperatively. In contrast, we found in the present
                  study that patients from areas of greater social deprivation demonstrated inferior outcomes across all
                  measured PROs and had significantly lower achievement of clinically meaningful outcomes (PASS and
                  SCB). The aforementioned studies reported on patients at an approximate mean follow-up of ≤2.5 years,
                  whereas the present study had a mean follow-up of approximately 4.5 years. Additionally, our
                  institution operates in urban and suburban settings, including in neighboring states. We also had a
                  larger cohort than Maempel et al. and Saks et al. This additional time and the different
                  characteristics of our cohort may have allowed for discrepancies in outcomes across SDI groups to be
                  further elucidated.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Interestingly, we found that patients from areas of higher
                  social deprivation, despite having inferior clinical outcomes, had lower rates of revision HA and
                  similar rates of THA conversion compared with patients from areas of lower social deprivation. Prior
                  literature has suggested that sociodemographic factors greatly influence the utilization of
                  arthroscopic procedures and arthroplasty<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">54</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">56</span></a>. As such, one may speculate that the time and financial
                  resources associated with secondary surgery played a part in patients electing to forgo additional
                  surgeries. Moreover, the relationship between socioeconomic status and trust in health care, which has
                  been well established across the medical field<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">57</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">58</span></a>, may have also influenced the lower rate of revision HA and
                  the similar rate of THA conversion despite the worse outcome measures in these patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Although the role of social determinants of health factors
                  on the incidence of FAIS, to our knowledge, has not yet been explored in any studies, it is likely
                  that this pathology occurs at a similar frequency regardless of social deprivation. However, the
                  quartile with the highest social deprivation constituted only 11.8% of the overall cohort in the
                  present study and 13.8% of the cohort in the study by Saks et al.<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">28</span></a>. It appears that HA is disproportionately utilized by more
                  affluent individuals. Notably, in the present study, patients with higher social deprivation presented
                  at an older age and had a higher BMI, a longer duration of hip pain, a higher prevalence of smoking,
                  less participation in routine physical activity, a higher rate of preoperative back pain, a higher
                  rate of Workers’ Compensation, and a greater prevalence of Tönnis grade 1 preoperatively, all of which
                  are known contributors to worse outcomes following HA<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">59</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">68</span></a>. This discrepancy was further reflected in the
                  multivariable analyses, as age, BMI, physical activity status, race and/or ethnicity classified as
                  “other,” Workers’ Compensation status, preoperative back pain, duration of preoperative hip pain, and
                  Tönnis grade 1 were identified as independent predictors of clinically meaningful outcome achievement,
                  revision arthroscopy, and/or THA conversion. It appears that the baseline preoperative characteristics
                  of patients from less affluent populations contributed significantly to the inferior outcomes
                  following HA that were observed across the more deprived SDI quartiles. Of note, African American and
                  Hispanic race and/or ethnicity were not determined to be significant predictors of postoperative
                  outcomes despite their difference in distribution across the SDI groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">The pathomorphology of FAIS is a relatively new
                  understanding, with FAIS diagnoses and subsequent treatment with HA having increased rapidly over the
                  last decade<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">69</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">71</span></a>. As such, awareness of FAIS and avenues for treatment may
                  be limited by differential access to health-care resources. The results of the present study serve to
                  emphasize the importance of patient outreach and education, as earlier intervention might have
                  improved the outcomes for those patients from areas of greater social deprivation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">In addition to being a source of increased pain and
                  dysfunction, FAIS has been linked to osteoarthritis of the hip<a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_33"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">21</span></a>. Arthritic progression appears to be a strong contributor
                  to THA conversion following HA, with studies showing that Tönnis grade-1 hips undergo conversion more
                  frequently than Tönnis grade-0 hips at mid- and long-term follow-up<a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">59</span></a><span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">63</span></a>. Fenn et al.<a href="" data-id="citation_reference_54"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">59</span></a>
                  demonstrated that Tönnis grade-1 hips not only underwent THA at higher rates than grade-0 hips (25.8%
                  versus 4.8%) but also underwent conversion at a mean of 44.6 months sooner. Although we did not find a
                  relationship between SDI quartile and THA conversion in this study, it is perhaps likely that the
                  older age and greater joint degeneration in the groups with higher social deprivation would ultimately
                  contribute to higher THA conversion rates, which can be further evaluated in future studies with
                  longer-term follow-up. Early THA may necessitate subsequent revision surgery, and thus delays in joint
                  preservation procedures may have a profound impact on future joint health and health-care costs,
                  highlighting the importance of early intervention.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Although the differential outcomes across social
                  deprivation quartiles observed in this study appear to have been determined by differing demographic
                  characteristics, variability in outcomes after HA remains multifactorial. Earlier diagnosis and
                  treatment for patients from areas of greater social deprivation may positively influence outcomes;
                  however, it is important to recognize that other factors, such as access to routine postoperative
                  physical therapy or the ability to take time off from work, may have played a role in the inferior
                  outcomes among the more socially deprived. Nonetheless, the results of the present study represent an
                  area of opportunity for surgeons to be advocates for equitable care access, including by engaging with
                  local politicians and organizations to help grow health equity programs. Ultimately, it is our
                  responsibility to help close the gaps in order to maximize clinical improvement for patients
                  undergoing arthroscopic surgery for FAIS.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Certain limitations of the present study must be
                  acknowledged. First, the retrospective nature of this study limited our ability to determine
                  causation. Second, despite the large cohort included in this study, only 11.8% of the patients were
                  from the highest SDI quartile (Q4) and 17.0% of the cohort was lost to follow-up, which could have
                  introduced sample bias. Additionally, although there is precedence for the use of measures of
                  deprivation that are based on ZIP codes<a href="" data-id="citation_reference_55"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_36" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_36" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_57"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">15</span></a>, we
                  relied on the SDI as a surrogate for measuring social deprivation, which may not accurately depict all
                  aspects of the socioeconomic background of a patient. Namely, deprivation scores that are assigned on
                  the basis of ZIP codes may be less reliable than census tract data for determining socioeconomic
                  status. As a single-institution study, the represented ZIP codes were reflective of social deprivation
                  in a particular census tract, limiting generalizability. Postoperative resource utilization, such as
                  the number of physical therapy visits, may help to explain outcome differences, but we are unable to
                  comment on this as such data were not collected. Finally, longer-term follow-up may be needed to
                  determine whether there are any differences in THA conversion.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Among patients with FAIS, HA appears to be
                  disproportionately utilized by more affluent individuals. The present study showed that those with
                  greater socioeconomic deprivation demonstrated inferior postoperative PRO scores and decreased
                  achievement of the PASS and SCB. These findings were primarily driven by preoperative patient
                  characteristics such as SDI, longer duration of hip pain, increased joint degeneration at the time of
                  presentation, and poorer overall health. Despite differential outcomes, patients still had significant
                  clinical improvement regardless of SDI quartile. Disparities related to social deprivation may be
                  associated with inequitable access to care and delays in treatment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Adler NE</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Glymour MM</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Fielding J</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Addressing Social Determinants
                  of Health and Health Inequalities</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2016 Oct 25;316(16):1641-2.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Addressing%20Social%20Determinants%20of%20Health%20and%20Health%20Inequalities&as_occt=title&as_sauthors=%20%22NE%20Adler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Dickman SL</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Himmelstein DU</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Woolhandler S</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Inequality and the health-care
                  system in the USA</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2017 Apr 8;389(10077):1431-41.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inequality%20and%20the%20health-care%20system%20in%20the%20USA&as_occt=title&as_sauthors=%20%22SL%20Dickman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Link BG</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Phelan J</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Social conditions as fundamental
                  causes of disease</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Health Soc Behav.</span> 1995;(Spec No):80-94.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Social%20conditions%20as%20fundamental%20causes%20of%20disease&as_occt=title&as_sauthors=%20%22BG%20Link%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Marmot M</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Bell R</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Social inequalities in health: a
                  proper concern of epidemiology</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Ann Epidemiol.</span> 2016 Apr;26(4):238-40.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Social%20inequalities%20in%20health%3A%20a%20proper%20concern%20of%20epidemiology&as_occt=title&as_sauthors=%20%22M%20Marmot%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Schoen C</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Osborn R</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Squires D</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Doty MM</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Access, affordability, and
                  insurance complexity are often worse in the United States compared to ten other countries</span>.
                <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Health Aff
                  (Millwood).</span> 2013 Dec;32(12):2205-15.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Access%2C%20affordability%2C%20and%20insurance%20complexity%20are%20often%20worse%20in%20the%20United%20States%20compared%20to%20ten%20other%20countries&as_occt=title&as_sauthors=%20%22C%20Schoen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Deng X</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Yang X</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Yang C</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Chen K</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Ren J</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Zeng J</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Zhang Q</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Li T</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Tang Q</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Zhu J</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Socioeconomic deprivation and
                  survival outcomes in primary central nervous system lymphomas</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Front Oncol.</span> 2022 Aug
                26;12:929585.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Socioeconomic%20deprivation%20and%20survival%20outcomes%20in%20primary%20central%20nervous%20system%20lymphomas&as_occt=title&as_sauthors=%20%22X%20Deng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Kaur M</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Patterson A</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Molina-Vega J</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Rothschild H</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Clelland E</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Ewing CA</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Mujir F</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Esserman LJ</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Olopade OI</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Mukhtar RA</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Area Deprivation Index in
                  Patients with Invasive Lobular Carcinoma of the Breast: Associations with Tumor Characteristics and
                  Outcomes</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Cancer Epidemiol Biomarkers Prev.</span> 2023 Aug
                1;32(8):1107-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Area%20Deprivation%20Index%20in%20Patients%20with%20Invasive%20Lobular%20Carcinoma%20of%20the%20Breast%3A%20Associations%20with%20Tumor%20Characteristics%20and%20Outcomes&as_occt=title&as_sauthors=%20%22M%20Kaur%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Kurani SS</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Heien HC</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Sangaralingham LR</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Inselman JW</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Shah ND</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Golden SH</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> McCoy RG</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Association of Area-Level
                  Socioeconomic Deprivation With Hypoglycemic and Hyperglycemic Crises in US Adults With
                  Diabetes</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">JAMA
                  Netw Open.</span> 2022 Jan 4;5(1):e2143597.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20Area-Level%20Socioeconomic%20Deprivation%20With%20Hypoglycemic%20and%20Hyperglycemic%20Crises%20in%20US%20Adults%20With%20Diabetes&as_occt=title&as_sauthors=%20%22SS%20Kurani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Michaels AD</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Meneveau MO</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Hawkins RB</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Charles EJ</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Mehaffey JH</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Socioeconomic risk-adjustment
                  with the Area Deprivation Index predicts surgical morbidity and cost</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Surgery.</span> 2021
                Nov;170(5):1495-500.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Socioeconomic%20risk-adjustment%20with%20the%20Area%20Deprivation%20Index%20predicts%20surgical%20morbidity%20and%20cost&as_occt=title&as_sauthors=%20%22AD%20Michaels%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Nkoy FL</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Stone BL</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Knighton AJ</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Fassl BA</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Johnson JM</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Maloney CG</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Savitz LA</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Neighborhood Deprivation and
                  Childhood Asthma Outcomes, Accounting for Insurance Coverage</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Hosp Pediatr.</span> 2018 Jan
                9;8(2):59-67.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Neighborhood%20Deprivation%20and%20Childhood%20Asthma%20Outcomes%2C%20Accounting%20for%20Insurance%20Coverage&as_occt=title&as_sauthors=%20%22FL%20Nkoy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Sawhney S</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Blakeman T</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Blana D</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Boyers D</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Fluck N</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Nath M</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Methven S</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Rzewuska M</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Black C</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Care processes and outcomes of
                  deprivation across the clinical course of kidney disease: findings from a high-income country with
                  universal healthcare</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Nephrol Dial Transplant.</span> 2023 May 4;38(5):1170-82.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Care%20processes%20and%20outcomes%20of%20deprivation%20across%20the%20clinical%20course%20of%20kidney%20disease%3A%20findings%20from%20a%20high-income%20country%20with%20universal%20healthcare&as_occt=title&as_sauthors=%20%22S%20Sawhney%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Yu KX</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Yuan WJ</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Huang CH</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Xiao L</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Xiao RS</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Zeng PW</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Chen L</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Chen ZH</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Socioeconomic deprivation and
                  survival outcomes in patients with colorectal cancer</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Am J Cancer Res.</span> 2022 Feb
                15;12(2):829-38.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Socioeconomic%20deprivation%20and%20survival%20outcomes%20in%20patients%20with%20colorectal%20cancer&as_occt=title&as_sauthors=%20%22KX%20Yu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Evans S</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Okoroafor UC</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Calfee RP</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Is Social Deprivation
                  Associated with PROMIS Outcomes After Upper Extremity Fractures in Children?</span><span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2021 Apr 1;479(4):826-34.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20Social%20Deprivation%20Associated%20with%20PROMIS%20Outcomes%20After%20Upper%20Extremity%20Fractures%20in%20Children%3F&as_occt=title&as_sauthors=%20%22S%20Evans%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Gulley ML</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Carender CN</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Glass NA</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Bedard NA</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">What is the Impact of Social
                  Deprivation on Mental and Physical Health Before and After Primary Total Knee
                  Arthroplasty?</span><span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Arthroplast Today.</span> 2023 Jun 12;22:101156.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20is%20the%20Impact%20of%20Social%20Deprivation%20on%20Mental%20and%20Physical%20Health%20Before%20and%20After%20Primary%20Total%20Knee%20Arthroplasty%3F&as_occt=title&as_sauthors=%20%22ML%20Gulley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Hong Z</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Clever DC</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Tatman LM</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Miller AN</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">The Effect of Social
                  Deprivation on Fracture-Healing and Patient-Reported Outcomes Following Intramedullary Nailing of
                  Tibial Shaft Fractures</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2022 Nov 16;104(22):1968-76.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=3448175" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Norris AC</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Mears SC</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Siegel ER</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Barnes CL</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Stambough JB</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Social Needs of Patients
                  Undergoing Total Joint Arthroplasty</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2022 Jul;37(7S):S416-21.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Social%20Needs%20of%20Patients%20Undergoing%20Total%20Joint%20Arthroplasty&as_occt=title&as_sauthors=%20%22AC%20Norris%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Okoroafor UC</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Gerull W</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Wright M</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Guattery J</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Sandvall B</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Calfee RP</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">The Impact of Social
                  Deprivation on Pediatric PROMIS Health Scores After Upper Extremity Fracture</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J Hand Surg Am.</span> 2018
                Oct;43(10):897-902.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Impact%20of%20Social%20Deprivation%20on%20Pediatric%20PROMIS%20Health%20Scores%20After%20Upper%20Extremity%20Fracture&as_occt=title&as_sauthors=%20%22UC%20Okoroafor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Wright MA</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Beleckas CM</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Calfee RP</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Mental and Physical Health
                  Disparities in Patients With Carpal Tunnel Syndrome Living With High Levels of Social
                  Deprivation</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J
                  Hand Surg Am.</span> 2019 Apr;44(4):335.e1-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mental%20and%20Physical%20Health%20Disparities%20in%20Patients%20With%20Carpal%20Tunnel%20Syndrome%20Living%20With%20High%20Levels%20of%20Social%20Deprivation&as_occt=title&as_sauthors=%20%22MA%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Beck M</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Kalhor M</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Leunig M</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Ganz R</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Hip morphology influences the
                  pattern of damage to the acetabular cartilage: femoroacetabular impingement as a cause of early
                  osteoarthritis of the hip</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 2005 Jul;87(7):1012-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20morphology%20influences%20the%20pattern%20of%20damage%20to%20the%20acetabular%20cartilage%3A%20femoroacetabular%20impingement%20as%20a%20cause%20of%20early%20osteoarthritis%20of%20the%20hip&as_occt=title&as_sauthors=%20%22M%20Beck%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Ganz R</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Parvizi J</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Beck M</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Leunig M</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Nötzli H</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Siebenrock KA</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Femoroacetabular impingement: a
                  cause for osteoarthritis of the hip</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2003 Dec;(417):112-20.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Femoroacetabular%20impingement%3A%20a%20cause%20for%20osteoarthritis%20of%20the%20hip&as_occt=title&as_sauthors=%20%22R%20Ganz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Husen M</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Leland DP</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Melugin HP</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Poudel K</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Hevesi M</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Levy BA</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Krych AJ</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Progression of Osteoarthritis
                  at Long-term Follow-up in Patients Treated for Symptomatic Femoroacetabular Impingement With Hip
                  Arthroscopy Compared With Nonsurgically Treated Patients</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2023
                Sep;51(11):2986-95.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Progression%20of%20Osteoarthritis%20at%20Long-term%20Follow-up%20in%20Patients%20Treated%20for%20Symptomatic%20Femoroacetabular%20Impingement%20With%20Hip%20Arthroscopy%20Compared%20With%20Nonsurgically%20Treated%20Patients&as_occt=title&as_sauthors=%20%22M%20Husen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Domb BG</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Chen SL</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Go CC</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Shapira J</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Rosinsky PJ</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Meghpara MB</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Maldonado DR</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Lall AC</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Predictors of Clinical Outcomes
                  After Hip Arthroscopy: 5-Year Follow-up Analysis of 1038 Patients</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2021
                Jan;49(1):112-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictors%20of%20Clinical%20Outcomes%20After%20Hip%20Arthroscopy%3A%205-Year%20Follow-up%20Analysis%20of%201038%20Patients&as_occt=title&as_sauthors=%20%22BG%20Domb%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Fabricant PD</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Fields KG</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Taylor SA</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Magennis E</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Bedi A</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Kelly BT</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">The effect of femoral and
                  acetabular version on clinical outcomes after arthroscopic femoroacetabular impingement
                  surgery</span>. <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2015 Apr 1;97(7):537-43.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1215841" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Menge TJ</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Briggs KK</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Dornan GJ</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> McNamara SC</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Philippon MJ</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Survivorship and Outcomes 10
                  Years Following Hip Arthroscopy for Femoroacetabular Impingement: Labral Debridement Compared with
                  Labral Repair</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2017 Jun 21;99(12):997-1004.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1330653" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Nawabi DH</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Degen RM</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Fields KG</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> McLawhorn A</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Ranawat AS</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Sink EL</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Kelly BT</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Outcomes After Arthroscopic
                  Treatment of Femoroacetabular Impingement for Patients With Borderline Hip Dysplasia</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2016 Apr;44(4):1017-23.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20After%20Arthroscopic%20Treatment%20of%20Femoroacetabular%20Impingement%20for%20Patients%20With%20Borderline%20Hip%20Dysplasia&as_occt=title&as_sauthors=%20%22DH%20Nawabi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Nho SJ</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Beck EC</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Nwachukwu BU</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Cvetanovich GL</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Neal WH</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Harris JD</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Weber AE</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Mather RC</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Survivorship and Outcome of Hip
                  Arthroscopy for Femoroacetabular Impingement Syndrome Performed With Modern Surgical
                  Techniques</span>. <span data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Am
                  J Sports Med.</span> 2019 Jun;47(7):1662-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Survivorship%20and%20Outcome%20of%20Hip%20Arthroscopy%20for%20Femoroacetabular%20Impingement%20Syndrome%20Performed%20With%20Modern%20Surgical%20Techniques&as_occt=title&as_sauthors=%20%22SJ%20Nho%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Skendzel JG</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Philippon MJ</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Briggs KK</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Goljan P</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The effect of joint space on
                  midterm outcomes after arthroscopic hip surgery for femoroacetabular impingement</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2014;42(5):1127-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20joint%20space%20on%20midterm%20outcomes%20after%20arthroscopic%20hip%20surgery%20for%20femoroacetabular%20impingement&as_occt=title&as_sauthors=%20%22JG%20Skendzel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Saks BR</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Ouyang VW</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Domb ES</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Jimenez AE</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Maldonado DR</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Lal</span>May<span
                  data-id="annotation_157" class="annotation" style="position: unset;"> l AC</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Domb BG</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Equality in Hip Arthroscopy
                  Outcomes Can Be Achieved Regardless of Patient Socioeconomic Status</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2021 Dec;49(14):3915-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Equality%20in%20Hip%20Arthroscopy%20Outcomes%20Can%20Be%20Achieved%20Regardless%20of%20Patient%20Socioeconomic%20Status&as_occt=title&as_sauthors=%20%22BR%20Saks%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Maempel JF</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Ting JZ</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Gaston P</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Assessing the Outcome of Hip
                  Arthroscopy for Labral Tears in Femoroacetabular Impingement Using the Minimum Dataset of the British
                  Non-arthroplasty Hip Register: A Single-Surgeon Experience</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2018
                Jul;34(7):2131-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessing%20the%20Outcome%20of%20Hip%20Arthroscopy%20for%20Labral%20Tears%20in%20Femoroacetabular%20Impingement%20Using%20the%20Minimum%20Dataset%20of%20the%20British%20Non-arthroplasty%20Hip%20Register%3A%20A%20Single-Surgeon%20Experience&as_occt=title&as_sauthors=%20%22JF%20Maempel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;">Robert
                Graham Center. <span data-id="strong_30" class="annotation strong" style="position: unset;">Social
                  Deprivation Index (SDI)</span>. Accessed 2024 Feb 20. <a
                  href="https://www.graham-center.org/maps-data-tools/social-deprivation-index.html" target="_blank"
                  data-id="link_1" class="link"
                  style="position: unset;">https://www.graham-center.org/maps-data-tools/social-deprivation-index.html</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Social%20Deprivation%20Index%20(SDI)&as_occt=title&as_sauthors=%20%22Robert%20Graham%20Center%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Sheth MM</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Morris BJ</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Laughlin MS</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Elkousy HA</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Edwards TB</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Lower Socioeconomic Status Is
                  Associated With Worse Preoperative Function, Pain, and Increased Opioid Use in Patients With Primary
                  Glenohumeral Osteoarthritis</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2020 Apr 1;28(7):287-92.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lower%20Socioeconomic%20Status%20Is%20Associated%20With%20Worse%20Preoperative%20Function%2C%20Pain%2C%20and%20Increased%20Opioid%20Use%20in%20Patients%20With%20Primary%20Glenohumeral%20Osteoarthritis&as_occt=title&as_sauthors=%20%22MM%20Sheth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Butler DC</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Petterson S</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Phillips RL</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Bazemore AW</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Measures of social deprivation
                  that predict health care access and need within a rational area of primary care service
                  delivery</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Health Serv Res.</span> 2013 Apr;48(2 Pt 1):539-59.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measures%20of%20social%20deprivation%20that%20predict%20health%20care%20access%20and%20need%20within%20a%20rational%20area%20of%20primary%20care%20service%20delivery&as_occt=title&as_sauthors=%20%22DC%20Butler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Stephens AR</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Tyser AR</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Kazmers NH</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">The Impact of Social
                  Deprivation on Orthopaedic Outpatient Satisfaction Using the Press Ganey Outpatient Medical Practice
                  Survey</span>. <span data-id="emphasis_32" class="annotation emphasis" style="position: unset;">J Am
                  Acad Orthop Surg.</span> 2020 Dec 15;28(24):e1111-20.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Impact%20of%20Social%20Deprivation%20on%20Orthopaedic%20Outpatient%20Satisfaction%20Using%20the%20Press%20Ganey%20Outpatient%20Medical%20Practice%20Survey&as_occt=title&as_sauthors=%20%22AR%20Stephens%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Wright MA</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Adelani M</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Dy C</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> OʼKeefe R</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Calfee RP</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">What is the Impact of Social
                  Deprivation on Physical and Mental Health in Orthopaedic Patients?</span><span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2019
                Aug;477(8):1825-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20is%20the%20Impact%20of%20Social%20Deprivation%20on%20Physical%20and%20Mental%20Health%20in%20Orthopaedic%20Patients%3F&as_occt=title&as_sauthors=%20%22MA%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Chen R</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Crichton S</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> McKevitt C</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Rudd AG</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Sheldenkar A</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Wolfe CDA</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Association between
                  socioeconomic deprivation and functional impairment after stroke: the South London Stroke
                  Register</span>. <span data-id="emphasis_34" class="annotation emphasis"
                  style="position: unset;">Stroke.</span> 2015 Mar;46(3):800-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20socioeconomic%20deprivation%20and%20functional%20impairment%20after%20stroke%3A%20the%20South%20London%20Stroke%20Register&as_occt=title&as_sauthors=%20%22R%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Kerluku J</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Walker P</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Amen T</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Almeida BA</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> McColgan R</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Urruela A</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Nguyen J</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Fufa DT</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Evaluation of Racial, Ethnic,
                  and Socioeconomic Disparities in Indication for Carpal Tunnel Release</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2023 Sep 20;105(18):1442-9.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=3780a8e4-d141-4fa6-95f6-196cf3bf3cd2" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Griffin DR</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Dickenson EJ</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> O’Donnell J</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Agricola R</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Awan T</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Beck M</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Dijkstra HP</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Falvey E</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Gimpel M</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Hinman RS</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Hölmich P</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Kassarjian A</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Martin HD</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Martin R</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Mather RC</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Philippon MJ</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Reiman MP</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Takla A</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Thorborg K</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Walker S</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Weir A</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Bennell KL</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">The Warwick Agreement on
                  femoroacetabular impingement syndrome (FAI syndrome): an international consensus statement</span>.
                <span data-id="emphasis_36" class="annotation emphasis" style="position: unset;">Br J Sports Med.</span>
                2016 Oct;50(19):1169-76.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Warwick%20Agreement%20on%20femoroacetabular%20impingement%20syndrome%20(FAI%20syndrome)%3A%20an%20international%20consensus%20statement&as_occt=title&as_sauthors=%20%22DR%20Griffin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Beck EC</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Alter T</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Mehta N</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Cancienne JM</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Sarmast S</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Liu JN</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Nho SJ</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Contemporary Hip Capsular
                  Management and Closure Using a Suture Passing Device</span>. <span data-id="emphasis_37"
                  class="annotation emphasis" style="position: unset;">Arthrosc Tech.</span> 2019 Aug
                23;8(9):e947-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Contemporary%20Hip%20Capsular%20Management%20and%20Closure%20Using%20a%20Suture%20Passing%20Device&as_occt=title&as_sauthors=%20%22EC%20Beck%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;">Centers
                for Disease Control and Prevention. <span data-id="strong_39" class="annotation strong"
                  style="position: unset;">Adult BMI Categories</span>. Accessed 2024 Sep 10. <a
                  href="https://www.cdc.gov/bmi/adult-calculator/bmi-categories.html" target="_blank" data-id="link_2"
                  class="link"
                  style="position: unset;">https://www.cdc.gov/bmi/adult-calculator/bmi-categories.html</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adult%20BMI%20Categories&as_occt=title&as_sauthors=%20%22Centers%20for%20Disease%20Control%20and%20Prevention%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Amenabar T</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Piriz J</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Mella C</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Hetaimish BM</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> O’Donnell J</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Reliability of 3 Different
                  Arthroscopic Classifications for Chondral Damage of the Acetabulum</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2015
                Aug;31(8):1492-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reliability%20of%203%20Different%20Arthroscopic%20Classifications%20for%20Chondral%20Damage%20of%20the%20Acetabulum&as_occt=title&as_sauthors=%20%22T%20Amenabar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Mayer SW</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Fauser TR</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Marx RG</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Ranawat AS</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Kelly BT</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Lyman S</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Nawabi DH</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Reliability of the
                  classification of cartilage and labral injuries during hip arthroscopy</span>. <span
                  data-id="emphasis_39" class="annotation emphasis" style="position: unset;">J Hip Preserv Surg.</span>
                2021 Mar 6;7(3):448-57.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reliability%20of%20the%20classification%20of%20cartilage%20and%20labral%20injuries%20during%20hip%20arthroscopy&as_occt=title&as_sauthors=%20%22SW%20Mayer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Mygind-Klavsen B</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Lund B</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Nielsen TG</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Maagaard N</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Kraemer O</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Hölmich P</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Winge S</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Lind M</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Danish Hip Arthroscopy
                  Registry: predictors of outcome in patients with femoroacetabular impingement (FAI)</span>. <span
                  data-id="emphasis_40" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2019 Oct;27(10):3110-20.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Danish%20Hip%20Arthroscopy%20Registry%3A%20predictors%20of%20outcome%20in%20patients%20with%20femoroacetabular%20impingement%20(FAI)&as_occt=title&as_sauthors=%20%22B%20Mygind-Klavsen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Nepple JJ</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Larson CM</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Smith MV</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Zaltz I</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Sierra RJ</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Clohisy JC</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">The reliability of arthroscopic
                  classification of acetabular rim labrochondral disease</span>. <span data-id="emphasis_41"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2012
                Oct;40(10):2224-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20reliability%20of%20arthroscopic%20classification%20of%20acetabular%20rim%20labrochondral%20disease&as_occt=title&as_sauthors=%20%22JJ%20Nepple%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Bodendorfer BM</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> DeFroda SF</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Clapp IM</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Newhouse A</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Nwachukwu BU</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Nho SJ</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Defining Clinically Significant
                  Improvement on the Patient-Reported Outcomes Measurement Information System Test at 1-Year Follow-up
                  for Patients Undergoing Hip Arthroscopy for the Treatment of Femoroacetabular Impingement
                  Syndrome</span>. <span data-id="emphasis_42" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2021 Jul;49(9):2457-65.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Defining%20Clinically%20Significant%20Improvement%20on%20the%20Patient-Reported%20Outcomes%20Measurement%20Information%20System%20Test%20at%201-Year%20Follow-up%20for%20Patients%20Undergoing%20Hip%20Arthroscopy%20for%20the%20Treatment%20of%20Femoroacetabular%20Impingement%20Syndrome&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Norman GR</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Sloan JA</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Wyrwich KW</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Interpretation of changes in
                  health-related quality of life: the remarkable universality of half a standard deviation</span>. <span
                  data-id="emphasis_43" class="annotation emphasis" style="position: unset;">Med Care.</span> 2003
                May;41(5):582-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Interpretation%20of%20changes%20in%20health-related%20quality%20of%20life%3A%20the%20remarkable%20universality%20of%20half%20a%20standard%20deviation&as_occt=title&as_sauthors=%20%22GR%20Norman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Okoroha KR</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Beck EC</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Nwachukwu BU</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Kunze KN</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Nho SJ</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Defining Minimal Clinically
                  Important Difference and Patient Acceptable Symptom State After Isolated Endoscopic Gluteus Medius
                  Repair</span>. <span data-id="emphasis_44" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2019 Nov;47(13):3141-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Defining%20Minimal%20Clinically%20Important%20Difference%20and%20Patient%20Acceptable%20Symptom%20State%20After%20Isolated%20Endoscopic%20Gluteus%20Medius%20Repair&as_occt=title&as_sauthors=%20%22KR%20Okoroha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Ueland TE</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Disantis A</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Carreira DS</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Martin RL</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Patient-Reported Outcome
                  Measures and Clinically Important Outcome Values in Hip Arthroscopy: A Systematic Review</span>. <span
                  data-id="emphasis_45" class="annotation emphasis" style="position: unset;">JBJS Rev.</span> 2021 Jan
                26;9(1):00084.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient-Reported%20Outcome%20Measures%20and%20Clinically%20Important%20Outcome%20Values%20in%20Hip%20Arthroscopy%3A%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22TE%20Ueland%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Hajian-Tilaki K</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Receiver Operating
                  Characteristic (ROC) Curve Analysis for Medical Diagnostic Test Evaluation</span>. <span
                  data-id="emphasis_46" class="annotation emphasis" style="position: unset;">Caspian J Intern
                  Med.</span> 2013 Spring;4(2):627-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Receiver%20Operating%20Characteristic%20(ROC)%20Curve%20Analysis%20for%20Medical%20Diagnostic%20Test%20Evaluation&as_occt=title&as_sauthors=%20%22K%20Hajian-Tilaki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Mandrekar JN</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Receiver operating
                  characteristic curve in diagnostic test assessment</span>. <span data-id="emphasis_47"
                  class="annotation emphasis" style="position: unset;">J Thorac Oncol.</span> 2010
                Sep;5(9):1315-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Receiver%20operating%20characteristic%20curve%20in%20diagnostic%20test%20assessment&as_occt=title&as_sauthors=%20%22JN%20Mandrekar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Ruopp MD</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Perkins NJ</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Whitcomb BW</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Schisterman EF</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Youden Index and optimal
                  cut-point estimated from observations affected by a lower limit of detection</span>. <span
                  data-id="emphasis_48" class="annotation emphasis" style="position: unset;">Biom J.</span> 2008
                Jun;50(3):419-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Youden%20Index%20and%20optimal%20cut-point%20estimated%20from%20observations%20affected%20by%20a%20lower%20limit%20of%20detection&as_occt=title&as_sauthors=%20%22MD%20Ruopp%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Kocher MS</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Zurakowski D</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Clinical epidemiology and
                  biostatistics: a primer for orthopaedic surgeons</span>. <span data-id="emphasis_49"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2004
                Mar;86(3):607-20.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=905358"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Kim JH</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Multicollinearity and
                  misleading statistical results</span>. <span data-id="emphasis_50" class="annotation emphasis"
                  style="position: unset;">Korean J Anesthesiol.</span> 2019 Dec;72(6):558-69.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Multicollinearity%20and%20misleading%20statistical%20results&as_occt=title&as_sauthors=%20%22JH%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Menard S</span>. <span
                  data-id="emphasis_51" class="annotation emphasis" style="position: unset;">Applied Logistic Regression
                  Analysis.</span> Sage Publications; 2002.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Okike K</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Chang RN</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Royse KE</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Paxton EW</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Navarro RA</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Hinman AD</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Association Between
                  Race/Ethnicity and Total Joint Arthroplasty Utilization in a Universally Insured Population</span>.
                <span data-id="emphasis_52" class="annotation emphasis" style="position: unset;">J Am Acad Orthop
                  Surg.</span> 2022 Oct 15;30(20):e1348-57.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20Between%20Race%2FEthnicity%20and%20Total%20Joint%20Arthroplasty%20Utilization%20in%20a%20Universally%20Insured%20Population&as_occt=title&as_sauthors=%20%22K%20Okike%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Amen TB</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Chatterjee A</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Rudisill SS</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Joseph GP</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Nwachukwu BU</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Ode GE</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Williams RJ 3rd</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">National Patterns in
                  Utilization of Knee and Hip Arthroscopy: An Analysis of Racial, Ethnic, and Geographic Disparities in
                  the United States</span>. <span data-id="emphasis_53" class="annotation emphasis"
                  style="position: unset;">Orthop J Sports Med.</span> 2023 Aug 16;11(8):23259671231187447.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=National%20Patterns%20in%20Utilization%20of%20Knee%20and%20Hip%20Arthroscopy%3A%20An%20Analysis%20of%20Racial%2C%20Ethnic%2C%20and%20Geographic%20Disparities%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22TB%20Amen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Alvarez PM</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> McKeon JF</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Spitzer AI</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Krueger CA</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Pigott M</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Li M</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Vajapey SP</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Race, Utilization, and Outcomes
                  in Total Hip and Knee Arthroplasty: A Systematic Review on Health-Care Disparities</span>. <span
                  data-id="emphasis_54" class="annotation emphasis" style="position: unset;">JBJS Rev.</span> 2022 Mar
                1;10(3):e21.00061.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Race%2C%20Utilization%2C%20and%20Outcomes%20in%20Total%20Hip%20and%20Knee%20Arthroplasty%3A%20A%20Systematic%20Review%20on%20Health-Care%20Disparities&as_occt=title&as_sauthors=%20%22PM%20Alvarez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Arpey NC</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Gaglioti AH</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Rosenbaum ME</span>. <span
                  data-id="strong_56" class="annotation strong" style="position: unset;">How Socioeconomic Status
                  Affects Patient Perceptions of Health Care: A Qualitative Study</span>. <span data-id="emphasis_55"
                  class="annotation emphasis" style="position: unset;">J Prim Care Community Health.</span> 2017
                Jul;8(3):169-75.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20Socioeconomic%20Status%20Affects%20Patient%20Perceptions%20of%20Health%20Care%3A%20A%20Qualitative%20Study&as_occt=title&as_sauthors=%20%22NC%20Arpey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Best R</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Meister A</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Meier M</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Huth J</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Becker U</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Predictive Factors Influencing
                  Functional Results After Proximal Hamstring Tendon Avulsion Surgery: A Patient-Reported Outcome Study
                  After 227 Operations From a Single Center</span>. <span data-id="emphasis_56"
                  class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span> 2021 Oct
                29;9(10):23259671211043097.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictive%20Factors%20Influencing%20Functional%20Results%20After%20Proximal%20Hamstring%20Tendon%20Avulsion%20Surgery%3A%20A%20Patient-Reported%20Outcome%20Study%20After%20227%20Operations%20From%20a%20Single%20Center&as_occt=title&as_sauthors=%20%22R%20Best%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_59" style="position: unset;">
            <div class="content" style="position: unset;">59&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Fenn TW</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Kaplan DJ</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Brusalis CM</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Chapman RS</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Larson JH</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Nho SJ</span>. <span
                  data-id="strong_58" class="annotation strong" style="position: unset;">Functional Outcome Scores and
                  Conversion to Total Hip Arthroplasty After Hip Arthroscopy for Femoroacetabular Impingement Syndrome
                  in Patients With Tönnis Grade 1 Versus Grade 0 Arthritis: A Propensity-Matched Study at Minimum
                  10-Year Follow-up</span>. <span data-id="emphasis_57" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2024 Jan;52(1):34-44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Functional%20Outcome%20Scores%20and%20Conversion%20to%20Total%20Hip%20Arthroplasty%20After%20Hip%20Arthroscopy%20for%20Femoroacetabular%20Impingement%20Syndrome%20in%20Patients%20With%20T%C3%B6nnis%20Grade%201%20Versus%20Grade%200%20Arthritis%3A%20A%20Propensity-Matched%20Study%20at%20Minimum%2010-Year%20Follow-up&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_60" style="position: unset;">
            <div class="content" style="position: unset;">60&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Cancienne J</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Kunze KN</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Beck EC</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Chahla J</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Suppauksorn S</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Nho SJ</span>. <span
                  data-id="strong_59" class="annotation strong" style="position: unset;">Influence of Cigarette Smoking
                  at the Time of Surgery on Postoperative Outcomes in Patients With Femoroacetabular Impingement: A
                  Matched-Pair Cohort Analysis</span>. <span data-id="emphasis_58" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2019 Apr;47(5):1138-44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Influence%20of%20Cigarette%20Smoking%20at%20the%20Time%20of%20Surgery%20on%20Postoperative%20Outcomes%20in%20Patients%20With%20Femoroacetabular%20Impingement%3A%20A%20Matched-Pair%20Cohort%20Analysis&as_occt=title&as_sauthors=%20%22J%20Cancienne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_61" style="position: unset;">
            <div class="content" style="position: unset;">61&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Shapira J</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Glein RM</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Yelton MJ</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Maldonado DR</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Lall AC</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Domb BG</span>. <span
                  data-id="strong_60" class="annotation strong" style="position: unset;">Intra-articular Damage and
                  Patient Outcome Comparison Between Athletes and Nonathletes After Hip Arthroscopy</span>. <span
                  data-id="emphasis_59" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2022 Jul;50(8):2165-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intra-articular%20Damage%20and%20Patient%20Outcome%20Comparison%20Between%20Athletes%20and%20Nonathletes%20After%20Hip%20Arthroscopy&as_occt=title&as_sauthors=%20%22J%20Shapira%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_62" style="position: unset;">
            <div class="content" style="position: unset;">62&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Frank RM</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Lee S</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Bush-Joseph CA</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Salata MJ</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Mather RCI 3rd</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Nho SJ</span>. <span
                  data-id="strong_61" class="annotation strong" style="position: unset;">Outcomes for Hip Arthroscopy
                  According to Sex and Age: A Comparative Matched-Group Analysis</span>. <span data-id="emphasis_60"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2016 May
                18;98(10):797-804.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1253941"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_63" style="position: unset;">
            <div class="content" style="position: unset;">63&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Domb BG</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Chaharbakhshi EO</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Rybalko D</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Close MR</span>, <span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Litrenta J</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Perets I</span>. <span
                  data-id="strong_62" class="annotation strong" style="position: unset;">Outcomes of Hip Arthroscopic
                  Surgery in Patients With Tönnis Grade 1 Osteoarthritis at a Minimum 5-Year Follow-up: A Matched-Pair
                  Comparison With a Tönnis Grade 0 Control Group</span>. <span data-id="emphasis_61"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2017
                Aug;45(10):2294-302.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20Hip%20Arthroscopic%20Surgery%20in%20Patients%20With%20T%C3%B6nnis%20Grade%201%20Osteoarthritis%20at%20a%20Minimum%205-Year%20Follow-up%3A%20A%20Matched-Pair%20Comparison%20With%20a%20T%C3%B6nnis%20Grade%200%20Control%20Group&as_occt=title&as_sauthors=%20%22BG%20Domb%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_64" style="position: unset;">
            <div class="content" style="position: unset;">64&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Lee MS</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Mahatme RJ</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Simington J</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Gillinov SM</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Kim DN</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Moran J</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Islam W</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Fong S</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Pettinelli N</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Lee AY</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Jimenez AE</span>. <span
                  data-id="strong_63" class="annotation strong" style="position: unset;">Over 50% of Studies Report
                  Low-Back Pain Is Associated With Worse Outcomes After Hip Arthroscopy When Compared With a Control
                  Group: A Systematic Review</span>. <span data-id="emphasis_62" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2023 Dec;39(12):2547-67.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Over%2050%25%20of%20Studies%20Report%20Low-Back%20Pain%20Is%20Associated%20With%20Worse%20Outcomes%20After%20Hip%20Arthroscopy%20When%20Compared%20With%20a%20Control%20Group%3A%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22MS%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_65" style="position: unset;">
            <div class="content" style="position: unset;">65&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Kunze KN</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Nwachukwu BU</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Beck EC</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Chahla J</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Gowd AK</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Rasio J</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Nho SJ</span>. <span
                  data-id="strong_64" class="annotation strong" style="position: unset;">Preoperative Duration of
                  Symptoms Is Associated With Outcomes 5 Years After Hip Arthroscopy for Femoroacetabular Impingement
                  Syndrome</span>. <span data-id="emphasis_63" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2020 Apr;36(4):1022-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20Duration%20of%20Symptoms%20Is%20Associated%20With%20Outcomes%205%20Years%20After%20Hip%20Arthroscopy%20for%20Femoroacetabular%20Impingement%20Syndrome&as_occt=title&as_sauthors=%20%22KN%20Kunze%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_66" style="position: unset;">
            <div class="content" style="position: unset;">66&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Carreira DS</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Shaw DB</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Wolff AB</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Christoforetti JJ</span>, <span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Salvo JPR</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> R Kivlan B</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Matsuda DK</span>. <span
                  data-id="strong_65" class="annotation strong" style="position: unset;">Symptom duration predicts
                  inferior mid-term outcomes following hip arthroscopy</span>. <span data-id="emphasis_64"
                  class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2022
                Dec;46(12):2837-43.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Symptom%20duration%20predicts%20inferior%20mid-term%20outcomes%20following%20hip%20arthroscopy&as_occt=title&as_sauthors=%20%22DS%20Carreira%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_67" style="position: unset;">
            <div class="content" style="position: unset;">67&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Hall A</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Dandu N</span>, <span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Sonnier JH</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Rao S</span>, <span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Holston K</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Liu J</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Freedman K</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Tjoumakaris F</span>. <span
                  data-id="strong_66" class="annotation strong" style="position: unset;">The Influence of Psychosocial
                  Factors on Hip Surgical Disorders and Outcomes After Hip Arthroscopy: A Systematic Review</span>.
                <span data-id="emphasis_65" class="annotation emphasis" style="position: unset;">Arthroscopy.</span>
                2022 Dec;38(12):3194-206.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Influence%20of%20Psychosocial%20Factors%20on%20Hip%20Surgical%20Disorders%20and%20Outcomes%20After%20Hip%20Arthroscopy%3A%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22A%20Hall%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_68" style="position: unset;">
            <div class="content" style="position: unset;">68&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Horner N</span>, <span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Chapman RS</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Larson J</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Hevesi M</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Nho SJ</span>. <span
                  data-id="strong_67" class="annotation strong" style="position: unset;">Workers’ Compensation Patients
                  Undergoing Hip Arthroscopy for Femoroacetabular Impingement Syndrome Experience Worse Mid-Term
                  Outcomes but Similar Return-to-Work: A Propensity-Matched Analysis at 5-Year Follow-Up</span>. <span
                  data-id="emphasis_66" class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2023
                Nov;39(11):2293-2299.e1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Workers%E2%80%99%20Compensation%20Patients%20Undergoing%20Hip%20Arthroscopy%20for%20Femoroacetabular%20Impingement%20Syndrome%20Experience%20Worse%20Mid-Term%20Outcomes%20but%20Similar%20Return-to-Work%3A%20A%20Propensity-Matched%20Analysis%20at%205-Year%20Follow-Up&as_occt=title&as_sauthors=%20%22N%20Horner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_69" style="position: unset;">
            <div class="content" style="position: unset;">69&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_374" class="annotation" style="position: unset;"> Bonazza NA</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Homcha B</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Liu G</span>, <span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Leslie DL</span>, <span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Dhawan A</span>. <span
                  data-id="strong_68" class="annotation strong" style="position: unset;">Surgical Trends in Arthroscopic
                  Hip Surgery Using a Large National Database</span>. <span data-id="emphasis_67"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2018
                Jun;34(6):1825-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20Trends%20in%20Arthroscopic%20Hip%20Surgery%20Using%20a%20Large%20National%20Database&as_occt=title&as_sauthors=%20%22NA%20Bonazza%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_70" style="position: unset;">
            <div class="content" style="position: unset;">70&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Hale RF</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Melugin HP</span>, <span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Zhou J</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> LaPrade MD</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Bernard C</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Leland D</span>, <span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Levy BA</span>, <span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Krych AJ</span>. <span
                  data-id="strong_69" class="annotation strong" style="position: unset;">Incidence of Femoroacetabular
                  Impingement and Surgical Management Trends Over Time</span>. <span data-id="emphasis_68"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2021
                Jan;49(1):35-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20of%20Femoroacetabular%20Impingement%20and%20Surgical%20Management%20Trends%20Over%20Time&as_occt=title&as_sauthors=%20%22RF%20Hale%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_71" style="position: unset;">
            <div class="content" style="position: unset;">71&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Maradit Kremers H</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Schilz SR</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Van Houten HK</span>, <span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Herrin J</span>, <span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Koenig KM</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Bozic KJ</span>, <span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Berry DJ</span>. <span
                  data-id="strong_70" class="annotation strong" style="position: unset;">Trends in Utilization and
                  Outcomes of Hip Arthroscopy in the United States Between 2005 and 2013</span>. <span
                  data-id="emphasis_69" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2017
                Mar;32(3):750-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20in%20Utilization%20and%20Outcomes%20of%20Hip%20Arthroscopy%20in%20the%20United%20States%20Between%202005%20and%202013&as_occt=title&as_sauthors=%20%22H%20Maradit%20Kremers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6645dbc9-fc41-462e-b816-bf81761ff562&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[]}"
                href="/php/content/content_api.php?op=download&rsuite_id=6645dbc9-fc41-462e-b816-bf81761ff562&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 17.4972%;"></div>
                  <div class="pages" style="position: unset;"></div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D6645dbc9-fc41-462e-b816-bf81761ff562%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2232</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00217</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 15, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_70"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Rush
                            University Medical Center, Chicago, Illinois</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;6645dbc9-fc41-462e-b816-bf81761ff562&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=6645dbc9-fc41-462e-b816-bf81761ff562&type=pdf&name=JBJS.24.00217.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=6645dbc9-fc41-462e-b816-bf81761ff562&type=pdf&name=JBJS.24.00217.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_76"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_77" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I243"
                            target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I243</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;6645dbc9-fc41-462e-b816-bf81761ff562&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=6645dbc9-fc41-462e-b816-bf81761ff562&type=zip&name=JBJS.24.00217.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Omair Kazi, MS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0001-3963-9210" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0001-3963-9210</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alexander B. Alvero, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0004-5976-6374" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0004-5976-6374</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joshua P. Castle, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6006-5397" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6006-5397</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael J. Vogel, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-3151-1902" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-3151-1902</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Stephanie A. Boden, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1995-1116" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1995-1116</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joshua Wright-Chisem, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3760-388X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3760-388X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shane J. Nho, MD, MS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:nho.research@rushortho.com" class=""
                    style="position: unset;">nho.research@rushortho.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2789-1531" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2789-1531</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Section of Young Adult Hip Surgery, Division of Sports Medicine, Department of
                Orthopaedic Surgery, Rush University Medical Center, Rush Medical College of Rush University, Chicago,
                Illinois</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Henry Ford Health, Detroit, Michigan</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 37457.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
