/* -----------------Globals--------------- */
import {
	type DrawerNavigationEventMap,
	type DrawerNavigationOptions,
	createDrawerNavigator,
} from "@react-navigation/drawer";
import type {
	ParamListBase,
	StackNavigationState,
} from "@react-navigation/native";

/* -----------------Child components--------------- */
import { withLayoutContext } from "expo-router";

const { Navigator } = createDrawerNavigator();

export const Drawer = withLayoutContext<
	DrawerNavigationOptions,
	typeof Navigator,
	StackNavigationState<ParamListBase>,
	DrawerNavigationEventMap
>(Navigator);
