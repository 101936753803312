import type { Article } from "../../../../../types";

export const Article637: Article = {
	id: 637,
	rsuiteId: "94811891-30df-467d-86f6-166098bd27eb",
	type: "the orthopaedic forum",
	title:
		"Identifying Predictors of Successful Conversion from OREF Grants to Extramural Federal Funding Among Orthopaedic Surgeons",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=94811891-30df-467d-86f6-166098bd27eb&type=jpeg&name=JBJS.24.00643f1",
	subSpecialties: ["trauma"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Identifying Predictors of Successful Conversion from OREF
                Grants to Extramural Federal Funding Among Orthopaedic Surgeons</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Alan K. Li,
                      BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Joseph K.
                      Kendal, MD, MSc</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 8 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_17" class="annotation strong"
                          style="position: unset;">Background:</span> Musculoskeletal diseases are a major contributor
                        to global human disability, but research in this area of medicine contends with chronic
                        underfunding in the United States, receiving &lt;2% of the National Institutes of Health (NIH)
                        budget. The Orthopaedic Research and Education Foundation (OREF) serves as a vital
                        nongovernmental organization, providing essential support for orthopaedic research. This study
                        investigated the translation of various OREF grants into successful extramural federal funding
                        discerning the clinician demographics and OREF award characteristics associated with increased
                        chances of securing federal grants.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_18" class="annotation strong"
                          style="position: unset;">Methods:</span> We included OREF data from 1994 to 2020, and federal
                        funding from the NIH, the U.S. Department of Defense (DoD), the National Science Foundation
                        (NSF), and the Agency of Healthcare Research and Quality (AHRQ) was queried using publicly
                        available databases: the NIH RePORTER, the DoD Defense Technical Information Center, the NSF
                        search engine, and the AHRQ search engine. Clinician demographics and training were collected
                        from the American Academy of Orthopaedic Surgeons roster and institution departmental websites.
                        The successful translation of OREF grants into federal awards was assessed based on clinician
                        gender, training, and specialty, as well as OREF grant mechanisms and characteristics;
                        multivariable logistic regression was used to determine the likelihood of federal translation
                        based on these predictor variables.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_19" class="annotation strong"
                          style="position: unset;">Results:</span> Three hundred and forty-eight OREF grants were
                        awarded to 319 different clinicians, 23.5% of whom successfully translated their award into a
                        federal grant. Notably, recipients of the OREF Career Development Grant (CDG) demonstrated a
                        48.4% translation rate to federal funding. The federal return on investment for all of the OREF
                        grants averaged 503%, translating approximately $34 million in OREF awards into &gt;$200 million
                        in federal funding. Logistic regression revealed that recipients who received CDG funding, as
                        well as orthopaedic oncologists, were more than 3 times as likely to secure federal funding than
                        those who received grants with other mechanisms and those in other subspecialties.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_20" class="annotation strong"
                          style="position: unset;">Conclusions:</span> OREF fills a vital role in supporting budding
                        orthopaedic research careers, ensuring the field’s competitiveness for federal awards on a
                        national scale. The success rates, particularly for CDG recipients, highlight OREF’s
                        effectiveness in identifying promising surgeon-scientists.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Musculoskeletal research in the United States remains
                  disproportionately underfunded at the federal level<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Despite representing the second leading cause of human disability worldwide, musculoskeletal research
                  in the U.S. is funded less than all other surgical disciplines except for urology by the National
                  Institutes of Health (NIH)<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>. In
                  fact, &lt;2% of the NIH budget is allocated toward musculoskeletal research<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a>.
                  Therefore, the Orthopaedic Research and Education Foundation (OREF) plays an important role as the
                  primary nongovernmental organization for funding research across orthopaedic subspecialties.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Recognizing the dearth of federally funded musculoskeletal
                  researchers, a stated goal of the OREF is to promote career development for faculty in orthopaedic
                  surgery who plan to make research a central component of their careers. Its numerous grants are
                  intended to assist residents, postdoctoral researchers, fellows, and junior faculty in establishing
                  groundwork that may help pave the way toward building their careers as surgeon-scientists and toward
                  garnering larger sources of funding. Since its inception in 1955, the OREF has awarded &gt;$143
                  million for research and education initiatives<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a>. In a
                  previous study, our group demonstrated that 46% of recipients of the OREF Career Developmental Grant
                  (CDG) successfully went on to secure NIH funding—a rate more than double the average application
                  success rate<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">7</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">The purposes of this study were to determine the rate at
                  which various OREF granting mechanisms translate to future successful extramural federal funding and
                  to identify orthopaedic surgeon faculty demographics that may be associated with a higher likelihood
                  of securing federal funding. We focused on surgeon-scientists to determine the impact that OREF awards
                  have on the development of clinician research careers. This study expands on our previous analysis by
                  including additional OREF grant awardees and grant mechanisms, an increased follow-up interval after
                  the initial OREF grant award, and, importantly, additional sources of federal funding (the U.S.
                  Department of Defense [DoD], the National Science Foundation [NSF], and the Agency for Healthcare
                  Research and Quality [AHRQ]).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Material and
                Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Data regarding all OREF grants from 1994 to 2020 were
                  generously provided by the OREF, including the name of the principal investigator, the institution,
                  the grant mechanism, the grant amount, and the year that the grant was awarded. OREF grants that were
                  awarded after federal awards and those awarded to part-time faculty, volunteer faculty, PhD-only
                  faculty, or trainees (residents and students) were excluded in order to assess the translation of
                  orthopaedic surgeon faculty-level grants into securing future federal funding. The following OREF
                  grant mechanisms were included in our analysis: the CDG, the Zimmer Orthopaedic Career Development
                  Grant (zCDG), the OREF Research Grant, the OREF Clinical Research Award (consisting of 5 different
                  mechanisms dedicated to clinical research), and a catchall “other” category (consisting of 19
                  different mechanisms) (see Appendix and <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). OREF
                  grants were also categorized as Basic Science-Cell Biology, Basic Science-Biomechanical, Clinical, or
                  Epidemiological.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00643f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=94811891-30df-467d-86f6-166098bd27eb&type=jpeg&name=JBJS.24.00643f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_12" style="position: unset;">
                        <div class="content" style="position: unset;">Total and mean value of all OREF grants from 1994
                          to 2020 by grant mechanism.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Federal funding that was made to faculty who were listed
                  as principal investigators on the award was queried using the following publicly available databases:
                  the NIH RePORTER (grant information from 1992 to present), the DoD Defense Technical Information
                  Center (grant information from 2010 to present), the NSF search engine (grant information from 1976 to
                  present), and the AHRQ search engine (grant information from 2004 to present)<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">11</span></a>.
                  Successful translation into a federal award was defined as occurring when faculty secured federal
                  funding at any point in time after their OREF grant. For faculty with multiple OREF grants, only their
                  first award was considered. The federal grant value was the sum of the individual grant values over
                  the study period.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The publicly available roster of the American Academy of
                  Orthopaedic Surgeons (AAOS) was queried to determine the practice settings and subspecialties of all
                  of the OREF awardees. This information was cross-referenced with departmental websites that provided
                  additional demographic information to query the following key demographics: gender, training, and
                  orthopaedic specialty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Statistical analyses were performed using RStudio, version
                  4.2.0 (Posit). Multivariable logistic regression using faculty demographics, OREF grant mechanism,
                  grant class, and grant amount as independent variables determined the likelihood of securing federal
                  funding. The multicollinearity of independent variables was determined by calculating the variance
                  inflation factor (VIF); a VIF of &gt;5 indicated high correlation, and such variables were removed
                  from the regression model<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">12</span></a>. The
                  federal return on investment of the OREF grants was computed for all of the OREF grants and was
                  stratified by grant mechanism. The return on investment was determined by calculating the difference
                  between the total value of the federal grants and the total value of the OREF grants, and then
                  dividing that difference by the total value of the OREF grants. Significance was defined as p &lt;
                  0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Funding
                Overview</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">OREF
                Awards</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Three hundred and forty-eight of 755 OREF grants that were
                  awarded from 1994 to 2020 met the inclusion criteria and were included in our study. Grant awardees
                  were excluded from the analysis if they had received resident- or trainee-level grants (n = 255), were
                  nonsurgical faculty (e.g., PhDs and physical therapists) (n = 69), had been awarded an NIH grant prior
                  to their OREF award (n = 43), or had no academic affiliation identified via our search mechanisms (n =
                  40). The 348 OREF awards were awarded to 319 different orthopaedic surgeon faculty members and totaled
                  $33,969,147 in value.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">With 133 awards, the OREF Research Grant was the most
                  commonly awarded grant, and it had the highest total value at $11,902,716. The CDG, which was awarded
                  39 times, had the highest mean value at $201,624 (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 1</a> and <a
                    href="" data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">2-A</a>). In terms of grant class, Basic Science-Cell Biology was the most
                  common grant class with 155 OREF awards, followed by 47 Basic Science-Biomechanical grants, 124
                  Clinical grants, and 22 Epidemiological grants (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-B</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00643f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=94811891-30df-467d-86f6-166098bd27eb&type=jpeg&name=JBJS.24.00643f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;">Number of OREF grants awarded from 1994 to 2020 by
                          grant mechanism (<span data-id="strong_23" class="annotation strong"
                            style="position: unset;">Fig. 2-A</span>) and grant class (<span data-id="strong_24"
                            class="annotation strong" style="position: unset;">Fig. 2-B</span>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Federal
                Awards</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Seventy-five of the 319 faculty members successfully
                  secured a federal award, at a mean interval of 6.5 years after their initial OREF award, yielding an
                  overall translation rate from OREF into federal awards of 23.5%. The first federal awards secured by
                  these faculty included 69 NIH awards, 4 DoD awards, and 2 NSF awards. These faculty subsequently
                  secured a total of 180 federal awards, including 163 NIH awards, 10 DoD awards, 6 NSF awards, and 1
                  AHRQ award, for a total value of $204,679,354.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The federal funding represented a return on investment for
                  all of the OREF awards in our study of 503%, ranging from a low of 80% for the zCDG to a high of 822%
                  for the CDG (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Return on Investment for All OREF Grants Awarded from
                          1994 to 2020 by Grant Mechanism</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 397px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Grant Mechanism</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total OREF
                          Grant Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total
                          Federal Grant Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Return on
                          Investment</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OREF Research</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$11,902,716</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$82,342,478</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">592%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CDG</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$7,863,334</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$72,479,230</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">822%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">zCDG</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$2,275,577</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$4,099,228</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OREF Clinical Research
                          Award</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$4,952,501</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$25,891,513</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">423%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Other</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$6,975,019</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$19,866,905</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">185%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Overall</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$33,969,147</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$204,679,354</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">503%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Federal
                Success by Demographics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Of the 319 faculty who were awarded OREF grants, 44.8% of
                  faculty with MD-PhDs successfully translated OREF grants into federal awards compared with 21.4% of
                  faculty with MDs (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3-A</a>); 20.0%
                  of female faculty and 23.9% of male faculty received federal funding (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3-B</a>). Regarding subspeciality, the oncology faculty secured the
                  greatest number of federal awards (16) and demonstrated the highest translation rate from OREF grants
                  into federal awards at 50% (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3-C</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00643f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=94811891-30df-467d-86f6-166098bd27eb&type=jpeg&name=JBJS.24.00643f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">OREF grant recipients from 1994 to 2020 who
                          successfully secured federal funding by degree (<span data-id="strong_25"
                            class="annotation strong" style="position: unset;">Fig. 3-A</span>), gender (<span
                            data-id="strong_26" class="annotation strong" style="position: unset;">Fig. 3-B</span>), and
                          orthopaedic subspecialty (<span data-id="strong_27" class="annotation strong"
                            style="position: unset;">Fig. 3-C</span>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Federal
                Success by OREF Grant</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The median value of the OREF grants that were successfully
                  translated to federal awards was $98,298 (interquartile range width [IQR], $100,000) compared with
                  $86,940 (IQR, 50,000) for those that were not successfully translated (<a href=""
                    data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00643f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=94811891-30df-467d-86f6-166098bd27eb&type=jpeg&name=JBJS.24.00643f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;">The value of OREF grants that were successfully
                          and unsuccessfully translated into federal awards. A box indicates the interquartile range,
                          the line within the box indicates the median, the X indicates the mean, and the whiskers
                          indicate the range.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">When assessing federal success by OREF grant mechanisms,
                  the CDG recipients demonstrated the highest rate of translation into federal awards (48.4%). This was
                  followed by a translation rate of 23.8% for OREF Clinical Grant recipients, 23.0% for OREF Research
                  Grant recipients, 19.6% for zCDG recipients, and 16.7% for recipients of other OREF grants (<a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5-A</a>). Regarding OREF grant class, recipients of Basic Science-Cell
                  Biology grants demonstrated the highest rates of translation into federal awards at 31.7%. The was
                  followed by a translation rate of 26.3% for Epidemiological grants, 21.7% for Basic
                  Science-Biomechanical grants, and 13.9% for Clinical grants (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5-B</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00643f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=94811891-30df-467d-86f6-166098bd27eb&type=jpeg&name=JBJS.24.00643f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">OREF grant recipients from 1994 to 2020 who
                          successfully secured federal funding by OREF grant mechanism (<span data-id="strong_28"
                            class="annotation strong" style="position: unset;">Fig. 5-A</span>) and grant class (<span
                            data-id="strong_29" class="annotation strong" style="position: unset;">Fig. 5-B</span>).
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Predicting
                Federal Success</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Multivariable logistic regression using faculty
                  demographics and OREF grant mechanism, grant class, and grant amount to predict the translation rate
                  into federal awards determined that orthopaedic oncology faculty (odds ratio [OR], 3.13; 95%
                  confidence interval [CI], 1.16 to 8.49; p = 0.025) and OREF CDG recipients (OR, 3.56; 95% CI, 1.14 to
                  11.08; p = 0.029) were more than 3 times as likely to translate an OREF grant into a federal award
                  than faculty in other subspecialties and those with funding from other grant mechanisms. Recipients of
                  OREF grants in the Clinical grant class were nearly 3 times less likely to translate their grant into
                  a federal award (OR, 0.36; 95% CI, 0.17 to 0.79; p = 0.010) (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Multivariable Logistic Regression of Predictors of
                          Successful Translation into Federal Awards*</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1237px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Predictor</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.45</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.52 to
                          4.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.478</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Degree</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> MD</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> MD-PhD</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.96</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.78 to
                          4.95</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.153</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Subspecialty</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Arthroplasty</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pediatrics</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.59</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.94 to
                          7.10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.065</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Spine</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.52</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.18 to
                          1.52</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.230</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Oncology</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.16 to
                          8.49</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_30" class="annotation strong" style="position: unset;">0.025</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Trauma</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.92</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.28 to
                          2.99</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.884</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Shoulder & elbow</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.43</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.13 to
                          1.48</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.180</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hand</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.74</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.24 to
                          2.37</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.615</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Sports</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.66</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.23 to
                          1.90</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.445</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Foot & ankle</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.90</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.21 to
                          3.82</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.885</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Grant mechanism</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> OREF Research</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> CDG</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.56</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.14 to
                          11.08</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_31" class="annotation strong" style="position: unset;">0.029</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> zCDG</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.91</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.34 to
                          2.44</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.856</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> OREF Clinical</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.00</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.74 to
                          5.43</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.173</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.94</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.42 to
                          2.13</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.885</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Grant class</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Basic
                          Science-Cell</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Basic
                          Science-Biomechanical</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.83</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.35 to
                          1.98</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.668</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Clinical</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.36</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.17 to
                          0.79</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_32" class="annotation strong" style="position: unset;">0.010</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Epidemiological</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.01</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.31 to
                          3.33</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.988</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OREF grant amount (ref. =
                          $900,000)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.99 to
                          1.00</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.676</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">The OREF is the preeminent specialty-specific source of
                  orthopaedic funding in the U.S., playing an important role in supporting new investigators in
                  acquiring support prior to federal funding. This study aimed to understand the impact of faculty
                  demographics and prior OREF awards on the securing of federal funding. From 1994 to 2020, OREF awarded
                  755 grants, of which 348 (worth nearly $34 million) were given to orthopaedic surgeons with faculty
                  appointments, thus meeting our inclusion criteria. Regarding the OREF orthopaedic surgeon grant
                  recipients, 23.5% successfully translated their award into a federal grant. Recipients of the
                  prestigious OREF CDG translated their award into a federal grant at a rate of 48.4%, which is more
                  than double that of all other OREF grant mechanisms. Moreover, recipients of an OREF CDG were more
                  than 3 times as likely to go on to secure federal funding, even after accounting for differences in
                  faculty demographics and OREF award amounts.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">In 2022, the overall success rate of NIH grant
                  applications was 20.6%<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">13</span></a>,
                  whereas the success rate for surgeon-scientists across all surgical specialties was only 16.4%<a
                    href="" data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">15</span></a>. The
                  increased frequency at which OREF awardees, and particularly CDG recipients, attain federal funding
                  points to the success of the OREF in identifying promising orthopaedic surgeon-scientists and
                  supporting their nascent research careers. It also underscores the importance of clinician development
                  models in helping to jump-start the careers of young investigators. When considering specialties
                  within orthopaedics, orthopaedic oncologists were more than 3 times as likely to translate their OREF
                  grants into federal awards than those in other subspecialties. This may be related to the increased
                  involvement of orthopaedic oncologists in multidisciplinary cancer research, which is appealing to
                  federal entities.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">In this study, NIH awards made up the majority of the
                  total value and number of first-time federal grants secured by orthopaedic surgeons. This was followed
                  by 10 DoD grants, 4 of which represented first-time federal awards. It is possible that organizations
                  such as the DoD, the NSF, and the AHRQ are less conventional sources of public funding in this area of
                  medicine and they receive a lower volume of award applications; thus, they constitute a smaller
                  portion of the federal awards in this study. Nevertheless, the 10 DoD grants included in our analysis
                  were all awarded after 2016, hinting at a possible increase in the dedication of financial resources
                  to orthopaedic research by these organizations or a potential increase in faculty awareness of these
                  funding opportunities.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">This study had several limitations. Our investigation into
                  award translation rates considered only first-time OREF awards. It is possible that repeat OREF
                  awardees experience even greater federal success, which would not have been captured in our analysis.
                  Additionally, the majority of first-time federal awards were from the NIH. As such, it is possible
                  that we are underestimating the impact that OREF has in aiding investigators in procuring funding from
                  other sources. As orthopaedic funding from the DoD becomes more common, future studies may better
                  elucidate this relationship. The mean interval for the translation of OREF awards into federal awards
                  was &gt;6 years; our analysis was likely unable to capture the translational impact of more recent
                  OREF awards toward federal success and, as a result, may underestimate the true translation rate and
                  federal return on investment. It is also important to note the cohorts of awardees that were excluded
                  from this analysis. OREF awards allocated to residents were excluded, as these grants are distinct
                  awards of smaller amounts that are given to trainees at an earlier stage in their “differentiation”
                  into surgeon-scientists. These awards are clearly intended to support orthopaedic surgeons at an
                  earlier phase of their career and thus have different characteristics in terms of time to translation
                  as well as specialty (many residents do research in a field in which they do end up subspecializing).
                  As such, these valuable data were excluded from this analysis but have been reported in a recent
                  publication<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">16</span></a>. We
                  also chose to exclude nonsurgical faculty from this analysis (e.g., PhDs and physical therapists).
                  This group has only been considered for a subset of the OREF awards (e.g., only 1 has been awarded the
                  CDG) and, as such, their inclusion would have potentially biased the data comparing granting
                  mechanisms or subspecialties. Lastly, the results of this study are historical in nature. For this
                  reason, the implications drawn from these findings are limited in their ability to inform future
                  federal success.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, 23.5% of orthopaedic surgeons receiving an
                  OREF grant successfully secured a federal award, with winners of the CDG demonstrating rates of
                  success at the federal level as high as 48.4%. From a monetary standpoint, OREF awards yield a robust
                  return on investment at the federal level (&gt;500% on average). Our findings underscore the
                  importance of OREF grants as a source of initial funding for orthopaedic surgeon-scientists. Grants
                  awarded by the OREF are important not only for supporting investigators during the years that they are
                  funded, but also in launching young researchers to an NIH funding rate that is comparable with that of
                  nonsurgical faculty (20.7%). In this way, the OREF helps ensure that the field of orthopaedic surgery
                  contends with other specialties for federal awards on a level playing field.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_8" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I222" target="_blank" data-id="link_9" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I222</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Silvestre J</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Ahn J</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Levin LS</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">National Institutes of Health
                  Funding to Departments of Orthopaedic Surgery at U.S. Medical Schools</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2017 Jan 18;99(2):e5.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1270464"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Imam N</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Sudah SY</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Shaikh SZ</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Nicholson AD</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Namdari S</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Menendez ME</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">National Institutes of Health
                  Funding to Departments of Orthopaedic Surgery at U.S. Medical Schools from 2015 to 2021</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2023 Aug 2;105(15):1205-13.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=d738b4eb-2eae-456f-b768-032b8aae0da2" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Jacobs JJ</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> King TRW</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Klippel JH</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Berven SH</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Burr DB</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Caskey PM</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Elderkin AL</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Esposito PW</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Gall EP</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Goldring SR</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Pollak AN</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Sandborg CI</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Templeton KJ</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Beyond the decade: strategic
                  priorities to reduce the burden of musculoskeletal disease</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2013 Sep
                4;95(17):e1251-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Beyond%20the%20decade%3A%20strategic%20priorities%20to%20reduce%20the%20burden%20of%20musculoskeletal%20disease&as_occt=title&as_sauthors=%20%22JJ%20Jacobs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Weinstein SL</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The Burden of Musculoskeletal
                  Conditions</span>. <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2016 Aug 17;98(16):1331.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1258787" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Wurth GR</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">OREF: discovering the future of
                  orthopaedics</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J
                  Am Acad Orthop Surg.</span> 2006 Jan;14(1):2-4.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=OREF%3A%20discovering%20the%20future%20of%20orthopaedics&as_occt=title&as_sauthors=%20%22GR%20Wurth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Hegde V</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Johansen D</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Park HY</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Zoller SD</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Hamad C</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Bernthal NM</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The Relationship Between OREF
                  Grants and Future NIH Funding Success</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2017 Aug 16;99(16):e87.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1391562" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;">NIH RePORT.
                <span data-id="strong_7" class="annotation strong" style="position: unset;">Research Project Grants:
                  Competing Applications, Awards, and Success Rates</span>. Accessed 2024 Jul 23. <a
                  href="https://report.nih.gov/nihdatabook/report/20" target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://report.nih.gov/nihdatabook/report/20</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Research%20Project%20Grants%3A%20Competing%20Applications%2C%20Awards%2C%20and%20Success%20Rates&as_occt=title&as_sauthors=%20%22NIH%20RePORT%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;">NIH RePORT.
                <span data-id="strong_8" class="annotation strong" style="position: unset;">NIH RePORTER</span>.
                Accessed 2024 Jul 23. <a href="https://reporter.nih.gov/" target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://reporter.nih.gov/</a>.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=NIH%20RePORTER&as_occt=title&as_sauthors=%20%22NIH%20RePORT%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;">DoD Grant
                Awards Defense Technical Information Center. <span data-id="strong_9" class="annotation strong"
                  style="position: unset;">DoD Grant Awards Search</span>. Accessed 2024 Jul 23. <a
                  href="https://discover.dtic.mil/grant/" target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://discover.dtic.mil/grant/</a>.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=DoD%20Grant%20Awards%20Search&as_occt=title&as_sauthors=%20%22DoD%20Grant%20Awards%20Defense%20Technical%20Information%20Center%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;">National
                Science Foundation. <span data-id="strong_10" class="annotation strong" style="position: unset;">Awards
                  Simple Search</span>. Accessed 2024 Jul 23. <a href="https://www.nsf.gov/awardsearch" target="_blank"
                  data-id="link_4" class="link"
                  style="position: unset;">https://www.nsf.gov/awardsearch</a>.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Awards%20Simple%20Search&as_occt=title&as_sauthors=%20%22National%20Science%20Foundation%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Agency for Healthcare Research
                  and Quality</span>. Accessed 2024 Jul 23. <a href="https://www.ahrq.gov/" target="_blank"
                  data-id="link_5" class="link" style="position: unset;">https://www.ahrq.gov/</a>.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Marcoulides KM</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Raykov T</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Evaluation of Variance
                  Inflation Factors in Regression Models Using Latent Variable Modeling Methods</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Educ Psychol Meas.</span>
                2019 Oct;79(5):874-82.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20Variance%20Inflation%20Factors%20in%20Regression%20Models%20Using%20Latent%20Variable%20Modeling%20Methods&as_occt=title&as_sauthors=%20%22KM%20Marcoulides%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Lauer M</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">FY 2022 By the Numbers:
                  Extramural Grant Investments in Research</span>. Accessed 2024 Jul 23. <a
                  href="https://nexus.od.nih.gov/all/2023/03/01/fy-2022-by-the-numbers-extramural-grant-investments-in-research/"
                  target="_blank" data-id="link_6" class="link"
                  style="position: unset;">https://nexus.od.nih.gov/all/2023/03/01/fy-2022-by-the-numbers-extramural-grant-investments-in-research/</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=FY%202022%20By%20the%20Numbers%3A%20Extramural%20Grant%20Investments%20in%20Research&as_occt=title&as_sauthors=%20%22M%20Lauer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Narahari AK</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Mehaffey JH</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Hawkins RB</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Charles EJ</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Baderdinni PK</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Chandrabhatla AS</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Kocan JW</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Jones RS</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Upchurch GR Jr</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Kron IL</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Kern JA</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Ailawadi G</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Surgeon Scientists Are
                  Disproportionately Affected by Declining NIH Funding Rates</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">J Am Coll Surg.</span> 2018
                Apr;226(4):474-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgeon%20Scientists%20Are%20Disproportionately%20Affected%20by%20Declining%20NIH%20Funding%20Rates&as_occt=title&as_sauthors=%20%22AK%20Narahari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Nguyen M</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Gonzalez L</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Newman A</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Cannon A</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Zarebski SA</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Chaudhry SI</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Pomahac B</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Boatright D</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Dardik A</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Rates of National Institutes of
                  Health Funding for Surgeon-Scientists, 1995-2020</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">JAMA Surg.</span> 2023 Jul
                1;158(7):756-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rates%20of%20National%20Institutes%20of%20Health%20Funding%20for%20Surgeon-Scientists%2C%201995-2020&as_occt=title&as_sauthors=%20%22M%20Nguyen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Silvestre J</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Burgess RK</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Nelson CL</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Thompson TL</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Academic career outcomes of
                  Orthopedic Research and Education Foundation resident grant recipients</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J Orthop Res.</span> 2023
                Feb;41(2):459-65.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Academic%20career%20outcomes%20of%20Orthopedic%20Research%20and%20Education%20Foundation%20resident%20grant%20recipients&as_occt=title&as_sauthors=%20%22J%20Silvestre%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;94811891-30df-467d-86f6-166098bd27eb&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=94811891-30df-467d-86f6-166098bd27eb&type=supplement&name=12524"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D94811891-30df-467d-86f6-166098bd27eb%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): 96</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00643</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 1, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_5" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_11"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Orthopaedic Hospital Research Center, David Geffen School of Medicine, University of
                            California Los Angeles, Los Angeles, California</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_7" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;94811891-30df-467d-86f6-166098bd27eb&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=94811891-30df-467d-86f6-166098bd27eb&type=pdf&name=JBJS.24.00643.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=94811891-30df-467d-86f6-166098bd27eb&type=pdf&name=JBJS.24.00643.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_21"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_22" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I223" target="_blank" data-id="link_7"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I223</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;94811891-30df-467d-86f6-166098bd27eb&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=94811891-30df-467d-86f6-166098bd27eb&type=zip&name=JBJS.24.00643.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alan K. Li, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:akli@mednet.ucla.edu" class="" style="position: unset;">akli@mednet.ucla.edu</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2037-6262" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2037-6262</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joseph K. Kendal, MD, MSc<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9440-2294" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9440-2294</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Christopher D. Hamad, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8896-9857" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8896-9857</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nicholas V. Peterson, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-8238-9200" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-8238-9200</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Parsa Asachi, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jeremiah M. Taylor, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0064-0641" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0064-0641</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael M. Le, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1456-9532" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1456-9532</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Trevor S. Lloyd, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6592-5977" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6592-5977</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lauren E. Wessel, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6240-5981" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6240-5981</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nicholas M. Bernthal, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3338-5878" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3338-5878</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">David Geffen School of Medicine, University of California Los Angeles, Los
                Angeles, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of California Los Angeles, Los
                Angeles, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 20125.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
