export type FeedbackParams = {
	userName: string;
	email?: string;
	appName: string;
	platform: string;
	sessionId?: string;
	tenantId?: string;
	itemId?: string;
	currentAppVersion?: string;
	type?: "Review" | "Deletion";
	context?: string;
	feature?: "AI Session Analysis" | "AI QA";
	bundleId?: number;
	bundleName?: string;
	product?: string;
	companyName?: string;
	view?: string;
	runId?: string;
};
