/* -----------------Globals--------------- */
import { useEffect, useState } from "react";

import { parseAppConfig } from "@helpers/app-config";
import { buildType } from "@helpers/expo-extras";
import { isWeb } from "@helpers/platform";
import {
	isAuthenticated,
	retrieveKey,
	setUpAmplifyConfig,
} from "@memorang/helpers";
/* -----------------Helpers & Hooks--------------- */
import { useNavigationContainerRef } from "expo-router";
import { supabase } from "libs/supabase";

import { API } from "@aws-amplify/api";
/* -----------------Types--------------- */
import { Auth } from "@aws-amplify/auth";

/* -----------------Child components--------------- */
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";

import { getAwsExportsJson } from "@configs/awsexports";
import { routingInstrumentation } from "@helpers/lib/sentry";
/* -----------------Others--------------- */
import { customFonts } from "@memorang/media";
import { OneSignal } from "../libs/onesignal";
import { useAppStore } from "./useAppStore";

export default function useCachedResources() {
	const [isLoadingComplete, setLoadingComplete] = useState(false);
	const [isDarkMode, setIsDarkMode] = useState(false);

	const oneSignalAppId = useAppStore((store) => store.app.keys.oneSignalAppId);
	const setAppConfig = useAppStore((store) => store.setAppConfig);
	const finalAwsExports = getAwsExportsJson();

	const ref = useNavigationContainerRef();

	useEffect(() => {
		if (ref && !isWeb) {
			routingInstrumentation.registerNavigationContainer(ref);
		}
	}, [ref]);

	useEffect(() => {
		async function loadResourcesAndDataAsync() {
			try {
				SplashScreen.preventAutoHideAsync();
				// Load fonts
				await Font.loadAsync(customFonts);
				const darkMode = retrieveKey("isDarkMode");
				setIsDarkMode(darkMode as boolean);
				if (oneSignalAppId && OneSignal) {
					OneSignal.setAppId(oneSignalAppId);
					OneSignal.promptForPushNotificationsWithUserResponse();
				}
				const config = setUpAmplifyConfig(finalAwsExports, true, false);
				Auth.configure(config);
				API.configure(config);

				const result = await isAuthenticated();
				const latestConfig = setUpAmplifyConfig(
					finalAwsExports,
					true,
					result != null,
				);
				Auth.configure(latestConfig);
				API.configure(latestConfig);
			} catch (e) {
				console.warn(e);
				//TODO: log error
			} finally {
				setLoadingComplete(true);
				SplashScreen.hideAsync();
			}
		}

		loadResourcesAndDataAsync();
	}, [finalAwsExports, oneSignalAppId]);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await supabase
					.from("app_config")
					.select("*")
					.eq("id", buildType)
					.single();

				const parsedData = parseAppConfig(data);

				if (!parsedData) {
					throw new Error("No data found");
				}
				setAppConfig(parsedData);
			} catch (error) {
				console.error("❌ Error fetching custom app config:", error);
			}
		})();
	}, [setAppConfig]);

	return {
		isLoadingComplete,
		isDarkMode,
	};
}
