import { Pressable } from "react-native";
import { useAppTheme } from "../../useAppTheme";
const Handle = ({ onPress }: { onPress: () => void }) => {
	const theme = useAppTheme();
	return (
		<Pressable
			onPress={onPress}
			style={{
				borderRadius: 4,
				backgroundColor: theme.colors.surfaceDisabled,
				width: 72,
				height: 6,
				overflow: "hidden",
				alignSelf: "center",
				marginTop: 8,
				marginBottom: 16,
			}}
		/>
	);
};

export default Handle;
