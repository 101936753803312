import { newEvents } from "@constants/tracking";
import { getSelectSpecialtyOnboarding } from "@helpers/find-onboarding";
import { useAppStore } from "@hooks/useAppStore";
import useClerkUser from "@hooks/useClerkUser";
import { updateUserInfo } from "@memorang/api-client";
import { trackCustomEvent } from "analytics";
import { useRouter } from "expo-router";
import { useState } from "react";
import { useOnboardingStore } from "./useOnboardingStore";

export const useSpecialityOnboarding = () => {
	const [currentStepIndex, setCurrentStepIndex] = useState(0);

	const { setSpeciality, speciality, subSpecialities, setSubSpeciality } =
		useOnboardingStore();

	const router = useRouter();

	const avatarType = useAppStore((store) => store.app.avatarType);

	const { email } = useClerkUser();

	const { onboarding, appId } = useAppStore((store) => ({
		appId: store.app.id,
		onboarding: store.onboarding,
	}));
	const onboardingSpecialityStep = getSelectSpecialtyOnboarding(onboarding!);

	const mockedSpecialities = onboardingSpecialityStep?.itemsData?.items || [];

	const hasNestedItems =
		currentStepIndex === 0
			? mockedSpecialities.some((card) => card.items?.length)
			: false;

	// const currentSpecialties = onboardingRoleStep?.data!;
	const currentSpecialties = mockedSpecialities;
	const currentSelectedSpeciality = currentSpecialties.find(
		(specialty) => specialty.value === speciality,
	);
	const _defaultSelectedIds =
		currentStepIndex === 1
			? currentSelectedSpeciality?.defaultSelectedIds
			: onboardingSpecialityStep?.itemsData?.defaultSelectedIds;

	// useEffect(() => {
	// 	if (defaultSelectedIds) {
	// 		for (const id of defaultSelectedIds) {
	// 			setSubSpeciality(id);
	// 		}
	// 	}
	// }, [defaultSelectedIds, setSubSpeciality]);

	const selectedSpecialitiesFromType =
		currentStepIndex === 1
			? currentSelectedSpeciality?.items?.filter((item) =>
					subSpecialities?.includes(item.value!),
				) || []
			: currentSpecialties.filter((specialty) =>
					subSpecialities?.includes(specialty.value!),
				);

	const handleSelectSpeciality = (specialty: string) => {
		if (currentStepIndex === 0 && hasNestedItems) {
			setSpeciality(specialty);
			setCurrentStepIndex(1);
		} else {
			setSubSpeciality(specialty);
		}
	};

	const handleClickContinue = () => {
		const specialities = selectedSpecialitiesFromType.map((s) => s.value);
		updateUserInfo(email!, appId, {
			specialities: specialities,
		});
		trackCustomEvent(
			{
				eventName: newEvents.specialtySelected,
				specialties: specialities.join(","),
				type: "onboarding",
			},
			{
				specialties: specialities.join(","),
			},
		);
		const path = avatarType ? "name" : "avatar";
		router.setParams({
			step: path,
		});
		setCurrentStepIndex(0);
	};

	const handleSkip = () => {
		handleClickContinue();
	};

	return {
		currentSpecialties,
		handleSelectSpeciality,
		selectedSpecialities: subSpecialities,
		handleClickContinue,
		onboardingSpecialityStep,
		speciality,
		subSpecialities,
		mockedSpecialities,
		currentSelectedSpeciality,
		hasNestedItems,
		handleSkip,
		currentStepIndex,
	};
};
