import fetchQueryWrapper from "@helpers/relay-fetch-query-wrapper";
import type { SessionResponse } from "@memorang/types";
/* -----------------Globals--------------- */
import { graphql } from "react-relay";

interface Response {
	getMixedItemSession: SessionResponse;
}

const query = graphql`
  query FetchSessionQuery($userId: ID, $sessionId: ID, $numericId: Int) {
    getMixedItemSession(
      userId: $userId
      sessionId: $sessionId
      numericId: $numericId
    ) {
      id
      numericId
      examName
      reportSessionType
      status
      totalTime
      sessionTime
      startTime
      progressList {
        id
        progress
      }
      sessionItems {
        id
        containerId
        numItems
        stem
        itemType
        lastUpdated
        displayName
        marked
        highlight
        crossOut
        maxChoiceCount
        minChoiceCount
        video
        tags {
          id
          label
          type
        }
        children {
          id
          stem
          displayName
          itemType
          variant
          lastUpdated
          numFirstSelections
          parentType
          parentStem
          instruction
          maxChoiceCount
          minChoiceCount
          points
          video
          marked
          highlight
          crossOut
          tags {
            id
            label
            type
          }
          text
          isCorrect
          explanation
          tags {
            id
            type
            label
          }
          answers {
            isCorrect
            selectedChoiceIds
            timeTaken
            score
            pointsList {
              choiceId
              points
            }
          }
          children {
            id
            stem
            displayName
            text
            lastUpdated
            isCorrect
            marked
            maxChoiceCount
            minChoiceCount
            points
            video
            highlight
            crossOut
            itemType
            feedback
            variant
            numFirstSelections
            explanation
            instruction
            answers {
              isCorrect
              selectedChoiceIds
              timeTaken
              score
              pointsList {
                choiceId
                points
              }
            }
            children {
              id
              text
              feedback
              stem
              isCorrect
              explanation
              answers {
                isCorrect
                selectedChoiceIds
                timeTaken
                score
                selectedaction
              }
            }
          }
        }
      }
    }
  }
`;

const fetchSession = async ({
	sessionId,
	userId,
	numericId,
}: {
	sessionId?: string;
	numericId?: number;
	userId: string;
}): Promise<{
	response: SessionResponse;
	lastUpdatedBlockIndex: number;
	lastUpdatedItemIndex: number;
	groupIndex: number;
	progressArray: { id: string; progress: number }[];
}> => {
	const variables = {
		sessionId,
		userId,
		numericId,
	};
	const { getMixedItemSession } = (await fetchQueryWrapper(
		query,
		variables,
	)) as Response;

	const { sessionItems, progressList } = getMixedItemSession;
	const lastUpdatedBlockIndex = sessionItems.findIndex(({ children }) =>
		children.some(({ lastUpdated }) => {
			if (lastUpdated) {
				return lastUpdated;
			}
			return false;
		}),
	);

	let finalLastUpdatedBlockIndex =
		lastUpdatedBlockIndex > 0 ? lastUpdatedBlockIndex : 0;

	let groupIndex = 0;
	const lastUpdatedItemIndex = sessionItems[
		finalLastUpdatedBlockIndex
	]?.children?.findIndex(({ lastUpdated, children }) => {
		if (lastUpdated) {
			return lastUpdated;
		}
		const groupItemIndex =
			children?.findIndex(({ lastUpdated: lt }) => lt) || 0;
		if (
			children &&
			groupItemIndex > -1 &&
			groupItemIndex !== children.length - 1
		) {
			groupIndex = groupItemIndex + 2;
		}
		return children?.some(({ lastUpdated: lt }) => lt);
	});

	const getFinalLastUpdatedItemIndex = () => {
		if (lastUpdatedItemIndex === -1) {
			return 1;
		}
		if (lastUpdatedItemIndex === 0) {
			return 2;
		}
		const isLastItemInTheBlock =
			lastUpdatedItemIndex ===
			sessionItems[finalLastUpdatedBlockIndex].children.length - 1;
		if (isLastItemInTheBlock) {
			finalLastUpdatedBlockIndex += 1;
		}
		// Move to first item in the block
		return isLastItemInTheBlock ? 1 : lastUpdatedItemIndex + 2;
	};
	const finalLastUpdatedItemIndex = getFinalLastUpdatedItemIndex();
	return {
		response: getMixedItemSession,
		lastUpdatedBlockIndex: finalLastUpdatedBlockIndex,
		lastUpdatedItemIndex: finalLastUpdatedItemIndex,
		groupIndex,
		progressArray: progressList?.filter(({ id }) => id != null),
	};
};

export { fetchSession };
