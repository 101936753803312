import { useGlobalStore } from "@hooks/useGlobalStore";
import * as Haptics from "expo-haptics";
import { createContext, useCallback, useContext } from "react";

type HapticPattern =
	| "success"
	| "error"
	| "warning"
	| "light"
	| "medium"
	| "heavy";

type HapticContextType = {
	triggerHaptic: (pattern: HapticPattern) => void;
};

const HapticContext = createContext<HapticContextType | null>(null);

type Props = {
	children: React.ReactNode;
};

export const HapticProvider = ({ children }: Props) => {
	const isHapticEnabled = useGlobalStore(
		(state) => state.settingsEnabledFeatures.haptic,
	);

	const triggerHaptic = useCallback(
		(pattern: HapticPattern) => {
			if (!isHapticEnabled) {
				return;
			}

			switch (pattern) {
				case "success":
					Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
					break;
				case "error":
					Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
					break;
				case "warning":
					Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
					break;
				case "light":
					Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
					break;
				case "medium":
					Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
					break;
				case "heavy":
					Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
					break;
			}
		},
		[isHapticEnabled],
	);

	return (
		<HapticContext.Provider value={{ triggerHaptic }}>
			{children}
		</HapticContext.Provider>
	);
};

export const useHaptics = () => {
	const context = useContext(HapticContext);
	if (!context) {
		throw new Error("useHaptics must be used within a HapticProvider");
	}
	return context;
};
