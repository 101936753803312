import type { Article } from "../../../../../types";

export const Article628: Article = {
	id: 628,
	rsuiteId: "5d32a53b-08f9-420f-82a2-9af4df2ee6c4",
	type: "scientific article",
	title:
		"Muscle Compensation Strategies to Maintain Glenohumeral Joint Stability in Rotator Cuff Tears",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d32a53b-08f9-420f-82a2-9af4df2ee6c4&type=jpeg&name=JBJS.24.00411f1",
	subSpecialties: ["shoulder_elbow"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Muscle Compensation Strategies to Maintain Glenohumeral
                Joint Stability in Rotator Cuff Tears</div>
              <div class="text subtitle" style="position: unset;">A Cadaveric Study</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Kyosuke
                      Hoshikawa, PT, MS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Manuela
                      Dominguez, BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_48" class="annotation strong"
                          style="position: unset;">Background:</span> Superior translation of the humeral head is often
                        identified in large and massive rotator cuff (RC) tears. However, the ability of the remaining
                        RC muscles to compensate for the forces causing such superior translation in RC tears remains
                        unclear. The purpose of this study was to investigate the impact of compensatory forces exerted
                        by the remaining RC muscles on humeral head translation using a progressive RC tear model.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_49" class="annotation strong"
                          style="position: unset;">Methods:</span> Eight fresh-frozen cadaveric shoulders (mean donor
                        age, 57 years) were tested using a custom shoulder testing system. In addition to an intact RC
                        model, 3 RC tear models were created: a supraspinatus tear (Tear I); combined supraspinatus and
                        infraspinatus tears (Tear II); and combined tears of the supraspinatus, infraspinatus, and
                        superior one-third of the subscapularis (Tear III). Compensatory conditions were simulated by
                        increasing the loading of the remaining RC muscles in each RC tear model. Humeral head
                        translation was measured at different abduction and neutral rotation angles in each condition
                        with normal and high deltoid muscle loading.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_50" class="annotation strong"
                          style="position: unset;">Results:</span> Significant superior translation of the humeral head
                        was observed in Tears II and III (but not Tear I), compared with the intact state, under high
                        loading of the deltoid during abduction and during rotation. In Tear II, compensatory conditions
                        involving increased loading of the teres minor and subscapularis muscles effectively reduced
                        superior translation, so that no significant differences were observed compared with the intact
                        state, even under high deltoid muscle loading. However, in Tear III, significant superior
                        translation was still observed, regardless of the compensatory conditions.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_51" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Compensation by the remaining RC muscles,
                        particularly the teres minor and subscapularis, effectively reduced superior translation of the
                        humeral head in the posterosuperior RC tear model, whereas this compensatory strategy was
                        insufficient if tears also involved the superior one-third of the subscapularis.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_52" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> Patients with posterosuperior RC tears may
                        find conservative treatment focusing on strengthening the remaining RC muscles, especially the
                        subscapularis and teres minor, to be beneficial. Conversely, patients with repairable massive RC
                        tears also involving the subscapularis tendon may benefit from surgical interventions aimed at
                        primarily repairing the subscapularis tendon to restore the transverse force couple. Massive
                        tears deemed not to be repairable should be evaluated for arthroplasty or other procedures.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Rotator cuff (RC) tears are one of the most common
                  orthopaedic conditions causing dysfunction and pain in the shoulder<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Humeral head translation is often identified in large and
                  massive RC tears<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a> and
                  is recognized as a negative prognostic factor for muscle fatty degeneration<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">2</span></a>, shoulder dysfunction<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">3</span></a>, and pain<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">4</span></a>.
                  Inman et al. first reported that the coronal-plane force couple between the middle deltoid muscle and
                  RC muscles maintained active arm elevation<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">5</span></a>.
                  Burkhart then showed that normal arm elevation could occur even in the presence of an RC tear as long
                  as there was a balance in both the transverse force couple, namely between the remaining (unaffected)
                  subscapularis (SSC) muscle and the infraspinatus (ISP) and teres minor (TMin) muscles, and the
                  coronal-plane force couple<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Previous clinical studies have investigated compensation
                  strategies by the remaining RC muscles in shoulders with RC tears<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">9</span></a>.
                  Hawkes et al. observed higher deltoid and RC muscle activation during arm elevation in patients with
                  RC tears compared with controls without RC tears<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">7</span></a>.
                  Tamura et al. found that patients with RC tears and atrophy of both the TMin and SSC muscles had
                  significant superior translation of the humeral head, but those with hypertrophy of the TMin and
                  non-atrophy of the SSC did not<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">9</span></a>.
                  Simulation and cadaveric studies of RC tear models demonstrated the need for large forces from the
                  deltoid and remaining RC muscles, specifically the SSC, ISP, and TMin muscles, during the initial
                  motion in arm elevation<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">12</span></a>. These findings suggest that the remaining SSC, ISP, and
                  TMin muscles may compensate for the tear by preserving the transverse force couple essential for
                  averting superior translation of the humeral head provoked by the deltoid muscle. Similarly, humeral
                  head translation in progressive RC tear models has been studied on the basis of a single force vector
                  associated with each muscle or evenly divided force vectors within each RC muscle<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">17</span></a>. Other studies have determined various force vectors within
                  each muscle based on its distinct anatomical subregions<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">22</span></a>. Importantly, biomechanical<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">25</span></a> and functional studies<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">30</span></a> have highlighted differences among the subregions of the RC
                  muscles. Specifically, the anterior region of the supraspinatus (SSP) muscle is primarily activated
                  during abduction, whereas the posterior deep region of the SSP is primarily activated during external
                  rotation<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">30</span></a>. The middle region of the ISP muscle is activated during
                  mid-range abduction and external rotation, whereas the superior and inferior regions are activated
                  during initial or end-range abduction and rotation<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">28</span></a>. Additionally, the superior and middle regions of the SSC
                  muscle demonstrate higher activation during abduction and flexion compared with the inferior region<a
                    href="" data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">29</span></a>. Nevertheless, the extent to which each remaining RC muscle
                  and its distinct subregions reduce humeral head translation induced by the deltoid muscle across
                  various sizes of tears remains uncertain.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">This study aimed to investigate the biomechanical
                  compensation by the remaining RC muscles and subregions to prevent humeral head translation resulting
                  from deltoid muscle loading in a progressive RC tear model. We hypothesized that increasing the
                  loading in these muscles and subregions would reduce humeral head translation effectively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Specimen
                Preparation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Eight fresh-frozen cadaveric shoulders (mean donor age, 57
                  years; 6 males; 5 right shoulders) without RC tears were used after institutional review board
                  approval. Specimens were thawed overnight before dissection and testing. All soft tissues except for
                  the RC muscles and tendons, joint capsule, and coracoacromial and coracohumeral ligaments were
                  removed. The TMin muscle and anatomical subregions of the SSC (superior, middle, and inferior)<a
                    href="" data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">19</span></a>, SSP (anterior and posterior)<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">21</span></a>, and ISP muscles (superior, middle, and inferior)<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">22</span></a> were defined and isolated on the basis of previous studies.
                  Briefly, the superior and middle regions of the SSC muscle have tendinous insertions located on the
                  upper margin of the lesser tuberosity and anteromedial portion of the lesser tuberosity, whereas the
                  inferior region has tendinous and muscular insertions. The anterior region of the SSP muscle is
                  characterized by a thick and tubular tendon with a small cross-sectional area, whereas the posterior
                  region has a flatter and wider tendon with a larger cross-sectional area. The ISP muscle is thick and
                  triangular, with the superior and inferior regions located superficial to the deep middle region,
                  which inserts on the middle facet of the greater tubercle of the humerus.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">RC muscle fibers inserted into the tendons were carefully
                  separated to obtain individual subregions. The tendinous insertions of the RC muscle subregions were
                  sutured using a Krackow stitch to which loads could be applied. The deltoid insertion was identified
                  before resection, and a screw was inserted at this location to allow for suture placement and for
                  simulated deltoid muscle loading. Three small screws were inserted on the proximal and distal aspects
                  of the bicipital groove and on the greater tuberosity to serve as markers for the creation of a
                  humeral technical coordinate system. Each humerus was transected 2 cm below the deltoid tuberosity,
                  and an intramedullary rod was inserted to allow for mounting of the specimen in a custom-built
                  fixture.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Testing
                Setup</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The scapula and glenoid were oriented vertically in the
                  fixture, which allowed for adjustments of the humerus in 6 degrees of freedom (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). Muscle subregions were kept in tension during testing by
                  attaching a weight to each suture. The maximum voluntary contraction force for each muscle or muscle
                  subregion was estimated from its anatomical cross-sectional area measured using a Mach-30 ultrasound
                  system (SuperSonic Imagine) and an SL10-2 transducer<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">32</span></a>. Loading for each muscle or muscle subregion was determined
                  based on the estimated maximum voluntary contraction force and the percentage of maximum voluntary
                  contraction from electromyographic studies<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">35</span></a>. In the noncompensatory condition, loading was as follows:
                  3 N to the superior region, 8 N to the middle region, and 5 N to the inferior region of the SSC
                  muscle; 11 N to the anterior region and 4 N to the posterior region of the SSP muscle; 2 N to the
                  superior region, 9 N to the middle region, and 2 N to the inferior region of the ISP muscle; and 4 N
                  to the TMin muscle. To assess the impact of muscle compensation in various tear scenarios, the loads
                  applied to the remaining RC muscle subregions (outlined below) were increased to 1.5 times their
                  original load, based on electromyographic studies of these muscles<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">35</span></a>. When the tear included the ISP tendon, the load on the
                  TMin muscle was increased to 3 times the original load, simulating a hypertrophic response of the TMin
                  muscle<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">36</span></a>. To
                  simulate superiorly directed forces, 2 deltoid muscle-loading conditions were evaluated: normal load
                  (40 N) and high load (80 N)<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">13</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00411f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d32a53b-08f9-420f-82a2-9af4df2ee6c4&type=jpeg&name=JBJS.24.00411f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_55"
                            class="annotation strong" style="position: unset;">Fig. 1-A</span> Schematic of the testing
                          setup. The right shoulder is mounted in the custom-built testing apparatus. A through E show
                          the loading of each muscle or muscle subregion. A shows the deltoid muscle line of action. B
                          shows the SSP muscle subregions and superior region of the ISP muscle. C shows the middle
                          region of the ISP muscle. D shows the inferior region of the ISP muscle. E shows the TMin
                          muscle. The SSC muscle subregions (on the back side of the apparatus) are not shown. <span
                            data-id="strong_56" class="annotation strong" style="position: unset;">Fig. 1-B</span>
                          Cadaveric shoulder mounted in the testing setup. <span data-id="strong_57"
                            class="annotation strong" style="position: unset;">Figs. 1-C, 1-D, and 1-E</span> SSP, ISP,
                          and SSC muscles with their individual subregions. A = anterior, P = posterior, S = superior, M
                          = middle, and I = inferior.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Testing
                Conditions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">In addition to an intact tendon model, 3 RC tear models
                  were created and evaluated: Tear I involved an isolated SSP tear, Tear II involved combined SSP and
                  ISP tears, and Tear III involved combined tears of the SSP, ISP, and superior one-third of the SSC (<a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). Models were created according to the anatomy of the RC
                  footprint<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">17</span></a>.
                  Following testing under noncompensatory conditions, loads on the remaining muscles were adjusted to
                  reflect increased demands as follows. In the Tear I+ condition, loads were increased 1.5-fold,
                  resulting in 4 N to the superior region, 12 N to the middle region, and 7 N to the inferior region of
                  the SSC muscle; 3 N to the superior region, 13 N to the middle region, and 3 N to the inferior region
                  of the ISP muscle; and 6 N to the TMin muscle. In Tear II+, the load on the TMin muscle was increased
                  3-fold, to 12N. In Tear II++, the load to the TMin was increased 3-fold, to 12 N, and the loads on the
                  SSC muscle were increased 1.5-fold, resulting in 4 N to the superior region, 12 N to the middle
                  region, and 7 N to the inferior region. In Tear III+, the load on the TMin muscle was increased
                  3-fold, to 12 N. In Tear III++, the load on the TMin muscle was increased 3-fold, to 12 N, and the
                  loads were both increased 1.5-fold on the middle SSC muscle region, resulting in 12 N, and the
                  inferior SSC muscle region, resulting in 7 N.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00411f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d32a53b-08f9-420f-82a2-9af4df2ee6c4&type=jpeg&name=JBJS.24.00411f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;">The tear progression models: intact, Tear I
                          (isolated SSP tear), Tear II (SSP and ISP tears), and Tear III (tears of the SSP, ISP, and
                          one-third of the SSC).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Measurements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Testing of the intact and tear models (under compensatory
                  and noncompensatory conditions) with both deltoid loading conditions was performed at 10°, 30°, and
                  50° of abduction in the scapular plane with neutral rotation, and at 30° of internal rotation and 30°
                  of external rotation with 10° of abduction. Specimens were preconditioned by rotating the humerus from
                  maximum internal rotation to maximum external rotation 3 times before testing. To evaluate the
                  position of the humeral head center relative to the center of the glenoid in each testing
                  configuration, the 3 markers were digitized using a MicroScribe 3DLX (Revware). After testing was
                  completed, the glenohumeral joint was disarticulated, and the humeral head rim and glenoid were
                  digitized to establish a local coordinate system as previously described (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>)<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">37</span></a>.
                  Briefly, the glenoid coordinate system was established by digitizing markers on the osseous glenoid
                  and its rim. A superior-inferior axis connected points on the superior and inferior rims, whereas
                  anterior and posterior landmarks were defined where the rim intersected a perpendicular plane
                  bisecting the superior-inferior axis. The preliminary glenoid center was found at this intersection
                  and adjusted to the glenoid surface to obtain the true center, which became the origin of a glenoid
                  center coordinate system. Using a right-hand rule-based Cartesian coordinate system, the
                  superior-inferior, anterior-posterior, and medial-lateral axes were defined. A coordinate system based
                  on the humeral head center was determined by digitizing points along the articular margin of the
                  humeral head, with the origin set at the geometric centroid of this border. This humeral head center
                  was then transformed to the humeral technical coordinate system, which was used to track its position.
                  The position of the humeral head center relative to the glenoid center was then quantified for each
                  testing condition.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00411f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d32a53b-08f9-420f-82a2-9af4df2ee6c4&type=jpeg&name=JBJS.24.00411f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;">Schematic drawing showing the digitized points
                          used to create the local coordinate axes for the humeral head center, glenoid, and humerus. To
                          evaluate the humeral head position relative to the glenoid, 28 points on the humeral head rim
                          (yellow dots) were digitized to allow for the creation of local x, y, and z axes with an
                          origin at the humeral head center. Twelve points on the glenoid rim (red dots) and additional
                          points (black dots) inside the glenoid cavity were digitized to allow for the creation of the
                          glenoid local coordinate system. Three markers (white dots h1, h2, and h3) were placed on the
                          proximal humerus to create a technical (not anatomical) coordinate system for the humerus that
                          allowed tracking of the humeral head center position using a rigid-body transformation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Sample
                Size</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">A sample size analysis was conducted a priori with data
                  obtained from 2 specimens and using G*Power (version 3.1; University of Düsseldorf). The calculation
                  was performed on the basis of mean superior-inferior translations obtained under intact and Tear-II
                  conditions at all abduction angles and neutral rotation. Eight specimens were found to be required to
                  achieve a power of 0.80 with an α level of 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Each position and condition was digitized and measured
                  twice, and the mean was used for analysis. Analysis was performed using SPSS (version 29.0; IBM). The
                  mean humeral head center translations relative to the glenoid center were compared with the intact
                  state using analysis of variance (ANOVA) followed by Tukey post hoc pairwise tests. Significance was
                  defined as p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Humeral Head
                Translations During Abduction</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 4</a> shows
                  an example of the mean translations for all 8 specimens with respect to the center of the glenoid for
                  all testing conditions. <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 5</a> shows
                  superior-inferior translations during abduction with neutral rotation. With normal loading of the
                  deltoid muscle, Tear III showed significant superior translation when compared with the intact state
                  at 10° and 30° of abduction (p &lt; 0.05), whereas no significant superior translation was observed
                  for Tears I and II. Under compensatory conditions, both Tear III+, which includes compensation by the
                  TMin alone, and Tear III++, which includes compensation by both the TMin and SSC muscles, restored
                  superior translation to levels that were not significantly different from the intact state. With high
                  loading of the deltoid muscle, Tears II and III resulted in significant superior translation at 10°
                  and 30° of abduction compared with the intact state (p &lt; 0.05), whereas Tear I did not exhibit
                  significant superior translation. Under compensatory conditions, Tear II+ and Tear II++ restored
                  superior translation to levels no different from that in the intact state. However, in Tear III,
                  significant superior translation was still observed regardless of the compensatory conditions, except
                  in Tear III++ at 30° of abduction. No significant anterior-posterior translation differences compared
                  with the intact state were observed in any tear condition (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00411f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d32a53b-08f9-420f-82a2-9af4df2ee6c4&type=jpeg&name=JBJS.24.00411f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">Positioning of the humeral head center at 10° of
                          abduction relative to the geometric glenoid center (0, 0). The mean humeral head center
                          position for all 8 specimens for each testing condition is shown in the blowup at the right.
                          IR = 30° of internal rotation, NR = neutral, and ER = 30° of external rotation. Solid lines
                          indicate normal deltoid muscle loading. Dashed lines indicate high deltoid muscle loading.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00411f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d32a53b-08f9-420f-82a2-9af4df2ee6c4&type=jpeg&name=JBJS.24.00411f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Superior-inferior mean (and standard deviation)
                          translation of the humeral head center position relative to the center of the glenoid for each
                          RC tear model and the corresponding compensatory conditions at 10°, 30°, and 50° of abduction
                          with neutral rotation. Asterisks indicate significant superior translation compared with the
                          intact state (p &lt; 0.05). Tear I: isolated SSP tear. Tear I+: loads increased 1.5-fold on
                          the remaining ISP and TMin muscles and SSC muscle subregions. Tear II: combined SSP and ISP
                          tears. Tear II+: load increased 3-fold on the TMin muscle. Tear II++: loads increased 3-fold
                          on the TMin muscle and 1.5-fold on the SSC muscle subregions. Tear III: tears of the SSP, ISP,
                          and superior one-third of the SSC. Tear III+: load increased 3-fold on the TMin muscle. Tear
                          III++: loads increased 3-fold on the TMin muscle and 1.5-fold on the middle and inferior
                          subregions of the SSC muscle. The blue solid line indicates the mean humeral head center
                          position in the intact shoulders. The blue shaded area depicts ±1 standard deviation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.24.00411f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d32a53b-08f9-420f-82a2-9af4df2ee6c4&type=jpeg&name=JBJS.24.00411f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;">Anterior-posterior mean (and standard deviation)
                          translation of the humeral head center position relative to the center of the glenoid for each
                          RC tear model and the corresponding compensatory conditions at 10°, 30°, and 50° of abduction
                          with neutral rotation. Tear I: isolated SSP tear. Tear I+: loads increased 1.5-fold on the
                          remaining ISP and SSC muscle subregions and in the TMin muscle. Tear II: combined SSP and ISP
                          tears. Tear II+: load increased 3-fold on the TMin muscle. Tear II++: loads increased 3-fold
                          on the TMin muscle and 1.5-fold on the SSC muscle subregions. Tear III: tears of the SSP, ISP,
                          and superior one-third of the SSC. Tear III+: load increased 3-fold on the TMin muscle. Tear
                          III++: loads increased 3-fold on the TMin muscle and 1.5-fold on the middle and inferior
                          subregions of the SSC muscle. The blue solid line indicates the mean humeral head center
                          position in the intact shoulders. The blue shaded area depicts ±1 standard deviation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Humeral Head
                Translations During Rotation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 7</a> shows
                  superior-inferior translations during internal, neutral, and external rotation with 10° of abduction.
                  With normal deltoid muscle loading, significant superior translation was observed in Tear III compared
                  with the intact state at 30° internal and external rotations (p &lt; 0.05), whereas no significant
                  superior translation was observed in Tears I and II. Under compensatory conditions, both Tear III+ and
                  Tear III++ showed no difference compared with the intact state. With high deltoid muscle loading, Tear
                  II and Tear III resulted in significant superior translation compared with the intact state at 30°
                  internal and external rotations (p &lt; 0.05), but no significant superior translation was observed in
                  Tear I. Although compensatory conditions reduced superior translation, the superior translation
                  remained significantly higher than in the intact state at 30° internal and external rotations, except
                  in Tear II++ at 30° of external rotation. There was a tendency for posterior translation of the
                  humeral head with increasing tear size, but no significant anterior-posterior translation differences
                  were observed in any tear condition compared with the intact state (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 8</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.24.00411f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d32a53b-08f9-420f-82a2-9af4df2ee6c4&type=jpeg&name=JBJS.24.00411f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">Superior-inferior mean (and standard deviation)
                          translation of the humeral head center position relative to the center of the glenoid for each
                          RC tear model and the corresponding compensatory conditions at 30° of internal rotation,
                          neutral, and 30° of external rotation with 10° of abduction. Asterisks indicate significant
                          superior translation compared with the intact state (p &lt; 0.05). Tear I: isolated SSP tear.
                          Tear I+: loads increased 1.5-fold on the remaining ISP and TMin muscles and SSC muscle
                          subregions. Tear II: combined SSP and ISP tears. Tear II+: load increased 3-fold on the TMin
                          muscle. Tear II++: loads increased 3-fold on the TMin muscle and 1.5-fold on the SSC muscle
                          subregions. Tear III: tears of the SSP, ISP, and superior one-third of the SSC. Tear III+:
                          load increased 3-fold on the TMin muscle. Tear III++: loads increased 3-fold on the TMin
                          muscle and 1.5-fold on the middle and inferior subregions of the SSC muscle. The blue solid
                          line indicates the mean humeral head center position in the intact shoulders. The blue shaded
                          area depicts ±1 standard deviation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 8</div>
                <div class="image-download" name="JBJS.24.00411f8" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d32a53b-08f9-420f-82a2-9af4df2ee6c4&type=jpeg&name=JBJS.24.00411f8"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Anterior-posterior mean (and standard deviation)
                          translation of the humeral head center position relative to the center of the glenoid for each
                          RC tear model and the corresponding compensatory conditions at 30° of internal rotation,
                          neutral, and 30° of external rotation with 10° of abduction. Tear I: isolated SSP tear. Tear
                          I+: loads increased 1.5-fold on the remaining ISP and SSC muscle subregions and in the TMin
                          muscle. Tear II: combined SSP and ISP tears. Tear II+: load increased 3-fold on the TMin
                          muscle. Tear II++: loads increased 3-fold on the TMin muscle and 1.5-fold on the SSC muscle
                          subregions. Tear III: tears of the SSP, ISP, and superior one-third of the SSC. Tear III+:
                          load increased 3-fold on the TMin muscle. Tear III++: loads increased 3-fold on the TMin
                          muscle and 1.5-fold on the middle and inferior subregions of the SSC muscle. The blue solid
                          line indicates the mean humeral head center position in the intact shoulders. The blue shaded
                          area depicts ±1 standard deviation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">This study represents a novel biomechanical investigation
                  into the mechanisms by which the remaining subregions of the RC muscles reduce humeral head
                  translation, using a cadaveric model replicating progressive RC tears and varied deltoid loading
                  conditions. Our approach to loading each RC muscle subregion individually aimed to enhance our
                  understanding of humeral head translation, considering the properties of the individual subregions and
                  the intact SSC tendon. Our findings offer important biomechanical insights that could aid in
                  identifying patients with RC tears who may benefit from conservative treatment aimed at strengthening
                  the remaining RC muscles as well as identifying those patients who may require surgical intervention.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Previous biomechanical studies showed increased superior
                  and posterior translations of the humeral head with progressive RC tears<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">17</span></a>. Itami et al. showed superior translation during 0° to 40°
                  of abduction and 0° to maximum external rotation in posterosuperior RC tear models compared with the
                  intact state<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">13</span></a>. Oh
                  et al. observed posterior translation from 30° of internal to 30° of external rotation at 0° and 30°
                  of abduction in large posterosuperior RC tears<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">17</span></a>. To
                  our knowledge, ours is the first study investigating the effects of coronal- and transverse-plane
                  force couple balance from the deltoid and remaining RC muscles or muscle subregions on humeral head
                  translation. Specifically, it investigated the biomechanical capacity of each remaining RC muscle or
                  muscle subregion to reduce superior and posterior translations caused by the deltoid in a progressive
                  RC tear model. Supporting our hypothesis, increased loading of the remaining muscles and subregions
                  reduced superior and posterior translations in the RC tear models. In Tear II, compensation by
                  increased loading of the SSC and TMin muscles reduced superior and posterior translations to a level
                  comparable with the intact state at 10° to 30° of abduction and at 30° of external rotation, although
                  not at 30° of internal rotation. However, in the tear model that also included the superior one-third
                  of the SSC tendon (Tear III), superior translation with high deltoid muscle loading remained
                  significantly greater than in the intact state, despite compensation from the remaining SSC muscle
                  subregions and TMin muscle, at 10° of abduction, at 30° of internal rotation, and at 30° of external
                  rotation. These findings suggest that reinforcing the remaining RC muscles in the transverse force
                  couple may effectively reduce superior and posterior translations in cases of posterosuperior RC
                  tears. However, this compensatory strategy may prove to be inadequate during internal rotation and in
                  the presence of tears that include the superior one-third of the SSC tendon.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Previous studies highlighted the role of the transverse
                  force-couple balance for maintaining glenohumeral joint equilibrium during shoulder motion<a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_32" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">12</span></a>.
                  Burkhart observed that patients with RC tears could achieve normal arm elevation when a balance of the
                  transverse force couple existed between the remaining SSC muscle and the ISP and TMin muscles<a
                    href="" data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">6</span></a>. Biomechanical studies demonstrated the need for
                  compensatory loading by the remaining TMin muscle in posterosuperior RC tears<a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">11</span></a>, and by both the SSC muscle and the ISP and TMin muscles in
                  massive tears, including those that involved the SSC tendon<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">12</span></a>, to
                  counteract the high superior shear forces from the deltoid muscle at the beginning of abduction.
                  However, the compensatory loads in these prior studies exceeded physiological limits. In contrast, in
                  our study of massive tears, with and without tearing of the superior one-third of the SSC, we examined
                  the compensatory capacities of the TMin muscle alone and of both the SSC and TMin muscles, while
                  ensuring that each simulated load remained within physiological limits based on studies of their
                  cross-sectional area<a href="" data-id="citation_reference_54"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">36</span></a> and
                  electromyographic activity<a href="" data-id="citation_reference_55"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_37"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">35</span></a>. We showed that increased loading of both the SSC and TMin
                  muscles reduced superior translation of the humeral head at 30° of external rotation even in the
                  presence of high deltoid muscle loading, but compensation by the TMin muscle alone was not effective.
                  These findings reinforce Burkhart’s theory that an effective transverse force couple can be obtained
                  via compensation by the SSC and TMin muscles in patients with RC tears who have an intact SSC tendon.
                  Conversely, compensation by the SSC and TMin muscles did not effectively reduce humeral head
                  translation during abduction and rotation in massive RC tears that included a tear of the superior
                  one-third of the SSC tendon.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Clinical studies indicate that concomitant SSC tears
                  alongside RC tears negatively impact outcomes<a href="" data-id="citation_reference_57"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_38"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">41</span></a>. Lee et al. noted a high failure rate after repair of
                  massive RC tears involving over one-third of the SSC tendon<a href="" data-id="citation_reference_59"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">39</span></a>,
                  and Collin et al. showed that SSC tears can result in pseudoparalysis in patients with massive RC
                  tears<a href="" data-id="citation_reference_60"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_40" class="annotation superscript" style="position: unset;">41</span></a>.
                  Yamada et al. observed superior humeral head translation at the beginning of shoulder abduction in
                  patients with massive RC and SSC tears compared with patients with RC tears but without SSC tears<a
                    href="" data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">42</span></a>. Similarly, Yoo et al. demonstrated that tears in the
                  superior half of the SSC tendon significantly increased superior translation at various individual
                  positions from 0° to 60° of abduction<a href="" data-id="citation_reference_62"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_42" class="annotation superscript" style="position: unset;">16</span></a>.
                  These findings align with our results, indicating that compensation by the SSC and TMin muscles did
                  not adequately reduce superior humeral head translation when the deltoid force exceeded normal values
                  in the tear model that included the superior one-third of the SSC tendon. These outcomes support the
                  critical role of the superior region of the SSC muscle and tendon in maintaining shoulder joint
                  stability, highlighting its clinical importance in patients with massive RC tears.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Although surgical repair of RC tears has been associated
                  with improved outcomes<a href="" data-id="citation_reference_63"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">43</span></a>,
                  conservative treatment can also achieve satisfactory results<a href="" data-id="citation_reference_64"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">44</span></a><span data-id="superscript_44"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_65" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">46</span></a>. After 13 years, approximately 90% of conservatively
                  managed patients in 1 study experienced minimal shoulder pain, with approximately 70% maintaining
                  normal daily activities<a href="" data-id="citation_reference_66"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_45" class="annotation superscript" style="position: unset;">44</span></a>. At
                  a 5-year follow-up, surgical and conservative treatments for isolated SSP tears showed similar
                  outcomes in another study<a href="" data-id="citation_reference_67"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_46" class="annotation superscript" style="position: unset;">45</span></a>. In
                  a third study, patients with an intact SSC tendon and a hypertrophic TMin muscle had reduced pain and
                  preserved range of motion with conservative treatment<a href="" data-id="citation_reference_68"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_47" class="annotation superscript" style="position: unset;">46</span></a>.
                  Conversely, satisfactory clinical outcomes and structural integrity have been observed with isolated
                  SSC tendon repair in cases of irreparable massive RC tears<a href="" data-id="citation_reference_69"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">47</span></a>. Our findings suggest that patients with posterosuperior RC
                  tears may benefit from conservative treatment to strengthen the remaining RC muscles, particularly the
                  SSC and TMin muscles. However, patients with repairable massive RC tears involving the SSC tendon may
                  benefit from surgical interventions aimed at primarily repairing the SSC tendon to restore the
                  transverse force couple<a href="" data-id="citation_reference_71"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_49"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_72" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">47</span></a>. Massive tears deemed not to be repairable should be
                  evaluated for an arthroplasty or other procedures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Our study had some limitations. First, we evaluated
                  shoulders only during scapular-plane abduction and during rotation at 10° of abduction, aligning with
                  our research goals as these positions have previously shown relevant superior translation of the
                  humeral head<a href="" data-id="citation_reference_73"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_50"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">17</span></a>. Second, pain has been shown to negatively affect shoulder
                  function in patients, but it could not be incorporated as a variable in the present cadaveric study.
                  Third, we focused on the force couple in the coronal plane between the deltoid muscle and the SSC,
                  SSP, ISP, and TMin muscles and their subregions, removing the forearm to allow the humerus to be
                  mounted in our fixture. Future studies should consider including additional muscles and the long head
                  of the biceps tendon, and should account for the weight of the resected forearm. Fourth, the
                  assessment of the humeral head position was conducted after preconditioning of the specimen and
                  involved static shoulder positions with static loading applied to each muscle or muscle subregion.
                  Ideally, a dynamic scenario involving continuous shoulder motion and load adjustment would provide a
                  more comprehensive evaluation of compensatory mechanisms. However, to our knowledge, our study is the
                  first to demonstrate the critical role of compensatory loading by the remaining SSC and TMin muscles
                  in reducing superior translation, and their potential inefficiencies in certain RC tear conditions,
                  highlighting the need for further investigations. Despite these constraints, our findings provide
                  valuable insights to clinicians managing patients with RC tears.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, we highlight the importance of compensation
                  by the remaining RC muscles in shoulders with RC tears. Specifically, the compensation by the TMin and
                  SSC muscles was emphasized, showcasing their role in reducing superior translation of the humeral head
                  to a level not significantly different from that in the intact state, especially in cases of
                  posterosuperior RC tears. However, the compensatory efficiency of these muscles may fall short of
                  restoring translation to normal levels in massive tears that also involve the SSC tendon, indicating
                  the need for surgical intervention in that situation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Yamamoto A</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Takagishi K</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Osawa T</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Yanagawa T</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Nakajima D</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Shitara H</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Kobayashi T</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Prevalence and risk factors of a
                  rotator cuff tear in the general population</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2010
                Jan;19(1):116-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20and%20risk%20factors%20of%20a%20rotator%20cuff%20tear%20in%20the%20general%20population&as_occt=title&as_sauthors=%20%22A%20Yamamoto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Nové-Josserand L</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Edwards TB</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> O’Connor DP</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Walch G</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The acromiohumeral and
                  coracohumeral intervals are abnormal in rotator cuff tears with muscular fatty degeneration</span>.
                <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2005 Apr;(433):90-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20acromiohumeral%20and%20coracohumeral%20intervals%20are%20abnormal%20in%20rotator%20cuff%20tears%20with%20muscular%20fatty%20degeneration&as_occt=title&as_sauthors=%20%22L%20Nov%C3%A9-Josserand%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Bezer M</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Yildirim Y</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Akgün U</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Erol B</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Güven O</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Superior excursion of the
                  humeral head: a diagnostic tool in rotator cuff tear surgery</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2005
                Jul-Aug;14(4):375-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Superior%20excursion%20of%20the%20humeral%20head%3A%20a%20diagnostic%20tool%20in%20rotator%20cuff%20tear%20surgery&as_occt=title&as_sauthors=%20%22M%20Bezer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Keener JD</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Wei AS</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Kim HM</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Steger-May K</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Yamaguchi K</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Proximal humeral migration in
                  shoulders with symptomatic and asymptomatic rotator cuff tears</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2009
                Jun;91(6):1405-13.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1056398"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Inman VT</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Saunders JB</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Abbott LC</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Observations of the function of
                  the shoulder joint. 1944</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 1996 Sep;(330):3-12.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Observations%20of%20the%20function%20of%20the%20shoulder%20joint.%201944&as_occt=title&as_sauthors=%20%22VT%20Inman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Burkhart SS</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Arthroscopic treatment of
                  massive rotator cuff tears. Clinical results and biomechanical rationale</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 1991 Jun;(267):45-56.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopic%20treatment%20of%20massive%20rotator%20cuff%20tears.%20Clinical%20results%20and%20biomechanical%20rationale&as_occt=title&as_sauthors=%20%22SS%20Burkhart%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Hawkes DH</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Alizadehkhaiyat O</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Kemp GJ</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Fisher AC</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Roebuck MM</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Frostick SP</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Shoulder muscle activation and
                  coordination in patients with a massive rotator cuff tear: an electromyographic study</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J Orthop Res.</span> 2012
                Jul;30(7):1140-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Shoulder%20muscle%20activation%20and%20coordination%20in%20patients%20with%20a%20massive%20rotator%20cuff%20tear%3A%20an%20electromyographic%20study&as_occt=title&as_sauthors=%20%22DH%20Hawkes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Kikukawa K</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Ide J</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Terakawa Y</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Takada K</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Morita M</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Hashimoto K</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Mizuta H</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Hypertrophic teres minor
                  restores shoulder strength and range of external rotation in posterosuperior rotator cuff
                  tears</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2016 Nov;25(11):1882-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hypertrophic%20teres%20minor%20restores%20shoulder%20strength%20and%20range%20of%20external%20rotation%20in%20posterosuperior%20rotator%20cuff%20tears&as_occt=title&as_sauthors=%20%22K%20Kikukawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Tamura T</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Tokunaga T</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Karasugi T</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Miyamoto T</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Kikukawa K</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The remaining teres minor and
                  subscapularis may contribute to preventing superior migration of the humeral head and progression of
                  osteoarthritic change in rotator cuff tears</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">JSES Int.</span> 2023 Apr
                7;7(4):561-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20remaining%20teres%20minor%20and%20subscapularis%20may%20contribute%20to%20preventing%20superior%20migration%20of%20the%20humeral%20head%20and%20progression%20of%20osteoarthritic%20change%20in%20rotator%20cuff%20tears&as_occt=title&as_sauthors=%20%22T%20Tamura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Khandare S</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Arce RA</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Vidt ME</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Muscle compensation strategies
                  to maintain glenohumeral joint stability with increased rotator cuff tear severity: a simulation
                  study</span>. <span data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J
                  Electromyogr Kinesiol.</span> 2022 Feb;62:102335.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Muscle%20compensation%20strategies%20to%20maintain%20glenohumeral%20joint%20stability%20with%20increased%20rotator%20cuff%20tear%20severity%3A%20a%20simulation%20study&as_occt=title&as_sauthors=%20%22S%20Khandare%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Moroder P</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Chamberlain A</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Gabriel SM</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Jacofsky MC</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Sengun MZ</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Spenciner DB</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Tokish JM</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Lacheta L</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Effect of active and passive
                  function of the posterosuperior rotator cuff on compensatory muscle loads in the shoulder</span>.
                <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Orthop J Sports
                  Med.</span> 2022 May 23;10(5):23259671221097062.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20active%20and%20passive%20function%20of%20the%20posterosuperior%20rotator%20cuff%20on%20compensatory%20muscle%20loads%20in%20the%20shoulder&as_occt=title&as_sauthors=%20%22P%20Moroder%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Hansen ML</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Otis JC</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Johnson JS</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Cordasco FA</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Craig EV</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Warren RF</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Biomechanics of massive rotator
                  cuff tears: implications for treatment</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2008 Feb;90(2):316-25.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=914222" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Itami Y</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Park MC</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Lin CC</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Patel NA</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> McGarry MH</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Park CJ</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Neo M</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Lee TQ</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Biomechanical analysis of
                  progressive rotator cuff tendon tears on superior stability of the shoulder</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2021 Nov;30(11):2611-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biomechanical%20analysis%20of%20progressive%20rotator%20cuff%20tendon%20tears%20on%20superior%20stability%20of%20the%20shoulder&as_occt=title&as_sauthors=%20%22Y%20Itami%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Mura N</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> O’Driscoll SW</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Zobitz ME</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Heers G</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Jenkyn TR</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Chou SM</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Halder AM</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> An KN</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">The effect of infraspinatus
                  disruption on glenohumeral torque and superior migration of the humeral head: a biomechanical
                  study</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2003 Mar-Apr;12(2):179-84.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20infraspinatus%20disruption%20on%20glenohumeral%20torque%20and%20superior%20migration%20of%20the%20humeral%20head%3A%20a%20biomechanical%20study&as_occt=title&as_sauthors=%20%22N%20Mura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Su WR</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Budoff JE</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Luo ZP</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">The effect of anterosuperior
                  rotator cuff tears on glenohumeral translation</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2009
                Mar;25(3):282-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20anterosuperior%20rotator%20cuff%20tears%20on%20glenohumeral%20translation&as_occt=title&as_sauthors=%20%22WR%20Su%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Yoo JC</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> McGarry MH</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Jun BJ</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Scott J</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Lee TQ</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The influence of partial
                  subscapularis tendon tears combined with supraspinatus tendon tears</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2014 Jun;23(6):902-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20influence%20of%20partial%20subscapularis%20tendon%20tears%20combined%20with%20supraspinatus%20tendon%20tears&as_occt=title&as_sauthors=%20%22JC%20Yoo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Oh JH</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Jun BJ</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> McGarry MH</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Lee TQ</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Does a critical rotator cuff
                  tear stage exist? A biomechanical study of rotator cuff tear progression in human cadaver
                  shoulders</span>. <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2011 Nov 16;93(22):2100-9.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1137080" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Arai R</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Sugaya H</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Mochizuki T</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Nimura A</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Moriishi J</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Akita K</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Subscapularis tendon tear: an
                  anatomic and clinical investigation</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2008 Sep;24(9):997-1004.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Subscapularis%20tendon%20tear%3A%20an%20anatomic%20and%20clinical%20investigation&as_occt=title&as_sauthors=%20%22R%20Arai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Ward SR</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Hentzen ER</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Smallwood LH</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Eastlack RK</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Burns KA</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Fithian DC</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Friden J</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Lieber RL</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Rotator cuff muscle
                  architecture: implications for glenohumeral stability</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2006
                Jul;448(448):157-63.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rotator%20cuff%20muscle%20architecture%3A%20implications%20for%20glenohumeral%20stability&as_occt=title&as_sauthors=%20%22SR%20Ward%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Roh MS</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Wang VM</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> April EW</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Pollock RG</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Bigliani LU</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Flatow EL</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Anterior and posterior
                  musculotendinous anatomy of the supraspinatus</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2000
                Sep-Oct;9(5):436-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20and%20posterior%20musculotendinous%20anatomy%20of%20the%20supraspinatus&as_occt=title&as_sauthors=%20%22MS%20Roh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Yuri T</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Kobayashi H</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Takano Y</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Yoshida S</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Naito A</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Fujii H</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Kiyoshige Y</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Capsular attachment of the
                  subregions of rotator cuff muscles</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Surg Radiol Anat.</span> 2019 Nov;41(11):1351-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Capsular%20attachment%20of%20the%20subregions%20of%20rotator%20cuff%20muscles&as_occt=title&as_sauthors=%20%22T%20Yuri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Bacle G</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Gregoire JM</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Patat F</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Clavert P</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> de Pinieux G</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Laulan J</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Lakhal W</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Favard L</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Anatomy and relations of the
                  infraspinatus and the teres minor muscles: a fresh cadaver dissection study</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Surg Radiol Anat.</span>
                2017 Feb;39(2):119-26.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anatomy%20and%20relations%20of%20the%20infraspinatus%20and%20the%20teres%20minor%20muscles%3A%20a%20fresh%20cadaver%20dissection%20study&as_occt=title&as_sauthors=%20%22G%20Bacle%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Yuri T</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Trevino JH 3rd</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Hooke A</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Giambini H</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Moment arms from the anatomical
                  subregions of the rotator cuff muscles during flexion</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Biomech.</span> 2022
                Nov;144:111340.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Moment%20arms%20from%20the%20anatomical%20subregions%20of%20the%20rotator%20cuff%20muscles%20during%20flexion&as_occt=title&as_sauthors=%20%22T%20Yuri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Yuri T</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Trevino JH 3rd</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Hoshikawa K</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Hooke A</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Giambini H</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Moment arms of the anatomical
                  subregions of the rotator cuff muscles during shoulder rotation</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Clin Biomech (Bristol, Avon).</span> 2023
                Jul;107:106040.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Moment%20arms%20of%20the%20anatomical%20subregions%20of%20the%20rotator%20cuff%20muscles%20during%20shoulder%20rotation&as_occt=title&as_sauthors=%20%22T%20Yuri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Yuri T</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Prado M</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Trevino JH 3rd</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Giambini H</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Strain distribution in the
                  bursal rotator cuff based on whole-muscle and muscle subregion-specific loading: A cadaveric
                  study</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J
                  Orthop Res.</span> 2023 Sep;41(9):1863-70.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Strain%20distribution%20in%20the%20bursal%20rotator%20cuff%20based%20on%20whole-muscle%20and%20muscle%20subregion-specific%20loading%3A%20A%20cadaveric%20study&as_occt=title&as_sauthors=%20%22T%20Yuri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Hoshikawa K</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Yuri T</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Giambini H</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Kiyoshige Y</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Shoulder scaption is dependent
                  on the behavior of the different partitions of the infraspinatus muscle</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Surg Radiol Anat.</span>
                2021 May;43(5):653-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Shoulder%20scaption%20is%20dependent%20on%20the%20behavior%20of%20the%20different%20partitions%20of%20the%20infraspinatus%20muscle&as_occt=title&as_sauthors=%20%22K%20Hoshikawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Hoshikawa K</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Yuri T</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Mura N</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Giambini H</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Kiyoshige Y</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Coordination of the sub-regions
                  of the supraspinatus and deltoid muscles during shoulder scaption: a shear wave elastography
                  study</span>. <span data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Muscles
                  Ligaments Tendons J.</span> 2021;11(03):569-76.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Coordination%20of%20the%20sub-regions%20of%20the%20supraspinatus%20and%20deltoid%20muscles%20during%20shoulder%20scaption%3A%20a%20shear%20wave%20elastography%20study&as_occt=title&as_sauthors=%20%22K%20Hoshikawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Yuri T</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Trevino JH</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Hatta T</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Kiyoshige Y</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Jacobs PM</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Giambini H</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Stiffness of the infraspinatus
                  and the teres minor muscles during shoulder external rotation: an in-vitro and in-vivo shear wave
                  elastography study</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Clin Biomech (Bristol, Avon).</span> 2021 May;85:105328.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Stiffness%20of%20the%20infraspinatus%20and%20the%20teres%20minor%20muscles%20during%20shoulder%20external%20rotation%3A%20an%20in-vitro%20and%20in-vivo%20shear%20wave%20elastography%20study&as_occt=title&as_sauthors=%20%22T%20Yuri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Wickham J</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Pizzari T</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Balster S</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Ganderton C</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Watson L</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">The variable roles of the upper
                  and lower subscapularis during shoulder motion</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Clin Biomech (Bristol, Avon).</span> 2014
                Sep;29(8):885-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20variable%20roles%20of%20the%20upper%20and%20lower%20subscapularis%20during%20shoulder%20motion&as_occt=title&as_sauthors=%20%22J%20Wickham%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Yuri T</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Kuwahara Y</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Fujii H</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Kiyoshige Y</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Functions of the subregions of
                  the supraspinatus muscle</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Clin Anat.</span> 2017 Apr;30(3):347-51.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Functions%20of%20the%20subregions%20of%20the%20supraspinatus%20muscle&as_occt=title&as_sauthors=%20%22T%20Yuri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Hubal MJ</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Gordish-Dressman H</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Thompson PD</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Price TB</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Hoffman EP</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Angelopoulos TJ</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Gordon PM</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Moyna NM</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Pescatello LS</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Visich PS</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Zoeller RF</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Seip RL</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Clarkson PM</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Variability in muscle size and
                  strength gain after unilateral resistance training</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">Med Sci Sports Exerc.</span> 2005
                Jun;37(6):964-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Variability%20in%20muscle%20size%20and%20strength%20gain%20after%20unilateral%20resistance%20training&as_occt=title&as_sauthors=%20%22MJ%20Hubal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Yanagisawa O</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Dohi M</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Okuwaki T</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Tawara N</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Niitsu M</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Takahashi H</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Appropriate slice location to
                  assess maximal cross-sectional area of individual rotator cuff muscles in normal adults and
                  athletes</span>. <span data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Magn
                  Reson Med Sci.</span> 2009;8(2):65-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Appropriate%20slice%20location%20to%20assess%20maximal%20cross-sectional%20area%20of%20individual%20rotator%20cuff%20muscles%20in%20normal%20adults%20and%20athletes&as_occt=title&as_sauthors=%20%22O%20Yanagisawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Kronberg M</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Németh G</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Broström LA</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Muscle activity and
                  coordination in the normal shoulder. An electromyographic study</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 1990
                Aug;(257):76-85.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Muscle%20activity%20and%20coordination%20in%20the%20normal%20shoulder.%20An%20electromyographic%20study&as_occt=title&as_sauthors=%20%22M%20Kronberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Hamada J</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Nimura A</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Yoshizaki K</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Akita K</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Anatomic study and
                  electromyographic analysis of the teres minor muscle</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2017
                May;26(5):870-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anatomic%20study%20and%20electromyographic%20analysis%20of%20the%20teres%20minor%20muscle&as_occt=title&as_sauthors=%20%22J%20Hamada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Reed D</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Cathers I</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Halaki M</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Ginn KA</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Does changing the plane of
                  abduction influence shoulder muscle recruitment patterns in healthy individuals?</span><span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Man Ther.</span> 2016
                Feb;21:63-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20changing%20the%20plane%20of%20abduction%20influence%20shoulder%20muscle%20recruitment%20patterns%20in%20healthy%20individuals%3F&as_occt=title&as_sauthors=%20%22D%20Reed%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Kikukawa K</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Ide J</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Kikuchi K</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Morita M</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Mizuta H</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Ogata H</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Hypertrophic changes of the
                  teres minor muscle in rotator cuff tears: quantitative evaluation by magnetic resonance
                  imaging</span>. <span data-id="emphasis_36" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2014 Dec;23(12):1800-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hypertrophic%20changes%20of%20the%20teres%20minor%20muscle%20in%20rotator%20cuff%20tears%3A%20quantitative%20evaluation%20by%20magnetic%20resonance%20imaging&as_occt=title&as_sauthors=%20%22K%20Kikukawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Lee YS</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Lee TQ</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Specimen-specific method for
                  quantifying glenohumeral joint kinematics</span>. <span data-id="emphasis_37"
                  class="annotation emphasis" style="position: unset;">Ann Biomed Eng.</span> 2010
                Oct;38(10):3226-36.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Specimen-specific%20method%20for%20quantifying%20glenohumeral%20joint%20kinematics&as_occt=title&as_sauthors=%20%22YS%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Kim SJ</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Choi YR</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Jung M</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Lee WY</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Chun YM</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Isolated subscapularis repair
                  in irreparable posterosuperior massive rotator cuff tears involving the subscapularis tendon</span>.
                <span data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2017 May;45(6):1269-75.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Isolated%20subscapularis%20repair%20in%20irreparable%20posterosuperior%20massive%20rotator%20cuff%20tears%20involving%20the%20subscapularis%20tendon&as_occt=title&as_sauthors=%20%22SJ%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Lee SH</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Nam DJ</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Kim SJ</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Kim JW</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Comparison of clinical and
                  structural outcomes by subscapularis tendon status in massive rotator cuff tear</span>. <span
                  data-id="emphasis_39" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2017 Sep;45(11):2555-62.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20clinical%20and%20structural%20outcomes%20by%20subscapularis%20tendon%20status%20in%20massive%20rotator%20cuff%20tear&as_occt=title&as_sauthors=%20%22SH%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Park JY</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Chung SW</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Lee SJ</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Cho HW</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Lee JH</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Lee JH</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Oh KS</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Combined subscapularis tears in
                  massive posterosuperior rotator cuff tears: do they affect postoperative shoulder function and rotator
                  cuff integrity?</span><span data-id="emphasis_40" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2016 Jan;44(1):183-90.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Combined%20subscapularis%20tears%20in%20massive%20posterosuperior%20rotator%20cuff%20tears%3A%20do%20they%20affect%20postoperative%20shoulder%20function%20and%20rotator%20cuff%20integrity%3F&as_occt=title&as_sauthors=%20%22JY%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Collin P</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Matsumura N</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Lädermann A</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Denard PJ</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Walch G</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Relationship between massive
                  chronic rotator cuff tear pattern and loss of active shoulder range of motion</span>. <span
                  data-id="emphasis_41" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2014 Aug;23(8):1195-202.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Relationship%20between%20massive%20chronic%20rotator%20cuff%20tear%20pattern%20and%20loss%20of%20active%20shoulder%20range%20of%20motion&as_occt=title&as_sauthors=%20%22P%20Collin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Yamada Y</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Kai Y</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Kida N</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Koda H</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Takeshima M</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Hoshi K</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Gamada K</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Morihara T</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Dynamic three-dimensional
                  shoulder kinematics in patients with massive rotator cuff tears: a comparison of patients with and
                  without subscapularis tears</span>. <span data-id="emphasis_42" class="annotation emphasis"
                  style="position: unset;">Clin Shoulder Elb.</span> 2022 Dec;25(4):265-73.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dynamic%20three-dimensional%20shoulder%20kinematics%20in%20patients%20with%20massive%20rotator%20cuff%20tears%3A%20a%20comparison%20of%20patients%20with%20and%20without%20subscapularis%20tears&as_occt=title&as_sauthors=%20%22Y%20Yamada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Hurley ET</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Maye AB</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Mullett H</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Arthroscopic rotator cuff
                  repair: a systematic review of overlapping meta-analyses</span>. <span data-id="emphasis_43"
                  class="annotation emphasis" style="position: unset;">JBJS Rev.</span> 2019 Apr;7(4):e1.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1996012" target="_new" class=""
                  style="position: unset;">JBJS Reviews</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Kijima H</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Minagawa H</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Nishi T</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Kikuchi K</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Shimada Y</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Long-term follow-up of cases of
                  rotator cuff tear treated conservatively</span>. <span data-id="emphasis_44"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2012
                Apr;21(4):491-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20follow-up%20of%20cases%20of%20rotator%20cuff%20tear%20treated%20conservatively&as_occt=title&as_sauthors=%20%22H%20Kijima%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Kukkonen J</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Ryösä A</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Joukainen A</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Lehtinen J</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Kauko T</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Mattila K</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Äärimaa V</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Operative versus conservative
                  treatment of small, nontraumatic supraspinatus tears in patients older than 55 years: over 5-year
                  follow-up of a randomized controlled trial</span>. <span data-id="emphasis_45"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2021
                Nov;30(11):2455-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Operative%20versus%20conservative%20treatment%20of%20small%2C%20nontraumatic%20supraspinatus%20tears%20in%20patients%20older%20than%2055%20years%3A%20over%205-year%20follow-up%20of%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22J%20Kukkonen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Yoon TH</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Kim SJ</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Choi CH</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Yoon SP</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Chun YM</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">An intact subscapularis tendon
                  and compensatory teres minor hypertrophy yield lower failure rates for non-operative treatment of
                  irreparable, massive rotator cuff tears</span>. <span data-id="emphasis_46"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2019
                Oct;27(10):3240-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20intact%20subscapularis%20tendon%20and%20compensatory%20teres%20minor%20hypertrophy%20yield%20lower%20failure%20rates%20for%20non-operative%20treatment%20of%20irreparable%2C%20massive%20rotator%20cuff%20tears&as_occt=title&as_sauthors=%20%22TH%20Yoon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Austin L</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Chang ES</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Zmistowski B</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Nydick J</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Lazarus M</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Isolated subscapularis repair
                  for massive rotator cuff tear</span>. <span data-id="emphasis_47" class="annotation emphasis"
                  style="position: unset;">Orthopedics.</span> 2014 Nov;37(11):e962-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Isolated%20subscapularis%20repair%20for%20massive%20rotator%20cuff%20tear&as_occt=title&as_sauthors=%20%22L%20Austin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D5d32a53b-08f9-420f-82a2-9af4df2ee6c4%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): 26</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00411</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 29, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_48"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            University of Texas at San Antonio, San Antonio, Texas</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5d32a53b-08f9-420f-82a2-9af4df2ee6c4&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=5d32a53b-08f9-420f-82a2-9af4df2ee6c4&type=pdf&name=JBJS.24.00411.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=5d32a53b-08f9-420f-82a2-9af4df2ee6c4&type=pdf&name=JBJS.24.00411.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_53"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was in part
                          supported by the University of Texas at San Antonio and the San Antonio Medical Foundation.
                          The <span data-id="strong_54" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I254" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I254</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5d32a53b-08f9-420f-82a2-9af4df2ee6c4&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=5d32a53b-08f9-420f-82a2-9af4df2ee6c4&type=zip&name=JBJS.24.00411.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kyosuke Hoshikawa, PT, MS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0006-2767-017X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0006-2767-017X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Manuela Dominguez, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-4244-5023" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-4244-5023</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Rebekah L. Lawrence, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3777-8169" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3777-8169</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Philip M. Jacobs, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-2078-4889" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-2078-4889</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Takuma Yuri, OT, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6757-6053" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6757-6053</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nariyuki Mura, MD, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8751-1118" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8751-1118</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hugo Giambini, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:hugo.giambini@utsa.edu" class=""
                    style="position: unset;">hugo.giambini@utsa.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3147-209X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3147-209X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Biomedical Engineering and Chemical Engineering, University of
                Texas at San Antonio, San Antonio, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Graduate School of Health Sciences, Yamagata Prefectural University of Health
                Sciences, Yamagata, Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Washington University School of Medicine,
                St. Louis, Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopedics, University of Texas Health Science Center at San
                Antonio, San Antonio, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 33086.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
