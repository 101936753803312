import type { Article } from "../../../../../types";

export const Article600: Article = {
	id: 600,
	rsuiteId: "0c6e7cfe-ac32-44ba-9a14-73193530918c",
	type: "the orthopaedic forum",
	title: "Artificial Intelligence Portrayals in Orthopaedic Surgery",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0c6e7cfe-ac32-44ba-9a14-73193530918c&type=jpeg&name=JBJS.24.00150f1",
	subSpecialties: ["trauma"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Artificial Intelligence Portrayals in Orthopaedic Surgery
              </div>
              <div class="text subtitle" style="position: unset;">An Analysis of Gender and Racial Diversity Using
                Text-to-Image Generators</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Mary Morcos,
                      BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jessica
                      Duggan, BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 2 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Background:</span> The increasing accessibility of artificial
                        intelligence (AI) text-to-image generators offers a novel avenue for exploring societal
                        perceptions. The present study assessed AI-generated images to examine the representation of
                        gender and racial diversity among orthopaedic surgeons.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Methods:</span> Five prominent text-to-image generators (DALL·E 2,
                        Runway, Midjourney, ImagineAI, and JasperArt) were utilized to create images for the search
                        queries “Orthopedic Surgeon,” “Orthopedic Surgeon’s Face,” and “Portrait of an Orthopedic
                        Surgeon.” Each query produced 80 images, resulting in a total of 240 images per generator. Two
                        independent reviewers categorized race, sex, and age in each image, with a third reviewer
                        resolving discrepancies. Images with incomplete or multiple faces were excluded. The demographic
                        proportions (sex, race, and age) of the AI-generated images were then compared with those of the
                        2018 American Academy of Orthopaedic Surgeons (AAOS) census.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Results:</span> In our examination across all AI platforms, 82.8% of
                        the images depicted surgeons as White, 12.3% as Asian, 4.1% as Black, and 0.75% as other; 94.5%
                        of images were men; and a majority (64.4%) appeared ≥50 years old. DALL·E 2 exhibited
                        significantly increased diversity in representation of both women and non-White surgeons
                        compared with the AAOS census, whereas Midjourney, Runway, and ImagineAI exhibited significantly
                        decreased representation.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The present study highlighted distortions in AI
                        portrayal of orthopaedic surgeon diversity, influencing public perceptions and potentially
                        reinforcing disparities. DALL·E 2 and JasperArt show encouraging diversity, but limitations
                        persist in other generators. Future research should explore strategies for improving AI to
                        promote a more inclusive and accurate representation of the evolving demographics of orthopaedic
                        surgery, mitigating biases related to race and gender.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> This study is clinically relevant as it
                        investigates the accuracy of AI-generated images in depicting diversity among orthopaedic
                        surgeons. The findings reveal significant discrepancies in representation by race and gender,
                        which could impact societal perceptions and exacerbate existing disparities in health care.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">The advent of artificial intelligence (AI) has ushered in
                  transformative capabilities, notably with the development and widespread accessibility of
                  text-to-image generators. These AI systems provide a unique lens through which societal perceptions
                  can be explored and analyzed. In this context, the present study assessed AI-generated images to
                  examine the representation of gender and racial diversity among orthopaedic surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">The influence of AI on shaping perceptions is a critical
                  area of inquiry, as AI not only reflects technological advancements but also has the potential for
                  societal impact. These databases have seen a large surge in popularity, with some producing millions
                  of images each day<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>,
                  likely because of their ability to create highly realistic, detailed, and creative outputs in a matter
                  of seconds. Text-to-image AI has been utilized across all fields, including policy, education, art,
                  and medicine<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a>, yet instances of bias in AI algorithms, such as
                  controversial predictions of criminal activity based on race and gender, have underscored the ethical
                  challenges of AI implementation<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">7</span></a>. Recognizing this, industry leaders such as Microsoft and
                  IBM have committed to debiasing technology<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">8</span></a>,
                  acknowledging the pitfalls associated with open-source AI that draws on data from the existing
                  internet database, unveiling biases within the preexisting data and the general population.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Orthopaedic surgery, a field marked by both historical
                  underrepresentation of minority groups and evolving demographics<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">10</span></a>,
                  serves as an intriguing case study for investigating the accuracy and inclusivity of AI-generated
                  imagery. The significance of diversity in all specialties, as previously noted in medical anthropology
                  studies, enhances educational experiences, promotes professionalism, and expands the pool of
                  physicians catering to underserved and diverse patient populations<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">13</span></a>. The
                  most recent 2018 American Academy of Orthopaedic Surgeons (AAOS) census identified 92.4% of practicing
                  orthopaedic surgeons as male and 84.7% as White<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">14</span></a>. Hispanic and Black/African American physicians accounted
                  for &lt;5% of practicing surgeons<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">14</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Over recent years, strides have been made to bring the
                  level of diversity within orthopaedics closer to that of the patient population<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">18</span></a>;
                  however, these changes may be too recent to be reflected in the portrayal of orthopaedic surgeons by
                  AI. In the present study, we utilized 5 prominent text-to-image generators to create a dataset of
                  orthopaedic surgeon images, probing the portrayal of demographic characteristics such as gender, race,
                  and age. Further, we compared the generated images to the AAOS census to elucidate potential biases
                  and misrepresentations of orthopaedic surgery in the United States, either perpetuated or mitigated by
                  AI.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">AI Image
                Generation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">In the selection of the text-to-image generators for this
                  investigation, we aimed to encompass a diverse and representative array of models, considering their
                  use and recognition within the AI community. The choice of DALL·E 2, Runway, Midjourney, ImagineAI,
                  and JasperArt was informed by a thorough review of numerous articles and assessments that gauged
                  user-friendliness, realism of images, and ease of use across various text-to-image generators<a
                    href="" data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">23</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">To assemble a comprehensive dataset, we utilized 3
                  distinct search queries for each selected generator: “Orthopedic Surgeon,” “Orthopedic Surgeon’s
                  Face,” and “Portrait of an Orthopedic Surgeon.” We generated 80 images per generator for each search
                  query, resulting in a total of 240 images per database. Images featuring incomplete or multiple faces
                  were omitted, and a subsequent image was generated and stored.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Review of
                AI-Generated Images</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Two independent reviewers (J.D. and M.M.) categorized each
                  generated image based on race, with options including White, Black, Asian (encompassing East and
                  Southwest Asian), and an “other” category for races that could not be definitively determined. The
                  “other” category included the AAOS categories of “other” (Hispanic/Latino, multi-racial, other, and
                  Native American) because of their small representation among the population as well as the difficulty
                  in image classification. Classifications were based off existing databases, including the Diversity in
                  Faces, UTKFace, and Chicago Face datasets, which are large datasets with classifications of photos
                  displaying highly diverse faces of different ages, sex, and race<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">26</span></a>. Options for sex included male, female, or an “other”
                  category if the sex was unclear. Additionally, categories for perceived age were evaluated, and
                  determined as over or under 50 years old. Features such as hair color and wrinkles assisted with this
                  classification. In instances of discrepancies, a third reviewer (J.Y.) resolved the differences, and
                  results were reviewed by the entirety of the authorship group. STROBE (Strengthening the Reporting of
                  Observational Studies in Epidemiology) guidelines were followed for all methods<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">27</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Descriptive statistics and item category counts were
                  calculated with use of Microsoft Excel. Statistical analyses, including chi-square goodness-of-fit and
                  Fisher exact tests, were performed with use of SPSS Statistics (IBM). A sensitivity analysis was
                  conducted, particularly for instances in which a chi-square goodness-of-fit test could not be applied
                  because of data limitations. We opted for the Fisher exact test as an alternative in such cases,
                  especially when examining race-related variables for which data scarcity in certain categories posed
                  challenges in primary analyses and pairwise comparisons. The Fisher exact test serves as a
                  conservative measure, assuming that both AI and AOS census data groups are samples. This sensitivity
                  analysis was considered essential to address potential critiques related to data scarcity, adding an
                  extra layer of robustness to our findings. Significance was set at p = 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">A total of 1,200 images were analyzed across the 5
                  text-to-image generators (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).
                  Examples of images produced by each site can be seen in <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1</a>. There
                  was substantial to perfect agreement between the 2 independent reviewers in coding the race, gender,
                  and age of the surgeons depicted (kappa: 0.796, 0.911, and 0.846, respectively). The 2018 AAOS census,
                  comprising 6,775 members, served as a comparator for real-life demographics of orthopaedic surgeons.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Summary of the AI-Generated Images</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 417.613px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.601463);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Demographic
                          Measure</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Subcategory</span></th>
                      <th align="center" colspan="6" style="position: unset;"><span class="text"
                          style="position: unset;">AI Platform</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">DALL·E 2
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ImagineAI
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">JasperArt
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Midjourney
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Runway (N
                          = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">All
                          Platforms (N = 1,200)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Gender</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">193
                          (80%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">240
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">234
                          (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">236
                          (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">234
                          (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,137
                          (95%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (20%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63
                          (5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Age</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">≥50 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (18%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">239
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">217
                          (90%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109
                          (45%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">164
                          (68%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">773
                          (64%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;50 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">196
                          (82%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">131
                          (55%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76
                          (32%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">427
                          (36%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">Race</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">White</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">167
                          (70%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">209
                          (87%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">153
                          (64%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">230
                          (96%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">235
                          (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">994
                          (83%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Asian</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (15%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (11%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74
                          (31%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">148
                          (12%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Black</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (14%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49
                          (4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (1%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00150f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0c6e7cfe-ac32-44ba-9a14-73193530918c&type=jpeg&name=JBJS.24.00150f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;">Top left: Runway image #6 for prompt “Orthopedic
                          Surgeon.” Top right: DALL·E 2 image #10 for “Orthopedic Surgeon’s Face.” Middle left:
                          JasperArt image #3 for “Orthopedic Surgeon.” Middle right: ImagineAI image #12 for “Orthopedic
                          Surgeon.” Bottom: Midjourney image #6 for “Orthopedic Surgeon’s Face.”</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Overview</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Across all 5 AI platforms, 82.8% of images were classified
                  as White, 12.3% as Asian, 4.1% as Black, and 0.75% as “other”; 94.5% of images were classified as
                  male; and 64.4% of images were classified as ≥50 years old.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The census identified 84.7% of surgeons as White, 6.7% as
                  Asian, 1.9% as Black, and 6.7% as “other”; 92.4% of surgeons identified as male; and 51.7% of surgeons
                  were ≥50 years old. There were no significant differences between the 3 different prompts (“Orthopedic
                  Surgeon,” “Orthopedic Surgeon’s Face”, and “Portrait of an Orthopedic Surgeon”) for race, age, or
                  gender, as determined by a chi-square test.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Gender</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">The overall results for gender can be seen in <a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>. Across all AI platforms, there was a significant difference
                  in the gender proportions in AI-generated images compared with the AAOS census (p = 0.002). This held
                  true when each platform was analyzed individually, except for DALL·E 2, which demonstrated a higher
                  proportion of women surgeons compared with the census (p &lt; 0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Surgeon Gender Proportions in AI-Generated Images
                          Versus 2018 AAOS Census</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 289.379px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.660643);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="6" style="position: unset;"><span class="text"
                          style="position: unset;">AI Platform</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">2018 AAOS Census (N = 6,775)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">DALL·E 2
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ImagineAI
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">JasperArt
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Midjourney
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Runway (N
                          = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">All
                          Platforms (N = 1,200)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male gender</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">193
                          (80%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">240
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">234
                          (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">236
                          (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">234
                          (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,137
                          (95%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,260
                          (92%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female gender</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (20%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63
                          (5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515
                          (8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">P value versus2018 AAOS
                          census</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.003<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.003<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.002<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Age</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The overall results for age can be seen in <a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>. Across all AI platforms, there was a significant difference
                  in the age proportions of AI-generated images compared with the AAOS census (p &lt; 0.001). DALL·E 2
                  demonstrated a significantly greater proportion of surgeons &lt;50 years old (p &lt; 0.001), whereas
                  Runway, JasperArt, and ImagineAI demonstrated significantly lower proportions of surgeons &lt;50 years
                  old (p &lt; 0.001). Midjourney demonstrated no difference from the AAOS census.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Surgeon Age Proportions in AI-Generated Images
                          Versus 2018 AAOS Census</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 342.364px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.691176);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="6" style="position: unset;"><span class="text"
                          style="position: unset;">AI Platform</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">2018 AAOS Census (N = 6,775)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">DALL·E 2
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ImagineAI
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">JasperArt
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Midjourney
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Runway (N
                          = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">All
                          Platforms (N = 1,200)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥50 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (18%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">239
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">217
                          (90%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109
                          (45%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">164
                          (68%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">773
                          (64%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,452
                          (51%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;50 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">196
                          (82%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">131
                          (55%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76
                          (32%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">427
                          (36%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,323
                          (49%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">P value versus 2018 AAOS
                          census</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_9"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.086</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_10"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Race</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The overall results for race, including percentages, can
                  be seen in <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>. A
                  pairwise test was conducted for the following categories.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Surgeon Race Proportions in AI-Generated Images
                          Versus 2018 AAOS Census</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 437.161px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.691176);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="6" style="position: unset;"><span class="text"
                          style="position: unset;">AI Platform</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">2018 AAOS Census (N = 6,775)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">DALL·E 2
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ImagineAI
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">JasperArt
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Midjourney
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Runway (N
                          = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">All
                          Platforms (N = 1,200)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">White race</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">167
                          (70%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">209
                          (87%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">153
                          (64%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">230
                          (96%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">235
                          (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">994
                          (83%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,738
                          (85%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Asian race</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (15%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (11%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74
                          (31%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">148
                          (12%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">454
                          (7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Black race</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (14%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49
                          (4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">129
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Other race</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">454
                          (7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">P value versus 2018 AAOS
                          census</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_12"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_13"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_14"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_15"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_16"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_17"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">White Versus
                Non-White</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Across all AI platforms, there were no significant
                  differences in the White versus non-White proportions of AI-generated images compared with the AAOS
                  census. This result was also observed when ImagineAI was assessed individually. DALL·E 2 and JasperArt
                  had significantly more non-White surgeons (p &lt; 0.001), while Midjourney and Runway had
                  significantly more White surgeons (p &lt; 0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Asian Versus
                Non-Asian</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Across all AI platforms, there were significant
                  differences in the Asian versus non-Asian proportions of AI-generated images compared with the AAOS
                  census (p &lt; 0.001), with AI providing a greater proportion of Asian surgeons. This result was also
                  observed when DALL·E 2 (p &lt; 0.001), JasperArt (p &lt; 0.001), and ImagineAI were assessed
                  individually (p = 0.007). Both Midjourney (p = 0.037) and Runway (p &lt; 0.001) produced significantly
                  fewer images of non-Asian surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Black Versus
                Non-Black</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Across all AI platforms, there were significant
                  differences in the Black versus non-Black proportions of AI-generated images compared with the AAOS
                  census (p &lt; 0.001), with AI providing a greater proportion of Black surgeons. This result was also
                  observed when DALL·E 2 (p &lt; 0.001) and JasperArt (p = 0.002) were assessed individually. ImagineAI
                  and Midjourney produced similar proportions to those reported in the AAOS census. Runway produced no
                  images of Black surgeons and was therefore unable to be analyzed by goodness of fit; the Fisher exact
                  test showed significant differences in Black versus non-Black images (p = 0.023).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">“Other”
                Versus “Non-Other”</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Across all AI platforms, there were significant
                  differences in the “other” versus “non-other” proportions of AI-generated images compared with the
                  AAOS census (p &lt; 0.001), with AI providing a lower proportion of surgeons who would belong in the
                  “other” category. This result was observed in all 5 AI platforms when assessed individually (p &lt;
                  0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Sensitivity
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Both goodness-of-fit and Fisher exact tests indicated a
                  significant gender difference overall (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>). In
                  terms of age, both tests confirmed a significant difference, with individual AI platforms displaying
                  varied age distributions (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>). For
                  race, both tests aligned in indicating significant differences in the generated images compared with
                  the AAOS census (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VII</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Sensitivity Analysis Using Fisher Exact Test for
                          Surgeon Gender in AI-Generated Images Versus 2018 AAOS Census</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 320.147px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.686848);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="6" style="position: unset;"><span class="text"
                          style="position: unset;">AI Platform</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">2018 AAOS Census (N = 6,775)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">DALL·E 2
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ImagineAI
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">JasperArt
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Midjourney
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Runway (N
                          = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">All
                          Platforms (N = 1,200)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male gender</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">193
                          (80%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">240
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">234
                          (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">236
                          (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">234
                          (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,137
                          (95%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,260
                          (92%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female gender</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (20%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63
                          (5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515
                          (8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">P value versus 2018 AAOS
                          census</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_18"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_19"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.002<span
                            data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_21"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.002<span
                            data-id="table_footnote_reference_22" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.003<span
                            data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Sensitivity Analysis Using Fisher Exact Test for
                          Surgeon Age in AI-Generated Images Versus 2018 AAOS Census</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 342.364px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.691176);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="6" style="position: unset;"><span class="text"
                          style="position: unset;">AI Platform</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">2018 AAOS Census (N = 6,775)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">DALL·E 2
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ImagineAI
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">JasperArt
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Midjourney
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Runway (N
                          = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">All
                          Platforms (N = 1,200)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥50 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (18%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">239
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">217
                          (90%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109
                          (45%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">164
                          (68%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">773
                          (64%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,452
                          (51%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;50 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">196
                          (82%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">131
                          (55%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76
                          (32%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">427
                          (36%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,323
                          (49%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">P value versus 2018 AAOS
                          census</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_24"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_25"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_26"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.100</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_27"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_28"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VII</span>Sensitivity Analysis Using Fisher Exact Test for
                          Surgeon Race in AI-Generated Images Versus 2018 AAOS Census</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 437.161px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.691176);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="6" style="position: unset;"><span class="text"
                          style="position: unset;">AI Platform</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">2018 AAOS Census (N = 6,775)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">DALL·E 2
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ImagineAI
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">JasperArt
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Midjourney
                          (N = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Runway (N
                          = 240)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">All
                          Platforms (N = 1,200)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">White race</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">167
                          (70%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">209
                          (87%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">153
                          (64%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">230
                          (96%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">235
                          (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">994
                          (83%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,738
                          (85%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Asian race</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (15%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (11%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74
                          (31%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (3.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">148
                          (12%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">454
                          (7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Black race</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (14%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49
                          (4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">129
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Other race</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">454
                          (7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">P value versus 2018 AAOS
                          census</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_29"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_30"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_31"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_32"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_33"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_34"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">The present study aimed to assess how 5 leading
                  text-to-image AI platforms generated images of orthopaedic surgeons, representing a field that is
                  currently attempting to undergo a transformative shift in terms of diversification<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">9</span></a>. The focus of this analysis was not only to understand how
                  orthopaedic surgery is reflected by AI but also to provide insight into navigating the complex
                  landscape of mitigating biases, both in AI and within the field of orthopaedics itself. Our main
                  finding was that 2 AI platforms (DALL·E 2 and JasperArt) showed significantly increased racial
                  diversity, while the remaining 3 (Midjourney, Runway, and ImagineAI) showed significantly less
                  diversity, compared with the AAOS 2018 census.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">By examining 1,200 images from 5 prominent text-to-image
                  generators, we aimed to provide a characteristic sample that was consistent in size with similar
                  studies<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">28</span></a>.
                  The demographic breakdown among our results uncovered noteworthy patterns. Across all AI platforms,
                  male orthopaedic surgeons dominated, constituting 94.5% of images and showing significant gender bias
                  compared with the AAOS census (p = 0.002). However, considerable variations were observed among the
                  platforms, with DALL·E 2 notably exhibiting a greater representation of women surgeons (p &lt; 0.001)
                  and with ImagineAI producing no images of women surgeons, revealing limitations in the representation
                  of gender diversity in the platform’s training data.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Surprisingly, representation of surgeons &lt;50 years old
                  differed significantly across AI platforms (p &lt; 0.001). Notably, Runway, JasperArt, and ImagineAI
                  produced a higher proportion of images of older surgeons, whereas DALL·E 2 and Midjourney produced a
                  lower proportion, hinting at potential limitations in correctly representing age diversity among
                  orthopaedic surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Concerns regarding racial representation were also
                  apparent, with the AI platforms predominantly producing images of White surgeons (82.8%; p &lt; 0.001
                  for overall race; p = 0.346 for overall White versus non-White images). JasperArt and DALL·E 2 stood
                  out, with significantly greater proportions of images showing non-White, Asian, and Black surgeons.
                  Midjourney and Runway produced lower proportions of all non-White surgeons. Runway produced no images
                  of Black surgeons. All datasets produced significantly lower proportions of images depicting surgeons
                  in the “other” category. These findings underscore the need for diversity in training datasets.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The disproportionate depiction of White orthopaedic
                  surgeons in some AI-generated images holds the potential to simultaneously accurately reflect and
                  inadvertently reinforce existent disparities within the field. Whereas sources such as DALL·E 2 and
                  JasperArt were notably more diverse and representative within the race and gender analyses, other free
                  and publicly available resources such as ImagineAI failed to reflect the evolving diversity and
                  representation exhibited within orthopaedics. Although the AAOS 2018 census provided the most
                  up-to-date statistics on diversity among practicing orthopaedic surgeons, the orthopaedic trainee
                  population continues to grow in diversity, with recruitment aimed at producing an increasingly
                  representative field<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">29</span></a>. The biased AI representations possess the capacity to
                  influence public perceptions, potentially reinforcing and amplifying stereotypical notions and
                  impeding endeavors aimed at diversifying the orthopaedic profession.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">The source of the training data for each generator is
                  worth mentioning. The text-to-image generative capabilities of AI platforms are believed to be the
                  result of training on large datasets, a concept known as neural network training<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">30</span></a>. DALL·E 2, created by a U.S.-based AI research organization
                  called OpenAI, does not publicly disclose its datasets<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">31</span></a>.
                  JasperArt, created by Jasper, similarly does not disclose its datasets, although the platform states
                  that its neural network training utilizes a variety of sources, including social media and websites<a
                    href="" data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">32</span></a>. Midjourney’s databases include Exhibit J, which has 16,000
                  artist names, images, and styles<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">33</span></a>, as
                  well as databases such as OpenAI’s Contrastive Language-Image Pre-training (CLIP)<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">34</span></a>, Microsoft’s Common Objects in Context (COCO)<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">35</span></a>, and Large-scale Artificial Intelligence Open Network
                  (LAION)<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">36</span></a>.
                  These databases collectively contribute billions of image-caption sources. Similar to Jasper and
                  DALL·E 2, the datasets utilized by ImagineAI, created by Vyro.ai, a company based in Pakistan<a
                    href="" data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">37</span></a>, were unable to be elucidated. Lastly, although Runway also
                  does not disclose its training sets, the platform recently announced a partnership with Getty Images,
                  a media company that creates and supplies stock images and has a library of over 447 million assets<a
                    href="" data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">39</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Of the 5 AI platforms utilized in this study, 4 lacked an
                  easily and publicly accessible disclosure of the training dataset sources. The lack of transparency
                  regarding the datasets utilized by the text-to-image generators has implications for biases in the
                  generated images. Without access to information regarding the source datasets, it is challenging to
                  assess the diversity and representativeness of the training data. This opacity can lead to concerns
                  regarding the perpetuation of biases present in the training data, potentially contributing to the
                  skewed representations apparent in the generated images. Moreover, partnerships with media companies
                  that utilize stock images create a data repository that, while vast, may not accurately reflect the
                  diversity of real-world populations. Overall, the lack of transparency limits accountability and
                  hinders efforts to mitigate biases in AI-generated images, underscoring the importance of promoting
                  ethical practices in AI development.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Furthermore, the subscription-based nature of Midjourney,
                  DALL·E 2, and JasperArt introduces an additional layer of concern, given their somewhat more limited
                  accessibility as they are not freely available to the general population, requiring a small
                  subscription fee. The imposition of access restrictions may curtail the diversity of users and
                  contributors, pushing the general population toward using the more freely available and potentially
                  more biased programs. Although the increased representation of diversity shown by DALL·E 2 and
                  JasperArt is encouraging, it serves as a reminder that these powerful tools require continuous
                  improvement and will be unable to capture and reflect ongoing growth of diversity within orthopaedics
                  without the use of accurate, continually updated, and unbiased training sets<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">29</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Although the present study provided a critical analysis of
                  the orthopaedic surgeon representation by leading text-to-image AI platforms, there were certain
                  limitations. The subjective determination of race and gender, although based on existing
                  facial-recognition datasets supported by existing literature<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">28</span></a>, could present inherent challenges, mitigated to some
                  extent by employing 2 independent reviewers with high interrater reliability and resorting to a third
                  reviewer in cases of disagreement. Nevertheless, we acknowledge that gender identification and gender
                  expression may vary and cannot be determined by visual identification. We also recognize that there
                  are individuals who do not identify with a binary gender system. As for age, we acknowledge that many
                  factors such as stress may impact physical appearance in high-pressure professions. Our results were
                  supported by high interrater reliability, review by a diverse authorship group, the pooling of data
                  across multiple platforms, and previous similar studies that relied on visual assessment of race and
                  gender<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">28</span></a>.
                  Moreover, constraints inherent to the goodness-of-fit test, particularly its inability to function
                  when no images are available, limited the scope of our analysis. In cases such as these, a Fisher
                  exact test was utilized as a conservative test. The modest sample size of 1,200 images further
                  underscores the need for cautious interpretation; it is possible that a greater sample size would have
                  resulted in adjustments in the alignment of our data with the recorded census. Lastly, intrinsic
                  limitations of AI, including censorship of certain prompts and an inability to read foreign languages,
                  could impact its broader international applicability. These limitations underscore the importance of
                  ongoing refinement, which is necessary as AI technology evolves.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">The present study analyzed 5 text-to-image AI platforms
                  and the ability of each to portray orthopaedic surgeons without bias, aiming to shed light on the
                  extent to which AI may influence and, at times, distort the perception of orthopaedic surgeons.
                  Although DALL·E 2 and JasperArt were able to more accurately represent the increasing diversity in
                  orthopaedics, other databases fell short, propagating racial and gender stereotypes that persist
                  within the field. The findings not only contribute to the current discourse on AI ethics but also
                  prompt consideration of how these technologies can be harnessed to foster a more inclusive and
                  accurate representation of professionals in orthopaedic surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;">OpenAI.
                <span data-id="strong_1" class="annotation strong" style="position: unset;">DALL·E now available without
                  waitlist</span>. Accessed 2024 Jan 2. <a
                  href="https://openai.com/blog/dall-e-now-available-without-waitlist" target="_blank" data-id="link_1"
                  class="link"
                  style="position: unset;">https://openai.com/blog/dall-e-now-available-without-waitlist</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=DALL%C2%B7E%20now%20available%20without%20waitlist&as_occt=title&as_sauthors=%20%22OpenAI%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Lauterbach A</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Artificial intelligence and
                  policy: quo vadis? Digital</span><span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Policy Regulation and Governance.</span> 2019;21(3):238-63.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Artificial%20intelligence%20and%20policy%3A%20quo%20vadis%3F%20Digital&as_occt=title&as_sauthors=%20%22A%20Lauterbach%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Noel GPJC</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Evaluating AI-powered
                  text-to-image generators for anatomical illustration: A comparative study</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Anatomical Sciences
                  Education.</span> 2023. Accessed 2024 Jan 3. <a
                  href="https://onlinelibrary.wiley.com/doi/full/10.1002/ase.2336" target="_blank" data-id="link_2"
                  class="link"
                  style="position: unset;">https://onlinelibrary.wiley.com/doi/full/10.1002/ase.2336</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluating%20AI-powered%20text-to-image%20generators%20for%20anatomical%20illustration%3A%20A%20comparative%20study&as_occt=title&as_sauthors=%20%22GPJC%20Noel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Dehouche N</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Dehouche K</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">What’s in a text-to-image
                  prompt? The potential of stable diffusion in visual arts education</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Heliyon.</span> 2023 May
                26;9(6):e16757.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%E2%80%99s%20in%20a%20text-to-image%20prompt%3F%20The%20potential%20of%20stable%20diffusion%20in%20visual%20arts%20education&as_occt=title&as_sauthors=%20%22N%20Dehouche%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Parikh RB</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Teeple S</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Navathe AS</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Addressing Bias in Artificial
                  Intelligence in Health Care</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2019 Dec 24;322(24):2377-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Addressing%20Bias%20in%20Artificial%20Intelligence%20in%20Health%20Care&as_occt=title&as_sauthors=%20%22RB%20Parikh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Rajpurkar P</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Chen E</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Banerjee O</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Topol EJ</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">AI in health and
                  medicine</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Nat
                  Med.</span> 2022 Jan;28(1):31-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=AI%20in%20health%20and%20medicine&as_occt=title&as_sauthors=%20%22P%20Rajpurkar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Dastin J</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Insight - Amazon scraps secret
                  AI recruiting tool that showed bias against women</span>. Accessed 2024 Jan 2. <a
                  href="https://www.reuters.com/article/us-amazon-com-jobs-automation-insight/amazon-scraps-secret-ai-recruiting-tool-that-showed-bias-against-women-idUSKCN1MK08G/"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://www.reuters.com/article/us-amazon-com-jobs-automation-insight/amazon-scraps-secret-ai-recruiting-tool-that-showed-bias-against-women-idUSKCN1MK08G/</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Insight%20-%20Amazon%20scraps%20secret%20AI%20recruiting%20tool%20that%20showed%20bias%20against%20women&as_occt=title&as_sauthors=%20%22J%20Dastin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;">IBM
                Research Blog. <span data-id="strong_8" class="annotation strong" style="position: unset;">IBM
                  researchers investigate ways to help reduce bias in healthcare AI</span>. Accessed 2024 Jan 2. <a
                  href="https://research.ibm.com/blog/ibm-reduce-bias-in-healthcare-ai" target="_blank" data-id="link_4"
                  class="link"
                  style="position: unset;">https://research.ibm.com/blog/ibm-reduce-bias-in-healthcare-ai</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=IBM%20researchers%20investigate%20ways%20to%20help%20reduce%20bias%20in%20healthcare%20AI&as_occt=title&as_sauthors=%20%22IBM%20Research%20Blog%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Lin JS</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Lattanza LL</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Weber KL</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Balch Samora J</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Improving sexual, racial, and
                  ethnic diversity in orthopedics: An imperative</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2020 May
                1;43(3):e134-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Improving%20sexual%2C%20racial%2C%20and%20ethnic%20diversity%20in%20orthopedics%3A%20An%20imperative&as_occt=title&as_sauthors=%20%22JS%20Lin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Day MA</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Owens JM</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Caldwell LS</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Breaking Barriers: A Brief
                  Overview of Diversity in Orthopedic Surgery</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Iowa Orthop J.</span> 2019;39(1):1-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Breaking%20Barriers%3A%20A%20Brief%20Overview%20of%20Diversity%20in%20Orthopedic%20Surgery&as_occt=title&as_sauthors=%20%22MA%20Day%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Komaromy M</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Grumbach K</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Drake M</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Vranizan K</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Lurie N</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Keane D</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Bindman AB</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">The role of Black and Hispanic
                  physicians in providing health care for underserved populations</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">N Engl J Med.</span> 1996 May
                16;334(20):1305-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20Black%20and%20Hispanic%20physicians%20in%20providing%20health%20care%20for%20underserved%20populations&as_occt=title&as_sauthors=%20%22M%20Komaromy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Whitla DK</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Orfield G</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Silen W</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Teperow C</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Howard C</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Reede J</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Educational benefits of
                  diversity in medical school: a survey of students</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Acad Med.</span> 2003
                May;78(5):460-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Educational%20benefits%20of%20diversity%20in%20medical%20school%3A%20a%20survey%20of%20students&as_occt=title&as_sauthors=%20%22DK%20Whitla%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Chisholm MA</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Diversity: A Missing Link to
                  Professionalism</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Am J Pharm Educ.</span> 2004;68(5):1-3.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diversity%3A%20A%20Missing%20Link%20to%20Professionalism&as_occt=title&as_sauthors=%20%22MA%20Chisholm%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;">American
                Academy of Orthopaedic Surgeons. <span data-id="strong_14" class="annotation strong"
                  style="position: unset;">Orthopaedic Practice in the US 2018 Report</span>. Accessed 2024 Jan 2. <a
                  href="https://www5.aaos.org/store/product/?productId=13545850&ssopc=1" target="_blank"
                  data-id="link_5" class="link"
                  style="position: unset;">https://www5.aaos.org/store/product/?productId=13545850&ssopc=1</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Orthopaedic%20Practice%20in%20the%20US%202018%20Report&as_occt=title&as_sauthors=%20%22American%20Academy%20of%20Orthopaedic%20Surgeons%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Ihejirika-Lomedico R</span>,
                <span data-id="annotation_35" class="annotation" style="position: unset;"> Brooks J</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> McLaurin TM</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Egol KA</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Hogan MV</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> LaPorte D</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Pean C</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Jegede K</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Kemp AK</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Taylor E</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Zuckerman JD</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Dyer G</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Increasing Diversity in
                  Orthopaedics: The Problem, Strategies, and Solutions</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Instr Course Lect.</span>
                2023;72:79-87.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increasing%20Diversity%20in%20Orthopaedics%3A%20The%20Problem%2C%20Strategies%2C%20and%20Solutions&as_occt=title&as_sauthors=%20%22R%20Ihejirika-Lomedico%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Dykes DC</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> White AA 3rd</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Getting to equal: strategies to
                  understand and eliminate general and orthopaedic healthcare disparities</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2009 Oct;467(10):2598-605.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Getting%20to%20equal%3A%20strategies%20to%20understand%20and%20eliminate%20general%20and%20orthopaedic%20healthcare%20disparities&as_occt=title&as_sauthors=%20%22DC%20Dykes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Brooks JT</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Dixon T</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Simpson Mason B</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Archdeacon M</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">An Organizational Approach to
                  Addressing Racism in Orthopaedic Surgery: AOA Critical Issues Symposium</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2022;104(20):E88.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=3301039" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Wright MA</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Murthi AM</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Aleem A</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Zmistowski B</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Patient Disparities and
                  Provider Diversity in Orthopaedic Surgery: A Complex Relationship</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2023 Feb
                1;31(3):132-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20Disparities%20and%20Provider%20Diversity%20in%20Orthopaedic%20Surgery%3A%20A%20Complex%20Relationship&as_occt=title&as_sauthors=%20%22MA%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Sharma R</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">12 Best AI Text to Image
                  Generator Tools in January 2024</span>. Accessed 2024 Jan 2. <a
                  href="https://www.webdew.com/blog/ai-text-to-image-generator/" target="_blank" data-id="link_6"
                  class="link"
                  style="position: unset;">https://www.webdew.com/blog/ai-text-to-image-generator/</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=12%20Best%20AI%20Text%20to%20Image%20Generator%20Tools%20in%20January%202024&as_occt=title&as_sauthors=%20%22R%20Sharma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;">ZDNET.
                <span data-id="strong_20" class="annotation strong" style="position: unset;">The best AI image
                  generators to try right now</span>. Accessed 2024 Jan 2. <a
                  href="https://www.zdnet.com/article/best-ai-art-generator/" target="_blank" data-id="link_7"
                  class="link"
                  style="position: unset;">https://www.zdnet.com/article/best-ai-art-generator/</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20best%20AI%20image%20generators%20to%20try%20right%20now&as_occt=title&as_sauthors=%20%22ZDNET%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;">Zapier.
                <span data-id="strong_21" class="annotation strong" style="position: unset;">The best AI image
                  generators in 2024</span>. Accessed 2024 Jan 2. <a
                  href="https://zapier.com/blog/best-ai-image-generator/" target="_blank" data-id="link_8" class="link"
                  style="position: unset;">https://zapier.com/blog/best-ai-image-generator/</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20best%20AI%20image%20generators%20in%202024&as_occt=title&as_sauthors=%20%22Zapier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;">Cyberlink.
                <span data-id="strong_22" class="annotation strong" style="position: unset;">10 Best AI Image Generator
                  Apps To Convert Text To Image</span>. Accessed 2024 Jan 2. <a
                  href="https://www.cyberlink.com/blog/ai-app-photo-editing/2419/best-ai-image-generators-from-text"
                  target="_blank" data-id="link_9" class="link"
                  style="position: unset;">https://www.cyberlink.com/blog/ai-app-photo-editing/2419/best-ai-image-generators-from-text</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=10%20Best%20AI%20Image%20Generator%20Apps%20To%20Convert%20Text%20To%20Image&as_occt=title&as_sauthors=%20%22Cyberlink%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Gwira C</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">12 Best AI Text-to-Image Tools
                  in 2023 (Compared)</span>. Accessed 2024 Jan 2. <a
                  href="https://www.elegantthemes.com/blog/design/best-ai-text-to-image-tools" target="_blank"
                  data-id="link_10" class="link"
                  style="position: unset;">https://www.elegantthemes.com/blog/design/best-ai-text-to-image-tools</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=12%20Best%20AI%20Text-to-Image%20Tools%20in%202023%20(Compared)&as_occt=title&as_sauthors=%20%22C%20Gwira%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;">UTKFace.
                <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Large Scale Face
                  Dataset.</span> Accessed 2024 Jun 25. <a href="https://susanqq.github.io/UTKFace/" target="_blank"
                  data-id="link_11" class="link" style="position: unset;">https://susanqq.github.io/UTKFace/</a>.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Ma DS</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Correll J</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Wittenbrink B</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">The Chicago face database: A
                  free stimulus set of faces and norming data</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Behav Res Methods.</span> 2015
                Dec;47(4):1122-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Chicago%20face%20database%3A%20A%20free%20stimulus%20set%20of%20faces%20and%20norming%20data&as_occt=title&as_sauthors=%20%22DS%20Ma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Merler M</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Ratha N</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Feris RS</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Smith JR</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Diversity in Faces</span>. 2019
                Jan 29 Accessed 2024 Jan 6. <a href="https://arxiv.org/abs/1901.10436v6" target="_blank"
                  data-id="link_12" class="link"
                  style="position: unset;">https://arxiv.org/abs/1901.10436v6</a></span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diversity%20in%20Faces&as_occt=title&as_sauthors=%20%22M%20Merler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;">STROBE.
                <span data-id="strong_26" class="annotation strong" style="position: unset;">What is STROBE?</span>
                Accessed 2024 Jan 6. <a href="https://www.strobe-statement.org/" target="_blank" data-id="link_13"
                  class="link" style="position: unset;">https://www.strobe-statement.org/</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20is%20STROBE%3F&as_occt=title&as_sauthors=%20%22STROBE%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Ali R</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Tang OY</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Connolly ID</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Abdulrazeq HF</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Mirza FN</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Lim RK</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Johnston BR</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Groff MW</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Williamson T</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Svokos K</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Libby TJ</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Shin JH</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Gokaslan ZL</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Doberstein CE</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Zou J</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Asaad WF</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Demographic Representation in 3
                  Leading Artificial Intelligence Text-to-Image Generators</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">JAMA Surg.</span> 2024 Jan
                1;159(1):87-95.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Demographic%20Representation%20in%203%20Leading%20Artificial%20Intelligence%20Text-to-Image%20Generators&as_occt=title&as_sauthors=%20%22R%20Ali%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Fierro SX</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Jardaly AH</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Vatsia SK</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Williams MD</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Taunton JD</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Gruenberger EH</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Navarro RA</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Mehran N</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Ponce BA</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Diversity in Orthopaedic
                  Surgery Residencies Based on Allopathic Medical School Affiliation</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">JB JS Open Access.</span> 2023 May
                4;8(2):e22.00113.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diversity%20in%20Orthopaedic%20Surgery%20Residencies%20Based%20on%20Allopathic%20Medical%20School%20Affiliation&as_occt=title&as_sauthors=%20%22SX%20Fierro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;">Machine
                Learning. <span data-id="strong_29" class="annotation strong" style="position: unset;">Training Neural
                  Networks</span>. Accessed 2024 Mar 12. <a
                  href="https://developers.google.com/machine-learning/crash-course/training-neural-networks/video-lecture"
                  target="_blank" data-id="link_14" class="link"
                  style="position: unset;">https://developers.google.com/machine-learning/crash-course/training-neural-networks/video-lecture</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Training%20Neural%20Networks&as_occt=title&as_sauthors=%20%22Machine%20Learning%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;">OpenAI.
                <span data-id="strong_30" class="annotation strong" style="position: unset;">DALL·E 3</span>. Accessed
                2024 Mar 12. <a href="https://openai.com/dall-e-3" target="_blank" data-id="link_15" class="link"
                  style="position: unset;">https://openai.com/dall-e-3</a>.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=DALL%C2%B7E%203&as_occt=title&as_sauthors=%20%22OpenAI%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;">Jasper.
                <span data-id="strong_31" class="annotation strong" style="position: unset;">AI Art Generator | AI Image
                  Generator</span>. Accessed 2024 Mar 12. <a href="https://www.jasper.ai/art" target="_blank"
                  data-id="link_16" class="link" style="position: unset;">https://www.jasper.ai/art</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=AI%20Art%20Generator%20%7C%20AI%20Image%20Generator&as_occt=title&as_sauthors=%20%22Jasper%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;">NSS
                Magazine. <span data-id="strong_32" class="annotation strong" style="position: unset;">The artists that
                  Midjourney employs to train the AI</span>. Accessed 2024 Mar 12. <a
                  href="https://www.nssmag.com/en/lifestyle/35338/midjourney-artisti" target="_blank" data-id="link_17"
                  class="link"
                  style="position: unset;">https://www.nssmag.com/en/lifestyle/35338/midjourney-artisti</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20artists%20that%20Midjourney%20employs%20to%20train%20the%20AI&as_occt=title&as_sauthors=%20%22NSS%20Magazine%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;">OpenAI.
                <span data-id="strong_33" class="annotation strong" style="position: unset;">CLIP: Connecting text and
                  images</span>. Accessed 2024 Mar 12. <a href="https://openai.com/research/clip" target="_blank"
                  data-id="link_18" class="link"
                  style="position: unset;">https://openai.com/research/clip</a>.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=CLIP%3A%20Connecting%20text%20and%20images&as_occt=title&as_sauthors=%20%22OpenAI%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;">COCO -
                Common Objects in Context. Homepage. Accessed 2024 Mar 12. <a href="https://cocodataset.org/#home"
                  target="_blank" data-id="link_19" class="link"
                  style="position: unset;">https://cocodataset.org/#home</a>.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Beaumont R</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">LAION-5B: A NEW ERA OF OPEN
                  LARGE-SCALE MULTI-MODAL DATASETS</span>. Accessed 2024 Mar 12. <a
                  href="https://laion.ai/blog/laion-5b/" target="_blank" data-id="link_20" class="link"
                  style="position: unset;">https://laion.ai/blog/laion-5b/</a>.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=LAION-5B%3A%20A%20NEW%20ERA%20OF%20OPEN%20LARGE-SCALE%20MULTI-MODAL%20DATASETS&as_occt=title&as_sauthors=%20%22R%20Beaumont%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;">Vyro.
                <span data-id="strong_35" class="annotation strong" style="position: unset;">Vyro AI</span>. Accessed
                2024 Mar 12. <a href="https://vyro.ai/about" target="_blank" data-id="link_21" class="link"
                  style="position: unset;">https://vyro.ai/about</a>.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vyro%20AI&as_occt=title&as_sauthors=%20%22Vyro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;">Runway
                Blog. <span data-id="strong_36" class="annotation strong" style="position: unset;">Runway partners with
                  Getty Images to build enterprise ready AI tools</span>. Accessed 2024 Mar 12. <a
                  href="https://runwayml.com/blog/runway-partners-with-getty-images/" target="_blank" data-id="link_22"
                  class="link"
                  style="position: unset;">https://runwayml.com/blog/runway-partners-with-getty-images/</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Runway%20partners%20with%20Getty%20Images%20to%20build%20enterprise%20ready%20AI%20tools&as_occt=title&as_sauthors=%20%22Runway%20Blog%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;">Getty
                Images. <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Royalty-Free
                  Stock Photos, Creative Images & Vectors | News, Fashion, and Entertainment imagery.</span> Accessed
                2024 Mar 12. <a href="https://www.gettyimages.com/" target="_blank" data-id="link_23" class="link"
                  style="position: unset;">https://www.gettyimages.com/</a>.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D0c6e7cfe-ac32-44ba-9a14-73193530918c%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2278</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00150</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_20"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Harvard
                            Medical School, Boston, Massachusetts, and the Harvard Combined Orthopaedic Residency
                            Program, Boston, Massachusetts</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;0c6e7cfe-ac32-44ba-9a14-73193530918c&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=0c6e7cfe-ac32-44ba-9a14-73193530918c&type=pdf&name=JBJS.24.00150.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=0c6e7cfe-ac32-44ba-9a14-73193530918c&type=pdf&name=JBJS.24.00150.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_42"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_43" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I106" target="_blank" data-id="link_24"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I106</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;0c6e7cfe-ac32-44ba-9a14-73193530918c&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=0c6e7cfe-ac32-44ba-9a14-73193530918c&type=zip&name=JBJS.24.00150.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mary Morcos, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:marymorcos@hms.harvard.edu" class=""
                    style="position: unset;">marymorcos@hms.harvard.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9269-0042" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9269-0042</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jessica Duggan, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6197-0842" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6197-0842</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jason Young, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9718-1797" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9718-1797</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shaina A. Lipa, MD, MPH<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8041-7601" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8041-7601</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Harvard Medical School, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Harvard Combined Orthopaedic Residency Program, Boston, Massachusetts</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Brigham and Women’s Hospital, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 27200.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
