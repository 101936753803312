import { relayEnv } from "@memorang/configs";
import { fetchData } from "@memorang/helpers";
import type { AchievementType } from "@memorang/types";
/* -----------------Globals--------------- */
import { graphql } from "react-relay";

type Response = {
	getAchievementList: AchievementType;
};
const query = graphql`
  query FetchAchievementsQuery($userId: ID!) {
    getAchievementList(userId: $userId) {
      id
      points
      level
      events
      totalEvents
      usersEarned
      title
      description
      icon {
        url
        backgroundColor
      }
    }
  }
`;

const fetchAchievements = async (userId: string) => {
	const variables = {
		userId,
	};

	const { getAchievementList } = (await fetchData(
		relayEnv,
		query,
		variables,
	)) as Response;

	return {
		getAchievementList,
	};
};

export default fetchAchievements;
