/* -----------------UI--------------- */
import { Text } from "react-native-paper";

/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

/* -----------------Child components--------------- */
import { isValidElement } from "react";
import { Box, type IconType, TooltipWrapper, useAppTheme } from "../../index";
import { OverlineText } from "../OverlineText";

/* -----------------Props--------------- */
export type StatProps = {
	tooltip?: string;
	overlineText: string;
	icon: IconType | React.ReactNode;
	color?: string;
	stacked?: boolean;
	value?: string;
};

/* -----------------Component--------------- */
const StatItem = (props: StatProps) => {
	const { tooltip, value, overlineText, icon, color, stacked } = props;
	const theme = useAppTheme();
	// description

	const RenderIcon = () => {
		return (
			<>
				{isValidElement(icon) ? (
					icon
				) : (
					<Icon
						name={icon as IconType}
						color={color || theme.colors.text}
						size={24}
					/>
				)}
			</>
		);
	};
	const Stacked = () => (
		<Box
			alignItems="center"
			maxWidth={120}
			style={{
				cursor: "help",
			}}
		>
			<RenderIcon />
			<Text numberOfLines={1} variant="labelSmall">
				{value}
			</Text>
			<OverlineText numberOfLines={1}>{overlineText}</OverlineText>
		</Box>
	);

	const Row = () => (
		<Box flexDirection="row" alignItems="center" maxWidth={120}>
			<RenderIcon />
			<Box
				display="flex"
				justifyContent="center"
				flexDirection="column"
				marginLeft={8}
			>
				<OverlineText numberOfLines={1}>{overlineText}</OverlineText>
				<Text numberOfLines={1} variant="labelSmall">
					{value}
				</Text>
			</Box>
		</Box>
	);

	const Content = () => {
		if (stacked) {
			return <Stacked />;
		}
		return <Row />;
	};

	return tooltip ? (
		<TooltipWrapper title={tooltip}>
			<Content />
		</TooltipWrapper>
	) : (
		<Content />
	);
};

export { StatItem };
