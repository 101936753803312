import { CardContainer, DonutScore, TooltipWrapper } from "@memorang/ui";
/* -----------------UI--------------- */
import { Caption, Card } from "react-native-paper";
import { PracticeTestCardListItem } from "./PracticeTestCardListItem";

type ListItemData = {
	title: string;
	description: string;
	score: number;
	cta: string;
	numericId: number;
};

type Props = {
	title: string;
	subtitle: string;
	list: ListItemData[];
	avgScore: number;
};
export const PracticeTestsCard = ({
	title,
	subtitle,
	list,
	avgScore,
}: Props) => {
	const Content = () => (
		<Card.Content
			style={{ paddingTop: 16, paddingBottom: 8, paddingHorizontal: 0 }}
		>
			<TooltipWrapper
				title={`Your current estimated performance score is ${avgScore}%. This is an approximation of how well you are expected to perform.`}
			>
				<DonutScore
					distribution={[avgScore, 100 - avgScore]}
					score={avgScore}
					radius={42}
					variant="progress"
					innerRadius={32}
					textVariant="titleMedium"
				/>
			</TooltipWrapper>
			<Caption
				style={{
					textAlign: "center",
				}}
			>
				Estimated Performance
			</Caption>
			{list.map(
				({ title: listItemTitle, description, score, cta, numericId }) => (
					<PracticeTestCardListItem
						key={listItemTitle}
						title={listItemTitle}
						description={description}
						score={score}
						cta={cta}
						numericId={numericId}
					/>
				),
			)}
		</Card.Content>
	);

	return (
		<CardContainer
			title={title}
			subtitle={subtitle}
			icon="clipboard-check-multiple-outline"
		>
			<Content />
		</CardContainer>
	);
};
