/* ----------------- React --------------- */
import { useState } from "react";

/* ----------------- Third parties --------------- */
import { useRouter } from "expo-router";
import { HelperText, TextInput } from "react-native-paper";

/* ----------------- Applets --------------- */
import { useAuthContext } from "@memorang/applets";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "@hooks/useAppTheme";

/* ----------------- Constants --------------- */
import { events } from "@constants/tracking";

/* ----------------- Analytics --------------- */
import { trackCustomEvent } from "analytics";

/* ----------------- UI --------------- */
import { Box, MarkdownRenderer } from "@memorang/ui";

/* ----------------- Components --------------- */
import DeleteFormActions from "./DeleteFormActions";

/* ----------------- Mutations --------------- */
import { useClerk } from "@clerk/clerk-expo";
import { useToast } from "@contexts/ToastContext";
import { zodResolver } from "@hookform/resolvers/zod";
import useClerkUser from "@hooks/useClerkUser";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

type Props = {
	handleSendFeedback: () => void;
	handleDismiss: () => void;
};

const emailSchema = z.object({
	email: z.string().email("Please enter a valid email address"),
});
const DeleteForm = ({ handleSendFeedback, handleDismiss }: Props) => {
	const { viewerId: userId } = useAuthContext();

	const { email: existingUserEmail } = useClerkUser();

	const router = useRouter();

	const { showToast } = useToast();

	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
	} = useForm({
		resolver: zodResolver(emailSchema),
		mode: "onChange",
	});

	const email = watch("email");

	const [showError, setShowError] = useState(false);
	const [loading, setLoading] = useState(false);

	const { user } = useClerk();

	const handleLogout = () => {
		handleDismiss();
		router.replace("/");
	};

	const handleDelete = async () => {
		setLoading(true);
		const finalEmail = email.trim().toLowerCase();
		if (finalEmail !== existingUserEmail) {
			setShowError(true);
			setLoading(false);
			return;
		}
		if (email) {
			try {
				await user?.delete();
				showToast({
					message: "Account deleted successfully!",
				});
				handleSendFeedback();
				trackCustomEvent({
					eventName: events.accountDeleted,
					userId,
					email,
				});
				await handleLogout();
			} catch (err) {
				setShowError(true);
				setValue("email", "");
				console.error("Error deleting account:", err);
			} finally {
				setLoading(false);
			}
		}
	};

	const handleFormAction = (shouldDelete?: boolean) => {
		if (shouldDelete) {
			handleDelete();
		} else {
			handleDismiss();
		}
	};

	const theme = useAppTheme();

	const errorMessage =
		"Sorry, that email doesn't match this account. Please try again";
	return (
		<>
			<Controller
				control={control}
				render={({ field: { onChange, onBlur, value } }) => (
					<Box>
						<TextInput
							mode="outlined"
							placeholder="e.g., dave@gmail.com"
							onChangeText={(text) => {
								onChange(text);
								setShowError(false);
							}}
							onBlur={onBlur}
							value={value}
							autoFocus
							label="Email"
							autoCapitalize="none"
							onSubmitEditing={handleSubmit(() => handleFormAction(true))}
							keyboardType="email-address"
							disabled={loading}
							style={{ width: "100%" }}
						/>
						{errors.email && typeof errors.email.message === "string" && (
							<HelperText type="error">{errors.email.message}</HelperText>
						)}
						{showError && !errors.email && (
							<MarkdownRenderer
								text={errorMessage}
								customStyles={{
									text: {
										fontSize: 12,
										color: theme.colors.error,
									},
								}}
							/>
						)}
						<DeleteFormActions
							handleAction={handleFormAction}
							primaryActionLabel="Delete"
							disablePrimaryButton={!email || loading}
							loadingPrimaryButton={loading}
						/>
					</Box>
				)}
				name="email"
				defaultValue=""
			/>
		</>
	);
};

export default DeleteForm;
