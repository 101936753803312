import { useEffect } from "react";
import { Platform } from "react-native";
import {
	type HotkeyItemOptions,
	getHotkeyHandler,
	getHotkeyMatcher,
} from "../parseHotkey";

export type { HotkeyItemOptions };
export { getHotkeyHandler };

export type HotkeyItem = [
	string,
	(event: KeyboardEvent) => void,
	HotkeyItemOptions?,
];

function shouldFireEvent(
	event: KeyboardEvent,
	tagsToIgnore: string[],
	triggerOnContentEditable = false,
) {
	if (event.target instanceof HTMLElement) {
		if (triggerOnContentEditable) {
			return !tagsToIgnore.includes(event.target.tagName);
		}

		return !(
			event.target.isContentEditable ||
			tagsToIgnore.includes(event.target.tagName)
		);
	}

	return true;
}

export function useHotkeys(
	hotkeys: HotkeyItem[],
	tagsToIgnore: string[] = ["INPUT", "TEXTAREA", "SELECT"],
	triggerOnContentEditable = false,
) {
	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	useEffect(() => {
		if (Platform.OS !== "web") {
			return;
		}
		const keydownListener = (event: KeyboardEvent) => {
			for (const [
				hotkey,
				handler,
				options = { preventDefault: true },
			] of hotkeys) {
				if (
					getHotkeyMatcher(hotkey)(event) &&
					shouldFireEvent(event, tagsToIgnore, triggerOnContentEditable)
				) {
					if (options.preventDefault) {
						event.preventDefault();
					}

					handler(event);
				}
			}
		};

		document.documentElement.addEventListener("keydown", keydownListener);
		return () =>
			document.documentElement.removeEventListener("keydown", keydownListener);
	}, [hotkeys]);
}
