import type { Article } from "../../../../../types";

export const Article661: Article = {
	id: 661,
	rsuiteId: "af870c67-cc5c-49b9-96a7-45c0d7faf4cb",
	type: "scientific article",
	title: "Vertebral Body Tethering Surgery",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=af870c67-cc5c-49b9-96a7-45c0d7faf4cb&type=jpeg&name=JBJS.23.00930f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Vertebral Body Tethering Surgery</div>
              <div class="text subtitle" style="position: unset;">Study Showing No Decrease in Coronal Curve Magnitude
                After First Postoperative Erect Radiograph in 92% of Patients</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Tiffany N.
                      Phan, BA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Tishya A.L.
                      Wren, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 8 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_22" class="annotation strong"
                          style="position: unset;">Background:</span> Much enthusiasm has been generated around
                        vertebral body tethering (VBT) as an alternative to fusion treatment. However, the majority of
                        studies have shown little difference between coronal curve magnitude on the first postoperative
                        erect radiograph and that at final follow-up, suggesting that VBT is not consistently modulating
                        spine growth in a manner that substantially affects coronal curve magnitude.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_23" class="annotation strong"
                          style="position: unset;">Methods:</span> All patients with adolescent idiopathic scoliosis
                        (AIS) who underwent VBT between December 2013 and June 2020 and subsequently had at least 2
                        years of follow-up in a multicenter registry were reviewed. The change in coronal curve
                        magnitude was calculated by subtracting the magnitude at the time of final follow-up from that
                        on the first postoperative erect radiograph. A change of &lt;5° was considered to be within
                        normal measurement variability.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_24" class="annotation strong"
                          style="position: unset;">Results:</span> One hundred and ten patients met the inclusion
                        criteria (racial breakdown: 94 White, 6 Black/African American, 3 Asian, 2 Middle Eastern, 1
                        mixed race, and 4 uncertain/unspecified; ethnicity: 87 not Hispanic or Latino, 1
                        Hispanic/Latino, and 22 did not report their ethnicity). Their mean age was 12.9 years (standard
                        deviation [SD], 1.3 years) and the mean follow-up was 3.7 years (range, 2.0 to 6.9 years). A
                        mean of 6.6 levels were tethered. The preoperative mean coronal curve magnitude was 51.0°
                        (range, 32° to 75°), which corrected to a mean of 27.3° (range, 10° to 53°) on the first
                        postoperative erect radiograph. At the time of final follow-up, the mean coronal curve magnitude
                        was 30.9° (range, −50° to 69°), and 49.1% (54) of the 110 curves were stable (the coronal curve
                        magnitude at final follow-up was within 5° of that on the first postoperative erect radiograph).
                        A total of 42.7% (47) had &gt;5° of increase in coronal curve magnitude following the initial
                        postoperative erect radiograph, whereas 8.2% (9) showed a &gt;5° increase during the follow-up
                        period. Five of the 9 patients (4.5% of the series) had a &gt;10° decrease, with 2 of the 5
                        having overcorrection, ending up with 31° and 50° curves in the opposite direction.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_25" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Although VBT holds promise and many (49.1%) of
                        the 110 curves in this series remained stable from the first postoperative erect to the final
                        follow-up radiograph, only 9 demonstrated a decrease in coronal curve magnitude over time,
                        including 2 that overcorrected. Further research is needed to identify the factors
                        differentiating among patients who had curve progression, did not have progression, or had
                        overcorrection to determine which patients are more likely to benefit from VBT.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_26" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level IV</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Adolescent idiopathic scoliosis (AIS) is the most common
                  form of scoliosis, and posterior spinal fusion (PSF) remains the gold standard due to its predictable
                  clinical and surgical outcomes<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">5</span></a>. However, the potential for preservation of mobility has
                  generated interest in anterior vertebral body tethering (VBT) as an alternative surgical treatment.
                  VBT is intended to be a fusionless procedure, designed to capitalize on growth modulation via the
                  Hueter-Volkmann principle<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">6</span></a>—i.e.,
                  that growth increases when a distracting force is applied and is inhibited when there is compression
                  in skeletally immature patients. In a series of 51 patients who underwent VBT, McDonald et al.
                  reported slower growth on the convexity relative to the concavity of 764 thoracic vertebrae,
                  supporting this concept<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">7</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Despite the potential for an altered growth rate on the
                  concavity relative to the convexity, the majority of studies of this growth-guided technique have
                  shown little difference between the coronal curve magnitude on the first postoperative erect
                  radiograph and that at final follow-up<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">10</span></a>.
                  Mathew et al. reported on a matched control-case series that underwent VBT or PSF. A preoperative
                  curve magnitude of 50° in the 26 patients in the VBT group was corrected to 22° at 3 months
                  postoperatively, 25° at 1 year, and 23° at 2 years<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">9</span></a>.
                  Similarly, Rushton et al. reported that an average preoperative curve magnitude of 50.8° in their
                  series corrected to 26.6° on the first postoperative erect radiograph, was 23.1° at 1 year, and
                  increased to 25.7° at the time of final follow-up<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>.
                  Newton et al. demonstrated a mild improvement from 31° postoperatively to 27° at final follow-up<a
                    href="" data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">11</span></a>. Given the similar values from initial postoperative
                  correction to final follow-up, the literature suggests that VBT is not consistently modulating spinal
                  growth in a manner that substantially affects coronal curve magnitude. Some have suggested that the
                  reason why investigators have not found differences between the first erect postoperative and final
                  follow-up radiographs is because they looked at means, which do not account for variability—i.e., some
                  patients have tether breakage and subsequent worsening while others have correction or even
                  overcorrection.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The purpose of this study was to examine more granularly
                  the effect of VBT on coronal curve magnitude in patients with AIS followed for a minimum of 2 years
                  postoperatively. We hypothesized that coronal curve magnitude would remain stable following VBT in the
                  majority of patients with AIS.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Following institutional review board approval, a
                  retrospective review of a prospective multicenter registry was conducted to evaluate the outcomes in
                  skeletally immature patients (Risser sign of &lt;4) with AIS who underwent VBT between December 2013
                  and June 2020 and subsequently had at least 2 years of follow-up. Patients were excluded if they had
                  &lt;2 years of follow-up or were missing radiographs at any of 3 time points (preoperative, first
                  erect postoperative, and final follow-up radiographs). Demographics, the coronal curve magnitude at
                  the 3 time points, status of the triradiate cartilage, Risser sign, and number of tethered levels were
                  collected. The Proximal Femur Maturity Index described by Cheung et al.<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">12</span></a> was also determined as an additional assessment of skeletal
                  maturity. Final follow-up radiographs were defined as those made either most recently or just prior to
                  fusion. The change in coronal curve magnitude was calculated by subtracting the magnitude at the time
                  of final follow-up from that on the first postoperative erect radiograph. Consistent with the study by
                  Morrissy et al.<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">13</span></a>, a
                  difference in angles of ≤5° was considered within normal measurement variability. All radiographic
                  measurements were processed centrally using Surgimap (Nemaris) by the Pediatric Spine Study Group
                  (PSSG).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The collected data were analyzed using descriptive and
                  inferential statistics. As this was a retrospective review of prospectively collected data, a power
                  analysis was not performed. Univariate logistic regression was utilized to analyze continuous
                  variables. Statistical analyses were performed using STATA/1C 14.0 (Stata Statistical Software:
                  Release 14; StataCorp).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">A total of 110 patients (101 female, 9 male) met the
                  inclusion criteria. The racial breakdown of the participants was 85.5% (94) White, 5.5% (6)
                  Black/African American, 2.7% (3) Asian, 1.8% (2) Middle Eastern, 0.9% (1) mixed race, and 3.6% (4)
                  unknown. With regard to ethnicity, 79.1% (87) were not Hispanic or Latino, 0.9% (1) were
                  Hispanic/Latino, and 20.0% (22) did not specify their ethnicity. Their mean age was 12.9 years
                  (standard deviation [SD], 1.3 years) at the time of the initial VBT, and the mean follow-up was 3.7
                  years (range, 2.0 to 6.9 years). Menarcheal status was not available for 10 of the 101 females. Of the
                  remaining 91 females, the majority (62; 68.1%) were premenarcheal. The Risser sign was 0 in 69
                  patients, 1 in 19, 2 in 13, and 3 in 9. A mean of 6.6 levels (SD, 1.1; range, 4 to 11) were tethered.
                  The majority (102) of the 110 implanted tethers were thoracic, 5 were thoracolumbar, and 3 were double
                  tethers.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The preoperative mean coronal curve magnitude was 51.0°
                  (range, 32° to 75°), which corrected to a mean of 27.3° (range, 10° to 53°) on the first postoperative
                  erect radiograph. The first erect radiographs were made in accordance with the postoperative protocol
                  at each institution, at an average of 50.1 days (SD, 29.3 days) postoperatively. At the time of final
                  follow-up, the mean coronal curve magnitude was 30.9° (range, −50° to 69°) and 49.1% (54) of the 110
                  curves were stable (coronal curve magnitude within 5° of that on the first erect radiograph) (<a
                    href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). A total of 42.7% (47) of the patients had &gt;5° of increase
                  in the coronal curve magnitude following the first postoperative erect radiograph, whereas 8.2% (9)
                  showed a &gt;5° decrease in the coronal curve magnitude during the follow-up period. Five of the 9
                  (4.5% of the series) had &gt;10° of additional correction, with 2 of the 5 having overcorrection,
                  ending up with 31° and 50° curves in the opposite direction. Of note, only 3 of the 110 patients had a
                  major coronal curve magnitude that was &lt;0° at final follow-up. <a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figures 2</a>, <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">3</a>, and <a
                    href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">4</a> show radiographs of patients who had improvement, overcorrection, and
                  worsening of the coronal curve, respectively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00930f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=af870c67-cc5c-49b9-96a7-45c0d7faf4cb&type=jpeg&name=JBJS.23.00930f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;">Differences in the magnitude of the coronal curve
                          angle, calculated by subtracting the magnitude on the first postoperative erect radiograph
                          from that on the final follow-up radiograph. Purple = overcorrection, green = improvement,
                          yellow = stabilization, and red = progression.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00930f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=af870c67-cc5c-49b9-96a7-45c0d7faf4cb&type=jpeg&name=JBJS.23.00930f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;">Example of a patient who had improvement after the
                          first postoperative erect radiograph. This 9.5-year-old girl had a 51° curve preoperatively
                          (Fig. 2-A), which improved to 30° on the first erect radiograph (Fig. 2-B) and then to 12°
                          over 3.9 years of follow-up (Fig. 2-C).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.00930f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=af870c67-cc5c-49b9-96a7-45c0d7faf4cb&type=jpeg&name=JBJS.23.00930f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;">Example of a patient who had overcorrection after
                          the first postoperative erect radiograph. This 11.1-year-old girl had a 46° curve
                          preoperatively (Fig. 3-A), which improved to 20° on the first erect radiograph (Fig. 3-B) and
                          subsequently overcorrected to −31° over 2.3 years of follow-up (Fig. 3-C).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.00930f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=af870c67-cc5c-49b9-96a7-45c0d7faf4cb&type=jpeg&name=JBJS.23.00930f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Example of a patient who had worsening after the
                          first postoperative erect radiograph. This 14.2-year-old girl with a 42° curve preoperatively
                          (Fig. 4-A), which improved to 26° on the first erect radiograph (Fig. 4-B) and subsequently
                          increased to 37° over 3.2 years of follow-up (Fig. 4-C).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Although only 8.2% of the patients demonstrated
                  improvement from the first postoperative erect radiograph, 93.6% had a curve of &lt;50° at final
                  follow-up and 57.3% had a curve of &lt;35° at that time. Definitive fusion was eventually performed in
                  4.5% (5) of the 110 patients, and tether revision was done in 1.8% (2) of the 110.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Curve
                Flexibility</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Preoperative bending radiographs, available for 70 of the
                  110 patients, were reviewed in an effort to assess curve flexibility. Of the 70 patients, only 7 had a
                  bending curve magnitude of &gt;35°. Of the 63 curves with a magnitude of ≤35°, 5 improved, 36
                  stabilized, and 22 worsened following the first postoperative erect radiograph.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Subanalysis
                by Coronal Curve Magnitude on First Postoperative Erect Radiograph</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">We divided the patients into those who had a coronal curve
                  magnitude of ≤35° on the first postoperative erect radiograph (n = 93) and those who had &gt;35° (n =
                  17). Of the curves that were ≤35°, 41.9% (39) were stable (coronal curve magnitude at final follow-up
                  within 5° of that on the first postoperative erect radiograph), 48.4% (45) had increased &gt;5° during
                  the follow-up period, and 9.7% (9) had decreased &gt;5° during the follow-up period. Five of the
                  latter 9 (5.4% of the patients in the ≤35° group) had &gt;10° of additional correction, and 2 of the 5
                  had overcorrection.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Of the 17 coronal curves that were &gt;35° on first
                  postoperative erect radiograph, 88.2% (15) were stable and 11.8% (2) had worsened during the follow-up
                  period. It was notable that no curve in the &gt;35° group showed any improvement after the initial
                  correction (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>);
                  however, the majority remained stable. No significant difference was observed between the ≤35° and
                  &gt;35° groups in terms of age (p = 0.23); however, there was a significant difference with respect to
                  the Risser sign (p = 0.05).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Final Status of the Curve, Compared with First
                          Postoperative Erect Radiograph, in Patients with Coronal Curve Magnitude of ≤35° or &gt;35° on
                          First Postoperative Erect Radiograph</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 204px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">≤35° (N =
                          93)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">&gt;35° (N
                          = 17)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Overcorrected</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Improved</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (7.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stabilized</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39
                          (41.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (88.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Worsened</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45
                          (48.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (11.8%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Subanalysis
                by Risser Sign</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">We divided the patients into those with a Risser sign of
                  ≤2 (n = 101) and those with a Risser sign of &gt;2 (n = 9). Of the 101 patients with a Risser sign of
                  ≤2, 49.5% (50) had a stable curve and 41.6% (42) showed curve progression. All 9 patients who had a
                  decrease in coronal curve magnitude had a Risser sign of ≤2.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">None of the 9 patients who had a Risser sign of &gt;2
                  showed improvement in coronal curve magnitude after the initial correction, 44% (4) had a stable
                  curve, and 56% (5) demonstrated worsening (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Final Status of the Curve, Compared with First
                          Postoperative Erect Radiograph, in Patients with Risser Sign of ≤2 or &gt;2</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 204px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Risser ≤2
                          (N = 101)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Risser
                          &gt;2 (N = 9)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Overcorrected</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Improved</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (6.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stabilized</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50
                          (49.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (44.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Worsened</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (41.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (55.6%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Subanalysis
                by Status of Triradiate Cartilage</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">We were unable to assess the status of the triradiate
                  cartilage in 8 of the 110 patients due to inadequate radiographs. In the remaining 102, it was
                  visualized on either the erect or the bending radiographs; 19 were open and 83 were closed. In the
                  patients in whom it was open, 4 curves improved, 8 stabilized, and 7 worsened. In the patients with
                  closed triradiate cartilage, 5 curves improved, 41 stabilized, and 37 worsened.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">In 4 of the 69 patients who had a Risser sign of 0, the
                  triradiate cartilage could not be visualized due to shielding on the radiographs. The remaining 65
                  patients had well-visualized triradiate cartilage, which was open in 19 of them and closed in 46. Of
                  the 19 patients who had open triradiate cartilage, 18 had a coronal curve magnitude of ≤35° on the
                  first postoperative radiographs. In those 18 patients, 4 curves improved, 8 were stable, and 6
                  worsened.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Subanalysis
                by Proximal Femur Maturity Index</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">The Proximal Femur Maturity Index could be assessed in 95
                  patients and was 0 in 0 patients, 1 in 1, 2 in 7, 3 in 29, 4 in 41, 5 in 16, and 6 in 1. The results
                  of a subanalysis comparing the change in the coronal curve magnitude in the follow-up period according
                  to Proximal Femur Maturity Index can be found in <a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Proximal Femur Maturity Index Stratified by Whether
                          Curve Improved, Stabilized, or Worsened</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 204px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Index</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Improved</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Stabilized</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Worsened</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">0-2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">4-6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patients
                with Curve Correction in Follow-up Period</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Of the patients who had improvement of &gt;5° in the
                  coronal curve magnitude, 7 had a Risser sign of 0, 1 had a Risser sign of 1, and 1 had a Risser sign
                  of 2. The mean coronal curve magnitude in these patients was 48.1° (SD, 4.6°) preoperatively, 24.1°
                  (SD, 5.7°) on the first postoperative erect radiograph, and −0.11° (SD, 25.2°; range, −50° to 22°) at
                  the time of final follow-up (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Demographic and Radiographic Information for
                          Patients with Curve Correction on First Postoperative Erect Radiograph</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 362.609px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.645098);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Subject</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Age at Index Surgery <span data-id="emphasis_23"
                            class="annotation emphasis" style="position: unset;">(yr)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Risser Sign</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Coronal Curve Magnitude <span data-id="emphasis_24"
                            class="annotation emphasis" style="position: unset;">(°)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Follow-up <span data-id="emphasis_25" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">First
                          Postop. Erect Radiographs</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Final
                          Follow-up</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Change
                          After First Postop. Erect Radiograph</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−25</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−31</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−51</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−50</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−80</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patients
                with Curve Progression in Follow-up Period</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Of the 47 patients who experienced &gt;5° of increase in
                  the coronal curve magnitude, 28 had a Risser sign of 0, 7 had a Risser sign of 1, 7 had a Risser sign
                  of 2, and 5 had a Risser sign of 3. The mean difference in the coronal curve magnitude angle was 11.9°
                  (SD, 6.6°; range, 6° to 37°) between the first postoperative erect and final follow-up radiographs.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Conceptually, anterior VBT is appealing because of its
                  potential to achieve correction over time and preserve range of motion<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">9</span></a>. However, with current indications and technology it appears
                  that subsequent modulation and improvement in coronal curve magnitude occurs in only a minority of
                  patients. Consistent with other literature<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_11" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_11" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a>, we found that the mean coronal curve magnitude on
                  follow-up was quite similar to that on the first postoperative erect radiograph. The more detailed
                  picture obtained in this multicenter series demonstrates that, while many (49%) of the curves remained
                  stable from the first postoperative erect to the final follow-up radiographs, a large proportion (43%)
                  worsened. Only a small proportion (8%) of the patients in the study had any subsequent improvement in
                  coronal curve magnitude after the first postoperative erect radiograph.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Mathew et al. found an association between a mean coronal
                  curve magnitude of &lt;35° on the initial postoperative upright imaging and successful outcomes in a
                  series of 26 patients treated with VBT<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">9</span></a>.
                  However, although the 110 patients in our series had a mean coronal curve magnitude of only 27.3° on
                  the first erect radiograph made after VBT, only 9 demonstrated a decrease over time, including 2 who
                  had overcorrection. When we divided the patients into those with a coronal curve magnitude of ≤35° and
                  those who had &gt;35°, we saw no subsequent improvement in the &gt;35° group (<a href=""
                    data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). Similar to other studies, we also found no meaningful
                  improvements in coronal curve magnitude in patients with a Risser sign of &gt;2 (<a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). Although the sample sizes of these groups were limited, this
                  suggests that despite the potential for growth modulation, it is not occurring on a consistent basis
                  to an extent that decreases coronal curve magnitude. It remains to be determined whether the patients
                  who had stable curves, or only mild worsening, will have sufficient stability to have a satisfactory
                  outcome. Still, it certainly raises concerns about our current understanding of the surgical
                  indications for and mechanical principles underlying this growth modulation procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Identifying the ideal scenario and surgical candidate for
                  VBT continues to be a priority among researchers. Alanay et al. reported that curve correction
                  differed between patients with a Sanders Skeletal Maturity stage of 2, who were at a greater risk for
                  overcorrection, and those with a stage of 3, 4, or 5<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">15</span></a>.
                  These findings were supported by a study by Baker et al.<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">16</span></a>,
                  who found that patients with a successful outcome—defined as a coronal curve magnitude of &lt;35°
                  without revision surgery—had an average Sanders stage of 3.7 preoperatively and 7.7 at final
                  follow-up, while the unsuccessful group had a Sanders stage of 2.5 preoperatively and 5.5 at final
                  follow-up. As VBT is a relatively new surgical option, long-term studies are needed to determine how
                  these patients will do at and beyond skeletal maturity, but the fact that only a small percentage of
                  them had the anticipated change in the vertebral body shape and improvement in curve magnitude over
                  time is cause for concern.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">This study did not evaluate each level for possible tether
                  breakage, which is one of the obvious variables impacting which patients will and will not have
                  further modulation. The best method for determining cord breakage continues to be an area of debate.
                  With the availability of many studies investigating cord breakage<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">18</span></a>, it was not the focus of the present paper. Still, it is
                  notable that even if we were to presume a 50% tether breakage rate, as has been reported in some
                  articles<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">20</span></a>, only &lt;20% of the patients who had intact tethers would
                  have had additional improvement in curve magnitude.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Our study has limitations. Using Sanders staging when we
                  assessed skeletal maturity would have provided additional nuance in our understanding of the outcomes
                  in our patients<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">21</span></a>;
                  however, Sanders staging was not consistently collected as a data point in the registry until recently
                  and was unavailable for 95.6% of our patients. Furthermore, many patients in this series had not
                  reached skeletal maturity by the time of final follow-up and their ultimate outcome is unknown. An
                  additional limitation of this study is that surgical technique, including level selection and
                  tensioning, likely varied among the surgeons and centers and we were not able to determine the
                  criteria that each used. There was also potential selection bias with enrollment into the multicenter
                  database. Patient selection bias may have also been introduced, further skewing the data, by inherent
                  differences in patients who opt for a newer and less proven surgical option in the hope of preserving
                  motion. They may be more active and potentially stress the system more than other patients with AIS.
                  Lastly, although the mean follow-up approached 4 years, the patients with the minimum 2-year follow-up
                  continue to have a substantial risk of tether breakage that may be appreciated with a longer
                  follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Nevertheless, it is illuminating that the coronal curve
                  magnitude did not improve following the first postoperative erect radiograph in 92% of patients who
                  underwent VBT. This study highlights that indications, technique, technology, and/or timing need to be
                  improved to optimize the outcomes of VBT. Further research is warranted to identify the
                  differentiating factors among patients in whom the curve worsened, improved, remained stable, or
                  overcorrected, and how these groups fare in the long term, to determine which patients are appropriate
                  candidates for VBT.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Note: Members of the Pediatric Spine Study Group include
                  John Anderson, David Bauer, Kenneth Cheung, Carter Clement, Haemish Crawford, Mari Groves, Steven
                  Hwang, Allen Kadado, Judson Karlen, Kenny Kwan, Robert Lark, Craig Louer, Stuart Mitchell, Kevin
                  Morash, Jean Ouellet, Anand Segar, Suken Shah, Otis Shirley, Joe Stone, Stuart Weinstein, Nichola
                  Wilson, David Lebel, Karl Balsara, Timothy Borden, Bruno Braga, Aaron Buckland, Rebecca Burke, Luke
                  Drake, Katie Fehnel, Rolando Figueroa Roberto, John Ghazi, Lawrence Haber, Michael Hughes, Omar Iqbal,
                  Andrew Jea, Matthew Landrum, Hai Le, Brett Lullo, Smitha Mathew, Kyle Miller, Blake Montgomery, Taimin
                  Oh, Keith Orland, Paul Rushton, Brett Shannon, Aaron Shaw, Graham Shea, Andrea Simmonds, Dominick
                  Tuason, Sara Van Nortwick, Jennifer Bauer, Eduardo Beauchamp, James Bennett, Douglas Brockmeyer, David
                  Bumpass, Yehia El-Bromboly, Mark Erickson, Tenner Guillaume, Stephanie Ihnow, Rajiv Iyer, Viral Jain,
                  Brian Kelly, Joshua Klatt, Jonathan Martin, Jessica McQuerry, Daniel Miller, Ferran Pellise, Joseph
                  Perra, Nigel Price, Michael Schmitz, Brian Snyder, Michal Szczodry, Tomoko Tanaka, Tyler Tetreault,
                  John Smith, Randy Betz, Alvin Crawford, Gokhan Demirkiran, John Emans, Charles Johnston, Lawrence
                  Karlin, Noriaki Kawakami, Richard McCarthy, Francisco Perez-Grueso, David Roye, Harry Shufflebarger,
                  Peter Sturm, George Thompson, Muharrem Yazici, William Accousti, Edward Ahn, Harry Akoto, Stephen
                  Albanese, Laura Bellaire, Craig Birch, Oheneba Boachie-Adjei, Chris Bonfield, Daniel Bouton, Felix
                  Brassard, Anthony Catanzano, Olivier Chemaly, Jason Cheung, Robert Cho, Tyler Christman, Daniel
                  Couture, Benny Dahl, Dennis Devito, Mohammad Diab, Dan Drake, Antony Field, Peter Gabos, Alejandro
                  Peiro Garcia, Adrian Gardner, Stephen George, Frank Gerow, David Gonda, Kim Hammerberg, Daniel
                  Hedequist, Jose Herrera, Josh Holt, Jason Howard, Tim Hresko, Kenneth Illingworth, Morgan Jones, Derek
                  Kelly, Michael Kelly, Paul Koljonen, Hubert Labelle, William Lavelle, Lawrence Lenke, Sean Lew,
                  Jean-Marc Mac Thiong, Stuart Mackenzie, Erin MacKintosh, Francesco Mangano, David Marks, Alejandro
                  Jose Marquez-Lara, Jeffrey Martus, Jwalant Mehta, Lionel Metz, Nancy Miller, Firoz Miyanji, Greg
                  Mundis, Susan Nelson, Peter Newton, Matthew Newton Ede, Cynthia Nguyen, Toba Niazi, Susana Nunez
                  Pereira, Stefan Parent, Javier Pizones, Selina Poon, Subaraman Ramchandran, Norman Ramirez, Luis
                  Rodriguez, Jose Miguel Sanchez Marquez, James Sanders, Christina Sayama, Jacob Schulz, Richard
                  Schwend, Timothy Skalak, Joshua Speirs, Hamdi Sukkarieh, John Thometz, Andrew Tice, Vidyadhar Upasani,
                  Raphael Vialle, Shengru Wang, Bill Warner, David Wrubel, Nan Wu, Kwadwo Yankey, Terry Jianguo Zhang,
                  Carl-Eric Aubin, Patrick Carry, Oscar Mayer, Rachel White, Behrooz Akbarnia, Richard Anderson, Lindsay
                  Andras, Laurel Blakemore, Pat Cahill, Ron El-Hawary, Jack Flynn, Michael Glotzbecker, Jaime Gomez,
                  Christina Hardesty, Grant Hogue, Noelle Larson, Ying Li, Scott Luhmann, Robert Murphy, Josh Murphy,
                  Matthew Oetgen, Josh Pahys, Amer Samdani, Jeffrey Sawyer, David Skaggs, Paul Sponseller, Michael
                  Vitale, Klane White, Burt Yaszay, Jason Anari, Jaysson Brooks, Ryan Fitzgerald, Lorena Floccari,
                  Sumeet Garg, Purnendu Gupta, Michael Heffernan, Ilkka Helenius, Megan Johnson, Hiroko Matsumoto, Amy
                  McIntosh, Brandon Ramo, Gregory Redding, Todd Ritzman, Juan Rodriguez-Olaverri, Benjamin Roye, Kevin
                  Smit, Ishaan Swarup, Walter Truong, John Vorhies, Michelle Welborn, and Scott Yang.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Lonner BS</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Ren Y</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Yaszay B</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Cahill PJ</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Shah SA</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Betz RR</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Samdani AF</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Shufflebarger HL</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Newton PO</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Evolution of Surgery for
                  Adolescent Idiopathic Scoliosis Over 20 Years: Have Outcomes Improved?</span><span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2018 Mar 15;43(6):402-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evolution%20of%20Surgery%20for%20Adolescent%20Idiopathic%20Scoliosis%20Over%2020%20Years%3A%20Have%20Outcomes%20Improved%3F&as_occt=title&as_sauthors=%20%22BS%20Lonner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Konieczny MR</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Senyurt H</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Krauspe R</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Epidemiology of adolescent
                  idiopathic scoliosis</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Child Orthop.</span> 2013 Feb;7(1):3-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiology%20of%20adolescent%20idiopathic%20scoliosis&as_occt=title&as_sauthors=%20%22MR%20Konieczny%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Mehta NN</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Talwar D</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Flynn JM</span>; Harms Study
                Group. <span data-id="strong_3" class="annotation strong" style="position: unset;">Unplanned return to
                  the operating room (UPROR) after surgery for adolescent idiopathic scoliosis</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2021
                Jul;9(4):1035-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Unplanned%20return%20to%20the%20operating%20room%20(UPROR)%20after%20surgery%20for%20adolescent%20idiopathic%20scoliosis&as_occt=title&as_sauthors=%20%22NN%20Mehta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Kwan MK</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Loh KW</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Chung WH</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Chiu CK</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Hasan MS</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Chan CYW</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Perioperative outcome and
                  complications following single-staged Posterior Spinal Fusion (PSF) using pedicle screw
                  instrumentation in Adolescent Idiopathic Scoliosis (AIS): a review of 1057 cases from a single
                  centre</span>. <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">BMC
                  Musculoskelet Disord.</span> 2021 May 4;22(1):413.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perioperative%20outcome%20and%20complications%20following%20single-staged%20Posterior%20Spinal%20Fusion%20(PSF)%20using%20pedicle%20screw%20instrumentation%20in%20Adolescent%20Idiopathic%20Scoliosis%20(AIS)%3A%20a%20review%20of%201057%20cases%20from%20a%20single%20centre&as_occt=title&as_sauthors=%20%22MK%20Kwan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Lander ST</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Thirukumaran C</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Saleh A</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Noble KL</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Menga EN</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Mesfin A</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Rubery PT</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Sanders JO</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Long-Term Health-Related Quality
                  of Life After Harrington Instrumentation and Fusion for Adolescent Idiopathic Scoliosis: A Minimum
                  40-Year Follow-up</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2022 Jun 1;104(11):995-1003.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=3282046" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Rushton PRP</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Nasto L</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Parent S</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Turgeon I</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Aldebeyan S</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Miyanji F</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Anterior Vertebral Body
                  Tethering for Treatment of Idiopathic Scoliosis in the Skeletally Immature: Results of 112
                  Cases</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Spine
                  (Phila Pa 1976).</span> 2021 Nov 1;46(21):1461-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20Vertebral%20Body%20Tethering%20for%20Treatment%20of%20Idiopathic%20Scoliosis%20in%20the%20Skeletally%20Immature%3A%20Results%20of%20112%20Cases&as_occt=title&as_sauthors=%20%22PRP%20Rushton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> McDonald TC</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Shah SA</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Hargiss JB</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Varghese J</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Boeyer ME</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Pompliano M</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Neal K</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Lonner BS</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Larson AN</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Yaszay B</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Hoernschemeyer DG</span>; Harms
                Nonfusion Study Group. <span data-id="strong_7" class="annotation strong" style="position: unset;">When
                  successful, anterior vertebral body tethering (VBT) induces differential segmental growth of
                  vertebrae: an in vivo study of 51 patients and 764 vertebrae</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2022
                Jul;10(4):791-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=When%20successful%2C%20anterior%20vertebral%20body%20tethering%20(VBT)%20induces%20differential%20segmental%20growth%20of%20vertebrae%3A%20an%20in%20vivo%20study%20of%2051%20patients%20and%20764%20vertebrae&as_occt=title&as_sauthors=%20%22TC%20McDonald%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Abdullah A</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Parent S</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Miyanji F</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Smit K</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Murphy J</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Skaggs D</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Gupta P</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Vitale M</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Ouellet J</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Saran N</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Cho RH</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Group PSS</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> El-Hawary R</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Risk of early complication
                  following anterior vertebral body tethering for idiopathic scoliosis</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2021
                Sep;9(5):1419-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20of%20early%20complication%20following%20anterior%20vertebral%20body%20tethering%20for%20idiopathic%20scoliosis&as_occt=title&as_sauthors=%20%22A%20Abdullah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Mathew SE</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Hargiss JB</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Milbrandt TA</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Stans AA</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Shaughnessy WJ</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Larson AN</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Vertebral body tethering
                  compared to posterior spinal fusion for skeletally immature adolescent idiopathic scoliosis patients:
                  preliminary results from a matched case-control study</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2022
                Sep;10(5):1123-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebral%20body%20tethering%20compared%20to%20posterior%20spinal%20fusion%20for%20skeletally%20immature%20adolescent%20idiopathic%20scoliosis%20patients%3A%20preliminary%20results%20from%20a%20matched%20case-control%20study&as_occt=title&as_sauthors=%20%22SE%20Mathew%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Bartley CE</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Bastrom TP</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Kluck DG</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Saito W</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Yaszay B</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Anterior Spinal Growth
                  Modulation in Skeletally Immature Patients with Idiopathic Scoliosis: A Comparison with Posterior
                  Spinal Fusion at 2 to 5 Years Postoperatively</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2020 May
                6;102(9):769-77.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2342010"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Kluck DG</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Saito W</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Yaszay B</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Bartley CE</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Bastrom TP</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Anterior Spinal Growth
                  Tethering for Skeletally Immature Patients with Scoliosis: A Retrospective Look Two to Four Years
                  Postoperatively</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2018 Oct 3;100(19):1691-7.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1814471" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Cheung PWH</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Canavese F</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Chan CYW</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Wong JSH</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Shigematsu H</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Luk KDK</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Cheung JPY</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The Utility of a Novel Proximal
                  Femur Maturity Index for Staging Skeletal Growth in Patients with Idiopathic Scoliosis</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2022 Apr 6;104(7):630-40.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=3164248" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Morrissy RT</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Goldsmith GS</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Hall EC</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Kehl D</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Cowie GH</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Measurement of the Cobb angle
                  on radiographs of patients who have scoliosis. Evaluation of intrinsic error</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 1990 Mar;72(3):320-7.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=536488" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Raitio A</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Syvänen J</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Helenius I</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Vertebral Body Tethering:
                  Indications, Surgical Technique, and a Systematic Review of Published Results</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Clin Med.</span> 2022 May
                4;11(9):2576.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebral%20Body%20Tethering%3A%20Indications%2C%20Surgical%20Technique%2C%20and%20a%20Systematic%20Review%20of%20Published%20Results&as_occt=title&as_sauthors=%20%22A%20Raitio%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Alanay A</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Yucekul A</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Abul K</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Ergene G</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Senay S</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Ay B</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Cebeci BO</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Yalinay Dikmen P</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Zulemyan T</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Yavuz Y</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Yilgor C</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Thoracoscopic Vertebral Body
                  Tethering for Adolescent Idiopathic Scoliosis: Follow-up Curve Behavior According to Sanders Skeletal
                  Maturity Staging</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2020 Nov 15;45(22):E1483-92.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Thoracoscopic%20Vertebral%20Body%20Tethering%20for%20Adolescent%20Idiopathic%20Scoliosis%3A%20Follow-up%20Curve%20Behavior%20According%20to%20Sanders%20Skeletal%20Maturity%20Staging&as_occt=title&as_sauthors=%20%22A%20Alanay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Baker CE</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Kiebzak GM</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Neal KM</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Anterior vertebral body
                  tethering shows mixed results at 2-year follow-up</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2021
                Mar;9(2):481-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20vertebral%20body%20tethering%20shows%20mixed%20results%20at%202-year%20follow-up&as_occt=title&as_sauthors=%20%22CE%20Baker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Tetreault TA</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Phan TN</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Wren TAL</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Heffernan MJ</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Welborn MC</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Smith JT</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> El-Hawary R</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Cheung K</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Illingworth KD</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Skaggs DL</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Andras LM</span>; Pediatric
                Spine Study Group. <span data-id="strong_17" class="annotation strong" style="position: unset;">The Fate
                  of The Broken Tether: How Do Curves Treated with Vertebral Body Tethering Behave After Tether
                  Breakage?</span><span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Spine
                  (Phila Pa 1976).</span> 2024 Jun 12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Fate%20of%20The%20Broken%20Tether%3A%20How%20Do%20Curves%20Treated%20with%20Vertebral%20Body%20Tethering%20Behave%20After%20Tether%20Breakage%3F&as_occt=title&as_sauthors=%20%22TA%20Tetreault%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Yang MJ</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Samdani AF</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Pahys JM</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Quinonez A</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> McGarry M</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Grewal H</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Hwang SW</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">What Happens After a Vertebral
                  Body Tether Break? Incidence, Location, and Progression With Five-year Follow-up</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2023 Jun 1;48(11):742-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20Happens%20After%20a%20Vertebral%20Body%20Tether%20Break%3F%20Incidence%2C%20Location%2C%20and%20Progression%20With%20Five-year%20Follow-up&as_occt=title&as_sauthors=%20%22MJ%20Yang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Cahill PJ</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Miyanji F</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Lullo BR</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Samdani AF</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Lonner BS</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Pahys JM</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Hwang SW</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Haber LL</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Alanay A</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Shah SA</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Parent S</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Blakemore LC</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Hoernschemeyer DG</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Neal KM</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Newton PO</span>; Harms Study
                Group. <span data-id="strong_19" class="annotation strong" style="position: unset;">Incidence of Tether
                  Breakage in Anterior Vertebral Body Tethering</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 2024 Apr
                1;44(4):e323-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20of%20Tether%20Breakage%20in%20Anterior%20Vertebral%20Body%20Tethering&as_occt=title&as_sauthors=%20%22PJ%20Cahill%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Hoernschemeyer DG</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Boeyer ME</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Robertson ME</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Loftis CM</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Worley JR</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Tweedy NM</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Gupta SU</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Duren DL</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Holzhauser CM</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Ramachandran VM</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Anterior Vertebral Body
                  Tethering for Adolescent Scoliosis with Growth Remaining: A Retrospective Review of 2 to 5-Year
                  Postoperative Results</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2020 Jul 1;102(13):1169-76.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2406104" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Neal KM</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Shirley ED</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Kiebzak GM</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Maturity Indicators and
                  Adolescent Idiopathic Scoliosis: Evaluation of the Sanders Maturity Scale</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2018 Apr 1;43(7):E406-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Maturity%20Indicators%20and%20Adolescent%20Idiopathic%20Scoliosis%3A%20Evaluation%20of%20the%20Sanders%20Maturity%20Scale&as_occt=title&as_sauthors=%20%22KM%20Neal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Daf870c67-cc5c-49b9-96a7-45c0d7faf4cb%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): 257</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00930</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 23, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_22"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Children’s
                            Hospital Los Angeles, Los Angeles, California</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;af870c67-cc5c-49b9-96a7-45c0d7faf4cb&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=af870c67-cc5c-49b9-96a7-45c0d7faf4cb&type=pdf&name=JBJS.23.00930.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=af870c67-cc5c-49b9-96a7-45c0d7faf4cb&type=pdf&name=JBJS.23.00930.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_27"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_28" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I318"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I318</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;af870c67-cc5c-49b9-96a7-45c0d7faf4cb&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=af870c67-cc5c-49b9-96a7-45c0d7faf4cb&type=zip&name=JBJS.23.00930.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tiffany N. Phan, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5125-6194" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5125-6194</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tishya A.L. Wren, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1032-1767" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1032-1767</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael J. Heffernan, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0632-3367" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0632-3367</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tyler A. Tetreault, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3844-9984" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3844-9984</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Stefan Parent, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4854-2816" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4854-2816</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michelle C. Welborn, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9422-6849" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9422-6849</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">David L. Skaggs, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6137-5576" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6137-5576</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kenneth D. Illingworth, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lindsay M. Andras, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:landras@chla.usc.edu" class="" style="position: unset;">landras@chla.usc.edu</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3390-7878" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3390-7878</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">the Pediatric Spine Study Group<span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Jackie and Gene Autry Orthopaedic Center, Children’s Hospital Los Angeles, Los
                Angeles, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedics, CHU Sainte-Justine, Montreal, Quebec, Canada</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Surgery, University of Montreal, Montreal, Quebec, Canada</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Shriners Hospital Portland, Portland,
                Oregon</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Department of Orthopaedics, Cedars-Sinai Medical Center, Los Angeles,
                California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">A list of the Pediatric Spine Study Group members is
                        included as a note at the end of the article.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 26094.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
