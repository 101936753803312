import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* ----------------- Third Party Imports --------------- */
import { List, Text } from "react-native-paper";

/* ----------------- Expo Imports --------------- */
import { Image } from "@memorang/ui";
import { Pressable } from "react-native";

/* ----------------- Local Imports --------------- */
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";

/* ----------------- Type Definitions --------------- */
export interface SelectableItemType {
	label: string;
	value?: string;
	icon?: string;
	items?: SelectableItemType[];
}

interface Props extends SelectableItemType {
	onSelect: (id: string) => void;
	selected?: boolean;
	disabled?: boolean;
	variant?: "card" | "listItem";
	description?: string;
	count?: number;
}

/* ----------------- Helper Components --------------- */
const ItemImage = ({ image, size }: { image?: string; size: number }) =>
	image?.includes("http") ? (
		<Image
			source={{ uri: image }}
			style={{ width: size, height: size }}
			contentFit="contain"
		/>
	) : (
		<Text variant="headlineLarge" style={{ textAlign: "center" }}>
			{image}
		</Text>
	);

const SelectionIcon = ({
	selected,
	theme,
}: {
	selected: boolean;
	theme: ReturnType<typeof useAppTheme>;
}) => (
	<Icon
		name={selected ? "check-circle" : "radiobox-blank"}
		size={24}
		color={selected ? theme.colors.primary : theme.colors.outline}
	/>
);

/* ----------------- Variant Components --------------- */
const CardView = ({
	label,
	icon,
	selected,
}: {
	label: string;
	icon?: string;
	selected: boolean;
}) => {
	const theme = useAppTheme();
	const borderWidth = 3;
	const basePadding = 24;
	const bottomPadding = 16;
	const borderRadius = 24;

	return (
		<Box
			style={{
				alignItems: "center",
				gap: 10,
				position: "relative",
				justifyContent: "center",
				flexShrink: 0,
				paddingTop: basePadding,
				paddingRight: basePadding,
				paddingBottom: bottomPadding,
				paddingLeft: basePadding,
				borderWidth,
				borderColor: selected ? theme.colors.primary : "transparent",
				borderRadius,
				backgroundColor: selected
					? theme.colors.surfaceVariant
					: theme.colors.elevation.level2,
			}}
			flexDirection="column"
		>
			<ItemImage image={icon} size={100} />
			<Text
				variant="titleSmall"
				style={{
					textAlign: "center",
				}}
			>
				{label}
			</Text>
			{selected && (
				<Box
					style={{
						position: "absolute",
						top: 8,
						right: 8,
					}}
					alignItems="center"
					justifyContent="center"
				>
					<SelectionIcon selected={selected} theme={theme} />
				</Box>
			)}
		</Box>
	);
};

/* ----------------- Main Component --------------- */
const SelectableItem = ({
	label,
	value,
	icon,
	onSelect,
	selected = false,
	disabled = false,
	variant = "card",
	description,
	count,
}: Props) => {
	const theme = useAppTheme();

	return (
		<Pressable onPress={() => onSelect(value!)} disabled={disabled}>
			{variant === "listItem" ? (
				<List.Item
					title={label}
					descriptionNumberOfLines={1}
					onPress={() => onSelect(value!)}
					description={description}
					style={{
						paddingHorizontal: 16,
					}}
					left={() => <ItemImage image={icon} size={44} />}
					right={(p) => (
						<List.Icon
							{...p}
							icon={() => (
								<Box
									paddingLeft={8}
									flexDirection="row"
									alignItems="center"
									gap={4}
								>
									{count && <Text variant="labelSmall">{count}</Text>}
									<Icon
										name="chevron-right"
										size={24}
										color={theme.colors.outline}
									/>
								</Box>
							)}
						/>
					)}
				/>
			) : (
				<Box
					style={{
						opacity: disabled ? 0.5 : 1,
						overflow: "hidden",
					}}
				>
					<CardView label={label} icon={icon} selected={selected} />
				</Box>
			)}
		</Pressable>
	);
};

export default SelectableItem;
