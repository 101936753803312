import type { Article } from "../../../../../types";

export const Article547: Article = {
	id: 547,
	rsuiteId: "ef557ce0-258c-48d0-a2f8-7e29fd439985",
	type: "scientific article",
	title:
		"Generalized Joint Hypermobility Is Associated with Type-A Hip Dysplasia in Patients Undergoing Periacetabular Osteotomy",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ef557ce0-258c-48d0-a2f8-7e29fd439985&type=jpeg&name=JBJS.23.01030f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Generalized Joint Hypermobility Is Associated with Type-A
                Hip Dysplasia in Patients Undergoing Periacetabular Osteotomy</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Hangyu Ping,
                      MM</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Xiangpeng
                      Kong, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Background:</span> Joint hypermobility may be associated with
                        developmental dysplasia of the hip (DDH), but no definite conclusion has been reached. On the
                        basis of long-term clinical observations, we hypothesized that joint hypermobility was
                        associated with the occurrence, imaging findings, and clinical symptoms of DDH.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_43" class="annotation strong"
                          style="position: unset;">Methods:</span> We conducted a case-control study that included 175
                        Chinese Han patients between 13 and 45 years of age with Hartofilakidis type-A hip dysplasia.
                        All of these patients underwent periacetabular osteotomy (PAO) between November 2021 and
                        February 2023. An additional 76 individuals of comparable age and sex but without hip dysplasia
                        were selected from the general population to serve as healthy controls. The Beighton 9-point
                        scoring system was used to evaluate joint hypermobility, and a score of ≥4 was defined as
                        generalized joint hypermobility. Standing anteroposterior pelvic radiographs were reviewed. For
                        patients with DDH, the lateral center-edge angle, Tönnis angle, Sharp angle, lateralization of
                        the femoral head, and patient-reported outcomes (iHOT-12, HHS, and WOMAC) were also collected to
                        determine the radiographic severity or clinical symptoms of DDH.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_44" class="annotation strong"
                          style="position: unset;">Results:</span> Patients with DDH had an elevated prevalence of
                        generalized joint hypermobility compared with that in the healthy population (27% versus 12%; p
                        = 0.009). Among patients with DDH, those with concomitant generalized joint hypermobility had
                        lower lateral center-edge angles (3.55° versus 9.36°; p = 0.012), greater lateralization of the
                        femoral head (13.78 versus 12.17 mm; p = 0.020), greater standardized lateralization of the
                        femoral head (0.64 versus 0.54; p = 0.009), and lower iHOT-12 scores (35.22 versus 40.96; p =
                        0.009) than did those without concomitant generalized joint hypermobility. Further multivariable
                        linear regression analysis revealed that higher Beighton scores and younger age were predictive
                        of more severe hip dysplasia. However, the Beighton score was not found to be independently
                        associated with patient-reported outcomes according to multivariable linear regression analysis.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_45" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The prevalence of generalized joint hypermobility
                        was greater in patients with DDH than in healthy controls. A higher degree of joint
                        hypermobility was also correlated with more severe hip dysplasia. These results suggest that
                        joint laxity, in addition to bone or cartilage factors, is an important factor related to DDH.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_46" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Generalized joint hypermobility is a relatively common
                  condition, with an overall prevalence of 5% to 26% in the general population<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">4</span></a>, and
                  presents with excessive laxity of soft tissues. Excessive soft-tissue laxity reduces the restraining
                  force of the joint, increases the prevalence of joint instability<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">6</span></a>, and
                  increases the risk of several musculoskeletal disorders. Several studies have shown generalized joint
                  hypermobility to be associated with traumatic shoulder dislocation<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">1</span></a>, anterior cruciate ligament (ACL) injury<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">7</span></a>, hand osteoarthritis<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">8</span></a>, idiopathic scoliosis<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">9</span></a>, and fibromyalgia syndrome<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">10</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Two previous studies have shown a greater prevalence of
                  joint laxity among children with developmental dysplasia of the hip (DDH) and have suggested that
                  joint laxity is a risk factor for DDH<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">12</span></a>. Santore et al.<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">13</span></a> also
                  recently reported a significantly greater prevalence of generalized joint hypermobility (Beighton
                  score ≥4) in patients with DDH than in patients with other hip disorders (39% versus 14%). However, to
                  our knowledge, no study has investigated whether generalized joint hypermobility is associated with
                  radiographic findings or clinical symptoms in patients with DDH.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Therefore, we hypothesized that joint laxity, which can
                  induce instability, is an important causative factor related to DDH. The purpose of the present study
                  was to answer 3 questions: (1) Is there a difference in the prevalence of joint hypermobility between
                  patients with DDH and healthy controls? (2) Is joint hypermobility associated with the degree of
                  radiographic severity in patients with DDH? (3) Is joint hypermobility associated with more pronounced
                  clinical symptoms in patients with DDH?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">According to a standardized evaluation, we conducted a
                  case-control study to collect data from patients with DDH who were scheduled for periacetabular
                  osteotomy (PAO) at our medical center between November 2021 and February 2023. This study was approved
                  by the institutional review board (No. 2023KY005-KS001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">A total of 175 patients were included in this study. The
                  inclusion criteria were Hartofilakidis type-A hip dysplasia (see Appendix Figure 1 for an explanation
                  of Hartofilakidis types A, B, and C), age between 13 and 45 years, and treatment with PAO at our
                  medical center. The exclusion criteria were (1) a history of other hip diseases, trauma, surgery
                  affecting the hip, or use of a hip abduction device (Pavlik harness, abduction orthosis, or spica
                  casting), (2) Tönnis grade-II or III osteoarthritis, (3) neuromuscular disease affecting motor
                  function, (4) a history of systemic disease affecting soft tissue (Ehlers-Danlos syndrome, Marfan
                  syndrome, or osteogenesis imperfecta), and (5) inflammatory arthropathy. The detailed selection
                  procedure is shown in <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1</a>.
                  Additionally, a total of 76 healthy controls were included; these controls mainly consisted of medical
                  students or medical staff members who were undergoing medical examinations at our hospital, were
                  comparable in terms of age and sex ratio (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>), and
                  had no hip symptoms or history of hip, knee, spine, or neuromuscular disease. All of the controls
                  underwent examination with anteroposterior pelvic radiography to exclude any asymptomatic hip disease.
                  Informed consent was obtained from all of the subjects in both groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01030f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ef557ce0-258c-48d0-a2f8-7e29fd439985&type=jpeg&name=JBJS.23.01030f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;">Study selection process and reasons for exclusion.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Basic Information on Patients with DDH and
                          Controls<span data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 531px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Patients
                          with DDH (N = 175)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Controls
                          (N = 76)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.06 ±
                          8.28</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.30 ±
                          5.10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.376</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.57 ±
                          8.38</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.02 ±
                          5.41</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.576</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.60 ±
                          7.44</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.82 ±
                          2.77</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.611</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex <span
                            data-id="emphasis_44" class="annotation emphasis" style="position: unset;">(no. [%] of
                            patients or controls)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">175
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.330</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">145
                          (83%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59
                          (78%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (22%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="emphasis_45" class="annotation emphasis" style="position: unset;">(kg/m<span
                              data-id="superscript_10" class="annotation superscript"
                              style="position: unset;">2</span>)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.00 ±
                          3.05</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.46 ±
                          3.16</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.201</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Operative side <span
                            data-id="emphasis_46" class="annotation emphasis" style="position: unset;">(no. [%] of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">175
                          (100%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72
                          (41%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">103
                          (59%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">BMI = body mass index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Basic information about the patients with DDH and healthy
                  controls, such as age, sex, and body mass index (BMI), was collected. For patients with DDH,
                  information on the involved side, family history, breech birth, swaddling experience, dance
                  experience, and acrobatic training experience was also collected as possible confounding factors.
                  Patient-reported outcomes (PROs), including the International Hip Outcome Tool (iHOT-12) score<a
                    href="" data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a>, Harris hip score (HHS)<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">15</span></a>, and Western Ontario and McMaster Universities
                  Osteoarthritis Index (WOMAC)<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">16</span></a>,
                  were also collected preoperatively to evaluate the patients’ clinical symptoms. The data were
                  collected by 2 trained researchers and another independent observer.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Assessment
                of Joint Hypermobility</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">We selected a commonly used method, the Beighton 9-point
                  scoring system, to evaluate the degree of joint hypermobility with good-to-excellent reproducibility<a
                    href="" data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">2</span></a>. Based on the results of physical examinations, the Beighton
                  score was used to assess the mobility of multiple joints throughout the body (thumb, elbow, knee,
                  spine, and fifth metacarpophalangeal joint) for both groups. Specifically, positive criteria were
                  defined as &gt;90° of passive hyperextension of the fifth metacarpophalangeal joint, the ability of
                  the thumb to passively touch the lateral aspect of the forearm of the ipsilateral limb, &gt;10° of
                  elbow hyperextension, &gt;10° of knee hyperextension, and the ability of the palm to make contact with
                  the ground when the legs were straight. All passive motions involved the maximum position that did not
                  cause pain. A score of 1 point for each side meeting each of the positive criteria was recorded, up to
                  a maximum score of 9 points. We selected a commonly used threshold of a Beighton score of ≥4 as the
                  criterion for identifying generalized joint hypermobility in our population<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">18</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">To ensure objective and reliable data collection and to
                  reduce measurement bias, 2 orthopaedic residents who had not previously participated in this study
                  were specifically trained, and the purpose of the study was not disclosed in advance. We initially
                  recruited 15 healthy volunteers as a training cohort, with the aim of minimizing interobserver
                  variation and subjective interference. Any discrepancies during the training were resolved with the
                  assistance of another senior orthopaedic surgeon. Following the training process, an additional 10
                  patients with DDH and 5 healthy volunteers were recruited to evaluate interobserver reproducibility.
                  The Cohen kappa statistic and the intraclass correlation coefficient (ICC) were used to evaluate
                  interobserver reproducibility. The diagnosis of generalized joint hypermobility demonstrated a 93.3%
                  consistency rate, with a kappa value of 0.857. Kappa values for both thumbs, both fifth fingers, both
                  elbows, the left knee, and anterior spinal flexion ranged from 0.6 to 1.0. The ICC for the total
                  Beighton score was 0.966 (95% confidence interval [CI], 0.901 to 0.988). These results indicated
                  good-to-excellent interobserver reproducibility after the training was complete. Detailed data are
                  provided in Appendix Table 1.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Measurements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">All individuals were evaluated with standardized standing
                  anteroposterior pelvic radiographic examinations preoperatively, which showed bilateral symmetry of
                  the obturator foramen and the sacrococcygeal joint at 1/2 the height of the hip. For each patient with
                  DDH, we attached a solid reference ball (3 cm in diameter) to the thigh at the same level as the
                  coronal plane of the hip. Imaging data were measured with use of a specialized medical imaging system
                  (Mimics, version 25.0; Materialise), with results accurate to 0.1° or 0.1 mm. Privacy was strictly
                  protected.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Radiographic parameters, including the lateral center-edge
                  angle, Tönnis angle, Sharp angle, and lateralization of the femoral head, were measured (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 2-A</a> through <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">2-D</a>). There are
                  2 methods for measuring lateralization of the femoral head: (1) determining the shortest distance (mm)
                  from the ilioischial line to the medial aspect of the femoral head<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">22</span></a> and (2) finding the shortest distance from the medial edge
                  of the femoral head to the lateral edge of the teardrop<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">24</span></a>. The radiographic findings of the 2 measures are similar,
                  and the first method is more commonly used. We chose the first method to measure lateralization of the
                  femoral head (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-D</a>).
                  Furthermore, we described standardized lateralization of the femoral head as a new variable by
                  calculating the ratio of lateralization of the femoral head (mm) to the radius of the femoral head
                  (mm), in order to reduce the influence of individual factors such as sex or body size that affect the
                  absolute value of lateralization of the femoral head.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01030f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ef557ce0-258c-48d0-a2f8-7e29fd439985&type=jpeg&name=JBJS.23.01030f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_49"
                            class="annotation strong" style="position: unset;">Figs. 2-A through 2-D</span> Radiographic
                          parameters on standing anteroposterior pelvic radiographs. <span data-id="strong_50"
                            class="annotation strong" style="position: unset;">Fig. 2-A</span> The lateral center-edge
                          angle (LCEA) is the angle between the vertical reference line (a) and the line connecting the
                          center of the femoral head to the lateral edge of the acetabular sourcil (b). <span
                            data-id="strong_51" class="annotation strong" style="position: unset;">Fig. 2-B</span> The
                          Tönnis angle (TA) is the angle between the horizontal reference line (a) and the line between
                          the lateral and medial portions of the weight-bearing acetabular sourcil (b). <span
                            data-id="strong_52" class="annotation strong" style="position: unset;">Fig. 2-C</span> The
                          Sharp angle (SA) is the angle between the line from the inferior edge of the teardrop to the
                          lateral sourcil (b) and the horizontal reference line (a). <span data-id="strong_53"
                            class="annotation strong" style="position: unset;">Fig. 2-D</span> Lateralization of the
                          femoral head (LFH) was defined as the shortest distance (mm) from the ilioischial line (b) to
                          the medial aspect of the femoral head (c). The standardized LFH was calculated by dividing the
                          LFH (mm) by the femoral head radius (mm, line “d”).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Two researchers with no previous knowledge regarding the
                  purpose of the study completed all of the data measurements. All disagreements were resolved through
                  consultation with another senior orthopaedic surgeon. Then, 30 patients were randomly selected for the
                  calculation of the ICC; the results indicated good-to-excellent interobserver reproducibility (see
                  Appendix Table 1).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">SPSS (version 22.0; IBM) was used for statistical
                  analysis. We calculated the required sample size; according to the proportions of subjects with
                  generalized joint hypermobility reported in previous studies<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_18" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">25</span></a>, for a power of 0.9 (β = 0.1) and a selection ratio of 1:1
                  to 3:1, the total required sample size was 124 to 192 individuals<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">26</span></a>. We used the Cohen kappa statistic as in a previous study<a
                    href="" data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">27</span></a>, and ICC, a 2-way random-effects model for absolute
                  agreement (model 2.1)<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_21" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">28</span></a>, to evaluate interobserver reproducibility. Variables that
                  conformed to a normal distribution are presented as the mean and standard deviation (SD). Independent
                  t tests or Mann-Whitney U tests were used for continuous independent data. The chi-square test or
                  Fisher exact test was used for count data. To control for confounding factors, further multivariable
                  linear regression analysis was used to assess patients with DDH. The level of significance was set at
                  p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">A total of 175 patients with Hartofilakidis type-A hip
                  dysplasia who were scheduled for PAO and 76 healthy controls were included in the study; the average
                  ages were 28.06 ± 8.28 and 27.30 ± 5.10 years, respectively. No significant differences were found
                  between the 2 groups in terms of basic patient information (age, sex, or BMI) (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). Patients with DDH had a greater prevalence of generalized
                  joint hypermobility than did the healthy population (27% versus 12%; p = 0.009) (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). The subgroup analysis on the basis of sex revealed that,
                  compared with males in the DDH group, females with DDH had a greater prevalence of hypermobility (30%
                  versus 10%; p = 0.022).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Prevalence of Generalized Joint Hypermobility in
                          Individuals with DDH and Controls</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 357px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Patients
                          with DDH (N = 175)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Controls
                          (N = 76)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Generalized joint
                          hypermobility<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">*</span><span
                            data-id="emphasis_47" class="annotation emphasis" style="position: unset;">(no. [%] of
                            patients or controls)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> All</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (27%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (12%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.009<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (30%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (14%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.013<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_9"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.000</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  P value</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.022<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.662<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Beighton score ≥4.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Patients vs. controls.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Males vs females.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">For patients with DDH, we further analyzed whether DDH
                  combined with generalized joint hypermobility was associated with more severe radiographic findings or
                  more pronounced clinical symptoms. Independent t tests (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>)
                  revealed that, compared with the patients with DDH without generalized joint hypermobility, the
                  patients with both DDH and generalized joint hypermobility (Beighton score, ≥4) had lower lateral
                  center-edge angles (3.55° versus 9.36°; p = 0.012), greater lateralization of the femoral head (13.78
                  versus 12.17 mm; p = 0.020), greater standardized lateralization of the femoral head (0.64 versus 0.54
                  mm; p = 0.009), and lower iHOT-12 scores (35.22 versus 40.96; p = 0.009), while the Tönnis angle,
                  Sharp angle, HHS, and WOMAC were not associated with generalized joint hypermobility (p &gt; 0.05).
                  However, the demographic data were not completely comparable between the 2 groups (see Appendix Table
                  2). Therefore, we constructed a multiple linear regression model to control for confounding factors in
                  both groups and validate our results (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>). A
                  higher Beighton score and younger age were independently associated with more severe dysplasia on
                  radiographic analysis (lower lateral center-edge angle, greater lateralization of the femoral head,
                  and greater standardized lateralization of the femoral head) (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).
                  However, the Beighton score was not found to be independently associated with PROs according to the
                  iHOT-12, HHS, or WOMAC (see Appendix Table 3).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Radiographic Parameters and Hip Scores in Patients
                          with DDH with or without Generalized Joint Hypermobility</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 362px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">With
                          GJH<span data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Without
                          GJH<span data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">iHOT-12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.22 ±
                          11.74</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40.96 ±
                          14.77</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.009<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">HHS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82.47 ±
                          10.39</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84.19 ±
                          12.44</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.399</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">WOMAC</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.77 ±
                          10.27</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.96 ±
                          10.65</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.655</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LCEA <span
                            data-id="emphasis_48" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.55 ±
                          14.49</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.36 ±
                          8.62</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.012<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Tönnis angle <span
                            data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.30 ±
                          9.46</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.79 ±
                          7.55</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.267</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sharp angle <span
                            data-id="emphasis_50" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.61 ±
                          4.48</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.50 ±
                          4.26</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.882</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LFH <span
                            data-id="emphasis_51" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.78 ±
                          5.01</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.17 ±
                          3.58</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.020<span
                            data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Standardized LFH</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.64 ±
                          0.24</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.54 ±
                          0.15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.009<span
                            data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">iHOT-12 = International Hip Outcome Tool, HHS = Harris hip score, WOMAC
                        = Western Ontario and McMaster Universities Osteoarthritis Index, LCEA = lateral center-edge
                        angle, LFH = lateralization of femoral head.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">GJH = generalized joint hypermobility. The values are given as the mean
                        and the standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Multivariable Linear Regression Analysis of
                          Radiographic Parameters and Associated Factors in Patients with Hip Dysplasia<span
                            data-id="table_footnote_reference_31" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 3220px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Dependent
                          Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Retained
                          Variables</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Regression
                          Coefficient (95% CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="9" style="position: unset;"><span class="text" style="position: unset;">LCEA</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Age in yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.47 (0.27
                          to 0.67)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_20"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.24
                          (−3.18 to 5.66)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.580</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI in kg/m<span
                            data-id="superscript_22" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.35
                          (−0.90 to 0.20)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.207</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Family history</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.31
                          (−5.71 to 5.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.911</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">More severe on right
                          side</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.91 (0.93
                          to 6.89)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.011<span
                            data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Breech birth</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.16
                          (−4.90 to 9.22)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.547</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Swaddling
                          experience</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.24
                          (−3.29 to 5.78)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.589</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dance or acrobatic
                          experience</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.28
                          (−5.99 to 10.54)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.588</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Beighton score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.97
                          (−1.73 to −0.21)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.012<span
                            data-id="table_footnote_reference_22" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="9" style="position: unset;"><span class="text" style="position: unset;">LFH</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Age in yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.15
                          (−0.22 to −0.07)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_23"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.80 (2.18
                          to 5.42)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_24"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI in kg/m<span
                            data-id="superscript_23" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.10
                          (−0.10 to 0.30)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.328</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Family history</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.51
                          (−2.49 to 1.47)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.614</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">More severe on right
                          side</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.06
                          (−0.03 to 2.15)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.057</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Breech birth</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.06
                          (−2.65 to 2.52)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.961</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Swaddling
                          experience</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.88
                          (−2.54 to 0.79)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.300</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dance or acrobatic
                          experience</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.83
                          (−4.86 to 1.20)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.234</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Beighton score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.34 (0.06
                          to 0.62)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.017<span
                            data-id="table_footnote_reference_25" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="9" style="position: unset;"><span class="text" style="position: unset;">Standardized
                          LFH</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Age in yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.008
                          (−0.011 to −0.004)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_26"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.084
                          (0.008 to 0.160)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.030<span
                            data-id="table_footnote_reference_27" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI in kg/m<span
                            data-id="superscript_24" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.002
                          (−0.007 to 0.012)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.603</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Family history</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.005
                          (−0.10 to 0.088)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.922</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">More severe on right
                          side</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.041
                          (−0.010 to 0.092)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.116</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Breech birth</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.036
                          (−0.17 to 0.085)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.557</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Swaddling
                          experience</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.03
                          (−0.106 to 0.049)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.472</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dance or acrobatic
                          experience</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.065
                          (−0.21 to 0.08)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.367</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Beighton score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.017
                          (0.004 to 0.030)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.011<span
                            data-id="table_footnote_reference_28" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="9" style="position: unset;"><span class="text" style="position: unset;">Tönnis
                          angle</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Age in yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.18
                          (−0.34 to −0.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.039<span
                            data-id="table_footnote_reference_29" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.86
                          (−4.53 to 2.80)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.643</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI in kg/m<span
                            data-id="superscript_25" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.452
                          (−0.003 to 0.906)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.051</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Family history</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.038
                          (−4.45 to 4.53)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.987</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">More severe on right
                          side</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.20
                          (−2.28 to 2.67)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.874</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Breech birth</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.74
                          (−6.60 to 5.12)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.803</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Swaddling
                          experience</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.82
                          (−4.58 to 2.95)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.668</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dance or acrobatic
                          experience</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.31
                          (−9.17 to 4.55)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.509</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Beighton score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.61
                          (−0.02 to 1.24)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.056</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="9" style="position: unset;"><span class="text" style="position: unset;">Sharp
                          angle</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Age in yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.13
                          (−0.22 to −0.05)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.003<span
                            data-id="table_footnote_reference_30" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.42
                          (−3.35 to 0.51)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.148</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI in kg/m<span
                            data-id="superscript_26" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.05
                          (−0.19 to 0.28)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.710</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Family history</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.48
                          (−0.88 to 3.84)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.218</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">More severe on right
                          side</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.46
                          (−0.84 to 1.77)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.483</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Breech birth</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.79
                          (−4.87 to 1.29)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.253</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Swaddling
                          experience</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.58
                          (−2.57 to 1.40)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.561</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dance or acrobatic
                          experience</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.26
                          (−5.87 to 1.36)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.219</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Beighton score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.095
                          (−0.237 to 0.427)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.571</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">LCEA = lateral center-edge angle, LFH = lateralization of femoral head,
                        BMI = body mass index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">In the present study, we found that patients with DDH had
                  a greater prevalence of generalized joint hypermobility than did the healthy population. Among
                  patients with DDH, those with concomitant generalized joint hypermobility had a lower lateral
                  center-edge angle, greater lateralization of the femoral head, greater standardized lateralization of
                  the femoral head, and lower iHOT-12 score than did those without concomitant generalized joint
                  hypermobility. Further multivariable linear regression analysis of the imaging data from patients with
                  DDH revealed that higher Beighton scores predicted more severe radiographic hip dysplasia. These
                  findings indicated that patients with DDH with concomitant generalized joint hypermobility tend to
                  have more severe radiographic hip dysplasia. However, joint hypermobility was not found to be
                  independently associated with PROs (iHOT-12, WOMAC, or HHS).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The stability of a normal hip joint comes from 2 key
                  aspects: (1) appropriate anatomical matching of the acetabulum and the femoral head, and (2) the
                  restraint of soft-tissue tension, such as that provided by the capsule, ligaments, and muscles<a
                    href="" data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">29</span></a>. Individuals with generalized joint hypermobility have
                  reduced restraint strength in the soft tissues around the joints and are more likely to experience
                  joint instability<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_28" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">30</span></a>, which is also an important risk factor for musculoskeletal
                  disorders<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">32</span></a>. Chahal et al.<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">1</span></a>
                  found that patients with primary shoulder dislocation had increased external rotation of the
                  contralateral shoulder joint and a greater prevalence of generalized joint laxity. We found that the
                  prevalence of generalized joint hypermobility in patients with DDH was significantly greater than that
                  in healthy controls of comparable age and sex. To our knowledge, this is the first study to compare
                  nonpediatric patients with DDH and healthy individuals. In 1970, Wynne-Davies<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">11</span></a> found that the prevalence of “familial” joint laxity was
                  greater in children with DDH and their first-degree relatives than in age-matched controls. Santore et
                  al.<a href="" data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">13</span></a> investigated the prevalence of generalized joint
                  hypermobility in patients with different hip disease diagnoses and found that patients with hip
                  dysplasia had a significantly greater prevalence of hypermobility than did those with other diagnoses
                  (odds ratio [OR] = 7.1; 95% CI = 5.1 to 10.0).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Subsequently, in patients with DDH, we further explored
                  the following 2 questions: (1) Is there a correlation between joint hypermobility and the degree of
                  radiographic severity of the hip dysplasia? (2) Is joint hypermobility associated with more pronounced
                  clinical symptoms? We selected radiographic parameters (the lateral center-edge angle, Tönnis angle,
                  Sharp angle, lateralization of the femoral head, and standardized lateralization of the femoral head)
                  to evaluate the positional relationship between the acetabulum and femoral head and to determine the
                  severity of DDH. PROs (iHOT-12, HHS, and WOMAC) were collected to evaluate clinical and functional
                  outcomes. Our study revealed that patients with DDH and generalized joint hypermobility tended to have
                  more severe radiographic hip dysplasia and lower iHOT-12 scores (35.22 versus 40.96; p = 0.009), but
                  the results showed no significant difference in the HHS (82.47 versus 84.19; p = 0.399) or WOMAC
                  (39.77 versus 38.96; p = 0.655). After controlling for confounding factors, we verified that younger
                  age and higher Beighton scores were independent predictors of poorer radiographic findings (including
                  a lower lateral center-edge angle and more lateralization of the femoral head and standardized
                  lateralization of the femoral head), reflecting a more severe presentation of hip dysplasia. However,
                  PROs, including the iHOT-12 score, were not found to be associated with joint hypermobility according
                  to multivariable linear regression analysis. The lack of correlation between the degree of joint
                  hypermobility and PROs may be attributed to the following reasons: (1) the iHOT-12 has shown better
                  performance in evaluating clinical and functional outcomes in young, active patients with hip
                  disorders<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">14</span></a>,
                  and it is more sensitive for assessing patients with Hartofilakidis type-A dysplasia, and (2) enrolled
                  patients with DDH were generally young, and none of them had arthritis greater than Tönnis grade I,
                  which made joint degeneration and pain caused by joint hypermobility indistinguishable on the basis of
                  symptoms. We will continue to follow these patients to evaluate long-term symptoms and functional
                  outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">DDH is a broad term that encompasses a variety of
                  phenotypes with several relatively distinct pathogenic factors. In our study, the prevalence of
                  generalized joint hypermobility in patients with DDH was 27%, which was much greater than the 12%
                  prevalence in healthy controls; however, 73% of patients with DDH did not exhibit generalized joint
                  hypermobility, which also suggested that joint laxity may not be the only influencing factor among
                  many pathogenic factors. Many studies have confirmed that genes<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">36</span></a>, abnormal mechanics<a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">37</span></a><span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">39</span></a>, and the environment<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">40</span></a> all can contribute to DDH. In terms of soft tissue, several
                  basic science studies have shown that hip dysplasia may be related to the abnormal development of
                  joint capsules and surrounding soft tissue. Li et al.<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">41</span></a>
                  found that the collagen content and the expression of collagen types I and III in the hip capsule were
                  significantly decreased in patients with DDH. Zhou et al.<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">40</span></a>
                  found that the ratio of type-I to type-III collagen (collagen I/III) in the hip capsule was lower in
                  mice with DDH that were given excessive fluoride intake than in normal mice, suggesting that the
                  tensile strength of the joint capsule was weakened. Although these results suggest an important link
                  between abnormal soft tissue of the hip and DDH, determining whether joint laxity is the cause of DDH
                  requires further genetic or pathophysiological exploration.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The limitations of our study are as follows. First, the
                  patients with DDH who were included in this study had Hartofilakidis type-A dysplasia and were
                  scheduled for PAO, and they are therefore likely not fully representative of the DDH population.
                  Second, the healthy controls were mainly medical students or staff members who underwent medical
                  examinations at our hospital, which may introduce selection bias. Third, the majority of patients with
                  DDH and normal controls were recruited from a single medical center and were predominantly female,
                  which may limit the generalizability of the results. This study may not be representative of male
                  patients with DDH. Designing a large, multicenter, and male-targeted study will help clarify this
                  relationship. Finally, the etiology of DDH is complex, and it is difficult to evaluate the degree of
                  hip joint laxity directly through experimental methods. Determining the causal relationship between
                  joint hypermobility and DDH requires further basic research on pathological mechanisms to provide a
                  clearer answer.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">We found that patients with DDH had a greater prevalence
                  of generalized joint hypermobility than did healthy controls. In addition, in patients with DDH,
                  greater hypermobility was correlated with more severe hip dysplasia on imaging. These results suggest
                  that soft-tissue laxity, in addition to bone or cartilage factors, is another important factor of
                  concern in DDH.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I113" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I113</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Chahal J</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Leiter J</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> McKee MD</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Whelan DB</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Generalized ligamentous laxity
                  as a predisposing factor for primary traumatic anterior shoulder dislocation</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2010 Dec;19(8):1238-42.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Generalized%20ligamentous%20laxity%20as%20a%20predisposing%20factor%20for%20primary%20traumatic%20anterior%20shoulder%20dislocation&as_occt=title&as_sauthors=%20%22J%20Chahal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Juul-Kristensen B</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Røgind H</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Jensen DV</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Remvig L</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Inter-examiner reproducibility
                  of tests and criteria for generalized joint hypermobility and benign joint hypermobility
                  syndrome</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Rheumatology (Oxford).</span> 2007 Dec;46(12):1835-41.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inter-examiner%20reproducibility%20of%20tests%20and%20criteria%20for%20generalized%20joint%20hypermobility%20and%20benign%20joint%20hypermobility%20syndrome&as_occt=title&as_sauthors=%20%22B%20Juul-Kristensen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Remvig L</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Jensen DV</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Ward RC</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Epidemiology of general joint
                  hypermobility and basis for the proposed criteria for benign joint hypermobility syndrome: review of
                  the literature</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Rheumatol.</span> 2007 Apr;34(4):804-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiology%20of%20general%20joint%20hypermobility%20and%20basis%20for%20the%20proposed%20criteria%20for%20benign%20joint%20hypermobility%20syndrome%3A%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22L%20Remvig%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Russek LN</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Errico DM</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Prevalence, injury rate and,
                  symptom frequency in generalized joint laxity and joint hypermobility syndrome in a “healthy” college
                  population</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Clin Rheumatol.</span> 2016 Apr;35(4):1029-39.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%2C%20injury%20rate%20and%2C%20symptom%20frequency%20in%20generalized%20joint%20laxity%20and%20joint%20hypermobility%20syndrome%20in%20a%20%E2%80%9Chealthy%E2%80%9D%20college%20population&as_occt=title&as_sauthors=%20%22LN%20Russek%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Clapp IM</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Paul KM</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Beck EC</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Nho SJ</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Hypermobile Disorders and Their
                  Effects on the Hip Joint</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Front Surg.</span> 2021 Mar 25;8:596971.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hypermobile%20Disorders%20and%20Their%20Effects%20on%20the%20Hip%20Joint&as_occt=title&as_sauthors=%20%22IM%20Clapp%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Larson CM</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Stone RM</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Grossi EF</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Giveans MR</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Cornelsen GD</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Ehlers-Danlos Syndrome:
                  Arthroscopic Management for Extreme Soft-Tissue Hip Instability</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2015
                Dec;31(12):2287-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ehlers-Danlos%20Syndrome%3A%20Arthroscopic%20Management%20for%20Extreme%20Soft-Tissue%20Hip%20Instability&as_occt=title&as_sauthors=%20%22CM%20Larson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Myer GD</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Ford KR</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Paterno MV</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Nick TG</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Hewett TE</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">The effects of generalized joint
                  laxity on risk of anterior cruciate ligament injury in young female athletes</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2008
                Jun;36(6):1073-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effects%20of%20generalized%20joint%20laxity%20on%20risk%20of%20anterior%20cruciate%20ligament%20injury%20in%20young%20female%20athletes&as_occt=title&as_sauthors=%20%22GD%20Myer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Jónsson H</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Valtýsdóttir ST</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Hypermobility features in
                  patients with hand osteoarthritis</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Osteoarthritis Cartilage.</span> 1995 Mar;3(1):1-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hypermobility%20features%20in%20patients%20with%20hand%20osteoarthritis&as_occt=title&as_sauthors=%20%22H%20J%C3%B3nsson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Czaprowski D</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Kotwicki T</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Pawłowska P</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Stoliński L</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Joint hypermobility in children
                  with idiopathic scoliosis: SOSORT award 2011 winner</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Scoliosis.</span> 2011 Oct 7;6:22.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Joint%20hypermobility%20in%20children%20with%20idiopathic%20scoliosis%3A%20SOSORT%20award%202011%20winner&as_occt=title&as_sauthors=%20%22D%20Czaprowski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Acasuso-Díaz M</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Collantes-Estévez E</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Joint hypermobility in patients
                  with fibromyalgia syndrome</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Arthritis Care Res.</span> 1998 Feb;11(1):39-42.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Joint%20hypermobility%20in%20patients%20with%20fibromyalgia%20syndrome&as_occt=title&as_sauthors=%20%22M%20Acasuso-D%C3%ADaz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Wynne-Davies R</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Acetabular dysplasia and
                  familial joint laxity: two etiological factors in congenital dislocation of the hip. A review of 589
                  patients and their families</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 1970 Nov;52(4):704-16.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acetabular%20dysplasia%20and%20familial%20joint%20laxity%3A%20two%20etiological%20factors%20in%20congenital%20dislocation%20of%20the%20hip.%20A%20review%20of%20589%20patients%20and%20their%20families&as_occt=title&as_sauthors=%20%22R%20Wynne-Davies%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Carter C</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Wilkinson J</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Persistent Joint Laxity and
                  Congenital Dislocation of the Hip</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 1964 Feb;46:40-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Persistent%20Joint%20Laxity%20and%20Congenital%20Dislocation%20of%20the%20Hip&as_occt=title&as_sauthors=%20%22C%20Carter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Santore RF</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Gosey GM</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Muldoon MP</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Long AA</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Healey RM</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Hypermobility Assessment in
                  1,004 Adult Patients Presenting with Hip Pain: Correlation with Diagnoses and Demographics</span>.
                <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2020 Nov 4;102(Suppl 2):27-33.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hypermobility%20Assessment%20in%201%2C004%20Adult%20Patients%20Presenting%20with%20Hip%20Pain%3A%20Correlation%20with%20Diagnoses%20and%20Demographics&as_occt=title&as_sauthors=%20%22RF%20Santore%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Griffin DR</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Parsons N</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Mohtadi NG</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Safran MR</span>; <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Multicenter Arthroscopy of the
                  Hip Outcomes Research Network. A short version of the International Hip Outcome Tool (iHOT-12) for use
                  in routine clinical practice</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2012 May;28(5):611-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Multicenter%20Arthroscopy%20of%20the%20Hip%20Outcomes%20Research%20Network.%20A%20short%20version%20of%20the%20International%20Hip%20Outcome%20Tool%20(iHOT-12)%20for%20use%20in%20routine%20clinical%20practice&as_occt=title&as_sauthors=%20%22DR%20Griffin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Nilsdotter A</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Bremander A</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Measures of hip function and
                  symptoms: Harris Hip Score (HHS), Hip Disability and Osteoarthritis Outcome Score (HOOS), Oxford Hip
                  Score (OHS), Lequesne Index of Severity for Osteoarthritis of the Hip (LISOH), and American Academy of
                  Orthopedic Surgeons (AAOS) Hip and Knee Questionnaire</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">Arthritis Care Res (Hoboken).</span> 2011
                Nov;63(11):S200-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measures%20of%20hip%20function%20and%20symptoms%3A%20Harris%20Hip%20Score%20(HHS)%2C%20Hip%20Disability%20and%20Osteoarthritis%20Outcome%20Score%20(HOOS)%2C%20Oxford%20Hip%20Score%20(OHS)%2C%20Lequesne%20Index%20of%20Severity%20for%20Osteoarthritis%20of%20the%20Hip%20(LISOH)%2C%20and%20American%20Academy%20of%20Orthopedic%20Surgeons%20(AAOS)%20Hip%20and%20Knee%20Questionnaire&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Collins NJ</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Misra D</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Felson DT</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Crossley KM</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Roos EM</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Measures of knee function:
                  International Knee Documentation Committee (IKDC) Subjective Knee Evaluation Form, Knee Injury and
                  Osteoarthritis Outcome Score (KOOS), Knee Injury and Osteoarthritis Outcome Score Physical Function
                  Short Form (KOOS-PS), Knee Outcome Survey Activities of Daily Living Scale (KOS-ADL), Lysholm Knee
                  Scoring Scale, Oxford Knee Score (OKS), Western Ontario and McMaster Universities Osteoarthritis Index
                  (WOMAC), Activity Rating Scale (ARS), and Tegner Activity Score (TAS)</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Arthritis Care Res
                  (Hoboken).</span> 2011 Nov;63(11):S208-28.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measures%20of%20knee%20function%3A%20International%20Knee%20Documentation%20Committee%20(IKDC)%20Subjective%20Knee%20Evaluation%20Form%2C%20Knee%20Injury%20and%20Osteoarthritis%20Outcome%20Score%20(KOOS)%2C%20Knee%20Injury%20and%20Osteoarthritis%20Outcome%20Score%20Physical%20Function%20Short%20Form%20(KOOS-PS)%2C%20Knee%20Outcome%20Survey%20Activities%20of%20Daily%20Living%20Scale%20(KOS-ADL)%2C%20Lysholm%20Knee%20Scoring%20Scale%2C%20Oxford%20Knee%20Score%20(OKS)%2C%20Western%20Ontario%20and%20McMaster%20Universities%20Osteoarthritis%20Index%20(WOMAC)%2C%20Activity%20Rating%20Scale%20(ARS)%2C%20and%20Tegner%20Activity%20Score%20(TAS)&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Gullo TR</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Golightly YM</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Flowers P</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Jordan JM</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Renner JB</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Schwartz TA</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Kraus VB</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Hannan MT</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Cleveland RJ</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Nelson AE</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Joint hypermobility is not
                  positively associated with prevalent multiple joint osteoarthritis: a cross-sectional study of older
                  adults</span>. <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">BMC
                  Musculoskelet Disord.</span> 2019 Apr 11;20(1):165.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Joint%20hypermobility%20is%20not%20positively%20associated%20with%20prevalent%20multiple%20joint%20osteoarthritis%3A%20a%20cross-sectional%20study%20of%20older%20adults&as_occt=title&as_sauthors=%20%22TR%20Gullo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Flowers PPE</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Cleveland RJ</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Schwartz TA</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Nelson AE</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Kraus VB</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Hillstrom HJ</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Goode AP</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Hannan MT</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Renner JB</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Jordan JM</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Golightly YM</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Association between general
                  joint hypermobility and knee, hip, and lumbar spine osteoarthritis by race: a cross-sectional
                  study</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Arthritis Res Ther.</span> 2018 Apr 18;20(1):76.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20general%20joint%20hypermobility%20and%20knee%2C%20hip%2C%20and%20lumbar%20spine%20osteoarthritis%20by%20race%3A%20a%20cross-sectional%20study&as_occt=title&as_sauthors=%20%22PPE%20Flowers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Carlisle JC</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Beaulé PE</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Sierra RJ</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Leunig M</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Schoenecker PL</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Millis MB</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">A systematic approach to the
                  plain radiographic evaluation of the young adult hip</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2008
                Nov;90(4):47-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20approach%20to%20the%20plain%20radiographic%20evaluation%20of%20the%20young%20adult%20hip&as_occt=title&as_sauthors=%20%22JC%20Clohisy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Troelsen A</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Jacobsen S</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Rømer L</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Søballe K</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Weightbearing anteroposterior
                  pelvic radiographs are recommended in DDH assessment</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2008
                Apr;466(4):813-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Weightbearing%20anteroposterior%20pelvic%20radiographs%20are%20recommended%20in%20DDH%20assessment&as_occt=title&as_sauthors=%20%22A%20Troelsen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Siebenrock KA</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Schöll E</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Lottenbach M</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Ganz R</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Bernese periacetabular
                  osteotomy</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 1999 Jun;(363):9-20.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bernese%20periacetabular%20osteotomy&as_occt=title&as_sauthors=%20%22KA%20Siebenrock%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Carlisle JC</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Trousdale R</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Beaule PE</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Morgan P</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Steger-May K</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Schoenecker PL</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Millis M</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Radiographic evaluation of the
                  hip has limited reliability</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2009 Mar;467(3):666-75.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20evaluation%20of%20the%20hip%20has%20limited%20reliability&as_occt=title&as_sauthors=%20%22JC%20Clohisy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Yang G</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Zhang Z</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Hou X</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Luo D</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Cheng H</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Xiao K</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Liu H</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Zhang H</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">A New Parameter of Hip
                  Instability in Developmental Dysplasia of the Hip (DDH): Teardrop Distance</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Front Surg.</span> 2022 Jun
                14;9:899960.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20New%20Parameter%20of%20Hip%20Instability%20in%20Developmental%20Dysplasia%20of%20the%20Hip%20(DDH)%3A%20Teardrop%20Distance&as_occt=title&as_sauthors=%20%22G%20Yang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Sweeney JP</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Helms CA</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Minagi H</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Louie KW</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">The widened teardrop distance:
                  a plain film indicator of hip joint effusion in adults</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">AJR Am J Roentgenol.</span> 1987
                Jul;149(1):117-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20widened%20teardrop%20distance%3A%20a%20plain%20film%20indicator%20of%20hip%20joint%20effusion%20in%20adults&as_occt=title&as_sauthors=%20%22JP%20Sweeney%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Engesæter IØ</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Laborie LB</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Lehmann TG</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Fevang JM</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Lie SA</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Engesæter LB</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Rosendahl K</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Prevalence of radiographic
                  findings associated with hip dysplasia in a population-based cohort of 2081 19-year-old
                  Norwegians</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2013 Feb;95-B(2):279-85.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20radiographic%20findings%20associated%20with%20hip%20dysplasia%20in%20a%20population-based%20cohort%20of%202081%2019-year-old%20Norwegians&as_occt=title&as_sauthors=%20%22I%C3%98%20Enges%C3%A6ter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Whitley E</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Ball J</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Statistics review 4: sample
                  size calculations</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Crit Care.</span> 2002 Aug;6(4):335-41.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Statistics%20review%204%3A%20sample%20size%20calculations&as_occt=title&as_sauthors=%20%22E%20Whitley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Junge T</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Jespersen E</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Wedderkopp N</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Juul-Kristensen B</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Inter-tester reproducibility
                  and inter-method agreement of two variations of the Beighton test for determining Generalised Joint
                  Hypermobility in primary school children</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">BMC Pediatr.</span> 2013 Dec
                21;13:214.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inter-tester%20reproducibility%20and%20inter-method%20agreement%20of%20two%20variations%20of%20the%20Beighton%20test%20for%20determining%20Generalised%20Joint%20Hypermobility%20in%20primary%20school%20children&as_occt=title&as_sauthors=%20%22T%20Junge%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Ten Hove D</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Jorgensen TD</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> van der Ark LA</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Updated guidelines on selecting
                  an intraclass correlation coefficient for interrater reliability, with applications to incomplete
                  observational designs</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Psychol Methods.</span> Epub 2022 Sep 1.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Updated%20guidelines%20on%20selecting%20an%20intraclass%20correlation%20coefficient%20for%20interrater%20reliability%2C%20with%20applications%20to%20incomplete%20observational%20designs&as_occt=title&as_sauthors=%20%22D%20Ten%20Hove%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Engesaeter IØ</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Lie SA</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Lehmann TG</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Furnes O</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Vollset SE</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Engesaeter LB</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Neonatal hip instability and
                  risk of total hip replacement in young adulthood: follow-up of 2,218,596 newborns from the Medical
                  Birth Registry of Norway in the Norwegian Arthroplasty Register</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2008
                Jun;79(3):321-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Neonatal%20hip%20instability%20and%20risk%20of%20total%20hip%20replacement%20in%20young%20adulthood%3A%20follow-up%20of%202%2C218%2C596%20newborns%20from%20the%20Medical%20Birth%20Registry%20of%20Norway%20in%20the%20Norwegian%20Arthroplasty%20Register&as_occt=title&as_sauthors=%20%22I%C3%98%20Engesaeter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Bakshi NK</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Bayer JL</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Bigelow EMR</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Jameel OF</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Sekiya JK</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">The Effect of Capsulectomy on
                  Hip Joint Biomechanics</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Orthop J Sports Med.</span> 2017 Oct 18;5(10):2325967117733433.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Effect%20of%20Capsulectomy%20on%20Hip%20Joint%20Biomechanics&as_occt=title&as_sauthors=%20%22NK%20Bakshi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Mulpuri K</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Song KM</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Goldberg MJ</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Sevarino K</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Detection and Nonoperative
                  Management of Pediatric Developmental Dysplasia of the Hip in Infants up to Six Months of Age</span>.
                <span data-id="emphasis_31" class="annotation emphasis" style="position: unset;">J Am Acad Orthop
                  Surg.</span> 2015 Mar;23(3):202-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Detection%20and%20Nonoperative%20Management%20of%20Pediatric%20Developmental%20Dysplasia%20of%20the%20Hip%20in%20Infants%20up%20to%20Six%20Months%20of%20Age&as_occt=title&as_sauthors=%20%22K%20Mulpuri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Yang S</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Zusman N</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Lieberman E</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Goldstein RY</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Developmental Dysplasia of the
                  Hip</span>. <span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">Pediatrics.</span> 2019 Jan;143(1):e20181147.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Developmental%20Dysplasia%20of%20the%20Hip&as_occt=title&as_sauthors=%20%22S%20Yang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Harsanyi S</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Zamborsky R</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Kokavec M</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Danisovic L</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Genetics of developmental
                  dysplasia of the hip</span>. <span data-id="emphasis_33" class="annotation emphasis"
                  style="position: unset;">Eur J Med Genet.</span> 2020 Sep;63(9):103990.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Genetics%20of%20developmental%20dysplasia%20of%20the%20hip&as_occt=title&as_sauthors=%20%22S%20Harsanyi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Feldman G</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Offemaria A</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Sawan H</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Parvizi J</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Freeman TA</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">A murine model for
                  developmental dysplasia of the hip: ablation of CX3CR1 affects acetabular morphology and gait</span>.
                <span data-id="emphasis_34" class="annotation emphasis" style="position: unset;">J Transl Med.</span>
                2017 Nov 10;15(1):233.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20murine%20model%20for%20developmental%20dysplasia%20of%20the%20hip%3A%20ablation%20of%20CX3CR1%20affects%20acetabular%20morphology%20and%20gait&as_occt=title&as_sauthors=%20%22G%20Feldman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Kenanidis E</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Gkekas NK</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Karasmani A</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Anagnostis P</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Christofilopoulos P</span>,
                <span data-id="annotation_163" class="annotation" style="position: unset;"> Tsiridis E</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Genetic Predisposition to
                  Developmental Dysplasia of the Hip</span>. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2020 Jan;35(1):291-300.e1.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Genetic%20Predisposition%20to%20Developmental%20Dysplasia%20of%20the%20Hip&as_occt=title&as_sauthors=%20%22E%20Kenanidis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Yan W</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Zheng L</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Xu X</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Hao Z</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Lu J</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Sun Z</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Dai J</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Shi D</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Guo B</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Jiang Q</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Heterozygous LRP1 deficiency
                  causes developmental dysplasia of the hip by impairing triradiate chondrocytes differentiation due to
                  inhibition of autophagy</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">Proc Natl Acad Sci U S A.</span> 2022 Sep 13;119(37):e2203557119.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Heterozygous%20LRP1%20deficiency%20causes%20developmental%20dysplasia%20of%20the%20hip%20by%20impairing%20triradiate%20chondrocytes%20differentiation%20due%20to%20inhibition%20of%20autophagy&as_occt=title&as_sauthors=%20%22W%20Yan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Kural B</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Devecioğlu Karapınar E</span>,
                <span data-id="annotation_177" class="annotation" style="position: unset;"> Yılmazbaş P</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Eren T</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Gökçay G</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Risk Factor Assessment and a
                  Ten-Year Experience of DDH Screening in a Well-Child Population</span>. <span data-id="emphasis_37"
                  class="annotation emphasis" style="position: unset;">Biomed Res Int.</span> 2019 Aug
                4;2019:7213681.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20Factor%20Assessment%20and%20a%20Ten-Year%20Experience%20of%20DDH%20Screening%20in%20a%20Well-Child%20Population&as_occt=title&as_sauthors=%20%22B%20Kural%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Pollet V</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Percy V</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Prior HJ</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Relative Risk and Incidence for
                  Developmental Dysplasia of the Hip</span>. <span data-id="emphasis_38" class="annotation emphasis"
                  style="position: unset;">J Pediatr.</span> 2017 Feb;181:202-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Relative%20Risk%20and%20Incidence%20for%20Developmental%20Dysplasia%20of%20the%20Hip&as_occt=title&as_sauthors=%20%22V%20Pollet%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Schams M</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Labruyère R</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Zuse A</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Walensi M</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Diagnosing developmental
                  dysplasia of the hip using the Graf ultrasound method: risk and protective factor analysis in 11,820
                  universally screened newborns</span>. <span data-id="emphasis_39" class="annotation emphasis"
                  style="position: unset;">Eur J Pediatr.</span> 2017 Sep;176(9):1193-200.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnosing%20developmental%20dysplasia%20of%20the%20hip%20using%20the%20Graf%20ultrasound%20method%3A%20risk%20and%20protective%20factor%20analysis%20in%2011%2C820%20universally%20screened%20newborns&as_occt=title&as_sauthors=%20%22M%20Schams%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Zhou W</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Luo W</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Liu D</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Canavese F</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Li L</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Zhao Q</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Fluoride increases the
                  susceptibility of developmental dysplasia of the hip via increasing capsular laxity triggered by cell
                  apoptosis and oxidative stress in vivo and in vitro</span>. <span data-id="emphasis_40"
                  class="annotation emphasis" style="position: unset;">Ecotoxicol Environ Saf.</span> 2022 Apr
                1;234:113408.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fluoride%20increases%20the%20susceptibility%20of%20developmental%20dysplasia%20of%20the%20hip%20via%20increasing%20capsular%20laxity%20triggered%20by%20cell%20apoptosis%20and%20oxidative%20stress%20in%20vivo%20and%20in%20vitro&as_occt=title&as_sauthors=%20%22W%20Zhou%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Li C</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Peng Z</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Zhou Y</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Su Y</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Bu P</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Meng X</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Li B</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Xu Y</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Comprehensive analysis of
                  pathological changes in hip joint capsule of patients with developmental dysplasia of the hip</span>.
                <span data-id="emphasis_41" class="annotation emphasis" style="position: unset;">Bone Joint Res.</span>
                2021 Sep;10(9):558-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comprehensive%20analysis%20of%20pathological%20changes%20in%20hip%20joint%20capsule%20of%20patients%20with%20developmental%20dysplasia%20of%20the%20hip&as_occt=title&as_sauthors=%20%22C%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;ef557ce0-258c-48d0-a2f8-7e29fd439985&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[&quot;hp pd&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=ef557ce0-258c-48d0-a2f8-7e29fd439985&type=supplement&name=10518"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fhub.jbjs.org%2Freader.php%3Frsuite_id%3Def557ce0-258c-48d0-a2f8-7e29fd439985%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2F1895%26topics%3Dhp%2Bpd"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="authorinsights_reference"
            class="annotation authorinsights_reference resource-reference" style="position: unset;"></a>
          <div class="content-node authorinsights" data-id="authorinsights" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Author Insights</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6361793341112&quot;,&quot;type&quot;:&quot;author_insights&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;ef557ce0-258c-48d0-a2f8-7e29fd439985&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6361793341112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6361793341112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6361793341112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6361793341112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/c0ded852-0a88-427b-9c83-3d129c36b067/15806a73-9d6f-4216-b80f-8a6150f1b207/1280x720/match/image.jpg"
                        src="blob:https://hub.jbjs.org/bfa3f39a-62f6-4106-baf1-c8bf596e2504">
                        <track kind="metadata" label="segment-metadata">
                        <track kind="subtitles" label="English" srclang="en" default="" id="English">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/c0ded852-0a88-427b-9c83-3d129c36b067/15806a73-9d6f-4216-b80f-8a6150f1b207/1280x720/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:56</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:56">
                            <div class="vjs-load-progress"><span class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">0%</span></span></div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:56</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden vjs-disabled">
                          <button
                            class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button vjs-disabled"
                            type="button" aria-disabled="true" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false" disabled="disabled"><span class="vjs-icon-placeholder"
                              aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu vjs-hidden">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content" role="menu">
                              <li class="vjs-menu-item" tabindex="-1" role="menuitemradio" aria-disabled="false"
                                aria-checked="false"><span class="vjs-menu-item-text">captions off</span><span
                                  class="vjs-control-text" aria-live="polite"></span></li>
                              <li class="vjs-menu-item vjs-subtitles-menu-item vjs-selected" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">English</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button vjs-disabled"
                          type="button" title="Picture-in-Picture" aria-disabled="true" disabled="disabled"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                        <div class="vjs-overlay vjs-overlay-bottom-right vjs-overlay-no-background vjs-hidden"><img
                            src="//players.brightcove.net/2324982687001/studio_overlays/HyP0JSARx-0efbccee-026f-4138-bed4-07fddabe7725.png"
                            style="max-width: 100%;"></div>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_712_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_712_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): 1895</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01030</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 26, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_42"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Fourth
                            Medical Center of PLA General Hospital</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ef557ce0-258c-48d0-a2f8-7e29fd439985&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;hp pd&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=ef557ce0-258c-48d0-a2f8-7e29fd439985&type=pdf&name=JBJS.23.01030.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=ef557ce0-258c-48d0-a2f8-7e29fd439985&type=pdf&name=JBJS.23.01030.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_47"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was
                          supported by the Key Project of National Natural Science Foundation of China (No. U22A20355)
                          and the Beijing Municipal Science and Technology Project (No. Z221100003522014). The <span
                            data-id="strong_48" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I112" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I112</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ef557ce0-258c-48d0-a2f8-7e29fd439985&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;hp pd&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=ef557ce0-258c-48d0-a2f8-7e29fd439985&type=zip&name=JBJS.23.01030.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hangyu Ping, MM<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3516-5430" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3516-5430</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Xiangpeng Kong, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3122-9716" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3122-9716</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hong Zhang, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9201-6274" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9201-6274</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dianzhong Luo, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0001-8535-5593" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0001-8535-5593</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Qing Jiang, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:qingj@nju.edu.cn" class="" style="position: unset;">qingj@nju.edu.cn</a><span class=""
                    style="position: unset;">;</span><a href="mailto:chaiwei301@163.com" class=""
                    style="position: unset;">chaiwei301@163.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2552-9686" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2552-9686</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Wei Chai, MD<span data-id="affiliation_reference_3"
                  class="label annotation cross_reference">3</span><span data-id="affiliation_reference_4"
                  class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:qingj@nju.edu.cn" class="" style="position: unset;">qingj@nju.edu.cn</a><span class=""
                    style="position: unset;">;</span><a href="mailto:chaiwei301@163.com" class=""
                    style="position: unset;">chaiwei301@163.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8781-9745" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8781-9745</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">China Japan Friendship Hospital, Chinese Academy of Medical Sciences &
                Peking Union Medical College, Beijing, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">School of Medicine, Nankai University, Tianjin, People’s Republic of
                China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Senior Department of Orthopedics, Fourth Medical Center of PLA General
                Hospital, Beijing, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">National Clinical Research Center for Orthopedics, Sports Medicine &
                Rehabilitation, Beijing, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">State Key Laboratory of Pharmaceutical Biotechnology, Division of Sports
                Medicine and Adult Reconstructive Surgery, Department of Orthopedic Surgery, Nanjing Drum Tower
                Hospital, Nanjing University Medical School, Nanjing, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Hangyu Ping, MM, and Xiangpeng Kong, MD, contributed
                        equally to this work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 31574.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
