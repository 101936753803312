import type { Article } from "../../../../../types";

export const Article630: Article = {
	id: 630,
	rsuiteId: "790e740a-6bf9-4d84-95c4-fbb99b62ed1b",
	type: "scientific article",
	title:
		"Radiographic Outcome Following Treatment of Residual Hip Dysplasia with Pemberton Versus Salter Osteotomy",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=790e740a-6bf9-4d84-95c4-fbb99b62ed1b&type=jpeg&name=JBJS.23.01346f1",
	subSpecialties: ["pediatrics"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Radiographic Outcome Following Treatment of Residual Hip
                Dysplasia with Pemberton Versus Salter Osteotomy</div>
              <div class="text subtitle" style="position: unset;">Comparison of Results in Patients Followed to Skeletal
                Maturity</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Daniel J.
                      Sucato, MD, MS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Case E.
                      Brabham, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Background:</span> Salter osteotomy (SO) and Pemberton
                        acetabuloplasty (PA) are procedures to treat skeletally immature patients with developmental
                        dysplasia of the hip (DDH). The purpose of this study was to compare the radiographic results
                        and rate of residual dysplasia (RD) after treatment with SO and with PA.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Methods:</span> This was a retrospective analysis of pediatric
                        patients treated with either SO or PA for DDH between 1980 and 2013 who were skeletally mature
                        at the time of follow-up. The preoperative and postoperative acetabular index (AI) and
                        postoperative lateral center-edge angle (LCEA) and anterior center-edge angle (ACEA) were
                        collected. RD was defined as an LCEA or ACEA of &lt;20° or an acetabular inclination (AIn) of
                        &gt;15° at skeletal maturity. Continuous variables were compared between treatment groups with
                        2-sample t tests, and categorical variables were compared using chi-square tests. Multivariable
                        analysis was used to identify risk factors for RD with p &lt; 0.05.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Results:</span> The study included 113 hips in 102 patients with a
                        mean follow-up of 10.5 years. The SO group had 73 hips treated at a mean patient age of 5.4
                        years, and the PA group had 40 hips treated at a mean age of 5.3 years. The preoperative AI did
                        not differ between the treatment groups (32.2° versus 31.3°, p = 0.658), nor did the immediate
                        postoperative AI (20.0° versus 20.2°, p = 0.459). At the time of final follow-up, the SO group
                        had a significantly smaller AIn (8.6° versus 13.2°, p = 0.001), a trend toward a greater LCEA
                        (26.2° versus 21.6°, p = 0.056), and a similar ACEA (24.1° versus 26.1°, p = 0.808). By the
                        abovementioned definition, 36 hips (31.9%) had evidence of RD, with a lower rate in the SO group
                        (26.0% versus 42.5%, p = 0.07). Patients who had a positive family history of hip dysplasia were
                        more likely to have RD (odds ratio = 4.311, 95% confidence interval = 1.125 to 16.528).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Patients with RD could be effectively treated
                        with either SO or PA, with overall good radiographic outcomes. However, SO achieved a better AI
                        and may thus yield better long-term health of the affected hip.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Salter osteotomy (SO) and Pemberton acetabuloplasty (PA)
                  are commonly used to treat young patients with developmental dysplasia of the hip (DDH)<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">6</span></a>.
                  Salter first described the SO in 1961 as providing an additional stabilizing factor during the
                  treatment of a dislocated hip, through redirecting the dysplastic acetabulum by rotating it through
                  the symphysis pubis after performing an innominate osteotomy, and reported excellent or good results
                  at a follow-up of 1 to 3 years<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">7</span></a>. The
                  PA was first described in 1965 as an acetabuloplasty that relies on the plasticity of the triradiate
                  cartilage (TRC) to achieve rotation, which may result in a decreased volume of the acetabulum<a
                    href="" data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">8</span></a>. These procedures can be utilized in patients with DDH, in
                  combination with open reduction with a capsulorrhaphy with or without femoral osteotomy, or in
                  patients who have residual dysplasia (RD) after treatment to improve the radiographically evident
                  dysplasia in an attempt to achieve a normal or nearly normal hip at skeletal maturity<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">2</span></a>. Both techniques have been widely reported to provide good
                  radiographic correction in the short term. However, to our knowledge, there are no studies comparing
                  their radiographic outcomes at skeletal maturity in a subset of patients who had an isolated SO or PA
                  to treat RD.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The purpose of this study was to compare the effectiveness
                  of isolated SO and isolated PA for correcting RD after treatment of idiopathic DDH and to determine
                  the radiographic outcomes and the rate of RD at skeletal maturity after each treatment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">This was an institutional review board-approved
                  retrospective analysis of a consecutive series of patients who were treated with either isolated SO or
                  isolated PA between 1980 and 2013 for radiographic RD after treatment of idiopathic DDH. The surgery
                  was performed by 1 of 6 fellowship-trained pediatric orthopaedic surgeons. The initial DDH treatment
                  modality, whether the procedure was open or closed, and whether or not osseous surgery was required
                  were not used as exclusion criteria. Patients with underlying syndromes, with teratologic
                  dislocations, with concomitant open reduction and femoral shortening procedures, and not reaching
                  skeletal maturity were excluded. Demographic and clinical information were collected from the
                  remaining patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Standard radiographic measurements during the included the
                  acetabular index (AI), which was measured on a standing radiograph using the angle subtended by the
                  Hilgenreiner line and a line drawn from the TRC to the lateral edge of the sourcil<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">9</span></a> (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). The AI
                  was measured before SO or PA and on the first standing radiograph following surgery. At skeletal
                  maturity, standing anteroposterior pelvic radiographs were used to measure the acetabular inclination
                  (AIn), or Tönnis angle<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">10</span></a>, and
                  the lateral center-edge angle (LCEA)<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">11</span></a> and
                  to look for the presence of a crossover sign, an ischial spine sign, and a posterior wall sign. The
                  anterior center-edge angle (ACEA)<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">12</span></a> was
                  measured on the false-profile view. The level of agreement among the 3 reviewers was assessed using
                  the intraclass correlation coefficient (ICC). For patients who required a subsequent pelvic osteotomy
                  for RD after the SO or PA, the radiographic evaluation before the subsequent procedure was utilized
                  for the analysis of the outcome of the SO or PA procedure. RD was defined as an LCEA or ACEA of
                  &lt;20° or an AIn of &gt;15° at the time of final follow-up<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">12</span></a>. The
                  presence of osteonecrosis was classified using the method described by Bucholz and Ogden<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">13</span></a> and was assessed at the time of surgery, immediately
                  postoperatively, and at the time of final follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01346f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=790e740a-6bf9-4d84-95c4-fbb99b62ed1b&type=jpeg&name=JBJS.23.01346f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;">The acetabular index was determined by measuring
                          the angle subtended by the Hilgenreiner line (H) and a line from the triradiate cartilage to
                          the edge of the sourcil (A) rather than to the lateral margin (B).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Values are reported as the count and/or percentage or as
                  the mean with or without the standard deviation. Two-sample t tests were used to compare continuous
                  variables between the SO and PA groups, and chi-square tests were used to compare categorical
                  variables. The ICC was used to analyze the interobserver agreement for the continuous radiographic
                  variables and the crossover sign. Data were checked for normality and equal variances. Multivariable
                  analysis was used to identify risk factors for RD at the time of final follow-up. Alpha was set to p
                  &lt; 0.05. Statistical analysis was performed using IBM SPSS (version 19).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">A total of 410 patients were treated with either SO or PA
                  during the study period. Of these, 96 patients were excluded for an underlying syndromic or
                  teratologic cause of the dislocation, 3 for missing medical records due to lack of follow-up, 172 for
                  concomitant open reduction and/or femoral shortening, 3 for prior surgery, and 34 for skeletally
                  immaturity at the time of study completion.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The remaining 102 patients, with a total of 113 dysplastic
                  hips, who underwent surgical treatment of RD with either SO or PA were included. Ninety-seven hips
                  (85.8%) were in female patients and 16 (14.2%) were in male patients. Race and ethnicity were not
                  identified in the medical record and could not be included in the analysis for this study. There were
                  73 hips (65%) in the SO group and 40 (35%) in the PA group. The mean follow-up for the study cohort
                  was 10.5 years (range, 5.0 to 20.7 years). The SO and PA groups were similar with respect to age at
                  surgery (5.4 versus 5.3 years, p = 0.701) and estimated blood loss (103.4 versus 123.0 mL, p = 0.797),
                  but the SO group did have a significantly shorter operative time (118.6 versus 155.5 minutes, p &lt;
                  0.0001). The age range at which surgery was performed was the same in both groups (2 to 9 years). No
                  differences were seen with respect to risk factors for DDH or rates of previous treatment (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Preoperative Demographic Data</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 987px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Salter
                          Osteotomy<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span>, N = 73
                          Hips</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Pemberton
                          Acetabuloplasty<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">*</span>, N = 40
                          Hips</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at surgery <span
                            data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.4 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.3 ±
                          1.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.701</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at final follow-up
                          <span data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.9 ±
                          3.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.0 ±
                          3.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Duration of follow-up
                          <span data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.6 ±
                          3.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.7 ±
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Left side</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">50.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">60.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.342</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Risk factors</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female gender</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">83.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">90.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.324</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> First born</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">46.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">47.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.967</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Family history</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">21.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.163</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Breech birth</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">31.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">30.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.799</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Previous treatment</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pavlik harness</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">34.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.096</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Closed reduction</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">76.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">62.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.129</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Open reduction</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">24.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">39.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.108</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± standard deviation or as the
                        percentage.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The follow-up was longer for the SO group (11.6 versus 8.7
                  years, p = 0.001), and the patients treated with SO were older (16.9 versus 14.0 years, p = 0.001) at
                  the time of final follow-up. All patients were skeletally mature at the final follow-up, and the age
                  ranges were 10.3 to 25.0 years in the SO group and 9.6 to 20.8 years in the PA group. Almost all hips
                  (97.3%) in the SO group required an additional anesthetic for pin removal, whereas only 1 patient
                  (2.5%) in the PA group had pins placed for stabilization at the time of the initial surgery that
                  required removal (p &lt; 0.001). The interobserver agreement for all radiographic variables was
                  excellent (LCEA: ICC = 0.951; ACEA: ICC = 0.931; preoperative AI: ICC = 0.848; postoperative AI: ICC =
                  0.931; crossover sign: ICC = 1.000). Preoperatively, the AI did not differ between the SO and PA
                  groups (32.2° versus 31.3°, p = 0.658). The immediate postoperative AI also did not differ between the
                  SO and PA groups (20.0° versus 20.2°, p = 0.459). At the time of final follow-up, there was a trend
                  toward a better LCEA in the SO group (26.2° versus 21.6°, p = 0.056) and the ACEA was similar between
                  the SO and PA groups (24.1° versus 26.1°, p = 0.808). However, the SO group had a better AIn (8.6°
                  versus 13.2°, p = 0.003) (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 2-A</a>
                  through <a href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">3-D</a>, <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).
                  Overall, 36 hips (31.9%) had evidence of RD at final follow-up: 26.0% in the SO group and 42.5% in the
                  PA group (p = 0.07). Among the patients who had RD, there was no difference between the SO and PA
                  groups in the preoperative AI (33.2° versus 35.7°, p = 0.20), the postoperative AI (22.1° versus
                  23.9°, p = 0.42), or the amount of AI improvement with surgery (11.2° versus 11.0°, p = 0.96).
                  Multivariable analysis identified a positive family history of hip dysplasia as a significant
                  predictor of RD (odds ratio = 4.311, 95% confidence interval = 1.125 to 16.528). None of the other
                  risk factors for DDH that were examined (family history, type of surgical osteotomy [SO versus PA],
                  open reduction, preoperative AI, age at surgery, and operative time) were found to be predictors of
                  RD.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_37"
                            class="annotation strong" style="position: unset;">Figs. 2-A through 2-D</span> Salter
                          osteotomy. <span data-id="strong_38" class="annotation strong" style="position: unset;">Fig.
                            2-A</span> Preoperative standing anteroposterior pelvic radiograph made at the age of 5
                          years, showing residual right hip dysplasia with an acetabular index (AI) of 36°. <span
                            data-id="strong_39" class="annotation strong" style="position: unset;">Fig. 2-B</span>
                          Standing anteroposterior pelvic radiograph made 6 months after surgery, showing improvement of
                          the AI to 21°. <span data-id="strong_40" class="annotation strong"
                            style="position: unset;">Figs. 2-C and 2-D</span> Standing anteroposterior and false-profile
                          pelvic radiographs made 14 years postoperatively, at 19 years of age, showing a nearly
                          normal-appearing hip.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-A</div>
                    <div class="image-download" name="JBJS.23.01346f2a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=790e740a-6bf9-4d84-95c4-fbb99b62ed1b&type=jpeg&name=JBJS.23.01346f2a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-B</div>
                    <div class="image-download" name="JBJS.23.01346f2b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=790e740a-6bf9-4d84-95c4-fbb99b62ed1b&type=jpeg&name=JBJS.23.01346f2b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-C</div>
                    <div class="image-download" name="JBJS.23.01346f2c" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=790e740a-6bf9-4d84-95c4-fbb99b62ed1b&type=jpeg&name=JBJS.23.01346f2c"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-D</div>
                    <div class="image-download" name="JBJS.23.01346f2d" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=790e740a-6bf9-4d84-95c4-fbb99b62ed1b&type=jpeg&name=JBJS.23.01346f2d"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Figs. 3-A through 3-D</span> Pemberton
                          osteotomy. <span data-id="strong_46" class="annotation strong" style="position: unset;">Fig.
                            3-A</span> Preoperative standing anteroposterior pelvic radiograph made at the age of 5
                          years, showing residual right hip dysplasia with an acetabular index (AI) of 37°. <span
                            data-id="strong_47" class="annotation strong" style="position: unset;">Fig. 3-B</span>
                          Standing anteroposterior pelvic radiograph made 13 months after surgery, showing improvement
                          of the AI to 22°. <span data-id="strong_48" class="annotation strong"
                            style="position: unset;">Figs. 3-C and 3-D</span> Standing anteroposterior and false-profile
                          pelvic radiographs made 16 years postoperatively, at 20 years of age, showing mild residual
                          hip dysplasia.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-A</div>
                    <div class="image-download" name="JBJS.23.01346f3a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=790e740a-6bf9-4d84-95c4-fbb99b62ed1b&type=jpeg&name=JBJS.23.01346f3a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-B</div>
                    <div class="image-download" name="JBJS.23.01346f3b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=790e740a-6bf9-4d84-95c4-fbb99b62ed1b&type=jpeg&name=JBJS.23.01346f3b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-C</div>
                    <div class="image-download" name="JBJS.23.01346f3c" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=790e740a-6bf9-4d84-95c4-fbb99b62ed1b&type=jpeg&name=JBJS.23.01346f3c"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_12" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-D</div>
                    <div class="image-download" name="JBJS.23.01346f3d" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=790e740a-6bf9-4d84-95c4-fbb99b62ed1b&type=jpeg&name=JBJS.23.01346f3d"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_15" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Radiographic Parameters</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 776px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Salter
                          Osteotomy<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">*</span>, N = 73
                          Hips</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Pemberton
                          Acetabuloplasty<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span>, N = 40
                          Hips</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative AI <span
                            data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.2 ±
                          6.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.3 ±
                          7.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.658</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Immediate postoperative
                          AI <span data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.0 ±
                          5.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.2 ±
                          7.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.459</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Final follow-up</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> AIn <span
                            data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.6 ±
                          6.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.2 ±
                          8.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.003</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> LCEA <span
                            data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.2 ±
                          10.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.6 ±
                          11.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.056</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ACEA <span
                            data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.1 ±
                          18.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.1 ±
                          13.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.808</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Crossover sign</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">22.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.594</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Residual
                          dysplasia</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">26.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">42.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.07</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tönnis grade</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.317</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  0 or 1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">93.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">90.0%</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.5%</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.5%</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± standard deviation or as the
                        percentage. AI = acetabular index, AIn = acetabular inclination (Tönnis angle), LCEA = lateral
                        center-edge angle, and ACEA = anterior center-edge angle.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">The rate of acetabular retroversion at final follow-up was
                  similar in the 2 groups, with 17.8% of the SO group and 22.5% of the PA group having a crossover sign
                  (p = 0.594) (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). There
                  was no significant difference in the rate of osteonecrosis between the SO and PA groups (20.5% versus
                  32.5%, p = 0.185), with the majority being grade-1 changes, which are considered to not be clinically
                  important. There was no difference in the Tönnis osteoarthritis grade between the SO and PA groups (p
                  = 0.317). Nine skeletally mature patients, 4 in the PA group and 5 in the SO group, had a Tönnis grade
                  of 2 or 3 (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, this study is the first to specifically
                  compare the radiographic outcomes and rate of RD between the SO and PA procedures at mid-term
                  follow-up at a single institution. Our results demonstrated that, at a follow-up of slightly more than
                  10 years, there were differences in radiographic outcomes (AIn and LCEA) and in the rate of RD after
                  treatment with SO compared with PA, with SO yielding better radiographic results with respect to the
                  AIn and trends toward a larger LCEA and a lower rate of RD. These differences were seen even though
                  the patient demographics, including age and gender, as well as the preoperative and immediate
                  postoperative AI were similar in the 2 groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The mean age at surgery was 5 years in each group, and is
                  a reflection of the philosophy that the remodeling potential of the acetabulum is greatest before this
                  age. Pemberton provided an interim report on his osteotomy and suggested that surgery should be
                  performed after 1 year of age and all of the way up to when the “triradiate cartilage becomes too
                  rigid to use as a hinge,” which he believed was 10 to 14 years of age<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a>. The majority of authors have described use of the PA in
                  patients who were generally younger than 5 years of age<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">17</span></a>. However, it has also been described in older patients,
                  with overall good results<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_13" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">18</span></a>. Salter described the innominate osteotomy and recommended
                  that it be performed between 18 months and 6 years of age for a dislocated hip, and between 18 months
                  and “early adult life” for RD<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">19</span></a>.
                  Salter and Dubos subsequently reported better results in the 1.5 to 4-year-old group compared with the
                  4 to 10-year-old group (94% versus 57% excellent results)<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">20</span></a>,
                  and others have also reported better results when SO is performed in younger patients<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_16" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">23</span></a>. Our general strategy for children older than 5 or 6 years
                  of age is to utilize the more powerful Tönnis triple osteotomy to achieve improved correction in all 3
                  planes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">It is important for a fair comparison that the age and
                  preoperative severity of acetabular dysplasia were the same in the 2 groups in this study. Just as
                  importantly, the AI was improved from just above 30° preoperatively to 20° in the immediate
                  postoperative period by both procedures. This postoperative correction to the same level suggests that
                  both the SO and the PA are effective treatments for substantially improving RD, as represented by the
                  AI, at the time of surgery. The development of the acetabulum from that point forward is the important
                  aspect of this study, and we demonstrated that acetabular development over time, as shown by the AIn,
                  was greater for those who underwent SO compared with PA. This suggests that the ability to remodel
                  over time, which has not previously been fully evaluated in SO compared with PA, is greater in
                  patients treated with SO. The explanation for this is not completely understood. One hypothesis is
                  that penetration of the osteotome into the TRC during the PA results in some growth disturbance, which
                  does not allow for optimal acetabular development over time. We did not see obvious evidence of
                  premature TRC closure with an osseous bridge or the associated widened teardrop over time<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">24</span></a>. Development of the acetabulum over time can also be
                  related to the forces across the hip joint, which help to maintain congruity of the hip joint. An in
                  vitro study of these forces by Pfeifer et al. demonstrated decreased muscle forces when the hip joint
                  had been positioned more medially and caudally by an SO<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">25</span></a>.
                  These changes are probably important and influence the development of the acetabulum over time. It can
                  be hypothesized that because the hinge of the PA is the TRC, the joint may become more lateralized
                  following surgery, inhibiting further development of the acetabulum. The lower LCEA at the time of
                  follow-up in the PA group in this study suggests that this may be true.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">The mechanism of correction is very different between the
                  2 procedures and potentially could lead to differences between lateral femoral head coverage (LCEA)
                  and anterior coverage (ACEA) and differences in version. We did not demonstrate any differences
                  between the 2 groups with respect to the ACEA or the percentage of patients with a crossover sign.
                  Previous work comparing the 2 procedures demonstrated a tendency to have greater anterior coverage and
                  a higher likelihood of a positive impingement sign in the PA group compared with the SO group<a
                    href="" data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">26</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, our study is the first large long-term
                  follow-up study comparing patients treated for RD with SO with those who underwent PA. Others have
                  compared these techniques when performing an open reduction for DDH<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">27</span></a>, in small series<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">26</span></a>, or
                  with shorter follow-up<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">28</span></a>.
                  Wang et al. primarily analyzed anterior coverage in a total of 42 hips at a minimum follow-up of 10
                  years and found a greater ACEA in the patients treated with PA<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">26</span></a>. Ertürk et al. compared 47 patients treated with SO and 50
                  patients treated with PA, with a minimum follow-up of only 5 years, and demonstrated no difference in
                  the AI (19.7° versus 20.1°, p &gt; 0.05) or the LCEA (28.5° versus 31.0°, p &gt; 0.05)<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">28</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Several reports have documented radiographic evidence of
                  complications, including osteonecrosis and RD, after treatment with PA. Wu et al. reported on 49 PAs
                  in patients with DDH who were ≥18 months of age at treatment and had a minimum follow-up of 10 years<a
                    href="" data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">29</span></a>. Patients were divided into osteonecrosis-absent and
                  osteonecrosis-present groups according to the criteria described by Bucholz and Ogden<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">13</span></a>. Twenty-four patients (49%) were included in the
                  osteonecrosis-absent group, and 25 patients (51%) were included in the osteonecrosis-present group<a
                    href="" data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">29</span></a>. The osteonecrosis-absent group was more likely to have a
                  satisfactory result than the osteonecrosis-present group (96% versus 76% with Severin class I or II, p
                  &lt; 0.0001)<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">29</span></a>. In
                  another series that included 16 hips in 14 children older than 7 years treated with PA for DDH, the
                  authors reported that no patient had developed osteonecrosis at the time of final follow-up and
                  correction of acetabular dysplasia was achieved in 14 of the 16 hips<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">18</span></a>. In the remaining 2 hips, subsequent subluxation of the
                  femoral head resulted in a radiographic Severin classification of IV, although there was improvement
                  in the AIn and center-edge angle (CEA), at the time of final follow-up<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">18</span></a>. The results of our study demonstrated that although 32.5%
                  of the PA group had radiographic evidence of osteonecrosis at the time of final follow-up, this rate
                  did not differ significantly from that of the SO group (20.5%, p = 0.185).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">There are limitations to this study. First, it was a
                  retrospective study and the inherent limitations of this design are present, including a lack of
                  understanding of the indications for the choice of SO or PA in each patient. A randomized study
                  comparing the 2 procedures would have been better, but the preoperative AI as well as age were similar
                  in the 2 groups. The retrospective nature of the study also limited the number of patients to those
                  who had surgery at our institution, and differences that were not significant might have become
                  significant with larger numbers. Second, it is not clear whether the goals of correction were the same
                  for each of the patients. However, the mean AI in the immediate postoperative period was similar in
                  both groups. Third, the 3-dimensional morphology of the hips immediately preoperatively and
                  postoperatively was not evaluated; only anteroposterior radiographs were obtained in the age group in
                  which surgery was performed. At the time of follow-up, the standard evaluation consisted of
                  anteroposterior and false-profile pelvic radiographs, providing a good evaluation of the acetabular
                  morphology. Advanced imaging with computed tomography would have provided better evaluation of both
                  the femoral and the acetabular morphology; however, the retrospective nature of the study precluded
                  this. Fourth, the differences in outcome in this study primarily involve the radiographic findings and
                  not patient-reported outcomes. In future studies, long-term outcome with respect to pain, function,
                  and the onset of arthritic changes would be ideal. Finally, race and ethnicity could not be evaluated
                  because they were not identified in the medical record, which may limit the applicability of this
                  study to the general population. Nevertheless, we believe that the differences in radiographic
                  outcomes involving the acetabular morphology provide the most useful information to date for selecting
                  between these 2 procedures when planning surgery for these patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">For patients with residual childhood hip dysplasia, the
                  Salter innominate osteotomy results in better acetabular inclination, AIn, compared with the Pemberton
                  osteotomy, without a difference in Tönnis grade, at mid-term follow-up. The Salter procedure, however,
                  almost always requires pin removal as an additional procedure, whereas that is not typically necessary
                  with the Pemberton procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank Drs. Harry Kim, Tony Herring, and William
                  Morris for their review of the manuscript prior to submission.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Faciszewski T</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Coleman SS</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Biddulph G</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Triple innominate osteotomy for
                  acetabular dysplasia</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">J Pediatr Orthop.</span> 1993 Jul-Aug;13(4):426-30.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Triple%20innominate%20osteotomy%20for%20acetabular%20dysplasia&as_occt=title&as_sauthors=%20%22T%20Faciszewski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Gillingham BL</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Sanchez AA</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Wenger DR</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Pelvic osteotomies for the
                  treatment of hip dysplasia in children and young adults</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 1999
                Sep-Oct;7(5):325-37.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20osteotomies%20for%20the%20treatment%20of%20hip%20dysplasia%20in%20children%20and%20young%20adults&as_occt=title&as_sauthors=%20%22BL%20Gillingham%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Wada A</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Fujii T</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Takamura K</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Yanagida H</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Taketa M</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Nakamura T</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Pemberton osteotomy for
                  developmental dysplasia of the hip in older children</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 2003
                Jul-Aug;23(4):508-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pemberton%20osteotomy%20for%20developmental%20dysplasia%20of%20the%20hip%20in%20older%20children&as_occt=title&as_sauthors=%20%22A%20Wada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Robb CA</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Datta A</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Nayeemuddin M</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Bache CE</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Assessment of acetabular
                  retroversion following long term review of Salter’s osteotomy</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Hip Int.</span> 2009
                Jan-Mar;19(1):8-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessment%20of%20acetabular%20retroversion%20following%20long%20term%20review%20of%20Salter%E2%80%99s%20osteotomy&as_occt=title&as_sauthors=%20%22CA%20Robb%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Böhm P</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Brzuske A</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Salter innominate osteotomy for
                  the treatment of developmental dysplasia of the hip in children: results of seventy-three consecutive
                  osteotomies after twenty-six to thirty-five years of follow-up</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2002
                Feb;84(2):178-86.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=796025"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Tukenmez M</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Tezeren G</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Salter innominate osteotomy for
                  treatment of developmental dysplasia of the hip</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">J Orthop Surg (Hong Kong).</span> 2007
                Dec;15(3):286-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Salter%20innominate%20osteotomy%20for%20treatment%20of%20developmental%20dysplasia%20of%20the%20hip&as_occt=title&as_sauthors=%20%22M%20Tukenmez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Salter RB</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Innominate osteotomy in the
                  treatment of congenital dislocation and subluxation of the hip</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span>
                1961;43:518-39.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Innominate%20osteotomy%20in%20the%20treatment%20of%20congenital%20dislocation%20and%20subluxation%20of%20the%20hip&as_occt=title&as_sauthors=%20%22RB%20Salter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Pemberton PA</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Pericapsular Osteotomy of the
                  Ilium for Treatment of Congenital Subluxation and Dislocation of the Hip</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                1965 Jan;47:65-86.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=299461"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Novais EN</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Pan Z</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Autruong PT</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Meyers ML</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Chang FM</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Normal Percentile Reference
                  Curves and Correlation of Acetabular Index and Acetabular Depth Ratio in Children</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span>
                2018 Mar;38(3):163-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Normal%20Percentile%20Reference%20Curves%20and%20Correlation%20of%20Acetabular%20Index%20and%20Acetabular%20Depth%20Ratio%20in%20Children&as_occt=title&as_sauthors=%20%22EN%20Novais%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Carlisle JC</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Bealé PE</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Kim Y-J</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Sierra RJ</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Leunig M</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Schoenecker PL</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Millis MB</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">A systematic approach to the
                  plain radiographic evaluation of the young adult hip</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2008 Nov;90 Suppl
                4(Suppl 4):47-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20approach%20to%20the%20plain%20radiographic%20evaluation%20of%20the%20young%20adult%20hip&as_occt=title&as_sauthors=%20%22JC%20Clohisy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Wiberg G</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Studies on dysplastic acetabula
                  and congenital subluxation of the hip joint: with special reference to the complication of
                  osteoarthritis</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Acta Chir Scand.</span> 1939;83(Suppl 58):5-135.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Studies%20on%20dysplastic%20acetabula%20and%20congenital%20subluxation%20of%20the%20hip%20joint%3A%20with%20special%20reference%20to%20the%20complication%20of%20osteoarthritis&as_occt=title&as_sauthors=%20%22G%20Wiberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Tannast M</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Hanke MS</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Zheng G</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Steppacher SD</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Siebenrock KA</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">What are the radiographic
                  reference values for acetabular under- and overcoverage?</span><span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2015
                Apr;473(4):1234-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20are%20the%20radiographic%20reference%20values%20for%20acetabular%20under-%20and%20overcoverage%3F&as_occt=title&as_sauthors=%20%22M%20Tannast%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Bucholz RW</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Odgen JA</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Patterns of ischemic necrosis
                  of the proximal femur in nonoperatively treated congenital hip disease</span>. In: <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">The Hip: Proceedings of the
                  Sixth Open Scientific Meeting of the Hip Society,</span>, 1978: p 43-63.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patterns%20of%20ischemic%20necrosis%20of%20the%20proximal%20femur%20in%20nonoperatively%20treated%20congenital%20hip%20disease&as_occt=title&as_sauthors=%20%22RW%20Bucholz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Pemberton PA</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Pericapsular osteotomy of the
                  ilium for the treatment of congenitally dislocated hips</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 1974
                Jan-Feb;(98):41-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pericapsular%20osteotomy%20of%20the%20ilium%20for%20the%20treatment%20of%20congenitally%20dislocated%20hips&as_occt=title&as_sauthors=%20%22PA%20Pemberton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Faciszewski T</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Kiefer GN</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Coleman SS</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Pemberton osteotomy for
                  residual acetabular dysplasia in children who have congenital dislocation of the hip</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 1993 May;75(5):643-9.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=575648" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Gordon JE</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Capelli AM</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Strecker WB</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Delgado ED</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Schoenecker PL</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Pemberton pelvic osteotomy and
                  varus rotational osteotomy in the treatment of acetabular dysplasia in patients who have static
                  encephalopathy</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1996 Dec;78(12):1863-71.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=76697" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Szepesi K</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Rigó J</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Bíró B</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Fazekas K</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Póti L</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Pemberton’s pericapsular
                  osteotomy for the treatment of acetabular dysplasia</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop B. 1996
                  Fall</span>;5(4):252-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pemberton%E2%80%99s%20pericapsular%20osteotomy%20for%20the%20treatment%20of%20acetabular%20dysplasia&as_occt=title&as_sauthors=%20%22K%20Szepesi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Vedantam R</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Capelli AM</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Schoenecker PL</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Pemberton osteotomy for the
                  treatment of developmental dysplasia of the hip in older children</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 1998
                Mar-Apr;18(2):254-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pemberton%20osteotomy%20for%20the%20treatment%20of%20developmental%20dysplasia%20of%20the%20hip%20in%20older%20children&as_occt=title&as_sauthors=%20%22R%20Vedantam%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Salter RB</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Role of innominate osteotomy in
                  the treatment of congenital dislocation and subluxation of the hip in the older child</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 1966 Oct;48(7):1413-39.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=322660" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Salter RB</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Dubos JP</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The first fifteen year’s
                  personal experience with innominate osteotomy in the treatment of congenital dislocation and
                  subluxation of the hip</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 1974 Jan-Feb;(98):72-103.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20first%20fifteen%20year%E2%80%99s%20personal%20experience%20with%20innominate%20osteotomy%20in%20the%20treatment%20of%20congenital%20dislocation%20and%20subluxation%20of%20the%20hip&as_occt=title&as_sauthors=%20%22RB%20Salter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Eren A</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Pekmezci M</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Demirkiran G</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Cakar M</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Guven M</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Yazici M</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Modified Salter osteotomy for
                  the treatment of developmental dysplasia of the hip: description of a new technique that eliminated
                  the use of pins for internal fixation</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 2007 Oct;89(10):1375-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modified%20Salter%20osteotomy%20for%20the%20treatment%20of%20developmental%20dysplasia%20of%20the%20hip%3A%20description%20of%20a%20new%20technique%20that%20eliminated%20the%20use%20of%20pins%20for%20internal%20fixation&as_occt=title&as_sauthors=%20%22A%20Eren%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Sawamura K</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Kitoh H</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Kaneko H</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Kitamura A</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Hattori T</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Appropriate Surgical Timing of
                  Salter Innominate Osteotomy for Residual Acetabular Dysplasia in Children</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span>
                2022 Nov-Dec 01;42(10):e971-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Appropriate%20Surgical%20Timing%20of%20Salter%20Innominate%20Osteotomy%20for%20Residual%20Acetabular%20Dysplasia%20in%20Children&as_occt=title&as_sauthors=%20%22K%20Sawamura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Vengust R</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Antolic V</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Srakar F</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Salter osteotomy for treatment
                  of acetabular dysplasia in developmental dysplasia of the hip in patients under 10 years</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Pediatr Orthop B.</span>
                2001 Jan;10(1):30-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Salter%20osteotomy%20for%20treatment%20of%20acetabular%20dysplasia%20in%20developmental%20dysplasia%20of%20the%20hip%20in%20patients%20under%2010%20years&as_occt=title&as_sauthors=%20%22R%20Vengust%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Bucholz RW</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Ezaki M</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Ogden JA</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Injury to the acetabular
                  triradiate physeal cartilage</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1982 Apr;64(4):600-9.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=494545" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Pfeifer R</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Hurschler C</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Ostermeier S</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Windhagen H</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Pressel T</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">In vitro investigation of
                  biomechanical changes of the hip after Salter pelvic osteotomy</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Clin Biomech (Bristol, Avon).</span> 2008
                Mar;23(3):299-304.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=In%20vitro%20investigation%20of%20biomechanical%20changes%20of%20the%20hip%20after%20Salter%20pelvic%20osteotomy&as_occt=title&as_sauthors=%20%22R%20Pfeifer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Wang CW</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Wu KW</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Wang TM</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Huang SC</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Kuo KN</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Comparison of acetabular
                  anterior coverage after Salter osteotomy and Pemberton acetabuloplasty: a long-term followup</span>.
                <span data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2014 Mar;472(3):1001-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20acetabular%20anterior%20coverage%20after%20Salter%20osteotomy%20and%20Pemberton%20acetabuloplasty%3A%20a%20long-term%20followup&as_occt=title&as_sauthors=%20%22CW%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Bhatti A</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Abbasi I</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Naeem Z</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Jaffri K</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Bhatti MY</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">A Comparative Study of Salter
                  Versus Pemberton Osteotomy in Open Reduction of Developmental Dysplastic Hips and Clinical Evaluation
                  on Bhatti’s Functional Score System</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Cureus.</span> 2021 Jan 11;13(1):e12626.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Comparative%20Study%20of%20Salter%20Versus%20Pemberton%20Osteotomy%20in%20Open%20Reduction%20of%20Developmental%20Dysplastic%20Hips%20and%20Clinical%20Evaluation%20on%20Bhatti%E2%80%99s%20Functional%20Score%20System&as_occt=title&as_sauthors=%20%22A%20Bhatti%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Ertürk C</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Altay MA</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Işikan UE</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">A radiological comparison of
                  Salter and Pemberton osteotomies to improve acetabular deformations in developmental dysplasia of the
                  hip</span>. <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">J Pediatr
                  Orthop B.</span> 2013 Nov;22(6):527-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20radiological%20comparison%20of%20Salter%20and%20Pemberton%20osteotomies%20to%20improve%20acetabular%20deformations%20in%20developmental%20dysplasia%20of%20the%20hip&as_occt=title&as_sauthors=%20%22C%20Ert%C3%BCrk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Wu KW</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Wang TM</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Huang SC</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Kuo KN</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Chen CW</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Analysis of osteonecrosis
                  following Pemberton acetabuloplasty in developmental dysplasia of the hip: long-term results</span>.
                <span data-id="emphasis_29" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2010 Sep 1;92(11):2083-94.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1099175" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D790e740a-6bf9-4d84-95c4-fbb99b62ed1b%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): 46</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01346</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 7, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_30"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Scottish
                            Rite for Children, Dallas, Texas</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;790e740a-6bf9-4d84-95c4-fbb99b62ed1b&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=790e740a-6bf9-4d84-95c4-fbb99b62ed1b&type=pdf&name=JBJS.23.01346.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=790e740a-6bf9-4d84-95c4-fbb99b62ed1b&type=pdf&name=JBJS.23.01346.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_35"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_36" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I268"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I268</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;790e740a-6bf9-4d84-95c4-fbb99b62ed1b&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=790e740a-6bf9-4d84-95c4-fbb99b62ed1b&type=zip&name=JBJS.23.01346.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daniel J. Sucato, MD, MS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Dan.sucato@tsrh.org" class="" style="position: unset;">Dan.sucato@tsrh.org</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3352-5551" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3352-5551</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Case E. Brabham, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3393-4857" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3393-4857</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Adriana De La Rocha, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">David A. Podeszwa, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2367-2657" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2367-2657</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lori A. Karol, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Scottish Rite for Children, Dallas, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">University of Texas Southwestern Medical Center, Dallas, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Harvard Medical School, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 26563.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
