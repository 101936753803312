import type { Article } from "../../../../../types";

export const Article570: Article = {
	id: 570,
	rsuiteId: "2d621903-4161-4e2b-8259-519aa92827d2",
	type: "scientific article",
	title:
		"Demographics, Indications, and Revision Rates for Radial Head Arthroplasty",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2d621903-4161-4e2b-8259-519aa92827d2&type=jpeg&name=JBJS.24.00072f1",
	subSpecialties: ["hand_wrist"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Demographics, Indications, and Revision Rates for Radial
                Head Arthroplasty</div>
              <div class="text subtitle" style="position: unset;">Analysis of Data from the Australian Orthopaedic
                Association National Joint Replacement Registry</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Narinder
                      Kumar, MBBS, MSurg(Ortho)</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Belinda J.
                      Gabbe, BPhysio(Hons), MAppSc, MBiostat, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Background:</span> Population-level data from national arthroplasty
                        registries enable the use and outcomes of arthroplasty procedures to be monitored over time.
                        This study aimed to describe the demographics, indications, and outcomes (up to 15 years) for
                        radial head arthroplasty (RHA) procedures in Australia, as well as the factors associated with
                        an increased likelihood of revision.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Methods:</span> Individual-level deidentified data on demographics
                        and surgery characteristics, including revision surgery, were obtained from the Australian
                        Orthopaedic Association National Joint Replacement Registry for all primary isolated RHA
                        procedures that had been performed from 2007 to 2021. The probability of prosthesis survival was
                        determined using Kaplan-Meier estimates. Cox proportional hazards models were used to calculate
                        hazard ratios (HRs) for revision.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Results:</span> A total of 3,457 primary RHAs were performed during
                        the study period (mean follow-up, 5.6 years; range, 0 to 15 years). The mean age at the time of
                        RHA was 53 years, with female predominance (n = 2,009, 58%). The most common indication for RHA
                        was fracture or dislocation (n = 3,166, 92%), followed by osteoarthritis (n = 232, 7%). The
                        radial head prostheses that were most commonly used were monopolar (n = 3,378, 98%) with a
                        modular design (n = 3,442, 99.6%) and cementless fixation (n = 3,387, 98%). Both metallic and
                        nonmetallic prostheses were used (52% and 48%, respectively). Of the total cohort, 160 revisions
                        (5%) were performed during the 15-year period; most revisions occurred in the first 5 years. The
                        most common revision indication was prosthesis loosening (n = 62, 39%), and 54% of the revisions
                        (n = 87) involved a change of the radial component. In multivariable analysis, primary
                        procedures for osteoarthritis (HR, 1.65; 95% confidence interval [CI], 1.01 to 2.70) or “other”
                        indications (e.g., inflammatory arthritis, osteonecrosis, or tumor) were revised more frequently
                        (HR, 3.68; 95% CI, 1.14 to 11.91) than procedures for fracture or dislocation. Procedures with
                        nonmetallic prostheses had higher rates of revision (HR, 1.61; 95% CI, 1.17 to 2.22) than those
                        with metallic prostheses.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Trauma remained the most prevalent indication for
                        RHA in Australia from 2007 to 2021. Encouragingly, revision rates were low, most notably for RHA
                        that had been performed for fracture or dislocation. The likelihood of revision was highest for
                        RHA procedures performed for osteoarthritis and for an indication other than osteoarthritis or
                        trauma. Nonmetallic prostheses had a higher rate of revision than metallic prostheses.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">The predominant indication for radial head arthroplasty
                  (RHA) is the reconstruction of a radial head fracture when internal fixation is not possible<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. RHA is also commonly performed to manage the sequelae of
                  radial head fractures<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a>; less-frequent indications include osteoarthritis,
                  instability, and inflammatory arthritis. Our current understanding of the use of RHA and its outcomes
                  is limited as the procedure is performed and reported relatively infrequently (e.g., only 279
                  procedures were performed in Australia in 2021<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a>) in
                  comparison with primary total knee or hip arthroplasty (58,928 and 41,424 procedures, respectively,
                  were performed in Australia in 2021<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>).
                  Only small patient groups (commonly from single institutions) have been studied to date: the largest
                  published studies include a clinical series of 119 cases from the United Kingdom<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">5</span></a>, a radiographic series of 258 cases from the United States<a
                    href="" data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">6</span></a>, and a retrospective analysis of 970 cases from an
                  integrated health-care and insurance database in the U.S.<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a>. Most
                  prior studies have reported only short-term outcomes after RHA consisting of early radiographic
                  complications<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">6</span></a> and
                  revision surgery<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">7</span></a>.
                  Consequently, longer-term outcomes and relationships between patient or surgical factors and the risk
                  of revision remain largely unknown<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_10" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">10</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The examination of RHA outcomes based on large
                  representative national cohorts would reduce selection bias and increase the generalizability of the
                  findings. However, international arthroplasty registries that routinely collect RHA data (e.g.,
                  Australia, Norway, The Netherlands, Sweden, and the United Kingdom) typically include only limited
                  demographic data and overall revision outcomes, without any subgroup analysis, in their annual
                  reports<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">11</span></a>.
                  One previous study, which used a national registry database in The Netherlands, reported the
                  demographics and short- to mid-term outcomes (up to 5 years postoperatively) of 258 RHA procedures<a
                    href="" data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">12</span></a>. The Australian Orthopaedic Association National Joint
                  Replacement Registry (AOANJRR) systematically collects data on all joint replacement procedures that
                  are performed in Australia<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">4</span></a>.
                  These comprehensive data represent an important resource for examining the use and outcomes of RHA at
                  a national population level over an extended period of time. Our study aimed to describe the
                  demographics, indications, and outcomes (up to 15 years postoperatively) for RHA procedures in
                  Australia. It also aimed to explore patient, clinical, and surgical factors that are associated with
                  an increased likelihood of revision.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">An analysis of prospectively collected national
                  arthroplasty registry data, which was reported according to the RECORD (REporting of studies Conducted
                  using Observational Routinely-collected health Data) checklist, was performed<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">13</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Ethical approval was obtained from the Monash University
                  Human Research Ethics Committee (#32865). A data request was approved by the AOANJRR. Individual
                  consent was not required for this study as only deidentified data were used.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Sources</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The study used individual-level deidentified data on all
                  primary isolated RHA procedures that were performed in Australia between January 1, 2007, and December
                  31, 2021. The AOANJRR started collecting national data from all Australian hospitals that performed
                  RHA procedures in 2007, with established procedures for ensuring high-quality data<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">4</span></a>. Registry data are validated monthly against recorded
                  procedure data from Australian health departments using a sequential multilevel matching process<a
                    href="" data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">4</span></a>. Statisticians at the AOANJRR had full access to
                  individual-level registry data and oversaw the data extraction procedures for this study. Available
                  AOANJRR data included patient characteristics (age, gender, postal code, and American Society of
                  Anesthesiologists [ASA] score) and primary RHA characteristics (date of surgery, side of surgery,
                  indication for surgery, implant design, fixation technique, materials used, and modularity). Race and
                  ethnicity data are not collected by the AOANJRR. Data on revision surgery characteristics (date of
                  revision, side of revision, indication for revision, and type of revision procedure), when performed,
                  and the date of death (for right-censoring, obtained through regular linkage with the National Death
                  Index), where relevant, were also sourced from the AOANJRR. The AOANJRR defines revision surgery as
                  any reoperation requiring the replacement or removal of any prosthetic component or the addition of
                  another component<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">4</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">All analyses were performed using SPSS (version 29; IBM
                  Statistics) and Stata (version 18; StataCorp). Because this study focused on procedures that were
                  performed on adults, we excluded 8 primary RHA procedures that were performed on patients who were
                  &lt;18 years of age from the analysis. Socioeconomic status was approximated using postal codes to
                  link to the Australian Socio-Economic Indexes for Areas (SEIFA) 2021 Index of Relative Socio-economic
                  Advantage and Disadvantage<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">14</span></a>.
                  The first SEIFA decile represents geographical areas with the greatest socioeconomic disadvantage,
                  while the tenth decile represents areas with the greatest advantage. Data on patient, RHA, and
                  revision surgery characteristics were analyzed descriptively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The probability of prosthesis survival (expressed as a
                  function of time to revision surgery) was determined for the overall cohort using Kaplan-Meier
                  estimates, which were based on the date of primary RHA and the date of revision surgery. For patients
                  who did not undergo revision surgery, the analysis was censored at the end of the data collection
                  period (December 31, 2021) or the date of death. Stratified analyses were undertaken by the indication
                  for the primary RHA and by the prosthesis material. The cumulative percent revision was calculated
                  using unadjusted point-wise Greenwood estimates with 95% confidence intervals (CIs). Cox proportional
                  hazards models were used to calculate hazard ratios (HRs) and 95% CIs for revision by ASA score,
                  indication for the primary RHA, prosthesis polarity, prosthesis material, and fixation technique.
                  Rates of revision were not examined according to the side of surgery as this was considered unlikely
                  to be associated with revision. Additionally, prosthesis modularity was not examined in these models
                  as &gt;99% of the cohort received a modular prosthesis. Unadjusted univariable analyses were
                  undertaken, followed by a single multivariable analysis in which the model included the indication for
                  the primary RHA as well as its characteristics (i.e., prosthesis polarity, material, and fixation
                  method), with adjustment for age and sex. The assumption of proportional hazards was checked
                  analytically using Schoenfeld residuals; no violation of this assumption was evident. For all of the
                  analyses, a p value of &lt;0.05 was considered to be significant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Characteristics of the RHA Cohort</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">A total of 3,457 primary RHA procedures were performed
                  during the study period, with a maximum follow-up time of 15 years (mean follow-up, 5.6 years;
                  standard deviation [SD], 3.9 years; range, 0 to 15 years). An overview of patient characteristics and
                  primary RHA characteristics is provided in <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>. The
                  mean age at the time of surgery was 53 years (SD, 15 years; range, 18 to 102 years), with a female
                  predominance (n = 2,009, 58%). Fifty percent of procedures for which ASA data were available were
                  performed for patients with mild systemic disease (ASA grade 2; n = 1,223, 50%). The most common
                  indication for RHA was a fracture or dislocation of the radial head (n = 3,166, 92%), followed by
                  osteoarthritis (n = 232, 7%). Radial head prostheses were most commonly monopolar (n = 3,378, 98%)
                  with a modular design (n = 3,442, 99.6%) and cementless fixation (n = 3,387, 98%). Fracture and
                  dislocation remained the most prevalent indications for surgery from 2007 to 2021 (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1</a>
                  illustrates annual trends in RHA by sex. From 2009 onward, RHA was more frequently performed in
                  females (apart from 2015, when similar numbers were observed for females and males). <a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 2</a> shows the annual trends in RHA by age; surgery was most
                  commonly performed in people aged 50 to 69 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Characteristics of RHA in Australia from 2007 to 2021
                          (N = 3,457)<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2393px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristic</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No.
                          (%)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age in yr at time of
                          surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 18-29</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">280
                          (8.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 30-49</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1040
                          (30.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 50-69</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1634
                          (47.3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥70</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">503
                          (14.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,448
                          (41.9)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,009
                          (58.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Socioeconomic status<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 (most
                          disadvantaged)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">198
                          (5.8)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">289
                          (8.4)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">233
                          (6.8)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">312
                          (9.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">312
                          (9.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">333
                          (9.7)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">319
                          (9.3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">416
                          (12.2)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">456
                          (13.3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 10 (least
                          disadvantaged)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">554
                          (16.2)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA grade</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 (normal healthy
                          patient)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">626
                          (25.7)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2 (mild systemic
                          disease)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,223
                          (50.2)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3 (severe systemic
                          disease)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">562
                          (23.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4 or 5 (severe systemic
                          disease that is a threat to life/moribund patient)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (1.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Indication for
                          surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fracture or
                          dislocation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,166
                          (91.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteoarthritis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">232
                          (6.7)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Instability</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (1.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (0.7)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Side of surgery</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,901
                          (55.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,556
                          (45.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Prosthesis
                          polarity</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Monopolar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,378
                          (97.7)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bipolar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79
                          (2.3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Prosthesis
                          material</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Metallic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,804
                          (52.2)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonmetallic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,650
                          (47.7)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unknown</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (0.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Prosthesis
                          modularity</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Modular</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,442
                          (99.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonmodular</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (0.4)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Prosthesis
                          fixation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cementless</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,387
                          (98.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cemented</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70
                          (2.0)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The numbers may not total 3,457 due to missing data; the percentages
                        may exceed 100% due to rounding. ASA = American Society of Anesthesiologists.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Based on the SEIFA 2021 Index of Relative Socio-economic Advantage and
                        Disadvantage deciles.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Includes inflammatory arthritis, osteonecrosis, and tumor.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Annual Trends in RHA by Indication</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 658.59px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.872679);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" colspan="6" style="position: unset;"><span class="text"
                          style="position: unset;">Indication for RHA (no. [%])</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Year</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Fracture
                          or Dislocation</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Instability</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Osteoarthritis</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Other</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Total</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2007</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (96)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2008</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">118
                          (83)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (13)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">143</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2009</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">153
                          (92)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">167</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2010</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">164
                          (92)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">178</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2011</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">156
                          (91)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">171</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2012</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">185
                          (92)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">201</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2013</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">174
                          (90)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">194</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2014</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">238
                          (91)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">261</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2015</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">235
                          (91)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">258</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2016</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">245
                          (93)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">264</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2017</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">299
                          (91)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">327</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2018</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">291
                          (90)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">323</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2019</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">316
                          (93)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">340</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2020</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">304
                          (93)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">327</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2021</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">264
                          (95)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">278</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,166
                          (92)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">232
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (&lt;1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,457</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00072f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2d621903-4161-4e2b-8259-519aa92827d2&type=jpeg&name=JBJS.24.00072f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;">Annual trends in RHA by sex.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00072f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2d621903-4161-4e2b-8259-519aa92827d2&type=jpeg&name=JBJS.24.00072f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;">Annual trends in RHA by age.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Characteristics of Revision Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Of the total cohort, 160 RHA procedures (5%) were
                  subsequently revised during the 15-year study period. The characteristics of the revision procedures
                  are shown in <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>. The
                  most common indication for revision surgery was loosening of the prosthesis (n = 62, 39%), and just
                  over half of the revisions involved a change of the radial component (n = 87, 54%).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Characteristics of Revision Surgery (N = 160)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 836px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristic</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No.
                          (%)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81
                          (51)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79
                          (49)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Indication for primary
                          surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fracture or
                          dislocation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">135
                          (84)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteoarthritis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (11)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Instability</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (2)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Indication for
                          revision</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Loosening</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62
                          (39)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Instability</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (11)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Incorrect sizing</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (9)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (8)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45
                          (28)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Type of revision
                          surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Change of radial
                          component</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87
                          (54)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Insertion of
                          humeral/ulnar components</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (17)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Removal of
                          prostheses</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (16)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (13)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Includes osteonecrosis, rheumatoid arthritis, and “other” (e.g., tumor)
                        as reported to the AOANJRR.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Includes arthrofibrosis, lysis, malposition, metal-related pathology,
                        pain, progression of disease, and prosthesis dislocation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Includes unicompartmental humeral/radial replacement, reinsertion of
                        components, radial neck only, revision of radial head only, minor components, and cement/cement
                        spacer.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Time to
                Revision Surgery and Factors Associated with Revision Rates</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Most revisions occurred within the first 5 years after the
                  primary RHA (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>). The
                  cumulative percent revision was 2.9% (95% CI, 2.3% to 3.5%) at 1 year, 5.2% (95% CI, 4.4% to 6.0%) at
                  5 years, and 5.4% (95% CI, 4.6% to 6.4%) at 10 years. The rate of revision varied depending on the
                  indication for surgery (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00072f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2d621903-4161-4e2b-8259-519aa92827d2&type=jpeg&name=JBJS.24.00072f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier estimates for time to revision for
                          the overall cohort and a risk table. The shaded areas represent the 95% CIs. The initial
                          number at risk was 3,456 as 1 primary RHA procedure was performed on the censoring date.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00072f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2d621903-4161-4e2b-8259-519aa92827d2&type=jpeg&name=JBJS.24.00072f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier estimates for time to revision by
                          primary diagnosis. The shaded areas represent the 95% CIs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Univariable models showed that procedures performed for
                  osteoarthritis (HR, 1.81; 95% CI, 1.11 to 2.96) or “other” indications, including inflammatory
                  arthritis, osteonecrosis, and tumor, were revised more frequently (HR, 3.17; 95% CI, 1.01 to 9.94)
                  than procedures performed for fractures or dislocations. The rate of revision also differed according
                  to the materials used (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>). On
                  univariable analysis, radial head prostheses with nonmetallic materials had a higher rate of revision
                  (HR, 1.73; 95% CI, 1.26 to 2.37) compared with prostheses with metallic materials. As shown in <a
                    href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>, rates of revision did not differ according to prosthesis
                  polarity (monopolar versus bipolar) or fixation (cementless versus cemented), although the number of
                  revisions in the bipolar and cemented groups was small (n = 2 and n = 3, respectively). Because
                  revision rates were not associated with the ASA grade on univariable analysis, this variable was not
                  included in the multivariable Cox proportional hazards model. After controlling for age and sex, the
                  indication for RHA and prosthesis material remained significantly associated with revision rates (<a
                    href="" data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00072f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2d621903-4161-4e2b-8259-519aa92827d2&type=jpeg&name=JBJS.24.00072f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier estimates for time to revision by
                          prosthesis material. The shaded areas represent the 95% CIs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Cox Proportional Hazards Analysis of Time to
                          Revision Surgery<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1263px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Variable</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Hazard Ratio (95% CI)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Univariable Analysis</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Multivariable Analysis<span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA grade</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 (normal healthy
                          patient)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (reference)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Not
                          applicable</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2 (mild systemic
                          disease)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.94 (0.61
                          to 1.45)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Not
                          applicable</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3 (severe systemic
                          disease)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.11 (0.68
                          to 1.83)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Not
                          applicable</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4 or 5 (severe systemic
                          disease that is a threat to life/ moribund patient)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.83 (0.11
                          to 6.08)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Not
                          applicable</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Indication for primary
                          surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fracture or
                          dislocation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (reference)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (reference)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteoarthritis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_36" class="annotation strong" style="position: unset;">1.81 (1.11 to
                            2.96)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_37" class="annotation strong" style="position: unset;">1.65 (1.01 to
                            2.70)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Instability</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.59 (0.96
                          to 7.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.47 (0.91
                          to 6.69)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_38" class="annotation strong" style="position: unset;">3.17 (1.01 to
                            9.94)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_39" class="annotation strong" style="position: unset;">3.68 (1.14 to
                            11.91)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Prosthesis
                          polarity</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Monopolar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (reference)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (reference)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bipolar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.49 (0.12
                          to 1.97)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.49 (0.11
                          to 2.12)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Prosthesis
                          material</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Metallic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (reference)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (reference)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonmetallic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_40" class="annotation strong" style="position: unset;">1.73 (1.26 to
                            2.37)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_41" class="annotation strong" style="position: unset;">1.61 (1.17 to
                            2.22)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Prosthesis
                          fixation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cementless</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (reference)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (reference)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cemented</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.94 (0.30
                          to 2.96)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01 (0.31
                          to 3.32)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The largest category for each variable was used as the reference group.
                        Statistically significant associations are shown in bold font. ASA = American Society of
                        Anesthesiologists.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Model adjusted for age and sex.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">We believe that this is the largest study to date
                  reporting the demographics, indications, and outcomes of RHA procedures, with 3,457 procedures and a
                  15-year follow-up period. Our national-level data showed that traumatic injury (specifically,
                  fractures and dislocations) remained the most prevalent indication for RHA from 2007 to 2021 and that
                  surgery was commonly performed for people aged 50 to 69 years, with a predominance in women.
                  Encouragingly, implant survival was high; only 5% of procedures were revised during the 15-year
                  period. Most revisions occurred within the first 5 years, and loosening was the most common reason for
                  revision among our cohort. Importantly, we examined a range of patient and prosthesis factors in
                  relation to revision outcomes and identified that procedures performed for fracture or dislocation had
                  the lowest rates of revision.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Data from national arthroplasty registries represent an
                  important resource for examining the use and outcomes of surgical procedures over long surveillance
                  periods at a national population level. To our knowledge, only 1 other study has examined the use and
                  outcomes of RHA using national registry data. The previous study, from the Dutch National Register,
                  included 258 patients with a relatively short follow-up period (mean, 2.2 years). Those authors
                  reported a relatively high implant survival rate of 90% after 5 years<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">12</span></a>. The most common indication for primary RHA in The
                  Netherlands was acute radial head fracture (69%), followed by sequelae of trauma (25%)<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">12</span></a>. In comparison, traumatic injury (comprising fracture and
                  dislocation) is categorized as a single category in the AOANJRR; in Australia, this indication also
                  accounted for the majority (92%) of RHA procedures. Annual reports from other international
                  arthroplasty registries also highlight the prominence of traumatic injury as an indication for primary
                  RHA. In Norway, acute fracture was the most common indication (78%), followed by sequelae of trauma
                  (13%)<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">15</span></a>.
                  The National Joint Registry in the United Kingdom also reported that fractures were the most frequent
                  indication for RHA (83%), followed by sequelae of trauma (12%)<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">16</span></a>. The Swedish Arthroplasty Register does not report data on
                  indications for RHA in its annual report<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">17</span></a>.
                  With regard to prosthesis polarity, 98% of the implants in the AOANJRR were monopolar, which is
                  similar to the proportion in the U.K. (97%)<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">16</span></a> but
                  higher than in The Netherlands, where 68% of implants are bipolar in design<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">12</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Our revision data can be considered in the context of data
                  from other countries. The cumulative percent revision in our study was 2.9% at 1 year, increasing to
                  5.2% at 5 years and to 5.4% at 10 years. The U.K. National Joint Registry reports separate cumulative
                  percent revision estimates following RHA for acute trauma cases and for elective cases. For acute
                  trauma, the cumulative percent revision has been reported as 0.70% at 1 year, 1.36% at 5 years, and
                  1.76% at 9 years<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">16</span></a>.
                  For elective cases, it has been reported as 2.46% at 1 year, 4.98% at 5 years, and 7.02% at 9 years<a
                    href="" data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">16</span></a>. Other studies have reported implant survival rates rather
                  than cumulative percent revision estimates; small clinical studies from different countries have
                  reported 5-year implant survival rates between 71% and 100%<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">23</span></a>. Additional studies have reported that most revisions occur
                  in the first 3 years following the index surgery<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">24</span></a>, which is broadly consistent with our findings.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">The most common indication for revision in our study was
                  aseptic loosening (39%); this proportion was lower when compared with data from the Dutch Arthroplasty
                  Register (56%)<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">12</span></a> but
                  similar to data reported by the U.K. National Joint Registry (38%)<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">16</span></a>. The most common radial stem implants used in Australia are
                  press-fit or smooth loose-fitting stems<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">25</span></a>.
                  Undersizing of press-fit stems to reduce the risk of periprosthetic fracture of the radial shaft may
                  lead to inadequate primary fixation<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">26</span></a> and
                  late osteolysis<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">8</span></a>.
                  Smooth loose-fitting stems reduce proximal fracture risk but have been associated with longer-term
                  tilting and proximal radial shaft expansion<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">8</span></a>.
                  Aseptic loosening may be reported to the AOANJRR under both circumstances. Other prosthetic features
                  such as pyrocarbon radial heads have theoretical advantages (low coefficient of friction) but risk
                  implant breakage during head impaction<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">10</span></a>.
                  This mode of failure has also been reported for silicone implants<a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">27</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The present study has several key strengths, including the
                  use of data from a long-established arthroplasty registry with national coverage (since November 2007)
                  from all Australian public and private hospitals where arthroplasty procedures are performed. We
                  examined both short-term and longer-term outcomes (up to 15 years), and the relatively large data set
                  allowed multivariable analysis to be undertaken. We also acknowledge that the study had limitations.
                  To maximize data completeness, national arthroplasty registries such as the AOANJRR typically collect
                  a limited data set. While we included several key patient characteristics in our analyses, data on
                  other clinical characteristics (e.g., specific comorbidities and injury severity) that may have
                  influenced surgical outcomes were not available. We also note that information regarding race and
                  ethnicity was not available to further characterize the cohort<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">28</span></a>. This study sought to evaluate the overall outcomes for the
                  RHA cohort rather than brand-specific outcomes. Also, because the AOANJRR does not differentiate
                  between recent trauma and the sequelae of trauma, these indications for RHA could not be examined
                  separately. We recognize that there are other clinical outcomes that are important to consider besides
                  time to revision. However, data on outcomes such as pain, range of motion, function, and
                  health-related quality of life are not collected by the AOANJRR for patients undergoing elbow
                  arthroplasty procedures. Finally, we recognize that there may be underreporting of excision
                  arthroplasty (because this may not always be reported as a revision procedure at some hospitals),
                  although these cases would usually be identified as a revision outcome through routine AOANJRR data
                  validation procedures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The indications for RHA in Australia have remained stable
                  over the last 15 years, with trauma contributing to &gt;90% of all RHA procedures since 2007. Surgical
                  outcomes were excellent, with evidence of low revision rates at 15 years. The most common reason for
                  revision was aseptic loosening. Nontraumatic indications for RHA (osteoarthritis and other
                  indications, including inflammatory arthritis, osteonecrosis, and tumors) were associated with a
                  significantly higher likelihood of revision when compared with traumatic indications (fracture or
                  dislocation). With regard to prosthesis characteristics, nonmetallic materials had a higher rate of
                  revision, while polarity and fixation method were not associated with revision rates.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank the Australian Orthopaedic Association
                  National Joint Replacement Registry for providing the data used for this study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Madsen JE</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Flugsrud G</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Radial head fractures:
                  Indications and technique for primary arthroplasty</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Eur J Trauma Emerg Surg.</span> 2008
                Apr;34(2):105-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radial%20head%20fractures%3A%20Indications%20and%20technique%20for%20primary%20arthroplasty&as_occt=title&as_sauthors=%20%22JE%20Madsen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Thyagarajan DS</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Radial head replacement - A
                  comprehensive review</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Orthop.</span> 2022 Dec 15;36:51-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radial%20head%20replacement%20-%20A%20comprehensive%20review&as_occt=title&as_sauthors=%20%22DS%20Thyagarajan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Ring D</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Radial head fracture: open
                  reduction-internal fixation or prosthetic replacement</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2011
                Mar;20(2)(Suppl):S107-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radial%20head%20fracture%3A%20open%20reduction-internal%20fixation%20or%20prosthetic%20replacement&as_occt=title&as_sauthors=%20%22D%20Ring%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;">Australian
                Orthopaedic Association National Joint Replacement Registry. <span data-id="strong_4"
                  class="annotation strong" style="position: unset;">Hip, Knee and Shoulder Arthroplasty Annual
                  Report</span> 2022. Accessed 2024 Jul 8. <a
                  href="https://aoanjrr.sahmri.com/en-US/annual-reports-2022" target="_blank" data-id="link_1"
                  class="link"
                  style="position: unset;">https://aoanjrr.sahmri.com/en-US/annual-reports-2022</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%2C%20Knee%20and%20Shoulder%20Arthroplasty%20Annual%20Report&as_occt=title&as_sauthors=%20%22Australian%20Orthopaedic%20Association%20National%20Joint%20Replacement%20Registry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Cristofaro CD</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Carter TH</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Wickramasinghe NR</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> McQueen MM</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> White TO</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Duckworth AD</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">High risk of further surgery
                  after radial head replacement for unstable fractures: Longer-term outcomes at a minimum follow-up of 8
                  years</span>. <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2019 Nov;477(11):2531-40.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High%20risk%20of%20further%20surgery%20after%20radial%20head%20replacement%20for%20unstable%20fractures%3A%20Longer-term%20outcomes%20at%20a%20minimum%20follow-up%20of%208%20years&as_occt=title&as_sauthors=%20%22CD%20Cristofaro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Ha AS</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Petscavage JM</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Chew FS</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Radial head arthroplasty: a
                  radiologic outcome study</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">AJR Am J Roentgenol.</span> 2012 Nov;199(5):1078-82.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radial%20head%20arthroplasty%3A%20a%20radiologic%20outcome%20study&as_occt=title&as_sauthors=%20%22AS%20Ha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Foroohar A</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Prentice HA</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Burfeind WE</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Navarro RA</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Mirzayan R</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Zeltser DW</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Radial head arthroplasty: a
                  descriptive study of 970 patients in an integrated health care system</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2022 Jun;31(6):1242-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radial%20head%20arthroplasty%3A%20a%20descriptive%20study%20of%20970%20patients%20in%20an%20integrated%20health%20care%20system&as_occt=title&as_sauthors=%20%22A%20Foroohar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Songy CE</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Kennon JC</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Barlow JD</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Sanchez-Sotelo J</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> O’Driscoll SW</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Morrey ME</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Radial head replacement for
                  acute radial head fractures: Outcome and survival of three implant designs with and without cement
                  fixation</span>. <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J
                  Orthop Trauma.</span> 2021 Jun 1;35(6):e202-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radial%20head%20replacement%20for%20acute%20radial%20head%20fractures%3A%20Outcome%20and%20survival%20of%20three%20implant%20designs%20with%20and%20without%20cement%20fixation&as_occt=title&as_sauthors=%20%22CE%20Songy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Heijink A</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Kodde IF</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Mulder PGH</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Veltman ES</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Kaas L</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> van den Bekerom MPJ</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Eygendaal D</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Radial head arthroplasty: A
                  systematic review</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">JBJS Rev.</span> 2016 Oct 18;4(10):e3.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1295180" target="_new" class=""
                  style="position: unset;">JBJS Reviews</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Page RS</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Paltoglou NG</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Arora V</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Eng K</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Gill SD</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Retrospective review of
                  pyrocarbon radial head replacement</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">JSES Rev Rep Tech.</span> 2021 Sep 3;1(4):376-80.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Retrospective%20review%20of%20pyrocarbon%20radial%20head%20replacement&as_occt=title&as_sauthors=%20%22RS%20Page%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Porter M</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Rolfson O</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> de Steiger R</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">International Registries: U.K.
                  National Joint Registry, Nordic Registries, and Australian Orthopaedic Association National Joint
                  Replacement Registry (AOANJRR)</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2022 Oct 19;104(Suppl 3):23-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=International%20Registries%3A%20U.K.%20National%20Joint%20Registry%2C%20Nordic%20Registries%2C%20and%20Australian%20Orthopaedic%20Association%20National%20Joint%20Replacement%20Registry%20(AOANJRR)&as_occt=title&as_sauthors=%20%22M%20Porter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Macken AA</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Prkić A</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Koenraadt KL</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> van Oost I</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Spekenbrink-Spooren A</span>,
                <span data-id="annotation_46" class="annotation" style="position: unset;"> The B</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Eygendaal D</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">A registry study on radial head
                  arthroplasties in the Netherlands: Indications, types and short-term survival</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Shoulder Elbow.</span> 2022
                Feb;14(1):96-102.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20registry%20study%20on%20radial%20head%20arthroplasties%20in%20the%20Netherlands%3A%20Indications%2C%20types%20and%20short-term%20survival&as_occt=title&as_sauthors=%20%22AA%20Macken%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Benchimol EI</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Smeeth L</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Guttmann A</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Harron K</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Moher D</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Petersen I</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Sørensen HT</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> von Elm E</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Langan SM</span>; RECORD Working
                Committee. <span data-id="strong_13" class="annotation strong" style="position: unset;">The REporting of
                  studies Conducted using Observational Routinely-collected health Data (RECORD) statement</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">PLoS Med.</span> 2015 Oct
                6;12(10):e1001885.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20REporting%20of%20studies%20Conducted%20using%20Observational%20Routinely-collected%20health%20Data%20(RECORD)%20statement&as_occt=title&as_sauthors=%20%22EI%20Benchimol%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;">Australian
                Bureau of Statistics. <span data-id="strong_14" class="annotation strong"
                  style="position: unset;">Socio-Economic Indexes for Areas (SEIFA), Australia</span>. 2023. Accessed
                2023 Aug 9. <a
                  href="https://www.abs.gov.au/statistics/people/people-and-communities/socio-economic-indexes-areas-seifa-australia/latest-release"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://www.abs.gov.au/statistics/people/people-and-communities/socio-economic-indexes-areas-seifa-australia/latest-release</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Socio-Economic%20Indexes%20for%20Areas%20(SEIFA)%2C%20Australia&as_occt=title&as_sauthors=%20%22Australian%20Bureau%20of%20Statistics%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Norwegian National Advisory
                  Unit on Arthroplasty and Hip Fractures</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Norwegian Arthroplasty Register Annual Report.</span> 2023. Accessed 2024 Jul
                8. <a
                  href="https://www.helse-bergen.no/4ad28f/siteassets/seksjon/nasjonal_kompetansetjeneste_leddproteser_hoftebrudd/share-point-documents/rapport/report-2022-english.pdf"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://www.helse-bergen.no/4ad28f/siteassets/seksjon/nasjonal_kompetansetjeneste_leddproteser_hoftebrudd/share-point-documents/rapport/report-2022-english.pdf</a>.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;">National
                Joint Registry. <span data-id="strong_16" class="annotation strong" style="position: unset;">NJR Annual
                  Report</span> 2022. Accessed 2024 Jul 8. <a
                  href="https://www.njrcentre.org.uk/njr-annual-report-2022/" target="_blank" data-id="link_4"
                  class="link"
                  style="position: unset;">https://www.njrcentre.org.uk/njr-annual-report-2022/</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=NJR%20Annual%20Report&as_occt=title&as_sauthors=%20%22National%20Joint%20Registry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;">Swedish
                Arthroplasty Register. <span data-id="strong_17" class="annotation strong"
                  style="position: unset;">Annual report</span> 2022. Accessed 2024 Jul 8. <a
                  href="https://registercentrum.blob.core.windows.net/sar/r/SAR-Annual-Report-2022_EN-HkgQE89Nus.pdf"
                  target="_blank" data-id="link_5" class="link"
                  style="position: unset;">https://registercentrum.blob.core.windows.net/sar/r/SAR-Annual-Report-2022_EN-HkgQE89Nus.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Annual%20report&as_occt=title&as_sauthors=%20%22Swedish%20Arthroplasty%20Register%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Flinkkilä T</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Kaisto T</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Sirniö K</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Hyvönen P</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Leppilahti J</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Short- to mid-term results of
                  metallic press-fit radial head arthroplasty in unstable injuries of the elbow</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 2012 Jun;94(6):805-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Short-%20to%20mid-term%20results%20of%20metallic%20press-fit%20radial%20head%20arthroplasty%20in%20unstable%20injuries%20of%20the%20elbow&as_occt=title&as_sauthors=%20%22T%20Flinkkil%C3%A4%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Allavena C</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Delclaux S</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Bonnevialle N</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Rongières M</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Bonnevialle P</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Mansat P</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Outcomes of bipolar radial head
                  prosthesis to treat complex radial head fractures in 22 patients with a mean follow-up of 50
                  months</span>. <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Orthop
                  Traumatol Surg Res.</span> 2014 Nov;100(7):703-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20bipolar%20radial%20head%20prosthesis%20to%20treat%20complex%20radial%20head%20fractures%20in%2022%20patients%20with%20a%20mean%20follow-up%20of%2050%20months&as_occt=title&as_sauthors=%20%22C%20Allavena%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Dotzis A</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Cochu G</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Mabit C</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Charissoux JL</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Arnaud JP</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Comminuted fractures of the
                  radial head treated by the Judet floating radial head prosthesis</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 2006
                Jun;88(6):760-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comminuted%20fractures%20of%20the%20radial%20head%20treated%20by%20the%20Judet%20floating%20radial%20head%20prosthesis&as_occt=title&as_sauthors=%20%22A%20Dotzis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Laumonerie P</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Reina N</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Ancelin D</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Delclaux S</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Tibbo ME</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Bonnevialle N</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Mansat P</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Mid-term outcomes of 77 modular
                  radial head prostheses</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2017 Sep;99-B(9):1197-203.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mid-term%20outcomes%20of%2077%20modular%20radial%20head%20prostheses&as_occt=title&as_sauthors=%20%22P%20Laumonerie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Kachooei AR</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Baradaran A</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Ebrahimzadeh MH</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> van Dijk CN</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Chen N</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">The rate of radial head
                  prosthesis removal or revision: A systematic review and meta-analysis</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Hand Surg Am.</span> 2018
                Jan;43(1):39-53.e1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20rate%20of%20radial%20head%20prosthesis%20removal%20or%20revision%3A%20A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22AR%20Kachooei%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Sershon RA</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Luchetti TJ</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Cohen MS</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Wysocki RW</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Radial head replacement with a
                  bipolar system: an average 10-year follow-up</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2018
                Feb;27(2):e38-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radial%20head%20replacement%20with%20a%20bipolar%20system%3A%20an%20average%2010-year%20follow-up&as_occt=title&as_sauthors=%20%22RA%20Sershon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Chien HY</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Chen AC</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Huang JW</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Cheng CY</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Hsu KY</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Short- to medium-term outcomes
                  of radial head replacement arthroplasty in posttraumatic unstable elbows: 20 to 70 months
                  follow-up</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Chang Gung Med J.</span> 2010 Nov-Dec;33(6):668-78.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Short-%20to%20medium-term%20outcomes%20of%20radial%20head%20replacement%20arthroplasty%20in%20posttraumatic%20unstable%20elbows%3A%2020%20to%2070%20months%20follow-up&as_occt=title&as_sauthors=%20%22HY%20Chien%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Smith PN</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Gill DR</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> McAuliffe MJ</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> McDougall C</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Stoney JD</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Vertullo CJ</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Wall CJ</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Corfield S</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Page R</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Cuthbert AR</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Du P</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Harries D</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Holder C</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Lorimer MF</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Cashman K</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Lewis PL</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Demographics and Outcomes of
                  Elbow and Wrist Arthroplasty Supplementary Report in Hip</span>, Knee & Shoulder Arthroplasty: 2023
                Annual Report, Australian Orthopaedic Association National Joint Replacement Registry, AOA, Adelaide.
                Accessed 2023 Oct 27. <a href="https://doi.org/10.25310/AUMZ6296" target="_blank" data-id="link_6"
                  class="link" style="position: unset;">https://doi.org/10.25310/AUMZ6296</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Demographics%20and%20Outcomes%20of%20Elbow%20and%20Wrist%20Arthroplasty%20Supplementary%20Report%20in%20Hip&as_occt=title&as_sauthors=%20%22PN%20Smith%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Moon JG</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Berglund LJ</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Domire Z</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> An KN</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> O’Driscoll SW</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Stem diameter and micromotion
                  of press fit radial head prosthesis: a biomechanical study</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2009
                Sep-Oct;18(5):785-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Stem%20diameter%20and%20micromotion%20of%20press%20fit%20radial%20head%20prosthesis%3A%20a%20biomechanical%20study&as_occt=title&as_sauthors=%20%22JG%20Moon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> van Bussel EM</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Lindenhovius AL</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> The B</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Eygendaal D</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Silicone radial head prostheses
                  revisited: do they have a role in today’s practice? A systematic review of literature on clinical
                  outcomes</span>. <span data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Clin
                  Shoulder Elb.</span> 2023 Sep;26(3):312-22.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Silicone%20radial%20head%20prostheses%20revisited%3A%20do%20they%20have%20a%20role%20in%20today%E2%80%99s%20practice%3F%20A%20systematic%20review%20of%20literature%20on%20clinical%20outcomes&as_occt=title&as_sauthors=%20%22EM%20van%20Bussel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Okike K</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Swiontkowski M</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">The Reporting of Race and
                  Ethnicity in The Journal of Bone & Joint Surgery</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2023 Feb
                15;105(4):267-8.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1450030a-d678-46b0-bf99-e1c08b258066" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D2d621903-4161-4e2b-8259-519aa92827d2%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="authorinsights_reference"
            class="annotation authorinsights_reference resource-reference" style="position: unset;"></a>
          <div class="content-node authorinsights" data-id="authorinsights" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Author Insights</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6362922673112&quot;,&quot;type&quot;:&quot;author_insights&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;2d621903-4161-4e2b-8259-519aa92827d2&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6362922673112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6362922673112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6362922673112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6362922673112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/cb74573e-84d7-4b04-9be9-ff731c6b0fe3/f5ea61de-f307-492e-aaed-51dea7b5960a/1280x720/match/image.jpg"
                        src="blob:https://www.jbjs.org/edb121e4-6d1b-4619-af7a-3ba773e9041c">
                        <track kind="metadata" label="segment-metadata">
                        <track kind="subtitles" label="English" srclang="en" default="" id="English">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/cb74573e-84d7-4b04-9be9-ff731c6b0fe3/f5ea61de-f307-492e-aaed-51dea7b5960a/1280x720/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:45</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:45">
                            <div class="vjs-load-progress"><span class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">0%</span></span></div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:45</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden vjs-disabled">
                          <button
                            class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button vjs-disabled"
                            type="button" aria-disabled="true" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false" disabled="disabled"><span class="vjs-icon-placeholder"
                              aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu vjs-hidden">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content" role="menu">
                              <li class="vjs-menu-item" tabindex="-1" role="menuitemradio" aria-disabled="false"
                                aria-checked="false"><span class="vjs-menu-item-text">captions off</span><span
                                  class="vjs-control-text" aria-live="polite"></span></li>
                              <li class="vjs-menu-item vjs-subtitles-menu-item vjs-selected" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">English</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button vjs-disabled"
                          type="button" title="Picture-in-Picture" aria-disabled="true" disabled="disabled"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_705_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_705_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2085</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00072</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 6, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_24"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Monash
                            University, Melbourne, Victoria, Australia</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;2d621903-4161-4e2b-8259-519aa92827d2&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=2d621903-4161-4e2b-8259-519aa92827d2&type=pdf&name=JBJS.24.00072.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=2d621903-4161-4e2b-8259-519aa92827d2&type=pdf&name=JBJS.24.00072.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_34"
                            class="annotation strong" style="position: unset;">Disclosure:</span> Dr. Narinder Kumar is
                          supported by a Research Training Program (RTP) scholarship from the Australian Government.
                          Professor Belinda Gabbe is supported by a National Health and Medical Research Council
                          Investigator Grant (Leadership level 2, ID200998). The funding sources had no role in the
                          study design, collection, analysis and interpretation of data, in the writing of the
                          manuscript, or in the decision to submit the manuscript for publication. The <span
                            data-id="strong_35" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I179" target="_blank" data-id="link_7"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I179</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;2d621903-4161-4e2b-8259-519aa92827d2&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=2d621903-4161-4e2b-8259-519aa92827d2&type=zip&name=JBJS.24.00072.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Narinder Kumar, MBBS, MSurg(Ortho)<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0898-908X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0898-908X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Belinda J. Gabbe, BPhysio(Hons), MAppSc, MBiostat,
                PhD<span data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7096-7688" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7096-7688</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Richard S. Page, BMedSci, MBBS, FRACS, FAOrthA<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2225-7144" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2225-7144</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sze-Ee Soh, BSc(Physio), MBiostat, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1400-7700" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1400-7700</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">David R.J. Gill, MB ChB, FRACS, FAOrthA<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0023-630X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0023-630X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dylan Harries, BSc(Hons), PhD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ilana N. Ackerman, BPhysio(Hons), PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:ilana.ackerman@monash.edu" class=""
                    style="position: unset;">ilana.ackerman@monash.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6028-1612" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6028-1612</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">School of Public Health and Preventive Medicine, Monash University, Melbourne,
                Victoria, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">St John of God Hospital and Barwon Health, Geelong, Victoria, Australia</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Deakin University, Geelong, Victoria, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">School of Primary and Allied Health Care, Monash University, Frankston,
                Victoria, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Australian Orthopaedic Association National Joint Replacement Registry,
                Adelaide, South Australia, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">South Australian Health and Medical Research Institute, Adelaide, South
                Australia, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 29494.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
