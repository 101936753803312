import type { MessageData } from "@memorang/ui";
import type { searchContexts } from "../constants";

export type VectorDocument = {
	metadata: {
		SourceId: number;
		title: string;
		chapter: string;
		subChapter: string;
		cfi: string;
	};
	pageContent: string;
};

export type VectorStoreParams = {
	examId?: string;
	itemId?: string;
	contextId?: string;
	filterSourceIds?: number[];
};

export type EpubParams = {
	chapterCFI?: {
		start: string;
		end: string;
	};
	pageText?: string;
	pageCFI?: string;
	selectedText?: string;
	selectedCFI?: string;
	isSelected?: boolean;
	scope: "global" | "aila";
	isSummarize?: boolean;
	filterSourceIds?: number[];
};

export type SearchContext =
	(typeof searchContexts)[keyof typeof searchContexts];

type ThreadMessage = Omit<MessageData, "streaming" | "loading" | "references">;

export type Thread = {
	id: string;
	message: string;
	messages: ThreadMessage[];
	initialTitle?: string;
};

export type Source = {
	pageContent: string;
	metadata: {
		_id: string;
		path: string;
		level: string;
		type: string;
		levelNumber: number;
		levelValue: string;
		docLevel: string;
		sourceId: number;
		meta: {
			publisher: string;
			author: string;
			title: string;
			embeddingVersion: string;
			embeddingModel: string;
			embeddingDimensions: number;
		};
		parent_doc_id: string;
	};
};
