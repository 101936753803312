/* ----------------- Third parties --------------- */
import { Image } from "@memorang/ui";
import { Button, List, type ListItemProps, Text } from "react-native-paper";

/* ----------------- Constants --------------- */
import { CHALLENGE_IMAGE_HEIGHT, CHALLENGE_IMAGE_WIDTH } from "./constants";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { type IconType, useAppTheme } from "../../index";
import Box from "../Box";

type VariantProp = {
	icon: IconType;
	title: string;
};
type ButtonProps = {
	cta: string;
	icon?: IconType;
};
interface Props extends ListItemProps {
	title: string;
	thumbnail: string;
	description: string;
	variantProp?: VariantProp;
	buttonProps?: ButtonProps;
}

const ChallengeStatusListItem = ({
	title,
	thumbnail,
	description,
	variantProp,
	onPress,
	buttonProps,
	...rest
}: Props) => {
	const theme = useAppTheme();
	const Title = () => {
		return (
			<Box>
				{variantProp && (
					<Box flexDirection="row" gap={8} alignItems="center">
						<Icon name={variantProp.icon} size={16} />
						<Text variant="bodyMedium">{variantProp.title}</Text>
					</Box>
				)}
				<Text numberOfLines={1} variant="titleMedium">
					{title}
				</Text>
			</Box>
		);
	};
	return (
		<List.Item
			title={<Title />}
			onPress={onPress}
			description={description}
			style={{
				paddingLeft: 16,
				paddingRight: 8,
			}}
			right={(p) =>
				buttonProps && (
					<Button
						{...p}
						icon={() => {
							//TODO: Temp solution for storybook, passing icon name directly with button does not work in storybook. revisit
							return (
								<Icon
									name={buttonProps.icon}
									color={theme.colors.primary}
									size={16}
								/>
							);
						}}
					>
						{buttonProps.cta}
					</Button>
				)
			}
			left={(p) => (
				<Image
					{...p}
					source={{ uri: thumbnail }}
					style={{
						width: CHALLENGE_IMAGE_WIDTH,
						height: CHALLENGE_IMAGE_HEIGHT,
						borderRadius: theme.roundness,
					}}
				/>
			)}
			{...rest}
		/>
	);
};

export default ChallengeStatusListItem;
