/* ----------------- Components --------------- */
import Box from "../../Box";
import { StatItem, type StatProps } from "../../StatItem";

type Props = {
	stats: StatProps[];
};
const CardContent = ({ stats }: Props) => (
	<Box
		flexDirection="row"
		flexWrap="wrap"
		gap={24}
		justifyContent="center"
		paddingVertical={16}
	>
		{stats.map((stat) => {
			return <StatItem stacked key={stat.overlineText} {...stat} />;
		})}
	</Box>
);
export default CardContent;
