/* ----------------- React Native Paper Imports --------------- */

/* ----------------- Memorang UI Imports --------------- */
import { Box } from "@memorang/ui";

/* ----------------- Local Imports --------------- */
import { useChallenges } from "../hooks/useChallenges";

/* ----------------- Component Imports --------------- */
import { ScrollContainer } from "@memorang/ui";
import { QuickActionChips } from "components/QuickActionChips";
import { TempAppbar } from "components/TempAppbar";
import { useRouter } from "expo-router";
import ChallengesFolderView from "../components/ChallengesFolderView";

const ChallengesContainer = () => {
	const { quickActionsChips, challengesSectionList } = useChallenges();
	const router = useRouter();

	const handleNavigateToChallengesFolder = (fodlerName: string) => {
		router.push({
			pathname: "/(protected)/challenges/[id]/challenges",
			params: {
				id: decodeURIComponent(fodlerName),
			},
		});
	};

	const handleClickFeaturedChallenge = () => {
		//TODO:implement
	};
	return (
		<>
			<TempAppbar view="challenges" title="Challenges" />

			<ScrollContainer>
				<Box paddingTop={16} paddingBottom={8}>
					<QuickActionChips
						quickActionsChips={quickActionsChips}
						handleQuickActionPress={handleClickFeaturedChallenge}
					/>
				</Box>
				<ChallengesFolderView
					challengesSectionList={challengesSectionList}
					handleNavigateToChallengesFolder={handleNavigateToChallengesFolder}
					handleClickFeaturedChallenge={handleClickFeaturedChallenge}
				/>
			</ScrollContainer>
		</>
	);
};

export default ChallengesContainer;
