// ----------------- UI Components ---------------
import {
	Box,
	ChallengeStatusListItem,
	CustomListSubheader,
} from "@memorang/ui";

// ----------------- Types ---------------
import type { TakeChallenge, Variant } from "../types";

// ----------------- Helpers ---------------
import {
	getButtonProps,
	getDescription,
	getVariantIconAndTitle,
} from "../helpers";

interface Props {
	challenges: TakeChallenge[];
	handlePressChallenge: (variant: Variant) => void;
}
const TakeChallengesView = ({ challenges, handlePressChallenge }: Props) => {
	return (
		<Box paddingTop={8}>
			<CustomListSubheader>
				{`${challenges.length} Challenges`}
			</CustomListSubheader>
			<>
				{challenges.map((item) => {
					const description = getDescription(item.status);
					const buttonProps = getButtonProps(item.status);
					const variantProps = getVariantIconAndTitle(item.variant);
					return (
						<ChallengeStatusListItem
							key={item.title}
							description={description}
							title={item.title}
							thumbnail={item.thumbnail}
							onPress={() => handlePressChallenge(item.variant)}
							buttonProps={buttonProps}
							variantProp={variantProps}
						/>
					);
				})}
			</>
		</Box>
	);
};

export default TakeChallengesView;
