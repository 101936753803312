/* ----------------- Helpers --------------- */
import { formatRank } from "@memorang/helpers";

/* ----------------- UI --------------- */
import { Text } from "react-native-paper";
import Box from "../Box";
import CustomAvatar from "../CustomAvatar";
import CustomChip from "../CustomChip";

import AvatarGenerator from "../NameCard/AvatarGenerator";
/* ----------------- Constants --------------- */
import { rankStyles } from "./constants";

type Props = {
	rank: number;
	xp: number;
	title: string;
	image: string;
	avatarType?: "initials" | "identicon";
};
const LeaderboardPodium = (props: Props) => {
	const { rank, image, title, xp, avatarType } = props;
	const rankLabel = formatRank(rank);
	const { podiumBg, textColor, height, marginTop, avatarSize } =
		rankStyles[rank - 1];

	return (
		<Box
			flex={1}
			paddingHorizontal={4}
			display="flex"
			maxWidth={200}
			flexDirection="column"
			justifyContent="flex-end"
		>
			{image ? (
				<CustomAvatar size={avatarSize} caption={title} avatar={image} />
			) : (
				<>
					<AvatarGenerator
						firstName={title}
						lastName={title}
						size={avatarSize}
						avatarType={avatarType}
						customStyle={{
							position: "relative",
							top: undefined,
							alignSelf: "center",
						}}
					/>
					{title ? (
						<Text
							variant="labelLarge"
							style={{ fontFamily: "OpenSans-SemiBold", textAlign: "center" }}
						>
							{title}
						</Text>
					) : null}
				</>
			)}
			<Box
				width="100%"
				backgroundColor={podiumBg}
				borderTopLeftRadius={12}
				borderTopRightRadius={12}
				height={height}
				alignItems="center"
				justifyContent="center"
				marginTop={marginTop}
				gap={8}
			>
				<Text
					variant="headlineMedium"
					style={{
						color: textColor,
					}}
				>
					{rankLabel}
				</Text>
				<CustomChip
					elevated
					elevation={4}
					textStyle={{
						color: textColor,
					}}
					style={{
						backgroundColor: podiumBg,
					}}
				>
					{`${xp.toLocaleString()} XP`}
				</CustomChip>
			</Box>
		</Box>
	);
};

export default LeaderboardPodium;
