/* -----------------Helpers & Hooks--------------- */
import { useSearchContext } from "./contexts/SearchContext";
import { useSearchView } from "./hooks/useSearchView";

/* -----------------Types--------------- */
import type { FeedbackParams } from "../feedback";
import type { HandleTracking } from "../types";

/* -----------------Child components--------------- */
import { ChatUI, Container } from "@memorang/ui";

type Props = {
	containerMaxWidth: "xs" | "sm" | "md" | "lg";
	handleTracking: HandleTracking;
	disableCustomResponse?: string;
	feedbackDialogParams?: FeedbackParams;
	loader?: React.ReactNode;
	handleNavigateToReference?: (url: string) => void;
	handleClose?: () => void;
	referenceThumbnails?: Record<string, string>;
	isItemAnswered?: boolean;
};

export const SearchOverlayBodyContainer = ({
	containerMaxWidth,
	feedbackDialogParams,
	handleTracking,
	loader,
	handleNavigateToReference,
	handleClose,
	referenceThumbnails,
	isItemAnswered,
}: Props) => {
	const {
		loading,
		streaming,
		messages,
		context,
		showReference,
		searchQuery,
		abortSearch,
		assistantInfo,
		isThreadLoading,
	} = useSearchContext();

	const {
		handleSelectQuery,
		localRecommendedQueries,
		messageActions,
		appBarFeedbackAction,
		messageFeedbackComponents,
		isRecommendedQuestionsFetching,
		handleResetChatState,
		interceptSearchQuery,
		initialTitle,
	} = useSearchView({
		feedbackDialogParams,
		handleTracking,
	});

	const handleOverlayClose = () => {
		handleClose?.();
	};

	const shouldScrollViewScrollToEnd =
		loading || streaming || isRecommendedQuestionsFetching || isThreadLoading;

	return (
		<>
			<Container
				containerStyle={{
					flex: 1,
				}}
				maxWidth={containerMaxWidth}
			>
				<ChatUI>
					<ChatUI.AppBar
						isBackButtonVisible={true}
						backAction={{
							icon: "close",
							handleAction: handleOverlayClose,
						}}
						title={assistantInfo.title}
						subtitle={isItemAnswered ? initialTitle : null}
						isSubtitleLoading={
							(isRecommendedQuestionsFetching && !initialTitle) ||
							isThreadLoading
						}
						menuActions={[appBarFeedbackAction]}
					/>
					<ChatUI.ScrollView shouldScrollToEnd={shouldScrollViewScrollToEnd}>
						<ChatUI.MessageList
							assistantInfo={assistantInfo}
							messages={messages}
							isRecommendedQuestionsFetching={isRecommendedQuestionsFetching}
							loading={loading}
							streaming={streaming}
							recommendedQueries={localRecommendedQueries}
							handleSelectQuery={handleSelectQuery}
							loader={loader}
							context={context}
							showSummarizeResponse={false}
							handleNavigateToReference={handleNavigateToReference}
							showReference={showReference}
							messageActions={messageActions}
							referenceThumbnails={referenceThumbnails}
							isThreadLoading={isThreadLoading || !initialTitle}
							isItemAnswered={isItemAnswered}
						/>
					</ChatUI.ScrollView>
					<ChatUI.BottomAppBar
						showConversationMode
						showInlineButtons
						searchQuery={searchQuery.value}
						interceptSearchQuery={interceptSearchQuery}
						searchContext={context}
						handleReset={handleResetChatState}
						disableSearch={loading || streaming || !isItemAnswered}
						handleAbortSearch={abortSearch}
						showResetButton={false}
						showAdornments={isItemAnswered}
					/>
				</ChatUI>
				{messageFeedbackComponents}
			</Container>
		</>
	);
};
