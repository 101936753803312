import { useWindowDimensions } from "react-native";
import { useDevice } from "../../../useDevice";
import AiInsightsSuggestion from "../../AiInsightsSuggestion";
import Box from "../../Box";
import CardContainer from "../../CardContainer";
import DistributionBar from "../../DistributionBar";
import RowStats, { type Stat } from "../../RowStats";

type GuessDistribution = {
	[key: string]: number;
};

type Props = {
	title: string;
	tooltip: string;
	subtitle: string;
	distribution: GuessDistribution;
	stats: Stat[];
	insights: string;
};
const OverallYourGuessDistributionCard = ({
	title,
	tooltip,
	subtitle,
	distribution,
	stats,
	insights,
}: Props) => {
	const { width } = useWindowDimensions();
	const { isMobile } = useDevice();
	const maxValue = Math.max(...Object.values(distribution), 5);
	const barContainerWidth = isMobile ? width - 80 : 768 - 80;
	return (
		<CardContainer
			title={title}
			tooltip={tooltip}
			subtitle={subtitle}
			icon="head-dots-horizontal-outline"
		>
			<Box padding={16} gap={24}>
				<Box gap={8}>
					{Object.entries(distribution).map(([guess, count]) => (
						<DistributionBar
							key={guess}
							guess={guess}
							count={count}
							maxValue={maxValue}
							barContainerWidth={barContainerWidth}
						/>
					))}
				</Box>
				<RowStats stats={stats} stacked={false} />
			</Box>
			<AiInsightsSuggestion text={insights} />
		</CardContainer>
	);
};

export default OverallYourGuessDistributionCard;
