/* -----------------Globals--------------- */
import type { GetMessageActions } from "./types";

/* -----------------UI--------------- */
import { IconButton } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "../../useAppTheme";

/* -----------------Child components--------------- */
import Box from "../Box";
import TooltipWrapper from "../TooltipWrapper";

type Props = {
	messageActions: GetMessageActions;
	content?: string;
	showEmail?: boolean;
	align?:
		| "flex-start"
		| "flex-end"
		| "center"
		| "space-between"
		| "space-around"
		| "space-evenly"
		| undefined;
	runId?: string;
};

export const MessageActions = ({
	messageActions,
	align = "flex-start",
	showEmail,
	content,
	runId,
}: Props) => {
	const theme = useAppTheme();

	return (
		<Box flexDirection="row" justifyContent={align}>
			{messageActions(showEmail, runId).map(
				({ icon, tooltip, handleOnPress }) => (
					<TooltipWrapper key={icon} title={tooltip}>
						<IconButton
							icon={icon}
							size={18}
							iconColor={theme.colors.onPrimaryContainer}
							onPress={() => handleOnPress(content)}
							style={{ margin: 0 }}
						/>
					</TooltipWrapper>
				),
			)}
		</Box>
	);
};
