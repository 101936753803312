import type { Article } from "../../../../../types";

export const Article558: Article = {
	id: 558,
	rsuiteId: "ddb99bbf-936a-442c-9fd9-d74667b1a34c",
	type: "scientific article",
	title:
		"Thirty-five Years of Reporting of Sex and Race in Clinical Studies of U.S. FDA-Authorized Orthopaedic Devices",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ddb99bbf-936a-442c-9fd9-d74667b1a34c&type=jpeg&name=JBJS.24.00201f1",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Thirty-five Years of Reporting of Sex and Race in
                Clinical Studies of U.S. FDA-Authorized Orthopaedic Devices</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Hongying
                      Jiang, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Katherine
                      Kavlock, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Background:</span> At the U.S. Food and Drug Administration (FDA),
                        the mission of the Center for Devices and Radiological Health (CDRH) is to ensure that all
                        patients and providers have timely and continued access to safe, effective, and high-quality
                        medical devices and safe radiation-emitting products. Although the CDRH has observed enrollment
                        differences in some clinical trials, no systematic analysis has been conducted regarding
                        enrollment differences in baseline demographics, to our knowledge.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Methods:</span> The CDRH has summarized information on study
                        participants and their baseline demographics in public-facing documentation for all authorized
                        medical devices that involved orthopaedic clinical studies from 1985 to 2020. Descriptive
                        analyses and exploratory statistical testing have been conducted to investigate the reported
                        percentages by sex and race compared with those reported in the U.S. National Census and the
                        American Joint Replacement Registry (AJRR), respectively.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_43" class="annotation strong"
                          style="position: unset;">Results:</span> We identified 94 submissions and corresponding
                        combined clinical trials from 261 original clinical study arms with 34,193 participants. Most of
                        the submissions reported age and sex, while only 36 submissions (38.3%) reported racial
                        demographics. Among the 88 trials providing enrollment by sex, the female enrollment percentage
                        ranged from 22.2% to 88.7%, with a mean of 55.0%. In the submissions that reported racial data
                        (38.3%), White and Black patients had a mean enrollment of 89.2% (range, 64.8% to 98.7%) and
                        6.2% (range, 0.4% to 20.7%), respectively. The enrollment for other minority groups ranged from
                        0% to 3.0%. These clinical trials have shown numerically lower female representation (55.0%) but
                        higher White representation (89.2%) than what has been reported in the AJRR. The other racial
                        groups have participated much less than their corresponding percentages in the U.S. population,
                        but they are similarly represented in the AJRR.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_44" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The clinical trials supporting the FDA’s
                        authorization of orthopaedic devices had a wide range of sex and racial enrollments. It appears
                        that female enrollment mirrors the percentage of women in the U.S. population. However, despite
                        prior efforts, some racial groups are still underrepresented. The FDA has made a commitment to
                        advancing health equity as part of the 2022-2025 Strategic Priorities of the CDRH. We hope that
                        the results of this study will help health-care professionals make informed clinical decisions
                        when using medical devices.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">The U.S. Food and Drug Administration (FDA) has undertaken
                  a multitude of efforts to achieve the mission of the Center for Devices and Radiological Health
                  (CDRH), which is to ensure that all patients and providers have timely and continued access to safe,
                  effective, and high-quality medical devices and safe radiation-emitting products. In 2014, the FDA
                  issued a guidance document (which was not binding on the FDA or the public), “Evaluation of
                  Sex-Specific Data in Medical Device Clinical Studies. Guidance for Industry and Food and Drug
                  Administration Staff,” to outline the expectations of the CDRH regarding sex-specific patient
                  enrollment and to promote study enrollment and data analysis to adequately account for sex and other
                  demographics<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. In
                  2016, the FDA issued another guidance document (“Collection of Race and Ethnicity Data in Clinical
                  Trials”) to further clarify FDA recommendations on the use of a standardized approach for collecting
                  and reporting race and ethnicity data in clinical trials<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>. In
                  2022, the FDA issued a draft guidance document that recommended that the sponsors developing medical
                  products provide “Diversity Plans to Improve Enrollment of Participants from Underrepresented Racial
                  and Ethnic Populations in Clinical Trials.”<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Among a handful of articles reporting sex and/or
                  racial/ethnic representation in orthopaedic device clinical trials over the past decade, various
                  demographic groups are underrepresented, which may affect generalization of the clinical outcomes,
                  even when the patients have all been treated with the same medical devices<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">12</span></a>. For
                  example, a recent meta-analysis of orthopaedic clinical studies with 24 million patients reported that
                  although overall 55% of the 24 million patients enrolled were women, “only 34% (241 of 712) of the
                  studies published in 2016 included gender as a variable in a multifactorial statistical model.”<a
                    href="" data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">13</span></a></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Among clinical studies used to support premarket approval
                  of orthopaedic devices at the FDA, different percentages of race/ethnicity and sex/gender have been
                  observed in study enrollments. However, to our knowledge, no studies have systematically analyzed the
                  inclusion of racial minorities and female participants in all of the orthopaedic clinical studies that
                  the FDA has accepted for orthopaedic device authorizations. Other recent publications reporting sex
                  and/or racial/ethnic representation of orthopaedic clinical trials were mostly limited to randomized
                  controlled trials or a subset of databases of the FDA, with limited time periods and comparisons with
                  U.S. Census data. The objective of this study was to summarize the sex and race data included in the
                  FDA public-facing documents of all orthopaedic devices with submission year up to 2020 (regardless of
                  the study design or time of the authorization). In addition, we compared the results with U.S. Census
                  data as well as with demographic data in the American Joint Replacement Registry (AJRR), which
                  provides data to similar patient populations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Source</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Data were extracted from public-facing summary documents
                  via the FDA online databases for orthopaedic devices that had received FDA marketing authorization
                  through various pathways with submission year from 1985 to 2020: specifically, the Summary of Safety
                  and Effectiveness for a Premarket Approval (PMA) application, the Summary of Safety and Probable
                  Benefit for a Humanitarian Device Exemption (HDE), and the De Novo decision summary for a De Novo
                  request<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">14</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">16</span></a>. These documents are required as part of the submissions
                  and authorizations for PMA, HDE, and De Novo devices, which normally contain clinical data and were
                  thus used in this study. The Premarket Notification 510(k) pathway is another type of authorization
                  used by the FDA; however, because clinical data were mostly not provided in 510(k)s, we did not
                  include them in our study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">For each submission, the raw data were entered into a
                  customized Microsoft Access database. The raw data mainly included submission identification, type of
                  study arm (e.g., investigational device or control), sample size, participant baseline demographic
                  information (e.g., sex, age, race, and ethnicity), and location of the clinical trial. Most
                  submissions included a control and/or additional investigational arm(s), while a few studies only
                  provided demographic data on a combined cohort of all of the enrolled participants.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">We also gathered U.S. Census data from 1990 to 2020, and
                  orthopaedic surgical procedures that had been conducted from 2012 to 2020 were used as comparators<a
                    href="" data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">23</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Cleaning and Quality Control</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">All public-facing documents were manually extracted,
                  reviewed to ensure the accuracy of the entered raw data, and annotated accordingly. After the initial
                  data review of the study arms, we analyzed the demographic data by combining all of the study arms for
                  each submission in this analysis. Of note, 7 PMAs referenced the same clinical trial due to a
                  licensing agreement; however, only the original clinical trial was included in the following analysis.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Descriptive analyses were conducted for each demographic
                  data element and included the mean, median, minimum, maximum, and 2-sided 95% confidence interval
                  (CI). Of note, the term “sex” was used in this analysis because most of the submissions correctly
                  reported males and females in the “sex” category; however, a few used “gender” as a surrogate for sex.
                  Most of the trials (89.5%, 17 of 19) reported Hispanic group data under the “race” category (i.e., as
                  another race or ethnic group). To leverage all of the possible racial data that was provided in all of
                  the submissions, we included the Hispanic data as a proxy for the Hispanic/Latino racial group data in
                  our race analysis. A linear regression analysis was also conducted to explore the longitudinal trend.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The mean sex and racial percentages and 95% CIs were
                  separately compared with the U.S. national census data and the U.S. hip and knee arthroplasty
                  procedures reported by the AJRR. Racial comparisons were conducted for the White, Black, Asian, and
                  Hispanic/Latino groups, but not for the remaining racial groups due to small sample sizes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Statistical analysis was conducted using SAS (version 9.4;
                  SAS Institute).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Overview</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">There were 261 original clinical trial study arms in the
                  94 marketing submissions to the FDA that were used in this analysis. After data cleaning, each
                  submission had 1 clinical trial included in the analysis. Of the 94 submissions, most were PMAs (84%,
                  79 of 94), 11% (10 of 94) were HDEs, and 5% (5 of 94) were De Novo submissions (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00201f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ddb99bbf-936a-442c-9fd9-d74667b1a34c&type=jpeg&name=JBJS.24.00201f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;">Clinical trial selection flowchart showing the
                          identification of the final unique submissions for the FDA-authorized orthopaedic devices. PMA
                          = premarket approval, and HDE = Humanitarian Device Exemption.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">A total of 34,193 participants were included in the
                  analysis; 16,036 (46.9%) were men and 16,405 (48.0%) were women (the sex of the remaining 1,752
                  participants [5.1%] was unknown). Among the 94 submissions, 6 and 58 of them did not provide
                  participant data by sex or race, respectively (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).
                  Demographic data were reported for 11,986 White/Caucasian (35.1%), 842 Black/African American (2.5%),
                  237 Asian (0.7%), 202 Hispanic (0.6%), and 231 “other” race (0.7%) participants.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Baseline Descriptive Statistics of All Orthopaedic
                          Clinical Trials Supporting the 94 FDA Authorizations<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 849.312px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.898907);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Data Element</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Min.</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Mean</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Median</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Max.</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Submissions <span data-id="emphasis_25" class="annotation emphasis"
                            style="position: unset;">(no. [%])</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sample size <span
                            data-id="emphasis_26" class="annotation emphasis"
                            style="position: unset;">(no.)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">364</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">311</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,385</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94
                          (100%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean age <span
                            data-id="emphasis_27" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">50.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.5,
                          53.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">51.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">69.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88
                          (93.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean BMI <span
                            data-id="emphasis_28" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_29" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_8" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_8"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_30"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">28.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.4,
                          28.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">28.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">32.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (50.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Women <span
                            data-id="emphasis_31" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">22.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">55.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52.0,
                          58.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">52.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">88.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88
                          (93.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Men <span
                            data-id="emphasis_32" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">44.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.9,
                          47.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">47.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">77.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88
                          (93.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">White <span
                            data-id="emphasis_33" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">64.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">89.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86.9,
                          91.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">90.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">98.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (38.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Black <span
                            data-id="emphasis_34" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4,
                          8.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (35.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hispanic<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.5,
                          4.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (20.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Asian <span
                            data-id="emphasis_36" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7,
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (30.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hawaiian<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">§</span><span data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0,
                          0.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (10.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Indigenous<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">#</span><span data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3,
                          1.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (14.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Other <span
                            data-id="emphasis_39" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2,
                          2.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (35.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Multiple<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">**</span><span data-id="emphasis_40" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2.1%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Min. = minimum, CI = confidence interval, Max. = maximum, BMI = body
                        mass index, and NA = not applicable.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">2-sided 95% CI for the mean.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Also referred to as Latino and reported as a racial group in some
                        submissions, and thus included in our race analysis. Of note, this Hispanic classification is
                        not adherent to the FDA guidance document, probably due to studies that predated the guidance,
                        which should have been reported as 1 of the 2 ethnicity groups.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Native Hawaiian and Other Pacific Islander.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Also referred to as American Indian and Alaska Native.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">**</b> <span class="text"
                        style="position: unset;">The individual reported ≥2 races.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The sample size per trial ranged from 5 to 2,385
                  participants, with a mean and median of 364 and 311, respectively. The reported mean age of
                  participants was 50.9 years, ranging from 12.4 to 69.6 years (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Female
                Enrollment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Among the 88 submissions that provided sex demographic
                  information, female enrollment ranged from 22.2% to 88.7%, with an average of 55.0% and a median of
                  52.5%, which were approximately 10% and 5% higher than the average and median male enrollment of 44.9%
                  and 47.5%, respectively, over the past 35 years (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">We found 7 outliers (i.e., observations that were at least
                  1.5 times the interquartile range width away from the first or third quartile) for female enrollment
                  (<a href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>, right side). Specifically, the top 2 upper outliers, with 88.7%
                  and 86.0% female enrollment, respectively, involved HDE devices that were approved in 2019 to treat
                  participants with adolescent idiopathic scoliosis (AIS) using non-fusion methods<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">25</span></a>. The
                  next 2 upper outliers, with the same 84.9% female enrollment (overlaid as 1 dot on <a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>), are both finger prostheses<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">27</span></a>. There also were 2 lower outliers with female enrollment of
                  22.2% and 24.4% that involved magnetic resonance-guided high-intensity focused ultrasound (MR-HIFU)
                  and a recombinant human bone morphogenetic protein (rhBMP) device, respectively<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">29</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00201f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ddb99bbf-936a-442c-9fd9-d74667b1a34c&type=jpeg&name=JBJS.24.00201f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">The distribution of female enrollment (pct =
                          percentage) in the orthopaedic clinical trials supporting for FDA authorizations, and female
                          percentages in the U.S. Census data (1990 to 2020) (left panel: scatterplot; right panel: box
                          and whisker plot). The box represents the interquartile range (IQR), the center line in the
                          box represents the median, the whiskers represent the expected variation of the data extending
                          values within 1.5 times the IQR width of the box, the circles above and below the whiskers
                          represent outliers, and the X represents the mean.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Disparities
                in Racial Enrollment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The most frequently reported race group, White/Caucasian,
                  was reported in only 38.3% of submissions (36 of 94), and the least frequently reported race group was
                  Native Hawaiian or Other Pacific Islander, which was reported in only 10.6% of the included
                  submissions (10 of 94). Black, Asian, and “other” races were reported in about one-third of the
                  studies, (35.1%, 30.9%, and 35.1%, respectively) (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">In all of the clinical trials that reported racial data,
                  White was the predominant participant group, with a mean and median enrollment percentage of 89.2% and
                  90.6%, respectively, and a range from 64.8% to 98.7%. Black was the most commonly enrolled minority
                  group, with mean and median enrollment percentages of 6.2% and 4.5%, respectively, and a range from
                  0.4% to 20.7%. Other minority groups were enrolled in only 0.2% to 3.0% of the cohorts on average (<a
                    href="" data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 3</a> and <a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">4</a>, <a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00201f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ddb99bbf-936a-442c-9fd9-d74667b1a34c&type=jpeg&name=JBJS.24.00201f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Box and whisker plot showing the distribution of
                          racial enrollment (pct = percentage) in the orthopaedic clinical trials supporting FDA
                          authorizations. The box represents the interquartile range (IQR), the center line in the box
                          represents the median, the whiskers represent the expected variation of the data extending
                          values within 1.5 times the IQR width of the box, the circles above and below the whiskers
                          represent outliers, and the X represents the mean.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00201f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ddb99bbf-936a-442c-9fd9-d74667b1a34c&type=jpeg&name=JBJS.24.00201f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;">The percentage (pct) of different racial groups
                          enrolled in the FDA-accepted orthopaedic clinical trials (1985 to 2020) and (c) in the U.S.
                          census data (1990 to 2020).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Sex and
                Racial Enrollment Over Time</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The overall average female enrollment of 55.0% (95% CI,
                  52.0% to 58.0%) presented above is numerically higher than the percentage of women in the U.S.
                  population based on U.S. Census surveys, which has remained steady around 51% (between 50.8% and
                  51.3%) for the past 30 years<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">22</span></a> (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>, <a
                    href="" data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Tables I</a> and <a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">II</a>). In
                  addition, the percentage of female participants in all of the trials increased slightly over the
                  years, although this increase did not reach significance (<a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Female Participants and Reported Races in the U.S.
                          Population from 1990 to 2020 and the Sum of Primary and Revision Hip and Knee Arthroplasty
                          Procedures Performed from 2012 to 2020<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 341.935px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.605893);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Year</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Female
                          <span data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">White
                          <span data-id="emphasis_42" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Black or
                          African American <span data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">American
                          Indian and Alaska Native <span data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Asian
                          <span data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Native
                          Hawaiian and Other Pacific Islander <span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Some Other
                          Race <span data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">≥2 Races
                          <span data-id="emphasis_48" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Hispanic
                          or Latino <span data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1990 population</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">51.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">80.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2000 population</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">50.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">75.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2010 population</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">50.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">72.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.3</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2020 population</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">50.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">61.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2012-2020 surgeries<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">58.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">75.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Sources: references 17-23. NA = not applicable.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Hip and knee arthroplasty procedures reported in the AJRR (reference
                        23).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Over the past 35 years, the enrollment percentages of
                  different racial groups showed different trends (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>).
                  Specifically, the percentage of White enrollment in orthopaedic clinical trials remained consistently
                  high, with a mean of 89.2% (95% CI, 86.9 to 91.6), and was significantly higher than the percentage in
                  the U.S. population (ranging from 61.6% to 80.3%)<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">19</span></a> (<a href="" data-id="figure_reference_16"
                    class="annotation figure_reference resource-reference" style="position: unset;">Tables I</a> and <a
                    href="" data-id="figure_reference_17" class="annotation figure_reference resource-reference"
                    style="position: unset;">II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Among minority racial groups, the percentage of Black
                  enrollment in orthopaedic clinical trials has been reported most frequently and has increased slightly
                  over the past 35 years, with a mean of 6.2% (95% CI, 4.4% to 8.1%). However, the Black percentage
                  enrolled in these trials remained well below the percentage in the U.S. population, which ranged
                  between 12.1% and 12.6%<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">19</span></a> (<a href="" data-id="figure_reference_18"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>, <a
                    href="" data-id="figure_reference_19" class="annotation figure_reference resource-reference"
                    style="position: unset;">Tables I</a> and <a href="" data-id="figure_reference_20"
                    class="annotation figure_reference resource-reference" style="position: unset;">II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">The Asian, Hispanic, and “other” minority groups were
                  enrolled much less than their corresponding U.S. populations. For example, the average Asian
                  enrollment in orthopaedic clinical trials was 1.7%, which is much lower than the 2.8% to 6.0% reported
                  in the U.S. population in the last 30 years (<a href="" data-id="figure_reference_21"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>, <a
                    href="" data-id="figure_reference_22" class="annotation figure_reference resource-reference"
                    style="position: unset;">Tables I</a> and <a href="" data-id="figure_reference_23"
                    class="annotation figure_reference resource-reference" style="position: unset;">II</a>). As minority
                  populations have increased in the U.S. over the past 30 years (<a href=""
                    data-id="figure_reference_24" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>), there has been some improvement in minority enrollment in
                  orthopaedic clinical trials, particularly among the Asian and Hispanic groups. However, none of these
                  increases in enrollment reached significance. Of note, Hispanics recently have become the largest
                  minority (16.3% and 18.7% in 2010 and 2020, respectively, in the U.S. Census) (<a href=""
                    data-id="figure_reference_25" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>), yet Hispanic enrollment in the orthopaedic clinical trials
                  was, on average, only 3% (ranging from 0% to 11.3%) (<a href="" data-id="figure_reference_26"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">According to the 2021 annual report of the AJRR, there
                  were a total of 2,244,587 primary and revision hip and knee arthroplasty procedures performed in the
                  U.S. between 2012 and 2020<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">23</span></a>.
                  The AJRR report showed that 58.5% of the patients were women; 75.6% were White, 5.5% were Black, 1.1%
                  were Asian, 0.7% were Indigenous/American Indian and Alaska Native, and 0.1% were Native Hawaiian or
                  Other Pacific Islander<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">23</span></a>.
                  When comparing the above enrollment percentages in orthopaedic device clinical trials with the
                  demographics in the AJRR, we observed a significantly lower representation of female patients (55.0%
                  [95% CI, 52.0% to 58.0%] versus 58.5%), but there was a significantly higher representation of White
                  patients (89.2% [95% CI, 86.9% to 91.6%] versus 75.6%) than the corresponding percentages in the hip
                  and knee arthroplasty procedures reported by the AJRR. The remaining racial groups were enrolled in a
                  similar proportion in both the orthopaedic device trials and the hip and knee arthroplasty procedures
                  reported by the AJRR (<a href="" data-id="figure_reference_27"
                    class="annotation figure_reference resource-reference" style="position: unset;">Tables I</a> and <a
                    href="" data-id="figure_reference_28" class="annotation figure_reference resource-reference"
                    style="position: unset;">II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Orthopaedic device submissions have reported a range of
                  female enrollment that is similar to that of the percentage of female participants that is reported in
                  the orthopaedic clinical literature<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_17" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_17" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">35</span></a>. This mean percentage of female enrollment in clinical
                  trials of studied orthopaedic devices appears to be higher than those in other device areas (e.g.,
                  cardiovascular clinical trials, which have reported female enrollment of only 33.9% on average)<a
                    href="" data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">36</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">The 7 outliers in our study appear to correspond to the
                  underlying medical condition in the target patient populations. For example, the top 2 upper outliers
                  in female enrollment, at 88.7% and 86.0%, involved HDE devices that were approved to treat patients
                  with AIS using non-fusion methods, respectively<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">25</span></a>. AIS affects girls more than boys (1.4 to 7.2 times more
                  often). The other 2 upper outliers, with the same 84.9% female enrollment are both finger prosthesis;
                  this corresponds to a higher prevalence of finger and/or hand osteoarthritis (OA) in women than in
                  men<a href="" data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">37</span></a>. The lowest outlier (22.2%) involved an MR-HIFU device,
                  which uses magnetic resonance imaging to deliver focused, high-intensity ultrasound energy to treat
                  osteoid osteomas (OOs)<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">29</span></a>. An
                  OO is a benign painful bone tumor that affects men at a rate 2 to 3 times greater than women.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Although the percentage of women who were enrolled in
                  orthopaedic device trials was higher than the percentage of women in the overall U.S. population, it
                  was slightly lower than the percentage of women who underwent hip or knee replacements in the U.S. as
                  reported by the AJRR. Based on the adjusted estimates for 2015, 92.1 million adults have arthritis
                  diagnosed by health-care professionals and/or report joint symptoms that are consistent with a
                  diagnosis of arthritis<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">38</span></a>.
                  The Arthritis Foundation reported that more than two-thirds of women who are ≥65 years old may have
                  arthritis; women are almost twice as likely to be diagnosed with OA or hand OA than men who are ≥60
                  years of age<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">38</span></a>.
                  The prevalence of symptomatic knee OA in patients who are ≥45 years of age has also been estimated; in
                  this case as well, women experience more OA than men. Therefore, the higher percentage of female
                  enrollment in those orthopaedic clinical trial outliers appears to be consistent with the higher
                  prevalence of the underlying orthopaedic disease conditions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Regarding racial enrollment in clinical trials, the
                  percentage of White enrollment was consistently higher than the percentage of the White racial group
                  in the U.S. overall population and in those who underwent hip or knee replacement in the U.S., similar
                  to trials with other devices (e.g., cardiovascular). However, the enrollment percentages of the other
                  minority groups were all lower than their percentages in the overall U.S. population, particularly for
                  the Hispanic group, which, based on the 2020 U.S. Census data, is the largest minority group<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">23</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">These findings may not be unexpected since the Census and
                  the AJRR data may not represent the population that receives orthopaedic devices. For example, the
                  U.S. Census data represent the population of the entire nation, while surgeries reported by the AJRR
                  in the U.S. appear to reflect the real-world population of those treated with such orthopaedic
                  devices. However, the AJRR demographic data are based only on patients who undergo hip or knee
                  arthroplasty surgeries, which may not be representative of all types of orthopaedic devices. Despite
                  national initiatives, orthopaedic surgery is likely more accessible to those who have better
                  socioeconomic status<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_25" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">39</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Although certain sex and racial disparities may be related
                  to the prevalence of underlying disease, additional investigation is warranted to identify whether the
                  enrollment percentages truly mirror the intended patient populations, and whether the disparities
                  affect the clinical outcomes. This study is one of the FDA’s efforts to understand the landscape of
                  orthopaedic clinical trials that have been submitted to the FDA over the past 35 years, help identify
                  demographic gaps, and further encourage more inclusive clinical studies to better understand health
                  outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">This retrospective study had several limitations.
                  Specifically, (1) it had a high amount of missing demographic data, particularly regarding
                  race/ethnicity, (2) it had small sample sizes per trial in certain racial groups, (3) some trials
                  categorized Hispanic/Latino as a racial group (rather than an ethnicity), (4) there were limitations
                  because of the 2 comparators (i.e., the U.S. Census data and the data from the AJRR), and (5) sampling
                  bias was present since we analyzed demographic data from public-facing documents only, whereas other
                  documents might also have provided clinical data. All of these limitations may have altered the
                  results.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">The clinical trials supporting the FDA’s authorization of
                  orthopaedic devices had a wide range of sex and racial enrollments. It appears that female enrollment
                  mirrors the female percentage in the U.S. population. However, despite prior efforts, some racial
                  groups are still underrepresented. The FDA has made a commitment to advancing health equity as part of
                  the 2022-2025 Strategic Priorities of the CDRH, including developing a framework for when a device
                  should be evaluated in diverse populations in order to support marketing authorization<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">40</span></a>. We hope that the results of this study will help
                  health-care professionals make informed clinical decisions when using medical devices.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: We thank the 4 summer interns who entered the raw data from the
                  source documents into the customized Microsoft Access database, namely Marisa Marine, Megan Chow,
                  Joseph Peluso, and Cecilia Groves. We also thank the following colleagues for their intellectual
                  discussions and comments that improved the quality of this research project: Jonathan Peck, Dr. Limin
                  Sun, and Dr. Laura Rose.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Throughout this paper, the spelling of “orthopaedic”
                  corresponds with the preferred style of <span data-id="emphasis_24" class="annotation emphasis"
                    style="position: unset;">The Journal of Bone & Joint Surgery</span> rather than the style of the
                  U.S. FDA (“orthopedic”).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;">Center for
                Devices and Radiological Health. <span data-id="strong_1" class="annotation strong"
                  style="position: unset;">Evaluation of Sex-Specific Data in Medical Device Clinical Studies</span>.
                <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Guidance for Industry
                  and Food and Drug Administration Staff.</span> 2014. Accessed 2024 May 8. <a
                  href="https://www.fda.gov/media/82005/download" target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.fda.gov/media/82005/download</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20Sex-Specific%20Data%20in%20Medical%20Device%20Clinical%20Studies&as_occt=title&as_sauthors=%20%22Center%20for%20Devices%20and%20Radiological%20Health%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="strong_2" class="annotation strong"
                  style="position: unset;">Collection of Race and Ethnicity Data in Clinical Trials</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Guidance for Industry and
                  Food and Drug Administration Staff.</span> 2016. Accessed 2024 May 8. <a
                  href="https://www.fda.gov/media/75453/download/" target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://www.fda.gov/media/75453/download/</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Collection%20of%20Race%20and%20Ethnicity%20Data%20in%20Clinical%20Trials&as_occt=title&as_sauthors=%20%22U.S.%20Food%20and%20Drug%20Administration%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="strong_3" class="annotation strong"
                  style="position: unset;">Diversity Plans to Improve Enrollment of Participants from Underrepresented
                  Racial and Ethnic Populations in Clinical Trials</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Guidance for Industry.</span> 2022 Apr. Draft
                Guidance. Accessed 2024 May 8. <a href="https://www.fda.gov/media/157635/download" target="_blank"
                  data-id="link_3" class="link"
                  style="position: unset;">https://www.fda.gov/media/157635/download</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diversity%20Plans%20to%20Improve%20Enrollment%20of%20Participants%20from%20Underrepresented%20Racial%20and%20Ethnic%20Populations%20in%20Clinical%20Trials&as_occt=title&as_sauthors=%20%22U.S.%20Food%20and%20Drug%20Administration%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Cwalina TB</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Jella TK</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Manyak GA</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kuo A</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Kamath AF</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Is Our Science Representative? A
                  Systematic Review of Racial and Ethnic Diversity in Orthopaedic Clinical Trials from 2000 to
                  2020</span>. <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2022 May 1;480(5):848-58.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20Our%20Science%20Representative%3F%20A%20Systematic%20Review%20of%20Racial%20and%20Ethnic%20Diversity%20in%20Orthopaedic%20Clinical%20Trials%20from%202000%20to%202020&as_occt=title&as_sauthors=%20%22TB%20Cwalina%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> George J</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Gautam D</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Sugumar PA</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Janardhanan R</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Kabra A</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Malhotra R</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Uneven global and racial
                  representation in major orthopaedic clinical trials: Trends over a decade</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J Clin Orthop Trauma.</span>
                2022 May 13;29:101894.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Uneven%20global%20and%20racial%20representation%20in%20major%20orthopaedic%20clinical%20trials%3A%20Trends%20over%20a%20decade&as_occt=title&as_sauthors=%20%22J%20George%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Hecht CJ II</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Friedl SL</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Ong CB</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Burkhart RJ</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Porto JR</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Kamath AF</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Are orthopedic clinical trials
                  representative? An analysis of race and ethnicity reported in clinical trials between 2007 and
                  2022</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Arch
                  Orthop Trauma Surg.</span> 2024 May;144(5):1977-87.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20orthopedic%20clinical%20trials%20representative%3F%20An%20analysis%20of%20race%20and%20ethnicity%20reported%20in%20clinical%20trials%20between%202007%20and%202022&as_occt=title&as_sauthors=%20%22CJ%20II%20Hecht%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Issa TZ</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Lambrechts MJ</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Lin JS</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Brush PL</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Canseco JA</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Hilibrand AS</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Kepler CK</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Schroeder GD</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Vaccaro AR</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Diversity in Orthopaedic Surgery
                  Medical Device Clinical Trials: An Analysis of the Food and Drug Administration Safety and Innovation
                  Act</span>. <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J Am Acad
                  Orthop Surg.</span> 2023 Feb 1;31(3):155-65.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diversity%20in%20Orthopaedic%20Surgery%20Medical%20Device%20Clinical%20Trials%3A%20An%20Analysis%20of%20the%20Food%20and%20Drug%20Administration%20Safety%20and%20Innovation%20Act&as_occt=title&as_sauthors=%20%22TZ%20Issa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Nayar SK</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Marrache M</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Ali I</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Bressner J</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Raad M</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Shafiq B</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Srikumaran U</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Racial Disparity in Time to
                  Surgery and Complications for Hip Fracture Patients</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Surg.</span> 2020
                Dec;12(4):430-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Racial%20Disparity%20in%20Time%20to%20Surgery%20and%20Complications%20for%20Hip%20Fracture%20Patients&as_occt=title&as_sauthors=%20%22SK%20Nayar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Perez BA</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Slover J</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Edusei E</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Horan A</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Anoushiravani A</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Kamath AF</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Nelson CL</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Impact of gender and race on
                  expectations and outcomes in total knee arthroplasty</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">World J Orthop.</span> 2020 May
                18;11(5):265-77.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20gender%20and%20race%20on%20expectations%20and%20outcomes%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22BA%20Perez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Schoenfeld AJ</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Tipirneni R</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Nelson JH</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Carpenter JE</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Iwashyna TJ</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The influence of race and
                  ethnicity on complications and mortality after orthopedic surgery: a systematic review of the
                  literature</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Med Care.</span> 2014 Sep;52(9):842-51.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20influence%20of%20race%20and%20ethnicity%20on%20complications%20and%20mortality%20after%20orthopedic%20surgery%3A%20a%20systematic%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22AJ%20Schoenfeld%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Shahid H</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Singh JA</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Racial/Ethnic Disparity in
                  Rates and Outcomes of Total Joint Arthroplasty</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Curr Rheumatol Rep.</span> 2016
                Apr;18(4):20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Racial%2FEthnic%20Disparity%20in%20Rates%20and%20Outcomes%20of%20Total%20Joint%20Arthroplasty&as_occt=title&as_sauthors=%20%22H%20Shahid%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Somerson JS</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Bhandari M</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Vaughan CT</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Smith CS</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Zelle BA</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Lack of Diversity in
                  Orthopaedic Trials Conducted in the United States</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2014;96(e56):1-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lack%20of%20Diversity%20in%20Orthopaedic%20Trials%20Conducted%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22JS%20Somerson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Gianakos AL</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> George N</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Pinninti A</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Kwan S</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> LaPorte D</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Mulcahey MK</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Sex- and Gender-specific
                  Analysis in Orthopaedic Studies</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2020 Jul;478(7):1482-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex-%20and%20Gender-specific%20Analysis%20in%20Orthopaedic%20Studies&as_occt=title&as_sauthors=%20%22AL%20Gianakos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="strong_14" class="annotation strong"
                  style="position: unset;">Device Classification Under Section 513(f)(2)(De Novo)</span>. Accessed 2024
                May 8. <a href="https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfPMN/denovo.cfm" target="_blank"
                  data-id="link_4" class="link"
                  style="position: unset;">https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfPMN/denovo.cfm</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Device%20Classification%20Under%20Section%20513(f)(2)(De%20Novo)&as_occt=title&as_sauthors=%20%22U.S.%20Food%20and%20Drug%20Administration%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="strong_15" class="annotation strong"
                  style="position: unset;">Humanitarian Device Exemption (HDE)</span>. Accessed 2024 May 8. <a
                  href="https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfHDE/hde.cfm" target="_blank"
                  data-id="link_5" class="link"
                  style="position: unset;">https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfHDE/hde.cfm</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Humanitarian%20Device%20Exemption%20(HDE)&as_occt=title&as_sauthors=%20%22U.S.%20Food%20and%20Drug%20Administration%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="strong_16" class="annotation strong"
                  style="position: unset;">Premarket Approval (PMA)</span>. Accessed 2024 May 8. <a
                  href="https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfPMA/pma.cfm" target="_blank"
                  data-id="link_6" class="link"
                  style="position: unset;">https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfPMA/pma.cfm</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Premarket%20Approval%20(PMA)&as_occt=title&as_sauthors=%20%22U.S.%20Food%20and%20Drug%20Administration%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;">Bureau of
                the Census. <span data-id="strong_17" class="annotation strong" style="position: unset;">We asked. You
                  told us. Gender</span>. 1990. Accessed 2024 Jul 9. <a
                  href="https://www2.census.gov/library/publications/decennial/1990/cqc/cqc-03.pdf" target="_blank"
                  data-id="link_7" class="link"
                  style="position: unset;">https://www2.census.gov/library/publications/decennial/1990/cqc/cqc-03.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=We%20asked.%20You%20told%20us.%20Gender&as_occt=title&as_sauthors=%20%22Bureau%20of%20the%20Census%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;">Unites
                States Census Bureau. <span data-id="strong_18" class="annotation strong"
                  style="position: unset;">Census 2000 PHC-T-1. Population by Race and Hispanic or Latino Origin for the
                  United States: 1990 and 2000</span>. 2000. Accessed 2024 Jul 9. <a
                  href="https://www2.census.gov/programs-surveys/decennial/2000/phc/phc-t-01/tab04.pdf" target="_blank"
                  data-id="link_8" class="link"
                  style="position: unset;">https://www2.census.gov/programs-surveys/decennial/2000/phc/phc-t-01/tab04.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Census%202000%20PHC-T-1.%20Population%20by%20Race%20and%20Hispanic%20or%20Latino%20Origin%20for%20the%20United%20States%3A%201990%20and%202000&as_occt=title&as_sauthors=%20%22Unites%20States%20Census%20Bureau%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;">United
                States Census Bureau. <span data-id="strong_19" class="annotation strong" style="position: unset;">Table
                  2. Percentage of Population and Percent Change by Race: 2010 and 2020</span>. 2020. Accessed 2024 Jul
                9. <a
                  href="https://www2.census.gov/programs-surveys/decennial/2020/data/redistricting-supplementary-tables/redistricting-supplementary-table-02.pdf"
                  target="_blank" data-id="link_9" class="link"
                  style="position: unset;">https://www2.census.gov/programs-surveys/decennial/2020/data/redistricting-supplementary-tables/redistricting-supplementary-table-02.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Table%202.%20Percentage%20of%20Population%20and%20Percent%20Change%20by%20Race%3A%202010%20and%202020&as_occt=title&as_sauthors=%20%22United%20States%20Census%20Bureau%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;">United
                States Census Bureau. <span data-id="strong_20" class="annotation strong"
                  style="position: unset;">QuickFacts. United States</span>. Accessed 2024 Jul 9. <a
                  href="https://www.census.gov/quickfacts/fact/table/US" target="_blank" data-id="link_10" class="link"
                  style="position: unset;">https://www.census.gov/quickfacts/fact/table/US</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=QuickFacts.%20United%20States&as_occt=title&as_sauthors=%20%22United%20States%20Census%20Bureau%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Howden LM</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Meyer JA</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Age and Sex Composition: 2010.
                  2010 Census Briefs</span>. 2011 May. Accessed 2024 Jul 9. <a
                  href="https://www.census.gov/content/dam/Census/library/publications/2011/dec/c2010br-03.pdf"
                  target="_blank" data-id="link_11" class="link"
                  style="position: unset;">https://www.census.gov/content/dam/Census/library/publications/2011/dec/c2010br-03.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Age%20and%20Sex%20Composition%3A%202010.%202010%20Census%20Briefs&as_occt=title&as_sauthors=%20%22LM%20Howden%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Smith DL</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Spraggins RE</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Gender: 2000. Census 2000
                  Brief</span>. Accessed 2024 Jul 9. <a
                  href="https://www2.census.gov/library/publications/decennial/2000/briefs/c2kbr01-09.pdf"
                  target="_blank" data-id="link_12" class="link"
                  style="position: unset;">https://www2.census.gov/library/publications/decennial/2000/briefs/c2kbr01-09.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gender%3A%202000.%20Census%202000%20Brief&as_occt=title&as_sauthors=%20%22DL%20Smith%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;">American
                Academy of Orthopaedic Surgeons and American Joint Replacement Registry. <span data-id="strong_23"
                  class="annotation strong" style="position: unset;">Download the 2021 Annual Report</span>. 2021.
                Accessed 2024 Jul 9. <a href="https://connect.registryapps.net/2021-ajrr-annual-report" target="_blank"
                  data-id="link_13" class="link"
                  style="position: unset;">https://connect.registryapps.net/2021-ajrr-annual-report</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Download%20the%202021%20Annual%20Report&as_occt=title&as_sauthors=%20%22American%20Academy%20of%20Orthopaedic%20Surgeons%20and%20American%20Joint%20Replacement%20Registry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;">ApiFix.
                <span data-id="strong_24" class="annotation strong" style="position: unset;">Summary of Safety and
                  Probable Benefit (SSPB)</span>. Accessed 2024 May 8. <a
                  href="https://www.accessdata.fda.gov/cdrh_docs/pdf17/H170001B.pdf" target="_blank" data-id="link_14"
                  class="link"
                  style="position: unset;">https://www.accessdata.fda.gov/cdrh_docs/pdf17/H170001B.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Summary%20of%20Safety%20and%20Probable%20Benefit%20(SSPB)&as_occt=title&as_sauthors=%20%22ApiFix%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;">Zimmer
                Biomet Spine. <span data-id="strong_25" class="annotation strong" style="position: unset;">Summary of
                  Safety and Probable Benefit (SSPB)</span>. Accessed 2024 May 8. <a
                  href="https://www.accessdata.fda.gov/cdrh_docs/pdf19/H190005B.pdf" target="_blank" data-id="link_15"
                  class="link"
                  style="position: unset;">https://www.accessdata.fda.gov/cdrh_docs/pdf19/H190005B.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Summary%20of%20Safety%20and%20Probable%20Benefit%20(SSPB)&as_occt=title&as_sauthors=%20%22Zimmer%20Biomet%20Spine%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;">Ascension
                Orthopaedics. <span data-id="strong_26" class="annotation strong" style="position: unset;">Summary of
                  Safety and Effectiveness Data</span>. Accessed 2024 May 8. <a
                  href="https://www.accessdata.fda.gov/cdrh_docs/pdf/P000057B.pdf" target="_blank" data-id="link_16"
                  class="link"
                  style="position: unset;">https://www.accessdata.fda.gov/cdrh_docs/pdf/P000057B.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Summary%20of%20Safety%20and%20Effectiveness%20Data&as_occt=title&as_sauthors=%20%22Ascension%20Orthopaedics%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;">Ascension
                Orthopaedics. <span data-id="strong_27" class="annotation strong" style="position: unset;">Summary of
                  Safety and Probable Benefit</span>. Accessed 2024 May 8. <a
                  href="https://www.accessdata.fda.gov/cdrh_docs/pdf/H010005B.pdf" target="_blank" data-id="link_17"
                  class="link"
                  style="position: unset;">https://www.accessdata.fda.gov/cdrh_docs/pdf/H010005B.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Summary%20of%20Safety%20and%20Probable%20Benefit&as_occt=title&as_sauthors=%20%22Ascension%20Orthopaedics%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;">Stryker
                Biotech. <span data-id="strong_28" class="annotation strong" style="position: unset;">Summary of Safety
                  and Probable Benefit</span>. Accessed 2024 May 8. <a
                  href="https://www.accessdata.fda.gov/cdrh_docs/pdf/H010002B.pdf" target="_blank" data-id="link_18"
                  class="link"
                  style="position: unset;">https://www.accessdata.fda.gov/cdrh_docs/pdf/H010002B.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Summary%20of%20Safety%20and%20Probable%20Benefit&as_occt=title&as_sauthors=%20%22Stryker%20Biotech%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;">Profound
                Medical. <span data-id="strong_29" class="annotation strong" style="position: unset;">Summary of Safety
                  and Probable Benefit (SSPB)</span>. Accessed 2024 May 8. <a
                  href="https://www.accessdata.fda.gov/cdrh_docs/pdf19/H190003B.pdf" target="_blank" data-id="link_19"
                  class="link"
                  style="position: unset;">https://www.accessdata.fda.gov/cdrh_docs/pdf19/H190003B.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Summary%20of%20Safety%20and%20Probable%20Benefit%20(SSPB)&as_occt=title&as_sauthors=%20%22Profound%20Medical%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Ang DC</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> James G</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Stump TE</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Clinical appropriateness and
                  not race predicted referral for joint arthroplasty</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Arthritis Rheum.</span> 2009 Dec
                15;61(12):1677-85.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20appropriateness%20and%20not%20race%20predicted%20referral%20for%20joint%20arthroplasty&as_occt=title&as_sauthors=%20%22DC%20Ang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Bass AR</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Do HT</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Mehta B</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Lyman S</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Mirza SZ</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Parks M</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Figgie M</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Mandl LA</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Goodman SM</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Assessment of Racial
                  Disparities in the Risks of Septic and Aseptic Revision Total Knee Replacements</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2021
                Jul 1;4(7):e2117581.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessment%20of%20Racial%20Disparities%20in%20the%20Risks%20of%20Septic%20and%20Aseptic%20Revision%20Total%20Knee%20Replacements&as_occt=title&as_sauthors=%20%22AR%20Bass%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Singh JA</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Ramachandran R</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Persisting Racial Disparities
                  in Total Shoulder Arthroplasty Utilization and Outcomes</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Racial Ethn Health Disparities.</span>
                2015;2015:1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Persisting%20Racial%20Disparities%20in%20Total%20Shoulder%20Arthroplasty%20Utilization%20and%20Outcomes&as_occt=title&as_sauthors=%20%22JA%20Singh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Singh JA</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Ramachandran R</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Racial disparities in total
                  ankle arthroplasty utilization and outcomes</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Arthritis Res Ther.</span> 2015b Mar
                21;17(1):70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Racial%20disparities%20in%20total%20ankle%20arthroplasty%20utilization%20and%20outcomes&as_occt=title&as_sauthors=%20%22JA%20Singh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Singh JA</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Ramachandran R</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Are there racial disparities in
                  utilization and outcomes after total elbow arthroplasty?</span><span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">Rheumatol Int.</span> 2015c
                Sep;35(9):1479-87.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20there%20racial%20disparities%20in%20utilization%20and%20outcomes%20after%20total%20elbow%20arthroplasty%3F&as_occt=title&as_sauthors=%20%22JA%20Singh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Singh V</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Realyvasquez J</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Kugelman DN</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Aggarwal VK</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Long WJ</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Schwarzkopf R</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Does racial background
                  influence outcomes following total joint arthroplasty?</span><span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Clin Orthop Trauma.</span> 2021 May
                21;19:139-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20racial%20background%20influence%20outcomes%20following%20total%20joint%20arthroplasty%3F&as_occt=title&as_sauthors=%20%22V%20Singh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Kramer DB</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Mallis E</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Zuckerman BD</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Zimmerman BA</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Maisel WH</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Premarket clinical evaluation
                  of novel cardiovascular devices: quality analysis of premarket clinical studies submitted to the Food
                  and Drug Administration 2000-2007</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Am J Ther.</span> 2010 Jan-Feb;17(1):2-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Premarket%20clinical%20evaluation%20of%20novel%20cardiovascular%20devices%3A%20quality%20analysis%20of%20premarket%20clinical%20studies%20submitted%20to%20the%20Food%20and%20Drug%20Administration%202000-2007&as_occt=title&as_sauthors=%20%22DB%20Kramer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Haara MM</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Manninen P</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Kröger H</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Arokoski JP</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Kärkkäinen A</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Knekt P</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Aromaa A</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Heliövaara M</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Osteoarthritis of finger joints
                  in Finns aged 30 or over: prevalence, determinants, and association with mortality</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Ann Rheum Dis.</span> 2003
                Feb;62(2):151-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoarthritis%20of%20finger%20joints%20in%20Finns%20aged%2030%20or%20over%3A%20prevalence%2C%20determinants%2C%20and%20association%20with%20mortality&as_occt=title&as_sauthors=%20%22MM%20Haara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;">Arthritis
                Foundation. <span data-id="strong_38" class="annotation strong" style="position: unset;">Arthritis By
                  the Numbers. Book of Trusted Facts and Figures</span>. 2019. Accessed 2024 Jul 9. <a
                  href="https://www.arthritis.org/getmedia/e1256607-fa87-4593-aa8a-8db4f291072a/2019-abtn-final-march-2019.pdf"
                  target="_blank" data-id="link_20" class="link"
                  style="position: unset;">https://www.arthritis.org/getmedia/e1256607-fa87-4593-aa8a-8db4f291072a/2019-abtn-final-march-2019.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthritis%20By%20the%20Numbers.%20Book%20of%20Trusted%20Facts%20and%20Figures&as_occt=title&as_sauthors=%20%22Arthritis%20Foundation%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Best MJ</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> McFarland EG</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Thakkar SC</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Srikumaran U</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Racial Disparities in the Use
                  of Surgical Procedures in the US</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">JAMA Surg.</span> 2021 Mar 1;156(3):274-81.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Racial%20Disparities%20in%20the%20Use%20of%20Surgical%20Procedures%20in%20the%20US&as_occt=title&as_sauthors=%20%22MJ%20Best%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="strong_40" class="annotation strong"
                  style="position: unset;">Center for Devices and Radiological Health. 2022-2025 Strategic
                  Priorities</span>. Accessed 2024 May 8. <a href="https://www.fda.gov/media/155888/download"
                  target="_blank" data-id="link_21" class="link"
                  style="position: unset;">https://www.fda.gov/media/155888/download</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Center%20for%20Devices%20and%20Radiological%20Health.%202022-2025%20Strategic%20Priorities&as_occt=title&as_sauthors=%20%22U.S.%20Food%20and%20Drug%20Administration%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dddb99bbf-936a-442c-9fd9-d74667b1a34c%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F2009%26topics%3Doe"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 2009</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00201</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 12, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_5" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_23"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the U.S.
                            Food and Drug Administration, Silver Spring, Maryland</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_7" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Written work prepared by employees of the Federal
                          Government as part of their official duties is, under the United States Copyright Act, a ‘work
                          of the United States Government’ for which copyright protection under that Act is not
                          available. As such, copyright protection does not extend to the contributions of employees of
                          the Federal Government prepared as part of their employment.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ddb99bbf-936a-442c-9fd9-d74667b1a34c&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=ddb99bbf-936a-442c-9fd9-d74667b1a34c&type=pdf&name=JBJS.24.00201.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=ddb99bbf-936a-442c-9fd9-d74667b1a34c&type=pdf&name=JBJS.24.00201.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_46" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I178"
                            target="_blank" data-id="link_22" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I178</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ddb99bbf-936a-442c-9fd9-d74667b1a34c&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=ddb99bbf-936a-442c-9fd9-d74667b1a34c&type=zip&name=JBJS.24.00201.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hongying Jiang, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8866-0254" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8866-0254</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Katherine Kavlock, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-3434-0719" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-3434-0719</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Qin Li, PhD<span data-id="affiliation_reference_2"
                  class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-8509-8702" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-8509-8702</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shruti Mistry, MS<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0001-0834-1593" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0001-0834-1593</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Valerie Hermes, BS<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-3616-4540" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-3616-4540</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alonza Gibbs, BBA<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0004-5683-4837" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0004-5683-4837</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Elizabeth Adegboyega-Panox, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0004-8669-2323" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0004-8669-2323</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Raquel Peat, PhD, MPH<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Raquel.Peat@fda.hhs.gov" class=""
                    style="position: unset;">Raquel.Peat@fda.hhs.gov</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2020-8808" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2020-8808</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Office of Orthopedic Devices, Office of Product Evaluation and Quality, Center
                for Devices and Radiological Health, U.S. Food and Drug Administration, Silver Spring, Maryland</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Office of Clinical Evidence and Analysis, Office of Product Evaluation and
                Quality, Center for Devices and Radiological Health, U.S. Food and Drug Administration, Silver Spring,
                Maryland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Office of Cardiovascular Devices, Office of Product Evaluation and Quality,
                Center for Devices and Radiological Health, U.S. Food and Drug Administration, Silver Spring, MD</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Office of Communication, Information Disclosure, Training and Education, Office
                of Product Evaluation and Quality, Center for Devices and Radiological Health, U.S. Food and Drug
                Administration, Silver Spring, MD</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Office of Management, Center for Devices and Radiological Health, U.S. Food and
                Drug Administration, Silver Spring, MD</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 28305.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
