/**
 * @generated SignedSource<<c86d8f19dc2de7edca9c0d3f5ba7f7f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Mode = "FLIP" | "MATCH" | "QUESTION" | "QUIZ" | "%future added value";
export type SuggestionInput = {
  correction: string;
  source: string;
};
export type ModeDetails = {
  flip?: FlipMode | null;
  match?: MatchMode | null;
  mode?: Mode | null;
  quiz?: QuizMode | null;
};
export type FlipMode = {
  backOfCardContent: string;
  frontOfCardContent: string;
};
export type MatchMode = {
  factCardContent: string;
  termCardContent: string;
};
export type QuizMode = {
  choices: ReadonlyArray<QuizModeChoice>;
  question: string;
};
export type QuizModeChoice = {
  isChosen: boolean;
  isCorrect: boolean;
  name: string;
};
export type SuggestCorrectionMutation$variables = {
  itemId: string;
  modeDetails?: ModeDetails | null;
  suggestion: SuggestionInput;
};
export type SuggestCorrectionMutation$data = {
  readonly suggestCorrection: boolean;
};
export type SuggestCorrectionMutation = {
  response: SuggestCorrectionMutation$data;
  variables: SuggestCorrectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "modeDetails"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestion"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "itemId",
        "variableName": "itemId"
      },
      {
        "kind": "Variable",
        "name": "modeDetails",
        "variableName": "modeDetails"
      },
      {
        "kind": "Variable",
        "name": "suggestion",
        "variableName": "suggestion"
      }
    ],
    "kind": "ScalarField",
    "name": "suggestCorrection",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SuggestCorrectionMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SuggestCorrectionMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "1d21f6dd33b8a96f1e7e386dd636e62c",
    "id": null,
    "metadata": {},
    "name": "SuggestCorrectionMutation",
    "operationKind": "mutation",
    "text": "mutation SuggestCorrectionMutation(\n  $itemId: ID!\n  $suggestion: SuggestionInput!\n  $modeDetails: ModeDetails\n) {\n  suggestCorrection(itemId: $itemId, suggestion: $suggestion, modeDetails: $modeDetails)\n}\n"
  }
};
})();

(node as any).hash = "47062b6b907237169c30cbccd3e98550";

export default node;
