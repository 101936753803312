import type { Article } from "../../../../../types";

export const Article567: Article = {
	id: 567,
	rsuiteId: "1f8df2b0-e3a9-43f0-8428-3b7536fc74d2",
	type: "guest editorial",
	title: "What’s New in Orthopaedic Rehabilitation",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/567.jpeg",
	subSpecialties: ["shoulder_elbow"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s New in Orthopaedic Rehabilitation</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Donald
                      Kasitinon, MD, RMSK</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Alia Hemeida,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Orthopaedic surgery and rehabilitation medicine continue
                  to remain synergistic fields of medicine. This editorial reviews articles published from March 2023
                  through February 2024. We considered articles from the <span data-id="emphasis_46"
                    class="annotation emphasis" style="position: unset;">American Journal of Physical Medicine &
                    Rehabilitation; The American Journal of Sports Medicine; Clinical Journal of Sports Medicine;
                    Archives of Physical Medicine and Rehabilitation; The BMJ (British Medical Journal); The Journal of
                    Bone & Joint Surgery; JAMA (The Journal of the American Medical Association); Journal of
                    Rehabilitation Medicine; Journal of Shoulder and Elbow Surgery; The New England Journal of Medicine;
                    Pain Medicine; PM&R: The Journal of Injury, Function and Rehabilitation; Regional Anesthesia & Pain
                    Medicine;</span> and <span data-id="emphasis_47" class="annotation emphasis"
                    style="position: unset;">The Spine Journal</span>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Shoulder</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Myofascial trigger points, which often accompany
                  subacromial pain syndrome, can be successfully treated with dry needling, which reduces pain and
                  disability for up to 6 months, increases blood flow, and promotes collagen proliferation. In a
                  systematic review and meta-analysis, Griswold et al. discovered that dry needling added to other
                  conservative treatments is superior to the other conservative treatment alone in terms of pain and
                  disability outcomes in patients with subacromial pain syndrome<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">In a double-blinded, randomized controlled trial (RCT),
                  Kandemir et al. compared the effectiveness of pulsed electromagnetic field therapy with that of sham
                  therapy for treating shoulder impingement syndrome<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>.
                  Compared with sham therapy with exercise, pulsed electromagnetic field therapy plus exercise
                  demonstrated better pain scores, active but not passive range of motion, disability at the 4-week and
                  12-week follow-ups, and quality of life at the 12-week follow-up. No significant difference in muscle
                  strength was noted between the 2 groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">In a 3-arm RCT of 60 patients with shoulder impingement
                  syndrome, ElGendy et al. compared 3 treatments: physical therapy with local corticosteroid injection,
                  physical therapy alone, and physical therapy with radial extracorporeal shockwave therapy (ESWT)<a
                    href="" data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a>. There was no significant difference in pain and disability,
                  range of motion, and subacromial space width between all groups at 4 weeks. At 12 weeks, no
                  significant difference was noted between patients who underwent physical therapy with corticosteroid
                  injection and patients who underwent physical therapy only, but patients who underwent physical
                  therapy with ESWT demonstrated significant differences in almost all outcomes compared with patients
                  who underwent physical therapy alone and significantly improved shoulder internal rotation and
                  subacromial space width compared with patients who underwent physical therapy with corticosteroid
                  injection. This study suggests that physical therapy with ESWT is a superior long-term treatment for
                  shoulder impingement syndrome compared with physical therapy with corticosteroid injection and with
                  physical therapy only.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Angileri et al. completed a systematic review and
                  meta-analysis of 27 RCTs comparing operative and nonoperative interventions for chronic calcific
                  tendinitis of the rotator cuff<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>.
                  Patients who underwent radial ESWT, ultrasound-guided needling, and surgical intervention reported
                  improved visual analog scale (VAS) pain scores after compared with before treatment. Patients who
                  underwent surgical treatment reported greater improvement in shoulder functional outcome scores
                  compared with patients who underwent ESWT and ultrasound-guided needling. In a single study of 20
                  patients who underwent surgical treatment, 85% of the patients had complete radiographic resolution of
                  calcium deposits; in comparison, such resolution occurred in 66.7% of patients after ultrasound-guided
                  needling and 27.3% of patients after ESWT. This study was unable to identify significant differences
                  between these interventions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Vitamin C’s antioxidant and anti-inflammatory properties
                  make it an appealing low-cost and low-risk treatment intervention. In a study using a rat model, Feusi
                  et al. compared a group that received prophylactic vitamin C and a control group with respect to the
                  prevention of secondary adhesive capsulitis<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">5</span></a>. The
                  intervention group had significantly less thickening of the axillary fold on an operatively treated
                  shoulder compared with the control group. Vitamin C supplementation may be a potential prophylactic
                  treatment for secondary adhesive capsulitis in patients undergoing operative treatment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Elbow</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Repeated corticosteroid injections can cause tendon damage
                  or rupture if used too often. This brings into question whether too many preoperative corticosteroid
                  injections may lead to poor outcomes of surgery on tendons, but there had been no prior literature on
                  the effect of repeat corticosteroid injections on lateral epicondylitis surgery with a modified
                  Nirschl technique. In a case series of 99 patients, Ha et al. found that linear regression models did
                  not show a significant impact of preoperative corticosteroid injections on postoperative clinical
                  outcomes<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Hand</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Carpal tunnel syndrome is characterized by symptomatic
                  compression of the median nerve as it passes through the wrist at the transverse carpal ligament. A
                  systematic review and network meta-analysis of 12 RCTs compared short-term effectiveness of 5%
                  dextrose in water (D5W), platelet-rich plasma (PRP), and corticosteroid injections for carpal tunnel
                  syndrome<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a>. The
                  results of the study recommended PRP injections as the most likely to relieve symptoms, improve
                  function, and alleviate pain; however, all 3 treatments were significantly better than placebo (normal
                  saline solution injections).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">A separate study compared the efficacy of focused ESWT in
                  patients with moderate-to-severe carpal tunnel syndrome<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">8</span></a>. In a
                  single-blinded RCT, participants were allocated either to the intervention group receiving ESWT plus
                  conservative treatment or to the control group receiving conservative treatment alone consisting of
                  gliding exercises, night splints, and activity modification. Administration of the Thai version of the
                  Boston Carpal Tunnel Questionnaire (T-BCTQ), nerve conduction studies, and ultrasonography of the
                  median nerve cross-sectional area were performed before treatment and at 3 and 6 weeks. Both groups
                  had significant improvement in the T-BCTQ, with the improvement favoring ESWT; however, there was
                  significant improvement in electrodiagnostic findings at 3 weeks after treatment only with ESWT.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Chou et al. performed a systematic review to evaluate the
                  clinical effectiveness and safety of ultrasound-guided percutaneous carpal tunnel release<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">9</span></a>. The 20 studies (3 RCTs, 3 prospective cohort studies, and
                  14 case series) meeting inclusion criteria for ultrasound-guided percutaneous carpal tunnel release (n
                  = 1,772) were found to have very low levels of evidence. Despite this, the authors concluded that
                  these early studies were suggestive of a relatively safe, effective treatment option for carpal tunnel
                  syndrome with the potential for shorter recovery time.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Although typically treated conservatively, several
                  surgical and minimally invasive techniques have been proposed to treat de Quervain tenosynovitis.
                  Beidleman et al. performed a retrospective case series utilizing a complete percutaneous release of
                  the first dorsal compartment with ultrasound guidance<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">10</span></a>.
                  Primary outcome measures showed significant pain reduction as determined by the Numeric Rating Scale
                  and improvement in function as determined by the Nirschl Phase scale at short-term and long-term
                  follow-ups. The authors concluded that this novel, minimally invasive technique may provide a safe and
                  effective treatment option for patients who have had failure of conservative management.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Hip</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Ebert et al. evaluated the response to an
                  ultrasound-guided hip corticosteroid injection combined with a 12-week structured rehabilitation
                  program in patients with chronic magnetic resonance imaging-confirmed femoroacetabular impingement
                  (FAI) syndrome<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">11</span></a>.
                  After 8 weeks of rehabilitation, 32% of patients pursued surgical intervention. Those who did not
                  pursue a surgical procedure had a significant increase in range of motion, isometric hip strength, and
                  patient-reported outcomes at 8 weeks and up until 24 months after the injection.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Husen et al. studied the long-term progression of hip
                  osteoarthritis in young patients with symptomatic FAI who were treated nonoperatively compared with
                  patients treated with arthroscopic surgery<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">12</span></a>.
                  After at least 5 years of follow-up, the nonoperative group had a significantly higher rate of
                  progression of osteoarthritis to Tönnis grade 2 compared with the arthroscopy group. Hip arthroscopy
                  may prevent the progression of osteoarthritis in patients with FAI.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">There has been limited literature on the duration of
                  physical therapy to use following hip arthroscopy for FAI. In a cohort study, Kaplan et al.
                  demonstrated a plateau in improvement on the Lower Extremity Functional Scale after 40 physical
                  therapy sessions<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">13</span></a>.
                  Patients who completed 3 to 6 months of physical therapy after hip arthroscopy had better 2-year
                  outcomes compared with the cohorts who completed 0 to 3 months and 6 to 12 months of physical therapy.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">There has been mixed literature on the prevalence of
                  rapidly progressive osteoarthritis of the hip, ranging from 7% to 44% in various studies. In a case
                  series including 924 subjects, Sanguino et al. found the prevalence of rapidly progressive
                  osteoarthritis of the hip following intra-articular corticosteroid injection to be 2.8%, with 50% of
                  those developing rapidly progressive osteoarthritis of the hip doing so within the first 3 months
                  after the injection<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">14</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Knee</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Research on the long-term outcomes of nonoperatively
                  treated anterior cruciate ligament (ACL) tears has been limited. In a case series, Hellberg et al.
                  reviewed 100 subjects who had sustained an ACL tear &gt;30 years prior and were initially treated
                  nonoperatively<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">15</span></a>. Of
                  the 75% who developed radiographic knee osteoarthritis, only 38% were symptomatic and knee function
                  was good for most of the subjects. However, one-half of the patients did undergo meniscal surgery, and
                  approximately one-third of the patients ultimately chose to pursue ACL reconstruction for persistent
                  symptomatic instability.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Kinesiophobia can impact rehabilitation and subsequent
                  return to sport following ACL reconstruction. In a cross-sectional study, Ohji et al. evaluated 31
                  athletes (mean age, 20 years) who underwent primary unilateral ACL reconstruction and subsequently
                  returned to sport<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">16</span></a>.
                  Increased kinesiophobia was seen in subjects with impaired preparatory vastus medialis activity during
                  landing as well as those with greater knee pain intensity. In an RCT of 100 amateur male athletes
                  (mean age, 22 years), Elabd et al. found that a combination of an accelerated rehabilitation protocol
                  and a conventional rehabilitation protocol after ACL reconstruction proved more efficacious than a
                  conventional rehabilitation protocol alone for improving knee pain and function<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">17</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Foot and
                Ankle</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Dextrose prolotherapy shows promise as a low-cost,
                  efficacious, and safe treatment for plantar fasciopathy. Two systematic reviews and meta-analyses of
                  the same 8 RCTs on the use of prolotherapy to treat plantar fasciopathy were conducted. Chutumstid et
                  al. reported greater improvements in VAS foot pain scores with prolotherapy treatment when assessed at
                  &lt;6-month follow-up compared with non-active treatment groups (exercise, normal saline solution
                  injection) but no difference when compared with active treatment controls (ESWT, corticosteroid
                  injection, PRP)<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">18</span></a>.
                  Prolotherapy was more effective than all other groups in improving foot function and reducing plantar
                  fascia thickness at &lt;6-month follow-up but showed no difference at ≥6 months. There were no
                  differences in VAS scores between groups at ≥6 months. However, Fong et al. reported prolotherapy to
                  be less efficacious for pain reduction when compared with corticosteroid injection at &lt;3 months but
                  more efficacious for improving pain and function at 3 to 6.5 months when compared with normal saline
                  solution injection<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">19</span></a>.
                  There were no severe adverse events reported<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">19</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">ESWT may be used for treatment of plantar fasciopathy but
                  may be more effective with combined use of local vibration. In an RCT, On and Yim evaluated the use of
                  radial ESWT alone and radial ESWT in combination with local vibration for 5 weeks (2 sessions per
                  week)<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">20</span></a>.
                  Both groups experienced significant improvements in pain and function and reduction in plantar fascia
                  thickness; however, greater improvements in pain and reduction in plantar fascia thickness were seen
                  in the combined ESWT and local vibration group compared with ESWT alone.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Spine</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Rodríguez-Sanz et al. performed an anatomical study on 40
                  cadavers in a cross-sectional method to describe the accuracy of ultrasound-guided needle placement
                  along the L5 nerve root<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">21</span></a>.
                  The angulation, length, distance from the vertebral spine, relevant ultrasound anatomical references,
                  and accuracy of the procedure were evaluated. The results demonstrated that this approach was accurate
                  in 72.5% of the cases when performed by an experienced clinician and may be a relevant option because
                  it avoids radiation exposure and has potentially lower economic costs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Sousa et al. conducted a systematic review and
                  meta-analysis of 102 studies on the effects of active video game (AVG) intervention on postural
                  balance<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">22</span></a>.
                  The overall study quality was determined to be low, but AVG intervention groups showed significantly
                  larger effects compared with passive controls and conventional treatments. Children, followed by
                  seniors, experienced larger treatment effects, and the largest effect on balance improvement was seen
                  in healthy individuals without medical conditions. The authors concluded that AVG interventions can
                  improve postural balance and provide benefit in all populations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">High-intensity laser therapy is a noninvasive treatment
                  modality delivering targeted, high-powered laser energy to stimulate deep tissue structures and reduce
                  pain in neuropathic diseases. İnce et al. conducted a prospective, randomized, placebo-controlled
                  trial to evaluate the efficacy of high-intensity laser therapy in cervical radiculopathy<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">23</span></a>. Ninety patients were randomized to high-intensity laser
                  therapy and exercise, placebo and exercise, and exercise-only groups and assessed with the 36-item
                  Short-Form (SF-36) survey up to 12 weeks. Although all 3 groups showed improvement, the high-intensity
                  laser therapy and exercise group demonstrated the greatest improvements, and these interventions may
                  be considered as future treatment options.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The application of noninvasive, short, high-energy pulsed
                  electromagnetic fields has the potential to therapeutically influence intracellular structures.
                  Hartard et al. performed a prospective, randomized, sham-controlled clinical trial for the treatment
                  of unspecified back pain<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">24</span></a>.
                  Pain intensity (VAS), local oxyhemoglobin saturation, heart rate, blood pressure, and the perfusion
                  index were evaluated before and after interventions. Results demonstrated a reduction only in pain
                  intensity for the treatment group compared with sham.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Arthroplasty</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">A prospective cohort study evaluated preoperative
                  predictors of instrumental activities of daily living (ADL) in older adults (≥65 years of age) at 6
                  months after knee arthroplasty<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">25</span></a>.
                  Instrumental ADL status was evaluated for 6 activities at 1 month preoperatively and 6 months
                  postoperatively. Participants chose “able,” “need help,” or “unable.” If they chose “need help” or
                  “unable” for ≥1 items, they were defined as “disabled.” At 6 months, 50% reported instrumental ADL
                  disability, with the usual gait speed being the only significant independent predictor.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">In an RCT, 20 patients who would be undergoing total knee
                  arthroplasty (TKA) were randomized into a prehabilitation group or a control group<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">26</span></a>. Feasibility, self-reported knee function, pain, physical
                  performance, and hospital length of stay were assessed at baseline and at 6 and 12 weeks
                  postoperatively. Personalized prehabilitation was found to be feasible, significantly favored on the
                  Knee Injury and Osteoarthritis Outcome Score (KOOS) and the Tegner Activity Scale, and safe, without
                  adverse events, supporting the effectiveness of prehabilitation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">An RCT with 24 patients tested the feasibility and
                  outcomes of a unilateral strengthening program focused only on the leg that had undergone TKA compared
                  with standard postoperative bilateral lower-limb training programs<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">27</span></a>. Conducted over 6 sessions in 3 weeks of inpatient
                  rehabilitation, the study measured isometric strength, knee flexibility, circumference, rising from a
                  chair, walking ability, perceived exertion, and pain. The study showed that unilateral strength
                  training was feasible and improved strength and flexibility better than bilateral training.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">A retrospective cohort study included the postoperative
                  evaluation of patients who underwent TKA at a large academic center over 5 years and were discharged
                  to a skilled nursing facility or home health care<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">28</span></a>.
                  The purpose was to examine 30-day and 90-day readmission risk, controlling for mobility status,
                  caregiver support, and home area deprivation measures. The study revealed that discharge to a skilled
                  nursing facility was independently associated with 90-day readmission but not with 30-day readmission
                  after controlling for the above factors.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Pain
                Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Genicular nerve radiofrequency ablation (RFA) is a
                  minimally invasive procedure used to treat chronic knee pain, most often in patients with
                  osteoarthritis or after surgery. In a pilot study, Vanneste et al. performed a double-blinded,
                  noninferiority RCT comparing the effects of cooled RFA techniques with those of conventional RFA
                  techniques<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">29</span></a>.
                  Although both groups experienced pain reduction, the primary outcome measure of ≥50% pain reduction at
                  3 months did not demonstrate a significant difference between groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">ESWT is a noninvasive technique used to treat various
                  musculoskeletal conditions, including myofascial pain syndrome. Avendaño-López et al. performed a
                  systematic review and meta-analysis of RCTs of ESWT (radial and focused) for myofascial pain syndrome,
                  focusing on pain and functional improvement<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">30</span></a>.
                  Twenty-seven studies were included and showed ESWT to be more effective in pain reduction and
                  functional improvement compared with control and ultrasound therapy; however, no differences were
                  found between ESWT and dry needling, exercises, infiltration, and laser therapies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Orthobiologics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">A systematic review and meta-analysis evaluated 13 studies
                  comparing injections of PRP with those of corticosteroid for lateral epicondylitis<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">31</span></a>. Symptoms, patient-perceived outcomes, and pain were
                  assessed. The results suggest that PRP has no advantage over corticosteroid injections within the
                  first month of treatment. However, PRP was superior to corticosteroids at both 3 and 6 months,
                  indicating better long-term benefits for patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">A systematic review and meta-analysis reviewed data
                  comparing PRP and corticosteroids for treating rotator cuff disease<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">32</span></a>. Nine studies, including 469 patients, utilized Constant,
                  Simple Shoulder Test, and American Shoulder and Elbow Surgeons (ASES) scores. The results indicated
                  that PRP was more beneficial for long-term (&gt;12 weeks) recovery than corticosteroids, whereas
                  corticosteroids were more effective in the short term (2 to 6 weeks). There was no intermediate-term
                  (6 to 12 weeks) efficacy difference between the 2 groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">In a controlled laboratory study, exosomes from
                  kartogenin-preconditioned human bone marrow mesenchymal stem cells (KGN-Exos) were studied for their
                  ability to promote tendon-to-bone healing in a rat model of chronic rotator cuff tendinopathy<a
                    href="" data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">33</span></a>. Sixty-six rats were randomized into exosome-treated and
                  control groups. The treatment group showed higher glycosaminoglycan and collagen II at 4 and 8 weeks.
                  The findings suggested that KGN-Exos can be a cell-free therapeutic option to accelerate
                  tendon-to-bone healing.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">A systematic review and meta-analysis studied the effect
                  of PRP on range of motion, pain, and disability in adhesive capsulitis<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">34</span></a>. Fourteen studies with 1,139 patients were included. The
                  results indicated that glenohumeral PRP injection significantly improved passive abduction and flexion
                  and reduced disability within 1 month after the injection. Moreover, it improved passive abduction,
                  flexion, and external rotation, as well as pain and disability, at 3 months after injection,
                  suggesting that PRP is an effective treatment for adhesive capsulitis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">A meta-analysis evaluated intra-articular PRP for hip
                  osteoarthritis, focusing on the therapeutic effect, duration, dose influence, and adverse effects<a
                    href="" data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">35</span></a>. Eight studies with 331 patients were included. Significant
                  findings were reduced hip pain, most notably at the 1 to 2-month follow-up, and greater functional
                  improvement, which was only significant at this time. Pain was more significantly impacted with a
                  single dose, a total dose of &lt;15 mL, and leukocyte-poor PRP, indicating specific dosage and
                  composition preferences.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">In a Phase-III, double-blinded RCT, autologous
                  culture-expanded adipose-derived mesenchymal stem cells (ADMSCs) were compared with placebo for knee
                  arthritis (Kellgren-Lawrence grade 3)<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">36</span></a>. In
                  this study, 261 patients received a single ADMSC or placebo injection. ADMSCs yielded significantly
                  better VAS pain and Western Ontario and McMaster Universities Osteoarthritis Index (WOMAC), the
                  primary end points, than placebo at 3 and 6 months of follow-up. Clinical examinations were also
                  significantly better in the ADMSC group at 6 months, suggesting that ADMSCs provide pain relief and
                  functional improvement in Kellgren-Lawrence grade-3 knee arthritis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Sports</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">A men’s National Collegiate Athletic Association (NCAA)
                  soccer team with 23 players was retrospectively analyzed over an 18-week season<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">37</span></a>. GPS (Global Positioning System) instrumentation tracked
                  workload variables, including total distance, high-speed distance, acceleration, player load, and mean
                  velocity. The team’s mean daily acute workload (over the past 3 days) and chronic workload (over the
                  past 28 days) were calculated. Injury days had higher acute workloads and lower chronic workloads for
                  all variables tested, indicating a correlation between workload and injury occurrence.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">NCAA Division-I athletes with neurogenic thoracic outlet
                  syndrome from 2000 to 2020 were studied retrospectively<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">38</span></a>.
                  The study included 23 female athletes and 13 male athletes, aiming to identify symptoms and diagnostic
                  findings. Digit plethysmography showed diminished or obliterated waveforms with provocative maneuvers
                  in 23 of 25 athletes. Forty-two percent continued competing despite symptoms. Among those initially
                  unable to compete, 12% returned after physical therapy, 42% returned after botulinum toxin injections,
                  and 42% returned after thoracic outlet decompression surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Parasports</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Parasports have many unique characteristics that have not
                  been considered much in the past. Many studies have examined the role of gait mechanics in injury and
                  pain risks, but literature on wheelchair propulsion mechanics has remained limited. Braaksma et al.
                  evaluated individuals with spinal cord injury and found that those with shoulder pain had a
                  0.30-second shorter cycle time, 0.22-second shorter recovery time, 15.6° smaller contact angle, and 8%
                  lower variability in work per push compared with those without shoulder pain<a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">39</span></a>. Another unique aspect analyzed in parasports recently was
                  head impact characteristics in blind football. Tsutsumi et al. evaluated the 18 official games at the
                  Tokyo 2020 Paralympic Games and noted that the rate of head impacts with falls was significantly
                  higher in the preliminary phase and the offense phase and during dribbling<a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">40</span></a>. Studies such as these 2 can contribute to the development
                  of parasport-specific injury prevention programs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Ultrasonography</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Ultrasound is a useful diagnostic tool to assess for
                  Achilles tendon abnormalities. In patients with Achilles tendon rupture, there is a higher incidence
                  of contralateral tendon rupture compared with the general population. Park et al. found that patients
                  diagnosed with an acute Achilles tendon rupture did not have ultrasonographic abnormalities of the
                  contralateral Achilles tendon at the time of diagnosis<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_42" class="annotation superscript" style="position: unset;">41</span></a>. As
                  long-term follow-up of the contralateral Achilles tendon was lacking, future studies are needed to
                  assess for contralateral Achilles tendon remodeling after sustaining an acute Achilles tendon rupture.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Provo et al. found that there was no significant
                  difference in the rate of successful glenohumeral aspiration under ultrasonography compared with
                  fluoroscopy<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">42</span></a>.
                  Although significantly more fluid was obtained with aspiration under fluoroscopy in patients with a
                  body mass index (BMI) of &gt;35 kg/m<span data-id="superscript_44" class="annotation superscript"
                    style="position: unset;">2</span>, ultrasound-guided aspiration can be less expensive and less
                  uncomfortable, can be performed in an ambulatory setting, and allows for dynamic visualization in
                  patients with a BMI of ≤35 kg/m<span data-id="superscript_45" class="annotation superscript"
                    style="position: unset;">2</span>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Prosthetics
                and Orthotics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Currently, 46% to 79% of gymnasts wear wrist guards to
                  prevent wrist pain. In a controlled laboratory study, Hart et al. evaluated 23 female athletes with
                  regard to the impact of no wrist supports or 2 common wrist supports while performing back
                  handsprings<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_46" class="annotation superscript" style="position: unset;">43</span></a>.
                  Wrist guards significantly limited wrist extension angles and the total range-of-motion arc but
                  increased the wrist flexion moment compared with no wrist supports. The greater wrist flexion moment
                  could increase injury risk.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">In a controlled laboratory study, Willwacher et al.
                  compared the sports performance, restriction, and subjective perception of 20 uninjured athletes when
                  using an adaptive ankle brace or 2 passive braces (a lace-up ankle brace and a rigid ankle brace)<a
                    href="" data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">44</span></a>. The adaptive brace was similar to the passive braces
                  except that it provided greater patient comfort and range of motion of the ankle during low-velocity
                  movement. The study limitations included evaluating athletes without any prior or current ankle
                  sprains.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Physiatry
                Societies and Upcoming Events</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">There are 3 medical societies in the United States for
                  physical medicine and rehabilitation (PM&R): the Association of Academic Physiatrists (AAP), the
                  American Academy of Physical Medicine and Rehabilitation (AAPM&R), and the American Congress of
                  Rehabilitation Medicine (ACRM). The 2025 AAP Annual Meeting will be in Phoenix, Arizona, from February
                  25 to March 1, 2025. The 2025 AAPM&R Annual Assembly will be in Salt Lake City, Utah, from October 22
                  to 26, 2025. The 2025 ACRM Annual Conference will be held in Chicago, Illinois, from October 28 to 31,
                  2025.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Evidence-Based Orthopaedics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">The editorial staff of <span data-id="emphasis_48"
                    class="annotation emphasis" style="position: unset;">JBJS</span> reviewed a large number of recently
                  published studies related to the musculoskeletal system that received a higher Level of Evidence
                  grade. In addition to articles cited already in this update, 4 other articles relevant to orthopaedic
                  rehabilitation are appended to this review after the standard bibliography, with a brief commentary
                  about each article to help guide your further reading, in an evidence-based fashion, in this
                  subspecialty area.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Evidence-Based Orthopaedics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_47" class="annotation strong"
                    style="position: unset;">Cantrell WA, Cox CL, Johnson C, Obuchowski N, Strnad G, Swinehart D, Yalcin
                    S, Spindler KP.</span> The effect of aspiration and corticosteroid injection after ACL injury on
                  postoperative infection rate. <span data-id="emphasis_49" class="annotation emphasis"
                    style="position: unset;">Am J Sports Med.</span> 2023 Dec;51(14):3665-9.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">Arthroplasty literature has confirmed an increased risk of
                  infections with preoperative corticosteroid injections, and arthroscopy literature has shown that
                  corticosteroid injections at the time of the surgical procedure increase the postoperative infection
                  risk. This retrospective cohort study included 693 patients who underwent bone-patellar tendon-bone
                  (BTB) ACL reconstruction and found no postoperative infections in the 273 patients who had undergone a
                  preoperative aspiration and corticosteroid injection. These data support that a corticosteroid
                  injection after the injury but preoperatively is a safe intervention prior to an ACL reconstruction,
                  but future studies with larger sample sizes, longer follow-ups, and multiple surgeons can further
                  support this recommendation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">This study provides data to counter concerns of infection
                  risks related to corticosteroid injections prior to a surgical procedure and thus opens up a potential
                  anesthetic treatment option for prehabilitation while patients are waiting for ACL reconstruction.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_51" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_48" class="annotation strong"
                    style="position: unset;">Kulkarni R, Guareschi AS, Eichinger JK, Friedman RJ.</span> How using body
                  mass index cutoffs to determine eligibility for total shoulder arthroplasty affects health care
                  disparities. <span data-id="emphasis_50" class="annotation emphasis" style="position: unset;">J
                    Shoulder Elbow Surg.</span> 2023 Nov;32(11):2239-44.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_52" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_52" style="position: unset;">
                <div class="content" style="position: unset;">The use of BMI cutoffs for risk stratification is becoming
                  more common in joint arthroplasty, and this may impact access to care. The authors reviewed 20,872
                  patients who underwent a primary elective total shoulder arthroplasty (TSA), both anatomic and
                  reverse, from 2015 to 2019. They then used 5 separate cutoffs and categorized the patients as
                  “eligible” if they had a BMI that was below the cutoff and “ineligible” if they had a BMI that was
                  above the cutoff. The cutoffs were set at 30, 35, 40, 45, and 50 kg/m<span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">2</span>. For all BMI cutoffs, female and
                  Black patients were more likely to be considered ineligible compared with male and non-Black patients.
                  Conversely, White patients were more likely to be considered eligible than non-White patients for all
                  BMI cutoffs. This shows that physicians may inadvertently perpetuate health-care disparities observed
                  in TSA if they solely use BMI as a risk stratification tool, and that BMI should only be 1 of many
                  factors when determining the eligibility of a patient for TSA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_53" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_53" style="position: unset;">
                <div class="content" style="position: unset;">This study highlights the importance of recognizing health
                  disparities and inequities when it comes to utilizing BMI cutoffs to determine shoulder arthroplasty
                  eligibility. Hopefully, this will lead the medical community to find methods to increase accessibility
                  for quality care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_54" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_54" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_49" class="annotation strong"
                    style="position: unset;">Shah S, Schwenk ES, Sondekoppam RV, Clarke H, Zakowski M, Rzasa-Lynn RS,
                    Yeung B, Nicholson K, Schwartz G, Hooten WM, Wallace M, Viscusi ER, Narouze S.</span> ASRA Pain
                  Medicine consensus guidelines on the management of the perioperative patient on cannabis and
                  cannabinoids. <span data-id="emphasis_51" class="annotation emphasis" style="position: unset;">Reg
                    Anesth Pain Med.</span> 2023 Mar;48(3):97-117.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_55" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_55" style="position: unset;">
                <div class="content" style="position: unset;">With the legalization and decriminalization of cannabis
                  use over the past 2 decades, there are many questions to be answered with regard to the perioperative
                  use of cannabis. The American Society of Regional Anesthesia and Pain Medicine charged the Cannabis
                  Working Group to develop guidelines. Nine questions were selected, with 100% consensus achieved on the
                  third round of voting. One key question involved postoperative considerations in patients taking
                  perioperative cannabinoids. The group decided that acute cannabis intoxication and active cannabis use
                  disorder may be associated with increased risks of acute postoperative myocardial infarction and
                  cerebrovascular morbidity, and that cannabis use and cannabis use disorder may be associated with
                  higher postoperative pain scores and opioid use. As the use of cannabis continues to increase, these
                  guidelines can be very useful to continue optimizing orthopaedic care for all patients in the fluid
                  medical, social, and political landscape regarding cannabis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_56" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_56" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_50" class="annotation strong"
                    style="position: unset;">Shanthanna H, Eldabe S, Provenzano DA, Bouche B, Buchser E, Chadwick R,
                    Doshi TL, Duarte R, Hunt C, Huygen FJPM, Knight J, Kohan L, North R, Rosenow J, Winfree CJ, Narouze
                    S.</span> Evidence-based consensus guidelines on patient selection and trial stimulation for spinal
                  cord stimulation therapy for chronic non-cancer pain. <span data-id="emphasis_52"
                    class="annotation emphasis" style="position: unset;">Reg Anesth Pain Med.</span> 2023
                  Jun;48(6):273-87.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_57" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_57" style="position: unset;">
                <div class="content" style="position: unset;">Spinal cord stimulation is an option for patients with
                  recalcitrant pain despite maximized nonoperative and operative treatments. However, patient selection
                  can be difficult. A committee of experts approved by the Board of Directors of the American Society of
                  Regional Anesthesia and Pain Medicine came together to formulate a consensus statement. The primary
                  recommendations included that a spinal cord stimulation trial (despite some limitations) should be
                  performed before a definitive spinal cord stimulation implant except for anginal pain and that all
                  patients must be screened with an objective, validated instrument for psychosocial factors including
                  depression. This statement helps to optimize patient selection for spinal cord stimulation, which is
                  key for successful outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Griswold D</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Learman K</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Ickert E</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Tapp A</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Ross O</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Dry needling for subacromial
                  pain syndrome: a systematic review with meta-analysis</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Pain Med.</span> 2023 Mar
                1;24(3):285-99.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dry%20needling%20for%20subacromial%20pain%20syndrome%3A%20a%20systematic%20review%20with%20meta-analysis&as_occt=title&as_sauthors=%20%22D%20Griswold%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Kandemir O</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Adar S</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Dündar Ü</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Toktaş H</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Yeşil H</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Eroğlu S</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Eyvaz N</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Effectiveness of pulse
                  electromagnetic field therapy in patients with subacromial impingement syndrome: a double-blind
                  randomized sham controlled study</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Arch Phys Med Rehabil.</span> 2024 Feb;105(2):199-207.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effectiveness%20of%20pulse%20electromagnetic%20field%20therapy%20in%20patients%20with%20subacromial%20impingement%20syndrome%3A%20a%20double-blind%20randomized%20sham%20controlled%20study&as_occt=title&as_sauthors=%20%22O%20Kandemir%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> ElGendy MH</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Mazen MM</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Saied AM</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> ElMeligie MM</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Aneis Y</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Extracorporeal shock wave
                  therapy vs. corticosteroid local injection in shoulder impingement syndrome: a three-arm randomized
                  controlled trial</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Am J Phys Med Rehabil.</span> 2023 Jun 1;102(6):533-40.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Extracorporeal%20shock%20wave%20therapy%20vs.%20corticosteroid%20local%20injection%20in%20shoulder%20impingement%20syndrome%3A%20a%20three-arm%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22MH%20ElGendy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Angileri HS</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Gohal C</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Comeau-Gauthier M</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Owen MM</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Shanmugaraj A</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Terry MA</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Tjong VK</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Khan M</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Chronic calcific tendonitis of
                  the rotator cuff: a systematic review and meta-analysis of randomized controlled trials comparing
                  operative and nonoperative interventions</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2023
                Aug;32(8):1746-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Chronic%20calcific%20tendonitis%20of%20the%20rotator%20cuff%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20randomized%20controlled%20trials%20comparing%20operative%20and%20nonoperative%20interventions&as_occt=title&as_sauthors=%20%22HS%20Angileri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Feusi O</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Fleischmann T</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Waschkies C</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Pape HC</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Werner CML</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Tiziani S</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Vitamin C as a potential
                  prophylactic measure against frozen shoulder in an in vivo shoulder contracture animal model</span>.
                <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2023 Jul;51(8):2041-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vitamin%20C%20as%20a%20potential%20prophylactic%20measure%20against%20frozen%20shoulder%20in%20an%20in%20vivo%20shoulder%20contracture%20animal%20model&as_occt=title&as_sauthors=%20%22O%20Feusi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Ha C</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Cho W</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Hong IT</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Park J</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Ahn W</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Han SH</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Effect of repetitive
                  corticosteroid injection on tennis elbow surgery</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2023
                Jun;51(7):1886-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20repetitive%20corticosteroid%20injection%20on%20tennis%20elbow%20surgery&as_occt=title&as_sauthors=%20%22C%20Ha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Gao N</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Yan L</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Ai F</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Kang J</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Wang L</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Weng Y</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Comparison of the short-term
                  clinical effectiveness of 5% dextrose water, platelet-rich plasma and corticosteroid injections for
                  carpal tunnel syndrome: a systematic review and network meta-analysis of randomized controlled
                  trials</span>. <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Arch
                  Phys Med Rehabil.</span> 2023 May;104(5):799-811.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20the%20short-term%20clinical%20effectiveness%20of%205%25%20dextrose%20water%2C%20platelet-rich%20plasma%20and%20corticosteroid%20injections%20for%20carpal%20tunnel%20syndrome%3A%20a%20systematic%20review%20and%20network%20meta-analysis%20of%20randomized%20controlled%20trials&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Vongvachvasin P</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Phakdepiboon T</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Chira-Adisai W</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Siriratna P</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Efficacy of focused shockwave
                  therapy in patients with moderate-to-severe carpal tunnel syndrome: a preliminary study</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Rehabil Med.</span> 2024
                Feb 8;56:jrm13411.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20focused%20shockwave%20therapy%20in%20patients%20with%20moderate-to-severe%20carpal%20tunnel%20syndrome%3A%20a%20preliminary%20study&as_occt=title&as_sauthors=%20%22P%20Vongvachvasin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Chou RC</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Robinson DM</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Homer S</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Ultrasound-guided percutaneous
                  carpal tunnel release: a systematic review</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">PM R.</span> 2023 Mar;15(3):363-79.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ultrasound-guided%20percutaneous%20carpal%20tunnel%20release%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22RC%20Chou%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Beidleman MB</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Colberg RE</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Beason DP</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Fleisig GS</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">A retrospective case series
                  study on a minimally invasive ultrasound-guided first dorsal compartment release technique for
                  refractory De Quervain tenosynovitis</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Am J Phys Med Rehabil.</span> 2023 Mar 1;102(3):235-40.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20retrospective%20case%20series%20study%20on%20a%20minimally%20invasive%20ultrasound-guided%20first%20dorsal%20compartment%20release%20technique%20for%20refractory%20De%20Quervain%20tenosynovitis&as_occt=title&as_sauthors=%20%22MB%20Beidleman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Ebert JR</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Raymond AC</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Aujla RS</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> D’Alessandro P</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">The effect of a formal
                  nonoperative management program combining a hip injection with structured adjunctive exercise
                  rehabilitation in patients with symptomatic femoroacetabular impingement syndrome</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2023 Mar;51(3):694-706.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20a%20formal%20nonoperative%20management%20program%20combining%20a%20hip%20injection%20with%20structured%20adjunctive%20exercise%20rehabilitation%20in%20patients%20with%20symptomatic%20femoroacetabular%20impingement%20syndrome&as_occt=title&as_sauthors=%20%22JR%20Ebert%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Husen M</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Leland DP</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Melugin HP</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Poudel K</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Hevesi M</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Levy BA</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Krych AJ</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Progression of osteoarthritis
                  at long-term follow-up in patients treated for symptomatic femoroacetabular impingement with hip
                  arthroscopy compared with nonsurgically treated patients</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2023
                Sep;51(11):2986-95.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Progression%20of%20osteoarthritis%20at%20long-term%20follow-up%20in%20patients%20treated%20for%20symptomatic%20femoroacetabular%20impingement%20with%20hip%20arthroscopy%20compared%20with%20nonsurgically%20treated%20patients&as_occt=title&as_sauthors=%20%22M%20Husen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Kaplan DJ</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Larson JH</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Fenn TW</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Allahabadi S</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Malloy P</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Nho SJ</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Use and effectiveness of
                  physical therapy after hip arthroscopy for femoroacetabular impingement</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2023 Jul;51(8):2141-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Use%20and%20effectiveness%20of%20physical%20therapy%20after%20hip%20arthroscopy%20for%20femoroacetabular%20impingement&as_occt=title&as_sauthors=%20%22DJ%20Kaplan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Sanguino RA</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Sood V</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Santiago KA</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Cheng J</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Casey E</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Mintz D</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Wyss JF</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Prevalence of rapidly
                  progressive osteoarthritis of the hip following intra-articular steroid injections</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">PM R.</span> 2023
                Mar;15(3):259-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20rapidly%20progressive%20osteoarthritis%20of%20the%20hip%20following%20intra-articular%20steroid%20injections&as_occt=title&as_sauthors=%20%22RA%20Sanguino%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Hellberg C</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Kostogiannis I</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Stylianides A</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Neuman P</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Outcomes &gt;30 years after
                  initial nonoperative treatment of anterior cruciate ligament injuries</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2024 Feb;52(2):320-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20%3E30%20years%20after%20initial%20nonoperative%20treatment%20of%20anterior%20cruciate%20ligament%20injuries&as_occt=title&as_sauthors=%20%22C%20Hellberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Ohji S</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Aizawa J</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Hirohata K</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Ohmi T</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Mitomo S</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Koga H</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Yagishita K</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Association between landing
                  biomechanics, knee pain, and kinesiophobia in athletes following anterior cruciate ligament
                  reconstruction: a cross-sectional study</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">PM R.</span> 2023 May;15(5):552-62.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20landing%20biomechanics%2C%20knee%20pain%2C%20and%20kinesiophobia%20in%20athletes%20following%20anterior%20cruciate%20ligament%20reconstruction%3A%20a%20cross-sectional%20study&as_occt=title&as_sauthors=%20%22S%20Ohji%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Elabd OM</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Alghadir AH</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Ibrahim AR</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Hasan S</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Rizvi MR</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Sharma A</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Iqbal A</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Elabd AM</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Functional outcomes of
                  accelerated rehabilitation protocol for anterior cruciate ligament reconstruction in amateur athletes:
                  a randomized clinical trial</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">J Rehabil Med.</span> 2024 Feb 22;56:jrm12296.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Functional%20outcomes%20of%20accelerated%20rehabilitation%20protocol%20for%20anterior%20cruciate%20ligament%20reconstruction%20in%20amateur%20athletes%3A%20a%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22OM%20Elabd%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Chutumstid T</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Susantitaphong P</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Koonalinthip N</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Effectiveness of dextrose
                  prolotherapy for the treatment of chronic plantar fasciitis: a systematic review and meta-analysis of
                  randomized controlled trials</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">PM R.</span> 2023 Mar;15(3):380-91.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effectiveness%20of%20dextrose%20prolotherapy%20for%20the%20treatment%20of%20chronic%20plantar%20fasciitis%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20randomized%20controlled%20trials&as_occt=title&as_sauthors=%20%22T%20Chutumstid%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Fong HPY</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Zhu MT</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Rabago DP</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Reeves KD</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Chung VCH</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Sit RWS</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Effectiveness of hypertonic
                  dextrose injection (prolotherapy) in plantar fasciopathy: a systematic review and meta-analysis of
                  randomized controlled trials</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Arch Phys Med Rehabil.</span> 2023 Nov;104(11):1941-1953.e9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effectiveness%20of%20hypertonic%20dextrose%20injection%20(prolotherapy)%20in%20plantar%20fasciopathy%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20randomized%20controlled%20trials&as_occt=title&as_sauthors=%20%22HPY%20Fong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> On H</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Yim J</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Effects of local vibration
                  combined with extracorporeal shock wave therapy in plantar fasciitis: a randomized controlled
                  trial</span>. <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J
                  Rehabil Med.</span> 2023 Oct 23;55:jrm12405.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20local%20vibration%20combined%20with%20extracorporeal%20shock%20wave%20therapy%20in%20plantar%20fasciitis%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22H%20On%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Rodríguez-Sanz J</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Borrella-Andrés S</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Pérez-Bellmunt A</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Fernández-de-Las-Peñas C</span>,
                <span data-id="annotation_113" class="annotation" style="position: unset;"> Albarova-Corral I</span>,
                <span data-id="annotation_114" class="annotation" style="position: unset;"> López-de-Celis C</span>,
                <span data-id="annotation_115" class="annotation" style="position: unset;"> Arias-Buría JL</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> González-Rueda V</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Malo-Urriés M</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Accuracy of ultrasound-guided
                  needle placement on the L5 lumbar nerve root: a cadaveric study</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Am J Phys Med Rehabil.</span> 2023 Dec
                1;102(12):1091-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Accuracy%20of%20ultrasound-guided%20needle%20placement%20on%20the%20L5%20lumbar%20nerve%20root%3A%20a%20cadaveric%20study&as_occt=title&as_sauthors=%20%22J%20Rodr%C3%ADguez-Sanz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Sousa CV</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Lee K</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Alon D</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Sternad D</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Lu AS</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">A systematic review and
                  meta-analysis of the effect of active video games on postural balance</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Arch Phys Med
                  Rehabil.</span> 2023 Apr;104(4):631-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20review%20and%20meta-analysis%20of%20the%20effect%20of%20active%20video%20games%20on%20postural%20balance&as_occt=title&as_sauthors=%20%22CV%20Sousa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> İnce S</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Eyvaz N</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Dündar Ü</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Toktaş H</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Yeşil H</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Eroğlu S</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Adar S</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Clinical efficiency of
                  high-intensity laser therapy in patients with cervical radiculopathy: a 12-week follow-up, randomized,
                  placebo-controlled trial</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Am J Phys Med Rehabil.</span> 2024 Jan 1;103(1):3-12.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20efficiency%20of%20high-intensity%20laser%20therapy%20in%20patients%20with%20cervical%20radiculopathy%3A%20a%2012-week%20follow-up%2C%20randomized%2C%20placebo-controlled%20trial&as_occt=title&as_sauthors=%20%22S%20%C4%B0nce%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Hartard M</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Fenneni MA</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Scharla S</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Hartard C</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Hartard D</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Mueller S</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Botta Mendez G</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Ben Saad H</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Electromagnetic induction for
                  treatment of unspecific back pain: a prospective randomized sham-controlled clinical trial</span>.
                <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">J Rehabil Med.</span>
                2023 Apr 28;55:jrm00389.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Electromagnetic%20induction%20for%20treatment%20of%20unspecific%20back%20pain%3A%20a%20prospective%20randomized%20sham-controlled%20clinical%20trial&as_occt=title&as_sauthors=%20%22M%20Hartard%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Nanjo K</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Ikeda T</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Nagashio N</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Sakai T</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Jinno T</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Preoperative predictors of
                  instrumental activities of daily living disability in older adults 6 months after knee
                  arthroplasty</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">Arch Phys Med Rehabil.</span> 2023 Aug;104(8):1260-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20predictors%20of%20instrumental%20activities%20of%20daily%20living%20disability%20in%20older%20adults%206%20months%20after%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22K%20Nanjo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Gränicher P</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Mulder L</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Lenssen T</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Fucentese SF</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Swanenburg J</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> De Bie R</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Scherr J</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Exercise- and education-based
                  prehabilitation before total knee arthroplasty: a pilot study</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">J Rehabil Med.</span> 2024 Jan
                8;56:jrm18326.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Exercise-%20and%20education-based%20prehabilitation%20before%20total%20knee%20arthroplasty%3A%20a%20pilot%20study&as_occt=title&as_sauthors=%20%22P%20Gr%C3%A4nicher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Tilp M</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Ringler S</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Mariacher H</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Rafolt D</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Unilateral strength training
                  after total knee arthroplasty leads to similar or better effects on strength and flexibility than
                  bilateral strength training - a randomized controlled pilot study</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">J Rehabil Med.</span> 2023 Apr
                19;55:jrm00381.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Unilateral%20strength%20training%20after%20total%20knee%20arthroplasty%20leads%20to%20similar%20or%20better%20effects%20on%20strength%20and%20flexibility%20than%20bilateral%20strength%20training%20-%20a%20randomized%20controlled%20pilot%20study&as_occt=title&as_sauthors=%20%22M%20Tilp%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Johnson JK</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Pasqualini I</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Tidd J</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Klika AK</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Jones G</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Piuzzi NS</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Considering mobility status and
                  home environment in readmission risk after total knee arthroplasty</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2023 Dec
                20;105(24):1987-92.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=f0e443c9-cf9c-403e-81bb-cd1724ba532d" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Vanneste T</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Belba A</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Kallewaard JW</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> van Kuijk SMJ</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Gelissen M</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Emans P</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Bellemans J</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Smeets K</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Terwiel C</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Van Boxem K</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Sommer M</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Van Zundert J</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Comparison of cooled versus
                  conventional radiofrequency treatment of the genicular nerves for chronic knee pain: a multicenter
                  non-inferiority randomized pilot trial (COCOGEN trial)</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Reg Anesth Pain Med.</span> 2023
                May;48(5):197-204.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20cooled%20versus%20conventional%20radiofrequency%20treatment%20of%20the%20genicular%20nerves%20for%20chronic%20knee%20pain%3A%20a%20multicenter%20non-inferiority%20randomized%20pilot%20trial%20(COCOGEN%20trial)&as_occt=title&as_sauthors=%20%22T%20Vanneste%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Avendaño-López C</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Megía-García Á</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Beltran-Alacreu H</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Serrano-Muñoz D</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Arroyo-Fernández R</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Comino-Suárez N</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Avendaño-Coy J</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Efficacy of extracorporeal
                  shockwave therapy on pain and function in myofascial pain syndrome: a systematic review and
                  meta-analysis of randomized clinical trials</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">Am J Phys Med Rehabil.</span> 2024 Feb
                1;103(2):89-98.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20extracorporeal%20shockwave%20therapy%20on%20pain%20and%20function%20in%20myofascial%20pain%20syndrome%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20randomized%20clinical%20trials&as_occt=title&as_sauthors=%20%22C%20Avenda%C3%B1o-L%C3%B3pez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Hohmann E</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Tetsworth K</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Glatt V</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Corticosteroid injections for
                  the treatment of lateral epicondylitis are superior to platelet-rich plasma at 1 month but
                  platelet-rich plasma is more effective at 6 months: an updated systematic review and meta-analysis of
                  level 1 and 2 studies</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Sep;32(9):1770-83.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Corticosteroid%20injections%20for%20the%20treatment%20of%20lateral%20epicondylitis%20are%20superior%20to%20platelet-rich%20plasma%20at%201%20month%20but%20platelet-rich%20plasma%20is%20more%20effective%20at%206%20months%3A%20an%20updated%20systematic%20review%20and%20meta-analysis%20of%20level%201%20and%202%20studies&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Peng Y</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Li F</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Ding Y</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Sun X</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Wang G</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Jia S</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Zheng C</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Comparison of the effects of
                  platelet-rich plasma and corticosteroid injection in rotator cuff disease treatment: a systematic
                  review and meta-analysis</span>. <span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Jun;32(6):1303-13.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20the%20effects%20of%20platelet-rich%20plasma%20and%20corticosteroid%20injection%20in%20rotator%20cuff%20disease%20treatment%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22Y%20Peng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Cai J</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Xu J</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Ye Z</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Wang L</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Zheng T</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Zhang T</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Jiang J</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Zhao J</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Exosomes derived from
                  kartogenin-preconditioned mesenchymal stem cells promote cartilage formation and collagen maturation
                  for enthesis regeneration in a rat model of chronic rotator cuff tear</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2023 Apr;51(5):1267-76.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Exosomes%20derived%20from%20kartogenin-preconditioned%20mesenchymal%20stem%20cells%20promote%20cartilage%20formation%20and%20collagen%20maturation%20for%20enthesis%20regeneration%20in%20a%20rat%20model%20of%20chronic%20rotator%20cuff%20tear&as_occt=title&as_sauthors=%20%22J%20Cai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Lin HW</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Tam KW</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Liou TH</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Rau CL</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Huang SW</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Hsu TH</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Efficacy of platelet-rich
                  plasma injection on range of motion, pain, and disability in patients with adhesive capsulitis: a
                  systematic review and meta-analysis</span>. <span data-id="emphasis_34" class="annotation emphasis"
                  style="position: unset;">Arch Phys Med Rehabil.</span> 2023 Dec;104(12):2109-22.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20platelet-rich%20plasma%20injection%20on%20range%20of%20motion%2C%20pain%2C%20and%20disability%20in%20patients%20with%20adhesive%20capsulitis%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22HW%20Lin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Lim A</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Zhu JB</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Khanduja V</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">The use of intra-articular
                  platelet-rich plasma as a therapeutic intervention for hip osteoarthritis: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2023 Jul;51(9):2487-97.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20intra-articular%20platelet-rich%20plasma%20as%20a%20therapeutic%20intervention%20for%20hip%20osteoarthritis%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22A%20Lim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Kim KI</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Lee MC</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Lee JH</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Moon YW</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Lee WS</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Lee HJ</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Hwang SC</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> In Y</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Shon OJ</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Bae KC</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Song SJ</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Park KK</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Kim JH</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Clinical efficacy and safety of
                  the intra-articular injection of autologous adipose-derived mesenchymal stem cells for knee
                  osteoarthritis: a phase III, randomized, double-blind, placebo-controlled trial</span>. <span
                  data-id="emphasis_36" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2023 Jul;51(9):2243-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20efficacy%20and%20safety%20of%20the%20intra-articular%20injection%20of%20autologous%20adipose-derived%20mesenchymal%20stem%20cells%20for%20knee%20osteoarthritis%3A%20a%20phase%20III%2C%20randomized%2C%20double-blind%2C%20placebo-controlled%20trial&as_occt=title&as_sauthors=%20%22KI%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Bakal DR</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Friedrich TR</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Keane G</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> White B</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Roh EY</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Team’s average acute:chronic
                  workload ratio correlates with injury risk in NCAA men’s soccer team</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">PM R.</span> 2023
                Sep;15(9):1140-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Team%E2%80%99s%20average%20acute%3Achronic%20workload%20ratio%20correlates%20with%20injury%20risk%20in%20NCAA%20men%E2%80%99s%20soccer%20team&as_occt=title&as_sauthors=%20%22DR%20Bakal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Olson EM</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Dyrek P</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Harris T</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Fereydooni A</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Lee JT</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Kussman A</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Roh E</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Neurogenic thoracic outlet
                  syndrome in Division 1 collegiate athletes: presentation, diagnosis, and treatment</span>. <span
                  data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Clin J Sport Med.</span>
                2023 Sep 1;33(5):467-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Neurogenic%20thoracic%20outlet%20syndrome%20in%20Division%201%20collegiate%20athletes%3A%20presentation%2C%20diagnosis%2C%20and%20treatment&as_occt=title&as_sauthors=%20%22EM%20Olson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Braaksma J</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Vegter RJK</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Leving MT</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> van der Scheer JW</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Tepper M</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Woldring FAB</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> van der Woude LHV</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Houdijk H</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> de Groot S</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Handrim wheelchair propulsion
                  technique in individuals with spinal cord injury with and without shoulder pain: a cross-sectional
                  comparison</span>. <span data-id="emphasis_39" class="annotation emphasis" style="position: unset;">Am
                  J Phys Med Rehabil.</span> 2023 Oct 1;102(10):886-95.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Handrim%20wheelchair%20propulsion%20technique%20in%20individuals%20with%20spinal%20cord%20injury%20with%20and%20without%20shoulder%20pain%3A%20a%20cross-sectional%20comparison&as_occt=title&as_sauthors=%20%22J%20Braaksma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Tsutsumi S</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Sasadai J</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Maeda N</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Shimizu R</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Suzuki A</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Fukui K</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Arima S</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Tashiro T</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Kaneda K</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Yoshimi M</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Mizuta R</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Ishihara H</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Esaki H</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Tsuchida K</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Terada T</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Komiya M</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Urabe Y</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Head impact in blind football
                  during the Tokyo Paralympics: video-based observational study</span>. <span data-id="emphasis_40"
                  class="annotation emphasis" style="position: unset;">Am J Phys Med Rehabil.</span> 2023 Sep
                1;102(9):836-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Head%20impact%20in%20blind%20football%20during%20the%20Tokyo%20Paralympics%3A%20video-based%20observational%20study&as_occt=title&as_sauthors=%20%22S%20Tsutsumi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Park YH</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Kim W</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Choi JW</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Kim HJ</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Ultrasonographic finding of
                  contralateral Achilles tendon in patients with acute Achilles tendon rupture: a prospective
                  observational study</span>. <span data-id="emphasis_41" class="annotation emphasis"
                  style="position: unset;">Clin J Sport Med.</span> 2023 Nov 1;33(6):598-602.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ultrasonographic%20finding%20of%20contralateral%20Achilles%20tendon%20in%20patients%20with%20acute%20Achilles%20tendon%20rupture%3A%20a%20prospective%20observational%20study&as_occt=title&as_sauthors=%20%22YH%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Provo J</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Teramoto M</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Foley J</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Roesly H</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Gee C</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Cushman DM</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Comparing fluoroscopic versus
                  ultrasound guidance in glenohumeral joint aspiration</span>. <span data-id="emphasis_42"
                  class="annotation emphasis" style="position: unset;">PM R.</span> 2023 Jun;15(6):697-704.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparing%20fluoroscopic%20versus%20ultrasound%20guidance%20in%20glenohumeral%20joint%20aspiration&as_occt=title&as_sauthors=%20%22J%20Provo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Hart E</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Whited A</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Bae DS</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Bauer AS</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Sugimoto D</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Wrist guards/supports in
                  gymnastics: are they helping or hurting you?</span><span data-id="emphasis_43"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2023
                Nov;51(13):3426-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Wrist%20guards%2Fsupports%20in%20gymnastics%3A%20are%20they%20helping%20or%20hurting%20you%3F&as_occt=title&as_sauthors=%20%22E%20Hart%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Willwacher S</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Bruder A</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Robbin J</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Kruppa J</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Mai P</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">A multidimensional assessment
                  of a novel adaptive versus traditional passive ankle sprain protection systems</span>. <span
                  data-id="emphasis_44" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2023 Mar;51(3):715-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20multidimensional%20assessment%20of%20a%20novel%20adaptive%20versus%20traditional%20passive%20ankle%20sprain%20protection%20systems&as_occt=title&as_sauthors=%20%22S%20Willwacher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D1f8df2b0-e3a9-43f0-8428-3b7536fc74d2%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F22%2F2055%26topics%3Drb"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Guest Editorial</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2055</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00841</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_45"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Physical Medicine and Rehabilitation, University of Texas Southwestern Medical
                            Center, Dallas, Texas</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;1f8df2b0-e3a9-43f0-8428-3b7536fc74d2&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;rb&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=1f8df2b0-e3a9-43f0-8428-3b7536fc74d2&type=pdf&name=JBJS.24.00841.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=1f8df2b0-e3a9-43f0-8428-3b7536fc74d2&type=pdf&name=JBJS.24.00841.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_46" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I190" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I190</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;1f8df2b0-e3a9-43f0-8428-3b7536fc74d2&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;rb&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=1f8df2b0-e3a9-43f0-8428-3b7536fc74d2&type=zip&name=JBJS.24.00841.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Donald Kasitinon, MD, RMSK<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:donald.kasitinon@utsouthwestern.edu" class=""
                    style="position: unset;">donald.kasitinon@utsouthwestern.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5505-1572" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5505-1572</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alia Hemeida, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Reed C. Williams, MD, MBS, RMSK<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4360-6825" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4360-6825</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mahmood Gharib, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0833-2144" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0833-2144</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sara Raiser, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Amanda Wise, DO<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0325-0585" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0325-0585</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nitin B. Jain, MD, MSPH<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4362-8522" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4362-8522</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Physical Medicine and Rehabilitation, University of Texas
                Southwestern Medical Center, Dallas, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Summit Orthopedics, Plymouth, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Physical Medicine and Rehabilitation, University of Minnesota
                Medical Center, Minneapolis, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Physical Medicine and Rehabilitation, University of Virginia
                Medical Center, Charlottesville, Virginia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Department of Physical Medicine and Rehabilitation, University of Michigan
                Medical Center, Ann Arbor, Michigan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 33156.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
