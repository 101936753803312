const encouragements = {
	positive: [
		"You're doing great!",
		"Great job!",
		"You did it!",
		"Wow, that was awesome!",
		"Well done!",
		"Impressive!",
		"You are amazing!",
		"You are awesome!",
		"You're on a roll!",
		"Quite the performance!",
		"Fantastic effort!",
		"Keep it up!",
		"Knowledge courses through your veins!",
	],
	negative: [
		"Hang in there",
		"You can do it",
		"Keep trying and you'll get there",
		"Improvement comes one step at a time",
		"You only learn from wrong answers",
		"Persistence is key",
		"Practice makes perfect",
		"Keep going strong",
	],
};
const getDistributionKey = (score: number) => {
	if (score < 75) {
		return "low";
	}
	if (score < 85) {
		return "medium";
	}
	return "high";
};

const getRandomEncouragement = (encouragementArray: string[]) => {
	return (
		encouragementArray[Math.floor(Math.random() * encouragementArray.length)] ||
		"You can do better"
	);
};

const getFeedbackTextAndIcon = (score: number) => {
	const distributionKey = getDistributionKey(score);
	let text = "";
	let icon = "";

	switch (distributionKey) {
		case "low":
		case "medium": {
			text = getRandomEncouragement(encouragements.negative);
			icon = "thumb-up-outline";
			break;
		}
		case "high": {
			text = getRandomEncouragement(encouragements.positive);
			icon = "thumb-up-outline";
			break;
		}
		default: {
			text = "";
			icon = "";
			break;
		}
	}

	return { text, icon };
};

export { getDistributionKey, getFeedbackTextAndIcon };
