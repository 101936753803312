/* ----------------- Constants --------------- */
import { cacheKeys } from "@constants/cache-keys";
import { events } from "@constants/tracking";
import type { EXAM_STATUS } from "types/tag";

import { useQuery } from "@tanstack/react-query";
/* ----------------- React & Hooks --------------- */
import { useCallback, useEffect, useState } from "react";

/* ----------------- Features --------------- */
import { useExamContext } from "@features/exam";
import {
	createSession,
	useResumeSession,
	useSessionStore,
} from "@features/session";
import { fetchBundleDetails, useBundleStore } from "@features/store";

/* ----------------- Types --------------- */
import type { BundleDetails } from "@memorang/types";

/* ----------------- Queries --------------- */
import fetchPracticeTestContents from "../queries/FetchPracticeTestsContents";

import { useAuthContext } from "@memorang/applets";
import { useDevice } from "@memorang/ui";
/* ----------------- Utils --------------- */
import { useFocusEffect, useRouter } from "expo-router";

import { getIsOlxExam } from "@helpers/exams";
/* ----------------- Analytics --------------- */
import { trackCustomEvent } from "analytics";

export const usePracticeTests = () => {
	const {
		currentExamContext: { practiceTestProductId, practiceTestProductNumericId },
	} = useExamContext();

	const { viewerId } = useAuthContext();

	const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);
	const [selectedExamId, setSelectedExamId] = useState<string | undefined>();
	const [creatingSession, setCreatingSession] = useState(false);
	const [showErrorDialog, setShowErrorDialog] = useState(false);

	const [showCollectUserDetailsDialog, setShowCollectUserDetailsDialog] =
		useState(false);

	const [olxExamEligibility, setOlxExamEligibility] = useState<{
		endDate: string;
		startDate: string;
	} | null>(null);

	const resetSessionStore = useSessionStore((state) => state.resetSessionStore);
	const updateSession = useSessionStore((state) => state.updateSession);

	const sessionNumericId = useSessionStore((state) => state.numericId);

	const toggleShowCollectUserDetailsDialog = () => {
		setShowCollectUserDetailsDialog((prev) => !prev);

		router.push({
			pathname: "/(protected)/session/[id]",
			params: {
				id: sessionNumericId,
				index: "1",
			},
		});
	};

	const { handleResumeSession } = useResumeSession();

	const toggleShowPurchaseDialog = () => {
		setShowPurchaseDialog((prev) => !prev);
	};

	const [localBundleDetails, setLocalBundleDetails] =
		useState<BundleDetails | null>(null);

	const { setCurrentlySelectedBundleDetailsForIAP } = useBundleStore();

	const { isMobile } = useDevice();

	useEffect(() => {
		if (practiceTestProductNumericId) {
			fetchBundleDetails(practiceTestProductNumericId).then((res) => {
				setLocalBundleDetails(res);
				setCurrentlySelectedBundleDetailsForIAP(res);
			});
		}
	}, [practiceTestProductNumericId, setCurrentlySelectedBundleDetailsForIAP]);

	const router = useRouter();
	const {
		data: mixedItemDistResponse,
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [cacheKeys.practiceTest, practiceTestProductId],
		queryFn: () => {
			if (practiceTestProductId) {
				return fetchPracticeTestContents(practiceTestProductId);
			}
		},
		enabled: !!practiceTestProductId,
		refetchOnWindowFocus: true,
		staleTime: 0,
	});

	useFocusEffect(
		useCallback(() => {
			if (practiceTestProductId) {
				refetch();
			}
		}, [refetch, practiceTestProductId]),
	);

	const hasProductAccess =
		mixedItemDistResponse?.getMixedItemDistribution.hasAccess;

	const handleStartSession = async (
		examId: string,
		numericId?: number,
		isQuiz?: boolean,
		isOlx?: boolean,
	) => {
		setSelectedExamId(examId);
		setCreatingSession(true);
		try {
			trackCustomEvent({
				eventName: events.sessionStarted,
				type: "summative",
				numericId,
				isQuiz: String(isQuiz),
			});
			const result = await createSession({
				action: "SUMMATIVE_SESSION",
				createSessionType: "PRACTICE",
				learningOrder: "SHUFFLED",
				examId,
				bundleId: practiceTestProductId,
				userId: viewerId!,
			});
			updateSession({
				sessionResponse: result,
				isSummativeTest: true,
			});
			if (isOlx) {
				setShowCollectUserDetailsDialog(true);
			} else {
				router.push({
					pathname: "/(protected)/session/[id]",
					params: {
						id: result.numericId,
						index: "1",
					},
				});
			}
		} catch (_err) {
			setShowErrorDialog(true);
		} finally {
			setSelectedExamId(undefined);
			setCreatingSession(false);
		}
	};
	const handleActiveSession = async (examId: string, numericId?: number) => {
		if (numericId) {
			setSelectedExamId(examId);
			setCreatingSession(true);
			await handleResumeSession(numericId);
			setSelectedExamId(undefined);
			setCreatingSession(false);
		}
	};
	const handlePracticeTestSession = (
		examId: string,
		status: EXAM_STATUS,
		numericId?: number,
		isQuiz?: boolean,
		hasExamAccess?: boolean,
		canStartSession?: boolean,
	) => {
		resetSessionStore();
		switch (status) {
			case "COMPLETE":
			case "LEGACYREPORT":
			case "MIGRATEDREPORT":
				// handleStartSession(examId, numericId, isQuiz);
				router.push(`/session/${numericId}/report`);
				break;
			case "ACTIVE":
				handleActiveSession(examId, numericId);
				break;
			case "NOT_STARTED":
				if ((hasExamAccess && hasProductAccess) || isQuiz) {
					if (getIsOlxExam(examId) && !canStartSession) {
						const olxExam =
							mixedItemDistResponse?.getMixedItemDistribution.exams?.find(
								(exam) => exam.id === examId,
							);
						if (olxExam) {
							setOlxExamEligibility({
								startDate: olxExam.beginStartDate!,
								endDate: olxExam.endStartDate!,
							});
						}
					} else {
						handleStartSession(examId, numericId, isQuiz, getIsOlxExam(examId));
					}
				} else {
					if (examId.includes("OLX")) {
						router.push("/redeem");
						return;
					}
					if (localBundleDetails) {
						setCurrentlySelectedBundleDetailsForIAP(localBundleDetails);
					}
					if (isMobile) {
						router.push("/purchase");
					} else {
						setShowPurchaseDialog(true);
					}
				}
		}
	};

	return {
		mixedItemDistResponse,
		handlePracticeTestSession,
		showPurchaseDialog,
		toggleShowPurchaseDialog,
		selectedExamId,
		creatingSession,
		showErrorDialog,
		setShowErrorDialog,
		loading: isLoading,
		olxExamEligibility,
		setOlxExamEligibility,
		showCollectUserDetailsDialog,
		toggleShowCollectUserDetailsDialog,
	};
};
