import { commitMutation, graphql } from "react-relay";

import { relayEnv } from "@memorang/configs";

type UpdateExamMetadataInput = {
	examDate: string;
	id: string;
};

interface UpdateExamMetadataResponse {
	updateExamMetaDetails: boolean;
}

const updateExamMeta = async ({
	examDate,
	id,
}: UpdateExamMetadataInput): Promise<boolean> => {
	const mutation = graphql`
    mutation UpdateExamMetadataMutation($examMeta: UpdateMetaInput!, $id: ID!) {
      updateExamMetaDetails(examMeta: $examMeta, id: $id)
    }
  `;

	const promise = new Promise<boolean>((resolve, reject) => {
		const variables = {
			examMeta: {
				examDate,
			},
			id,
		};
		commitMutation(relayEnv, {
			mutation,
			variables,
			onCompleted: (response) => {
				resolve((response as UpdateExamMetadataResponse).updateExamMetaDetails);
			},
			onError: (err) => {
				reject(err);
			},
		});
	});
	return promise;
};

export default updateExamMeta;
