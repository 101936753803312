import type { Distribution } from "@memorang/types";
import type { Content } from "types/tag";

const calculateParentDist = (contents: Content[]) => {
	const parentDist = {
		low: 0,
		medium: 0,
		high: 0,
		none: 0,
	};
	const accumulateDist = (dist: Distribution) => {
		for (const key of Object.keys(dist)) {
			const finalKey = key as keyof Distribution;
			parentDist[finalKey] = parentDist[finalKey] || 0;
			parentDist[finalKey] += dist[finalKey];
		}
	};

	for (const content of contents) {
		accumulateDist(content.distribution);
	}

	return parentDist;
};

const getParentMetadata = (contents: Content[], numItems: number) => {
	const dist = calculateParentDist(contents);
	const finalDist = {
		...dist,
		none: numItems - (dist.low + dist.medium + dist.high),
	};
	return {
		distribution: finalDist,
		isFavorited: false,
		permissions: ["VIEW"],
	};
};

export { getParentMetadata };
