export const appBarMeta = {
	"product-home": {
		title: "Study-Pack",
		description:
			"Premium materials to help you learn and prepare for your exam",
	},
	"practice-tests": {
		title: "Practice Tests",
		description:
			"Take practice tests to assess your exam readiness, strengths, and opportunities for improvement",
	},
	store: {
		title: "Test-Prep Store",
		description: "Maximize your exam readiness with premium study materials",
	},
	leaderboard: {
		title: "Leaderboard",
		description: "See how you stack up against others",
	},
	profile: {
		title: "Profile",
		description: "View your profile",
	},
	progress: {
		title: "Progress",
		description: "Review your performance and areas for improvements",
	},
};
