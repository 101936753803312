import { useAppStore } from "@hooks/useAppStore";
import {
	DarkTheme as NavigationDarkTheme,
	DefaultTheme as NavigationDefaultTheme,
} from "@react-navigation/native";
import merge from "deepmerge";
import {
	MD3DarkTheme,
	MD3LightTheme,
	adaptNavigationTheme,
} from "react-native-paper";
import type { Apps } from "types/apps";

const getCustomPalette = (dark: boolean) => ({
	info: {
		main: dark ? "#29b6f6" : "#0288d1",
		light: dark ? "#4fc3f7" : "#03a9f4",
		dark: dark ? "#0288d1" : "#01579b",
	},
	success: {
		main: dark ? "#1DB45A" : "#1AA251",
		light: dark ? "#6AE79C" : "#6AE79C",
		dark: dark ? "#1AA251" : "#1AA251",
	},
	warning: {
		main: dark ? "#DEA500" : "#DEA500",
		light: dark ? "#FFDC48" : "#FFDC48",
		dark: dark ? "#AB6800" : "#AB6800",
	},
	freeChip: {
		background: dark ? "rgba(196, 238, 208, 1)" : "rgba(196, 238, 208, 1)",
		color: dark ? "rgba(20, 108, 46, 1)" : "rgba(20, 108, 46, 1)",
	},
	premiumChip: {
		background: dark ? "#EDE7F6" : "#EDE7F6",
		color: dark ? "rgba(98, 0, 234, 1)" : "rgba(98, 0, 234, 1)",
	},

	divider: dark ? "rgba(255,255,255,0.12)" : "rgba(0,0,0,0.12)",

	answer: {
		selected: {
			border: "#6da3eb",
			background: "rgba(109, 163, 235, 0.15)",
		},
		correct: {
			border: "#66bb67",
			background: "rgba(102, 187, 103, 0.15)",
		},
		incorrect: {
			border: "#d66565",
			background: "rgba(214, 101, 101, 0.15)",
		},
	},
	confidence: {
		high: "#66bb68",
		medium: "#dda53a",
		low: "#d66565",
	},
	progress: {
		high: "#66bb68",
		medium: "#dda53a",
		low: "#d66565",
		none: dark ? "#757575" : "#ddd",
		noneBackground: "#888888", // old: '#888888'
		lowBackground: "rgba(255,105,97, 0.3)", // old: '#ffb4b0'
		highBackground: "rgba(0, 168, 106, 0.3)", // old: '#90d3b5'
	},
	leader: {
		gold: "#FFD336",
	},
});

const getMergedCustomTheme = ({
	darkMode,
}: {
	appName: Apps;
	darkMode: boolean;
}) => {
	const { LightTheme, DarkTheme } = adaptNavigationTheme({
		reactNavigationLight: NavigationDefaultTheme,
		reactNavigationDark: NavigationDarkTheme,
	});
	const DefaultLightTheme = merge(MD3LightTheme, LightTheme);
	const DefaultDarkTheme = merge(MD3DarkTheme, DarkTheme);

	const theme = useAppStore.getState().theme;

	const mergedCustomTheme = merge(
		darkMode ? DefaultDarkTheme : DefaultLightTheme,
		{
			...theme,
			overrides: theme.overrides,
			colors: {
				...theme.colors[darkMode ? "dark" : "light"],
			},
		},
	);
	return mergedCustomTheme;
};

const textColors = (dark: boolean) => ({
	primary: dark ? "rgba(255,255,255,0.87)" : "rgba(0,0,0,0.87)",
	secondary: dark ? "rgba(255,255,255,0.60)" : "rgba(0,0,0,0.60)",
	disabled: dark ? "rgba(255,255,255,0.38)" : "rgba(0,0,0,0.38)",
});

export { getCustomPalette, getMergedCustomTheme, textColors };
