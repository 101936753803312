import { ids } from "@constants/common";
import { REST_API_BASE_URL } from "@constants/urls";
import { buildType } from "@helpers/expo-extras";
import axios from "axios";
import type { DashboardData } from "./schema";

export const fetchDashboardItems = async ({
	userId,
	token,
	tenantId,
}: {
	userId: number;
	token: string;
	tenantId: string;
}): Promise<DashboardData> => {
	const { data } = await axios.post(
		`${REST_API_BASE_URL}/user-events/get-items-for-dashboard`,
		{
			collectionId: ids[buildType].collectionId,
			action: "LIBRARY",
			type: "QUESTION",
			userId,
		},
		{
			headers: {
				Authorization: token,
				tenantId,
			},
		},
	);

	return data;
};
