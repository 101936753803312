export const emptyStates = {
	progress: {
		title: "No Progress Data Available",
		subtitle: "As you study your progress will show up here",
		cta: "STUDY",
	},
	insights: {
		title: "No insights available yet",
		subtitle:
			"As you complete more challenges, insights will be generated here",
		cta: "START CHALLENGE",
	},
	cmeActivityLog: {
		title: "No activity log or CME credits available yet",
		subtitle:
			"As you complete more activities, your activity log and earned CME credits will show up here. You'll be able to export your CME certificates.",
		cta: "START CHALLENGE",
	},
};

export const buttonConfig: Record<
	string,
	{
		buttonMode: "contained" | "outlined" | "text" | "contained-tonal";
		buttonIcon: "play" | "play-pause" | "file-chart-outline";
		text?: string;
	}
> = {
	NOT_STARTED: {
		buttonMode: "contained",
		buttonIcon: "play",
		text: "Start",
	},
	ACTIVE: {
		buttonMode: "outlined",
		buttonIcon: "play-pause",
		text: "Resume",
	},
	COMPLETE: {
		buttonMode: "text",
		buttonIcon: "file-chart-outline",
		text: "View Report",
	},
	LEGACYREPORT: {
		buttonMode: "contained-tonal",
		buttonIcon: "file-chart-outline",
	},
	MIGRATEDREPORT: {
		buttonMode: "contained-tonal",
		buttonIcon: "file-chart-outline",
	},
};

export const statusMeta: Record<
	string,
	{
		section: string;
		text: string;
		icon?: string;
	}
> = {
	NOT_STARTED: {
		section: "Full-Length Practice Tests",
		text: "Not started",
		icon: "clipboard-check-outline",
	},
	ACTIVE: {
		section: "In progress",
		text: "In progress",
		icon: "progress-clock",
	},
	COMPLETE: {
		section: "Completed",
		text: "Completed",
	},

	LEGACYREPORT: {
		text: "Legacy Reports",
		section: "Legacy Reports",
	},
	MIGRATEDREPORT: {
		text: "Migrated Reports",
		section: "Migrated Reports",
	},
};

export const purchaseUrlMap: Record<
	number,
	{
		purchaseUrl: string;
	}
> = {
	66: {
		purchaseUrl: "https://www.therapyed.com/npte-exam/npte-study-guide/",
	},
	67: {
		purchaseUrl: "https://www.therapyed.com/pta-exam/pta-study-guide/",
	},
	68: {
		purchaseUrl: "https://www.therapyed.com/slp-praxis-exam/slp-study-guide/",
	},
	69: {
		purchaseUrl:
			"https://www.therapyed.com/nbcot-cota-exam/nbcot-cota-study-guide/",
	},
	70: {
		purchaseUrl:
			"https://www.therapyed.com/nbcots-otr-exam/nbcots-otr-study-guide/",
	},
};

export const specialitiesToCategoriesMap: Record<
	string,
	Record<string, string[]>
> = {
	specialty_to_categories: {
		ai: [
			// Allergy/Immunology
			"Allergy/Immunology",
			"Dermatology",
			"Pulmonary/Critical Care",
		],
		an: [
			// Anesthesiology
			"Pulmonary/Critical Care",
			"Surgery",
			"Emergency Medicine",
		],
		cd: [
			// Cardiology
			"Cardiology",
			"Pulmonary/Critical Care",
			"Emergency Medicine",
		],
		ccm: [
			// Critical Care Medicine
			"Pulmonary/Critical Care",
			"Cardiology",
			"Neurology/Neurosurgery",
		],
		omf: [
			// Dentistry (Oral and Maxillofacial)
			"Dentistry",
			"Otolaryngology",
			"Surgery",
		],
		d: [
			// Dermatology
			"Dermatology",
			"Allergy/Immunology",
			"Rheumatology",
		],
		em: [
			// Emergency Medicine
			"Emergency Medicine",
			"Cardiology",
			"Pulmonary/Critical Care",
		],
		end: [
			// Endocrinology
			"Endocrinology",
			"Nephrology",
			"Cardiology",
		],
		ep: [
			// Epidemiology/Statistics
			"Infectious Disease",
			"Genetics",
			"Public Health",
		],
		gp: [
			// Family Medicine/General Practice
			"Cardiology",
			"Dermatology",
			"Endocrinology",
		],
		ge: [
			// Gastroenterology
			"Gastroenterology",
			"Surgery",
			"Hematology/Oncology",
		],
		cg: [
			// Genetics
			"Genetics",
			"Pediatrics",
			"Hematology/Oncology",
		],
		ger: [
			// Geriatrics
			"Geriatrics/Aging",
			"Cardiology",
			"Neurology/Neurosurgery",
		],
		fpg: [
			// Geriatric Medicine, Family Practice
			"Geriatrics/Aging",
			"Family Medicine",
			"Cardiology",
		],
		lm: [
			// Health Law/Ethics/Public Policy
			"Health Law/Ethics/Public Policy",
			"Public Health",
			"Allergy/Immunology",
		],
		hem: [
			// Hematology
			"Hematology/Oncology",
			"Infectious Disease",
			"Genetics",
		],
		hos: [
			// Hospital Medicine
			"Hospital Medicine",
			"Infectious Disease",
			"Pulmonary/Critical Care",
		],
		id: [
			// Infectious Diseases
			"Infectious Disease",
			"Pulmonary/Critical Care",
			"Hematology/Oncology",
		],
		im: [
			// Internal Medicine
			"Cardiology",
			"Endocrinology",
			"Gastroenterology",
		],
		nep: [
			// Nephrology
			"Nephrology",
			"Cardiology",
			"Endocrinology",
		],
		n: [
			// Neurology
			"Neurology/Neurosurgery",
			"Psychiatry",
			"Geriatrics/Aging",
		],
		obg: [
			// Obstetrics/Gynecology
			"Obstetrics/Gynecology",
			"Endocrinology",
			"Surgery",
		],
		on: [
			// Oncology
			"Hematology/Oncology",
			"Surgery",
			"Radiology",
		],
		oph: [
			// Ophthalmology
			"Ophthalmology",
			"Neurology/Neurosurgery",
			"Rheumatology",
		],
		pm: [
			// Orthopedics
			"Orthopedics",
			"Rheumatology",
			"Surgery",
		],
		os: [
			// Other
			"All Categories",
		],
		oto: [
			// Otolaryngology
			"Otolaryngology",
			"Allergy/Immunology",
			"Surgery",
		],
		plm: [
			// Palliative Medicine
			"Palliative Medicine",
			"Hematology/Oncology",
			"Geriatrics/Aging",
		],
		clp: [
			// Pathology
			"Pathology",
			"Hematology/Oncology",
			"Infectious Disease",
		],
		mpd: [
			// Pediatric Subspecialty
			"Pediatrics",
			"Genetics",
			"All Pediatric Subspecialties",
		],
		pd: [
			// Pediatrics/Adolescent Medicine
			"Pediatrics",
			"Genetics",
			"Infectious Disease",
		],
		pa: [
			// Pharmacology/Pharmacy
			"Pharmacology/Pharmacy",
			"All Categories",
			"Public Health",
		],
		ptx: [
			// Preventive Medicine
			"Preventive Medicine",
			"Public Health",
			"Infectious Disease",
		],
		p: [
			// Psychiatry
			"Psychiatry",
			"Neurology/Neurosurgery",
			"Geriatrics/Aging",
		],
		pud: [
			// Pulmonary Medicine
			"Pulmonary/Critical Care",
			"Allergy/Immunology",
			"Cardiology",
		],
		r: [
			// Radiology
			"Radiology",
			"Surgery",
			"Hematology/Oncology",
		],
		rhu: [
			// Rheumatology
			"Rheumatology",
			"Orthopedics",
			"Allergy/Immunology",
		],
		gs: [
			// Surgery, General
			"Surgery",
			"Gastroenterology",
			"Hematology/Oncology",
		],
		ts: [
			// Surgery, Specialized
			"Surgery",
			"Orthopedics",
			"Neurology/Neurosurgery",
		],
		us: [
			// Unspecified
			"All Categories",
		],
		u: [
			// Urology
			"Urology/Prostate Disease",
			"Hematology/Oncology",
			"Surgery",
		],
	},
};

export const drawerWidths = {
	collapsed: 80,
	expanded: 360,
};
