/**
 * @generated SignedSource<<b1ac3dbd655a97cce0e11c6725baca8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type StudyGuideAction = "GET_STUDY_GUIDE_ITEMS" | "GET_STUDY_GUIDE_TAGS" | "%future added value";
export type FetchStudyGuidesQuery$variables = {
  action?: StudyGuideAction | null;
  bundleId: string;
  tagID?: string | null;
};
export type FetchStudyGuidesQuery$data = {
  readonly fetchStudyGuideItems: {
    readonly items: ReadonlyArray<{
      readonly childTags: ReadonlyArray<{
        readonly childTags: ReadonlyArray<{
          readonly id: string | null;
          readonly items: ReadonlyArray<{
            readonly childTags: ReadonlyArray<{
              readonly id: string | null;
              readonly items: ReadonlyArray<{
                readonly id: string | null;
                readonly label: string | null;
                readonly title: string | null;
                readonly type: string | null;
              } | null> | null;
              readonly label: string | null;
              readonly title: string | null;
              readonly type: string | null;
            } | null> | null;
            readonly id: string | null;
            readonly label: string | null;
            readonly title: string | null;
            readonly type: string | null;
          } | null> | null;
          readonly label: string | null;
          readonly title: string | null;
          readonly type: string | null;
        } | null> | null;
        readonly id: string | null;
        readonly items: ReadonlyArray<{
          readonly id: string | null;
          readonly label: string | null;
          readonly title: string | null;
          readonly type: string | null;
        } | null> | null;
        readonly label: string | null;
        readonly title: string | null;
        readonly type: string | null;
      } | null> | null;
      readonly id: string | null;
      readonly items: ReadonlyArray<{
        readonly id: string | null;
        readonly label: string | null;
        readonly title: string | null;
        readonly type: string | null;
      } | null> | null;
      readonly label: string | null;
      readonly title: string | null;
      readonly type: string | null;
    } | null> | null;
  } | null;
};
export type FetchStudyGuidesQuery = {
  response: FetchStudyGuidesQuery$data;
  variables: FetchStudyGuidesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "action"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bundleId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagID"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "StudyGuideItem",
  "kind": "LinkedField",
  "name": "items",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "action",
        "variableName": "action"
      },
      {
        "kind": "Variable",
        "name": "bundleId",
        "variableName": "bundleId"
      },
      {
        "kind": "Variable",
        "name": "tagID",
        "variableName": "tagID"
      }
    ],
    "concreteType": "StudyGuideResponse",
    "kind": "LinkedField",
    "name": "fetchStudyGuideItems",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StudyGuideItem",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyGuideItem",
            "kind": "LinkedField",
            "name": "childTags",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyGuideItem",
                "kind": "LinkedField",
                "name": "childTags",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyGuideItem",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudyGuideItem",
                        "kind": "LinkedField",
                        "name": "childTags",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v7/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchStudyGuidesQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "FetchStudyGuidesQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "85e720a3150c4e2d788cf295fd965940",
    "id": null,
    "metadata": {},
    "name": "FetchStudyGuidesQuery",
    "operationKind": "query",
    "text": "query FetchStudyGuidesQuery(\n  $bundleId: ID!\n  $action: StudyGuideAction\n  $tagID: ID\n) {\n  fetchStudyGuideItems(bundleId: $bundleId, action: $action, tagID: $tagID) {\n    items {\n      childTags {\n        id\n        label\n        title\n        type\n        childTags {\n          id\n          label\n          title\n          type\n          items {\n            id\n            label\n            title\n            type\n            childTags {\n              id\n              label\n              title\n              type\n              items {\n                id\n                label\n                title\n                type\n              }\n            }\n          }\n        }\n        items {\n          id\n          label\n          title\n          type\n        }\n      }\n      id\n      items {\n        id\n        label\n        title\n        type\n      }\n      label\n      title\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "139355df9d82ec211fc4544c2dfb6169";

export default node;
