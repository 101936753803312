import { ids } from "@constants/common";
import { REST_API_BASE_URL } from "@constants/urls";
import { buildType } from "@helpers/expo-extras";
import axios from "axios";
import type { InsightsData } from "./schema";

type Params = {
	itemId?: number;
	userId: number;
	collectionId?: number;
	token: string;
	tenantId: string;
};
export const fetchInsights = async (params?: Params): Promise<InsightsData> => {
	const { itemId, userId, collectionId, token, tenantId } = params ?? {};

	const { data } = await axios.post(
		`${REST_API_BASE_URL}/user-events/progress`,
		{
			projectId: ids[buildType].projectId,
			userId,
			versionId: 1,
			itemId,
			organizationId: ids[buildType].organizationId,
			collectionId,
		},
		{
			headers: {
				Authorization: token,
				tenantId,
			},
		},
	);

	return data;
};
