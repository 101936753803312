import type { Article } from "../../../../../types";

export const Article546: Article = {
	id: 546,
	rsuiteId: "0ed188b4-2fb7-4720-bfae-6cf33130e444",
	type: "scientific article",
	title:
		"Accuracy of 4 Different Methods for Estimation of Remaining Growth and Timing of Epiphysiodesis",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0ed188b4-2fb7-4720-bfae-6cf33130e444&type=jpeg&name=JBJS.23.01483f1a",
	subSpecialties: ["pediatrics"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Accuracy of 4 Different Methods for Estimation of
                Remaining Growth and Timing of Epiphysiodesis</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Anne Berg
                      Breen, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Harald Steen,
                      MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_18" class="annotation strong"
                          style="position: unset;">Background:</span> The calculation of remaining growth in children
                        and the timing of epiphysiodesis in those with leg-length discrepancy (LLD) is most often done
                        with 4 methods: the Green-Anderson, White-Menelaus, Moseley straight-line graph, and multiplier
                        methods. The aims of this study were to identify the most accurate method with use of bone age
                        or chronological age and to evaluate the influence of including inhibition in the calculations.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_19" class="annotation strong"
                          style="position: unset;">Methods:</span> One hundred and ninety-one children (10 to 17 years
                        of age) with LLD who underwent surgical closure of the growth plate and were followed until
                        skeletal maturity were identified from a local health register. Patients had at least 2
                        leg-length examinations with simultaneous bone-age assessments (according to the Greulich and
                        Pyle method), with the last examination performed ≤6 months before surgery. The accuracy of each
                        method was calculated as the mean absolute prediction error (predicted leg length – actual leg
                        length at maturity) for the short leg, the long leg, and the LLD. Comparisons were made among
                        the 4 methods and among calculations made with chronological age versus bone age and those made
                        with versus those without incorporation of a reduced growth rate (inhibition) of the short leg
                        compared with the long leg.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_20" class="annotation strong"
                          style="position: unset;">Results:</span> The White-Menelaus method with use of bone age and a
                        fixed inhibition rate was the most accurate method, with a prediction error of 1.5 ± 1.5 cm for
                        the short leg, 1.0 ± 1.2 cm for the long leg, and 0.7 ± 0.7 cm for the LLD. Pairwise comparison
                        of short-leg length and LLD according to the White-Menelaus and other methods showed that they
                        were significantly different (p ≤ 0.002). The calculated inhibition rate did not increase
                        accuracy.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_21" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The White-Menelaus method used with bone age and
                        constant inhibition should be the preferred method when predicting remaining growth and the
                        timing of epiphysiodesis in children between 10 and 17 years of age. One examination is in most
                        cases sufficient for the preoperative clinical investigation when chronological age and bone age
                        are concordant.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_22" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Permanent epiphysiodesis of the growth plates around the
                  knee is a well-established method to treat leg-length discrepancy (LLD) in children<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. The results of permanent epiphysiodesis strongly rely on
                  the ability to estimate the correct timing for surgery. Furthermore, accurate calculation of remaining
                  growth is of utmost importance when evaluating children with injuries to the growth plate, to assist
                  in clinical decision-making among various treatment options (no treatment, lengthening procedures, or
                  epiphysiodesis of the contralateral leg), depending on the estimated LLD at maturity. The 4 most
                  common methods for calculating remaining growth are the Green-Anderson<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>, White-Menelaus<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>,
                  Moseley straight-line graph<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>, and
                  multiplier<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">5</span></a>
                  methods. Makarov et al.<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>, in
                  2018, found that the White-Menelaus method was the most accurate, whereas the multiplier method was
                  less accurate compared with the Green-Anderson, White-Menelaus, and Moseley straight-line graph
                  (Rotterdam version) methods for predicting short-leg and long-leg lengths and LLD at maturity and that
                  using bone age yielded higher accuracy than chronological age. Lee et al.<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">7</span></a> compared the Green-Anderson, multiplier, and Moseley
                  straight-line graph methods and evaluated the growth patterns by applying the Shapiro type<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">8</span></a>. They found that all methods overestimated growth compared
                  with actual growth, with the Green-Anderson method combined with Shapiro type being the most accurate.
                  Burger et al.<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">1</span></a>
                  compared the Green-Anderson method using bone age and the multiplier method using chronological age
                  and found that (1) the Green-Anderson method was more accurate and (2) both methods overestimated
                  remaining growth, in accordance with the findings of Lee et al.<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">7</span></a>. Munger et al.<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">9</span></a>
                  found that including chronological age, bone age (as determined according to the Greulich and Pyle<a
                    href="" data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">10</span></a>, FELS<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">11</span></a>,
                  and Sanders<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">12</span></a>
                  methods), and height gave the most accurate growth prediction. However, they did not find a
                  significant difference compared with predictions made with use of individual skeletal maturity
                  systems. To our knowledge, no commonly used method incorporates all of these variables in the
                  calculation of remaining growth and the timing of epiphysiodesis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">It would be an advantage to identify the most accurate
                  method and thereby simplify the calculation of remaining growth in children to allow time-saving and
                  effective management of LLD, including the reduction of radiation exposure of the children. Except for
                  the White-Menelaus method, found to be most accurate by Makarov et al.<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">6</span></a>, no single method has clearly shown superior accuracy
                  compared with many others in predicting remaining growth and the timing of epiphysiodesis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Therefore, the first aim of the current study was to
                  compare the 4 most frequently used methods for growth prediction in a large cohort of patients who
                  were treated with epiphysiodesis and followed prospectively. Our hypothesis was that 1 of the methods
                  would show superior accuracy for estimating remaining growth with use of chronological age or bone
                  age. Second, we wanted to evaluate the importance of including the reduced growth rate of the short
                  leg (inhibition) compared with the long leg when estimating the LLD at maturity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Demographic
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">From a local hospital health register of children treated
                  for LLD, we identified 269 patients who were managed with epiphysiodesis with use of the percutaneous
                  technique (as described by Canale et al.<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">13</span></a>)
                  between 1992 and 2019. Of these, 257 patients were followed until skeletal maturity. The inclusion
                  criteria were at least 2 leg-length examinations with simultaneous bone-age assessments, with the last
                  examination performed ≤6 months before surgery. Patients who were examined within 3 months before
                  surgery were later compared with those who were examined within 6 months before surgery. Patients were
                  excluded if they had undergone other surgical procedures involving the lower extremity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Measurements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Leg lengths were assessed with an orthoroentgenogram (3
                  weight-bearing radiographs centered on the hip, knee, and ankle with a fixed ruler), with the images
                  being manually examined by 2 radiologists and 1 experienced ortho-pediatric consultant. Femoral length
                  was measured from the top of the femoral head to the most distal point of the medial femoral condyle.
                  Tibial length was measured from the most distal point of the medial femoral condyle to the tibial
                  plafond. For bone-age assessment, radiographs of the left hand were manually rated by experienced
                  radiologists according to the Greulich and Pyle atlas<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">10</span></a>.
                  The interobserver and intraobserver reliabilities were previously reported as 0.96 and 0.98<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">14</span></a>, respectively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Calculation
                of Remaining Growth</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">We compared the Green-Anderson<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">2</span></a>, White-Menelaus<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">3</span></a>,
                  Moseley straight-line graph (computer version)<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">4</span></a>, and
                  multiplier<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">5</span></a>
                  methods. Remaining growth was calculated with all methods on the basis of chronological age and bone
                  age separately. Originally, the Green-Anderson and Moseley straight-line graph methods were used only
                  with bone age, whereas the White-Menelaus and multiplier methods were only used with chronological
                  age.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">In most children with LLD, it can be assumed that there is
                  a relatively constant growth inhibition of the short leg compared with the long leg. All 4 methods
                  were examined with and without inhibition.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">In all calculations of remaining growth, we assumed that
                  71% of femoral growth occurs at the distal physis (accounting for 37% of the total leg growth) and 57%
                  of tibial growth at the proximal physis (28% of the total leg growth) as described by Green and
                  Anderson<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">2</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Green-Anderson Method</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">We used the Green-Anderson growth-remaining charts and the
                  mean values to estimate remaining growth and the effect of epiphysiodesis, both with and without
                  inhibition. Without inhibition, we assumed no change in LLD from the last preoperative examination
                  until maturity. The reduced growth rate of the short leg was calculated by dividing the difference in
                  short-leg length by the difference in long-leg length using the first and last preoperative leg-length
                  assessments.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">White-Menelaus Method</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">We used the White-Menelaus method with and without
                  inhibition and converted the original measurements in inches to the metric system. The method assumes
                  a constant inhibition rate of 1/8 inch/year (0.3175 cm/year) independent of sex, age, and LLD<a
                    href="" data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">15</span></a>. Furthermore, the White-Menelaus method assumes that
                  epiphysiodesis at the distal femur and proximal tibia yields a growth retardation of 3/8 inch (0.9525
                  cm) and 1/4 inch (0.635 cm) per year, respectively, until the patients reach skeletal maturity (girls
                  at 14 years and boys at 16 years).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Moseley
                Straight-Line Graph Method</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The original Mosely method is based on ≥3 longitudinal
                  observations with parallel examinations of leg length and bone age, ideally 6 months apart. We used
                  the Moseley computer program (version 2009) that allows an assumption of constant inhibition of the
                  short leg based on only 1 examination. This method must be considered a modification of the original
                  method, and both methods were evaluated. With only 1 (the last) examination, calculations were based
                  on the Green-Anderson growth chart (1964), assuming a constant growth rate since birth<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">16</span></a>. One hundred and fifty-five patients had 3 assessments. The
                  method uses a reference line for the long leg and constructs a straight line for the short leg based
                  on the leg length and bone age measurements. The computer program estimates the inhibition rate, the
                  lengths of the short leg and long leg and the LLD at maturity, and the result of epiphysiodesis
                  (femoral, tibial, or a combination) in the form of the amount of overcorrection or undercorrection (in
                  centimeters). Finally, the program estimates the length of the long leg at the time of epiphysiodesis
                  to achieve equal leg lengths at maturity. The date of surgery is recorded, and the program calculates
                  the effect of epiphysiodesis on the long leg and the LLD at maturity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Multiplier
                Method</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">We used the multiplier method with constant inhibition and
                  with calculated inhibition, based on 1 assessment (the last preoperative assessment) and 2 assessments
                  of leg lengths, respectively. Differentiation between congenital and developmental etiologies was not
                  performed. A difference of &lt;5% between the inhibition rates based on 1 or 2 assessments was defined
                  as constant (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Etiologies of Leg-Length Discrepancy (LLD)<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 798px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Etiology</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Patients</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Patients with Constant Inhibition Rate of Short Leg</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Idiopathic LLD<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Clubfoot</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hemihypertrophy<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Developmental dysplasia
                          of the hip, Legg-Calvé-Perthes disease, slipped capital femoral epiphysis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Posttraumatic LLD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Congenital lower-limb
                          deficiencies<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cerebral palsy</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postinfectious</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hemihypoplasia<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Vascular
                          malformation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rheumatoid
                          arthritis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Neurofibromatosis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">191</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">104</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The reduced growth rate (inhibition rate) of the short leg was
                        calculated based on 1 leg-length assessment and 2 leg-length assessments in all patients. If the
                        difference between the inhibition rates based on 1 assessment and 2 assessments was &lt;5%, we
                        defined the inhibition rate as constant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The etiology was assumed to have constant inhibition.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">We used STATA/SE 17.0 (StataCorp) with paired t tests to
                  compare the accuracy of the 4 prediction methods with the mean absolute prediction error (predicted
                  leg length – actual leg length at maturity) for the short leg, the long leg, and the LLD. In addition,
                  we compared calculations based on chronological age versus bone age and with versus without the
                  incorporation of inhibition. All data were expressed as the mean and the standard deviation (SD).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Demographic
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">In total, 191 patients (114 boys and 77 girls) fulfilled
                  the inclusion criteria. Thirty-six patients had 2 radiographic examinations, and the rest had &gt;2
                  such examinations; the mean time interval between the first and last radiographic examinations was
                  1.64 years. The majority of patients (150 of 191) were operatively managed within 3 months after the
                  last bone-age assessment. At the time of surgery, the 114 boys had a mean chronological age of 14.1 ±
                  1.2 years (range, 9.9 to 17.3 years) and a mean bone age of 13.8 ± 0.9 years (range, 10.3 to 15.8
                  years) and the 77 girls had a mean chronological age of 12.2 ± 1.2 years (range, 9.1 to 15.2 years)
                  and a mean bone age of 12.0 ± 1.1 years (range, 9.7 to 14.3 years). The mean difference between bone
                  age and chronological age at the last preoperative assessment was 0.8 ± 0.7 year (range, 0.0 to 4.1
                  years). The most frequent etiologies of LLD were idiopathic (32 patients), clubfoot (25 patients), and
                  hemihypertrophy (24 patients) (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). The
                  mean LLD was 2.80 ± 1.23 cm preoperatively and 1.26 ± 0.99 cm at maturity. The mean correction in LLD
                  was 1.54 ± 1.18 cm. At skeletal maturity, 50% of the children had an LLD of ≤1.1 cm and 75% had an LLD
                  of ≤1.7 cm. The mean LLD at maturity was 1.40 ± 1.60 cm among patients with a difference of &gt;1 year
                  between chronological age and bone age (n = 63), compared with 1.19 ± 0.89 cm among patients with a
                  difference of ≤1 year (p = 0.17).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Accuracy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The White-Menelaus method used with bone age and
                  inhibition was the most accurate method when we compared prediction errors for the short leg (1.49 ±
                  1.49 cm) and for the LLD (0.72 ± 0.65 cm) (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 1-A</a>
                  through <a href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">1-D</a>, <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). The
                  inclusion of inhibition gave a significantly more accurate result only within the White-Menelaus
                  method, and only for the LLD prediction error, using both chronological age and bone age (p &lt;
                  0.001). The LLD prediction error was significantly less accurate for the Moseley method (2 or 3
                  assessments) compared with the Moseley program (1 assessment) used with bone age (p = 0.025) (<a
                    href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). According to our calculations, there were no significant
                  differences in the Moseley straight-line graph predictions based on either 2 or 3 assessments. The
                  predictions were more accurate with use of bone age in the calculations, as compared with the use of
                  chronological age (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).
                  Pairwise comparison of the different methods used with bone age and inhibition, with the last
                  examination performed &lt;3 months before surgery, demonstrated a significant difference between all
                  methods for both short-leg length and LLD (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  Comparison of the absolute difference between predicted and actual length at skeletal maturity
                  according to the time between the last preoperative consultation and surgery (3 to 6 months or &lt;3
                  months) demonstrated no difference regarding the short-leg length and LLD (except for the Moseley
                  straight-line graph with bone age, in favor of &lt;3 months) and a significant difference for long-leg
                  length both with bone age and chronological age for the Green-Anderson and White-Menelaus methods (<a
                    href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_25"
                            class="annotation strong" style="position: unset;">Figs. 1-A through 1-D</span> Prediction
                          error (PE) for leg-length discrepancy (LLD) at maturity calculated using the multiplier (MP)
                          and White-Menelaus (WM) methods with chronological age and with bone age. The PE is the
                          absolute difference between the predicted and actual lengths</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-A</div>
                    <div class="image-download" name="JBJS.23.01483f1a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0ed188b4-2fb7-4720-bfae-6cf33130e444&type=jpeg&name=JBJS.23.01483f1a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_26" style="position: unset;">
                            <div class="content" style="position: unset;">Prediction error (cm) for the multiplier
                              method with bone age (years).</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-B</div>
                    <div class="image-download" name="JBJS.23.01483f1b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0ed188b4-2fb7-4720-bfae-6cf33130e444&type=jpeg&name=JBJS.23.01483f1b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_27" style="position: unset;">
                            <div class="content" style="position: unset;">Prediction error (cm) for the White-Menelaus
                              method with bone age (years).</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-C</div>
                    <div class="image-download" name="JBJS.23.01483f1c" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0ed188b4-2fb7-4720-bfae-6cf33130e444&type=jpeg&name=JBJS.23.01483f1c"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_28" style="position: unset;">
                            <div class="content" style="position: unset;">Prediction error (cm) for the multiplier
                              method with chronological age (years).</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-D</div>
                    <div class="image-download" name="JBJS.23.01483f1d" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0ed188b4-2fb7-4720-bfae-6cf33130e444&type=jpeg&name=JBJS.23.01483f1d"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_29" style="position: unset;">
                            <div class="content" style="position: unset;">Prediction error (cm) for the White-Menelaus
                              method with bone age (years).</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Mean Absolute Prediction Errors for Each Method,
                          Using 4 Assumptions Regarding Age and Inhibition<span data-id="table_footnote_reference_9"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1423.75px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.976261);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Green-Anderson</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Moseley
                          Straight-Line Graph</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Multiplier</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">White-Menelaus</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Short-leg length</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Chronological age</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.23 ±
                          1.79</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.34 ±
                          1.93</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.66 ±
                          2.15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.11 ±
                          1.64</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bone age</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.61 ±
                          1.37</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.82 ±
                          1.63</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.31 ±
                          1.88</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.59 ±
                          1.33</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  P value</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.002</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NS</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Chronological age with
                          inhibition</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.17 ±
                          1.78</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.42 ±
                          1.96</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.63 ±
                          2.19</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.08 ±
                          1.69</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bone age with
                          inhibition</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.58 ±
                          1.48</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.93 ±
                          1.74</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.31 ±
                          1.99</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.49 ±
                          1.49</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  P value</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.004</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NS</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Long-leg length</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Chronological age</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.26 ±
                          1.20</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.32 ±
                          1.26</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.36 ±
                          1.33</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.33 ±
                          1.28</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bone age</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98 ±
                          1.06</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.07 ±
                          1.14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.19 ±
                          1.24</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.02 ±
                          1.19</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  P value</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.004</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NS</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LLD</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Chronological age</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.33 ±
                          1.07</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.32 ±
                          1.11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.60 ±
                          1.31</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.15 ±
                          0.89</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bone age</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.06 ±
                          0.81</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.14 ±
                          0.87<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.48 ±
                          1.09</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.96 ±
                          0.75</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  P value</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.002</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NS</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NS</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.007</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Chronological age with
                          inhibition</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.24 ±
                          1.07</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.41 ±
                          1.23</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.60 ±
                          1.40</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.94 ±
                          0.78<span data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bone age with
                          inhibition</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01 ±
                          0.93</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.29 ±
                          1.12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.52 ±
                          1.25</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.72 ±
                          0.65<span data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  P value</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.003</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NS</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NS</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean absolute prediction error (and
                        standard deviation), in centimeters, calculated as the absolute difference between the predicted
                        and the actual short-leg length, long-leg length, or leg-length discrepancy (LLD) at maturity.
                        NS = not significant. If “with inhibition” is stated, the reduced growth rate (inhibition rate)
                        of the short leg compared with the long leg is included in the predictions of the short-leg
                        length and LLD. Also, as stated, each calculation was based on either chronological or bone
                        age.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">A paired t test indicated a significant difference in favor of not
                        including inhibition in the calculations.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">A paired t test indicated a significant difference in favor of
                        including inhibition in the calculations.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Pairwise Comparisons of Mean Absolute Prediction
                          Error<span data-id="table_footnote_reference_39" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 698.832px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.806373);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="6" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">GA vs.
                          M-SLG</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">GA vs.
                          MP</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">GA vs.
                          WM</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">M-SLG vs.
                          MP</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">M-SLG vs.
                          WM</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">MP vs.
                          WM</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">All patients (n =
                          191)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Short-leg length with
                          inhibition</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0001<span data-id="table_footnote_reference_10"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_12"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_13"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0017<span data-id="table_footnote_reference_14"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_15"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Long-leg length</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0301</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0008<span data-id="table_footnote_reference_16"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.3403</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0158</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.4355</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0412</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> LLD with
                          inhibition</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_17"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_18"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_19"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_20"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_21"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_22"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patients with last
                          examination &lt;3 mo before surgery (n = 150)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Short-leg length with
                          inhibition</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0001<span data-id="table_footnote_reference_23"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_24"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_25"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_26"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0003<span data-id="table_footnote_reference_27"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_28"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Long-leg length</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0071<span data-id="table_footnote_reference_29"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_30"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.7905</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0031<span data-id="table_footnote_reference_31"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1767</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0044<span data-id="table_footnote_reference_32"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> LLD with
                          inhibition</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_33"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_34"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0001<span data-id="table_footnote_reference_35"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0007<span data-id="table_footnote_reference_36"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_37"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001<span data-id="table_footnote_reference_38"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The mean absolute prediction error (in centimeters) is calculated as
                        the absolute difference between the predicted and the actual short-leg length, long-leg length,
                        or leg-length discrepancy (LLD) at maturity. The reduced growth rate (inhibition) of the short
                        leg compared with the long leg is included in the predictions of the short-leg length and LLD.
                        GA = Green-Anderson, M-SLG = Moseley straight-line graph, MP = multiplier, WM =
                        White-Menelaus.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Significant (p ≤ 0.0083) after Bonferroni correction for the 6
                        comparisons made for each length.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Comparison of Mean Absolute Prediction Error
                          According to Time from Last Preoperative Consultation to Surgery<span
                            data-id="table_footnote_reference_40" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1533px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Bone Age</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Chronological Age</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">&lt;3
                          Mo</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3-6
                          Mo</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">&lt;3
                          Mo</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3-6
                          Mo</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Prediction error of
                          short-leg length</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Green-Anderson</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.64 ±
                          1.26</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.46 ±
                          1.71</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.461</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.29 ±
                          1.76</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.04 ±
                          1.91</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.441</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Moseley straight-line
                          graph</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.87 ±
                          1.51</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.64 ±
                          2.02</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.432</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.40 ±
                          1.91</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.13 ±
                          2.02</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.438</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Multiplier</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.40 ±
                          1.81</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.99 ±
                          2.09</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.213</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.75 ±
                          2.16</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.33 ±
                          2.13</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.261</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> White-Menelaus</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.61 ±
                          1.25</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.48 ±
                          1.61</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.586</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.12 ±
                          1.60</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.09 ±
                          1.77</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.934</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Prediction error of
                          long-leg length</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Green-Anderson</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.89 ±
                          0.85</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.32 ±
                          1.58</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.022</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.17 ±
                          1.01</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.61 ±
                          1.71</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.040</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Moseley straight-line
                          graph</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00 ±
                          0.97</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.32 ±
                          1.59</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.109</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.25 ±
                          1.10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.57 ±
                          1.74</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.153</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Multiplier</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.17 ±
                          1.11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.28 ±
                          1.65</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.607</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.34 ±
                          1.19</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.44 ±
                          1.74</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.672</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> White-Menelaus</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.90 ±
                          1.01</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.45 ±
                          1.63</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.009</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.20 ±
                          1.08</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.82 ±
                          1.77</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.005</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Prediction error of
                          LLD</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Green-Anderson</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01 ±
                          0.79</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.27 ±
                          0.88</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.065</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.29 ±
                          1.07</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.48 ±
                          1.07</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.324</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Moseley straight-line
                          graph</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.07 ±
                          0.86</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.38 ±
                          0.90</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.042</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.29 ±
                          1.10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.43 ±
                          1.12</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.473</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Multiplier</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.42 ±
                          1.07</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.69 ±
                          1.12</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.149</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.55 ±
                          1.31</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.78 ±
                          1.33</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.307</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> White-Menelaus</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.92 ±
                          0.74</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.13 ±
                          0.76</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.105</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.14 ±
                          0.89</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.21 ±
                          0.89</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.647</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean prediction error (and standard
                        deviation), in centimeters, for short-leg length, long-leg length, and leg-length discrepancy
                        (LLD) according to the time from the last preoperative consultation to surgery, 3-6 months (n =
                        41) or &lt;3 months (n = 150), for each of the 4 methods. For all methods, the predictions are
                        based on the bone age or chronological age at the last preoperative consultation. Methods used
                        with constant inhibition: Moseley computer program and multiplier. Methods used with no
                        inhibition: Green-Anderson and White-Menelaus.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The important findings of this study were that the
                  White-Menelaus method used with bone age and a fixed inhibition rate was the most accurate method for
                  the calculation of remaining growth and the timing of epiphysiodesis. Calculating inhibition based on
                  repeated radiographic assessments of leg lengths, compared with a fixed inhibition rate, did not have
                  a significant impact on the predictions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The White-Menelaus method was significantly better for
                  predicting short-leg length and LLD at maturity after epiphysiodesis compared with the other methods.
                  In contrast, the multiplier method was significantly less accurate compared with the other methods in
                  the prediction of short-leg length and LLD (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  Similar to Makarov et al.<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">6</span></a>, we
                  compared the 4 methods and found the White-Menelaus method to be the most accurate, although our
                  calculations were based on a larger cohort with less-strict inclusion criteria. We found a mean LLD of
                  2.8 cm preoperatively and 1.3 cm at skeletal maturity. In comparison, Makarov et al. reported a mean
                  LLD of 3.7 cm preoperatively and 2.0 cm at skeletal maturity<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">For all methods the predictions were more accurate when
                  the calculations were based on bone age, significantly so for the Green-Anderson and White-Menelaus
                  methods. Makarov et al.<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">6</span></a>,
                  Burger et al.<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">1</span></a>, and
                  Lee et al.<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">7</span></a> all
                  found bone age to be more accurate than chronological age, in accordance with our results. When the
                  White-Menelaus method with inhibition was used to compare chronological age with bone age, the mean
                  absolute difference was 0.6 cm in short-leg predictions, 0.3 cm in long-leg predictions, and 0.2 cm in
                  LLD predictions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Including inhibition in the calculations did not yield a
                  significant difference for short-leg predictions; only the LLD predictions with the White-Menelaus
                  method differed significantly in favor of incorporating a fixed inhibition. This finding is in
                  accordance with those of Makarov et al.<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">6</span></a>.
                  Empirically, we consider the precision in the radiographic assessment of LLD to be ±3 mm. The
                  radiographic technique and its measurement error represent one of the limitations of the study and
                  might affect the estimates based on calculated inhibitions. Lee et al.<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">7</span></a> found better accuracy of the LLD predictions without
                  inhibition compared with predictions with calculated inhibition.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">When we compared the prediction error depending on the
                  duration between the last preoperative consultation and surgery, the long-leg predictions using the
                  Green-Anderson and White-Menelaus methods were significantly more accurate in children treated within
                  3 months. The effect of epiphysiodesis was calculated on the basis of the last preoperative assessment
                  of long-leg length and bone age for all methods, explaining those better results. The time from the
                  last assessment to surgery did not have an impact on the short-leg predictions in our results.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Two radiologists manually rated the bone-age assessments.
                  We previously conducted an interobserver and intraobserver study<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">14</span></a> and found the 95% precision for the Greulich and Pyle
                  method to be ±0.81 year, representing one of the limitations of the present study. Automated rating
                  increases accuracy<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">17</span></a>,
                  and new methods based on more modern populations, including maturation analysis of directly involved
                  growth plates around the knee, are under development<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">18</span></a>. A
                  strength of the current study is the large number of children included, but a limitation is the
                  less-strict inclusion criteria compared with other studies. We included patients with 3 to 6 months
                  between the last consultation and surgery (n = 41) and those with only 2 preoperative consultations (n
                  = 36). We performed a regression analysis to evaluate the significance of &lt;3 months versus up to 6
                  months between the last preoperative consultation and surgery. Also, for the Moseley method, we
                  compared the results depending on whether the patient had 2 or 3 preoperative consultations. Neither
                  the time from the last preoperative examination nor the number of preoperative consultations were of
                  significance according to our analysis. The included patients reflect our clinical practice, where in
                  some cases LLD might first be acknowledged when the child is already close to skeletal maturity and
                  the calculations can only be made on the basis of 1 or 2 radiographic assessments. The leg-length
                  measurements and bone-age rating were not done in a blinded fashion and might have caused a detection
                  bias.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Patients were not divided into congenital and
                  developmental etiologies in the calculations, even though that is recommended for the multiplier
                  method. We performed a subanalysis of the inhibition rate based on 1 leg-length assessment and 2
                  assessments according to the multiplier method and found that the assumption of constant inhibition in
                  congenital etiologies was not always correct. This finding illustrates one of the difficulties in
                  growth prediction in children.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">We consider our results to be generalizable, as we
                  included a large number of patients. The patient population was not preselected in terms of diagnosis
                  or other factors, but rather it covered all common diagnoses associated with LLD that a pediatric
                  orthopaedic surgeon encounters in daily practice (i.e., all age groups of patients with remaining
                  growth and the full spectrum in terms of the amount of LLD). Our patient cohort can be considered to
                  be representative and comparable with any patient population undergoing treatment of LLD. To validate
                  our results, we compared our results with those of similar studies and used methods and outcome
                  measures that should be comparable with those of the largest previous study in this field<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">6</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">On the basis of the present study, which we believe to be
                  the largest to date, the White-Menelaus method with bone age and fixed inhibition is the most accurate
                  method for the prediction of remaining growth and the timing of epiphysiodesis in children between 10
                  and 17 years of age. In most cases, 1 radiographic assessment may be sufficient for the calculation,
                  as calculation of inhibition based on 2 or 3 assessments rather than 1 assessment did not have a
                  significant impact on the predictions. In clinical practice, our results may contribute to substantial
                  simplification in terms of both time and cost and may reduce potentially harmful exposure to radiation
                  by reducing the number of radiographic examinations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Burger K</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Farr S</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Hahne J</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Radler C</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Ganger R</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Long-term results and comparison
                  of the Green-Anderson and multiplier growth prediction methods after permanent epiphysiodesis using
                  Canale’s technique</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">J Child Orthop.</span> 2019 Aug 1;13(4):423-30.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20results%20and%20comparison%20of%20the%20Green-Anderson%20and%20multiplier%20growth%20prediction%20methods%20after%20permanent%20epiphysiodesis%20using%20Canale%E2%80%99s%20technique&as_occt=title&as_sauthors=%20%22K%20Burger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Anderson M</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Green WT</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Messner MB</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Growth and predictions of growth
                  in the lower extremities</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1963 Jan;45-A:1-14.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=304200" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Menelaus MB</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Correction of leg length
                  discrepancy by epiphysial arrest</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 1966 May;48(2):336-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Correction%20of%20leg%20length%20discrepancy%20by%20epiphysial%20arrest&as_occt=title&as_sauthors=%20%22MB%20Menelaus%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Moseley CF</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">A straight-line graph for
                  leg-length discrepancies</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1977 Mar;59(2):174-9.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=397067" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Paley D</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Bhave A</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Herzenberg JE</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Bowen JR</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Multiplier method for predicting
                  limb-length discrepancy</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2000 Oct;82(10):1432-46.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=769410" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Makarov MR</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Jackson TJ</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Smith CM</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Jo CH</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Birch JG</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Timing of Epiphysiodesis to
                  Correct Leg-Length Discrepancy: A Comparison of Prediction Methods</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2018 Jul
                18;100(14):1217-22.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1734305"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Lee SC</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Shim JS</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Seo SW</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Lim KS</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Ko KR</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">The accuracy of current methods
                  in determining the timing of epiphysiodesis</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2013
                Jul;95-B(7):993-1000.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20accuracy%20of%20current%20methods%20in%20determining%20the%20timing%20of%20epiphysiodesis&as_occt=title&as_sauthors=%20%22SC%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Shapiro F</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Developmental patterns in
                  lower-extremity length discrepancies</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1982 Jun;64(5):639-51.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=493853" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Munger AM</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Yu KE</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Li DT</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Furdock RJ</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Boeyer ME</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Duren DL</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Weber DR</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Cooperman DR</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The More the Merrier:
                  Integrating Multiple Models of Skeletal Maturity Improves the Accuracy of Growth Prediction</span>.
                <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span>
                2021 Aug;41(7):e580-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20More%20the%20Merrier%3A%20Integrating%20Multiple%20Models%20of%20Skeletal%20Maturity%20Improves%20the%20Accuracy%20of%20Growth%20Prediction&as_occt=title&as_sauthors=%20%22AM%20Munger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Greulich WW</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Pyle SI</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Radiographic atlas of
                  skeletal development of the hand and the wrist.</span> Stanford: Stanford University Press;
                1959.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Chumela WC</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Roche AF</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Thissen D</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The FELS method of assessing
                  the skeletal maturity of the hand-wrist</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Am J Hum Biol.</span>
                1989;1(2):175-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20FELS%20method%20of%20assessing%20the%20skeletal%20maturity%20of%20the%20hand-wrist&as_occt=title&as_sauthors=%20%22WC%20Chumela%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Sanders JO</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Khoury JG</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Kishan S</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Browne RH</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Mooney JF</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Arnold KD</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> McConnell SJ</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Bauman JA</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Finegold DN</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Predicting scoliosis
                  progression from skeletal maturity: a simplified classification during adolescence</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2008 Mar;90(3):540-53.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=967288" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Canale ST</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Russell TA</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Holcomb RL</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Percutaneous epiphysiodesis:
                  experimental study and preliminary clinical results</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 1986
                Mar-Apr;6(2):150-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Percutaneous%20epiphysiodesis%3A%20experimental%20study%20and%20preliminary%20clinical%20results&as_occt=title&as_sauthors=%20%22ST%20Canale%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Breen AB</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Steen H</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Pripp A</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Gunderson R</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Sandberg Mentzoni HK</span>,
                <span data-id="annotation_56" class="annotation" style="position: unset;"> Merckoll E</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Zaidi W</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Lambert M</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Hvid I</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Horn J</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">A comparison of 3 different
                  methods for assessment of skeletal age when treating leg-length discrepancies: an inter- and
                  intra-observer study</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2022 Jan 11;93:222-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comparison%20of%203%20different%20methods%20for%20assessment%20of%20skeletal%20age%20when%20treating%20leg-length%20discrepancies%3A%20an%20inter-%20and%20intra-observer%20study&as_occt=title&as_sauthors=%20%22AB%20Breen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Westh RN</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Menelaus MB</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">A simple calculation for the
                  timing of epiphysial arrest: a further report</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 1981
                Feb;63-B(1):117-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20simple%20calculation%20for%20the%20timing%20of%20epiphysial%20arrest%3A%20a%20further%20report&as_occt=title&as_sauthors=%20%22RN%20Westh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Anderson M</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Messner MB</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Green WT</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Distribution of lengths of the
                  normal femur and tibia in children from one to eighteen years of age</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 1964 Sep;46:1197-202.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=303235" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Breen AB</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Steen H</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Pripp A</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Hvid I</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Horn J</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Comparison of Different Bone
                  Age Methods and Chronological Age in Prediction of Remaining Growth Around the Knee</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span>
                2023 Jul 1;43(6):386-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20Different%20Bone%20Age%20Methods%20and%20Chronological%20Age%20in%20Prediction%20of%20Remaining%20Growth%20Around%20the%20Knee&as_occt=title&as_sauthors=%20%22AB%20Breen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Benedick AJ</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Hogue B</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Furdock RJ</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Uli N</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Liu RW</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Estimating Skeletal Maturity
                  Using Knee Radiographs During Preadolescence: The Epiphyseal:Metaphyseal Ratio</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span>
                2021 Oct 1;41(9):566-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Estimating%20Skeletal%20Maturity%20Using%20Knee%20Radiographs%20During%20Preadolescence%3A%20The%20Epiphyseal%3AMetaphyseal%20Ratio&as_occt=title&as_sauthors=%20%22AJ%20Benedick%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fhub.jbjs.org%2Freader.php%3Frsuite_id%3D0ed188b4-2fb7-4720-bfae-6cf33130e444%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2F1888%26topics%3Dpd"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): 1888</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01483</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 25, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_19"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Oslo
                            University Hospital and the Institute of Clinical Medicine at the University of Oslo, Oslo,
                            Norway</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;0ed188b4-2fb7-4720-bfae-6cf33130e444&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;pd&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=0ed188b4-2fb7-4720-bfae-6cf33130e444&type=pdf&name=JBJS.23.01483.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=0ed188b4-2fb7-4720-bfae-6cf33130e444&type=pdf&name=JBJS.23.01483.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_23"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_24" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I116"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I116</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;0ed188b4-2fb7-4720-bfae-6cf33130e444&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;pd&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=0ed188b4-2fb7-4720-bfae-6cf33130e444&type=zip&name=JBJS.23.01483.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Anne Berg Breen, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:anneb3@ous-hf.no" class="" style="position: unset;">anneb3@ous-hf.no</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5127-4974" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5127-4974</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Harald Steen, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Are Pripp, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1678-7309" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1678-7309</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sanyalak Niratisairak, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8532-700X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8532-700X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joachim Horn, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Orthopedic Surgery, Oslo University Hospital, Oslo, Norway</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Institute of Clinical Medicine, University of Oslo, Oslo, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Oslo Centre of Biostatistics and Epidemiology, Oslo University Hospital, Oslo,
                Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 26364.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
