import type { Article } from "../../../../../types";

export const Article606: Article = {
	id: 606,
	rsuiteId: "8dc9ee49-d1b4-4854-9c75-ded620cb6b56",
	type: "scientific article",
	title:
		"Identifying Risk Factors for Disease Progression in Developmental Dysplasia of the Hip Using a Contralateral Hip Model",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=8dc9ee49-d1b4-4854-9c75-ded620cb6b56&type=jpeg&name=JBJS.24.00308f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Identifying Risk Factors for Disease Progression in
                Developmental Dysplasia of the Hip Using a Contralateral Hip Model</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Michael D.
                      Harris, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Susan Thapa,
                      MPH, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_25" class="annotation strong"
                          style="position: unset;">Background:</span> Developmental dysplasia of the hip (DDH) can cause
                        pain and premature osteoarthritis. The risk factors and timing for disease progression in
                        adolescents and young adults have not been fully defined. This study aimed to determine the
                        prevalence of and risk factors for contralateral hip pain and surgery after periacetabular
                        osteotomy (PAO) on a dysplastic hip.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_26" class="annotation strong"
                          style="position: unset;">Methods:</span> Patients undergoing unilateral PAO for DDH were
                        followed for at least 2 years and categorized into contralateral pain and no-pain groups and
                        contralateral surgery and no-surgery groups. Pain was defined with the modified Harris hip
                        score. Univariate analysis tested group differences in demographics, radiographic measures, and
                        range of motion. Kaplan-Meier survival analysis was used to assess pain development and surgery
                        in the contralateral hip over time. Multivariable regression identified risk factors for
                        contralateral pain and surgery. Contralateral pain and surgery predictors were secondarily
                        assessed after categorization of the contralateral hips as dysplastic, borderline, and
                        non-dysplastic and in subgroups based on the lateral center-edge angle (LCEA) and acetabular
                        inclination (AI) in 5° increments.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_27" class="annotation strong"
                          style="position: unset;">Results:</span> One hundred and eighty-four patients were followed
                        for a mean of 4.6 ± 1.6 years (range, 2.0 to 8.8 years), during which 51% (93) reported
                        contralateral hip pain and 33% (60) underwent contralateral surgery. Kaplan-Meier analysis
                        predicted 5-year survivorship of 49% with contralateral pain development as the end point and
                        66% with contralateral surgery as the end point. Painful hips exhibited more severe dysplasia
                        compared with no-pain hips (LCEA = 16.5° versus 20.3°, p &lt; 0.001; AI = 13.2° versus 10.0°, p
                        &lt; 0.001). AI was the sole predictor of pain, with every 1° increase in the AI raising the
                        risk by 11%. Surgically treated hips also had more severe dysplasia (LCEA = 14.9° versus 20.0°,
                        p &lt; 0.001; AI = 14.7° versus 10.2°, p &lt; 0.001) and were in younger patients (21.6 versus
                        24.1 years, p = 0.022). AI and a maximum alpha angle of ≥55° were predictors of contralateral
                        surgery.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Conclusions:</span> At 5 years after hip PAO, approximately 50% of
                        contralateral hips will have pain and approximately 35% can be expected to need surgery. More
                        severe dysplasia, based on the LCEA and AI, increases the risk of contralateral hip pain and
                        surgery, with AI being a predictor of both outcomes. Knowing these risks can inform patient
                        counseling and treatment planning.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Developmental dysplasia of the hip (DDH) is one of the
                  most common causes of hip pain, dysfunction, and secondary osteoarthritis in young adult patients<a
                    href="" data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">3</span></a>. DDH
                  is characterized by deficiency of the osseous acetabulum, a lateralized hip center, and variable
                  patterns of acetabular version<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">4</span></a>. The
                  abnormal acetabular morphology results in undercoverage of the femoral head, altered intra-articular
                  force distribution, and secondary damage to cartilage and the acetabular labrum<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">6</span></a>. While the abnormal osseous morphology that characterizes
                  DDH is readily identifiable with radiographs, determination of which patients will have subsequent
                  symptomatic joint degeneration has not been fully defined.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Studies of the natural history of DDH have found frequent
                  development of radiographic evidence of osteoarthritis by age 50<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">7</span></a>. Patient factors reported to increase the likelihood of
                  early-onset osteoarthritis include high body mass index (BMI), increased activity level, female sex,
                  and more severe dysplasia<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">8</span></a>.
                  While most prior natural history studies have focused on the later progression of DDH to severe
                  osteoarthritis, an improved understanding of the factors associated with early disease progression
                  would facilitate early diagnosis and timely intervention. For example, many patients with symptomatic
                  DDH benefit from joint-preserving surgery, such as periacetabular osteotomy (PAO), but outcomes are
                  considerably better if the cartilage has been preserved<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">10</span></a>. Therefore, identifying early risk factors for disease
                  progression will provide prognostic information and can guide patient counseling and physician-patient
                  treatment decision-making.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Many patients with symptomatic DDH of one hip wonder if
                  they will develop symptoms in and need treatment of the contralateral hip. Because &gt;80% of patients
                  have bilateral radiographic evidence of DDH<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">11</span></a>,
                  studying disease progression in the contralateral hip could be beneficial for 2 major reasons. First,
                  it can help physicians and researchers understand the factors that most influence DDH disease
                  progression. Second, it can help patients and physicians understand the risk of contralateral symptoms
                  and future surgery. While prior studies have identified high rates of eventual progression to
                  osteoarthritis and arthroplasty in the contralateral hip of patients with DDH<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">12</span></a>,
                  information regarding the time to early development of contralateral pain or other symptoms is largely
                  missing.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This study aimed to determine the prevalence of and risk
                  factors for contralateral hip pain and surgery after patients undergo PAO. We focused on patients with
                  a minimum 2-year follow-up after the index PAO, and hypothesized that most contralateral hips become
                  symptomatic and that patients with worse hip dysplasia as seen radiographically have higher risks of
                  progression to contralateral surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">This was a prospective investigation of patients
                  undergoing unilateral PAO with a follow-up period of 2 to 8 years. Institutional review board approval
                  was obtained prior to initiation of the study. Patients who underwent PAO for symptomatic DDH during a
                  6-year period were identified from an observational longitudinal database of a single surgeon
                  (J.C.C.)<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">13</span></a>.
                  Patients were excluded if they had any of the following: incomplete contralateral hip pain data, prior
                  surgery on the contralateral hip, an age of ≥40 years, radiographic evidence of osteoarthritis (Tönnis
                  grade ≥2) in the contralateral hip at baseline, a history of Legg-Calvé-Perthes disease or slipped
                  capital femoral epiphysis of the contralateral hip, cerebral palsy, or neuromuscular disease. Of 215
                  patients who met our inclusion criteria, 184 had a minimum of 2 years of complete follow-up, including
                  data on the contralateral hip, and were included in the final cohort (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00308f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=8dc9ee49-d1b4-4854-9c75-ded620cb6b56&type=jpeg&name=JBJS.24.00308f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart demonstrating the initial cohort and the
                          final cohort, grouped according to whether pain developed and surgical treatment was performed
                          in the contralateral hip.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Baseline demographics (age, sex, BMI, involved side), hip
                  range of motion, and activity level (UCLA [University of California at Los Angeles] score) were
                  recorded prior to the index PAO. Routine follow-up was carried out at 1, 2, and 5 years following the
                  index PAO with patients completing standardized outcome questionnaires including a contralateral hip
                  assessment. Telephone follow-up was conducted if a clinical follow-up visit was missed. Pain in the
                  contralateral hip was assessed with the pain component of the modified Harris hip score (mHHS) and
                  graded as none, slight, mild, moderate, severe, or disabling<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">14</span></a>.
                  “Pain” for this study was defined as moderate or greater, as previously reported by our group<a
                    href="" data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">15</span></a>. The time to the development of contralateral hip pain was
                  recorded, with 0 indicating pain at baseline, prior to the index PAO. Contralateral pain development
                  was not considered during the first 6 months after the index PAO. Surgery, including PAO or hip
                  arthroscopy, on the contralateral hip was also recorded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Radiographs were obtained using standardized protocols and
                  included standing anteroposterior pelvic, 45° Dunn, frog-leg lateral, and false-profile views<a
                    href="" data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">16</span></a>. Two trained researchers made bilateral hip measurements of
                  the lateral center-edge angle (LCEA), acetabular inclination (AI), anteroposterior alpha angle, Dunn
                  alpha angle, frog-leg lateral alpha angle, anterior center-edge angle (ACEA), and Tönnis
                  osteoarthritis grade. All measurements have been shown to have high intra- and interobserver
                  reliability within our group<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">17</span></a>. Bilateral hip range of motion (internal rotation with
                  flexion, external rotation with flexion, flexion, and abduction) was recorded at baseline.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Univariate comparisons of baseline demographic,
                  radiographic, and range-of-motion measures were made between patients with no contralateral pain at
                  the last follow-up and those who had contralateral pain (α = 0.05). Continuous measures were analyzed
                  with t tests and Wilcoxon rank-sum tests, while categorical measures were analyzed with chi-square
                  tests of independence or Fisher exact tests, as appropriate. Similar tests were used to compare
                  baseline measures between patients who did and those who did not go on to have contralateral surgery.
                  Kaplan-Meier survival analyses predicted survivorship rates, with contralateral hip pain and
                  contralateral surgery as the end points, at 5 years after the index PAO. To understand the
                  contributors to pain and surgery, the rates of these outcomes were analyzed according to subcategories
                  defined by the severity of the acetabular deformity—dysplastic (LCEA &lt; 20°), borderline dysplastic
                  (LCEA = 20° to 25°), and non-dysplastic (LCEA &gt; 25°)—as well as in subgroups defined by the LCEA
                  and AI in 5° increments.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Finally, multivariable Poisson regression analysis was
                  conducted to identify significant risk factors for contralateral hip pain and surgery; models included
                  potential risk factors that had differed significantly between hips with and those without
                  contralateral pain or surgery in the univariate analyses. Models were adjusted for age, sex, and BMI
                  as potential confounders.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The mean follow-up (and standard deviation) for the 184
                  included patients was 4.6 ± 1.6 years (range, 2 to 8.8) years. The mean age was 23.3 years (range, 12
                  to 39 years) at baseline, and the cohort included more females (n = 150, 82%) than males (n = 34, 18%)
                  (<a href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). The majority of the contralateral hips were either dysplastic
                  (n = 105, 57%) or borderline dysplastic (n = 52, 28%). There were 27 contralateral hips (15%) without
                  dysplasia. Of the patients without contralateral dysplasia, 19 (70%) reported no contralateral pain at
                  the time of final follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Baseline Measures Compared Between Contralateral Pain
                          and No-Pain Groups<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1627px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Parameter</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Pain (N =
                          93)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No Pain (N
                          = 91)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_26" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.0 ±
                          7.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.6 ±
                          7.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.57</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.76</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19%
                          (18)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18%
                          (16)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81%
                          (75)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82%
                          (75)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgically treated
                          hip</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.36</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54%
                          (50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60%
                          (55)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46%
                          (43)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40%
                          (36)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up <span
                            data-id="emphasis_27" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.97</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_28" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_29" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_14" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_14"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_30"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.8 ±
                          3.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.5 ±
                          3.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.62</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Obesity</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.19</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Obese (BMI ≤30 kg/m<span
                            data-id="superscript_15" class="annotation superscript"
                            style="position: unset;">2</span>)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11%
                          (10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5%
                          (5)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Non-obese (BMI &lt;30
                          kg/m<span data-id="superscript_16" class="annotation superscript"
                            style="position: unset;">2</span>)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">89%
                          (83)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          (86)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">UCLA activity
                          level</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.34</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41%
                          (38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34%
                          (31)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59%
                          (55)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66%
                          (60)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Contralateral
                          radiographic parameters</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> LCEA <span
                            data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.5 ±
                          6.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.3 ±
                          5.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_32" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> AI <span
                            data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.2 ±
                          5.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.0 ±
                          4.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_33" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ACEA <span
                            data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.8 ±
                          10.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.3 ±
                          6.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.19</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anteroposterior alpha
                          angle <span data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43.5 ±
                          11.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.1 ±
                          8.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.13</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dunn alpha angle <span
                            data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52.1 ±
                          11.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.9 ±
                          7.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.43</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Frog-leg lateral alpha
                          angle <span data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47.4 ±
                          9.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43.9 ±
                          7.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.13</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Max. alpha angle</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.14</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥55°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37%
                          (34)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26%
                          (24)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;55°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63%
                          (59)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74%
                          (67)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Contralateral Tönnis
                          osteoarthritis grade = 0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61%
                          (57)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52%
                          (47)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.11</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Contralateral range of
                          motion <span data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Internal rotation with
                          flexion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.8 ±
                          15.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.1 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.42</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> External rotation with
                          flexion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.9 ±
                          17.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.3 ±
                          15.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.89</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Flexion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100.8 ±
                          6.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101.4 ±
                          10.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.67</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Abduction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.9 ±
                          11.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.2 ±
                          10.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.29</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation or as the
                        percentage with the number in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Kaplan-Meier analysis with contralateral pain as the end
                  point determined a 49% survival rate at 5 years after the index hip PAO, meaning that it predicted
                  that 51% would develop pain (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). The
                  probability of survival at 5 years with contralateral surgery as the end point was 66%, meaning that
                  34% were expected to need surgery (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).
                  Survivorship probability was worse for patients with dysplasia, with 62% expected to develop pain and
                  50% needing surgery (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). At 5
                  years, 42% of the borderline dysplastic hips and 27% of the non-dysplastic hips were predicted to have
                  pain, and 11% and 9%, respectively, were expected to need surgery (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00308f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=8dc9ee49-d1b4-4854-9c75-ded620cb6b56&type=jpeg&name=JBJS.24.00308f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier survival curve with contralateral
                          pain (<span data-id="strong_34" class="annotation strong" style="position: unset;">Fig.
                            2-A</span>) and surgery (<span data-id="strong_35" class="annotation strong"
                            style="position: unset;">Fig. 2-B</span>) as the end points. The survival rates represent
                          the probability of no pain or no surgery.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Prevalence
                and Predictors of Contralateral Hip Pain</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Overall, 51% (93) of the 184 patients reported
                  contralateral pain at baseline or developed pain by the time of follow-up. Twenty-three percent (42)
                  of the 184 began with contralateral pain at baseline. Of the 142 hips that were asymptomatic at
                  baseline, 36% (51) developed pain during the follow-up period.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Of the hips that were painful at baseline or that later
                  developed pain, 71% were dysplastic, 20% were borderline dysplastic, and 9% were non-dysplastic. When
                  the hips were grouped according to their LCEA or AI, in 5° increments, it was found that contralateral
                  pain became more common as both the LCEA and the AI indicated more severe dysplasia (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00308f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=8dc9ee49-d1b4-4854-9c75-ded620cb6b56&type=jpeg&name=JBJS.24.00308f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Prevalence of contralateral pain among subgroups
                          based on LCEA and AI, in 5° increments. Contralateral pain became more prevalent as both the
                          LCEA and the AI indicated more severe dysplasia (i.e., lower LCEA and higher AI).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The LCEA and AI were the only measures that differed
                  between the pain and no-pain groups (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). There
                  were no intergroup differences in baseline age, BMI, UCLA activity level, or range of motion (<a
                    href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Multivariable regression modeling showed that only the AI
                  in the contralateral hip predicted contralateral pain (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). Based
                  on the Poisson risk ratio, every 1° increase in the contralateral AI (more severe dysplasia) increased
                  the risk of contralateral pain by 11%. Multivariable modeling within the dysplasia severity
                  subcategories (dysplastic, borderline dysplastic, and non-dysplastic contralateral hips) found that no
                  risk factors, including contralateral AI, predicted contralateral pain (<a href=""
                    data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). However, in a subcategory consisting of both dysplastic and
                  borderline hips, every 1° increase in the contralateral AI increased the risk of contralateral pain by
                  14% (<a href="" data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Multivariable Poisson Modeling of the Predicted Risk
                          for Contralateral Hip Pain with Every 1° Increase in Contralateral AI<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 329px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Risk Ratio
                          (95% Confidence Interval)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">All patients</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_36" class="annotation strong" style="position: unset;">1.11 (1.03,
                            1.26)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_37" class="annotation strong" style="position: unset;">0.01</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dysplastic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.10
                          (0.98, 1.18)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.13</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Borderline
                          dysplastic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.29
                          (0.99, 1.70)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dysplastic and
                          borderline</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_38" class="annotation strong" style="position: unset;">1.14 (1.05,
                            1.24)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_39" class="annotation strong" style="position: unset;">0.002</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Non-dysplastic</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.11
                          (0.79, 1.57)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.57</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Adjusted for age, sex, and BMI.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Prevalence
                and Predictors of Contralateral Hip Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">All patients who received contralateral surgery also had
                  contralateral pain. Overall, 33% (60) of the 184 patients went on to have contralateral hip surgery.
                  This included 79% (33) of the 42 patients who had contralateral pain at baseline and 53% (27) of the
                  51 patients who developed such pain during the follow-up period. Ninety-three percent (56) of the 60
                  patients who had contralateral surgery underwent PAO and 7% (4) of the 60 underwent hip arthroscopy.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Of the 60 contralateral hips treated with surgery, 85% had
                  dysplasia, 12% had borderline dysplasia, and 3% were non-dysplastic. Of the 4 hips that underwent
                  arthroscopy, 3 had borderline dysplasia (LCEA = 22.6°, 21.4°, 24.7°) and 1 was non-dysplastic (LCEA =
                  25.8°). Similar to the pain rates, the rates of contralateral surgery increased as the level of
                  dysplasia severity, based on the LCEA and AI, increased (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00308f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=8dc9ee49-d1b4-4854-9c75-ded620cb6b56&type=jpeg&name=JBJS.24.00308f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;">Prevalence of contralateral surgery among
                          subgroups based on LCEA and AI, in 5° increments. Contralateral surgery became more prevalent
                          as both the LCEA and the AI indicated more severe dysplasia (i.e., lower LCEA and higher AI).
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">The average age among those who underwent contralateral
                  surgery was younger than that of the patients who did not (<a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  Also, the proportion of males who had contralateral surgery was greater than the proportion of females
                  who had it (47% [16 of 34] versus 29% [44 of 150], p = 0.047) (<a href=""
                    data-id="figure_reference_15" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). Patients with a maximum alpha angle of ≥55° had a higher
                  rate of surgery than patients with maximum alpha angle of &lt;55° (52% versus 24%, p &lt; 0.001) (<a
                    href="" data-id="figure_reference_16" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). The LCEA and ACEA were lower, and the AI and frog-leg
                  lateral alpha angles were higher, among those who had contralateral surgery (<a href=""
                    data-id="figure_reference_17" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). There was no significant difference between the surgery and
                  no-surgery groups with regard to any range-of-motion parameter, although the difference in abduction
                  was nearly significant (<a href="" data-id="figure_reference_18"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Baseline Measures Compared Between Patients Who
                          Received Contralateral Surgery and Those Who Did Not<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1413px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Parameter</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Surgery (N
                          = 60)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No Surgery
                          (N = 124)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.6 ±
                          6.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.1 ±
                          7.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_40" class="annotation strong" style="position: unset;">0.022</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgically treated
                          hip</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.18</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50%
                          (30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60%
                          (75)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50%
                          (30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40%
                          (49)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up <span
                            data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.7 ±
                          1.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.75</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_40" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_41" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_17" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_17"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_42"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.9 ±
                          3.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.6 ±
                          3.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.59</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">UCLA activity
                          level</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.87</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38%
                          (23)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37%
                          (46)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62%
                          (37)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63%
                          (78)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_41" class="annotation strong" style="position: unset;">0.047<span
                              data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                              style="position: unset;">†</span></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27%
                          (16)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15%
                          (18)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73%
                          (44)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85%
                          (106)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Contralateral
                          radiographic parameters</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> LCEA <span
                            data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.9 ±
                          5.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.0 ±
                          5.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_42" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> AI <span
                            data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.7 ±
                          5.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.2 ±
                          4.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_43" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ACEA <span
                            data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.6 ±
                          10.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.1 ±
                          7.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_44" class="annotation strong" style="position: unset;">0.004</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anteroposterior alpha
                          angle <span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43.9 ±
                          13.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.5 ±
                          8.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.23</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dunn alpha angle <span
                            data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53.0 ±
                          10.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.8 ±
                          9.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.18</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Frog-leg lateral alpha
                          angle <span data-id="emphasis_48" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.6 ±
                          9.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43.6 ±
                          7.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_45" class="annotation strong" style="position: unset;">0.009</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Max. alpha angle</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_46" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥55°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50%
                          (30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23%
                          (28)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;55°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50%
                          (30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77%
                          (96)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Contralateral Tönnis
                          osteoarthritis grade = 0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63%
                          (38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65%
                          (81)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.79</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Contralateral range of
                          motion <span data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Internal rotation with
                          flexion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.9 ±
                          11.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.0 ±
                          11.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.99</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> External rotation with
                          flexion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36.0 ±
                          15.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40.7 ±
                          16.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.082</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Flexion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100.5 ±
                          7.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101.3 ±
                          9.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.52</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Abduction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.3 ±
                          8.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.4 ±
                          11.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.053</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation or as the
                        percentage with the number in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Represents the proportion of males receiving surgery versus the
                        proportion of females receiving surgery.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The multivariable regression analysis showed that the AI
                  and a maximum alpha angle of ≥55° were predictors of contralateral surgery (<a href=""
                    data-id="figure_reference_19" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). Among all patients, every 1° increase in the contralateral
                  AI increased the risk of contralateral surgery by 23%. Likewise, a maximum alpha angle of ≥55°
                  increased the risk by nearly 2.7 times. Multivariable modeling within the dysplastic, borderline, and
                  non-dysplastic subcategories found that the contralateral AI predicted surgery in the dysplastic but
                  not in the borderline and non-dysplastic hips. Additionally, a maximum alpha angle of ≥55° was a
                  predictor of contralateral surgery in the borderline but not in the dysplastic and non-dysplastic hips
                  (<a href="" data-id="figure_reference_20" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). However, in a subcategory consisting of both dysplastic and
                  borderline hips, both the AI and a maximum alpha angle of ≥55° predicted surgery (<a href=""
                    data-id="figure_reference_21" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Multivariable Poisson Modeling of the Predicted Risk
                          for Contralateral Surgery with Every 1° Increase in Contralateral AI or with Contralateral
                          Maximum Alpha Angle ≥55°<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 568px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">AI</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Max. Alpha Angle ≥55°</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Risk Ratio
                          (95% Confidence Interval)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Risk Ratio
                          (95% Confidence Interval)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">All patients</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_47" class="annotation strong" style="position: unset;">1.23 (1.13,
                            1.35)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_48" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_49" class="annotation strong" style="position: unset;">2.67 (1.25,
                            5.70)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_50" class="annotation strong" style="position: unset;">0.001</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dysplastic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_51" class="annotation strong" style="position: unset;">1.12 (1.00,
                            1.24)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_52" class="annotation strong" style="position: unset;">0.049</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.55
                          (0.98, 6.63)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.055</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Borderline
                          dysplastic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.15
                          (0.76, 1.73)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.517</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_53" class="annotation strong" style="position: unset;">8.48 (1.21,
                            59.7)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_54" class="annotation strong" style="position: unset;">0.032</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dysplastic and
                          borderline</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_55" class="annotation strong" style="position: unset;">1.21 (1.10,
                            1.34)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_56" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_57" class="annotation strong" style="position: unset;">2.74 (1.25,
                            6.01)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_58" class="annotation strong" style="position: unset;">0.001</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Non-dysplastic</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.14
                          (0.66, 2.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.638</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Adjusted for age, sex, and BMI.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">If and when the contralateral hip will become painful and
                  need surgery is a common question asked by patients with DDH and the physicians who treat them. This
                  study aimed to determine the prevalence of and risk factors for contralateral hip pain and surgery
                  after PAO. While differences exist among dysplastic, borderline dysplastic, and non-dysplastic hips,
                  our Kaplan-Meier analysis predicted that 5 years after PAO, 51% of contralateral hips will have pain
                  and 34% will need surgery. The prevalences of pain and surgery were higher in contralateral hips with
                  more severe DDH as seen radiographically. Interestingly, among the factors analyzed, only AI was a
                  predictor of contralateral pain. Both AI and a maximum alpha angle of ≥55° were predictors of surgery.
                  Knowing these risks can guide patient counseling and treatment planning.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The prevalence of contralateral pain and contralateral
                  surgery increased with the severity of DDH, with AI being a consistent predictor of each outcome. This
                  finding agrees with prior reports on AI and osteoarthritis. Murphy et al. reported that radiographic
                  evidence of contralateral osteoarthritis was seen after hip arthroplasty in all patients with an AI of
                  &gt;15°<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">12</span></a>.
                  Similarly, Wyles et al. found that 1 in 3 patients with contralateral DDH developed osteoarthritis in
                  that hip within 10 years after arthroplasty on the other side, with AI as a risk factor<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">2</span></a>. These prior studies of older patients, along with our
                  findings in younger patients, underscore the AI’s importance in predicting DDH progression from early
                  symptom development to eventual osteoarthritis. Similar to previous research, we found that the
                  prevalences of both contralateral hip pain and contralateral hip surgery also increased with a worse
                  contralateral LCEA<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_20" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">12</span></a>. However, based on multivariable analysis, LCEA was not a
                  significant predictor of pain or surgery in our patients. AI, as an indicator of overall acetabular
                  sourcil shape, may identify joint instability better than the LCEA can, as the LCEA may not reflect
                  regional coverage deficiencies in the joint. Thus, low contralateral LCEA values may be harbingers of
                  contralateral pain and surgery, but AI is a stronger indicator of the fate of the contralateral hip.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">While patients with radiographic evidence of more severe
                  DDH were predisposed to develop pain, modifiable factors including BMI and activity level were not
                  associated with contralateral hip pain. Matheney et al. reported earlier DDH symptoms in active
                  patients (UCLA activity score of 8, 9, or 10)<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">18</span></a>.
                  Nunley et al. also reported pain to be common during activities such as walking, running, and pivoting
                  in patients with DDH<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">19</span></a>.
                  However, these studies focused on pain in the index hip rather than contralateral pain post-treatment,
                  possibly explaining differences between previous and current findings related to modifiable factors
                  and pain. Moreover, we did not specifically study activity changes after PAO, but rather compared
                  baseline activity levels. The role of activity level in initial pain development may differ from its
                  role in subsequent development of contralateral pain, necessitating further investigation to determine
                  if activity modification is advisable.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">In our patients, femoral geometric deformities strongly
                  predicted eventual contralateral surgery. Notably, patients with a maximum contralateral alpha angle
                  of ≥55° had a 2.7-fold increased risk of surgery. Of those with a maximum alpha angle of &lt;55°, only
                  24% underwent surgery, whereas 52% of those with a maximum alpha angle of ≥55° required surgery. These
                  maximum alpha angles included measurements made from the anteroposterior, frog-leg lateral, or Dunn
                  views, reflecting asphericity at various regions on the femoral head in DDH<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">21</span></a>. However, because frog-leg lateral alpha angles were
                  significantly larger in the surgical group than in the nonsurgical group, anterolateral cam-type
                  lesions likely contribute to the eventual need for surgery, as previously suggested<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">22</span></a>. Other femoral features that we did not measure may also
                  influence contralateral pain and surgery, including femoral head extrusion and lateralization, which
                  have been linked to contralateral progression to osteoarthritis<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">2</span></a>. Coupled with recent findings regarding femoral deformities
                  and biomechanics in DDH, our results emphasize the importance of considering the femur when assessing
                  pain and damage risks in DDH<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">24</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Our results provide 3 insights into disease progression in
                  hips with borderline DDH. First, survivorship estimates indicated that the likelihood of pain in
                  borderline hips (42%) was lower than that in dysplastic hips (62%). Second, the probability of surgery
                  was lower in borderline hips (11%) than in dysplastic hips and was, in fact, similar to that in
                  non-dysplastic hips (9%), although the predicted pain likelihood was higher in borderline cases.
                  Third, a maximum alpha angle of ≥55° in a borderline hip may strongly increase the risk of future
                  surgery. Specifically, the risk ratio for contralateral surgery based on a maximum alpha angle of ≥55°
                  was 8.48, which is high. The reason for this finding is not immediately clear, but it should motivate
                  additional investigation into the role of femoral abnormalities in pain, impingement, instability, and
                  damage in borderline hips. While treatment decisions for borderline DDH continue to be challenging,
                  this study provides insight for surgeons about the likelihood that patients will develop pain and
                  suggests that specific attention be directed to the femoral head and head-neck morphology as this is
                  an important driver of the risk of future surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">This study had several limitations. First, measurements on
                  radiographs may not identify structural abnormalities such as femoral version that may impact symptoms
                  and disease progression. Second, all of the contralateral hips that were examined coexisted with a
                  symptomatic index hip, possibly introducing selection bias for patients with a predisposition to
                  symptom progression. Third, questions remain about DDH progression mechanisms. For instance, 85% of
                  the patients had dysplasia or borderline dysplasia in the contralateral hip, yet it remains unclear
                  why pain manifested in one hip initially and not in the other, or why only 23% of the patients
                  initially had bilateral pain. Additionally, it is uncertain if PAO alters joint mechanics in a way
                  that affects contralateral hip symptoms.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, our study provides additional insight into
                  disease progression in patients with DDH. Physicians can look to the AI measure as a significant
                  predictor of pain, and may wish to carefully examine femoral deformities as contributors to pain and
                  the need for surgery. Physicians can also refer to our rates of contralateral pain and surgery as they
                  counsel patients and develop future treatment plans.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank Gail Pashos and Sean Akers for their
                  assistance with this project.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Sankar WN</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Duncan ST</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Baca GR</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Beaulé PE</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Millis MB</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Peters CL</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Podeszwa DA</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Schoenecker PL</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Sierra RJ</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Sink EL</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Sucato DJ</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Zaltz I</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Clohisy JC</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Descriptive Epidemiology of
                  Acetabular Dysplasia: The Academic Network of Conservational Hip Outcomes Research (ANCHOR)
                  Periacetabular Osteotomy</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2017 Feb;25(2):150-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Descriptive%20Epidemiology%20of%20Acetabular%20Dysplasia%3A%20The%20Academic%20Network%20of%20Conservational%20Hip%20Outcomes%20Research%20(ANCHOR)%20Periacetabular%20Osteotomy&as_occt=title&as_sauthors=%20%22WN%20Sankar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Wyles CC</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Heidenreich MJ</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Jeng J</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Larson DR</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Sierra RJ</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The John Charnley Award:
                  Redefining the Natural History of Osteoarthritis in Patients With Hip Dysplasia and
                  Impingement</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2017 Feb;475(2):336-50.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20John%20Charnley%20Award%3A%20Redefining%20the%20Natural%20History%20of%20Osteoarthritis%20in%20Patients%20With%20Hip%20Dysplasia%20and%20Impingement&as_occt=title&as_sauthors=%20%22CC%20Wyles%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Nho SJ</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Kymes SM</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Callaghan JJ</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Felson DT</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">The burden of hip osteoarthritis
                  in the United States: epidemiologic and economic considerations</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2013;21(Suppl
                1):S1-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20burden%20of%20hip%20osteoarthritis%20in%20the%20United%20States%3A%20epidemiologic%20and%20economic%20considerations&as_occt=title&as_sauthors=%20%22SJ%20Nho%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Nepple JJ</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Wells J</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Ross JR</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Bedi A</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Schoenecker PL</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Clohisy JC</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Three Patterns of Acetabular
                  Deficiency Are Common in Young Adult Patients With Acetabular Dysplasia</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2017 Apr;475(4):1037-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three%20Patterns%20of%20Acetabular%20Deficiency%20Are%20Common%20in%20Young%20Adult%20Patients%20With%20Acetabular%20Dysplasia&as_occt=title&as_sauthors=%20%22JJ%20Nepple%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Henak CR</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Abraham CL</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Anderson AE</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Maas SA</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Ellis BJ</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Peters CL</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Weiss JA</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Patient-specific analysis of
                  cartilage and labrum mechanics in human hips with acetabular dysplasia</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Osteoarthritis
                  Cartilage.</span> 2014 Feb;22(2):210-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient-specific%20analysis%20of%20cartilage%20and%20labrum%20mechanics%20in%20human%20hips%20with%20acetabular%20dysplasia&as_occt=title&as_sauthors=%20%22CR%20Henak%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Song K</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Pascual-Garrido C</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Harris MD</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Acetabular Edge Loading During
                  Gait Is Elevated by the Anatomical Deformities of Hip Dysplasia</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Front Sports Act Living.</span> 2021 Jul
                1;3:687419.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acetabular%20Edge%20Loading%20During%20Gait%20Is%20Elevated%20by%20the%20Anatomical%20Deformities%20of%20Hip%20Dysplasia&as_occt=title&as_sauthors=%20%22K%20Song%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Dobson MA</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Robison JF</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Warth LC</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Zheng J</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Liu SS</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Yehyawi TM</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Callaghan JJ</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Radiographic structural
                  abnormalities associated with premature, natural hip-joint failure</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2011 May;93(Suppl
                2):3-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20structural%20abnormalities%20associated%20with%20premature%2C%20natural%20hip-joint%20failure&as_occt=title&as_sauthors=%20%22JC%20Clohisy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Jacobsen S</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Sonne-Holm S</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Hip dysplasia: a significant
                  risk factor for the development of hip osteoarthritis. A cross-sectional survey</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Rheumatology
                  (Oxford).</span> 2005 Feb;44(2):211-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20dysplasia%3A%20a%20significant%20risk%20factor%20for%20the%20development%20of%20hip%20osteoarthritis.%20A%20cross-sectional%20survey&as_occt=title&as_sauthors=%20%22S%20Jacobsen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Ganz R</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Klaue K</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Vinh TS</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Mast JW</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">A new periacetabular osteotomy
                  for the treatment of hip dysplasias: technique and preliminary results. 1988</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2004 Jan;(418):3-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20new%20periacetabular%20osteotomy%20for%20the%20treatment%20of%20hip%20dysplasias%3A%20technique%20and%20preliminary%20results.%201988&as_occt=title&as_sauthors=%20%22R%20Ganz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Wells J</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Schoenecker P</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Duncan S</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Goss CW</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Thomason K</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Clohisy JC</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Intermediate-Term Hip
                  Survivorship and Patient-Reported Outcomes of Periacetabular Osteotomy: The Washington University
                  Experience</span>. <span data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2018 Feb 7;100(3):218-25.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1564309" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Okano K</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Takaki M</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Okazaki N</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Shindo H</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Bilateral incidence and
                  severity of acetabular dysplasia of the hip</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">J Orthop Sci.</span> 2008
                Sep;13(5):401-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bilateral%20incidence%20and%20severity%20of%20acetabular%20dysplasia%20of%20the%20hip&as_occt=title&as_sauthors=%20%22K%20Okano%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Murphy SB</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Ganz R</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Müller ME</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The prognosis in untreated
                  dysplasia of the hip. A study of radiographic factors that predict the outcome</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 1995 Jul;77(7):985-9.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=606735" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Ackerman J</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Baca G</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Baty J</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Beaulé PE</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Millis MB</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Podeszwa DA</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Schoenecker PL</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Sierra RJ</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Sink EL</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Sucato DJ</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Zaltz I</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Patient-Reported Outcomes of
                  Periacetabular Osteotomy from the Prospective ANCHOR Cohort Study</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2017 Jan
                4;99(1):33-41.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1269085"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Mahomed NN</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Arndt DC</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> McGrory BJ</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Harris WH</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">The Harris hip score:
                  comparison of patient self-report with surgeon assessment</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2001
                Aug;16(5):575-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Harris%20hip%20score%3A%20comparison%20of%20patient%20self-report%20with%20surgeon%20assessment&as_occt=title&as_sauthors=%20%22NN%20Mahomed%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Louer CR</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Pashos G</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Nepple JJ</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">A Prospective Analysis of the
                  Contralateral Hip Among Patients With Femoroacetabular Impingement: What Are the Risk Factors for
                  Disease Progression?</span><span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2018 Aug;46(10):2486-91.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Prospective%20Analysis%20of%20the%20Contralateral%20Hip%20Among%20Patients%20With%20Femoroacetabular%20Impingement%3A%20What%20Are%20the%20Risk%20Factors%20for%20Disease%20Progression%3F&as_occt=title&as_sauthors=%20%22CR%20Louer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Carlisle JC</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Beaulé PE</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Sierra RJ</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Leunig M</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Schoenecker PL</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Millis MB</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">A systematic approach to the
                  plain radiographic evaluation of the young adult hip</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2008 Nov;90(Suppl
                4):47-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20approach%20to%20the%20plain%20radiographic%20evaluation%20of%20the%20young%20adult%20hip&as_occt=title&as_sauthors=%20%22JC%20Clohisy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Nepple JJ</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Martell JM</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Zaltz I</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Millis MB</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Podeszwa DA</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Sucato DJ</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Sink EL</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Clohisy JC</span>; ANCHOR Study
                Group. <span data-id="strong_17" class="annotation strong" style="position: unset;">Interobserver and
                  intraobserver reliability of the radiographic analysis of femoroacetabular impingement and dysplasia
                  using computer-assisted measurements</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2014 Oct;42(10):2393-401.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Interobserver%20and%20intraobserver%20reliability%20of%20the%20radiographic%20analysis%20of%20femoroacetabular%20impingement%20and%20dysplasia%20using%20computer-assisted%20measurements&as_occt=title&as_sauthors=%20%22JJ%20Nepple%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Matheney T</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Zaltz I</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Schoenecker P</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Millis M</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Podeszwa D</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Zurakowski D</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Beaulé P</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Clohisy J</span>; ANCHOR Study
                Group. <span data-id="strong_18" class="annotation strong" style="position: unset;">Activity Level and
                  Severity of Dysplasia Predict Age at Bernese Periacetabular Osteotomy for Symptomatic Hip
                  Dysplasia</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2016 Apr 20;98(8):665-71.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1252768" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Nunley RM</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Prather H</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Hunt D</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Schoenecker PL</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Clohisy JC</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Clinical presentation of
                  symptomatic acetabular dysplasia in skeletally mature patients</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2011 May;93(Suppl
                2):17-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20presentation%20of%20symptomatic%20acetabular%20dysplasia%20in%20skeletally%20mature%20patients&as_occt=title&as_sauthors=%20%22RM%20Nunley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Harris MD</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Gaffney BMM</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Pascual-Garrido C</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Femurs in Patients with Hip
                  Dysplasia Have Fundamental Shape Differences Compared to Cam Femoroacetabular Impingement</span>.
                <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J Hip Preserv
                  Surg.</span> 2024 Feb 5;11(2):132-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Femurs%20in%20Patients%20with%20Hip%20Dysplasia%20Have%20Fundamental%20Shape%20Differences%20Compared%20to%20Cam%20Femoroacetabular%20Impingement&as_occt=title&as_sauthors=%20%22MD%20Harris%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Okano K</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Yamaguchi K</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Ninomiya Y</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Matsubayashi S</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Osaki M</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Takahashi K</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Femoral head deformity and
                  severity of acetabular dysplasia of the hip</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2013
                Sep;95-B(9):1192-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Femoral%20head%20deformity%20and%20severity%20of%20acetabular%20dysplasia%20of%20the%20hip&as_occt=title&as_sauthors=%20%22K%20Okano%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Wells J</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Nepple JJ</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Crook K</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Ross JR</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Bedi A</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Schoenecker P</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Clohisy JC</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Femoral Morphology in the
                  Dysplastic Hip: Three-dimensional Characterizations With CT</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2017
                Apr;475(4):1045-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Femoral%20Morphology%20in%20the%20Dysplastic%20Hip%3A%20Three-dimensional%20Characterizations%20With%20CT&as_occt=title&as_sauthors=%20%22J%20Wells%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Shepherd MC</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Gaffney BMM</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Song K</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Nepple JJ</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Harris MD</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Femoral version deformities
                  alter joint reaction forces in dysplastic hips during gait</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Biomech.</span> 2022
                Apr;135:111023.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Femoral%20version%20deformities%20alter%20joint%20reaction%20forces%20in%20dysplastic%20hips%20during%20gait&as_occt=title&as_sauthors=%20%22MC%20Shepherd%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Aitken HD</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Westermann RW</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Bartschat NI</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Willey MC</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Goetz JE</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Effect of modeling femoral
                  version and head-neck offset correction on computed contact mechanics in dysplastic hips treated with
                  periacetabular osteotomy</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">J Biomech.</span> 2022 Aug;141:111207.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20modeling%20femoral%20version%20and%20head-neck%20offset%20correction%20on%20computed%20contact%20mechanics%20in%20dysplastic%20hips%20treated%20with%20periacetabular%20osteotomy&as_occt=title&as_sauthors=%20%22HD%20Aitken%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D8dc9ee49-d1b4-4854-9c75-ded620cb6b56%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="authorinsights_reference"
            class="annotation authorinsights_reference resource-reference" style="position: unset;"></a>
          <div class="content-node authorinsights" data-id="authorinsights" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Author Insights</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6364405041112&quot;,&quot;type&quot;:&quot;author_insights&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;8dc9ee49-d1b4-4854-9c75-ded620cb6b56&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6364405041112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6364405041112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6364405041112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6364405041112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/f1ef0841-8981-4e96-866e-7f6d1368635c/4b0060f2-5ef0-43a6-b0d4-06fc1b02fde9/1280x720/match/image.jpg"
                        src="blob:https://www.jbjs.org/5475f58c-5dbe-48e3-afed-45c4eda85702">
                        <track kind="metadata" label="segment-metadata">
                        <track kind="subtitles" label="English" srclang="en" default="" id="English">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/f1ef0841-8981-4e96-866e-7f6d1368635c/4b0060f2-5ef0-43a6-b0d4-06fc1b02fde9/1280x720/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">3:21</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 3:21">
                            <div class="vjs-load-progress" style="width: 4.92%;"><span
                                class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">4.92%</span></span>
                              <div data-start="0" data-end="9.919999" style="left: 0%; width: 100%;"></div>
                            </div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">3:21</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden vjs-disabled">
                          <button
                            class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button vjs-disabled"
                            type="button" aria-disabled="true" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false" disabled="disabled"><span class="vjs-icon-placeholder"
                              aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu vjs-hidden">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content" role="menu">
                              <li class="vjs-menu-item" tabindex="-1" role="menuitemradio" aria-disabled="false"
                                aria-checked="false"><span class="vjs-menu-item-text">captions off</span><span
                                  class="vjs-control-text" aria-live="polite"></span></li>
                              <li class="vjs-menu-item vjs-subtitles-menu-item vjs-selected" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">English</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button" type="button"
                          title="Picture-in-Picture" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_705_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_705_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <script src="https://vjs.zencdn.net/vttjs/0.15.3/vtt.global.min.js"></script>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2322</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00308</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 15, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_25"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Washington
                            University School of Medicine in St. Louis, St. Louis, Missouri</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;8dc9ee49-d1b4-4854-9c75-ded620cb6b56&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=8dc9ee49-d1b4-4854-9c75-ded620cb6b56&type=pdf&name=JBJS.24.00308.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=8dc9ee49-d1b4-4854-9c75-ded620cb6b56&type=pdf&name=JBJS.24.00308.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_30"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This work was
                          supported in part by the Curing Hip Disease fund, the Jacqueline & W. Randolph Baker fund, The
                          Foundation for Barnes-Jewish Hospital (Award Reference 5228), The Foundation for Barnes-Jewish
                          Hospital (Award Reference 6421), and Daniel C. Viehmann (all to J.C.C.). Research reported in
                          this publication was supported by the National Institute of Arthritis and Musculoskeletal and
                          Skin Diseases of the National Institutes of Health under award number R01AR081881. The <span
                            data-id="strong_31" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I231" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I231</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;8dc9ee49-d1b4-4854-9c75-ded620cb6b56&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=8dc9ee49-d1b4-4854-9c75-ded620cb6b56&type=zip&name=JBJS.24.00308.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael D. Harris, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3475-819X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3475-819X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Susan Thapa, MPH, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-9972-5637" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-9972-5637</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Elizabeth G. Lieberman, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3305-4067" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3305-4067</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Cecilia Pascual-Garrido, MD, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7487-4753" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7487-4753</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Wahid Abu-Amer, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7455-6517" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7455-6517</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jeffrey J. Nepple, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7582-1415" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7582-1415</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">John C. Clohisy, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:jclohisy@wustl.edu" class="" style="position: unset;">jclohisy@wustl.edu</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7040-616X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7040-616X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Program in Physical Therapy, Washington University School of Medicine in St.
                Louis, St. Louis, Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Washington University School of Medicine in
                St. Louis, St. Louis, Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 28343.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
