/* ----------------- Components --------------- */
import OutlinedCard from "components/OutlinedCard";

import { useAppTheme } from "@hooks/useAppTheme";
import { HeaderCardAvatar, MasteryListItem, useDevice } from "@memorang/ui";
import { Button, Card, Divider } from "react-native-paper";
/* ----------------- Types --------------- */
import type { SectionItems } from "../../types";
import CardSkeleton from "../Skeletons/CardSkeleton";

interface Props {
	items: SectionItems;
	showStudyPackViews?: boolean;
	handleSelectTopic: (id: string) => void;
	handleShowPurchase: () => void;
	studyPackSubtitle?: string;
	handleNavigateToDetailView?: () => void;
	loading?: boolean;
}
const TopicsSection = ({
	items,
	showStudyPackViews,
	handleSelectTopic,
	handleShowPurchase,
	studyPackSubtitle,
	handleNavigateToDetailView,
	loading,
}: Props) => {
	const theme = useAppTheme();

	const { isMobile } = useDevice();

	const CardHeader = () => {
		return (
			<>
				<Card.Title
					title="Study-Pack"
					left={() => {
						return <HeaderCardAvatar icon="tag-multiple-outline" />;
					}}
					subtitle={showStudyPackViews && studyPackSubtitle}
				/>
				<Divider
					style={{
						backgroundColor: theme.colors.surfaceVariant,
					}}
				/>
			</>
		);
	};

	const showCardHeader = !isMobile;
	if (loading) {
		return <CardSkeleton />;
	}
	return (
		<OutlinedCard>
			{showCardHeader && <CardHeader />}
			{items.map((item) => {
				if ("distribution" in item) {
					const { title, distribution, items, id, locked } = item;
					return (
						<MasteryListItem
							title={title}
							key={id}
							distribution={distribution}
							rightText={`${items.length}`}
							locked={locked}
							onPress={
								showStudyPackViews
									? () => {
											if (locked) {
												handleShowPurchase();
											} else {
												handleSelectTopic(id);
											}
										}
									: undefined
							}
						/>
					);
				}
				return null;
			})}
			<Card.Actions>
				<Button onPress={handleNavigateToDetailView} mode="text">
					View All
				</Button>
			</Card.Actions>
		</OutlinedCard>
	);
};

export default TopicsSection;
