import type { Article } from "../../../../../types";

export const Article560: Article = {
	id: 560,
	rsuiteId: "d68b170-bdcc-47d5-b749-edde4710666f",
	type: "current concepts review",
	title: "Delayed Unions or Nonunions of the Distal Femur",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=jpeg&name=JBJS.23.00985f1",
	subSpecialties: ["trauma"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Delayed Unions or Nonunions of the Distal Femur</div>
              <div class="text subtitle" style="position: unset;">A Comprehensive Overview</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Elsa B.
                      Rodriguez-Baron, MD, MPH</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Hosaam Gawad,
                      BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 2 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node list" data-id="list_1" style="position: unset;">
                  <ul class="simple content" style="position: unset;">
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_1" style="position: unset;">
                            <div class="content" style="position: unset;">The failure rate of treatment of nonunions of
                              the distal femur with internal fixation and autologous bone grafting remains high at 20%
                              to 30%.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_2" style="position: unset;">
                            <div class="content" style="position: unset;">Successful treatment of nonunions of the
                              distal femur requires good mechanical stability combined with autologous bone graft. This
                              may require dual-plate fixation or intramedullary nail-plate combinations.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_3" style="position: unset;">
                            <div class="content" style="position: unset;">Autologous bone graft remains the bone graft
                              of choice. Bone-graft substitutes and biologics require further research.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Anatomy,
                Definition, and Incidence</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">The anatomic limits of the distal femur are from the
                  metadiaphyseal junction to the articular surface, encompassing approximately 15 cm of the femur. In
                  the axial plane, it is trapezoidal with a lateral cortex slope of 10° and a medial cortex slope of
                  25°. Distal femoral limits are demarcated by the medial and lateral condyles and the epicondyles<a
                    href="" data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">2</span></a>. The
                  definition of a nonunion is not universally accepted. A review of prospective clinical trials of
                  long-bone nonunions found that only 50% of articles provided a definition of nonunion<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a>. Those studies that defined a nonunion used a variety of
                  time, radiographic, and clinical criteria to make the diagnosis. The U.S. Food and Drug Administration
                  (FDA) requires nonunion to have<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a>: “a
                  minimum of nine months elapsed since the injury, and the fracture site has shown no radiographical
                  sign of healing progression, i.e., no change in the fracture callus, for the final three months (i.e.,
                  six months to be considered a nonunion plus three additional months to verify that the nonunion is
                  established).” The term “delayed union” is used to refer to the scenario in which the healing of the
                  fractured bone extends beyond the expected time, spanning several months to over a year in certain
                  cases<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a>.
                  Although not universally accepted, a fracture that has lost alignment and/or had implants that failed
                  at &gt;3 months can also be considered a nonunion.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Operative treatment of distal femoral fractures results in
                  a nonunion rate between 0% and 34%, with a bimodal distribution because the rate differs between
                  high-energy injuries in younger patients and lower-energy injuries in the geriatric population<a
                    href="" data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">6</span></a>. A study identified obesity, diabetes, open fractures,
                  medial bone defects, comminuted fractures, and unstable fixation as contributing factors to nonunion
                  development<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>.
                  Several techniques of fixation of acute distal femoral fractures have been described. Nonunion after
                  treatment with lateral locked plating has been reported to have a rate of between 6% and 22%<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">10</span></a> (<a
                    href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). In their meta-analysis, Yoon et al.<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">9</span></a> found a lower nonunion rate in 166 (7.7%) of 2,156 patients.
                  Some reviews found nonunion rates of 8 (4.2%) of 191 after locked lateral plating<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">7</span></a> and 7 (31.8%) of 22 after retrograde intramedullary
                  nailing<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">8</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Outcomes in Studies of Revision Fixation of Distal
                          Femoral Nonunions</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 603px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Key Studies</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Major
                          Finding</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Koso<a href=""
                            data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                              style="position: unset;">6</span></a><span data-id="superscript_11"
                            class="annotation superscript" style="position: unset;"></span> (2018)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Eleven studies including
                          505 distal femoral fractures indicated a 4.7% nonunion rate, with no difference between
                          treatment with a nail or plate</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Attum<a href=""
                            data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                              style="position: unset;">8</span></a><span data-id="superscript_12"
                            class="annotation superscript" style="position: unset;"></span> (2017)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">All 10 distal femoral
                          nonunions treated with a nail-plate combination successfully united (as measured using the
                          RUST [Radiographic Union Scale in Tibia Fractures] score) at the 24-month follow-up</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Peschiera<a href=""
                            data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                              style="position: unset;">31</span></a><span data-id="superscript_13"
                            class="annotation superscript" style="position: unset;"></span> (2018)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Malreduction,
                          particularly an axial defect, was associated with asymmetric fixation, and a medial cortical
                          bone defect was a major risk factor for nonunion</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wang<a href=""
                            data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                              style="position: unset;">14</span></a><span data-id="superscript_14"
                            class="annotation superscript" style="position: unset;"></span> (2003)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The study indicated that
                          ORIF with allograft struts and an autogenous bone graft is an effective treatment for distal
                          femoral fracture nonunion, with all nonunions uniting at a mean of 5 months
                          postoperatively</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Pathophysiology</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">See the Appendix.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Treatment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Nonoperative
                Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">See the Appendix.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Operative
                Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The classification by Weber and Cech of hypertrophic,
                  oligotrophic, and atrophic nonunions in 1976 has never been validated<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">12</span></a>. It is a useful construct when considering treatment
                  options, but it places a continuous state into categorical “boxes.” The validity of the original
                  belief that atrophic nonunions are also avascular has also been questioned. A new classification
                  system has been proposed that scores nonunion from 0 to 100 based on bone, soft-tissue, and patient
                  qualities<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">13</span></a>.
                  Despite the controversy, however, we will refer to the Weber and Cech classification system
                  (hypertrophic, oligotrophic, and atrophic). Regardless of the classification system, every nonunion
                  requires stability and adequate biology. It is imperative to obtain stabilization of bone that has
                  sufficient blood supply to support healing and to optimize soft-tissue coverage over the nonunion
                  site. Oligotrophic or atrophic nonunions may need osteoinductive factors such as autologous bone graft
                  and/or biologics such as bone morphogenetic protein when needed to stimulate bone formation<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">15</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The primary goal of treatment is mechanical stability,
                  combined with improved biology (autograft or biologics if autograft is not available or feasible) when
                  needed<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">17</span></a>. The technique is based on patient factors, nonunion type,
                  location, current fixation, and bone quality<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">20</span></a>. This may require dual-plate fixation or intramedullary
                  nail-plate combinations. Autologous bone graft remains the bone graft of choice. Bone-graft
                  substitutes and biologics require further research. The failure rate of treatment of nonunion of the
                  distal femur with internal fixation and autologous bone grafting remains high at 20% to 30%<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">20</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">A hypertrophic nonunion occurs when there is inadequate
                  stability, excessive movement, and excessive mechanical stress at the fracture site, and is
                  characterized by abundant callus formation without bridging bone and a persistent fracture line that
                  will need mechanical stability to heal<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">17</span></a>.
                  This type of nonunion in the presence of an acceptable alignment usually just requires stabler
                  fixation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">An oligotrophic nonunion results from delayed or
                  insufficient bone healing due to a partial or inadequate biologic response at the fracture site,
                  resulting from inadequate reduction or decreased biologic response, and is characterized by incomplete
                  bridging callus. These nonunions will usually require mechanical stability and biologic stimulation to
                  heal<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">20</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">An atrophic nonunion is caused by excessive motion and/or
                  poor biologic response, characterized by the absence of callus formation and impaired bone healing<a
                    href="" data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">21</span></a>. The gold standard of atrophic nonunion treatment is
                  revision fixation and cancellous autograft. Additional treatment with biologic growth factors is
                  considered after the failure of the first attempt at treating the nonunion. Biologics also may be used
                  as adjuncts after debridement of an infected nonunion with a large bone defect. In all other respects,
                  the surgical procedure is identical to the second step of the Masquelet technique of cement spacer
                  removal and placement of autograft, biologics, or a combination of both. Septic nonunions need to have
                  infection eradication, first and foremost, before additional stabilization and bone grafting<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">21</span></a>. One commonality among almost all types of nonunions is the
                  need for revision to achieve optimal alignment and stable fixation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Therefore, internal fixation is the standard operative
                  treatment that can be combined with biologic stimulation for optimal results<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">20</span></a>. Hence, creating a favorable biomechanical environment for
                  osseous consolidation includes proper preparation of the nonunion site, correction of any deformity,
                  stable fixation, and biologic stimulation<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">20</span></a>.
                  Biologic stimulation can be essential, as it provides osteoinductive and osteoconductive graft
                  materials.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Several fixation methods, including external fixation
                  (rarely indicated), revision plating (medial and/or lateral), intramedullary nailing, combined
                  fixation methods, and distal femoral replacement, are currently used to manage femoral nonunions<a
                    href="" data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_27" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">22</span></a>.
                  The technique used is based on the nonunion type and bone quality. Accurate alignment, restoration of
                  anatomy, and fixation placement are critical factors when deciding on the technique used<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_28" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">23</span></a>. To assess alignment, coronal alignment can be measured by
                  the mechanical lateral distal femoral angle, defined as the angle formed by the line connecting the
                  center of the femoral head to the center of the distal femoral epiphysis and the knee joint line of
                  the femur. This measurement accurately indicates the alignment resulting from the fracture, with an
                  acceptable alignment being 87° ± 2°<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">24</span></a>.
                  The contralateral femur can be used as a preoperative template and/or for intraoperative assessment.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Screw
                Fixation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">If a patient has a delayed oligotrophic or hypertrophic
                  nonunion with maintenance of alignment, the placement of additional percutaneous screws through an
                  existing plate is unusual, but can increase the stiffness and stability of the construct to allow more
                  time for complete fracture consolidation (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 1</a> and <a
                    href="" data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">2</a>). Additional fixation with screws or a secondary plate can be added
                  to an asymptomatic delayed union or nonunion at any time period, as long as the initial implant is
                  still functional and alignment is acceptable.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00985f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=jpeg&name=JBJS.23.00985f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_56"
                            class="annotation strong" style="position: unset;">Figs. 1-A and 1-B</span> Anteroposterior
                          and lateral radiographs of a closed comminuted left distal femoral periprosthetic fracture in
                          a 72-year-old woman. <span data-id="strong_57" class="annotation strong"
                            style="position: unset;">Figs. 1-C and 1-D</span> Anteroposterior and lateral immediate
                          4-month postoperative radiographs showing delayed union of the fracture; the distal screws
                          were loose.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00985f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=jpeg&name=JBJS.23.00985f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_58"
                            class="annotation strong" style="position: unset;">Figs. 2-A and 2-B</span> Anteroposterior
                          and lateral radiographs of the patient in <a href="" data-id="figure_reference_4"
                            class="annotation figure_reference resource-reference" style="position: unset;">Figure 1</a>
                          at 4 months after additional fixation due to the delayed union of the distal femoral fracture.
                          <span data-id="strong_59" class="annotation strong" style="position: unset;">Figs. 2-C and
                            2-D</span> Anteroposterior and lateral radiographs at 11 months postoperatively showing
                          healing after the additional screw fixation. The patient had been ambulating without assistive
                          devices or pain at 3 months postoperatively and had healed by 9 months postoperatively.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Lateral
                Plate Fixation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Historically, distal femoral nonunions were treated with
                  repeat lateral plating and bone graft (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 3-A</a>
                  through <a href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">3-F</a>, <a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).
                  Gardner et al.<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">25</span></a>
                  reviewed 31 distal femoral nonunion cases treated with a laterally placed fixed-angle implant (blade
                  plate, locking plate, or dynamic compression screw) plus lag screws and bone graft (22 [71%] of 31
                  patients had an iliac crest graft and 9 [29%] had demineralized bone matrix). Nonunion in this study
                  was defined as the failure of fracture union at 6 months postoperatively. The union rate was 97% (30
                  of 31 patients), with a mean time to union of 16 weeks, and a return to the preinjury functional
                  status was seen in 26 (84%) of the 31 patients<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">25</span></a>.
                  Biomechanical studies have demonstrated no significant difference in stability between locking plates
                  and blade plates. These plates are similar in that they allow for the correction of the angular and
                  rotational deformity and compression across the fracture. In addition, lag screws may be placed
                  through the plate to achieve more rigid stabilization<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_32" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_32" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_32" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">10</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.00985f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=jpeg&name=JBJS.23.00985f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_60"
                            class="annotation strong" style="position: unset;">Figs. 3-A and 3-B</span> Anteroposterior
                          and lateral radiographs showing a distal femoral fracture after fixation with a Less Invasive
                          Stabilization System (LISS; DePuy Synthes) plate. <span data-id="strong_61"
                            class="annotation strong" style="position: unset;">Figs. 3-C and 3-D</span> Anteroposterior
                          and lateral radiographs showing a distal femoral oligotrophic nonunion at 4 months. <span
                            data-id="strong_62" class="annotation strong" style="position: unset;">Figs. 3-E and
                            3-F</span> Anteroposterior and lateral radiographs showing union 6 months after revision
                          fixation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Lateral Plating</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 973px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Key Studies</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Major
                          Finding</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Bellabarba<a href=""
                            data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                              style="position: unset;">26</span></a><span data-id="superscript_33"
                            class="annotation superscript" style="position: unset;"></span> (2002)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The article describes a
                          successful approach to achieving healing of distal femoral fractures, using advanced plating
                          methods; every patient in the study experienced fracture-healing, with a mean recovery period
                          of about 14 weeks, alongside notable enhancements in knee function and no major adverse
                          events</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wang<a href=""
                            data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                              style="position: unset;">16</span></a><span data-id="superscript_34"
                            class="annotation superscript" style="position: unset;"></span> (2019)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Eight studies on 1,380
                          distal femoral fractures found high body mass index, open fractures, comminution, infection,
                          stainless steel plates, shorter lengths, ORIF, high rigidity, and all-locking constructs as
                          key risk factors affecting nonunion</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rollo<a href=""
                            data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                              style="position: unset;">27</span></a><span data-id="superscript_35"
                            class="annotation superscript" style="position: unset;"></span> (2019)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">A bio-metallic solution,
                          in combination with a specific surgical approach, for treating distal femoral nonunions and
                          malunions proved effective; this approach successfully restored knee anatomy and
                          functionality, leading to a satisfactory quality of life in patients, without significant
                          differences between the nonunion and malunion groups in terms of clinical and radiographic
                          outcomes</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Gardner<a href=""
                            data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                              style="position: unset;">25</span></a><span data-id="superscript_36"
                            class="annotation superscript" style="position: unset;"></span> (2008)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The study aimed to
                          evaluate the treatment outcomes for distal femoral nonunion using a standardized protocol; 31
                          patients were treated, with a 97% union rate and a mean healing time of 15.9 weeks; a
                          significant improvement in the Knee Society Rating System (KSRS) score was observed,
                          indicating a successful return to preinjury function for the majority of patients</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Bellabarba et al.<a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">26</span></a> described using an indirect reduction technique with
                  plating (95° condylar blade-plate, condylar buttress plate, or locking condylar plate) and autologous
                  cancellous bone graft in 9 (45%) of 20 patients for nonunion treatment. All healed successfully by a
                  mean of 14 weeks (range, 12 to 20 weeks). No patient had substantial postoperative axial or rotational
                  malalignment (&gt;5°) or limb-length discrepancy (&gt;1 cm). The authors concluded that contemporary
                  plating techniques effectively treat distal femoral nonunions with few complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Rollo et al.<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">27</span></a>
                  treated 35 patients with distal femoral nonunions with a blade plate. Of the 35 patients with a
                  nonunion, 10 (29%) had an oligotrophic nonunion and 25 (71%) had an atrophic nonunion. Union was
                  assessed by a modified Radiographic Union Score on radiographs at a 12-month follow-up after the
                  revision surgery. All healed, with no significant difference between the oligotrophic or atrophic
                  nonunions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">In their retrospective study, Wang and Weng analyzed the
                  results of the treatment of 13 distal femoral nonunions with internal fixation combined with cortical
                  allograft struts and autogenous iliac bone grafting. Ten nonunions were fixed with a blade-plate or
                  condylar buttress plate, and 3 nonunions were fixed with an intramedullary nail. All nonunions united,
                  at a mean of 5 months postoperatively. Given the results, the authors concluded that open reduction
                  and internal fixation (ORIF) with allograft struts and autogenous bone graft is an effective treatment
                  for distal femoral nonunions<a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">14</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Despite these reported good results with revision, lateral
                  plating for a distal femoral nonunion is not always successful. Ebraheim et al. reported that 11 (79%)
                  of 14 cases of distal femoral nonunion fixation required a secondary surgical procedure<a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">17</span></a>. In the largest single-surgeon series of femoral nonunions
                  published, Wiss et al. reported on 122 femoral nonunions, and only 66% “healed as intended” with the
                  index operation. This series included all types of femoral fractures, but proximal, shaft, and distal
                  fractures all had similar union results. These humbling results by experienced surgeons have led other
                  surgeons to evaluate other options for fixation<a href="" data-id="citation_reference_54"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_41" class="annotation superscript" style="position: unset;">24</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Dual
                Plating</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Considering the mechanical axis of the lower extremity,
                  nonunion of a distal femoral fracture can result in varus collapse if only lateral fixation is used<a
                    href="" data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_42" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_56"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_42" class="annotation superscript" style="position: unset;">28</span></a>.
                  Applying additional medial column support with plating adds mechanical stability and decreases the
                  complication rate, with minimal blood supply disruption<a href="" data-id="citation_reference_57"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_43" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">23</span></a> (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>). This
                  combination can also be used if a lateral plate or intramedullary nail has not failed biomechanically,
                  and limb alignment continues to be appropriate, but the patient has increased pain with ambulation and
                  the fracture is not healed radiographically (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 4</a> and <a
                    href="" data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">5</a>). Medial plating can complement the revision of laterally based
                  fixation when varus collapse occurs. This configuration allows more rigid fixation of the distal
                  femur. Medial fixation can be placed via a medial or a medial parapatellar exposure<a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">29</span></a> (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Lateral Plus Medial Plating</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 313px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Key Study</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Major
                          Finding</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Liu<a href=""
                            data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                              style="position: unset;">15</span></a><span data-id="superscript_45"
                            class="annotation superscript" style="position: unset;"></span> (2021)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The study retrospectively
                          analyzed 62 patients with comminuted distal femoral fractures (OTA/AO 33-A3/33-C2/33-C3), from
                          January 2015 to March 2020, who had fixation with either lateral locked plating augmented with
                          hybrid locked medial plating (LP-HLMP, n = 32) or lateral locked plating alone (LLP, n = 30);
                          LP-HLMP had a higher union rate and a lower reoperation rate</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.00985f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=jpeg&name=JBJS.23.00985f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_63"
                            class="annotation strong" style="position: unset;">Figs. 4-A and Fig.4-B</span>
                          Anteroposterior and lateral views of with retrograde nail fixation and lateral plate with loss
                          of reduction. <span data-id="strong_64" class="annotation strong"
                            style="position: unset;">Figs. 4-C and 4-D</span> Anteroposterior and lateral radiographs
                          showing the revision of a comminuted fracture initially treated with retrograde nail fixation
                          and lateral plate. Revision with a medial plate and bone graft in addition to an
                          intramedullary nail and a lateral plate.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.00985f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=jpeg&name=JBJS.23.00985f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_65"
                            class="annotation strong" style="position: unset;">Figs. 5-A and 5-B</span> Anteroposterior
                          and lateral radiographs of a comminuted left distal femoral fracture treated with locking
                          plate fixation and bone substitute that showed varus loss of reduction and nonunion at the
                          3-month follow-up. <span data-id="strong_66" class="annotation strong"
                            style="position: unset;">Figs. 5-C and 5-D</span> Anteroposterior and lateral radiographs
                          showing that the nonunion was treated with the addition of a proximal humeral plate on the
                          medial femoral condyle and tibial bone graft.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.00985f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=jpeg&name=JBJS.23.00985f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_67"
                            class="annotation strong" style="position: unset;">Figs. 6-A and 6-B</span> Anteroposterior
                          and lateral radiographs of an intra-articular distal femoral fracture nonunion at 6 months
                          after the initial injury. <span data-id="strong_68" class="annotation strong"
                            style="position: unset;">Figs. 6-C and 6-D</span> Anteroposterior and lateral radiographs
                          showing fixation of the nonunion using dual-plate fixation. <span data-id="strong_69"
                            class="annotation strong" style="position: unset;">Figs. 6-E and 6-F</span> Anteroposterior
                          and lateral radiographs showing healing of the nonunion 1 year following nonunion surgery.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The vascular anatomy of the medial distal femur allows a
                  safe zone for minimally invasive plate osteosynthesis (MIPO) plating that spans 50% to 60% of the
                  length of the femur. Then adding a medial plate to a lateral plate construct results in minimal
                  disruption of the blood supply to the fracture site<a href="" data-id="citation_reference_61"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_46"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">30</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Dual plating is an effective treatment for atrophic
                  nonunion when combined with autologous bone grafting<a href="" data-id="citation_reference_63"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_47" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_47" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_47"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_65" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">32</span></a>. Vellingiri and Nagakumar reported that dual plating
                  combined with iliac crest bone graft had promising results when treating atrophic distal nonunions
                  with a bone defect. Patients who underwent treatment had high rates of bone healing and functional
                  recovery<a href="" data-id="citation_reference_66"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_48" class="annotation superscript" style="position: unset;">7</span></a>.
                  Existing biomechanical literature suggests that dual-plating constructs are mechanically more robust
                  than other constructs commonly used in the distal femoral segment, as they allow less fracture
                  displacement in axial and torsional testing and demonstrate greater loads to failure<a href=""
                    data-id="citation_reference_67" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_49"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_68" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_49"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_69" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">33</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Dual plating can also be accomplished through a lateral
                  approach and placement of a lateral plate and an additional anterior plate. <a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 7</a> illustrates an incomplete nonunion or malunion after Ilizarov
                  fixation. A subtle persistent nonunion was present on all imaging. A corrective osteotomy to correct
                  coronal, sagittal, and rotational malalignment was performed, and a combination of lateral and
                  anterior plates allowed early mobility and weight-bearing.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.23.00985f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=jpeg&name=JBJS.23.00985f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_70"
                            class="annotation strong" style="position: unset;">Figs. 7-A and 7-B</span> Anteroposterior
                          and lateral radiographs of a left distal femoral fracture nonunion after Ilizarov fixation.
                          <span data-id="strong_71" class="annotation strong" style="position: unset;">Fig. 7-C</span>
                          Coronal computed tomography imaging confirming persistent nonunion of the distal femur. <span
                            data-id="strong_72" class="annotation strong" style="position: unset;">Figs. 7-D and
                            7-E</span> Anteroposterior and lateral radiographs after osteotomy to correct coronal,
                          sagittal, and rotational malalignment, and lateral and anterior plating that allowed early
                          mobility and weight-bearing.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Intramedullary Nail Only</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Retrograde intramedullary nailing is an approach that
                  offers biomechanical and biologic advantages while preserving soft tissues, and it is often used as an
                  internal splint that allows preservation of fracture biology (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_39" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Retrograde Intramedullary Nailing</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 723px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Key Studies</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Major
                          Finding</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wu<a href=""
                            data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                              style="position: unset;">46</span></a><span data-id="superscript_50"
                            class="annotation superscript" style="position: unset;"></span> (2018)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Twelve patients with 12
                          nonunions had a mean follow-up of 2.9 years (range, 1.1 to 4.7 years); all nonunions healed
                          (100% union rate), and the mean time to union was 4.2 months (range, 3.0 to 5.5 months);
                          satisfactory knee function among the 12 patients improved from 42% preoperatively to 100% at
                          the latest follow-up</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Koval<a href=""
                            data-id="citation_reference_71" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                              style="position: unset;">49</span></a><span data-id="superscript_51"
                            class="annotation superscript" style="position: unset;"></span> (1995)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">In a study of 16
                          nonunions treated with retrograde nailing, patients had a mean follow-up of 20 months (range,
                          9 to 46 months); of these, 4 nonunions (25%) successfully achieved union after a single
                          surgery, with a mean time to union of 17 months; a fifth nonunion achieved union at 21 months
                          following nail dynamization and subsequent nail breakage; additionally, the nonunions in 2
                          more patients achieved union after further intervention, to include exchange of a broken
                          retrograde nail; additional surgical procedures were performed in 6 of the remaining 9
                          patients in an attempt to obtain union; at a mean follow-up of 16 months (range, 9 to 23
                          months), none of the 9 had united</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Intramedullary nailing is a viable option for the
                  treatment of distal femoral fracture nonunions in which distal periarticular fractures have healed but
                  a metadiaphyseal nonunion is present (<a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 8</a>). Studies
                  have shown that exchange nailing for distal femoral nonunion may exhibit a significantly lower success
                  rate compared with the previously mentioned techniques with more rigid constructs<a href=""
                    data-id="citation_reference_72" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_52" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_52" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_73"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_52" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_52" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_52" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_52" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_75"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_52" class="annotation superscript" style="position: unset;">43</span></a>.
                  One of the main concerns with intramedullary nailing alone is maintaining adequate stability until
                  union is achieved, as the bone quality of the distal femur is poor, especially in patients who are
                  obese, have osteoporotic bone, or have distal fracture patterns without a good diaphyseal fit<a
                    href="" data-id="citation_reference_76" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_53"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_77" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">44</span></a><span data-id="superscript_53"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_78" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">46</span></a>. This low union rate is unique to the distal femur, as
                  fractures in the proximal portion of the distal femur that have developed nonunion following antegrade
                  intramedullary nailing have high union rates when treated with exchange nailing involving reaming and
                  insertion of a larger nail with cross-locking screws<a href="" data-id="citation_reference_79"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_54" class="annotation superscript" style="position: unset;">46</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 8</div>
                <div class="image-download" name="JBJS.23.00985f8" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=jpeg&name=JBJS.23.00985f8"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_41" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_73"
                            class="annotation strong" style="position: unset;">Figs. 8-A and 8-B</span> Anteroposterior
                          and lateral radiographs of a left distal femoral fracture with intercondylar extension treated
                          with a distal femoral locking plate. <span data-id="strong_74" class="annotation strong"
                            style="position: unset;">Figs. 8-C and 8-D</span> Anteroposterior and lateral radiographs
                          showing nonunion with plate failure at the 4-month follow-up after a ground-level fall. <span
                            data-id="strong_75" class="annotation strong" style="position: unset;">Figs. 8-E and
                            8-F</span> Anteroposterior and lateral radiographs after treatment of the nonunion with a
                          retrograde femoral nail supplemented by a Reamer-Irrigator-Aspirator (DePuy Synthes) bone
                          graft.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Nonunion rates reported with this technique are variable.
                  Some studies have shown union rates as high as 80% to 90%, whereas others have shown rates as low as
                  25%<a href="" data-id="citation_reference_80" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_55" class="annotation superscript"
                      style="position: unset;">47</span></a><span data-id="superscript_55"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_81" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_55" class="annotation superscript"
                      style="position: unset;">48</span></a>. Research has suggested that the likelihood of recalcitrant
                  nonunion is higher when reaming is omitted or when nail exchange is performed with closed removal of
                  the broken nail. For example, in their case series, Koval et al. reported that only 4 (25%) of 16
                  cases achieved union with a single nonunion surgery using retrograde nail fixation, with a mean
                  healing time of 7 months<a href="" data-id="citation_reference_82"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_56" class="annotation superscript" style="position: unset;">49</span></a>. In
                  contrast, in the case series by Wu, all 12 nonunions treated with retrograde intramedullary nailing
                  and cancellous bone graft or bone graft substitute achieved union with a mean union time of 4.2 months
                  (range, 3.0 to 5.5 months)<a href="" data-id="citation_reference_83"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_57" class="annotation superscript" style="position: unset;">46</span></a>.
                  Exchange nailing is more effective for diaphyseal or isthmus fractures than metaphyseal fractures.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Plate and
                Nail</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Plating combined with an intramedullary nail provides
                  additional implant stability and a better mechanical environment for bone healing compared with only
                  an intramedullary nail (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>). This
                  technique is an option for both fractures and nonunions, especially if metaphyseal and/or diaphyseal
                  comminution or a defect is present<a href="" data-id="citation_reference_84"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_58" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_58"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_85" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_58" class="annotation superscript"
                      style="position: unset;">29</span></a>. Combined nail-plate constructs offer encouraging outcomes
                  in the management of nonunion of distal femoral fractures, especially in elderly patients and patients
                  with osteopenia. This technique can be utilized when the surgeon feels that 1 of these techniques
                  alone will not supply enough stability to maintain fracture alignment. This technique is especially
                  useful when placing a retrograde nail in situations in which the fixation of cross-locking screws in
                  the distal fragment is inadequate. It is also beneficial in cases of substantial medial bone loss or
                  severe comminution, or in patients who are obese or have osteoporosis. These scenarios raise concerns
                  about fixation loss under weight-bearing, making this technique valuable for ensuring stability and
                  reducing the risk of failure during healing.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_44" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Nail-Plate Construct</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 791px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Key Studies</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Major
                          Finding</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Saxena<a href=""
                            data-id="citation_reference_86" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_59" class="annotation superscript"
                              style="position: unset;">41</span></a><span data-id="superscript_59"
                            class="annotation superscript" style="position: unset;"></span> (2023)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The augmentation of an
                          intramedullary nail with a locking plate, referred to as a nail-plate construct, provides
                          encouraging outcomes in the management of nonunion of distal femoral fractures, particularly
                          in elderly patients and patients with osteopenia; this technique appears to be associated with
                          improved biomechanical stability and restored limb alignment and allows for early
                          rehabilitation and weight-bearing, resulting in improved functional outcomes as measured by
                          the International Knee Documentation Committee (IKDC) score</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Liporace<a href=""
                            data-id="citation_reference_87" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_60" class="annotation superscript"
                              style="position: unset;">43</span></a><span data-id="superscript_60"
                            class="annotation superscript" style="position: unset;"></span> (2019)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The nail-plate
                          combination technique allows for the stable fixation of low-energy distal femoral fractures in
                          elderly patients with poor bone quality, enabling immediate weight-bearing and early
                          mobilization, which is vital for reducing the risk of complications in this patient
                          population</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Attum<a href=""
                            data-id="citation_reference_88" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_61" class="annotation superscript"
                              style="position: unset;">8</span></a><span data-id="superscript_61"
                            class="annotation superscript" style="position: unset;"></span> (2017)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">All 10 distal femoral
                          nonunions treated with a nail-plate combination successfully united (as measured using the
                          RUST [Radiographic Union Scale in Tibia Fractures] score) at the 24-month follow-up</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Combining plate and intramedullary nail fixation evenly
                  distributes stress forces, with biomechanical studies showing the nail-plate combination to have more
                  robust architecture and more resistance to failure in axial and torsional load tests and
                  load-to-failure tests compared with a locking plate or an intramedullary nail alone<a href=""
                    data-id="citation_reference_89" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_62" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_62"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_90" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_62" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_62"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_91" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_62" class="annotation superscript"
                      style="position: unset;">18</span></a>. Additionally, the nail-plate combination offers a reliable
                  technique for early mobilization (<a href="" data-id="figure_reference_16"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 9</a>) and
                  immediate weight-bearing<a href="" data-id="citation_reference_92"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_63" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_63"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_93" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_63" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_63"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_94" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_63" class="annotation superscript"
                      style="position: unset;">29</span></a>. According to Liporace and Yoon<a href=""
                    data-id="citation_reference_95" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_64" class="annotation superscript"
                      style="position: unset;">43</span></a>, the placement of the intramedullary nail initially shifts
                  the weight-bearing axis more medially, aligning it closer to the anatomical femoral axis. The addition
                  of a locking lateral plate further enhances stability, reducing movement at the fracture site.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 9</div>
                <div class="image-download" name="JBJS.23.00985f9" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=jpeg&name=JBJS.23.00985f9"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_14" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_46" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_76"
                            class="annotation strong" style="position: unset;">Figs. 9-A and 9-B</span> Anteroposterior
                          and lateral radiographs of a grade-IIIA open intra-articular distal femoral fracture treated
                          with a lateral locking plate. <span data-id="strong_77" class="annotation strong"
                            style="position: unset;">Figs. 9-C and 9-D</span> Anteroposterior and lateral radiographs
                          showing distal femoral nonunion with failure of the plate, which broke at a screw hole in the
                          middle of the bridging plating construct at the 4-month follow-up.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">For nonunions initially treated with a plate, revision of
                  the plate can be performed via the subvastus approach, with the addition of an intramedullary nail
                  prior to revision of the plating. When the initial treatment was intramedullary nailing and its
                  failure caused loss of reduction, nonunion treatment can entail exchange nailing in which bone graft
                  is obtained from the medullary canal and supplementation with a lateral plate<a href=""
                    data-id="citation_reference_96" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_65" class="annotation superscript"
                      style="position: unset;">43</span></a>. Alternatively, additional stability can be attained with
                  only medial or lateral plate augmentation of an intact intramedullary nail, using a small-fragment,
                  large-fragment, or distal femoral plate, with or without bone grafting, based on the nonunion type
                  (atrophic, oligotrophic, bone loss)<a href="" data-id="citation_reference_97"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_66"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_98" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_66"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_99" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">29</span></a>. Nonlocking screws in the plate can be placed around the
                  nail, and locking screws can be placed unicortically if the nail is blocking the screw path<a href=""
                    data-id="citation_reference_100" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_67" class="annotation superscript"
                      style="position: unset;">29</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">External
                Fixation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">The use of external fixation is usually reserved for
                  infection that precludes the use of internal fixation and/or substantial soft-tissue loss (<a href=""
                    data-id="figure_reference_17" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>). When either or both are present and there is a substantial
                  leg-length discrepancy or deformity, the Ilizarov technique can be utilized to treat both
                  complications simultaneously. Although Ilizarov developed the concept and technique of bone transport,
                  successful results were initially reported by Paley et al. while treating tibial nonunions and
                  osteomyelitis with bone loss<a href="" data-id="citation_reference_101"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_68" class="annotation superscript" style="position: unset;">5</span></a>0.
                  Saridis et al. demonstrated a 100% union rate in treating 13 distal femoral nonunions using the
                  Ilizarov external fixator. However, the time to union was 309.8 days, and 9 of 13 patients had
                  pin-site infections, severe sleep disturbance, and/or poor device tolerance<a href=""
                    data-id="citation_reference_102" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_69" class="annotation superscript"
                      style="position: unset;">51</span></a>. In their case series studying complex distal femoral
                  fractures, including aseptic and infected nonunions, both Saridis et al.<a href=""
                    data-id="citation_reference_103" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_70" class="annotation superscript"
                      style="position: unset;">51</span></a> (13 patients) and Cavusoglu et al.<a href=""
                    data-id="citation_reference_104" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_71" class="annotation superscript"
                      style="position: unset;">47</span></a> (10 patients) also reported a 100% union rate. However,
                  there were many pin-site infections and a prolonged time to union, and the device was poorly
                  tolerated<a href="" data-id="citation_reference_105"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_72" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_72"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_106" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_72" class="annotation superscript"
                      style="position: unset;">26</span></a>. The technique is an option for select patients who
                  tolerate treatment and an experienced surgeon.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_15" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_49" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>External Fixation</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 581px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Key Studies</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Major
                          Finding</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Saridis<a href=""
                            data-id="citation_reference_107" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_73" class="annotation superscript"
                              style="position: unset;">51</span></a><span data-id="superscript_73"
                            class="annotation superscript" style="position: unset;"></span> (2006)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">External fixation in the
                          treatment of infected nonunion of the distal femur with bone loss was found to be effective;
                          the study reviewed 13 patients and found that all achieved osseous union and the ability to
                          fully bear weight and had resolution of the infection after being treated with radical
                          surgical debridement and the application of an Ilizarov external fixator</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cavusoglu<a href=""
                            data-id="citation_reference_108" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_74" class="annotation superscript"
                              style="position: unset;">47</span></a><span data-id="superscript_74"
                            class="annotation superscript" style="position: unset;"></span> (2009)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">A modified Ilizarov
                          fixator effectively treated complex distal femoral fractures, achieving union in all cases
                          with minimal complications; the mean time in the fixator was 158 days, and the mean Iowa Knee
                          Score indicated good functional outcomes; this suggests that the low-profile Ilizarov fixator
                          is a viable option for managing difficult distal femoral fractures and nonunions</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Joint
                Arthroplasty</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">See the Appendix and <a href=""
                    data-id="figure_reference_18" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VII</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_16" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_51" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VII</span>Joint Arthroplasty</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 524px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Study</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Major
                          Finding</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Althausen<a href=""
                            data-id="citation_reference_109" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_75" class="annotation superscript"
                              style="position: unset;">44</span></a><span data-id="superscript_75"
                            class="annotation superscript" style="position: unset;"></span> (2003)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The LISS (Less Invasive
                          Stabilization System) fixation system’s ability to provide stable, complication-minimizing
                          fixation was successful after a periprosthetic fracture by ensuring the preservation and
                          functionality of the joint replacement, facilitating early mobilization, and reducing the risk
                          of complications that could impact the success of the prosthesis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Haidukewych<a href=""
                            data-id="citation_reference_110" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_76" class="annotation superscript"
                              style="position: unset;">54</span></a><span data-id="superscript_76"
                            class="annotation superscript" style="position: unset;"></span> (2005)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Although total knee
                          arthroplasty (TKA) as a salvage procedure after failed internal fixation or nonunion of the
                          distal femur provides reliable pain relief and functional improvement, it comes with a higher
                          risk of complications and inferior outcomes compared with primary TKA</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Bone
                Graft</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_52" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_52" style="position: unset;">
                <div class="content" style="position: unset;">See the Appendix.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Complications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_53" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_53" style="position: unset;">
                <div class="content" style="position: unset;">See the Appendix and <a href=""
                    data-id="figure_reference_19" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 10</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 10</div>
                <div class="image-download" name="JBJS.23.00985f10" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=jpeg&name=JBJS.23.00985f10"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_17" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_54" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_54" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_78"
                            class="annotation strong" style="position: unset;">Figs. 10-A and 10-B</span>
                          Anteroposterior and lateral radiographs showing comminuted intra-articular left distal femoral
                          fracture treated with a retrograde intramedullary nail and a lateral locking plate. <span
                            data-id="strong_79" class="annotation strong" style="position: unset;">Figs. 10-C and
                            10-D</span> Anteroposterior and lateral radiographs showing catastrophic failure of the
                          distal femoral nail, with breakage at the proximal screw hole. <span data-id="strong_80"
                            class="annotation strong" style="position: unset;">Figs. 10-E and 10-F</span>
                          Anteroposterior and lateral radiographs showing revision with a distal femoral replacement.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Impact on
                Quality of Life</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_55" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_55" style="position: unset;">
                <div class="content" style="position: unset;">A cohort of patients with lower-extremity nonunions
                  examined by Zlowodzki et al. was shown to have drastically lower quality-of-life scores assessed by
                  the 36-item Short Form-36 (SF-36) survey compared with patients with osteoarthritis of the hip, knee,
                  or shoulder or rheumatoid arthritis<a href="" data-id="citation_reference_111"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_77" class="annotation superscript" style="position: unset;">52</span></a>.
                  Monroy et al. found that, although nonunions may take longer to heal if adequately treated, the
                  prognosis with respect to pain and functionality is favorable, although patients may never fully reach
                  the quality-of-life levels observed in the general population<a href=""
                    data-id="citation_reference_112" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_78" class="annotation superscript"
                      style="position: unset;">19</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Summary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_56" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_56" style="position: unset;">
                <div class="content" style="position: unset;">A distal femoral nonunion produces profound physical
                  disability and usually requires a surgical procedure. Risk factors for distal femoral nonunion are
                  infection, open fracture, diabetes mellitus, increased body mass index, and tobacco use. Surgical
                  fixation has consistently demonstrated superior outcomes compared with nonoperative treatment. The
                  selection of a particular technique is based on bone quality, the degree of comminution, and whether
                  the alignment of the fragments is adequate. Several fixation methods, including lateral plating,
                  intramedullary nailing, dual fixation, external fixation, and distal femoral replacement, are
                  currently used to manage these nonunions, with no specific gold standard. Accurate alignment
                  restoration and implant stability are the most critical factors when deciding on the fixation method.
                  Constructs that provide more stability, such as a nail-plate combination or dual plating, allow
                  immediate weight-bearing and early mobilization. Patients and surgeons must be aware of risks and
                  results in order to allow shared decision-making and realistic expectations. Our recommended grades
                  for the management of distal femoral nonunions are listed in <a href="" data-id="figure_reference_20"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VIII</a><a
                    href="" data-id="citation_reference_113" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_79" class="annotation superscript"
                      style="position: unset;">53</span></a>. Further research is indicated to improve union rates and
                  decrease complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_18" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_57" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_57" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VIII</span>Grades of Recommendation</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 596px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Recommendation</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Grade<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">The fixation of distal
                          femoral nonunions has reported union rates of 0% to 34%. Recent, larger reports indicate
                          &gt;30% recalcitrant nonunions.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">B</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Adequate stabilization
                          and biology are essential to good outcomes. Internal stabilization and autologous bone
                          grafting are gold standards.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">B</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Percutaneous additional
                          stabilization can be effective in a hypertrophic nonunion.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">B</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Distal femoral
                          replacement is a viable option in elderly patients with osteoporosis and severe
                          comminution.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">B</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Exchange intramedullary
                          nail with bone graft is feasible in a diaphyseal or meta-diaphyseal fracture nonunion.</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">C</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Increased stability with
                          plate and nail or dual-plate combination allows for earlier weight-bearing and may have
                          improved results.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">C</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">According to Wright<a href="" data-id="citation_reference_114"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_80" class="annotation superscript"
                            style="position: unset;">53</span></a><span data-id="superscript_80"
                          class="annotation superscript" style="position: unset;"></span>, grade A indicates good
                        evidence (Level-I studies with consistent findings) for or against recommending intervention;
                        grade B, fair evidence (Level-II or III studies with consistent findings) for or against
                        recommending intervention; grade C, poor-quality evidence (Level-IV or V studies with consistent
                        findings) for or against recommending intervention; and grade I, insufficient or conflicting
                        evidence not allowing a recommendation for or against intervention.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_58" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_58" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I205" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I205</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Coon MS</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Best BJ</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Distal femur fractures</span>.
                In: <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">StatPearls. Treasure
                  Island</span>: StatPearls Publishing; 2022.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Distal%20femur%20fractures&as_occt=title&as_sauthors=%20%22MS%20Coon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Butt WP</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Samuel E</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Radiologic anatomy of the
                  proximal end of the femur</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Can Assoc Radiol.</span> 1966 Jun;17(2):103-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiologic%20anatomy%20of%20the%20proximal%20end%20of%20the%20femur&as_occt=title&as_sauthors=%20%22WP%20Butt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Wittauer M</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Burch MA</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> McNally M</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Vandendriessche T</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Clauss M</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Della Rocca GJ</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Giannoudis PV</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Metsemakers WJ</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Morgenstern M</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Definition of long-bone
                  nonunion: a scoping review of prospective clinical trials to evaluate current practice</span>. 2021
                Nov;52(11):3200-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Definition%20of%20long-bone%20nonunion%3A%20a%20scoping%20review%20of%20prospective%20clinical%20trials%20to%20evaluate%20current%20practice&as_occt=title&as_sauthors=%20%22M%20Wittauer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="strong_4" class="annotation strong"
                  style="position: unset;">Guidance Document for Industry and CDRH Staff for the Preparation of
                  Investigational Device Exemptions and Premarket Approval Applications for Bone Growth Stimulator
                  Devices</span>. 1998. Accessed 2024 May 30. <a
                  href="https://www.federalregister.gov/documents/1998/04/28/98-11158/guidance-document-for-industry-and-cdrh-staff-for-the-preparation-of-investigational-device"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.federalregister.gov/documents/1998/04/28/98-11158/guidance-document-for-industry-and-cdrh-staff-for-the-preparation-of-investigational-device</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Guidance%20Document%20for%20Industry%20and%20CDRH%20Staff%20for%20the%20Preparation%20of%20Investigational%20Device%20Exemptions%20and%20Premarket%20Approval%20Applications%20for%20Bone%20Growth%20Stimulator%20Devices&as_occt=title&as_sauthors=%20%22U.S.%20Food%20and%20Drug%20Administration%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Thomas JD</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Kehoe JL</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Bone nonunion. 2023 Mar
                  6</span>. In: <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">StatPearls. Treasure Island</span>: StatPearls Publishing; 2023.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20nonunion.%202023%20Mar%206&as_occt=title&as_sauthors=%20%22JD%20Thomas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Koso RE</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Terhoeve C</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Steen RG</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Zura R</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Healing, nonunion, and
                  re-operation after internal fixation of diaphyseal and distal femoral fractures: a systematic review
                  and meta-analysis</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Int Orthop.</span> 2018 Nov;42(11):2675-83.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Healing%2C%20nonunion%2C%20and%20re-operation%20after%20internal%20fixation%20of%20diaphyseal%20and%20distal%20femoral%20fractures%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22RE%20Koso%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Vellingiri K</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Nagakumar JS</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Infected non-union of the distal
                  femur</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Cureus.</span> 2021;13(1):11-4.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Infected%20non-union%20of%20the%20distal%20femur&as_occt=title&as_sauthors=%20%22K%20Vellingiri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Attum B</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Douleh D</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Whiting PS</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> White-Dzuro GA</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Dodd AC</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Shen MS</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Mir HR</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Obremskey WT</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Sethi MK</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Outcomes of distal femur
                  nonunions treated with a combined nail/plate construct and autogenous bone grafting</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2017
                Sep;31(9):e301-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20distal%20femur%20nonunions%20treated%20with%20a%20combined%20nail%2Fplate%20construct%20and%20autogenous%20bone%20grafting&as_occt=title&as_sauthors=%20%22B%20Attum%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Yoon BH</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Park IK</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Kim Y</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Oh HK</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Choo SK</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Sung YB</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Incidence of nonunion after
                  surgery of distal femoral fractures using contemporary fixation device: a meta-analysis</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 2021 Feb;141(2):225-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20of%20nonunion%20after%20surgery%20of%20distal%20femoral%20fractures%20using%20contemporary%20fixation%20device%3A%20a%20meta-analysis&as_occt=title&as_sauthors=%20%22BH%20Yoon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Ricci WM</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Streubel PN</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Morshed S</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Collinge CA</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Nork SE</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Gardner MJ</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Risk factors for failure of
                  locked plate fixation of distal femur fractures: an analysis of 335 cases</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2014
                Feb;28(2):83-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20failure%20of%20locked%20plate%20fixation%20of%20distal%20femur%20fractures%3A%20an%20analysis%20of%20335%20cases&as_occt=title&as_sauthors=%20%22WM%20Ricci%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Weber BG</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Cech O</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Pseudarthrosis.</span> New
                York: Grune and Stratton; 1976.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Reed AA</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Joyner CJ</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Brownlow HC</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Simpson AH</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Human atrophic fracture
                  non-unions are not avascular</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">J Orthop Res.</span> 2002 May;20(3):593-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Human%20atrophic%20fracture%20non-unions%20are%20not%20avascular&as_occt=title&as_sauthors=%20%22AA%20Reed%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Pushilin S</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Tarkin IS</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Management of distal femoral
                  nonunions</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Oper Tech Orthop.</span> 2018;28(3):134-40.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20distal%20femoral%20nonunions&as_occt=title&as_sauthors=%20%22S%20Pushilin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Wang JW</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Weng LH</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Treatment of distal femoral
                  nonunion with internal fixation, cortical allograft struts, and autogenous bone-grafting</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2003;85(3):436-40.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=835026" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Liu JF</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Zhou ZF</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Hou XD</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Chen YX</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Zheng LP</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Hybrid locked medial plating in
                  dual plate fixation optimizes the healing of comminuted distal femur fractures: a retrospective cohort
                  study</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Injury.</span> 2021 Jun;52(6):1614-20.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hybrid%20locked%20medial%20plating%20in%20dual%20plate%20fixation%20optimizes%20the%20healing%20of%20comminuted%20distal%20femur%20fractures%3A%20a%20retrospective%20cohort%20study&as_occt=title&as_sauthors=%20%22JF%20Liu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Wang MT</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> An VVG</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Sivakumar BS</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Non-union in lateral locked
                  plating for distal femoral fractures: a systematic review</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Injury.</span> 2019
                Nov;50(11):1790-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Non-union%20in%20lateral%20locked%20plating%20for%20distal%20femoral%20fractures%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22MT%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Ebraheim NA</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Buchanan GS</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Liu X</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Cooper ME</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Peters N</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Hessey JA</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Liu J</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Treatment of distal femur
                  nonunion following initial fixation with a lateral locking plate</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">Orthop Surg.</span> 2016
                Aug;8(3):323-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20of%20distal%20femur%20nonunion%20following%20initial%20fixation%20with%20a%20lateral%20locking%20plate&as_occt=title&as_sauthors=%20%22NA%20Ebraheim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Carbone LD</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Ahn J</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Adler RA</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Cervinka T</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Craven C</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Geerts W</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Hsu JR</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Huang D</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Karunakar MA</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Kiratli BJ</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Krause PC</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Morse LR</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Mirick Mueller GE</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Nana A</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Rogers E</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Rivera JC</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Spitler C</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Weaver FM</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Obremskey W</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Acute lower extremity fracture
                  management in chronic spinal cord injury: 2022 Delphi Consensus Recommendations</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">JB JS Open Access.</span>
                2022 Dec 8;7(4):e21.00152.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20lower%20extremity%20fracture%20management%20in%20chronic%20spinal%20cord%20injury%3A%202022%20Delphi%20Consensus%20Recommendations&as_occt=title&as_sauthors=%20%22LD%20Carbone%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Monroy A</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Urruela A</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Singh P</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Tornetta P 3rd</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Egol KA</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Distal femur nonunion patients
                  can expect good outcomes</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">J Knee Surg.</span> 2014 Feb;27(1):83-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Distal%20femur%20nonunion%20patients%20can%20expect%20good%20outcomes&as_occt=title&as_sauthors=%20%22A%20Monroy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Tarkin IS</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Sojka JM</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Biomechanical strategies for
                  managing atrophic and oligotrophic nonunions</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">Oper Tech Orthop.</span> 2008;18(2).</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biomechanical%20strategies%20for%20managing%20atrophic%20and%20oligotrophic%20nonunions&as_occt=title&as_sauthors=%20%22IS%20Tarkin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Thomas JD</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Kehoe JL</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Bone Nonunion. Treasure
                  Island</span>: StatPearls Publishing; 2022.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> DeKeyser GJ</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Hakim AJ</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> O’Neill DC</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Schlickewei CW</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Marchand LS</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Haller JM</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Biomechanical and anatomical
                  considerations for dual plating of distal femur fractures: a systematic literature review</span>.
                <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 2022;142(10):2596-609.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biomechanical%20and%20anatomical%20considerations%20for%20dual%20plating%20of%20distal%20femur%20fractures%3A%20a%20systematic%20literature%20review&as_occt=title&as_sauthors=%20%22GJ%20DeKeyser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Ebraheim NA</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Martin A</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Sochacki KR</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Liu J</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Nonunion of distal femoral
                  fractures: a systematic review</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Orthop Surg.</span> 2013 Feb;5(1):46-50.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nonunion%20of%20distal%20femoral%20fractures%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22NA%20Ebraheim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Wiss DA</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Garlich J</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Hasmi S</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Neustein A</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Risk factors for development of
                  a recalcitrant femoral non-union</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2021 Dec 1;35(12):619-25.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20development%20of%20a%20recalcitrant%20femoral%20non-union&as_occt=title&as_sauthors=%20%22DA%20Wiss%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Gardner MJ</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Toro-Arbelaez JB</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Harrison M</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Hierholzer C</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Lorich DG</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Helfet DL</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Open reduction and internal
                  fixation of distal femoral nonunions: long-term functional outcomes following a treatment
                  protocol</span>. <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J
                  Trauma.</span> 2008 Feb;64(2):434-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Open%20reduction%20and%20internal%20fixation%20of%20distal%20femoral%20nonunions%3A%20long-term%20functional%20outcomes%20following%20a%20treatment%20protocol&as_occt=title&as_sauthors=%20%22MJ%20Gardner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Bellabarba C</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Ricci WM</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Bolhofner BR</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Indirect reduction and plating
                  of distal femoral nonunions</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2002 May;16(5):287-96.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Indirect%20reduction%20and%20plating%20of%20distal%20femoral%20nonunions&as_occt=title&as_sauthors=%20%22C%20Bellabarba%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Rollo G</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Pichierri P</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Grubor P</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Marsilio A</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Bisaccia M</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Grubor M</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Pace V</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Lanzetti RM</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Giaracuni M</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Filipponi M</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Meccariello L</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The challenge of nonunion and
                  malunion in distal femur surgical revision</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Med Glas (Zenica).</span> 2019 Aug
                1;16(2).</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20challenge%20of%20nonunion%20and%20malunion%20in%20distal%20femur%20surgical%20revision&as_occt=title&as_sauthors=%20%22G%20Rollo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Bhat D</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Doddaiah S</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Murugesh P</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Pushpa N</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Vascular anatomy of distal end
                  of femur and its clinical implications</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">J Anat Soc India.</span> 2021;70(1):30-4.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vascular%20anatomy%20of%20distal%20end%20of%20femur%20and%20its%20clinical%20implications&as_occt=title&as_sauthors=%20%22D%20Bhat%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Lynch JR</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Taitsman LA</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Barei DP</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Nork SE</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Femoral nonunion: risk factors
                  and treatment options</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2008 Feb;16(2):88-97.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Femoral%20nonunion%3A%20risk%20factors%20and%20treatment%20options&as_occt=title&as_sauthors=%20%22JR%20Lynch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_138" class="annotation" style="position: unset;"> George MD</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Baker JF</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Leonard CE</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Mehta S</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Miano TA</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Hennessy S</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Risk of nonunion with
                  nonselective NSAIDs, COX-2 inhibitors, and opioids</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2020 Jul
                15;102(14):1230-8.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2436316"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Peschiera V</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Staletti L</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Cavanna M</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Saporito M</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Berlusconi M</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Predicting the failure in
                  distal femur fractures</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Injury.</span> 2018 Nov;49(Suppl 3):S2-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predicting%20the%20failure%20in%20distal%20femur%20fractures&as_occt=title&as_sauthors=%20%22V%20Peschiera%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Tripathy SK</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Mishra NP</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Varghese P</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Panigrahi S</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Purudappa PP</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Goel A</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Sen RK</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Dual-plating in distal femur
                  fracture: a systematic review and limited meta-analysis</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">Indian J Orthop.</span> 2021 Aug
                23;56(2):183-207.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dual-plating%20in%20distal%20femur%20fracture%3A%20a%20systematic%20review%20and%20limited%20meta-analysis&as_occt=title&as_sauthors=%20%22SK%20Tripathy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Smolle MA</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Leitner L</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Böhler N</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Seibert FJ</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Glehr M</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Leithner A</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Fracture, nonunion and
                  postoperative infection risk in the smoking orthopaedic patient: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">EFORT Open Rev.</span> 2021 Nov 19;6(11):1006-19.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%2C%20nonunion%20and%20postoperative%20infection%20risk%20in%20the%20smoking%20orthopaedic%20patient%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22MA%20Smolle%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Calori GM</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Phillips M</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Jeetle S</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Tagliabue L</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Giannoudis PV</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Classification of non-union:
                  need for a new scoring system?</span><span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">Injury.</span> 2008 Sep;39(Suppl 2):S59-63.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Classification%20of%20non-union%3A%20need%20for%20a%20new%20scoring%20system%3F&as_occt=title&as_sauthors=%20%22GM%20Calori%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Stoffel K</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Sommer C</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Lee M</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Zhu TY</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Schwieger K</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Finkemeier C</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Double fixation for complex
                  distal femoral fractures</span>. <span data-id="emphasis_33" class="annotation emphasis"
                  style="position: unset;">EFORT Open Rev.</span> 2022 Apr 21;7(4):274-86.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Double%20fixation%20for%20complex%20distal%20femoral%20fractures&as_occt=title&as_sauthors=%20%22K%20Stoffel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Lu J</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Guo SC</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Wang QY</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Sheng JG</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Tao SC</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">J-bone graft with double
                  locking plate: a symphony of mechanics and biology for atrophic distal femoral non-union with bone
                  defect</span>. <span data-id="emphasis_34" class="annotation emphasis" style="position: unset;">J
                  Orthop Surg Res.</span> 2020 Apr 15;15(1):144.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=J-bone%20graft%20with%20double%20locking%20plate%3A%20a%20symphony%20of%20mechanics%20and%20biology%20for%20atrophic%20distal%20femoral%20non-union%20with%20bone%20defect&as_occt=title&as_sauthors=%20%22J%20Lu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Poelmann J</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Kloen P</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Modified use of the proximal
                  humeral internal locking system (PHILOS) plate for distal femoral nonunions</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Eur J Orthop Surg
                  Traumatol.</span> 2023 Feb;33(2):425-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modified%20use%20of%20the%20proximal%20humeral%20internal%20locking%20system%20(PHILOS)%20plate%20for%20distal%20femoral%20nonunions&as_occt=title&as_sauthors=%20%22J%20Poelmann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Bologna MG</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Claudio MG</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Shields KJ</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Katz C</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Salopek T</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Westrick ER</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Dual plate fixation results in
                  improved union rates in comminuted distal femur fractures compared to single plate fixation</span>.
                <span data-id="emphasis_36" class="annotation emphasis" style="position: unset;">J Orthop.</span> 2019
                Sep 15;18:76-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dual%20plate%20fixation%20results%20in%20improved%20union%20rates%20in%20comminuted%20distal%20femur%20fractures%20compared%20to%20single%20plate%20fixation&as_occt=title&as_sauthors=%20%22MG%20Bologna%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Zhang W</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Zhang Z</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Zhang L</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Chen H</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Tang P</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Clinical outcomes of femoral
                  shaft non-union: dual plating versus exchange nailing with augmentation plating</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">J Orthop Surg Res.</span>
                2018 Nov 20;13(1):295.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20outcomes%20of%20femoral%20shaft%20non-union%3A%20dual%20plating%20versus%20exchange%20nailing%20with%20augmentation%20plating&as_occt=title&as_sauthors=%20%22W%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_192" class="annotation" style="position: unset;"> von Keudell A</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Shoji K</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Nasr M</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Lucas R</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Dolan R</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Weaver MJ</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Treatment options for distal
                  femur fractures</span>. <span data-id="emphasis_38" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2016 Aug;30(8)(Suppl 2):S25-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20options%20for%20distal%20femur%20fractures&as_occt=title&as_sauthors=%20%22A%20von%20Keudell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Saxena V</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Akshay V</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Panwar A</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Kumar S</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Management of non-union distal
                  femur fractures with augmentation nail plate construct</span>. <span data-id="emphasis_39"
                  class="annotation emphasis" style="position: unset;">Cureus.</span> 2023 Apr
                5;15(4):e37173.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20non-union%20distal%20femur%20fractures%20with%20augmentation%20nail%20plate%20construct&as_occt=title&as_sauthors=%20%22V%20Saxena%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Kiyono M</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Noda T</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Nagano H</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Maehara T</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Yamakawa Y</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Mochizuki Y</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Uchino T</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Yokoo S</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Demiya K</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Saiga K</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Shimamura Y</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Ozaki T</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Clinical outcomes of treatment
                  with locking compression plates for distal femoral fractures in a retrospective cohort</span>. <span
                  data-id="emphasis_40" class="annotation emphasis" style="position: unset;">J Orthop Surg Res.</span>
                2019 Nov 26;14(1):384.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20outcomes%20of%20treatment%20with%20locking%20compression%20plates%20for%20distal%20femoral%20fractures%20in%20a%20retrospective%20cohort&as_occt=title&as_sauthors=%20%22M%20Kiyono%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Liporace FA</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Yoon RS</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Nail plate combination
                  technique for native and periprosthetic distal femur fractures</span>. <span data-id="emphasis_41"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2019
                Feb;33(2):e64-e68.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nail%20plate%20combination%20technique%20for%20native%20and%20periprosthetic%20distal%20femur%20fractures&as_occt=title&as_sauthors=%20%22FA%20Liporace%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Althausen PL</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Lee MA</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Finkemeier CG</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Meehan JP</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Rodrigo JJ</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Operative stabilization of
                  supracondylar femur fractures above total knee arthroplasty: a comparison of four treatment
                  methods</span>. <span data-id="emphasis_42" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2003 Oct;18(7):834-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Operative%20stabilization%20of%20supracondylar%20femur%20fractures%20above%20total%20knee%20arthroplasty%3A%20a%20comparison%20of%20four%20treatment%20methods&as_occt=title&as_sauthors=%20%22PL%20Althausen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Ostrum RF</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Agarwal A</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Lakatos R</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Poka A</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Prospective comparison of
                  retrograde and antegrade femoral intramedullary nailing</span>. <span data-id="emphasis_43"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2000
                Sep-Oct;14(7):496-501.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prospective%20comparison%20of%20retrograde%20and%20antegrade%20femoral%20intramedullary%20nailing&as_occt=title&as_sauthors=%20%22RF%20Ostrum%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Wu CC</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Retrograde dynamic locked
                  intramedullary nailing for distal femoral aseptic nonunion associated with broken antegrade locked
                  nail</span>. <span data-id="emphasis_44" class="annotation emphasis" style="position: unset;">Acta
                  Orthop Belg.</span> 2018 Jun;84(2):132-40.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Retrograde%20dynamic%20locked%20intramedullary%20nailing%20for%20distal%20femoral%20aseptic%20nonunion%20associated%20with%20broken%20antegrade%20locked%20nail&as_occt=title&as_sauthors=%20%22CC%20Wu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Cavusoglu AT</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Ozsoy MH</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Dincel VE</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Sakaogullari A</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Basarir K</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Ugurlu M</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">The use of a low-profile
                  Ilizarov external fixator in the treatment of complex fractures and non-unions of the distal
                  femur</span>. <span data-id="emphasis_45" class="annotation emphasis" style="position: unset;">Acta
                  Orthop Belg.</span> 2009 Apr;75(2):209-18.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20a%20low-profile%20Ilizarov%20external%20fixator%20in%20the%20treatment%20of%20complex%20fractures%20and%20non-unions%20of%20the%20distal%20femur&as_occt=title&as_sauthors=%20%22AT%20Cavusoglu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Karek MR</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Jackson NM</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Flynn JC</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Vaidya R</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Markel DC</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Elution profiles of two methods
                  of antibiotic tibial nail preparations</span>. <span data-id="emphasis_46" class="annotation emphasis"
                  style="position: unset;">Orthopedics.</span> 2017 May 1;40(3):e436-42.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Elution%20profiles%20of%20two%20methods%20of%20antibiotic%20tibial%20nail%20preparations&as_occt=title&as_sauthors=%20%22MR%20Karek%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Koval KJ</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Seligson D</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Rosen H</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Fee K</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Distal femoral nonunion:
                  treatment with a retrograde inserted locked intramedullary nail</span>. <span data-id="emphasis_47"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span>
                1995;9(4):285-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Distal%20femoral%20nonunion%3A%20treatment%20with%20a%20retrograde%20inserted%20locked%20intramedullary%20nail&as_occt=title&as_sauthors=%20%22KJ%20Koval%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Paley D</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Catagni MA</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Argnani F</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Villa A</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Benedetti GB</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Cattaneo R</span><span
                  data-id="strong_48" class="annotation strong" style="position: unset;">.</span><span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Ilizarov treatment of tibial
                  nonunions with bone loss</span>. <span data-id="emphasis_48" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 1989;(241):146-65.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ilizarov%20treatment%20of%20tibial%20nonunions%20with%20bone%20loss&as_occt=title&as_sauthors=%20%22D%20Paley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Saridis A</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Panagiotopoulos E</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Tyllianakis M</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Matzaroglou C</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Vandoros N</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Lambiris E</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">The use of the Ilizarov method
                  as a salvage procedure in infected nonunion of the distal femur with bone loss</span>. <span
                  data-id="emphasis_49" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 2006;88(2):232-37.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20the%20Ilizarov%20method%20as%20a%20salvage%20procedure%20in%20infected%20nonunion%20of%20the%20distal%20femur%20with%20bone%20loss&as_occt=title&as_sauthors=%20%22A%20Saridis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Zlowodzki M</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Obremskey WT</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Thomison JB</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Kregor PJ</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Functional outcome after
                  treatment of lower-extremity nonunions</span>. <span data-id="emphasis_50" class="annotation emphasis"
                  style="position: unset;">J Trauma.</span> 2005;58(2):312-17.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Functional%20outcome%20after%20treatment%20of%20lower-extremity%20nonunions&as_occt=title&as_sauthors=%20%22M%20Zlowodzki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Wright JG</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Revised grades of
                  recommendation for summaries or reviews of orthopaedic surgical studies</span>. <span
                  data-id="emphasis_51" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2006 May;88(5):1161-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revised%20grades%20of%20recommendation%20for%20summaries%20or%20reviews%20of%20orthopaedic%20surgical%20studies&as_occt=title&as_sauthors=%20%22JG%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Haidukewych GJ</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Springer BD</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Jacofsky DJ</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Berry DJ</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Total knee arthroplasty for
                  salvage of failed internal fixation or nonunion of the distal femur</span>. <span
                  data-id="emphasis_52" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2005
                Apr;20(3):344-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20knee%20arthroplasty%20for%20salvage%20of%20failed%20internal%20fixation%20or%20nonunion%20of%20the%20distal%20femur&as_occt=title&as_sauthors=%20%22GJ%20Haidukewych%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;5d68b170-bdcc-47d5-b749-edde4710666f&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[&quot;ta&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=supplement&name=10542"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D5d68b170-bdcc-47d5-b749-edde4710666f%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F2028%26topics%3Dta"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node eletter" data-id="eletter_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="file" style="position: unset;"><span class="" style="position: unset;">eLetter 1</span><a
                  class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;5d68b170-bdcc-47d5-b749-edde4710666f&quot;,&quot;type&quot;:&quot;eletter&quot;,&quot;topics&quot;:[&quot;ta&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=eletter&name=1454"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Current Concepts Review</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 2028</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00985</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 30, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_4" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_53"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Vanderbilt
                            Orthopaedic Institute, Vanderbilt University Medical Center, Nashville, Tennessee</span>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_5" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5d68b170-bdcc-47d5-b749-edde4710666f&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;ta&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=pdf&name=JBJS.23.00985.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=pdf&name=JBJS.23.00985.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_54"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_55" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I204" target="_blank" data-id="link_2"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I204</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5d68b170-bdcc-47d5-b749-edde4710666f&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;ta&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=5d68b170-bdcc-47d5-b749-edde4710666f&type=zip&name=JBJS.23.00985.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Elsa B. Rodriguez-Baron, MD, MPH<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2154-4843" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2154-4843</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hosaam Gawad, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-7840-4307" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-7840-4307</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Basem Attum, MD, MS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5272-3679" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5272-3679</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">William T. Obremskey, MD, MPH<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:william.obremskey@vumc.org" class=""
                    style="position: unset;">william.obremskey@vumc.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8942-1842" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8942-1842</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Vanderbilt Orthopaedic Institute, Vanderbilt University Medical Center,
                Nashville, Tennessee</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 38211.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
