import { mockedArticleContentMarkdown } from "../constants";
import { useArticles } from "./useArticles";

export const useDetailArticle = (id: number) => {
	const { handleSaveArticle, isSavedArticle } = useArticles();

	const menuIcons = [
		{
			icon: isSavedArticle(id) ? "bookmark" : "bookmark-outline",
			onPress: () => handleSaveArticle(id),
		},
		// {
		//   icon: 'information-outline',
		//   onPress: () => console.log('Information'),
		// },
		// {
		//   icon: 'dots-vertical',
		//   onPress: () => console.log('Dots'),
		// },
	];

	return {
		menuIcons,
		mockedArticleContentMarkdown,
	};
};
