import { Box, CustomListSubheader } from "@memorang/ui";
import { QuickActionChips } from "components/QuickActionChips";
import { memo } from "react";
import { SortButton } from "./SortButton";

type QuickActionsChipType = {
	label: string;
	type: string;
	tooltip: string;
	selected?: boolean;
	closeIcon?: string;
	onClose?: () => void;
};

type Props = {
	numChallenges: number;
	quickActionsChips: QuickActionsChipType[];
	onQuickActionPress: (type: string) => void;
	searchQuery?: string;
};

export const ChallengesHeader = memo(
	({
		numChallenges,
		quickActionsChips,
		onQuickActionPress,
		searchQuery,
	}: Props) => (
		<Box>
			<QuickActionChips
				quickActionsChips={quickActionsChips}
				handleQuickActionPress={onQuickActionPress}
			/>
			<Box
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<CustomListSubheader>{numChallenges} Challenges</CustomListSubheader>
				{!searchQuery && <SortButton />}
			</Box>
		</Box>
	),
);

ChallengesHeader.displayName = "ChallengesHeader";
