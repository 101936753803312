import type { Article } from "../../../../../types";

export const Article613: Article = {
	id: 613,
	rsuiteId: "5f4649ed-180c-4e56-afd0-7b8ef9bf82e9",
	type: "scientific article",
	title:
		"Management of Dorsal Bunion in Nonambulatory Adolescents with Cerebral Palsy",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=jpeg&name=JBJS.24.00092f1",
	subSpecialties: ["foot_ankle"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Management of Dorsal Bunion in Nonambulatory Adolescents
                with Cerebral Palsy</div>
              <div class="text subtitle" style="position: unset;">A Retrospective Cohort Study</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Samuel K. Van
                      de Velde, MD, MPH, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">H. Kerr
                      Graham, MD, FRACS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Background:</span> A dorsal bunion may occur in nonambulatory
                        adolescents with cerebral palsy (CP) and a Gross Motor Function Classification System (GMFCS)
                        level of IV or V. The deformity can cause pain, skin breakdown, and difficulty wearing shoes and
                        braces. A consensus on the biomechanics and surgical management of dorsal bunions in persons
                        with severe CP has not been established.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Methods:</span> This retrospective cohort study included 23
                        nonambulatory adolescents with CP, GMFCS level IV or V, and symptomatic dorsal bunions requiring
                        surgery. The median age at surgery was 17 years, and the median follow-up was 56 months.
                        Reconstructive surgery included the excision of a 2 to 3-cm segment of the tibialis anterior
                        tendon to correct the elevation of the first metatarsal. The fixed deformity of the first
                        metatarsophalangeal joint was managed with use of corrective arthrodesis and dorsal plate
                        fixation. Clinical and radiographic outcomes were assessed preoperatively and postoperatively at
                        the transition to adult services.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Results:</span> There were significant improvements in the clinical
                        and radiographic outcome measures (p &lt; 0.001). Pain was relieved, and there were no further
                        episodes of skin breakdown. The elevation of the first metatarsal was corrected from a mean of
                        3° of dorsiflexion to a mean of 19° of plantar flexion. The deformity of the first
                        metatarsophalangeal joint was corrected from a mean of 55° of plantar flexion to a mean of 21°
                        of dorsiflexion. Six patients had complications, all of which were grade I or II according to
                        the modified Clavien-Dindo system.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The surgical reconstruction of a dorsal bunion
                        via soft-tissue rebalancing of the first ray and corrective arthrodesis of the first
                        metatarsophalangeal joint resulted in favorable medium-term clinical and radiographic outcomes
                        in nonambulatory adolescents with CP.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level IV</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Hip displacement management and neuromuscular scoliosis
                  correction dominate the orthopaedic care of nonambulatory children with cerebral palsy (CP)<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">4</span></a>.
                  However, once children with CP reach adolescence, painful deformities of the first metatarsophalangeal
                  joint (MTPJ) may also develop<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">5</span></a>.
                  Ambulatory adolescents with spastic diplegia and a Gross Motor Function Classification System (GMFCS)
                  level of I to III mainly develop symptomatic hallux valgus, whereas nonambulatory adolescents with a
                  GMFCS level of IV or V more commonly develop dorsal bunions<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">6</span></a>. The accepted treatment of symptomatic hallux valgus in most
                  adolescents with CP is arthrodesis of the first MTPJ<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">8</span></a>. The optimal surgical treatment of dorsal bunions in
                  adolescents with CP, however, remains unknown<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">To date, research on dorsal bunions and their treatment
                  has consisted of small case series and case reports on heterogeneous patient groups with different
                  functional levels, prior surgical treatment, and diagnoses, including hallux rigidus, congenital
                  talipes equinovarus, poliomyelitis, and CP<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">11</span></a>. Various surgical combinations have been advocated to
                  correct dorsiflexion at the metatarsocuneiform joint, elevation of the first metatarsal, and fixed
                  plantar flexion contracture at the first MTPJ. Treatment algorithms usually comprise a combination of
                  the following: plantar flexion osteotomy of the first ray; corrective arthrodesis of the
                  metatarsocuneiform and/or naviculocuneiform joints, sometimes with a partial excision of the proximal
                  phalanx; and tendon transfers to rebalance the first ray and to maintain correction<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">11</span></a>. The first MTPJ is typically preserved, except when it
                  demonstrates severe degeneration<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">11</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Lapidus and Goldner identified an overactive tibialis
                  anterior as being associated with a dorsal bunion, and both advised a transfer of the tibialis
                  anterior tendon (TAT) as a part of the surgical reconstruction<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">9</span></a>. In a
                  previous population-based study of deformities of the first MTPJ in patients with CP<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">5</span></a>, Van de Velde et al. hypothesized that the emergence of
                  dystonia or spastic dystonia in the tibialis anterior muscle during the adolescent growth spurt
                  results in increased dorsiflexion at the ankle, progressive elevation of the first metatarsal, and
                  flexion at the first MTPJ (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 1</a> and <a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">2</a>). In the present study, we hypothesized that the elevation of the
                  first metatarsal remains flexible in patients in whom dystonic hypertonia is intermittent. In
                  contrast, the hyperflexed first MTPJ undergoes degeneration with severe cartilage loss, and the
                  deformity becomes fixed. On the basis of this hypothesis, we developed a surgical algorithm for dorsal
                  bunion correction in nonambulatory adolescents with CP, which consists of both the excision of a 2 to
                  3-cm segment of the TAT to correct the elevation of the first metatarsal and corrective arthrodesis of
                  the first MTPJ, and does not require a plantar flexion osteotomy of the first ray or tendon transfers.
                  The principal deforming force, the dystonic tibialis anterior muscle, is defunctioned, allowing the
                  first metatarsal to flex. The intact flexor hallucis brevis (FHB) and flexor hallucis longus (FHL) are
                  then able to act as depressors of the first ray (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 3</a> and <a
                    href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">4</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00092f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=jpeg&name=JBJS.24.00092f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_13" style="position: unset;">
                        <div class="content" style="position: unset;">Illustration depicting a biomechanical hypothesis
                          for the development of dorsal bunions in adolescents with cerebral palsy (CP). FHB = flexor
                          hallucis brevis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00092f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=jpeg&name=JBJS.24.00092f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;">Preoperative clinical photograph of a symptomatic
                          dorsal bunion in a patient with GMFCS level IV. The ankle is dorsiflexed, the tibialis
                          anterior is contracted, the first metatarsal is elevated, and there is a healing area of skin
                          breakdown over the apex of the dorsal bunion.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00092f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=jpeg&name=JBJS.24.00092f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;">Illustration depicting a surgical algorithm for
                          dorsal bunion correction in nonambulatory adolescents with cerebral palsy (CP). A segment of
                          the contracted tibialis anterior tendon (TAT) is excised and corrective arthrodesis of the
                          first metatarsophalangeal joint (MTPJ) is performed. The elevated first ray is plantar-flexed
                          by the intact flexor hallucis brevis (FHB) and flexor hallucis longus (FHL).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00092f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=jpeg&name=JBJS.24.00092f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;">Postoperative clinical photograph of the foot of
                          the same patient shown in <a href="" data-id="figure_reference_5"
                            class="annotation figure_reference resource-reference" style="position: unset;">Figure
                            2</a>. Surgical reconstruction of the dorsal bunion was performed with use of soft-tissue
                          rebalancing of the first ray and corrective arthrodesis of the first metatarsophalangeal joint
                          (MTPJ). The deformity has been corrected and the inflammation has resolved. The patient was
                          able to wear shoes and ankle-foot orthoses (AFOs) and reported improved transfers and
                          weight-bearing.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The purpose of this study was to review the clinical and
                  radiographic outcomes of 23 nonambulatory adolescents with CP and symptomatic dorsal bunions who
                  underwent excision of a segment of the TAT and arthrodesis of the first MTPJ.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study Design
                and Setting</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">We performed a retrospective observational study using
                  prospectively collected data on adolescents with CP who had undergone surgery for a dorsal bunion
                  between 1996 and 2019 at a single tertiary-level children’s hospital. The Ethics in Human Research
                  Committee of the investigating institution gave ethical approval for the study (QA/97547/RCHM-2023).
                  The inclusion criteria were a diagnosis of CP with registration on the statewide CP register, the
                  surgical correction of a dorsal bunion in 1 or both feet, and the availability of standardized
                  radiographs made at baseline and at a minimum follow-up of 36 months postoperatively. Patients were
                  classified according to their functional level, which was determined with use of the GMFCS<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">12</span></a>; the movement disorder (spastic, dystonic, or mixed tone);
                  and the topographical distribution (hemiplegia, diplegia, triplegia, or quadriplegia)<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_12" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">13</span></a>.
                  Out of 27 patients who were identified, 2 patients who had previously undergone surgery for pes valgus
                  were excluded, as were 2 patients who underwent lengthening of the TAT rather than excision of a
                  segment of the tendon.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">A total of 23 patients with CP (12 male and 11 female)
                  were included in the study and their data were evaluated. All except 1 patient underwent bilateral
                  surgery for dorsal bunions (45 feet). The median age at the time of surgery was 17 years (range, 14 to
                  20 years). The functional level was classified as GMFCS level V in 14 patients and level IV in 9
                  patients. Spastic dystonia was present in 14 patients, and a dystonic movement disorder was present in
                  the remaining 9 patients. All patients had quadriplegia.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Clinical and radiographic outcomes were obtained before
                  and after surgery. Clinical and radiographic follow-up continued until the transition to adult
                  services at age 20 to 25.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Procedure</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The tibialis anterior muscle was injected with botulinum
                  neurotoxin A (BoNT-A) to reduce hypertonia, postoperative pain, and the risk of a postoperative
                  dystonic crisis. Using 100 units of onabotulinumtoxinA (BOTOX; Allergan) diluted in 4 mL of normal
                  saline solution, a dose of 3 U per kg of body weight was administered per side, with use of muscle
                  stimulation for improved accuracy<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">14</span></a>. A
                  thigh tourniquet was utilized, and the operation was performed with the patient under general
                  anesthesia, which was supplemented by a sciatic nerve block for postoperative analgesia.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">A 2 to 3-cm segment of the TAT was excised through a short
                  incision just proximal to the extensor retinaculum (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>). The
                  dorsal bunion was approached through a 6-cm dorsomedial incision, with care taken to avoid atrophic
                  skin that was damaged from previous inflammation or ulceration (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>). The extensor hallucis longus tendon was identified, mobilized,
                  and retracted. Extensive division of the joint capsule and mobilization of the collateral ligaments
                  were required to fully mobilize the fixed flexion deformity of the proximal phalanx<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">11</span></a>. A trial reduction was performed to confirm that the first
                  metatarsal would have a satisfactory degree of plantar flexion and that the proximal phalanx could be
                  reduced onto the head of the metatarsal. Preparation of the joint was performed with use of
                  cup-and-cone reamers of the appropriate size<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_15" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">15</span></a>. Care was taken to avoid excessive shortening of
                  osteoporotic bone. The subchondral bone was perforated on both sides of the joint to promote earlier
                  fusion. A pre-bent, 4-hole Vitallium plate (Stryker) was utilized in the first 6 patients to provide
                  stable internal fixation and to correct the deformity<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">16</span></a>.
                  Later, in the remaining patients, a custom dorsal locking plate (Acumed) designed for fusion of the
                  first MTPJ was utilized. This plate has a preset hallux valgus angle (HVA) and a preset lateral
                  metatarsophalangeal angle (LMTPA)<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_17" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">17</span></a>. Following copious wound irrigation, the incision was
                  closed routinely in layers with use of interrupted 3-0 nylon sutures in the skin. Two patients
                  underwent percutaneous release of toe flexion contractures at the time of the index procedure, and no
                  patients underwent a concomitant procedure for pes valgus during the index surgery (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 5</a> and <a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">6</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00092f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=jpeg&name=JBJS.24.00092f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">Preoperative photograph of a severe dorsal bunion
                          in a patient with GMFCS level V. Under the influence of the contracted tibialis anterior,
                          which is “tenting” the skin, the foot is dorsiflexed and supinated. There is a severe fixed
                          flexion deformity of the first metatarsophalangeal joint (MTPJ) and fixed flexion contractures
                          of the lesser toes. There were 2 episodes of skin breakdown over the apex of the bunion prior
                          to surgical correction.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.24.00092f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=jpeg&name=JBJS.24.00092f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Postoperative photograph of the foot of the same
                          patient shown in <a href="" data-id="figure_reference_10"
                            class="annotation figure_reference resource-reference" style="position: unset;">Figure
                            5</a>. Surgical reconstruction of the first ray and percutaneous flexor tenotomies of the
                          lesser toes were performed. The flexor digitorum longus (FDL) and both slips of the flexor
                          digitorum brevis (FDB) were released in the 4 lesser toes, to good effect.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Several layers of gauze dressing were placed directly over
                  the dorsal incision, and a well-padded posterior back-slab cast was applied with the foot and ankle in
                  10° to 20° of plantar flexion to stretch out the lengthened TAT. The cast was removed at 2 weeks
                  postoperatively to inspect the wound and remove the skin sutures. A well-padded cast was reapplied for
                  a further 4 weeks; alternatively, a CAM (controlled ankle motion) walker was utilized in adolescents
                  who were capable of weight-bearing transfers. Radiographs were made prior to discharge and at 6 and 12
                  weeks to assess the integrity of the fixation and the state of fusion. An ankle-foot orthosis (AFO)
                  was provided after cast removal to protect the fusion site and to reduce the risk of recurrent
                  contractures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical
                Evaluations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">To record patient-reported outcome measures (PROMs) in the
                  present study, we devised a 10-point visual analog scale (VAS) for each of 3 domains: pain, skin
                  inflammation and/or sores, and difficulty with shoe and brace wear<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">18</span></a>. Each scale ranged from “none” to “very severe” (see
                  Appendix Fig. 1) and was completed by the patient or their parent or caregiver. PROMs were recorded
                  preoperatively and at the time of final follow-up. The final evaluations were conducted during the
                  clinic appointment for the formal transition to adult services. Postoperative complications were noted
                  and graded with use of the modified Clavien-Dindo (MCD) system, which has good reliability<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">8</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Evaluations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Standardized simulated weight-bearing anteroposterior and
                  lateral radiographs were made by having the patient seated in their wheelchair with the film cassette
                  placed under the foot for the anteroposterior view or at the side for the lateral view (<a href=""
                    data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 7</a> through <a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">10</a>)<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">5</span></a>. Measurements included the HVA, the LMTPA (see Appendix Fig.
                  2), and the first metatarsal declination angle (MTDA; see Appendix Fig. 3)<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_21" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_21" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">20</span></a>. Radiographic measurements on digital radiographs were made
                  by 1 attending orthopaedic surgeon and 1 fellow in orthopaedic surgery with use of PACS (picture
                  archiving and communication system) tools. The mean of each pair of measurements was utilized for the
                  subsequent analysis. The analysis was performed with use of the preoperative radiographs and
                  radiographs from the clinic appointment for the transition to adult services.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.24.00092f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=jpeg&name=JBJS.24.00092f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Preoperative lateral radiograph of the left foot
                          of a 14-year-old boy with cerebral palsy (CP), dystonia, GMFCS level IV, and bilateral
                          symptomatic dorsal bunions. The first metatarsal is horizontal to the floor, and the head of
                          the metatarsal is subcutaneous. The first metatarsophalangeal joint (MTPJ) has a severe fixed
                          flexion deformity.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 8</div>
                <div class="image-download" name="JBJS.24.00092f8" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=jpeg&name=JBJS.24.00092f8"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;">Postoperative lateral radiograph following
                          reconstruction of the first ray in the same patient first shown in <a href=""
                            data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                            style="position: unset;">Figure 7</a>. The first metatarsal has been flexed, the first
                          metatarsal declination angle (MTDA) has been corrected to the normal range, and the flexion
                          deformity of the first metatarsophalangeal joint (MTPJ) has been corrected. The alignment of
                          the first ray has been improved at each articulation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 9</div>
                <div class="image-download" name="JBJS.24.00092f9" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=jpeg&name=JBJS.24.00092f9"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Preoperative anteroposterior radiograph of the
                          feet of the same patient first shown in <a href="" data-id="figure_reference_14"
                            class="annotation figure_reference resource-reference" style="position: unset;">Figure
                            7</a>. All symptoms were dorsal, not medial.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 10</div>
                <div class="image-download" name="JBJS.24.00092f10" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=jpeg&name=JBJS.24.00092f10"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;">Postoperative anteroposterior radiograph of the
                          feet of the same patient first shown in <a href="" data-id="figure_reference_15"
                            class="annotation figure_reference resource-reference" style="position: unset;">Figure
                            7</a>. The hallux valgus was corrected by performing bilateral arthrodesis of the first
                          metatarsophalangeal joint (MTPJ) with use of a locking plate with a preset hallux valgus angle
                          (HVA). There is a moderate degree of pes valgus and midfoot abduction, which were not
                          symptomatic and were not corrected.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The statistical analysis was performed with use of IBM
                  SPSS Statistics (version 29.0.0.0). The results are given as the mean, with the standard deviation or
                  range, or as the median and range. Data were tested for normality with use of the Kolmogorov-Smirnov
                  test. Significance was analyzed with use of the Student t test for unpaired data, with the level of
                  significance set at p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Two patients with a previous surgery for pes valgus were
                  excluded. Two patients with deformities that had been managed with intramuscular lengthening of the
                  tibialis anterior before the surgical approach was standardized, and who required revision surgery to
                  excise a segment of the TAT, were also excluded. This left 23 patients (45 feet) for analysis. No
                  patients were lost to follow-up in the period between the surgery and the evaluation at the
                  appointment for the transition to adult services. The median follow-up duration was 56 months (range,
                  36 to 152 months).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Preoperatively, the mean VAS score in each of the 3
                  domains was 7 (“severe”; range, 5 to 10). In the 12 months before the surgery, 11 patients had skin
                  ulceration and 12 had inflammation without frank skin breakdown (<a href=""
                    data-id="figure_reference_16" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). One patient required admission to the hospital for the
                  management of infected sores over the apex of the dorsal bunion, 1 patient had a sore that was managed
                  at home with intravenous antibiotics and dressings, and another 3 patients received ≥1 course of oral
                  antibiotics.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Intraoperatively, a fixed flexion deformity of the first
                  MTPJ, with at least 50% of the area exhibiting full-thickness cartilage loss, was encountered in all
                  patients. The first metatarsocuneiform joint was flexible, and the elevation of the first metatarsal
                  was reduced with use of manual pressure after resecting the TAT.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">There were no recurrences of dorsal bunion deformity,
                  pain, skin irritation, or skin breakdown following surgical reconstruction. There were no instances of
                  nonunion or acquired equinus deformity. At the final postoperative follow-up, the mean VAS scores for
                  pain, skin irritation, and difficulties with shoes and braces were 1.0, 0.8, and 0.7, respectively
                  (range for each domain, 0 to 3), which were significantly improved from baseline (p &lt; 0.001). VAS
                  scores were completed by parents or caregivers for 15 patients with communication difficulties. All
                  patients were prescribed solid AFOs after cast removal. A total of 12 patients (8 with GMFCS level IV)
                  continued to use AFOs in the longer-term follow-up period in order to facilitate standing transfers
                  and limited ambulation postoperatively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">The surgical and medical complications are listed in <a
                    href="" data-id="figure_reference_17" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>. There were 10 complications across 6 patients; all
                  complications had an MCD grade of I or II. Adverse events were mild, self-limiting, and usually
                  multiple.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Complications Graded with Use of the Modified
                          Clavien-Dindo System<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 471px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">MCD Grade</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Complication</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Patients</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Treatment</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Grade I</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Acute increase in
                          dystonia and painful spasms</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Benzodiazepines,
                          baclofen, and opiate analgesia</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Grade I</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Temporary respiratory
                          depression</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Chest
                          physiotherapy</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Grade II</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Urinary tract
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV antibiotics</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Grade II</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Superficial wound
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Suture removal and
                          dressings</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Grade II</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Superficial cast
                          sores</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Dressings and change of
                          cast</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">IV = intravenous. Ten complications were recorded in 6 patients. When
                        complications occurred, they were usually multiple: for example, increased dystonia and spasms
                        required increased doses of benzodiazepines and opiates, and were followed by either rubbing
                        against the cast, with associated skin sores, or respiratory depression requiring chest
                        physiotherapy.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Outcomes (Table II)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">The mean HVA (and standard deviation) was 27° ± 6°
                  preoperatively and 9° ± 3° postoperatively (<a href="" data-id="figure_reference_18"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 6</a> and <a
                    href="" data-id="figure_reference_19" class="annotation figure_reference resource-reference"
                    style="position: unset;">7</a>). The deformity of the first MTPJ was corrected from a mean LMTPA of
                  55° ± 10° of plantar flexion preoperatively to a mean of 21° ± 5° of dorsiflexion at the final
                  follow-up (p &lt; 0.0001). The elevation of the first metatarsal was significantly (p &lt; 0.0001)
                  improved from a mean first MTDA of 3° ± 9° of dorsiflexion preoperatively to a mean of 19° ± 3° of
                  plantar flexion postoperatively (<a href="" data-id="figure_reference_20"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 8</a> and <a
                    href="" data-id="figure_reference_21" class="annotation figure_reference resource-reference"
                    style="position: unset;">9</a>; <a href="" data-id="figure_reference_22"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_40" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Radiographic Outcomes in the Right and Left Feet of
                          23 Nonambulatory Adolescents with CP and Symptomatic Dorsal Bunions<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 373.48px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.868074);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Radiographic
                          Outcome</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preoperative<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="emphasis_29" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Postoperative<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">HVA, right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.8 ± 6.2
                          (14 to 38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.1 ± 3.3
                          (3 to 15)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">HVA, left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.0 ± 7.1
                          (16 to 43)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.0 ± 3.5
                          (3 to 16)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LMTPA, right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−54.9 ±
                          9.3 (−77 to −37)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.0 ± 4.0
                          (15 to 28)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LMTPA, left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−55.2 ±
                          9.7 (−74 to −35)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.2 ± 5.8
                          (13 to 36)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">First MTDA, right</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.7 ± 8.8
                          (−24 to 13)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.9 ± 2.6
                          (14 to 23)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">First MTDA, left</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.8 ± 8.7
                          (−24 to 12)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.1 ± 3.5
                          (12 to 26)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">HVA = hallux valgus angle, LMTPA = lateral metatarsophalangeal angle,
                        first MTDA = first metatarsal declination angle. See Appendix Figs. 2 and 3 for the measurement
                        techniques for the LMTPA and MTDA.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± standard deviation, with the range in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Determined with use of the Student t test for unpaired data. P &lt;
                        0.05 was significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">In this study, we found that dorsal bunion correction by
                  excision of a 2 to 3-cm segment of the TAT and corrective arthrodesis of the first MTPJ resulted in
                  favorable medium-term clinical and radiographic outcomes in nonambulatory adolescents with CP. The
                  lasting effect of this procedure confirmed our hypothesis regarding the underlying abnormal
                  biomechanics of dorsal bunions in CP—that is, that dystonia in the tibialis anterior muscle leads to
                  progressive but flexible elevation of the first metatarsal and fixed flexion at the first MTPJ.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">We agree with the views of Lapidus, Goldner, and Bleck
                  that the elevation of the first metatarsal, due to a combination of hypertonia and the contracture of
                  the TAT, is the primary deformity in symptomatic dorsal bunions in CP<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_22" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_22" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">10</span></a>. In an analysis of a population-based CP register over a
                  study period of 21 years, Van de Velde et al. reported that symptomatic dorsal bunions developed in
                  nonambulatory adolescents who had a GMFCS level of IV or V (as seen in the present study) and either
                  dystonic hypertonia or mixed-tone hypertonia (i.e., spastic dystonia) in the tibialis anterior
                  muscle<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">5</span></a>. We
                  considered that the TAT contracture required lengthening, and we judged that a tendon transfer was
                  unlikely to succeed (<a href="" data-id="figure_reference_23"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 2</a> and <a
                    href="" data-id="figure_reference_24" class="annotation figure_reference resource-reference"
                    style="position: unset;">5</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Given the inherent risks of deformity reversal associated
                  with dystonia, we cautiously approached the defunctioning of the TAT. Before performing any surgery in
                  the first 4 patients, we injected the tibialis anterior muscle with BoNT-A to see if it would be
                  beneficial and if there was a risk of precipitating an equinus deformity<a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_24" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">14</span></a>.
                  The clinical effects of injections of BoNT-A were marginal and short-lived. Prior to the
                  standardization of the surgical approach to include the resection of a segment of the TAT, 2 patients
                  underwent conservative intramuscular lengthening of the TAT that was performed proximal to the
                  extensor retinaculum. Intramuscular lengthening was effective in the short term, but painful dystonic
                  contractions were noted at 8 to 10 weeks after the index surgery. A revision surgery with excision of
                  the tendon was required for both patients, who demonstrated excellent outcomes at the time of the
                  final follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">We believe that surgical reconstruction of the first ray
                  is advisable as a matter of urgency once there is symptomatic inflammation over the apex of the dorsal
                  bunion and should preferably be performed before there are multiple episodes of skin ulceration<a
                    href="" data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_25" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">6</span></a>.
                  Theoretically, if the deformity were recognized in its earliest stage, then isolated defunctioning of
                  the TAT might be sufficient to correct the subsequent abnormal biomechanics of the first ray<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">5</span></a>. However, we found that, once the presence of a dorsal
                  bunion was established, the deformity progressed rapidly, with escalating pain and skin ulceration,
                  subluxation, and a severe fixed flexion deformity of the first MTPJ that included full-thickness loss
                  of articular cartilage at the head of the first metatarsal (<a href="" data-id="figure_reference_25"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 7</a>). In
                  contrast to the severe and fixed flexion contracture at the first MTPJ, the elevation of the first
                  metatarsal remained flexible and was easily reduced with use of manual pressure after resecting the
                  TAT. None of our patients required an osteotomy or arthrodesis at the proximal end of the first
                  metatarsal to correct the elevation of the first metatarsal. The primary benefit of corrective
                  arthrodesis of the first MTPJ was a lasting correction of the fixed deformity, with pain relief and
                  improved skin care. A secondary benefit was that the intact FHB and FHL actively depressed the first
                  ray instead of flexing the first MTPJ (<a href="" data-id="figure_reference_26"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 8</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">The use of cup-and-cone reamers and dorsal plating allowed
                  for optimal correction and stable fixation<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_27" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">16</span></a>. Surgery can trigger an increase in dystonia in patients
                  with CP, which can occasionally lead to a dystonic crisis. We considered stable fixation essential for
                  reducing the risks of delayed union or malunion in patients at a risk for low bone density<a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">23</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Major reconstructive surgery in nonambulatory patients
                  with CP has been associated with significant morbidity and mortality<a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_29" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">24</span></a>.
                  Orthopaedic surgery in children with dystonia has had a reputation for unpredictable outcomes, but a
                  recent study showed that good outcomes can be achieved<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">25</span></a>. In
                  the present study, 3 patients had multiple medical adverse events related to an increase in dystonia,
                  pain, and spasms that resulted in increased medication, which was followed by either skin irritation
                  from the cast or respiratory depression (<a href="" data-id="figure_reference_27"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).
                  Multidisciplinary perioperative management and the involvement of a pain management service are
                  advised<a href="" data-id="citation_reference_54"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">27</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">The present study has limitations. The study was
                  retrospective and did not include a control group. No validated PROMs have been developed for foot and
                  ankle surgery in this population<a href="" data-id="citation_reference_56"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">18</span></a>.
                  Each VAS that we utilized was simple, feasible, and acceptable but has not been tested for validity or
                  reliability<a href="" data-id="citation_reference_57"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">18</span></a>.
                  Sixty-five percent of the study group (15 adolescent patients with severe communication difficulties)
                  had VAS scores completed by parents or caregivers. The study population had moderate hallux valgus as
                  well as dorsal bunions. The diagnosis of a dorsal bunion was based on the location of the symptoms
                  rather than prespecified radiographic criteria<a href="" data-id="citation_reference_58"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_34" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">8</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">The strengths of the study were the standardization of the
                  surgical approach and the prospective collection of data with use of standardized clinical and
                  radiographic measures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">There were no cases of acquired equinus deformity at the
                  time of the final follow-up. At the time of writing, none of the implants have been symptomatic and
                  none have been removed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, the surgical reconstruction of a dorsal
                  bunion via soft-tissue rebalancing of the first ray and corrective arthrodesis of the first MTPJ
                  resulted in favorable medium-term clinical and radiographic outcomes in nonambulatory adolescents with
                  CP.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_51" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I169" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I169</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Graham HK</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Rosenbaum P</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Paneth N</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Dan B</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Lin JP</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Damiano DL</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Becher JG</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Gaebler-Spira D</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Colver A</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Reddihough DS</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Crompton KE</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Cerebral palsy</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Nat Rev Dis Primers.</span>
                2016 Jan 7;2:15082.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cerebral%20palsy&as_occt=title&as_sauthors=%20%22HK%20Graham%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Soo B</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Howard JJ</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Boyd RN</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Reid SM</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Lanigan A</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Wolfe R</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Reddihough D</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Graham HK</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Hip displacement in cerebral
                  palsy</span>. <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Am.</span> 2006 Jan 1;88(1):121-1-8.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=967682" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Willoughby KL</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Ang SG</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Thomason P</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Rutz E</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Shore B</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Buckland AJ</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Johnson MB</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Graham HK</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Epidemiology of scoliosis in
                  cerebral palsy: A population-based study at skeletal maturity</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Paediatr Child Health.</span> 2022
                Feb;58(2):295-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiology%20of%20scoliosis%20in%20cerebral%20palsy%3A%20A%20population-based%20study%20at%20skeletal%20maturity&as_occt=title&as_sauthors=%20%22KL%20Willoughby%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> DiFazio RL</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Vessey JA</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Miller PE</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Snyder BD</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Shore BJ</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Health-related quality of life
                  and caregiver burden after hip reconstruction and spinal fusion in children with spastic cerebral
                  palsy</span>. <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Dev Med
                  Child Neurol.</span> 2022 Jan;64(1):80-1-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health-related%20quality%20of%20life%20and%20caregiver%20burden%20after%20hip%20reconstruction%20and%20spinal%20fusion%20in%20children%20with%20spastic%20cerebral%20palsy&as_occt=title&as_sauthors=%20%22RL%20DiFazio%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Van de Velde SK</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Cashin M</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Johari R</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Blackshaw R</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Khot A</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Graham HK</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Symptomatic hallux valgus and
                  dorsal bunion in adolescents with cerebral palsy: clinical and biomechanical factors</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Dev Med Child Neurol.</span>
                2018 Jun;60(6):624-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Symptomatic%20hallux%20valgus%20and%20dorsal%20bunion%20in%20adolescents%20with%20cerebral%20palsy%3A%20clinical%20and%20biomechanical%20factors&as_occt=title&as_sauthors=%20%22SK%20Van%20de%20Velde%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Goldner JL</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Hallux valgus and hallux flexus
                  associated with cerebral palsy: analysis and treatment</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 1981
                Jun;(157):98-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hallux%20valgus%20and%20hallux%20flexus%20associated%20with%20cerebral%20palsy%3A%20analysis%20and%20treatment&as_occt=title&as_sauthors=%20%22JL%20Goldner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Davids JR</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Mason TA</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Danko A</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Banks D</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Blackhurst D</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Surgical management of hallux
                  valgus deformity in children with cerebral palsy</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 2001
                Jan-Feb;21(1):89-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20management%20of%20hallux%20valgus%20deformity%20in%20children%20with%20cerebral%20palsy&as_occt=title&as_sauthors=%20%22JR%20Davids%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Ye K</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Cashin M</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Van de Velde SK</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Khot A</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Graham K</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Rutz E</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Arthrodesis of the first
                  metatarsophalangeal joint for severe hallux valgus in adolescents with cerebral palsy: A retrospective
                  comparison study of three surgical techniques</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">J Child Orthop.</span> 2023 Oct
                5;17(6):607-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthrodesis%20of%20the%20first%20metatarsophalangeal%20joint%20for%20severe%20hallux%20valgus%20in%20adolescents%20with%20cerebral%20palsy%3A%20A%20retrospective%20comparison%20study%20of%20three%20surgical%20techniques&as_occt=title&as_sauthors=%20%22K%20Ye%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Lapidus PW.</span> ‘<span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Dorsal bunion’: its mechanics
                  and operative correction</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1940;22:627-1-8.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=166137" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Bleck EE</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Forefoot problems in cerebral
                  palsy-diagnosis and management</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Foot Ankle.</span> 1984 Jan-Feb;4(4):188-1-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Forefoot%20problems%20in%20cerebral%20palsy-diagnosis%20and%20management&as_occt=title&as_sauthors=%20%22EE%20Bleck%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> McKay DW</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Dorsal bunions in
                  children</span>. <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 1983 Sep;65(7):975-1-8.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=462189" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Palisano R</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Rosenbaum P</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Walter S</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Russell D</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Wood E</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Galuppi B</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Development and reliability of
                  a system to classify gross motor function in children with cerebral palsy</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Dev Med Child
                  Neurol.</span> 1997 Apr;39(4):214-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20and%20reliability%20of%20a%20system%20to%20classify%20gross%20motor%20function%20in%20children%20with%20cerebral%20palsy&as_occt=title&as_sauthors=%20%22R%20Palisano%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Howard J</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Soo B</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Graham HK</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Boyd RN</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Reid S</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Lanigan A</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Wolfe R</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Reddihough DS</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Cerebral palsy in Victoria:
                  motor types, topography and gross motor function</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Paediatr Child Health.</span> 2005
                Sep-Oct;41(9-10):479-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cerebral%20palsy%20in%20Victoria%3A%20motor%20types%2C%20topography%20and%20gross%20motor%20function&as_occt=title&as_sauthors=%20%22J%20Howard%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Chin TY</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Nattrass GR</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Selber P</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Graham HK</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Accuracy of intramuscular
                  injection of botulinum toxin A in juvenile cerebral palsy: a comparison between manual needle
                  placement and placement guided by electrical stimulation</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 2005
                May-Jun;25(3):286-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Accuracy%20of%20intramuscular%20injection%20of%20botulinum%20toxin%20A%20in%20juvenile%20cerebral%20palsy%3A%20a%20comparison%20between%20manual%20needle%20placement%20and%20placement%20guided%20by%20electrical%20stimulation&as_occt=title&as_sauthors=%20%22TY%20Chin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Wagner E</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Wagner P</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Ortiz C</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Arthrodesis of the hallux
                  metatarsophalangeal joint</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">JBJS Essent Surg Tech.</span> 2015 Oct 14;5(4):e20.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=875554" target="_new" class=""
                  style="position: unset;">JBJS Essent Surg Tech</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Coughlin MJ</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Abdo RV</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Arthrodesis of the first
                  metatarsophalangeal joint with Vitallium plate fixation</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Foot Ankle Int.</span> 1994
                Jan;15(1):18-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthrodesis%20of%20the%20first%20metatarsophalangeal%20joint%20with%20Vitallium%20plate%20fixation&as_occt=title&as_sauthors=%20%22MJ%20Coughlin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Tang MJ</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Ye K</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Van de Velde SK</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Rutz E</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Graham K</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Arthrodesis of the First
                  Metatarsophalangeal Joint in Adolescents with Cerebral Palsy</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">J POSNA.</span> 2021 Nov;3(4):371.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthrodesis%20of%20the%20First%20Metatarsophalangeal%20Joint%20in%20Adolescents%20with%20Cerebral%20Palsy&as_occt=title&as_sauthors=%20%22MJ%20Tang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Coombes L</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Bristowe K</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Ellis-Smith C</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Aworinde J</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Fraser LK</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Downing J</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Bluebond-Langner M</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Chambers L</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Murtagh FEM</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Harding R</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Enhancing validity, reliability
                  and participation in self-reported health outcome measurement for children and young people: a
                  systematic review of recall period, response scale format, and administration modality</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Qual Life Res.</span> 2021
                Jul;30(7):1803-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Enhancing%20validity%2C%20reliability%20and%20participation%20in%20self-reported%20health%20outcome%20measurement%20for%20children%20and%20young%20people%3A%20a%20systematic%20review%20of%20recall%20period%2C%20response%20scale%20format%2C%20and%20administration%20modality&as_occt=title&as_sauthors=%20%22L%20Coombes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Davids JR</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Gibson TW</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Pugh LI</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Quantitative segmental analysis
                  of weight-bearing radiographs of the foot and ankle for children: normal alignment</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span>
                2005 Nov-Dec;25(6):769-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quantitative%20segmental%20analysis%20of%20weight-bearing%20radiographs%20of%20the%20foot%20and%20ankle%20for%20children%3A%20normal%20alignment&as_occt=title&as_sauthors=%20%22JR%20Davids%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Bryant A</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Tinley P</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Singer K</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">A comparison of radiographic
                  measurements in normal, hallux valgus, and hallux limitus feet</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">J Foot Ankle Surg.</span> 2000
                Jan-Feb;39(1):39-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comparison%20of%20radiographic%20measurements%20in%20normal%2C%20hallux%20valgus%2C%20and%20hallux%20limitus%20feet&as_occt=title&as_sauthors=%20%22A%20Bryant%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Rice J</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Skuza P</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Baker F</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Russo R</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Fehlings D</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Identification and measurement
                  of dystonia in cerebral palsy</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Dev Med Child Neurol.</span> 2017 Dec;59(12):1249-1-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Identification%20and%20measurement%20of%20dystonia%20in%20cerebral%20palsy&as_occt=title&as_sauthors=%20%22J%20Rice%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Chien C</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Alfred T</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Freihaut R</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Pit S</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">First metatarsophalangeal joint
                  arthrodesis in hallux valgus versus hallux rigidus using cup and cone preparation compression screw
                  and dorsal plate fixation</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Cureus.</span> 2017 Oct 19;9(10):e1786.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=First%20metatarsophalangeal%20joint%20arthrodesis%20in%20hallux%20valgus%20versus%20hallux%20rigidus%20using%20cup%20and%20cone%20preparation%20compression%20screw%20and%20dorsal%20plate%20fixation&as_occt=title&as_sauthors=%20%22C%20Chien%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Houlihan CM</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Bone health in cerebral palsy:
                  who’s at risk and what to do about it?</span><span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">J Pediatr Rehabil Med.</span> 2014;7(2):143-1-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20health%20in%20cerebral%20palsy%3A%20who%E2%80%99s%20at%20risk%20and%20what%20to%20do%20about%20it%3F&as_occt=title&as_sauthors=%20%22CM%20Houlihan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Hariharan A</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Sees JP</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Pargas C</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Rogers KJ</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Niiler T</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Shrader MW</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Miller F</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Mortality after spinal fusion
                  in children with cerebral palsy and cerebral-palsy-like conditions: A 30-year follow-up study</span>.
                <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Dev Med Child
                  Neurol.</span> 2023 Sep;65(9):1190-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mortality%20after%20spinal%20fusion%20in%20children%20with%20cerebral%20palsy%20and%20cerebral-palsy-like%20conditions%3A%20A%2030-year%20follow-up%20study&as_occt=title&as_sauthors=%20%22A%20Hariharan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Blumetti FC</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Wu JCN</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Barzi F</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Axt MW</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Waugh MC</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Selber P</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Orthopaedic surgery in dystonic
                  cerebral palsy</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">J Pediatr Orthop.</span> 2019 Apr;39(4):209-1-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Orthopaedic%20surgery%20in%20dystonic%20cerebral%20palsy&as_occt=title&as_sauthors=%20%22FC%20Blumetti%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Gorodetsky C</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Fasano A</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Approach to the treatment of
                  pediatric dystonia</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Dystonia.</span> 2022;1:10287.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Approach%20to%20the%20treatment%20of%20pediatric%20dystonia&as_occt=title&as_sauthors=%20%22C%20Gorodetsky%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Shea J</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Nunally KD</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Miller PE</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Difazio R</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Matheney TH</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Snyder B</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Shore BJ</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Hip reconstruction in
                  nonambulatory children with cerebral palsy: Identifying risk factors associated with postoperative
                  complications and prolonged length of stay</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 2020
                Nov/Dec;40(10):e972-1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20reconstruction%20in%20nonambulatory%20children%20with%20cerebral%20palsy%3A%20Identifying%20risk%20factors%20associated%20with%20postoperative%20complications%20and%20prolonged%20length%20of%20stay&as_occt=title&as_sauthors=%20%22J%20Shea%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=supplement&name=12515"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D5f4649ed-180c-4e56-afd0-7b8ef9bf82e9%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): e49</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00092</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">August 22, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_28"
                            class="annotation emphasis" style="position: unset;">Investigation performed at The Royal
                            Children’s Hospital, Parkville, Victoria, Australia</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_1" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=pdf&name=JBJS.24.00092.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=pdf&name=JBJS.24.00092.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_33"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The Article Processing Charge for open access publication was
                          funded by the Murdoch Children’s Research Institute, Melbourne. The <span data-id="strong_34"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I168" target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I168</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=5f4649ed-180c-4e56-afd0-7b8ef9bf82e9&type=zip&name=JBJS.24.00092.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Samuel K. Van de Velde, MD, MPH, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3305-959X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3305-959X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">H. Kerr Graham, MD, FRACS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:kerr.graham@rch.org.au" class=""
                    style="position: unset;">kerr.graham@rch.org.au</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6607-7631" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6607-7631</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ken Ye, MBBS, BMedSci, PhD, FRACS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3531-673X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3531-673X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Henry Chambers, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4372-0780" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4372-0780</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Erich Rutz, MD, PhD, FRACS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2292-0946" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2292-0946</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Columbia University Medical Center, New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">The Royal Children’s Hospital, Parkville, Victoria, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Rady Children’s Hospital, University of California San Diego, San Diego,
                California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 29025.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
