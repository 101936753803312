/* -----------------Child components--------------- */
import { Box, CustomListSubheader, useDevice } from "@memorang/ui";

export const SectionHeader = ({ sectionTitle }: { sectionTitle: string }) => {
	const { isMobile } = useDevice();

	return (
		<Box justifyContent="space-between" alignItems="center" flexDirection="row">
			<CustomListSubheader enableGutters={isMobile}>
				{sectionTitle}
			</CustomListSubheader>
		</Box>
	);
};
