import type { ListItemProps } from "react-native-paper";
import type { IconType } from "../../../index";

export type ListItemData = {
	color?: string;
	icon: IconType;
	count: number;
	title: string;
	description?: string;
	type: "correct" | "incorrect" | "skipped" | "almostcorrect" | "marked";
} & ListItemProps;

export type Section = {
	sectionTitle?: string;
	items: ListItemData[];
};

type CheckboxType =
	| "correct"
	| "incorrect"
	| "skipped"
	| "almostcorrect"
	| "marked";
export type CheckboxStateMap = Record<CheckboxType, boolean>;
