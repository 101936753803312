import type { Article } from "../../../../../types";

export const Article528: Article = {
	id: 528,
	rsuiteId: "260a2ddb-890b-4d0d-9be7-d7877192fcfe",
	type: "the orthopaedic forum",
	title:
		"An In-Depth Analysis of Public and Private Research Funding in Orthopaedic Surgery from 2015 to 2021",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=260a2ddb-890b-4d0d-9be7-d7877192fcfe&type=jpeg&name=JBJS.23.00598f1",
	subSpecialties: ["trauma"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">An In-Depth Analysis of Public and Private Research
                Funding in Orthopaedic Surgery from 2015 to 2021</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Aakash K.
                      Shah, BA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Robert J.
                      Burkhart, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Background:</span> Understanding the trends and patterns of research
                        funding can aid in enhancing growth and innovation in orthopaedic research. We sought to analyze
                        financial trends in public orthopaedic surgery funding and characterize trends in private
                        funding distribution among orthopaedic surgeons and hospitals to explore potential disparities
                        across orthopaedic subspecialties.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Methods:</span> We conducted a cross-sectional analysis of private
                        and public orthopaedic research funding from 2015 to 2021 using the Centers for Medicare &
                        Medicaid Services Open Payments database and the National Institutes of Health (NIH) RePORTER
                        through the Blue Ridge Institute for Medical Research, respectively. Institutions receiving
                        funds from both the NIH and the private sector were classified separately as publicly funded and
                        privately funded. Research payment characteristics were categorized according to their
                        respective orthopaedic fellowship subspecialties. Descriptive statistics, Wilcoxon rank-sum
                        tests, and Mann-Kendall tests were employed. A p value of &lt;0.05 was considered significant.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Results:</span> Over the study period, $348,428,969 in private and
                        $701,078,031 in public research payments were reported. There were 2,229 unique surgeons
                        receiving funding at 906 different institutions. The data showed that a total of 2,154 male
                        orthopaedic surgeons received $342,939,782 and 75 female orthopaedic surgeons received
                        $5,489,187 from 198 different private entities. The difference in the median payment size
                        between male and female orthopaedic surgeons was not significant. The top 1% of all practicing
                        orthopaedic surgeons received 99% of all private funding in 2021. The top 20 publicly and top 20
                        privately funded institutions received 77% of the public and 37% of the private funding,
                        respectively. Private funding was greatest (31.5%) for projects exploring adult reconstruction.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Conclusion:</span> While the amount of public research funding was
                        more than double the amount of private research funding, the distribution of public research
                        funding was concentrated in fewer institutions when compared with private research funding. This
                        suggests the formation of orthopaedic centers of excellence (CoEs), which are programs that have
                        high concentrations of talent and resources. Furthermore, the similar median payment by gender
                        is indicative of equitable payment size. In the future, orthopaedic funding should follow a
                        distribution model that aligns with the existing approach, giving priority to a
                        nondiscriminatory stance regarding gender, and allocate funds toward CoEs.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> Securing research funding is vital for
                        driving innovation in orthopaedic surgery, which is crucial for enhancing clinical
                        interventions. Thus, understanding the patterns and distribution of research funding can help
                        orthopaedic surgeons tailor their future projects to better align with current funding trends,
                        thereby increasing the likelihood of securing support for their work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">While the interests of orthopaedic surgeons and
                  orthopaedic industry are often intertwined, this relationship has not always been transparent, and the
                  lack of transparency can hide conflicts of interest<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">2</span></a>. To remedy this, the Physician Payments Sunshine Act was
                  enacted in 2010<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a>. It
                  mandated that all financial payments of &gt;$10 to physicians from private entities be tracked and
                  reported annually. To track the data, the Centers for Medicare & Medicaid Services (CMS) created
                  the Open Payments database (OPD) and classified all payments as general or research payments<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a>.
                  Research payments are made to compensate physicians for their time invested in a research study or to
                  provide funding for the study. General payments are payments that fall outside the boundary of
                  research; they include speaking fees and consulting fees<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Orthopaedic surgeons have a long history of collaborating
                  with industry to fund research for the development of new implants and surgical tools<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">7</span></a>. This
                  relationship with industry has come under recent scrutiny due to the potential for conflict of
                  interest and bias<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">12</span></a>. Prior studies that have attempted to analyze this
                  relationship by studying the OPD found that orthopaedic surgeons receive a higher proportion of
                  payments compared with other specialties, and a very small subset of all orthopaedic surgeons receive
                  these payments<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">14</span></a>. However, the scope of these prior studies was limited
                  because they focused solely on general payments and did not examine the nature of research payments<a
                    href="" data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">15</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">17</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Our investigation aimed to characterize patterns in
                  privately and publicly funded orthopaedic surgery research from 2015 through 2021. The goals of this
                  study were to (1) report the public and private research payments to orthopaedic surgeons, (2) analyze
                  the trends of public and private payments made to orthopaedic surgeons and institutions, (3)
                  characterize the differences in private payments between orthopaedic subspecialties, and (4) analyze
                  the differences in private payments to orthopaedic surgeons by gender.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study Design
                and Setting</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">In January 2023, we conducted a retrospective
                  cross-sectional survey of public and private orthopaedic research funding. Because our analysis
                  utilized publicly available data, this study was exempt from institutional review board approval.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Privately funded research payment data from 2015 to 2021
                  were obtained from the CMS OPD<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">4</span></a>. All
                  private payments were recorded, including each surgeon and his or her respective subspecialty and
                  institution, the sponsoring manufacturer, and the amount of the payment. Publicly funded research
                  payment data were obtained from the Blue Ridge Institute for Medical Research (BRIMR) rankings, which
                  indexed the National Institutes of Health (NIH) RePORTER tool from 2015 to 2021<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">19</span></a>. All public payments were recorded along with the
                  associated institution as indicated in the BRIMR rankings. Institutions were labeled as both privately
                  funded and publicly funded if they received funding from both the private sector and the NIH. All
                  payments were reported in United States dollars (USD) and adjusted for inflation to December
                  2022-equivalent USD using the Consumer Price Index Calculator from the Bureau of Labor Statistics<a
                    href="" data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">20</span></a>. For public funding, data were collected by aggregating the
                  payments in the 2015 to 2021 “Orthopedics” rankings by the BRIMR. For private funding, data were
                  collected by aggregating payments to principal investigators claiming orthopaedic surgery or an
                  orthopaedic surgery subspeciality as their primary specialty. The subspecialties included adult
                  reconstruction, sports medicine, spine, foot and ankle, hand, trauma, pediatrics, and other.
                  Investigators categorized as “other” were surgeons who conducted research in a subspeciality that does
                  not have a designated subset in the CMS OPD, such as oncology, or who did not complete a fellowship.
                  Shoulder and elbow orthopaedic surgeons did not have a designated subset in the CMS OPD; they were
                  categorized in the “sports medicine/shoulder and elbow” subspecialty. Because 51% of all publicly
                  funded research involved basic science projects<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">21</span></a>, a
                  subspeciality analysis was conducted for privately funded research only, similarly to prior studies<a
                    href="" data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">25</span></a>. All entries by gender and incomplete subspecialty entries
                  were corrected based on the surgeon’s affiliated institution profile.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">This study followed the STrengthening the Reporting of
                  OBservational Studies in Epidemiology (STROBE) reporting guidelines.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Payment values were rounded to the nearest dollar.
                  Descriptive statistics for payments, hospitals, and manufacturers were reported from 2015 to 2021.
                  Institutions that were both publicly funded and privately funded were analyzed independently in the
                  publicly and privately funded institution categories. Trend analysis was performed using the
                  Mann-Kendall statistic to analyze the trends and the corresponding change points in time series data<a
                    href="" data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">27</span></a>. Differences in private payments by gender were analyzed
                  using the Wilcoxon rank-sum test. Data processing and statistical analysis were performed with RStudio
                  (Posit, PBC). A p value of &lt;0.05 was considered significant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Public and
                Private Research Payments</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">From 2015 to 2021, $701,078,031 was invested in
                  orthopaedic surgery research from public sources and $348,428,969 was invested from private sources.
                  Private investment grew from $36,858,675 in 2015 to $46,858,047 in 2021, which was not significant (p
                  = 0.764). Public investment significantly grew by 158% from $70,862,268 in 2015 to $112,481,055 in
                  2021 (p = 0.035) (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00598f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=260a2ddb-890b-4d0d-9be7-d7877192fcfe&type=jpeg&name=JBJS.23.00598f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;">Total private and public orthopaedic research
                          funding from 2015 to 2021. USD = United States dollars.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">In 2015, 851 unique orthopaedic surgeons received private
                  payments, compared with 923 in 2021. Ninety-seven percent of all private funding was received by male
                  orthopaedic surgeons. The gender distribution of private funding recipients did not change
                  significantly over the study period (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). The
                  top 1% of all practicing orthopaedic surgeons in the United States (230) received 99% of all private
                  funding in 2021. Across all medical specialties, orthopaedic surgery research funding was &lt;1% of
                  all total private research funding to non-industry physicians and surgeons in the CMS OPD.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Characteristics of Private Research Funding from 2015
                          to 2021<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 456.272px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.625475);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Year</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Number of
                          Orthopaedic Surgeons Receiving Payments</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Percentage
                          of Total Practicing U.S. Orthopaedic Surgeons Receiving Payments</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Number (%)
                          of Payments Made to Male Orthopaedic Surgeons</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Median
                          (IQR) Payment per Orthopaedic Surgeon</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Amount (%)
                          of Payments Made to Male Orthopaedic Surgeons</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total
                          Payments</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2015</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">851</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.45%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">831
                          (97.65%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$13,649
                          ($4,067-$40,495)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$36,011,715 (97.70%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$36,858,675</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2016</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">852</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.94%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">827
                          (97.07%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$16,618
                          ($4,349-$43,966)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$47,228,891 (97.05%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$48,664,660</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2017</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">936</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.20%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">910
                          (97.22%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$16,437
                          ($4,976-$50,413)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$57,972,793 (98.60%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$58,798,327</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2018</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">908</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.97%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">887
                          (97.69%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$18,758
                          ($5,534-$55,402)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$54,150,510 (99.11%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$54,635,430</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2019</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">962</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.18%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">933
                          (96.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$16,757
                          ($5,395-$50,066)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$61,652,691 (98.84%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$62,378,203</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2020</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">944</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.09%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">914
                          (96.82%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$13,822
                          ($3,981-$38,634)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$39,429,930 (98.00%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$40,235,627</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2021</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">923</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.02%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">897
                          (97.18%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$12,041
                          ($3,540-$38,041)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$46,493,252 (99.22%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$46,858,047</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Payments reported in United States dollars. IQR = interquartile
                        range.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Private
                Funding Across Orthopaedic Subspecialties</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Research pertaining to adult reconstruction topics
                  received the greatest proportion (31.50%) of all private orthopaedic funding disbursed during the
                  study period. This was followed by sports medicine/shoulder and elbow (23.33%), spine (14.39%), other
                  (7.95%), hand (7.54%), trauma (7.48%), foot and ankle (4.04%), and pediatrics (3.56%). Funding
                  disbursed to adult reconstruction and pediatrics decreased from 2015 to 2021; pediatrics funding
                  decreasing at the largest rate (a Mann-Kendall statistic of −13.00, indicating a strongly decreasing
                  trend). All other subspecialties had an increase in funding disbursed over the same period. Adult
                  reconstruction orthopaedic surgeons represented the largest proportion (27%) of all surgeons receiving
                  payments.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Trends in
                Institutions and Manufacturers from 2015 to 2021</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">From 2015 to 2021, the top publicly funded institution was
                  the University of California, San Francisco, which received $87.4 million, and the top privately
                  funded institution was Phoenix Orthopedic Surgeons, which received $17.3 million (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). Over the seven years, the top 20 publicly funded
                  institutions received 77% of all public funding, while the top 20 privately funded institutions
                  received 37% of all private funding (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).
                  Institutions receiving private funding are broken down by subspeciality in Appendix A. Zimmer Biomet
                  Holdings disbursed the greatest amount in research payments ($37.0 million) during the study period,
                  followed closely by Pfizer ($31.7 million) and Stryker ($29.8 million). The top 5 manufacturers in
                  terms of research payment disbursement provided 42% of all private funding (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). Manufacturers that provided private research funding are
                  broken down by subspeciality in Appendix B.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Top 20 Institutions with Respect to Reported Public
                          and Private Research Funding from 2015 to 2021<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2061px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Publicly Funded</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Funding</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Privately
                          Funded</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Funding</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">University of California,
                          San Francisco</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$87,383,723</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Phoenix Orthopedic
                          Surgeons</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$17,322,544</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">University of
                          Pennsylvania</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$48,376,425</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Florida Orthopedic
                          Institute</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$16,100,421</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Duke University</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$43,774,699</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Johns Hopkins
                          Hospital</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$11,456,347</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Washington University in
                          St. Louis</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$42,233,172</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Massachusetts General
                          Hospital</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$8,981,250</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">University of
                          Rochester</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$41,086,130</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Hospital for Special
                          Surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$7,327,634</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Johns Hopkins
                          University</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$35,714,410</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Rothman Orthopedic
                          Institute</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$6,433,550</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">University of Utah</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$27,655,484</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Rush University Medical
                          Center</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$6,163,431</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">New York University
                          School of Medicine</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$22,968,199</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Rady Children’s
                          Hospital-San Diego</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$5,523,930</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stanford
                          University</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$22,423,530</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Cleveland Clinic
                          Hospital</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$5,495,800</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">University of Southern
                          California</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$19,801,062</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Gold Coast
                          Research</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$4,549,747</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Thomas Jefferson
                          University</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$19,735,852</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Orlando Health Jewett
                          Orthopedic Institute</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$4,466,173</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">University of California,
                          Los Angeles</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$19,208,450</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Duke University
                          Hospital</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$4,461,388</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">University of Connecticut
                          School of Medicine</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$19,087,163</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">University of Colorado
                          Health</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$4,302,282</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Columbia University
                          Health Sciences</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$18,171,979</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Barnes-Jewish
                          Hospital</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$4,246,212</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">University of
                          Pittsburgh</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$17,251,037</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Bon Secours - Southampton
                          Memorial Hospital</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$3,809,366</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">University of Maryland,
                          Baltimore</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$13,230,518</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">William Beaumont Hospital
                          - Royal Oak</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$3,786,633</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Icahn School of Medicine
                          at Mount Sinai</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$11,984,525</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NYU Langone Health</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$3,783,965</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">University of
                          Michigan</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$11,918,569</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">MedStar Georgetown
                          University Hospital</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$3,711,172</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">University of
                          Washington</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$10,901,135</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Piedmont
                          Healthcare</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$3,615,226</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">University of Colorado
                          Denver</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$10,169,999</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">University of Virginia
                          Medical Center</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$3,517,683</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Payments reported in United States dollars.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Top 20 Manufacturers with Respect to Overall
                          Reported Private Research Funding from 2015 to 2021</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 836px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Manufacturer</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Funding<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Zimmer Biomet
                          Holdings</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$37,013,190</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pfizer</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$31,661,510</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stryker
                          Corporation</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$29,787,150</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Smith & Nephew</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$25,952,750</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Arthrex</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$22,783,290</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medical Device Business
                          Services</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$16,436,680</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">AbbVie</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$11,193,630</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Genentech</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$11,040,220</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Regeneron
                          Pharmaceuticals</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$8,940,243</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pacira
                          Pharmaceuticals</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$8,921,922</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Aesculap Implant
                          Systems</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$8,242,800</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">DePuy Orthopaedics</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$7,966,279</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medtronic USA</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$7,616,019</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Novartis
                          Pharmaceuticals</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$6,714,454</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Vertex
                          Pharmaceuticals</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$5,291,561</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sanofi U.S.
                          Services</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$4,865,020</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Eli Lilly</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$4,714,703</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Exactech</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$4,495,994</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Heron Therapeutics</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$4,404,198</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Janssen Research &
                          Development</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$4,179,140</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Payments reported in United States dollars.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Private
                Funding According to Gender</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Over the study period, a total of 2,154 unique male
                  orthopaedic surgeons at 887 unique institutions received $342,939,782 in private payments from 195
                  unique manufacturers. In comparison, only 75 unique female orthopaedic surgeons at 62 unique
                  institutions received $5,489,187 in payments from 46 unique manufacturers. The difference in the
                  median payment size to male and female orthopaedic surgeons was not significant during the study
                  period. The years 2018 and 2021 were outliers, with the largest differences in the median payment size
                  between genders; however, the differences did not reach significance (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Median Private Funding by Gender from 2015 to
                          2021<span data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 522px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Year</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Median
                          Payment to Male Orthopaedic Surgeons</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Median
                          Payment to Female Orthopaedic Surgeons</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Difference
                          in Median Payment between Male and Female Orthopaedic Surgeons</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2015</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$13,701</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$11,485</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$2,216</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.534</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2016</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$16,630</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$12,300</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$4,330</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.819</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2017</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$16,615</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$14,688</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$1,927</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.532</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2018</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$19,247</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$11,456</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$7,791</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.052</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2019</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$16,758</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$12,828</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$3,930</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.529</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2020</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$13,722</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$13,071</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$651</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.560</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2021</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$12,290</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$5,404</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$6,886</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.066</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Payments reported in United States dollars.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The greatest numbers of privately funded female and male
                  orthopaedic surgeons were in sports medicine/shoulder and elbow and in adult reconstruction,
                  respectively. The difference in total median payments between male and female orthopaedic surgeons was
                  greatest for spine surgeons and adult reconstruction surgeons at –$29,915 and $20,852, respectively
                  (<a href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Characteristics of Total Private Funding by
                          Subspeciality<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 483.492px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.584369);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Subspeciality</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Median
                          (IQR) Payment to Male Orthopaedic Surgeons</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Median
                          (IQR) Payment to Female Orthopaedic Surgeons</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Difference
                          in Median Payment Between Male and Female Orthopaedic Surgeons</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total
                          Number of Male Orthopaedic Surgeons</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total
                          Number of Female Orthopaedic Surgeons</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Adult
                          reconstruction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$50,810
                          ($12,658-$180,883)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$29,958
                          ($20,981-$442,076)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$20,852</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">598</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pediatrics</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$37,750
                          ($11,432-$91,984)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$28,844
                          ($17,255-$54,803)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$8,906</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Foot and ankle</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$25,580
                          (10,863-$77,729)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$16,722
                          ($3,601-$29,886)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$8,858</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">137</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Trauma</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$27,297
                          ($9,703-$78,615)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$20,050
                          ($9,038-$50,220)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$7,247</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">147</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sports medicine/shoulder
                          and elbow</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$39,974
                          ($11,516-$120,524)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$36,296
                          ($12,987-$64,025)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$3,678</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">552</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hand</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$14,300
                          ($4,047-$58,609)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$14,565
                          ($7,863-$37,783)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−$265</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">151</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Spine</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$27,128
                          ($6,972-$86,342)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$57,043
                          ($4,362-$144,879)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−$29,915</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">438</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$21,893
                          (4,403-$67,569)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$11,321
                          ($8,592-$13,555)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$10,572</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Payments reported in United States dollars. IQR = interquartile
                        range.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Our study examined trends in private and public research
                  funding to orthopaedic surgeons in the United States, and we aimed to identify the institutions and
                  manufacturers that received and contributed the most funding, respectively. From 2015 to 2021, our
                  analysis revealed that a total of $1,049,507,000 was invested in orthopaedic surgery research, both
                  publicly and privately. In terms of subspecialties, adult reconstruction received the greatest amount
                  of private funding, while pediatrics garnered the least. Additionally, there was substantial funding
                  clustering among all practicing orthopaedic surgeons, with the orthopaedic surgeons in the top 1% with
                  respect to funding receiving 99% of all private funding. Men constituted 93.5% of all practicing
                  orthopaedic surgeons<span data-id="superscript_15" class="annotation superscript"
                    style="position: unset;">28</span>; they received 97% of all private funding, indicating a
                  difference in the raw amount of funding disbursed by gender. However, the median payment size was not
                  significantly different between male and female orthopaedic surgeons. Ten manufacturers provided most
                  of the private funding and contributed approximately 58% of the total funding.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Both the private and public sectors tend to invest in
                  institutions with the best research talent and facilities, so the distribution of funding can be
                  viewed as a ranking of institutions under a democratic capitalist system with the formation of centers
                  of excellence (CoEs)<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">29</span></a>,
                  which are specialized programs allocating high concentrations of talent and resources to specific
                  medical areas<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">30</span></a>.
                  Future funding for orthopaedics should adhere to a distribution model consistent with the current
                  approach, emphasizing nondiscrimination based on gender. Furthermore, it is advisable to concentrate
                  funding in CoEs in order to bolster productivity and encourage innovation within the orthopaedic
                  field. Private manufacturers are urged to align their practices with the standards set by public
                  organizations. Transparency in reporting trends holds the potential to establish guidelines and
                  pathways for new researchers and entities seeking funding. Identifying patterns in funding
                  distribution across various parameters allows for a more targeted approach to resource allocation.
                  This, in turn, may enhance the fairness and effectiveness of funding distribution, ensuring that
                  deserving projects and researchers receive the support they need.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Public and
                Private Research Payments</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">The Physician Payments Sunshine Act was implemented in
                  2010 to enhance transparency in transactions between the medical industry and physicians. Okike et al.
                  found that studies authored by individuals with a conflict of interest related to research or
                  institutional funding were not more likely to present positive findings<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">11</span></a>. Similarly, Donoughe et al. found no correlation between
                  industry payments and reported outcomes<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">31</span></a>.
                  These findings indicated that an increase in private funding may not jeopardize the integrity of
                  orthopaedic research.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Private funding investments in institutions have a wider
                  distribution compared with public funding, which is concentrated at CoEs. CoEs are at the forefront of
                  health-care innovation and have invested in developing a research infrastructure to drive scientific
                  progress, allowing them to receive more funding compared with other institutions<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">32</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">33</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Our analysis revealed that a small group of 230 surgeons
                  received 99% of all private funding disbursed in 2021, which is a pattern consistent since 2015. This
                  highlights that a small cohort of surgeons are actively engaged in the research requiring funding and
                  are at the forefront of the field. Samuel et al. reported similar findings and stated that there was a
                  small group of surgeons receiving the bulk of the available private investment<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">14</span></a>. Comparable funding patterns have been observed in other
                  specialties, suggesting that this pattern of private medical research funding may be pervasive
                  throughout the United States<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">34</span></a>. Despite this concentration, the median payment to
                  orthopaedic surgeons is the greatest payment per physician across all specialties<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">35</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Private
                Funding Across Orthopaedic Subspecialties</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">The top-funded orthopaedic subspecialty was adult
                  reconstruction, followed by sports medicine/shoulder and elbow; however, recently, private funding for
                  adult reconstruction has decreased, while sports medicine/shoulder and elbow funding has grown. Thomas
                  et al. found that general and research payments to all spine surgeons, both those trained in
                  neurosurgery and orthopaedic surgery, decreased by 17.5% from 2014 to 2019<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">16</span></a>. However, our analysis found that research funding to
                  orthopaedic spine surgeons increased by 29% during the assessed period.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Trends in
                Payments from 2015 to 2021</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Both private and public orthopaedic research funding grew
                  over the study period; however, this trend was only significant for public funding. Private
                  orthopaedic funding accounted for &lt;1% of all private research payments throughout the study period,
                  constituting 0.90% of total private research funding in 2019 and falling to 0.56% in 2020. This trend
                  between 2019 and 2020 was also observed in other research fields<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">25</span></a>. In contrast, public orthopaedic funding was more
                  resilient, continuing its upward trajectory without any notable decrease.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Throughout the observed period, the actual number of
                  orthopaedic surgeons receiving private payments increased, accompanied by a modest rise in the average
                  payment size and a slight decline in the median payment size. A similar growth pattern for physicians
                  receiving payments has been noted across other medical specialties<a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">23</span></a>, reflecting an expansion of research within the health-care
                  industry.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Private
                Funding According to Gender</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Female orthopaedic surgeons constituted only 3% of all
                  orthopaedic surgeons receiving private funding in 2015, and they received just over 2% of the funding
                  dollars distributed that year. In 2021, all funded female orthopaedic surgeons combined constituted 3%
                  of all funded surgeons but received &lt;1% of the funding dollars distributed that year. The median
                  funding to male surgeons was higher than funding to female surgeons in each year studied, but these
                  differences were not significant. Thus, female surgeons received an equitable distribution of research
                  funds but did not have the same level of participation as male surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">This study had several limitations. First, it utilized the
                  CMS OPD and NIH RePORTER tool. There are no available data to indicate the number of surgeons who
                  apply to receive funding; therefore, it is impossible to know if female surgeons are being denied or
                  accepted for grants at different rates than male surgeons. Second, public funding included data from
                  the NIH RePORTER tool, which only analyzed NIH, Agency for Healthcare Research and Quality, and
                  Veterans Affairs funding. The total amount of all public funding could have been underreported since
                  Department of Defense, institutional, and perhaps other public sources of funding were not included.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">There were many cases of industry payments to an
                  individual physician in a group practice, but there was no clarity on how the funds were distributed
                  within the group. Therefore, the data likely underreport the number of orthopaedic surgeons receiving
                  industry payments. Furthermore, the descriptive statistics are likely an overestimation for the same
                  reason. Importantly, the reliance on data from industry raises concerns about the completeness of the
                  information. Co-investigators were not readily available in the CMS OPD database, and most surgeons in
                  the database originally had no subspecialty classification prior to the reclassification that was
                  conducted as part of this study. Finally, future studies would benefit from an increase in oversight
                  and regular auditing of these databases to ensure the standardization and completeness of the data.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Our findings offer insight into the trends and
                  characteristics of public and private funding in orthopaedic research. They highlight a substantial
                  concentration in the distribution of research funding in orthopaedics, with the top 1% of all
                  practicing orthopaedic surgeons receiving a staggering 99% of all private funding. Additionally, our
                  analysis revealed that adult reconstruction received the greatest amount of private funding, whereas
                  pediatrics received the least amount of private funding. Although the total amount of public funding
                  was greater than the amount of private funding, it was disbursed among markedly fewer institutions
                  compared with private funding, which was indicative of funding being distributed to the CoEs. While
                  there was no significant difference between the median private payment to orthopaedic surgeons
                  according to gender, men received 97% of all private funding. Appreciating the vital role of research
                  funding to advance orthopaedic surgery research and having a comprehensive understanding of its
                  distribution is crucial. Increased transparency in funding distribution can facilitate a more targeted
                  approach to resource allocation, thereby improving the fairness and effectiveness of funding
                  distribution.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_8" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/H955" target="_blank" data-id="link_9" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/H955</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Rosenthal MB</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Mello MM</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Sunlight as disinfectant—new
                  rules on disclosure of industry payments to physicians</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">N Engl J Med.</span> 2013 May
                30;368(22):2052-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sunlight%20as%20disinfectant%E2%80%94new%20rules%20on%20disclosure%20of%20industry%20payments%20to%20physicians&as_occt=title&as_sauthors=%20%22MB%20Rosenthal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Agrawal S</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Brennan N</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Budetti P</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The Sunshine Act—effects on
                  physicians</span>. <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">N
                  Engl J Med.</span> 2013 May 30;368(22):2054-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Sunshine%20Act%E2%80%94effects%20on%20physicians&as_occt=title&as_sauthors=%20%22S%20Agrawal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;">Patient
                Protection and Affordable Care Act. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Patient Protection and Affordable Care Act,</span>, Pub L. 111-148.
                2010.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;">Centers for
                Medicare & Medicaid Services. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Open Payments - Natures of Payment.</span> Accessed 2024 Feb 7. <a
                  href="https://www.cms.gov/OpenPayments/Natures-of-Payment" target="_blank" data-id="link_1"
                  class="link" style="position: unset;">https://www.cms.gov/OpenPayments/Natures-of-Payment</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Grassley C</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Physician Payments Sunshine
                  Act of 2009.</span> Accessed 2024 Feb 7. <a href="http://www.congress.gov/" target="_blank"
                  data-id="link_2" class="link" style="position: unset;">http://www.congress.gov/</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Chatterji AK</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Fabrizio KR</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Mitchell W</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Schulman KA</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Physician-industry cooperation
                  in the medical device industry</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Health Aff (Millwood).</span> 2008 Nov-Dec;27(6):1532-43.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Physician-industry%20cooperation%20in%20the%20medical%20device%20industry&as_occt=title&as_sauthors=%20%22AK%20Chatterji%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Crowninshield RD</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Callaghan JJ</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The orthopaedic profession and
                  the industry partnership</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2007 Apr;457(457):73-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20orthopaedic%20profession%20and%20the%20industry%20partnership&as_occt=title&as_sauthors=%20%22RD%20Crowninshield%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Rajaratnam A</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Current trends in the
                  relationship between orthopaedic surgeons and industry</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 2009
                Oct;91(10):1265-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20trends%20in%20the%20relationship%20between%20orthopaedic%20surgeons%20and%20industry&as_occt=title&as_sauthors=%20%22A%20Rajaratnam%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Gelberman RH</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Samson D</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Mirza SK</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Callaghan JJ</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Pellegrini VD Jr</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Orthopaedic surgeons and the
                  medical device industry: the threat to scientific integrity and the public trust</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2010 Mar;92(3):765-77.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1076004"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Bekelman JE</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Gross CP</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Scope and impact of financial
                  conflicts of interest in biomedical research: a systematic review</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">JAMA.</span> 2003 Jan
                22-29;289(4):454-65.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Scope%20and%20impact%20of%20financial%20conflicts%20of%20interest%20in%20biomedical%20research%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22JE%20Bekelman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Okike K</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Kocher MS</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Mehlman CT</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Bhandari M</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Conflict of interest in
                  orthopaedic research. An association between findings and funding in scientific presentations</span>.
                <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2007 Mar;89(3):608-13.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1008382" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Heckmann ND</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Mayfield CK</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Chung BC</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Christ AB</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Lieberman JR</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Industry Payment Trends to
                  Orthopaedic Surgeons From 2014 to 2018: An Analysis of the First 5 Years of the Open Payments
                  Database</span>. <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Am
                  Acad Orthop Surg.</span> 2022 Jan 15;30(2):e191-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Industry%20Payment%20Trends%20to%20Orthopaedic%20Surgeons%20From%202014%20to%202018%3A%20An%20Analysis%20of%20the%20First%205%20Years%20of%20the%20Open%20Payments%20Database&as_occt=title&as_sauthors=%20%22ND%20Heckmann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Cvetanovich GL</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Chalmers PN</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Bach BR Jr</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Industry Financial
                  Relationships in Orthopaedic Surgery: Analysis of the Sunshine Act Open Payments Database and
                  Comparison with Other Surgical Subspecialties</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2015 Aug
                5;97(15):1288-95.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1218242"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Samuel AM</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Webb ML</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Lukasiewicz AM</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Bohl DD</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Basques BA</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Russo GS</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Rathi VK</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Grauer JN</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Orthopaedic Surgeons Receive
                  the Most Industry Payments to Physicians but Large Disparities are Seen in Sunshine Act Data</span>.
                <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2015 Oct;473(10):3297-306.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Orthopaedic%20Surgeons%20Receive%20the%20Most%20Industry%20Payments%20to%20Physicians%20but%20Large%20Disparities%20are%20Seen%20in%20Sunshine%20Act%20Data&as_occt=title&as_sauthors=%20%22AM%20Samuel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Frane N</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Partan MJ</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> White PB</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Iturriaga C</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Tarazi JM</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Roy T</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Bitterman AD</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Orthopaedic Trauma Surgeons’
                  Financial Relationships With Industry: An Analysis of the Sunshine Act Reporting of Physician Open
                  Payments From 2014 to 2019</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg Glob Res Rev.</span> 2021 Nov
                9;5(11):e21.00251.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Orthopaedic%20Trauma%20Surgeons%E2%80%99%20Financial%20Relationships%20With%20Industry%3A%20An%20Analysis%20of%20the%20Sunshine%20Act%20Reporting%20of%20Physician%20Open%20Payments%20From%202014%20to%202019&as_occt=title&as_sauthors=%20%22N%20Frane%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Thomas G</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Bornstein S</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Cho K</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Rao RD</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Industry payments to spine
                  surgeons from 2014 to 2019: trends and comparison of payments to spine surgeons versus all
                  physicians</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2022 Jun;22(6):910-20.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Industry%20payments%20to%20spine%20surgeons%20from%202014%20to%202019%3A%20trends%20and%20comparison%20of%20payments%20to%20spine%20surgeons%20versus%20all%20physicians&as_occt=title&as_sauthors=%20%22G%20Thomas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> White PB</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Iturriaga C</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Frane N</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Partan MJ</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Ononuju U</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Mont MA</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Bitterman A</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Industry Payments to Adult
                  Reconstruction-Trained Orthopedic Surgeons: An Analysis of the Open Payments Database From 2014 to
                  2019</span>. <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2021 Nov;36(11):3788-95.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Industry%20Payments%20to%20Adult%20Reconstruction-Trained%20Orthopedic%20Surgeons%3A%20An%20Analysis%20of%20the%20Open%20Payments%20Database%20From%202014%20to%202019&as_occt=title&as_sauthors=%20%22PB%20White%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Parslow TG</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Roskoski R Jr</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">A Primer on BRIMR:
                  Understanding the Rankings of NIH Support from the Blue Ridge Institute for Medical Research</span>.
                <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Am J Pathol.</span>
                2022 Mar;192(3):392-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Primer%20on%20BRIMR%3A%20Understanding%20the%20Rankings%20of%20NIH%20Support%20from%20the%20Blue%20Ridge%20Institute%20for%20Medical%20Research&as_occt=title&as_sauthors=%20%22TG%20Parslow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;">NIH
                RePORT. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">RePORTER.</span> Accessed 2024 Feb 7. <a href="https://reporter.nih.gov/"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://reporter.nih.gov/</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;">U.S.
                Bureau of Labor Statistics. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">CPI Inflation Calculator.</span> Accessed 2024 Feb 7. <a
                  href="https://www.bls.gov/data/inflation_calculator.htm" target="_blank" data-id="link_4" class="link"
                  style="position: unset;">https://www.bls.gov/data/inflation_calculator.htm</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;">National
                Institutes of Health (NIH). <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Basic Research – Digital Media Kit.</span> Accessed 2024 Feb 7. <a
                  href="https://www.nih.gov/news-events/basic-research-digital-media-kit" target="_blank"
                  data-id="link_5" class="link"
                  style="position: unset;">https://www.nih.gov/news-events/basic-research-digital-media-kit</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Kalva P</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Kakkilaya A</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Mekala P</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Cavdar IK</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Patel M</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Kooner KS</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Trends and Characteristics of
                  Industry Payments for Ophthalmology Research From 2014 to 2020</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">JAMA Ophthalmol.</span> 2022 Oct
                13;140(11):1105-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20and%20Characteristics%20of%20Industry%20Payments%20for%20Ophthalmology%20Research%20From%202014%20to%202020&as_occt=title&as_sauthors=%20%22P%20Kalva%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Khetpal S</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Le NK</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Ahmad M</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Parikh J</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Pathak N</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Pourtaheri N</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Lopez J</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Alperovich M</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Trends in industry-sponsored
                  research in plastic surgery since implementation of the Sunshine Act</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Plast Reconstr Aesthet
                  Surg.</span> 2022 Jun;75(6):2019-26.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20in%20industry-sponsored%20research%20in%20plastic%20surgery%20since%20implementation%20of%20the%20Sunshine%20Act&as_occt=title&as_sauthors=%20%22S%20Khetpal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Murayama A</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Kamamoto S</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Saito H</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Tanimoto T</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Ozaki A</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Trends in Industry Payments to
                  Diabetologists and Endocrinologists in the United States During the COVID-19 Pandemic</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Cureus.</span> 2022 Dec
                17;14(12):e32643.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20in%20Industry%20Payments%20to%20Diabetologists%20and%20Endocrinologists%20in%20the%20United%20States%20During%20the%20COVID-19%20Pandemic&as_occt=title&as_sauthors=%20%22A%20Murayama%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Murayama A</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Nakano K</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Kamamoto S</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Sato M</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Saito H</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Tanimoto T</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Ozaki A</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Trend in industry payments to
                  infectious disease physicians in the United States: a seven-year analysis of nonresearch payments from
                  the Open Payments Database between 2014 and 2020</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Clin Microbiol Infect.</span> 2022
                Dec;28(12):1655.e1-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trend%20in%20industry%20payments%20to%20infectious%20disease%20physicians%20in%20the%20United%20States%3A%20a%20seven-year%20analysis%20of%20nonresearch%20payments%20from%20the%20Open%20Payments%20Database%20between%202014%20and%202020&as_occt=title&as_sauthors=%20%22A%20Murayama%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Mann HB</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Nonparametric Tests Against
                  Trend</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Econometrica.</span> 1945;13:245-59.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nonparametric%20Tests%20Against%20Trend&as_occt=title&as_sauthors=%20%22HB%20Mann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Chen X</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Wang H</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Lyu W</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Xu R</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">The Mann-Kendall-Sneyers test
                  to identify the change points of COVID-19 time series in the United States</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">BMC Med Res
                  Methodol.</span> 2022 Aug 30;22(1):233.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Mann-Kendall-Sneyers%20test%20to%20identify%20the%20change%20points%20of%20COVID-19%20time%20series%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22X%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;">American
                Academy of Orthopaedic Surgeons. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Making the Case (Again) for Gender Equity. 2019.</span> Accessed 2024 Feb 7.
                <a href="https://www.aaos.org/aaosnow/2019/jun/youraaos/youraaos05/" target="_blank" data-id="link_6"
                  class="link"
                  style="position: unset;">https://www.aaos.org/aaosnow/2019/jun/youraaos/youraaos05/</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Khatana SAM</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Nathan AS</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Dayoub EJ</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Giri J</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Groeneveld PW</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Centers of Excellence
                  Designations, Clinical Outcomes, and Characteristics of Hospitals Performing Percutaneous Coronary
                  Interventions</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">JAMA Intern Med.</span> 2019 Aug 1;179(8):1138-40.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Centers%20of%20Excellence%20Designations%2C%20Clinical%20Outcomes%2C%20and%20Characteristics%20of%20Hospitals%20Performing%20Percutaneous%20Coronary%20Interventions&as_occt=title&as_sauthors=%20%22SAM%20Khatana%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Elrod JK</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Fortenberry JL Jr</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Centers of excellence in
                  healthcare institutions: what they are and how to assemble them</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">BMC Health Serv Res.</span> 2017 Jul 11;17(Suppl
                1):425.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Centers%20of%20excellence%20in%20healthcare%20institutions%3A%20what%20they%20are%20and%20how%20to%20assemble%20them&as_occt=title&as_sauthors=%20%22JK%20Elrod%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Donoughe JS</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Safavi KS</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Rezvani A</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Healy N</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Jupiter DC</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Panchbhavi VK</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Janney CC</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Industry Payments to Foot and
                  Ankle Surgeons and Their Effect on Total Ankle Arthroplasty Outcomes</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">Foot Ankle Orthop.</span>
                2021 Sep 14;6(3):24730114211034519.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Industry%20Payments%20to%20Foot%20and%20Ankle%20Surgeons%20and%20Their%20Effect%20on%20Total%20Ankle%20Arthroplasty%20Outcomes&as_occt=title&as_sauthors=%20%22JS%20Donoughe%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Burson RC</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Clapp JT</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Fleisher LA</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Centers of excellence: Are
                  there standards?</span><span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">Healthc (Amst).</span> 2020 Mar;8(1):100388.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Centers%20of%20excellence%3A%20Are%20there%20standards%3F&as_occt=title&as_sauthors=%20%22J%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Manyazewal T</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Woldeamanuel Y</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Oppenheim C</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Hailu A</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Giday M</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Medhin G</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Belete A</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Yimer G</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Collins A</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Makonnen E</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Fekadu A</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Conceptualising centres of
                  excellence: a scoping review of global evidence</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">BMJ Open.</span> 2022 Feb
                7;12(2):e050419.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Conceptualising%20centres%20of%20excellence%3A%20a%20scoping%20review%20of%20global%20evidence&as_occt=title&as_sauthors=%20%22T%20Manyazewal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Brauer PR</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Morse E</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Mehra S</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Industry Payments for
                  Otolaryngology Research: A Four-Year Analysis of the Open Payments Database</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Laryngoscope.</span> 2020
                Feb;130(2):314-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Industry%20Payments%20for%20Otolaryngology%20Research%3A%20A%20Four-Year%20Analysis%20of%20the%20Open%20Payments%20Database&as_occt=title&as_sauthors=%20%22PR%20Brauer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Cuomo RE</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Cai M</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Shah N</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Mackey TK</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Physicians payment in the
                  United States between 2014 and 2018: An analysis of the CMS Open Payments database</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">PLoS One.</span> 2021 Jun
                2;16(6):e0252656.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Physicians%20payment%20in%20the%20United%20States%20between%202014%20and%202018%3A%20An%20analysis%20of%20the%20CMS%20Open%20Payments%20database&as_occt=title&as_sauthors=%20%22RE%20Cuomo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;260a2ddb-890b-4d0d-9be7-d7877192fcfe&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=260a2ddb-890b-4d0d-9be7-d7877192fcfe&type=supplement&name=10469"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D260a2ddb-890b-4d0d-9be7-d7877192fcfe%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">September 4, 2024;
                      106 (17): 1631</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00598</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">April 1, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_36"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Cleveland Clinic Foundation, Cleveland, Ohio</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;260a2ddb-890b-4d0d-9be7-d7877192fcfe&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=260a2ddb-890b-4d0d-9be7-d7877192fcfe&type=pdf&name=JBJS.23.00598.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=260a2ddb-890b-4d0d-9be7-d7877192fcfe&type=pdf&name=JBJS.23.00598.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_34"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_35" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/H954" target="_blank" data-id="link_7"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/H954</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;260a2ddb-890b-4d0d-9be7-d7877192fcfe&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=260a2ddb-890b-4d0d-9be7-d7877192fcfe&type=zip&name=JBJS.23.00598.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Aakash K. Shah, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1229-6852" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1229-6852</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robert J. Burkhart, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1675-2365" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1675-2365</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Varunil N. Shah, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-4964-5503" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-4964-5503</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Heath P. Gould, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8941-8811" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8941-8811</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alexander J. Acuña, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8349-7846" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8349-7846</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Atul F. Kamath, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:kamatha@ccf.org" class="" style="position: unset;">kamatha@ccf.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9214-2756" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9214-2756</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Cleveland Clinic Foundation, Cleveland,
                Ohio</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University Hospitals, Cleveland, Ohio</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Sports Medicine Institute, Hospital for Special Surgery, New York, NY</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Midwest Orthopaedics at Rush, Chicago,
                Illinois</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 28174.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
