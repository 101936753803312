import CustomSnackbar from "components/CustomSnackbar";
import type React from "react";
import { createContext, useCallback, useContext, useState } from "react";

type ToastAction = {
	label: string;
	action: () => void;
};

type ToastOptions = {
	message: string;
	duration?: number;
	action?: ToastAction;
};

type ToastContextType = {
	showToast: (options: ToastOptions) => void;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export function useToast() {
	const context = useContext(ToastContext);
	if (!context) {
		throw new Error("useToast must be used within a ToastProvider");
	}
	return context;
}

type ToastProviderProps = {
	children: React.ReactNode;
};

export function ToastProvider({ children }: ToastProviderProps) {
	const [visible, setVisible] = useState(false);
	const [options, setOptions] = useState<ToastOptions>({ message: "" });

	const showToast = useCallback((newOptions: ToastOptions) => {
		setOptions(newOptions);
		setVisible(true);
	}, []);

	const handleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const contextValue = {
		showToast,
	};

	return (
		<ToastContext.Provider value={contextValue}>
			{children}
			<CustomSnackbar
				visible={visible}
				handleDismiss={handleDismiss}
				message={options.message}
				duration={options.duration}
				action={options.action}
			/>
		</ToastContext.Provider>
	);
}

export default ToastProvider;
