/* -----------------Globals--------------- */
import { useEffect } from "react";

/* -----------------Child components--------------- */
import StudySessionView from "../components/StudySessionView";

import { useAuthContext } from "@memorang/applets";
import { isUuid } from "@memorang/helpers";
import { useLocalSearchParams, useRouter } from "expo-router";
/* -----------------Helpers & Hooks--------------- */
import useSessionStore from "../hooks/useSessionStore";

/* -----------------UI--------------- */
import { Loader } from "@memorang/ui";

import PracticeSessionView from "../components/PracticeSessionView";
import MoreItemsAlertDialog from "../components/dialogs/MoreItemsAlertDialog";
/* -----------------Types--------------- */
import { useResumeSession } from "../hooks/useResumeSession";

const SessionContainer = () => {
	const sessionItems = useSessionStore((state) => state.sessionItems);
	const numItemsRequested = useSessionStore((state) => state.numItemsRequested);
	const sessionId = useSessionStore((state) => state.id);
	const isSummativeTest = useSessionStore((state) => state.isSummativeTest);
	const currentBlockIndex = useSessionStore((state) => state.currentBlockIndex);
	const totalTime = useSessionStore((state) => state.totalTime);
	const inReviewMode = useSessionStore((state) => state.inReviewMode);
	const reportSessionType = useSessionStore((state) => state.reportSessionType);
	const showMoreItemsAlert = useSessionStore(
		(state) => state.showMoreItemsAlert,
	);

	const toggleShowMoreItemsAlert = useSessionStore(
		(state) => state.toggleShowMoreItemsAlert,
	);
	const { handleResumeSession } = useResumeSession();
	const { viewerId } = useAuthContext();
	const router = useRouter();

	const id = useLocalSearchParams<{
		id: string;
	}>().id;

	useEffect(() => {
		if (!sessionId && viewerId && id) {
			const sessionNumericId = isUuid(id) ? undefined : Number(id);
			if (sessionNumericId) {
				handleResumeSession(sessionNumericId!);
			} else {
				//formattive session, redirect to home since resume session is not supported
				router.replace("/home");
			}
		}
	}, [handleResumeSession, id, router, sessionId, viewerId]);

	if (!sessionId) {
		return <Loader />;
	}

	if (isSummativeTest) {
		return (
			<PracticeSessionView
				sessionItems={sessionItems}
				sessionId={sessionId}
				currentBlockIndex={currentBlockIndex}
				totalTime={totalTime}
				inReviewMode={inReviewMode}
				reportSessionType={reportSessionType}
			/>
		);
	}

	return (
		<>
			<StudySessionView
				sessionItems={sessionItems}
				sessionId={sessionId}
				isSummativeTest={isSummativeTest}
				currentBlockIndex={currentBlockIndex}
			/>
			{showMoreItemsAlert && (
				<MoreItemsAlertDialog
					visible={showMoreItemsAlert}
					handleClose={toggleShowMoreItemsAlert}
					numItemsAvailable={sessionItems.length}
					numItemsRequested={numItemsRequested}
				/>
			)}
		</>
	);
};

export default SessionContainer;
