import type { Article } from "../../../../../types";

export const Article519: Article = {
	id: 519,
	rsuiteId: "03633c20-2304-4f03-a4a4-24de05fb8e21",
	type: "scientific article",
	title:
		"Ten-Year Follow-up of Posterior Decompression and Fusion Surgery for Thoracic Ossification of the Posterior Longitudinal Ligament",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03633c20-2304-4f03-a4a4-24de05fb8e21&type=jpeg&name=JBJS.23.01475f2",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Ten-Year Follow-up of Posterior Decompression and Fusion
                Surgery for Thoracic Ossification of the Posterior Longitudinal Ligament</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Sadayuki Ito,
                      MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Hiroaki
                      Nakashima, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 8 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Background:</span> We evaluated the clinical, functional, and quality
                        of life (QoL) outcomes of surgical treatment of thoracic ossification of the posterior
                        longitudinal ligament (T-OPLL).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Methods:</span> We retrospectively evaluated 51 patients followed for
                        ≥10 years after posterior decompression and corrective fusion surgery for T-OPLL. The data
                        collected included demographics, comorbidities, and pre- and postoperative symptoms. The
                        Japanese Orthopaedic Association (JOA) score, numerical rating scale (NRS) for back and leg
                        pain, and EuroQol-5 Dimension-5 Level (EQ-5D-5L) were used to assess neurological function,
                        pain, and QoL. Imaging evaluations were conducted to assess changes in kyphotic angles and
                        ossification progression.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Results:</span> A significant improvement was observed in the JOA
                        score from preoperatively (3.7) to 2 years postoperatively (7.9) (p &lt; 0.05); the score
                        remained stable thereafter. The mean EQ-5D-5L score improved from 0.53 preoperatively to 0.68 at
                        10 years postoperatively (p &lt; 0.001). NRS scores for back and leg pain decreased from 5.4 to
                        3.5 and 4.0 to 3.0, respectively, from preoperatively to 10 years (p &lt; 0.001 for both).
                        Radiographic outcomes showed changes in kyphotic angles and ossification areas, with no
                        significant progression after 2 years. Fourteen (27.5%) of the patients experienced
                        postoperative complications. Of these, 8 (15.7%) required reoperation, 6 (11.8%) in the
                        perioperative period and 2 (3.9%) later. Four (7.8%) of the patients underwent additional
                        surgeries for conditions including lumbar spinal canal stenosis and cervical OPLL. Nonetheless,
                        physical function in all cases with postoperative complications or additional surgery remained
                        stable over the decade.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Surgical treatment of T-OPLL is effective in
                        improving neurological function, QoL, and pain management over an extended period. The long-term
                        outcomes of T-OPLL surgery revealed that, although cervical and lumbar spinal lesions led to
                        reoperations, they did not affect QoL, and relative improvement was maintained even after 10
                        years.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level IV</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Thoracic ossification of the posterior longitudinal
                  ligament (T-OPLL), characterized by ectopic bone growth in the spine, can lead to neurological issues
                  due to spinal cord compression<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  T-OPLL is most prevalent in Japan (0.6% to 1.9%), with lower rates in China, South Korea, Europe, and
                  North America<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">5</span></a>. Cervical involvement is common with OPLL; T-OPLL is rarer
                  and progresses slowly, often without detection until severe symptoms emerge, particularly in women.
                  The lack of understanding of its molecular basis limits treatment options to surgical decompression
                  because no effective medical or preventive therapies exist, often resulting in unsatisfactory outcomes
                  for advanced cases<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">7</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">T-OPLL often leads to more severe spinal cord issues than
                  cervical OPLL, including paralysis, walking difficulties, and bowel/bladder dysfunction, with poorer
                  surgical outcomes attributed to poor thoracic cord blood flow and decreased mobility from kyphotic
                  curvature<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">10</span></a>. A comparison of surgical treatment options for T-OPLL
                  (decompression alone versus decompression combined with fixation) revealed improved surgical outcomes
                  following decompression with fixation<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">11</span></a>. In
                  addition to direct decompression, performing dekyphosis during fixation can indirectly reduce the
                  compression from anterior ossification even further<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">12</span></a>. The
                  rate of employment of fixation techniques for T-OPLL was reported to be 88%<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">13</span></a>. Addressing unstable, discontinuous segments through
                  fixation not only improves myelopathy but also prevents future ossification progression, making
                  posterior decompression with instrumentation the most common method for T-OPLL<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">18</span></a>.
                  However, the long-term benefits of these surgeries remain uncertain, highlighting the need for more
                  data, especially over follow-up of ≥10 years, to assess their effectiveness and sustainability in
                  managing T-OPLL<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">19</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">20</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">We retrospectively evaluated patients who underwent
                  posterior decompression and corrective fixation surgery for T-OPLL at our institution, with long-term
                  follow-up exceeding 10 years. We conducted a comprehensive assessment of neurological function and
                  quality of life (QoL). We used patient-reported outcome measures (PROMs) to evaluate the impact of
                  surgery from the patient’s perspective<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">21</span></a>.
                  Our aim was for our findings to substantially expand the literature on thoracic OPLL, shedding light
                  on the long-term QoL after posterior decompression and fusion surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The study included patients with T-OPLL who underwent
                  posterior decompression surgery, posterior correction, and fixation surgery at our hospital between
                  2001 and 2014. The surgical indication was progressive myelopathy with T-OPLL extending over multiple
                  vertebrae on computed tomography (CT) images and spinal compression at the level at which T-OPLL could
                  be visualized on magnetic resonance imaging (MRI). All patients underwent fixation to stabilize the
                  discontinuous segments causing myelopathy and to facilitate osseous fusion in discontinuous segments
                  that may progress to ossification in the future, thus preventing further ossification<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a>. Performing dekyphosis during fusion allows for further
                  decompression of the spinal cord<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">12</span></a>.
                  Dekyphosis reduces the spinal cord compression from anterior ossification lesions by weakening the
                  thoracic kyphosis, which is achieved by installing rigid rods on both left and right pedicle screws
                  using the cantilever method during fixation. Compression of the spinal cord from anterior ossification
                  lesions is reduced after dekyphosis<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">12</span></a>. Of
                  the 62 patients, 51 (26 male and 25 female; average age, 50.4 years [range, 18 to 80 years]) with ≥10
                  years of follow-up were included.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This study was approved by the institutional review board
                  (2005-0354-7), and all patients provided informed consent. Data from medical records, including age,
                  sex, and body mass index (BMI) as well as diabetes mellitus and hypertension, which are recognized as
                  relevant comorbidities, were collected<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">13</span></a>.
                  Myelopathy symptoms before and after surgery were assessed using the Japanese Orthopaedic Association
                  (JOA) score (maximum, 11 points). The postoperative JOA recovery rate was calculated as (postoperative
                  JOA score – preoperative JOA score) × 100/(maximum JOA score – preoperative JOA score)<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">23</span></a>. The postoperative JOA score and JOA recovery rate were
                  calculated for all 51 patients at 6 months and 1, 2, 5, and 10 years postoperatively to gauge
                  time-dependent improvement. Preoperative and final follow-up assessments included the numerical rating
                  scale (NRS) for back and leg pain and the EuroQol-5 Dimension-5 Level (EQ-5D-5L). We also examined
                  postoperative complications and subsequent spinal surgeries during the study period. “Reoperation”
                  surgeries involved the primary surgical and/or adjacent level, whereas “additional” surgeries did not.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Strategy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The surgical strategy for T-OPLL at our institution
                  involves setting the decompression range to the level of spinal cord compression caused by T-OPLL. The
                  fusion range includes all decompression levels along with the range in which discontinuous OPLL is
                  expected to increase in the future<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">14</span></a>.
                  The range is selected to sufficiently include the levels involved in dekyphosis to indirectly
                  decompress the spinal cord compression caused by T-OPLL.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Procedure</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Following insertion of the pedicle screws, temporary rods
                  were placed on both left and right pedicle screws, respectively. Laminectomy was performed using an
                  air drill. Stiff rods for kyphosis reduction were installed on both left and right pedicle screws,
                  respectively, via the cantilever method for enhanced spinal cord decompression. This was done with the
                  aid of spinal cord monitoring. The extent of decompression was confirmed by ultrasound examination<a
                    href="" data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">12</span></a>. All patients underwent postoperative rehabilitation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Imaging
                Evaluation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Preoperative and postoperative T1-T12 and fusion-level
                  Cobb angles were evaluated using radiographs to determine postoperative changes in kyphosis. To
                  evaluate proximal junctional kyphosis and distal junctional kyphosis, the proximal junctional angle
                  (PJA) was defined as the Cobb angle between the lower or caudal end plate of the upper instrumented
                  vertebra (UIV) and the upper or cranial end plate of the upper vertebra adjacent to the UIV, measured
                  preoperatively and at 2, 5, and 10 years postoperatively. The distal junctional angle (DJA) was
                  defined as the Cobb angle between the upper end plate of the lower instrumented vertebra (LIV) and the
                  lower or caudal end plate of the lower vertebra adjacent to the LIV measured preoperatively and 2, 5,
                  and 10 years postoperatively. Proximal junctional kyphosis and distal junctional kyphosis were defined
                  as a PJA and DJA of ≥20°, respectively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Preoperative CT was used to identify the most stenotic
                  T-OPLL level in the upper (T1-T4), middle (T5-T8), or lower thoracic (T9-T12) spine. Ossification was
                  classified as beak-type or flat-type<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">24</span></a>. At
                  the most narrowed spinal canal level, preoperative axial CT images quantified both the spinal canal
                  area and ossification within it, establishing the spinal canal occupancy ratio (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). Ossification measurements at this level were made at 2, 5, and
                  10 years postoperatively (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>), with
                  long-term progression assessed accordingly (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>). All CT
                  images were reviewed by 2 orthopaedic spine surgeons who identified and characterized the lesions by
                  consensus. Both surgeons were blinded to age, sex, clinical history, symptoms, and surgical outcomes
                  at the time of this review.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01475f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03633c20-2304-4f03-a4a4-24de05fb8e21&type=jpeg&name=JBJS.23.01475f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_40"
                            class="annotation strong" style="position: unset;">Fig. 1-A</span> Preoperative axial CT
                          image of the most narrowed area of OPLL. <span data-id="strong_41" class="annotation strong"
                            style="position: unset;">Fig. 1-B</span> Measurement of the ossification area. <span
                            data-id="strong_42" class="annotation strong" style="position: unset;">Fig. 1-C</span>
                          Measurement of the area of the spinal canal region.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01475f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03633c20-2304-4f03-a4a4-24de05fb8e21&type=jpeg&name=JBJS.23.01475f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Fig. 2-A</span> Postoperative axial CT
                          image of the most narrowed area of OPLL. <span data-id="strong_44" class="annotation strong"
                            style="position: unset;">Fig. 2-B</span> Measurement of the ossification area.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01475f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03633c20-2304-4f03-a4a4-24de05fb8e21&type=jpeg&name=JBJS.23.01475f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Figs. 3-A and 3-B</span> A case of
                          ossification progression. <span data-id="strong_46" class="annotation strong"
                            style="position: unset;">Fig. 3-A</span> Sagittal CT image of the fixed caudal end at 1
                          month postoperatively. <span data-id="strong_47" class="annotation strong"
                            style="position: unset;">Fig. 3-B</span> CT image of the fixed caudal end at 6 years
                          postoperatively.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Statistical analysis was performed using the Mann-Whitney
                  U test and chi-square test in SPSS for Windows (version 28; IBM), with p &lt; 0.05 considered
                  significant. Data are presented as the mean and standard deviation (SD).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Patient characteristics are presented in <a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>. The average BMI was 32.9 kg/m<span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">2</span>. Diabetes mellitus and hypertension
                  were observed in 20 (39.2%) and 6 (11.8%) of the patients, respectively. The average number of T-OPLL
                  levels was 5.8, and beak-type OPLL at the most stenotic location was observed in 46 (90.2%). OPLL with
                  ossification of the ligamentum flavum (OLF) was observed in 26 (51.0%). The most severe ossification
                  was predominantly located in the middle-thoracic spine (T5-T8; n = 36), followed by the upper-thoracic
                  spine (T1-T4; n = 10).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Summary of Patient Characteristics (N = 51 with
                          T-OPLL)<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 959px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.4 ±
                          11.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49.0%
                          (25)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_35" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_36" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_20" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_20"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_37"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.9 ±
                          7.9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diabetes mellitus</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.2%
                          (20)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hypertension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.8%
                          (6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. radiographic
                          findings</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Kyphotic angle of T1-T12
                          <span data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44.8 ±
                          11.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Kyphotic angle of fusion
                          level <span data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.6 ±
                          14.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Beak-type</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90.2%
                          (46)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. CT findings</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> T-OPLL most stenotic
                          level</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Upper thoracic
                          (T1-T4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Middle thoracic
                          (T5-T8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Lower thoracic
                          (T9-T12)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No. of T-OPLL
                          levels</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.8 ±
                          3.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Spinal canal occupancy
                          ratio <span data-id="emphasis_40" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.7 ±
                          12.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> OLF</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51.0%
                          (26)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. clinical
                          findings</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PST positive</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56.9%
                          (29)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> JOA score</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.7 ±
                          2.5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> EQ-5D-5L</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.527 ±
                          0.246</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> NRS for back pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.4 ±
                          3.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> NRS for leg pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0 ±
                          3.4</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation, or as the
                        percentage (number) of patients. T-OPLL = thoracic ossification of the posterior longitudinal
                        ligament, BMI = body mass index, CT = computed tomography, OLF = ossification of the ligamentum
                        flavum, PST = prone and supine position test, JOA = Japanese Orthopaedic Association, EQ-5D-5L =
                        EuroQol-5 Dimension-5 Level, and NRS = numerical rating scale.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Surgical findings are presented in <a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>. On average, 5.9 vertebrae were decompressed and 9.6 were
                  fused. Twenty-six (51.0%) of the patients underwent simultaneous cervical laminoplasty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Intraoperative Findings (N = 51)<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 243px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Operative time <span
                            data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;">(min)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">448 ±
                          137</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">EBL <span
                            data-id="emphasis_42" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">967 ±
                          860</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of fused
                          vertebrae</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.6 ±
                          3.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of decompressed
                          vertebrae</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.9 ±
                          2.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Concomitant cervical
                          laminoplasty</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51.0%
                          (26)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation, or as the
                        percentage (number) of patients. T-OPLL = thoracic ossification of the posterior longitudinal
                        ligament, and EBL = estimated blood loss.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The mean JOA score improved significantly from 3.7
                  preoperatively to 7.1 at 6 months postoperatively (p &lt; 0.001), 7.6 at 1 year (p &lt; 0.001), and
                  7.9 at 2 years (p = 0.043). At both 5 and 10 years postoperatively, the mean JOA score was 7.8, with
                  no changes observed thereafter (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>, <a
                    href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01475f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03633c20-2304-4f03-a4a4-24de05fb8e21&type=jpeg&name=JBJS.23.01475f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">The mean JOA score of 3.7 preoperatively gradually
                          increased up to 2 years postoperatively, reaching 7.1, 7.6, and 7.9 at 6 months, 1 year, and 2
                          years, respectively, and then was 7.8 at both 5 and 10 years. The mean recovery rates were
                          49.1%, 56.3%, 59.7%, 58.3%, and 57.9% at 6 months and 1, 2, 5, and 10 years, respectively. *P
                          &lt; 0.05 compared with preoperatively. I-bars represent the standard deviation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Postoperative Clinical Outcomes (N = 51)<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 757px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">JOA score</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.7 ±
                          2.5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  6 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.1 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  1 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.6 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  2 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.9 ±
                          2.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.043</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  5 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.8 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.083</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  10 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.8 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.327</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postop. JOA recovery rate
                          <span data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 6 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49.1 ±
                          26.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56.3 ±
                          28.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59.7 ±
                          27.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.040</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.3 ±
                          28.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.087</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 10 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.9 ±
                          28.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.327</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10-yr postop.
                          PROMs</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> EQ-5D-5L</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.682 ±
                          0.192</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> NRS for back pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.5 ±
                          2.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> NRS for leg pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0 ±
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation. JOA = Japanese
                        Orthopaedic Association, PROMs = patient-reported outcome measures, EQ-5D-5L = EuroQol-5
                        Dimension-5 Level, and NRS = numerical rating scale.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">P values for the JOA score are from comparisons between the score for
                        the indicated time point and that of the preceding time point (e.g., 6 months versus
                        preoperatively, 1 year versus 6 months, etc.). P values for the JOA recovery rate are from
                        comparisons between the rate for the indicated time point and that of the previously assessed
                        time point (e.g., 6 months versus 3 months, 1 year versus 6 months, etc.). P values for 10-year
                        PROMs are from comparisons with preoperative values.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patient-Reported Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">At 10 years postoperatively, the mean EQ-5D-5L score had
                  improved significantly compared with preoperatively, from 0.53 to 0.68 (p &lt; 0.001). At 10 years
                  postoperatively, the mean NRS score also had improved significantly, from 5.4 preoperatively to 3.5
                  for back pain and from 4.0 to 3.0 for leg pain (p &lt; 0.001 for both) (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>
                  summarizes radiographic outcomes. The mean preoperative T1-T12 Cobb angle was 44.8°, decreasing to
                  35.9° immediately postoperatively and then increasing to 37.2°, 41.0°, and 43.3° at 2, 5, and 10
                  years, respectively (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>). At the
                  fusion level, angles shifted from 33.6° preoperatively to 24.5° immediately postoperatively and then
                  to 25.9°, 27.9°, and 31.4° at 2, 5, and 10 years, respectively (<a href=""
                    data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6</a>). Kyphosis decreased by 8.9° for T1-T12 and by 9.1° at the
                  fusion level immediately after surgery, with a 7.4° and 6.9° increase at 10 years for T1-T12 and at
                  the fusion level, respectively. No proximal junctional kyphosis or proximal junctional failure was
                  reported. Distal junctional failure related to a vertebral compression fracture at the LIV occurred in
                  4 cases within 3 to 6 months, while there were no cases of distal junctional kyphosis. Eleven (21.6%)
                  of the patients demonstrated ossification progression. The mean ossification area of the most stenotic
                  level increased significantly, from 77.3 mm<span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">2</span> preoperatively to 88.8 mm<span
                    data-id="superscript_22" class="annotation superscript" style="position: unset;">2</span> at 2 years
                  (p = 0.013). The mean ossification area of the most stenotic level was 90.8 mm<span
                    data-id="superscript_23" class="annotation superscript" style="position: unset;">2</span> at 5 years
                  and 91.1 mm<span data-id="superscript_24" class="annotation superscript"
                    style="position: unset;">2</span> at 10 years postoperatively, with no significant changes
                  thereafter (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 7</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Postoperative Radiographic Outcomes (N = 51)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1347px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Radiographic
                          Findings</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Value<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Kyphotic angle of T1-T12
                          <span data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44.8 ±
                          11.0</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Immediate
                          postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.9 ±
                          11.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.139</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  2 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37.2 ±
                          14.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.507</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  5 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.0 ±
                          9.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.254</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  10 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43.3 ±
                          14.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.208</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Kyphotic angle of fusion
                          level <span data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.6 ±
                          14.7</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Immediate
                          postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.5 ±
                          13.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  2 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.9 ±
                          11.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.307</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  5 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.9 ±
                          9.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.071</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  10 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.4 ±
                          12.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.493</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CT findings</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Ossification area of
                          most stenotic level <span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(mm</span><span data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_25"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_48"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77.3 ±
                          28.1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">   2 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88.8 ±
                          26.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.013</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">   5 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90.8 ±
                          27.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.000</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">   10 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">91.1 ±
                          27.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.000</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Postop. ossification
                          progression</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.6%
                          (11)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation, or as the
                        percentage (number) of patients.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">P values are from comparisons between the measurement at the given time
                        point and that of the preceding time point (e.g., for the kyphotic angle of T1-T12, immediately
                        postoperatively versus preoperatively, 2 years versus immediately postoperatively, etc.).</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01475f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03633c20-2304-4f03-a4a4-24de05fb8e21&type=jpeg&name=JBJS.23.01475f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;">The mean preoperative, immediate postoperative,
                          and 2, 5, and 10-year postoperative Cobb angles for T1-T12 were 44.8°, 35.9°, 37.2°, 41.0°,
                          and 43.3°, respectively. The change in kyphotic angle from preoperatively to immediately
                          postoperatively and from immediately postoperatively to 10 years was 8.9° and 7.4°,
                          respectively. There were no significant differences in the Cobb angles for T1-T12 between
                          preoperatively and each postoperative time period. I-bars represent the standard deviation.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.01475f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03633c20-2304-4f03-a4a4-24de05fb8e21&type=jpeg&name=JBJS.23.01475f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;">The mean preoperative, immediate postoperative,
                          and 2, 5, and 10-year postoperative Cobb angles of the fusion level were 33.6°, 24.5°, 25.9°,
                          27.9°, and 31.4°, respectively. The changes in the kyphotic angle of the fusion level from
                          preoperatively to immediately postoperatively and from immediately postoperatively to 10 years
                          were 9.1° and 6.9°, respectively. The immediate postoperative Cobb angle of the fusion level
                          was significantly lower than the preoperative angle (*p &lt; 0.001). No significant
                          differences in the Cobb angle of the fusion level between 2 years and the other time periods
                          were found. I-bars represent the standard deviation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.23.01475f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03633c20-2304-4f03-a4a4-24de05fb8e21&type=jpeg&name=JBJS.23.01475f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;">The ossification area increased significantly from
                          a mean of 77.3 mm<span data-id="superscript_26" class="annotation superscript"
                            style="position: unset;">2</span> preoperatively to 88.8 mm<span data-id="superscript_27"
                            class="annotation superscript" style="position: unset;">2</span> at 2 years postoperatively
                          (*p &lt; 0.05). The ossification areas at 5 and 10 years postoperatively were 90.8 and 91.1
                          mm<span data-id="superscript_28" class="annotation superscript"
                            style="position: unset;">2</span>, respectively, with no significant difference compared
                          with that at 2 years. I-bars represent the standard deviation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Complications, Reoperations, and Additional Operations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Six (11.8%) of the patients underwent a total of 6
                  reoperations within 30 days following surgery. Three patients with postoperative lower-limb paralysis
                  underwent posterior-approach anterior decompression, 2 with postoperative infection underwent
                  debridement, and 1 with postoperative hematoma underwent hematoma removal (<a href=""
                    data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>). Eight additional patients (15.7%) developed postoperative
                  complications. Four (7.8%) had adjacent vertebral fractures, all of which were distal junctional
                  failure, 2 (3.9%) had implant failure, and 2 (3.9%) had adjacent progression of caudal OLF (<a href=""
                    data-id="figure_reference_14" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>). Only the 2 cases related to adjacent progression of caudal
                  OLF underwent reoperation, with JOA recovery rates of 62.5% and 56.3% at the final follow-up. Four
                  (7.8%) of the patients underwent additional operations during the follow-up period. These included 2
                  cases each of lumbar spinal canal stenosis and worsening symptoms due to preexisting cervical OPLL (<a
                    href="" data-id="figure_reference_15" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>). Physical function in these cases with postoperative
                  complications or additional complications remained stable over the decade.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Postoperative Complications (with or without
                          Reoperation) and Additional Operations by ≥10-Year Follow-up (N = 51 Patients with
                          T-OPLL)<span data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1180px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">%
                          (No.)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Surgical
                          Procedure</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total % (no.) with
                          complication (with or without reoperation) or additional operation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.3%
                          (18)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complication with or
                          without reoperation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.5%
                          (14)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complication with
                          reoperation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.7%
                          (8)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Perioperative
                          reoperation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.8%
                          (6)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Paralysis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.9%
                          (3)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anterior
                          decompression</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Surgical site
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9%
                          (2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Debridement</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Hematoma</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0%
                          (1)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Hematoma removal</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Later reoperation</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9%
                          (2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Adjacent progression of
                          caudal OLF</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9%
                          (2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior decompression
                          and fusion</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complication without
                          reoperation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.8%
                          (6)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Distal junctional
                          failure</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.8%
                          (4)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Implant failure</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9%
                          (2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Additional
                          operation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.8%
                          (4)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preexisting cervical
                          OPLL</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9%
                          (2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Cervical
                          laminoplasty</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lumbar spinal canal
                          stenosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9%
                          (2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior decompression
                          and fusion</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the percentage (number) of patients. T-OPLL =
                        thoracic ossification of the posterior longitudinal ligament, OLF = ossification of the
                        ligamentum flavum, and cervical OPLL = cervical ossification of the posterior longitudinal
                        ligament.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Illustrative
                Cases</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Case
                1</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">A 42-year-old male patient with T-OPLL underwent T1-T5
                  decompression for gait issues, and later presented with cervical OPLL, T7-T8 OPLL, and T6-T7 OLF (<a
                    href="" data-id="figure_reference_16" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 8-A</a>). He underwent C3-C6 vertebroplasty, C7 laminectomy, T5-T8
                  posterior decompression, and T1-T9 fixation with instrumentation at our hospital (<a href=""
                    data-id="figure_reference_17" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 8-B</a>). Seventeen years later, he developed gait disturbances and
                  T9-T11 OLF (<a href="" data-id="figure_reference_18"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 8-C</a>),
                  necessitating T1-T12 fixation and T9-T11 decompression (<a href="" data-id="figure_reference_19"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 8-D</a>).
                  Initially, his JOA score of 4 improved to 6 and 7 at 6 months and 1 year, respectively, with recovery
                  rates of 28.6% and 42.9%. Before reoperation, his JOA score was 5, improving to 7 six months
                  post-reoperation, the same as before the worsening of symptoms.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 8</div>
                <div class="image-download" name="JBJS.23.01475f8" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03633c20-2304-4f03-a4a4-24de05fb8e21&type=jpeg&name=JBJS.23.01475f8"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;">Case 1: CT scan of a 42-year-old male patient with
                          cervical OPLL, T7-T8 OPLL, and T6-T7 OLF (<span data-id="strong_48" class="annotation strong"
                            style="position: unset;">Fig. 8-A</span>), who underwent C3-C6 vertebroplasty, C7
                          laminectomy, T5-T8 posterior decompression, and T1-T9 fixation with instrumentation at our
                          hospital (<span data-id="strong_49" class="annotation strong" style="position: unset;">Fig.
                            8-B</span>). The CT image at 17 years after surgery shows the T9-T11 OLF at the lower end of
                          fixation (<span data-id="strong_50" class="annotation strong" style="position: unset;">Fig.
                            8-C</span>, white circle). CT after reoperation shows T1-T12 fixation (extended fixation)
                          and T9-T11 decompression (<span data-id="strong_51" class="annotation strong"
                            style="position: unset;">Fig. 8-D</span>; the white circle shows the decompression level).
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Case
                2</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">A 68-year-old female patient with C3-C6 and T1-T8 OPLL and
                  myelopathy (<a href="" data-id="figure_reference_20"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 9-A</a>)
                  underwent C3-C6 laminoplasty and T1-T10 posterior decompression and fixation with instrumentation (<a
                    href="" data-id="figure_reference_21" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 9-B</a>). Three weeks postoperatively, a T10 vertebral fracture was
                  observed at the lower end of the fixation (<a href="" data-id="figure_reference_22"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 9-C</a>).
                  Osseous fusion was achieved after conservative treatment and rest (<a href=""
                    data-id="figure_reference_23" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 9-D</a>). Her JOA score improved from 3 to 9 and 10 at 6 months and 1
                  year, respectively, with recovery rates of 75.0% and 87.5%.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 9</div>
                <div class="image-download" name="JBJS.23.01475f9" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03633c20-2304-4f03-a4a4-24de05fb8e21&type=jpeg&name=JBJS.23.01475f9"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_14" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_40" style="position: unset;">
                        <div class="content" style="position: unset;">Case 2: CT was performed for a 68-year-old female
                          patient with OPLL at C3-C6 and T1-T8 and progressive myelopathy (<span data-id="strong_52"
                            class="annotation strong" style="position: unset;">Fig. 9-A</span>). The patient underwent
                          C3-C6 laminoplasty and T1-T10 posterior decompression and fixation with instrumentation (<span
                            data-id="strong_53" class="annotation strong" style="position: unset;">Fig. 9-B</span>). CT
                          at 3 weeks postoperatively revealed a T10 vertebral fracture at the lower end of the fixation
                          (<span data-id="strong_54" class="annotation strong" style="position: unset;">Fig. 9-C</span>,
                          white circle). CT at 7 months postoperatively revealed a fused T10 vertebral fracture (<span
                            data-id="strong_55" class="annotation strong" style="position: unset;">Fig. 9-D</span>,
                          white circle). CT at 10 years postoperatively (<span data-id="strong_56"
                            class="annotation strong" style="position: unset;">Fig. 9-E</span>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">We assessed the long-term outcomes of posterior
                  decompression and fusion surgery for T-OPLL using 10-year follow-up data to assess neurological
                  function, QoL, and pain reduction. Our findings indicate improvements in comfort and function. It is
                  important to consider how the improvements in the JOA, EQ-5D-5L, and NRS scores in this study are
                  positioned relative to other patient groups who have undergone similar surgeries.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">The improved EQ-5D-5L and NRS scores postoperatively
                  indicate enhanced QoL after thoracic decompression and fusion surgery for T-OPLL. Patient-reported
                  outcomes are crucial for evaluating clinical outcomes. Regarding cervical OPLL, a study reported EQ-5D
                  improvement<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">25</span></a> and
                  significant improvements in NRS scores for neck and upper-extremity pain<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">26</span></a>. The long-term QoL and pain outcomes of T-OPLL (with a high
                  incidence of postoperative complications<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">13</span></a>) in
                  this study are similar to the short-term QoL and pain outcomes of cervical OPLL, suggesting that
                  posterior decompression and fusion for T-OPLL maintains good long-term outcomes for 10 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">The improved JOA scores postoperatively indicate sustained
                  neurological and functional improvement from surgery over the long term and provide longer follow-up
                  data compared with previous studies reporting postoperative JOA score improvements in patients with
                  T-OPLL<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">27</span></a>.
                  Previous long-term follow-up studies for cervical OPLL revealed a decrease in the JOA score<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">28</span></a>. In the current study, the JOA score peaked at 2 years
                  postoperatively and then slightly declined, possibly because of additional surgeries for age-related
                  degenerative spinal diseases not directly related to T-OPLL.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Notably, the changes in the kyphotic angle preoperatively,
                  immediately postoperatively, and at 10 years postoperatively, the presence or absence of ossification
                  progression, and the occurrence of proximal junctional kyphosis, proximal junctional failure, distal
                  junctional kyphosis, or distal junctional failure are essential for assessing structural changes and
                  long-term stability from surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Regarding the presence or absence of ossification
                  progression, the area of ossification at the most stenotic level had increased significantly at 2
                  years postoperatively and then remained stable thereafter. Achievement of osseous fusion of the
                  ossified area may have contributed to the lack of ossification progression after 2 years
                  postoperatively, consistent with previous study results<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">14</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">In this study, proximal and distal junctional kyphosis
                  were absent, but distal junctional failure and adjacent-segment disorders at the caudal end were
                  observed, with cases of distal junctional failure occurring within 6 months post-surgery, highlighting
                  the need for vigilance until osseous fusion is achieved. Conservative treatment yielded positive
                  outcomes for all cases of distal junctional failure, aligning with previous findings on disorders at
                  the caudal end of the fixation<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">19</span></a>. In
                  most cases, the UIV was located in the upper thoracic spine (C7-T4). Patients with T-OPLL, especially
                  those with OPLL in the upper thoracic region, often present with concurrent cervical OPLL<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">29</span></a>. This co-occurrence leads to reduced mobility at the
                  cervicothoracic junction, which is considered a contributing factor to the low incidence of proximal
                  junctional failure. In contrast, in most cases, the LIV is located in the lower thoracic spine (T9 or
                  below), which is part of the thoracolumbar junction. The thoracic spine forms a junction with the
                  rigid rib cage and flexible lumbar spine, leading to increased mechanical stress at the thoracolumbar
                  junction when it serves as the LIV<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">30</span></a>.
                  Additionally, intervertebral mobility at the thoracolumbar junction is generally greater than that in
                  other spine regions, suggesting that having this level as the LIV further increases mechanical stress
                  distally<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">15</span></a>.
                  The application of thoracic dekyphosis during surgery can potentially lead to increased distal
                  loading. These factors may be associated with the occurrence of distal junctional kyphosis. OLF in 5
                  cases was noted at the caudal end of the fixation within 2 years, primarily affecting the lower
                  thoracic region due to its increased mechanical stress<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_39"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">31</span></a>. Only 2 (3.9%) of the patients underwent surgery for caudal
                  OLF, whereas the others had otherwise progressed uneventfully. Ossification itself may gradually
                  increase in some cases, and careful follow-up is necessary for cases in which ossification develops
                  within 2 years after surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">Our imaging results indicate that surgical treatment for
                  T-OPLL has a long-term effect on the stability and inhibition of ossification progression in the
                  operated thoracic spine, with little reoperation risk. Although there were reoperations associated
                  with distal-end OLF, distal junctional failure did not lead to reoperations, and QoL and pain remained
                  improved for ≥10 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">Reoperation incidence is a key metric for assessing the
                  long-term success of surgical treatment for T-OPLL. We identified 12 spinal surgeries during the
                  follow-up period, including 8 reoperations, with 2 addressing distal-end OLF through extended
                  fixation. Additionally, 4 surgeries unrelated to the initial surgical site were performed, 2 each in
                  the cervical and lumbar regions. For the lumbar spine, a previous study of the long-term follow-up of
                  cervical spine surgery found that 2.5% (2 of 80) required additional lumbar spine surgery, indicating
                  that spinal disease at additional levels may develop with age<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">28</span></a>. In the current study, the rate of additional lumbar spine
                  surgery was 3.9% (2 of 51 cases), comparable with previous reports<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_41"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">20</span></a>. Cervical OPLL combined with T-OPLL is associated with a
                  higher cervical spine ossification of the posterior longitudinal ligament index<a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">32</span></a>, making it the more severe form of cervical OPLL. In the
                  current study, 51.0% of the patients who underwent surgery for T-OPLL also required surgery for
                  cervical OPLL. Performing surgery in both regions reduced the need for subsequent procedures, with
                  only 2 additional cervical spine surgeries in the 10-year follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">This study’s limitations include a small sample size,
                  potentially affecting the generalizability of the results, and selection bias due to only including
                  surgical patients, hindering comparison with nonsurgical cases. In addition, radiographic assessment
                  and measurements may have been subject to detection or measurement bias. The 10-year follow-up lacks
                  longer-term data, essential for fully understanding OPLL progression and treatment outcomes. Future
                  studies should include larger, multi-institutional efforts with diverse patient groups and include
                  nonsurgical controls for a comprehensive evaluation of surgical effectiveness.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, posterior decompression and fusion for
                  T-OPLL improved neurological function, QoL, and pain reduction in the long-term. Notable improvements
                  in JOA and EQ-5D-5L scores were demonstrated 10 years after surgery compared to preoperatively,
                  suggesting that surgery generally enhances function and comfort in patients’ daily lives. Some
                  patients experienced distal junctional failure or revision surgery for OLF at the distal end. However,
                  these complications and interventions did not substantially detract from the overall QoL improvement.
                  The imaging results showed minimal progression of ossification beyond 2 years postoperatively,
                  contributing to the long-term stability and structural improvements observed in the patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Nam DC</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Lee HJ</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Lee CJ</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Hwang SC</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Molecular Pathophysiology of
                  Ossification of the Posterior Longitudinal Ligament (OPLL)</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Biomol Ther (Seoul).</span> 2019 Jul
                1;27(4):342-48.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Molecular%20Pathophysiology%20of%20Ossification%20of%20the%20Posterior%20Longitudinal%20Ligament%20(OPLL)&as_occt=title&as_sauthors=%20%22DC%20Nam%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Matsunaga S</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Sakou T</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Epidemiology of ossification of
                  the posterior longitudinal ligament</span>. In: <span data-id="annotation_7" class="annotation"
                  style="position: unset;"> Yonenobu K</span>, <span data-id="annotation_8" class="annotation"
                  style="position: unset;"> Sakou T</span>, <span data-id="annotation_9" class="annotation"
                  style="position: unset;"> Ono K</span>, editors. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">OPLL, Ossification of the Longitudinal
                  Ligament.</span> Tokyo: Springer; 1997. p 3-17.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiology%20of%20ossification%20of%20the%20posterior%20longitudinal%20ligament&as_occt=title&as_sauthors=%20%22S%20Matsunaga%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Mori K</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Imai S</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Kasahara T</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Nishizawa K</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Mimura T</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Matsusue Y</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Prevalence, distribution, and
                  morphology of thoracic ossification of the posterior longitudinal ligament in Japanese: results of
                  CT-based cross-sectional study</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2014 Mar 1;39(5):394-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%2C%20distribution%2C%20and%20morphology%20of%20thoracic%20ossification%20of%20the%20posterior%20longitudinal%20ligament%20in%20Japanese%3A%20results%20of%20CT-based%20cross-sectional%20study&as_occt=title&as_sauthors=%20%22K%20Mori%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Ono M</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Russell WJ</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Kudo S</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Kuroiwa Y</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Takamori M</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Motomura S</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Murakami J</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Ossification of the thoracic
                  posterior longitudinal ligament in a fixed population. Radiological and neurological
                  manifestations</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Radiology.</span> 1982 May;143(2):469-74.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ossification%20of%20the%20thoracic%20posterior%20longitudinal%20ligament%20in%20a%20fixed%20population.%20Radiological%20and%20neurological%20manifestations&as_occt=title&as_sauthors=%20%22M%20Ono%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Ohtsuka K</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Terayama K</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Yanagihara M</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Wada K</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Kasuga K</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Machida T</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Furukawa K</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">An epidemiological survey on
                  ossification of ligaments in the cervical and thoracic spine in individuals over 50 years of
                  age</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Nihon
                  Seikeigeka Gakkai Zasshi.</span> 1986 Nov;60(11):1087-98.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20epidemiological%20survey%20on%20ossification%20of%20ligaments%20in%20the%20cervical%20and%20thoracic%20spine%20in%20individuals%20over%2050%20years%20of%20age&as_occt=title&as_sauthors=%20%22K%20Ohtsuka%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Hasegawa T.</span><span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The Essence of Clinical Practice
                  Guidelines for Ossification of Spinal Ligaments, 2019: 1. Epidemiology of OPLL</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Spine Surg Relat Res.</span>
                2021 Sep 27;5(5):318-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Essence%20of%20Clinical%20Practice%20Guidelines%20for%20Ossification%20of%20Spinal%20Ligaments%2C%202019%3A%201.%20Epidemiology%20of%20OPLL&as_occt=title&as_sauthors=%20%22T.%20Hasegawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Xu C</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Zhang Z</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Liu N</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Li L</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Zhong H</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Wang R</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Shi Q</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Zhang Z</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Wei L</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Hu B</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Zhang H</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Shen X</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Liu Y</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Yuan W</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Small extracellular
                  vesicle-mediated miR-320e transmission promotes osteogenesis in OPLL by targeting TAK1</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Nat Commun.</span> 2022 May
                5;13(1):2467.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Small%20extracellular%20vesicle-mediated%20miR-320e%20transmission%20promotes%20osteogenesis%20in%20OPLL%20by%20targeting%20TAK1&as_occt=title&as_sauthors=%20%22C%20Xu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Barnett GH</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Hardy RW Jr</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Little JR</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Bay JW</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Sypert GW</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Thoracic spinal canal
                  stenosis</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J
                  Neurosurg.</span> 1987 Mar;66(3):338-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Thoracic%20spinal%20canal%20stenosis&as_occt=title&as_sauthors=%20%22GH%20Barnett%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Dommisse GF</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The blood supply of the spinal
                  cord. A critical vascular zone in spinal surgery</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 1974
                May;56(2):225-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20blood%20supply%20of%20the%20spinal%20cord.%20A%20critical%20vascular%20zone%20in%20spinal%20surgery&as_occt=title&as_sauthors=%20%22GF%20Dommisse%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Aizawa T</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Sato T</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Sasaki H</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Matsumoto F</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Morozumi N</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Kusakabe T</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Itoi E</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Kokubun S</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Results of surgical treatment
                  for thoracic myelopathy: minimum 2-year follow-up study in 132 patients</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J Neurosurg Spine.</span>
                2007 Jul;7(1):13-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Results%20of%20surgical%20treatment%20for%20thoracic%20myelopathy%3A%20minimum%202-year%20follow-up%20study%20in%20132%20patients&as_occt=title&as_sauthors=%20%22T%20Aizawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Matsumoto M</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Chiba K</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Toyama Y</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Takeshita K</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Seichi A</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Nakamura K</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Arimizu J</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Fujibayashi S</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Hirabayashi S</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Hirano T</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Iwasaki M</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Kaneoka K</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Kawaguchi Y</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Ijiri K</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Maeda T</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Mikami Y</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Murakami H</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Nagashima H</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Nagata K</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Nakahara S</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Nohara Y</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Oka S</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Sakamoto K</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Saruhashi Y</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Sasao Y</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Shimizu K</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Taguchi T</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Takahashi M</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Tanaka Y</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Tani T</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Tokuhashi Y</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Uchida K</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Yamamoto K</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Yamazaki M</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Yokoyama T</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Yoshida M</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Nishiwaki Y</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Surgical results and related
                  factors for ossification of posterior longitudinal ligament of the thoracic spine: a
                  multi-institutional retrospective study</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2008 Apr
                20;33(9):1034-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20results%20and%20related%20factors%20for%20ossification%20of%20posterior%20longitudinal%20ligament%20of%20the%20thoracic%20spine%3A%20a%20multi-institutional%20retrospective%20study&as_occt=title&as_sauthors=%20%22M%20Matsumoto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Sakai Y</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Katayama Y</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Imagama S</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Ito Z</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Wakao N</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Yukawa Y</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Ito K</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Kamiya M</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Kanemura T</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Sato K</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Ishiguro N</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Indirect posterior
                  decompression with corrective fusion for ossification of the posterior longitudinal ligament of the
                  thoracic spine: is it possible to predict the surgical results?</span><span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2009
                Jul;18(7):943-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Indirect%20posterior%20decompression%20with%20corrective%20fusion%20for%20ossification%20of%20the%20posterior%20longitudinal%20ligament%20of%20the%20thoracic%20spine%3A%20is%20it%20possible%20to%20predict%20the%20surgical%20results%3F&as_occt=title&as_sauthors=%20%22Y%20Matsuyama%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Imagama S</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Ando K</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Takeuchi K</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Kato S</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Murakami H</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Aizawa T</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Ozawa H</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Hasegawa T</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Koda M</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Yamazaki M</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Chikuda H</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Shindo S</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Nakagawa Y</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Kimura A</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Takeshita K</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Wada K</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Katoh H</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Watanabe M</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Yamada K</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Furuya T</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Tsuji T</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Fujibayashi S</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Mori K</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Kawaguchi Y</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Watanabe K</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Matsumoto M</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Yoshii T</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Okawa A</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Perioperative Complications
                  After Surgery for Thoracic Ossification of Posterior Longitudinal Ligament: A Nationwide Multicenter
                  Prospective Study</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2018 Dec 1;43(23):E1389-97.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perioperative%20Complications%20After%20Surgery%20for%20Thoracic%20Ossification%20of%20Posterior%20Longitudinal%20Ligament%3A%20A%20Nationwide%20Multicenter%20Prospective%20Study&as_occt=title&as_sauthors=%20%22S%20Imagama%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Ando K</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Imagama S</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Ito Z</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Kobayashi K</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Ukai J</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Muramoto A</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Shinjo R</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Matsumoto T</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Nakashima H</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Ishiguro N</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Radiologic evaluation after
                  posterior instrumented surgery for thoracic ossification of the posterior longitudinal ligament: union
                  between rostral and caudal ossifications</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">J Spinal Disord Tech.</span> 2014
                May;27(3):181-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiologic%20evaluation%20after%20posterior%20instrumented%20surgery%20for%20thoracic%20ossification%20of%20the%20posterior%20longitudinal%20ligament%3A%20union%20between%20rostral%20and%20caudal%20ossifications&as_occt=title&as_sauthors=%20%22K%20Ando%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Yamazaki M</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Mochizuki M</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Ikeda Y</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Sodeyama T</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Okawa A</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Koda M</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Moriya H</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Clinical results of surgery for
                  thoracic myelopathy caused by ossification of the posterior longitudinal ligament: operative
                  indication of posterior decompression with instrumented fusion</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2006 Jun
                1;31(13):1452-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20results%20of%20surgery%20for%20thoracic%20myelopathy%20caused%20by%20ossification%20of%20the%20posterior%20longitudinal%20ligament%3A%20operative%20indication%20of%20posterior%20decompression%20with%20instrumented%20fusion&as_occt=title&as_sauthors=%20%22M%20Yamazaki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Koda M</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Furuya T</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Okawa A</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Inada T</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Kamiya K</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Ota M</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Maki S</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Takahashi K</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Yamazaki M</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Aramomi M</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Ikeda O</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Mannoji C</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Mid- to long-term outcomes of
                  posterior decompression with instrumented fusion for thoracic ossification of the posterior
                  longitudinal ligament</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">J Clin Neurosci.</span> 2016 May;27:87-90.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mid-%20to%20long-term%20outcomes%20of%20posterior%20decompression%20with%20instrumented%20fusion%20for%20thoracic%20ossification%20of%20the%20posterior%20longitudinal%20ligament&as_occt=title&as_sauthors=%20%22M%20Koda%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Matsumoto M</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Toyama Y</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Chikuda H</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Takeshita K</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Kato T</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Shindo S</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Abumi K</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Takahata M</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Nohara Y</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Taneichi H</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Tomita K</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Kawahara N</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Imagama S</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Yamazaki M</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Okawa A</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Outcomes of fusion surgery for
                  ossification of the posterior longitudinal ligament of the thoracic spine: a multicenter retrospective
                  survey: clinical article</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">J Neurosurg Spine.</span> 2011 Oct;15(4):380-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20fusion%20surgery%20for%20ossification%20of%20the%20posterior%20longitudinal%20ligament%20of%20the%20thoracic%20spine%3A%20a%20multicenter%20retrospective%20survey%3A%20clinical%20article&as_occt=title&as_sauthors=%20%22M%20Matsumoto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Imagama S</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Ando K</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Ito Z</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Kobayashi K</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Hida T</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Ito K</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Ishikawa Y</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Tsushima M</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Matsumoto A</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Tanaka S</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Morozumi M</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Machino M</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Ota K</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Nakashima H</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Wakao N</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Nishida Y</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Ishiguro N</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Resection of Beak-Type Thoracic
                  Ossification of the Posterior Longitudinal Ligament from a Posterior Approach under Intraoperative
                  Neurophysiological Monitoring for Paralysis after Posterior Decompression and Fusion Surgery</span>.
                <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Global Spine J.</span>
                2016 Dec;6(8):812-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Resection%20of%20Beak-Type%20Thoracic%20Ossification%20of%20the%20Posterior%20Longitudinal%20Ligament%20from%20a%20Posterior%20Approach%20under%20Intraoperative%20Neurophysiological%20Monitoring%20for%20Paralysis%20after%20Posterior%20Decompression%20and%20Fusion%20Surgery&as_occt=title&as_sauthors=%20%22S%20Imagama%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Ando K</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Nakashima H</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Machino M</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Ito S</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Segi N</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Tomita H</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Koshimizu H</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Imagama S</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Postoperative progression of
                  ligamentum flavum ossification after posterior instrumented surgery for thoracic posterior
                  longitudinal ligament ossification: long-term outcomes during a minimum 10-year follow-up</span>.
                <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Neurosurg
                  Spine.</span> 2021 Dec 24;36(6):986-96.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postoperative%20progression%20of%20ligamentum%20flavum%20ossification%20after%20posterior%20instrumented%20surgery%20for%20thoracic%20posterior%20longitudinal%20ligament%20ossification%3A%20long-term%20outcomes%20during%20a%20minimum%2010-year%20follow-up&as_occt=title&as_sauthors=%20%22K%20Ando%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Maruyama J</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Furuya T</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Maki S</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Inoue T</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Yunde A</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Miura M</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Shiratani Y</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Nagashima Y</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Shiga Y</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Inage K</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Eguchi Y</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Orita S</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Takahashi H</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Koda M</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Yamazaki M</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Ohtori S</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Posterior Decompression and
                  Fixation for Thoracic Spine Ossification: A 10-Year Follow-Up Study</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J Clin Med.</span> 2023 Sep
                1;12(17):5701.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior%20Decompression%20and%20Fixation%20for%20Thoracic%20Spine%20Ossification%3A%20A%2010-Year%20Follow-Up%20Study&as_occt=title&as_sauthors=%20%22J%20Maruyama%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Finkelstein JA</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Schwartz CE</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Patient-reported outcomes in
                  spine surgery: past, current, and future directions</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">J Neurosurg Spine.</span> 2019 Aug
                1;31(2):155-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient-reported%20outcomes%20in%20spine%20surgery%3A%20past%2C%20current%2C%20and%20future%20directions&as_occt=title&as_sauthors=%20%22JA%20Finkelstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Hirabayashi K</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Miyakawa J</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Satomi K</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Maruyama T</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Wakano K</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Operative results and
                  postoperative progression of ossification among patients with ossification of cervical posterior
                  longitudinal ligament</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 1981 Jul-Aug;6(4):354-64.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Operative%20results%20and%20postoperative%20progression%20of%20ossification%20among%20patients%20with%20ossification%20of%20cervical%20posterior%20longitudinal%20ligament&as_occt=title&as_sauthors=%20%22K%20Hirabayashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Yonenobu K</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Abumi K</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Nagata K</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Taketomi E</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Ueyama K</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Interobserver and intraobserver
                  reliability of the Japanese Orthopaedic Association scoring system for evaluation of cervical
                  compression myelopathy</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2001 Sep 1;26(17):1890-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Interobserver%20and%20intraobserver%20reliability%20of%20the%20Japanese%20Orthopaedic%20Association%20scoring%20system%20for%20evaluation%20of%20cervical%20compression%20myelopathy&as_occt=title&as_sauthors=%20%22K%20Yonenobu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Yoshihara H</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Tsuji T</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Sakai Y</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Yukawa Y</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Nakamura H</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Ito K</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Ishiguro N</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Surgical outcome of
                  ossification of the posterior longitudinal ligament (OPLL) of the thoracic spine: implication of the
                  type of ossification and surgical options</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">J Spinal Disord Tech.</span> 2005
                Dec;18(6):492-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20outcome%20of%20ossification%20of%20the%20posterior%20longitudinal%20ligament%20(OPLL)%20of%20the%20thoracic%20spine%3A%20implication%20of%20the%20type%20of%20ossification%20and%20surgical%20options&as_occt=title&as_sauthors=%20%22Y%20Matsuyama%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Inose H</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Hirai T</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Yoshii T</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Kimura A</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Takeshita K</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Inoue H</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Maekawa A</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Endo K</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Furuya T</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Nakamura A</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Mori K</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Kanbara S</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Imagama S</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Seki S</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Matsunaga S</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Takahashi K</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Okawa A</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Predictors associated with
                  neurological recovery after anterior decompression with fusion for degenerative cervical
                  myelopathy</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">BMC Surg.</span> 2021 Mar 19;21(1):144.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictors%20associated%20with%20neurological%20recovery%20after%20anterior%20decompression%20with%20fusion%20for%20degenerative%20cervical%20myelopathy&as_occt=title&as_sauthors=%20%22H%20Inose%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Namgoong J</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Lee YH</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Ju AR</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Chai J</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Choi D</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Choi HJ</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Seo JY</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Park KS</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Lee YJ</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Lee J</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Ha IH</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Long-Term Follow-Up of Patients
                  with Neck Pain Associated with Ossification of the Posterior Longitudinal Ligament Treated with
                  Integrative Complementary and Alternative Medicine: A Retrospective Analysis and Questionnaire
                  Survey</span>. <span data-id="emphasis_26" class="annotation emphasis" style="position: unset;">J Pain
                  Res.</span> 2022 May 24;15:1527-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-Term%20Follow-Up%20of%20Patients%20with%20Neck%20Pain%20Associated%20with%20Ossification%20of%20the%20Posterior%20Longitudinal%20Ligament%20Treated%20with%20Integrative%20Complementary%20and%20Alternative%20Medicine%3A%20A%20Retrospective%20Analysis%20and%20Questionnaire%20Survey&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Imagama S</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Ando K</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Kobayashi K</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Hida T</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Ito K</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Tsushima M</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Ishikawa Y</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Matsumoto A</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Morozumi M</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Tanaka S</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Machino M</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Ota K</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Nakashima H</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Nishida Y</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Ishiguro N</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Factors for a Good Surgical
                  Outcome in Posterior Decompression and Dekyphotic Corrective Fusion with Instrumentation for Thoracic
                  Ossification of the Posterior Longitudinal Ligament: Prospective Single-Center Study</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Oper Neurosurg
                  (Hagerstown).</span> 2017 Dec 1;13(6):661-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20for%20a%20Good%20Surgical%20Outcome%20in%20Posterior%20Decompression%20and%20Dekyphotic%20Corrective%20Fusion%20with%20Instrumentation%20for%20Thoracic%20Ossification%20of%20the%20Posterior%20Longitudinal%20Ligament%3A%20Prospective%20Single-Center%20Study&as_occt=title&as_sauthors=%20%22S%20Imagama%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Chiba K</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Ogawa Y</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Ishii K</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Takaishi H</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Nakamura M</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Maruiwa H</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Matsumoto M</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Toyama Y</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Long-term results of expansive
                  open-door laminoplasty for cervical myelopathy—average 14-year follow-up study</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2006 Dec 15;31(26):2998-3005.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20results%20of%20expansive%20open-door%20laminoplasty%20for%20cervical%20myelopathy%E2%80%94average%2014-year%20follow-up%20study&as_occt=title&as_sauthors=%20%22K%20Chiba%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Hirai T</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Yoshii T</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Nagoshi N</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Takeuchi K</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Mori K</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Ushio S</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Iwanami A</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Yamada T</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Seki S</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Tsuji T</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Fujiyoshi K</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Furukawa M</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Nishimura S</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Wada K</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Furuya T</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Hasegawa T</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Takeshita K</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Kimura A</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Abematsu M</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Haro H</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Ohba T</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Watanabe M</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Katoh H</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Watanabe K</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Ozawa H</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Kanno H</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Imagama S</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Ando K</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Fujibayashi S</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Koda M</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Yamazaki M</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Matsumoto M</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Nakamura M</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Okawa A</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Kawaguchi Y</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Distribution of ossified spinal
                  lesions in patients with severe ossification of the posterior longitudinal ligament and prediction of
                  ossification at each segment based on the cervical OP index classification: a multicenter study (JOSL
                  CT study)</span>. <span data-id="emphasis_29" class="annotation emphasis" style="position: unset;">BMC
                  Musculoskelet Disord.</span> 2018 Apr 5;19(1):107.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Distribution%20of%20ossified%20spinal%20lesions%20in%20patients%20with%20severe%20ossification%20of%20the%20posterior%20longitudinal%20ligament%20and%20prediction%20of%20ossification%20at%20each%20segment%20based%20on%20the%20cervical%20OP%20index%20classification%3A%20a%20multicenter%20study%20(JOSL%20CT%20study)&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Okada K</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Oka S</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Tohge K</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Ono K</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Yonenobu K</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Hosoya T</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Thoracic myelopathy caused by
                  ossification of the ligamentum flavum. Clinicopathologic study and surgical treatment</span>. <span
                  data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 1991 Mar;16(3):280-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Thoracic%20myelopathy%20caused%20by%20ossification%20of%20the%20ligamentum%20flavum.%20Clinicopathologic%20study%20and%20surgical%20treatment&as_occt=title&as_sauthors=%20%22K%20Okada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Ando K</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Imagama S</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Kaito T</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Takenaka S</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Sakai K</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Egawa S</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Shindo S</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Watanabe K</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Fujita N</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Matsumoto M</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Nakashima H</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Wada K</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Kimura A</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Takeshita K</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Kato S</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Murakami H</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Takeuchi K</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Takahata M</span>, <span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Koda M</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Yamazaki M</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Watanabe M</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Fujibayashi S</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Furuya T</span>, <span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Kawaguchi Y</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Yoshii T</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Okawa A</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Outcomes of Surgery for
                  Thoracic Myelopathy Owing to Thoracic Ossification of The Ligamentum Flavum in a Nationwide
                  Multicenter Prospectively Collected Study in 223 Patients: Is Instrumented Fusion
                  Necessary?</span><span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2020 Feb 1;45(3):E170-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20Surgery%20for%20Thoracic%20Myelopathy%20Owing%20to%20Thoracic%20Ossification%20of%20The%20Ligamentum%20Flavum%20in%20a%20Nationwide%20Multicenter%20Prospectively%20Collected%20Study%20in%20223%20Patients%3A%20Is%20Instrumented%20Fusion%20Necessary%3F&as_occt=title&as_sauthors=%20%22K%20Ando%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Hirai T</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Yoshii T</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Iwanami A</span>, <span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Takeuchi K</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Mori K</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Yamada T</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Wada K</span>, <span
                  data-id="annotation_374" class="annotation" style="position: unset;"> Koda M</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Takeshita K</span>, <span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Abematsu M</span>, <span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Haro H</span>, <span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Watanabe M</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Watanabe K</span>, <span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Ozawa H</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Kanno H</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Imagama S</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Fujibayashi S</span>, <span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Yamazaki M</span>, <span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Matsumoto M</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Nakamura M</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Okawa A</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Kawaguchi Y</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Prevalence and Distribution of
                  Ossified Lesions in the Whole Spine of Patients with Cervical Ossification of the Posterior
                  Longitudinal Ligament A Multicenter Study (JOSL CT study)</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">PLoS One.</span> 2016 Aug
                22;11(8):e0160117.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20and%20Distribution%20of%20Ossified%20Lesions%20in%20the%20Whole%20Spine%20of%20Patients%20with%20Cervical%20Ossification%20of%20the%20Posterior%20Longitudinal%20Ligament%20A%20Multicenter%20Study%20(JOSL%20CT%20study)&as_occt=title&as_sauthors=%20%22T%20Hirai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D03633c20-2304-4f03-a4a4-24de05fb8e21%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">September 4, 2024;
                      106 (17): 1600</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01475</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">August 5, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_33"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopedic Surgery, Nagoya University Graduate School of Medicine, Nagoya,
                            Aichi, Japan</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;03633c20-2304-4f03-a4a4-24de05fb8e21&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=03633c20-2304-4f03-a4a4-24de05fb8e21&type=pdf&name=JBJS.23.01475.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=03633c20-2304-4f03-a4a4-24de05fb8e21&type=pdf&name=JBJS.23.01475.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_39" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I119"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I119</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;03633c20-2304-4f03-a4a4-24de05fb8e21&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=03633c20-2304-4f03-a4a4-24de05fb8e21&type=zip&name=JBJS.23.01475.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sadayuki Ito, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8806-2473" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8806-2473</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hiroaki Nakashima, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:hirospine@med.nagoya-u.ac.jp" class=""
                    style="position: unset;">hirospine@med.nagoya-u.ac.jp</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0039-9678" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0039-9678</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Naoki Segi, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9681-2422" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9681-2422</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jun Ouchida, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0003-3447-1628" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0003-3447-1628</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ryotaro Oishi, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-6036-3598" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-6036-3598</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ippei Yamauchi, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0004-3005-2570" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0004-3005-2570</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yuichi Miyairi, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0005-1894-8814" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0005-1894-8814</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yoshinori Morita, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7675-6985" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7675-6985</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yukihito Ode, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0005-5473-9817" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0005-5473-9817</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shiro Imagama, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6951-8575" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6951-8575</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Nagoya University Graduate School of
                Medicine, Nagoya, Aichi, Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 36801.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
