/* -----------------Analytics--------------- */
import type { LeaderboardItem } from "@features/dashboard/queries/schema";
import { getDisplayName } from "@helpers/user";
import { useAuthContext } from "@memorang/applets";
import { Box, LeaderboardPodium } from "@memorang/ui";

/* -----------------Child components--------------- */

type Props = {
	topRankers: LeaderboardItem[];
	avatarType?: "initials" | "identicon";
};

const TopRankersList = ({ topRankers, avatarType }: Props) => {
	const { newUserInfo } = useAuthContext();
	const role = newUserInfo?.profession;

	return (
		<Box
			flexDirection="row"
			justifyContent="center"
			alignItems="flex-end"
			width="100%"
		>
			{topRankers.map(({ username, xp, rank, avatar, firstName, lastName }) => (
				<LeaderboardPodium
					key={username}
					title={
						firstName || lastName
							? getDisplayName(role, firstName, lastName)
							: username
					}
					xp={xp}
					rank={rank!}
					image={avatar}
					avatarType={avatarType}
				/>
			))}
		</Box>
	);
};

export default TopRankersList;
