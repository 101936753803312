import type { Article } from "../../../../../types";

export const Article601: Article = {
	id: 601,
	rsuiteId: "4f9fc051-43cf-47d7-a2d2-d90c5747bb16",
	type: "the orthopaedic forum",
	title:
		"Academic and Demographic Profile of Orthopaedic Vice Chairs of Research",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4f9fc051-43cf-47d7-a2d2-d90c5747bb16&type=jpeg&name=JBJS.23.01296f1",
	subSpecialties: ["trauma"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Academic and Demographic Profile of Orthopaedic Vice
                Chairs of Research</div>
              <div class="text subtitle" style="position: unset;">Implications for Leadership</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Seetha
                      Aribindi, BA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Philipp
                      Leucht, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 2 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_16" class="annotation strong"
                          style="position: unset;">Background:</span> Vice chairs (VCs) of research play an integral
                        role in orthopaedic departments at academic medical centers; they strategically lead research
                        efforts and support the research careers of faculty and trainees. To our knowledge, no analysis
                        of orthopaedic VCs of research exists in the literature, and no similar analyses have been
                        completed in other medical specialties. We aimed to investigate the academic and demographic
                        characteristics of orthopaedic VCs of research.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_17" class="annotation strong"
                          style="position: unset;">Methods:</span> Doximity was used to identify orthopaedic residencies
                        in the U.S. Personal and program websites were queried to identify VCs of research and collect
                        academic and demographic characteristics. The Scopus database, the National Institutes of Health
                        (NIH) RePORTER, and Google Scholar were used to obtain each investigator’s Hirsch index
                        (h-index) and the number and type of NIH grants awarded, respectively.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_18" class="annotation strong"
                          style="position: unset;">Results:</span> Of the 207 orthopaedic residency programs identified,
                        71 (34%) had a named VC of research in the orthopaedic department. Of the top 50 medical
                        schools, 42 were affiliated with such programs. Most VCs were men (89%). The racial and/or
                        ethnic background of the majority of VCs was White (85%), followed by Asian (14%), and Black
                        (1%). Most held the rank of professor (78%), followed by associate professor (18%), and
                        assistant professor (4%). Over half were PhDs (55%), followed by MDs (37%) and MD/PhDs (8%). On
                        average, the VCs had an h-index of 40.5. Furthermore, 65% had been awarded at least 1 NIH grant
                        for their research, with 43% awarded at least 1 R01 grant.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_19" class="annotation strong"
                          style="position: unset;">Conclusions:</span> VCs of research develop research opportunities
                        and shape the brand recognition of academic orthopaedic programs. Most orthopaedic VCs of
                        research are men (89%); 85% each are White and have a rank of professor. Nearly half have been
                        awarded at least 1 R01 grant from the NIH.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_20" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> This study outlines important academic and
                        demographic characteristics among orthopaedic surgery VCs of research. Considering the
                        mentorship aspect of their role, VCs of research have an opportunity to influence the diversity
                        of incoming trainees in the field of academic orthopaedics.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Research plays a pivotal role in shaping the reputation of
                  academic orthopaedic surgery programs. Academic productivity has been shown to be associated with the
                  ranking of orthopaedic residencies<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. Vice
                  chairs (VCs) of research are central to driving research initiatives and fostering a culture of
                  academic excellence in these programs. These individuals hold key leadership positions, guiding the
                  strategic direction of scholarship and influencing the future trajectory of orthopaedic advancements<a
                    href="" data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Naming a VC of research is 1 strategy for programs to
                  increase research output by having an individual who is responsible for strategically reviewing the
                  department’s research portfolio in order to identify areas for growth<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">2</span></a>. Despite the integral role that VCs of research play, there
                  is an absence of studies exploring the academic characteristics and demographics of individuals named
                  to this position in all medical specialties, including orthopaedic surgery. A prior study evaluated
                  the role of and challenges faced by VCs of research in radiology<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">2</span></a>. A study of VCs is valuable, as individuals in this position
                  have a unique opportunity to shape the brand recognition of academic orthopaedic programs and create
                  opportunities to promote the advancement of orthopaedic trainees and academicians. Understanding the
                  profile of these leaders can offer valuable insights into the composition and dynamics of orthopaedic
                  academic institutions, highlighting trends in scholarly productivity, educational backgrounds, and
                  diversity representation. Such knowledge is essential for refining recruitment strategies, promoting
                  inclusivity, and optimizing research outcomes within these critical leadership roles.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The goal of this study was to investigate 3 objectives:
                  (1) Are there academic characteristics common to orthopaedic VCs of research? (2) Are there
                  demographic characteristics common to orthopaedic VCs of research? (3) Do orthopaedic VCs of research
                  lead their respective institution’s Center for Musculoskeletal Research? All 3 of the objectives were
                  achieved.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Doximity was used to identify U.S. orthopaedic residency
                  programs affiliated with an academic medical center<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">3</span></a>.
                  <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">U.S. News & World
                    Report</span> rankings were used to determine the primary medical school that is affiliated with
                  each medical center and its respective ranking<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">4</span></a>.
                  <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">U.S. News & World
                    Report</span> research rankings are a composite score composed of the following: total federal
                  research activity (30%); peer score, which reflects the school’s reputation among medical school deans
                  and senior faculty from other institutions (15%); residency director score (15%); median Medical
                  College Admission Test (MCAT) score (13%); average federal research activity per faculty member (10%);
                  faculty resources (10%); median undergraduate grade point average (GPA) (6%); and overall acceptance
                  rate (1%)<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">5</span></a>.
                  These rankings have been used previously in the orthopaedic literature<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">6</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">In June 2023, publicly available personal and orthopaedic
                  residency program websites were used to identify VCs of research. The inclusion criterion was a status
                  as VC of research at an allopathic or osteopathic orthopaedic residency program accredited by the
                  Accreditation Council for Graduate Medical Education (ACGME). The exclusion criterion was a residency
                  programs location outside of the United States. Individuals’ academic profile webpages were used to
                  determine sex, race and/or ethnicity, academic rank, type of degree, residency or postdoctoral
                  institution, fellowship institution, specialty within orthopaedics, and any additional degree(s).
                  Program websites were also used to identify VCs who served as directors of a Center for
                  Musculoskeletal Research or an equivalent research center that conducts basic and translational
                  research on a wide range of musculoskeletal disorders at their institution.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The Hirsch index (h-index), which measures the number of
                  an investigator’s publications that are cited ≥h times, was used to quantify academic productivity<a
                    href="" data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">7</span></a>. This index captures the quantity and quality of research
                  output and has been used previously in the orthopaedic literature<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_10" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">9</span></a>. The
                  Scopus database and Google Scholar were used to record the h-index of each VC of research as of July
                  2023. The number and type of National Institutes of Health (NIH) grants awarded to each VC of research
                  were identified using the NIH RePORTER. Notably, these metrics represent cumulative accomplishments up
                  to the date when the study was conducted.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Exact binomial and multinomial tests were employed to
                  assess sex and racial distributions among VCs, which were compared with expected values from the 2018
                  American Academy of Orthopaedic Surgeons (AAOS) Physician Census; a p value of 0.05 was considered to
                  be significant. Additionally, Fisher exact tests and t tests were utilized to compare sex and racial
                  distributions, as well as h-index scores, between VCs of research and other orthopaedic surgeons,
                  respectively; corrections for multiple comparisons were applied using q values.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Program
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Our analysis of 207 current U.S. orthopaedic residency
                  programs found that 34% had appointed a VC of research for their department. Of the top 50 medical
                  schools, as designated by the <span data-id="emphasis_17" class="annotation emphasis"
                    style="position: unset;">U.S. News & World Report</span> rankings, 42 were affiliated with
                  orthopaedic residency programs that had a VC of research. Similarly, among the top 20 medical schools,
                  18 were affiliated with orthopaedic residency programs that had a VC of research.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Demographic
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Information was collected on 71 orthopaedic VCs of
                  research who met our inclusion criterion. An analysis of diversity among VCs in 2023 revealed that 63
                  (89%) were male and 8 (11%) were female faculty members. Of the 71 total, 60 (85%) were White, 10
                  (14%) were Asian, and 1 (1%) was Black. None of the VCs identified as other (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographic Characteristics of the 71 Orthopaedic VCs
                          of Research</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 362px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Percentage
                          [95% Exact CI]</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88.7%
                          [79.0%, 95.0%]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.3%
                          [4.99%, 21.0%]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Race</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> White</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84.5%
                          [74.0%, 92.0%]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Asian</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.1%
                          [6.97%, 24.4%]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Black or African
                          American</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.4%
                          [0.04%, 7.60%]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0% [0%,
                          5.06%]</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Academic
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Over half of the VCs were PhDs (55%), followed by MDs
                  (37%) and MD/PhDs (8%). Notably, none of the VCs had graduated from osteopathic (DO) medical programs.
                  Approximately 19% possessed additional graduate degrees besides an MD, PhD, or MD/PhD, with the most
                  common degrees being Doctor of Physical Therapy (DPT) or Master of Business Administration (MBA).
                  Analysis of the sex breakdown revealed notable differences across education types among VCs of
                  research. Specifically, 7 (88%) of 8 female VCs compared with 51% of male VCs held only PhDs. Notably,
                  only male VCs of research held MD/PhDs (9%) (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). The
                  majority of all VCs held the academic rank of professor (78%), followed by associate professor (18%)
                  and assistant professor (4%). Only 8 (11%) led a Center for Musculoskeletal Research at their
                  institution.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01296f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4f9fc051-43cf-47d7-a2d2-d90c5747bb16&type=jpeg&name=JBJS.23.01296f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;">Differences in degree representation by sex among
                          orthopaedic VCs of research.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The distribution of VCs revealed varied representation
                  across orthopaedic subspecialties. Among the VCs with MDs and MD/PhDs, the subspecialties were
                  distributed as follows: sports medicine had the highest representation (n = 7), followed by adult
                  reconstruction (n = 6), trauma surgery (n = 6), spine surgery (n = 5), hand surgery (n = 3), oncology
                  (n = 2), pediatrics (n = 2), and no specified subspecialty (n = 2).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">None of the VCs had completed their orthopaedic residency
                  at the same program. However, 5 orthopaedic fellowship programs had trained ≥2 VCs. These included
                  Washington University Hospital (n = 3), Brigham and Women’s Hospital (n = 2), the University of
                  Pittsburgh (n = 2), the University of Virginia (n = 2), and the University of Wisconsin (n = 2). Of
                  note, these programs all currently have a VC of research appointed in the Department of Orthopaedics.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Research
                Productivity</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Among 71 orthopaedic VCs, the mean h-index was 40.48
                  (standard deviation [SD] = 22.75. Approximately one-third of VCs had an h-index of 29 to 43 (<a
                    href="" data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). Among the VCs, 10 individuals demonstrated the highest
                  scholarly productivity, with a mean h-index of 81.70 (SD = 7.48) and h-indices ranging from 65 to 100
                  within this subset.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01296f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4f9fc051-43cf-47d7-a2d2-d90c5747bb16&type=jpeg&name=JBJS.23.01296f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Hirsch index (h-index) distribution among
                          orthopaedic VCs of research. Recorded values span from 4 to 100, with an average h-index of
                          40.5.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Furthermore, 65% had been awarded at least 1 NIH grant for
                  their research. Among the 275 total NIH grants that were awarded, 119 (43%) were R01s, 34 (12%) were
                  R21s, 11 (4%) were R13s, and 6 (2%) were R03s (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>; see also
                  the Appendix). Forty-three percent of the VCs had been awarded at least 1 R01.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01296f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4f9fc051-43cf-47d7-a2d2-d90c5747bb16&type=jpeg&name=JBJS.23.01296f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Count of NIH grants awarded to orthopaedic VCs of
                          research to date, by grant mechanism: R01, R03, R13, and R21.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, this is the first study investigating
                  the demographic and academic characteristics of VCs of research in orthopaedics. The role of VCs in
                  orthopaedics has been under-studied. This omission is surprising, given that VCs have a unique
                  opportunity to influence the future of the specialty by mentoring trainees and developing
                  opportunities for them to participate in novel research that advances the field<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">2</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Regarding program characteristics, we observed that
                  approximately one-third (34%) of surveyed orthopaedic residency programs had appointed a VC of
                  research. Among the top 20 medical schools, a sizable proportion (90%) were associated with
                  orthopaedic residency programs that had a VC of research. Similarly, among the top 50 medical schools,
                  42 (84%) were associated with orthopaedic residency programs that had a VC of research. These results
                  indicate a stronger presence of these leadership positions at prestigious institutions. This has
                  implications for the overall brand recognition of orthopaedic programs, since previous research
                  indicates an association between holding an orthopaedic leadership position and having received
                  training at prestigious programs<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">6</span></a>.
                  Although it was not explored in this study, future research should investigate the relationship
                  between academic productivity and the presence of a VC of research in the Department of Orthopaedics.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The demographics of orthopaedic VCs were as follows; the
                  majority were male (89%) and White (85%). Little research exists on how these demographics compare
                  with VCs in other specialties. However, insights gleaned from the AAOS Orthopaedic Physician Census
                  serve as a foundation for comparing the demographic makeup of orthopaedic VCs to that of the wider
                  population of orthopaedic surgeons. According to the 2018 census data, 92.4% of orthopaedic surgeons
                  identified as male and 7.6% identified as female<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">10</span></a>. Of
                  the 71 VCs in our study, 63 were male and 8 were female, compared with the expected 66 males and 5
                  females using the AAOS census percentages. There was not a significant difference between the observed
                  and expected percentages (p = 0.257). <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a> contains
                  the percentages and 95% exact confidence intervals (CIs) for the race and sex of the VCs of research.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">According to the census data, racial demographics were
                  distributed as follows: 84.7% identified as White, 6.7% as Asian, 1.9% as Black, and 6.7% as belonging
                  to another racial category<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">10</span></a>.
                  There was a significant difference between the observed and expected racial distributions of VCs (p =
                  0.008). However, after adjusting for multiple comparisons, no specific racial category was
                  significantly different from what was expected. Of the 71 VCs, 60 were White, 10 were Asian, 1 was
                  Black, and there were none of another race, compared with the expected 60 White (q &gt; 0.999), 5
                  Asian (q = 0.054), 1 Black (q &gt;0.999), and 5 of another race (q = 0.054), as demonstrated in the
                  census data.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Prior studies evaluating the academic and demographic
                  characteristics of orthopaedic spine surgery division chiefs (SSDCs) and sports medicine division
                  chiefs (SMDCs) were used to assess how the VCs compared with other members of academic orthopaedic
                  leadership<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">12</span></a>. The comparison groups consisted of 102 SSDCs and 100
                  SMDCs. There were 101 male (99%) and 1 female (1%) SSDCs. There were 96 male (96%) and 4 female (4%)
                  SMDCs. Significantly more of the VCs were female compared with the SSDCs (p = 0.004). However, there
                  was not a significant difference in sex between VCs and SMDCs (p = 0.077). Seventy-four (72.5%) of the
                  SSDCs were White, 23 (22.5%) were Asian, 2 (2.0%) were Black, and 3 (2.9%) were of another or unknown
                  race<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">11</span></a>.
                  Eighty-six (86%) of the SMDCs were White, 11 (11%) were Asian, 1 (1%) was Black, and 2 (2%) were of
                  another race<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">12</span></a>.
                  There was not a significant difference in race between the VCs and SSDCs (p = 0.241) or between the
                  VCs and SMDCs (p = 0.785). While these results highlight that there may be a higher degree of sex
                  diversity among VCs relative to other leadership positions, there is still room for improvement in
                  racial representation in academic orthopaedics.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">In terms of academic characteristics, the majority (55%)
                  of VCs were PhDs. Of note, the analysis of the sex breakdown revealed notable differences in education
                  types, with a higher proportion of female VCs (88%) compared with male VCs (51%) holding only doctoral
                  degrees (PhDs). Moreover, only 1 female VC held an MD degree, suggesting potential disparities in the
                  educational pathways leading to these leadership positions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">A previous study examining pipeline programs found that
                  young orthopaedic surgeons who participated in programs created to address the decline in
                  clinician-researchers and engaged in career-impactful opportunities afterward demonstrated higher
                  scholarly activity and impact<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">9</span></a>.
                  Given that pipeline programs appear to play an important role in promoting the careers of
                  clinician-researchers in orthopaedic surgery, this approach may be adapted to advance female MDs who
                  are well-suited to take on the role of VC of research. This may be beneficial, as demonstrated from a
                  previous study analyzing authorship trends in major orthopaedic journals from 1987 to 2017 that found
                  that the growth rate of women in senior authorship had increased at a significantly slower pace than
                  the growth rate of practicing women orthopaedic surgeons<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">13</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">A substantial proportion (78%) of VCs held the title of
                  professor, signifying their seniority and established academic careers. A small percentage (11%) of
                  VCs led a Center for Musculoskeletal Research at their respective institutions. While it is unclear
                  why this percentage is so small, this likely reflects the selectiveness of such appointments. We
                  identified 5 orthopaedic fellowship programs that had trained ≥2 VCs, highlighting the potential
                  impact of specific fellowship training in preparing future leaders in orthopaedic research.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">In terms of research productivity, our study found that
                  orthopaedic VCs demonstrated an average h-index of 40.5. As noted in previous literature, the h-index
                  is most valuable for comparing individuals within the same specialty<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">14</span></a>. While there was limited information available on the
                  h-index values for SMDCs (mean = 22.1), the mean h-index was significantly higher for VCs compared
                  with SSDCs (mean = 21.7, SD = 19.1) (t[133.8] = 5.7, p &lt; 0.001)<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">12</span></a>. This substantial difference in h-indices emphasizes the
                  elevated research productivity of VCs in academic orthopaedic leadership. These results illustrate the
                  importance of high research productivity among individuals appointed to this role.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">The majority of VCs (65%) had been awarded at least 1
                  grant from the NIH. Particularly noteworthy was the fact that nearly half of the VCs (43%) had been
                  successful in obtaining R01 grants, which are considered prestigious and highly competitive funding
                  opportunities. While these results suggest that VCs are successful in securing NIH grants, the current
                  literature suggests that funding to orthopaedics lags behind funding to other surgical
                  subspecialties<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">15</span></a>. A
                  study on the funding of orthopaedic departments revealed that NIH research funding has not kept pace
                  with the growing burden of musculoskeletal disease. This discrepancy is particularly apparent as NIH
                  funding for other conditions continues to rise despite the relatively higher burden of musculoskeletal
                  disease<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">16</span></a>.
                  These findings underscore the need for greater NIH support for orthopaedic VCs of research, who play a
                  pivotal role in addressing the mounting health and economic challenges posed by musculoskeletal
                  disease.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">This study has several limitations. First, the inability
                  to locate all VCs due to limited up-to-date information on publicly available websites may have
                  introduced selection bias. Second, our study design was cross-sectional, capturing data at a specific
                  point in time. Consequently, collected metrics such as the h-index and number of NIH grants, which are
                  subject to continual change, may not entirely reflect the qualifications possessed by VCs at the time
                  of their appointment. Third, we identified race and/or ethnicity using the country of origin of the
                  last names of the VCs, supplemented by their profile pictures for confirmation. While this allowed us
                  to assess the demographics of VCs, it may not fully reflect an individual’s self-identified race
                  and/or ethnicity, introducing potential discrepancies in the reported data. Moreover, to appropriately
                  contextualize the demographics of VCs of research, it would be ideal to compare their demographics
                  with those of other full-time faculty members. It is important to note this as a limitation since
                  demographic data for other faculty members are not publicly available. Another limitation of this
                  study was the small numbers for categorical data, which made the analyses vulnerable to statistical
                  error.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Several findings reported in this paper identify the key
                  characteristics of orthopaedic VCs of research. The majority of individuals in this role have the
                  academic rank of professor and are likely to have received at least 1 NIH grant. A small percentage
                  led a Center for Musculoskeletal Research at their institution. Additional initiatives can be
                  considered to promote the advancement of female and underrepresented individuals in medicine into this
                  role. Given the mentorship component of this position, faculty members in this role may have the
                  opportunity to increase diversity awareness and recruitment within academic orthopaedics.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_6" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I135" target="_blank" data-id="link_7" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I135</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Meade PJ</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Amin SJ</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Stamm MA</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Mulcahey MK</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Doximity Orthopaedic Surgery
                  Program Rankings Are Associated with Academic Productivity</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">JB JS Open Access.</span> 2023 Jan
                6;8(1):48-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Doximity%20Orthopaedic%20Surgery%20Program%20Rankings%20Are%20Associated%20with%20Academic%20Productivity&as_occt=title&as_sauthors=%20%22PJ%20Meade%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Krupinski EA</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Carr JC</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Sullivan DC</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Vice Chairs for Research: What
                  They Do & Challenges They Face</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Med Res Arch.</span> 2020;8(1).</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vice%20Chairs%20for%20Research%3A%20What%20They%20Do%20%26%20Challenges%20They%20Face&as_occt=title&as_sauthors=%20%22EA%20Krupinski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;">Doximity.
                <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Orthopaedic Surgery
                  Residency Programs.</span> Accessed 2023 Jul 25. <a
                  href="https://www.doximity.com/residency/specialties/bd234238-6960-4260-9475-1fa18f58f092-orthopaedic-surgery"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.doximity.com/residency/specialties/bd234238-6960-4260-9475-1fa18f58f092-orthopaedic-surgery</a>.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;">U.S. News &
                World Report. <span data-id="strong_3" class="annotation strong" style="position: unset;">2023-2024 Best
                  Medical Schools (Research)</span>. Accessed 2023 Jul 25. <a
                  href="https://www.usnews.com/best-graduate-schools/top-medical-schools/research-rankings"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://www.usnews.com/best-graduate-schools/top-medical-schools/research-rankings</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=2023-2024%20Best%20Medical%20Schools%20(Research)&as_occt=title&as_sauthors=%20%22U.S.%20News%20%26%20World%20Report%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;">U.S. News &
                World Report. <span data-id="strong_4" class="annotation strong" style="position: unset;">Methodology:
                  2023 Best Medical Schools Rankings</span>. Accessed 2023 Jul 25. <a
                  href="https://www.usnews.com/education/best-graduate-schools/articles/medical-schools-methodology"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://www.usnews.com/education/best-graduate-schools/articles/medical-schools-methodology</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Methodology%3A%202023%20Best%20Medical%20Schools%20Rankings&as_occt=title&as_sauthors=%20%22U.S.%20News%20%26%20World%20Report%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Elkadi SH</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Donaldson S</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Krisanda E</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Kessler MW</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Trends in Medical Training and
                  Leadership at Academic Orthopedic Programs</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Cureus.</span> 2022 Sep
                13;14(9):e29100.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20in%20Medical%20Training%20and%20Leadership%20at%20Academic%20Orthopedic%20Programs&as_occt=title&as_sauthors=%20%22SH%20Elkadi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Hirsch JE</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">An index to quantify an
                  individual’s scientific research output</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Proc Natl Acad Sci U S A.</span> 2005 Nov 15;102(46):16569-72.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20index%20to%20quantify%20an%20individual%E2%80%99s%20scientific%20research%20output&as_occt=title&as_sauthors=%20%22JE%20Hirsch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Bastian S</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Ippolito JA</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Lopez SA</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Eloy JA</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Beebe KS</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">The Use of the h-Index in
                  Academic Orthopaedic Surgery</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2017 Feb 15;99(4):e14.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1272890" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Bernstein DN</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Lawson M</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Menga EN</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> O’Keefe RJ</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Rubery PT</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Mesfin A</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Scholarly Success of Orthopaedic
                  Surgeons Participating in the Clinician Scholar Career Development Program</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2018;100(17):e115.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1781801"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;">American
                Academy of Orthopaedic Surgeons. <span data-id="strong_9" class="annotation strong"
                  style="position: unset;">A Snapshot of U.S. Orthopaedic Surgeons: Results from the 2018 OPUS
                  Survey</span>. 2019. Accessed 2024 Jun 10. <a
                  href="https://www.aaos.org/aaosnow/2019/sep/youraaos/youraaos01/" target="_blank" data-id="link_4"
                  class="link"
                  style="position: unset;">https://www.aaos.org/aaosnow/2019/sep/youraaos/youraaos01/</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Snapshot%20of%20U.S.%20Orthopaedic%20Surgeons%3A%20Results%20from%20the%202018%20OPUS%20Survey&as_occt=title&as_sauthors=%20%22American%20Academy%20of%20Orthopaedic%20Surgeons%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Mesfin A</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Huber A</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Denasty A</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Maqsoodi N</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">What are the academic and
                  demographic characteristics of orthopaedic spine surgery division chiefs?</span><span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">N Am Spine Soc J.</span>
                2022 Aug 1;11:100147.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20are%20the%20academic%20and%20demographic%20characteristics%20of%20orthopaedic%20spine%20surgery%20division%20chiefs%3F&as_occt=title&as_sauthors=%20%22A%20Mesfin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Maqsoodi N</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Mesfin A</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Li X</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Academic, Leadership, and
                  Demographic Characteristics of Orthopaedic Sports Medicine Division Chiefs in the United
                  States</span>. <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Am
                  Acad Orthop Surg Glob Res Rev.</span> 2022 Jan 10;6(1):e21.00139.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Academic%2C%20Leadership%2C%20and%20Demographic%20Characteristics%20of%20Orthopaedic%20Sports%20Medicine%20Division%20Chiefs%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22N%20Maqsoodi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Brown MA</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Erdman MK</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Munger AM</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Miller AN</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Despite Growing Number of Women
                  Surgeons, Authorship Gender Disparity in Orthopaedic Literature Persists Over 30 Years</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2020 Jul;478(7):1542-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Despite%20Growing%20Number%20of%20Women%20Surgeons%2C%20Authorship%20Gender%20Disparity%20in%20Orthopaedic%20Literature%20Persists%20Over%2030%20Years&as_occt=title&as_sauthors=%20%22MA%20Brown%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Svider PF</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Pashkova AA</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Choudhry Z</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Agarwal N</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Kovalerchik O</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Baredes S</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Liu JK</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Eloy JA</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Comparison of scholarly impact
                  among surgical specialties: an examination of 2429 academic surgeons</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Laryngoscope.</span> 2013
                Apr;123(4):884-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20scholarly%20impact%20among%20surgical%20specialties%3A%20an%20examination%20of%202429%20academic%20surgeons&as_occt=title&as_sauthors=%20%22PF%20Svider%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Imam N</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Sudah SY</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Shaikh SZ</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Nicholson AD</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Namdari S</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Menendez ME</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">National Institutes of Health
                  Funding to Departments of Orthopaedic Surgery at U.S. Medical Schools from 2015 to 2021</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2023 Aug 2;105(15):1205-13.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=d738b4eb-2eae-456f-b768-032b8aae0da2" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> McConaghy K</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Klika AK</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Apte SS</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Erdemir A</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Derwin K</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Piuzzi NS</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">A Call to Action for
                  Musculoskeletal Research Funding: The Growing Economic and Disease Burden of Musculoskeletal
                  Conditions in the United States Is Not Reflected in Musculoskeletal Research Funding</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2023 Mar 15;105(6):492-8.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=3509098" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;4f9fc051-43cf-47d7-a2d2-d90c5747bb16&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=4f9fc051-43cf-47d7-a2d2-d90c5747bb16&type=supplement&name=12498"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D4f9fc051-43cf-47d7-a2d2-d90c5747bb16%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2286</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01296</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">August 1, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_14"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Medstar
                            Orthopaedic Institute, Washington Hospital Center, Georgetown University School of Medicine,
                            Washington, DC</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4f9fc051-43cf-47d7-a2d2-d90c5747bb16&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=4f9fc051-43cf-47d7-a2d2-d90c5747bb16&type=pdf&name=JBJS.23.01296.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=4f9fc051-43cf-47d7-a2d2-d90c5747bb16&type=pdf&name=JBJS.23.01296.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_21"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_22" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I134" target="_blank" data-id="link_5"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I134</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4f9fc051-43cf-47d7-a2d2-d90c5747bb16&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=4f9fc051-43cf-47d7-a2d2-d90c5747bb16&type=zip&name=JBJS.23.01296.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Seetha Aribindi, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5344-4455" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5344-4455</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Philipp Leucht, MD, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8409-8513" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8409-8513</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Wellington K. Hsu, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9086-6868" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9086-6868</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Addisu Mesfin, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:amesfin@gmail.com" class="" style="position: unset;">amesfin@gmail.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0076-4185" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0076-4185</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Georgetown University School of Medicine, Washington, DC</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, NYU Robert I. Grossman School of Medicine,
                New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Feinberg School of Medicine, Northwestern
                University, Chicago, Illinois</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Medstar Orthopaedic Institute, Washington Hospital Center, Georgetown
                University School of Medicine, Washington, DC</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 20098.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
