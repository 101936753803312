/* -----------------UI--------------- */
import { Box, Loader, ScrollContainer } from "@memorang/ui";
import { CustomAppbar } from "components/index";

/* -----------------Helpers & Hooks--------------- */
import { useExamContext } from "@features/exam";

import { useBreakpoints } from "@memorang/ui";

/* -----------------Types--------------- */
import type { MixedItemDistResponse } from "@memorang/types";

/* -----------------Child components--------------- */
import { PracticeTestsView } from "../components/PracticeTestsView";

import { appBarMeta } from "@constants/appbar";
import { isWeb } from "@helpers/platform";
/* -----------------Helpers--------------- */
import { getPracticeTestsData } from "../helpers/practice-tests-data";

import { CollectUserDetailsDialog } from "@features/account";
import { ErrorDialog } from "components/ErrorDialog";
import { OLXExamEligibilityDialog } from "../../../components/OLXExamEligibilityDialog";
/* -----------------Routing--------------- */
import { PurchaseDialog } from "../components/PurchaseDialog";
import { usePracticeTests } from "../hooks/usePracticeTests";

const PracticeTestsContainer = () => {
	const { mixedItemDistResponse } = usePracticeTests();

	const {
		currentExamContext: { examName, examDate },
	} = useExamContext();

	const subtitle = examDate || "Practice-Tests";

	const { smDown } = useBreakpoints();
	const finalHeaderTitle = smDown
		? examName
		: appBarMeta["practice-tests"].title;
	const finalSubtitle = smDown
		? subtitle
		: appBarMeta["practice-tests"].description;

	const CustomLoader = () => {
		return (
			<Box flex={1}>
				<CustomAppbar
					back
					showRightAction={false}
					options={{
						headerTitle: finalHeaderTitle,
						subtitle: finalSubtitle,
					}}
				/>
				<Loader />
			</Box>
		);
	};
	if (!mixedItemDistResponse) {
		return <CustomLoader />;
	}

	return (
		<PreloadedView
			mixedItemDistResponse={mixedItemDistResponse}
			headerTitle={finalHeaderTitle}
			headerSubtitle={finalSubtitle}
		/>
	);
};

export default PracticeTestsContainer;

const PreloadedView = ({
	mixedItemDistResponse,
	headerTitle,
	headerSubtitle,
}: {
	mixedItemDistResponse: MixedItemDistResponse;
	headerTitle: string;
	headerSubtitle: string;
}) => {
	const {
		currentExamContext: { examName, examDate },
	} = useExamContext();

	const data = mixedItemDistResponse;

	const {
		sections,
		stats,
		hasAccess: hasProductAccess,
	} = getPracticeTestsData(
		data as unknown as MixedItemDistResponse,
		examName,
		examDate,
	);

	const {
		handlePracticeTestSession,
		selectedExamId,
		creatingSession,
		showPurchaseDialog,
		toggleShowPurchaseDialog,
		showErrorDialog,
		setShowErrorDialog,
		olxExamEligibility,
		setOlxExamEligibility,
		showCollectUserDetailsDialog,
		toggleShowCollectUserDetailsDialog,
	} = usePracticeTests();

	const Wrapper = isWeb ? ScrollContainer : Box;

	const showOlxExamEligibilityDialog = !!olxExamEligibility;

	return (
		<>
			<CustomAppbar
				back
				showRightAction={false}
				options={{
					headerTitle,
					subtitle: headerSubtitle,
				}}
				stats={stats}
			/>
			<Wrapper
				style={{
					flexGrow: 1,
					paddingVertical: 16,
				}}
			>
				<PracticeTestsView
					sections={sections}
					handleNavigation={handlePracticeTestSession}
					selectedExamId={selectedExamId}
					disableCard={creatingSession}
					hasProductAccess={hasProductAccess}
				/>
				{showPurchaseDialog && (
					<PurchaseDialog
						visible={showPurchaseDialog}
						handleClose={toggleShowPurchaseDialog}
						productType="PRACTICE_TEST"
						fromPracticeTestPage
					/>
				)}
				{showErrorDialog && (
					<ErrorDialog
						visible={showErrorDialog}
						handleClose={() => setShowErrorDialog(false)}
						title="Error"
						message="Session creation failed. Please try again."
					/>
				)}
				{showOlxExamEligibilityDialog && (
					<OLXExamEligibilityDialog
						visible={showOlxExamEligibilityDialog}
						handleClose={() => setOlxExamEligibility(null)}
						startDateTime={olxExamEligibility?.startDate}
						endDateTime={olxExamEligibility?.endDate}
					/>
				)}

				{showCollectUserDetailsDialog && (
					<CollectUserDetailsDialog
						visible={showCollectUserDetailsDialog}
						handleClose={toggleShowCollectUserDetailsDialog}
					/>
				)}
			</Wrapper>
		</>
	);
};
