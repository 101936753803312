import type { Article } from "../../../../../types";

export const Article644: Article = {
	id: 644,
	rsuiteId: "ea862d36-4cec-4554-81aa-4d312177b8f5",
	type: "scientific article",
	title:
		"Volume and Distribution of Early Knee Effusion After TKA with a PEEK-Based Knee Prosthesis",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ea862d36-4cec-4554-81aa-4d312177b8f5&type=jpeg&name=JBJS.24.00309f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Volume and Distribution of Early Knee Effusion After TKA
                with a PEEK-Based Knee Prosthesis</div>
              <div class="text subtitle" style="position: unset;">Correlation with Changes in Serum Inflammation Marker
                Levels, Knee Functional Score, and Range of Motion</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Zhuocheng
                      Lyu, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ke Yang</a>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_25" class="annotation strong"
                          style="position: unset;">Background:</span> Early knee effusion is a common phenomenon after
                        total knee arthroplasty (TKA), with potential clinical implications. Unlike traditional alloy
                        knee prostheses, the polyetheretherketone (PEEK) knee system has radiographic transparency on
                        magnetic resonance (MR) scans, which allows analysis of prosthetic knee effusion. We aimed to
                        identify the distribution and volume of knee effusion after TKA with the PEEK prosthesis with
                        use of MR imaging and to analyze whether dynamic changes in effusion were correlated with serum
                        inflammatory marker changes and knee function recovery.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_26" class="annotation strong"
                          style="position: unset;">Methods:</span> Nine patients with osteoarthritis who were 59 to 74
                        years old underwent unilateral TKA with the PEEK prosthesis between June 2021 and August 2021.
                        Dynamic early postoperative changes in the volume and distribution of knee effusion were
                        evaluated with use of 3D MR stereoscopic images. Serum inflammatory markers were measured via
                        blood tests, and joint function was evaluated with use of the subjective functional score of the
                        Knee Society Score (KSS) and knee range of motion (ROM). Linear regression analyses were
                        performed to assess for correlations between knee effusion volume and inflammatory markers and
                        between knee effusion volume and joint function.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_27" class="annotation strong"
                          style="position: unset;">Results:</span> The mean serum inflammatory marker levels increased
                        significantly at 1 week after TKA with the PEEK prosthesis and then gradually decreased with
                        time from 1 to 6 months. The mean total knee effusion volume gradually decreased over time.
                        Concurrently, the mean KSS subjective functional score and mean knee ROM improved with time.
                        Total knee effusion volume was positively correlated with C-reactive protein level (R<span
                          data-id="superscript_1" class="annotation superscript" style="position: unset;">2</span> =
                        0.16; p = 0.007) and negatively correlated with the change in KSS score between the preoperative
                        and postoperative time points (R<span data-id="superscript_2" class="annotation superscript"
                          style="position: unset;">2</span> = 0.19; p = 0.003). Using the 1-week total knee effusion
                        volume as a reference, a positive correlation was observed between the reduction in total knee
                        effusion volume and the actual value of the ROM (R<span data-id="superscript_3"
                          class="annotation superscript" style="position: unset;">2</span> = 0.36; p = 0.0001) from 3 to
                        24 months postoperatively.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Through 3D MR imaging, the precise distribution
                        and volume of, and dynamic changes in, knee effusion after TKA with the PEEK prosthesis were
                        confirmed and were found to be correlated with inflammation and joint function in the early
                        postoperative period. The results demonstrate the potential clinical benefit of the PEEK-based
                        knee system for future use.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Diagnostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">After total knee arthroplasty (TKA), knee effusion may
                  occur as a result of inflammatory reactions within the prosthetic joint. Excessive knee effusion may
                  result in late postoperative pain, limited postoperative joint motion, and even implant failure.
                  Accurate evaluation of knee effusion is crucial. However, obtaining clear knee-effusion image
                  information through magnetic resonance (MR) is difficult due to the metallic artifacts from
                  traditional alloy knee prostheses<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">1</span></a>. The
                  specific distribution and volume of, and dynamic changes in, knee effusion after TKA remain unclear.
                  Moreover, the extent to which effusion relates to periprosthetic knee inflammation and function is
                  unknown.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Applying substitute materials with good radiographic
                  transparency characteristics is a possible solution. Polyetheretherketone (PEEK) is a polyaromatic
                  semicrystalline thermoplastic polymer with beneficial properties, such as radiographic transparency,
                  high strength, resistance to fatigue strain, suitability for common sterilization techniques, and
                  excellent biocompatibility, that make it useful for biomedical applications<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">3</span></a>.
                  Currently, several orthopaedic applications of PEEK are available, including the use of PEEK for the
                  femoral component of hip prostheses, bone anchors, and spinal implants<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">4</span></a>. Our previous study demonstrated good biosafety in goats
                  that underwent TKA with cemented PEEK-on-highly cross-linked polyethylene (HXLPE) prosthetic knee
                  joints<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">3</span></a>. An
                  animal model study proved the feasibility of in vivo MR image monitoring after TKA<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">5</span></a>. We recently performed TKA with the PEEK-based implant for
                  the first time in an ethically approved clinical trial that involved the same patient cohort as the
                  present study<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">2</span></a>. The
                  PEEK knee system was manufactured by Suzhou SinoMed Biomaterials in collaboration with Solvay U.S.A.
                  During 6 months of follow-up, the PEEK-based implant demonstrated excellent computed tomography (CT)
                  compatibility<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">2</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">In the present study, we aimed to identify the
                  distribution and volume of knee effusion after TKA with the PEEK prosthesis with use of MR imaging.
                  Additionally, we sought to analyze whether dynamic changes in knee effusion were correlated with joint
                  function, as measured with use of the Knee Society Score (KSS) and range of motion (ROM), as well as
                  with changes in serum inflammatory marker levels, including C-reactive protein (CRP) and erythrocyte
                  sedimentation rate (ESR).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patients and
                the PEEK-on-HXLPE Implant</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">As an early feasibility study of a first-in-human medical
                  device, the sample size was limited to 10 subjects according to the guidance of the U.S. Department of
                  Health and Human Services Food and Drug Administration. Between June 2021 and August 2021, 10 patients
                  were recruited after obtaining the approval of the Ethics Committee (KY2021-025). All patients had
                  clear indications for TKA, and the mean age was 66.8 ± 4.42 years (range, 59 to 74 years).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The inclusion criteria are described in our previous
                  publication<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">2</span></a>.
                  There were 8 exclusion criteria, as follows: (1) patients with neuromuscular dysfunction that could
                  result in postoperative knee instability or an abnormal gait; (2) patients with bilateral knee
                  conditions for whom it was anticipated that both knees would require replacement during the study
                  period (i.e., within the next 12 months); (3) patients with a history of alcohol or substance abuse;
                  (4) patients with a body mass index (BMI) of &gt;35 kg/m<span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">2</span>; (4) patients with severe diabetes
                  (a fasting blood glucose level of &gt;10 mmol/L); (5) pregnant or breastfeeding women; (6) patients
                  with comorbid conditions that would limit their ability to participate in the study, compromise
                  follow-up adherence, or affect the scientific integrity of the study; (7) patients who participated in
                  other drug, biologic, or medical device clinical trials within 1 month prior to enrollment without
                  reaching the primary end point of that trial; (8) any other condition deemed unsuitable for the
                  clinical study by the investigator.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Measurements were completed for all patients at 1 week and
                  3, 6, 12, and 24 months after TKA. We excluded from the study a patient with rheumatoid arthritis who
                  was assessed as having a possible postoperative infection and was administered antibiotics. A total of
                  9 patients with osteoarthritis who underwent unilateral TKA were analyzed in the study. Descriptive
                  statistics for all 10 patients are provided in <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a> and
                  Appendix Figures S1, S2, and S3. The race and ethnicity information of the study participants was
                  identified by the researchers on the basis of the participants’ self-reports.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographic Information Regarding All Patients</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 270.712px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.480292);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Case No.</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Gender</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Race/Ethnicity</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_26" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Height
                          <span data-id="emphasis_27" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Weight
                          <span data-id="emphasis_28" class="annotation emphasis"
                            style="position: unset;">(kg)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Operated
                          Side of the Knee</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Size
                          Code<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span> of the Femoral Component</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Size
                          Code<span data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span> of the Tibial Component</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Thickness
                          of the Insert Component <span data-id="emphasis_29" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Diameter
                          of the Patellar Component <span data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Male</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Han Chinese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">175</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Han Chinese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">156</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Han Chinese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">159</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">4</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Han Chinese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">163</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Han Chinese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">148</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Han Chinese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">158</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">7</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Han Chinese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">160</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Han Chinese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">163</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">89</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">9</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Han Chinese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Han Chinese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">152</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The femoral components were available in 8 distinct sizes ranging from
                        Size No. 1 to No. 8 according to their anterior-posterior dimensions (Size 1 = 46 mm, Size 2 =
                        50.5 mm, Size 3 = 54.5 mm, Size 4 = 59 mm, Size 5 = 62.5 mm, Size 6 = 65.5 mm, Size 7 = 69.5 mm,
                        and Size 8 = 75 mm).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The tibial components were available in 8 distinct sizes ranging from
                        Size No. 1 to No. 8 according to their medial-lateral dimensions (Size 1 = 60 mm, Size 2 = 64
                        mm, Size 3 = 68 mm, Size 4 = 71 mm, Size 5 = 74.5 mm, Size 6 = 77 mm, Size 7 = 81 mm, and Size 8
                        = 85 mm).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The implant utilized in this study was a cemented
                  PEEK-on-HXLPE knee system manufactured by Suzhou SinoMed Biomaterials that consisted of a PEEK femoral
                  component, a PEEK tibial tray, an HXLPE bearing, and an HXLPE patellar component. The femoral
                  component and tibial tray were made from PEEK pellets (Zeniva PEEK ZA-500L; Solvay Specialty Polymers
                  USA) with use of a Sumitomo Electric Precision Injection Molding Machine. The bearing and patellar
                  component were made from HXLPE (Chirulen HXLPE 1020X; Quadrant EPP Deutschland).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Techniques and Postoperative Treatment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">All procedures were performed at our center by 2
                  experienced surgeons with Good Clinical Practice certificates (Y.W. and B.Y.) with use of routine
                  surgical techniques, with the patient under general anesthesia. Patients received postoperative
                  drainage for 48 hours.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Postoperative pain control was achieved with oral
                  paracetamol and dihydrocodeine. Venous thromboembolism prophylaxis was performed with use of
                  low-molecular-weight heparin for 1 week and rivaroxaban for the next 3 weeks. Standardized
                  rehabilitation started the day after surgery, with partial-weight-bearing walking and active
                  range-of-motion exercises.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcome
                Measures and Statistical Analyses</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">KSS subjective functional scores<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">6</span></a> and serum inflammatory marker (CRP and ESR) values were
                  obtained and routine blood tests were performed before and after surgery. Postoperative knee ROM was
                  measured in the supine position without weight-bearing. A CRP level of &lt;5 mg/L and an ESR of ≤ 20
                  mm/hr were considered normal.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Postoperative knee effusion was measured with use of a 3D
                  MR stereoscopic imaging technique. The successive T2-weighted (proton-density-weighted fast spin echo
                  [PDW-FSE]) MR images of knee effusion in sagittal planes were clustered with use of threshold
                  segmentation, which was based on the highest-signal gray value of the water-only region at the femoral
                  intercondylar fossa. After segmentation, a 3D model of the knee effusion was generated with use of
                  Materialise Mimics.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">To analyze the effusion distribution, the 3D models were
                  subdivided into suprapatellar bursal, patellofemoral joint, and infrapatellar bursal regions (<a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00309f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ea862d36-4cec-4554-81aa-4d312177b8f5&type=jpeg&name=JBJS.24.00309f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;">Schematic diagram of the technique utilized for
                          making, reconstructing, and subdividing 3D MR stereoscopic images of knee effusion. MRI =
                          magnetic resonance imaging, PDW-FSE = proton-density-weighted fast spin echo, P = patella.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">We performed a statistical analysis of the CRP level, ESR,
                  and KSS as well as the preoperative-to-postoperative change in value for each of these measures at
                  each postoperative time point. We also conducted an analysis of the real-time knee effusion volume at
                  various locations, as well as the change in volume relative to the 1-week postoperative value, at each
                  postoperative time point. Simple linear regression analyses were utilized to identify any correlations
                  between knee effusion and inflammatory markers and between knee effusion and joint function.
                  Significance was set at p &lt; 0.05. Analyses were performed with use of Stata (version 13.0;
                  StataCorp).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">General
                Clinical Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">All patients recovered steadily after surgery, with the
                  wound healing as intended. None of the knees had physical signs or symptoms of malalignment or
                  instability. None of the patients experienced surgery-related complications. Routine blood tests
                  during the 2-year follow-up showed no abnormalities, with slight decreases in red blood-cell counts
                  and hemoglobin levels at 1 week postoperatively that returned to the normal range at the subsequent
                  follow-up time points (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00309f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ea862d36-4cec-4554-81aa-4d312177b8f5&type=jpeg&name=JBJS.24.00309f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Box plots of red blood-cell (RBC) counts, white
                          blood-cell (WBC) counts, platelet counts, and hemoglobin levels, as determined on peripheral
                          blood tests. The horizonal line in the box represents the median, the boundaries of the box
                          represent the interquartile range, and the whiskers represent the range.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">MR
                Imaging</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The 3D MR stereoscopic images revealed that the mean total
                  knee effusion volume gradually decreased with time. The mean volume (and standard deviation [SD]) at 2
                  years postoperatively was 11.45 ± 5.19 mL, which was significantly lower than that at 1 week
                  postoperatively (31.86 ± 15.34 mL). Quantitative analyses showed a dramatic, significant decrease in
                  the mean total knee effusion volume and effusion volume in the suprapatellar bursa over the 2-year
                  follow-up period. In contrast, effusion volume in the patella and that in the infrapatellar bursa
                  remained relatively stable, with a slight decrease during the follow-up period, which was significant
                  only for the patellar region. The mean total knee effusion volume at each follow-up point, from 3 to
                  24 months postoperatively, was significantly lower than that at 1 week postoperatively (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>, <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00309f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ea862d36-4cec-4554-81aa-4d312177b8f5&type=jpeg&name=JBJS.24.00309f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">3D MR stereoscopic images of total knee effusion.
                          The patellar bursal region is marked in green, adjacent to the upper suprapatellar bursal
                          region and the lower infrapatellar bursal region.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Postoperative Knee Effusion Volumes and Changes in
                          Volume from Postoperative Week 1<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 747.784px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.911357);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Week
                          1</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Month
                          3</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Month
                          6</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Month
                          12</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Month
                          24</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">JEV <span
                            data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.86 ±
                          15.34</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.32 ±
                          10.71</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.11 ±
                          11.09</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.94 ±
                          4.66</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.45 ±
                          5.19</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0004</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Suprapatellar</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.71 ±
                          13.53</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.82 ±
                          7.52</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.79 ±
                          6.71</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.74 ±
                          2.63</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.84 ±
                          2.87</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Patellar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.93 ±
                          2.88</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.25 ±
                          2.27</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.76 ±
                          1.52</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.33 ±
                          0.72</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.42 ±
                          0.63</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.015</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Infrapatellar</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.19 ±
                          4.24</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.24 ±
                          4.54</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.68 ±
                          4.13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.97 ±
                          2.47</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.19 ±
                          2.54</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.051</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Change in JEV from Week 1
                          <span data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−7.53 ±
                          6.37</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−11.74 ±
                          7.96</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−19.92 ±
                          12.94</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−20.41 ±
                          12.19</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.029</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Suprapatellar</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−8.9 ±
                          6.79</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−9.92 ±
                          7.56</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−14.97 ±
                          11.61</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−14.87 ±
                          11.31</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.399</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Patellar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.68 ±
                          2.43</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.17 ±
                          1.99</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.59 ±
                          2.53</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.51 ±
                          3.21</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.307</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Infrapatellar</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.06 ±
                          3.23</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.51 ±
                          5.36</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.21 ±
                          3.48</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.99 ±
                          4.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.064</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">JEV = knee joint effusion volume. Values are given as the mean ±
                        standard deviation and are based on the entire analyzed cohort (n = 9).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">1-way ANOVA (analysis of variance).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Inflammatory
                Markers</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The mean serum inflammatory marker levels of the 9
                  patients increased significantly at 1 week postoperatively (CRP, 23.12 ± 18.92 mg/L; ESR, 59.78 ±
                  33.43 mm/hr). From 1 week to 6 months postoperatively, the mean CRP and ESR values decreased with time
                  and approached normal ranges at different rates of decrease. Normal CRP values were observed in 8 of 9
                  patients at 3 months, in 8 of 9 patients at 6 months, in 9 of 9 patients at 12 months, and in 7 of 9
                  patients at 24 months postoperatively. Normal ESR values were observed in 5 of 9 patients at 3 months,
                  in 9 of 9 patients at 6 months, in 7 of 9 patients at 12 months, and in 7 of 9 patients at 24 months
                  postoperatively. The faster changes in CRP than in ESR suggested that it was a better indicator of
                  inflammation (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>, <a
                    href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00309f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ea862d36-4cec-4554-81aa-4d312177b8f5&type=jpeg&name=JBJS.24.00309f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;">Graphs showing the serum inflammatory marker (ESR
                          and CRP) levels of each patient. A solid black triangle indicates a level within the normal
                          range. A hollow triangle indicates an abnormal level.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Mean CRP and ESR Values and Changes in the Values
                          Postoperatively<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 376.452px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.808354);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Week
                          1</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Month
                          3</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Month
                          6</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Month
                          12</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Month
                          24</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CRP level <span
                            data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(mg/L)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.79 ±
                          1.02</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.12 ±
                          18.92</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.64 ±
                          1.96</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.96 ±
                          1.81</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.14 ±
                          1.57</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.20 ±
                          3.52</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ESR <span
                            data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(mm/hr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.22 ±
                          7.90</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59.78 ±
                          33.43</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.89 ±
                          9.62</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.67 ±
                          4.58</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.33 ±
                          5.61</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.89 ±
                          5.84</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Change in CRP<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(mg/L)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.33 ±
                          19.05</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.86 ±
                          2.14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.17 ±
                          1.68</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.36 ±
                          1.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.41 ±
                          3.69</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Change in ESR<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">§</span><span data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(mm/hr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.56 ±
                          32.39</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.67 ±
                          8.94</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.56 ±
                          8.73</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.11 ±
                          7.88</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.67 ±
                          10.24</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD and are based on the entire analyzed
                        cohort (n = 9).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">1-way ANOVA (analysis of variance).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Relative to the preoperative CRP value.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Relative to the preoperative ESR value.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Knee
                Function</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The mean knee ROM of the 9 patients increased over
                  time,although the increase was not significant. The mean postoperative ROM was 95.78° ± 21.20° at 3
                  months, 105.78° ± 20.84° at 6 months, 113.78° ± 17.1° at 12 months, and 116.1° ± 15.46° at 24 months.
                  The KSS values at 2 years postoperatively were significantly improved from the preoperative values (p
                  &lt; 0.0001). The KSS subjective functional score improved in 5 of 9 patients at 1 week, in 7 of 9
                  patients at 3 months, and in 9 of 9 patients at 6, 12, and 24 months of follow-up. KSS subjective
                  functional scores at the time of the final follow-up were significantly higher than those before
                  surgery (p &lt; 0.0001), increasing from a mean of 37.78 ± 16.68 to 86.56 ± 16.52 (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). The mean KSS at 6 months was similar to that at 12 months (p
                  = 0.953) and 24 months (p = 0.783).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Mean Postoperative ROM, KSS, and Change in KSS<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 278px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Week
                          1<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Month
                          3</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Month
                          6</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Month
                          12</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Month
                          24</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ROM <span
                            data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95.78 ±
                          21.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">105.78 ±
                          20.84</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">113.78 ±
                          17.10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">116.10 ±
                          15.46</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KSS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37.78 ±
                          16.68</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.67 ±
                          9.46</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55.56 ±
                          18.57</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84.56 ±
                          13.65</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84.11 ±
                          17.44</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86.56 ±
                          16.52</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Change in KSS<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.89 ±
                          18.81</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.78 ±
                          19.97</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.78 ±
                          12.49</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.33 ±
                          16.26</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.77 ±
                          12.96</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD and are based on the entire analyzed
                        cohort (n = 9).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Factors such as pain, swelling, and fatigue may affect the measurement
                        of ROM at 1 week after TKA. Pain may cause patients to be unwilling to reach their maximum ROM,
                        leading to measured values that are lower than the actual ROM and that fail to reflect the true
                        objective condition, resulting in unreliable outcomes. Therefore, given the purpose of our
                        study, we did not include the measurement of actual ROM at 1 week postoperatively in our study
                        design.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Relative to the preoperative KSS value.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Relationship
                Between Knee Effusion and CRP or ESR Value</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">The preoperative values of CRP and ESR were utilized as
                  references for the changes in CRP level and ESR at each postoperative time point. The results revealed
                  that the greater the total knee effusion volume after surgery, the greater the postoperative CRP and
                  ESR values and the greater the changes in CRP and ESR postoperatively. Positive correlations were
                  observed between CRP level and knee effusion volume (R<span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">2</span> = 0.16; p = 0.007), between ESR and
                  knee effusion volume (R<span data-id="superscript_15" class="annotation superscript"
                    style="position: unset;">2</span> = 0.22; p = 0.001), between the change in CRP level and knee
                  effusion volume (R<span data-id="superscript_16" class="annotation superscript"
                    style="position: unset;">2</span> = 0.16; p = 0.007), and between the change in ESR and knee
                  effusion volume (R<span data-id="superscript_17" class="annotation superscript"
                    style="position: unset;">2</span> = 0.15; p = 0.009) (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00309f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ea862d36-4cec-4554-81aa-4d312177b8f5&type=jpeg&name=JBJS.24.00309f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;">Correlation analyses revealing that the greater
                          the total knee effusion volume after surgery, the greater the postoperative CRP and ESR values
                          or the change in postoperative CRP and ESR values relative to their preoperative values. CI =
                          confidence interval.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Relationship
                Between Knee Effusion and ROM or KSS</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Using the 1-week total knee effusion volume as a reference
                  for measuring the change in total knee effusion volume, a positive correlation was observed between
                  the reduction in total knee effusion volume and the actual value of the ROM (R<span
                    data-id="superscript_18" class="annotation superscript" style="position: unset;">2</span> = 0.36; p
                  = 0.0001) from 3 to 24 months postoperatively. There was also a negative correlation between the
                  change in effusion volume in the suprapatellar bursal region (R<span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">2</span> = 0.29; p = 0.0006) and ROM. No
                  such correlation was found for the infrapatellar bursal region (R<span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">2</span> = 0.05; p = 0.21) or the
                  patellofemoral region (R<span data-id="superscript_21" class="annotation superscript"
                    style="position: unset;">2</span> = 0.05; p = 0.18). A negative correlation (R<span
                    data-id="superscript_22" class="annotation superscript" style="position: unset;">2</span> = 0.19; p
                  = 0.003) was observed between total knee effusion volume and the change in the KSS, indicating that
                  greater effusion volume was associated with less postoperative improvement. A negative correlation was
                  found between knee effusion volume in the suprapatellar bursal region (R<span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">2</span> = 0.16; p = 0.006) or
                  patellofemoral region (R<span data-id="superscript_24" class="annotation superscript"
                    style="position: unset;">2</span> = 0.20; p = 0.002) and the change in the postoperative KSS (<a
                    href="" data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.24.00309f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ea862d36-4cec-4554-81aa-4d312177b8f5&type=jpeg&name=JBJS.24.00309f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_40" style="position: unset;">
                        <div class="content" style="position: unset;">Correlation analyses revealing a positive
                          correlation between the reduction in total knee joint effusion volume (JEV) or effusion volume
                          in the suprapatellar bursal region and the actual value of the ROM. The greater the total JEV
                          or the postoperative JEV in the suprapatellar and patellofemoral bursal regions, the smaller
                          the postoperative improvement in the KSS. CI = confidence interval.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">The clinical application of the PEEK prosthesis for TKA
                  offers ideal mechanical properties, an elastic modulus similar to bone, and a better option for
                  patients with metal allergies. Our previous study of the same group of patients demonstrated that
                  periprosthetic bone density decreased at 3 months and rebounded at 6 months postoperatively<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">2</span></a>. The mechanical compatibility of PEEK material with the host
                  bone was also confirmed in prior research<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_26" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">9</span></a>. In the present study, we found improvement in the KSS
                  functional score, from a mean of 37.78 to 86.56, and a mean ROM of 116.1° at 2 years postoperatively,
                  indicating that TKA with the PEEK prosthesis provides a generally satisfactory short-term clinical
                  effect, comparable with that of TKA with a traditional alloy prosthesis<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">10</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">It should be noted that, in the present study, significant
                  (p &lt; 0.05) improvement in the change in KSS score relative to the preoperative value was observed
                  at 2 months and 6 months postoperatively, but this improvement reached a plateau after 6 months, which
                  is consistent with the result reported by Zhou et al.<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">11</span></a>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">We found that serum inflammatory marker levels increased
                  in the early postoperative period (1 week after TKA) and were associated with increased knee joint
                  effusion volume. Although temporary, the increased levels of inflammatory markers (CRP or ESR) and
                  knee effusion may be indications of surgical trauma-related inflammation, bleeding, or infection and
                  may have potential clinical implications. As shown in <a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 4</a>,
                  several patients exhibited increased CRP level or ESR that was slightly outside of the normal range
                  from 12 to 24 months postoperatively (cases 6, 7, and 10). The contralateral knees of 2 of these
                  patients (cases 6 and 7) were also diagnosed with end-stage osteoarthritis. The other patient (case
                  10) was identified as having chronic hepatitis and an increased level of transaminase. For the index
                  knee, all 3 patients exhibited gradual improvement in knee effusion, ROM, and KSS from 12 to 24 months
                  postoperatively. Multiple studies have confirmed the association between inflammatory marker levels
                  and progressive osteoarthritis with no interventions<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">12</span></a>.
                  The delayed surgical interventions for the contralateral knee and other systemic diseases could have
                  potentially influenced the study outcomes. Despite the potential bias mentioned above, in order to
                  study the actual host response to novel prosthetic materials such as PEEK, it was necessary to know
                  the degree of knee effusion after implantation, as it is an indicator of an inflammatory reaction to
                  the foreign body. To our knowledge, there have been no reports on the precise distribution and volume
                  of knee effusion after TKA<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">13</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">A major advantage of the PEEK prosthesis is its
                  transparency on radiographs and MR, which avoids the metallic artifacts seen in MR images of joints
                  with traditional alloy prostheses. Wittstein et al. revealed that 3D models of knee effusion generated
                  with use of MR imaging using the SPACE (sampling perfection with application-optimized contrast using
                  different flip angle evolution) sequence and T2WTSE (T2-weighted turbo spin echo) provided accurate
                  estimates of knee effusion volume in a cadaveric study<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">14</span></a>.
                  The relatively precise quantification of knee effusion with MR imaging may be based on the T2WTSE or
                  T2WFSE (T2-weighted fast spin echo) sequence for scanning and 3D reconstruction. In the present study,
                  a T2-weighted PDW-FSE sequence was utilized to optimize the segmentation of the knee effusion image.
                  Sagittal images were utilized to generate the 3D models since axial images might have left the
                  suprapatellar recess uncovered. The 3D MR imaging models displayed the distribution and volume of
                  effusion in the knee with the PEEK prosthesis. Using 3D model analysis, dynamic changes in knee
                  effusion were identified, which were characterized by an early rise in the mean volume to 31.86 ±
                  15.34 mL at 1 week postoperatively, followed by a gradual decrease to 11.45 ± 5.19 mL at 2 years
                  postoperatively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">CRP and ESR are important markers for assessing local and
                  systemic inflammation after TKA and their use for ruling out periprosthetic joint infection is well
                  supported<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">15</span></a>.
                  Research has shown that CRP and ESR tests, although highly sensitive, have low specificity for
                  periprosthetic joint infection<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_33"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">17</span></a>. Kim et al. found that elevated CRP levels after TKA can be
                  caused by various noninfectious issues, including cardiovascular, gastrointestinal, urologic, or
                  respiratory problems, in the elderly<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">15</span></a>.
                  Rapid changes in CRP level and ESR during the early stage (1 week postoperatively) after TKA with the
                  PEEK prosthesis were observed in the present study. The peak level may have occurred earlier, at 2 or
                  3 days postoperatively, but such data were unavailable due to the design of this clinical trial<a
                    href="" data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">19</span></a>. These changes in inflammatory marker levels may have been
                  due to temporary aseptic inflammation from surgical trauma during TKA. Shen et al. revealed that less
                  severe bone and bone marrow injuries during TKA resulted in a lower postoperative CRP level<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">20</span></a>. In a previous animal study, implantation of the PEEK-based
                  knee prosthesis was also shown to cause mild inflammation of the synovium, in which the inflammatory
                  cytokines interleukin-6 and TNF-α (tumor necrosis factor-α) were elevated at 12 weeks and normalized
                  by 24 weeks<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">21</span></a>.
                  The correlation analysis in the present study revealed that postoperative CRP and ESR values (and the
                  changes in those values relative to the preoperative values) were positively correlated with total
                  knee effusion volume, indicating that the enhancement or attenuation of inflammation was accompanied
                  by the production or absorption of effusion, respectively. TKA may trigger a mild inflammatory
                  reaction in synovial tissue, causing knee effusion. This suggests that effusion volume could serve as
                  a reference for assessing local inflammation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">The volume of knee effusion in the suprapatellar bursal or
                  patellofemoral region was negatively correlated with the change in the KSS functional score, and the
                  change in the volume of knee effusion in the suprapatellar bursal region was negatively correlated
                  with the ROM. These results indicated that the reduction in knee effusion contributed to knee function
                  improvement after TKA. Improved prosthetic knee function results from well-balanced soft tissue in
                  TKA, effective pain alleviation, early mobilization, and prevention of arthrofibrosis. Among all
                  influential factors, arthrofibrosis prevention has been considered the most important factor in
                  avoiding stiff knees postoperatively and achieving good ROM<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_38"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">23</span></a>. Malahias et al. suggested that patients who develop
                  stiffness 6 weeks after TKA have a distinct acute postoperative cytokine response profile<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">24</span></a>. In the present study, the concurrent reduction in knee
                  effusion and in CRP level or ESR indicated an alleviated inflammatory reaction in the knee joint. The
                  decreased inflammation likely led to pain relief, which could have resulted in the observed
                  improvements in the KSS subjective functional score and ROM.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">Our study has several limitations. First, the sample size
                  was small and the follow-up time was short. Since the PEEK knee system was applied clinically for TKA
                  for the first time by our medical team, long-term results are unavailable. Second, although the
                  correlation coefficients between knee effusion volume and CRP levels, as well as between knee effusion
                  volume and KSS, were significant, all of them were relatively low. The practical relevance of these
                  findings needs to be further proven in larger-scale clinical studies. Finally, due to the metallic
                  artifacts in MR images of alloy prostheses, we did not directly compare the results of TKA with
                  traditional alloy implants to those of TKA with the PEEK implant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">TKA with the PEEK prosthesis demonstrated a generally
                  satisfactory clinical effect at 2 years postoperatively. Through 3D MR imaging of the knee in which
                  the PEEK prosthesis was implanted, the distribution and volume of, and dynamic changes in, knee
                  effusion were precisely monitored and were found to be correlated with inflammation and prosthetic
                  knee function. These results demonstrate the potential clinical benefit of the PEEK-based knee system
                  for future use.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I285" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I285</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Fritz J</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Lurie B</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Potter HG</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">MR Imaging of Knee Arthroplasty
                  Implants</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Radiographics.</span> 2015 Sep-Oct;35(5):1483-501.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=MR%20Imaging%20of%20Knee%20Arthroplasty%20Implants&as_occt=title&as_sauthors=%20%22J%20Fritz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Cai Z</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Qu X</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Zhao Y</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Yuan Z</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Zheng L</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Long T</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Yao Q</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Yue B</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Wang Y</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Preliminary Study on Immediate
                  Postoperative CT Images and Values of the Modular Polyetheretherketone Based Total Knee Arthroplasty:
                  An Observational First-in-Human Trial</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Front Surg.</span> 2022 Feb 14;9:809699.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preliminary%20Study%20on%20Immediate%20Postoperative%20CT%20Images%20and%20Values%20of%20the%20Modular%20Polyetheretherketone%20Based%20Total%20Knee%20Arthroplasty%3A%20An%20Observational%20First-in-Human%20Trial&as_occt=title&as_sauthors=%20%22Z%20Cai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Du Z</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Zhu Z</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Yue B</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Li Z</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Wang Y</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Feasibility and Safety of a
                  Cemented PEEK-on-PE Knee Replacement in a Goat Model: A Preliminary Study</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Artif Organs.</span> 2018
                Aug;42(8):E204-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Feasibility%20and%20Safety%20of%20a%20Cemented%20PEEK-on-PE%20Knee%20Replacement%20in%20a%20Goat%20Model%3A%20A%20Preliminary%20Study&as_occt=title&as_sauthors=%20%22Z%20Du%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Kurtz SM</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Devine JN</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">PEEK biomaterials in trauma,
                  orthopedic, and spinal implants</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Biomaterials.</span> 2007 Nov;28(32):4845-69.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=PEEK%20biomaterials%20in%20trauma%2C%20orthopedic%2C%20and%20spinal%20implants&as_occt=title&as_sauthors=%20%22SM%20Kurtz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Meng X</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Du Z</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Wang Y</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Feasibility of Magnetic
                  Resonance Imaging Monitoring of Postoperative Total Knee Arthroplasty without Metal Artifacts: A
                  Preliminary Study of a Novel Implant Model</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Biomed Res Int.</span> 2018 Oct
                23;2018:8194670.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Feasibility%20of%20Magnetic%20Resonance%20Imaging%20Monitoring%20of%20Postoperative%20Total%20Knee%20Arthroplasty%20without%20Metal%20Artifacts%3A%20A%20Preliminary%20Study%20of%20a%20Novel%20Implant%20Model&as_occt=title&as_sauthors=%20%22X%20Meng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Scuderi GR</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Bourne RB</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Noble PC</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Benjamin JB</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Lonner JH</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Scott WN</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The new Knee Society Knee
                  Scoring System</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2012 Jan;470(1):3-19.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20new%20Knee%20Society%20Knee%20Scoring%20System&as_occt=title&as_sauthors=%20%22GR%20Scuderi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Rankin KE</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Dickinson AS</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Briscoe A</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Browne M</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Does a PEEK Femoral TKA Implant
                  Preserve Intact Femoral Surface Strains Compared With CoCr? A Preliminary Laboratory Study</span>.
                <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2016 Nov;474(11):2405-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20a%20PEEK%20Femoral%20TKA%20Implant%20Preserve%20Intact%20Femoral%20Surface%20Strains%20Compared%20With%20CoCr%3F%20A%20Preliminary%20Laboratory%20Study&as_occt=title&as_sauthors=%20%22KE%20Rankin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Yildiz H</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Ha SK</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Chang FK</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Composite hip prosthesis design.
                  I. Analysis</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J
                  Biomed Mater Res.</span> 1998 Jan;39(1):92-101.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Composite%20hip%20prosthesis%20design.%20I.%20Analysis&as_occt=title&as_sauthors=%20%22H%20Yildiz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Yildiz H</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Chang FK</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Goodman S</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Composite hip prosthesis design.
                  II. Simulation</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">J Biomed Mater Res.</span> 1998 Jan;39(1):102-19.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Composite%20hip%20prosthesis%20design.%20II.%20Simulation&as_occt=title&as_sauthors=%20%22H%20Yildiz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Panchani SK</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Divecha HM</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Lafferty R</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Pavlou G</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Oakley J</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Shaw D</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Chitre A</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Wynn Jones H</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Raut V</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Smith R</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Gambhir A</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Board T</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Early Functional Outcomes After
                  Evolutionary Total Knee Arthroplasty: A Randomized Controlled Trial. Is New Always Better?</span><span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">JB JS Open Access.</span>
                2021 Jul 28;6(3):e21.00016.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20Functional%20Outcomes%20After%20Evolutionary%20Total%20Knee%20Arthroplasty%3A%20A%20Randomized%20Controlled%20Trial.%20Is%20New%20Always%20Better%3F&as_occt=title&as_sauthors=%20%22SK%20Panchani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Zhou Z</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Yew KSA</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Arul E</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Chin PL</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Tay KJ</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Lo NN</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Chia SL</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Yeo SJ</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Recovery in knee range of
                  motion reaches a plateau by 12 months after total knee arthroplasty</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2015 Jun;23(6):1729-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Recovery%20in%20knee%20range%20of%20motion%20reaches%20a%20plateau%20by%2012%20months%20after%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22Z%20Zhou%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Stannus OP</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Jones G</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Blizzard L</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Cicuttini FM</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Ding C</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Associations between serum
                  levels of inflammatory markers and change in knee pain over 5 years in older adults: a prospective
                  cohort study</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Ann Rheum Dis.</span> 2013 Apr;72(4):535-40.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Associations%20between%20serum%20levels%20of%20inflammatory%20markers%20and%20change%20in%20knee%20pain%20over%205%20years%20in%20older%20adults%3A%20a%20prospective%20cohort%20study&as_occt=title&as_sauthors=%20%22OP%20Stannus%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Yau LK</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Henry FU</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Man Hong C</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Amy C</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Wai Kwan Vincent C</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Ping Keung C</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Kwong Yuen C</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Swelling assessment after total
                  knee arthroplasty</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">J Orthop Surg (Hong Kong).</span> 2022
                Sep-Dec;30(3):10225536221127668.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Swelling%20assessment%20after%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22LK%20Yau%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Wittstein J</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Spritzer C</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Garrett WE</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">MRI Determination of Knee
                  Effusion Volume: A Cadaveric Study</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">The Duke Orthopaedic Journal.</span> 2013 Jul;3(1):67-70.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=MRI%20Determination%20of%20Knee%20Effusion%20Volume%3A%20A%20Cadaveric%20Study&as_occt=title&as_sauthors=%20%22J%20Wittstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Kim TW</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Kim DH</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Oh WS</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Sim JA</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Lee YS</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Lee BK</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Analysis of the Causes of
                  Elevated C-Reactive Protein Level in the Early Postoperative Period After Primary Total Knee
                  Arthroplasty</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2016 Sep;31(9):1990-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Analysis%20of%20the%20Causes%20of%20Elevated%20C-Reactive%20Protein%20Level%20in%20the%20Early%20Postoperative%20Period%20After%20Primary%20Total%20Knee%20Arthroplasty&as_occt=title&as_sauthors=%20%22TW%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Tubb CC</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Polkowksi GG</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Krause B</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Diagnosis and Prevention of
                  Periprosthetic Joint Infections</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2020 Apr 15;28(8):e340-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnosis%20and%20Prevention%20of%20Periprosthetic%20Joint%20Infections&as_occt=title&as_sauthors=%20%22CC%20Tubb%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Volpe L</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Indelli PF</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Latella L</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Poli P</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Yakupoglu J</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Marcucci M</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Periprosthetic joint
                  infections: a clinical practice algorithm</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Joints.</span> 2015 Feb
                13;2(4):169-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20joint%20infections%3A%20a%20clinical%20practice%20algorithm&as_occt=title&as_sauthors=%20%22L%20Volpe%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Niskanen RO</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Korkala O</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Pammo H</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Serum C-reactive protein levels
                  after total hip and knee arthroplasty</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 1996 May;78(3):431-3.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Serum%20C-reactive%20protein%20levels%20after%20total%20hip%20and%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22RO%20Niskanen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> White J</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Kelly M</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Dunsmuir R</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">C-reactive protein level after
                  total hip and total knee replacement</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 1998 Sep;80(5):909-11.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=C-reactive%20protein%20level%20after%20total%20hip%20and%20total%20knee%20replacement&as_occt=title&as_sauthors=%20%22J%20White%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Shen H</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Zhang N</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Zhang X</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Ji W</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">C-reactive protein levels after
                  4 types of arthroplasty</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2009 Jun;80(3):330-3.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=C-reactive%20protein%20levels%20after%204%20types%20of%20arthroplasty&as_occt=title&as_sauthors=%20%22H%20Shen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Meng X</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Du Z</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Wang Y</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Characteristics of wear
                  particles and wear behavior of retrieved PEEK-on-HXLPE total knee implants: a preliminary
                  study</span>. <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">RSC
                  Adv.</span> 2018 Aug 28;8(53):30330-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Characteristics%20of%20wear%20particles%20and%20wear%20behavior%20of%20retrieved%20PEEK-on-HXLPE%20total%20knee%20implants%3A%20a%20preliminary%20study&as_occt=title&as_sauthors=%20%22X%20Meng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Scheidt M</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Ellman MB</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Bhatia S</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Knee Arthrofibrosis: How to
                  Prevent and How to Treat</span>. In: <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Evidence-Based Management of Complex Knee Injuries: Restoring the Anatomy to
                  Achieve Best Outcomes.</span> Elsevier; 2022.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Knee%20Arthrofibrosis%3A%20How%20to%20Prevent%20and%20How%20to%20Treat&as_occt=title&as_sauthors=%20%22M%20Scheidt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Thompson R</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Novikov D</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Cizmic Z</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Feng JE</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Fideler K</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Sayeed Z</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Meftah M</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Anoushiravani AA</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Schwarzkopf R</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Arthrofibrosis After Total Knee
                  Arthroplasty: Pathophysiology, Diagnosis, and Management</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">Orthop Clin North Am.</span> 2019
                Jul;50(3):269-79.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthrofibrosis%20After%20Total%20Knee%20Arthroplasty%3A%20Pathophysiology%2C%20Diagnosis%2C%20and%20Management&as_occt=title&as_sauthors=%20%22R%20Thompson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Malahias MA</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Birch GA</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Zhong H</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Sideris A</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Gonzalez Della Valle A</span>,
                <span data-id="annotation_119" class="annotation" style="position: unset;"> Sculco PK</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Kirksey M</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Postoperative Serum Cytokine
                  Levels Are Associated With Early Stiffness After Total Knee Arthroplasty: A Prospective Cohort
                  Study</span>. <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2020 Jun;35(6S):S336-47.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postoperative%20Serum%20Cytokine%20Levels%20Are%20Associated%20With%20Early%20Stiffness%20After%20Total%20Knee%20Arthroplasty%3A%20A%20Prospective%20Cohort%20Study&as_occt=title&as_sauthors=%20%22MA%20Malahias%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;ea862d36-4cec-4554-81aa-4d312177b8f5&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=ea862d36-4cec-4554-81aa-4d312177b8f5&type=supplement&name=12538"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dea862d36-4cec-4554-81aa-4d312177b8f5%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="authorinsights_reference"
            class="annotation authorinsights_reference resource-reference" style="position: unset;"></a>
          <div class="content-node authorinsights" data-id="authorinsights" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Author Insights</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6365578856112&quot;,&quot;type&quot;:&quot;author_insights&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;ea862d36-4cec-4554-81aa-4d312177b8f5&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6365578856112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6365578856112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6365578856112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6365578856112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/99ec709c-7054-4531-a36f-a4f2132765e1/e652289b-c779-403b-9aca-703ed19ccb62/1280x720/match/image.jpg"
                        src="blob:https://www.jbjs.org/a9cb038e-100d-45b4-9918-f781b6720195">
                        <track kind="metadata" label="segment-metadata">
                        <track kind="subtitles" label="English" srclang="en" default="" id="English">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/99ec709c-7054-4531-a36f-a4f2132765e1/e652289b-c779-403b-9aca-703ed19ccb62/1280x720/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:47</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:47">
                            <div class="vjs-load-progress" style="width: 5.91%;"><span
                                class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">5.91%</span></span>
                              <div data-start="0" data-end="9.919999" style="left: 0%; width: 100%;"></div>
                            </div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:47</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden vjs-disabled">
                          <button
                            class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button vjs-disabled"
                            type="button" aria-disabled="true" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false" disabled="disabled"><span class="vjs-icon-placeholder"
                              aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu vjs-hidden">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content" role="menu">
                              <li class="vjs-menu-item" tabindex="-1" role="menuitemradio" aria-disabled="false"
                                aria-checked="false"><span class="vjs-menu-item-text">captions off</span><span
                                  class="vjs-control-text" aria-live="polite"></span></li>
                              <li class="vjs-menu-item vjs-subtitles-menu-item vjs-selected" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">English</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button" type="button"
                          title="Picture-in-Picture" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_705_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_705_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <script src="https://vjs.zencdn.net/vttjs/0.15.3/vtt.global.min.js"></script>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): 134</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00309</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 20, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_25"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Bone and Joint Surgery, Renji Hospital, School of Medicine, Shanghai Jiaotong
                            University, Shanghai, People’s Republic of China</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Author(s). Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_1" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ea862d36-4cec-4554-81aa-4d312177b8f5&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=ea862d36-4cec-4554-81aa-4d312177b8f5&type=pdf&name=JBJS.24.00309.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=ea862d36-4cec-4554-81aa-4d312177b8f5&type=pdf&name=JBJS.24.00309.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_30"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study and the
                          Article Processing Charge for open access publication were supported by grants from the
                          National Key R&D Program of China (2016YFC1101802). Suzhou SinoMed Biomaterials provided the
                          PEEK knee systems for this study.The <span data-id="strong_31" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I284"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I284</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ea862d36-4cec-4554-81aa-4d312177b8f5&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=ea862d36-4cec-4554-81aa-4d312177b8f5&type=zip&name=JBJS.24.00309.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Zhuocheng Lyu, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1986-5605" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1986-5605</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ke Yang<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span><span data-id="footnote_reference_1"
                  class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-1768-2972" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-1768-2972</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Zhiguo Yuan, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4469-8802" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4469-8802</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Teng Long, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8158-3575" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8158-3575</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Xinhua Qu, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8889-0890" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8889-0890</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Bing Yue, MD<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3279-9676" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3279-9676</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Xiaolin Liu, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1685-6463" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1685-6463</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">You Wang, MD<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:drwangyou@126.com" class="" style="position: unset;">drwangyou@126.com</a><span
                    class="" style="position: unset;">;</span><a href="mailto:doclxl@163.com" class=""
                    style="position: unset;">doclxl@163.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6331-9401" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6331-9401</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Bone and Joint Surgery, Renji Hospital, School of Medicine,
                Shanghai Jiaotong University, Shanghai, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Zhuocheng Lyu, MD, and Ke Yang contributed equally
                        to this work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 27668.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
