import type {
	Confidence,
	Mode,
	ReportSessionType,
	SessionItem,
	SessionResponse,
} from "@memorang/types";

interface Answer {
	correct?: boolean;
	confidence?: Confidence;
	timeTaken?: number;
	selectedChoiceIds?: string[];
}

interface ItemState {
	selectedChoiceIds?: string[];
	correctAnswerIds?: string[];
	crossedOutChoiceIds?: string[];
	scores: number[];
	answered?: boolean;
	correctAnswerOrder?: string[];
}

type ItemStates = Map<string, ItemState>;
type Answers = Map<string, Answer>;

type UpdateGroupItemStatesType = (
	groupItemId: string,
	actionId: string,
) => void;

export type UpdateItemStatesPayload = {
	itemId: string;
	selectedChoiceId: string;
	multiSelect?: boolean;
	correctAnswerIds?: string[];
	crossOut?: boolean;
	scores?: number[];
	answered?: boolean;
	correctAnswerOrder?: string[];
};
type UpdateItemStatesType = ({
	itemId,
	selectedChoiceId,
	multiSelect,
	correctAnswerIds,
	crossOut,
	scores,
	answered,
}: UpdateItemStatesPayload) => void;

export type BlockState = {
	currentProgress?: number;
	completed?: boolean;
	completedStatuses?: {
		[itemId: string]: "completed" | "skipped";
	};
	markedItemsForReview?: string[];
	highlightedItems?: Map<string, string | undefined>;
};

export type AttemptState = {
	answered?: boolean;
	showTryAgain?: boolean;
	currentSelectedChoiceId?: string;
} & {
	choiceAttempts: Record<
		string,
		{
			correct?: boolean;
			crossOut?: boolean;
			attempted?: boolean;
		}
	>;
};

interface SessionState {
	id: string;
	sessionItems: SessionItem[];
	examName?: string;
	isWeeklyChallenge?: boolean;
	totalTime: number;
	sessionTime: number;
	currentItemIndex: number;
	startTime?: string;
	showMoreItemsAlert?: boolean;
	timeElapsed: number;
	hintUsed?: Map<string, boolean>;
	currentProgress: number;
	isReverseMode?: boolean;
	currentBlockIndex: number;
	reportSessionType?: ReportSessionType;
	mode: Mode;
	numericId: number;
	answers?: Answers;
	numGoal?: number;
	itemStates: ItemStates;
	numItemsRequested: number;
	isRecommendedTask?: boolean;
	isAnswerEventInProgress?: boolean;
	isSummativeTest?: boolean;
	persistedCurrentProgress?: number;
	persistedSessionItems?: SessionItem[];
	inReviewMode?: boolean;
	blockStates: Map<string, BlockState>;
	attempts: Map<string, AttemptState>;
	confettiCannons: Map<string, boolean>;
	isEndingSessionInProgress?: boolean;
	updateHintUsed: (itemId: string) => void;
	updateShowConfetti: ({
		itemId,
		showConfetti,
	}: {
		itemId: string;
		showConfetti: boolean;
	}) => void;
	toggleShowMoreItemsAlert: () => void;

	updateMarkedItemsForReview: (itemId: string) => void;
	updateHighlightedItems: (itemId: string, html?: string) => void;
	updateCurrentBlockIndex: (index: number) => void;
	updateInReview: (inReview: boolean, items: SessionItem[]) => void;
	updateAnswerEventInProgress: (isAnswerEventInProgress: boolean) => void;
	updateEndingSessionInProgress: (isEndingSessionInProgress: boolean) => void;
	updateCurrentItemIndex: (index: number) => void;
	resetSessionStore: () => void;
	updateNumericId: (numericId: number) => void;
	updateAnswers: (id: string, answer: Answer) => void;
	updateItemStates: UpdateItemStatesType;
	updateItemAttempts: ({
		itemId,
		selectedChoiceId,
		correct,
		crossOut,
		answered,
		showTryAgain,
		currentSelectedChoiceId,
		attempted,
	}: {
		itemId: string;
		selectedChoiceId?: string;
		correct?: boolean;
		crossOut?: boolean;
		answered?: boolean;
		showTryAgain?: boolean;
		currentSelectedChoiceId?: string;
		attempted?: boolean;
	}) => void;
	updateProgress: (progress: number) => void;
	updateTimeElapsed: (timeElapsed: number) => void;
	updateProgressForEachBlock: (progress: number, blockId: string) => void;
	updateSession: ({
		sessionResponse,
		mode,
		isReverseMode,
		isSummativeTest,
		inReviewMode,
		isRecommendedTask,
	}: {
		sessionResponse: SessionResponse;
		mode?: Mode;
		isReverseMode?: boolean;
		isSummativeTest?: boolean;
		inReviewMode?: boolean;
		numItemsRequested?: number;
		isRecommendedTask?: boolean;
	}) => void;
}
export type {
	Answer,
	ItemState,
	ItemStates,
	Answers,
	UpdateGroupItemStatesType,
	SessionState,
};
