import type { Article } from "../../../../../types";

export const Article617: Article = {
	id: 617,
	rsuiteId: "7be0f8e8-aac2-4591-9003-27cede48ec4b",
	type: "the orthopaedic forum",
	title: "Virtual Clinical Trials",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=7be0f8e8-aac2-4591-9003-27cede48ec4b&type=jpeg&name=JBJS.23.01236f1",
	subSpecialties: ["spine"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Virtual Clinical Trials</div>
              <div class="text subtitle" style="position: unset;">Implications of Computer Simulations and Artificial
                Intelligence for Musculoskeletal Research</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Steven P.
                      Mell, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Alexander L.
                      Hornung, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 2 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract:</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">In silico clinical trials, particularly when
                        augmented with artificial intelligence methods, represent an innovative approach with much to
                        offer, particularly in the musculoskeletal field. They are a cost-effective, efficient, and
                        ethical means of evaluating treatments and interventions by supplementing and/or augmenting
                        traditional randomized controlled trials (RCTs). While they are not a panacea and should not
                        replace traditional RCTs, their integration into the research process promises to accelerate
                        medical advancements and improve patient outcomes. To accomplish this, a multidisciplinary
                        approach is needed, and collaboration is instrumental. With advances in computing and analytical
                        prowess, and by adhering to the tenets of team science, realization of such a novel integrative
                        approach toward clinical trials may not be far from providing far-reaching contributions to
                        medical research. As such, by harnessing the power of in silico clinical trials, investigators
                        can potentially unlock new possibilities in treatment and intervention for ultimately improving
                        patient care and outcomes.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Randomized controlled trials (RCTs) are considered the
                  “gold standard” in medical research for evaluating the safety as well as the efficacy and
                  effectiveness of new treatments or interventions. The history of RCTs dates back to the mid-twentieth
                  century, when they were first proposed by a British statistician, Austin Bradford Hill, as a method
                  for evaluating the effectiveness of medical treatments<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">2</span></a>. This concept was first utilized in the late 1940s to test
                  the effectiveness of streptomycin in treating tuberculosis<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a>. Over
                  the course of the following 10 years, RCTs became more widely accepted as a method for evaluating
                  medical treatments. In 1962, the United States Food and Drug Administration (FDA) implemented policies
                  requiring pharmaceutical companies to conduct RCTs to demonstrate the safety and effectiveness of new
                  drugs before they could be approved for use<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a>.
                  Today, RCTs continue to be an essential tool for medical professionals across all disciplines when
                  evaluating interventions for their preventive, diagnostic, and therapeutic utility, and for ensuring
                  that ethical and regulatory standards are upheld to promote public safety. In the musculoskeletal
                  field, RCTs, whether company-sponsored or investigator-initiated, have addressed a variety of topics,
                  ranging from arthroplasty devices to chiropractic manipulation approaches to specific drugs for pain
                  management<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">6</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">The main strength of the RCT is its potential for
                  lessening allocation bias, as it is the only study design that allows balancing unknown and
                  potentially confounding prognostic factors at the initiation of the study<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">7</span></a>. However, despite the advantages of RCTs regarding internal
                  validity and causal inference, RCTs are often expensive for the institution and the consumer due to
                  their complicated and often time-consuming nature; they may require &gt;15 years to reach completion<a
                    href="" data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">10</span></a>.
                  Interestingly, two-thirds of the total cost of discovering and developing a new drug is incurred
                  during the clinical-testing phase<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">12</span></a>. This is likely secondary to patient recruitment, which can
                  be challenging, particularly for studies with strict inclusion and exclusion criteria or those
                  requiring a substantial time commitment from or burden on participants, with nearly 30% of Phase-3
                  studies being terminated as a result of enrollment issues<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">13</span></a>.
                  Furthermore, there is always a risk of adverse events or side effects in subjects who are
                  participating in RCTs; although these risks are carefully assessed and monitored, it is ultimately
                  impossible to completely eliminate the chances of adverse events. Finally, RCTs are not without their
                  ethical concerns. The susceptibility of RCTs to unethical practice has been highlighted historically
                  with the testing of new treatments on vulnerable populations or underserved patients. Also, only 2% of
                  the eligible population in the United States participates in clinical trials, which leads to bias<a
                    href="" data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">16</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Therefore, there is an urgent need to address such
                  limitations of RCTs. One such approach would be to augment or supplement traditional clinical trials
                  with “models.” A model is a simplified representation of a complex system, the primary purpose of
                  which is to help to better understand the system and to predict future responses. Models of many kinds
                  are applied across all fields of science and take many forms. Animal models, for example, are already
                  used extensively in medicine during preclinical testing as a precursor to human testing, with the
                  explicit goal of establishing safety and efficacy prior to the human testing, thereby identifying
                  potentially ineffective interventions and promoting cost savings on expensive and potentially harmful
                  RCTs<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">18</span></a>. Cell and tissue culture models, which fall under the
                  umbrella of in vitro models, are also widely used<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">19</span></a>.
                  Such preclinical models have already proven to be useful in the process of demonstrating the
                  effectiveness and/or safety of interventions prior to testing in human subjects.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">With the rise of the digital age, another modeling
                  approach that has come to the forefront of medicine is computer modeling, also known as in silico
                  modeling<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">21</span></a>. Today, advanced computational techniques already are used
                  extensively in biomedical science for applications that include bioinformatics, genome-wide
                  association studies, computer simulations of physical and/or mechanical phenomenon, and more. Moore’s
                  law, which states that the number of transistors in an integrated circuit doubles every 2 years, has
                  made it increasingly possible to augment and/or supplement traditional RCTs with in silico methods<a
                    href="" data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">23</span></a>. This exponential growth in computing power has enabled
                  machine learning and other types of artificial intelligence (AI) to become more accessible for
                  specialized research communities as well as the global population.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">An “in silico clinical trial” refers to a framework
                  whereby a cohort of subjects is represented virtually using a well-established, validated, and
                  verified computational model on which machine learning also can be employed<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">24</span></a>. An intervention can then be simulated on this virtual
                  cohort, and the effect of the intervention can be analyzed either statistically or with use of AI
                  methods. An in silico clinical trial differs from a traditional computational experiment in its
                  approach. While a traditional computational experiment is more akin to a laboratory experiment, an in
                  silico clinical trial is approached in the same methodical manner as a traditional RCT; it
                  incorporates population- and/or patient-specific data in a probabilistic manner to attempt to capture
                  variability in human physiology and pathology.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">This in silico approach offers several advantages over a
                  traditional RCT. It is cost-effective, as it reduces the burden of recruiting and monitoring large
                  cohorts of human subjects, and it reduces all of the logistics and pitfalls surrounding this process<a
                    href="" data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">24</span></a>. Depending on the computational complexity of the
                  underlying model, researchers can test a much larger number of virtual subjects compared with what
                  would be possible in a traditional RCT. This would potentially enable capturing subjects at the
                  extremes of the population’s probability distribution, therefore predicting adverse events that might
                  not present themselves in a traditional RCT. In addition, in silico clinical trials enable
                  simultaneous rapid testing of multiple interventions in the same “virtual” subject, allowing for
                  greater statistical power and more controlled statistical methods. Finally, in silico clinical trials
                  pose no ethical concerns and no harm to subjects, allowing for the conducting of innovative studies
                  that otherwise would not be ethically or physically possible. It is because of these advantages that
                  in silico clinical trials present a promising avenue for the future of biomedical research—they can be
                  used to test and predict outcomes for a variety of treatments and conditions, ranging from the effects
                  of surgical approaches and/or procedures to different implant designs and materials to different
                  rehabilitation strategies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">There is also an exciting opportunity for clinicians and
                  researchers in all disciplines to augment in silico clinical trials with AI methodologies (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). AI methods could conceivably be incorporated in an in silico
                  clinical trial on either the front end through generative AI approaches or the back end through AI and
                  machine learning data analysis strategies. Generative AI could be used to generate a “virtual patient”
                  through a variety of sampling, generative, and “big data” approaches. Depending on the goals of the
                  study, there are many ways to generate virtual patients and their features of interest. An example
                  from a musculoskeletal modeling application could include variational autoencoders to generate time
                  series data, such as movement patterns and forces. Statistical shape modeling could then be used to
                  generate realistic anatomy, while other information about patients could be probabilistically sampled
                  from real-life patient cohorts. Using generative AI, thousands of virtual patients could be generated
                  to create a massive virtual cohort that is statistically representative of the actual population
                  distribution. This virtual cohort could then be used in an in silico clinical trial. In our example,
                  this could include simulating an intervention using techniques such as musculoskeletal modeling and
                  finite element analysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01236f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=7be0f8e8-aac2-4591-9003-27cede48ec4b&type=jpeg&name=JBJS.23.01236f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;">Virtual clinical trial framework.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Once a large data set of outcomes is generated by an
                  underlying in silico model, there is a plethora of AI and machine learning methods, such as artificial
                  neural networks, unsupervised clustering, and classification, that can be used to analyze the results
                  on the back end. Another use of AI in the augmentation of an in silico clinical trial is through
                  surrogate models, whereby an underlying high-fidelity computational model, such as a finite element
                  model, is used to generate an initial data set, and is then subsequently used to train a following
                  statistical or AI model, which can make subsequent predictions more efficiently than the initial
                  underlying model<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">25</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">It must be noted that the approach of in silico clinical
                  trials augmented by AI is not intended to replace traditional RCTs. Rather, the goal is to supplement,
                  or augment, traditional RCTs in such a way as to reduce the economic and time burden on both
                  researchers and the health-care system. As such, this process can take many forms. The large number of
                  subjects one can test using in silico clinical trials could be leveraged to capture adverse events
                  more readily than a traditional RCT, and in many cases substantially faster. A device failure, for
                  example, may take several years to occur in vivo, whereas an in silico clinical trial may capture this
                  outcome within the time it takes to run the model (usually within seconds to months, depending on the
                  application). A traditional RCT could also be performed in parallel, possibly with a reduced sample
                  size, for the purpose of cross-validation, ensuring that the in silico clinical trial is in fact a
                  representative model of the system. Additionally, in silico clinical trials could be used in a similar
                  manner to preclinical animal and in vitro models as an initial check of the treatment or intervention,
                  with a subsequent traditional RCT being performed only if the results of the in silico clinical trial
                  are promising. Finally, in cases where it may be unethical or dangerous to perform testing in humans,
                  an in silico clinical trial could be used as an alternative strategy.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Limitations
                of in Silico Clinical Trials</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">It is important to note that in silico clinical trials are
                  not without limitations. Simulating complex physiological processes can be very difficult and requires
                  accurate mathematical representations of complex biomechanical and/or biochemical interactions.
                  Therefore, it is not always possible or feasible to account for every adverse event that may occur.
                  For instance, challenges that are intrinsic to specific procedures, rejection of biological materials
                  without a comprehensive safety history, psychosocial factors, or unexpected interactions with patient
                  characteristics may not be accounted for in a physics-based finite element model. For this reason, it
                  is imperative to carefully document and methodically characterize the capabilities of the underlying
                  model, and to clearly define model inputs, the clinical question, and the end points of the study.
                  Therefore, knowing the capabilities and limitations of in silico clinical trials is crucial for their
                  appropriate use and implementation. As an example, studying the effect on mechanics of a change in an
                  implant design that uses well-studied materials and common implantation methods, at purely mechanical
                  end points, would be a perfect use for an in silico clinical trial. In contrast, studying a completely
                  new implant with untested materials and surgical implantation methods may not be a good use of an in
                  silico trial, and would necessitate, at the very least, that a traditional RCT be run in parallel.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Summary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">In silico clinical trials, particularly when augmented
                  with AI methods, represent an innovative approach with much to offer, particularly in the
                  musculoskeletal field. They are a cost-effective, efficient, and ethical means of evaluating
                  treatments and interventions by supplementing and/or augmenting traditional RCTs. While they are not a
                  panacea and should not replace traditional RCTs, their integration into the research process promises
                  to accelerate medical advancements and improve patient outcomes. To accomplish this, a
                  multidisciplinary approach is needed, and collaboration is instrumental<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">23</span></a>. With advances in computing and analytical prowess, and by
                  adhering to the tenets of team science, realization of such a novel integrative approach toward
                  clinical trials may not be far from providing far-reaching contributions to medical research. As such,
                  by harnessing the power of in silico clinical trials, investigators can potentially unlock new
                  possibilities in treatment and intervention for ultimately improving patient care and outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="strong_1" class="annotation strong" style="position: unset;">STREPTOMYCIN treatment of
                  pulmonary tuberculosis</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Br Med J.</span> 1948 Oct 30;2(4582):769-82.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Yoshioka A</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Use of randomisation in the
                  Medical Research Council’s clinical trial of streptomycin in pulmonary tuberculosis in the
                  1940s</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">BMJ.</span> 1998 Oct 31;317(7167):1220-3.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Use%20of%20randomisation%20in%20the%20Medical%20Research%20Council%E2%80%99s%20clinical%20trial%20of%20streptomycin%20in%20pulmonary%20tuberculosis%20in%20the%201940s&as_occt=title&as_sauthors=%20%22A%20Yoshioka%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Long ER</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Ferebee SH</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">A controlled investigation of
                  streptomycin treatment in pulmonary tuberculosis</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Public Health Rep(</span> (1896). 1950 Nov
                3;65(44):1421-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20controlled%20investigation%20of%20streptomycin%20treatment%20in%20pulmonary%20tuberculosis&as_occt=title&as_sauthors=%20%22ER%20Long%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Williams CT</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Food and Drug Administration
                  Drug Approval Process: A History and Overview</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Nurs Clin North Am.</span> 2016
                Mar;51(1):1-11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Food%20and%20Drug%20Administration%20Drug%20Approval%20Process%3A%20A%20History%20and%20Overview&as_occt=title&as_sauthors=%20%22CT%20Williams%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Goedmakers CMW</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> de Vries F</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Bosscher L</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Peul WC</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Arts MP</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Vleggeert-Lankamp CLA</span>.
                <span data-id="strong_5" class="annotation strong" style="position: unset;">Long-term results of the
                  NECK trial-implanting a disc prosthesis after cervical anterior discectomy cannot prevent adjacent
                  segment disease: five-year clinical follow-up of a double-blinded randomised controlled trial</span>.
                <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Spine J.</span> 2023
                Mar;23(3):350-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20results%20of%20the%20NECK%20trial-implanting%20a%20disc%20prosthesis%20after%20cervical%20anterior%20discectomy%20cannot%20prevent%20adjacent%20segment%20disease%3A%20five-year%20clinical%20follow-up%20of%20a%20double-blinded%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22CMW%20Goedmakers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Shea GKH</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Zhang C</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Suen WS</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Cheung PWH</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Cheung JPY</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Maatta J</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Karppinen J</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Samartzis D</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Oral Zoledronic acid
                  bisphosphonate for the treatment of chronic low back pain with associated Modic changes: A pilot
                  randomized controlled trial</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Orthop Res.</span> 2022 Dec;40(12):2924-36.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Oral%20Zoledronic%20acid%20bisphosphonate%20for%20the%20treatment%20of%20chronic%20low%20back%20pain%20with%20associated%20Modic%20changes%3A%20A%20pilot%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22GKH%20Shea%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Vavken P</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Ganal-Antonio AK</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Shen FH</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Chapman JR</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Samartzis D</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Fundamentals of clinical
                  outcomes assessment for spinal disorders: study designs, methodologies, and analyses</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Global Spine J.</span> 2015
                Apr;5(2):156-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fundamentals%20of%20clinical%20outcomes%20assessment%20for%20spinal%20disorders%3A%20study%20designs%2C%20methodologies%2C%20and%20analyses&as_occt=title&as_sauthors=%20%22P%20Vavken%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Ali Z</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Zibert JR</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Thomsen SF</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Virtual Clinical Trials:
                  Perspectives in Dermatology</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Dermatology.</span> 2020;236(4):375-82.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Virtual%20Clinical%20Trials%3A%20Perspectives%20in%20Dermatology&as_occt=title&as_sauthors=%20%22Z%20Ali%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Prasad V</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Mailankody S</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Research and Development
                  Spending to Bring a Single Cancer Drug to Market and Revenues After Approval</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">JAMA Intern Med.</span> 2017
                Nov 1;177(11):1569-75.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Research%20and%20Development%20Spending%20to%20Bring%20a%20Single%20Cancer%20Drug%20to%20Market%20and%20Revenues%20After%20Approval&as_occt=title&as_sauthors=%20%22V%20Prasad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Sanson-Fisher RW</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Bonevski B</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Green LW</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> D’Este C</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Limitations of the randomized
                  controlled trial in evaluating population-based health interventions</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Am J Prev Med.</span> 2007
                Aug;33(2):155-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Limitations%20of%20the%20randomized%20controlled%20trial%20in%20evaluating%20population-based%20health%20interventions&as_occt=title&as_sauthors=%20%22RW%20Sanson-Fisher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Akhondzadeh S</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">The Importance of Clinical
                  Trials in Drug Development</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Avicenna J Med Biotechnol.</span> 2016 Oct-Dec;8(4):151.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Importance%20of%20Clinical%20Trials%20in%20Drug%20Development&as_occt=title&as_sauthors=%20%22S%20Akhondzadeh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Hardman TC</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Aitchison R</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Scaife R</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Edwards J</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Slater G</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The future of clinical trials
                  and drug development: 2050</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Drugs Context.</span> 2023 Jun 8;12:2023-2-2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20future%20of%20clinical%20trials%20and%20drug%20development%3A%202050&as_occt=title&as_sauthors=%20%22TC%20Hardman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Carlisle B</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Kimmelman J</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Ramsay T</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> MacKinnon N</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Unsuccessful trial accrual and
                  human subjects protections: an empirical analysis of recently closed trials</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Clin Trials.</span> 2015
                Feb;12(1):77-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Unsuccessful%20trial%20accrual%20and%20human%20subjects%20protections%3A%20an%20empirical%20analysis%20of%20recently%20closed%20trials&as_occt=title&as_sauthors=%20%22B%20Carlisle%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Alegria M</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Sud S</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Steinberg BE</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Gai N</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Siddiqui A</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Reporting of Participant Race,
                  Sex, and Socioeconomic Status in Randomized Clinical Trials in General Medical Journals, 2015 vs
                  2019</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">JAMA
                  Netw Open.</span> 2021 May 3;4(5):e2111516.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reporting%20of%20Participant%20Race%2C%20Sex%2C%20and%20Socioeconomic%20Status%20in%20Randomized%20Clinical%20Trials%20in%20General%20Medical%20Journals%2C%202015%20vs%202019&as_occt=title&as_sauthors=%20%22M%20Alegria%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Comis RL</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Miller JD</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Aldigé CR</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Krebs L</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Stoval E</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Public attitudes toward
                  participation in cancer clinical trials</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">J Clin Oncol.</span> 2003 Mar
                1;21(5):830-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Public%20attitudes%20toward%20participation%20in%20cancer%20clinical%20trials&as_occt=title&as_sauthors=%20%22RL%20Comis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Heller C</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Balls-Berry JE</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Nery JD</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Erwin PJ</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Littleton D</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Kim M</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Kuo WP</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Strategies addressing barriers
                  to clinical trial enrollment of underrepresented populations: a systematic review</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Contemp Clin Trials.</span>
                2014 Nov;39(2):169-82.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Strategies%20addressing%20barriers%20to%20clinical%20trial%20enrollment%20of%20underrepresented%20populations%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22C%20Heller%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Curt GA</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">The use of animal models in
                  cancer drug discovery and development</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Stem Cells.</span> 1994 Jan;12(1):23-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20animal%20models%20in%20cancer%20drug%20discovery%20and%20development&as_occt=title&as_sauthors=%20%22GA%20Curt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Rios JL</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Sapède D</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Djouad F</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Rapp AE</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Lang A</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Larkin J</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Ladel C</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Mobasheri A</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Animal Models of Osteoarthritis
                  Part 1-Preclinical Small Animal Models: Challenges and Opportunities for Drug Development</span>.
                <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Curr Protoc.</span>
                2022 Nov;2(11):e596.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Animal%20Models%20of%20Osteoarthritis%20Part%201-Preclinical%20Small%20Animal%20Models%3A%20Challenges%20and%20Opportunities%20for%20Drug%20Development&as_occt=title&as_sauthors=%20%22JL%20Rios%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Shi P</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Chee A</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Liu W</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Chou PH</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Zhu J</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> An HS</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Therapeutic effects of cell
                  therapy with neonatal human dermal fibroblasts and rabbit dermal fibroblasts on disc degeneration and
                  inflammation</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2019 Jan;19(1):171-81.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Therapeutic%20effects%20of%20cell%20therapy%20with%20neonatal%20human%20dermal%20fibroblasts%20and%20rabbit%20dermal%20fibroblasts%20on%20disc%20degeneration%20and%20inflammation&as_occt=title&as_sauthors=%20%22P%20Shi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Church RJ</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Watkins PB</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">In silico modeling to optimize
                  interpretation of liver safety biomarkers in clinical trials</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Exp Biol Med (Maywood).</span> 2018
                Feb;243(3):300-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=In%20silico%20modeling%20to%20optimize%20interpretation%20of%20liver%20safety%20biomarkers%20in%20clinical%20trials&as_occt=title&as_sauthors=%20%22RJ%20Church%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Tran VD</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Nguyen TN</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Ballit A</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Dao TT</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Novel Baseline Facial Muscle
                  Database Using Statistical Shape Modeling and In Silico Trials toward Decision Support for Facial
                  Rehabilitation</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Bioengineering (Basel).</span> 2023 Jun 19;10(6):737.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Novel%20Baseline%20Facial%20Muscle%20Database%20Using%20Statistical%20Shape%20Modeling%20and%20In%20Silico%20Trials%20toward%20Decision%20Support%20for%20Facial%20Rehabilitation&as_occt=title&as_sauthors=%20%22VD%20Tran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Mallow GM</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Hornung A</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Barajas JN</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Rudisill SS</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> An HS</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Samartzis D</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Quantum Computing: The Future
                  of Big Data and Artificial Intelligence in Spine</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Spine Surg Relat Res.</span> 2022 Feb
                10;6(2):93-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quantum%20Computing%3A%20The%20Future%20of%20Big%20Data%20and%20Artificial%20Intelligence%20in%20Spine&as_occt=title&as_sauthors=%20%22GM%20Mallow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Mallow GM</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Siyaji ZK</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Galbusera F</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Espinoza-Orías AA</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Giers M</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Lundberg H</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Ames C</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Karppinen J</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Louie PK</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Phillips FM</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Pourzal R</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Schwab J</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Sciubba DM</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Wang JC</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Wilke HJ</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Williams FMK</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Mohiuddin SA</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Makhni MC</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Shepard NA</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> An HS</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Samartzis D</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Intelligence-Based Spine Care
                  Model: A New Era of Research and Clinical Decision-Making</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">Global Spine J.</span> 2021
                Mar;11(2):135-45.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intelligence-Based%20Spine%20Care%20Model%3A%20A%20New%20Era%20of%20Research%20and%20Clinical%20Decision-Making&as_occt=title&as_sauthors=%20%22GM%20Mallow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Favre P</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Maquer G</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Henderson A</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Hertig D</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Ciric D</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Bischoff JE</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">In Silico Clinical Trials in
                  the Orthopedic Device Industry: From Fantasy to Reality?</span><span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Ann Biomed Eng.</span> 2021
                Dec;49(12):3213-26.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=In%20Silico%20Clinical%20Trials%20in%20the%20Orthopedic%20Device%20Industry%3A%20From%20Fantasy%20to%20Reality%3F&as_occt=title&as_sauthors=%20%22P%20Favre%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Mell SP</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Wimmer MA</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Jacobs JJ</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Lundberg HJ</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Optimal surgical component
                  alignment minimizes TKR wear - An in silico study with nine alignment parameters</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J Mech Behav Biomed
                  Mater.</span> 2022 Jan;125:104939.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Optimal%20surgical%20component%20alignment%20minimizes%20TKR%20wear%20-%20An%20in%20silico%20study%20with%20nine%20alignment%20parameters&as_occt=title&as_sauthors=%20%22SP%20Mell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D7be0f8e8-aac2-4591-9003-27cede48ec4b%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2400</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01236</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 20, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;7be0f8e8-aac2-4591-9003-27cede48ec4b&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=7be0f8e8-aac2-4591-9003-27cede48ec4b&type=pdf&name=JBJS.23.01236.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=7be0f8e8-aac2-4591-9003-27cede48ec4b&type=pdf&name=JBJS.23.01236.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_26"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_27" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I79" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I79</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;7be0f8e8-aac2-4591-9003-27cede48ec4b&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=7be0f8e8-aac2-4591-9003-27cede48ec4b&type=zip&name=JBJS.23.01236.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Steven P. Mell, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1109-9336" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1109-9336</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alexander L. Hornung, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7041-3319" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7041-3319</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Catherine Yuh, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2544-5906" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2544-5906</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dino Samartzis, DSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Dino_Samartzis@rush.edu" class=""
                    style="position: unset;">Dino_Samartzis@rush.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7473-1311" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7473-1311</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Rush University Medical Center, Chicago,
                Illinois</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 15229.2px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
