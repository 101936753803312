/* ----------------- React --------------- */
import {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	useTransition,
} from "react";

/* ----------------- React Native --------------- */
import { Platform } from "react-native";

/* ----------------- Constants --------------- */
import {
	ENDPOINT_ASSISTANT_SESSION_REVIEW,
	ENDPOINT_THREADS,
	GET_THREAD_QUERY_KEY,
	RECOMMENDED_QUESTIONS_QUERY_KEY,
	initialAiMessage,
} from "../constants";

import type { AiAssistantInfo, MessageData } from "@memorang/ui";
import type { RecommendedQuestions } from "../../types";
import type { OriginalQuestionData } from "../types/question";
/* ----------------- Types --------------- */
import type {
	EpubParams,
	Thread,
	VectorDocument,
	VectorStoreParams,
} from "./../types/";

/* ----------------- Libraries --------------- */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import EventSource from "react-native-sse";
import { v4 as uuidv4 } from "uuid";

/* ----------------- Misc --------------- */
//@ts-ignore
import packageJson from "../../../package.json";
import { getInitialQuestionMarkdown } from "../helpers";
import { fetchRecommendedQueries } from "./useRecommendedQueries";

type Props = {
	activityType: "review" | "reader";
	context: string;
	assistantInfo: AiAssistantInfo;
	searchQuery?: {
		value: string;
	};
	vectorStoreParams: VectorStoreParams;
	sessionId?: string;
	userId?: string;
	epubParams?: EpubParams;
	topic?: string;
	originalQuestionData?: OriginalQuestionData;
	action?: "new" | null;
	userPrompt?: string;
	defaultInitialTitle?: string;
	initialFollowUps?: string[];
	filterSourceIds?: string[];
	extraDetails?: string;
};

const version = packageJson.version;

const useSearch = ({
	activityType,
	context,
	assistantInfo,
	searchQuery,
	vectorStoreParams,
	sessionId,
	userId,
	epubParams,
	topic,
	originalQuestionData,
	action = null,
	userPrompt,
	defaultInitialTitle,
	initialFollowUps = [],
	filterSourceIds,
	extraDetails,
}: Props) => {
	const queryClient = useQueryClient();
	const [streaming, setStreaming] = useState(false);
	const [loading, setLoading] = useState(false);
	const [_isPending, startTransition] = useTransition();
	const [summaryResponse, setSummaryResponse] = useState<string | undefined>(
		undefined,
	);

	const eventSourceRef = useRef<EventSource | null>(null);

	const [references, setReferences] = useState<VectorDocument[] | []>([]);
	const [showReference, setShowReference] = useState<boolean | undefined>(
		undefined,
	);
	const [threadAction, setThreadAction] = useState(action);

	const { examId, itemId, contextId } = vectorStoreParams;

	const threadQueryKey = useMemo(
		() => [
			GET_THREAD_QUERY_KEY,
			examId,
			userId,
			itemId,
			threadAction,
			activityType,
		],
		[examId, userId, itemId, threadAction, activityType],
	);

	const {
		isLoading: isThreadLoading,
		isRefetching: isThreadRefetching,
		data: thread,
	} = useQuery<Thread>({
		queryKey: threadQueryKey,
		refetchOnWindowFocus: false,
		staleTime: 0,

		queryFn: async () => {
			const result = await fetch(ENDPOINT_THREADS, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					assistantId: examId,
					userId: userId,
					itemId: itemId,
					activityType,
					// TODO: this will be passed for reader (new chat)
					...(threadAction ? { action: threadAction } : {}),
					// action: 'new',
				}),
			});
			const body = await result.json();
			body.initialTitle = defaultInitialTitle;
			const { topic, followUps } =
				await queryClient.ensureQueryData<RecommendedQuestions>({
					queryKey: [RECOMMENDED_QUESTIONS_QUERY_KEY, userPrompt],
					queryFn: ({ signal }) =>
						fetchRecommendedQueries({
							userPrompt: userPrompt!,
							vectorStoreParams,
							userId,
							epubParams,
							signal,
						}),
				});
			if (topic && followUps) {
				const initialMessage: MessageData = {
					id: uuidv4(),
					type: "assistant",
					content: initialAiMessage({
						context,
						topic,
						aiAssistantInfo: assistantInfo,
						isArticle: activityType === "reader",
						multipleArticles: (filterSourceIds?.length ?? 0) > 1,
					}),
					loading: false,
					streaming: false,
					references: originalQuestionData
						? getInitialQuestionMarkdown(originalQuestionData)
						: undefined,
				};
				body.messages = [initialMessage, ...body.messages];
				const lastMessage = body.messages.at(-1);
				const sourceCount = filterSourceIds?.length ?? 0;
				lastMessage.followUps = [
					...(sourceCount > 0 && sourceCount < 6
						? [
								`Summarize ${sourceCount === 1 ? "this article" : "these articles"}`,
							]
						: []),
					...followUps,
				];
				body.initialTitle = topic;
			}
			if (!followUps) {
				body.messages[body.messages.length - 1].followUps = [
					...initialFollowUps,
				];
			}
			return body;
		},
	});

	const setLocalMessages = (
		updateFunctionOrValue:
			| ((prevMessages: MessageData[]) => MessageData[])
			| MessageData[],
	) => {
		queryClient.setQueryData<Thread | undefined>(threadQueryKey, (prevData) => {
			if (prevData) {
				if (Array.isArray(updateFunctionOrValue)) {
					prevData.messages = updateFunctionOrValue;
				} else {
					prevData.messages = updateFunctionOrValue(prevData.messages);
				}
			}
			return prevData;
		});
	};

	const addMessage = (message: MessageData) => {
		queryClient.setQueryData<Thread | undefined>(threadQueryKey, (prevData) => {
			if (prevData) {
				prevData.messages = [...prevData.messages, message];
			}
			return prevData;
		});
	};

	const { mutate: mutateSummary } = useMutation({
		mutationFn: async ({
			examId,
			query,
			cacheKey,
			epubProps,
			threadId,
			originalQuestionData,
			extraDetails,
		}: {
			examId: string;
			query: string;
			cacheKey: string;
			threadId: string;
			epubProps?: EpubParams;
			originalQuestionData?: OriginalQuestionData;
			extraDetails?: string;
		}) => {
			setLoading(true);
			setReferences([]);
			const originalQuestion = originalQuestionData && {
				stem: originalQuestionData.stem,
				explanation: originalQuestionData.explanation,
				topics: originalQuestionData.topics,
				correctChoice:
					originalQuestionData.choices.find((choice) => choice.isCorrect)
						?.text || "",
				incorrectChoices: originalQuestionData.choices
					.filter((choice) => !choice.isCorrect)
					.map((choice) => choice.text || ""),
			};

			const es = new EventSource(ENDPOINT_ASSISTANT_SESSION_REVIEW, {
				method: "POST",
				headers: { "Content-Type": "text/event-stream" },
				body: JSON.stringify({
					query,
					cacheKey,
					scope: epubProps?.scope,
					isSummarize: epubProps?.isSummarize,
					pageText: epubProps?.pageText,
					selectedText: epubProps?.selectedText,
					filterSourceIds,
					platform: Platform.OS,
					examId,
					assistantId: examId,
					threadId,
					originalQuestion,
					// TODO: Remove when reader uses the new API
					categoryId: [examId],
					sessionId: sessionId || threadId,
					userId,
					itemId,
					contextId,
					topic,
					...(epubProps || {}),
					activityType,
					extraDetails,
				}),
			});
			eventSourceRef.current = es;

			es.addEventListener("open", (_event) => {
				// console.info("Open SSE connection.");
			});

			es.addEventListener("message", (event) => {
				if (!event.data) {
					return;
				}
				const data = JSON.parse(event.data);

				if (data.type === "run-details") {
					startTransition(() => {
						setLocalMessages((prevMessages) => {
							const lastMessage = prevMessages[prevMessages.length - 1];
							if (!lastMessage) {
								return prevMessages;
							}

							if (data.showSources) {
								setShowReference(true);
							}

							if (data.tools) {
								lastMessage.tools = {
									...data.tools,
								};
							}

							if (!lastMessage.runId) {
								lastMessage.runId = data.runId;
							}

							if (data.sources && data.showSources) {
								lastMessage.sources = data.sources;
							}
							return [...prevMessages];
						});
					});
					return;
				}
				if (data?.close) {
					es.close();
					return;
				}

				if (!streaming) {
					setLoading(false);
					setStreaming(true);
					setLocalMessages((prevMessages) => {
						const lastMessage = prevMessages[prevMessages.length - 1];
						if (lastMessage) {
							lastMessage.loading = false;
							lastMessage.streaming = true;
						}
						return [...prevMessages];
					});
				}

				if (data.followUps) {
					setLocalMessages((prevMessages) => {
						const lastMessage = prevMessages[prevMessages.length - 1];
						lastMessage.followUps = data.followUps;
						return [...prevMessages];
					});
				}

				if (data.response) {
					setLocalMessages((prevMessages) => {
						const lastMessage = prevMessages[prevMessages.length - 1];
						lastMessage.content = data.response;
						return [...prevMessages];
					});

					setSummaryResponse(data.response);
				}
			});

			es.addEventListener("close", (_event) => {
				eventSourceRef.current = null;
				setStreaming(false);
				setLocalMessages((prevMessages) => {
					const lastMessage = prevMessages[prevMessages.length - 1];
					if (
						!lastMessage ||
						lastMessage.type !== "assistant" ||
						!lastMessage.id
					) {
						return prevMessages;
					}
					lastMessage.streaming = false;
					return [...prevMessages];
				});
			});

			es.addEventListener("error", (event) => {
				if (event.type === "error") {
					console.error("Connection error:", event.message);
				} else if (event.type === "exception") {
					console.error("Error:", event.message, event.error);
				}
			});
		},
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	const abortSearch = useCallback(async () => {
		if (eventSourceRef.current) {
			eventSourceRef.current?.close();
			setLoading(false);
			if (loading && !streaming) {
				setSummaryResponse(undefined);
				setLocalMessages((prevMessages) => {
					const lastMessage = prevMessages[prevMessages.length - 1];
					if (lastMessage?.loading) {
						prevMessages.pop();
					}
					return [...prevMessages];
				});
			}
			setStreaming(false);
		}
	}, [loading, streaming, queryClient]);

	const getSummary = async () => {
		if (examId && searchQuery?.value && vectorStoreParams) {
			const data = await queryClient.ensureQueryData<Thread | undefined>({
				queryKey: threadQueryKey,
			});
			if (data) {
				const cacheKey = `${version} : ${JSON.stringify(vectorStoreParams)} : ${
					searchQuery.value
				}`;
				mutateSummary({
					examId,
					query: searchQuery.value,
					cacheKey,
					epubProps: epubParams,
					threadId: data.id,
					originalQuestionData,
					extraDetails,
				});
				return () => {
					eventSourceRef.current?.close();
				};
			}
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	useEffect(() => {
		getSummary();
	}, [searchQuery?.value]);

	const resetSearch = useCallback(
		(shouldResetMessages = false) => {
			if (shouldResetMessages) {
				if (threadAction === "new") {
					queryClient.invalidateQueries({
						queryKey: ["thread"],
					});
				} else {
					setThreadAction("new");
				}
			}
			setSummaryResponse(undefined);
			setStreaming(false);
			setReferences([]);
		},
		[queryClient, threadAction],
	);

	const lastFollowUps =
		thread?.messages?.[thread?.messages.length - 1]?.followUps;

	const secondLastFollowUps =
		thread?.messages?.[thread?.messages.length - 3]?.followUps;

	return {
		loading: loading || isThreadLoading || isThreadRefetching,
		summaryResponse,
		references,
		streaming,
		setMessages: setLocalMessages,
		addMessage,
		messages: thread?.messages || [],
		setSummaryResponse,
		resetSearch,
		showReference,
		abortSearch,
		isThreadLoading: isThreadLoading || isThreadRefetching,
		thread,
		initialTitle: thread?.initialTitle,
		followUps: lastFollowUps?.length
			? lastFollowUps
			: secondLastFollowUps || [],
	};
};

export { useSearch };
