import type { Image, Score } from "../..";

interface Choice {
	id: string;
	text: string;
	isCorrect?: boolean;
	explanation?: string;
	stem: string;
	images?: Image[];
}
enum QuestionVariant {
	mcq = "MCQ",
	sata = "SATA",
	speaking = "speaking",
	writing = "writing",
	sequence = "sequence",
}
interface Question {
	id: string;
	children: Choice[];
	stem: string;
	prompt?: string;
	images?: Image[];
	variant?: QuestionVariant;
	currentScore?: Score;
}

export { type Question, QuestionVariant };
