type FontWeights =
	| "800"
	| "700"
	| "600"
	| "500"
	| "400"
	| "300"
	| "100"
	| "bold"
	| "normal"
	| "200"
	| "900"
	| undefined;

const makeFont = (fontFamily: string, fontWeight: FontWeights) => ({
	fontFamily,
	fontWeight,
});

const fontWeights: Record<string, FontWeights> = {
	extraBold: "800",
	bold: "700",
	semiBold: "600",
	medium: "500",
	regular: "400",
	light: "300",
	thin: "100",
};

const fontSizes = {
	h1: 48,
	h2: 40,
	h3: 34,
	h4: 32,
	h5: 24,
	h6: 20,
	subtitle1: 16,
	subtitle2: 14,
	body2: 14,
	button: 14,
	caption: 12,
	overline: 10,
};
const textColors = (darkMode: boolean) =>
	darkMode
		? {
				primary: "rgba(255,255,255,0.87)",
				secondary: "rgba(255,255,255,0.60)",
				disabled: "rgba(255,255,255,0.38)",
			}
		: {
				primary: "rgba(0,0,0,0.87)",
				secondary: "rgba(0,0,0,0.60)",
				disabled: "rgba(0,0,0,0.38)",
			};
const fontReusables = (darkMode: boolean) => ({
	overline: {
		fontSize: fontSizes.overline,
		fontWeight: fontWeights.semiBold,
		letterSpacing: 1.0,
		textTransform: "uppercase" as
			| "uppercase"
			| "none"
			| "capitalize"
			| "lowercase"
			| undefined,
		color: textColors(darkMode).secondary,
	},
	button: {
		fontSize: fontSizes.button,
		fontWeight: fontWeights.bold,
		letterSpacing: 1.25,
	},
});

const typography = (darkMode: boolean, appFont: string) => ({
	fonts: {
		extraBold: makeFont(`${appFont}-extrabold`, "800"),
		bold: makeFont(`${appFont}-bold`, "700"),
		medium: makeFont(`${appFont}-semibold`, "500"),
		regular: makeFont(`${appFont}-regular`, "400"),
		light: makeFont(`${appFont}-light`, "300"),
		thin: makeFont(`${appFont}-thin`, "100"),
	},
	...fontReusables(darkMode),
	fontSizes,
});

export { typography };
