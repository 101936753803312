import { isIOS } from "@helpers/platform";
import { useAppTheme } from "@hooks/useAppTheme";
import { Image } from "expo-image";
import { FAB } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useAppStore } from "../hooks/useAppStore";

const AssistantFab = ({
	handleAssistantFabPress,
	customBottomPosition,
	disabled,
}: {
	handleAssistantFabPress: () => void;
	customBottomPosition?: number;
	disabled?: boolean;
}) => {
	const bottomPosition = 80 + 24;
	const theme = useAppTheme();
	const aiAssistantFabIcon = useAppStore(
		(state) => state.app.aiAssistant.fabIcon,
	);
	const aiAssistantAlias = useAppStore((state) => state.app.aiAssistant.alias);
	const bottom = useSafeAreaInsets().bottom;
	const finalBottomPosition = customBottomPosition
		? customBottomPosition
		: bottomPosition;

	return (
		<FAB
			icon={
				aiAssistantFabIcon
					? () => (
							<Image
								source={{
									uri: aiAssistantFabIcon,
								}}
								style={{
									width: 24,
									height: 24,
								}}
							/>
						)
					: "chat-question-outline"
			}
			label={aiAssistantAlias}
			style={{
				position: "absolute",
				// account for safe area inset on iOS
				bottom: finalBottomPosition + (isIOS ? bottom - 12 : 0),
				right: 24,
				//TODO:Move this to app config
				backgroundColor: "#CD0700",
			}}
			color={theme.colors.white}
			onPress={disabled ? undefined : handleAssistantFabPress}
		/>
	);
};

export default AssistantFab;
