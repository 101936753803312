import Tooltip from "@mui/material/Tooltip";
type Props = {
	title: string;
	children: React.ReactElement;
	style?: React.CSSProperties;
};
const TooltipWrapper = ({ title, children, style }: Props) => {
	return (
		<Tooltip title={title} enterTouchDelay={0} leaveTouchDelay={2000}>
			<div
				style={{
					cursor: "help",
					...style,
				}}
			>
				{children}
			</div>
		</Tooltip>
	);
};
export default TooltipWrapper;
