import dayjs from "dayjs";
import { DateTime } from "luxon";

/**
 * Formats a date into a string.
 * @param date - The date to format.
 * @returns The formatted date.
 */
const formatDate = (date: string | DateTime): string => {
	if (!date) {
		return "";
	}
	const dateTime = typeof date === "string" ? DateTime.fromISO(date) : date;
	return dateTime.toFormat("MMMM dd, yyyy");
};

const formatTimeString = (time: string, timeStamp?: boolean, useUTC = true) => {
	const date = new Date(time);
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "long",
		day: "numeric",
		...(timeStamp
			? {
					hour: "numeric",
					minute: "numeric",
					...(useUTC ? { timeZone: "UTC" } : {}),
					hour12: true,
				}
			: {}),
	};

	const formattedDate = date.toLocaleString("en-US", options);
	return formattedDate;
};

const formatExamDate = (examDate: string) => {
	return dayjs(examDate).isValid()
		? dayjs(examDate).format("MMMM DD, YYYY")
		: examDate;
};

export { formatDate, formatTimeString, formatExamDate };
