import type { Article } from "../../../../../types";

export const Article582: Article = {
	id: 582,
	rsuiteId: "888ac4ed-a70b-44a4-8689-838b9bbc940f",
	type: "symposium series",
	title: "Sex and Gender Considerations in Orthopaedic Research",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=888ac4ed-a70b-44a4-8689-838b9bbc940f&type=jpeg&name=JBJS.24.00240f1",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Sex and Gender Considerations in Orthopaedic Research
              </div>
              <div class="text subtitle" style="position: unset;">Existing Barriers, What’s Needed by Institutions and
                Research Departments, and How to Guide Junior Investigators and Faculty</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Joan E.
                      Bechtold, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Thomas W.
                      Bauer, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 12 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">JBJS convened a symposium to discuss the reporting
                        of sex and gender in research studies as an imperative to improve research methods and results
                        to benefit all patients. Barriers to improved reporting include a lack of societal and cultural
                        acceptance of its need; a lack of education regarding appropriate terminology and appropriate
                        statistical methods and efficient study designs; a need for increased research funding to
                        support larger group sizes; unknown concordance of cell and animal models with humans to reflect
                        biologic variables such as sex; and a lack of understanding of key considerations of gender,
                        race, and other social determinants of health and how these factors intersect. Attention to
                        developing and disseminating best-practice statistical methods and to educating investigators
                        (at all career levels), reviewers, funders, editors, and staff in their proper implementation
                        will aid reporting. Concomitantly, well-designed studies with sufficient rigor and adequate
                        resources are essential to enable meaningful and reproducible research. Existing
                        recommendations, such as the Sex and Gender Equity in Research (SAGER) guidelines, provide
                        valuable guidance that can be applied across the research ecosystem. Academic institutions and
                        private foundations are likely groups to assist in scientific and institutional review board
                        guidance and study recruitment and pilot funding to generate meaningful power estimates, and to
                        serve as sources for additional funding and presentation of workshops, educational events, and
                        seminars. All of this needs to be conducted on an ongoing basis to ensure that sex and gender
                        are considered in scientific analyses, where relevant.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">At the JBJS Sex and Gender Reporting in Musculoskeletal
                  Research Symposium in Boston in late 2023, participants convened to discuss the need for improved
                  reporting of sex and gender, to identify barriers to this reporting, and to suggest solutions. It is
                  increasingly recognized that the treatment of sex and gender in medicine is insufficient, including
                  addressing the needs of sex and gender minorities such as gender-expansive and transgender
                  individuals, and people with variations of sex characteristics. Although each of these groups has its
                  own unique health care needs, it has been known that many members of these communities constantly
                  experience stigma, discrimination, harassment, violence, and inequities in health care settings,
                  resulting in worse care and very poor outcomes<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Attendees of the symposium participated in 2 breakout
                  groups. This paper reports on the recommendation of Group 1, who were assigned to consider 6 questions
                  related to existing barriers to publication, what is needed at institutions and research departments,
                  and how to guide junior investigators and faculty. Overall themes are to educate investigators,
                  faculty, reviewers, editors, and funders, and to partner with entities already developing and
                  implementing best practices. This paper is one of several articles based on the proceedings of the
                  symposium. An editorial that was jointly published in June 2024 shares several resolutions pertaining
                  to the reporting of sex and gender in musculoskeletal research<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Symposium
                Question 1: Existing Barriers</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">The first question that the group considered was the
                  following: what barriers exist that impede the reporting of results based on sex or gender?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">The reporting of results based on sex (as a biological
                  variable) or gender (as a sociocultural and psychological variable) requires first that studies be
                  appropriately stratified and analyzed to produce rigorous findings. There are multiple barriers,
                  including cultural, societal, and study design considerations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Cultural and
                Societal Barriers</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Lack of
                Recognition of Needs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">There is a lack of widespread recognition of the need and
                  ethical imperative for stratification and reporting of disaggregated data (sex, gender, and other
                  variables) and the need to consider intersectionality. While there is increasing attention to and
                  understanding of these needs within our general societal cultures as well as academic medicine, it is
                  not yet universally accepted or expected.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node list" data-id="list_1" style="position: unset;">
            <ul class="content" style="position: unset;">
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_9" style="position: unset;">
                      <div class="content" style="position: unset;">Recommendation: emphasize the role of relevant and
                        robust research design and conduct, along with transparent and complete reporting in enhancing
                        both rigor and reproducibility. This can be approached through educational editorials;
                        instructions to authors, reviewers, and editors; social media and podcasts of journals; and
                        research society mentorship, in addition to other ways. Academic institutions and institutional
                        review boards (IRBs) play key roles as well, as noted below.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_10" style="position: unset;">
                      <div class="content" style="position: unset;">Recommendation: promote the adoption and adherence
                        to established guidelines for research conduct and reporting, including the Sex and Gender
                        Equity in Research (SAGER) guidelines<a href="" data-id="citation_reference_3"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>
                        and Animal Research: Reporting of In Vivo Experiments (ARRIVE) guidelines<a href=""
                          data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                          style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                            style="position: unset;">4</span></a>.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="content-node heading level-3" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Imprecise
                Language</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Efforts to improve the inclusivity of lesbian, gay,
                  bisexual, transgender, queer, intersex, asexual and all sexual and gender minority (LGBTQIA+)
                  categories, as well as the process of revising these categories, are needed. The terms “sex” and
                  “gender” can be clearly defined but are often used by both laypeople and professionals in imprecise
                  ways. It is also important to ensure clarity and transparency in how these are defined and measured in
                  each research project. Adopting common language and terms will aid in the study and reporting of data
                  and comparison of results across studies. This includes using the appropriate terms (e.g., “female”
                  versus “woman,” “male” versus “man”). For language to be precise, it may still be helpful to note that
                  “sex” refers to biological characteristics (e.g., physical, anatomical, physiological, genetic), while
                  “gender” is societally based. As for most barriers, we anticipate that wide dissemination, frequent
                  repetition, rigor, and oversight by authors, reviewers, editors, and staff will be required. Research
                  studies have begun the collection of additional data, including sex assigned at birth, sexual
                  orientation, and gender identity. Lack of options for patients to self-identify and report their
                  gender identity and sexual orientation can lead to incomplete or inaccurate data to understand
                  complexities that can inform diagnostic or treatment decisions and thus cause health disparities<a
                    href="" data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">5</span></a>. Collection of data on sexual orientation, gender identity,
                  and intersex status will help to reduce health disparities that affect people from sexual and gender
                  minority communities<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">5</span></a>. A
                  useful discussion of sex and gender was presented by Ritz and Greaves in 2022<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">6</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node list" data-id="list_2" style="position: unset;">
            <ul class="content" style="position: unset;">
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_12" style="position: unset;">
                      <div class="content" style="position: unset;">Recommendation: the National Institutes of Health
                        (NIH) offers the following online guidance: “Typical sex categories include male, female, and
                        intersex. <span data-id="emphasis_16" class="annotation emphasis"
                          style="position: unset;">Sex</span> is used when describing anatomical, gonadal, chromosomal,
                        hormonal, cellular, and basic biological phenomena. E.g., <span data-id="emphasis_17"
                          class="annotation emphasis" style="position: unset;">sex development, sex hormones, sex
                          characteristics.”</span> Regarding gender, “Gender is a multidimensional social and cultural
                        construct that includes gender roles, expressions, behaviors, activities, power dynamics, and/or
                        attributes that a given society associates with being a woman, man, girl or boy, as well as
                        relationships with each other.”<a href="" data-id="citation_reference_8"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_8" class="annotation superscript" style="position: unset;">7</span></a>
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_13" style="position: unset;">
                      <div class="content" style="position: unset;">Resources for inclusive language of sex and gender
                        include the PFLAG glossary, the National LGBTQIA+ glossary, and the standard of care guidelines
                        for transgender people published by the World Professional Association for Transgender Health
                        (WPATH)<a href="" data-id="citation_reference_9"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_9" class="annotation superscript"
                            style="position: unset;">8</span></a><span data-id="superscript_9"
                          class="annotation superscript" style="position: unset;">-</span><a href=""
                          data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                          style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                            style="position: unset;">10</span></a>.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Note that terms are continuously changing/evolving, so it
                  is important to keep up to date. Journal style guides, including the JBJS Style Guide, continue to be
                  updated to reflect evolving usage. The role of epigenetic factors will also be important to track, as
                  more data emerge. We further suggest integrating terms regarding sex and gender, and their definitions
                  and implications for research and clinical care, into medical school curricula.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Barriers</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Potential
                Need for Increased Sample Size and Funding; Potential to Underpower, Overinterpret Findings</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Research studies need to be adequately powered with
                  sufficient numbers of participants to provide meaningful and valid data. Setting a goal of inclusion
                  and including the sex assigned at birth (i.e., male, female, or intersex) may require increased
                  funding for research studies, although not all study designs may require an increased total number of
                  participants<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">11</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Oversight by authors and editors will be needed to require
                  appropriate subject recruitment goals to provide the needed statistical power and to prevent the
                  overinterpretation of findings, where relevant. The publication of studies that are underpowered is
                  discouraged, and if their publication is deemed warranted, the limitation needs to be strongly
                  emphasized. It very much depends on the primary end point of the study, and whether the study intends
                  to identify sex or gender differences, or to account for them<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">12</span></a>. While a study may be insufficiently powered for sex or
                  gender subgroup analysis, there could still be room to consider publication, highlighting the
                  limitation and caveats in the interpretation of the data. In cases in which studies are not
                  sufficiently powered to detect sex- or gender-based differences, publishing raw data disaggregated by
                  sex can be valuable, as it enables future meta-analyses or pooled analyses. Subgroup analyses, with
                  clearly stated limitations, may also reveal signals that justify further investigation in more
                  robustly designed studies. The SAGER guidelines encourage highlighting the limitation of any subgroup
                  analysis of sex or gender differences, and pointing out whether there is any indication that warrants
                  better design and larger sample size to produce more definitive evidence about sex or gender
                  differences<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">13</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node list" data-id="list_3" style="position: unset;">
            <ul class="content" style="position: unset;">
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_17" style="position: unset;">
                      <div class="content" style="position: unset;">Recommendation: convene a workshop to develop
                        standards and templates to standardize language and provide education on statistical
                        methodologies for stratified reporting and consideration of intersectionality. Propose methods
                        to increase efficiency while maintaining rigor. Include experts from the NIH, Orthopaedic
                        Research and Education Foundation (OREF), Orthopaedic Research Society (ORS), and Canadian
                        counterparts (who are leading on many of these topics), as well as communities of LGBTQIA+
                        patients and their families. Disseminate templates and methodology widely, e.g., through
                        participating publications, social media, societies, grant-writing workshops, institutions, and
                        medical school curricula.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The Need to
                Evaluate Study Effects That Might Be Weaker but Still Beneficial</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">It is important to ensure that evaluations are not biased
                  and that there are measures that can objectively assess the effect of an intervention to avoid
                  implicit and internalized biases or other factors that can influence outcome. If an effect is found to
                  be more pronounced in one sex compared to others, it is important to evaluate whether the less
                  pronounced effect is still substantial enough to justify treatment for that group (e.g., do not keep
                  treatments from female patients if there is a demonstrated effect even if lower than that for male
                  patients or intersex patients). We need to keep in mind negative consequences of treatment. Clinicians
                  also need to consider (and discuss with their patients) when the risks of negative outcomes for one
                  sex outweigh the benefits and where additional research is needed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Unknown
                Correlation of Animal and in Vitro Cell Studies to Results in Humans, Including Impacts of Sex</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Social determinants of health, such as gender, race,
                  socioeconomic status, and environmental health, add complexities when attempting to correlate the
                  results of animal or in vitro studies with what would be seen in humans. Dance (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>) explains different routes for pain perception in males (through
                  microglia) and females (through T cells)<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">14</span></a>.
                  Pain can also be influenced by gender, which is not accounted for in studies focusing only on
                  sex-based differences<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">15</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00240f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=888ac4ed-a70b-44a4-8689-838b9bbc940f&type=jpeg&name=JBJS.24.00240f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;">Different routes for pain perception have been
                          demonstrated in males (through microglia) and in females (through T cells), as studied in
                          mice. (Reproduced, with permission, from: Dance A. Why the sexes don’t feel pain the same way.
                          Nature. 2019 Mar;567[7749]:448-50. Springer Nature).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Recommendations:</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node list" data-id="list_4" style="position: unset;">
            <ul class="content" style="position: unset;">
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_22" style="position: unset;">
                      <div class="content" style="position: unset;">Education of basic scientists regarding these
                        limitations and the need to include information on the sex(es) of animals included in group, and
                        the sex(es) of cells used in in vitro or in vivo cell therapy research. Importantly, both sexes
                        of animals and cells should be included.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_23" style="position: unset;">
                      <div class="content" style="position: unset;">Encourage broader use of the SAGER and ARRIVE
                        guidelines in reporting<a href="" data-id="citation_reference_16"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_15" class="annotation superscript"
                            style="position: unset;">3</span></a><span data-id="superscript_15"
                          class="annotation superscript" style="position: unset;">,</span><a href=""
                          data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                          style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                            style="position: unset;">4</span></a>. Work with the National Centre for Replacement,
                        Refinement and Reduction of Animals in Research (NC3Rs, London, England) to update the ARRIVE
                        guidelines to include the reporting of sex of cells.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_24" style="position: unset;">
                      <div class="content" style="position: unset;">Request that funders, similar to the NIH, require a
                        statement in research proposals and funding applications as to how “sex as a biologic variable”
                        is being considered, or why it cannot or is not being considered. Educate reviewers and
                        decision-makers about why this is essential.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_25" style="position: unset;">
                      <div class="content" style="position: unset;">Request that funders, similar to the Canadian
                        Institutes of Health Research and European Commission, require a statement in research proposals
                        and funding applications as to how “gender as a sociocultural variable” is being considered, or
                        why it cannot or is not being considered. Educate reviewers and decision-makers about why this
                        is essential.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_26" style="position: unset;">
                      <div class="content" style="position: unset;">Adopt language to offer authors as a standard
                        acknowledgment of the limitation of such basic and applied research, including recognition of
                        impacts of intersectionality.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="content-node heading level-3" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Difficulty
                in Obtaining Valid Gender (and Race and Ethnicity) Data in the EMR</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Gender, race, and ethnicity data can be entered into the
                  electronic medical record (EMR) by the investigator or study coordinator upon asking the patient for
                  self-reported gender identity and race and ethnicity data. Definitions need to be precise, and
                  regulatory and privacy concerns exist that may prevent the patient from agreeing to enter this
                  information. Elsevier has developed a questionnaire to be used when submitting manuscripts, and other
                  publishers may have similar tools<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">16</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Symposium
                Question 2: Improvements by Institutions, IRBs, Research Departments</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The second question that we considered was the following:
                  what is needed at institutions, institutional review boards (IRBs), or research departments to improve
                  the assessment and reporting of sex and/or gender-based data?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Academic institutional guidelines, regulations, and
                  approvals direct research design at the outset of projects. There are multiple opportunities to refine
                  research design to produce rigorous data appropriate for stratified interpretation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Institutional Organization and Commitment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Academic institutions vary in their commitment to
                  scholarship using stratified groups, and in their resources to assist their faculty. The following are
                  examples of activities that different offices and groups could implement:</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node list" data-id="list_5" style="position: unset;">
            <ul class="content" style="position: unset;">
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_31" style="position: unset;">
                      <div class="content" style="position: unset;">Associate Dean for Research and Office of VP of
                        Research: provide pilot funding to demonstrate success that will be needed for subsequent NIH
                        funding, provide methodology assistance.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_32" style="position: unset;">
                      <div class="content" style="position: unset;">Implementation of review mechanisms beyond IRBs<a
                          href="" data-id="citation_reference_19"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_17" class="annotation superscript"
                            style="position: unset;">17</span></a><span data-id="superscript_17"
                          class="annotation superscript" style="position: unset;">,</span><a href=""
                          data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                          style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                            style="position: unset;">18</span></a>. IRBs largely focus on risks to study participants
                        and are prohibited by U.S. federal laws like the Common Rule from considering broader impacts,
                        such as the risks to communities<a href="" data-id="citation_reference_21"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_18" class="annotation superscript"
                            style="position: unset;">19</span></a>. Also, as the IRB focuses on human subjects research,
                        research studies that use already collected data may not need to undergo an IRB review process.
                        As an example, Stanford University has created an Ethics & Society Review board<a href=""
                          data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                          style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                            style="position: unset;">20</span></a>. In addition, academic institutions can provide
                        greater education about topics like research communication, risks to communities, and the
                        importance of community engagement; as noted in an American Association for the Advancement of
                        Science (AAAS) survey, more scientists rate these skills as very important but have limited
                        access to resources to develop them<a href="" data-id="citation_reference_23"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_20" class="annotation superscript"
                            style="position: unset;">21</span></a>.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_33" style="position: unset;">
                      <div class="content" style="position: unset;">Curriculum committee: create an ecosystem in which
                        sex and gender are integrated throughout the curriculum as well as considered in stand-alone
                        modules teaching the ethical imperative for consideration of sex and gender. Develop a
                        curriculum for medical students and early-stage investigators focused on sex and gender. The
                        goal should be a fundamental change in curricula such that sex and gender are woven into all
                        areas of teaching in medicine—not teaching research that is based on men with the assumption
                        that women are just small men, or separate modules to learn about women as somehow different
                        (i.e., the view that men are the norm and all others are aberrant) or ignoring intersex
                        individuals.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_34" style="position: unset;">
                      <div class="content" style="position: unset;">Incorporate this training with existing resources
                        (for efficiency), including NIH Clinical and Translational Science Award (CTSA), NIH Training
                        Grants (T32), and Biostatistics Core support.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_35" style="position: unset;">
                      <div class="content" style="position: unset;">Recruit and support more women, LGBTQIA+
                        individuals, and women of racial minority and ethnic groups to faculty, in part to provide
                        increased diversity of opinions and insights as well as to support the careers of an
                        increasingly diverse health-care and research workforce.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_36" style="position: unset;">
                      <div class="content" style="position: unset;">Provide peer-to-peer mentoring and support at all
                        levels and enhanced education on and reminders of the NIH SABV (sex as a biological variable)
                        policy and how to incorporate SABV into NIH (and other) funding proposals.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Symposium
                Question 3: The Role of Funding Organizations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">The third question that we examined: is there a role for
                  funding organizations?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Extramural and private funding organizations often have
                  latitude to direct funds toward goals such as advocacy for improving research design and the reporting
                  of sex and gender. Such funding organizations can initiate programs to develop and produce research
                  methodology training workshops, online asynchronous workshop materials, and pilot funding to generate
                  data-supported power analyses. Funding priorities focused on the inclusion of sex and gender in
                  research can support the development of multidisciplinary partnerships, infrastructure, capabilities,
                  and more robust data resources.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Orthopaedic-Specific Funding</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node list" data-id="list_6" style="position: unset;">
            <ul class="content" style="position: unset;">
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_39" style="position: unset;">
                      <div class="content" style="position: unset;">Recommendation: liaison with orthopaedic-based
                        organizations and funders—such as OREF, ORS, the J. Robert Gladden Orthopaedic Society, the Ruth
                        Jackson Orthopaedic Society, Nth Dimensions, the Orthopaedic Diversity Leadership Consortium,
                        the Arthritis Foundation, Board of Specialty Societies groups (e.g., Orthopaedic Trauma
                        Association [OTA])—to initiate programs structured to foster inclusion of sex- and gender-based
                        analysis in research design, grant applications, and reporting.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">General,
                Societal Funding</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node list" data-id="list_7" style="position: unset;">
            <ul class="content" style="position: unset;">
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_40" style="position: unset;">
                      <div class="content" style="position: unset;">Recommendation: partner with groups already active
                        in promoting this area of focus in research (e.g., the American Medical Association) to raise
                        awareness among governmental and society-based funders.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_41" style="position: unset;">
                      <div class="content" style="position: unset;">Recommendation: engage in advocacy and participate
                        with lobbying and professional organizations, such as the Federation of American Societies for
                        Experimental Biology (FASEB), American Institute for Medical and Biological Engineering (AIMBE),
                        and Research!America.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Symposium
                Question 4: Guidance and Engagement</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">The fourth question that we considered: how do we guide
                  and engage early-career investigators/faculty in considering sex and/or gender in research design?
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">An individual’s motivation to appropriately design studies
                  and provide stratified data is not sufficient to achieve those goals. Education needs to be built in
                  throughout career development, and departmental and divisional attention, education, mentoring, and
                  statistical resources are also needed. Again, a curriculum is needed that is focused on studying and
                  understanding all relevant aspects of sex and gender.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Recommendations:</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node list" data-id="list_8" style="position: unset;">
            <ul class="content" style="position: unset;">
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_45" style="position: unset;">
                      <div class="content" style="position: unset;">Engage at all levels, even at the high school level
                        and in undergraduate education.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_46" style="position: unset;">
                      <div class="content" style="position: unset;">Identify and provide pilot funding (departments,
                        institutions, funders, OREF, etc.) for supplements to existing studies, to lay a framework for
                        groups to include sex- and gender-based analysis.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_47" style="position: unset;">
                      <div class="content" style="position: unset;">Include departmental mentoring activities, such as
                        through The Perry Initiative and initiatives that orthopaedic-oriented foundations and agencies
                        can provide.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Symposium
                Question 5: Addressing Intersectionality</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">The fifth question was the following: (how) should
                  researchers address intersectionality?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">Understanding the intersectionality of biological and
                  societal constructs, such as sex, gender, race, educational level, and environmental determinants of
                  health (including physical and social exposures), is key to providing the scope of data interpretation
                  needed to more fully understand how to improve health and treatments.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Organizing
                Incremental Progress</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">Incremental progress needs to be organized to lead to
                  refined studies that provide valuable data on how to address intersectionality within realistic time
                  frames and study costs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node list" data-id="list_9" style="position: unset;">
            <ul class="content" style="position: unset;">
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_51" style="position: unset;">
                      <div class="content" style="position: unset;">Recommendation: convene a workshop to develop
                        statistical methodology and disseminate recommendations (see Symposium Question 1).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="content-node paragraph" data-id="paragraph_52" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_52" style="position: unset;">
                <div class="content" style="position: unset;">An illustrative example of the interaction of sex and
                  gender is seen in ischemic damage in stroke, where a specific treatment reduced infarction in males
                  but increased damage in females<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">22</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Symposium
                Question 6: Data Repository Development</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_53" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_53" style="position: unset;">
                <div class="content" style="position: unset;">The sixth question that was explored was the following:
                  should institutions or organizations develop a data repository, especially for smaller studies, for
                  investigators to later perform a post-hoc analysis?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_54" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_54" style="position: unset;">
                <div class="content" style="position: unset;">The benefits of data sharing include encouraging
                  collaboration with diverse groups and enabling larger sample sizes to be collected. But biobanks also
                  demand responsibilities to ensure that individual data and communities are protected from risks of
                  loss of privacy and stigmatization. The current approval process of biobanks should be revisited to
                  reconsider how access is given, particularly to ensure that the use of a biobank to study a specific
                  trait is paired with collaboration with a community that has a stake in how that trait is perceived<a
                    href="" data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">23</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_55" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_55" style="position: unset;">
                <div class="content" style="position: unset;">If standardized research protocols are developed that
                  incorporate modular and coordinated protocols, then data-sharing protocols with appropriate
                  statistical data analysis can help to address intersectionality in a more efficient manner. However,
                  such a coordinated effort generally requires a strong central data core (such as the Department of
                  Defense Major Extremity Trauma Research Consortium [DoD METRC] program). Developing and maintaining a
                  central data core has benefits as well as drawbacks.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_56" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_56" style="position: unset;">
                <div class="content" style="position: unset;">If a topic lends itself to standardized protocols such
                  that pooling data would be meaningful, then the answer to the question is yes. Especially in animal
                  studies, protocols may not be similar enough to provide incremental information that can be pooled.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_57" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_57" style="position: unset;">
                <div class="content" style="position: unset;">Guidance on data-sharing approaches could be considered at
                  the workshop proposed in Question 1.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Cho MK</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Duque Lasio ML</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Amarillo I</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Mintz KT</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Bennett RL</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Brothers KB</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Words matter: The language of
                  difference in human genetics</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Genet Med.</span> 2023 Mar;25(3):100343.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Words%20matter%3A%20The%20language%20of%20difference%20in%20human%20genetics&as_occt=title&as_sauthors=%20%22MK%20Cho%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Leopold SS</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Hensinger RN</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Schoenfeld AJ</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Swiontkowski M</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Rossi MJ</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Templeton KJ</span>; Sex and
                Gender Research in Orthopaedic Journals Group. <span data-id="strong_2" class="annotation strong"
                  style="position: unset;">Improving How Orthopaedic Journals Report Research Outcomes Based on Sex and
                  Gender</span>. <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2024 Jul;39(7):1633-4.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Improving%20How%20Orthopaedic%20Journals%20Report%20Research%20Outcomes%20Based%20on%20Sex%20and%20Gender&as_occt=title&as_sauthors=%20%22SS%20Leopold%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Heidari S</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Babor TF</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> De Castro P</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Tort S</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Curno M</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Sex and Gender Equity in
                  Research: rationale for the SAGER guidelines and recommended use</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Res Integr Peer Rev.</span> 2016 May
                3;1:2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20and%20Gender%20Equity%20in%20Research%3A%20rationale%20for%20the%20SAGER%20guidelines%20and%20recommended%20use&as_occt=title&as_sauthors=%20%22S%20Heidari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Percie du Sert N</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Hurst V</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Ahluwalia A</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Alam S</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Avey MT</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Baker M</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Browne WJ</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Clark A</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Cuthill IC</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Dirnagl U</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Emerson M</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Garner P</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Holgate ST</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Howells DW</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Karp NA</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Lazic SE</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Lidster K</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> MacCallum CJ</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Macleod M</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Pearl EJ</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Petersen OH</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Rawle F</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Reynolds P</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Rooney K</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Sena ES</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Silberberg SD</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Steckler T</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Würbel H</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The ARRIVE guidelines 2.0:
                  Updated guidelines for reporting animal research</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">PLoS Biol.</span> 2020 Jul
                14;18(7):e3000410.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20ARRIVE%20guidelines%202.0%3A%20Updated%20guidelines%20for%20reporting%20animal%20research&as_occt=title&as_sauthors=%20%22N%20Percie%20du%20Sert%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Keuroghlian AS</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Electronic health records as an
                  equity tool for LGBTQIA+ people</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Nat Med.</span> 2021 Dec;27(12):2071-3.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Electronic%20health%20records%20as%20an%20equity%20tool%20for%20LGBTQIA%2B%20people&as_occt=title&as_sauthors=%20%22AS%20Keuroghlian%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Ritz SA</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Greaves L</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Transcending the Male-Female
                  Binary in Biomedical Research: Constellations, Heterogeneity, and Mechanism When Considering Sex and
                  Gender</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Int J
                  Environ Res Public Health.</span> 2022 Mar 30;19(7):4083.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Transcending%20the%20Male-Female%20Binary%20in%20Biomedical%20Research%3A%20Constellations%2C%20Heterogeneity%2C%20and%20Mechanism%20When%20Considering%20Sex%20and%20Gender&as_occt=title&as_sauthors=%20%22SA%20Ritz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;">National
                Institutes of Health. <span data-id="strong_7" class="annotation strong" style="position: unset;">Sex,
                  Gender, and Sexuality</span>. Accessed 2024 Sep 20. <a
                  href="https://www.nih.gov/nih-style-guide/sex-gender-sexuality#:%7E:text=Typical%20sex%20categories%20include%20male,%2C%20sex%20hormones%2C%20sex%20characteristics"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.nih.gov/nih-style-guide/sex-gender-sexuality#:∼:text=Typical%20sex%20categories%20include%20male,%2C%20sex%20hormones%2C%20sex%20characteristics</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%2C%20Gender%2C%20and%20Sexuality&as_occt=title&as_sauthors=%20%22National%20Institutes%20of%20Health%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;">PFLAG.
                <span data-id="strong_8" class="annotation strong" style="position: unset;">PFLAG National
                  Glossary</span>. Accessed 2024 Aug 15. <a href="https://pflag.org/glossary/" target="_blank"
                  data-id="link_2" class="link" style="position: unset;">https://pflag.org/glossary/</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=PFLAG%20National%20Glossary&as_occt=title&as_sauthors=%20%22PFLAG%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;">National
                LGBTQIA+ Health Education Center. <span data-id="strong_9" class="annotation strong"
                  style="position: unset;">LGBTQIA+ Glossary of Terms for Health Care Teams</span> 2020. Accessed 2024
                Aug 15. <a
                  href="https://www.lgbtqiahealtheducation.org/publication/lgbtqia-glossary-of-terms-for-health-care-teams/"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://www.lgbtqiahealtheducation.org/publication/lgbtqia-glossary-of-terms-for-health-care-teams/</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=LGBTQIA%2B%20Glossary%20of%20Terms%20for%20Health%20Care%20Teams&as_occt=title&as_sauthors=%20%22National%20LGBTQIA%2B%20Health%20Education%20Center%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Coleman E</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Radix AE</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Bouman WP</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Brown GR</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> de Vries ALC</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Deutsch MB</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Ettner R</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Fraser L</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Goodman M</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Green J</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Hancock AB</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Johnson TW</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Karasic DH</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Knudson GA</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Leibowitz SF</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Meyer-Bahlburg HFL</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Monstrey SJ</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Motmans J</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Nahata L</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Nieder TO</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Reisner SL</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Richards C</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Schechter LS</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Tangpricha V</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Tishelman AC</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Van Trotsenburg MAA</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Winter S</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Ducheny K</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Adams NJ</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Adrián TM</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Allen LR</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Azul D</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Bagga H</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Başar K</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Bathory DS</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Belinky JJ</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Berg DR</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Berli JU</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Bluebond-Langner RO</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Bouman MB</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Bowers ML</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Brassard PJ</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Byrne J</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Capitán L</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Cargill CJ</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Carswell JM</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Chang SC</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Chelvakumar G</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Corneil T</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Dalke KB</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> De Cuypere G</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> de Vries E</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Den Heijer M</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Devor AH</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Dhejne C</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> D’Marco A</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Edmiston EK</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Edwards-Leeper L</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Ehrbar R</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Ehrensaft D</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Eisfeld J</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Elaut E</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Erickson-Schroth L</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Feldman JL</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Fisher AD</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Garcia MM</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Gijs L</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Green SE</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Hall BP</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Hardy TLD</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Irwig MS</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Jacobs LA</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Janssen AC</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Johnson K</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Klink DT</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Kreukels BPC</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Kuper LE</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Kvach EJ</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Malouf MA</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Massey R</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Mazur T</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> McLachlan C</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Morrison SD</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Mosser SW</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Neira PM</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Nygren U</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Oates JM</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Obedin-Maliver J</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Pagkalos G</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Patton J</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Phanuphak N</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Rachlin K</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Reed T</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Rider GN</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Ristori J</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Robbins-Cherry S</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Roberts SA</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Rodriguez-Wallberg KA</span>,
                <span data-id="annotation_147" class="annotation" style="position: unset;"> Rosenthal SM</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Sabir K</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Safer JD</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Scheim AI</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Seal LJ</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Sehoole TJ</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Spencer K</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> St Amand C</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Steensma TD</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Strang JF</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Taylor GB</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Tilleman K</span>. <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> T’Sjoen GG</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Vala LN</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Van Mello NM</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Veale JF</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Vencill JA</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Vincent B</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Wesp LM</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> West MA</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Arcelus J</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Standards of Care for the
                  Health of Transgender and Gender Diverse People, Version 8</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Int J Transgender Health.</span> 2022 Sep
                6;23(Suppl 1):S1-259.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Standards%20of%20Care%20for%20the%20Health%20of%20Transgender%20and%20Gender%20Diverse%20People%2C%20Version%208&as_occt=title&as_sauthors=%20%22E%20Coleman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_168" class="annotation" style="position: unset;"> White J</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Tannenbaum C</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Klinge I</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Schiebinger L</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Clayton J</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">The Integration of Sex and
                  Gender Considerations Into Biomedical Research: Lessons From International Funding Agencies</span>.
                <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Clin Endocrinol
                  Metab.</span> 2021 Sep 27;106(10):3034-48.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Integration%20of%20Sex%20and%20Gender%20Considerations%20Into%20Biomedical%20Research%3A%20Lessons%20From%20International%20Funding%20Agencies&as_occt=title&as_sauthors=%20%22J%20White%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Gompers A</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Olivier MT</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Maney DL</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Training in the implementation
                  of sex and gender research policies: an evaluation of publicly available online courses</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Biol Sex Differ.</span> 2024
                Apr 3;15(1):32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Training%20in%20the%20implementation%20of%20sex%20and%20gender%20research%20policies%3A%20an%20evaluation%20of%20publicly%20available%20online%20courses&as_occt=title&as_sauthors=%20%22A%20Gompers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Rich-Edwards JW</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Kaiser UB</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Chen GL</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Manson JE</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Goldstein JM</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Sex and Gender Differences
                  Research Design for Basic, Clinical, and Population Studies: Essentials for Investigators</span>.
                <span data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Endocr Rev.</span> 2018
                Aug 1;39(4):424-39.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20and%20Gender%20Differences%20Research%20Design%20for%20Basic%2C%20Clinical%2C%20and%20Population%20Studies%3A%20Essentials%20for%20Investigators&as_occt=title&as_sauthors=%20%22JW%20Rich-Edwards%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Dance A</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Why the sexes don’t feel pain
                  the same way</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Nature.</span> 2019 Mar;567(7749):448-50.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Why%20the%20sexes%20don%E2%80%99t%20feel%20pain%20the%20same%20way&as_occt=title&as_sauthors=%20%22A%20Dance%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Keogh E</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Boerner KE</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Challenges with embedding an
                  integrated sex and gender perspective into pain research: Recommendations and opportunities</span>.
                <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Brain Behav
                  Immun.</span> 2024 Mar;117:112-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Challenges%20with%20embedding%20an%20integrated%20sex%20and%20gender%20perspective%20into%20pain%20research%3A%20Recommendations%20and%20opportunities&as_occt=title&as_sauthors=%20%22E%20Keogh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;">Royal
                Society of Chemistry. <span data-id="strong_16" class="annotation strong"
                  style="position: unset;">Diversity Data Collection in Scholarly Publishing</span>. Accessed 2024 Jul
                16. <a
                  href="https://www.rsc.org/policy-evidence-campaigns/inclusion-diversity/joint-commitment-for-action-inclusion-and-diversity-in-publishing/diversity-data-collection-in-scholarly-publishing/"
                  target="_blank" data-id="link_4" class="link"
                  style="position: unset;">https://www.rsc.org/policy-evidence-campaigns/inclusion-diversity/joint-commitment-for-action-inclusion-and-diversity-in-publishing/diversity-data-collection-in-scholarly-publishing/</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diversity%20Data%20Collection%20in%20Scholarly%20Publishing&as_occt=title&as_sauthors=%20%22Royal%20Society%20of%20Chemistry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Saxena A</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Lasher E</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Somerville C</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Heidari S</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Considerations of sex and
                  gender dimensions by research ethics committees: a scoping review</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Int Health.</span> 2022 Nov
                1;14(6):554-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Considerations%20of%20sex%20and%20gender%20dimensions%20by%20research%20ethics%20committees%3A%20a%20scoping%20review&as_occt=title&as_sauthors=%20%22A%20Saxena%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;">GENDRO.
                Meeting: <span data-id="strong_18" class="annotation strong" style="position: unset;">Sex and gender
                  considerations in current research ethics guidelines and research ethics committees’ work</span>. 2023
                Feb 2. Accessed 2024 Sep 20. <a
                  href="https://gendro.org/gendro-and-cioms-discuss-the-role-of-research-ethics-committees-in-improving-sex-and-gender-considerations-in-research/"
                  target="_blank" data-id="link_5" class="link"
                  style="position: unset;">https://gendro.org/gendro-and-cioms-discuss-the-role-of-research-ethics-committees-in-improving-sex-and-gender-considerations-in-research/</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20and%20gender%20considerations%20in%20current%20research%20ethics%20guidelines%20and%20research%20ethics%20committees%E2%80%99%20work&as_occt=title&as_sauthors=%20%22GENDRO.%20Meeting%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Criteria for IRB approval of
                  research. 45 C.F.R. §46.111</span>. 2024.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Center for Advanced Study
                  in the Behavioral Sciences.</span> Stanford University. Ethics & society review. Accessed 2024 Aug 15.
                <a href="https://casbs.stanford.edu/ethics-society-review-stanford-university" target="_blank"
                  data-id="link_6" class="link"
                  style="position: unset;">https://casbs.stanford.edu/ethics-society-review-stanford-university</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Wyndham JM</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Anderson MS</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Hinkins S</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Ericsen J</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Olsen A</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Jeske M</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Liu R</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Weeding J</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Jaffe R</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The social responsibilities of
                  scientists and engineers: a view from within</span>. AAAS: 2021.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20social%20responsibilities%20of%20scientists%20and%20engineers%3A%20a%20view%20from%20within&as_occt=title&as_sauthors=%20%22JM%20Wyndham%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Yuan M</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Siegel C</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Zeng Z</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Liu F</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> McCullough LD</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Sex differences in the response
                  to activation of the poly (ADP-ribose) polymerase pathway after experimental stroke</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Exp Neurol.</span> 2009
                May;217(1):210-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20differences%20in%20the%20response%20to%20activation%20of%20the%20poly%20(ADP-ribose)%20polymerase%20pathway%20after%20experimental%20stroke&as_occt=title&as_sauthors=%20%22M%20Yuan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;">American
                Society of Human Genetics. <span data-id="strong_22" class="annotation strong"
                  style="position: unset;">Data Sharing to Advance Genetic and Genomic Knowledge</span>. Accessed 2024
                Aug 15. <a
                  href="https://www.ashg.org/wp-content/uploads/2023/10/ASHG_Success_Stories-Data-Sharing-Final.pdf"
                  target="_blank" data-id="link_7" class="link"
                  style="position: unset;">https://www.ashg.org/wp-content/uploads/2023/10/ASHG_Success_Stories-Data-Sharing-Final.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Data%20Sharing%20to%20Advance%20Genetic%20and%20Genomic%20Knowledge&as_occt=title&as_sauthors=%20%22American%20Society%20of%20Human%20Genetics%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D888ac4ed-a70b-44a4-8689-838b9bbc940f%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Symposium Series</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2160</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00240</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 8, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;888ac4ed-a70b-44a4-8689-838b9bbc940f&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=888ac4ed-a70b-44a4-8689-838b9bbc940f&type=pdf&name=JBJS.24.00240.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=888ac4ed-a70b-44a4-8689-838b9bbc940f&type=pdf&name=JBJS.24.00240.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_23"
                            class="annotation strong" style="position: unset;">Disclosure:</span> Research reported in
                          this publication was supported by the National Institute of Arthritis and Musculoskeletal and
                          Skin Diseases of the National Institutes of Health under Award Number R13AR082710. The content
                          is solely the responsibility of the authors and does not necessarily represent the official
                          views of the National Institutes of Health. The <span data-id="strong_24"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I228" target="_blank" data-id="link_8" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I228</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;888ac4ed-a70b-44a4-8689-838b9bbc940f&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=888ac4ed-a70b-44a4-8689-838b9bbc940f&type=zip&name=JBJS.24.00240.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joan E. Bechtold, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:bechtold@umn.edu" class="" style="position: unset;">bechtold@umn.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7090-4270" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7090-4270</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Thomas W. Bauer, MD, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0976-2119" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0976-2119</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Janine Clayton, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2981-3622" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2981-3622</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kharma C. Foucher, MD, PhD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Leland Graves, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3181-0052" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3181-0052</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shirin Heidari, PhD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mary Ireland, MD<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Elena Losina, PhD<span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Caroline R. Paul, MD<span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9989-8997" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9989-8997</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Paula S. Ramos, PhD<span
                  data-id="affiliation_reference_10" class="label annotation cross_reference">10</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Judith G. Regensteiner, PhD<span
                  data-id="affiliation_reference_11" class="label annotation cross_reference">11</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9331-3908" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9331-3908</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Londa Schiebinger, PhD<span
                  data-id="affiliation_reference_12" class="label annotation cross_reference">12</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3438-3081" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3438-3081</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kimberly Templeton, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7805-2658" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7805-2658</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sarah Temkin, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">University of Minnesota, Departments of Orthopedic Surgery and Biomedical
                Engineering, Hennepin Healthcare Research Institute, Minneapolis, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Hospital for Special Surgery, New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Office of Research on Women’s Health, National Institutes of Health, Bethesda,
                Maryland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Kinesiology and Nutrition, University of Illinois Chicago,
                Chicago, Illinois</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, University of Kansas Medical Center, Kansas
                City, Kansas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Graduate Institute of International and Developmental Studies, Geneva,
                Switzerland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Kentucky Sports Medicine, Lexington, Kentucky</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Brigham and Women's Hospital, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">9</span><span class="text"
                style="position: unset;">Department of Pediatrics, NYU Langone Health and New York University Grossman
                School of Medicine, New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">10</span><span class="text"
                style="position: unset;">Departments of Medicine and Public Health Sciences, Medical University of South
                Carolina, Charleston, South Carolina</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">11</span><span class="text"
                style="position: unset;">Ludeman Family Center for Women’s Health Research, University of Colorado
                Anschutz Medical Campus School of Medicine, Aurora, Colorado</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">12</span><span class="text"
                style="position: unset;">History of Science, Stanford University, Stanford, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 25625.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
