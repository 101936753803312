{
	"API-Endpoint": "https://memorang-api.graphcdn.app",
	"API-Key": "da2-bjtzol27sffprcuwpekyzknxxu",
	"IconStorage": "memorang-prod-icon",
	"IdentityPoolId": "us-east-1:9ab800ea-e179-4e6e-8baa-535677ce25a9",
	"MediaStorage": "memorang-prod-media",
	"UpdateMergeFlagApi": "https://6hqh0xr0r7.execute-api.us-east-1.amazonaws.com/Prod/updateMergeFlag",
	"UserPoolClientId": "729uce6u26clp0or2ped65dndc",
	"UserPoolClientIdWeb": "4obc86johpkoqc97l4hvcvg20m",
	"UserPoolClients": "{\"mobileClientScope\":[\"aws.cognito.signin.user.admin\",\"openid\"],\"mobileClientCallbackURLs\":[\"https://memorang.com/landing?loggedin=true\"],\"mobileClientLogoutURLs\":[\"https://memorang.com/landing/\"],\"webClientScope\":[\"aws.cognito.signin.user.admin\",\"openid\"],\"webClientCallbackURLs\":[\"https://memorang.com/dashboard\",\"http://localhost:3000/dashboard\"],\"webClientLogoutURLs\":[\"http://localhost:3000/signin\",\"https://memorang.com/signin\"]}",
	"UserPoolDomain": "therapyed-user-pool.auth.us-east-1.amazoncognito.com",
	"UserPoolId": "us-east-1_PpHwWiL0i",
	"Region": "us-east-1"
}
