import type { Article } from "../../../../../types";

export const Article612: Article = {
	id: 612,
	rsuiteId: "a5e9b424-a285-47a2-b9e1-f5f661835990",
	type: "scientific article",
	title:
		"Evaluating the Ability of Brachial Plexus-Injured Patients to Control an Externally Powered (Myoelectric) Hand Prosthesis",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a5e9b424-a285-47a2-b9e1-f5f661835990&type=jpeg&name=JBJS.23.00938f1",
	subSpecialties: ["hand_wrist"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Evaluating the Ability of Brachial Plexus-Injured
                Patients to Control an Externally Powered (Myoelectric) Hand Prosthesis</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ellen Y. Lee,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Andrew W.
                      Nelson, CPO</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Background:</span> Restoration of hand function after traumatic
                        brachial plexus injury (BPI) remains a formidable challenge. Traditional methods such as nerve
                        or free muscle transfers yield suboptimal results. Advancements in myoelectric prostheses,
                        characterized by novel signal acquisition and improved material technology, show promise in
                        restoring functional grasp. This study evaluated the ability of adults with a BPI injury to
                        control an externally powered prosthetic hand using nonintuitive signals, simulating the
                        restoration of grasp with a myoelectric prosthesis. It also assessed the effectiveness of a
                        comprehensive multidisciplinary evaluation in guiding treatment decisions.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Methods:</span> A multidisciplinary brachial plexus team assessed
                        adults with compromised hand function due to BPI. The feasibility of amputation coupled with
                        fitting of a myoelectric prosthesis for grasp reconstruction was evaluated. Participants'
                        ability to control a virtual or model prosthetic hand using surface electromyography (EMG) as
                        well as with contralateral shoulder motion-activated linear transducer signals was tested. The
                        patient’s input and injury type, along with the information from the prosthetic evaluation, were
                        used to determine the reconstructive plan. The study also reviewed the number of participants
                        opting for amputation and a myoelectric prosthetic hand for grasp restoration, and a follow-up
                        survey was conducted to assess the impact of the initial evaluation on decision-making.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Results:</span> Of 58 subjects evaluated, 47 (81%) had pan-plexus BPI
                        and 42 (72%) received their initial assessment within 1 year post-injury. Forty-seven patients
                        (81%) could control the virtual or model prosthetic hand using nonintuitive surface EMG signals,
                        and all 58 could control it with contralateral uniscapular motion via a linear transducer and
                        harness. Thirty patients (52%) chose and pursued amputation, and 20 (34%) actively used a
                        myoelectric prosthesis for grasp. The initial evaluation was informative and beneficial for the
                        majority of the patients, especially in demonstrating the functionality of the myoelectric
                        prosthesis.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The study indicates that adults with traumatic
                        BPI can effectively operate a virtual or model myoelectric prosthesis using nonintuitive control
                        signals. The simulation and multidisciplinary evaluation influenced informed treatment choices,
                        with a high percentage of patients continuing to use the myoelectric prostheses post-amputation,
                        highlighting its long-term acceptance and viability.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level IV</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Adult traumatic brachial plexus injury (BPI) can be
                  devastating and life-altering<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">2</span></a>. The annual incidence in the United States ranges from 0.64
                  to 3.9 cases per 100,000 persons<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a>.
                  Treatment of BPI is complex and was historically divided among different surgical specialties<a
                    href="" data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a>. Multidisciplinary teams composed of surgical and
                  non-surgical specialties have resulted in an integrated and comprehensive care model for adult BPI
                  injuries<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>. Part
                  of this multidisciplinary approach has been to solve the challenges in restoring grasp or hand
                  function.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Grasp reconstruction in BPI remains challenging despite
                  advances in surgical techniques. Despite complex microsurgical reconstructions, such as single<a
                    href="" data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">5</span></a> and double<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>-stage
                  gracilis free functioning muscle transfers (FFMT) and contralateral C7 transfers<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">8</span></a>, the
                  ability to restore a consistent useful grasp has been lacking<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">10</span></a>.
                  This lack of success and the advances in myoelectric prosthetics have precipitated a resurgence of
                  interest in the historic treatment strategy of upper limb amputation and prosthetic fitting to restore
                  grasp<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">11</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The application of upper extremity prosthetics for people
                  with BPI has been erroneously translated from individuals with traumatic amputations<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_10" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">13</span></a>.
                  Body-powered devices require 2 to 4 in (5 to 10 cm) of biscapular excursion and normal bilateral
                  shoulder function to generate sufficient force for prosthetic grasp. Individuals with BPI lack normal
                  ipsilateral shoulder musculature and cannot generate the necessary excursion or force to operate a
                  body-powered prosthesis. Myoelectric prostheses applied after traumatic amputations rely on intuitive,
                  ipsilateral surface electromyography (EMG) signals to control grasp and release, recently popularized
                  by targeted muscle reinnervation (TMR)<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">14</span></a> or
                  regenerative peripheral nerve interfaces (RPNI)<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">15</span></a>.
                  Unlike individuals with traumatic amputations, those with BPI rarely have ipsilateral muscle function,
                  as their level of injury is proximal to the stump muscles. Adults with BPI do not have proximal
                  functioning nerves and neurologically viable distal muscles for TMR or RPNI. Operating a myoelectric
                  prosthesis requires use of nonintuitive signals. Nonintuitive EMG signals are generated by muscles
                  that normally do not contract when an individual opens or closes the hand. This concept can confuse
                  the individual, surgeon, and prosthetist. To overcome this confusion, the simulation of controlling a
                  myoelectric hand model with nonintuitive signals has been a routine part of our multidisciplinary team
                  evaluation to allow the patient to understand better the role of amputation and use of nonintuitive
                  signals in controlling a myoelectric prosthesis in the restoration of grasp<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">9</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">This study investigated if adults with traumatic BPI could
                  control an externally powered model hand using nonintuitive signals to simulate grasp with a
                  myoelectric prosthesis following amputation. It also assessed the utility of this evaluation in
                  guiding their choice of grasp reconstruction.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Institutional review board approval was obtained to review
                  the records of adults with traumatic BPI evaluated by the multidisciplinary brachial plexus team
                  between March 2018 and January 2023 (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>) and to
                  contact them to determine how the index evaluation with the virtual or model myoelectric prosthetic
                  hand affected their treatment decisions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Subject Demographics, Brachial Plexus Injury
                          Characteristics, and Prior Treatment Information (N = 58)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2354px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          (Range) or No. (%) of Subjects</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at study evaluation
                          <span data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37 (17 to
                          67)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54
                          (93%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Side injured</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (62%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (38%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at injury <span
                            data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31 (3 to
                          65)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pattern of injury</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pan-plexus</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (81%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> C5-C8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> C8-T1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> C7-T1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Infraclavicular (medial,
                          lateral, and posterior cords)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Interval from injury to
                          first consult in brachial plexus clinic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2 yr + 11
                          mo + 3 wk (16 days to 43 yr + 9 mo)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Interval from injury to
                          evaluation of Virtu-Limb control</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6 yr (42
                          days to 43 yr)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Subjects presenting &lt;1
                          yr after injury</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (72%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Interval from injury to
                          first consult in brachial plexus clinic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 mo + 3
                          wk (16 to 363 days)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nerve reconstruction
                          procedures</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Root grafting, nerve
                          transfers</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Free functioning muscle
                          transfers (FFMT)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">   Elbow flexion</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">   Elbow and finger
                          flexion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Additional procedures
                          for hand function</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Total wrist arthrodesis
                          and thumb carpometacarpal and interphalangeal joint arthrodeses</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Various tendon
                          transfers</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Dorsal
                          metacarpophalangeal joint capsulotomies</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Flexor tendon
                          fractional lengthening</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Corrective osteotomy
                          for forearm pronation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Thumb metacarpal
                          extension osteotomy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Outcome of grasp
                          reconstruction</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Uses injured hand to
                          play video games</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Can hold a &lt;500-mL
                          bottle</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Weak finger
                          flexion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No hand motion
                          recovered</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  &lt;6 mo since
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Subjects presenting ≥1 yr
                          after injury</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (28%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Interval from injury to
                          first consult in brachial plexus clinic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9 yr + 9
                          mo (14 mo to 43 yr + 9 mo)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No surgery prior to
                          presentation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Primary reconstruction
                          procedures elsewhere</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Specific procedures for
                          hand function</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Intercostal nerves to
                          median nerve transfers 2 yr before evaluation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Tendon transfer for
                          finger flexion and thumb carpometacarpal joint arthrodesis 30 yr prior</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No hand motion
                          recovered</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">All individuals identified were patients of the
                  multidisciplinary brachial plexus team. They were first evaluated by the surgeons (A.Y.S., R.J.S.,
                  A.T.B., and N.P.) and then by a pain specialist. They underwent appropriate diagnostic studies and
                  then were evaluated by the prosthetic team (B.P.S. and A.W.N.).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Two primary options were discussed with individuals
                  desiring grasp function: (1) single-stage FFMT<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">5</span></a> and
                  (2) amputation followed by application of a myoelectric prosthesis to enable grasp<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">9</span></a>. Patients were informed that to undergo single-stage FFMT
                  ideally they should be presenting within 6 to 8 months after the injury and that the procedure could
                  be performed no more than 1 year after the injury; they also had to have intact vasculature as well as
                  functioning spinal accessory and intercostal nerves (ICN). The need to limit the time between the
                  injury and FFMT is that transfer of ICN motor nerves to the triceps branch is necessary to provide
                  agonist function to allow finger flexion by opposing the FFMT’s elbow flexion force<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">16</span></a>. If the time from injury was &gt;1 year or there were other
                  contraindications to FFMT (e.g., vascular injury), amputation and myoelectric prosthetic use was the
                  only available option for grasp reconstruction. Elective amputation was contemplated for those who had
                  viable signals for an externally powered prosthesis, were willing to undergo amputation, and had
                  secured funding for a prosthesis. All subjects were counseled on neuropathic pain management,
                  emphasizing that amputation would not alleviate the avulsion-mediated neuropathic pain. The role of
                  secondary surgical procedures (wrist, thumb axis, and shoulder arthrodeses, and selected tendon
                  transfers) were discussed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The prosthetic team provided an overview of upper
                  extremity prosthetics, including componentry, socket design, control strategy, and terminal device
                  options. Subjects learned about how BPI affected intuitive prosthetic control. The subject’s
                  functional goals, their expectations in terms of the outcomes of planned procedures, and how
                  prosthetic grasp could be obtained were reviewed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The ability of the participant to control the externally
                  powered prosthesis using surface EMG signals and a linear force transducer powered by the
                  contralateral uniscapular protraction was evaluated. Surface EMG signals detected from any ipsilateral
                  voluntarily contracted muscles were identified. Consistent, reproducible, and adequate surface EMG
                  signals were marked on the skin (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). The
                  surface EMG sensors were connected to the Virtu-Limb (Össur) (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>) and the
                  participant’s ability to open and close the virtual or model hand was determined (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). Next, the participant donned a figure-of-9 harness around the
                  contralateral, uninjured shoulder. A linear force transducer requiring 1/4 in (6.4 mm) of excursion to
                  transmit a signal was connected to the harness and to the Virtu-Limb (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>). The participant used uniscapular abduction and shoulder
                  flexion on the uninjured side to generate excursion, and their ability to open and close the virtual
                  or model hand was evaluated.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00938f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a5e9b424-a285-47a2-b9e1-f5f661835990&type=jpeg&name=JBJS.23.00938f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;">The locations of consistent, reproducible, and
                          adequate surface EMG signals from the trapezius for opening and closing the myoelectric
                          prosthetic hand are marked on the skin.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00938f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a5e9b424-a285-47a2-b9e1-f5f661835990&type=jpeg&name=JBJS.23.00938f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;">The surface EMG sensors connected to the
                          Virtu-Limb (Össur).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.00938f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a5e9b424-a285-47a2-b9e1-f5f661835990&type=jpeg&name=JBJS.23.00938f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;">Nonintuitive signals from a participant with a
                          C8-T1 injury opening and closing the prosthetic hand model.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.00938f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a5e9b424-a285-47a2-b9e1-f5f661835990&type=jpeg&name=JBJS.23.00938f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Linear transducer on a figure-of-9 harness,
                          showing the excursion that activates it. The excursion is generated by shoulder motion, which
                          causes the device (circled on left image) to slide open/become wider, exposing the 2 metal
                          pins (right image).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Each participant was queried regarding their level of
                  interest in amputation and prosthetic fitting to obtain grasp function immediately after the
                  evaluation. Interest was categorized as indifferent, curious, intrigued, or committed. “Indifferent”
                  subjects showed no interest in amputation after the evaluation. “Curious” subjects were still
                  gathering information and wanted to learn more, even requesting to meet others who had undergone
                  amputation and fitting. “Intrigued” subjects contemplated undergoing amputation and fitting but not
                  immediately. “Committed” subjects expressed a definite intent to undergo amputation and fitting as
                  soon as possible.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Participants were given a minimum of 1 month to consider
                  their options. For those with acute injuries without prior surgery who wished to undergo amputation
                  and prosthetic fitting, the reconstruction plan was modified to include FFMT for elbow flexion and
                  nerve transfers (if donor nerves were available) for more proximal muscles with shorter reinnervation
                  distances to generate additional sources of surface EMG signals for myoelectric prosthesis control.
                  Participants were followed for 1 year after FFMT and nerve transfer to assess recovered function.
                  Those with adequate elbow flexion underwent transradial amputation. Those without adequate elbow
                  flexion underwent transhumeral amputation. For those who had already undergone brachial plexus
                  reconstruction, a surgical plan was formulated to maximize control of the prosthesis after an adequate
                  observation period for recovery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">In addition to demographic data (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>), the following information was obtained from chart review: the
                  interval from injury to presentation, the pattern of the BPI, any prior surgery, the subject’s
                  perceived outcome of hand reconstruction, their ability to control the externally powered prosthesis
                  with nonintuitive EMG signals and with the figure-of-9 harness and linear transducer, their level of
                  interest in amputation and prosthetic fitting after the evaluation, whether amputation was performed,
                  the interval from injury to amputation, and details of the amputation and prosthetic fitting. Subjects
                  were interviewed at an average of 2.5 years after their index evaluation and queried on how the
                  virtual or model prosthesis simulation helped in their decision-making.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Fifty-eight subjects were evaluated over a 4-year and
                  10-month period. The subjects were an average of 31 years old and predominantly male, and the majority
                  of the injuries were on the left side (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). No
                  subject had any useful hand function, with 47 (81%) having a complete pan-plexus (C5-T1) injury. Other
                  patterns of injury are listed in <a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Forty-two (72%) of the participants were seen within 1
                  year (average, 4 months and 3 weeks) after their BPI injury (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). There
                  were 33 pan-plexus injuries, 4 C5-C8 injuries, 3 C8-T1 injuries, and 2 infraclavicular injuries
                  involving all of the cords (medial, lateral, and posterior). Thirty-six subjects underwent nerve
                  reconstructions. Twenty-one participants had additional procedures performed to improve hand position
                  and function (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). Six
                  participants (3 pan-plexus injuries, 2 C8-T1 injuries, and 1 infraclavicular injury) chose to forego
                  reconstructive procedures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Sixteen patients (28%) presented 14 months to 43 years
                  after the BPI (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).
                  Fourteen had pan-plexus and 2 had C8-T1 injuries. Three subjects had had prior surgery to address hand
                  function performed elsewhere that had failed to restore grasp (<a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Virtu-Limb
                Control and Level of Interest in Amputation and Myoelectric Prosthesis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Forty-seven (81%) of the participants were able to control
                  the Virtu-Limb using surface EMG electrode signals from muscles listed in <a href=""
                    data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>. Most signals were obtained from surgically reinnervated or
                  recovered muscles. Eleven participants with pan-plexus injuries could not generate any surface EMG
                  signal. Five had undergone previous cervical spinal nerve grafting and nerve transfers, 2 had had
                  FFMT, and 4 did not have prior surgery. All 58 participants demonstrated the ability to control the
                  Virtu-Limb with contralateral uniscapular motion using the figure-of-9 harness and linear transducer.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Data on Subjects Able to Control Virtual or Model
                          Prosthesis</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 645px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. (%) of
                          Subjects</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Control by generating
                          ipsilateral surface EMG signals</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (81%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Muscle(s) generating
                          signal</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Gracilis FFMT</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Biceps</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Forearm muscles</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Biceps, triceps</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Trapezius</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Gracilis, pectoralis
                          major</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Trapezius, pectoralis
                          major</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Triceps</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Gracilis, biceps,
                          triceps</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No surface EMG
                          signals</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (19%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Control by contralateral
                          uniscapular motion using figure-of-9 harness and linear transducer</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58
                          (100%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Twenty-seven (47%) of the participants were “committed” to
                  amputation and prosthetic fitting after their Virtu-Limb evaluation (<a href=""
                    data-id="figure_reference_14" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). In addition, 2 participants who were “intrigued” after the
                  evaluation ultimately decided to have the procedure, and 1 “curious” participant opted to have it when
                  there was inadequate functional recovery after reconstruction. Thus, 30 participants (52%) ultimately
                  underwent amputation, as early as 4 months and as late as 44 years after injury (<a href=""
                    data-id="figure_reference_15" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Level of Interest in Amputation and Prosthetic
                          Fitting, and Eventual Amputation Rate</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 368px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Level of
                          Interest</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">No. of Subjects</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Underwent
                          Evaluation of Virtu-Limb Control</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Underwent
                          Amputation</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Decided
                          Against Amputation</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Indifferent</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Curious</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Intrigued</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Committed</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (52%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Details Regarding Amputation and Myoelectric
                          Prosthetic Fitting</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 877px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          (Range) or No. of Subjects</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Time interval from injury
                          to amputation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6 yr + 1
                          mo (4 mo + 1 wk to 44 yr + 3 mo)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Level of
                          amputation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Transhumeral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Transradial</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Had additional procedures
                          performed during amputation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Glenohumeral
                          arthrodesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Gracilis tendon
                          reinsertion (from finger flexion to elbow flexion only)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pulley to improve elbow
                          flexion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nerve transfer to
                          musculocutaneous nerve motor branch</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Gracilis
                          tenolysis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Eventually fitted with
                          myoelectric prosthesis after amputation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Outcomes for amputees
                          without a prosthesis (n = 10)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Satisfied without
                          prosthesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Awaiting prosthetic
                          fitting</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lost to follow-up</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Amputation
                Levels, Myoelectric Prosthesis Use, and Participants’ Perception of Initial Evaluation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Twenty-two subjects had transradial and 8 had transhumeral
                  amputation. Fifteen had additional procedures performed with the amputation, the most common being
                  glenohumeral arthrodesis to improve arm positioning and correct shoulder subluxation (<a href=""
                    data-id="figure_reference_16" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). At an average of 2.5 years after the index evaluation, 20
                  amputees were using their externally powered prosthesis for grasp in activities of daily living and 3
                  were awaiting fitting of the myoelectric prosthesis. Five amputees were content without a prosthesis
                  and ultimately decided not to get fitted for one. Twenty-eight amputees appreciated that the index
                  evaluation had allowed them to see what they could do with a virtual or model prosthesis and thought
                  that it had been valuable in helping them decide on their treatment. We were not able to contact the
                  remaining 2 individuals post-amputation. Of the 28 who opted against amputation, 25 reported that the
                  evaluation process was informative and helped in their decision-making and 3 could not be contacted
                  (<a href="" data-id="figure_reference_17" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Perceptions of Evaluation Process Among Subjects
                          Opting Against Amputation</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 873px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Subjects</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Indifferent after
                          evaluation (n = 2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Evaluation was
                          informative and appreciated understanding their options</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Curious after evaluation
                          (n = 10)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Evaluation was
                          thought-provoking and reinforced decision against amputation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Evaluation demonstrated
                          potential function and they are considering amputation if there is no recovery after
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Evaluation demonstrated
                          potential function and they will pursue amputation in the next year</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Evaluation was
                          compelling and he is leaning more toward amputation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Could not be
                          contacted</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Intrigued after
                          evaluation (n = 16)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Evaluation was
                          thought-provoking and reinforced their decision against amputation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Evaluation demonstrated
                          potential function and they are considering amputation if there is no recovery after
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Evaluation demonstrated
                          potential function and they will pursue amputation in the next year</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Could not be
                          contacted</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Between 1940 and1958, the brachial plexus was only
                  explored to determine the prognosis<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">17</span></a> as
                  repair was considered impossible if the plexus was found to be ruptured<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">17</span></a>. Treatment options at that time included amputation or
                  reconstruction with shoulder arthrodesis, use of a posterior bone block at the elbow, finger
                  tenodesis, or no operation<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">17</span></a>. By
                  1974, surgical positioning of the flail upper limb using bone blocks was abandoned because the
                  insensate limbs were not functional<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">18</span></a>. If
                  preganglionic injuries were present, patients were advised that the arm would never recover and
                  amputation with shoulder arthrodesis and prosthetic fitting would be the best option for function<a
                    href="" data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">18</span></a>. There were opposing views on whether amputation should be
                  performed early<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">19</span></a> or late<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">20</span></a> and
                  whether adding a shoulder arthrodesis improved the outcome<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">21</span></a>. Advancements in the surgical treatment of BPI over the
                  past 75 years with the advent of the operative microscope and microsurgical techniques shifted the
                  primary goal to restoration of function of the paralyzed arm rather than amputation. Timely
                  microsurgical reconstruction with neuroma excision and grafting, intra- and extra-plexal nerve
                  transfers, and FFMT became the standard of care<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">22</span></a>.
                  Selected fusions<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">23</span></a> and
                  tendon transfers further improved the aesthetics and movement of the reanimated hand. Elective
                  amputation was only indicated when it was requested by patients with no motor recovery or if the flail
                  arm became a risk when performing manual labor<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">24</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Despite advancements in treatment of traumatic BPI, the
                  reconstruction of grasp has remained primitive<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_28" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_28" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">28</span></a>. Those with limited function after reconstructions often
                  inquired about elective amputation to reduce the burden of a paralyzed and insensate extremity. In the
                  early 1980s, commercial availability of externally powered prostheses emerged<a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">29</span></a>. Since then, important technological advances included
                  lighter construction materials; smaller and more powerful microprocessors; smaller, self-contained,
                  long-lasting battery packs; and the development of a variety of interfaces for control<a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">30</span></a>. The use of myoelectric prostheses for grasp has been
                  recently introduced<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_31" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">11</span></a>. This involved the surgical creation of independent EMG
                  signals at the level of amputation, using biofeedback to activate these signals and a model hand, and
                  ultimately amputation and fitting of a myoelectric prosthesis<a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">11</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Several challenges associated with BPI add to the
                  complexity of myoelectric prosthesis application. Unlike a traumatic amputation that leaves
                  functioning muscles proximal to the amputation site, brachial plexus injuries are proximal to the
                  shoulder and there are no or few functional muscles or nerves distal to the injury. This rules out
                  strategies such as TMR<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">14</span></a> or
                  RPNI<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">15</span></a>
                  that provide intuitive prosthetic control, and alternative strategies using nonintuitive signals are
                  required. Recovery of shoulder function is often limited, necessitating a glenohumeral arthrodesis to
                  support the weight of the prosthesis and position the arm in front of the abdomen. People with BPI
                  root avulsion have substantial neuropathic pain, which will not be relieved by amputation and may
                  impede prosthesis wear.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">In our study, a multidisciplinary evaluation was
                  undertaken for every individual with a BPI before deciding on amputation and use of an externally
                  powered prosthesis for grasp<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">4</span></a>. The
                  interdisciplinary discussion aided participants in framing their questions, allowed them to hear
                  information reiterated from various perspectives, and provided them with support from people with
                  similar conditions. The Virtu-Limb used to evaluate the potential for grasp provided participants with
                  visual and palpable simulation of the function expected with a prosthesis. Our results showed that 47
                  (81%) of those tested were able to operate a simulated prosthesis using nonintuitive surface EMG
                  signals, and all 58 were able to do so using a figure-of-9 harness with a linear transducer on the
                  contralateral shoulder. This allowed our patients to make an informed decision, and 30 subjects (52%)
                  eventually underwent amputation. Twenty were using a myoelectric prosthesis at the time of follow-up
                  and 3 more were awaiting prosthetic fitting.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">This study is limited by the heterogeneity of the
                  population evaluated for their ability to operate an externally powered prosthesis. Eleven subjects
                  (19%) had a mix of lower trunk, infraclavicular, and C5-C8 injuries with severely limited wrist or
                  hand motion that lacked grasp function. Also, there was no pre-evaluation assessment of the level of
                  interest, and results were reported using non-validated qualitative methods. Five subjects were lost
                  to follow-up. It was not our objective to discuss the results of amputation and prosthetic fitting as
                  a technique for grasp reconstruction, which have been previously reported<a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_36" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_36"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">13</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">These limitations notwithstanding, this study demonstrated
                  the nonintuitive control of a myoelectric hand prosthesis by participants with a BPI. Participants
                  without any EMG signals were able to control a simulated externally powered prosthesis with their
                  contralateral shoulder motion. Most participants found the demonstration of expected prosthetic
                  function informative. Previously reserved for failed BPI reconstruction, amputation now holds promise
                  in restoring grasp during the acute management of traumatic BPI, thanks to technological advancements
                  enabling the conversion and amplification of nonintuitive signals into grasp with a prosthesis. The
                  ability to control a virtual or model prosthesis with their own signals enabled people with BPI to
                  comprehend their options and actively participate in their decision-making.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Kretschmer T</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Ihle S</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Antoniadis G</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Seidel JA</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Heinen C</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Börm W</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Richter HP</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> König R</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Patient satisfaction and
                  disability after brachial plexus surgery</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Neurosurgery.</span> 2009
                Oct;65(4)(Suppl):A189-96.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20satisfaction%20and%20disability%20after%20brachial%20plexus%20surgery&as_occt=title&as_sauthors=%20%22T%20Kretschmer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Rasulić L</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Savić A</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Živković B</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Vitošević F</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Mićović M</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Baščarević V</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Puzović V</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Novaković N</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Lepić M</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Samardžić M</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Mandić-Rajčević S</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Outcome after brachial plexus
                  injury surgery and impact on quality of life</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Acta Neurochir (Wien).</span> 2017
                Jul;159(7):1257-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcome%20after%20brachial%20plexus%20injury%20surgery%20and%20impact%20on%20quality%20of%20life&as_occt=title&as_sauthors=%20%22L%20Rasuli%C4%87%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Midha R</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Epidemiology of brachial plexus
                  injuries in a multitrauma population</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Neurosurgery.</span> 1997 Jun;40(6):1182-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiology%20of%20brachial%20plexus%20injuries%20in%20a%20multitrauma%20population&as_occt=title&as_sauthors=%20%22R%20Midha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Shin AY</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Bishop AT</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Loosbroch MF</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Spinner RJ</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">A multidisciplinary approach to
                  the management of brachial plexus injuries: experience from the Mayo Clinic over 100 years</span>.
                <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Hand Surg Eur
                  Vol.</span> 2022 Dec;47(11):1103-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20multidisciplinary%20approach%20to%20the%20management%20of%20brachial%20plexus%20injuries%3A%20experience%20from%20the%20Mayo%20Clinic%20over%20100%20years&as_occt=title&as_sauthors=%20%22AY%20Shin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Maldonado AA</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Poppler L</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Loosbrock Rn MF</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Spinner RJ</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Bishop AT</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Shin AY</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Restoration of Grasp after
                  Single-Stage Free Functioning Gracilis Muscle Transfer in Traumatic Adult Pan-Brachial Plexus
                  Injury</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Plast
                  Reconstr Surg.</span> 2023 Jan 1;151(1):133-42.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Restoration%20of%20Grasp%20after%20Single-Stage%20Free%20Functioning%20Gracilis%20Muscle%20Transfer%20in%20Traumatic%20Adult%20Pan-Brachial%20Plexus%20Injury&as_occt=title&as_sauthors=%20%22AA%20Maldonado%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Dodakundi C</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Doi K</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Hattori Y</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Sakamoto S</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Fujihara Y</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Takagi T</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Fukuda M</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Outcome of surgical
                  reconstruction after traumatic total brachial plexus palsy</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2013 Aug
                21;95(16):1505-12.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1170643"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Gu YD</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Chen DS</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Zhang GM</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Cheng XM</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Xu JG</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Zhang LY</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Cai PQ</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Chen L</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Long-term functional results of
                  contralateral C7 transfer</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">J Reconstr Microsurg.</span> 1998 Jan;14(1):57-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20functional%20results%20of%20contralateral%20C7%20transfer&as_occt=title&as_sauthors=%20%22YD%20Gu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Songcharoen P</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Wongtrakul S</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Mahaisavariya B</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Spinner RJ</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Hemi-contralateral C7 transfer
                  to median nerve in the treatment of root avulsion brachial plexus injury</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Hand Surg Am.</span> 2001
                Nov;26(6):1058-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hemi-contralateral%20C7%20transfer%20to%20median%20nerve%20in%20the%20treatment%20of%20root%20avulsion%20brachial%20plexus%20injury&as_occt=title&as_sauthors=%20%22P%20Songcharoen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Cantwell SR</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Nelson AW</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Sampson BP</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Spinner RJ</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Bishop AT</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Pulos N</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Shin AY</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The Role of Amputation and
                  Myoelectric Prosthetic Fitting in Patients with Traumatic Brachial Plexus Injuries</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2022 Aug 17;104(16):1475-82.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=3348143" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Hruby LA</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Gstoettner C</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Sturma A</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Salminger S</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Mayer JA</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Aszmann OC</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Bionic Upper Limb
                  Reconstruction: A Valuable Alternative in Global Brachial Plexus Avulsion Injuries-A Case
                  Series</span>. <span data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J Clin
                  Med.</span> 2019 Dec 20;9(1):23.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bionic%20Upper%20Limb%20Reconstruction%3A%20A%20Valuable%20Alternative%20in%20Global%20Brachial%20Plexus%20Avulsion%20Injuries-A%20Case%20Series&as_occt=title&as_sauthors=%20%22LA%20Hruby%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Aszmann OC</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Roche AD</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Salminger S</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Paternostro-Sluga T</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Herceg M</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Sturma A</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Hofer C</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Farina D</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Bionic reconstruction to
                  restore hand function after brachial plexus injury: a case series of three patients</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Lancet.</span> 2015 May
                30;385(9983):2183-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bionic%20reconstruction%20to%20restore%20hand%20function%20after%20brachial%20plexus%20injury%3A%20a%20case%20series%20of%20three%20patients&as_occt=title&as_sauthors=%20%22OC%20Aszmann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Wright TW</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Hagen AD</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Wood MB</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Prosthetic usage in major upper
                  extremity amputations</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">J Hand Surg Am.</span> 1995 Jul;20(4):619-22.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prosthetic%20usage%20in%20major%20upper%20extremity%20amputations&as_occt=title&as_sauthors=%20%22TW%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Lombard A</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Lavrard B</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Pierrart J</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Masmejean E</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Indications for amputation
                  after traumatic brachial plexus injury in adults: Case report and review of new prosthetic
                  technologies</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Hand Surg Rehabil.</span> 2022 Feb;41S:S71-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Indications%20for%20amputation%20after%20traumatic%20brachial%20plexus%20injury%20in%20adults%3A%20Case%20report%20and%20review%20of%20new%20prosthetic%20technologies&as_occt=title&as_sauthors=%20%22A%20Lombard%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Bergmeister KD</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Salminger S</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Aszmann OC</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Targeted Muscle Reinnervation
                  for Prosthetic Control</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Hand Clin.</span> 2021 Aug;37(3):415-24.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Targeted%20Muscle%20Reinnervation%20for%20Prosthetic%20Control&as_occt=title&as_sauthors=%20%22KD%20Bergmeister%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Ganesh Kumar N</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Kung TA</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Cederna PS</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Regenerative Peripheral Nerve
                  Interfaces for Advanced Control of Upper Extremity Prosthetic Devices</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Hand Clin.</span> 2021
                Aug;37(3):425-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Regenerative%20Peripheral%20Nerve%20Interfaces%20for%20Advanced%20Control%20of%20Upper%20Extremity%20Prosthetic%20Devices&as_occt=title&as_sauthors=%20%22N%20Ganesh%20Kumar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Doi K</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Shigetomi M</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Kaneko K</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Soo-Heong T</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Hiura Y</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Hattori Y</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Kawakami F</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Significance of elbow extension
                  in reconstruction of prehension with reinnervated free-muscle transfer following complete brachial
                  plexus avulsion</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Plast Reconstr Surg.</span> 1997 Aug;100(2):364-72, discussion
                373-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Significance%20of%20elbow%20extension%20in%20reconstruction%20of%20prehension%20with%20reinnervated%20free-muscle%20transfer%20following%20complete%20brachial%20plexus%20avulsion&as_occt=title&as_sauthors=%20%22K%20Doi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Yeoman PM</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Seddon HJ</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Brachial plexus injuries:
                  Treatment of the flail arm</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 1961;43-B(3):493-500.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Brachial%20plexus%20injuries%3A%20Treatment%20of%20the%20flail%20arm&as_occt=title&as_sauthors=%20%22PM%20Yeoman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Parry CB</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">The management of injuries to
                  the brachial plexus</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Proc R Soc Med.</span> 1974 Jun;67(6 Pt 1):488-90.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20management%20of%20injuries%20to%20the%20brachial%20plexus&as_occt=title&as_sauthors=%20%22CB%20Parry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Rorabeck CH</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">The management of the flail
                  upper extremity in brachial plexus injuries</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Trauma.</span> 1980
                Jun;20(6):491-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20management%20of%20the%20flail%20upper%20extremity%20in%20brachial%20plexus%20injuries&as_occt=title&as_sauthors=%20%22CH%20Rorabeck%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Ransford AO</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Hughes SPF</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Complete brachial plexus
                  lesions: a ten-year follow-up of twenty cases</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 1977
                Nov;59-B(4):417-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Complete%20brachial%20plexus%20lesions%3A%20a%20ten-year%20follow-up%20of%20twenty%20cases&as_occt=title&as_sauthors=%20%22AO%20Ransford%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Bedi A</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Miller B</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Jebson PJL</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Combined glenohumeral
                  arthrodesis and above-elbow amputation for the flail limb following a complete posttraumatic brachial
                  plexus injury</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Tech Hand Up Extrem Surg.</span> 2005 Jun;9(2):113-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Combined%20glenohumeral%20arthrodesis%20and%20above-elbow%20amputation%20for%20the%20flail%20limb%20following%20a%20complete%20posttraumatic%20brachial%20plexus%20injury&as_occt=title&as_sauthors=%20%22A%20Bedi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Terzis JK</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Vekris MD</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Soucacos PN</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Brachial plexus root
                  avulsions</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">World J Surg.</span> 2001 Aug;25(8):1049-61.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Brachial%20plexus%20root%20avulsions&as_occt=title&as_sauthors=%20%22JK%20Terzis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Giuffre JL</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Bishop AT</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Spinner RJ</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Kircher MF</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Shin AY</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Wrist, first carpometacarpal
                  joint, and thumb interphalangeal joint arthrodesis in patients with brachial plexus injuries</span>.
                <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Hand Surg Am.</span>
                2012 Dec;37(12):2557-63.e1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Wrist%2C%20first%20carpometacarpal%20joint%2C%20and%20thumb%20interphalangeal%20joint%20arthrodesis%20in%20patients%20with%20brachial%20plexus%20injuries&as_occt=title&as_sauthors=%20%22JL%20Giuffre%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Wilkinson MCP</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Birch R</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Bonney G</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Brachial plexus injury: when to
                  amputate?</span><span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Injury.</span> 1993 Oct;24(9):603-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Brachial%20plexus%20injury%3A%20when%20to%20amputate%3F&as_occt=title&as_sauthors=%20%22MCP%20Wilkinson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Terzis JK</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Vekris MD</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Soucacos PN</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Outcomes of brachial plexus
                  reconstruction in 204 patients with devastating paralysis</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Plast Reconstr Surg.</span> 1999
                Oct;104(5):1221-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20brachial%20plexus%20reconstruction%20in%20204%20patients%20with%20devastating%20paralysis&as_occt=title&as_sauthors=%20%22JK%20Terzis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Sammer DM</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Kircher MF</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Bishop AT</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Spinner RJ</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Shin AY</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Hemi-contralateral C7 transfer
                  in traumatic brachial plexus injuries: outcomes and complications</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2012 Jan
                18;94(2):131-7.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1130123"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Wang SF</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Li PC</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Xue YH</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Yiu HW</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Li YC</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Wang HH</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Contralateral C7 nerve transfer
                  with direct coaptation to restore lower trunk function after traumatic brachial plexus
                  avulsion</span>. <span data-id="emphasis_27" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2013 May 1;95(9):821-7, S1-2.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1164927" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Doi K</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Muramatsu K</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Hattori Y</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Otsuka K</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Tan SH</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Nanda V</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Watanabe M</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Restoration of prehension with
                  the double free muscle technique following complete avulsion of the brachial plexus. Indications and
                  long-term results</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2000 May;82(5):652-66.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=759089" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Childress DS</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Historical aspects of powered
                  limb prostheses</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Clinical Prosthetics & Orthotics.</span> 1985;9(1):2-13.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Historical%20aspects%20of%20powered%20limb%20prostheses&as_occt=title&as_sauthors=%20%22DS%20Childress%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Raschke SU</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Limb Prostheses: Industry 1.0
                  to 4.0: Perspectives on Technological Advances in Prosthetic Care</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">Front Rehabil Sci.</span> 2022 Mar
                10;3:854404.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Limb%20Prostheses%3A%20Industry%201.0%20to%204.0%3A%20Perspectives%20on%20Technological%20Advances%20in%20Prosthetic%20Care&as_occt=title&as_sauthors=%20%22SU%20Raschke%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Da5e9b424-a285-47a2-b9e1-f5f661835990%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2375</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00938</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 10, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_31"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Mayo
                            Clinic Brachial Plexus Clinic, Rochester, Minnesota</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;a5e9b424-a285-47a2-b9e1-f5f661835990&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=a5e9b424-a285-47a2-b9e1-f5f661835990&type=pdf&name=JBJS.23.00938.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=a5e9b424-a285-47a2-b9e1-f5f661835990&type=pdf&name=JBJS.23.00938.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_36"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_37" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I8"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I8</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;a5e9b424-a285-47a2-b9e1-f5f661835990&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=a5e9b424-a285-47a2-b9e1-f5f661835990&type=zip&name=JBJS.23.00938.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ellen Y. Lee, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1175-7132" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1175-7132</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Andrew W. Nelson, CPO<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0003-1834-715X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0003-1834-715X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brandon P. Sampson, CP<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6748-5661" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6748-5661</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">F. Clay Smither, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7738-9040" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7738-9040</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nicholas Pulos, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8706-4522" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8706-4522</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Allen T. Bishop, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9856-6448" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9856-6448</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robert J. Spinner, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0443-7763" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0443-7763</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alexander Y. Shin, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:shin.alexander@mayo.edu" class=""
                    style="position: unset;">shin.alexander@mayo.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9658-8192" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9658-8192</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Hand and Microvascular Surgery, Department of Orthopedic Surgery,
                Mayo Clinic, Rochester, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Hand and Reconstructive Microsurgery, National University Health
                System, Singapore</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Limb Lab, Rochester, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Physical Medicine and Rehabilitation, Mayo Clinic, Rochester,
                Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Department of Neurosurgery, Mayo Clinic, Rochester, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 28926.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
