import type { Article } from "../../../../../types";

export const Article511: Article = {
	id: 511,
	rsuiteId: "24986d95-79bf-46da-9d3c-e23c3a134551",
	type: "symposium series",
	title:
		"Influence of Sex and Gender on Musculoskeletal Conditions and How They Are Reported",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/511.png",
	subSpecialties: ["sports_medicine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Influence of Sex and Gender on Musculoskeletal Conditions
                and How They Are Reported</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Laura L.
                      Tosi, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Kimberly
                      Templeton, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract:</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">There is increasing evidence that musculoskeletal
                        tissues are differentially regulated by sex hormones in males and females. The influence of sex
                        hormones, in addition to other sex-based differences such as in anatomical alignment and
                        immune-system function, impact the prevalence and severity of disease as well as the types of
                        injuries that affect the musculoskeletal system and the outcomes of prevention measures and
                        treatment. Literature specifically addressing sex differences related to the musculoskeletal
                        system is limited, underscoring the imperative for both basic and clinical research on this
                        topic. This review highlights areas of research that have implications for bone and cartilage
                        health, including growth and development, sports injuries, osteoarthritis, osteoporosis, and
                        bone frailty. It is clear that important aspects of the musculoskeletal system have been
                        understudied. Consideration of how sex hormone therapy will affect musculoskeletal tissues in
                        prepuberty, during puberty, and in adults is vital, yet little is known. The purpose of this
                        article is to foster awareness and interest in advancing our understanding of how sex
                        differences influence orthopaedic practice.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Genetic, epigenetic, cellular, molecular, anatomical,
                  biomechanical, environmental, and hormonal differences between women and men can create different
                  health issues, responses to treatment, and outcomes<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Gender constructs can add to these differences through their impact on resource access (e.g., access
                  to appropriate prevention initiatives), behaviors (e.g., risky behaviors and related injuries),
                  willingness to seek care, societal gendered expectations (e.g., a role as primary caregiver), and
                  other factors. Sex differences are particularly pronounced in musculoskeletal conditions, including in
                  the underlying biology and in response to treatment. Differences in the skeletons of males and females
                  have long been recognized<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a> and
                  are a hallmark of the archeological record of human evolution. The incidences of musculoskeletal
                  conditions, injuries, and diseases differ by sex, as do pain sensation, drug handling, reactions to
                  anesthesia and surgery, and healing responses. The biological basis for these differences is not yet
                  well understood for all conditions, and further research is required for the development of better
                  prevention strategies, diagnostic techniques, and treatments<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Our understanding of the impact of sex on the
                  musculoskeletal system has been limited for a number of reasons. Animal studies tend to use male
                  animals or mixed populations, which can obscure the specific contributions of genetic sex to the
                  observations. Even at the cellular level, the sex of the cell or cell line is frequently not
                  considered or mentioned in the methods. This can lead to erroneous conclusions concerning
                  mechanism-regulating cellular responses, particularly when examining the roles of steroid hormones
                  such as estrogen or testosterone<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Beyond the inclusion of both sexes in research, the
                  disaggregation of data is needed for studies that include cell lines, animal models, and humans, but
                  it is infrequently performed. The literature tells the story. In a recent review of papers on sex and
                  gender in skeletal research using mice, Sharma et al.<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">5</span></a>
                  reported that only 2.6% of publications in PubMed between 1999 and 2009 mentioned sex in the title or
                  abstract. This statistic improved to just 4.1% in 2010 to 2020 and was not much better in journals
                  that are specifically focused on bone, with rates of 8.1% in the <span data-id="emphasis_78"
                    class="annotation emphasis" style="position: unset;">Journal of Bone and Mineral Research,</span>
                  17.3% in <span data-id="emphasis_79" class="annotation emphasis" style="position: unset;">Calcified
                    Tissue International and Musculoskeletal Research,</span> and 7.5% in <span data-id="emphasis_80"
                    class="annotation emphasis" style="position: unset;">Bone.</span> A review of articles on common
                  musculoskeletal conditions in 4 high-impact journals found that results based on sex were reported in
                  only approximately 30% of the articles; furthermore, the percentage was just slightly higher when the
                  research involved osteoarthritis (OA) of the knee, which is known to exhibit sex-based differences<a
                    href="" data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">6</span></a>. Recent publications focusing on other specific
                  musculoskeletal conditions have also demonstrated low rates of reporting of results based on sex<a
                    href="" data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">10</span></a>.
                  This lack of reporting makes it difficult to discern sex-based differences in disease risk, disease
                  presentation, and treatment outcomes, which, if known, could impact prevention measures, the
                  development of clinical practice guidelines, and what and how care is delivered.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Despite recognition that sex and gender have a clear
                  impact on health-care delivery and outcomes, it was not until 2015 that the National Institutes of
                  Health (NIH) issued the Guide Notice (NOT-OD-15-102) urging investigators to consider the possible
                  role of sex differences as a part of their research design, analyses, and reporting of vertebrate
                  animal and human studies<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">11</span></a>. The
                  present review highlights what we can learn from recent research in which sex has been reported, with
                  the hope of encouraging attention to sex differences in future musculoskeletal research and clinical
                  practice.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Preclinical
                and Genetic Studies</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Every Cell
                Has a Sex</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">An increasing number of reports have shown that the
                  genetic sex of a cell plays a major role in its biological function. This role has been demonstrated
                  in vivo with respect to the contribution of multipotent mesenchymal cells to differences in
                  musculoskeletal conditions, particularly in bone, between males and females<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">12</span></a>. Human males appear to have more foci for these cells
                  within their bone marrow, suggesting a greater capacity for osteogenic differentiation. In contrast,
                  females exhibit greater capacity for neuronal differentiation. These differences are observable not
                  only at the tissue level but also in vitro. However, a lack of donor heterogeneity is reflected in the
                  single-sex populations utilized for these studies, which can preclude reaching definitive sex-based
                  conclusions<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">13</span></a>.
                  Achieving sufficient power in complex, in vitro experimental designs is difficult as a result of
                  variability in a variety of factors, including donor age, obesity, and prior exposure to disease,
                  trauma, and medications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">There are a number of examples that demonstrate the
                  difficulty in studying the role of cell sex in physiological outcomes in musculoskeletal conditions.
                  Type-2 diabetes mellitus (T2DM), which has a major impact on the wound-healing and regeneration of
                  musculoskeletal tissues, can be considered a sex and gender-linked disease: both genetic sex and
                  gender affect the incidence and severity of T2DM<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">14</span></a>.
                  Although it has been shown that T2DM can affect the immune system and that there are differences in
                  immune cell responses between males and females<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">15</span></a>,
                  little is known about sex-based interactions between T2DM and immune response and how these
                  differences might contribute to the success (or failure) of orthopaedic implants or other treatment
                  modalities. It has been hypothesized that estrogen has a protective effect, as suggested by animal
                  studies showing that estrogens reduce whole-body adiposity and increase insulin sensitivity and
                  overall glucose tolerance<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">17</span></a>. However, because of variability in the human population,
                  even in well-controlled studies, it is difficult to separate from other factors the relative
                  contributions of sex or gender to the eventual clinical outcome. For example, research has shown that
                  males with T2DM are more likely than females to present with diabetic foot ulcers, especially ulcers
                  that are larger. It is unknown if this reflects sex-based differences in the biology of T2DM,
                  sex-based differences in the biology of an associated vascular disease, or gender-based differences,
                  as women are more likely to engage in self-care<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">18</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Impact of
                Sex Hormones on Musculoskeletal Cells</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Studies assessing the effects of estrogen are particularly
                  sensitive to the sex of the cell. Early studies that utilized osteoblast lineage cells, including bone
                  marrow stromal cells and osteoblasts, to examine the response of cells to orthopaedic implant
                  materials showed a clear sex-dependence<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">19</span></a>. Cells from male donors failed to show an estrogen effect;
                  however, cells from female donors showed a marked response to estrogen that depended on the surface
                  properties of their substrate<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">20</span></a>. Studies examining the regulation of articular chondrocytes
                  have also shown sexual dimorphism in the response of these cells to estrogen and testosterone<a
                    href="" data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">22</span></a>; similar findings have been demonstrated in studies
                  examining the regulation of growth plate chondrocytes by these hormones<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">23</span></a>. It is becoming increasingly clear that, although male and
                  female cells have receptors for both estrogen and testosterone, there are sex-specific differences in
                  receptor number<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">24</span></a> and
                  in the mechanisms by which estrogen and testosterone signal through membrane-mediated pathways<a
                    href="" data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">25</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">In a review of sex differences in tissue repair, Knewtson
                  et al.<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">26</span></a>
                  discussed the potential impact of estrogens and the importance of performing studies in the field of
                  tissue engineering that differentiate outcomes on the basis of cell sex. They noted that both in vitro
                  and in vivo studies have found differences between male and female cells with respect to adult
                  musculoskeletal stem-cell number, proliferation and differentiation capabilities, and response to
                  estrogen treatment. Among the many differences between cells that are XX and those that are XY are the
                  degree of senescence and the ability to maintain stemness, both of which impact the regenerative
                  capacity of a tissue in vivo or the production of tissues ex vivo.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Gene
                Expression</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Over the past 2 decades, genome-wide analyses of gene
                  expression have provided a much better understanding of the genes located on the sex chromosomes,
                  their evolution, and their function. This research not only confirms the extensive phenotypic
                  divergence between the sexes but also reveals that there is an equally extensive amount of divergence
                  in gene expression. Moreover, the inherent inequality in the amount of genetic material in the X and Y
                  chromosomes between the 2 sexes has effects throughout the body, not just on the gonads. Thus,
                  although males and females share a common genome, genes that differ in expression between the sexes
                  (i.e., sex-biased genes) lead to variation in phenotypic traits<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">27</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">A recent study by Pacheco et al.<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">28</span></a> utilized pathway analyses to reveal sex-specific
                  dysregulation of key biological processes associated with frailty, such as inflammation,
                  musculoskeletal physiology, the cell cycle, and metabolism. They underscored the importance of sex
                  differences in gene expression by exploring transcriptome differences that could contribute to
                  pathophysiological profiles of frailty in middle-aged males and females from the Healthy Aging in
                  Neighborhoods of Diversity across the Life Span (HANDLS) study. Their work provides novel insights
                  into candidate genes and biological pathways that differ between middle-aged males and females. They
                  found that reduced inflammation was associated with pathways exclusive to frail females versus normal
                  females, whereas aberrant musculoskeletal physiology was associated with pathways exclusive to frail
                  males versus normal males. Additionally, reduced cell cycle regulation and activated catabolism were
                  associated with pathways exclusive to frail males versus frail females. By focusing on changes
                  occurring in midlife, Pacheco et al. hope to advance knowledge on frailty progression.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Growth and
                Development</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Biology of
                the Growth Plate in Girls and Boys</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Studies examining the role of sex in the biology of the
                  growth plate have provided detailed information on the sex-specific signaling mechanisms by which
                  estrogen and testosterone to mediate chondrocyte proliferation and differentiation<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">30</span></a>. Estrogen acts on cells in the reserve zone (i.e., resting
                  zone) of the growth plate, stimulating proliferation and long-bone growth. Through its actions on
                  membrane-associated estrogen receptors, including the canonical estrogen receptor (ERα66) and the
                  splice variant ERα36, estrogen signals the postproliferative cells to undergo hypertrophy. During
                  puberty, circulating estrogen levels increase, causing more of the cells to hypertrophy and to calcify
                  their extracellular matrix, ultimately leading to the closure of the growth plate. Female growth plate
                  chondrocytes metabolize systemic testosterone via aromatase to estrogen, further increasing the local
                  concentration of the hormone. In contrast, in male cells, testosterone is metabolized to
                  dihydrotestosterone (DHT), which acts via the androgen receptor. Because local and systemic estrogen
                  are comparatively low, the growth plate remains open longer. Interestingly, DHT action on the androgen
                  receptor involves both ERα and ERβ in the membrane, providing scientists with years of experiments to
                  tease out the mechanisms that regulate the effects of sex on growth and development.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Bone Health
                in Transgender and Gender-Diverse Youth</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">As more people with a gender role that is different from
                  their genetic sex at birth choose to undergo medical transition, additional research is needed to
                  ensure that we understand how their health needs might be affected by this transition, particularly in
                  the area of skeletal health. Ciancia et al.<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">31</span></a>
                  noted that many people with persistent gender dysphoria at the onset of puberty will pursue treatment
                  with long-term puberty suppression until they reach the appropriate age to start using
                  gender-affirming hormones (GAH), and that, because puberty is a critical period for bone development,
                  safety concerns have been raised regarding bone development and increased future fracture risk. van
                  der Loos et al.<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">32</span></a>
                  found that, although treatment with gonadotropin hormone-releasing hormone (GnRH) agonists followed by
                  long-term GAH is safe with regard to bone health in transgender persons receiving testosterone, bone
                  health in transgender persons receiving estrogen requires further study. Ciancia et al.<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">31</span></a> further acknowledged that most studies “indicate a negative
                  impact of long-term puberty suppression on bone mineral density, especially at the lumbar spine, which
                  is only partially restored after sex steroid administration” and added that “trans girls are more
                  vulnerable than trans boys for compromised bone health.” Thus, Ciancia et al. concluded that
                  “behavioral health measures [promoting] bone mineralization, such as weight-bearing exercise and
                  calcium and vitamin D supplementation, are strongly recommended in transgender youth” across the
                  lifespan<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">31</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Sports
                Injuries</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Although a person’s gender is having less impact on what
                  sport they play, a person’s sex plays an important role in determining their risk of, presentation
                  with, and treatment outcome after a sports injury. Bassett et al.<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">33</span></a> noted that, “In general, males have longer limb levers,
                  stronger bones, greater muscle mass and strength, and greater aerobic capacity. Females exhibit less
                  muscle fatigability and faster recovery during endurance exercise.” Physiological sex-based
                  differences are associated with disparities in performance, which have led the International Olympic
                  Committee to define blood testosterone limits for competing in the female category<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">33</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Sex differences in sports injury rates have been
                  reported<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">34</span></a>. A
                  comparison of athletes who were <span data-id="strong_79" class="annotation strong"
                    style="position: unset;">≥</span>16 years old showed that there were more injuries among male
                  athletes than among female athletes in basketball, field hockey, soccer, handball, rugby, and
                  volleyball. The injuries among male athletes were primarily in the upper extremity, hip or groin,
                  thigh, or foot, whereas the injuries among the female athletes occurred more frequently in the
                  anterior cruciate ligament (ACL). Although most ACL injuries have been shown to occur in male
                  athletes, female athletes are consistently observed to be at a higher risk for an ACL injury for a
                  given sports exposure, particularly among high school and college students<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">35</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">It is likely that both intrinsic and extrinsic factors,
                  including tibial slope, notch width, quadriceps activation, and neuromuscular control, contribute to
                  the risk of ACL injury<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">36</span></a>.
                  Recent studies have also identified differences in the biochemical properties of the collagen that
                  comprises the ligament<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">37</span></a>.
                  The sex-specific differences in the degree of ligament stiffness resulting from collagen cross-links
                  may alter the way that force is transduced to the muscle or ligament, which could also account for
                  differences in postoperative outcomes<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">38</span></a>.
                  Sex hormones also impact ligamentous laxity, and fluctuations in these hormones in females may impact
                  injury risk. Understanding sex-based differences in ACL injury risk is important when developing
                  prevention measures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Studies examining the presentation of sex-based
                  differences in orthopaedic conditions are relatively recent. A systematic review showed that male
                  patients undergoing hip arthroscopy for femoroacetabular impingement syndrome (FAIS) had a higher
                  prevalence of mixed-type FAIS, whereas female patients more frequently had pincer-type FAIS<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">39</span></a>. Moreover, female patients self-reported more improvement
                  after surgery. Although the FAIS study was robust, the importance of sex differences remains
                  relatively ignored for other injuries or conditions. Mondini Trissino da Lodi et al.<a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">40</span></a> observed that patellar tendinopathy, a common
                  musculoskeletal condition that was traditionally thought of as a male problem, affects a large number
                  of female athletes as well. They found that only 2% of the articles focused solely on female subjects
                  and that only 5% listed male-only or predominantly male studies as a limitation. Mondini Trissino da
                  Lodi et al. also noted that the results of the few cases included in the literature describe various
                  interventions, thus offering little solid evidence for the results and limitations of any therapeutic
                  approach in women. The lack of assessment of sex-based differences in sports injuries has also been
                  reported in studies that have evaluated outcomes after lateral ankle ligament repair, rotator cuff
                  repair, and ACL reconstruction.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">There is even less information on the incidence of sport
                  injuries in transgender women. Although it is generally held that the physical performance of male
                  athletes is greater than that of female athletes, limited data exist regarding the impact of female
                  hormones, particularly estrogen, on musculoskeletal biology in genetically-male tissues or the impact
                  of androgens, such as testosterone, on female musculoskeletal biology. There are also no data
                  regarding how hormonal interventions impact neuromuscular control and related injury risks. Hilton and
                  Lundberg<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">41</span></a>
                  reported a performance gap between males and females that was significant starting at puberty and
                  typically ranged from 10% to 50%, depending on the sport. They found that this gap was more noticeable
                  in athletic activities that depend on muscle mass and explosive strength, particularly in the upper
                  body. Noting that “Longitudinal studies examining the effects of testosterone suppression on muscle
                  mass and strength in transgender women consistently show very modest changes, where the loss of lean
                  body mass, muscle area and strength typically amounts to approximately 5% after 12 months of
                  treatment,” Hilton and Lundberg concluded that “the muscular advantage enjoyed by transgender women is
                  only minimally reduced when testosterone is suppressed.”<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">41</span></a>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Musculoskeletal injuries are a common challenge for the
                  growing number of young female persons who seek military careers<a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">42</span></a>. Numerous reports have indicated that active female
                  military members and recruits are at a greater risk for suffering musculoskeletal injuries during
                  training and deployment than their male counterparts. The consequences of these injuries for active
                  military members include limited duties, time off, and discharge. For recruits, consequences include
                  missed training days, limited duty days, and release<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_41" class="annotation superscript" style="position: unset;">43</span></a>. As
                  the U.S. military looks for ways to support its female members, the development of training strategies
                  that mitigate injury risk factors will be essential to the success of females pursuing careers in the
                  military and will require additional research.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Bone Loss
                and Osteoporosis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Osteoporosis is often described as a “silent disease,” but
                  it is a disorder with important consequences: fractures, particularly hip fractures, lead to marked
                  increases in morbidity and mortality. Historically, osteoporosis has been considered a “woman’s
                  disorder,” and there is a substantial body of literature describing the diagnosis and management of
                  osteoporosis in females after fracture. However, this has led physicians to under-screen,
                  underdiagnose, and undertreat the disorder in males, despite research showing that the risk of fatal
                  complications is higher in males than in females<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_42" class="annotation superscript" style="position: unset;">44</span></a>. In
                  a recent report, Mazeda and Barcelos<a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">45</span></a>
                  noted that females were more likely than males to receive pharmacotherapy and that mortality rates
                  following fracture were higher in males. They also noted that, in both sexes, a lower degree of
                  dependence was significantly associated with a greater probability of receiving anti-osteoporotic
                  therapy. One of the substantial changes in the osteoporosis literature in the last decade has been the
                  call to recognize osteoporosis in males<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_44" class="annotation superscript" style="position: unset;">46</span></a>.
                  Unfortunately, thus far, this call to action has not increased diagnosis or treatment in either sex,
                  even following a fragility fracture<a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_45" class="annotation superscript" style="position: unset;">47</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Osteoarthritis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">OA is a leading cause of disability in the United States,
                  and sex has been identified as a contributor to the damage and inflammation associated with this
                  condition<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_46" class="annotation superscript" style="position: unset;">48</span></a>.
                  Research has shown that, prior to the onset of menopause in females, the ratio of females to males
                  with OA is approximately 1:1, but after menopause, the ratio becomes &gt;2:1<a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">49</span></a>. Thus, after menopausal age in females, there is an
                  increase in the number of females with the condition compared with males of a similar age. OA is more
                  common in females than in males for every joint except the shoulder and elbow. Although risk factors
                  such as age, obesity, injury, socioeconomic status, and race have been identified, with higher rates
                  of OA observed in elderly and non-White populations, the role of sex in OA has received less
                  emphasis<a href="" data-id="citation_reference_55"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">50</span></a><span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">51</span></a>, despite the fact that sex differences in the prevalence,
                  incidence, and severity of OA have been reported for decades<a href="" data-id="citation_reference_57"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_49" class="annotation superscript" style="position: unset;">52</span></a>.
                  Szilagyi et al.<a href="" data-id="citation_reference_58"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_50" class="annotation superscript" style="position: unset;">53</span></a>
                  found “an indication of sex differences in risk factors leading to higher risk of KOA [knee OA]: high
                  BMI [body mass index], alcohol consumption, atherosclerosis and high vitamin E levels in women, and
                  high physical activity, soft drink consumption and abdominal obesity in men.” They also reported that
                  “Knee injury, high blood pressure and low step rate seem to affect both women and men.”<a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">53</span></a> However, the picture is likely more complex. Females with
                  obesity are more likely to develop OA than males with similar BMIs, and research has suggested that
                  the link between obesity and OA may be through low-grade chronic inflammation, especially for
                  females<a href="" data-id="citation_reference_60"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_52" class="annotation superscript" style="position: unset;">54</span></a>. In
                  addition, although both females and males are at an increased risk for developing OA after injury, OA
                  is more likely to occur in females, which may reflect sex-based differences in inflammatory response
                  to injury.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Given the disproportionate number of females relative to
                  males in the aging U.S. population, the greater severity of knee OA in females, and the impact of OA
                  on quality of life and independence, the diagnosis of OA is a serious clinical concern<a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">55</span></a>. In addition, females with OA are at an increased risk for
                  developing other chronic health conditions, including cardiovascular disease<a href=""
                    data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_54" class="annotation superscript"
                      style="position: unset;">56</span></a>. Even though many published reports on OA have not
                  considered sex-specific outcomes, important sex differences in clinical presentation have been
                  identified<a href="" data-id="citation_reference_63"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_55" class="annotation superscript" style="position: unset;">57</span></a>.
                  Besides recognizing that females over the age of 55 years have a higher prevalence of knee OA than
                  males, we now also know that females experience more debilitating pain<a href=""
                    data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_56" class="annotation superscript"
                      style="position: unset;">58</span></a>, have more rapidly progressing annual articular cartilage
                  loss (4 times the rate in males)<a href="" data-id="citation_reference_65"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_57" class="annotation superscript" style="position: unset;">59</span></a>,
                  and have a more severe phenotype as shown on radiographs<a href="" data-id="citation_reference_66"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_58" class="annotation superscript" style="position: unset;">52</span></a>.
                  Although the other noted differences are sex-based, pain has both a sex and a gender component.
                  Compared with males, females also tend to have worse outcomes after total knee arthroplasty, often
                  related to their preoperative pain and functional status as well as the presence of comorbidities<a
                    href="" data-id="citation_reference_67" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_59" class="annotation superscript"
                      style="position: unset;">60</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Knee tissues are modulated by sex hormones during tissue
                  development and throughout the life cycle in both males and females. Although menopause is associated
                  with an increase in OA severity in females, loss of systemic estrogen alone cannot explain the
                  observed sex differences. For example, sex-specific variations identified in the responses of
                  chondrocytes to sex steroids have been shown to be the result of differences in receptor number as
                  well as mechanisms of hormone action<a href="" data-id="citation_reference_68"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_60" class="annotation superscript" style="position: unset;">46</span></a>. At
                  the same time, sex hormones such as estrogen have been shown to mitigate pain and to have a protective
                  effect on articular cartilage biochemistry. Therefore, researchers have postulated that the
                  exacerbated progression of OA in postmenopausal females may be explained, at least in part, by the
                  loss of estrogen<a href="" data-id="citation_reference_69"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_61" class="annotation superscript" style="position: unset;">61</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Historically, OA was perceived as the result of the “wear
                  and tear” of the joints, and most research was focused on biomechanical causes of the disease. OA was
                  presented as a disease of articular cartilage because cartilage was the most affected tissue. Studies
                  on the mechanism of OA did not take sex differences into account, regardless of whether in vitro cell
                  culture or animal models were utilized. However, most recently, OA is thought of as a disease of the
                  whole joint, and the joint is analyzed as an organ system<a href="" data-id="citation_reference_70"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_62" class="annotation superscript" style="position: unset;">46</span></a>.
                  Thus, in OA of the knee joint, the subchondral bone, articular cartilage, meniscus, ACL, synovium, and
                  synovial fluid are all considered to be involved. A change in any of these tissues can influence the
                  distribution of load across the joint, with corresponding adaptations in the other tissues and,
                  ultimately, in the cartilage. Similarly, more studies have begun to explore the intricate biological
                  processes that contribute to the pathogenesis of OA, with findings that suggest that it is a far more
                  complex pathology than initially believed<a href="" data-id="citation_reference_71"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_63" class="annotation superscript" style="position: unset;">62</span></a>.
                  Although little is known about the mechanisms that contribute to disparities in disease incidence and
                  severity between males and females, they likely involve both mechanical and molecular events in the
                  affected joint. In addition, OA is now considered an inflammatory disease, and sex differences have
                  been demonstrated in some inflammatory markers in OA<a href="" data-id="citation_reference_72"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_64" class="annotation superscript" style="position: unset;">63</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Frailty and
                Lifespan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Life expectancy has increased dramatically over the last
                  few decades. According to the Centers for Disease Control and Prevention, the average life expectancy
                  at birth in the U.S. in 2021 was 79.3 years for females and 73.5 years for males<a href=""
                    data-id="citation_reference_73" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_65" class="annotation superscript"
                      style="position: unset;">64</span></a><span data-id="superscript_65"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_65" class="annotation superscript"
                      style="position: unset;">65</span></a>. Unfortunately, a longer lifespan has been accompanied by
                  an increase in frailty and decreased independence in older adults, with major differences existing
                  between males and females. For example, females tend to live longer than males but also experience
                  higher rates of frailty and disability. Yet, regardless of age or level of frailty, older frail
                  females have better survival compared with older frail males. The sex-associated divergence in frailty
                  prevalence and mortality has been referred to as the sex-frailty paradox<a href=""
                    data-id="citation_reference_75" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">66</span></a>. Sex differences in frailty and aging have been attributed
                  to a complex interplay between uncontrollable factors (e.g., genetic, epigenetic, and physiological
                  factors) and controllable factors (e.g., psychosocial and lifestyle factors)<a href=""
                    data-id="citation_reference_76" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_67" class="annotation superscript"
                      style="position: unset;">67</span></a>. Relatively little is known about sex differences in muscle
                  biology and how they might be impacted by aging. However, compared with females, males have been shown
                  to have higher rates of muscle loss and a greater incidence of sarcopenia with aging<a href=""
                    data-id="citation_reference_77" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_68" class="annotation superscript"
                      style="position: unset;">68</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Concerns regarding the use of pharmacological
                  interventions for frailty have been raised because most substances have only been tested in male
                  animals. In a systematic review, Knufinke et al.<a href="" data-id="citation_reference_78"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_69" class="annotation superscript" style="position: unset;">69</span></a>
                  analyzed the effects of interventions on median and maximal lifespan and health-span markers. They
                  found that only 36% of the 64 pharmacologic compounds tested utilized both male and female mice, yet
                  73% of these studies showed sex-specific outcomes related to health span and/or lifespan, muscle
                  function and coordination, cognitive function and learning, metabolism, and cancer. These data
                  highlight the importance of studying and reporting data for both sexes in the search for medications
                  to improve lifespan, as the biology of aging is not the same in male and female mice. More needs to be
                  known about sex-based differences in aging among humans and in the response to and safety of
                  interventions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Contribution
                of the Immune System</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Autoimmune
                Disorders</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">The immune system is increasingly understood as playing an
                  important role in the incidence and severity of conditions that affect the musculoskeletal system.
                  Autoimmune diseases disproportionately affect more females than males<a href=""
                    data-id="citation_reference_79" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_70" class="annotation superscript"
                      style="position: unset;">70</span></a>, yet males are more prone to viral infections<a href=""
                    data-id="citation_reference_80" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_71" class="annotation superscript"
                      style="position: unset;">71</span></a>. Under chronic disease conditions, there is divergence in
                  immune responsivity that complicates the intersection of sex, immune function, and disease. The
                  elevated responsivity evident in females can lead to long-term changes in immunomodulation, increasing
                  the risk for females to develop inflammatory diseases<a href="" data-id="citation_reference_81"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_72" class="annotation superscript" style="position: unset;">72</span></a>. In
                  addition, age-related changes in immune function differ between males and females. This change is
                  tightly tied to the altered levels of estrogen and testosterone that accompany normal aging.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Recently, Dou et al.<a href=""
                    data-id="citation_reference_82" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_73" class="annotation superscript"
                      style="position: unset;">73</span></a> reported findings that help to explain the association of
                  the XX sex chromosome complement with susceptibility to autoimmunity. They found that Xist long
                  non-coding RNA (lncRNA) is expressed only in females to randomly inactivate 1 of the 2 X chromosomes
                  to achieve gene dosage compensation. The Xist ribonucleoprotein (RNP) complex is an important driver
                  of sex-biased autoimmunity, and multiple RNPs can interact with lncRNA. Because male mice have only a
                  single X chromosome, they do not exhibit the same ability to produce autoantibodies. People with
                  autoimmune diseases display significant autoantibodies to multiple components of Xist RNP. Studies of
                  this kind are critical for our ability to devise technologies that address the role of
                  immunomodulation in musculoskeletal conditions that have a sex-specific dependence. Sex differences in
                  immune responsivity create uniquely vulnerable disease states in males and females. Despite these
                  known effects, little attention has been paid to sex as a contributing factor when determining best
                  practices in the treatment of immune-related conditions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Although there is inconsistent or insufficient evidence of
                  sex differences in disease incidence and in the presentation of a disease or condition in the
                  musculoskeletal system, even less is understood about the impact of immunomodulation on the outcomes
                  of orthopaedic interventions, including the use of biomaterials. Orthopaedic surgery by its very
                  nature involves responding to or creating an injury, and the response to that injury depends on the
                  immune system, which is itself impacted by sex. Sex differences in immune function and responsivity
                  are evident throughout the lifespan and impact the response to disease states. In general, females
                  tend to mount a more robust immune response than males. For example, the stimulation of toll-like
                  receptor 7 (TLR7) has been shown to induce a larger output of interferon α in females than in males<a
                    href="" data-id="citation_reference_83" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_74" class="annotation superscript"
                      style="position: unset;">74</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Immune
                Response to Injury</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Sex differences in immune response to musculoskeletal
                  injury have been identified in mouse models, but more needs to be learned about this interaction in
                  humans<a href="" data-id="citation_reference_84"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_75" class="annotation superscript" style="position: unset;">75</span></a>.
                  Male sex has been repeatedly identified as a risk factor for infection surrounding orthopaedic
                  implants and for orofacial implant failure, although such guidance comes from relatively small
                  prospective and retrospective studies. This is an underexplored but important issue. However, it is
                  unclear how much of the risk of implant failure is impacted by biological differences and how much is
                  due to gender (e.g., gendered differences in activity level). Mechanistically, the risk of implant
                  failure may be related to the sex differences observed for macrophage polarization; notably, the
                  pro-regenerative M2 macrophage phenotype is stronger in females<a href=""
                    data-id="citation_reference_85" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_76" class="annotation superscript"
                      style="position: unset;">14</span></a>. Caicedo et al.<a href="" data-id="citation_reference_86"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_77" class="annotation superscript" style="position: unset;">76</span></a>
                  found that, among patients with continued pain after joint arthroplasty, females had higher levels of
                  pain than males. Females also demonstrated higher rates and severity of metal sensitization, which may
                  help to explain at least a part of their pain. In addition, Latteier et al.<a href=""
                    data-id="citation_reference_87" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_78" class="annotation superscript"
                      style="position: unset;">77</span></a> found that females were more likely than males to develop
                  an increased risk of failure due to aseptic loosening and pseudotumors after undergoing a
                  metal-on-metal hip arthroplasty. These findings are indications of the complex interplay between sex
                  hormones, orthopaedic injury, immune response to biomaterials, and patient outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Where do we stand regarding how sex and gender affect
                  musculoskeletal afflictions and treatment? Perhaps “unsettled” is a reasonable characterization,
                  indicating that future research—and reporting on research—must pay attention to the potential effects
                  of sex and gender. We need to be cautious in employing past research and research reports that
                  aggregate results for males and females or that include insufficient numbers of both sexes, which may
                  mislead us moving forward. Past efforts that acknowledge their reliance on male-only models and
                  databases remain relevant for males but not necessarily for females. However, we benefit from the
                  limited literature in which results for females are clearly identified, as understanding the impacts
                  of sex and gender on health and disease improves outcomes for everyone.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">A recent review offers a useful set of 3 general
                  guidelines: (1) sex differences are relative, not (necessarily) absolute; (2) differences may occur
                  during hormone-level changes, thus ranging across the lifespan; and (3) some musculoskeletal
                  conditions that exhibit sex differences likely involve different molecular mechanisms—not all
                  differences can be directly traced to sex hormones<a href="" data-id="citation_reference_88"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_79" class="annotation superscript" style="position: unset;">51</span></a>. In
                  addition, every cell has a sex, so responses to hormones can vary; there are also organism-level
                  differences to consider, such as differences in limb alignment and neuromuscular control.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Improving the knowledge base regarding sex-based
                  differences in musculoskeletal conditions requires a multipronged approach, including input from
                  journals. Musculoskeletal journals need to help us by ensuring that future research will consider sex
                  in the research design and statistics plan and insisting that any manuscript accepted for publication
                  either report on the sex of the subjects or, if including both male and female subjects was not
                  appropriate for a given study, explain that to readers.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Equally importantly, we need to use improved research
                  efforts to guide our practices and to inform our training programs, including their epidemiologic
                  content. A well-known failure of the health-care system is the failure to ask about or respond
                  appropriately to conditions that predominantly affect females (e.g., fragility fractures). Important
                  differences in the sex-based incidence and prevalence of musculoskeletal conditions, injuries, and
                  diseases need emphasis. The challenge of positioning orthopaedists to consider, and deal thoughtfully
                  with, sex-based differences in musculoskeletal conditions may look daunting given the now-understood
                  deficits in research and research reporting, but establishing the epidemiologic basis of our practice
                  is an important and effective way to begin.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Tosi LL</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Boyan BD</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Boskey AL</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Does sex matter in
                  musculoskeletal health? The influence of sex and gender on musculoskeletal health</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2005 Jul;87(7):1631-47.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=978406"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Seeman E</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Periosteal bone formation—a
                  neglected determinant of bone strength</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">N Engl J Med.</span> 2003 Jul 24;349(4):320-3.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periosteal%20bone%20formation%E2%80%94a%20neglected%20determinant%20of%20bone%20strength&as_occt=title&as_sauthors=%20%22E%20Seeman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Leopold SS</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Beadling L</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Dobbs MB</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Gebhardt MC</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Lotke PA</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Manner PA</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Rimnac CM</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Wongworawat MD</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Fairness to all: gender and sex
                  in scientific reporting</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2014 Feb;472(2):391-2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fairness%20to%20all%3A%20gender%20and%20sex%20in%20scientific%20reporting&as_occt=title&as_sauthors=%20%22SS%20Leopold%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Zucker I</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Prendergast BJ</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Beery AK</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Pervasive Neglect of Sex
                  Differences in Biomedical Research</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Cold Spring Harb Perspect Biol.</span> 2022 May 17;14(4):a039156.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pervasive%20Neglect%20of%20Sex%20Differences%20in%20Biomedical%20Research&as_occt=title&as_sauthors=%20%22I%20Zucker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Sharma A</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Michels LV</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Pitsillides AA</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Greeves J</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Plotkin LI</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Cardo V</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Sims NA</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Clarkin CE</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Sexing Bones: Improving
                  Transparency of Sex Reporting to Address Bias Within Preclinical Studies</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J Bone Miner Res.</span>
                2023 Jan;38(1):5-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sexing%20Bones%3A%20Improving%20Transparency%20of%20Sex%20Reporting%20to%20Address%20Bias%20Within%20Preclinical%20Studies&as_occt=title&as_sauthors=%20%22A%20Sharma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Stumpff K</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Hadley M</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Corn K</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Templeton K</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Sex-Based Reporting of Common
                  Musculoskeletal Conditions</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Womens Health (Larchmt).</span> 2021 May;30(5):689-93.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex-Based%20Reporting%20of%20Common%20Musculoskeletal%20Conditions&as_occt=title&as_sauthors=%20%22K%20Stumpff%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Goodrich E</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Vopat ML</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Baker J</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Tarakemeh A</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Templeton K</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Mulcahey MK</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Schroeppel JP</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Mullen S</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Vopat BG</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Sex-Specific Differences
                  Following Lateral Ankle Ligament Repair</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Foot Ankle Int.</span> 2021 Oct;42(10):1311-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex-Specific%20Differences%20Following%20Lateral%20Ankle%20Ligament%20Repair&as_occt=title&as_sauthors=%20%22E%20Goodrich%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Fancher AJ</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Mok AC</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Vopat ML</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Templeton K</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Kimbrel BK</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Tarakemeh A</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Mulcahey MK</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Mullen S</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Schroeppel JP</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Vopat BG</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Comparing Sex-Specific Outcomes
                  After Rotator Cuff Repair: A Meta-analysis</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span> 2022 May
                20;10(5):23259671221086259.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparing%20Sex-Specific%20Outcomes%20After%20Rotator%20Cuff%20Repair%3A%20A%20Meta-analysis&as_occt=title&as_sauthors=%20%22AJ%20Fancher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Fancher AJ</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Hinkle AJ</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Vopat ML</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Templeton K</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Tarakemeh A</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Vopat BG</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Mulcahey MK</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Comparing Sex-Specific Outcomes
                  After Medial Patellofemoral Ligament Reconstruction for Patellar Instability: A Systematic
                  Review</span>. <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Orthop
                  J Sports Med.</span> 2021 Nov 29;9(11):23259671211058170.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparing%20Sex-Specific%20Outcomes%20After%20Medial%20Patellofemoral%20Ligament%20Reconstruction%20for%20Patellar%20Instability%3A%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22AJ%20Fancher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Mok AC</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Fancher AJ</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Vopat ML</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Baker J</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Tarakemeh A</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Mullen S</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Schroeppel JP</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Templeton K</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Mulcahey MK</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Vopat BG</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Sex-Specific Outcomes After
                  Anterior Cruciate Ligament Reconstruction: A Systematic Review and Meta-analysis</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span>
                2022 Feb 23;10(2):23259671221076883.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex-Specific%20Outcomes%20After%20Anterior%20Cruciate%20Ligament%20Reconstruction%3A%20A%20Systematic%20Review%20and%20Meta-analysis&as_occt=title&as_sauthors=%20%22AC%20Mok%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;">National
                Institutes of Health. <span data-id="strong_11" class="annotation strong"
                  style="position: unset;">Consideration of sex as a biological variable in NIH-funded research</span>.
                2015. Accessed 2024 Feb 10.
                http://grants.nih.gov/grants/guide/notice-files/NOT-OD-15-102.html</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Consideration%20of%20sex%20as%20a%20biological%20variable%20in%20NIH-funded%20research&as_occt=title&as_sauthors=%20%22National%20Institutes%20of%20Health%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Greiner JFW</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Merten M</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Kaltschmidt C</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Kaltschmidt B</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Sexual dimorphisms in adult
                  human neural, mesoderm-derived, and neural crest-derived stem cells</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">FEBS Lett.</span> 2019
                Dec;593(23):3338-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sexual%20dimorphisms%20in%20adult%20human%20neural%2C%20mesoderm-derived%2C%20and%20neural%20crest-derived%20stem%20cells&as_occt=title&as_sauthors=%20%22JFW%20Greiner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Berger MB</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Cohen DJ</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Olivares-Navarrete R</span>,
                <span data-id="annotation_71" class="annotation" style="position: unset;"> Williams JK</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Cochran DL</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Boyan BD</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Schwartz Z</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Human osteoblasts exhibit
                  sexual dimorphism in their response to estrogen on microstructured titanium surfaces</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Biol Sex Differ.</span>
                2018 Jul 3;9(1):30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Human%20osteoblasts%20exhibit%20sexual%20dimorphism%20in%20their%20response%20to%20estrogen%20on%20microstructured%20titanium%20surfaces&as_occt=title&as_sauthors=%20%22MB%20Berger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Kautzky-Willer A</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Harreiter J</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Pacini G</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Sex and Gender Differences in
                  Risk, Pathophysiology and Complications of Type 2 Diabetes Mellitus</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Endocr Rev.</span> 2016
                Jun;37(3):278-316.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20and%20Gender%20Differences%20in%20Risk%2C%20Pathophysiology%20and%20Complications%20of%20Type%202%20Diabetes%20Mellitus&as_occt=title&as_sauthors=%20%22A%20Kautzky-Willer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Seghieri G</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Franconi F</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Campesi I</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Why we need sex-gender
                  medicine: The striking example of type 2 diabetes</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Diabetology (Basel).</span>
                2022;3:460-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Why%20we%20need%20sex-gender%20medicine%3A%20The%20striking%20example%20of%20type%202%20diabetes&as_occt=title&as_sauthors=%20%22G%20Seghieri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> González-Granillo M</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Savva C</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Li X</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Ghosh Laskar M</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Angelin B</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Gustafsson JÅ</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Korach-André M</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Selective estrogen receptor
                  (ER)β activation provokes a redistribution of fat mass and modifies hepatic triglyceride composition
                  in obese male mice</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Mol Cell Endocrinol.</span> 2020 Feb 15;502:110672.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Selective%20estrogen%20receptor%20(ER)%CE%B2%20activation%20provokes%20a%20redistribution%20of%20fat%20mass%20and%20modifies%20hepatic%20triglyceride%20composition%20in%20obese%20male%20mice&as_occt=title&as_sauthors=%20%22M%20Gonz%C3%A1lez-Granillo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Policardo L</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Seghieri G</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Francesconi P</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Anichini R</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Franconi F</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Del Prato S</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Gender difference in diabetes
                  related excess risk of cardiovascular events: When does the ‘risk window’ open?</span><span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J Diabetes
                  Complications.</span> 2017 Jan;31(1):74-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gender%20difference%20in%20diabetes%20related%20excess%20risk%20of%20cardiovascular%20events%3A%20When%20does%20the%20%E2%80%98risk%20window%E2%80%99%20open%3F&as_occt=title&as_sauthors=%20%22L%20Policardo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Rossboth S</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Lechleitner M</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Oberaigner W</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Risk factors for diabetic foot
                  complications in type 2 diabetes-A systematic review</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Endocrinol Diabetes Metab.</span> 2020 Aug
                17;4(1):e00175.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20diabetic%20foot%20complications%20in%20type%202%20diabetes-A%20systematic%20review&as_occt=title&as_sauthors=%20%22S%20Rossboth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Olivares-Navarrete R</span>,
                <span data-id="annotation_98" class="annotation" style="position: unset;"> Hyzy SL</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Boyan BD</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Schwartz Z</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Regulation of osteoblast
                  differentiation by acid-etched and/or grit-blasted titanium substrate topography is enhanced by
                  1,25(OH)2D3 in a sex-dependent manner</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Biomed Res Int.</span> 2015;2015:365014.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Regulation%20of%20osteoblast%20differentiation%20by%20acid-etched%20and%2For%20grit-blasted%20titanium%20substrate%20topography%20is%20enhanced%20by%201%2C25(OH)2D3%20in%20a%20sex-dependent%20manner&as_occt=title&as_sauthors=%20%22R%20Olivares-Navarrete%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Lohmann CH</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Tandy EM</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Sylvia VL</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Hell-Vocke AK</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Cochran DL</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Dean DD</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Boyan BD</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Schwartz Z</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Response of normal female human
                  osteoblasts (NHOst) to 17beta-estradiol is modulated by implant surface morphology</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Biomed Mater Res.</span>
                2002 Nov;62(2):204-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Response%20of%20normal%20female%20human%20osteoblasts%20(NHOst)%20to%2017beta-estradiol%20is%20modulated%20by%20implant%20surface%20morphology&as_occt=title&as_sauthors=%20%22CH%20Lohmann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Kinney RC</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Schwartz Z</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Week K</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Lotz MK</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Boyan BD</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Human articular chondrocytes
                  exhibit sexual dimorphism in their responses to 17beta-estradiol</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Osteoarthritis Cartilage.</span> 2005
                Apr;13(4):330-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Human%20articular%20chondrocytes%20exhibit%20sexual%20dimorphism%20in%20their%20responses%20to%2017beta-estradiol&as_occt=title&as_sauthors=%20%22RC%20Kinney%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Pan Q</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> O’Connor MI</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Coutts RD</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Hyzy SL</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Olivares-Navarrete R</span>,
                <span data-id="annotation_119" class="annotation" style="position: unset;"> Schwartz Z</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Boyan BD</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Characterization of
                  osteoarthritic human knees indicates potential sex differences</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Biol Sex Differ.</span> 2016 Jun
                2;7:27.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Characterization%20of%20osteoarthritic%20human%20knees%20indicates%20potential%20sex%20differences&as_occt=title&as_sauthors=%20%22Q%20Pan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Joshua Cohen D</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> ElBaradie K</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Boyan BD</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Schwartz Z</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Sex-specific effects of
                  17β-estradiol and dihydrotestosterone (DHT) on growth plate chondrocytes are dependent on both ERα and
                  ERβ and require palmitoylation to translocate the receptors to the plasma membrane</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Biochim Biophys Acta Mol
                  Cell Biol Lipids.</span> 2021 Dec;1866(12):159028.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex-specific%20effects%20of%2017%CE%B2-estradiol%20and%20dihydrotestosterone%20(DHT)%20on%20growth%20plate%20chondrocytes%20are%20dependent%20on%20both%20ER%CE%B1%20and%20ER%CE%B2%20and%20require%20palmitoylation%20to%20translocate%20the%20receptors%20to%20the%20plasma%20membrane&as_occt=title&as_sauthors=%20%22D%20Joshua%20Cohen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Nasatzky E</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Schwartz Z</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Soskolne WA</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Brooks BP</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Dean DD</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Boyan BD</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Ornoy A</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Evidence for receptors specific
                  for 17 beta-estradiol and testosterone in chondrocyte cultures</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">Connect Tissue Res.</span>
                1994;30(4):277-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evidence%20for%20receptors%20specific%20for%2017%20beta-estradiol%20and%20testosterone%20in%20chondrocyte%20cultures&as_occt=title&as_sauthors=%20%22E%20Nasatzky%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> ElBaradie K</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Boyan BD</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Schwartz Z</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Rapid membrane responses to
                  dihydrotestosterone are sex dependent in growth plate chondrocytes</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">J Steroid Biochem Mol Biol.</span> 2012
                Oct;132(1-2):15-23.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rapid%20membrane%20responses%20to%20dihydrotestosterone%20are%20sex%20dependent%20in%20growth%20plate%20chondrocytes&as_occt=title&as_sauthors=%20%22K%20ElBaradie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Knewtson KE</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Ohl NR</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Robinson JL</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Estrogen Signaling Dictates
                  Musculoskeletal Stem Cell Behavior: Sex Differences in Tissue Repair</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Tissue Eng Part B
                  Rev.</span> 2022 Aug;28(4):789-812.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Estrogen%20Signaling%20Dictates%20Musculoskeletal%20Stem%20Cell%20Behavior%3A%20Sex%20Differences%20in%20Tissue%20Repair&as_occt=title&as_sauthors=%20%22KE%20Knewtson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Parsch J</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Ellegren H</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The evolutionary causes and
                  consequences of sex-biased gene expression</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">Nat Rev Genet.</span> 2013
                Feb;14(2):83-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20evolutionary%20causes%20and%20consequences%20of%20sex-biased%20gene%20expression&as_occt=title&as_sauthors=%20%22J%20Parsch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Pacheco NL</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Noren Hooten N</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Prince CS</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Mode NA</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Ezike N</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Becker KG</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Zonderman AB</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Evans MK</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Sex-specific transcriptome
                  differences in a middle-aged frailty cohort</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">BMC Geriatr.</span> 2022 Aug
                9;22(1):651.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex-specific%20transcriptome%20differences%20in%20a%20middle-aged%20frailty%20cohort&as_occt=title&as_sauthors=%20%22NL%20Pacheco%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Nasatzky E</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Schwartz Z</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Boyan BD</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Soskolne WA</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Ornoy A</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Sex-dependent effects of
                  17-beta-estradiol on chondrocyte differentiation in culture</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">J Cell Physiol.</span> 1993
                Feb;154(2):359-67.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex-dependent%20effects%20of%2017-beta-estradiol%20on%20chondrocyte%20differentiation%20in%20culture&as_occt=title&as_sauthors=%20%22E%20Nasatzky%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Schwartz Z</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Nasatzky E</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Ornoy A</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Brooks BP</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Soskolne WA</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Boyan BD</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Gender-specific,
                  maturation-dependent effects of testosterone on chondrocytes in culture</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Endocrinology.</span> 1994
                Apr;134(4):1640-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gender-specific%2C%20maturation-dependent%20effects%20of%20testosterone%20on%20chondrocytes%20in%20culture&as_occt=title&as_sauthors=%20%22Z%20Schwartz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Ciancia S</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Dubois V</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Cools M</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Impact of gender-affirming
                  treatment on bone health in transgender and gender diverse youth</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">Endocr Connect.</span> 2022 Sep
                28;11(11):e220280.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20gender-affirming%20treatment%20on%20bone%20health%20in%20transgender%20and%20gender%20diverse%20youth&as_occt=title&as_sauthors=%20%22S%20Ciancia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_164" class="annotation" style="position: unset;"> van der Loos MATC</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Vlot MC</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Klink DT</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Hannema SE</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> den Heijer M</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Wiepjes CM</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Bone Mineral Density in
                  Transgender Adolescents Treated With Puberty Suppression and Subsequent Gender-Affirming
                  Hormones</span>. <span data-id="emphasis_31" class="annotation emphasis" style="position: unset;">JAMA
                  Pediatr.</span> 2023 Dec 1;177(12):1332-41.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20Mineral%20Density%20in%20Transgender%20Adolescents%20Treated%20With%20Puberty%20Suppression%20and%20Subsequent%20Gender-Affirming%20Hormones&as_occt=title&as_sauthors=%20%22MATC%20van%20der%20Loos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Bassett AJ</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Ahlmen A</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Rosendorf JM</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Romeo AA</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Erickson BJ</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Bishop ME</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">The Biology of Sex and
                  Sport</span>. <span data-id="emphasis_32" class="annotation emphasis" style="position: unset;">JBJS
                  Rev.</span> 2020 Mar;8(3):e0140.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=2368294" target="_new" class="" style="position: unset;">JBJS Reviews</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Zech A</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Hollander K</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Junge A</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Steib S</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Groll A</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Heiner J</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Nowak F</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Pfeiffer D</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Rahlf AL</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Sex differences in injury rates
                  in team-sport athletes: A systematic review and meta-regression analysis</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">J Sport Health Sci.</span>
                2022 Jan;11(1):104-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20differences%20in%20injury%20rates%20in%20team-sport%20athletes%3A%20A%20systematic%20review%20and%20meta-regression%20analysis&as_occt=title&as_sauthors=%20%22A%20Zech%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Beynnon BD</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Vacek PM</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Newell MK</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Tourville TW</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Smith HC</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Shultz SJ</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Slauterbeck JR</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Johnson RJ</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">The Effects of Level of
                  Competition, Sport, and Sex on the Incidence of First-Time Noncontact Anterior Cruciate Ligament
                  Injury</span>. <span data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2014 Aug;42(8):1806-12.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Effects%20of%20Level%20of%20Competition%2C%20Sport%2C%20and%20Sex%20on%20the%20Incidence%20of%20First-Time%20Noncontact%20Anterior%20Cruciate%20Ligament%20Injury&as_occt=title&as_sauthors=%20%22BD%20Beynnon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Ellison TM</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Flagstaff I</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Johnson AE</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Sexual Dimorphisms in Anterior
                  Cruciate Ligament Injury: A Current Concepts Review</span>. <span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span> 2021 Dec
                17;9(12):23259671211025304.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sexual%20Dimorphisms%20in%20Anterior%20Cruciate%20Ligament%20Injury%3A%20A%20Current%20Concepts%20Review&as_occt=title&as_sauthors=%20%22TM%20Ellison%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Lee CA</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Lee-Barthel A</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Marquino L</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Sandoval N</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Marcotte GR</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Baar K</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Estrogen inhibits lysyl oxidase
                  and decreases mechanical function in engineered ligaments</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">J Appl Physiol(</span> (1985). 2015 May
                15;118(10):1250-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Estrogen%20inhibits%20lysyl%20oxidase%20and%20decreases%20mechanical%20function%20in%20engineered%20ligaments&as_occt=title&as_sauthors=%20%22CA%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Bruder AM</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Culvenor AG</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> King MG</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Haberfield M</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Roughead EA</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Mastwyk J</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Kemp JL</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Ferraz Pazzinatto M</span>,
                <span data-id="annotation_210" class="annotation" style="position: unset;"> West TJ</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Coburn SL</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Cowan SM</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Ezzat AM</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> To L</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Chilman K</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Couch JL</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Whittaker JL</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Crossley KM</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Let’s talk about sex (and
                  gender) after ACL injury: a systematic review and meta-analysis of self-reported activity and
                  knee-related outcomes</span>. <span data-id="emphasis_37" class="annotation emphasis"
                  style="position: unset;">Br J Sports Med.</span> 2023 May;57(10):602-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Let%E2%80%99s%20talk%20about%20sex%20(and%20gender)%20after%20ACL%20injury%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20self-reported%20activity%20and%20knee-related%20outcomes&as_occt=title&as_sauthors=%20%22AM%20Bruder%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Owen MM</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Gohal C</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Angileri HS</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Hartwell MJ</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Plantz MA</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Tjong VK</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Terry MA</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Sex-Based Differences in
                  Prevalence, Outcomes, and Complications of Hip Arthroscopy for Femoroacetabular Impingement: A
                  Systematic Review and Meta-analysis</span>. <span data-id="emphasis_38" class="annotation emphasis"
                  style="position: unset;">Orthop J Sports Med.</span> 2023 Aug 4;11(8):23259671231188332.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex-Based%20Differences%20in%20Prevalence%2C%20Outcomes%2C%20and%20Complications%20of%20Hip%20Arthroscopy%20for%20Femoroacetabular%20Impingement%3A%20A%20Systematic%20Review%20and%20Meta-analysis&as_occt=title&as_sauthors=%20%22MM%20Owen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Mondini Trissino da Lodi
                  C</span>, <span data-id="annotation_227" class="annotation" style="position: unset;"> Landini
                  MP</span>, <span data-id="annotation_228" class="annotation" style="position: unset;"> Asunis
                  E</span>, <span data-id="annotation_229" class="annotation" style="position: unset;"> Filardo
                  G</span>. <span data-id="strong_40" class="annotation strong" style="position: unset;">Women Have
                  Tendons… and Tendinopathy: Gender Bias is a “Gender Void” in Sports Medicine with a Lack of Women Data
                  on Patellar Tendinopathy-A Systematic Review</span>. <span data-id="emphasis_39"
                  class="annotation emphasis" style="position: unset;">Sports Med Open.</span> 2022 Jun
                7;8(1):74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Women%20Have%20Tendons%E2%80%A6%20and%20Tendinopathy%3A%20Gender%20Bias%20is%20a%20%E2%80%9CGender%20Void%E2%80%9D%20in%20Sports%20Medicine%20with%20a%20Lack%20of%20Women%20Data%20on%20Patellar%20Tendinopathy-A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22Trissino%20da%20Lodi%20C%20Mondini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Hilton EN</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Lundberg TR</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Transgender Women in the Female
                  Category of Sport: Perspectives on Testosterone Suppression and Performance Advantage</span>. <span
                  data-id="emphasis_40" class="annotation emphasis" style="position: unset;">Sports Med.</span> 2021
                Feb;51(2):199-214.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Transgender%20Women%20in%20the%20Female%20Category%20of%20Sport%3A%20Perspectives%20on%20Testosterone%20Suppression%20and%20Performance%20Advantage&as_occt=title&as_sauthors=%20%22EN%20Hilton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Lovalekar M</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Bird MB</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Koltun KJ</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Steele E</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Forse J</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Vera Cruz JL</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Bannister AF</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Burns I</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Mi Q</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Martin BJ</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Nindl BC</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Sex differences in
                  musculoskeletal injury epidemiology and subsequent loss of tactical readiness during Marine Corps
                  Officer Candidates School</span>. <span data-id="emphasis_41" class="annotation emphasis"
                  style="position: unset;">BMJ Mil Health.</span> 2023 Jun 19:e002392.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20differences%20in%20musculoskeletal%20injury%20epidemiology%20and%20subsequent%20loss%20of%20tactical%20readiness%20during%20Marine%20Corps%20Officer%20Candidates%20School&as_occt=title&as_sauthors=%20%22M%20Lovalekar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Barbeau P</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Michaud A</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Hamel C</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Rice D</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Skidmore B</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Hutton B</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Garritty C</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> da Silva DF</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Semeniuk K</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Adamo KB</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Musculoskeletal Injuries Among
                  Females in the Military: A Scoping Review</span>. <span data-id="emphasis_42"
                  class="annotation emphasis" style="position: unset;">Mil Med.</span> 2021 Aug
                28;186(9-10):e903-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Musculoskeletal%20Injuries%20Among%20Females%20in%20the%20Military%3A%20A%20Scoping%20Review&as_occt=title&as_sauthors=%20%22P%20Barbeau%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Rinonapoli G</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Ruggiero C</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Meccariello L</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Bisaccia M</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Ceccarini P</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Caraffa A</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Osteoporosis in Men: A Review
                  of an Underestimated Bone Condition</span>. <span data-id="emphasis_43" class="annotation emphasis"
                  style="position: unset;">Int J Mol Sci.</span> 2021 Feb 20;22(4):2105.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoporosis%20in%20Men%3A%20A%20Review%20of%20an%20Underestimated%20Bone%20Condition&as_occt=title&as_sauthors=%20%22G%20Rinonapoli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Mazeda C</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Barcelos A</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Gender differences in
                  osteoporosis</span>. <span data-id="emphasis_44" class="annotation emphasis"
                  style="position: unset;">Osteoporos Int.</span> 2023 Oct;34(10):1805-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gender%20differences%20in%20osteoporosis&as_occt=title&as_sauthors=%20%22C%20Mazeda%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Vescini F</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Chiodini I</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Falchetti A</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Palermo A</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Salcuni AS</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Bonadonna S</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> De Geronimo V</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Cesareo R</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Giovanelli L</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Brigo M</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Bertoldo F</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Scillitani A</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Gennari L</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Management of Osteoporosis in
                  Men: A Narrative Review</span>. <span data-id="emphasis_45" class="annotation emphasis"
                  style="position: unset;">Int J Mol Sci.</span> 2021 Dec 20;22(24):13640.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20Osteoporosis%20in%20Men%3A%20A%20Narrative%20Review&as_occt=title&as_sauthors=%20%22F%20Vescini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Conley RB</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Adib G</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Adler RA</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Åkesson KE</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Alexander IM</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Amenta KC</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Blank RD</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Brox WT</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Carmody EE</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Chapman-Novakofski K</span>,
                <span data-id="annotation_284" class="annotation" style="position: unset;"> Clarke BL</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Cody KM</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Cooper C</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Crandall CJ</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Dirschl DR</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Eagen TJ</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Elderkin AL</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Fujita M</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Greenspan SL</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Halbout P</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Hochberg MC</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Javaid M</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Jeray KJ</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Kearns AE</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> King T</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Koinis TF</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Koontz JS</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Kužma M</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Lindsey C</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Lorentzon M</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Lyritis GP</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Michaud LB</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Miciano A</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Morin SN</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Mujahid N</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Napoli N</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Olenginski TP</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Puzas JE</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Rizou S</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Rosen CJ</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Saag K</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Thompson E</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Tosi LL</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Tracer H</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Khosla S</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Kiel DP</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Secondary Fracture Prevention:
                  Consensus Clinical Recommendations from a Multistakeholder Coalition</span>. <span
                  data-id="emphasis_46" class="annotation emphasis" style="position: unset;">J Bone Miner Res.</span>
                2020 Jan;35(1):36-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Secondary%20Fracture%20Prevention%3A%20Consensus%20Clinical%20Recommendations%20from%20a%20Multistakeholder%20Coalition&as_occt=title&as_sauthors=%20%22RB%20Conley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Boyan BD</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Tosi LL</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Coutts RD</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Enoka RM</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Hart DA</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Nicolella DP</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Berkley KJ</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Sluka KA</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Kwoh CK</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> O’Connor MI</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Kohrt WM</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Resnick E</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Addressing the gaps: sex
                  differences in osteoarthritis of the knee</span>. <span data-id="emphasis_47"
                  class="annotation emphasis" style="position: unset;">Biol Sex Differ.</span> 2013 Feb
                4;4(1):4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Addressing%20the%20gaps%3A%20sex%20differences%20in%20osteoarthritis%20of%20the%20knee&as_occt=title&as_sauthors=%20%22BD%20Boyan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Hart DA</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Sex Differences in Biological
                  Systems and the Conundrum of Menopause: Potential Commonalities in Post-Menopausal Disease
                  Mechanisms</span>. <span data-id="emphasis_48" class="annotation emphasis"
                  style="position: unset;">Int J Mol Sci.</span> 2022 Apr 8;23(8):4119.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20Differences%20in%20Biological%20Systems%20and%20the%20Conundrum%20of%20Menopause%3A%20Potential%20Commonalities%20in%20Post-Menopausal%20Disease%20Mechanisms&as_occt=title&as_sauthors=%20%22DA%20Hart%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Sowers M</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Epidemiology of risk factors
                  for osteoarthritis: systemic factors</span>. <span data-id="emphasis_49" class="annotation emphasis"
                  style="position: unset;">Curr Opin Rheumatol.</span> 2001 Sep;13(5):447-51.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiology%20of%20risk%20factors%20for%20osteoarthritis%3A%20systemic%20factors&as_occt=title&as_sauthors=%20%22M%20Sowers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Maleki-Fischbach M</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Jordan JM</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">New developments in
                  osteoarthritis. Sex differences in magnetic resonance imaging-based biomarkers and in those of joint
                  metabolism</span>. <span data-id="emphasis_50" class="annotation emphasis"
                  style="position: unset;">Arthritis Res Ther.</span> 2010;12(4):212.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=New%20developments%20in%20osteoarthritis.%20Sex%20differences%20in%20magnetic%20resonance%20imaging-based%20biomarkers%20and%20in%20those%20of%20joint%20metabolism&as_occt=title&as_sauthors=%20%22M%20Maleki-Fischbach%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Srikanth VK</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Fryer JL</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Zhai G</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Winzenberg TM</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Hosmer D</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Jones G</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">A meta-analysis of sex
                  differences prevalence, incidence and severity of osteoarthritis</span>. <span data-id="emphasis_51"
                  class="annotation emphasis" style="position: unset;">Osteoarthritis Cartilage.</span> 2005
                Sep;13(9):769-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20meta-analysis%20of%20sex%20differences%20prevalence%2C%20incidence%20and%20severity%20of%20osteoarthritis&as_occt=title&as_sauthors=%20%22VK%20Srikanth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Szilagyi IA</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Waarsing JH</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> van Meurs JBJ</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Bierma-Zeinstra SMA</span>,
                <span data-id="annotation_346" class="annotation" style="position: unset;"> Schiphof D</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">A systematic review of the sex
                  differences in risk factors for knee osteoarthritis</span>. <span data-id="emphasis_52"
                  class="annotation emphasis" style="position: unset;">Rheumatology (Oxford).</span> 2023 Jun
                1;62(6):2037-47.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20review%20of%20the%20sex%20differences%20in%20risk%20factors%20for%20knee%20osteoarthritis&as_occt=title&as_sauthors=%20%22IA%20Szilagyi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Batushansky A</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Zhu S</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Komaravolu RK</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> South S</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Mehta-D’souza P</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Griffin TM</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">Fundamentals of OA. An
                  initiative of Osteoarthritis and Cartilage. Obesity and metabolic factors in OA</span>. <span
                  data-id="emphasis_53" class="annotation emphasis" style="position: unset;">Osteoarthritis
                  Cartilage.</span> 2022 Apr;30(4):501-15.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fundamentals%20of%20OA.%20An%20initiative%20of%20Osteoarthritis%20and%20Cartilage.%20Obesity%20and%20metabolic%20factors%20in%20OA&as_occt=title&as_sauthors=%20%22A%20Batushansky%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Hart DA</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Sex differences in
                  musculoskeletal injury and disease risks across the lifespan: Are there unique subsets of females at
                  higher risk than males for these conditions at distinct stages of the life cycle?</span><span
                  data-id="emphasis_54" class="annotation emphasis" style="position: unset;">Front Physiol.</span> 2023
                Apr 11;14:1127689.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20differences%20in%20musculoskeletal%20injury%20and%20disease%20risks%20across%20the%20lifespan%3A%20Are%20there%20unique%20subsets%20of%20females%20at%20higher%20risk%20than%20males%20for%20these%20conditions%20at%20distinct%20stages%20of%20the%20life%20cycle%3F&as_occt=title&as_sauthors=%20%22DA%20Hart%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Kluzek S</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Sanchez-Santos MT</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Leyland KM</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Spector TD</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Hart D</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Cooper C</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Newton J</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Arden NK</span>. <span
                  data-id="strong_56" class="annotation strong" style="position: unset;">Painful knee but not hand
                  osteoarthritis is an independent predictor of mortality over 23 years follow-up of a population-based
                  cohort of middle-aged women</span>. <span data-id="emphasis_55" class="annotation emphasis"
                  style="position: unset;">Ann Rheum Dis.</span> 2016 Oct;75(10):1749-56.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Painful%20knee%20but%20not%20hand%20osteoarthritis%20is%20an%20independent%20predictor%20of%20mortality%20over%2023%20years%20follow-up%20of%20a%20population-based%20cohort%20of%20middle-aged%20women&as_occt=title&as_sauthors=%20%22S%20Kluzek%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Li C</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Zheng Z</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Cartilage Targets of Knee
                  Osteoarthritis Shared by Both Genders</span>. <span data-id="emphasis_56" class="annotation emphasis"
                  style="position: unset;">Int J Mol Sci.</span> 2021 Jan 8;22(2):569.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cartilage%20Targets%20of%20Knee%20Osteoarthritis%20Shared%20by%20Both%20Genders&as_occt=title&as_sauthors=%20%22C%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Colbath A</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Haubruck P</span>. <span
                  data-id="strong_58" class="annotation strong" style="position: unset;">Closing the gap: sex-related
                  differences in osteoarthritis and the ongoing need for translational studies</span>. <span
                  data-id="emphasis_57" class="annotation emphasis" style="position: unset;">Ann Transl Med.</span> 2023
                Aug 30;11(10):339.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Closing%20the%20gap%3A%20sex-related%20differences%20in%20osteoarthritis%20and%20the%20ongoing%20need%20for%20translational%20studies&as_occt=title&as_sauthors=%20%22A%20Colbath%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_59" style="position: unset;">
            <div class="content" style="position: unset;">59&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Tschon M</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Contartese D</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Pagani S</span>, <span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Borsari V</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Fini M</span>. <span
                  data-id="strong_59" class="annotation strong" style="position: unset;">Gender and Sex Are Key
                  Determinants in Osteoarthritis Not Only Confounding Variables. A Systematic Review of Clinical
                  Data</span>. <span data-id="emphasis_58" class="annotation emphasis" style="position: unset;">J Clin
                  Med.</span> 2021 Jul 19;10(14):3178.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gender%20and%20Sex%20Are%20Key%20Determinants%20in%20Osteoarthritis%20Not%20Only%20Confounding%20Variables.%20A%20Systematic%20Review%20of%20Clinical%20Data&as_occt=title&as_sauthors=%20%22M%20Tschon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_60" style="position: unset;">
            <div class="content" style="position: unset;">60&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Mehta SP</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Perruccio AV</span>, <span
                  data-id="annotation_374" class="annotation" style="position: unset;"> Palaganas M</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Davis AM</span>. <span
                  data-id="strong_60" class="annotation strong" style="position: unset;">Do women have poorer outcomes
                  following total knee replacement?</span><span data-id="emphasis_59" class="annotation emphasis"
                  style="position: unset;">Osteoarthritis Cartilage.</span> 2015 Sep;23(9):1476-82.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Do%20women%20have%20poorer%20outcomes%20following%20total%20knee%20replacement%3F&as_occt=title&as_sauthors=%20%22SP%20Mehta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_61" style="position: unset;">
            <div class="content" style="position: unset;">61&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Tschon M</span>, <span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Contartese D</span>, <span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Pagani S</span>, <span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Borsari V</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Fini M</span>. <span
                  data-id="strong_61" class="annotation strong" style="position: unset;">Gender and Sex Are Key
                  Determinants in Osteoarthritis Not Only Confounding Variables. A Systematic Review of Clinical
                  Data</span>. <span data-id="emphasis_60" class="annotation emphasis" style="position: unset;">J Clin
                  Med.</span> 2021 Jul 19;10(14):3178.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gender%20and%20Sex%20Are%20Key%20Determinants%20in%20Osteoarthritis%20Not%20Only%20Confounding%20Variables.%20A%20Systematic%20Review%20of%20Clinical%20Data&as_occt=title&as_sauthors=%20%22M%20Tschon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_62" style="position: unset;">
            <div class="content" style="position: unset;">62&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Haubruck P</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Pinto MM</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Moradi B</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Little CB</span>, <span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Gentek R</span>. <span
                  data-id="strong_62" class="annotation strong" style="position: unset;">Monocytes, Macrophages, and
                  Their Potential Niches in Synovial Joints - Therapeutic Targets in Post-Traumatic
                  Osteoarthritis?</span><span data-id="emphasis_61" class="annotation emphasis"
                  style="position: unset;">Front Immunol.</span> 2021 Nov 4;12:763702.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Monocytes%2C%20Macrophages%2C%20and%20Their%20Potential%20Niches%20in%20Synovial%20Joints%20-%20Therapeutic%20Targets%20in%20Post-Traumatic%20Osteoarthritis%3F&as_occt=title&as_sauthors=%20%22P%20Haubruck%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_63" style="position: unset;">
            <div class="content" style="position: unset;">63&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Perruccio AV</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Badley EM</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Power JD</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Canizares M</span>, <span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Kapoor M</span>, <span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Rockel J</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Chandran V</span>, <span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Gandhi R</span>, <span
                  data-id="annotation_394" class="annotation" style="position: unset;"> Mahomed NM</span>, <span
                  data-id="annotation_395" class="annotation" style="position: unset;"> Davey JR</span>, <span
                  data-id="annotation_396" class="annotation" style="position: unset;"> Syed K</span>, <span
                  data-id="annotation_397" class="annotation" style="position: unset;"> Veillette C</span>, <span
                  data-id="annotation_398" class="annotation" style="position: unset;"> Rampersaud YR</span>. <span
                  data-id="strong_63" class="annotation strong" style="position: unset;">Sex differences in the
                  relationship between individual systemic markers of inflammation and pain in knee
                  osteoarthritis</span>. <span data-id="emphasis_62" class="annotation emphasis"
                  style="position: unset;">Osteoarthr Cartil Open.</span> 2019 Oct 24;1(1-2):100004.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20differences%20in%20the%20relationship%20between%20individual%20systemic%20markers%20of%20inflammation%20and%20pain%20in%20knee%20osteoarthritis&as_occt=title&as_sauthors=%20%22AV%20Perruccio%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_64" style="position: unset;">
            <div class="content" style="position: unset;">64&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_399" class="annotation" style="position: unset;"> Woolf SH</span>. <span
                  data-id="strong_64" class="annotation strong" style="position: unset;">Falling Behind: The Growing Gap
                  in Life Expectancy Between the United States and Other Countries, 1933-2021</span>. <span
                  data-id="emphasis_63" class="annotation emphasis" style="position: unset;">Am J Public Health.</span>
                2023 Sep;113(9):970-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Falling%20Behind%3A%20The%20Growing%20Gap%20in%20Life%20Expectancy%20Between%20the%20United%20States%20and%20Other%20Countries%2C%201933-2021&as_occt=title&as_sauthors=%20%22SH%20Woolf%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_65" style="position: unset;">
            <div class="content" style="position: unset;">65&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_400" class="annotation" style="position: unset;"> Xu J</span>, <span
                  data-id="annotation_401" class="annotation" style="position: unset;"> Murphy SL</span>, <span
                  data-id="annotation_402" class="annotation" style="position: unset;"> Kochanek KD</span>, <span
                  data-id="annotation_403" class="annotation" style="position: unset;"> Arias E</span>. <span
                  data-id="emphasis_64" class="annotation emphasis" style="position: unset;">Mortality in the United
                  States, 2021.</span> NCHS Data Brief. 2022 Dec;(456):1-8.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_66" style="position: unset;">
            <div class="content" style="position: unset;">66&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_404" class="annotation" style="position: unset;"> Park C</span>, <span
                  data-id="annotation_405" class="annotation" style="position: unset;"> Ko FC</span>. <span
                  data-id="strong_65" class="annotation strong" style="position: unset;">The Science of Frailty: Sex
                  Differences</span>. <span data-id="emphasis_65" class="annotation emphasis"
                  style="position: unset;">Clin Geriatr Med.</span> 2021 Nov;37(4):625-38.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Science%20of%20Frailty%3A%20Sex%20Differences&as_occt=title&as_sauthors=%20%22C%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_67" style="position: unset;">
            <div class="content" style="position: unset;">67&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_406" class="annotation" style="position: unset;"> Zeidan RS</span>, <span
                  data-id="annotation_407" class="annotation" style="position: unset;"> McElroy T</span>, <span
                  data-id="annotation_408" class="annotation" style="position: unset;"> Rathor L</span>, <span
                  data-id="annotation_409" class="annotation" style="position: unset;"> Martenson MS</span>, <span
                  data-id="annotation_410" class="annotation" style="position: unset;"> Lin Y</span>, <span
                  data-id="annotation_411" class="annotation" style="position: unset;"> Mankowski RT</span>. <span
                  data-id="strong_66" class="annotation strong" style="position: unset;">Sex differences in frailty
                  among older adults</span>. <span data-id="emphasis_66" class="annotation emphasis"
                  style="position: unset;">Exp Gerontol.</span> 2023 Dec;184:112333.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20differences%20in%20frailty%20among%20older%20adults&as_occt=title&as_sauthors=%20%22RS%20Zeidan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_68" style="position: unset;">
            <div class="content" style="position: unset;">68&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_412" class="annotation" style="position: unset;"> de Jong JCBC</span>, <span
                  data-id="annotation_413" class="annotation" style="position: unset;"> Attema BJ</span>, <span
                  data-id="annotation_414" class="annotation" style="position: unset;"> van der Hoek MD</span>, <span
                  data-id="annotation_415" class="annotation" style="position: unset;"> Verschuren L</span>, <span
                  data-id="annotation_416" class="annotation" style="position: unset;"> Caspers MPM</span>, <span
                  data-id="annotation_417" class="annotation" style="position: unset;"> Kleemann R</span>, <span
                  data-id="annotation_418" class="annotation" style="position: unset;"> van der Leij FR</span>, <span
                  data-id="annotation_419" class="annotation" style="position: unset;"> van den Hoek AM</span>, <span
                  data-id="annotation_420" class="annotation" style="position: unset;"> Nieuwenhuizen AG</span>, <span
                  data-id="annotation_421" class="annotation" style="position: unset;"> Keijer J</span>. <span
                  data-id="strong_67" class="annotation strong" style="position: unset;">Sex differences in skeletal
                  muscle-aging trajectory: same processes, but with a different ranking</span>. <span
                  data-id="emphasis_67" class="annotation emphasis" style="position: unset;">Geroscience.</span> 2023
                Aug;45(4):2367-86.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20differences%20in%20skeletal%20muscle-aging%20trajectory%3A%20same%20processes%2C%20but%20with%20a%20different%20ranking&as_occt=title&as_sauthors=%20%22JCBC%20de%20Jong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_69" style="position: unset;">
            <div class="content" style="position: unset;">69&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_422" class="annotation" style="position: unset;"> Knufinke M</span>, <span
                  data-id="annotation_423" class="annotation" style="position: unset;"> MacArthur MR</span>, <span
                  data-id="annotation_424" class="annotation" style="position: unset;"> Ewald CY</span>, <span
                  data-id="annotation_425" class="annotation" style="position: unset;"> Mitchell SJ</span>. <span
                  data-id="strong_68" class="annotation strong" style="position: unset;">Sex differences in
                  pharmacological interventions and their effects on lifespan and healthspan outcomes: a systematic
                  review</span>. <span data-id="emphasis_68" class="annotation emphasis" style="position: unset;">Front
                  Aging.</span> 2023 May 22;4:1172789.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20differences%20in%20pharmacological%20interventions%20and%20their%20effects%20on%20lifespan%20and%20healthspan%20outcomes%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22M%20Knufinke%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_70" style="position: unset;">
            <div class="content" style="position: unset;">70&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_426" class="annotation" style="position: unset;"> Ortona E</span>, <span
                  data-id="annotation_427" class="annotation" style="position: unset;"> Pierdominici M</span>, <span
                  data-id="annotation_428" class="annotation" style="position: unset;"> Maselli A</span>, <span
                  data-id="annotation_429" class="annotation" style="position: unset;"> Veroni C</span>, <span
                  data-id="annotation_430" class="annotation" style="position: unset;"> Aloisi F</span>, <span
                  data-id="annotation_431" class="annotation" style="position: unset;"> Shoenfeld Y</span>. <span
                  data-id="strong_69" class="annotation strong" style="position: unset;">Sex-based differences in
                  autoimmune diseases</span>. <span data-id="emphasis_69" class="annotation emphasis"
                  style="position: unset;">Ann Ist Super Sanita.</span> 2016 Apr-Jun;52(2):205-12.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex-based%20differences%20in%20autoimmune%20diseases&as_occt=title&as_sauthors=%20%22E%20Ortona%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_71" style="position: unset;">
            <div class="content" style="position: unset;">71&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_432" class="annotation" style="position: unset;"> Wilkinson NM</span>, <span
                  data-id="annotation_433" class="annotation" style="position: unset;"> Chen HC</span>, <span
                  data-id="annotation_434" class="annotation" style="position: unset;"> Lechner MG</span>, <span
                  data-id="annotation_435" class="annotation" style="position: unset;"> Su MA</span>. <span
                  data-id="strong_70" class="annotation strong" style="position: unset;">Sex Differences in
                  Immunity</span>. <span data-id="emphasis_70" class="annotation emphasis" style="position: unset;">Annu
                  Rev Immunol.</span> 2022 Apr 26;40:75-94.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20Differences%20in%20Immunity&as_occt=title&as_sauthors=%20%22NM%20Wilkinson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_72" style="position: unset;">
            <div class="content" style="position: unset;">72&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_436" class="annotation" style="position: unset;"> Klein SL</span>, <span
                  data-id="annotation_437" class="annotation" style="position: unset;"> Flanagan KL</span>. <span
                  data-id="strong_71" class="annotation strong" style="position: unset;">Sex differences in immune
                  responses</span>. <span data-id="emphasis_71" class="annotation emphasis" style="position: unset;">Nat
                  Rev Immunol.</span> 2016 Oct;16(10):626-38.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20differences%20in%20immune%20responses&as_occt=title&as_sauthors=%20%22SL%20Klein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_73" style="position: unset;">
            <div class="content" style="position: unset;">73&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_438" class="annotation" style="position: unset;"> Dou DR</span>, <span
                  data-id="annotation_439" class="annotation" style="position: unset;"> Zhao Y</span>, <span
                  data-id="annotation_440" class="annotation" style="position: unset;"> Belk JA</span>, <span
                  data-id="annotation_441" class="annotation" style="position: unset;"> Zhao Y</span>, <span
                  data-id="annotation_442" class="annotation" style="position: unset;"> Casey KM</span>, <span
                  data-id="annotation_443" class="annotation" style="position: unset;"> Chen DC</span>, <span
                  data-id="annotation_444" class="annotation" style="position: unset;"> Li R</span>, <span
                  data-id="annotation_445" class="annotation" style="position: unset;"> Yu B</span>, <span
                  data-id="annotation_446" class="annotation" style="position: unset;"> Srinivasan S</span>, <span
                  data-id="annotation_447" class="annotation" style="position: unset;"> Abe BT</span>, <span
                  data-id="annotation_448" class="annotation" style="position: unset;"> Kraft K</span>, <span
                  data-id="annotation_449" class="annotation" style="position: unset;"> Hellström C</span>, <span
                  data-id="annotation_450" class="annotation" style="position: unset;"> Sjöberg R</span>, <span
                  data-id="annotation_451" class="annotation" style="position: unset;"> Chang S</span>, <span
                  data-id="annotation_452" class="annotation" style="position: unset;"> Feng A</span>, <span
                  data-id="annotation_453" class="annotation" style="position: unset;"> Goldman DW</span>, <span
                  data-id="annotation_454" class="annotation" style="position: unset;"> Shah AA</span>, <span
                  data-id="annotation_455" class="annotation" style="position: unset;"> Petri M</span>, <span
                  data-id="annotation_456" class="annotation" style="position: unset;"> Chung LS</span>, <span
                  data-id="annotation_457" class="annotation" style="position: unset;"> Fiorentino DF</span>, <span
                  data-id="annotation_458" class="annotation" style="position: unset;"> Lundberg EK</span>, <span
                  data-id="annotation_459" class="annotation" style="position: unset;"> Wutz A</span>, <span
                  data-id="annotation_460" class="annotation" style="position: unset;"> Utz PJ</span>, <span
                  data-id="annotation_461" class="annotation" style="position: unset;"> Chang HY</span>. <span
                  data-id="strong_72" class="annotation strong" style="position: unset;">Xist ribonucleoproteins promote
                  female sex-biased autoimmunity</span>. <span data-id="emphasis_72" class="annotation emphasis"
                  style="position: unset;">Cell.</span> 2024 Feb 1;187(3):733-749.e16.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Xist%20ribonucleoproteins%20promote%20female%20sex-biased%20autoimmunity&as_occt=title&as_sauthors=%20%22DR%20Dou%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_74" style="position: unset;">
            <div class="content" style="position: unset;">74&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_462" class="annotation" style="position: unset;"> Griesbeck M</span>, <span
                  data-id="annotation_463" class="annotation" style="position: unset;"> Ziegler S</span>, <span
                  data-id="annotation_464" class="annotation" style="position: unset;"> Laffont S</span>, <span
                  data-id="annotation_465" class="annotation" style="position: unset;"> Smith N</span>, <span
                  data-id="annotation_466" class="annotation" style="position: unset;"> Chauveau L</span>, <span
                  data-id="annotation_467" class="annotation" style="position: unset;"> Tomezsko P</span>, <span
                  data-id="annotation_468" class="annotation" style="position: unset;"> Sharei A</span>, <span
                  data-id="annotation_469" class="annotation" style="position: unset;"> Kourjian G</span>, <span
                  data-id="annotation_470" class="annotation" style="position: unset;"> Porichis F</span>, <span
                  data-id="annotation_471" class="annotation" style="position: unset;"> Hart M</span>, <span
                  data-id="annotation_472" class="annotation" style="position: unset;"> Palmer CD</span>, <span
                  data-id="annotation_473" class="annotation" style="position: unset;"> Sirignano M</span>, <span
                  data-id="annotation_474" class="annotation" style="position: unset;"> Beisel C</span>, <span
                  data-id="annotation_475" class="annotation" style="position: unset;"> Hildebrandt H</span>, <span
                  data-id="annotation_476" class="annotation" style="position: unset;"> Cénac C</span>, <span
                  data-id="annotation_477" class="annotation" style="position: unset;"> Villani AC</span>, <span
                  data-id="annotation_478" class="annotation" style="position: unset;"> Diefenbach TJ</span>, <span
                  data-id="annotation_479" class="annotation" style="position: unset;"> Le Gall S</span>, <span
                  data-id="annotation_480" class="annotation" style="position: unset;"> Schwartz O</span>, <span
                  data-id="annotation_481" class="annotation" style="position: unset;"> Herbeuval JP</span>, <span
                  data-id="annotation_482" class="annotation" style="position: unset;"> Autran B</span>, <span
                  data-id="annotation_483" class="annotation" style="position: unset;"> Guéry JC</span>, <span
                  data-id="annotation_484" class="annotation" style="position: unset;"> Chang JJ</span>, <span
                  data-id="annotation_485" class="annotation" style="position: unset;"> Altfeld M</span>. <span
                  data-id="strong_73" class="annotation strong" style="position: unset;">Sex differences in plasmacytoid
                  dendritic cell levels of IRF5 drive higher IFN-α production in women</span>. <span
                  data-id="emphasis_73" class="annotation emphasis" style="position: unset;">J Immunol.</span> 2015 Dec
                1;195(11):5327-36.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20differences%20in%20plasmacytoid%20dendritic%20cell%20levels%20of%20IRF5%20drive%20higher%20IFN-%CE%B1%20production%20in%20women&as_occt=title&as_sauthors=%20%22M%20Griesbeck%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_75" style="position: unset;">
            <div class="content" style="position: unset;">75&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_486" class="annotation" style="position: unset;"> Tawfik VL</span>, <span
                  data-id="annotation_487" class="annotation" style="position: unset;"> Huck NA</span>, <span
                  data-id="annotation_488" class="annotation" style="position: unset;"> Baca QJ</span>, <span
                  data-id="annotation_489" class="annotation" style="position: unset;"> Ganio EA</span>, <span
                  data-id="annotation_490" class="annotation" style="position: unset;"> Haight ES</span>, <span
                  data-id="annotation_491" class="annotation" style="position: unset;"> Culos A</span>, <span
                  data-id="annotation_492" class="annotation" style="position: unset;"> Ghaemi S</span>, <span
                  data-id="annotation_493" class="annotation" style="position: unset;"> Phongpreecha T</span>, <span
                  data-id="annotation_494" class="annotation" style="position: unset;"> Angst MS</span>, <span
                  data-id="annotation_495" class="annotation" style="position: unset;"> Clark JD</span>, <span
                  data-id="annotation_496" class="annotation" style="position: unset;"> Aghaeepour N</span>, <span
                  data-id="annotation_497" class="annotation" style="position: unset;"> Gaudilliere B</span>. <span
                  data-id="strong_74" class="annotation strong" style="position: unset;">Systematic Immunophenotyping
                  Reveals Sex-Specific Responses After Painful Injury in Mice</span>. <span data-id="emphasis_74"
                  class="annotation emphasis" style="position: unset;">Front Immunol.</span> 2020 Jul
                29;11:1652.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Systematic%20Immunophenotyping%20Reveals%20Sex-Specific%20Responses%20After%20Painful%20Injury%20in%20Mice&as_occt=title&as_sauthors=%20%22VL%20Tawfik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_76" style="position: unset;">
            <div class="content" style="position: unset;">76&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_498" class="annotation" style="position: unset;"> Caicedo MS</span>, <span
                  data-id="annotation_499" class="annotation" style="position: unset;"> Solver E</span>, <span
                  data-id="annotation_500" class="annotation" style="position: unset;"> Coleman L</span>, <span
                  data-id="annotation_501" class="annotation" style="position: unset;"> Jacobs JJ</span>, <span
                  data-id="annotation_502" class="annotation" style="position: unset;"> Hallab NJ</span>. <span
                  data-id="strong_75" class="annotation strong" style="position: unset;">Females with Unexplained Joint
                  Pain Following Total Joint Arthroplasty Exhibit a Higher Rate and Severity of Hypersensitivity to
                  Implant Metals Compared with Males: Implications of Sex-Based Bioreactivity Differences</span>. <span
                  data-id="emphasis_75" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2017 Apr 19;99(8):621-8.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1275517" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_77" style="position: unset;">
            <div class="content" style="position: unset;">77&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_503" class="annotation" style="position: unset;"> Latteier MJ</span>, <span
                  data-id="annotation_504" class="annotation" style="position: unset;"> Berend KR</span>, <span
                  data-id="annotation_505" class="annotation" style="position: unset;"> Lombardi AV Jr</span>, <span
                  data-id="annotation_506" class="annotation" style="position: unset;"> Ajluni AF</span>, <span
                  data-id="annotation_507" class="annotation" style="position: unset;"> Seng BE</span>, <span
                  data-id="annotation_508" class="annotation" style="position: unset;"> Adams JB</span>. <span
                  data-id="strong_76" class="annotation strong" style="position: unset;">Gender is a significant factor
                  for failure of metal-on-metal total hip arthroplasty</span>. <span data-id="emphasis_76"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2011
                Sep;26(6)(Suppl):19-23.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gender%20is%20a%20significant%20factor%20for%20failure%20of%20metal-on-metal%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22MJ%20Latteier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D24986d95-79bf-46da-9d3c-e23c3a134551%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Symposium Series</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): 1512</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00194</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 2, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_77"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Division
                            of Pediatric Orthopaedics and Sports Medicine, Children’s National Hospital, Washington, DC,
                            the University of Kansas Medical Center, Kansas City, Kansas, and the Institute for
                            Engineering and Medicine, College of Engineering, Virginia Commonwealth University,
                            Richmond, Virginia</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;24986d95-79bf-46da-9d3c-e23c3a134551&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=24986d95-79bf-46da-9d3c-e23c3a134551&type=pdf&name=JBJS.24.00194.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=24986d95-79bf-46da-9d3c-e23c3a134551&type=pdf&name=JBJS.24.00194.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_4" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_77"
                            class="annotation strong" style="position: unset;">Disclosure:</span> Research reported in
                          this publication was supported by the National Institute of Arthritis and Musculoskeletal and
                          Skin Diseases of the National Institutes of Health under Award Numbers R13AR082710, 1R01
                          AR072500, and K12AR084233; the Joan and Morgan Massey Foundation; and the Peter and Carrie
                          Ullrich Foundation. The content is solely the responsibility of the authors and does not
                          necessarily represent the official views of the National Institutes of Health. The <span
                            data-id="strong_78" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I78" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I78</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;24986d95-79bf-46da-9d3c-e23c3a134551&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=24986d95-79bf-46da-9d3c-e23c3a134551&type=zip&name=JBJS.24.00194.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Laura L. Tosi, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:LTOSI@childrensnational.org" class=""
                    style="position: unset;">LTOSI@childrensnational.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8827-9883" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8827-9883</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kimberly Templeton, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7805-2658" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7805-2658</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Andrew M. Pennington, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-7579-5344" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-7579-5344</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kendall A. Reid, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2105-6471" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2105-6471</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Barbara D. Boyan, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9642-0311" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9642-0311</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Pediatric Orthopaedics and Sports Medicine, Children’s National
                Hospital, Washington, DC</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">University of Kansas Medical Center, Kansas City, Kansas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Institute for Engineering and Medicine, College of Engineering, Virginia
                Commonwealth University, Richmond, Virginia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 39453.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
