import jbjsExports from "@configs/awsexports/jbjs.json";
import psiExports from "@configs/awsexports/psi.json";
import simwerxExports from "@configs/awsexports/simwerx.json";
import therapyedExports from "@configs/awsexports/therapyed.json";
import thiemeneetpg from "@configs/awsexports/thiemeneetpg.json";
import { buildType } from "@helpers/expo-extras";
import { Apps } from "types/apps";

export const getAwsExportsJson = () => {
	switch (buildType) {
		case Apps.TherapyEd:
			return therapyedExports;
		case Apps.Psi:
			return psiExports;
		case Apps.thiemeneetpg:
			return thiemeneetpg;
		case Apps.Simwerx:
			return simwerxExports;
		case Apps.Jbjs:
			return jbjsExports;
		default:
			return therapyedExports;
	}
};
