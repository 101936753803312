import { DateTime } from "luxon";
/* -----------------Globals--------------- */
import React, {
	createContext,
	useState,
	useEffect,
	useCallback,
	type ReactNode,
	useMemo,
} from "react";

/* -----------------Child components--------------- */
import { trackCustomEvent, updateUserProperties } from "analytics";

/* -----------------Constants--------------- */
import { events } from "@constants/tracking";

import { isDemoMode } from "@constants/common";
import { useAppStore } from "@hooks/useAppStore";
import { useAuthContext } from "@memorang/applets";
import {
	constructClientExamContext,
	retrieveExamContextFromKV,
	storeExamContextInKV,
} from "../helpers";
import { useExams } from "../hooks/useExams";
/* -----------------Local modules--------------- */
import updateExamMeta from "../mutations/UpdateExamMetadata";
import { fetchExamDate } from "../queries/FetchExamDate";
import { fetchExams } from "../queries/FetchExams";
import type { ExamDetails } from "../types";
import { defaultExamContext } from "./constants";

export type CurrentExamContext = {
	studyPackProductId: string;
	examName: string;
	examId: string;
	examDate?: string;
	examIcon?: string;
	studyPackProductNumericId: number;
	practiceTestProductId?: string;
	practiceTestProductNumericId?: number;
	hasRedeemed?: boolean;
	hasStudyPackAccess?: boolean;
	hasPracticeTestAccess?: boolean;
};

type ContextValue = {
	currentExamContext: CurrentExamContext;
	updateCurrentExamContext: (examContext: CurrentExamContext) => void;
	updateExamDate: (examDate: Date) => void;
	handleSwitchExamContext: (examDetails: ExamDetails) => void;
};

const initExamContext = {
	studyPackProductId: "",
	examName: "",
	examId: "",
	studyPackProductNumericId: 0,
};

const Context = createContext<ContextValue>({
	currentExamContext: initExamContext,
	updateCurrentExamContext: () => {
		//
	},
	updateExamDate: () => {
		//
	},
	handleSwitchExamContext: async () => {
		//
	},
});

const ExamContextProvider = ({ children }: { children: ReactNode }) => {
	const [currentExamContext, setCurrentExamContext] =
		useState<CurrentExamContext>(initExamContext);

	// const {
	// 	setCurrentlySelectedBundleDetails,
	// 	setCurrentlySelectedBundleDetailsForIAP,
	// 	setCurrentlySelectedBundleDetailsForPracticeTests,
	// } = useBundleStore();

	const { viewerId: userId } = useAuthContext();

	const {
		app: { id: appId },
		tenant: { id: tenantId },
	} = useAppStore((store) => ({
		app: store.app,
		tenant: store.tenant,
	}));
	const { exams } = useExams();

	const checkAndAddPracticeTest = useCallback(
		(data: CurrentExamContext) => {
			if (!data.practiceTestProductId) {
				const currentExam = exams.find((exam) => exam.exam.id === data.examId);
				const practiceTest = currentExam?.exam.bundles.find(
					(item) => item.bundle.bundleType === "PRACTICE_TEST",
				)?.bundle;
				return {
					...data,
					practiceTestProductId: practiceTest?.id,
					practiceTestProductNumericId: practiceTest?.numericId,
				};
			}
			return data;
		},
		[exams],
	);
	const checkAndStudyPack = useCallback(
		(data: CurrentExamContext) => {
			if (!data.studyPackProductId && data.examId) {
				const currentExam = exams.find((exam) => exam.exam.id === data.examId);
				const studyPack = currentExam?.exam.bundles.find(
					(item) => item.bundle.bundleType === "STUDY_PACK",
				)?.bundle;
				return {
					...data,
					studyPackProductId: studyPack?.id!,
					studyPackProductNumericId: studyPack?.numericId!,
				};
			}
			return data;
		},
		[exams],
	);

	const updateCurrentExamContext = useCallback(
		(data: CurrentExamContext) => {
			const finalExamContext = checkAndAddPracticeTest(data);
			const finalExamContextWithStudyPack = checkAndStudyPack(finalExamContext);
			setCurrentExamContext(finalExamContextWithStudyPack);
			if (userId) {
				storeExamContextInKV(finalExamContextWithStudyPack, userId);
			}
			trackCustomEvent({
				eventName: events.examContextSwitched,
				examName: data.examName,
				examId: data.examId,
			});
			updateUserProperties({
				examName: data.examName,
				examId: data.examId,
				examDate: data.examDate!,
			});
		},
		[checkAndAddPracticeTest, userId, checkAndStudyPack],
	);

	useEffect(() => {
		if (
			currentExamContext?.examId &&
			!currentExamContext.studyPackProductId &&
			!isDemoMode
		) {
			updateCurrentExamContext(currentExamContext);
		}
	}, [currentExamContext, updateCurrentExamContext]);

	const syncExamContext = useCallback(async () => {
		if (userId) {
			if (isDemoMode) {
				setCurrentExamContext(defaultExamContext);
				return;
			}
			const cachedExamContext = await retrieveExamContextFromKV(userId);
			if (cachedExamContext) {
				setCurrentExamContext(cachedExamContext);
				const examId = cachedExamContext.examId;
				const examDetails = await fetchExamDate({
					examId,
					tenantId,
				});
				const examDate = examDetails.metadata.examDate;
				const newExamContext = {
					...cachedExamContext,
					examDate,
				} as CurrentExamContext;
				setCurrentExamContext(newExamContext);
			} else {
				const latestExams = await fetchExams(tenantId, appId);
				const purchasedProductForExam = latestExams.find((item) => {
					return (
						item.exam.bundles.find(
							(bundle) => bundle.metadata?.subscription?.status === "ACTIVE",
						) != null
					);
				});
				if (purchasedProductForExam) {
					const examId = purchasedProductForExam.exam.id;
					const practiceTest = purchasedProductForExam.exam.bundles.find(
						(item) => item.bundle.bundleType === "PRACTICE_TEST",
					)?.bundle;
					const studyPack = purchasedProductForExam.exam.bundles.find(
						(item) => item.bundle.bundleType === "STUDY_PACK",
					)?.bundle;
					const studyPackProductId = studyPack?.id!;
					const studyPackProductNumericId = studyPack?.numericId!;
					const examName = purchasedProductForExam.exam.name;
					const examIcon = purchasedProductForExam.exam.icon;
					const examDate = purchasedProductForExam.metadata.examDate;
					const practiceTestProductId = practiceTest?.id;
					const practiceTestProductNumericId = practiceTest?.numericId;
					const newExamContext = {
						studyPackProductId,
						examName,
						examId,
						examDate,
						examIcon,
						studyPackProductNumericId,
						practiceTestProductId,
						practiceTestProductNumericId,
					};
					storeExamContextInKV(newExamContext, userId);
					setCurrentExamContext(newExamContext);
				} else {
					// no purchase, defaults
					setCurrentExamContext(defaultExamContext);
					storeExamContextInKV(defaultExamContext, userId);
				}
			}
		}
	}, [appId, tenantId, userId]);
	useEffect(() => {
		syncExamContext();
	}, [syncExamContext]);

	const updateExamDate = useCallback(
		(examDate: Date) => {
			if (examDate) {
				const formattedDate = DateTime.fromJSDate(examDate, {
					zone: "local",
				}).toFormat("MMMM dd, yyyy");
				updateExamMeta({
					id: currentExamContext.examId,
					examDate: formattedDate,
				});
				updateCurrentExamContext({
					...currentExamContext,
					examDate: formattedDate,
				});
			}
		},
		[currentExamContext, updateCurrentExamContext],
	);

	const handleSwitchExamContext = useCallback(
		(examDetails: ExamDetails) => {
			const constructedExamContext = constructClientExamContext(examDetails);

			updateCurrentExamContext(constructedExamContext);
		},
		[updateCurrentExamContext],
	);

	const value = useMemo(() => {
		return {
			currentExamContext,
			updateCurrentExamContext,
			updateExamDate,
			handleSwitchExamContext,
		};
	}, [
		currentExamContext,
		handleSwitchExamContext,
		updateCurrentExamContext,
		updateExamDate,
	]);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useExamContext = () => {
	const context = React.useContext(Context);
	if (context === undefined) {
		throw new Error("useExamContext must be used within a ExamContextProvider");
	}
	return context;
};

export { ExamContextProvider, useExamContext };
