export const CHALLENGE_IMAGE_WIDTH = 92;
export const CHALLENGE_IMAGE_HEIGHT = 56;

export const CHALLENGE_INSTRUCTIONS = {
	title: "How to complete image challenges",
	body: `
- Look at the image and read the question carefully
- Select your answer from the multiple choices provided
- Click your choice to check if it's correct
- If incorrect, you'll receive a helpful hint
- Keep trying different options until you find the right answer
- Learn and improve with each attempt!`,
	confirmCta: "Got it!",
};
