/* -----------------UI--------------- */
import { Button, Caption, Text } from "react-native-paper";

/* -----------------Hooks--------------- */
import { ChooseDateSvg } from "@memorang/media";

import { useExamContext } from "@features/exam";
import { isWeb } from "@helpers/platform";
import { useAppStore } from "@hooks/useAppStore";
import { useDevice } from "@hooks/useDevice";
/* -----------------Components--------------- */
import { Box } from "@memorang/ui";
import { useRouter } from "expo-router";
import { useState } from "react";
import OnboardingAppBar from "./OnboardingAppBar";
import FreeQuizSessionDialog from "./dialogs/FreeQuizSessionDialog";

interface Props {
	selectedExamDate?: string;
	toggleDatePicker: () => void;
}
const ChooseDateView = ({ selectedExamDate, toggleDatePicker }: Props) => {
	const { isMobile } = useDevice();

	const router = useRouter();

	const quizEnabled = useAppStore((store) => store.exam.quizEnabled);

	const [showFreeQuizDialog, setShowFreeQuizDialog] = useState(false);

	const handleCloseFreeQuizDialog = (navigate?: boolean) => {
		setShowFreeQuizDialog(false);
		if (navigate) {
			router.replace("/(protected)/(tabs)/home");
		}
	};

	const handleOpenFreeQuizDialog = () => {
		setShowFreeQuizDialog(true);
	};

	const {
		currentExamContext: { examName },
	} = useExamContext();

	const SVG = ChooseDateSvg;

	const title = "When do you plan on taking your exam:";

	const dateLabel = selectedExamDate || "Choose date";
	const caption = "You can change this later";

	const RenderTitle = () => (
		<Box gap={8} alignItems="center">
			<Text
				style={{
					textAlign: "center",
				}}
				variant="titleLarge"
			>
				{title}
			</Text>
			<Text
				style={{
					fontWeight: "bold",
					fontStyle: "italic",
				}}
				variant="titleLarge"
			>
				{examName}
			</Text>
		</Box>
	);

	const SelectDateButton = () => (
		<Button
			mode="contained"
			icon="calendar-outline"
			style={{
				marginTop: 16,
			}}
			onPress={toggleDatePicker}
		>
			{dateLabel}
		</Button>
	);

	const handleContinue = () => {
		if (isWeb && quizEnabled) {
			handleOpenFreeQuizDialog();
		} else {
			router.push("/(protected)/(tabs)/home");
		}
	};
	return (
		<>
			<OnboardingAppBar
				title="Choose exam date"
				onBackAction={() => {
					router.setParams({
						step: "chooseexam",
					});
				}}
				elevated
				showProfileMenu={!isMobile}
			/>
			<Box
				flex={1}
				display="flex"
				justifyContent="center"
				alignItems="center"
				paddingHorizontal={16}
				gap={16}
			>
				<SVG
					height={180}
					width="100%"
					style={{
						resizeMode: "contain",
						backgroundColor: "transparent",
					}}
				/>
				<RenderTitle />
				<SelectDateButton />
				<Button onPress={handleContinue}>Continue</Button>
				<Caption>{caption}</Caption>
			</Box>
			{showFreeQuizDialog && (
				<FreeQuizSessionDialog
					visible={showFreeQuizDialog}
					onClose={handleCloseFreeQuizDialog}
				/>
			)}
		</>
	);
};

export default ChooseDateView;
