import { create } from "zustand";

type State = {
	role?: string;
	speciality?: string;
	subRole?: string;
	subSpecialities?: string[];
	setRole: (role: string) => void;
	setSubRole: (subRole: string) => void;
	setSubSpeciality: (subSpecialty: string) => void;
	setSpeciality: (specialty: string) => void;
};

export const useOnboardingStore = create<State>((set) => ({
	subRole: undefined,
	subSpecialities: undefined,
	setSubRole: (subRole) => set({ subRole }),
	setSpeciality: (specialty) => set({ speciality: specialty }),
	setRole: (role) => set({ role }),
	setSubSpeciality: (specialty: string) =>
		set((state) => ({
			subSpecialities: state.subSpecialities
				? state.subSpecialities.includes(specialty)
					? state.subSpecialities.filter((s) => s !== specialty)
					: [...new Set([...state.subSpecialities, specialty])]
				: [specialty],
		})),
}));
