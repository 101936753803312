/* ----------------- Hooks --------------- */
import { useArticles } from "../hooks/useArticles";

import { Box, EmptyState } from "@memorang/ui";
/* ----------------- Components --------------- */
import SavedArticlesView from "../components/SavedArticlesView";
const SavedArticlesContainer = ({
	isBrowseTab,
	searchQuery,
}: {
	isBrowseTab?: boolean;
	searchQuery?: string;
}) => {
	const {
		selectedArticleIds,
		handleSelectArticle,
		handleNavigateToArticle,
		savedArticles,
	} = useArticles();

	const finalSavedArticles =
		!isBrowseTab && searchQuery
			? savedArticles.filter((article) =>
					article.title.toLowerCase().includes(searchQuery.toLowerCase()),
				)
			: savedArticles;

	if (!finalSavedArticles.length) {
		return (
			<Box flexGrow={1} height="100%">
				<EmptyState
					title="No results found"
					subtitle="Try changing the search query"
				/>
			</Box>
		);
	}

	return (
		<SavedArticlesView
			savedArticles={finalSavedArticles}
			handleSelectArticle={handleSelectArticle}
			selectedArticleIds={selectedArticleIds}
			handleNavigateToArticle={handleNavigateToArticle}
		/>
	);
};

export default SavedArticlesContainer;
