/* -----------------UI--------------- */
import { Stack, Tooltip, Typography } from "@mui/material/";
import { Card, DataTable, Text } from "react-native-paper";

/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppTheme } from "@hooks/useAppTheme";
import { Box, DonutScore } from "@memorang/ui";

type Column = {
	title: string;
	key: string;
	tooltip?: string;
};

type Row = {
	accuracy: {
		score: number;
	};
	title: string;
	correct: number;
	incorrect: number;
	items: number;
	exam?: number | null;
	opportunity?: number;
};
type Props = {
	columns: Column[];
	rows: Row[];
	ascendingMap: Record<string, boolean>;
	updateRows: (key: string) => void;
	footerData: Record<string, React.ReactNode>;
};
export default function DetailedListTopicsTable({
	columns,
	rows,
	ascendingMap,
	updateRows,
	footerData,
}: Props) {
	const theme = useAppTheme();

	const handleClickColumn = (key: string) => {
		updateRows(key);
	};
	const columnMaxWidths = {
		"accuracy.score": 96,
		accuracy: 96,
		title: "100%",
		correct: 120,
		incorrect: 120,
		items: 120,
		exam: 120,
		opportunity: 120,
	} as const;

	const getSortDirection = (key: string) => {
		if (ascendingMap[key] == null) {
			return undefined;
		}
		return ascendingMap[key] ? "ascending" : "descending";
	};
	const Table = () => (
		<DataTable>
			<DataTable.Header>
				{columns.map(({ key, title, tooltip }) => (
					<DataTable.Title
						key={key}
						sortDirection={getSortDirection(key)}
						style={{
							cursor: "pointer",
							maxWidth: columnMaxWidths[key as keyof typeof columnMaxWidths],
						}}
						onPress={() => {
							handleClickColumn(key);
						}}
					>
						<Tooltip title={tooltip} placement="top">
							<Typography variant="caption">{title}</Typography>
						</Tooltip>
					</DataTable.Title>
				))}
			</DataTable.Header>

			{rows.map((row) => {
				const {
					accuracy,
					title,
					correct,
					incorrect,
					items,
					exam: examPercentage,
					opportunity = 0,
				} = row;

				return (
					<DataTable.Row key={title}>
						<DataTable.Cell style={{ paddingVertical: 8, maxWidth: 96 }}>
							<DonutScore
								distribution={[accuracy.score, 100 - accuracy.score]}
								score={accuracy.score}
								innerRadius={19}
								radius={24}
							/>
						</DataTable.Cell>
						<DataTable.Cell style={{ maxWidth: "100%" }}>
							{title}
						</DataTable.Cell>
						<DataTable.Cell style={{ maxWidth: 120 }}>{correct}</DataTable.Cell>
						<DataTable.Cell style={{ maxWidth: 120 }}>
							{incorrect}
						</DataTable.Cell>
						<DataTable.Cell style={{ maxWidth: 120 }}>{items}</DataTable.Cell>
						{examPercentage != null && (
							<>
								<DataTable.Cell style={{ maxWidth: 120 }}>{`${
									examPercentage ? `${examPercentage}%` : "-"
								}`}</DataTable.Cell>
								<DataTable.Cell style={{ maxWidth: 120 }}>
									<Stack direction="row" alignItems="center" gap={1}>
										<Text
											style={{
												...theme.typography.fonts.bold,
												color:
													opportunity > 0
														? theme.colors.success.main
														: theme.palette.error.main,
												alignItems: "center",
												display: "flex",
												flexDirection: "row",
											}}
										>
											<Icon
												name={opportunity > 0 ? "menu-up" : "menu-down"}
												color={
													opportunity > 0
														? theme.colors.success.main
														: theme.palette.error.main
												}
												size={20}
											/>
											{`${opportunity}%`}
										</Text>
									</Stack>
								</DataTable.Cell>
							</>
						)}
					</DataTable.Row>
				);
			})}
			<DataTable.Header>
				{Object.keys(footerData).map((key) => (
					<DataTable.Title
						key={key}
						style={{
							cursor: "pointer",
							maxWidth: columnMaxWidths[key as keyof typeof columnMaxWidths],
						}}
						onPress={() => {
							handleClickColumn(key);
						}}
					>
						{footerData[key]}
					</DataTable.Title>
				))}
			</DataTable.Header>
		</DataTable>
	);
	return (
		<Box paddingBottom={16}>
			<Card>
				<Table />
			</Card>
		</Box>
	);
}
