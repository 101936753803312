/* -----------------Globals--------------- */
import { Alert } from "react-native";

/* -----------------Type declarations--------------- */
type AlertConfig = {
	title: string;
	message: string;
	positiveButtonText?: string;
	negativeButtonText?: string;
	onButtonPressed?: (type: string) => void;
	cancelable?: boolean;
};

/* -----------------Component--------------- */
const showAlert = (config: AlertConfig) => {
	Alert.alert(
		config.title,
		config.message,
		[
			...(config.negativeButtonText
				? [
						{
							text: config.negativeButtonText,
							onPress: () => {
								if (config.onButtonPressed) {
									config.onButtonPressed("cancel");
								}
							},
							style: "cancel" as const,
						},
					]
				: []),
			...(config.positiveButtonText
				? [
						{
							text: config.positiveButtonText,
							onPress: () => {
								if (config.onButtonPressed) {
									config.onButtonPressed("ok");
								}
							},
						},
					]
				: []),
		],
		{ cancelable: config.cancelable },
	);
};

export default showAlert;
