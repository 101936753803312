/* -----------------Globals--------------- */
import { Stack, useLocalSearchParams, useRouter } from "expo-router";

import { pageViewTypes } from "@constants/tracking";
import {
	FeedbackForm,
	type FeedbackType,
	defaultFeedbackSubtitle,
	dialogTexts,
} from "@features/feedback";
import useAnalytics from "@hooks/useAnalytics";
/* -----------------Child components--------------- */
import { Box } from "@memorang/ui";
import ModalHeader from "components/ModalHeader";

const FeedbackRoute = () => {
	const { view, type } = useLocalSearchParams<{
		view?: string;
		type?: string;
	}>();
	const router = useRouter();
	const config = dialogTexts.feedback;

	useAnalytics({
		type: pageViewTypes.feedback,
	});

	const handleDismissFeedback = () => {
		router.back();
	};
	return (
		<>
			<Stack.Screen
				options={{
					presentation: "modal",
					headerShown: true,
					header: () => (
						<ModalHeader backIcon="arrow-left" headerTitle={config.title} />
					),
				}}
			/>
			<Box padding={16}>
				<FeedbackForm
					view={view || "profile"}
					handleDismiss={handleDismissFeedback}
					feedbackSubtitle={defaultFeedbackSubtitle}
					type={type as FeedbackType}
				/>
			</Box>
		</>
	);
};

export default FeedbackRoute;
