/* ----------------- Third Party Imports --------------- */
import { Chip } from "react-native-paper";

/* ----------------- Local Imports --------------- */
import { useAppTheme } from "../../useAppTheme";

interface Props {
	free?: boolean;
}
const FreemiumChip = ({ free }: Props) => {
	const theme = useAppTheme();
	const text = free ? "Free" : "Premium";
	const background = free
		? theme.colors.freeChip.background
		: theme.colors.premiumChip.background;
	const color = free
		? theme.colors.freeChip.color
		: theme.colors.premiumChip.color;
	return (
		<Chip
			compact
			textStyle={{
				color: color,
				fontSize: 12,
			}}
			style={{
				backgroundColor: background,
				marginRight: 16,
			}}
		>
			{text}
		</Chip>
	);
};

export default FreemiumChip;
