/* -----------------Globals--------------- */
import { StyleSheet } from "react-native";

/* -----------------UI--------------- */
import { Avatar } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { type AppTheme, useAppTheme } from "@hooks/useAppTheme";

/* -----------------Child components--------------- */
import ProductIcon from "components/ProductIcon";

const getStyles = (theme: AppTheme) =>
	StyleSheet.create({
		avatar: {
			backgroundColor: theme.colors.primaryContainer,
			alignSelf: "center",
		},
	});
type Props = {
	icon: string;
};
const CardHeaderAvatar = ({ icon }: Props) => {
	const theme = useAppTheme();
	const styles = getStyles(theme);
	const isHttps = icon?.startsWith("https");
	if (isHttps) {
		return <ProductIcon image={icon} size={44} />;
	}
	return (
		<Avatar.Icon
			style={styles.avatar}
			size={40}
			icon={icon}
			color={theme.colors.onSurfaceVariant}
		/>
	);
};

export default CardHeaderAvatar;
