/* ----------------- Local Imports --------------- */
import { useAppTheme } from "../../useAppTheme";
import type { PlanProps } from "./types";

/* ----------------- Third Party Imports --------------- */
import { Card, Text } from "react-native-paper";
import { RadioButton } from "react-native-paper";

const PlanListItem = ({
	id,
	duration,
	price,
	expiry,
	selected,
	disabled,
	offer,
	handleSelectPlan,
}: PlanProps) => {
	const theme = useAppTheme();

	const cardSubtitle = (
		<Text numberOfLines={1}>
			<Text
				variant="titleSmall"
				style={{
					fontWeight: "700",
				}}
			>
				{price}
			</Text>{" "}
			<Text
				variant="titleSmall"
				style={{
					fontWeight: "700",
				}}
			>
				•
			</Text>{" "}
			<Text
				variant="titleSmall"
				style={{
					fontWeight: "400",
					fontStyle: "italic",
				}}
			>
				{expiry}
			</Text>
		</Text>
	);

	return (
		<Card
			mode="outlined"
			disabled={disabled || selected}
			onPress={() => handleSelectPlan(id)}
			style={{
				backgroundColor: selected
					? theme.colors.secondaryContainer
					: theme.colors.surface,
				width: "100%",
			}}
		>
			<Card.Title
				title={duration}
				titleVariant="titleMedium"
				titleNumberOfLines={1}
				subtitle={cardSubtitle}
				left={(props) => (
					<Card.Actions {...props} style={{ padding: 0 }}>
						<RadioButton.Android
							onPress={() => handleSelectPlan(id)}
							value={duration}
							status={selected ? "checked" : "unchecked"}
						/>
					</Card.Actions>
				)}
				right={(props) => (
					<Text
						{...props}
						numberOfLines={1}
						variant="labelLarge"
						style={{
							paddingRight: 16,
						}}
					>
						{offer}
					</Text>
				)}
			/>
		</Card>
	);
};

export default PlanListItem;
