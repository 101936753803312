/* -----------------Types--------------- */
import type { Confidence } from "@memorang/types";

import { Container } from "@memorang/ui";
import { Button } from "react-native-paper";
/* -----------------Child components--------------- */
import BottomNavigationSession from "./BottomNavigationSession";
import ConfidenceSubmitButtons from "./ConfidenceSubmitButtons";
import NavButtons, { type NextButtonProps } from "./NavButtons";

interface Props extends NextButtonProps {
	showConfidenceSubmitButtons?: boolean;
	onConfidenceProvided: (confidence: Confidence) => void;
	progressText?: string;
	isSummativeTest?: boolean;
	toggleHelpDialog: () => void;
	inReviewMode?: boolean;
	isSequence?: boolean;
	answered?: boolean;
	isImageChallengeApp?: boolean;
	selected?: boolean;
	loading?: boolean;
}
const StudySessionBottomAppBar = ({
	handleNext,
	handlePrevious,
	showConfidenceSubmitButtons,
	onConfidenceProvided,
	progressText,
	isSummativeTest,
	toggleHelpDialog,
	inReviewMode,
	isSequence,
	answered,
	isImageChallengeApp,
	selected,
	loading,
	...rest
}: Props) => {
	return (
		<BottomNavigationSession isSummativeTest={isSummativeTest}>
			<Container fullWidth={isSummativeTest} maxWidth={"sm"}>
				{showConfidenceSubmitButtons && !isSequence ? (
					<ConfidenceSubmitButtons
						onConfidenceProvided={onConfidenceProvided}
					/>
				) : !answered && isSequence ? (
					<Button onPress={() => onConfidenceProvided(50)} mode="contained">
						Submit
					</Button>
				) : (
					<NavButtons
						loading={loading}
						showButtonNavNext
						showButtonPrevious
						handleNext={handleNext}
						handlePrevious={handlePrevious}
						progressText={progressText}
						isSummativeTest={isSummativeTest}
						inReviewMode={inReviewMode}
						toggleHelpDialog={toggleHelpDialog}
						answered={answered}
						isImageChallengeApp={isImageChallengeApp}
						selected={selected}
						{...rest}
					/>
				)}
			</Container>
		</BottomNavigationSession>
	);
};

export default StudySessionBottomAppBar;
