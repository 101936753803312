/* -----------------Globals--------------- */
import type { FlashList } from "@shopify/flash-list";
import { type RefObject, useCallback, useEffect, useState } from "react";

import type {
	Confidence,
	SessionChildItem,
	SessionItem,
} from "@memorang/types";
import { QuestionVariant } from "@memorang/types";
import { useAilaStore } from "../../hooks/useAilaStore";
/* -----------------Hooks--------------- */
import useSessionStore from "../../hooks/useSessionStore";

import { useAuth } from "@clerk/clerk-expo";
import { newEvents } from "@constants/tracking";
import { useDailyProgressStore } from "@features/dashboard";
import useItems from "@features/dashboard/hooks/useItems";
import { useExamContext } from "@features/exam";
import { isWeb } from "@helpers/platform";
import useAnalytics from "@hooks/useAnalytics";
import { useAppStore } from "@hooks/useAppStore";
import useClerkUser from "@hooks/useClerkUser";
import { useGlobalStore } from "@hooks/useGlobalStore";
import { useAuthContext } from "@memorang/applets";
import { Box } from "@memorang/ui";
import { ConfirmDialog } from "components/ConfirmDialog";
import { router, useLocalSearchParams } from "expo-router";
import ConfettiCannon from "react-native-confetti-cannon";
import DialogSession from "../../components/SessionDialog";
/* -----------------Components--------------- */
import StudySessionBottomAppBar from "../../components/StudySessionBottomAppBar";
import ConfidencebarOnboardingDialog from "../../components/dialogs/ConfidencebarOnboardingDialog";
import { type DialogType, helpDialog } from "../../constants/dialog-configs";
import { useSession } from "../../hooks/useSession";
import { recordSummativeTestEvents } from "../../relay/RecordSummativeTestEvents";
import {
	calculateAndUpdateProgress,
	getNextButtonStates,
	getShouldShowConfidencesSubmitButtons,
	getShowButtonNavFinish,
	handlePostAnswer,
	handleSendSummativeAnswerEvent,
} from "./helpers";

interface Props {
	flashlistRef?: RefObject<FlashList<SessionItem>>;
	handleScrollInitial?: () => void;
}
const StudySessionBottombarContainer = ({
	flashlistRef,
	handleScrollInitial,
}: Props) => {
	const state = useSessionStore((state) => state);

	const { index } = useLocalSearchParams<{
		index: string;
	}>();

	const currentItemIndex = index ? Number.parseInt(index) - 1 : 0;

	const { filteredItems } = useItems();

	const [tempSelectedConfidence, setTempSelectedConfidence] =
		useState<Confidence | null>(null);
	const isImageChallengeApp = useAppStore(
		(state) => state.app.isImageChallengeApp,
	);

	const { viewerId } = useAuthContext();
	const {
		updateCurrentItemIndex,
		sessionItems,
		id,
		inReviewMode,
		isSummativeTest,
		currentBlockIndex,
		totalTime,
		blockStates,
		timeElapsed,
		sessionTime,
		updateProgress,
		numGoal,
		updateCurrentBlockIndex,
		numericId,
		reportSessionType,
		attempts,
		confettiCannons,
		updateShowConfetti,
	} = state;

	const { handleEndSession, handleCreateFormativeSession } = useSession();

	const [startTime, setStartTime] = useState<Date>(new Date());

	const [helpDialogVisible, setHelpDialogVisible] = useState(false);

	const dailyProgressStore = useDailyProgressStore();

	const [showSessionDialogData, setShowSessionDialogData] = useState<{
		show: boolean;
		type?: DialogType;
	}>({
		show: false,
	});

	const toggleHelpDialog = () => setHelpDialogVisible((prev) => !prev);

	const {
		currentExamContext: { examName, examId },
	} = useExamContext();

	const { userPublicMetadataId } = useClerkUser();

	const { explicitCallTrackCustomEvent } = useAnalytics();

	const updateShowDrawer = useAilaStore((state) => state.updateShowDrawer);

	const { getToken } = useAuth();
	const tenantId = useAppStore((store) => store.tenant.id);

	const currentBlock = sessionItems[currentBlockIndex];
	const numSections = sessionItems.length;
	const currentSessionItems = isSummativeTest
		? currentBlock?.children
		: sessionItems;
	const currentItem = currentSessionItems[currentItemIndex] as SessionChildItem;

	const singleItemSession = sessionItems.length === 1;

	const currentItemId = currentItem?.id;

	const currentAttempt = attempts.get(currentItemId);

	const currentSelectedChoiceId = currentAttempt?.currentSelectedChoiceId;

	const currentBlockState = currentBlock
		? blockStates.get(currentBlock.id)
		: undefined;
	const currentBlockProgress = currentBlockState?.currentProgress || 0;
	const markedItems = currentBlockState?.markedItemsForReview;
	const highlightedItems = currentBlockState?.highlightedItems;
	const isMarked = currentItem?.marked || markedItems?.includes(currentItemId);
	const highlightedHtml =
		currentItem?.highlight || highlightedItems?.get(currentItemId);

	const handleUpdateItemStatePeriodically = useCallback(
		(time: number) => {
			const itemState = {
				sessionId: id,
				sessionTime: time,
				itemId: currentItemId,
				userId: viewerId!,
				variant: QuestionVariant.mcq,
				progress: currentBlockProgress,
				marked: isMarked,
				highlight: highlightedHtml,
			};
			recordSummativeTestEvents(itemState);
		},
		[
			currentBlockProgress,
			currentItemId,
			highlightedHtml,
			id,
			isMarked,
			viewerId,
		],
	);

	useEffect(() => {
		if (
			timeElapsed &&
			timeElapsed % 300 === 0 &&
			!inReviewMode &&
			isSummativeTest
		) {
			handleUpdateItemStatePeriodically(timeElapsed + (sessionTime || 0));
		}
	}, [
		handleUpdateItemStatePeriodically,
		inReviewMode,
		isSummativeTest,
		sessionTime,
		timeElapsed,
	]);

	const isDiagnosticTest = reportSessionType === "DIAGNOSTIC";

	const confirmDialogConfig = helpDialog({
		sessionItems: currentSessionItems as SessionItem[],
		numSections,
		examName,
		totalTime,
		isDiagnosticTest,
		numTotalQuestions: sessionItems?.reduce(
			(acc, item) => acc + item.children.length,
			0,
		),
	});

	const hasShownConfidenceOnboardingDialog = useGlobalStore(
		(state) => state.hasShownConfidenceOnboardingDialog,
	);

	const setHasShownConfidenceOnboardingDialog = useGlobalStore(
		(state) => state.setHasShownConfidenceOnboardingDialog,
	);
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		setStartTime(new Date());
	}, [currentItemId]);

	const showConfidenceSubmitButtons =
		getShouldShowConfidencesSubmitButtons(state);

	// next button states
	const {
		text: nextButtonText,
		disabled: disableNextButton,
		showButtonNavSkip,
		answered,
	} = getNextButtonStates(state, currentItemIndex);
	const showButtonNavFinish = getShowButtonNavFinish(state, currentItemIndex);

	const sessionLength = isSummativeTest
		? sessionItems[currentBlockIndex].children.length
		: sessionItems.length;
	const isLastItem = currentItemIndex === sessionLength - 1;

	const prevButtonText = "Prev";

	const showPreviousButton = currentItemIndex > 0;

	const isLastBlock = isSummativeTest
		? currentBlockIndex === sessionItems?.length - 1
		: false;

	const isLastItemInTheBlock =
		currentItemIndex === currentBlock?.children?.length - 1;

	useEffect(() => {
		if (currentItemIndex === 0) {
			const initProgress = 1 / numGoal!;
			updateProgress(initProgress);
		}
	}, [currentItemIndex, numGoal, updateProgress]);
	const handleUpdateAnswers = async (confidence: Confidence) => {
		const token = await getToken({
			template: "jwt-with-user",
		});
		if (!token) {
			throw new Error("No token");
		}
		handlePostAnswer(
			state,
			startTime,
			confidence,
			dailyProgressStore,
			examId,
			userPublicMetadataId,
			token,
			tenantId,
		);
	};

	const handleTrackConfidenceClick = (confidence: Confidence) => {
		explicitCallTrackCustomEvent({
			eventName: newEvents.sessionItemConfidenceClicked,
			confidence,
			itemId: currentItemId,
			sessionId: id,
		});
	};

	const onConfidenceProvided = (confidence: Confidence) => {
		handleTrackConfidenceClick(confidence);

		if (hasShownConfidenceOnboardingDialog) {
			handleUpdateAnswers(confidence);
		} else {
			setTempSelectedConfidence(confidence);
		}
	};

	const handlePrevious = () => {
		explicitCallTrackCustomEvent({
			eventName: newEvents.sessionItemNavigated,
			sessionId: id,
			itemId: currentItemId,
			type: "previous",
		});
		router.setParams({
			index: String(currentItemIndex),
		});
		updateCurrentItemIndex(currentItemIndex - 1);
		calculateAndUpdateProgress(state, false);
	};

	useEffect(() => {
		if (!isWeb) {
			flashlistRef?.current?.scrollToIndex({
				index: currentItemIndex,
				animated: true,
			});
		}
	}, [currentItemIndex, flashlistRef]);

	const handleNext = () => {
		const eventParams = {
			index: currentItemIndex,
			itemId: currentItemId,
			sessionId: id,
			inReviewMode: String(inReviewMode),
		};
		handleScrollInitial?.();
		if (isLastItemInTheBlock && !inReviewMode && isSummativeTest) {
			updateShowDrawer(false);
			explicitCallTrackCustomEvent({
				eventName: newEvents.sessionFinishNavClicked,
				...eventParams,
			});
			const type = isLastBlock ? "completeTest" : "completeSection";
			if (isSummativeTest && !inReviewMode) {
				handleSendSummativeAnswerEvent(
					state,
					currentItemIndex,
					viewerId!,
					startTime,
				);
			}
			setShowSessionDialogData({
				show: true,
				type,
			});
			return;
		}
		if (showButtonNavFinish || isLastItem) {
			updateShowDrawer(false);
			explicitCallTrackCustomEvent({
				eventName: newEvents.sessionFinishNavClicked,
				...eventParams,
			});
			if (inReviewMode) {
				router.back();
			} else {
				if (singleItemSession && isImageChallengeApp) {
					router.replace("/home");
					return;
				}
				handleEndSession({
					id: id,
					sessionType: "FORMATIVE",
				});
				router.replace({
					pathname: "/(protected)/session/[id]/report",
					params: {
						id: id,
					},
				});
			}
			return;
		}

		const newIndex = currentItemIndex + 1;

		router.setParams({
			index: String(newIndex + 1),
		});
		explicitCallTrackCustomEvent({
			eventName: newEvents.sessionItemNavigated,
			...eventParams,
			type: "next",
		});
		updateCurrentItemIndex(newIndex);
		if (isSummativeTest && !inReviewMode) {
			handleSendSummativeAnswerEvent(
				state,
				currentItemIndex,
				viewerId!,
				startTime,
			);
		}
		calculateAndUpdateProgress(state, true);
	};

	const progressText = isSummativeTest
		? ""
		: `ITEM ${currentItemIndex + 1} / ${sessionItems.length}`;

	const handleCloseDialog = () => {
		setShowSessionDialogData({
			show: false,
		});
	};
	const moveToNextBlock = () => {
		if (isLastBlock) {
			handleEndSession({
				id: id,
				sessionType: "SUMMATIVE",
			});
			router.replace({
				pathname: "/(protected)/session/[id]/report",
				params: {
					id: numericId,
				},
			});
		} else {
			router.setParams({
				index: "1",
			});
			updateCurrentItemIndex(1);
			updateCurrentBlockIndex(currentBlockIndex + 1);
		}
	};
	const handleClickActionButton = (type: string) => {
		if (type === "cancel") {
			handleCloseDialog();
		} else {
			handleCloseDialog();
			moveToNextBlock();
		}
	};
	const showConfidenceOnboardingDialog = tempSelectedConfidence != null;

	const showConfetti = confettiCannons.get(currentItemId);

	const handleRandomChallenge = () => {
		explicitCallTrackCustomEvent({
			eventName: newEvents.sessionRandomChallengeClicked,
			sessionId: id,
			itemId: currentItemId,
		});

		const randomIndex = Math.floor(Math.random() * filteredItems.length);
		const challengeItem = filteredItems[randomIndex];
		handleCreateFormativeSession({
			challengeItem,
			replace: true,
		});
	};

	return (
		<Box maxWidth={"100%"} alignSelf="center" width="100%">
			<StudySessionBottomAppBar
				handleNext={handleNext}
				loading={!numericId && !currentItem.completedAt != null}
				disableNextButton={disableNextButton}
				nextButtonText={nextButtonText}
				handlePrevious={handlePrevious}
				isSummativeTest={isSummativeTest}
				showButtonNavSkip={showButtonNavSkip}
				showButtonPrevious={showPreviousButton}
				prevButtonText={prevButtonText}
				showButtonNavFinish={showButtonNavFinish}
				onConfidenceProvided={onConfidenceProvided}
				showConfidenceSubmitButtons={showConfidenceSubmitButtons}
				progressText={progressText}
				answered={answered}
				showRandomChallenge={
					singleItemSession && isImageChallengeApp && answered
				}
				handleRandomChallenge={handleRandomChallenge}
				handleSubmitAnswer={() => {
					handleUpdateAnswers(50);
				}}
				isSequence={currentItem?.variant === QuestionVariant.sequence}
				toggleHelpDialog={toggleHelpDialog}
				isImageChallengeApp={isImageChallengeApp}
				selected={currentSelectedChoiceId != null}
			/>
			{helpDialogVisible && (
				<ConfirmDialog
					visible={helpDialogVisible}
					title={confirmDialogConfig.title}
					icon={confirmDialogConfig.icon}
					body={confirmDialogConfig.body}
					dismissCallback={() => setHelpDialogVisible(false)}
					confirmCallback={() => setHelpDialogVisible(false)}
					confirmCta="Close"
				/>
			)}
			{showSessionDialogData.show && (
				<DialogSession
					type={showSessionDialogData.type}
					handleClickButton={handleClickActionButton}
					handleClose={handleCloseDialog}
					open={showSessionDialogData.show}
				/>
			)}
			{showConfidenceOnboardingDialog && (
				<ConfidencebarOnboardingDialog
					open={showConfidenceOnboardingDialog}
					handleClose={() => {
						if (tempSelectedConfidence) {
							handleUpdateAnswers(tempSelectedConfidence);
						}
						setTempSelectedConfidence(null);
						setHasShownConfidenceOnboardingDialog(true);
					}}
				/>
			)}
			{showConfetti && (
				<ConfettiCannon
					onAnimationEnd={() => {
						updateShowConfetti({ itemId: currentItemId, showConfetti: false });
					}}
					count={200}
					origin={{ x: -10, y: 0 }}
					fallSpeed={1500}
				/>
			)}
		</Box>
	);
};

export default StudySessionBottombarContainer;
