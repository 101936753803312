/* ----------------- React --------------- */
import { useState } from "react";

/* ----------------- React Native --------------- */
import { SectionList } from "react-native";

import type { QuickActionsChipType } from "components/QuickActionChips/types";
/* ----------------- Types --------------- */
import type { HeaderSectionData, HomeSection, SectionData } from "../../types/";

import { useExamContext } from "@features/exam";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
/* ----------------- Hooks --------------- */
import { useDevice } from "@hooks/useDevice";

/* ----------------- UI Components --------------- */
import { Box, ScrollContainer } from "@memorang/ui";
import { ExamsFilterDialog } from "components/ExamsFilterDialog";

import ExamInfoSection from "./ExamInfoSection";
import ProductsSection from "./ProductsSection";
/* ----------------- Child Components --------------- */
import { SectionHeader } from "./SectionHeader";
import TasksSection from "./TasksSection";
import TopicsSection from "./TopicsSection";

/* ----------------- Constants and Helpers --------------- */
import { getShowStudyPackViews } from "@helpers/exams";
import { PurchaseDialog } from "../PurchaseDialog";

type Props = {
	sections: HomeSection[];
	headerData?: HeaderSectionData;
	quickActionsChips?: QuickActionsChipType[];
	handleQuickActionPress: (action: QuickActionsChipType["type"]) => void;
	handleSelectTopic: (topicId: string) => void;
	handleShowPurchase: () => void;
	studyPackSubtitle?: string;
	showPurchaseDialog?: boolean;
	toggleShowPurchaseDialog?: () => void;
};

const renderSectionHeader = ({
	section: { title },
}: {
	section: HomeSection;
	handleExamsFilterNavigation: () => void;
}) => {
	return <SectionHeader sectionTitle={title} />;
};

export const HomeView = ({
	sections,
	headerData,
	handleSelectTopic,
	handleShowPurchase,
	studyPackSubtitle,
	showPurchaseDialog,
	toggleShowPurchaseDialog,
}: Props) => {
	const { isMobile } = useDevice();

	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();

	const showStudyPackViews = getShowStudyPackViews(studyPackProductId);
	const [showExamsFilterDialog, setShowExamsFilterDialog] = useState(false);

	const toggleShowExamsFilterDialog = () => {
		setShowExamsFilterDialog((prev) => !prev);
	};

	const { handleExamsFilterNavigation } = useCustomNavigation();

	const renderItem = ({
		item,
		section,
	}: {
		item: SectionData;
		section: {
			onPress?: () => void;
			loading?: boolean;
		};
	}) => {
		const { type, items } = item;

		if (type === "tasks") {
			return <TasksSection items={items} loading={section.loading} />;
		}
		if (type === "progress") {
			return (
				<TopicsSection
					items={items}
					showStudyPackViews={showStudyPackViews}
					handleSelectTopic={handleSelectTopic}
					handleShowPurchase={handleShowPurchase}
					studyPackSubtitle={studyPackSubtitle}
					handleNavigateToDetailView={section.onPress}
					loading={section.loading}
				/>
			);
		}

		if (type === "exam") {
			return <ProductsSection items={items} loading={section.loading} />;
		}
		return null;
	};

	const handleClickChangeExam = () => {
		handleExamsFilterNavigation(toggleShowExamsFilterDialog);
	};

	return (
		<ScrollContainer
			contentContainerStyle={{
				flexGrow: 1,
				paddingBottom: 84,
				paddingTop: isMobile ? 0 : 24,
				paddingHorizontal: isMobile ? 0 : 16,
			}}
		>
			<>
				{sections.length ? (
					<SectionList
						sections={sections}
						showsVerticalScrollIndicator={false}
						ListHeaderComponent={() => {
							return <ExamInfoSection headerData={headerData} />;
						}}
						SectionSeparatorComponent={() => <Box height={8} />}
						renderItem={({ item, section }) => {
							return renderItem({ item, section });
						}}
						renderSectionHeader={({ section }) => {
							return renderSectionHeader({
								section,
								handleExamsFilterNavigation: handleClickChangeExam,
							});
						}}
					/>
				) : null}
			</>

			{showExamsFilterDialog && (
				<ExamsFilterDialog
					visible={showExamsFilterDialog}
					handleClose={toggleShowExamsFilterDialog}
				/>
			)}
			{showPurchaseDialog && (
				<PurchaseDialog
					visible={showPurchaseDialog}
					handleClose={toggleShowPurchaseDialog!}
					productType="PRACTICE_TEST"
					fromHomePage
				/>
			)}
		</ScrollContainer>
	);
};
