import { zustandStorage } from "@helpers/storage";
import { create } from "zustand";
import { persist } from "zustand/middleware";

/* -----------------Types--------------- */
type State = {
	savedArticles: number[];
	addToSavedArticles: (itemId: number) => void;
	removeFromSavedArticles: (itemId: number) => void;
};

/* -----------------Store--------------- */
export const useArticlesStore = create<State>()(
	persist(
		(set) => ({
			savedArticles: [],
			addToSavedArticles: (itemId: number) =>
				set((state) => ({
					savedArticles: [...state.savedArticles, itemId],
				})),
			removeFromSavedArticles: (itemId: number) =>
				set((state) => ({
					savedArticles: state.savedArticles.filter(
						(article) => article !== itemId,
					),
				})),
		}),
		{
			name: "articles-store",
			getStorage: () => zustandStorage,
		},
	),
);
