/* ----------------- Types Imports --------------- */
import type { Article } from "../types";

import { JBJS_FULL_ARTICLE_URL } from "@constants/urls";
import { useAilaStore } from "@features/session";
import { useAppTheme } from "@hooks/useAppTheme";
import { composeMessage } from "@memorang/helpers";
/* ----------------- UI Imports --------------- */
import { focusManager, useQuery } from "@tanstack/react-query";
import AssistantFab from "components/AssistantFab";
import { useRouter } from "expo-router";
import * as WebBrowser from "expo-web-browser";
import { useCallback, useEffect } from "react";
import { AppState, PixelRatio, Platform } from "react-native";
import ArticleRenderer from "./Article";

type Props = {
	article: Article;
};

const DetailArticleContentView = ({ article }: Props) => {
	const router = useRouter();
	const theme = useAppTheme();
	const updateUserPrompt = useAilaStore((state) => state.updateUserPrompt);
	const updateItemId = useAilaStore((state) => state.updateItemId);
	const updateIsItemAnswered = useAilaStore(
		(state) => state.updateIsItemAnswered,
	);
	const updateOriginalQuestionData = useAilaStore(
		(state) => state.updateOriginalQuestionData,
	);

	const { data: fontScale } = useQuery({
		queryKey: ["fontScale"],
		initialData: PixelRatio.getFontScale(),
		queryFn: async () => {
			return await Promise.resolve(PixelRatio.getFontScale());
		},
		staleTime: 0,
		refetchOnWindowFocus: true,
		refetchOnMount: true,
	});

	// Refetch font scale when app state changes
	useEffect(() => {
		const subscription = AppState.addEventListener("change", (status) => {
			if (Platform.OS !== "web") {
				focusManager.setFocused(status === "active");
			}
		});

		return () => subscription.remove();
	}, []);

	const handleNavigateToAssistant = () => {
		updateUserPrompt(
			`Article title: ${article.title}\n Article type: ${article.type}`,
		);
		updateItemId(JSON.stringify([article.id]));
		updateIsItemAnswered(true);
		updateOriginalQuestionData(undefined);
		router.push({
			pathname: "/aila/[itemId]",
			params: {
				itemId: article.id,
			},
		});
	};
	const handleOpenLink = useCallback((url: string) => {
		WebBrowser.openBrowserAsync(url);
	}, []);

	const handleOpenEmail = useCallback((emailLink: string) => {
		const email = emailLink.split("mailto:")[1];
		composeMessage("", "", email);
	}, []);

	return (
		<>
			<ArticleRenderer
				articleHtml={article.content}
				openExternalLink={handleOpenLink}
				openEmail={handleOpenEmail}
				primaryColor={theme.colors.primary}
				fullArticleUrl={`${JBJS_FULL_ARTICLE_URL}${article.rsuiteId}`}
				showOnlyAbstract={
					article?.showOnlyAbstract ?? article.type === "scientific article"
				}
				fontScale={fontScale}
			/>
			<AssistantFab
				handleAssistantFabPress={handleNavigateToAssistant}
				customBottomPosition={16}
			/>
		</>
	);
};

export default DetailArticleContentView;
