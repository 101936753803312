import { groupTagsByType } from "@helpers/content/tag";
import { getShowStudyPackViews } from "@helpers/exams";
import type { BundleContext, MixedItemDist } from "@memorang/types";
import { trackCustomEvent } from "analytics";
import type { BundleType } from "../queries/FetchActiveCodes";

const trackBundleContext = ({ id, name }: BundleContext) => {
	const userProperty = {
		bundle_context_id: id,
		bundle_context_title: name,
	};
	trackCustomEvent(
		{ eventName: "Bundle_Context_Switched", id, name },
		userProperty,
	);
};

export const getSummativeTestAlertConfig = (examName: string) => ({
	quiz: {
		severity: "info",
		description: `What's on the ${examName} exam? Take a free mini-diagnostic assessment to become more familiar with various question types and topics. Each mini-diagnostic assessment includes a detailed score report of your basic strengths/weaknesses and tips for how to improve.`,
	},
	"practice-tests": {
		severity: "info",
		description: `Ready to practice? Assess your readiness for the ${examName} exam with premium, full-length, timed practice tests. Each test includes a timer, comprehensive explanations for each question, and a detailed score report to highlight your performance across all topics.`,
	},
	topicMastery: {
		severity: "info",
		title: "Mastery Breakdown",
		description:
			"The table below shows your performance on each topic, the number of items you’ve answered so far, the proportion of the exam that the topic represents, and your opportunity for improvement. The greater the “opportunity,” the better suited you will be by focusing your studies to strengthen your mastery of that topic to maximize your chance of passing.",
	},
});

const getProductItemDescription = (bundleType: BundleType) => {
	switch (bundleType) {
		case "STUDY_PACK":
			return "Study-Pack";
		case "PRACTICE_TEST":
			return "Practice Tests";
		case "EXAM":
			return "Exam-Prep";
		default:
			return "";
	}
};

export const getQuickActionsChips = (studyPackProductId?: string) => {
	const showStudyPackViews = getShowStudyPackViews(studyPackProductId);
	const chips = [
		...(showStudyPackViews
			? [
					{
						label: "Study weak areas",
						type: "weak" as const,
						icon: "alert-decagram-outline",
						tooltip:
							"Focus on areas where you are weak to improve your overall performance",
					},
				]
			: []),
		{
			label: "View progress",
			type: "progress" as const,
			icon: "chart-timeline-variant-shimmer",
			tooltip: "Track your progress and see how you are improving",
		},
		{
			label: "View rank",
			type: "leaderboard" as const,
			icon: "podium-gold",
			tooltip: "See how you rank against others",
		},
	];
	return chips;
};

export const getWeakTagsIds = (mixedItemDist: MixedItemDist) => {
	const { tags, freeTagIds } = mixedItemDist;
	const groupedTagsByType = groupTagsByType(tags, "Progress", freeTagIds);
	const allTags = Object.keys(groupedTagsByType).flatMap(
		(key) => groupedTagsByType[key],
	);
	const lowScoredTags = allTags.filter(
		(item) =>
			item.distribution.low > 0 ||
			item.distribution.high > 0 ||
			item.distribution.medium > 0,
	);

	const ids = lowScoredTags.map((tag) => tag.id);
	return ids;
};
export { trackBundleContext, getProductItemDescription };
