import type { Article } from "../../../../../types";

export const Article607: Article = {
	id: 607,
	rsuiteId: "e4ea1710-bc67-449c-acef-c2c72f6f88ea",
	type: "scientific article",
	title:
		"Levels of Synovial Fluid Inflammatory Biomarkers on Day of Arthroscopic Partial Meniscectomy Predict Long-Term Outcomes and Conversion to TKA",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4ea1710-bc67-449c-acef-c2c72f6f88ea&type=jpeg&name=JBJS.23.01392f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Levels of Synovial Fluid Inflammatory Biomarkers on Day
                of Arthroscopic Partial Meniscectomy Predict Long-Term Outcomes and Conversion to TKA</div>
              <div class="text subtitle" style="position: unset;">A 10-Year Mean Follow-up Study</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Michael R.
                      Moore, BA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Brittany
                      DeClouette, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Background:</span> The purpose of the present study was to evaluate
                        the relationships of the concentrations of pro- and anti-inflammatory biomarkers in the knee
                        synovial fluid at the time of arthroscopic partial meniscectomy (APM) to long-term
                        patient-reported outcomes (PROs) and conversion to total knee arthroplasty (TKA).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Methods:</span> A database of patients who underwent APM for isolated
                        meniscal injury was analyzed. Synovial fluid had been aspirated from the operatively treated
                        knee prior to the surgical incision, and concentrations of pro- and anti-inflammatory biomarkers
                        (RANTES, IL-6, MCP-1, MIP-1β, VEGF, TIMP-1, TIMP-2, IL-1RA, MMP-3, and bFGF) were quantified.
                        Prior to surgery and again at the time of final follow-up, patients were asked to complete a
                        survey that included a visual analog scale (VAS) for pain and Lysholm, Tegner, and Knee injury
                        and Osteoarthritis Outcome Score-Physical Function Short Form (KOOS-PS) questionnaires.
                        Clustering analysis of the 10 biomarkers of interest was carried out with the k-means algorithm.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Results:</span> Of the 82 patients who met the inclusion criteria for
                        the study, 59 had not undergone subsequent ipsilateral TKA or APM, and 43 (73%) of the 59
                        completed PRO questionnaires at long-term follow-up. The mean follow-up time was 10.6 ± 1.3
                        years (range, 8.7 to 12.4 years). Higher concentrations of individual pro-inflammatory
                        biomarkers including MCP-1 (β = 13.672, p = 0.017) and MIP-1β (β = −0.385, p = 0.012) were
                        associated with worse VAS pain and Tegner scores, respectively. K-means clustering analysis
                        separated the cohort of 82 patients into 2 groups, one with exclusively higher levels of
                        pro-inflammatory biomarkers than the second group. The “pro-inflammatory phenotype” cohort had a
                        significantly higher VAS pain score (p = 0.024) and significantly lower Lysholm (p = 0.022),
                        KOOS-PS (p = 0.047), and Tegner (p = 0.009) scores at the time of final follow-up compared with
                        the “anti-inflammatory phenotype” cohort. The rate of conversion to TKA was higher in the
                        pro-inflammatory cohort (29.4% versus 12.2%, p = 0.064). Logistic regression analysis
                        demonstrated that the pro-inflammatory phenotype was significantly correlated with conversion to
                        TKA (odds ratio = 7.220, 95% confidence interval = 1.028 to 50.720, p = 0.047).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The concentrations of synovial fluid biomarkers
                        on the day of APM can be used to cluster patients into pro- and anti-inflammatory cohorts that
                        are predictive of PROs and conversion to TKA at long-term follow-up.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Substantial research efforts over the last several decades
                  have been dedicated to identifying factors that may influence outcomes following meniscal surgery<a
                    href="" data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">3</span></a>. More
                  recently, there has been a surge in research activity centered around the effects of synovial fluid
                  (SF) biomarkers on the outcomes of patients with surgically treated meniscal tears<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The perturbation of the homeostatic equilibrium in SF
                  toward heightened pro-inflammatory cytokine signaling is considered a pivotal factor in the
                  pathogenesis of posttraumatic osteoarthritis<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">8</span></a>. Previous studies have demonstrated that SF biomarker levels
                  are associated with both the knee osteoarthritis grade on radiographs and magnetic resonance imaging
                  evidence of posttraumatic osteoarthritis in patients with meniscal injury<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">10</span></a>.
                  Furthermore, the concentrations of numerous SF biomarkers including interleukin (IL)-6, matrix
                  metalloproteinase (MMP)-3, and macrophage inhibitory protein (MIP)-1β have been suggested as potential
                  indices of inflammation and regenerative processes in the context of meniscal injuries<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">12</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Additionally, it is noteworthy that the levels of SF
                  biomarkers have demonstrated associations with patient-reported outcomes (PROs) following orthopaedic
                  injuries, including symptomatic meniscal tears requiring surgical intervention<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">13</span></a>. Our
                  group previously demonstrated a relationship between SF biomarker concentrations on the day of knee
                  arthroscopy and intermediate-term functional outcomes<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">14</span></a>. In
                  particular, it was noted that the concentrations of SF biomarkers on the day of surgery could be used
                  to cluster patients into cohorts that had more and less favorable outcomes at intermediate-term
                  follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">There remains a lack of research regarding the
                  relationship between SF biomarker levels on the day of surgery and long-term PROs and clinical
                  outcomes following arthroscopic partial meniscectomy (APM)<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">14</span></a>. The
                  primary purpose of the present study was to determine the relationships of the concentrations of pro-
                  and anti-inflammatory markers in SF on the day of surgery in patients with isolated meniscal injury
                  who were undergoing APM to both PROs and the rate of conversion to total knee arthroplasty (TKA) at
                  long-term follow-up. We hypothesized that patients with higher levels of pro-inflammatory biomarkers
                  and lower levels of anti-inflammatory biomarkers on the day of surgery would have poorer PROs and a
                  higher rate of conversion to ipsilateral TKA at long-term follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">With institutional review board approval (s15-00929), we
                  prospectively collected and analyzed SF samples from patients undergoing knee surgery between August
                  2011 and September 2023. On the day of surgery, prior to surgical incision, all enrolled patients
                  underwent SF aspiration from the affected knee, enabling quantification of the levels of the pro- and
                  anti-inflammatory biomarkers listed in <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Basic Functions of the Tested Biomarkers<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1129px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Biomarker</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Function</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pro-inflammatory</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RANTES</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Chemokine that recruits
                          leukocytes to the site of inflammation and activates natural killer cells</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IL-6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Cytokine secreted by T
                          cells and macrophages during infection or after trauma that stimulates IL-10 and IL-1RA
                          production to act in a negative feedback loop of inflammatory response</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> MCP-1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Chemokine that recruits
                          monocytes, memory T cells, and dendritic cells to the site of inflammation</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> MIP-1β</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Chemokine produced by
                          macrophages that activates granulocytes and promotes release of inflammatory cytokines</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> MMP-3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Matrix metalloproteinase
                          that degrades collagen, proteoglycans, fibronectin, laminin, and elastin, and that activates
                          other MMPs</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Anti-inflammatory</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> TIMP-1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Inhibitor of MMPs that
                          promotes growth of chondrocyte cells and has a chondroprotective effect</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> TIMP-2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Inhibitor of MMPs that
                          promotes tissue homeostasis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IL-1RA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Cytokine that inhibits
                          the pro-inflammatory effects of IL-1β</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Growth factor</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> VEGF</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Induces
                          angiogenesis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> bFGF</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Promotes chondrogenesis,
                          angiogenesis, wound healing, and granulation tissue formation</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">RANTES = regulated on activation, normal T cell expressed and secreted;
                        IL = interleukin; IL-1RA = IL-1 receptor antagonist; MCP = monocyte chemotactic protein; MIP =
                        macrophage inflammatory protein; MMP = matrix metalloproteinase; TIMP = tissue inhibitor of
                        metalloproteinase; VEGF = vascular endothelial growth factor; bFGF = basic fibroblast growth
                        factor.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Of the 1,334 patients enrolled in our database from August
                  2011 to August 2023, 404 patients with isolated meniscal injury who underwent APM were identified.
                  After those with &lt;8 years of follow-up were excluded, 90 patients were considered for inclusion in
                  the present study. Application of the exclusion criteria in <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1</a>
                  resulted in a final study cohort of 82 patients. The comprehensive questionnaire that had been
                  completed during the preoperative assessment included a visual analog scale (VAS) for pain, the
                  Lysholm score, and the Knee injury and Osteoarthritis Outcome Score-Physical Function Short Form
                  (KOOS-PS). We attempted to contact the patients again at the time of final follow-up at least 8 years
                  after surgery, and ask them to complete the same PRO questionnaires. However, the PROs of the patients
                  who had undergone subsequent knee surgery were excluded from the PRO analysis, as their questionnaire
                  responses would more likely be a reflection of the result of that subsequent surgery. Instead, the PRO
                  responses of the remaining patients were used to simulate the responses of the patients with
                  subsequent knee surgery, as outlined below.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01392f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4ea1710-bc67-449c-acef-c2c72f6f88ea&type=jpeg&name=JBJS.23.01392f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;">STROBE (Strengthening the Reporting of
                          Observational Studies in Epidemiology) diagram of patient inclusion.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The comprehensive questionnaire that had been completed
                  during the preoperative assessment included a VAS for pain, the Lysholm score, the KOOS-PS, and the
                  Tegner score. Patients were contacted again at the time of final follow-up at least 8 years after
                  surgery and asked to complete the same PRO questionnaires.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Demographic factors, the duration of symptoms, and the
                  International Cartilage Regeneration & Joint Preservation Society (ICRS) grade were accessed using the
                  electronic medical record. Information regarding conversion to ipsilateral TKA was obtained through
                  both review of the electronic medical record and the patient surveys.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">SF collection and the biomarker analysis are described in
                  the Appendix<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">15</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">16</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Statistical analysis was performed using SPSS (version 24;
                  IBM). Continuous variables were compared between cohorts using the 2-sample independent t test.
                  Chi-square analysis was used to compare categorical and binomial variables. A p value of 0.05 was
                  considered significant. Linear regression analysis was used to assess the relationship of each SF
                  biomarker with continuous outcome variables. Logistic regression was used to analyze the relationship
                  of SF biomarker values with binary categorical variables, including conversion to TKA. In all
                  regression analyses, the SF biomarker concentrations were standardized using z-scores, and age, sex,
                  body mass index (BMI), symptom duration, and ICRS grade were controlled for as covariates.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">As patients who elected to undergo subsequent ipsilateral
                  TKA or APM likely had worse outcomes than the average patient in the present analysis, the initial
                  exclusion of these patients from the analysis of PROs would have led to nonrandom losses that could
                  have potentially biased the data. These nonrandom losses of PROs for patients who had subsequent
                  ipsilateral TKA or APM were therefore accounted for by replacing them with random sampling of the PRO
                  scores of the bottom 10th and 25th percentiles, respectively, for each PRO score analyzed in the
                  present study, and assigning those scores to these excluded patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">K-Means
                Clustering Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">K-means clustering is an unsupervised machine learning
                  algorithm that is widely used in the context of biomedical research for partitioning a data set into a
                  set of distinct, non-overlapping clusters<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">17</span></a>.
                  The algorithm begins by initializing k centroids randomly among the data set, where k is a predefined
                  number of clusters. The algorithm then iteratively performs 2 main steps (assignment and update) until
                  convergence. In the assignment step, each data point is assigned to the nearest centroid (based on the
                  distance between the data point and the centroid). This process effectively partitions the data set
                  into clusters on the basis of the current centroids. In the update step, each centroid is recalculated
                  as the mean of all points assigned to that cluster, thus refining its position to better represent the
                  center of the cluster. This iterative process continues until the centroids no longer change
                  substantially in each update step, indicating that the clusters are as compact and distinct as
                  possible. The result is a partitioning of the data set into k groups that minimizes the within-cluster
                  variance and maximizes the between-cluster separation, providing a powerful and robust method for
                  identifying natural groupings within data<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">18</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">In the present study, the concentrations of the 10 SF
                  biomarkers of interest were standardized using z-scores, on account of the skewed nature of their
                  data, before the k-means clustering analysis was performed. The Akaike information criterion (AIC)
                  with a Euclidean distance measure was used to determine the number of clusters to implement in the
                  k-means algorithm. Based on the silhouette measure of cohesion and separation, the AIC analysis
                  suggested that 2 clusters would yield good cluster quality.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">PRO
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Demographics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">The 82 patients who met the criteria for the study had a
                  mean follow-up (and standard deviation) of 10.6 ± 1.3 years (range, 8.7 to 12.4 years). Their
                  demographic information can be found in <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>.
                  Fifty-nine of these patients had not undergone subsequent ipsilateral APM or TKA, and 43 (73%) of
                  those were successfully contacted and filled out PRO questionnaires.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Demographics (N = 82)<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 209px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49.8 ±
                          12.5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex, M/F</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">50%/50%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_34" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_35" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_12" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_12"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_36"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.4 ±
                          5.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Symptom duration <span
                            data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(mo)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.8 ±
                          16.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up <span
                            data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.6 ±
                          1.3</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Except for sex, the values are given as the mean and standard
                        deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Linear
                Regression Analyses of Biomarker Concentrations Versus PROs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">A higher monocyte chemoattractant protein (MCP)-1
                  concentration at the time of surgery was associated with lower Lysholm (β = −10.142, p = 0.017),
                  higher VAS pain (β = 13.672, p = 0.017), and lower KOOS-PS (β = −8.838, p = 0.046) scores at the time
                  of final follow-up. A higher tissue inhibitor of metalloproteinase (TIMP)-1 value at the time of
                  surgery was associated with a higher VAS pain score (β = 11.553, p = 0.035) at the time of final
                  follow-up (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>) as
                  well as a smaller decrease in VAS pain from the preoperative baseline to the final follow-up (β =
                  18.582, p = 0.005) (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).
                  Higher MCP-1 (β = −0.488, p = 0.018), MIP-1β (β = −0.385, p = 0.012), vascular endothelial growth
                  factor (VEGF) (β = −0.468, p = 0.015), and TIMP-1 (β = −0.530, p = 0.006) concentrations were
                  associated with a lower Tegner score at the time of final follow-up. Furthermore, higher MCP-1 (β =
                  −0.835, p = 0.013) and TIMP-1 (β = −0.649, p = 0.035) concentrations were associated with a smaller
                  increase in the Tegner score from the preoperative baseline to the final follow-up (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Linear Regression of Biomarker Concentrations
                          Versus VAS Pain, Lysholm, and KOOS-PS Scores<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 533.785px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.98209);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Biomarker</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Final VAS</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Final Lysholm</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Final KOOS-PS</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RANTES</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−5.654</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.152</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.841</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.218</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.989</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.188</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">IL-6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.159</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.443</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−8.484</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.304</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−4.972</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.538</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MCP-1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.672</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_40" class="annotation strong" style="position: unset;">0.017</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−10.142</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_41" class="annotation strong" style="position: unset;">0.024</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−8.838</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_42" class="annotation strong" style="position: unset;">0.046</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MIP-1β</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.770</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.183</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−4.683</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.168</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−4.473</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.178</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VEGF</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.452</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.100</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−7.420</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.082</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−7.527</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.071</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TIMP-1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.553</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_43" class="annotation strong" style="position: unset;">0.035</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−6.741</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.120</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−8.228</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.051</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TIMP-2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1.264</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.767</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.599</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.858</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.581</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.631</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">IL-1RA</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.241</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.713</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.780</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.910</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.209</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.857</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MMP-3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.576</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.287</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−5.039</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.133</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−4.450</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.173</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">bFGF</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−3.410</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.425</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.461</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.300</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.518</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.278</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The concentrations of the synovial fluid biomarkers were standardized
                        using z-scores. Each regression analysis controlled for age, sex, BMI, ICRS grade at the time of
                        surgery, and symptom duration prior to surgery. Beta represents the estimated slope of the
                        regression line between the standardized biomarker concentration (the predictor) and the
                        patient-reported outcome.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Linear Regression of Biomarker Concentrations Versus
                          Changes in VAS Pain and Lysholm Scores<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 480px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Biomarker</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">VAS Change</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Lysholm Change</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RANTES</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.272</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.478</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.550</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.881</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">IL-6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−12.071</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.407</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−3.951</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.732</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MCP-1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.903</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.095</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−10.835</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.065</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MIP-1β</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.045</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.123</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−2.149</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.600</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VEGF</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.690</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.531</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−6.720</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.242</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TIMP-1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.582</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_44" class="annotation strong" style="position: unset;">0.005</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−9.204</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.091</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TIMP-2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.608</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.177</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.615</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.699</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">IL-1RA</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.185</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.501</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−13.270</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.152</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MMP-3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.789</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.482</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−2.639</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.527</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">bFGF</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−5.497</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.290</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.121</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.598</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Changes were from the preoperative baseline to final follow-up. The
                        concentrations of the synovial fluid biomarkers were standardized using z-scores. Each
                        regression analysis controlled for age, sex, BMI, ICRS grade at the time of surgery, and symptom
                        duration prior to surgery. Beta represents the estimated slope of the regression line between
                        the standardized biomarker concentration (the predictor) and the change in the patient-reported
                        outcome.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Linear Regression of Biomarker Concentrations Versus
                          Tegner Scores and Score Changes<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 480px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Biomarker</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Final Tegner</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Tegner Change</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RANTES</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.188</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.184</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.197</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.345</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">IL-6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.266</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.453</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1.037</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_45" class="annotation strong" style="position: unset;">0.010</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MCP-1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.488</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_46" class="annotation strong" style="position: unset;">0.018</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.835</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_47" class="annotation strong" style="position: unset;">0.013</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MIP-1β</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.385</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_48" class="annotation strong" style="position: unset;">0.012</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.375</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.110</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VEGF</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.468</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_49" class="annotation strong" style="position: unset;">0.015</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.626</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.057</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TIMP-1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.530</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_50" class="annotation strong" style="position: unset;">0.006</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.649</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_51" class="annotation strong" style="position: unset;">0.035</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TIMP-2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.162</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.288</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.059</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.806</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">IL-1RA</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.035</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.911</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.737</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.166</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MMP-3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.230</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.135</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.155</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.524</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">bFGF</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.193</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.207</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.152</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.509</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Changes were from the preoperative baseline to final follow-up. The
                        concentrations of the synovial fluid biomarkers were standardized using z-scores. Each
                        regression analysis controlled for age, sex, BMI, ICRS grade at the time of surgery, and symptom
                        duration prior to surgery. Beta represents the estimated slope of the regression line between
                        the standardized biomarker concentration (the predictor) and the Tegner score or change in
                        Tegner score.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Biomarker
                Clustering Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Seven of the 82 patients in the initial cohort were
                  excluded from the clustering analysis due to incomplete biomarker assay results. K-means clustering
                  analysis separated the remining 75 patients into 2 clusters containing 41 and 34 patients. The cohort
                  containing 34 patients (“pro-inflammatory phenotype”) was found to exclusively have significantly
                  higher levels of several pro-inflammatory biomarkers than the cluster containing 41 patients
                  (“anti-inflammatory phenotype”). Significant differences were seen in IL-6 (p = 0.011), MCP-1 (p &lt;
                  0.001), MIP-1β (p &lt; 0.001), and MMP-3 (p &lt; 0.001) concentrations. Additionally, the cohort with
                  the pro-inflammatory phenotype had a significantly lower level of 1 anti-inflammatory biomarker
                  (TIMP-2 [p = 0.002]) and higher levels of 2 anti-inflammatory biomarkers (TIMP-1 [p &lt; 0.001] and
                  IL-1 receptor antagonist [IL-1RA] [p = 0.033]) and the growth factor VEGF (p &lt; 0.001) (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>) (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Synovial Fluid Biomarker Concentrations in the Pro-
                          and Anti-Inflammatory Patient Cohorts<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 883px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Biomarker</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Pro-Inflammatory Phenotype</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Anti-Inflammatory Phenotype</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RANTES</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.119 ×
                          10<span data-id="superscript_13" class="annotation superscript"
                            style="position: unset;">2</span> ± 2.695 × 10<span data-id="superscript_14"
                            class="annotation superscript" style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.695 ×
                          10<span data-id="superscript_15" class="annotation superscript"
                            style="position: unset;">2</span> ± 1.968 × 10<span data-id="superscript_16"
                            class="annotation superscript" style="position: unset;">3</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.226</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">IL-6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.629 ×
                          10<span data-id="superscript_17" class="annotation superscript"
                            style="position: unset;">2</span> ± 4.606 × 10<span data-id="superscript_18"
                            class="annotation superscript" style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.808 ×
                          10<span data-id="superscript_19" class="annotation superscript"
                            style="position: unset;">1</span> ± 9.886 × 10<span data-id="superscript_20"
                            class="annotation superscript" style="position: unset;">1</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_52" class="annotation strong" style="position: unset;">0.011</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MCP-1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.705 ×
                          10<span data-id="superscript_21" class="annotation superscript"
                            style="position: unset;">2</span> ± 1.892 × 10<span data-id="superscript_22"
                            class="annotation superscript" style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.263 ×
                          10<span data-id="superscript_23" class="annotation superscript"
                            style="position: unset;">2</span> ± 1.029 × 10<span data-id="superscript_24"
                            class="annotation superscript" style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_53" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MIP-1β</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.913 ×
                          10<span data-id="superscript_25" class="annotation superscript"
                            style="position: unset;">1</span> ± 2.539 × 10<span data-id="superscript_26"
                            class="annotation superscript" style="position: unset;">1</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.256 ×
                          10<span data-id="superscript_27" class="annotation superscript"
                            style="position: unset;">1</span> ± 1.465 × 10<span data-id="superscript_28"
                            class="annotation superscript" style="position: unset;">1</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_54" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VEGF</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.068 ×
                          10<span data-id="superscript_29" class="annotation superscript"
                            style="position: unset;">2</span> ± 1.811 × 10<span data-id="superscript_30"
                            class="annotation superscript" style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.676 ×
                          10<span data-id="superscript_31" class="annotation superscript"
                            style="position: unset;">2</span> ± 1.000 × 10<span data-id="superscript_32"
                            class="annotation superscript" style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_55" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TIMP-1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.064 ×
                          10<span data-id="superscript_33" class="annotation superscript"
                            style="position: unset;">5</span> ± 1.806 × 10<span data-id="superscript_34"
                            class="annotation superscript" style="position: unset;">5</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.318 ×
                          10<span data-id="superscript_35" class="annotation superscript"
                            style="position: unset;">5</span> ± 1.561 × 10<span data-id="superscript_36"
                            class="annotation superscript" style="position: unset;">5</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_56" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TIMP-2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.488 ×
                          10<span data-id="superscript_37" class="annotation superscript"
                            style="position: unset;">4</span> ± 9.801 × 10<span data-id="superscript_38"
                            class="annotation superscript" style="position: unset;">3</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.824 ×
                          10<span data-id="superscript_39" class="annotation superscript"
                            style="position: unset;">4</span> ± 4.439 × 10<span data-id="superscript_40"
                            class="annotation superscript" style="position: unset;">4</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_57" class="annotation strong" style="position: unset;">0.002</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">IL-1RA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.117 ×
                          10<span data-id="superscript_41" class="annotation superscript"
                            style="position: unset;">2</span> ± 2.948 × 10<span data-id="superscript_42"
                            class="annotation superscript" style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.581 ×
                          10<span data-id="superscript_43" class="annotation superscript"
                            style="position: unset;">1</span> ± 9.043 × 10<span data-id="superscript_44"
                            class="annotation superscript" style="position: unset;">1</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_58" class="annotation strong" style="position: unset;">0.033</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MMP-3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.640 ×
                          10<span data-id="superscript_45" class="annotation superscript"
                            style="position: unset;">6</span> ± 2.538 × 10<span data-id="superscript_46"
                            class="annotation superscript" style="position: unset;">6</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.476 ×
                          10<span data-id="superscript_47" class="annotation superscript"
                            style="position: unset;">5</span> ± 5.072 × 10<span data-id="superscript_48"
                            class="annotation superscript" style="position: unset;">5</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_59" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">bFGF</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.355 ×
                          10<span data-id="superscript_49" class="annotation superscript"
                            style="position: unset;">1</span> ± 5.901 × 10<span data-id="superscript_50"
                            class="annotation superscript" style="position: unset;">1</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.121 ×
                          10<span data-id="superscript_51" class="annotation superscript"
                            style="position: unset;">1</span> ± 3.940 × 10<span data-id="superscript_52"
                            class="annotation superscript" style="position: unset;">1</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.527</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The patients were separated into the 2 cohorts by k-means clustering
                        analysis. The values are given as the mean and standard deviation in pg/mL.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01392f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e4ea1710-bc67-449c-acef-c2c72f6f88ea&type=jpeg&name=JBJS.23.01392f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;">Scatterplots of standardized synovial fluid
                          biomarker concentrations in the pro-inflammatory and anti-inflammatory patient clusters. The 4
                          scatterplots show the results of the k-means clustering algorithm based on the concentrations
                          of 8 of the 10 synovial fluid biomarkers of interest standardized by z-scores. Green points
                          represent the values of patients in the anti-inflammatory cohort, and red points represent the
                          values in the pro-inflammatory cohort. The top left graph plots VEGF versus IL-1RA, the top
                          right graph plots MMP-3 versus TIMP-1, the bottom left graph plots MCP-1 versus IL-6, and the
                          bottom right graph plots MIP-1β versus TIMP-2.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">PROs by
                Cluster</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The cohort with the pro-inflammatory phenotype had a
                  significantly higher VAS pain level at the time of final follow-up (55.2 ± 34.3 versus 34.6 ± 33.4, p
                  = 0.024). Additionally, the cohort with the anti-inflammatory phenotype had significantly higher
                  Lysholm (71.8 ± 26.5 versus 54.5 ± 29.2, p = 0.022), KOOS-PS (69.1 ± 25.2 versus 55.0 ± 27.5, p =
                  0.047), and Tegner (2.9 ± 1.5 versus 2.0 ± 1.1, p = 0.009) scores at the time of final follow-up (<a
                    href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VII</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VII</span>Long-Term PROs in the Pro- and Anti-Inflammatory
                          Cohorts<span data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 579px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">PRO</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Pro-Inflammatory Phenotype</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Anti-Inflammatory Phenotype</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Final VAS pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55.2 ±
                          34.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.6 ±
                          33.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_60" class="annotation strong" style="position: unset;">0.024</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Change in VAS pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.7 ±
                          36.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−6.8 ±
                          42.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.115</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Final Lysholm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54.5 ±
                          29.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71.8 ±
                          26.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_61" class="annotation strong" style="position: unset;">0.022</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Change in Lysholm</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−6.6 ±
                          30.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.0 ±
                          31.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.101</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Final Tegner</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0 ±
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.9 ±
                          1.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_62" class="annotation strong" style="position: unset;">0.009</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Change in Tegner</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.5 ±
                          1.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2 ±
                          1.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.138</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Final KOOS-PS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55.0 ±
                          27.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69.1 ±
                          25.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_63" class="annotation strong" style="position: unset;">0.047</span></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation. PRO =
                        patient-reported outcome.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Conversion
                to TKA</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">TKA Rate
                According to Biomarker Concentrations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">The logistic regression analyses found that higher
                  concentrations of MCP-1 (odds ratio [OR] = 2.821, 95% confidence interval [CI] = 1.171 to 6.794, p =
                  0.021) and TIMP-1 (OR = 5.432, 95% CI = 1.479 to 19.956, p = 0.011) were associated with a higher rate
                  of conversion to TKA (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VIII</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VIII</span>Effects of Synovial Fluid Biomarker Concentrations
                          on Conversion to TKA<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 480px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Biomarker</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Conversion to TKA</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Odds Ratio
                          (95% CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RANTES</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.043
                          (0.000-252.909)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.477</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">IL-6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.006
                          (0.457-8.803)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.457</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MCP-1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.821
                          (1.171-6.794)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_64" class="annotation strong" style="position: unset;">0.021</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MIP-1β</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.721
                          (0.859-4.922)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.105</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VEGF</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.998
                          (0.816-4.890)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.130</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TIMP-1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.432
                          (1.479-19.956)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_65" class="annotation strong" style="position: unset;">0.011</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TIMP-2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.654
                          (0.254-1.680)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.254</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">IL-1RA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.793
                          (0.209-3.006)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.733</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MMP-3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.042
                          (0.520-2.088)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.907</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">bFGF</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.091
                          (0.006-1.426)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.088</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">According to logistic regression analysis. The concentrations of the
                        synovial fluid biomarkers were standardized using z-scores. Each regression analysis controlled
                        for age, sex, BMI, ICRS grade at the time of surgery, and symptom duration prior to
                        surgery.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">TKA Rate in
                Pro- and Anti-Inflammatory Phenotype Clusters</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The rate of conversion to TKA was higher in the cohort
                  with the pro-inflammatory phenotype (29.4%) than in the cohort with the anti-inflammatory phenotype
                  (12.2%), but the difference did not reach significance (p = 0.064). However, a logistic regression
                  analysis controlling for age, sex, BMI, symptom duration prior to surgery, and ICRS grade on the day
                  of surgery found that having the pro-inflammatory phenotype was significantly associated with
                  conversion to TKA (OR = 7.220, 95% CI = 1.028 to 50.720, p = 0.047).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">This study demonstrated that SF biomarker levels on the
                  day of APM can be used to predict PROs and conversion to TKA at long-term follow-up. Elevations in
                  individual pro-inflammatory biomarkers including MCP-1 and MIP-1β correlated with worse PROs including
                  Lysholm and Tegner scores at long-term follow-up. This finding is in agreement with a study by Cuéllar
                  et al., which found that higher levels of MCP-1 on the day of knee arthroscopy were associated with
                  higher pain levels at a mean follow-up of 17 months<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_53" class="annotation superscript" style="position: unset;">19</span></a>.
                  However, the most important finding of the present study was that k-means clustering analysis, an
                  unsupervised machine learning algorithm, could be successfully employed to separate patients into
                  unfavorable pro-inflammatory and favorable anti-inflammatory cohorts that differed significantly in
                  VAS pain, Lysholm, KOOS-PS, and Tegner scores, and with belonging to the pro-inflammatory cohort being
                  associated with a higher rate of conversion to TKA at long-term follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, this is the first study to establish a
                  relationship between the levels of SF biomarkers on the day of APM and long-term outcomes, as well as
                  with the rate of conversion to TKA. This study builds on our previous study not only by demonstrating
                  that patients can be clustered into cohorts that are predictive of outcomes at long-term follow-up,
                  but also by suggesting a clear pathophysiological mechanism for this difference in outcomes<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_54" class="annotation superscript"
                      style="position: unset;">14</span></a>. Namely, the present study demonstrated that patients who
                  were clustered into a cohort that had exclusively higher levels of pro-inflammatory biomarkers on the
                  day of surgery had worse PROs and a higher rate of conversion to TKA at long-term follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">The k-means clustering analysis in the present study also
                  expands on the prior research from our group<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_55" class="annotation superscript" style="position: unset;">14</span></a>.
                  Our previous study involved an analysis using the same database as the present study but examined
                  intermediate-term outcomes in a different subset of patients in the database<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_56" class="annotation superscript"
                      style="position: unset;">14</span></a>. It employed a hierarchical clustering algorithm based on 2
                  abstract principal components derived from dimensionality reduction on the same 10 inflammatory
                  biomarkers that were analyzed in the present study<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_57" class="annotation superscript" style="position: unset;">14</span></a>. In
                  the present study, which employed a k-means clustering algorithm, the pro-inflammatory cohort had
                  significantly greater concentrations of the pro-inflammatory biomarkers MCP-1, MMP-3, MIP-1β, ΙL-6,
                  and VEGF than the anti-inflammatory patient cohort; VEGF has been shown to be catabolic and
                  inflammatory in osteoarthritis<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_58" class="annotation superscript" style="position: unset;">20</span></a>.
                  Additionally, the anti-inflammatory cohort was found to have a higher level of TIMP-2, an inhibitor of
                  the pro-inflammatory MMPs. Although the abstract principal component variables that were used to
                  cluster patients in our previous study do not necessarily represent any underlying biological
                  phenotype, 1 of the principal components was found to correlate significantly with many
                  pro-inflammatory biomarkers, including MCP-1, MMP-3, IL-6, and MIP-1β. It is noteworthy that these are
                  the same pro-inflammatory biomarkers that were found to be significantly higher in the
                  pro-inflammatory patient cohort in the present study. Additionally, the second principal component in
                  our previous analysis was found to correlate significantly with the anti-inflammatory biomarker
                  TIMP-2, which was found to be elevated in the anti-inflammatory cohort in the present study. Taken
                  together, the findings of the present study and our previous study strongly support the conclusion
                  that patients can be clustered into pro-inflammatory and anti-inflammatory phenotypes on the basis of
                  their SF biomarker concentrations on the day of surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">The pro-inflammatory cohort in the present study was found
                  to have higher VAS pain, lower Lysholm, lower KOOS-PS, and lower Tegner scores at long-term follow-up.
                  More importantly, however, the present study demonstrated that the pro-inflammatory cohort had a
                  higher rate of conversion to TKA. When controlling for confounding variables in a logistic regression
                  analysis including age, sex, BMI, ICRS grade at the time of surgery, and symptom duration prior to
                  surgery, belonging to the pro-inflammatory cohort was found to be significantly associated with
                  conversion to TKA (OR = 7.220, 95% CI = 1.028 to 50.720, p = 0.047).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, no prior studies have investigated the
                  relationship between SF biomarker levels on the day of APM and conversion to TKA. However, Amano et
                  al. found that higher levels of several biomarkers on the day of anterior cruciate ligament
                  reconstruction, including IL-6, IL-8, IL-10, tumor necrosis factor-α, MMP-1, and MMP-3, were
                  associated with a higher rate of radiographic evidence of abnormal cartilage tissue integrity at
                  3-year follow-up<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_59" class="annotation superscript" style="position: unset;">21</span></a>.
                  Those results are strongly in agreement with those of the present study, in which both IL-6 and MMP-3
                  levels were significantly higher in the pro-inflammatory cohort, which had a higher rate of conversion
                  to TKA and therefore presumably greater cartilage degeneration.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">This study has several limitations. Although the results
                  offer a glimpse into the intra-articular conditions after isolated meniscal injury, it is crucial to
                  note that this represents only a single facet of the inflammatory process after meniscal injury.
                  Tracking of biomarker levels throughout the progression of a disease is useful, but the present study
                  only examined biomarkers at the time of surgery. Additionally, although the regression analyses
                  controlled for age, sex, BMI, ICRS grade at the time of surgery, and symptom duration prior to
                  surgery, there are many other variables that can influence a patient’s inflammatory biomarker profile
                  and outcomes following APM that were not considered in this study, including smoking, alcohol use,
                  illicit drug use, stress, diet, sleep patterns, and recent illness<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_60" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_60"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_60" class="annotation superscript"
                      style="position: unset;">25</span></a>. Finally, our study focused solely on 10 particular
                  biomarkers that our research team and other experts have recognized as clinically important<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_61" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_61" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_61" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_61"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_61" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_61"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_61" class="annotation superscript"
                      style="position: unset;">27</span></a>. Numerous other biomarkers, such as aggrecan, ghrelin,
                  IL-8, IL-10, and fibronectin, hold clinical relevance as well and could have also been considered in
                  our study<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_62" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_62"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_62" class="annotation superscript"
                      style="position: unset;">31</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Summary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">The findings of the present study demonstrated that the
                  concentrations of SF biomarkers on the day of APM can be used to cluster patients into pro- and
                  anti-inflammatory phenotypes and can predict PROs and conversion to TKA at long-term follow-up. These
                  findings may help to identify patients at high risk for worse postoperative outcomes and progression
                  of degenerative joint disease following APM, and they may also potentially help to isolate possible
                  targets for future therapy to improve patient outcomes and reduce the risk of posttraumatic
                  osteoarthritis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I186" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I186</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Xu C</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Zhao J</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">A meta-analysis comparing
                  meniscal repair with meniscectomy in the treatment of meniscal tears: the more meniscus, the better
                  outcome?</span><span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Knee
                  Surg Sports Traumatol Arthrosc.</span> 2015 Jan;23(1):164-70.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20meta-analysis%20comparing%20meniscal%20repair%20with%20meniscectomy%20in%20the%20treatment%20of%20meniscal%20tears%3A%20the%20more%20meniscus%2C%20the%20better%20outcome%3F&as_occt=title&as_sauthors=%20%22C%20Xu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Beaufils P</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Becker R</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Verdonk R</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Aagaard H</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Karlsson J</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Focusing on results after
                  meniscus surgery</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2015 Jan;23(1):3-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Focusing%20on%20results%20after%20meniscus%20surgery&as_occt=title&as_sauthors=%20%22P%20Beaufils%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Harput G</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Guney-Deniz H</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Nyland J</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Kocabey Y</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Postoperative rehabilitation and
                  outcomes following arthroscopic isolated meniscus repairs: A systematic review</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Phys Ther Sport.</span> 2020
                Sep;45:76-85.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postoperative%20rehabilitation%20and%20outcomes%20following%20arthroscopic%20isolated%20meniscus%20repairs%3A%20A%20systematic%20review&as_occt=title&as_sauthors=%20%22G%20Harput%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Rodeo SA</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Monibi F</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Dehghani B</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Maher S</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Biological and Mechanical
                  Predictors of Meniscus Function: Basic Science to Clinical Translation</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Orthop Res.</span> 2020
                May;38(5):937-45.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biological%20and%20Mechanical%20Predictors%20of%20Meniscus%20Function%3A%20Basic%20Science%20to%20Clinical%20Translation&as_occt=title&as_sauthors=%20%22SA%20Rodeo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Clair AJ</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Kingery MT</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Anil U</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Kenny L</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Kirsch T</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Strauss EJ</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Alterations in Synovial Fluid
                  Biomarker Levels in Knees With Meniscal Injury as Compared With Asymptomatic Contralateral
                  Knees</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2019 Mar;47(4):847-56.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Alterations%20in%20Synovial%20Fluid%20Biomarker%20Levels%20in%20Knees%20With%20Meniscal%20Injury%20as%20Compared%20With%20Asymptomatic%20Contralateral%20Knees&as_occt=title&as_sauthors=%20%22AJ%20Clair%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Garriga C</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Goff M</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Paterson E</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Hrusecka R</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Hamid B</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Alderson J</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Leyland K</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Honeyfield L</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Greenshields L</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Satchithananda K</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Lim A</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Arden NK</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Williams A</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Vincent TL</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Watt FE</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Clinical and molecular
                  associations with outcomes at 2 years after acute knee injury: a longitudinal study in the Knee Injury
                  Cohort at the Kennedy (KICK)</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Lancet Rheumatol.</span> 2021 Jun 24;3(9):e648-58.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20and%20molecular%20associations%20with%20outcomes%20at%202%20years%20after%20acute%20knee%20injury%3A%20a%20longitudinal%20study%20in%20the%20Knee%20Injury%20Cohort%20at%20the%20Kennedy%20(KICK)&as_occt=title&as_sauthors=%20%22C%20Garriga%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Favero M</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Belluzzi E</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Trisolino G</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Goldring MB</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Goldring SR</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Cigolotti A</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Pozzuoli A</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Ruggieri P</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Ramonda R</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Grigolo B</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Punzi L</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Olivotto E</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Inflammatory molecules produced
                  by meniscus and synovium in early and end-stage osteoarthritis: a coculture study</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J Cell Physiol.</span> 2019
                Jul;234(7):11176-87.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inflammatory%20molecules%20produced%20by%20meniscus%20and%20synovium%20in%20early%20and%20end-stage%20osteoarthritis%3A%20a%20coculture%20study&as_occt=title&as_sauthors=%20%22M%20Favero%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Yang L</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Chen Z</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Guo H</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Wang Z</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Sun K</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Yang X</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Zhao X</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Ma L</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Wang J</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Meng Z</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Jin Q</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Extensive cytokine analysis in
                  synovial fluid of osteoarthritis patients</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Cytokine.</span> 2021 Jul;143:155546.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Extensive%20cytokine%20analysis%20in%20synovial%20fluid%20of%20osteoarthritis%20patients&as_occt=title&as_sauthors=%20%22L%20Yang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Vangsness CT Jr</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Burke WS</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Narvy SJ</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> MacPhee RD</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Fedenko AN</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Human knee synovial fluid
                  cytokines correlated with grade of knee osteoarthritis—a pilot study</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Bull NYU Hosp Jt Dis.</span>
                2011;69(2):122-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Human%20knee%20synovial%20fluid%20cytokines%20correlated%20with%20grade%20of%20knee%20osteoarthritis%E2%80%94a%20pilot%20study&as_occt=title&as_sauthors=%20%22CT%20Jr%20Vangsness%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Ding J</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Niu X</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Su Y</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Li X</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Expression of synovial fluid
                  biomarkers in patients with knee osteoarthritis and meniscus injury</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Exp Ther Med.</span> 2017
                Aug;14(2):1609-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Expression%20of%20synovial%20fluid%20biomarkers%20in%20patients%20with%20knee%20osteoarthritis%20and%20meniscus%20injury&as_occt=title&as_sauthors=%20%22J%20Ding%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Turati M</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Franchi S</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Crippa M</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Rizzi L</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Rigamonti L</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Sacerdote P</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Gatti SD</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Piatti M</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Galimberti G</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Munegato D</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Amodeo G</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Omeljaniuk RJ</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Zatti G</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Torsello A</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Bigoni M</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Prokineticin 2 and Cytokine
                  Content in the Synovial Fluid of Knee Osteoarthritis and Traumatic Meniscal Tear Patients: Preliminary
                  Results</span>. <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J
                  Clin Med.</span> 2023 Jun 28;12(13):4330.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prokineticin%202%20and%20Cytokine%20Content%20in%20the%20Synovial%20Fluid%20of%20Knee%20Osteoarthritis%20and%20Traumatic%20Meniscal%20Tear%20Patients%3A%20Preliminary%20Results&as_occt=title&as_sauthors=%20%22M%20Turati%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Kim-Wang SY</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Holt AG</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> McGowan AM</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Danyluk ST</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Goode AP</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Lau BC</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Toth AP</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Wittstein JR</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> DeFrate LE</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Yi JS</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> McNulty AL</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Immune cell profiles in
                  synovial fluid after anterior cruciate ligament and meniscus injuries</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Arthritis Res Ther.</span>
                2021 Nov 4;23(1):280.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Immune%20cell%20profiles%20in%20synovial%20fluid%20after%20anterior%20cruciate%20ligament%20and%20meniscus%20injuries&as_occt=title&as_sauthors=%20%22SY%20Kim-Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Struglics A</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Turkiewicz A</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Larsson S</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Lohmander LS</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Roemer FW</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Frobell R</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Englund M</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Molecular and imaging
                  biomarkers of local inflammation at 2 years after anterior cruciate ligament injury do not associate
                  with patient reported outcomes at 5 years</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Osteoarthritis Cartilage.</span> 2020
                Mar;28(3):356-62.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Molecular%20and%20imaging%20biomarkers%20of%20local%20inflammation%20at%202%20years%20after%20anterior%20cruciate%20ligament%20injury%20do%20not%20associate%20with%20patient%20reported%20outcomes%20at%205%20years&as_occt=title&as_sauthors=%20%22A%20Struglics%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Kingery MT</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Adams AC</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Manjunath AK</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Berlinberg EJ</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Markus DH</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Strauss EJ</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Synovial Fluid Cytokine Profile
                  at the Time of Arthroscopy Explains Intermediate-Term Functional Outcomes</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2022 Apr;50(5):1261-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Synovial%20Fluid%20Cytokine%20Profile%20at%20the%20Time%20of%20Arthroscopy%20Explains%20Intermediate-Term%20Functional%20Outcomes&as_occt=title&as_sauthors=%20%22MT%20Kingery%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Cuellar VG</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Cuellar JM</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Golish SR</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Yeomans DC</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Scuderi GJ</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Cytokine profiling in acute
                  anterior cruciate ligament injury</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2010 Oct;26(10):1296-301.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cytokine%20profiling%20in%20acute%20anterior%20cruciate%20ligament%20injury&as_occt=title&as_sauthors=%20%22VG%20Cuellar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Kingery MT</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Anil U</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Berlinberg EJ</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Clair AJ</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Kenny L</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Strauss EJ</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Changes in the Synovial Fluid
                  Cytokine Profile of the Knee Between an Acute Anterior Cruciate Ligament Injury and Surgical
                  Reconstruction</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2022 Feb;50(2):451-60.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Changes%20in%20the%20Synovial%20Fluid%20Cytokine%20Profile%20of%20the%20Knee%20Between%20an%20Acute%20Anterior%20Cruciate%20Ligament%20Injury%20and%20Surgical%20Reconstruction&as_occt=title&as_sauthors=%20%22MT%20Kingery%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Wiwie C</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Baumbach J</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Röttger R</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Comparing the performance of
                  biomedical clustering methods</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Nat Methods.</span> 2015 Nov;12(11):1033-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparing%20the%20performance%20of%20biomedical%20clustering%20methods&as_occt=title&as_sauthors=%20%22C%20Wiwie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> McLachlan GJ</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Bean RW</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Ng SK</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Clustering</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Methods Mol Biol.</span>
                2017;1526:345-62.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clustering&as_occt=title&as_sauthors=%20%22GJ%20McLachlan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Cuéllar VG</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Cuéllar JM</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Kirsch T</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Strauss EJ</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Correlation of Synovial Fluid
                  Biomarkers With Cartilage Pathology and Associated Outcomes in Knee Arthroscopy</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2016
                Mar;32(3):475-85.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Correlation%20of%20Synovial%20Fluid%20Biomarkers%20With%20Cartilage%20Pathology%20and%20Associated%20Outcomes%20in%20Knee%20Arthroscopy&as_occt=title&as_sauthors=%20%22VG%20Cu%C3%A9llar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Hamilton JL</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Nagao M</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Levine BR</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Chen D</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Olsen BR</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Im HJ</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Targeting VEGF and Its
                  Receptors for the Treatment of Osteoarthritis and Associated Pain</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">J Bone Miner Res.</span> 2016
                May;31(5):911-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Targeting%20VEGF%20and%20Its%20Receptors%20for%20the%20Treatment%20of%20Osteoarthritis%20and%20Associated%20Pain&as_occt=title&as_sauthors=%20%22JL%20Hamilton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Amano K</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Huebner JL</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Stabler TV</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Tanaka M</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> McCulloch CE</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Lobach I</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Lane NE</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Kraus VB</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Ma CB</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Li X</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Synovial Fluid Profile at the
                  Time of Anterior Cruciate Ligament Reconstruction and Its Association With Cartilage Matrix
                  Composition 3 Years After Surgery</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2018 Mar;46(4):890-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Synovial%20Fluid%20Profile%20at%20the%20Time%20of%20Anterior%20Cruciate%20Ligament%20Reconstruction%20and%20Its%20Association%20With%20Cartilage%20Matrix%20Composition%203%20Years%20After%20Surgery&as_occt=title&as_sauthors=%20%22K%20Amano%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Galland L.</span><span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Diet and inflammation</span>.
                <span data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Nutr Clin Pract.</span>
                2010 Dec;25(6):634-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diet%20and%20inflammation&as_occt=title&as_sauthors=%20%22L.%20Galland%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Daniluk J</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Szuster-Ciesielska A</span>,
                <span data-id="annotation_149" class="annotation" style="position: unset;"> Drabko J</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Kandefer-Szerszeń M</span>.
                <span data-id="strong_23" class="annotation strong" style="position: unset;">Serum cytokine levels in
                  alcohol-related liver cirrhosis</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Alcohol.</span> 2001 Jan;23(1):29-34.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Serum%20cytokine%20levels%20in%20alcohol-related%20liver%20cirrhosis&as_occt=title&as_sauthors=%20%22J%20Daniluk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Bayazit H</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Selek S</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Karababa IF</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Cicek E</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Aksoy N</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Evaluation of
                  Oxidant/Antioxidant Status and Cytokine Levels in Patients with Cannabis Use Disorder</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Clin Psychopharmacol
                  Neurosci.</span> 2017 Aug 31;15(3):237-42.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20Oxidant%2FAntioxidant%20Status%20and%20Cytokine%20Levels%20in%20Patients%20with%20Cannabis%20Use%20Disorder&as_occt=title&as_sauthors=%20%22H%20Bayazit%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Ciftci TU</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Kokturk O</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Bukan N</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Bilgihan A</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The relationship between serum
                  cytokine levels with obesity and obstructive sleep apnea syndrome</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Cytokine.</span> 2004 Oct
                21;28(2):87-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20relationship%20between%20serum%20cytokine%20levels%20with%20obesity%20and%20obstructive%20sleep%20apnea%20syndrome&as_occt=title&as_sauthors=%20%22TU%20Ciftci%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Monibi F</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Roller BL</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Stoker A</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Garner B</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Bal S</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Cook JL</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Identification of Synovial
                  Fluid Biomarkers for Knee Osteoarthritis and Correlation with Radiographic Assessment</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">J Knee Surg.</span> 2016
                Apr;29(3):242-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Identification%20of%20Synovial%20Fluid%20Biomarkers%20for%20Knee%20Osteoarthritis%20and%20Correlation%20with%20Radiographic%20Assessment&as_occt=title&as_sauthors=%20%22F%20Monibi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Boffa A</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Merli G</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Andriolo L</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Lattermann C</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Salzmann GM</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Filardo G</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Synovial Fluid Biomarkers in
                  Knee Osteoarthritis: A Systematic Review and Quantitative Evaluation Using BIPEDs Criteria</span>.
                <span data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Cartilage.</span> 2021
                Dec;13(1_suppl):82S-103S.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Synovial%20Fluid%20Biomarkers%20in%20Knee%20Osteoarthritis%3A%20A%20Systematic%20Review%20and%20Quantitative%20Evaluation%20Using%20BIPEDs%20Criteria&as_occt=title&as_sauthors=%20%22A%20Boffa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Sobue Y</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Kojima T</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Kurokouchi K</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Takahashi S</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Yoshida H</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Poole R</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Ishiguro N</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Prediction of progression of
                  damage to articular cartilage 2 years after anterior cruciate ligament reconstruction: use of aggrecan
                  and type II collagen biomarkers in a retrospective observational study</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Arthritis Res Ther.</span>
                2017 Dec 6;19(1):265.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prediction%20of%20progression%20of%20damage%20to%20articular%20cartilage%202%20years%20after%20anterior%20cruciate%20ligament%20reconstruction%3A%20use%20of%20aggrecan%20and%20type%20II%20collagen%20biomarkers%20in%20a%20retrospective%20observational%20study&as_occt=title&as_sauthors=%20%22Y%20Sobue%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Zou YC</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Chen LH</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Ye YL</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Yang GG</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Mao Z</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Liu DD</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Chen JQ</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Chen JJ</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Liu G</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Attenuated synovial fluid
                  ghrelin levels are linked with cartilage damage, meniscus injury, and clinical symptoms in patients
                  with knee anterior cruciate ligament deficiency</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Discov Med.</span> 2016
                Dec;22(123):325-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Attenuated%20synovial%20fluid%20ghrelin%20levels%20are%20linked%20with%20cartilage%20damage%2C%20meniscus%20injury%2C%20and%20clinical%20symptoms%20in%20patients%20with%20knee%20anterior%20cruciate%20ligament%20deficiency&as_occt=title&as_sauthors=%20%22YC%20Zou%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Bigoni M</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Turati M</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Gandolla M</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Sacerdote P</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Piatti M</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Castelnuovo A</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Franchi S</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Gorla M</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Munegato D</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Gaddi D</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Pedrocchi A</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Omeljaniuk RJ</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Locatelli V</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Torsello A</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Effects of ACL Reconstructive
                  Surgery on Temporal Variations of Cytokine Levels in Synovial Fluid</span>. <span
                  data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Mediators Inflamm.</span>
                2016;2016:8243601.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20ACL%20Reconstructive%20Surgery%20on%20Temporal%20Variations%20of%20Cytokine%20Levels%20in%20Synovial%20Fluid&as_occt=title&as_sauthors=%20%22M%20Bigoni%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Chang JC</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Sebastian A</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Murugesh DK</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Hatsell S</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Economides AN</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Christiansen BA</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Loots GG</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Global molecular changes in a
                  tibial compression induced ACL rupture model of post-traumatic osteoarthritis</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">J Orthop Res.</span> 2017
                Mar;35(3):474-85.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Global%20molecular%20changes%20in%20a%20tibial%20compression%20induced%20ACL%20rupture%20model%20of%20post-traumatic%20osteoarthritis&as_occt=title&as_sauthors=%20%22JC%20Chang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;e4ea1710-bc67-449c-acef-c2c72f6f88ea&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[]}"
                href="/php/content/content_api.php?op=download&rsuite_id=e4ea1710-bc67-449c-acef-c2c72f6f88ea&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 100%; display: none;"></div>
                  <div class="pages" style="position: unset;"><canvas class="page" width="337" height="189"></canvas>
                  </div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;e4ea1710-bc67-449c-acef-c2c72f6f88ea&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=e4ea1710-bc67-449c-acef-c2c72f6f88ea&type=supplement&name=12511"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3De4ea1710-bc67-449c-acef-c2c72f6f88ea%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2330</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01392</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 12, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_32"
                            class="annotation emphasis" style="position: unset;">Investigation performed at NYU Langone
                            Orthopedic Hospital, NYU Langone Health, New York, NY</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_37"
                            class="annotation strong" style="position: unset;">A commentary by Charles J. Gatt Jr.,
                            MD,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=cabf7cd7-1aad-4f85-92b6-f957277cf6f0&native=1"
                              class="article-title" style="position: unset;">Can We Predict Poor Outcomes for
                              Arthroscopic Partial Meniscectomy?<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Commentary on an article by Michael R. Moore, BA, et al.:
                                “Levels of Synovial Fluid Inflammatory Biomarkers on Day of Arthroscopic Partial
                                Meniscectomy Predict Long-Term Outcomes and Conversion to TKA. A 10-Year Mean Follow-up
                                Study”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Gatt,
                              Charles J.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(24):e52 | December 18, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e4ea1710-bc67-449c-acef-c2c72f6f88ea&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=e4ea1710-bc67-449c-acef-c2c72f6f88ea&type=pdf&name=JBJS.23.01392.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=e4ea1710-bc67-449c-acef-c2c72f6f88ea&type=pdf&name=JBJS.23.01392.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_39" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I185"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I185</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e4ea1710-bc67-449c-acef-c2c72f6f88ea&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=e4ea1710-bc67-449c-acef-c2c72f6f88ea&type=zip&name=JBJS.23.01392.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael R. Moore, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2748-9988" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2748-9988</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brittany DeClouette, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3403-3970" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3403-3970</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Isabel Wolfe, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0483-1867" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0483-1867</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew T. Kingery, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7627-775X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7627-775X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Carlos Sandoval-Hernandez, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-1047-9560" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-1047-9560</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ryan Isber, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-4854-1079" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-4854-1079</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Thorsten Kirsch, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0184-9220" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0184-9220</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eric J. Strauss, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Eric.Strauss@nyulangone.org" class=""
                    style="position: unset;">Eric.Strauss@nyulangone.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9371-7649" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9371-7649</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">NYU Langone Orthopedic Hospital, NYU Langone Health, New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Biomedical Engineering, NYU Tandon School of Engineering, New
                York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 31556.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
