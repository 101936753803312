import type { Article } from "../../../../../types";

export const Article583: Article = {
	id: 583,
	rsuiteId: "aeac6603-350a-42e5-9d39-0b042163a9b9",
	type: "the orthopaedic forum",
	title: "What's Important (Arts & Humanities): The Silent Teacher",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=aeac6603-350a-42e5-9d39-0b042163a9b9&type=jpeg&name=JBJS.24.00262f1",
	subSpecialties: ["hand_wrist"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s Important (Arts & Humanities): The Silent Teacher
              </div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Eve R. Glenn,
                      ScB</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jay S.
                      Reidler, MD, MPH</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 1 more
                      contributor</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Each autumn at The Johns Hopkins University School of
                  Medicine, the Department of Orthopaedic Surgery provides first-year medical students with a unique,
                  hands-on surgical skills education experience as part of their gross anatomy course. Under the
                  guidance of orthopaedic residents and faculty, students perform procedures on cadavers, including
                  plate fixation (distal radial and fibular), fasciotomy, and carpal tunnel release. Initiated in 2015,
                  these sessions introduce students to how surgeons think about anatomy. The sessions aim to enhance
                  student understanding of musculoskeletal anatomy by demonstrating the clinical relevance of the
                  information that the students are learning. In addition, the exercise provides students with early
                  exposure to the field of orthopaedic surgery while offering the orthopaedic residents an opportunity
                  to practice their teaching skills.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Throughout the 4-year curriculum, the medical students are
                  encouraged to be introspective about their education as part of the formation of their professional
                  identity<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">2</span></a>. Reflection may be especially important surrounding cadaver
                  dissection, which can elicit complex emotions<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a>. The
                  cadaver has been referred to as the medical students’ “silent teacher.”<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a> Careful reflection about the experience and the persons who
                  donated their bodies for student education may contribute to students’ empathy and appreciation for
                  human dignity<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">3</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">In the poem entitled “What you give,” first-year medical
                  student and aspiring orthopaedic surgeon <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Eve Glenn</a>
                  thoughtfully reflects on her experience in the skills laboratory described above. She honors the
                  cadaver, expressing gratitude for the gift, while appreciating how the knowledge and surgical skills
                  that she gains will help her restore function in future patients. We encourage all current and future
                  orthopaedic surgeons to define their roles with introspection and compassion, recognizing the critical
                  impact that they have on patient care. The poem below aims to reinforce this ethos.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label"></div>
                <div class="image-download" name="JBJS.24.00262f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=aeac6603-350a-42e5-9d39-0b042163a9b9&type=jpeg&name=JBJS.24.00262f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Photograph of Eve R. Glenn, ScB.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">What you
                give</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node verse-group" data-id="verse_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node verse-line" data-id="verse_line_1" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Screws
                    fastened, volar plate secured</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_2" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">For someone
                    who will not grasp the “cure.”</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_3" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Knowledge
                    gained from generous grace.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_4" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">In the gleam
                    of steel and the shimmer of titanium,</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_5" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">I see
                    reflections of courage, of resilience, of kindness.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_6" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">As my journey
                    in medicine starts,</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_7" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">I ponder the
                    depth of “patients” passed.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_8" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Who were they,
                    what was their story?</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_9" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Appreciation
                    overcomes me, a profound sensation,</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_10" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">For those who
                    have entrusted budding doctors to learn.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_11" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">The ultimate
                    gift, provided with an intention so true.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_12" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">How do I
                    convey my infinite gratitude</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_13" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">For the
                    patients who walked and those who’ve yet to tread,</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_14" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Guiding my
                    path with their gift so that I can learn?</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_15" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Each screw a
                    testament, a story in its own right,</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_16" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Anchors of
                    hope.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_17" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">As they
                    restore function, breath by breath,</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_18" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">A life is
                    renewed beyond the shadows of immobility.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_19" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Bidding
                    farewell to my anatomy donor,</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_20" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">I feel
                    overwhelmed with thankful reverence.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_21" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Teaching,
                    guiding,</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_22" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Discernment of
                    once-textbook images.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_23" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">An intricate
                    first dance of what it means to restore.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_24" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">The first
                    glimpse into my future story.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_25" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">My hands as
                    tools,</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_26" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Gifts of
                    innovation, a shared purpose.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_27" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">From the
                    stainless steel,</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_28" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">A murmur so
                    subtle,</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_29" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Whispering
                    tales of hope into my delicate ears.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Tackett S</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Wright S</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Colbert-Getz J</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Shochet R</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Associations between learning
                  community engagement and burnout, quality of life, and empathy among medical students</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Int J Med Educ.</span> 2018
                Nov 30;9:316-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Associations%20between%20learning%20community%20engagement%20and%20burnout%2C%20quality%20of%20life%2C%20and%20empathy%20among%20medical%20students&as_occt=title&as_sauthors=%20%22S%20Tackett%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;">Johns
                Hopkins School of Medicine. <span data-id="strong_2" class="annotation strong"
                  style="position: unset;">College Advisory Program - About Us</span>. Accessed 2024 Feb 20. <a
                  href="https://www.hopkinsmedicine.org/som/education-programs/md-program/college-advisory-program/about-us"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.hopkinsmedicine.org/som/education-programs/md-program/college-advisory-program/about-us</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=College%20Advisory%20Program%20-%20About%20Us&as_occt=title&as_sauthors=%20%22Johns%20Hopkins%20School%20of%20Medicine%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Zuo SW</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Cichowitz C</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Shochet R</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Venkatesan A</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Peer-led, postanatomy reflection
                  exercise in dissection teams: curriculum and training materials</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">MedEdPORTAL.</span> 2017 Mar
                30;13:10565.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Peer-led%2C%20postanatomy%20reflection%20exercise%20in%20dissection%20teams%3A%20curriculum%20and%20training%20materials&as_occt=title&as_sauthors=%20%22SW%20Zuo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Leeper BJ</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Grachan JJ</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Robinson R</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Doll J</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Stevens K</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Honoring human body donors: Five
                  core themes to consider regarding ethical treatment and memorialization</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Anat Sci Educ.</span> 2024
                Apr-May;17(3):483-98.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Honoring%20human%20body%20donors%3A%20Five%20core%20themes%20to%20consider%20regarding%20ethical%20treatment%20and%20memorialization&as_occt=title&as_sauthors=%20%22BJ%20Leeper%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Daeac6603-350a-42e5-9d39-0b042163a9b9%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2170</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00262</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 14, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;aeac6603-350a-42e5-9d39-0b042163a9b9&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=aeac6603-350a-42e5-9d39-0b042163a9b9&type=pdf&name=JBJS.24.00262.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=aeac6603-350a-42e5-9d39-0b042163a9b9&type=pdf&name=JBJS.24.00262.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_5" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_6"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I56" target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I56</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;aeac6603-350a-42e5-9d39-0b042163a9b9&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=aeac6603-350a-42e5-9d39-0b042163a9b9&type=zip&name=JBJS.24.00262.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eve R. Glenn, ScB<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-4056-8943" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-4056-8943</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jay S. Reidler, MD, MPH<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9615-5351" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9615-5351</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Richard A. Schaefer, MD, MPH<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8633-8365" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8633-8365</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">The Johns Hopkins University School of Medicine, Baltimore, Maryland</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Icahn School of Medicine at Mount Sinai, New
                York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, The Johns Hopkins University, Baltimore,
                Maryland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5257.48px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
