import fetchQueryWrapper from "@helpers/relay-fetch-query-wrapper";
/* -----------------Globals--------------- */
import { graphql } from "react-relay";
import type { Category, ExamDetails } from "../types";

type Response = {
	examList: ExamDetails[];
};
const query = graphql`
  query FetchCategoriesQuery($tenantId: ID, $appId: ID) {
    examList(tenantId: $tenantId, appId: $appId) {
      id
      exam {
        id
        category {
          id
          name
          icon
        }
      }
    }
  }
`;

const fetchCategories = async (
	tenantId: string,
	appId: string,
): Promise<Category[]> => {
	const variables = {
		tenantId,
		appId,
	};
	const { examList } = (await fetchQueryWrapper(query, variables)) as Response;
	const categories = examList
		.flatMap((exam) => exam.exam.category)
		.reduce((unique, category) => {
			return unique.find((item) => item.id === category.id)
				? unique
				: // biome-ignore lint/performance/noAccumulatingSpread: // TODO fix me later
					[...unique, category];
		}, [] as Category[]);

	return categories;
};

export { fetchCategories };
