import type { Article } from "../../../../../types";

export const Article552: Article = {
	id: 552,
	rsuiteId: "4844781f-f971-49f6-8381-ff58739ecd94",
	type: "scientific article",
	title:
		"Outcomes of Obese Patients Undergoing Primary Total Knee Arthroplasty",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4844781f-f971-49f6-8381-ff58739ecd94&type=jpeg&name=JBJS.23.01413f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Outcomes of Obese Patients Undergoing Primary Total Knee
                Arthroplasty</div>
              <div class="text subtitle" style="position: unset;">Trends Over 30 Years</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Mason E.
                      Uvodich, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Evan M.
                      Dugdale, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Background:</span> The rates of obesity among patients undergoing
                        total knee arthroplasty (TKA) have substantially increased. In addition, obesity is a
                        well-established risk factor for complications after TKA. The purpose of this study was to
                        analyze trends in body mass index (BMI) and complication risk among obese patients undergoing
                        primary TKA treated at a single institution over 3 decades.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Methods:</span> Utilizing an institutional total joint registry,
                        13,919 primary TKAs performed to treat osteoarthritis between 1990 and 2019 were identified.
                        Patients were stratified by BMI according to the World Health Organization (WHO) classification
                        into 3 groups: non-obese (BMI, &lt;30 kg/m<span data-id="superscript_1"
                          class="annotation superscript" style="position: unset;">2</span>), WHO Class-I and II obese
                        (BMI, 30 to 39.9 kg/m<span data-id="superscript_2" class="annotation superscript"
                          style="position: unset;">2</span>), and WHO Class-III obese (BMI, ≥40 kg/m<span
                          data-id="superscript_3" class="annotation superscript" style="position: unset;">2</span>).
                        Trends in BMI and survivorship free from reoperation, revision, and periprosthetic joint
                        infection (PJI) were analyzed over time while controlling for age, sex, and the Charlson
                        Comorbidity Index.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Results:</span> Over the study period, there was a 90% increase in
                        the prevalence of Class-II obesity (13% to 25%) and a 300% increase in Class-III obesity (3% to
                        12%). Analysis of the entire cohort demonstrated a decrease in the 2-year risk of any
                        reoperation, any revision, and PJI (p &lt; 0.05 for all) with time. The risk decreased
                        significantly over time for non-obese patients for any reoperation (p = 0.029) and any revision
                        (p = 0.004) and for Class-III obese patients for any reoperation (p = 0.038) and any revision (p
                        = 0.012), but it remained stable for Class-I and II obese patients. The risk of PJI decreased
                        from 1990 to 2019 for non-obese patients (p = 0.005), but there were no significant changes in
                        PJI risk for any obesity group.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Despite increasing rates of obesity among our
                        patients who underwent TKA, we observed decreasing risks of reoperation, revision, and PJI over
                        time. The risks of reoperation and revision declined among non-obese patients, remained stable
                        for Class-I and II obese patients, and declined for Class-III obese patients. The PJI risk
                        declined for non-obese patients over the study period, but no such decline occurred for any
                        category of obesity. Despite the decreasing risks of reoperation and revision, further work is
                        needed to improve PJI risk in obese patients.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">In the United States, the prevalence of obesity is
                  increasing over time and is expected to affect almost 1 in 2 adults by 2030<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">2</span></a>.
                  Additionally, the proportion of individuals with a body mass index (BMI) of ≥35 kg/m<span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">2</span> is expected
                  to rise<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">2</span></a>.
                  Obesity is a demonstrated risk factor for osteoarthritis, and the proportion of individuals undergoing
                  total knee arthroplasty (TKA) who are obese is increasing over time<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Complications including periprosthetic joint infection
                  (PJI), reoperation, and revision have increased among obese patients who undergo TKA, and the
                  magnitude of obesity appears to have had a dose-related effect on the risk of postoperative
                  complications<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">11</span></a>. As a result, the proportion of patients undergoing
                  revision TKA who are obese is also increasing because of their elevated risk and the aforementioned
                  epidemiology<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">14</span></a>. Despite this risk profile, obese patients can benefit
                  greatly from TKA and have documented improvements in pain and function consistent with those of their
                  non-obese counterparts<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">16</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Prior studies have demonstrated improvements in mortality
                  rates, hospital length of stay, blood transfusions, readmissions, and 90-day complication rates
                  following primary TKA over the last 10 to 20 years<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">21</span></a>. However, most of the literature to date has focused on
                  early postoperative time periods, and very few studies have evaluated trends in arthroplasty-specific
                  outcomes over time. Furthermore, it is not clear whether the growing population of obese patients
                  undergoing TKA benefited equally from improvements in TKA care intended to reduce postoperative
                  complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The purpose of this study was to analyze trends in BMI and
                  postoperative complications among patients who underwent primary TKA and were treated at a single
                  institution over 3 decades. Specifically, we sought to report trends in TKA-specific outcomes
                  including reoperations, revisions, and PJI. We hypothesized that the prevalence of obesity among
                  patients who underwent primary TKA at our institution has increased over time and that modern
                  techniques and perioperative optimization protocols have helped to mitigate a rise in postoperative
                  complications despite increasing levels of obesity within the population undergoing TKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Utilizing a total joint registry (TJR) from a single
                  institution, we identified 17,229 primary TKAs performed between 1990 and 2019. From this group, we
                  excluded bilateral same-day TKAs (2,737 TKAs), TKAs performed for non-primary osteoarthritis
                  indications (e.g., inflammatory arthritis) (556 TKAs), and TKAs performed in patients with missing or
                  inaccurate BMIs (17 TKAs), leaving 13,919 primary TKAs (11,401 patients) for review (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). The study period was decided on the basis of the availability
                  of BMI data and a minimum potential 2-year follow-up. Institutional review board approval was obtained
                  prior to the initiation of the study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01413f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4844781f-f971-49f6-8381-ff58739ecd94&type=jpeg&name=JBJS.23.01413f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;">Identification of included TKAs and subsequent
                          classification by BMI cohort. OA = osteoarthritis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Patients were classified as non-obese if their BMI was
                  &lt;30 kg/m<span data-id="superscript_12" class="annotation superscript"
                    style="position: unset;">2</span>, and obese patients were classified according to the World Health
                  Organization (WHO) definitions as Class I (BMI, ≥30 but &lt;35 kg/m<span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">2</span>), Class II (BMI, ≥35 but &lt;40
                  kg/m<span data-id="superscript_14" class="annotation superscript" style="position: unset;">2</span>),
                  and Class III (BMI, ≥40 kg/m<span data-id="superscript_15" class="annotation superscript"
                    style="position: unset;">2</span>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Demographic Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The mean patient age was 69 years (range, 29 to 99 years).
                  Fifty-nine percent of patients were female. The mean BMI was 33 kg/m<span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">2</span> (range, 16 to 69 kg/m<span
                    data-id="superscript_17" class="annotation superscript" style="position: unset;">2</span>).
                  Sixty-one percent of patients were obese; of these patients, 30% were WHO Class-I obese, 18% were
                  Class-II obese, and 13% were Class-III obese (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). Within
                  2 years of the index TKA, 225 patients (239 TKAs) had died. Of the remainder, 1,178 patients (1,266
                  TKAs) had &lt;2 years of follow-up. Of the patients with at least 2 years of follow-up, the mean
                  follow-up was 7 years (range, 2 to 30 years).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Characteristics and Charlson Comorbidity
                          Index (CCI) by Time Period for TKA Cases</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2275px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Non-Obese
                          Group (N = 5,435)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Class-I
                          and II Obese Group (N = 6,631)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Class-III
                          Obese Group (N = 1,853)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patient
                          characteristics</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age at surgery <span
                            data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mean<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72.3 ±
                          8.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.7 ±
                          8.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.8 ±
                          8.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Range</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29 to
                          99</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31 to
                          94</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32 to
                          91</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> BMI <span
                            data-id="emphasis_32" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_33" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_18" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_18"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_34"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mean<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.6 ±
                          2.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.1 ±
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45.1 ±
                          4.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Median</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">26.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">33.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">43.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Range</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.8 to
                          29.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30 to
                          39.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40 to
                          68.9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CCI by time period<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1990 to 1994<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">738</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">613</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mean CCI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of 0 to 2<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">80.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">78.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of 3 to 5<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of ≥6<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1995 to 1999<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">618</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">516</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mean CCI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of 0 to 2<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">75.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">69.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">72.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of 3 to 5<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.2%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of ≥6<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2000 to 2004<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">490</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">468</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">147</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mean CCI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of 0 to 2<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">63.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">58.4%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of 3 to 5<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">23.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">26.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of ≥6<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2005 to 2009<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">777</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,073</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">309</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mean CCI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of 0 to 2<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">64.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">54.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of 3 to 5<span
                            data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">21.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">23.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">29.8%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of ≥6<span
                            data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2010 to 2014<span
                            data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,392</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,831</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">621</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mean CCI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of 0 to 2<span
                            data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">60.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">58.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of 3 to 5<span
                            data-id="table_footnote_reference_22" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">23.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">25.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">27.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of ≥6<span
                            data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2015 to 2019<span
                            data-id="table_footnote_reference_24" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,420</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,130</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">627</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mean CCI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of 0 to 2<span
                            data-id="table_footnote_reference_25" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">58.4%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of 3 to 5<span
                            data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">24.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">24.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">26.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CCI of ≥6<span
                            data-id="table_footnote_reference_27" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.1%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">CCI = non-age-adjusted Charlson Comorbidity Index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the number of TKA cases.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">The values are given as the percentage of patients in each score
                        category.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Reoperations
                and Revisions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Complications occurring within 2 years postoperatively
                  were identified and stratified into the following categories: any reoperation, any revision, any
                  aseptic revision, and PJI. Manual chart review identified primary indications for revision TKA, and
                  only revisions with confirmed indications were used in the aseptic revision analysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The percentages of WHO Class-I obese, Class-II obese,
                  Class-III obese, and non-obese patients were compiled by the year of the surgical procedure. Ordinal
                  logistic regression investigated for trends over time in the odds of a patient belonging to each
                  group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Five-year increments (e.g., 1990 through 1994, 1995
                  through 1999, and so forth) were designated for the cohort, and the estimated 2-year survival free of
                  any reoperation, any revision, any aseptic revision, and PJI following the index TKA were calculated
                  within each time frame. Any changes in the risk of these outcomes over time was investigated utilizing
                  Cox proportional hazards regression analysis while controlling for age, sex, and the Charlson
                  Comorbidity Index. Note that hazard ratios (HRs) are reported as the value per year (e.g., an HR of
                  0.98 reflects a decrease of 0.02, or 2%, per year rather than over 30 years). Patient death and
                  patient loss to follow-up were treated as censored events and were taken into account during the
                  regression and survival analyses. Kaplan-Meier survival analysis was utilized to evaluate outcome
                  measures over time within each obesity class. Both the Kaplan-Meier survival estimates and the Cox
                  proportional hazards regression analyses stratified BMI into 3 separate groups: non-obese patients
                  (BMI, &lt;30 kg/m<span data-id="superscript_19" class="annotation superscript"
                    style="position: unset;">2</span>), WHO Class-I and II patients (BMI, ≥30 but &lt;40 kg/m<span
                    data-id="superscript_20" class="annotation superscript" style="position: unset;">2</span>), and WHO
                  Class-III patients (BMI, ≥40 kg/m<span data-id="superscript_21" class="annotation superscript"
                    style="position: unset;">2</span>). The entire cohort was also analyzed as a group. All statistical
                  analysis was performed using Stata (version 17; StataCorp).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Trends in
                Obesity Over Time</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Over the study period, the prevalence of patient
                  non-obesity decreased by 34% (from 50% to 33%) and Class-I obesity decreased by 13% (34% to 30%).
                  Conversely, the prevalence of Class-II obesity increased by 90% (13% to 25%) and the prevalence of
                  Class-III obesity increased by 300% (3% to 12%). Overall, the odds of a patient being in an obesity
                  category relative to the non-obese category significantly increased with each decade (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01413f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4844781f-f971-49f6-8381-ff58739ecd94&type=jpeg&name=JBJS.23.01413f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">The percentage of non-obese patients and patients
                          in each WHO obesity class undergoing TKA from 1990 to 2019. Y axes represent the percentage of
                          patients undergoing TKA within each respective BMI category. X axes represent the year. Over
                          the 30-year study period, the proportion of patients undergoing TKA changed as follows:
                          non-obese patients decreased from 50% to 33%, Class-I patients decreased from 34% to 30%,
                          Class-II patients increased from 13% to 25%, and Class-III patients increased from 3% to 12%.
                          Ordinal logistic regression demonstrated that, with each 10-year time period, the odds of
                          being in Classes I, II, and III relative to the non-obese category increased by 1.19 (95% CI,
                          1.14 to 1.25; p &lt; 0.0001) for Class I, 1.55 (95% CI, 1.46 to 1.65; p &lt; 0.0001) for Class
                          II, and 1.7 (95% CI, 1.59 to 1.82; p &lt; 0.0001) for Class III.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Reoperations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">There were 441 TKAs (3.2%) that underwent reoperation
                  within 2 years following the index TKA. The entire cohort of patients experienced a significant
                  decrease in the 2-year risk of reoperation during the study period (HR, 0.98 [95% confidence interval
                  (CI), 0.97 to 0.99]; p = 0.002). Non-obese patients (HR, 0.98 [95% CI, 0.96 to 0.99]; p = 0.029) and
                  Class-III obese patients (HR, 0.95 [95% CI, 0.91 to 0.99]; p = 0.038) experienced a significant
                  reduction in the 2-year risk of reoperation over the study period. Class-I and II obese patients (HR,
                  0.99 [95% CI, 0.97 to 1.0]; p = 0.073) did not experience a significant change in the risk of
                  reoperation within 2 years over the study period (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Cox Proportional Hazards Regression Analysis of
                          Association Between 2-Year Outcomes and Year of Surgery</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1434px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">TKA Cases
                          at Risk<span data-id="table_footnote_reference_28" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Events<span data-id="table_footnote_reference_29" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Hazard
                          Ratio<span data-id="table_footnote_reference_30" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_31" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Entire cohort</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13,919</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Reoperation<span
                            data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">441</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98 (0.97
                          to 0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_37" class="annotation strong" style="position: unset;">0.002</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Any revision<span
                            data-id="table_footnote_reference_33" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">205</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.97 (0.96
                          to 0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_38" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Aseptic revision</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.96 (0.94
                          to 0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_39" class="annotation strong" style="position: unset;">0.002</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PJI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.97 (0.95
                          to 0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_40" class="annotation strong" style="position: unset;">0.017</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Non-obese group</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5,435</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Reoperation<span
                            data-id="table_footnote_reference_34" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">136</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98 (0.96
                          to 0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_41" class="annotation strong" style="position: unset;">0.029</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Any revision<span
                            data-id="table_footnote_reference_35" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.96 (0.94
                          to 0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_42" class="annotation strong" style="position: unset;">0.004</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Aseptic revision</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98 (0.94
                          to 1.02)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.257</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PJI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.95 (0.92
                          to 0.98)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_43" class="annotation strong" style="position: unset;">0.005</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Class-I and II obese
                          groups</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6,631</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Reoperation<span
                            data-id="table_footnote_reference_36" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">224</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.99 (0.97
                          to 1.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.073</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Any revision<span
                            data-id="table_footnote_reference_37" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98 (0.96
                          to 1.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.116</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Aseptic revision</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.96 (0.93
                          to 0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_44" class="annotation strong" style="position: unset;">0.011</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PJI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.99 (0.96
                          to 1.02)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.604</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Class-III obese
                          groups</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,853</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Reoperation<span
                            data-id="table_footnote_reference_38" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.95 (0.91
                          to 0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_45" class="annotation strong" style="position: unset;">0.038</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Any revision<span
                            data-id="table_footnote_reference_39" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.95 (0.91
                          to 0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_46" class="annotation strong" style="position: unset;">0.012</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Aseptic revision</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.93 (0.87
                          to 0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_47" class="annotation strong" style="position: unset;">0.023</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PJI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.95 (0.90
                          to 1.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.095</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Total number of TKA cases at risk over the 30-year study period.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Total number of the specified event within 2 years after the index
                        operation over the 30-year study period.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the hazard ratio, with the 95% CI in
                        parentheses. The Cox proportional hazards regression took into account death and loss to
                        follow-up as censored events. The hazard ratio is the relative change in risk of a given event
                        with increasing time (e.g., 0.98 indicates an approximately 2% relative decrease in risk each
                        year over the 30-year study period).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Significant values are shown in bold.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Any reoperation other than manipulation under anesthesia.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">**</b> <span class="text"
                        style="position: unset;">There were a total of 7 revisions without a confirmed indication for
                        revision (most commonly this occurred when the patient notified the registry of revision and no
                        detailed operative note was available for review). These 7 revisions were excluded from the
                        aseptic revision analysis, as we could not confirm whether they were aseptic or septic
                        revisions. Thus, the “any revision” total may not reflect the sum of aseptic revisions and
                        revisions for PJI.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Revisions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">There were 205 revisions (1.5%) within 2 years
                  postoperatively. Of those 205 revisions, the most common indication for revision was PJI (100 [49%]),
                  followed by aseptic loosening (28 [14%]) and instability (27 [13%]) (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). The entire cohort of patients experienced a significant
                  decrease in the 2-year revision risk over the study period (HR, 0.97 [95% CI, 0.96 to 0.99]; p &lt;
                  0.001). Analyzed separately, there was a significant decrease in the 2-year risk of any revision among
                  non-obese patients (HR, 0.96 [95% CI, 0.94 to 0.99]; p = 0.004) and Class-III obese patients (HR, 0.95
                  [95% CI, 0.91 to 0.99]; p = 0.012), but not among Class-I and II obese patients combined (HR, 0.98
                  [95% CI, 0.96 to 1.0]; p = 0.116) (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Primary Indications for Any Revision Within 2 Years
                          of the Index TKA by WHO Obesity Class<span data-id="table_footnote_reference_42"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 504px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Primary Indication</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Non-Obese</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Class
                          I</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Class
                          II</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Class
                          III</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">All
                          Patients</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wound complication<span
                            data-id="table_footnote_reference_40" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PJI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Instability</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patellar
                          maltracking</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Periprosthetic
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Other<span
                            data-id="table_footnote_reference_41" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">205</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number of TKA cases.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Acute hemarthrosis with polyethylene exchange and traumatic wound
                        dehiscence with polyethylene exchange.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Arthrofibrosis, extensor mechanism issues, malalignment, exploratory
                        surgery, and 7 TKA cases in which the revision indication could not be confirmed on the basis of
                        the available documentation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Analysis of only aseptic revisions demonstrated a decrease
                  in the 2-year risk of any aseptic revision for the entire cohort (HR, 0.96 [95% CI, 0.94 to 0.99]; p =
                  0.002), Class-I and II obese patients (HR, 0.96 [95% CI, 0.93 to 0.99]; p = 0.011), and Class-III
                  obese patients (HR, 0.93 [95% CI, 0.87 to 0.99]; p = 0.023). There were no significant changes over
                  time in the incidence of aseptic revision within 2 years for the non-obese patients (HR, 0.98 [95% CI,
                  0.94 to 1.02]; p = 0.257).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">PJI</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The risk of PJI within 2 years of the primary TKA
                  decreased significantly over the study period for the entire cohort of patients (HR, 0.97 [95% CI,
                  0.95 to 0.99]; p = 0.017). There was also a significant decrease in risk of PJI over time among
                  non-obese patients (HR, 0.95 [95% CI, 0.92 to 0.98]; p = 0.005) but a stable PJI risk over time for
                  Class-I and II obese patients (HR, 0.99 [95% CI, 0.96 to 1.02]; p = 0.604) and Class-III obese
                  patients (HR, 0.95 [95% CI, 0.90 to 1.01]; p = 0.095) (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Kaplan-Meier
                Survival Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Kaplan-Meier survival estimates, which provide an
                  estimation on the absolute change in survival free of specific outcomes in 5-year intervals and
                  complement the regression data, are shown in <a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Kaplan-Meier Estimated 2-Year Survival Free of
                          Specific Complications by Year of Surgery in 5-Year Increments</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 2250.51px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.875);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">1990 to
                          1994</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">1995 to
                          1999</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">2000 to
                          2004</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">2005 to
                          2009</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">2010 to
                          2014</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">2015 to
                          2019</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Reoperation<span
                            data-id="table_footnote_reference_43" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="table_footnote_reference_44"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Entire cohort</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95.9 (94.6
                          to 96.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96.6 (95.4
                          to 97.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95.5 (94.1
                          to 96.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96.9 (96.0
                          to 97.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96.7 (96.1
                          to 97.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.0 (96.4
                          to 97.5)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-obese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.0 (95.5
                          to 98.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96.1 (94.1
                          to 97.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96.9 (94.7
                          to 98.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.6 (96.2
                          to 98.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.8 (96.9
                          to 98.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.6 (96.6
                          to 98.3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class I and II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95.1 (93.0
                          to 96.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.4 (95.5
                          to 98.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94.5 (91.9
                          to 96.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96.5 (95.2
                          to 97.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96.5 (95.5
                          to 97.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96.8 (95.8
                          to 97.5)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88.3 (75.9
                          to 94.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95.6 (88.6
                          to 98.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94.4 (89.1
                          to 97.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96.3 (93.4
                          to 97.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94.7 (92.6
                          to 96.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96.4 (94.5
                          to 97.7)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Any revision<span
                            data-id="table_footnote_reference_45" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Entire cohort</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.7 (96.7
                          to 98.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.8 (96.8
                          to 98.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.6 (97.7
                          to 99.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.7 (98.1
                          to 99.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.5 (98.1
                          to 98.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.7 (98.2
                          to 99.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-obese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.2 (96.9
                          to 98.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.5 (95.9
                          to 98.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.4 (98.0
                          to 99.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.8 (97.7
                          to 99.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.0 (98.3
                          to 99.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.2 (98.5
                          to 99.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class I and II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.6 (96.0
                          to 98.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.0 (96.4
                          to 98.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.2 (96.5
                          to 99.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.7 (97.8
                          to 99.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.5 (97.8
                          to 99.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.4 (97.7
                          to 98.9)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90.6 (78.9
                          to 96.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.9 (92.6
                          to 99.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.2 (93.0
                          to 99.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.3 (96.0
                          to 99.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.5 (95.9
                          to 98.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.2 (96.6
                          to 99.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic revision<span
                            data-id="table_footnote_reference_46" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Entire cohort</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.9 (98.2
                          to 99.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.7 (97.8
                          to 99.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.1 (98.3
                          to 99.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.6 (99.2
                          to 99.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.4 (99.1
                          to 99.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.4 (99.1
                          to 99.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-obese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.3 (98.3
                          to 99.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.7 (97.3
                          to 99.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.8 (98.5
                          to 100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100
                          (undefined)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.7 (99.2
                          to 99.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.4 (98.7
                          to 99.7)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class I and II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.8 (97.5
                          to 99.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.4 (96.8
                          to 99.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.4 (96.8
                          to 99.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.4 (98.7
                          to 99.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.3 (98.7
                          to 99.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.5 (99.0
                          to 99.7)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94.2 (83.2
                          to 98.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100
                          (undefined)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.6 (94.6
                          to 99.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.0 (97.0
                          to 99.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.3 (98.2
                          to 99.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.4 (98.3
                          to 99.8)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PJI<span
                            data-id="table_footnote_reference_47" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Entire cohort</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.8 (98.0
                          to 99.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.9 (98.1
                          to 99.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.2 (98.4
                          to 99.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.0 (98.4
                          to 99.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.3 (98.9
                          to 99.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.2 (98.9
                          to 99.4)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-obese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.9 (97.8
                          to 99.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.8 (97.6
                          to 99.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.4 (98.0
                          to 99.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.0 (97.9
                          to 99.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.4 (98.8
                          to 99.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.8 (99.3
                          to 99.9)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class I and II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.8 (97.6
                          to 99.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.2 (97.9
                          to 99.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.1 (97.7
                          to 99.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.9 (98.0
                          to 99.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.4 (98.9
                          to 99.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.0 (98.4
                          to 99.3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96.2 (85.4
                          to 99.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97.8 (94.5
                          to 99.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.6 (94.5
                          to 99.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99.3 (97.4
                          to 99.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.7 (97.4
                          to 99.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.7 (97.3
                          to 99.4)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. at risk</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Entire cohort</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,406</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,228</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,105</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,159</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,844</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4,177</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-obese</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">738</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">618</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">490</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">777</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,392</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,420</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class I and II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">613</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">516</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">468</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,073</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,831</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,130</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">147</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">309</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">621</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">627</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_14" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the estimated 2-year survival free of the event
                        of interest, with the 95% CI in parentheses. (If no event occurred during a given time period
                        for a specific obesity class group, no CI could be calculated.) The data presented estimate the
                        survival free of an event. An alternative way to interpret these data would be to take 100%
                        minus the survival free of an event; this provides the estimated incidence of a specific event
                        within 2 years of the index TKA. For example, the estimated survival free from PJI for the
                        non-obese cohort between 2015 and 2019 was 99.8% (95% CI, 99.3% to 99.9%), so the estimated
                        2-year incidence of PJI in this group was 0.2% (95% CI, 0.1% to 0.7%).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_15" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Excluding manipulation under anesthesia.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Over the 30-year study period, the proportion of patients
                  undergoing primary TKA at our institution who were obese increased significantly, with the largest
                  increases observed in the highest BMI groups. Nonetheless, we did observe a decrease in the risks of
                  any reoperation, any revision, and PJI for our entire cohort. Comparing BMI subgroups, we identified
                  decreases in the risk of any reoperation within 2 years of the surgical procedure for both non-obese
                  and Class-III obese patients. The risk of revision similarly decreased among all patient groups except
                  for Class-I and II obese patients. Although the PJI risk decreased over time for non-obese patients,
                  it was unchanged for all categories of obesity throughout the study period.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The BMI distribution of our study population was similar
                  to that in prior primary TKA studies that showed approximately 50% of patients meeting the criteria
                  for Class-I and II obesity and 10% to 15% of patients meeting the criteria for Class-III obesity<a
                    href="" data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">24</span></a>. Additionally, the prevalence of obesity among our patients
                  who underwent primary TKA had increased over the last 3 decades, in accordance with the predicted rise
                  in the prevalence of obesity in the general population<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_23" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">2</span></a>. In 2019, the final year of our study, 55% of our patients
                  who underwent TKA were Class-I or II obese and an additional 12% of our patients were Class-III obese.
                  If the trends in obesity among patients who undergo primary TKA projected by Carender et al. remain
                  accurate, then arthroplasty surgeons in the United States can expect that approximately 7 of 10
                  patients who undergo primary TKA in 2029 will be obese<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">3</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Over the study period, the 2-year reoperation risk
                  declined for the entire cohort despite increasing patient BMI and more patients in the higher obesity
                  groups. Non-obese patients and Class-III obese patients had a significant decrease in reoperations,
                  whereas Class-I and II obese patients trended toward a reduction in reoperation risk. These findings
                  may have been due to a greater recognition of BMI as a risk factor for reoperation and the
                  implementation of risk mitigation strategies<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">11</span></a>. Previous studies have demonstrated that preoperative risk
                  stratification followed by intervention may help to reduce 90-day complications in obese patients
                  undergoing primary TKA<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">25</span></a>.
                  Although we were unable to objectively quantify the degree to which preoperative medical optimization
                  occurred for obese patients over time, recent changes within our group’s practice have promoted a
                  multidisciplinary approach to preoperative optimization for at-risk patients. Additionally, the use of
                  tranexamic acid, standardized perioperative protocols, and a focus on early mobilization and shorter
                  length of stay may all have contributed to outcome optimization. Our reduction in reoperation can also
                  be compared with that in the literature. DeMik et al. similarly evaluated TKA outcomes in morbidly
                  obese patients (BMI, ≥40 kg/m<span data-id="superscript_27" class="annotation superscript"
                    style="position: unset;">2</span>) over the last decade within the American College of Surgeons
                  National Surgical Quality Improvement Program (ACS NSQIP) database and found that the rate of
                  reoperation remained stable for morbidly obese patients over time and declined for non-morbidly obese
                  patients (BMI, &lt;40 kg/m<span data-id="superscript_28" class="annotation superscript"
                    style="position: unset;">2</span>)<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">22</span></a>.
                  These findings were similar to those of the present study in that non-morbidly obese patients had a
                  reduction in reoperations rates over time, but the results differed in that DeMik et al. did not
                  identify the improvement in reoperation rates for Class-III obese patients that was found in the
                  current study. However, DeMik et al. were only able to evaluate trends over an 8-year period and
                  capture 30-day reoperation rates, hindering a direct comparison between these studies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The risk of revision TKA within 2 years of the surgical
                  procedure decreased significantly over time for the entire cohort and for non-obese and Class-III
                  obese patients in this study. The most common indication for revision in this study was PJI, which
                  accounted for 49% of all revision procedures. This was followed by aseptic loosening and instability,
                  which both accounted for approximately 14% of revision procedures. An evaluation of the literature
                  demonstrates that the most common reasons for revision after TKA can vary depending on the era in
                  which the primary procedure was performed and the length of time after the surgical procedure in which
                  revision procedures are captured<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">12</span></a>.
                  When evaluating revision TKAs within 2 years of the surgical procedure, the literature has
                  consistently identified PJI as a leading cause of revision surgery<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">28</span></a>. When analyzing only revisions performed within 2 years for
                  aseptic indications, we observed a decrease in the risk of aseptic revision for the entire cohort and
                  each obesity subgroup. These data suggest that we are improving TKA outcomes for obese patients over
                  time. Although we could not determine causation, the results may be due to an improved understanding
                  of risk factors associated with poor outcomes, evolving fixation techniques, and better implant
                  design. However, despite improvements in reoperation and aseptic revision, the risk of PJI remained
                  stable over time for obese patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">In the present study, the PJI risk declined throughout the
                  study period for the entire cohort and for non-obese patients. There was an approximately 3% decrease
                  in PJI risk per year for the entire cohort and an approximately 5% decrease in PJI risk per year for
                  non-obese patients. Conversely, there was no detectable change in the PJI risk over time for any of
                  the obesity groups. Prior literature evaluating trends in PJI rates have reported either stable or
                  slightly decreasing rates of PJI over time for the general population undergoing TKA<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">29</span></a>. Kurtz et al. studied a U.S. Medicare population from 2005
                  to 2014 and was unable to identify any significant trend in cumulative incidence of PJI at 1 or 5
                  years after TKA<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">13</span></a>.
                  The McMaster Arthroplasty Collaborative published a 15-year, population-based, cohort study evaluating
                  PJI following primary TKA. This study evaluated approximately 130,000 Canadian patients who had
                  undergone TKA and found that the risk of PJI has decreased in small but steady increments over the
                  previous 15 years, citing a relative decrease of 4.6% in PJI risk for each subsequent year of the
                  study. These findings are quite similar to the 3% decrease in PJI risk per year identified for the
                  entire cohort in the present study<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">29</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">There is little published information on the temporal
                  changes in incidence of PJI in the obese population undergoing TKA. DeMik et al. was unable to find
                  any significant changes in PJI rates from 2011 to 2018 for either morbidly obese patients (BMI, ≥40
                  kg/m<span data-id="superscript_35" class="annotation superscript" style="position: unset;">2</span>)
                  or non-morbidly obese patients (BMI, &lt;40 kg/m<span data-id="superscript_36"
                    class="annotation superscript" style="position: unset;">2</span>) undergoing TKA captured within the
                  ACS NSQIP database<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">22</span></a>.
                  However, their study spanned a relatively short time frame and was only able to capture infection
                  occurring within 30 days of the surgical procedure. In the present study, we were unable to identify
                  any significant changes in PJI risk over 3 decades for any obesity group. These findings suggest that
                  the improvements in PJI risk observed for our entire cohort are likely due to the significant
                  decreases in PJI risk that were identified for non-obese patients. Considering these data and the
                  increasing prevalence of obesity among patients undergoing primary TKA, it is important for the
                  orthopaedic community to continue to work toward improving the PJI risk in obese patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">The main limitations to this study include its
                  retrospective nature and a limited number of reoperation and revision events, despite the almost
                  14,000 TKAs studied. The limited number of events is partially due to evaluating 2-year outcomes.
                  However, an evaluation of longer-term outcomes would have limited the time period for study.
                  Additionally, selection bias exists when deciding to revise or reoperate on a knee, and this was
                  unable to be controlled for in the present study. Despite these limitations, the strengths of the
                  study include the large sample size, the 30-year study period, the utilization of a single-institution
                  total joint registry that enhances data fidelity, and our ability to track these patients over time.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">In summary, the prevalence of obesity increased
                  substantially among patients who underwent TKA at our institution over 3 decades, at a rate that is in
                  line with the prevailing data on the epidemiology of obesity in the United States. Despite the
                  increasing rates of obesity among our patients who underwent TKA, we observed decreasing risks of
                  reoperation, revision, and PJI for the entire cohort. However, improvements in the outcomes of
                  non-obese patients undergoing TKA and in aseptic outcomes of obese patients undergoing TKA appeared to
                  be driving much of these changes, and we were unable to detect improvement in PJI risk for any obesity
                  groups. Despite the decreasing risk of reoperation and revision for Class-III obese patients, further
                  work is needed to improve the PJI risk in obese patients, especially as this population continues to
                  grow.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Finkelstein EA</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Khavjou OA</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Thompson H</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Trogdon JG</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Pan L</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Sherry B</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Dietz W</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Obesity and severe obesity
                  forecasts through 2030</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Am J Prev Med.</span> 2012 Jun;42(6):563-70.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Obesity%20and%20severe%20obesity%20forecasts%20through%202030&as_occt=title&as_sauthors=%20%22EA%20Finkelstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Ward ZJ</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Bleich SN</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Cradock AL</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Barrett JL</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Giles CM</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Flax C</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Long MW</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Gortmaker SL</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Projected U.S. state-level
                  prevalence of adult obesity and severe obesity</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">N Engl J Med.</span> 2019 Dec
                19;381(25):2440-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Projected%20U.S.%20state-level%20prevalence%20of%20adult%20obesity%20and%20severe%20obesity&as_occt=title&as_sauthors=%20%22ZJ%20Ward%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Carender CN</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Glass NA</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> DeMik DE</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Elkins JM</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Brown TS</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Bedard NA</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Projected prevalence of obesity
                  in primary total knee arthroplasty: how big will the problem get?</span><span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2022
                Jul;37(7):1289-95.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Projected%20prevalence%20of%20obesity%20in%20primary%20total%20knee%20arthroplasty%3A%20how%20big%20will%20the%20problem%20get%3F&as_occt=title&as_sauthors=%20%22CN%20Carender%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Fehring TK</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Odum SM</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Griffin WL</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Mason JB</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> McCoy TH</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The obesity epidemic: its effect
                  on total joint arthroplasty</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2007 Sep;22(6)(Suppl 2):71-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20obesity%20epidemic%3A%20its%20effect%20on%20total%20joint%20arthroplasty&as_occt=title&as_sauthors=%20%22TK%20Fehring%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Felson DT</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Anderson JJ</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Naimark A</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Walker AM</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Meenan RF</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Obesity and knee osteoarthritis.
                  The Framingham Study</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Ann Intern Med.</span> 1988 Jul 1;109(1):18-24.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Obesity%20and%20knee%20osteoarthritis.%20The%20Framingham%20Study&as_occt=title&as_sauthors=%20%22DT%20Felson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> George J</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Klika AK</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Navale SM</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Newman JM</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Barsoum WK</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Higuera CA</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Obesity epidemic: is its impact
                  on total joint arthroplasty underestimated? An analysis of national trends</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2017 Jul;475(7):1798-806.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Obesity%20epidemic%3A%20is%20its%20impact%20on%20total%20joint%20arthroplasty%20underestimated%3F%20An%20analysis%20of%20national%20trends&as_occt=title&as_sauthors=%20%22J%20George%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Stürmer T</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Günther KP</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Brenner H</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Obesity, overweight and patterns
                  of osteoarthritis: the Ulm Osteoarthritis Study</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Clin Epidemiol.</span> 2000 Mar
                1;53(3):307-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Obesity%2C%20overweight%20and%20patterns%20of%20osteoarthritis%3A%20the%20Ulm%20Osteoarthritis%20Study&as_occt=title&as_sauthors=%20%22T%20St%C3%BCrmer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Jung P</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Morris AJ</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Zhu M</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Roberts SA</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Frampton C</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Young SW</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">BMI is a key risk factor for
                  early periprosthetic joint infection following total hip and knee arthroplasty</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">N Z Med J.</span> 2017 Sep
                1;130(1461):24-34.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=BMI%20is%20a%20key%20risk%20factor%20for%20early%20periprosthetic%20joint%20infection%20following%20total%20hip%20and%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22P%20Jung%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Namba RS</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Paxton L</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Fithian DC</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Stone ML</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Obesity and perioperative
                  morbidity in total hip and total knee arthroplasty patients</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2005 Oct;20(7)(Suppl
                3):46-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Obesity%20and%20perioperative%20morbidity%20in%20total%20hip%20and%20total%20knee%20arthroplasty%20patients&as_occt=title&as_sauthors=%20%22RS%20Namba%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Schwarzkopf R</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Thompson SL</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Adwar SJ</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Liublinska V</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Slover JD</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Postoperative complication
                  rates in the “super-obese” hip and knee arthroplasty population</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2012
                Mar;27(3):397-401.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postoperative%20complication%20rates%20in%20the%20%E2%80%9Csuper-obese%E2%80%9D%20hip%20and%20knee%20arthroplasty%20population&as_occt=title&as_sauthors=%20%22R%20Schwarzkopf%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Wagner ER</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Kamath AF</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Fruth K</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Harmsen WS</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Berry DJ</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Effect of body mass index on
                  reoperation and complications after total knee arthroplasty</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2016 Dec
                21;98(24):2052-60.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1266052"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Delanois RE</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Mistry JB</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Gwam CU</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Mohamed NS</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Choksi US</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Mont MA</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Current epidemiology of
                  revision total knee arthroplasty in the United States</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2017
                Sep;32(9):2663-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20epidemiology%20of%20revision%20total%20knee%20arthroplasty%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22RE%20Delanois%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Kurtz SM</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Lau EC</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Son MS</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Chang ET</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Zimmerli W</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Parvizi J</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Are we winning or losing the
                  battle with periprosthetic joint infection: trends in periprosthetic joint infection and mortality
                  risk for the Medicare population</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2018 Oct;33(10):3238-45.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20we%20winning%20or%20losing%20the%20battle%20with%20periprosthetic%20joint%20infection%3A%20trends%20in%20periprosthetic%20joint%20infection%20and%20mortality%20risk%20for%20the%20Medicare%20population&as_occt=title&as_sauthors=%20%22SM%20Kurtz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Odum SM</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Van Doren BA</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Springer BD</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">National obesity trends in
                  revision total knee arthroplasty</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2016 Sep;31(9)(Suppl):136-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=National%20obesity%20trends%20in%20revision%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22SM%20Odum%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Amin AK</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Patton JT</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Cook RE</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Brenkel IJ</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Does obesity influence the
                  clinical outcome at five years following total knee replacement for osteoarthritis?</span><span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 2006 Mar;88(3):335-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20obesity%20influence%20the%20clinical%20outcome%20at%20five%20years%20following%20total%20knee%20replacement%20for%20osteoarthritis%3F&as_occt=title&as_sauthors=%20%22AK%20Amin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Li W</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Ayers DC</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Lewis CG</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Bowen TR</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Allison JJ</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Franklin PD</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Functional gain and pain relief
                  after total joint replacement according to obesity status</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2017 Jul
                19;99(14):1183-9.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1396890"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Berstock JR</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Beswick AD</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> López-López JA</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Whitehouse MR</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Blom AW</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Mortality after total knee
                  arthroplasty: a systematic review of incidence, temporal trends, and risk factors</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2018 Jun 20;100(12):1064-70.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1705538" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Cram P</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Lu X</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Kates SL</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Singh JA</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Wolf BR</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Total knee arthroplasty volume,
                  utilization, and outcomes among Medicare beneficiaries, 1991-2010</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">JAMA.</span> 2012 Sep
                26;308(12):1227-36.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20knee%20arthroplasty%20volume%2C%20utilization%2C%20and%20outcomes%20among%20Medicare%20beneficiaries%2C%201991-2010&as_occt=title&as_sauthors=%20%22P%20Cram%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> DeMik DE</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Carender CN</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Glass NA</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Brown TS</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Callaghan JJ</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Bedard NA</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Who is still receiving blood
                  transfusions after primary and revision total joint arthroplasty?</span><span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2022
                Jun;37(6S):S63-69.e1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Who%20is%20still%20receiving%20blood%20transfusions%20after%20primary%20and%20revision%20total%20joint%20arthroplasty%3F&as_occt=title&as_sauthors=%20%22DE%20DeMik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Forslund JM</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Chan PH</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Prentice HA</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Purdy AC</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Khatod M</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Preoperative patient
                  optimization: outcomes from elective total joint arthroplasty in a large US healthcare system</span>.
                <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J Am Acad Orthop
                  Surg.</span> 2023 Jul 15;31(14):746-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20patient%20optimization%3A%20outcomes%20from%20elective%20total%20joint%20arthroplasty%20in%20a%20large%20US%20healthcare%20system&as_occt=title&as_sauthors=%20%22JM%20Forslund%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Metoxen AJ</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Ferreira AC</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Zhang TS</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Harrington MA</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Halawi MJ</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Hospital readmissions after
                  total joint arthroplasty: an updated analysis and implications for value-based care</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2023
                Mar;38(3):431-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hospital%20readmissions%20after%20total%20joint%20arthroplasty%3A%20an%20updated%20analysis%20and%20implications%20for%20value-based%20care&as_occt=title&as_sauthors=%20%22AJ%20Metoxen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> DeMik DE</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Carender CN</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Glass NA</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Brown TS</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Callaghan JJ</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Bedard NA</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Are surgeons still performing
                  primary total knee arthroplasty in the morbidly obese?</span><span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2021 Jun;103-B(6)(Supple
                A):38-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20surgeons%20still%20performing%20primary%20total%20knee%20arthroplasty%20in%20the%20morbidly%20obese%3F&as_occt=title&as_sauthors=%20%22DE%20DeMik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Odum SM</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Springer BD</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Dennos AC</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Fehring TK</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">National obesity trends in
                  total knee arthroplasty</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2013 Sep;28(8)(Suppl):148-51.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=National%20obesity%20trends%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22SM%20Odum%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Wall CJ</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Vertullo CJ</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Kondalsamy-Chennakesavan
                  S</span>, <span data-id="annotation_126" class="annotation" style="position: unset;"> Lorimer
                  MF</span>, <span data-id="annotation_127" class="annotation" style="position: unset;"> de Steiger
                  RNA</span>. <span data-id="strong_24" class="annotation strong" style="position: unset;">A
                  prospective, longitudinal study of the influence of obesity on total knee arthroplasty revision rate:
                  results from the Australian Orthopaedic Association National Joint Replacement Registry</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2022 Aug 3;104(15):1386-92.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=3341494" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Kerbel YE</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Johnson MA</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Barchick SR</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Cohen JS</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Stevenson KL</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Israelite CL</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Nelson CL</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Preoperative risk
                  stratification minimizes 90-day complications in morbidly obese patients undergoing primary total knee
                  arthroplasty</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2021 Jun;103-B(6)(Supple A):45-50.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20risk%20stratification%20minimizes%2090-day%20complications%20in%20morbidly%20obese%20patients%20undergoing%20primary%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22YE%20Kerbel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Khan M</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Osman K</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Green G</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Haddad FS</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">The epidemiology of failure in
                  total knee arthroplasty: avoiding your next revision</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2016 Jan;98-B(1)(Suppl
                A):105-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20epidemiology%20of%20failure%20in%20total%20knee%20arthroplasty%3A%20avoiding%20your%20next%20revision&as_occt=title&as_sauthors=%20%22M%20Khan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Koh CK</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Zeng I</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Ravi S</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Zhu M</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Vince KG</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Young SW</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Periprosthetic joint infection
                  is the main cause of failure for modern knee arthroplasty: an analysis of 11,134 knees</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2017 Sep;475(9):2194-201.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20joint%20infection%20is%20the%20main%20cause%20of%20failure%20for%20modern%20knee%20arthroplasty%3A%20an%20analysis%20of%2011%2C134%20knees&as_occt=title&as_sauthors=%20%22CK%20Koh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Nham FH</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Patel I</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Zalikha AK</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> El-Othmani MM</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Epidemiology of primary and
                  revision total knee arthroplasty: analysis of demographics, comorbidities and outcomes from the
                  National Inpatient Sample</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Arthroplasty.</span> 2023 Apr 2;5(1):18.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiology%20of%20primary%20and%20revision%20total%20knee%20arthroplasty%3A%20analysis%20of%20demographics%2C%20comorbidities%20and%20outcomes%20from%20the%20National%20Inpatient%20Sample&as_occt=title&as_sauthors=%20%22FH%20Nham%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;">McMaster
                Arthroplasty Collaborative (MAC). <span data-id="strong_29" class="annotation strong"
                  style="position: unset;">Incidence and predictors of prosthetic joint infection following primary
                  total knee arthroplasty: a 15-year population-based cohort study</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2022
                Feb;37(2):367-372.e1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20and%20predictors%20of%20prosthetic%20joint%20infection%20following%20primary%20total%20knee%20arthroplasty%3A%20a%2015-year%20population-based%20cohort%20study&as_occt=title&as_sauthors=%20%22McMaster%20Arthroplasty%20Collaborative%20(MAC)%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D4844781f-f971-49f6-8381-ff58739ecd94%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F1963%26topics%3Dkn"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node eletter" data-id="eletter_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="file" style="position: unset;"><span class="" style="position: unset;">eLetter 1</span><a
                  class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;4844781f-f971-49f6-8381-ff58739ecd94&quot;,&quot;type&quot;:&quot;eletter&quot;,&quot;topics&quot;:[&quot;kn&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=4844781f-f971-49f6-8381-ff58739ecd94&type=eletter&name=1453"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 1963</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01413</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 24, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_30"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Mayo
                            Clinic, Rochester, Minnesota</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4844781f-f971-49f6-8381-ff58739ecd94&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;kn&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=4844781f-f971-49f6-8381-ff58739ecd94&type=pdf&name=JBJS.23.01413.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=4844781f-f971-49f6-8381-ff58739ecd94&type=pdf&name=JBJS.23.01413.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_35"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_36" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I209"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I209</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4844781f-f971-49f6-8381-ff58739ecd94&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;kn&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=4844781f-f971-49f6-8381-ff58739ecd94&type=zip&name=JBJS.23.01413.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mason E. Uvodich, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2635-7342" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2635-7342</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Evan M. Dugdale, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6747-4177" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6747-4177</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mark W. Pagnano, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1679-0046" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1679-0046</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daniel J. Berry, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1422-0306" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1422-0306</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew P. Abdel, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2398-1724" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2398-1724</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nicholas A. Bedard, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:bedard.nicholas@mayo.edu" class=""
                    style="position: unset;">bedard.nicholas@mayo.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3151-877X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3151-877X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Mayo Clinic, Rochester, Minnesota</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 29636.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
