export const physicianRoles = [
	"orthopaedic_surgeon",
	"physician_associate",
	"resident",
	"fellow",
];

export const getDisplayName = (
	role?: string,
	firstName?: string,
	lastName?: string,
) => {
	const isPhysician = role && physicianRoles.includes(role);
	return isPhysician ? `Dr. ${lastName || ""}` : firstName || "";
};
