import { formatRank } from "@memorang/helpers";
/* -----------------Child components--------------- */
import { Box, CustomAvatar } from "@memorang/ui";
import AvatarGenerator from "@memorang/ui/src/components/NameCard/AvatarGenerator";
import type { TextStyle } from "react-native";
/* -----------------UI--------------- */
import { List, Text } from "react-native-paper";
import type { Style } from "react-native-paper/lib/typescript/components/List/utils";

type Props = {
	title: string;
	rank: number;
	xp: number;
	showDivider?: boolean;
	image: string;
	textStyle?: TextStyle;
	avatarType?: "initials" | "identicon";
};
const LeaderboardListItem = (props: Props) => {
	const { rank, title, xp, image, textStyle, avatarType } = props;

	const renderRight = (p: {
		color: string;
		style?: Style;
	}) => (
		<List.Icon
			{...p}
			icon={() => (
				<Text variant="labelLarge">{`${xp?.toLocaleString() ?? 0} XP`}</Text>
			)}
		/>
	);

	const renderLeft = (p: { color: string; style: Style }) => {
		const rankLabel = formatRank(rank);

		return (
			<Box flexDirection="row" paddingLeft={8} alignItems="center">
				{rankLabel ? <Text variant="bodySmall">{rankLabel}</Text> : null}
				<List.Icon
					{...p}
					icon={() =>
						image ? (
							<CustomAvatar size={40} avatar={image} />
						) : (
							<AvatarGenerator
								firstName={title}
								lastName={title}
								size={23}
								avatarType={avatarType}
								key={title}
								customStyle={{
									position: "relative",
									top: undefined,
								}}
							/>
						)
					}
					style={p.style}
				/>
			</Box>
		);
	};

	return (
		<>
			<List.Item
				title={title}
				titleStyle={{
					...(textStyle ?? {}),
					fontSize: 14,
					fontFamily: "OpenSans-SemiBold",
				}}
				right={renderRight}
				left={renderLeft}
			/>
		</>
	);
};

export default LeaderboardListItem;
