import { useAuth } from "@clerk/clerk-expo";
import { queryKeys } from "@constants/common";
import safeParseJson from "@helpers/common";
import { useAppStore } from "@hooks/useAppStore";
import useClerkUser from "@hooks/useClerkUser";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import type { ItemData } from "schema/item";
import { fetchDashboardItems } from "../queries/dashboard";

const now = new Date();

const useItems = () => {
	const { userPublicMetadataId, isLoaded } = useClerkUser();

	const tenantId = useAppStore((state) => state.tenant.id);
	const { getToken } = useAuth();

	const { data: dashboardData, isLoading } = useQuery({
		queryKey: [queryKeys.dashboard],
		queryFn: async () => {
			if (!userPublicMetadataId) {
				return null;
			}
			const token = await getToken({
				template: "jwt-with-user",
			});

			if (!token) {
				throw new Error("No token");
			}
			return await fetchDashboardItems({
				userId: userPublicMetadataId,
				token,
				tenantId,
			});
		},
		enabled: !!userPublicMetadataId,
	});

	const finalIsLoading = isLoading || !isLoaded;

	const items = useMemo(() => {
		return dashboardData?.data.items ?? [];
	}, [dashboardData]);

	const shuffleChildren = useCallback(
		(children: ItemData["data"]["children"]) => {
			return children.sort(() => Math.random() - 0.5);
		},
		[],
	);

	const filteredItems = useMemo(
		() =>
			items
				.filter((item) => {
					return (
						item.metadata?.generatedType === "VARIANT" &&
						// item.status === "PUBLISHED" &&
						item.publishedAt &&
						new Date(item.publishedAt) <= now
					);
				})
				.map((item) => ({
					...item,
					data: {
						...item.data,
						children: item.shuffle
							? shuffleChildren(item.data.children)
							: item.data.children,
					},
					media: item.media?.map((media) => ({
						...media,
						metadata: media.metadata
							? safeParseJson(media.metadata)
							: undefined,
					})),
				})) || [],
		[items, shuffleChildren],
	);

	const futureItems = useMemo(() => {
		return dashboardData?.data.items?.filter(
			(item) => item.publishedAt && new Date(item.publishedAt) > now,
		);
	}, [dashboardData]);

	return {
		filteredItems,
		isLoading: finalIsLoading,
		futureItems,
	};
};

export default useItems;
