import { buildType } from "@helpers/expo-extras";
import { Apps } from "types/apps";

type DefaultExamContext = {
	studyPackProductId: string;
	examName: string;
	examId: string;
	examIcon?: string;
	studyPackProductNumericId: number;
	practiceTestProductId?: string;
};
export const defaultExamContextMap: Record<Apps, DefaultExamContext> = {
	[Apps.TherapyEd]: {
		studyPackProductId: "952aca40-c93d-4656-8814-94264f293ac4",
		examName: "NPTE-PT",
		studyPackProductNumericId: 44,
		practiceTestProductId: "3f789553-1d7d-4049-9ceb-cb610e83e102",
		examId: "e61873a5-ffd7-4bed-9745-256da36a16d7",
		examIcon: "https://icon.memorang.com/icon/icon_exam_21.png",
	},
	[Apps.Psi]: {
		studyPackProductId: "031ed055-d3a3-4a67-9048-5d9658454865",
		examName: "Life Accident and Health",
		studyPackProductNumericId: 45,
		practiceTestProductId: "8b8d2b39-1780-4b8c-a270-08053153cad3",
		examId: "c2dc2f42-ccbe-428a-81ed-0d45a5b2f001",
		examIcon: "https://icon.memorang.com/icon/icon_exam_1.png",
	},
	[Apps.hiset]: {
		studyPackProductId: "031ed055-d3a3-4a67-9048-5d9658454865",
		examName: "Life Accident and Health",
		studyPackProductNumericId: 45,
		examId: "c2dc2f42-ccbe-428a-81ed-0d45a5b2f001",
		examIcon: "https://icon.memorang.com/icon/icon_exam_1.png",
	},
	[Apps.Simwerx]: {
		studyPackProductId: "c625da7a-b90c-4eb1-98e0-746bdb804633",
		examName: "Simwerx",
		studyPackProductNumericId: 103,
		examId: "4e85d959-d7c0-4714-89bd-057030d6dbc6",
	},
	[Apps.Jbjs]: {
		studyPackProductId: "226e77c4-b1ca-4216-9e36-2ba07182f76f",
		examName: "",
		studyPackProductNumericId: 105,
		examId: "e61873a5-ffd7-4bed-9745-256da36a16d7",
	},
	[Apps.Ada]: {
		studyPackProductId: "226e77c4-b1ca-4216-9e36-2ba07182f76g",
		examName: "ADA",
		studyPackProductNumericId: 106,
		examId: "",
	},
	[Apps.zoetis]: {
		studyPackProductId: "226e77c4-b1ca-4216-9e36-2ba07182f76k",
		examName: "Zoetis",
		studyPackProductNumericId: 107,
		examId: "",
	},
	[Apps.thiemeneetpg]: {
		studyPackProductId: "fb00db47-e102-4362-9bcb-f2959b8ccedd",
		examName: "NEET PG",
		studyPackProductNumericId: 128,
		examId: "26560f9a-49b4-4440-b5e4-05ac8d5973f4",
		examIcon: "https://icon.memorang.com/icon/icon_exam_26.png",
	},
};

export const defaultExamContext = defaultExamContextMap[buildType];
