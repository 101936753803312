import type { Article } from "../../../../../types";

export const Article585: Article = {
	id: 585,
	rsuiteId: "e61a4a39-48a3-4802-aa85-37e10096885a",
	type: "the orthopaedic forum",
	title: "What Orthopaedic Infectious Diseases Specialists Want",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e61a4a39-48a3-4802-aa85-37e10096885a&type=jpeg&name=JBJS.24.00166f1",
	subSpecialties: ["trauma"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What Orthopaedic Infectious Diseases Specialists Want
              </div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Angela L.
                      Hewlett, MD, MS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Nicolás
                      Cortés-Penfield, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 2 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Following an educational lecture series on bone and joint
                  infections, an orthopaedic surgery resident posed a question to our orthopaedic infectious diseases
                  (Ortho ID) group: “What can we as surgeons do to make your job easier?” This was a thought-provoking
                  question, and one that we had not been previously asked despite our many collective years of
                  collaborative work.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">We subsequently conducted an informal email survey of a
                  longstanding group of U.S.-based Ortho ID physicians who collaborate in research and mutual clinical
                  consultation. Thirteen Ortho ID physicians from 9 institutions responded, and despite variations in
                  practice location, several common themes emerged, with an emphasis on opportunities for improvements
                  in the preoperative, intraoperative, and postoperative phases of care (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). In this article, we review these opportunities and provide
                  specific illustrative comments (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>), with
                  the goal of an improved partnership in the comanagement of patients with bone and joint infections.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00166f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e61a4a39-48a3-4802-aa85-37e10096885a&type=jpeg&name=JBJS.24.00166f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Opportunities for improvements in the
                          preoperative, intraoperative, and postoperative phases.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Specific Comments from Surveyed Ortho ID Physicians
                          in the U.S.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1247px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Phase of Care</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Example
                          Comments</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">“I strongly prefer to see
                          (or at least hear about) known or suspected infected patients preoperatively whenever
                          possible. This allows us to optimize culture processing, comment on PMMA/local antibiotics,
                          make preliminary antibiotic therapy plans, and frame expectations.”<br data-id="break_1"
                            style="position: unset;">“Please place an allergy referral preoperatively in patients with a
                          stated penicillin allergy so we can use the best drugs possible for prophylaxis and
                          treatment.”</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Intraoperative</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">“Make sure that the
                          culture and pathology specimens are labeled clearly and accurately in the operating room by
                          anatomic site and tissue type (we also realize that this is at the mercy of whomever labels
                          the specimens).”<br data-id="break_2" style="position: unset;">“Always take cultures. This
                          will help us guide antibiotic therapy and choose the best drug. Otherwise, we just have to
                          guess. We are ID, and we would rather have cultures than not have cultures anytime.”<br
                            data-id="break_3" style="position: unset;">“Take postoperative x-rays, even if you used
                          fluoroscopy intraoperatively. It is a lot easier for me to figure out what hardware stayed and
                          what hardware was removed if I can look at x-rays.”<br data-id="break_4"
                            style="position: unset;">“Teach us the orthopaedics you think we need to know, and invite us
                          into the operating room once in a while to make sure we ‘get it’.”</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">“Discuss the need for ID
                          consultation with the patient before ID walks in the room; frame the ID consultant as a
                          teammate.”<br data-id="break_5" style="position: unset;">“Please do not tell the patient that
                          they need IV antibiotics unless you’ve already heard from us. They might not. Don’t place a
                          PICC line before the plan is clear.”<br data-id="break_6" style="position: unset;">“Call us or
                          send us a message in the EMR [electronic medical record] if you are concerned about one of our
                          mutual patients that you see in clinic.”</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Preoperative
                Care</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Preoperative consultation with an ID specialist helps lay
                  the foundation for a team-based approach that can benefit patients throughout their operative and
                  postoperative care for infection. This evaluation allows input on the diagnostic testing approach and
                  facilitates obtaining relevant outside microbiology results prior to surgery, both of which can help
                  establish the optimal surgical and antimicrobial treatment strategy. ID physicians also can provide
                  guidance on optimal empiric intraoperative (e.g., antibiotics mixed into cement) and postoperative
                  antimicrobial regimens. Additionally, this evaluation can help set expectations for patients in a
                  controlled preoperative setting.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Preoperative ID consultation may be particularly helpful
                  in identifying and/or reinforcing opportunities for host optimization in order to decrease the risk of
                  infection. These may include obesity management, diabetes management, smoking cessation, improvement
                  in dentition, and antimicrobial allergy clarification. As for patients with nonmodifiable risk
                  factors, we can participate in frank discussions about the risk of infection, reinfection, and/or
                  treatment failure with the patient.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Intraoperative Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">In order for us to provide the best antimicrobial
                  management, ID physicians need the most appropriate culture results to guide therapy. This means
                  knowing where samples were taken from, the quality of the samples, and whether the samples were
                  processed optimally. Careful labeling of culture specimens with regard to the anatomic source (i.e.,
                  deep versus superficial) and the type of sample (i.e., bone versus soft tissue) is very helpful. To
                  avoid cross-contamination, a different instrument should be used for each specimen<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Additionally, swabs are not the preferred method for sample
                  collection since data suggest that tissue samples provide superior diagnostic yield and that some
                  clinically important organisms may not grow well in cultures obtained from swabs<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a>.
                  Specific culture methods may be necessary for the recovery of certain microorganisms, including an
                  extended incubation time for <span data-id="emphasis_6" class="annotation emphasis"
                    style="position: unset;">Cutibacterium acnes,</span> which can be implemented as routine laboratory
                  practice by establishing protocols for periprosthetic specimens<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Detailed operative reports are critical components of
                  communication, and a competent ID specialist will carefully review each operative note. This is a
                  chance for the surgeon to communicate key findings, such as the apparent depth of infection and which
                  hardware was involved or contiguous with the extent of infection, as well as which hardware was
                  removed and which remained at the end of the case. Communication of details regarding the extent of
                  the performed debridement is important for all cases, and it is especially helpful for the management
                  of infections caused by difficult-to-treat pathogens, where aggressive surgical intervention is the
                  most vital aspect of the treatment plan. Specific details regarding the intraoperative antimicrobials
                  used in the cement spacer, in beads (polymethylmethacrylate [PMMA] or calcium sulfate), or as powder,
                  may be helpful for the ID specialist to know when planning postoperative therapy or identifying
                  complications (e.g., acute renal failure or severe allergic reaction related to the antimicrobial
                  agents in spacers). Similarly, clear documentation about plans for additional surgical intervention,
                  including whether a functional spacer is intended as “destination therapy,” is invaluable. Finally, a
                  brief telephone call after surgery to the ID specialist is often much appreciated and can help
                  emphasize critical findings or plans that the surgeon considers particularly important.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Postoperative Care</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The initial postoperative period often involves pain,
                  waiting, and anticipation for the patient. While we may have immediate recommendations for in-hospital
                  management, final ID plans often require the results of microbiologic and histopathologic studies that
                  were obtained during surgery and may take several days. The orthopaedic surgery team, in whom the
                  patient has great trust, can help by outlining the involvement of the ID specialist and setting
                  realistic discharge recommendations (e.g., “Our ID team will help us tailor treatment, but cultures
                  take time, and we work together to get the best treatment for you.”). The surgical team should await
                  recommendations regarding the antibiotic therapy plan prior to ordering PICC (peripherally inserted
                  central catheter) line placement, since there are multiple factors that determine whether the patient
                  should be treated with intravenous (IV) or oral antibiotics. At the same time, it is very important
                  for the ID specialist to understand when the orthopaedic surgeon has a rationale for preferring a
                  certain antimicrobial strategy for a specific patient. Discussing this with the ID team prior to
                  informing the patient ensures that we are on the same page and delivering effective team-based care.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">In the late postoperative period, the ID team is often
                  carefully following patients to ensure that they tolerate their antimicrobial therapy without
                  toxicity. However, we may not be aware of or recognize new developments in other aspects of the
                  patient’s postoperative course, including poor wound-healing, unexpected late positive results from
                  culture specimens, subtle side effects from antimicrobials that are discovered in the postoperative
                  clinic, or other events that may influence the antimicrobial therapy plan. Reaching out to the ID team
                  with this information can only improve the patient’s care. It is also helpful to collaboratively
                  discuss future surgical and medical therapeutic options for each patient, including the utility of
                  suppressive antibiotic therapy (SAT) in select patients and surgical factors that should influence
                  decision-making about SAT (e.g., if the technical difficulty of the surgery resulted in suboptimal
                  debridement of infection, or if it is clear that future surgical options are limited and further
                  recurrence of infection will likely necessitate amputation or another salvage procedure). Finally, the
                  ID team often can help interpret positive cultures that are unexpected, grow unusual organisms, or
                  might be contaminants—we are eager to help avoid unnecessary clinic or hospital visits and unneeded
                  antibiotics in these patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">An emphasis on the importance of communication and
                  collaboration was interwoven throughout all of the responses from the Ortho ID physicians who were
                  surveyed. Relationships between orthopaedic surgeons and ID physicians at each institution need to be
                  built and maintained for optimal team-based patient care. Direct face-to-face time (versus solely
                  electronic communication or even telephone calls) is particularly valuable for building trusting
                  working relationships, and can be fostered by regular joint case conferences where patients are
                  discussed from both a surgical and an ID standpoint, co-rounding, or organizing a co-located
                  outpatient clinic (i.e., bringing the ID physician into the orthopaedic surgery clinic so that mutual
                  patients can be seen simultaneously and ID physicians are available for immediate consultation). Along
                  with enhancing communication, these efforts are often noticed by patients, who frequently express
                  their appreciation for seeing their physicians collaboratively discuss the plan of care. Communication
                  with the ID team postoperatively, or with complex consultation requests, produces better consultations
                  from our teams. These lines of communication should ideally involve all team members in the plan of
                  care, including residents, advanced practice providers, medical students, and nurses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Beyond direct patient care, effective collaboration
                  between orthopaedic surgeons and ID physicians can include joint research projects, which cement
                  relationships and also prioritize important clinical questions. Additionally, multidisciplinary
                  quality improvement initiatives that are mutually beneficial can be a source of collaborative work and
                  process improvement (e.g., setting institutional consensus protocols that standardize and optimize
                  infection care). All of these measures can potentially contribute positively to our ability to provide
                  the highest level of patient care, increase patient satisfaction, and contribute high-quality,
                  multidisciplinary research to combat the paucity of scientific literature on orthopaedic infections.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">For the ultimate benefit of improved patient care,
                  collaborative efforts between orthopaedic surgeons and ID physicians should be the standard, not the
                  exception, and the benefits of building and maintaining these relationships is what we should be
                  teaching our learners in both fields who follow our lead.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors appreciate the assistance of our Ortho ID
                  colleagues and survey participants: Dr. Laura Certain (University of Utah), Dr. Loreen Herwaldt
                  (University of Iowa), Dr. Martin Krsak (University of Colorado), Dr. Andy Miller (Hospital for Special
                  Surgery), Dr. Sandy Nelson (Massachusetts General Hospital), Dr. Doug Osmon (Mayo Clinic), Dr. Julie
                  Reznicek (Virginia Commonwealth University), Dr. Mark Rupp (University of Nebraska Medical Center),
                  Dr. Meredith Schade (Penn State University), and Dr. Poorani Sekar (University of Iowa).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Parvizi J</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Zmistowski B</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Berbari EF</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Bauer TW</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Springer BD</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Della Valle CJ</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Garvin KL</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Mont MA</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Wongworawat MD</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Zalavras CG</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">New definition for
                  periprosthetic joint infection: from the Workgroup of the Musculoskeletal Infection Society</span>.
                <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2011 Nov;469(11):2992-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=New%20definition%20for%20periprosthetic%20joint%20infection%3A%20from%20the%20Workgroup%20of%20the%20Musculoskeletal%20Infection%20Society&as_occt=title&as_sauthors=%20%22J%20Parvizi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Aggarwal VK</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Higuera C</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Deirmengian G</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Parvizi J</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Austin MS</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Swab cultures are not as
                  effective as tissue cultures for diagnosis of periprosthetic joint infection</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2013 Oct;471(10):3196-203.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Swab%20cultures%20are%20not%20as%20effective%20as%20tissue%20cultures%20for%20diagnosis%20of%20periprosthetic%20joint%20infection&as_occt=title&as_sauthors=%20%22VK%20Aggarwal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Larsen LH</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Khalid V</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Xu Y</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Thomsen TR</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Schønheyder HC</span>; <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> the PRIS Study Group</span>.
                <span data-id="strong_3" class="annotation strong" style="position: unset;">Differential Contributions
                  of Specimen Types, Culturing, and 16S rRNA Sequencing in Diagnosis of Prosthetic Joint
                  Infections</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J
                  Clin Microbiol.</span> 2018 Apr 25;56(5):e01351-17.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Differential%20Contributions%20of%20Specimen%20Types%2C%20Culturing%2C%20and%2016S%20rRNA%20Sequencing%20in%20Diagnosis%20of%20Prosthetic%20Joint%20Infections&as_occt=title&as_sauthors=%20%22LH%20Larsen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Butler-Wu SM</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Burns EM</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Pottinger PS</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Magaret AS</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Rakeman JL</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Matsen FA 3rd</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Cookson BT</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Optimization of periprosthetic
                  culture for diagnosis of Propionibacterium acnes prosthetic joint infection</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Clin Microbiol.</span>
                2011 Jul;49(7):2490-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Optimization%20of%20periprosthetic%20culture%20for%20diagnosis%20of%20Propionibacterium%20acnes%20prosthetic%20joint%20infection&as_occt=title&as_sauthors=%20%22SM%20Butler-Wu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3De61a4a39-48a3-4802-aa85-37e10096885a%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2175</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00166</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 14, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_5"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            University of Nebraska Medical Center, Omaha, Nebraska</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e61a4a39-48a3-4802-aa85-37e10096885a&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=e61a4a39-48a3-4802-aa85-37e10096885a&type=pdf&name=JBJS.24.00166.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=e61a4a39-48a3-4802-aa85-37e10096885a&type=pdf&name=JBJS.24.00166.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_5" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_6"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I58" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I58</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e61a4a39-48a3-4802-aa85-37e10096885a&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=e61a4a39-48a3-4802-aa85-37e10096885a&type=zip&name=JBJS.24.00166.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Angela L. Hewlett, MD, MS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:alhewlett@unmc.edu" class="" style="position: unset;">alhewlett@unmc.edu</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2772-6437" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2772-6437</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nicolás Cortés-Penfield, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6302-9471" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6302-9471</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Cole J. Ohnoutka, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Aaron J. Tande, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span><span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9775-7082" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9775-7082</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Infectious Diseases, University of Nebraska Medical Center, Omaha,
                Nebraska</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery and Rehabilitation, University of Nebraska
                Medical Center, Omaha, Nebraska</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Orthopedic Infectious Diseases Service, University of Nebraska Medical Center,
                Omaha, Nebraska</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Division of Public Health, Infectious Diseases, and Occupational Medicine, Mayo
                Clinic College of Medicine, Rochester, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Orthopedic Infectious Diseases Focus Group, Mayo Clinic College of Medicine,
                Rochester, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 11268.2px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
