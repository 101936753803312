/* -----------------Globals--------------- */
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useUser } from "@clerk/clerk-expo";
import fetchUserInfo from "@memorang/api-client/src/user/GetUserInfo";
import type { UserInfo } from "@memorang/types";

type AuthContextValue = {
	setUserInfoNew: (
		latestFirstName?: string,
		latestLastName?: string,
	) => Promise<void>;
	viewerId?: string;
	newUserInfo?: UserInfo;
	loading?: boolean;
	refreshAuth?: () => void;
};

// constants
const initialContextValue: AuthContextValue = {
	viewerId: undefined,
	refreshAuth: () => {
		throw new Error("refreshAuth is not implemented");
	},
	setUserInfoNew: () => {
		throw new Error("setUserInfoNew is not implemented");
	},
};

// context
const AuthContext = React.createContext<AuthContextValue>(initialContextValue);

/* -----------------Component--------------- */
const AuthProvider = ({
	children,
	identifyUser,
	app,
	appId,
}: {
	children: React.ReactNode;
	appId: string;
	identifyUser: ({
		userId,
		email,
		username,
		app,
	}: { userId: string; email: string; username: string; app: string }) => void;
	app: string;
}) => {
	const { user, isLoaded, isSignedIn } = useUser();
	const [newUserInfo, setNewUserInfo] = useState<UserInfo | undefined>(
		undefined,
	);
	const userId = user?.publicMetadata?.logicalId as string;
	const email = user?.emailAddresses[0].emailAddress;
	const username = user?.username;

	const refreshAuth = useCallback(() => {
		if (isLoaded && isSignedIn && userId && email) {
			identifyUser({ userId, email, username: username || "", app });
		}
	}, [userId, email, username, app, identifyUser, isSignedIn, isLoaded]);

	const setUserInfoNew = useCallback(
		async (latestFirstName?: string, latestLastName?: string) => {
			if (user && appId) {
				if (latestFirstName) {
					setNewUserInfo((prev) => {
						if (!prev) {
							return undefined;
						}
						return {
							...prev,
							firstName: latestFirstName,
							lastName: latestLastName,
						};
					});
					await user.update({
						firstName: latestFirstName,
						lastName: latestLastName,
					});
				} else {
					const email = user.emailAddresses[0].emailAddress;
					const userInfo = await fetchUserInfo(email, appId);
					setNewUserInfo(userInfo);
				}
			}
		},
		[user, appId],
	);

	useEffect(() => {
		setUserInfoNew();
	}, [setUserInfoNew]);

	useEffect(() => {
		refreshAuth();
	}, [refreshAuth]);

	const contextValue = useMemo(() => {
		return {
			isAuthenticated: !!user,
			user,
			viewerId: user
				? (user.publicMetadata?.logicalId as string | undefined)
				: "",
			setUserInfoNew,
			newUserInfo,
			loading: !isLoaded,
			refreshAuth,
		};
	}, [user, setUserInfoNew, newUserInfo, isLoaded, refreshAuth]);

	return (
		<AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
	);
};

const useAuthContext = () => {
	const context = React.useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuthContext must be used within a AuthProvider");
	}
	return context;
};
export { AuthProvider, useAuthContext };
