import type { Article } from "../../../../../types";

export const Article614: Article = {
	id: 614,
	rsuiteId: "66158ebb-9cdd-4502-bfe2-5cf477bb1a82",
	type: "systematic review",
	title: "Evidence Versus Frenzy in Robotic Total Knee Arthroplasty",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=66158ebb-9cdd-4502-bfe2-5cf477bb1a82&type=jpeg&name=JBJS.24.00264f1a",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Evidence Versus Frenzy in Robotic Total Knee Arthroplasty
              </div>
              <div class="text subtitle" style="position: unset;">A Systematic Review Comparing News Media Claims to
                Randomized Controlled Trial Evidence</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Seper
                      Ekhtiari, MD, MSc, FRCSC</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Bryan Sun,
                      BHSc</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Background:</span> Robotic total knee arthroplasty (rTKA) has
                        garnered increasing attention in recent years, both clinically and in the media. The purpose of
                        this study was to compare the volume of and messaging in published randomized controlled trials
                        (RCTs) versus media reports on the topic of rTKA.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_43" class="annotation strong"
                          style="position: unset;">Methods:</span> This was a systematic review of RCTs and media
                        articles on rTKA. PubMed, Embase, and MEDLINE were searched for RCTs; Factiva was searched for
                        media articles. The number of publications of each type per year was recorded. Media articles
                        were classified on the basis of their primary information source, their general tone toward
                        rTKA, and the benefits and drawbacks of rTKA discussed. The volume, tone, and specific messaging
                        around rTKA were compared between media articles and RCTs.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_44" class="annotation strong"
                          style="position: unset;">Results:</span> Fifteen RCTs and 460 media articles, published
                        between 1991 and 2023, were included. The rates of both publication types increased over time,
                        with more rapid increases in recent years. Ninety-five percent of media publications highlighted
                        at least 1 benefit of rTKA. The most commonly cited benefits were more precise implant
                        positioning (82.6%) and faster recovery (28.7%). Fewer than 7% of media publications (n = 30)
                        mentioned downsides to rTKA. Overall, 89.3% of media articles presented a favorable view of
                        rTKA. Ninety percent of RCTs reported that rTKA significantly outperformed manual TKA in terms
                        of component positioning. Four of 6 RCTs reported significantly longer operative times with
                        rTKA. Most RCTs found no significant differences in functional outcomes, opioid use, or
                        complication rates.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_45" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The rate of publications on rTKA has increased
                        substantially in media sources and peer-reviewed journals, with the volume of media articles far
                        outpacing RCTs on the topic. More precise component positioning was the most consistently
                        reported benefit of rTKA in RCTs. However, media sources also reported a range of other, less
                        well-supported benefits, and employed overwhelmingly positive tones regarding rTKA, more so than
                        is supported by mixed clinical results. Efforts to ensure that patients and health-care
                        providers receive accurate and evidence-based information about new health technologies are
                        critical.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_46" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> This study demonstrates a clear disparity
                        between news media coverage of rTKA and the best clinical evidence available. This information
                        can help to guide discussions between patients and surgeons regarding the use of rTKA.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Total knee arthroplasty (TKA) is the gold standard
                  treatment for end-stage knee osteoarthritis. Over 400,000 primary TKAs are performed annually in the
                  United States, a number that is projected to rise to nearly 3.5 million by 2030<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Conventional TKA is a highly successful operation that
                  provides patients with substantial pain relief and functional restoration<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. Nevertheless, despite its high volume and good
                  survivorship<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>,
                  approximately 1 in 5 patients who have undergone primary TKA remain unsatisfied with their outcomes<a
                    href="" data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Since the first robotic-assisted TKA was performed in
                  1988<a href="" data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">5</span></a>, the use of robotics in TKA has grown substantially in both
                  sophistication and availability. Given the aforementioned substantial minority of unsatisfied TKA
                  patients, new innovations and technologies such as robotic TKA (rTKA) often aim to improve patient
                  satisfaction. Some of the purported benefits of rTKA include greater precision and accuracy in
                  component positioning<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>,
                  soft-tissue protection<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a>,
                  ergonomic designs<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">8</span></a>, and
                  reduced surgeon and surgical team fatigue<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">9</span></a>.
                  Improved early functional outcomes, reduced limb malalignment, and improved patient satisfaction have
                  also been reported<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">10</span></a>. On
                  the other hand, potential downsides include longer operative time, higher cost, limited implant
                  choices, and an initial learning curve<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">11</span></a>.
                  Overall, recent high-quality meta-analyses report more precise component positioning but without a
                  detectable difference in clinical outcomes, particularly beyond the acute postoperative period<a
                    href="" data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">15</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The news media is cited as many people’s most important
                  source of health information, and a majority consider taking specific actions following exposure to
                  health information in the media<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">16</span></a>.
                  Given the widespread public and media attention regarding rTKA, combined with the early and developing
                  state of the literature on the procedure, it is important that the media coverage and public messaging
                  about rTKA reflect the best available evidence. Thus, it was our aim to compare the volume of, and
                  messaging contained in, the media coverage of rTKA. Specifically, we sought to answer the following
                  research questions: (1) Does the volume of media coverage of rTKA align with the volume of
                  high-quality literature on the topic? (2) Do the purported benefits and harms of rTKA presented in the
                  media align with the results published in the scientific literature?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This study consisted of 2 separate but related reviews
                  pertaining to rTKA: a systematic review of the academic literature and a news media review. The
                  systematic review was conducted in accordance with the Cochrane Handbook for Systematic Reviews of
                  Interventions<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">17</span></a> and
                  reported according to the PRISMA (Preferred Reporting Items for Systematic reviews and Meta-Analyses)
                  guidelines<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">18</span></a>.
                  Given the unique methodology involved, the protocol for this review was not prospectively registered.
                  As there are currently no guidelines for conducting a media review, we developed its methodology on
                  the basis of similar previously published studies<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">19</span></a> and
                  in consultation with a research librarian.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Information
                Sources and Search Strategy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Both search strategies were developed by 2 authors (S.E.
                  and R.M.) in collaboration with a research librarian. Three databases (PubMed, Embase, and MEDLINE)
                  were searched on February 5, 2023, for publications from 1988 until that search date. The Factiva (Dow
                  Jones & Company) database was searched on April 7, 2023, for pertinent news articles (see Appendix 1).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Screening and Selection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">For the systematic review, all studies retrieved from the
                  databases were imported into Covidence (Veritas Health Innovation) and duplicates were removed. Two
                  authors (B.S. and R.S.) reviewed titles, abstracts, and full texts independently and in duplicate.
                  Discrepancies at each stage were resolved through consensus. If consensus was not reached, 2 senior
                  authors (S.E. and R.M.) were consulted for resolution. For the media review, all articles were
                  uploaded to a shared drive. Articles were then divided among 3 authors (B.S., R.S., and A.M.A.-C.),
                  who reviewed all articles independently. Uncertainties about inclusion were initially resolved by
                  automatic inclusion. After this stage, all included articles were secondarily verified by 2 senior
                  authors (S.E. and R.M.).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Eligibility
                Criteria</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">For the systematic review, English-language randomized
                  controlled trials (RCTs) that reported rTKA as an intervention were included. Non-RCT study designs
                  and reviews were excluded. For the media review, relevant English-language news articles that
                  mentioned rTKA were included. Examples of excluded articles were industry-focused press releases,
                  stock market reports, industry analyses, and physician award advertisements.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Collection and Data Items</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">For both reviews, data were abstracted independently by up
                  to 3 authors (B.S, R.S., and A.M.A.-C.) into a Microsoft Excel spreadsheet. Information extracted from
                  the RCTs included the number of participants, interventions and controls used, outcome measures, and
                  follow-up duration. Information extracted from the news articles included the news source, publishing
                  date, funding sources, and specific claims about rTKA. A random spot-check method was used to verify
                  extracted data. For media articles, the articles were classified on the basis of the article type (see
                  Appendix 2). Advantages and disadvantages of rTKA were grouped and coded, and the overall tone of each
                  article was recorded as favorable, cautionary, or neutral. Finally, the primary source(s) of
                  information used for each article was recorded: the results of a published study, a surgeon or
                  health-care provider expert, or an industry expert.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">RCT
                Risk-of-Bias Assessment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The risk of bias of the included RCTs was assessed using
                  the Cochrane Risk of Bias Assessment tool, version 2 (RoB2)<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">20</span></a>.
                  Two authors (B.S. and R.S.) performed the quality assessment for each article independently and in
                  duplicate. No quality assessment was conducted for the media review.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Effect
                Measures and Synthesis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Descriptive statistics were used to report study
                  characteristics, demographic information, and patient outcomes. The volume of RCTs and volume of media
                  coverage were plotted over time and compared directly. In addition, media coverage was broken down
                  according to various characteristics.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">During the study inclusion period, 15 RCTs met the
                  eligibility criteria (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). The
                  year in which the most RCTs were published was 2022, with 6 RCTs. A total of 2,616 patients were
                  randomized in the included studies. <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1-A</a>
                  contains the PRISMA flow diagram for the included RCTs. There were 460 media publications between 1991
                  and the search date that met the inclusion criteria. The number of media articles per year increased
                  over time, particularly since 2016.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Randomized Controlled Trial Study Details</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1750px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Study</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Sample
                          Size</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Intervention</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Control</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cho (2019)<a href=""
                            data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                              style="position: unset;">29</span></a><span data-id="superscript_18"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">351</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ROBODOC robotic-assisted
                          TKA (Curexo Technology)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional TKA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fontalis (2022)<a href=""
                            data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                              style="position: unset;">35</span></a><span data-id="superscript_19"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Robotic-assisted
                          TKA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional jig-based
                          TKA</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Kayani (2021)<a href=""
                            data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                              style="position: unset;">22</span></a><span data-id="superscript_20"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mako robotic-assisted TKA
                          (Stryker)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional jig-based
                          TKA</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Kim (2020)<a href=""
                            data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                              style="position: unset;">33</span></a><span data-id="superscript_21"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,348</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ROBODOC robotic-assisted
                          TKA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional TKA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Li (2022)<a href=""
                            data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                              style="position: unset;">21</span></a><span data-id="superscript_22"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">HURWA robotic-assisted
                          TKA (Beijing HURWA-Robot Technology)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional TKA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Liow (2017)<a href=""
                            data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                              style="position: unset;">32</span></a><span data-id="superscript_23"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ROBODOC robotic-assisted
                          TKA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional TKA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lychagin (2022)<a href=""
                            data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                              style="position: unset;">34</span></a><span data-id="superscript_24"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ROBODOC robotic-assisted
                          TKA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional manual
                          TKA</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Park (2007)<a href=""
                            data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                              style="position: unset;">23</span></a><span data-id="superscript_25"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ROBODOC robotic-assisted
                          TKA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional manual
                          TKA</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Song (2011)<a href=""
                            data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                              style="position: unset;">31</span></a><span data-id="superscript_26"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ROBODOC robotic-assisted
                          TKA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional manual
                          TKA</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Song (2013)<a href=""
                            data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                              style="position: unset;">27</span></a><span data-id="superscript_27"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ROBODOC robotic-assisted
                          TKA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional manual
                          TKA</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sun (2021)<a href=""
                            data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                              style="position: unset;">30</span></a><span data-id="superscript_28"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">SkyWalker
                          robotic-assisted TKA (Microport Orthopaedics)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional TKA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Thiengwittayaporn
                          (2021)<a href="" data-id="citation_reference_30"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_29" class="annotation superscript"
                              style="position: unset;">25</span></a><span data-id="superscript_29"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">152</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Navio robotic-assisted
                          TKA (Smith & Nephew)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional TKA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Vaidya (2022)<a href=""
                            data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                              style="position: unset;">28</span></a><span data-id="superscript_30"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Navio robotic-assisted
                          TKA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional TKA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Xu, J (2022)<a href=""
                            data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                              style="position: unset;">24</span></a><span data-id="superscript_31"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">YUANHUA robotic-assisted
                          TKA (Yuanhua Robotics)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional manual
                          TKA</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Xu, Z (2022)<a href=""
                            data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                              style="position: unset;">26</span></a><span data-id="superscript_32"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">YUANHUA robotic-assisted
                          TKA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Conventional manual
                          TKA</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node figure" data-id="figure_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-A</div>
                    <div class="image-download" name="JBJS.24.00264f1a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=66158ebb-9cdd-4502-bfe2-5cf477bb1a82&type=jpeg&name=JBJS.24.00264f1a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_22" style="position: unset;">
                            <div class="content" style="position: unset;">PRISMA flow diagram for the identification of
                              randomized controlled trials.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-B</div>
                    <div class="image-download" name="JBJS.24.00264f1b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=66158ebb-9cdd-4502-bfe2-5cf477bb1a82&type=jpeg&name=JBJS.24.00264f1b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_23" style="position: unset;">
                            <div class="content" style="position: unset;">Flow diagram for the identification of news
                              articles.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 2</a>
                  demonstrates the rate of growth in media versus RCT publications on the topic of rTKA since 1991.
                  Since 2016, the year-over-year growth rate for media articles was &gt;100%, including a 700% increase
                  from 2016 to 2017. In contrast, only 3 of the same 7 years demonstrated a &gt;100% increase in RCTs
                  published, and the maximum increase was 200% (from 1 RCT in 2020 to 3 in 2021).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00264f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=66158ebb-9cdd-4502-bfe2-5cf477bb1a82&type=jpeg&name=JBJS.24.00264f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Cumulative number of media and RCT articles
                          published on the topic of rTKA since 1991.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Media
                Review</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1-B</a>
                  contains a flow diagram for the identification of the 460 included media articles. The most common
                  media article type (182, 39.6%) was an announcement of a “first” in rTKA at a local, regional,
                  national, or international level. The least common type was the reporting or discussion of a published
                  rTKA study (33, 7.2%). <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a> shows
                  the frequency of each article type. Ninety-five percent of media publications highlighted at least 1
                  benefit of rTKA (437). The most commonly cited benefits were more precise implant positioning (380,
                  82.6%), followed by faster recovery (132, 28.7%) and improved function (104, 22.6%). <a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a> summarizes the reporting of rTKA benefits in the media.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Media Publications by Article Type<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 272px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Article Type</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Frequency</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Proportion</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">“First”
                          announcement</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">182</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">39.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Discussion of a product
                          and/or company</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">116</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">25.2%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">General rTKA
                          information</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patient experience</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.8%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Discussion of rTKA
                          research</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.2%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The sum of the proportions may exceed 100% as some of the 460 articles
                        covered multiple topics.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Benefits of rTKA Discussed in the Media
                          Publications<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 283px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">rTKA Benefit</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Frequency</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Proportion</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">More precise implant
                          positioning</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">380</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">82.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Faster recovery</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">132</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">28.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Improved function</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">104</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">22.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Less pain</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fewer
                          complications</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Faster operative
                          time</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.0%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The sum of the proportions may exceed 100% as some of the 460 articles
                        covered multiple topics.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">A total of 167 articles (36.3% of 460) mentioned at least
                  1 patient-centered benefit of rTKA, including improved function, faster recovery, shorter hospital
                  stays, and/or less pain. The vast majority of these 167 articles (146, 87.4%) referred to these
                  benefits in general terms, without citing published studies or specific functional scores. Fifteen
                  (9.0%) of the 167 studies used patient anecdotes to demonstrate benefits of rTKA. Such studies
                  frequently conflated benefits arising from overall advancements in TKA with benefits attributable to
                  rTKA; for example, 1 article compared a patient’s experience of a contralateral TKA performed in the
                  mid-1990s and a modern rTKA, with the implication that the robotic-assisted surgery was responsible
                  for the improved recovery. Similarly, some articles attributed outcomes such as same-day
                  weight-bearing, short hospital stays, and return to activities such as golf and swimming to rTKA even
                  though they are associated with the vast majority of modern TKAs regardless of technique.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Over two-thirds of the 460 media articles made reference,
                  by brand name, to a specific rTKA device and/or company (315, 68.5%). Fewer than 7% of media
                  publications (30) mentioned potential downsides to rTKA (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>), most
                  commonly a lack of evidence of its superiority (8, 1.7%), increased cost (7, 1.5%), the diminished
                  role of the surgeon (5, 1.1%), and the learning curve (5, 1.1%). Fewer than 10% of the media
                  publications (33) used a peer-reviewed publication as a main source of information. The most common
                  primary source of information was a surgeon expert (303, 65.9%), followed by an industry expert (212,
                  46.1%).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Potential Downsides of rTKA Discussed in the Media
                          Publications<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 283px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">rTKA Downside</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Frequency</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Proportion</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lack of evidence of
                          superiority</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Increased cost</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diminished role of the
                          surgeon</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Learning curve</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complications specific to
                          rTKA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Longer operative
                          time</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.9%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The sum of the proportions may exceed 100% as some of the 460 articles
                        covered multiple topics.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Overall, 89.3% (411) of the media articles employed a
                  favorable tone regarding rTKA, 3.7% (17) employed a neutral tone, and 7.0% (32) presented a cautionary
                  view of rTKA. Interestingly, among the subset of studies discussing the results of published studies,
                  69.7% (23 of 33) presented rTKA using a positive tone, while 21.2% (7) employed a neutral tone and
                  9.1% (3) presented a cautionary view of rTKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">RCT
                Review</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Ten RCTs reported on the precision of achieving implant
                  positioning and alignment targets, with 9 of the 10 reporting that rTKA significantly outperformed
                  manual TKA in at least 1 plane<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_33"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">29</span></a>, only 1 study reporting no difference<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">30</span></a>, and no studies reporting that manual TKA outperformed
                  rTKA. Four of 6 RCTs reporting on operative time found that rTKA took significantly longer<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">27</span></a>, while 2 reported no significant difference<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_36"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">32</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Ten studies reported at least 1 functional outcome, 9 of
                  which found no significant difference between rTKA and conventional TKA for any functional scores<a
                    href="" data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_37"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_37"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_37"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_37"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_37"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_37"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_37"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">35</span></a>. The functional outcomes in these studies included purely
                  patient-reported (e.g., Oxford Knee Score), purely examiner-assessed (e.g., range of motion), or
                  combined outcome measures (e.g., the Knee Society Score). Only 1 RCT found that patients treated with
                  rTKA were significantly more likely to achieve the minimal clinically important difference on the
                  Short Form-36 (SF-36)<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">32</span></a>.
                  All 4 RCTs assessing systemic inflammatory markers of soft-tissue damage found significantly less
                  inflammation in the rTKA group<a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_39"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_39"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_39"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">35</span></a>. Two of 3 RCTs assessing acute postoperative pain scores
                  (at 7 days to 1 month) found that patients treated with rTKA had significantly less pain<a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_40"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">35</span></a>, while the third found no difference<a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">34</span></a>. However, both of the studies reporting less pain found no
                  significant difference in opioid use between rTKA and manual TKA<a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_42"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">35</span></a>. Interestingly, the unblinded study that found no
                  significant difference in pain scores did find a significant reduction in opioid utilization in the
                  rTKA group in the first 24 hours postoperatively<a href="" data-id="citation_reference_59"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">26</span></a>. <a
                    href="" data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a> summarizes the remaining outcomes reported in the included
                  RCTs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Outcomes Reported in the Randomized Controlled Trials
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 702px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Outcome</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Number of RCTs Reporting Comparisons</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">rTKA
                          Significantly Better</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No
                          Significant Difference</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">rTKA
                          Significantly Worse</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Precision</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Operative time</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Function</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Range of motion</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Systemic
                          inflammation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Survivorship</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pain</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Opioid consumption</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complications</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Drainage</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Incision length</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Length of stay</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 3</a>
                  compares the proportion of publications reporting on the most common benefits and drawbacks of rTKA in
                  the media versus the published RCTs. <a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a> reports
                  the risk-of-bias assessments of the included RCTs. Most of the studies had a low risk of bias in most
                  domains.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00264f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=66158ebb-9cdd-4502-bfe2-5cf477bb1a82&type=jpeg&name=JBJS.24.00264f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;">The most commonly discussed outcomes, and the
                          results as reported by media articles and RCTs, as a proportion of the total number included
                          in this study.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_39" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Risk-of-Bias Assessments of the Randomized
                          Controlled Trials<span data-id="table_footnote_reference_12"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 449.364px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.472023);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Study</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Random
                          Sequence Generation<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Allocation
                          Concealment<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Participants Aware of Allocation<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Care
                          Providers Aware of Allocation<span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Outcome
                          Assessors (Data Analysts, Etc.) Aware of Allocation<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Incomplete
                          Outcome Data or Loss to Follow-up<span data-id="table_footnote_reference_9"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Selective
                          Reporting (Reporting Bias)<span data-id="table_footnote_reference_10"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Other
                          Bias<span data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cho (2019)<a href=""
                            data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                              style="position: unset;">29</span></a><span data-id="superscript_44"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PY</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fontalis (2022)<a href=""
                            data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                              style="position: unset;">35</span></a><span data-id="superscript_45"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PY</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Kayani (2021)<a href=""
                            data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                              style="position: unset;">22</span></a><span data-id="superscript_46"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PY</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Kim (2020)<a href=""
                            data-id="citation_reference_63" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                              style="position: unset;">33</span></a><span data-id="superscript_47"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Li (2022)<a href=""
                            data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                              style="position: unset;">21</span></a><span data-id="superscript_48"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PY</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PY</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NI</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Liow (2017)<a href=""
                            data-id="citation_reference_65" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                              style="position: unset;">32</span></a><span data-id="superscript_49"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lychagin (2022)<a href=""
                            data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                              style="position: unset;">34</span></a><span data-id="superscript_50"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PY</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Park (2007)<a href=""
                            data-id="citation_reference_67" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                              style="position: unset;">23</span></a><span data-id="superscript_51"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PY</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Song (2011)<a href=""
                            data-id="citation_reference_68" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_52" class="annotation superscript"
                              style="position: unset;">31</span></a><span data-id="superscript_52"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PY</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Song (2013)<a href=""
                            data-id="citation_reference_69" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                              style="position: unset;">27</span></a><span data-id="superscript_53"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PY</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sun (2021)<a href=""
                            data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_54" class="annotation superscript"
                              style="position: unset;">30</span></a><span data-id="superscript_54"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NI</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NI</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NI</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NI</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NI</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NI</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Thiengwittayaporn
                          (2021)<a href="" data-id="citation_reference_71"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_55" class="annotation superscript"
                              style="position: unset;">25</span></a><span data-id="superscript_55"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PY</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Vaidya (2022)<a href=""
                            data-id="citation_reference_72" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_56" class="annotation superscript"
                              style="position: unset;">28</span></a><span data-id="superscript_56"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PY</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Xu, J (2022)<a href=""
                            data-id="citation_reference_73" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_57" class="annotation superscript"
                              style="position: unset;">24</span></a><span data-id="superscript_57"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PY</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Xu, Z (2022)<a href=""
                            data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_58" class="annotation superscript"
                              style="position: unset;">26</span></a><span data-id="superscript_58"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Y</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PY</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">PN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Y = yes, PY = probably yes, PN = probably no, N = no, NI = no
                        information.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Y and PY = low risk of bias.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">N and PN = low risk of bias.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">There has been a rapidly growing interest in rTKA by the
                  mainstream media in recent years, as evidenced by the high volume of publications. Media portrayals of
                  rTKA were often overly optimistic and in some cases inaccurate compared with the available Level-I
                  evidence on the topic. The volume of news articles about rTKA far outpaced the availability of
                  high-quality evidence. This is not unique to rTKA, and not surprising given that the topic is one of
                  interest to the general public. This phenomenon (termed an “infodemic”) was also seen during the early
                  stages of the COVID-19 pandemic, when large numbers of publications, peer-reviewed and otherwise, were
                  produced in a short period of time despite a lack of high-quality evidence<a href=""
                    data-id="citation_reference_75" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_59" class="annotation superscript"
                      style="position: unset;">36</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">In addition to the difference in the volume of
                  information, the information being presented in the media about rTKA was overly optimistic, and at
                  times contrary to the best available evidence. Improved precision, a commonly cited benefit of rTKA in
                  media articles, is well supported by RCT evidence regarding improved precision of implant positioning
                  and limb alignment with rTKA versus manual TKA. For most other outcomes, however, the optimism
                  presented in many media articles was either unsupported by RCT evidence (e.g., functional outcomes) or
                  directly contradicted by it (e.g., operative time). Given that patient satisfaction remains one of the
                  most challenging problems in TKA, and the critical role that patient expectations play in
                  postoperative satisfaction, it is important that media depictions do not paint an unrealistic picture
                  of rTKA. It was particularly concerning that a small number of news articles made patently incorrect
                  or unverifiable claims, such as stating that rTKA “completely eliminates human error,” that it
                  requires “no bone cuts,” that it would allow professional athletes to continue to play their sport,
                  and that it results in a “perfect” TKA lasting “30 to 40 years.”</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Surprisingly, the issue of cost, which is a major barrier
                  to more widespread use of rTKA, was rarely discussed in either the media publications or RCTs.
                  Although there is a lack of trial-based economic evaluations, there is disagreement among the
                  model-based economic evaluation literature on rTKA. Some studies have found that rTKA can be
                  cost-effective in high-volume hospitals<a href="" data-id="citation_reference_76"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_60" class="annotation superscript" style="position: unset;">37</span></a>,
                  while others have found that rTKA, in its current form, is not cost-effective due to only marginal
                  gains in quality-adjusted life-years<a href="" data-id="citation_reference_77"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_61" class="annotation superscript" style="position: unset;">38</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Interestingly, the findings of this study are in contrast
                  to a prior study, also using the Factiva database, that evaluated media articles on robotic surgery
                  across all surgical specialties, with the majority being urological and gynecological robotic
                  procedures<a href="" data-id="citation_reference_78"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_62" class="annotation superscript" style="position: unset;">39</span></a>.
                  The authors of that study found that the vast majority of news articles employed a negative tone
                  regarding robotic-assisted surgery, with frequent discussions of high costs and complication rates<a
                    href="" data-id="citation_reference_79" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_63" class="annotation superscript"
                      style="position: unset;">39</span></a>. In contrast, the media coverage surrounding rTKA has been
                  overwhelmingly positive to date. Furthermore, despite excluding all direct advertisements from the
                  search strategy, over two-thirds of media publications specifically mentioned a robot by brand name
                  and/or manufacturer, which, combined with the overwhelmingly positive tones, raises concerns related
                  to potential hidden biases.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">The strengths of this study include using a novel and
                  combined approach to systematically review both RCTs and media publications on the same topic. By
                  comparing these sources over the same time period, we were able to evaluate concordance between the
                  media coverage and the best available evidence in terms of both quantity and messaging.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">This study also has certain limitations. Because of a lack
                  of well-established methodology for systematically reviewing and evaluating media sources, we took
                  guidance from previous similar studies. Our search, although systematic, is unlikely to have been
                  comprehensive, given the widespread and diverse landscape of the media. Nonetheless, we believe that
                  the included articles are a representative cross-section of the media coverage on this topic. Finally,
                  the academic literature was limited to RCTs, in an effort to focus on only the highest-quality
                  evidence being produced; although many more lower-level studies were undoubtedly published in the same
                  time frame, we elected to include only the findings of RCTs because they present the most reliable
                  conclusions regarding rTKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Future directions include ongoing evaluation of rTKA as it
                  continues to evolve and becomes more commonly used. News and social media are important sources of
                  information for the majority of the public, and for most patients, they are likely to be accessed more
                  frequently than peer-reviewed publications<a href="" data-id="citation_reference_80"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_64" class="annotation superscript" style="position: unset;">40</span></a>.
                  Given the rise of health misinformation on the internet in general, and social media in particular<a
                    href="" data-id="citation_reference_81" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_65" class="annotation superscript"
                      style="position: unset;">41</span></a><span data-id="superscript_65"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_82" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_65" class="annotation superscript"
                      style="position: unset;">42</span></a>, there is an onus on researchers to contribute meaningful
                  and accurate information to the online conversation. In addition, the production and dissemination of
                  accurate, evidence-based information on TKA in general, and rTKA in particular, represent an important
                  part of the future of rTKA research. New technology should be promoted and introduced into health-care
                  settings on the basis of excitement combined with evidence, not excitement alone.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">The rate of publications on rTKA has increased
                  substantially in both media sources and peer-reviewed journals, but the volume of media articles had
                  far outpaced that of RCTs on the topic. More precise component positioning was the most consistently
                  reported benefit of rTKA in RCTs. However, media sources also reported a range of other, less
                  well-supported benefits, and employed overwhelmingly positive tones regarding rTKA, more so than is
                  supported by mixed clinical results. Efforts to ensure that patients and health-care providers receive
                  accurate and evidence-based information about new health technologies are critical.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_4" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I200" target="_blank" data-id="link_5" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I200</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Kurtz S</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Ong K</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Lau E</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Mowat F</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Halpern M</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Projections of primary and
                  revision hip and knee arthroplasty in the United States from 2005 to 2030</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2007 Apr;89(4):780-5.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1007448"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Choi YJ</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Ra HJ</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Patient Satisfaction after Total
                  Knee Arthroplasty</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Knee Surg Relat Res.</span> 2016 Mar;28(1):1-15.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20Satisfaction%20after%20Total%20Knee%20Arthroplasty&as_occt=title&as_sauthors=%20%22YJ%20Choi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Argenson JN</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Boisgard S</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Parratte S</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Descamps S</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Bercovy M</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Bonnevialle P</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Briard JL</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Brilhault J</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Chouteau J</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Nizard R</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Saragaglia D</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Servien E</span>; French Society
                of Orthopedic and Traumatologic Surgery (SOFCOT). <span data-id="strong_3" class="annotation strong"
                  style="position: unset;">Survival analysis of total knee arthroplasty at a minimum 10 years’
                  follow-up: a multicenter French nationwide study including 846 cases</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Orthop Traumatol Surg
                  Res.</span> 2013 Jun;99(4):385-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Survival%20analysis%20of%20total%20knee%20arthroplasty%20at%20a%20minimum%2010%20years%E2%80%99%20follow-up%3A%20a%20multicenter%20French%20nationwide%20study%20including%20846%20cases&as_occt=title&as_sauthors=%20%22JN%20Argenson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Bourne RB</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Chesworth BM</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Davis AM</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Mahomed NN</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Charron KDJ</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Patient satisfaction after total
                  knee arthroplasty: who is satisfied and who is not?</span><span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2010
                Jan;468(1):57-63.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20satisfaction%20after%20total%20knee%20arthroplasty%3A%20who%20is%20satisfied%20and%20who%20is%20not%3F&as_occt=title&as_sauthors=%20%22RB%20Bourne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Jakopec M</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Harris SJ</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Rodriguez y Baena F</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Gomes P</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Cobb J</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Davies BL</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">The first clinical application
                  of a “hands-on” robotic knee surgery system</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Comput Aided Surg.</span>
                2001;6(6):329-39.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20first%20clinical%20application%20of%20a%20%E2%80%9Chands-on%E2%80%9D%20robotic%20knee%20surgery%20system&as_occt=title&as_sauthors=%20%22M%20Jakopec%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Hampp EL</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Chughtai M</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Scholl LY</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Sodhi N</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Bhowmik-Stoker M</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Jacofsky DJ</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Mont MA</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Robotic-Arm Assisted Total Knee
                  Arthroplasty Demonstrated Greater Accuracy and Precision to Plan Compared with Manual
                  Techniques</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J
                  Knee Surg.</span> 2019 Mar;32(3):239-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robotic-Arm%20Assisted%20Total%20Knee%20Arthroplasty%20Demonstrated%20Greater%20Accuracy%20and%20Precision%20to%20Plan%20Compared%20with%20Manual%20Techniques&as_occt=title&as_sauthors=%20%22EL%20Hampp%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Kayani B</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Konan S</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Pietrzak JRT</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Haddad FS</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Iatrogenic Bone and Soft Tissue
                  Trauma in Robotic-Arm Assisted Total Knee Arthroplasty Compared With Conventional Jig-Based Total Knee
                  Arthroplasty: A Prospective Cohort Study and Validation of a New Classification System</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2018
                Aug;33(8):2496-501.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Iatrogenic%20Bone%20and%20Soft%20Tissue%20Trauma%20in%20Robotic-Arm%20Assisted%20Total%20Knee%20Arthroplasty%20Compared%20With%20Conventional%20Jig-Based%20Total%20Knee%20Arthroplasty%3A%20A%20Prospective%20Cohort%20Study%20and%20Validation%20of%20a%20New%20Classification%20System&as_occt=title&as_sauthors=%20%22B%20Kayani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Haffar A</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Krueger CA</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Goh GS</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Lonner JH</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Total Knee Arthroplasty With
                  Robotic Surgical Assistance Results in Less Physician Stress and Strain Than Conventional
                  Methods</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2022 Jun;37(6S):S193-200.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20Knee%20Arthroplasty%20With%20Robotic%20Surgical%20Assistance%20Results%20in%20Less%20Physician%20Stress%20and%20Strain%20Than%20Conventional%20Methods&as_occt=title&as_sauthors=%20%22A%20Haffar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Clark G</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Steer R</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Tippett B</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Wood D</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Short-Term Benefits of Robotic
                  Assisted Total Knee Arthroplasty Over Computer Navigated Total Knee Arthroplasty Are Not Sustained
                  With No Difference in Postoperative Patient-Reported Outcome Measures</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Arthroplast Today.</span>
                2022 Jan 12;14:210-215.e0.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Short-Term%20Benefits%20of%20Robotic%20Assisted%20Total%20Knee%20Arthroplasty%20Over%20Computer%20Navigated%20Total%20Knee%20Arthroplasty%20Are%20Not%20Sustained%20With%20No%20Difference%20in%20Postoperative%20Patient-Reported%20Outcome%20Measures&as_occt=title&as_sauthors=%20%22G%20Clark%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Mancino F</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Cacciola G</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Malahias MA</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> De Filippis R</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> De Marco D</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Di Matteo V, A G</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Sculco PK</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Maccauro G</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> De Martino I</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">What are the benefits of
                  robotic-assisted total knee arthroplasty over conventional manual total knee arthroplasty? A
                  systematic review of comparative studies</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Orthop Rev (Pavia).</span> 2020 Jun
                25;12(1S)(Suppl 1):8657.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20are%20the%20benefits%20of%20robotic-assisted%20total%20knee%20arthroplasty%20over%20conventional%20manual%20total%20knee%20arthroplasty%3F%20A%20systematic%20review%20of%20comparative%20studies&as_occt=title&as_sauthors=%20%22F%20Mancino%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Chen Z</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Bhowmik-Stoker M</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Palmer M</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Coppolecchia A</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Harder B</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Mont MA</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Marchand RC</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Time-Based Learning Curve for
                  Robotic-Assisted Total Knee Arthroplasty: A Multicenter Study</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">J Knee Surg.</span> 2023
                Jul;36(8):873-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Time-Based%20Learning%20Curve%20for%20Robotic-Assisted%20Total%20Knee%20Arthroplasty%3A%20A%20Multicenter%20Study&as_occt=title&as_sauthors=%20%22Z%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Edge R</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Farrah K</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Robotic-Assisted Surgical
                  Systems for Knee Arthroplasty: CADTH Health Technology Review</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">CADTH Health Technology Review.</span> 2022
                Mar;2(3).</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robotic-Assisted%20Surgical%20Systems%20for%20Knee%20Arthroplasty%3A%20CADTH%20Health%20Technology%20Review&as_occt=title&as_sauthors=%20%22R%20Edge%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Ruangsomboon P</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Ruangsomboon O</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Pornrattanamaneewong C</span>,
                <span data-id="annotation_71" class="annotation" style="position: unset;"> Narkbunnam R</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Chareancholvanich K</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Clinical and radiological
                  outcomes of robotic-assisted versus conventional total knee arthroplasty: a systematic review and
                  meta-analysis of randomized controlled trials</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2023 Feb
                20;94:60-79.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20and%20radiological%20outcomes%20of%20robotic-assisted%20versus%20conventional%20total%20knee%20arthroplasty%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20randomized%20controlled%20trials&as_occt=title&as_sauthors=%20%22P%20Ruangsomboon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Riantho A</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Butarbutar JCP</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Fidiasrianto K</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Elson E</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Irvan I</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Haryono H</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Prasetio JN</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Radiographic Outcomes of
                  Robot-Assisted Versus Conventional Total Knee Arthroplasty: A Systematic Review and Meta-Analysis of
                  Randomized Clinical Trials</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">JB JS Open Access.</span> 2023 May 15;8(2):e23.00010.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20Outcomes%20of%20Robot-Assisted%20Versus%20Conventional%20Total%20Knee%20Arthroplasty%3A%20A%20Systematic%20Review%20and%20Meta-Analysis%20of%20Randomized%20Clinical%20Trials&as_occt=title&as_sauthors=%20%22A%20Riantho%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Bensa A</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Sangiorgio A</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Deabate L</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Illuminati A</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Pompa B</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Filardo G</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Robotic-assisted mechanically
                  aligned total knee arthroplasty does not lead to better clinical and radiological outcomes when
                  compared to conventional TKA: a systematic review and meta-analysis of randomized controlled
                  trials</span>. <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Knee
                  Surg Sports Traumatol Arthrosc.</span> 2023 Nov;31(11):4680-91.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robotic-assisted%20mechanically%20aligned%20total%20knee%20arthroplasty%20does%20not%20lead%20to%20better%20clinical%20and%20radiological%20outcomes%20when%20compared%20to%20conventional%20TKA%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20randomized%20controlled%20trials&as_occt=title&as_sauthors=%20%22A%20Bensa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Brodie M</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Hamel EC</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Altman DE</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Blendon RJ</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Benson JM</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Health news and the American
                  public, 1996-2002</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">J Health Polit Policy Law.</span> 2003 Oct;28(5):927-50.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20news%20and%20the%20American%20public%2C%201996-2002&as_occt=title&as_sauthors=%20%22M%20Brodie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Higgins J</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Thomas J</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Chandler J</span>, <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Cochrane Handbook for
                  Systematic Reviews of Interventions Version</span> 6.0. 2019. Accessed 2024 Jul 26. <a
                  href="https://training.cochrane.org/handbook/archive/v6" target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://training.cochrane.org/handbook/archive/v6</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Page MJ</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> McKenzie JE</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Bossuyt PM</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Boutron I</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Hoffmann TC</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Mulrow CD</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Shamseer L</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Tetzlaff JM</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Akl EA</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Brennan SE</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Chou R</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Glanville J</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Grimshaw JM</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Hróbjartsson A</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Lalu MM</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Li T</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Loder EW</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Mayo-Wilson E</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> McDonald S</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> McGuinness LA</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Stewart LA</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Thomas J</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Tricco AC</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Welch VA</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Whiting P</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Moher D</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">The PRISMA 2020 statement: an
                  updated guideline for reporting systematic reviews</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">BMJ.</span> 2021 Mar 29;372:n71.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20PRISMA%202020%20statement%3A%20an%20updated%20guideline%20for%20reporting%20systematic%20reviews&as_occt=title&as_sauthors=%20%22MJ%20Page%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Leroux J</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> McCullogh E</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Systematic news media scanning
                  and synthesis: creating a dataset of emergent initiatives and localized responses to public washroom
                  provision in Canada during the COVID-19 pandemic</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Health Promot Chronic Dis Prev Can.</span> 2023
                Aug;43(8):385-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Systematic%20news%20media%20scanning%20and%20synthesis%3A%20creating%20a%20dataset%20of%20emergent%20initiatives%20and%20localized%20responses%20to%20public%20washroom%20provision%20in%20Canada%20during%20the%20COVID-19%20pandemic&as_occt=title&as_sauthors=%20%22J%20Leroux%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Higgins JPT</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Savović J</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Page MJ</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Sterne JAC</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">RoB 2: A Revised Cochrane risk
                  of bias tool for randomized trials</span>. Accessed 2024 Jul 30. <a
                  href="https://methods.cochrane.org/bias/resources/rob-2-revised-cochrane-risk-bias-tool-randomized-trials"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://methods.cochrane.org/bias/resources/rob-2-revised-cochrane-risk-bias-tool-randomized-trials</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=RoB%202%3A%20A%20Revised%20Cochrane%20risk%20of%20bias%20tool%20for%20randomized%20trials&as_occt=title&as_sauthors=%20%22JPT%20Higgins%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Li Z</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Chen X</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Wang X</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Zhang B</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Wang W</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Fan Y</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Yan J</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Zhang X</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Zhao Y</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Lin Y</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Liu J</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Lin J</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">HURWA robotic-assisted total
                  knee arthroplasty improves component positioning and alignment – A prospective randomized and
                  multicenter study</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">J Orthop Translat.</span> 2022 Feb 16;33:31-40.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=HURWA%20robotic-assisted%20total%20knee%20arthroplasty%20improves%20component%20positioning%20and%20alignment%20%E2%80%93%20A%20prospective%20randomized%20and%20multicenter%20study&as_occt=title&as_sauthors=%20%22Z%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Kayani B</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Tahmassebi J</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Ayuob A</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Konan S</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Oussedik S</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Haddad FS</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">A prospective randomized
                  controlled trial comparing the systemic inflammatory response in conventional jig-based total knee
                  arthroplasty versus robotic-arm assisted total knee arthroplasty</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2021
                Jan;103-B(1):113-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20prospective%20randomized%20controlled%20trial%20comparing%20the%20systemic%20inflammatory%20response%20in%20conventional%20jig-based%20total%20knee%20arthroplasty%20versus%20robotic-arm%20assisted%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22B%20Kayani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Park SE</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Lee CT</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Comparison of robotic-assisted
                  and conventional manual implantation of a primary total knee arthroplasty</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2007
                Oct;22(7):1054-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20robotic-assisted%20and%20conventional%20manual%20implantation%20of%20a%20primary%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22SE%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Xu J</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Li L</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Fu J</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Xu C</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Ni M</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Chai W</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Hao L</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Zhang G</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Chen J</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Early Clinical and Radiographic
                  Outcomes of Robot-Assisted Versus Conventional Manual Total Knee Arthroplasty: A Randomized Controlled
                  Study</span>. <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Orthop
                  Surg.</span> 2022 Sep;14(9):1972-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20Clinical%20and%20Radiographic%20Outcomes%20of%20Robot-Assisted%20Versus%20Conventional%20Manual%20Total%20Knee%20Arthroplasty%3A%20A%20Randomized%20Controlled%20Study&as_occt=title&as_sauthors=%20%22J%20Xu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Thiengwittayaporn S</span>,
                <span data-id="annotation_156" class="annotation" style="position: unset;"> Uthaitas P</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Senwiruch C</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Hongku N</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Tunyasuwanakul R</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Imageless robotic-assisted
                  total knee arthroplasty accurately restores the radiological alignment with a short learning curve: a
                  randomized controlled trial</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Int Orthop.</span> 2021 Nov;45(11):2851-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Imageless%20robotic-assisted%20total%20knee%20arthroplasty%20accurately%20restores%20the%20radiological%20alignment%20with%20a%20short%20learning%20curve%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22S%20Thiengwittayaporn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Xu Z</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Li H</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Liu Z</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Zhang J</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Wang M</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Zhang Y</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Robot-assisted surgery in total
                  knee arthroplasty: trauma maker or trauma savior? A prospective, randomized cohort study</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Burns Trauma.</span> 2022
                Sep 16;10:tkac034.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robot-assisted%20surgery%20in%20total%20knee%20arthroplasty%3A%20trauma%20maker%20or%20trauma%20savior%3F%20A%20prospective%2C%20randomized%20cohort%20study&as_occt=title&as_sauthors=%20%22Z%20Xu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Song EK</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Seon JK</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Yim JH</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Netravali NA</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Bargar WL</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Robotic-assisted TKA reduces
                  postoperative alignment outliers and improves gap balance compared to conventional TKA</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2013 Jan;471(1):118-26.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robotic-assisted%20TKA%20reduces%20postoperative%20alignment%20outliers%20and%20improves%20gap%20balance%20compared%20to%20conventional%20TKA&as_occt=title&as_sauthors=%20%22EK%20Song%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Vaidya NV</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Deshpande AN</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Panjwani T</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Patil R</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Jaysingani T</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Patil P</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Robotic-assisted TKA leads to a
                  better prosthesis alignment and a better joint line restoration as compared to conventional TKA: a
                  prospective randomized controlled trial</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2022
                Feb;30(2):621-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robotic-assisted%20TKA%20leads%20to%20a%20better%20prosthesis%20alignment%20and%20a%20better%20joint%20line%20restoration%20as%20compared%20to%20conventional%20TKA%3A%20a%20prospective%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22NV%20Vaidya%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Cho KJ</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Seon JK</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Jang WY</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Park CG</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Song EK</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Robotic versus conventional
                  primary total knee arthroplasty: clinical and radiological long-term results with a minimum follow-up
                  of ten years</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Int Orthop.</span> 2019 Jun;43(6):1345-54.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robotic%20versus%20conventional%20primary%20total%20knee%20arthroplasty%3A%20clinical%20and%20radiological%20long-term%20results%20with%20a%20minimum%20follow-up%20of%20ten%20years&as_occt=title&as_sauthors=%20%22KJ%20Cho%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Sun M</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Yang L</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Guo L</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> He R</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">[Effect of robotic-arm assisted
                  total knee arthroplasty on femoral rotation alignment and its short-term effectiveness</span>]. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Zhongguo Xiu Fu Chong Jian
                  Wai Ke Za Zhi.</span> 2021 Jul 15;35(7):807-12. Chinese.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BEffect%20of%20robotic-arm%20assisted%20total%20knee%20arthroplasty%20on%20femoral%20rotation%20alignment%20and%20its%20short-term%20effectiveness&as_occt=title&as_sauthors=%20%22M%20Sun%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Song EK</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Seon JK</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Park SJ</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Jung WB</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Park HW</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Lee GW</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Simultaneous bilateral total
                  knee arthroplasty with robotic and conventional techniques: a prospective, randomized study</span>.
                <span data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Knee Surg Sports
                  Traumatol Arthrosc.</span> 2011 Jul;19(7):1069-76.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Simultaneous%20bilateral%20total%20knee%20arthroplasty%20with%20robotic%20and%20conventional%20techniques%3A%20a%20prospective%2C%20randomized%20study&as_occt=title&as_sauthors=%20%22EK%20Song%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Liow MHL</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Goh GSH</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Wong MK</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Chin PL</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Tay DKJ</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Yeo SJ</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Robotic-assisted total knee
                  arthroplasty may lead to improvement in quality-of-life measures: a 2-year follow-up of a prospective
                  randomized trial</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2017
                Sep;25(9):2942-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robotic-assisted%20total%20knee%20arthroplasty%20may%20lead%20to%20improvement%20in%20quality-of-life%20measures%3A%20a%202-year%20follow-up%20of%20a%20prospective%20randomized%20trial&as_occt=title&as_sauthors=%20%22MHL%20Liow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Kim YH</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Yoon SH</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Park JW</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Does Robotic-assisted TKA
                  Result in Better Outcome Scores or Long-Term Survivorship Than Conventional TKA? A Randomized,
                  Controlled Trial</span>. <span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2020 Feb;478(2):266-75.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20Robotic-assisted%20TKA%20Result%20in%20Better%20Outcome%20Scores%20or%20Long-Term%20Survivorship%20Than%20Conventional%20TKA%3F%20A%20Randomized%2C%20Controlled%20Trial&as_occt=title&as_sauthors=%20%22YH%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Lychagin A</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Elizarov M</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Gritsyuk A</span>, <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Robot-assisted Knee
                  Arthroplasty: Randomized Clinical Trial</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">Open Access Maced J Med Sci.</span>
                2022;10(B).</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robot-assisted%20Knee%20Arthroplasty%3A%20Randomized%20Clinical%20Trial&as_occt=title&as_sauthors=%20%22A%20Lychagin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Fontalis A</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Kayani B</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Asokan A</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Haddad IC</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Tahmassebi J</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Konan S</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Oussedik S</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Haddad FS</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Inflammatory Response in
                  Robotic-Arm-Assisted Versus Conventional Jig-Based TKA and the Correlation with Early Functional
                  Outcomes: Results of a Prospective Randomized Controlled Trial</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2022;104(21).</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inflammatory%20Response%20in%20Robotic-Arm-Assisted%20Versus%20Conventional%20Jig-Based%20TKA%20and%20the%20Correlation%20with%20Early%20Functional%20Outcomes%3A%20Results%20of%20a%20Prospective%20Randomized%20Controlled%20Trial&as_occt=title&as_sauthors=%20%22A%20Fontalis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Gazendam A</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Ekhtiari S</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Wong E</span>, <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">The “infodemic” of Journal
                  Publication Associated with the Novel Coronavirus Disease</span>. <span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2020;102(13).</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20%E2%80%9Cinfodemic%E2%80%9D%20of%20Journal%20Publication%20Associated%20with%20the%20Novel%20Coronavirus%20Disease&as_occt=title&as_sauthors=%20%22A%20Gazendam%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Hua Y</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Salcedo J</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Cost-effectiveness analysis of
                  robotic-arm assisted total knee arthroplasty</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">PLoS One.</span> 2022 Nov
                28;17(11):e0277980.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cost-effectiveness%20analysis%20of%20robotic-arm%20assisted%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22Y%20Hua%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Zhang JJY</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Chen JY</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Tay DKJ</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Pang HN</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Yeo SJ</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Liow MHL</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Cost-Effectiveness of
                  Robot-Assisted Total Knee Arthroplasty: A Markov Decision Analysis</span>. <span data-id="emphasis_37"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2023
                Aug;38(8):1434-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cost-Effectiveness%20of%20Robot-Assisted%20Total%20Knee%20Arthroplasty%3A%20A%20Markov%20Decision%20Analysis&as_occt=title&as_sauthors=%20%22JJY%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Ficko Z</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Koo K</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Hyams ES</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">High tech or high risk? An
                  analysis of media reports about robotic surgery</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">J Robot Surg.</span> 2017
                Jun;11(2):211-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High%20tech%20or%20high%20risk%3F%20An%20analysis%20of%20media%20reports%20about%20robotic%20surgery&as_occt=title&as_sauthors=%20%22Z%20Ficko%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Clarke MA</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Moore JL</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Steege LM</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Koopman RJ</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Belden JL</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Canfield SM</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Meadows SE</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Elliott SG</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Kim MS</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Health information needs,
                  sources, and barriers of primary care patients to achieve patient-centered care: A literature
                  review</span>. <span data-id="emphasis_39" class="annotation emphasis" style="position: unset;">Health
                  Informatics J.</span> 2016 Dec;22(4):992-1016.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20information%20needs%2C%20sources%2C%20and%20barriers%20of%20primary%20care%20patients%20to%20achieve%20patient-centered%20care%3A%20A%20literature%20review&as_occt=title&as_sauthors=%20%22MA%20Clarke%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Borges do Nascimento IJ</span>,
                <span data-id="annotation_237" class="annotation" style="position: unset;"> Pizarro AB</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Almeida JM</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Azzopardi-Muscat N</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Gonçalves MA</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Björklund M</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Novillo-Ortiz D</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Infodemics and health
                  misinformation: a systematic review of reviews</span>. <span data-id="emphasis_40"
                  class="annotation emphasis" style="position: unset;">Bull World Health Organ.</span> 2022 Sep
                1;100(9):544-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Infodemics%20and%20health%20misinformation%3A%20a%20systematic%20review%20of%20reviews&as_occt=title&as_sauthors=%20%22IJ%20Borges%20do%20Nascimento%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Suarez-Lledo V</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Alvarez-Galvez J</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Prevalence of health
                  misinformation on social media: Systematic review</span>. <span data-id="emphasis_41"
                  class="annotation emphasis" style="position: unset;">J Med Internet Res.</span> 2021 Jan
                20;23(1):e17187.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20health%20misinformation%20on%20social%20media%3A%20Systematic%20review&as_occt=title&as_sauthors=%20%22V%20Suarez-Lledo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="videosummary_reference" class="annotation videosummary_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node videosummary" data-id="videoabstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Video Abstract</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6366111792112&quot;,&quot;type&quot;:&quot;video&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;66158ebb-9cdd-4502-bfe2-5cf477bb1a82&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6366111792112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6366111792112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6366111792112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6366111792112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/2f42694b-57f4-40ae-8995-aa283f42b86b/main/1280x720/1m12s597ms/match/image.jpg"
                        src="blob:https://www.jbjs.org/f140c2e2-caaa-4e5c-b672-d15794162b02">
                        <track kind="metadata" label="segment-metadata">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/2f42694b-57f4-40ae-8995-aa283f42b86b/main/1280x720/1m12s597ms/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:25</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:25">
                            <div class="vjs-load-progress" style="width: 0%;"><span
                                class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">0.00%</span></span>
                              <div data-start="0" data-end="0" style="left: 0%; width: 0%;"></div>
                            </div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:25</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">captions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button vjs-disabled"
                          type="button" title="Picture-in-Picture" aria-disabled="true" disabled="disabled"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_705_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_705_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;66158ebb-9cdd-4502-bfe2-5cf477bb1a82&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=66158ebb-9cdd-4502-bfe2-5cf477bb1a82&type=supplement&name=12507"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D66158ebb-9cdd-4502-bfe2-5cf477bb1a82%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Systematic Reviews</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2384</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00264</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 19, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_42"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            University of Toronto, Toronto, Ontario, Canada</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_47"
                            class="annotation strong" style="position: unset;">A commentary by Kim Madden, PhD, and
                            Anthony Adili, MD, PEng, FRCSC,</span> is linked to the online version of this article.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=142365e1-e37a-4a23-85a2-8a2854735ab5&native=1"
                              class="article-title" style="position: unset;">Robot-Assisted Arthroplasty Research
                              Focuses on the Wrong Outcomes<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Commentary on an article by Seper Ekhtiari, MD, MSc, FRCSC,
                                et al.: “Evidence Versus Frenzy in Robotic Total Knee Arthroplasty. A Systematic Review
                                Comparing News Media Claims to Randomized Controlled Trial Evidence”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Madden,
                              Kim; Adili, Anthony</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(24):e54 | December 18, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;66158ebb-9cdd-4502-bfe2-5cf477bb1a82&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=66158ebb-9cdd-4502-bfe2-5cf477bb1a82&type=pdf&name=JBJS.24.00264.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=66158ebb-9cdd-4502-bfe2-5cf477bb1a82&type=pdf&name=JBJS.24.00264.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_48"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_49" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I199" target="_blank" data-id="link_3"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I199</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;66158ebb-9cdd-4502-bfe2-5cf477bb1a82&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=66158ebb-9cdd-4502-bfe2-5cf477bb1a82&type=zip&name=JBJS.24.00264.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Seper Ekhtiari, MD, MSc, FRCSC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:seper.ekhtiari@medportal.ca" class=""
                    style="position: unset;">seper.ekhtiari@medportal.ca</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4571-9655" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4571-9655</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Bryan Sun, BHSc<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-4513-2574" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-4513-2574</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Rabbea Sidhu, BHSc<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0004-3383-7779" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0004-3383-7779</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ayomide Michael Ade-Conde<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2424-9655" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2424-9655</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Harman Chaudhry, MD, MSc, FRCSC<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3584-8418" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3584-8418</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sebastian Tomescu, MD, MSc, FRCSC<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-0600-6626" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-0600-6626</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Bheeshma Ravi, MD, PhD, FRCSC<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1829-6885" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1829-6885</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Raman Mundi, MD, MSc, FRCSC<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5115-5227" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5115-5227</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery, McMaster University, Hamilton, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery, University of Toronto, Toronto, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Granovsky Gluskin Division of Orthopaedics, Sinai Health, Toronto, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Michael G. DeGroote School of Medicine, McMaster University, Hamilton, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Faculty of Health Sciences, McMaster University, Hamilton, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Royal College of Surgeons in Ireland, Dublin, Ireland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Sunnybrook Health Sciences Centre, Toronto, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 32180.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
